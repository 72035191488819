import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ACTION from "../../common/action_constant";
import "./cityMasters.less";

import MainComponent from "../../home/main/main";
import Button from "../../home/button-classic/button-classic";
import CheckBox from "./../../components/checkbox/checkbox";
import InputText from "../../common/components/input-text/input-text";
import Loader from "../../common/components/loader/loader";
import MSGPopUp from "../../common/components/msg-popup/msg-popup";

import { getCookie } from "../../../utils/cookies";
import moment from 'moment';
import Save from '../../../assets/images/save.svg'
import Cancel from '../../../assets/images/cancel.svg'
import Edit from '../../../assets/images/edit.svg'
import Download from '../../../assets/images/direct-download.svg'
import Select from "react-select";
import { SingleDatePicker } from 'react-dates';
import { getUserCompanyId, LR_FOR_PANTALOON_COMPANY_ID, PANTALOONS_COMPANY_IDS } from '../../../utils/common';


const SERVICE_MODE = [
    {label: 'Road', value: 1},
    {label: 'Bus', value: 2},
    {label: 'Rail', value: 3},
    {label: 'Air', value: 4},
    {label: 'Surface', value: 5},
    {label: 'Road-Coastal', value: 6},
    {label: 'Road-Rail', value: 7},
    {label: 'Road-Rail (BCN)', value: 8},
    {label: 'Road-Rail (Container)', value: 9},
    {label: 'Road-Rail-Coastal', value: 10},
    {label: 'Road-Air', value: 11},
]

class LaneMasters extends Component {
    constructor(props){
        super(props);
        this.state = {
            selectedLanes: [],
            serviceMode: 1,
            serviceType: 'FTL',
            routeType: 1,
            distributionType: 1,
            locationType: 1,
            isAdded: false,
            routes: false,
            offset: 0,
            limit: 20,
            from:'',
            to: '',
            focused: false,
            cityMasterData: (props.dataGovernance && props.dataGovernance.cityMasterData) ? props.dataGovernance.cityMasterData : [],
            vendorId: '',
            newData: {
                company_id: JSON.parse(getCookie("user")).company_id, //"0ba6f7f37e0743eaaf119d81756dfec2",
                lan_type: this.getServiceName(2),
                looking_for: 'city',
                mode_id: 2,
                tat: 1,
                tat_uom: '',
                contract_price: 0,
                vendor_name: '',
                vendor_id: '',
                validity: null,
                pincode: '',
                from: {
                        city_name:"",
                        city_id:"",
                        city_pincode:"",
                        state_name:"",
                        region: ""
                },
                to: {
                        city_name:"",
                        city_id:"",
                        city_pincode:"",
                        state_name:"",
                        region: ""
                }
            }
        }
    }

    componentDidMount() {
        this.getCityMasterData();
        this.getVendorsData();
    }

    componentWillReceiveProps(nextProps) {
        const { dataGovernance } = this.props;
        
        if (dataGovernance !== nextProps.dataGovernance) {
            if (dataGovernance.cityMasterData !== nextProps.dataGovernance.cityMasterData) {
                this.setState({
                    cityMasterData: nextProps.dataGovernance.cityMasterData
                });
            }
        }
    }

    getServiceName = (id) => {
        let name = '';
        switch(id) {
            case 1:
                name = 'LCL';
                break;
            case 2:
                name = 'FTL';
                break;
            case 3:
                name = 'Courier';
                break;
            default:
                break;
        }
        return name;
    }

    getServiceModeName = (id) => {
        let name = '';
        switch(id) {
            case 1:
                name = 'Multimode';
                break;
            case 2:
                name = 'Milkrun';
                break;
            default:
                break;
        }
        return name;
    }
    
    getDistributionName = (id) => {
        let name = '';
        switch(id) {
            case 1:
                name = 'Primary';
                break;
            case 2:
                name = 'Secondary';
                break;
            default:
                break;
        }
        return name;
    }


    getCities = (searchValue) => {
        const { dispatch } = this.props;
        const lanType = (this.state.locationType===1)?'city':'pincode';
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_CITY_LIST,
            params: `cityName=${searchValue}&lookingFor=${lanType}`,
        });
    }

    getHubs = (cityId) => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_HUBS,
            params: `city_id=${cityId}`,
        });
    }

    getCityMasterData = () => {
        const { dispatch } = this.props;
        const { serviceMode, locationType, routes, vendorId, limit, offset, from, to, serviceType, routeType, distributionType } = this.state;
        const companyId = JSON.parse(getCookie("user")).company_id;
        const lanType = (locationType===1)?'city':'pincode';
        
        let params = `companyId=${companyId}&lanType=${serviceType}&lookingFor=${lanType}&limit=${limit}&offset=${offset}`;
        if (vendorId.length > 0) {
            params = params + `&vendor_id=` + vendorId
        }
        if(routeType && serviceType === 'FTL'){
            if(LR_FOR_PANTALOON_COMPANY_ID.includes(getUserCompanyId())){
               
            } else {
                params = params + `&routeType=${routeType}`
            }
        }
        if(distributionType && serviceType === 'FTL'){
            params = params + `&distributionType=${distributionType}`
        }
        if(from.length >0){
            params=params+`&from=`+from
        }
        
        if(to.length >0){
            params=params+`&to=`+to
        }

        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_CITY_MASTER,
            params,
            onSuccess: (() => { this.setState({ editId: null, isAdded: false  }) })
        })
    }

    handleLaneSelection = (e, id) => {
        const { selectedLanes } = this.state;
        if (selectedLanes.includes(id)) {
            selectedLanes.splice(id, 1);
        } else {
            selectedLanes.push(id);
        }
        
        this.setState({ selectedLanes });
    }

    saveLane = () => {
        const { dispatch } = this.props;
        let { newData, routeType, distributionType, serviceType } = this.state;
        newData.route_type_id = routeType ? routeType : '';
        newData.distribution_type_id = distributionType ? distributionType : '';
        newData.lan_type = serviceType;
        newData.route_type = this.getServiceModeName(routeType);
        newData.distribution_type = this.getDistributionName(distributionType);
        dispatch({
                type: ACTION.DATA_GOVERNANCE.POST_CITY_MASTER,
                params: this.state.newData,
                onSuccess: (() => {
                    this.setState({ isAdded: false, 
                        newData: {
                            company_id: JSON.parse(getCookie("user")).company_id, //"0ba6f7f37e0743eaaf119d81756dfec2",
                            lan_type: this.getServiceName(2),
                            looking_for: 'city',
                            mode_id: 2,
                            tat: 1,
                            tat_uom: '',
                            contract_price: 0,
                            vendor_name: '',
                            vendor_id: '',
                            validity: null,
                            pincode: '',
                            from: {
                                    city_name:"",
                                    city_id:"",
                                    city_pincode:"",
                                    state_name:"",
                                    region: ""
                            },
                            to: {
                                    city_name:"",
                                    city_id:"",
                                    city_pincode:"",
                                    state_name:"",
                                    region: ""
                            }
                        } })
                    this.getCityMasterData();
                })
            })
    }

    updateDelayPenalty = (penalty) => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.UPDATE_DELAY_PENALTY,
            params: penalty,
            onSuccess: (() => {
                this.setState({ isAdded: false, editId: null });
                this.getCityMasterData();
            })
        });
    }

    deleteLanes = () => {
        const { selectedLanes } = this.state;
        const { dispatch } = this.props;
        const companyId = JSON.parse(getCookie("user")).company_id;
        const data = {
            company_id: companyId,
            route_ids: selectedLanes,
            tat_with_vendor: this.state.routes,
            lan_type: this.state.serviceType,
        };
        if (selectedLanes.length > 0) {
            dispatch({
                type: ACTION.DATA_GOVERNANCE.DELETE_CITY_MASTER,
                params: data,
                onSuccess: (() => {
                    this.setState({ selectedLanes: [] });
                    this.getCityMasterData();
                })
            });
        }
    }

    downloadLane = (downloadDetails) => {
        const { dispatch } = this.props;
        const companyId = JSON.parse(getCookie("user")).company_id;
        const vendorId=this.state.vendorId;
        let params = `companyId=${companyId}&lanType=${this.state.serviceType}`//&vendorId=${vendorId}`
        if (vendorId) {
            params = params + `&vendorId=` + vendorId
        }
        if (downloadDetails){
            params = params + `&withData=` + downloadDetails
        }
        dispatch({
            type: ACTION.DATA_GOVERNANCE.DOWNLOAD_CITY_MASTER,
            params,
            onSuccess: ((data) => {
                if (data.length > 0) {
                    this.setState({ downloadLink: data[0].Link });
                    this.downloadBtn.click();
                }
            })
        })
    }

    uploadLane = (e) => {
        let formData = new FormData();
        formData.append('file', e.target.files[0]);
        const { dispatch } = this.props;
        const company_id = JSON.parse(getCookie("user")).company_id;
        const {vendorId, routeType, distributionType, vendorName }=this.state;
        let data = {
            company_id,
            lan_type: this.state.serviceType,
        }
        if (vendorId) {
            data['vendor_id']=vendorId;
            data['vendor_name']=vendorName;
        }
        if (routeType && this.state.serviceType === 'FTL') {
            data['route_type_id']=routeType;
            data['route_type']=this.getServiceModeName(routeType);
        }
        if (distributionType && this.state.serviceType === 'FTL') {
            data['distribution_type_id']=distributionType;
            data['distribution_type']=this.getDistributionName(distributionType);
        }
        formData.append('jsonData', JSON.stringify(data));
        dispatch({ 
            type: ACTION.DATA_GOVERNANCE.UPLOAD_CITY_MASTER, 
            data: formData, 
            onSuccess: (() => {
                this.getCityMasterData();
            })
        });
    }

    closeMsgPopup = () =>{
        const {dispatch} = this.props;
        dispatch({ type : ACTION.MSG_DISPLAY.HIDE_MSG });
    }

    clickCustomCheckBox = (id, name) =>{
        this.setState({
            [name]: id,
            offset: 0,
        }, () => {
            if (name==='locationType') {
                const { newData } = this.state;
                newData.looking_for = (id===1) ? 'city' : 'pincode';
                this.setState({ newData })
            }
            if (name==='serviceType') {
                const { newData } = this.state;
                newData.lan_type = this.state.serviceType;
                this.setState({ newData })
            }
            if (name === 'routes') {
                const { newData } = this.state;
                newData.tat_with_vendor = this.state.routes;
                this.setState({ newData })
            }
            this.getCityMasterData();
        })
    }

    filterCity = () =>{
        this.getCityMasterData()
    }

    editLane = (id) => {
        this.setState({
            editId: this.state.editId===id ? null : id,
            isAdded: false
        });
    }

    getVendorsData = () => {
      const { dispatch } = this.props;
      dispatch({
          type: ACTION.DATA_GOVERNANCE.GET_VENDORS_TYPES,
      });
    }

    cancelAction = () => {
        this.setState({
          ...this.state,
          isAdded: false,
          newData: {
            company_id: JSON.parse(getCookie("user")).company_id, //"0ba6f7f37e0743eaaf119d81756dfec2",
            lan_type: this.getServiceName(2),
            looking_for: 'city',
            mode_id: 2,
            tat: 1,
            tat_uom: '',
            contract_price: 0,
            vendor_name: '',
            vendor_id: '',
            validity: null,
            pincode: '',
            from: {
                    city_name:"",
                    city_id:"",
                    city_pincode:"",
                    state_name:"",
                    region: ""
            },
            to: {
                    city_name:"",
                    city_id:"",
                    city_pincode:"",
                    state_name:"",
                    region: ""
            }
        }
        })
    }

    previous = () => {
        if (this.state.offset > 0) {
            this.setState({
                offset: this.state.offset - 20
            }, () => {
                this.getCityMasterData()
            });
        }
    }
    next = () => {
        this.setState({
            offset: this.state.offset + 20
        }, () => {
            this.getCityMasterData()
        });
    }

    onChangeInput = (value, name) => {
        let { newData } = this.state;
        newData[name] = value;
        this.setState({newData})
    }


    renderTableBody = (laneData) => {
        const { editId, isAdded, selectedLanes, locationType, routes } = this.state;
        return (
          <Fragment>
            {Object.keys(laneData || {}).length > 0 && laneData.tat_routes && 
                (laneData.tat_routes || []).map((lane, index) => {
                    const fromCity = lane.from || {};
                    const toCity = lane.to || {};
                return (
                    <div className="list-section content divTableRow" key={lane.route_id}>
                        <div className="divTableCell select-row"> 
                        {!isAdded && (index !== editId) && 
                            <input 
                                type="checkbox" 
                                defaultChecked={selectedLanes.includes(lane.route_id)}
                                onClick={(e) => this.handleLaneSelection(e, lane.route_id)}
                            />
                        }
                        </div>
                        <div className="divTableCell" style={{width: '180px'}}> {lane.vendor_name || ''} </div>
                        <div className="divTableCell"> {lane.service_mode || ''} </div>
                        <div className="divTableCell"> {fromCity.city_name || ''} </div>
                        <div className="divTableCell"> <div>{toCity.city_name || ''}</div>{toCity.state_name || ''}<div></div> </div>
                        {/* <div className="divTableCell">  </div> */}
                        <div className="divTableCell"> {lane.pincode || ''} </div>
                        {!LR_FOR_PANTALOON_COMPANY_ID.includes(getUserCompanyId()) && <div className="divTableCell"> {lane.contract_price || ''} </div>}
                        {!LR_FOR_PANTALOON_COMPANY_ID.includes(getUserCompanyId()) && <div className="divTableCell"> {lane.validity ? moment(lane.validity).format('DD/MM/YYYY') : '-'} </div>}
                        <div className="divTableCell"> {`${lane.tat} ${lane.tat_uom}`}</div>
                        {!LR_FOR_PANTALOON_COMPANY_ID.includes(getUserCompanyId()) && <div className="divTableCell"> {lane.approval_status === 1 ? 'Pending' : (lane.approval_status === 2 ? 'Approved' : 'Rejected')} </div>}
                        {!LR_FOR_PANTALOON_COMPANY_ID.includes(getUserCompanyId()) && <div className="divTableCell"> {lane.approval_remarks || ''} </div>}
                    </div>
                )
            })}
          </Fragment>
        )
    }
    
    render() {
        const { cityMasterData, isAdded, serviceMode, locationType, routes, from, to, serviceType, routeType, distributionType, vendorId, newData } = this.state;
        const { dataGovernance } = this.props;
        // const styles = this.customStyles()

        const SERVICE_TYPE = LR_FOR_PANTALOON_COMPANY_ID.includes(getUserCompanyId()) ?
            [
                { label: 'FTL', value: 1 },
                { label: 'LCL', value: 2 },
                { label: 'Courier', value: 3 },
                { label: 'MILKRUN', value: 5 },
                { label: 'DEDICATED', value: 6 }
            ]
            :
            [
                { label: 'FTL', value: 1 },
                { label: 'LCL', value: 2 },
                { label: 'Courier', value: 3 }
            ] 
        
        let cities = [];
        const toCity = newData.to;
        if (dataGovernance.citiesList && dataGovernance.citiesList.length > 0) {
            (dataGovernance.citiesList || []).forEach((items) => {
                cities = cities.concat(items.cities || items.states || []);
            });
        }
        return (
            <div className="discount-wrap">
                <div className="wrapper">
                    <MainComponent>
                        {this.props.loader.isLoading && <Loader />}
                        {this.props.msgpopup.active && <MSGPopUp close = {this.closeMsgPopup} type = {this.props.msgpopup.type} msg = {this.props.msgpopup.msg}/>}
                        <div className="sub-heading-section"> 
                            <div className="sub-heading"> City & TAT Masters </div>
                        </div>

                        <div>
                            <div className='filter-section'>
                            <div className="serviceTypes mt-10">
                                <div className="cta-action-btns">
                                    <div className="cta-action-btns filterSelect mr-20">
                                        <div className='label'>Service Type<span className='danger-color'>*</span></div>
                                        <Select
                                            className="subSelect"
                                            placeholder={'Select Service Type'}
                                            options={SERVICE_TYPE}
                                            value={SERVICE_TYPE.filter(obj => {return obj.label===this.state.serviceType})}
                                            onChange={(value) => { this.setState({ serviceType: value===null ? '' : value.label, selectedLanes: [] }, () => { this.getCityMasterData() }) }}
                                        />
                                    </div>
                                    
                                    {this.state.serviceType === 'FTL' && (LR_FOR_PANTALOON_COMPANY_ID.includes(getUserCompanyId()) ? false : true)  && 
                                    <div className="cta-action-btns filterSelect mr-20">
                                        <div className='label'>Route Type<span className='danger-color'>*</span></div>
                                        <Select
                                            className="subSelect"
                                            placeholder={'Select Route Type'}
                                            options={[{label: 'Multimode', value: 1},{label: 'Milkrun', value: 2}]}
                                            value={[{label: 'Multimode', value: 1},{label: 'Milkrun', value: 2}].filter(obj => {return obj.value===this.state.routeType})}
                                            onChange={(value) => { this.setState({ routeType: value===null ? '' : value.value }, () => { this.getCityMasterData() }) }}
                                        />
                                    </div>}
                                    {this.state.serviceType === 'FTL' && 
                                    <div className="cta-action-btns filterSelect mr-20">
                                        <div className='label'>Distribution Type<span className='danger-color'>*</span></div>
                                        <Select
                                            className="subSelect"
                                            placeholder={'Select Distribution'}
                                            options={[{label: 'Primary', value: 1},{label: 'Secondary', value: 2}]}
                                            value={[{label: 'Primary', value: 1},{label: 'Secondary', value: 2}].filter(obj => {return obj.value===this.state.distributionType})}
                                            onChange={(value) => { this.setState({ distributionType: value===null ? '' : value.value }, () => { this.getCityMasterData() }) }}
                                        />
                                    </div>}
                                    {/* <div className = "section-item">
                                        <CustomeCheckBox id={1} toggle={(id) => this.clickCustomCheckBox(id, 'serviceType')} selected={(serviceType===1)}/>
                                        <div className="title"> LCL</div>
                                    </div>
                                    <div className = "section-item">
                                        <CustomeCheckBox id={2} toggle={(id) => this.clickCustomCheckBox(id, 'serviceType')} selected={(serviceType===2)}/>
                                        <div className="title"> FTL</div>
                                    </div>
                                    <div className = "section-item">
                                        <CustomeCheckBox  id={3} toggle={(id) => this.clickCustomCheckBox(id, 'serviceType')} selected={(serviceType===3)}/>
                                        <div className="title"> Courier</div>
                                    </div> */}
                                </div>
                                
                                <div className="cta-action-btns filterSelect mr-20">
                                    <div className='label'>Vendor<span className='danger-color'>*</span></div>
                                    <Select
                                        isClearable={true}
                                        className="select"
                                        placeholder={'Vendor Select'}
                                        options={dataGovernance.vendorTypes || []}
                                        value={(dataGovernance.vendorTypes || []).filter(obj => {return obj.value===this.state.vendorId})}
                                        onChange={(value) => { this.setState({ vendorId: value===null ? '' : value.value, vendorName:  value===null ? '' : value.label}, () => { this.getCityMasterData() }) }}
                                    />
                                </div>
                            </div>
                            
                            <div className="serviceTypes">
                                {/* <div className = "section-item">
                                    <CustomeCheckBox id={false} toggle={(id) => this.clickCustomCheckBox(id, 'routes')} selected={(routes===false)}/>
                                    <div className="title"> Routes </div>
                                </div>
                                <div className = "section-item">
                                    <CustomeCheckBox id={true} toggle={(id) => this.clickCustomCheckBox(id, 'routes')} selected={(routes===true)}/>
                                    <div className="title" style={{ width: '85%' }}> Vendor-wise Routes </div>
                                </div> */}
                                {/* <div className="flex section-item">
                                    <div className="col-xs-2 col-5 mt-15 section-item ml-15">
                                        <Select styles={styles} 
                                        value={from_city} //{label: mode_type, value: mode_type}}
                                        options={(['Bangalore', 'Port-Blair']).map(mov => { return { label : mov, value: mov}})}
                                        onChange={(value) => { this.setState({ from_city: value })}}
                                        placeholder={'From City'}
                                        />
                                    </div>
                                    <div className="col-xs-2 col-5 mt-15 section-item ml-5 mr-5">
                                        <Select styles={styles} 
                                        value={to_city} //{label: mode_type, value: mode_type}}
                                        options={(['Port-Blair', 'Bangalore']).map(mov => { return { label : mov, value: mov}})}
                                        onChange={(value) => { this.setState({ to_city: value })}}
                                        placeholder={'To City'}
                                        />
                                    </div>
                                </div>
                            */}
                                <div className="section-item">
                                    <input 
                                        type="text"
                                        placeholder="From City" 
                                        className="border-bottom-input outline-none ht-30"  
                                        value={from}  
                                        onChange={(e)=> this.setState({from: e.target.value})}
                                    />    
                                    <input 
                                        type="text" 
                                        placeholder="To City" 
                                        className="border-bottom-input outline-none ht-30 ml-10" 
                                        value={to} 
                                        onChange={(e)=> this.setState({to: e.target.value})}
                                    />   
                                    <button className="button-classic-small ml-10" style={{height: '30px'}}  onClick={this.filterCity}>Search</button>  
                                </div>
                            </div>
                        </div>
                            <div className="cta-action-btns fRight">
                                <button className="button-classic" onClick={() => {this.downloadLane(false)}}> Download Temp.</button>
                                <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
                                
                                <button className={this.state.vendorId ? "button-classic" : 'btn-disabled'} disabled={!this.state.vendorId} onClick={() => {this.uploadExcel.click()}}> Upload Excel </button>
                                <input type='file' style={{ display: 'none'}}  ref={(e) => { this.uploadExcel = e ;}} onChange={this.uploadLane} onClick={(e) => {e.target.value = null }}/>
                                
                                <button className={this.state.vendorId ? "button-classic" : 'btn-disabled'} disabled={!this.state.vendorId} onClick={() => {this.downloadLane(true)}}>Download Data</button>
                                <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
                                
                                <button className="button-classic" onClick={() => { this.setState({ isAdded: true }) }}>Add</button>
                                <button className="button-classic" onClick={this.deleteLanes}>Delete</button>
                            </div>

                            {/*<div className="serviceTypes">
                                <div className = "section-item">
                                    <CustomeCheckBox id={1} toggle={(id) => this.clickCustomCheckBox(id, 'locationType')} selected={(locationType===1)}/>
                                    <div className="title"> City</div>
                                </div>
                                <div className = "section-item">
                                    <CustomeCheckBox id={2} toggle={(id) => this.clickCustomCheckBox(id, 'locationType')} selected={(locationType===2)}/>
                                    <div className="title"> Pincode</div>
                                </div>
                            </div>*/}

                            <div className="divTable company-list-wrap">
                                <div className="list-section table-header divTableHeading divTableRow">
                                    <div className="divTableCell select-row"> </div>
                                    <div className="divTableCell"> Vendor </div>
                                    <div className="divTableCell"> Mode </div>
                                    <div className="divTableCell"> From City </div>
                                    <div className="divTableCell"> To City & State </div>
                                    {isAdded && <div className="divTableCell"> To State </div>}
                                    <div className="divTableCell"> Pincode </div>
                                    {!LR_FOR_PANTALOON_COMPANY_ID.includes(getUserCompanyId()) && <div className="divTableCell"> Base Rate </div>}
                                    {!LR_FOR_PANTALOON_COMPANY_ID.includes(getUserCompanyId()) && <div className="divTableCell"> Validity Date </div>}
                                    <div className="divTableCell"> TAT </div>
                                    {!LR_FOR_PANTALOON_COMPANY_ID.includes(getUserCompanyId()) && <div className="divTableCell"> Status </div>}
                                    {!LR_FOR_PANTALOON_COMPANY_ID.includes(getUserCompanyId()) && <div className="divTableCell"> Remarks </div>}
                                </div>
                                <div className="divTableBody">
                                    {this.renderTableBody(cityMasterData)}
                                </div>

                            </div> 

                            <div className="submit-section-pagination">
                                <div className="next">
                                    <Button value="Next" disabled={Object.keys(cityMasterData || {}).length <= 0 || cityMasterData.tat_routes.length <=0 || (cityMasterData.tat_routes_count <= this.state.offset+this.state.limit)} click={this.next} />
                                </div>
                                <div className="pre">
                                    <Button value="Previous" disabled={this.state.offset===0} click={this.previous} />
                                </div>
                            </div>
                        {isAdded &&
                        <div className="add-user-wrap">
                        <div className="overlay"></div>
                        <div id="modal" className="modal-popup" style={{width: '1100px', minHeight: '380px'}}>
                            <div className="pop-heading"> Add City & TAT </div>
                            <div className="content">
                                <div className='wt-100p'>
                                    <div className="cta-action-btns filterSelect mr-20">
                                        <div className='label'>Vendor</div>
                                        <Select
                                            className="select"
                                            placeholder={'Search'}
                                            options={dataGovernance.vendorTypes || []}
                                            value={(dataGovernance.vendorTypes || []).filter(obj => {return obj.label===newData.vendor_name})}
                                            onChange={(value) => { 
                                                const { newData } = this.state;
                                                newData.vendor_name = value.label;
                                                newData.vendor_id = value.value;
                                                // newData.seeker_id = value.seeker_id;
                                                newData.company_category = value.company_category;
                                                newData.company_category_id = value.company_category_id;
                                                this.setState({ newData });
                                            }}
                                        />
                                    </div>
                                    <div className="cta-action-btns filterSelect mr-20"> 
                                    <div className='label'>Service Mode</div>
                                        <Select
                                            className="select"
                                            placeholder={'Search'}
                                            options={SERVICE_MODE}
                                            value={SERVICE_MODE.filter(obj => {return obj.label===newData.service_mode})}
                                            onChange={(value) => { 
                                                const { newData } = this.state;
                                                newData.service_mode = value.label;
                                                this.setState({ newData });
                                            }}
                                        />
                                    </div>
                                    <div className="cta-action-btns filterSelect mr-20"> 
                                    <div className='label'>From City</div>
                                    <Select
                                        className="select"
                                        options={cities || []}
                                        value={(cities || []).filter(obj => { return (obj.label === newData.from.city_name || obj.label === newData.from.state)})}
                                        isSearchable={true}
                                        onInputChange={(value, items) => {
                                            this.setState({ fromSearch: value })
                                            if (value.length > 1) {
                                                this.getCities(value);
                                            }
                                        }}
                                        isDisabled={this.props.dataGovernance.loading}
                                        isLoading={this.props.dataGovernance.loading}
                                        autoFocus={this.state.fromSearch}
                                        menuIsOpen={this.state.fromSearch}
                                        inputValue={this.state.fromSearch}
                                        placeholder={newData.from.city_name || newData.from.state_name || 'Search'}
                                        onChange={(value) => { 
                                            const { newData } = this.state;
                                            if (value.city_name) {
                                                newData.from.city_name = value.label;
                                                newData.from.city_id = value.city_id;
                                                newData.from.state_name = value.state_name;
                                                newData.from.state_id = value.state_id;
                                                newData.from.region = value.region;
                                            } else {
                                                newData.from.state_name = value.state_name;
                                                newData.from.state_id = value.state_id;
                                                newData.from.region = value.region;
                                            }
                                            this.setState({ newData, fromSearch: '' });
                                        }}
                                    />
                                    </div>
                                    <div className="cta-action-btns filterSelect mr-20"> 
                                    <div className='label'>To City</div>
                                        <Select
                                            className="select"
                                            options={cities || []}
                                            isSearchable={true}
                                            onInputChange={(value) => {
                                                this.setState({ toSearch: value })
                                                if (value.length > 1) {
                                                    this.getCities(value)
                                                }
                                            }}
                                            isDisabled={this.props.dataGovernance.loading}
                                            isLoading={this.props.dataGovernance.loading}
                                            autoFocus={this.state.toSearch}
                                            menuIsOpen={this.state.toSearch}
                                            inputValue={this.state.toSearch}
                                            placeholder={newData.to.city_name || newData.to.state_name || 'Search'}
                                            value={(cities || []).filter(obj => { return (obj.label === newData.to.city_name || obj.label === newData.to.state)})}
                                            onChange={(value) => { 
                                                const { newData } = this.state;
                                                if (value.city_name) {
                                                    newData.to.city_name = value.label;
                                                    newData.to.city_id = value.city_id;
                                                    newData.to.state_name = value.state_name;
                                                    newData.to.state_id = value.state_id;
                                                    newData.to.region = value.region;
                                                    // this.getHubs(value.city_id);
                                                } else {
                                                    newData.to.state_id = value.state_id;
                                                    newData.to.state_name = value.state_name;
                                                    newData.to.region = value.region;
                                                    // this.getHubs(value.state_id);
                                                }
                                                this.setState({ newData });
                                            }}
                                        />
                                    </div>
                                    <div className="cta-action-btns filterSelect mr-20"> 
                                    <div className='label' style={{marginBottom: '15px'}}>To State</div>
                                        {toCity.state_name || 'Auto-fetch'} 
                                    </div>
                                    </div>
                                    <div className='flex mt-20' style={{width: '100%'}}>
                                    <div className="tatInput"> 
                                    <div className='label'>Pincode</div>
                                        <InputText 
                                            type="number"
                                            placeholder="Pincode"
                                            value={newData.pincode} 
                                            changeHandler={(value) => { 
                                                let { newData } = this.state;
                                                newData.pincode = value;
                                                this.setState({ newData });
                                            }}
                                        />
                                        
                                    </div>
                                    <div className="tatInput"> 
                                    <div className='label'>Contract Rate</div>
                                        <InputText 
                                            type="number"
                                            placeholder="Rate"
                                            value={newData.contract_price} 
                                            changeHandler={(value) => { 
                                                const { newData } = this.state;
                                                newData.contract_price = value;
                                                this.setState({ newData });
                                            }}
                                        />
                                        
                                    </div>
                                    <div className="tatInput single-date-input">
                                    <div className='label'>Validity Date</div> 
                                        <SingleDatePicker
                                            noBorder={true}
                                            displayFormat={"DD MMM YYYY"}
                                            hideKeyboardShortcutsPanel={true}
                                            placeholder={"Date"}
                                            numberOfMonths={1}
                                            date={newData.validity && moment(newData.validity) || null} 
                                            onDateChange={(from) =>{
                                                const {newData} = this.state;
                                                newData.validity = moment(from).valueOf() || null;
                                                this.setState({ newData });
                                            }}
                                            focused={this.state.focusedStart} 
                                            onFocusChange={({ focused }) => this.setState({ focusedStart: focused })} 
                                            // isOutsideRange={(date) => date < new Date().setHours(0)}
                                        />
                                        </div>
                                    <div className="tatInput"> 
                                    <div className='label'>TAT</div>
                                    <InputText 
                                        type="number"
                                        placeholder="Tat"
                                        value={newData.tat} 
                                        changeHandler={(value, id) => {
                                            const { newData } = this.state;
                                            newData.tat = value;
                                            this.setState({ newData });
                                        }}
                                    /> 
                                    </div>
                                    <div className="tatInput" style={{width: '80px'}}> 
                                    <div className='label'>TAT UOM</div>
                                    <Select
                                        className="wt-120"
                                        placeholder={'Tat UOM'}
                                        options={[{label: 'Days', value: 'Days'},{label: 'Hours', value: 'Hours'}]}
                                        value={[{label: 'Days', value: 'Days'},{label: 'Hours', value: 'Hours'}].filter(obj => {return obj.value===newData.tat_uom})}
                                        onChange={(value) => { 
                                            const { newData } = this.state;
                                            newData.tat_uom = value.value;
                                            this.setState({ newData });
                                        }}
                                    />
                                    </div>
                                    </div>
                                    <div className="submit-section mt-40">
                                        <button className="add-button" onClick={this.saveLane}>Save</button>
                                        <button className="add-button discard" onClick={this.cancelAction}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        </div>
                        
                    </MainComponent>
                </div>
            </div>
            
        );
    }
}


const mapStateToProps = state => {
    return {
        loader: state.loader,
        msgpopup: state.msgpopup,
        dataGovernance: state.dataGovernance,
    }
}

export default connect(mapStateToProps)(LaneMasters);
