import React, { Component } from 'react';
import SkuMaster from './sku_master/sku_master';
import TruckMaster from './truck_master/truck_master';
// import DriverMaster from './driver_master/index'
import DriverMaster from './driver_master/Driver_master'

class VehiclePlanning extends Component {
    constructor(props) {
        super(props);
        this.state = {
          Tabs : [
            {
              label: "Truck Master",
              id: "1"
            },
            {
              label: "SKU Master",
              id: "2"
            },
            {
              label: "Driver Master",
              id: "3"
            }
          ],
          selectedOption : "1"
        }
      }
	componentDidMount(){
		const { dispatch } = this.props; 
    
	}
    onTabChange = (tabId) => {
        this.setState({
          selectedOption : tabId
        })
      }
    render() {
        return (
            <div className = "sub-navigation-panel-root">
            <div className = "sub-navigation-holder">
          {
            this.state.Tabs.map(tab => {
              return(<div key={tab.id} className={this.state.selectedOption === tab.id
                ? "selected-option options"
                : "options"} onClick={() => this.onTabChange(tab.id)}>{tab.label}</div>)
            })
          }
        </div>
        {this.state.selectedOption === "1" && (
          <TruckMaster
            truckDatalist={this.props.truck}
            getTruckDetails={this.props.getTruckDetails}
            addTruckDetails={this.props.addTruckDetails}
            updateTruckDetails={this.props.updateTruckDetails}
            deleteTruckDetails={this.props.deleteTruckDetails}
            uploadTruckDetails={this.props.uploadTruckDetails}
            downloadTruckDetails={this.props.downloadTruckDetails}
            downloadTruckTemp={this.props.downloadTruckTemp}
            downloadUrl={this.props.downloadUrl}
            downloadTempUrl={this.props.downloadTempUrl}
            getVehicleSize={this.props.getVehicleSize}
            vehicleSizeData={this.props.vehicleSizeData}
            // vehicleSizeList={this.props.vehicleSize}
            getTransporterDetails={this.props.getTransporterDetails}
            // transporterDatalist={this.props.transportData}
            getCompanyTransporters={this.props.getCompanyTransporters}
            vehicleSize={this.props.vehicleSizeData}
            companyTransporters={this.props.companyTransporters}
            getTruckDriverDetails={this.props.getTruckDriverDetails}
            driverData={this.props.driverData}
            getAllBranch={this.props.getAllBranch}
            allPlantBranches={this.props.allPlantBranches}
          />
        )}
        {this.state.selectedOption === "2" && 
          <SkuMaster
            skuDatalist={this.props.sku}
            getSkuDetails={this.props.getSkuDetails}
            addSkuDetails={this.props.addSkuDetails}
            updateSkuDetails={this.props.updateSkuDetails}
            downloadSkuDetails={this.props.downloadSkuDetails}
            downloadSkuUrl={this.props.downloadSkuUrl}
            deleteSkuDetails={this.props.deleteSkuDetails}
            uploadSkuDetails={this.props.uploadSkuDetails}
            downloadSkuTemp={this.props.downloadSkuTemp}
            downloadSkuTempUrl={this.props.downloadSkuTempUrl}
          />} 
            {this.state.selectedOption === "3" && <DriverMaster
            driverDataList={this.props.driverData}
            getDriverDetails={this.props.getDriverDetails}
            addDriverDetails={this.props.addDriverDetails}
            updateDriverDetails={this.props.updateDriverDetails}
            deleteDriverDetails={this.props.deleteDriverDetails}
            downloadDriverDetails={this.props.downloadDriverDetails}
            downloadDriverUrl={this.props.downloadDriverUrl}
            uploadDriverDetails={this.props.uploadDriverDetails}
            downloadDriverTemp={this.props.downloadDriverTemp}
            driverTempUrl={this.props.driverTempUrl}
            companyTransporters={this.props.companyTransporters}
            getCompanyTransporters={this.props.getCompanyTransporters}
            />}
      </div>
    );
  }
}

export default VehiclePlanning;
