import axios from "axios";

// import { logOutWithToken } from "./api";

import ACTION from "../src/common/action_constant";
import { getCookie } from "./cookies";
import { store } from "../src";
function getToken() {
  var data = getCookie('user');
  var token = data ? JSON.parse(data).token : "";
  return token;
}
var axiosT1 = axios.create({
  baseURL: process.env.BASE_URL,
  //   timeout: 10000,
  headers: {
    token: getToken()
  }
});
axiosT1.interceptors.request.use(function (config) {
  // console.log("config:", config);
  store.dispatch({
    type: ACTION.LOADER.START_LOADER_T1
  });
  return config;
}, function (error) {
  console.log("error:", error);
  store.dispatch({
    type: ACTION.LOADER.STOP_LOADER_T1
  });
  // store.dispatch({
  //   type: TOAST_ERROR,
  //   message:
  //     error && error.message ? error.message : "Oops! Something Went Wrong",
  // });

  return Promise.reject(error);
});
axiosT1.interceptors.response.use(function (response) {
  // console.log("response:", response);
  store.dispatch({
    type: ACTION.LOADER.STOP_LOADER_T1
  });
  if (response.status === 401) {
    // logOutWithToken();
  }
  return response;
}, function (error) {
  console.log("error:", error);
  store.dispatch({
    type: ACTION.LOADER.STOP_LOADER_T1
  });
  // store.dispatch({
  //   type: TOAST_ERROR,
  //   message:
  //     error && error.message ? error.message : "Oops! Something Went Wrong",
  // });
  return Promise.reject(error);
});

// axiosT2 is without loader

export var axiosT2 = axios.create({
  baseURL: process.env.BASE_URL,
  //   timeout: 10000,
  headers: {
    token: getToken()
  }
});
axiosT2.interceptors.request.use(function (config) {
  // console.log("config:", config);
  // store.dispatch({ type: ACTION.START_LOADER_T1 });
  return config;
}, function (error) {
  console.log("error:", error);
  // store.dispatch({ type: ACTION.STOP_LOADER_T1 });
  // store.dispatch({
  //   type: TOAST_ERROR,
  //   message:
  //     error && error.message ? error.message : "Oops! Something Went Wrong",
  // });

  return Promise.reject(error);
});
axiosT2.interceptors.response.use(function (response) {
  // console.log("response:", response);
  // store.dispatch({ type: ACTION.STOP_LOADER_T1 });
  if (response.status === 401) {
    // logOutWithToken();
  }
  return response;
}, function (error) {
  console.log("error:", error);
  // store.dispatch({ type: ACTION.STOP_LOADER_T1 });
  // store.dispatch({
  //   type: TOAST_ERROR,
  //   message:
  //     error && error.message ? error.message : "Oops! Something Went Wrong",
  // });
  return Promise.reject(error);
});
export default axiosT1;