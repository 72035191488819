import React, { Component } from 'react';
import Select from 'react-select';
import InputText from '../../../common/components/input-text/input-text';
import Button from '../../button-classic/button-classic';

class DisposalMaterial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.editData || {
                zone: '',
                depo_code: '',
                depo_name: '',
                material_code: '',
                material_description: '',
                product_type: '',
                pricing_basis: '',
                price: ''
            },
            zoneList: (props.disposalMaterialDropdowns.zone_name || []).map(el => { return {label: (el || '').toUpperCase(), value: (el || '').toUpperCase() }}),
            branchCodeList: (props.disposalMaterialDropdowns.branch_code || []).map(el => { return {label: el, value: el }}),
            branchList: (props.disposalMaterialDropdowns.branch_name || []).map(el => { return {label: el, value: el }}),
            materialCodeList: (props.disposalMaterialDropdowns.material_code || []).map(el => { return {label: el, value: el }}),
            materialList: (props.disposalMaterialDropdowns.material_description || []).map(el => { return {label: el, value: el }}),
            productTypeList: (props.disposalMaterialDropdowns.product_type || []).map(el => { return {label: (el || '').toUpperCase(), value: (el || '').toUpperCase() }}),
            uomList: (props.disposalMaterialDropdowns.pricing_basis || []).map(el => { return {label: el, value: el }})
        }
    }

    onSave = () => {
        const { data } = this.state;
        if (!(data.zone && data.depo_code && data.depo_name && data.material_code && data.material_description && data.product_type && data.pricing_basis && data.price)) {
            this.setState({ isError: true });
        } else {
            this.setState({ isError: false });
            this.props.onSaveDisposalMaterial(data);
        }
    }

    onUpdate = () => {
        const { data } = this.state;
        if (!(data.zone && data.depo_code && data.depo_name && data.material_code && data.material_description && data.product_type && data.pricing_basis && data.price)) {
            this.setState({ isError: true });
        } else {
            this.setState({ isError: false });
            this.props.onUpdateDisposalMaterial(data);
        }
    }

    onSelect = (name, value) => {
        const { data } = this.state;
        data[name] = value.label;
        this.setState({ data });
    }

    render() {
        const { data, zoneList, branchCodeList, branchList, materialCodeList, materialList, uomList, productTypeList} = this.state;
        return (
            <div className="route_code_detail disposal-product">
                <div onClick={this.props.toggleAdd} className="overlay"></div>
                <div id="modal" className="modal-popup">
                    <div className="pop-heading">
                        {`${this.props.editData ? 'Edit' : 'Add'} Disposal Product`}
                    </div>
                    
                    <div className="route-detail">
                        <div className="details-section">
                            <div className='select'>
                                <div className="input-text-wrap">Zone<sup className={'error error-font'}>*</sup></div>
                                <Select
                                    className="select"
                                    options={zoneList}
                                    value={zoneList.filter(val => val.label == data.zone)}
                                    onChange={(option) =>this.onSelect('zone', option)}
                                    placeholder="Zone"
                                />
                            </div>
                            <div className='select'>
                                <div className="input-text-wrap">Depot Code<sup className={'error error-font'}>*</sup></div>
                                <Select
                                    className="select"
                                    options={branchCodeList}
                                    value={branchCodeList.filter(val => val.label == data.depo_code)}
                                    onChange={(option) =>this.onSelect('depo_code', option)}
                                    placeholder="Depot Code"
                                />
                            </div>
                            <div className='select'>
                                <div className="input-text-wrap">Depot Name<sup className={'error error-font'}>*</sup></div>
                                <Select
                                    className="select"
                                    options={branchList}
                                    value={branchList.filter(val => val.label == data.depo_name)}
                                    onChange={(option) =>this.onSelect('depo_name', option)}
                                    placeholder="Depot Name"
                                />
                            </div>
                        </div>
                        <div className="details-section">
                            <div className='select'>
                                <div className="input-text-wrap">Material Code<sup className={'error error-font'}>*</sup></div>
                                <Select
                                    className="select"
                                    options={materialCodeList}
                                    value={materialCodeList.filter(val => val.label == data.material_code)}
                                    onChange={(option) =>this.onSelect('material_code', option)}
                                    placeholder="Material Code"
                                />
                            </div>
                            <div className='select'>
                                <div className="input-text-wrap">Material Description<sup className={'error error-font'}>*</sup></div>
                                <Select
                                    className="select"
                                    options={materialList}
                                    value={materialList.filter(val => val.label == data.material_description)}
                                    onChange={(option) =>this.onSelect('material_description', option)}
                                    placeholder="Material Desc."
                                />
                            </div>
                            <div className='select'>
                                <div className="input-text-wrap">Product Type<sup className={'error error-font'}>*</sup></div>
                                <Select
                                    className="select"
                                    options={productTypeList}
                                    value={productTypeList.filter(val => val.label == data.product_type)}
                                    onChange={(option) =>this.onSelect('product_type', option)}
                                    placeholder="Product Type"
                                />
                            </div>
                        </div>
                        <div className="details-section">
                            <div className='select'>
                                <div className="input-text-wrap">UOM<sup className={'error error-font'}>*</sup></div>
                                <Select
                                    className="select"
                                    options={uomList}
                                    value={uomList.filter(val => val.label == data.pricing_basis)}
                                    onChange={(option) =>this.onSelect('pricing_basis', option)}
                                    placeholder="UOM"
                                />
                            </div>
                            <div className='select'>
                                <InputText
                                    type="number"
                                    placeholder="MSP"
                                    id={"price"}
                                    label="MSP"
                                    changeHandler={(value) => {
                                        const { data } = this.state;
                                        data['price'] = value ;
                                        // && Number(value) || value;
                                        this.setState({ data })
                                    }}
                                    value={data.price}
                                    required={true}
                                />
                            </div>
                        </div>
                        {this.state.isError && <div className={'error-message'}>
								{'Please Enter all mandatory fields'}
						</div>}
                        <div className={'button_route'}>
                            <Button value={'Cancel'} click={this.props.toggleAdd} />
                            {!this.props.editData ?
                                <Button value={'Save'} click={this.onSave} />
                                :
                                <Button value={'Update'} click={this.onUpdate} />
                            }
                        </div>
                    </div>

                </div> 
            </div>
        )
    }
}

export default DisposalMaterial;