import React, { Component } from "react";
import "./header-panel.less";
import logo from "../../../assets/images/group-2.svg";
import translogo from "../../../assets/images/trans-logo.svg";
import logOut from "../../../assets/images/logout.svg";
import userIcon from "../../../assets/images/user.svg";
import { NavLink } from "react-router-dom";
import { getCookie, deleteCookie } from "../../../utils/cookies";
import history from "../../history";
import api from '../../common/api_config';

class TopPanel extends Component {
  constructor(props) {
    super(props);
  }
  logout = () =>{
      deleteCookie("user");
      window.location.href="/";
      localStorage.clear();
      // history.push('/')
  }
  SSOLogout = () => {
    deleteCookie("token");
    deleteCookie("company_id");
    deleteCookie("company_type");
    deleteCookie("user");
    localStorage.clear();
    location.replace(`${api.getSSO}/?slo`);
    setTimeout(() => {window.location.href="/"}, 5000);
  }
  render() {
    const panelOptions = [{ name: "Home", url: "" }];
    const name = this.props.newVendor ? "" : JSON.parse(getCookie("user")).name || "";
    const isSSO = localStorage.getItem('isSSOUser');
    return (
      <div className="header-panel" style={this.props.newVendor &&{ width:'98%', marginTop:'-29px' }}>
        <div className="logo-section" onClick={() => { history.push("/home") }}>
          <div className="img" style={{ backgroundImage: `url(${translogo})` }} />
          {/* <div className="header-content">Easing Transport Management</div> */}
        </div>
      
      {!this.props.newVendor && 
      <div className="user-detail-section">
          <div className="user-icon" style={{ backgroundImage: `url(${userIcon})` }} />
          <div className="user-name">{name}</div>
          <div className="logout" onClick={isSSO ? this.SSOLogout : this.logout}> 
            <span className="logout-icon" style={{ backgroundImage: `url(${logOut})` }} />
            Logout
          </div>
        </div>
        }

      </div>
    );
  }
}

export default TopPanel;
