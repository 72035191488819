import api from "../common/api_config";
import {getData, postData, postDataWithoutToken, getDataSSO} from "../../utils/ajax";
import ACTION from "../common/action_constant";
import { put, call } from 'redux-saga/effects';
import {getCookie,setCookie} from "../../utils/cookies"
import history from "../history";
import {GENERIC_TOKEN} from "../../utils/common";

function* login(action) {    
    try{
        const data = yield call(postData, api.login, (action.data));
        if(action.onSuccess){
            action.onSuccess(data)
        }
        setCookie("user", JSON.stringify(data.data));
        history.push("/home");
        yield put({type: ACTION.LOGIN.STORE_USER, user: data.data});
    }
    catch(error){
        if(action.onError){
            action.onError(error)
        }
        // yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : error.responseJSON.message}});

    }
}

function* validateSSOLogin(action) {    
    try{
        const data = yield call(postDataWithoutToken, api.ssoUserValidation, (action.data), GENERIC_TOKEN);
        if(action.onSuccess){
            action.onSuccess(data.data)
        }
    }
    catch(error){
        yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : error.responseJSON.message}});

    }
}

function* ssoLogin(action) {    
    try{
        const data = yield call(getDataSSO, api.ssoLoginResponse, (action.data));
        setCookie("user", JSON.stringify(data.data));
        if(action.onSuccess){
            action.onSuccess(data.data)
        }
        history.push("/home");
        yield put({type: ACTION.LOGIN.STORE_USER, user: data.data});
    }
    catch(error){
        yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : error.responseJSON.message}});

    }
}

function* ssoConfig(action) {    
    try{
        const data = yield call(postDataWithoutToken, api.ssoConfigResponse, (action.data), GENERIC_TOKEN);
        yield put({type: ACTION.LOGIN.STORE_SSO_CONFIG_DATA, ssoConfigData: data.data});
    }
    catch(error){
        yield put({ type : ACTION.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : error.responseJSON.message}});

    }
}


export {
    login,
    ssoLogin,
    validateSSOLogin,
    ssoConfig
};