import React from "react";
import Select from "react-select";
import { DateRangePicker } from "react-dates";
import Moment from "moment";
import ACTIONS from "../../../common/action_constant";
import Download from "../../../../assets/images/download.svg";
import Delete from "../../../../assets/images/delete.svg";
import Edit from "../../../../assets/images/edit.svg";
import "./LrRange.less";
import e from "cors";
import { connect } from "react-redux";
import { getUserCompanyName } from "../../../../utils/common";
import InputText from "../../../common/components/input-text/input-text";
import { getCookie } from "../../../../utils/cookies";

const MOGLIX_USER_ID = "3fc45396-37f5-11ed-a261-0242ac120002";
const SIMBHUS_USER_ID = "1f5b2d19-6140-4332-b132-b5731c1ead4f";

class LrRange extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            boundTypeLrRange: "",
            selectedLsp: "",
            LspRange: [],
            boundTypeOptions: [{ key: 1, value: 1, label: "Inbound" }],
            selectedLSPindex: [],
            allDataSelected: false,
            selectedSendLrOption: {},
            showAllLSPs: true,
        };
    }

    componentDidMount() {
        this.props.getBasicDetails()
        this.fetchLRRangeData()
        this.selectedOptions();
    }


    fetchLRRangeData = async () => {
        const { dispatch, basicDetails } = this.props;
        const user = getCookie('user') && JSON.parse(getCookie('user')) || {};

        const companyId = this.props && this.props.companyId || user.company_id;
        await dispatch({
            type: ACTIONS.HOME.FETCH_LR_RANGE,
            ...((basicDetails && basicDetails.company_type === 2) ? { companyType: basicDetails.company_type } : { companyId: companyId }),
            onSuccess: (data) => {
                this.setState({ getLrRangeData: data, filteredSummaryData: data });
            },
        });
    };


    handleSelectedLSP = (e) => {
        if (e) {
            const { lsp_id, lsp_name, dc_list, label, value, provider_id } = e;
            this.setState({ selectedLsp: value })
            this.setState({ selectedLspId: lsp_id })
            this.setState({ selectedLspName: lsp_name })
            this.setState({ selectedLspAlias: label })
            this.setState({ selectedLspDcList: dc_list })
            this.setState({ selectedLspProviderId: provider_id })
            this.setState({ showAllLSPs: false })
        }

    }

    handleSelectBoundTypeForUpload = (e) => {
        this.setState({ boundTypeLrRange: e.value });
    };

    selectLSP = (id, data, allSelected) => {
        const { selectedLSPindex } = this.state;
        const isProcessed = data.is_processed;
    
        if (allSelected) {
            if (this.state.allDataSelected) {
                return this.setState({ allDataSelected: !this.state.allDataSelected, selectedLSPindex: [] })
            } else {
                return this.setState({ allDataSelected: !this.state.allDataSelected })
            }
        }
    
        
        if (!isProcessed) {
            if (selectedLSPindex.length === 1 && selectedLSPindex[0] === id) {
                
                this.setState({ selectedLSPindex: [], allDataSelected: false });
            } else {
                
                this.setState({ selectedLSPindex: [id], allDataSelected: false });
            }
        }
    }

    onChangeForSetLR = (value, id) => {
        const { data } = this.state
        data[id] = value.replace(/[a-zA-Z&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '');
        this.setState({ data })
    }

    EditLrRangeData = (providerLrData) => {
        const { data } = this.state;

        this.setState({
            selectedLsp: providerLrData.lsp_alias,
            boundTypeLrRange: providerLrData.bound_type,
            lr_prefix: providerLrData.lr_prefix,
            lr_mgmt_id: providerLrData.lr_mgmt_id,
            created_on: providerLrData.created_on,
            edit_provider_data: true,

        },)
        data.start_lr = providerLrData.lr_from;
        data.end_lr = providerLrData.lr_to;

        this.setState({ data })

    }

    saveLRrange = () => {
        const { dispatch, basicDetails } = this.props;
        const { edit_provider_data } = this.state;
        //const companyName = getUserCompanyName()

        const user = getCookie('user') && JSON.parse(getCookie('user')) || {};

        const companyId = this.props && this.props.companyId || user.company_id;

        if (basicDetails && basicDetails.company_type === 1) {
            if (this.state.data.start_lr && this.state.data.end_lr) {
                if (!edit_provider_data) {
                    let payload = {
                        provider_id: this.state.selectedLspProviderId,
                        company_id: companyId,
                        lr_from: parseInt(this.state.data.start_lr),
                        lr_to: parseInt(this.state.data.end_lr),
                        lr_prefix: this.state.lr_prefix,
                        bound_type: this.state.boundTypeLrRange,
                        lsp_alias: this.state.selectedLsp,
                        lsp_id: this.state.selectedLspId,
                        lsp_name: this.state.selectedLspName,
                    }

                    dispatch({
                        type: ACTIONS.HOME.ADD_LR_RANGE,
                        data: payload,
                        onSuccess: (() => {
                            this.setState({ ...this.state, data: { start_lr: "", end_lr: "" } })
                            this.setState({ boundTypeLrRange: "", selectedLsp: "", lr_prefix: "", edit_provider_data: false })
                            this.fetchLRRangeData()
                        })
                    })
                }
                else {
                    let payload = {
                        // provider_id: this.state.selectedLspProviderId,
                        company_id: companyId,
                        lr_from: parseInt(this.state.data.start_lr),
                        lr_to: parseInt(this.state.data.end_lr),
                        lr_prefix: this.state.lr_prefix,
                        bound_type: this.state.boundTypeLrRange,
                        lsp_alias: this.state.selectedLsp,
                        lr_mgmt_id: this.state.lr_mgmt_id,
                        // created_on: this.state.created_on
                    }

                    dispatch({
                        type: ACTIONS.HOME.EDIT_LR_RANGE,
                        data: payload,
                        onSuccess: (() => {
                            this.setState({ ...this.state, data: { start_lr: "", end_lr: "" } })
                            this.setState({ boundTypeLrRange: "", selectedLsp: "", lr_prefix: "", edit_provider_data: false })
                            this.fetchLRRangeData()
                        })
                    })
                }
            }
            else {
                this.setState({ rangeError: 'Please select the LR range' })
            }

        }
    }

    deleteLrRange = (data) => {
        const { dispatch } = this.props;
        const payLoad = {
            lr_mgmt_id: data.lr_mgmt_id
        }
        dispatch({
            type: ACTIONS.HOME.DELETE_LR_RANGE,
            data: payLoad,
            onSuccess: (() => {
                this.fetchLRRangeData()
            })
        })
    }

    sendToMoglix = (data) => {

        this.setState((prevState) => ({
            count: prevState.count + 1,
        }));
        const { dispatch } = this.props;
        dispatch({
            type: ACTIONS.HOME.SEND_LR_RANGE_TO_MOGLIX,
            data: `companyId=${this.props.companyId}&lr_mngmt_id=${data.lr_mgmt_id}`,
            onSuccess: (data) => {
                dispatch({
                    type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
                    data: { type: 1, msg: data },
                })
                this.fetchLRRangeData()
            },
        });
    }
    sendToSimbus = (data) => {

        this.setState((prevState) => ({
            count: prevState.count + 1,
        }));
        const { dispatch } = this.props;
        dispatch({
            type: ACTIONS.HOME.SEND_LR_RANGE_TO_SIMBUS,
            data: `companyId=${this.props.companyId}&lr_mngmt_id=${data.lr_mgmt_id}`,
            onSuccess: (data) => {
                dispatch({
                    type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
                    data: { type: 1, msg: data },
                })
                this.fetchLRRangeData()
            },
        });
    }

    selectedOptions = () => {

        const { dispatch } = this.props;
        const user = getCookie('user') && JSON.parse(getCookie('user')) || {};
        const companyId = this.props && this.props.companyId || user.company_id;
        const { LspRange } = this.state
        dispatch({
            type: ACTIONS.HOME.SELECTED_OPTIONS,
            data: companyId,
            onSuccess: (data) => {
                data &&
                    data.map((ele) => {
                        LspRange.push({ value: ele.lsp_alias, label: ele.lsp_alias, lsp_id: ele.lsp_id, lsp_name: ele.lsp_name, dc_list: ele.dc_list, provider_id: ele.provider_id });
                        // LSPDropdownOptionsForDownload.push({ value: ele.lsp_alias, label: ele.lsp_alias })
                    });
                this.setState({ LspRange })
            },
        });
    };

    handleSendLrDropdownChange = (event, data) => {
        // this.setState({ selectedSendLrOption: event.target.value });
        const { value } = event.target;
        this.setState((prevState) => ({
            selectedSendLrOption: {
                ...prevState.selectedSendLrOption,
                [data.lr_mgmt_id]: value,
            },
        }));
    };



    render() {

        const { basicDetails } = this.props;
        const { lrProviderDetails, searchValue, LspRange } = this.state;
        // console.log("state", this.state)
        // console.log("basicDetails", basicDetails)
        const customStyles = {
            control: (base) => ({
                ...base,
                borderColor: "#528aea",
            }),
        };

        const CustomeCheckBox = (props) => {
            return (
                <div
                    className={
                        (props.selected || this.state.allDataSelected)
                            ? "custome-checkbox-wrap selected"
                            : "custome-checkbox-wrap"
                    }
                    onClick={() => { this.selectLSP(props.idx, props.data, props.allSelected) }}
                >
                    {(props.selected || this.state.allDataSelected) && <div className="inner-circle"></div>}
                </div>
            );
        };



        return (
            <div className="Lr-upload">
                <div className="upload">
                    {basicDetails && basicDetails.company_type === 1 && <div className="border">
                        <div className="flex-item">

                            <Select
                                styles={customStyles}
                                options={this.state.LspRange}
                                onChange={this.handleSelectedLSP}
                                className="dropLSP"
                                selectedItem={(option) => option.label === this.state.selectedLsp}
                                value={(this.state.LspRange || []).filter((lsp) => { return lsp.label === this.state.selectedLsp })}
                            />
                            <Select
                                styles={customStyles}
                                className="boundType"
                                options={this.state.boundTypeOptions}
                                onChange={this.handleSelectBoundTypeForUpload}
                                placeholder="Select Bound Type"
                                value={(this.state.boundTypeOptions || []).filter((bound) => { return bound.value === this.state.boundTypeLrRange })}
                            />
                            <input
                                name="lr_prefix"
                                placeholder=" Enter LR prefix"
                                style={{ width: "200px", height: '35px', padding: '5px', border: '1px solid #528AEA', borderRadius: '4px', color: '#528AEA', outline: 'none' }}
                                onChange={(e) => {
                                    this.setState({ lr_prefix: e.target.value })
                                }}
                                value={this.state.lr_prefix}
                            />

                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div className="lr-range">
                                    <div className="lr-rangeleft">
                                        <div className="lr-range1">
                                            <input id="start_lr" type={"text"} placeholder="LR" value={this.state.data.start_lr} onChange={(e) => { this.onChangeForSetLR(e.target.value, 'start_lr') }} />
                                        </div>

                                        <div className="lr-range-to">TO</div>
                                        <div className="lr-range1">
                                            <input id="end_lr" type={"text"} placeholder="LR" value={this.state.data.end_lr} onChange={(e) => { this.onChangeForSetLR(e.target.value, 'end_lr') }} />
                                        </div>
                                    </div>

                                    <div className="lr-rangeright" onClick={() => this.saveLRrange()}>
                                        Save
                                    </div>
                                </div>
                                <span style={{ color: 'red', fontSize: '14px' }}>{this.state.rangeError ? this.state.rangeError : ""}</span>
                            </div>

                        </div>
                    </div>}

                    <div className="table">

                        {basicDetails && basicDetails.company_type === 1 &&
                            <table className="table-view">
                                <thead>
                                    <tr className="table-tr">
                                        <th
                                            className="tCenter"
                                            width="50px"
                                            style={{ borderTopLeftRadius: "8px" }}
                                        ></th>
                                        <th className="tCenter" width="120px">
                                            LSP Name
                                        </th>
                                        <th className="tCenter" width="120px">
                                            Bound Type
                                        </th>
                                        <th className="tCenter" width="120px">
                                            LR Prefix
                                        </th>
                                        <th className="tCenter" width="120px">
                                            LR from
                                        </th>
                                        <th className="tCenter" width="120px">
                                            LR to
                                        </th>
                                        <th className="tCenter" width="160px">
                                            Select to Send
                                        </th>
                                        <th className="tCenter" width="160px">
                                            Sent LR to
                                        </th>
                                        <th
                                            className="tCenter"
                                            width="50px"
                                            style={{ borderTopRightRadius: "8px" }}
                                        >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.getLrRangeData && (this.state.getLrRangeData || []).map((data, idx) => {
                                        if (this.state.showAllLSPs || data.lsp_alias === this.state.selectedLspAlias) { // Filter by selected LSP
                                            return (
                                                <tr className="tr-1">
                                                    <td className="tCenter">
                                                        <CustomeCheckBox idx={idx} data={data} selected={this.state.selectedLSPindex.includes(idx)} />
                                                    </td>
                                                    <td>{data.lsp_alias}</td>
                                                    <td>{data.bound_type == 1 ? " Inbound" : "Outbound"}</td>
                                                    <td>{data.lr_prefix}</td>
                                                    <td>{data.lr_from}</td>
                                                    <td>{data.lr_to}</td>
                                                    <td >
                                                        {data.is_processed ? "" : <select value={this.state.selectedSendLrOption[data.lr_mgmt_id] || ''}
                                                            onChange={(event) => this.handleSendLrDropdownChange(event, data)}
                                                            style={{ border: "1px solid #729FEB", color: "#729FEB", padding: "5px" }}
                                                        >
                                                            <option style={{ backgroundColor: "#fffff", color: "#729FEB" }} value="">Select to Send</option>
                                                            <option style={{ backgroundColor: "#fffff", color: "#729FEB" }} value="monglix">Moglix</option>
                                                            <option style={{ backgroundColor: "#fffff", color: "#729FEB" }} value="simbus">Simbus</option>
                                                        </select>}
                                                    </td>
                                                    <td>
                                                        {this.state.selectedSendLrOption[data.lr_mgmt_id] === 'monglix' && (
                                                            <p style={{ color: '#528aea', cursor: 'pointer' }} onClick={() => this.sendToMoglix(data)}>
                                                                Send to Moglix
                                                            </p>
                                                        )}
                                                        {data.is_processed === true && data.aggregator_user_id === MOGLIX_USER_ID ? (
                                                            <p style={{ color: '#528aea', cursor: 'pointer' ,display:"flex",flexDirection:"column"}}>
            
                                                                <p>Sent to Moglix</p>
                                                                <p>{Moment(data.processed_date).format('DD MMM, YYYY HH:mm')}</p>
                                                            </p>
                                                            
                                                        ) : data.is_processed === true && data.aggregator_user_id === SIMBHUS_USER_ID ? (
                                                            <p style={{ color: '#528aea', cursor: 'pointer' ,display:"flex",flexDirection:"column"}} >
                                                                 <p>Sent to Simbus</p>
                                                                 <p>{Moment(data.processed_date).format('DD MMM, YYYY HH:mm')}</p>
                                                            </p>
                                                        ) : null}
                                                        {this.state.selectedSendLrOption[data.lr_mgmt_id] === 'simbus' && (
                                                            <p style={{ color: '#528aea', cursor: 'pointer' }} onClick={() => this.sendToSimbus(data)}>
                                                                Send to Simbus
                                                            </p>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {data.is_processed ? "" : <div style={{ display: 'flex' }}>
                                                            <div
                                                                className={"delete-icon"}
                                                                style={{ backgroundImage: `url(${Edit})` }}
                                                                onClick={() => this.EditLrRangeData(data)}
                                                            />

                                                            <div
                                                                className={"delete-icon"}
                                                                style={{ backgroundImage: `url(${Delete})` }}
                                                                onClick={() => this.deleteLrRange(data)}
                                                            />
                                                        </div>}

                                                    </td>
                                                </tr>
                                            );
                                        }
                                        else {
                                            return (
                                                null
                                                // <tr className="tr-1">
                                                //     <td className="tCenter">
                                                //         <CustomeCheckBox idx={idx} data={data} selected={this.state.selectedLSPindex.includes(idx)} />
                                                //     </td>
                                                //     <td>{data.lsp_alias}</td>
                                                //     <td>{data.bound_type == 1 ? " Inbound" : "Outbound"}</td>
                                                //     <td>{data.lr_prefix}</td>
                                                //     <td>{data.lr_from}</td>
                                                //     <td>{data.lr_to}</td>
                                                //     {/* <td onClick={() => this.sendToMonglix(data)}>
                                                //         {

                                                //                 <p style={{ color: "#528aea", cursor: 'pointer' }}>Send to monglix</p>
                                                //         }
                                                //     </td> */}
                                                //     <td key={data.lr_mgmt_id}>
                                                //         <select value={this.state.selectedSendLrOption[data.lr_mgmt_id] || ''} 
                                                //         onChange={(event) => this.handleSendLrDropdownChange(event, data)}
                                                //         style={{border:"1px solid #729FEB", color:"#729FEB",padding:"5px"}}
                                                //         >
                                                //             <option style={{backgroundColor:"#fffff", color:"#729FEB"}} value="">Select an option</option>
                                                //             <option style={{backgroundColor:"#fffff" , color:"#729FEB"}} value="monglix">Send to Moglix</option>
                                                //             <option style={{backgroundColor:"#fffff", color:"#729FEB"}} value="simbus">Send to Simbus</option>
                                                //         </select>
                                                //     </td>
                                                //     <td key={data.lr_mgmt_id}>
                                                //     {this.state.selectedSendLrOption[data.lr_mgmt_id] === 'monglix' && (
                                                //         <p style={{ color: '#528aea', cursor: 'pointer' }} onClick={() => this.sendToMoglix(data)}>
                                                //             Send to Monglix
                                                //         </p>
                                                //     )}
                                                //     {this.state.selectedSendLrOption[data.lr_mgmt_id] === 'simbus' && (
                                                //         <p style={{ color: '#528aea', cursor: 'pointer' }} onClick={() => this.sendToSimbus(data)}>
                                                //             Send to Simbus
                                                //         </p>
                                                //     )}
                                                //     </td>
                                                //     <td>
                                                //         <div style={{ display: 'flex' }}>
                                                //             <div
                                                //                 className={"delete-icon"}
                                                //                 style={{ backgroundImage: `url(${Edit})` }}
                                                //                 onClick={() => this.EditLrRangeData(data)}
                                                //             />

                                                //             <div
                                                //                 className={"delete-icon"}
                                                //                 style={{ backgroundImage: `url(${Delete})` }}
                                                //                 onClick={() => this.deleteLrRange(data)}
                                                //             />
                                                //         </div>

                                                //     </td>
                                                // </tr>
                                            );
                                        }

                                    })}
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        basicDetails: state.home.basicDetails
    };
};

export default connect(mapStateToProps)(LrRange);
