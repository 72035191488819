import React, { Fragment } from 'react'
import { ReactComponent as LeftArrowIcon } from '../../../icon/leftArrowIcon.svg';
const BackButton = (props) => {
    const { className , ...restProps} = props
    return (
        <Fragment>
            <button className={`textBtnT1 flex items-center ${!!className ? className : ''}`} {...restProps} >
                <LeftArrowIcon
                     fill='#627FE9'
                     width='30px'
                     height='30px'
                />
                 Back
            </button>
        </Fragment>
    )
}

export default BackButton