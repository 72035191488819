import React, { Component } from "react";
import {connect} from 'react-redux';
import InputText from "../../../../common/components/input-text/input-text";
import Select from "react-select";
import Button from "../../../../home/button-classic/button-classic";
// import {  PackagingType, LoadType, unloadingMhe } from '../../../../../utils/constants';
import { getCookie } from "../../../../../utils/cookies.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { validationCheck } from "../../../../../utils/validate";
import ACTION from "../../../../common/action_constant";

class AddItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company_id: JSON.parse(getCookie("user")).company_id,
      transporter_name: "",
      driver_name: "",
      age: "",
      contact: "",
      emergency_contact: "",
      dl_no: "",
      dl_expiry: "",
      joining_date: "",
      operation_months: "",
      total_experience: "",
      vaccine_fisrtdose_on: "",
      vaccine_seconddose_on: "",
      adhar_no: "",
      address: "",
      driver_type_name: "",
      attachments: []
    };
  }
  componentDidMount() {
    this.props.getCompanyTransporters();
  }
  handleChange = (id, selectedOption) => {
    if(id === "transporter_name"){
      this.setState({transporter_id: selectedOption.value});
    }
    this.setState({ [id]: selectedOption.label });
  };

  // onChangeHandler = (value, id) => {
  //   const data = this.state;
  //   data[id] = value;
  //   this.setState(data);
  // };

  validateCheck = () => {
    const { transporter_name, driver_name, age, contact, emergency_contact, dl_no, driver_type_name } = this.state;
    if(transporter_name && driver_name && age && contact && emergency_contact && dl_no && driver_type_name){
      return true;
    } else return false;
  }
  
  onSave = () => {
    const data = this.state;
    if(this.validateCheck() && this.state.contact.length === 10 && this.state.emergency_contact.length === 10){
      data.dl_expiry = data.dl_expiry ? moment(data.dl_expiry).format("DD/MM/YYYY") : undefined;
      data.joining_date = data.joining_date ? moment(data.joining_date).format("DD/MM/YYYY") : undefined;
      data.vaccine_fisrtdose_on = data.vaccine_fisrtdose_on ? moment(data.vaccine_fisrtdose_on).format("DD/MM/YYYY") : undefined;
      data.vaccine_seconddose_on = data.vaccine_seconddose_on ? moment(data.vaccine_seconddose_on).format("DD/MM/YYYY") : undefined;
      this.props.toggleAdd();
      this.props.addDriverDetails(data);
    } else {
      this.setState({errorMsg: "Please add all mandatory fields"})
    }
  };

  onUpload = () => {
    this.uploadUrl.click();
  };
  
  uploadfiles = (e) => {
		const { dispatch } = this.props;
		let formData = new FormData();
		formData.append("file", e.target.files[0]);
		dispatch({
		  type: ACTION.DATA_GOVERNANCE.UPLOAD_VEHICLE_FIELS,
		  formData,
		  onSuccess: (details) => {
        let { attachments } = this.state;
        attachments.push(details)
			  this.setState({attachments})
		  }
		});
	};

  removeAttachment = (index) => {
    let { attachments } = this.state;
    attachments.splice(index, 1);
    this.setState({ attachments })
  }

  SelectDate = (id, selectedOption) => {
    if(id === 'joining_date'){
      this.presentMonth(selectedOption);
    }
    this.setState({ [id]: selectedOption });
  };

  presentMonth = (date) => {
    const currentDate = new Date();
    let months;
    months = (currentDate.getFullYear() - date.getFullYear()) * 12;
    months -= date.getMonth();
    months += currentDate.getMonth();
    this.setState({operation_months: months});
  }

  onChangeHandler = (value, id, isType) => {
    const result = validationCheck(value, this.state[id], isType);
		this.setState({ [id]: result});
	}

  render() {
    const { companyTransporters } = this.props;
    const companyTranspoList =
    (companyTransporters || []).map((data) => ({
      label: data.company_name,
      value: data.id,
    })) || [];
    return (
      <div className="add-user-wrap">
        <div onClick={this.props.toggleAdd} className="overlay"></div>
        <div id="modal" className="modal-popup">
          <div className="pop-heading">Add Driver Master</div>
          <div className="content add-item-details">
          <div className="select-wrap">
          <div className="label">Transporter<span className='error-color'>*</span></div>
          <Select
            className="item-select"
            value={companyTranspoList.find((val) => {
              return val.value == this.state.transporter_name;
            })}
            options={companyTranspoList}
            onChange={this.handleChange.bind(this, "transporter_name")}
          />
        </div>
        <div className="select-wrap">
          <div className="label">Driver Type<span className='error-color'>*</span></div>
          <Select
            className="item-select"
            value={[{value: 1, label: 'Driver'}, {value: 2, label: 'Co-Driver'}].find((val) => {
              return val.value == this.state.driver_type_name;
            })}
            options={[{value: 1, label: 'Driver'}, {value: 2, label: 'Co-Driver'}]}
            onChange={this.handleChange.bind(this, "driver_type_name")}
          />
        </div>
           
            <div className="select-wrap">
              <InputText
                label="Driver Name"
                id="driver_name"
                value={this.state.driver_name}
                placeholder="Driver Name"
                changeHandler={(e) => this.setState({driver_name: e})}
                required
                type="text"
              />
            </div>

            <div className="select-wrap">
              <InputText
                type="number"
                label="Age "
                id="age"
                value={this.state.age}
                placeholder="Age "
                changeHandler={(e) => this.setState({age: (e > 0 && e < 100 || e == "") ? e : this.state.age})}
                required
              />
            </div>

            <div className="select-wrap">
            <InputText
                label="Driver Contact"
                id="contact"
                value={this.state.contact}
                placeholder="Driver Contact"
                changeHandler={this.onChangeHandler}
                length={'10'}
						    isValidate={'numeric'}
                required
              />
              {/* {this.state.contact && this.state.contact.length !== 10 && <div className="error error-font">Invalid contact number</div>} */}
            </div>
            <div className="select-wrap">
              <InputText
                label="Emergency Contact"
                id="emergency_contact"
                value={this.state.emergency_contact}
                placeholder="Emergency Contact"
                changeHandler={this.onChangeHandler}
                length={'10'} 
                isValidate={'numeric'}
                required
              />
              
            </div>
            <InputText
              label="Liecense No"
              placeholder="Liecense No"
              id="dl_no"
              value={this.state.dl_no}
              changeHandler={(e) => this.setState({dl_no: e})}
              required
            />
            <div className="select-wrap">
              <div className="data-picker">
                <div className="label">DL Expiry Date</div>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={this.state.dl_expiry}
                  onChange={this.SelectDate.bind(this, "dl_expiry")}
                  placeholderText={"DD/MM/YYYY"}
                />
              </div>
            </div>
            <div className="select-wrap">
              <div className="data-picker">
                <div className="label">Joining Date</div>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={this.state.joining_date}
                  onChange={this.SelectDate.bind(this, "joining_date")}
                  placeholderText={"DD/MM/YYYY"}
                  maxDate={new Date()}
                />
              </div>
            </div>
            <div className="select-wrap">
              <div className="data-picker">
                <div className="label">First Dose Date</div>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={this.state.vaccine_fisrtdose_on}
                  onChange={this.SelectDate.bind(this, "vaccine_fisrtdose_on")}
                  placeholderText={"DD/MM/YYYY"}
                />
              </div>
            </div>
            <div className="select-wrap">
              <div className="data-picker">
                <div className="label">Second Dose Date</div>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={this.state.vaccine_seconddose_on}
                  onChange={this.SelectDate.bind(this, "vaccine_seconddose_on")}
                  placeholderText={"DD/MM/YYYY"}
                />
              </div>
            </div>

            <InputText
              label="Present Operation(Months)"
              placeholder="Present Operation(Months)"
              id="operation_months"
              value={this.state.operation_months}
              changeHandler={this.onChangeHandler}
              isValidate={'numeric'}
              readonly={true}
            />
            <InputText
              label="Total Exp"
              placeholder="Total Exp"
              id="total_experience"
              value={this.state.total_experience}
              changeHandler={this.onChangeHandler}
              isValidate={'numeric'}
            />

            <InputText
              label="ID Proof (Addhar Number)"
              placeholder="ID Proof (Addhar Number)"
              id="adhar_no"
              value={this.state.adhar_no}
              changeHandler={(value) => this.setState({adhar_no: value})}
            />
            <InputText
              label="Residential Address"
              placeholder="Residential Address"
              id="address"
              value={this.state.address}
              changeHandler={(value) => this.setState({address: value})}
            />
            <div className="select-wrap">
                <div className="data-picker">
                  <div className="label1">
                    Attach Documents
                  </div>
                <Button value={"Upload"} click={this.onUpload} />
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={(e) => {
                    this.uploadUrl = e;
                  }}
                  onChange={(e) => {this.uploadfiles(e)}}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                />
                {(this.state.attachments || []).map((att, index) => {
                return(
                <div>
                  {att && att.name ? (
                    <a
                      href={att.url}
                      download
                      className={"theme-color fs-12 mt-5"}
                    >
                      {att.name}
                    </a>
                  ) : null}
                  <span className="error pl-20 curP" onClick={() => this.removeAttachment(index)}>X</span>
                </div>
                )})}
                </div>
              </div>
          </div>
          <div className="submit-button">
            {this.state.errorMsg && <div className="error-color mb-10">{this.state.errorMsg}</div>}
            {this.state.emergency_contact && this.state.contact && (this.state.contact.length !== 10 || this.state.emergency_contact.length !== 10) && <div className="error-color mb-10">{`Invalid ${this.state.contact.length !== 10 ? 'contact' : (this.state.emergency_contact.length !== 10 ? 'emergency contact' : '')} number`}</div>}
            <div className="button-section">
              <Button value="Save" click={this.onSave} />
            </div>
            <div className="button-section">
              <Button value="Cancel" click={this.props.toggleAdd} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(AddItem);
