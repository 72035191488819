import React, { Component } from 'react';
import Button from "../../../button-classic/button-classic";
import Delete from "../../../../../assets/images/delete.svg";
import Edit from "../../../../../assets/images/edit.svg";
import Search from '../../../../common/components/search-text/search-text';
import AddMfsMaster from './add_mfs_master';
import actions from '../../../../common/action_constant';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { getEmail } from '../../../../../utils/common';

class Mfs extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
            search: ''
        }
    }

    componentDidMount() {
        this.props.getMfsMasterListing();
        this.props.getMfsMasterUomListing();


    }

    addMfsMaster = (editData = '') => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen, editData, previous_data: editData });
    }

    toggleDirectDelete = (mfs) => {
        const data = {
            material_code: mfs.material_code
        }
        this.props.deleteMfsMaster(data);
    }
    downloadTemp = (withData) => {
        const { dispatch, companyId, depotCode } = this.props;
        const queryString = `?company_id=${companyId}&depotCode=${depotCode}`;
        dispatch({
            type: actions.PLANTS.DOWNLOAD_MFS,
            queryString,
            withData,
            onSuccess: ((data) => {
                this.setState({ downloadUrl: data ? data[0].Link : '' }, () => {
                    this.downloadLink.click();
                });
            })
        })
    }
    uploadVM = () => {
        this.uploadExcel.click();
    }
    uploadMFSMaster = (e) => {
        let formData = new FormData();
        formData.append('file', e.target.files[0]);
        const { dispatch, companyId, depotCode } = this.props;
        const user_email=getEmail();
        const queryString = `?companyId=${companyId}&user_email=${user_email}`;
        dispatch({
            type: actions.PLANTS.UPLOAD_MFS, data: formData,
            queryString,
            onSuccess: () => {
                this.props.getMfsMasterListing();
            }
        });
    }
    onAuditClick = () => {
        const {history,branch_id,company_id,depotCode} = this.props;
        const companyId = company_id
        const branchId = branch_id
        const branchCode=depotCode
        const masterType = 14
        const navUrl = `/auditScreen/${companyId}/${masterType}/${branchId}/${branchCode}`;
        history.push(navUrl)
      }

    render() {

        const { mfsMasterListing, mfsMasterUomListing } = this.props;
        return (
            <div className="plants-depots-main-wrap">
                <div className="search-holder">
                    <div className="add-plants-section">
                        <div style={{ display: 'block', paddingBottom: '15px' }}>
                            <Search id="search" value={this.state.search} placeholder="Search..." changeHandler={(e) => this.setState({ search: e })} click={() => this.props.getMfsMasterListing(this.state.search)} canClear={this.state.search !== ''} handleClearFilter={() => this.setState({ search: '' }, this.props.getMfsMasterListing())} />
                        </div>
                        <Button value="+ Mfs Master" click={() => this.addMfsMaster('')} />
                        <Button value={'Download Temp.'} click={() => this.downloadTemp(true)} />
                        <a href={this.state.downloadUrl} style={{ display: 'none' }} ref={(e) => { this.downloadLink = e; }} />
                        <Button value={'Download Details'} click={() => this.downloadTemp(false)} />
                        <a href={this.state.downloadUrl} style={{ display: 'none' }} ref={(e) => { this.downloadLink = e; }} />
                        <Button value={'Upload'} click={this.uploadVM} />
                        <input type='file' style={{ display: 'none' }} ref={(e) => { this.uploadExcel = e; }} onChange={this.uploadMFSMaster} onClick={(e) => { e.target.value = null }} />
                        <button className="button-classic ml-10" onClick={()=>this.onAuditClick()}>Audit</button>
                    </div>
                </div>
                <div className="heading">
                    <div className="flow-head">Material code</div>
                    <div className="flow-head">Quantity</div>
                    <div className="flow-head">Percentage</div>
                    <div className="flow-head">Uom</div>
                    <div className="flow-head">Actions</div>
                </div>
                {(mfsMasterListing || []).map((mfs, index) => {
                    return (
                        <div className={"depot-users-list"} key={`mfs-${index}`}>
                            <div className="flow-head">{mfs.material_code}</div>
                            <div className="flow-head">{mfs.quantity}</div>
                            <div className="flow-head">{mfs.percentage}</div>
                            <div className="flow-head">{mfs.uom}</div>
                            <div className="flow-head">
                                <div className="delete-icon" style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.addMfsMaster(mfs)}></div>
                                <div className="delete-icon" style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleDirectDelete(mfs)}></div>
                            </div>
                        </div>)
                })}

                {(!mfsMasterListing || mfsMasterListing.length === 0) && <div className={'no_data_found'}>{'No Data Found'}</div>}

                {this.state.isOpen &&
                    <AddMfsMaster
                        toggleAdd={this.addMfsMaster}
                        onAddMfsMaster={this.props.onAddMfsMaster}
                        updateMfsMaster={this.props.updateMfsMaster}
                        data={this.state.editData}
                        mfsMasterUomListing={mfsMasterUomListing}
                        previous_data={this.state.previous_data} />}
            </div>
        )
    }
}

export default connect(null)(withRouter(Mfs));
