import React, { Component, Fragment } from 'react';
import Search from '../../common/components/search-text/search-text';
import Select from 'react-select';
import Button from '../../home/button-classic/button-classic';
// import Delete from '../../../assets/images/delete.svg';
import Edit from '../../../assets/images/edit.svg';
import Delete from '../../../assets/images/delete.svg';
import { connect } from "react-redux";
import ACTIONS from '../../common/action_constant';
import { toPascalCase } from "../../../utils/validate";
import Download from '../../../assets/images/direct-download.svg';
import { getCookie } from "../../../utils/cookies";
import './material-details.less';

class MaterialDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowPopup: false
        }
    }

    componentDidMount(){
        this.getMaterialDetials();
    }

    getMaterialDetials = () => {
        const { dispatch, company_id } = this.props;
        dispatch({
            type: ACTIONS.HOME.GET_MATERIAL_ITEM_DETAILS,
            companyId: company_id || JSON.parse(getCookie('user')).company_id
        })
    }

    showDetails = (index) => {
        this.setState({isShowPopup: true, viewId: index});
    }

    render(){
        const { materialDetails } = this.props;
        const { isShowPopup, viewId } = this.state;
        return(
            <div className={'location-network'}>
                <div className={'chain-details-section'}>
                    <div className={'list-section header'}>
                        <div className={'tabel-header'}>Product Name</div>
                        <div className={'tabel-header'}>Material Code</div>
                        <div className={'tabel-header'}>Product Category</div>
                        <div className={'tabel-header'}>Material Group</div>
                        <div className={'tabel-header'} style={{width: '125px'}}>Product UOM</div>
                        <div className={'tabel-header'}>Actions</div>
                    </div>
                    {materialDetails && materialDetails.length > 0 ?
                    (materialDetails || []).map((data, index) => {
                        return(
                            <div className={'list-section content'} key={`data_${index}`}>
								<div className={'tabel-header'}>{data.product_name}</div>
                                <div className={'tabel-header'}>{data.material_code}</div>
                                <div className={'tabel-header'}>{data.product_category}</div>
                                <div className={'tabel-header'}>{data.material_group}</div>
                                <div className={'tabel-header'} style={{width: '125px'}}>{data.prodcut_uom}</div>
                                <div className={'tabel-header'}>
                                    <button className="button-default" onClick={() => this.showDetails(index)}>View Details</button>
                                </div>
                            </div>
                        )
                    }) :
                    <div className={'no_data_found'}>No Data Found</div>}
                </div>
                {isShowPopup && 
                    <div className="route_code_detail">
                        <div onClick={this.props.toggleAdd} className="overlay"></div>
                        <div id="modal" className="modal-popup">
                            <div className="pop-heading">
                                {'Material Details'}
                            </div>
                            <div className="flex m-10 p-10">
                                <div className="wt-33p">Product Name: {materialDetails[viewId].product_name}</div>
                                <div className="wt-33p">Material Code: {materialDetails[viewId].material_code}</div>
                                <div className="wt-33p">Material Description: {materialDetails[viewId].material_desc}</div>
                            </div>
                            <div className="flex m-10 p-10">
                                <div className="wt-33p">Thickness Description: {materialDetails[viewId].thickness_desc}</div>
                                <div className="wt-33p">Product Size: {materialDetails[viewId].product_size}</div>
                                <div className="wt-33p">Product Grade: {materialDetails[viewId].product_grade}</div>
                            </div>
                            <div className="flex m-10 p-10">
                                <div className="wt-33p">Product Grade Desc.: {materialDetails[viewId].product_grade_dec}</div>
                                <div className="wt-33p">Product Category: {materialDetails[viewId].product_category}</div>
                                <div className="wt-33p">Product Category Desc.: {materialDetails[viewId].product_category_desc}</div>
                            </div>
                            <div className="flex m-10 p-10">
                                <div className="wt-33p">Material Group: {materialDetails[viewId].material_group}</div>
                                <div className="wt-33p">Material Group Desc.: {materialDetails[viewId].material_group_desc}</div>
                            </div>
                            <div className="flex m-10 p-10">
                                <div className="wt-33p">Design No.: {materialDetails[viewId].design_no}</div>
                                <div className="wt-33p">Design Desc.: {materialDetails[viewId].design_description}</div>
                                <div className="wt-33p">Finish Code: {materialDetails[viewId].finish_code}</div>
                            </div>
                            <div className="flex m-10 p-10">
                                <div className="wt-33p">Vision Panel Code: {materialDetails[viewId].vision_panel_code}</div>
                                <div className="wt-33p">Vision Panel Desc.: {materialDetails[viewId].vision_panel_desc}</div>
                                <div className="wt-33p">Lipping Description: {materialDetails[viewId].lipping_desc}</div>
                            </div>
                            <div className="flex m-10 p-10">
                                <div className="wt-33p">Product UOM: {materialDetails[viewId].prodcut_uom}</div>
                                <div className="wt-33p">Alternative Units: {materialDetails[viewId].alternatine_units}</div>
                            </div>
                            <div className="flex m-10 p-10">
                                <div className="wt-33p">Numerator: {materialDetails[viewId].numerator}</div>
                                <div className="wt-33p">Denominator: {materialDetails[viewId].denominator}</div>
                                <div className="wt-33p">Weight: {materialDetails[viewId].weight}</div>
                            </div>
                            <div className="flex m-10 p-10">
                                <div className="wt-33p">Sales Organization: {materialDetails[viewId].sales_org}</div>
                                <div className="wt-33p">Product Hierarchy: {materialDetails[viewId].product_hierarchy}</div>
                                <div className="wt-33p">Distribution Channel: {materialDetails[viewId].distribution_chnl}</div>
                            </div>
                            <div className="flex m-10 p-10">
                                <div className="wt-33p">Delivery Plant: {materialDetails[viewId].delivery_plant}</div>
                            </div>
                            <div className="mt-10 mb-10 tCenter">
                                <button className="button-default" onClick={() => this.setState({isShowPopup: false, viewId: ""})}>Close</button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        materialDetails : state.home.materialItemDetails || []
    };
};
    
export default connect(mapStateToProps)(MaterialDetails);