import api from "../common/api_config";
import { getData, postData,  fileUpload} from "../../utils/ajax";
import ACTION from "../common/action_constant";
import { put, call } from "redux-saga/effects";

function* getAllEmailsList(action) {
  try {
    //console.log("dsada", action);
    yield put({
        type: ACTION.LOADER.SHOW_LOADER
    });
    const url = "?type=" + action.data.type;
    const data = yield call(getData, api.getAllEmails + url, action.data);
    //console.log("email data", data.emailsList);
    yield put({
      type: ACTION.BROADCAST.GET_ALL_EMAILS_DATA,
       emailsList: data.emailsList
    });
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
  });
  } catch (error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: error.responseJSON.message }
    });
  }
}
function* copyAllEmailsList(action) {
   try {
      //console.log("dsada", action);
      //const url = "?type=" + action.data.type;
      yield put({
        type: ACTION.LOADER.SHOW_LOADER
      });
      const data = yield call(getData, api.copyAllEmails, action.data);
      //console.log("email data", data.emailsList);
      yield put({
         type: ACTION.BROADCAST.COPY_ALL_EMAILS_DATA,
         data: data
      });
      yield put({
        type: ACTION.LOADER.HIDE_LOADER
    });
   } catch (error) {
      yield put({
        type: ACTION.LOADER.HIDE_LOADER
    });
      yield put({
         type: ACTION.MSG_DISPLAY.SHOW_MSG,
         data: { type: 2, msg: error.responseJSON.message }
      });
   }
}

function* postAllEmailsList(action) {
  try {
    yield put({
        type: ACTION.LOADER.SHOW_LOADER
    });
    const data = yield call(fileUpload, api.postAllEmails, action.data);
    console.log("data", data);
    yield put({ type: ACTION.BROADCAST.POST_ALL_EMAILS_DATA, user: data.data });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 1, msg: data && data.result && data.result.message || 'Email Sent' }
    })
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
  });
  } catch (error) {
    yield put({
      type: ACTION.LOADER.HIDE_LOADER
    });
    yield put({
      type: ACTION.MSG_DISPLAY.SHOW_MSG,
      data: { type: 2, msg: error.responseJSON.message }
    });
  }
}

export { getAllEmailsList, postAllEmailsList , copyAllEmailsList };
