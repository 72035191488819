import React, { Component } from 'react';
import InputText from "../../../../../common/components/input-text/input-text";
import Select from 'react-select';
import validate from "../../../../../../utils/validate";

class AddVendor extends Component {
    constructor(props){
        super(props);
        this.state = {
            name : "",
            company_id : "",
            branch_id:"",
            contact:"",
            no_of_people:"",
            error : "",
            iserror : "",
            errormessage : ""
        }
    }

    onChangeHandler = (value, id, isType) =>{
        const result = validate.validationCheck(value, this.state[id], isType);
        const errMsg = validate.legthValidation(id, value);
        this.setState({
            [id] : result,
            errormessage : errMsg
        })
    }

    onBlurChange = (e) => {
        let errMsg = validate.legthValidation(e.target.id, e.target.value);
        if( errMsg && e.target.value){
            this.setState ({
                iserror: e.target.id,
                errormessage: errMsg
            })
        } else if(this.state.iserror){
            this.setState({iserror: ''});
        }
    }

    addVendor = () =>{
        let paramObj = {
            "company_id" : this.props.company_id,
            "branch_id" : this.props.branch_id,
            "name":this.state.name,
            "contact":this.state.contact,
            "no_of_people":this.state.no_of_people,
        }
        let errorMsg = "";
        const validationError = {
            "Name" : validate.isEmpty(paramObj.name),
        }
        const validationFields = [];
    	Object.keys(validationError).forEach((key) => {
    		if (validationError[key]) {
    			validationFields.push(key);
    		}
        });
    	if (validationFields.length) {
    		errorMsg = `Please Enter Valid ${validate.join(validationFields, (item, idx) => {
    			if (validationFields.length === 1) {
    				return "";
    			}
    			if (idx > 0 && idx < validationFields.length - 1) {
    				return ", ";
    			} else if (idx === validationFields.length - 1) {
    				return " and ";
    			}
    			return "";
    		})}.`;
        }
        let myDiv = document.getElementById('modal');
        if (errorMsg) {
            myDiv.scrollTop = 0;
    		this.setState({
    			error : errorMsg
    		});
    		return;
    	}
        this.props.addVendor(paramObj);
        this.props.toggleAdd5();
    }

    render() {
        const buttonDisable = (this.state.name) ? 'add-button' : 'add-button btn-disabled';
        return (
            <div className = "add-user-wrap">
                <div onClick = {this.props.toggleAdd5} className = "overlay"></div>
                <div id = "modal" className = "modal-popup">
                    <div className = "pop-heading">
                        Add Vendor
                    </div>
                    <div className = "content">
                    <label className = "error">{this.state.error}</label>
                        <form className = "form-section" autoComplete = "nope">
                            <InputText placeholder = "Name" id = {"name"} label = "Name" value = {this.state.name} changeHandler = {this.onChangeHandler}/>
                            <InputText placeholder = "Contact" id = {"contact"} label = "Contact" value = {this.state.contact} changeHandler = {this.onChangeHandler}/>
                            <InputText placeholder = "No of people" id = {"no_of_people"} label = "No of people" value = {this.state.no_of_people} changeHandler = {this.onChangeHandler}/>
                        </form>
                        <div className = "submit-section">
                            <button className = "add-button discard" onClick = {this.props.toggleAdd5}>Cancel</button>
                            <button className = "add-button" onClick = {this.addVendor}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddVendor;