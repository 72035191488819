import React, { Component, Fragment } from "react";
import InputText from "../../common/components/input-text/input-text";
import Button from "../button-classic/button-classic";
import "./route_code.less";
import { connect } from "react-redux";
import ACTIONS from "../../common/action_constant";
import { TTBIL_COMPANY_IDS, AWL_COMPANY_IDS, BEAM_COMPANY_IDS, DEEPAK_COMPANY_ID } from "../../../utils/common";
import Delete from "../../../assets/images/delete.svg";
import Select from "react-select";
import { validationCheck } from "../../../utils/validate";

class RouteStagespopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      multiModeYard: [],
      multiModeStations: [],
      data: ["737"].includes(this.props.company_id)
        ? {
            plant_name: this.props.editData
              ? this.props.editData.plant_name
              : "",
            point_of_loading: this.props.editData
              ? this.props.editData.point_of_loading
              : "",
            point_of_destination: this.props.editData
              ? this.props.editData.point_of_destination
              : "",
            destination: this.props.editData
              ? this.props.editData.destination
              : "",
            route_code: this.props.editData
              ? this.props.editData.route_code
              : "",
            route_code_type: this.props.editData
              ? this.props.editData.route_code_type
              : "",
            company_id: this.props.company_id,
          }
        : {
          routestages: this.props.editData?this.props.editData.routestages:[],
          route_code: this.props.editData
              ? this.props.editData.route_code
              : "",
            from: this.props.editData ? this.props.editData.source : "",
            to: this.props.editData ? this.props.editData.destination : "",
            sap_code: this.props.editData ? this.props.editData.sap_code : "",
            distance: this.props.editData ? this.props.editData.distance : null,
            ...([...BEAM_COMPANY_IDS, ...AWL_COMPANY_IDS].includes(this.props.company_id) && (this.props.modeType == 2) ? {
              from_yard: this.props.editData ? this.props.editData.origin_yard : null,
              to_yard: this.props.editData ? this.props.editData.destination_yard : null,
              from_station_code: this.props.editData ? this.props.editData.source_station_code : null,
              to_station_code: this.props.editData ? this.props.editData.dest_station_code : null,
              from_point: this.props.editData ? this.props.editData.departure_point : null,
              loading_point: this.props.editData ? this.props.editData.loading_point : null,
              drop_point: this.props.editData ? this.props.editData.destination_point : null,
              plant_to_yard_distance: this.props.editData ? this.props.editData.plant_to_yard_distance : null,
              yard_to_yard_distance: this.props.editData ? this.props.editData.yard_to_yard_distance : null,
              yard_to_depot_distance: this.props.editData ? this.props.editData.yard_to_depot_distance : null,
              transportation_type: this.props.editData ? this.props.editData.movement_type : null,
            } : {}),
            distance_uom: this.props.editData
              ? this.props.editData.distance_uom
              : "KM",
            company_id: this.props.company_id,
            is_distance_from_google: false,
            destination_list: this.props.editData
              ? this.props.editData.destination_list
              : [],
            tat: this.props.editData ? this.props.editData.tat : "",
            tat_uom: this.props.editData ? this.props.editData.tat_uom : "",
            from_district: this.props.editData
              ? this.props.editData.source_district
              : "",
            from_state: this.props.editData
              ? this.props.editData.source_state
              : "",
            to_state: this.props.editData
              ? (this.props.modeType == 2 ? this.props.editData.state : this.props.editData.destination_state)
              : "",
            to_district: this.props.editData
              ? this.props.editData.destination_district
              : "",
          },
    };
  }

  componentDidMount() {
    const { editData, company_id, modeType } = this.props;
    const { dispatch } = this.props;
    const isAdaniWilmar = ['999', '872'].includes(company_id);
    dispatch({
      type: ACTIONS.HOME.GET_DISTRICT,
      data: this.props.editData.source_state_code,
    });
    dispatch({
      type: ACTIONS.HOME.GET_TO_DISTRICT,
      data: this.props.editData.destination_state_code,
    });
    if(isAdaniWilmar && modeType==2){
      dispatch({
        type: ACTIONS.HOME.GET_ROUTE_CODE_DROPDOWN,
        param: {
          companyId: company_id,
          companyType: 1,
          limit: 100,
          offset: 0,
          type: 46
        },
        onSuccess: (data)=>{
          this.setState({
            multiModeYard: (data || []).map((op) => { return { label: op.branch_name, value: op.branch_name } }),
            multiModeStations: (data || []).map((op) => { return { label: op.branch_code, value: op.branch_code } })
          })
        }
      })
    }
  }
  onDeletePointofContactCompany = (index) => {
    let { data } = this.state;
    delete data.routestages[index];
    this.setState({ data });
}

  render() {
    const { modeType, company_id } = this.props;
    const isAdaniWilmar = ['999', '872'].includes(company_id);
    const { data} = this.state;
    return (
      <div className="route_code_detail">
        <div onClick={this.props.toggleAdd} className="overlay"></div>
        <div id="modal" className="modal-popup">
          <div className="pop-heading">
          <div className={'heading-section'}>Route Stages</div>
          <div className={"button_route mt--30"}>
              <Button value={"X"} click={this.props.toggleRoutestagepopup} />
            </div>
          </div>

          <div className="route-detail">
          <div className={'sub-heading-section'}>Route Code:{data.sap_code}</div>
           
            {(
              <div className="details-section">
               
                {isAdaniWilmar && (modeType == 2) &&
                <div className="route-stages-section point-of-contact">
                    {(!!data.routestages && data.routestages.length>0)?
                        (<table className="routestages-table">
                            <thead>
                            <th>Itinerary</th>
                            <th>Stage Category</th>
                            <th>Departure Point</th>
                            <th>Stage Number</th>
                            <th>Departure Point Desc</th>
                            <th>Destination Point</th>
                            <th>Destination Point Desc</th>
                            <th>Shipment Type</th>
                            <th>Distance</th>
                            <th>Unit</th>
                            <th>Leg Indicator</th>
                            </thead>
                            <tbody>
                            {data.routestages && data.routestages .map((val, index) => {
                                return(<tr key={index}>
                                    <td>{val && val.itinerary}</td>
                                    <td>{val && val.stageCat}</td>
                                    <td>{val && val.departurePoint}</td>
                                    <td>{val && val.stageNum}</td>
                                    <td>{val && val.departurePtDesc}</td>
                                    <td>{val && val.destinationPoint}</td>
                                    <td>{val && val.destinationPtDesc}</td>
                                    <td>{val && val.shipType}</td>
                                    <td>{val && val.distance}</td>
                                    <td>{val && val.unit}</td>
                                    <td>{val && val.legIndicator}</td>
                                </tr>)})
                            }
                            </tbody>
                        </table>)
                        :
                        (<div className="no-routestage-msg">No Route Stages Present for Route Code {data.sap_code}</div>)
                    }
                </div>
                }
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(RouteStagespopup);
