import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import './sapVendorForm.less';
import Loader from "../../common/components/loader/loader";
import history from "../../history";
import ACTION from "../../common/action_constant";
import ACTIONS from '../../common/action_constant';
import MSGPopUp from "../../common/components/msg-popup/msg-popup";
import validate from "../../../utils/validate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { DEEPAK_COMPANY_ID, ACTION_REQUIRED_OPTIONS, CARGILL_COMPANY_IDS } from '../../../utils/common';
import Select from "react-select";
import TopPanel from '../../components/header-panel/header-panel';
import cancelBtn from '../../../assets/images/cancel.svg'
import addBtn from '../../../assets/images/add.svg'
import { SingleDatePicker } from 'react-dates';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';

const TURNOVER_DETAILS = [
   { turnoverValue: '', turnover_uom: '', turnover_year: (new Date().getFullYear()) - 1 },
   { turnoverValue: '', turnover_uom: '', turnover_year: (new Date().getFullYear()) - 2 },
   { turnoverValue: '', turnover_uom: '', turnover_year: (new Date().getFullYear()) - 3 },
];

const PAYMENT_METHOD = [
   { label: 'B - BANK DRAFT', value: 'B - BANK DRAFT' },
   { label: 'C - CORPORATE CHECK', value: 'C - CORPORATE CHECK' },
   { label: 'E - NEFT', value: 'E - NEFT' },
   { label: 'W - WIRE TRANSFER', value: 'W - WIRE TRANSFER' },
]

const ACCOUNT_MEMO = [
   { label: '101 - MICRO SUPPLIER', value: '101 - MICRO SUPPLIER' },
   { label: '102 - SMALL SUPPLIER', value: '102 - SMALL SUPPLIER' },
   { label: '103 - MEDIUM SUPPLIER', value: '103 - MEDIUM SUPPLIER' },
   { label: '201 - MICRO ENTERPRISE', value: '201 - MICRO ENTERPRISE' },
   { label: '202 - SMALL ENTERPRISE', value: '202 - SMALL ENTERPRISE' },
   { label: '203 - MEDIUM ENTERPRISE', value: '203 - MEDIUM ENTERPRISE' },
   { label: '301 - OTHERS', value: '301 - OTHERS' },
]

const CASHMANAGEMENT = [
   { label: 'A1 - A/P DOMESTIC', value: 'A1 - A/P DOMESTIC' },
   { label: 'A3 - A/P INTERCOMPANY', value: 'A3 - A/P INTERCOMPANY' },
   { label: 'A2 - A/P FOREIGN', value: 'A2 - A/P FOREIGN' },
]

const VENDOR_ACCT_OPTIONS = [
   { label: 'ZV01 VENDOR 22000100', value: 'ZV01 VENDOR 22000100' },
   { label: 'ZV03 INTER CO 22210010', value: 'ZV03 INTER CO 22210010' },
   { label: 'ZV05 EMPLOYEES 21400010 ', value: 'ZV05 EMPLOYEES 21400010 ' },
   { label: 'ZV07 AGENT 22000100', value: 'ZV07 AGENT 22000100' },
   { label: 'ZV04 FRT VENDOR 22000100', value: 'ZV04 FRT VENDOR 22000100' },
]

const WTHTTYPE = [
   { label: 'I1 INV-CONTRACTORS OTH- SEC.194C-2%', value: 'I1 INV-CONTRACTORS OTH- SEC.194C-2%' },
   { label: 'I2 INV-CONTRACTORS INDUV&HUF- SEC194C-1%', value: 'I2 INV-CONTRACTORS INDUV&HUF- SEC194C-1%' },
   { label: 'II INV-TRANSPORT SERVICE W/ PAN 0%', value: 'II INV-TRANSPORT SERVICE W/ PAN 0%' },
]

const CustomeCheckBox = (props) => {
   return (
      <div className={props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick={() => props.toggle(props.id)}>
         {props.selected && <div className="inner-circle"></div>}
      </div>
   )
}

class MyDatePicker extends Component {
   render() {
      return (
         <div className="data-picker">
            <div className="label grey">{this.props.label}</div>
            <DatePicker
               dateFormat="dd/MM/yyyy"
               selected={this.props.startDate}
               onChange={this.props.handleChange.bind(this, this.props.id)}
            />
         </div>
      );
   }
}

class SapVendorForm extends Component {
   printRef = React.createRef();
   constructor(props) {
      super(props);
      this.state = {
         isPage: 1,
         otp_number: '',
         limit: 10,
         offset: 0,
         errorMsg: '',
         action_required_options: ACTION_REQUIRED_OPTIONS,
         data: {
            company_id: this.props.match.params.companyId,
            ob_vendor_id: this.props.match.params.vendorId,
            company_logo: '',
            scopes: [],
            type: [],
            company_contact_no: "",
            services_offered: [],
            contact_no: "",
            aadhar_card_no: "",
            pan_no: '',
            gst_no: '',
            gstDetails: [],
            currency: 'INR',
            email: '',
            created_by: '',
            additional_email: [],
            company_type: '',
            company_website: '',
            registration_year: '',
            turnover_details: TURNOVER_DETAILS,
            turnover: '',
            bank_name: '',
            branch_name: '',
            account_number: '',
            account_name: '',
            userList: [{
               admin_name: '',
               admin_designation: '',
               admin_email: '',
               admin_contact: '',
               admin_location: ''
            }],
            customerList: [{
               customer_name: '',
               location: '',
               service_provided: '',
               product_handled: '',
               email: ''
            }],
            regionalOfficeList: [{
               regional_office_address: '',
               regional_office_city: '',
               regional_office_number: '',
               regional_office_pincode: '',
               regional_office_state: '',
               ...(CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? { countryName: '' } : {}),
            }],
            branchOfficeList: [{
               branch_office_address: '',
               branch_office_city: '',
               branch_office_number: '',
               branch_office_pincode: '',
               branch_office_state: ''
            }],
            WH_Tax_Accounting: [{
               taxWHType: '',
               taxLiable: '',
               taxRec: ''
            }]
         },
      }
   }

   componentDidMount() {
      const cmpApprval = JSON.parse(localStorage.getItem('companyApproval'));
      if (cmpApprval && Object.keys(cmpApprval || {}).length > 0) {
         this.setState({ approvalObj: { ...cmpApprval } })
      }
      this.getPaymentTerms();
      this.getAdminNamesOptions()
      this.getCurrencyOptionsData()
      this.getCountryList()
      this.getBasicVendorDetails();
   }

   componentWillReceiveProps(nextProps) {

      if (nextProps.vendorForm.vendorDefaultData !== this.props.vendorForm.vendorDefaultData) {

         if (Object.keys(nextProps.vendorForm.vendorDefaultData).length > 0) {
            const defaultData = nextProps.vendorForm.vendorDefaultData || {};
            let { data, year } = this.state;
            data['vendor_company_name'] = defaultData.vendor_company_name;
            data['contact_person_name'] = defaultData.contact_person_name;
            data['email'] = defaultData.email;
            data['created_by'] = defaultData.created_by;
            data['scopes'] = defaultData.scopes || [];
            data['additional_email'] = defaultData.additional_email || [];
            data.contact_no = defaultData.contact_no ? (typeof (defaultData.contact_no) === "string" ? defaultData.contact_no : defaultData.contact_no.phone_no) : ""
            data.company_contact_no = defaultData.company_contact_no ? defaultData.company_contact_no : data.company_contact_no
            data.company_logo = defaultData.company_logo;
            data.type = defaultData.type || [];
            data.services_offered = defaultData.services_offered || [];
            data.pan_no = defaultData.pan_no || '';
            data.aadhar_card_no = defaultData.aadhar_card_no || '';
            data.gst_no = defaultData.gst_no || '';
            data.email = defaultData.email || '';
            data.created_by = defaultData.created_by || '';
            data.gstDetails = defaultData.gstin_details || [];
            data.company_type = defaultData.company_type || '';
            data.company_website = defaultData.company_website || '';
            data.registration_year = defaultData.registration_year || '';
            data.turnover = defaultData.turnover || '';
            data.turnover_details = defaultData.turnover_details || TURNOVER_DETAILS;
            data.bank_name = defaultData.bank_name || '';
            data.branch_name = defaultData.branch_name || '';
            data.account_name = defaultData.account_name || '';
            data.account_number = defaultData.account_number || '';
            data.IFSC_code = defaultData.IFSC_code || '';
            data.branchOfficeList = (defaultData.branchOfficeList && defaultData.branchOfficeList.length > 0) ? defaultData.branchOfficeList : data.branchOfficeList;
            data.regionalOfficeList = (defaultData.regionalOfficeList && defaultData.regionalOfficeList.length > 0) ? defaultData.regionalOfficeList : data.regionalOfficeList;
            data.userList = (defaultData.userList && defaultData.userList.length > 0) ? defaultData.userList : data.userList;
            data.customerList = (defaultData.customerList && defaultData.customerList.length > 0) ? defaultData.customerList : data.customerList;
            data.save_page_1 = defaultData.save_page_1 || null;
            data.vendor_type = defaultData.vendor_type || [];


            data.dateRequested = defaultData.dateRequested || moment(new Date()).valueOf();
            data.abformUpdateDate = defaultData.abformUpdateDate || moment(new Date()).valueOf();
            data.requester = defaultData.requester || 'Abhilekh Suryavanshi';
            data.verifier = defaultData.verifier || 'Sushmitha P';
            data.approver = defaultData.approver || 'Sushmitha P';
            data.actionRequired = defaultData.actionRequired || '';
            data.addressBookType = defaultData.addressBookType || '';
            data.companyCode = defaultData.companyCode || '1141';
            data.purchaseOrganisation = defaultData.purchaseOrganisation || 'IN13 TRANSP & LOGISTICS';
            data.vendorAcctGroup = defaultData.vendorAcctGroup || 'ZV04 FRT VENDOR 22000100';
            data.vendorType = defaultData.vendorType || '';
            data.withholdTax = defaultData.withholdTax || '';
            data.bankDetailsStatus = defaultData.bankDetailsStatus || 'YES';
            data.searchTerm = defaultData.searchTerm || '';
            data.stdCommunicationMethod = defaultData.stdCommunicationMethod || '';
            data.taxRetunStatusFor2years = defaultData.taxRetunStatusFor2years || '';
            data.panAadharLinkage = defaultData.panAadharLinkage || '';
            data.supplierPerson = defaultData.supplierPerson || '';
            data.bankcountryName = defaultData.bankcountryName || '';
            data.IBANNumber = defaultData.IBANNumber || '';
            data.swiftCode = defaultData.swiftCode || '';
            data.WH_Tax_Accounting = (defaultData.WH_Tax_Accounting && defaultData.WH_Tax_Accounting.length > 0) ? defaultData.WH_Tax_Accounting : data.WH_Tax_Accounting;
            data.accountingSortKey = defaultData.accountingSortKey || '';
            data.cashMgmtGroup = defaultData.cashMgmtGroup || '';
            data.doubleInvCheck = defaultData.doubleInvCheck || '';
            data.paymentTerms = defaultData.paymentTerms || '';
            data.paymentMethod = defaultData.paymentMethod || '';
            data.clrkInternet = defaultData.clrkInternet ? defaultData.clrkInternet : defaultData.email;
            data.accountMemo = defaultData.accountMemo || '';
            data.taxWHCountry = defaultData.taxWHCountry || '';
            data.groupVendorSchema = defaultData.groupVendorSchema || '';
            data.verifyGRBased = defaultData.verifyGRBased || '';
            data.verifySrvBased = defaultData.verifySrvBased || '';
            data.currencyOrder = defaultData.currencyOrder || 'INR';
            data.incoTerm1 = defaultData.incoTerm1 || 'DAP - DELIVERED AT PLACE';
            data.incoTerm2 = defaultData.incoTerm2 || 'EX - EX WORKS';
            data.save_SAP_form = defaultData.save_SAP_form || false;
         }

      }
   }

   componentWillUnmount() {
      // localStorage.setItem('companyApproval', null);
   }

   getPaymentTerms = () => {
      const { dispatch, match } = this.props;
      dispatch({
         type: ACTION.VENDORS.GET_PAYMENT_TERMS,
         params: `?companyId=${match.params.companyId}`,
         onSuccess: ((data) => {
            let temp = []
            if (data.data && data.data.length > 0) {
               data.data.map((d) => {
                  temp.push({ label: d.name, value: d.id })
               })
            }
            this.setState({ payment_terms_options: temp })
         })
      })
   }

   getAdminNamesOptions = () => {
      const { dispatch, match } = this.props;
      dispatch({
         type: ACTION.VENDORS.GET_ADMIN_NAMES,
         params: `?companyId=${match.params.companyId}`,
         onSuccess: ((data) => {
            let temp = []
            if (data.data && data.data.list && data.data.list.length > 0) {
               data.data.list.map((d) => {
                  temp.push({ label: d, value: d })
               })
            }
            temp.push({ label: 'Sushmitha P', value: 'Sushmitha P' })
            this.setState({ admin_names_options: temp })
         })
      })
   }

   getCurrencyOptionsData = () => {
      const { dispatch, match } = this.props;
      dispatch({
         type: ACTION.VENDORS.GET_CURRENCY_OPTIONS,
         params: `?companyId=${match.params.companyId}`,
         onSuccess: ((data) => {
            let temp = []
            if (data) {
               Object.keys(data).forEach((key) => {
                  temp.push({
                     label: key,
                     value: data[key]
                  })
               })
               this.setState({ currency_options: temp })
            }
         })
      })
   }

   getCountryList = () => {
      const { dispatch, match } = this.props;
      dispatch({
         type: ACTION.VENDORS.GET_COUNTRY_LIST,
         params: `?companyId=${match.params.companyId}`,
         onSuccess: ((data) => {
            let temp = []
            if (data.data && data.data.length > 0) {
               data.data.map((d) => {
                  temp.push({ label: d.name, value: d.name, iso_code: d.iso_code, code: d.code })
               })
            }
            this.setState({ country_options: temp })
         })
      })
   }

   getBankDetailsFromIFSCCode = () => {
      const { data } = this.state;
      const { dispatch } = this.props;
      dispatch({
         type: ACTION.VENDORS.GET_BANK_DETAILS_FROM_IFSC,
         params: data.IFSC_code,
         onSuccess: ((bankDetails) => {
            const { data } = this.state;
            if (bankDetails) {
               data.bank_name = bankDetails.BANK
               data.branch_name = bankDetails.BRANCH
            }
            this.setState({ data })
         })
      })
   }

   getStateAndCityFromPin = (pin, value) => {
      const { data } = this.state;
      this.props.dispatch({
         type: ACTIONS.VENDORS.GET_STATE_AND_CITY_FROM_PIN_CODE,
         data: pin,
         onSuccess: (pincodeData) => {
            if (pincodeData && pincodeData[0].Status === 'Success') {
               if (value === 'regional') {
                  data.regionalOfficeList[0].regional_office_state = pincodeData[0].PostOffice[0].State
                  data.regionalOfficeList[0].regional_office_city = pincodeData[0].PostOffice[0].District
               }
               this.setState(data)
            }
         }
      })
   }


   getAllVendorModes = (id) => {
      const { dispatch } = this.props;
      dispatch({
         type: ACTION.VENDORS.GET_FORM_VENDOR_MODES,
         params: `?mode_type=${id || 3}`
      })
   }

   validateMandatory = () => {
      let { data, errorMsg, isValid } = this.state;
      const validationError = {
         "Company Type": validate.isEmpty(data.company_type),
         "Additional Email": this.state.additional_email_error ? true : false,
         "Registration Year": validate.isEmpty(data.registration_year),
         "PAN No.": validate.isEmpty(data.pan_no) || this.state.panError,
         "Payment Currency": validate.isEmpty(data.currency),
         "Turn Over Year": data.turnover_details.find((detail) => !detail.turnover_year),
         "Turn Over Value": data.turnover_details.find((detail) => !detail.turnoverValue),
         "Admin Details": (validate.isEmpty(data.userList[0].admin_name) || validate.isEmpty(data.userList[0].admin_location) || validate.isEmpty(data.userList[0].admin_email) || validate.isEmpty(data.userList[0].admin_designation) || validate.isEmpty(data.userList[0].admin_contact) || validate.isEmpty(data.userList[0].admin_department)),
         "Customer Details": (validate.isEmpty(data.customerList[0].customer_name) || validate.isEmpty(data.customerList[0].location) || validate.isEmpty(data.customerList[0].city) || validate.isEmpty(data.customerList[0].state) || validate.isEmpty(data.customerList[0].pin_code) || validate.isEmpty(data.customerList[0].email) || validate.isEmpty(data.customerList[0].product_handled) || validate.isEmpty(data.customerList[0].service_provided)),
         "User Email": validate.isEmpty(data.userList[0].admin_email),
         "Contact Number": validate.isEmpty(data.contact_no),
         "Aadhar Card No": validate.isEmpty(data.aadhar_card_no),

         "Date": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? !data.dateRequested : false,
         "AB Form Last Updated": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? !data.abformUpdateDate : false,
         "Name of the Requestor": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.requester) : false,
         "Name of the Verifier": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.verifier) : false,
         "Name of the Approver": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.approver) : false,

         "Action Required": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.actionRequired) : false,
         "Type of Address Book": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.addressBookType) : false,
         "Company Code": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.companyCode) : false,
         "Purchase Organization": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.purchaseOrganisation) : false,
         "Vendor Acct. Group": validate.isEmpty(data.vendorAcctGroup),
         "Bank Details": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.bankDetailsStatus) : false,
         "Company Name": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.vendor_company_name) : false,
         "PAN & Aadhar Linkage ": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.panAadharLinkage) : false,
         "Supplier is specified Person": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.supplierPerson) : false,
         "Bank Country": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.bankcountryName) : false,
         "Sort Key": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.accountingSortKey) : false,
         "Cash Management Group": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.cashMgmtGroup) : false,
         "Payment Terms": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.paymentTerms) : false,
         "Check Double Inv.": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.doubleInvCheck) : false,
         "Payment Method": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.paymentMethod) : false,
         "GR - Based Vendor Inv.Verify": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.verifyGRBased) : false,
         "Srv. Based Inv Verify": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.verifySrvBased) : false,
         "IFSC Code": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.IFSC_code) : false,
         "Bank Name": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.bank_name) : false,
         "Bank Account No": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.account_number) : false,
         "Gst Details": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? ((data.gstDetails && data.gstDetails.length > 0) ? false : true) : false,
         "Gst No": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.gst_no) : false,
         "Currency Order": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? validate.isEmpty(data.currencyOrder) : false,

         "Address": !DEEPAK_COMPANY_ID.includes(this.props.match.params.companyId) ? (data.regionalOfficeList.find((regional) => !regional.regional_office_address) ? true : false) : false,
         "City": !DEEPAK_COMPANY_ID.includes(this.props.match.params.companyId) ? (data.regionalOfficeList.find((regional) => !regional.regional_office_city) ? true : false) : false,
         "Postal Code": !DEEPAK_COMPANY_ID.includes(this.props.match.params.companyId) ? (data.regionalOfficeList.find((regional) => !regional.regional_office_pincode) ? true : false) : false,
         "State": !DEEPAK_COMPANY_ID.includes(this.props.match.params.companyId) ? (data.regionalOfficeList.find((regional) => !regional.regional_office_state) ? true : false) : false,
         "Country": CARGILL_COMPANY_IDS.includes(this.props.match.params.companyId) ? (data.regionalOfficeList.find((regional) => !regional.countryName) ? true : false) : false,

         'Incoterms 1': validate.isEmpty(data.incoTerm1),
         'Incoterms 2': validate.isEmpty(data.incoTerm2),
         // "User Email": data.userList.find((user) => user.error) ? true : false,
      }

      const validationFields = [];
      Object.keys(validationError).forEach((key) => {
         if (validationError[key]) {
            validationFields.push(key);
         }
      });

      if (validationFields.length) {
         errorMsg = `Please Enter Valid ${validate.join(validationFields, (item, idx) => {
            if (validationFields.length === 1) {
               return "";
            }
            if (idx > 0 && idx < validationFields.length - 1) {
               return ", ";
            } else if (idx === validationFields.length - 1) {
               return " and ";
            }
            return "";
         })}.`;
         isValid = true;
      } else {
         errorMsg = '';
         isValid = false;
      }
      this.setState({ errorMsg });
      return isValid;
   }

   savePrimaryDetails = () => {
      const { data } = this.state;
      const { dispatch, match } = this.props;

      if (!this.validateMandatory()) {
         dispatch({
            type: ACTION.VENDORS.SAVE_SAP_VENDOR_FORM,
            data: data,
            onSuccess: (() => {
               this.getBasicVendorDetails();
            })
         });
      }
   }

   getBasicVendorDetails = () => {
      const { dispatch } = this.props;
      dispatch({
         type: ACTION.VENDORS.GET_VENDOR_DETAILS,
         params: `?vendorId=${this.props.match.params.vendorId}`,
         onSuccess: ((data) => {
            let type_of_vehicle = data && data[1][0].type_of_vehicle
            let vehicle_type_options = []
            type_of_vehicle.map((vehicle) => {
               vehicle_type_options.push({
                  label: vehicle,
                  value: vehicle
               })
            })
            this.setState({ vehicle_type_options: vehicle_type_options })
         })
      });
   }

   closeMsgPopup = () => {
      const { dispatch } = this.props;
      dispatch({ type: ACTION.MSG_DISPLAY.HIDE_MSG })
   }

   addWHfield = () => {
      let { data } = this.state;
      const wHdata = {
         taxWHType: '',
         taxLiable: '',
         taxRec: ''
      }
      data.WH_Tax_Accounting.push(wHdata);
      this.setState({ data })
   }

   removeWHfield = (index) => {
      const { data } = this.state;
      data.WH_Tax_Accounting.splice(index, 1);
      this.setState({
         data
      })
   }

   handleDownloadPdf = async () => {
      const element = this.printRef.current;
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL('image/png');

      const pdf = new jsPDF();
      const imgProperties = pdf.getImageProperties(data);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight =
         ((imgProperties.height * pdfWidth) / imgProperties.width) - 30;

      pdf.addImage(data, 'PNG', 1, 1, pdfWidth, pdfHeight);
      pdf.save('print.pdf');
   };

   render() {
      const { data, admin_names_options, country_options, currency_options } = this.state;
      return (
         <div className='main-container' ref={this.printRef} >
            <div className="boarding-form">
               <div className="header">

                  <div>
                     <TopPanel newVendor />
                  </div>
               </div>

               <div className='heading'>
                  <div className="header-title">Sap Vendor Form</div>
               </div>

               {this.props.loader.isLoading && <Loader />}
               {this.props.msgpopup.active && <MSGPopUp close={this.closeMsgPopup} type={this.props.msgpopup.type} msg={this.props.msgpopup.msg} />}

               <div className="form-page-1">
                  <div className='first-table'>
                     <div className="Table-header">
                        <div className="table-heading"></div>
                        <div className='first-table-body'>
                           <div className="black mb-20 wt-30p singleDatePicker">
                              <div className="black">Date<span className='error'>*</span></div>
                              <SingleDatePicker
                                 noBorder={true}
                                 displayFormat={"DD/MM/YYYY"}
                                 hideKeyboardShortcutsPanel={true}
                                 placeholder={"Date"}
                                 numberOfMonths={1}
                                 date={data.dateRequested && moment(data.dateRequested) || null}
                                 onDateChange={(from) => {
                                    const { data } = this.state;
                                    data.dateRequested = moment(from).valueOf() || null;
                                    this.setState({ data });
                                 }}
                                 focused={this.state.focusedStart}
                                 onFocusChange={({ focused }) => this.setState({ focusedStart: focused })}
                              />
                           </div>

                           <div className="black mb-20 wt-30p singleDatePicker">
                              <div className="black">AB Form Last Updated<span className='error'>*</span></div>
                              <SingleDatePicker
                                 noBorder={true}
                                 displayFormat={"DD/MM/YYYY"}
                                 hideKeyboardShortcutsPanel={true}
                                 placeholder={"Date"}
                                 numberOfMonths={1}
                                 date={data.abformUpdateDate && moment(data.abformUpdateDate) || null}
                                 onDateChange={(from) => {
                                    const { data } = this.state;
                                    data.abformUpdateDate = moment(from).valueOf() || null;
                                    this.setState({ data });
                                 }}
                                 focused={this.state.focused}
                                 onFocusChange={({ focused }) => this.setState({ focused: focused })}
                              />
                           </div>

                           <div className="dInBlock wt-30p">
                              <div className="black">Country<span className='error'>*</span></div>
                              <Select
                                 value={(country_options || []).filter(obj => { return 'India' === obj.label })}
                                 options={country_options || []}
                                 // onChange={(value) => {
                                 //    const { data } = this.state;
                                 //    data.accountMemo = value.label;
                                 //    this.setState({ data });
                                 // }}
                                 placeholder={'Country'}
                                 className='new-vendor-select'
                                 style={{ height: '50px' }}
                              />
                           </div>

                           <div className="dInBlock wt-30p">
                              <div className="black">Name of the requestor<span className='error'>*</span></div>
                              <Select
                                 value={(admin_names_options || []).filter(obj => { return data.requester === obj.label })}
                                 options={admin_names_options || []}
                                 onChange={(value) => {
                                    const { data } = this.state;
                                    data.requester = value.label;
                                    this.setState({ data });
                                 }}
                                 placeholder={'Name of the requestor'}
                                 className='new-vendor-select'
                                 style={{ height: '50px' }}
                              />
                           </div>

                           <div className="dInBlock wt-30p">
                              <div className="black">Name of the verifier<span className='error'>*</span></div>
                              <Select
                                 value={(admin_names_options || []).filter(obj => { return data.verifier === obj.label })}
                                 options={admin_names_options || []}
                                 onChange={(value) => {
                                    const { data } = this.state;
                                    data.verifier = value.label;
                                    this.setState({ data });
                                 }}
                                 placeholder={'Name of the verifier'}
                                 className='new-vendor-select'
                                 style={{ height: '50px' }}
                              />
                           </div>

                           <div className="dInBlock wt-30p">
                              <div className="black">Name of the Approver<span className='error'>*</span></div>
                              <Select
                                 value={(admin_names_options || []).filter(obj => { return data.approver === obj.label })}
                                 options={admin_names_options || []}
                                 onChange={(value) => {
                                    const { data } = this.state;
                                    data.approver = value.label;
                                    this.setState({ data });
                                 }}
                                 placeholder={'Name of the Approver'}
                                 className='new-vendor-select'
                                 style={{ height: '50px' }}
                              />
                           </div>

                        </div>
                     </div>
                  </div>

                  <div className='first-table'>
                     <div className="Table-header">
                        <div className="table-heading"></div>
                        <div className='first-table-body'>
                           <div className="dInBlock wt-30p">
                              <div className='dropdown'>
                                 <div className="black">Action Required<span className='error'>*</span></div>
                                 <Select
                                    value={this.state.action_required_options.filter((obj) => data.actionRequired === obj.label)}
                                    options={this.state.action_required_options}
                                    onChange={(value) => {
                                       const { data } = this.state;
                                       data.actionRequired = value.label;
                                       this.setState({ data });
                                    }}
                                    placeholder={"Action Required"}
                                    className="new-vendor-select"
                                 />
                              </div>
                           </div>
                           <div className="dInBlock wt-30p">
                              <div className='dropdown'>
                                 <div className="black">Type of Address Book<span className='error'>*</span></div>
                                 <Select
                                    value={[{ label: 'V_VENDOR', value: 'V_VENDOR' }, { label: 'E_EMPLOYEE', value: 'E_EMPLOYEE' }].filter((obj) => data.addressBookType === obj.label)}
                                    options={[{ label: 'V_VENDOR', value: 'V_VENDOR' }, { label: 'E_EMPLOYEE', value: 'E_EMPLOYEE' }]}
                                    onChange={(value) => {
                                       const { data } = this.state;
                                       data.addressBookType = value.label;
                                       this.setState({ data });
                                    }}
                                    placeholder={'Type of Address Book'}
                                    className="new-vendor-select"
                                 />
                              </div>
                           </div>
                           <div className="black mb-20 wt-30p">
                              <div className="black">Company Code<span className='error'>*</span></div>
                              {<input
                                 type="text"
                                 placeholder="company code"
                                 className="new-vendor-input"
                                 value={data.companyCode}
                                 onChange={(e) => {
                                    const { data } = this.state;
                                    data.companyCode = e.target.value;
                                    this.setState({ data });
                                 }}
                              />}
                           </div>
                           {/* <div className="dInBlock mb-20 wt-30p">
                              <div className='dropdown'>
                                 <div className="black">Purchase Organization<span className='error'>*</span></div>
                                 <Select
                                    value={PURCHASE_ORGANIZATION.filter((obj) => data.purchaseOrganisation === obj.label)}
                                    options={PURCHASE_ORGANIZATION || []}
                                    onChange={(value) => {
                                       const { data } = this.state;
                                       data.purchaseOrganisation = value.label;
                                       this.setState({ data });
                                    }}
                                    placeholder={'Purchase Organization'}
                                    className="new-vendor-select"
                                 />
                              </div>
                           </div> */}
                           <div className="black mb-20 wt-30p">
                              <div className="black">Purchase Organization<span className='error'>*</span></div>
                              {<input
                                 type="text"
                                 placeholder="Purchase Organization"
                                 className="new-vendor-input"
                                 value={data.purchaseOrganisation}
                                 onChange={(e) => {
                                    const { data } = this.state;
                                    data.purchaseOrganisation = e.target.value;
                                    this.setState({ data });
                                 }}
                              />}
                           </div>
                           <div className="black mb-20 wt-30p">
                              <div className="black">Vendor Acct. Group<span className='error'>*</span></div>
                              {<input
                                 type="text"
                                 placeholder="Vendor Acct. Group"
                                 className="new-vendor-input"
                                 value={data.vendorAcctGroup}
                                 onChange={(e) => {
                                    const { data } = this.state;
                                    data.vendorAcctGroup = e.target.value;
                                    this.setState({ data });
                                 }}
                              />}
                           </div>
                           <div className="dInBlock wt-30p">
                              <div className='dropdown'>
                                 <div className="black">Vendor Type</div>
                                 <Select
                                    value={[{ label: 'LOCAL VENDOR', value: 'LOCAL VENDOR' }, { label: 'FOREIGN VENDOR', value: 'FOREIGN VENDOR' }].filter((obj) => data.vendorType === obj.label)}
                                    options={[{ label: 'LOCAL VENDOR', value: 'LOCAL VENDOR' }, { label: 'FOREIGN VENDOR', value: 'FOREIGN VENDOR' }]}
                                    onChange={(value) => {
                                       const { data } = this.state;
                                       data.vendorType = value.label;
                                       this.setState({ data });
                                    }}
                                    placeholder={'Vendor Type'}
                                    className="new-vendor-select"
                                 />
                              </div>
                           </div>
                           <div className="dInBlock wt-30p">
                              <div className='dropdown'>
                                 <div className="black">With/h Tax Accounting</div>
                                 <Select
                                    value={[{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }, { label: 'LOWER WITH HOLDING TAX', value: 'LOWER WITH HOLDING TAX' }].filter((obj) => data.withholdTax === obj.label)}
                                    options={[{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }, { label: 'LOWER WITH HOLDING TAX', value: 'LOWER WITH HOLDING TAX' }]}
                                    onChange={(value) => {
                                       const { data } = this.state;
                                       data.withholdTax = value.label;
                                       this.setState({ data });
                                    }}
                                    placeholder={'With/h Tax Accounting'}
                                    className="new-vendor-select"
                                 />
                              </div>
                           </div>

                           <div className="dInBlock wt-30p">
                              <div className="black">Bank Details<span className='error'>*</span></div>
                              <Select
                                 value={[{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }].filter(obj => { return data.bankDetailsStatus === obj.label })}
                                 options={[{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }]}
                                 onChange={(value) => {
                                    const { data } = this.state;
                                    data.bankDetailsStatus = value.label;
                                    this.setState({ data });
                                 }}
                                 placeholder={'Bank Details'}
                                 className='new-vendor-select'
                                 style={{ height: '50px' }}
                              />
                           </div>
                           <div className="dInBlock wt-30p">
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className='first-table'>
                     <div className="Table-header">
                        <div className="table-heading">Address</div>
                        <div className='first-table-body'>
                           <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                              <div className="black mb-20 wt-45p">
                                 <div className="black">Name<span className='error'>*</span></div>
                                 {<input
                                    type="text"
                                    placeholder="name"
                                    className="new-vendor-input"
                                    value={data.vendor_company_name}
                                    onChange={(e) => {
                                       const { data } = this.state;
                                       data.vendor_company_name = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '');
                                       this.setState({ data });
                                    }}
                                 />}
                              </div>
                              <div className="black mb-20 wt-45p">
                                 <div className="black">Search Terms</div>
                                 {<input
                                    type="text"
                                    placeholder="search terms"
                                    className="new-vendor-input"
                                    value={data.searchTerm}
                                    onChange={(e) => {
                                       const { data } = this.state;
                                       data.searchTerm = e.target.value;
                                       this.setState({ data });
                                    }}
                                 />}
                              </div>
                           </div>

                           <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                              <p>Street Address</p>
                              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: "10px" }}>
                                 {/* <div className="black mb-20 wt-30p">
                                    <div className="black">Building Code<span className='error'>*</span></div>
                                    {<input
                                       type="text"
                                       placeholder="building code"
                                       className="new-vendor-input"
                                    // value={data.contact_person_name}
                                    // onChange={(e) => {
                                    //    const { data } = this.state;
                                    //    data.vendor_company_name = e.target.value;
                                    //    this.setState({ data });
                                    // }}
                                    />}
                                 </div>
                                 <div className="black mb-20 wt-30p">
                                    <div className="black">Room<span className='error'>*</span></div>
                                    {<input
                                       type="text"
                                       placeholder="room"
                                       className="new-vendor-input"
                                    // value={data.email}
                                    // onChange={(e) => {
                                    //    const { data } = this.state;
                                    //    data.vendor_company_name = e.target.value;
                                    //    this.setState({ data });
                                    // }}
                                    />}
                                 </div>
                                 <div className="black mb-20 wt-30p">
                                    <div className="black">Floor<span className='error'>*</span></div>
                                    {<input
                                       type="text"
                                       placeholder="floor"
                                       className="new-vendor-input"
                                    // value={data.email}
                                    // onChange={(e) => {
                                    //    const { data } = this.state;
                                    //    data.vendor_company_name = e.target.value;
                                    //    this.setState({ data });
                                    // }}
                                    />}
                                 </div>
                                 <div className="black mb-20 wt-30p">
                                    <div className="black">Street<span className='error'>*</span></div>
                                    {<input
                                       type="text"
                                       placeholder="street"
                                       className="new-vendor-input"
                                    // value={data.email}
                                    // onChange={(e) => {
                                    //    const { data } = this.state;
                                    //    data.vendor_company_name = e.target.value;
                                    //    this.setState({ data });
                                    // }}
                                    />}
                                 </div>
                                 <div className="black mb-20 wt-30p">
                                    <div className="black">Street2<span className='error'>*</span></div>
                                    {<input
                                       type="text"
                                       placeholder="street2"
                                       className="new-vendor-input"
                                    // value={data.email}
                                    // onChange={(e) => {
                                    //    const { data } = this.state;
                                    //    data.vendor_company_name = e.target.value;
                                    //    this.setState({ data });
                                    // }}
                                    />}
                                 </div>
                                 <div className="black mb-20 wt-30p">
                                    <div className="black">District</div>
                                    {<input
                                       type="text"
                                       placeholder="district"
                                       className="new-vendor-input"
                                    // value={data.email}
                                    // onChange={(e) => {
                                    //    const { data } = this.state;
                                    //    data.vendor_company_name = e.target.value;
                                    //    this.setState({ data });
                                    // }}
                                    />}
                                 </div> */}
                                 <div className="black mb-20 wt-100p">
                                    <div className="black">Address</div>
                                    {<input
                                       type="text"
                                       placeholder="Address"
                                       className="new-vendor-input"
                                       value={data.regionalOfficeList[0].regional_office_address}
                                       onChange={(e) => {
                                          const { data } = this.state;
                                          data.regionalOfficeList[0].regional_office_address = e.target.value;
                                          this.setState({ data });
                                       }}
                                    />}
                                 </div>
                                 <div className="black mb-20 wt-30p">
                                    <div className="black">Postal Code<span className='error'>*</span></div>
                                    {<input
                                       type="text"
                                       placeholder="postal Code"
                                       className="new-vendor-input"
                                       value={data.regionalOfficeList[0].regional_office_pincode}
                                       onChange={(e) => {
                                          const { data } = this.state;
                                          if (e.target.value.length > 6) {
                                             return
                                          }
                                          else {
                                             if (e.target.value != 0) {
                                                const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                                const check = e.target.value.match(regEx);
                                                if (!check) {
                                                   return
                                                }
                                             }
                                             data.regionalOfficeList[0].regional_office_pincode = e.target.value;
                                             this.getStateAndCityFromPin(e.target.value, 'regional')
                                             this.setState({ data });
                                          }
                                       }}
                                    />}
                                 </div>
                                 <div className="black mb-20 wt-30p">
                                    <div className="black">City<span className='error'>*</span></div>
                                    {<input
                                       type="text"
                                       placeholder="city"
                                       className="new-vendor-input"
                                       value={data.regionalOfficeList[0].regional_office_city}
                                       onChange={(e) => {
                                          const { data } = this.state;
                                          data.regionalOfficeList[0].regional_office_city = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}0-9]/g, '');
                                          this.setState({ data });
                                       }}
                                    />}
                                 </div>
                                 <div className="black mb-20 wt-30p">
                                    <div className="black">Region<span className='error'>*</span></div>
                                    {<input
                                       type="text"
                                       placeholder="Region"
                                       className="new-vendor-input"
                                       value={data.regionalOfficeList[0].regional_office_state}
                                       onChange={(e) => {
                                          const { data } = this.state;
                                          data.regionalOfficeList[0].regional_office_state = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}0-9]/g, '');
                                          this.setState({ data });
                                       }}
                                    />}
                                 </div>
                                 <div className="dInBlock mb-20 wt-30p">
                                    <div className="black">Country<span className='error'>*</span></div>
                                    <Select
                                       value={(country_options || []).filter(obj => { return data.regionalOfficeList[0].countryName === obj.label })}
                                       options={country_options || []}
                                       onChange={(value) => {
                                          const { data } = this.state;
                                          data.regionalOfficeList[0].countryName = value.label;
                                          this.setState({ data });
                                       }}
                                       placeholder={'Country'}
                                       className='new-vendor-select'
                                       style={{ height: '50px' }}
                                    />
                                 </div>
                              </div>
                           </div>

                           <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                              <p>Communication</p>
                              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: "10px" }}>
                                 <div className="black mb-20 wt-30p">
                                    <div className="black">Telephone<span className='error'>*</span></div>
                                    {<input
                                       type="text"
                                       placeholder="telephone"
                                       className="new-vendor-input"
                                       value={data.contact_no}
                                       onChange={(e) => {
                                          const { data } = this.state;
                                          if (e.target.value.length <= 10) {
                                             if (e.target.value != 0) {
                                                const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                                const check = e.target.value.match(regEx);
                                                if (!check) {
                                                   return
                                                }
                                             }
                                             data.contact_no = e.target.value;
                                          }
                                          this.setState({ data });
                                       }}
                                    />}
                                 </div>
                                 <div className="black mb-20 wt-30p">
                                    <div className="black">Fax</div>
                                    {<input
                                       type="text"
                                       placeholder="fax"
                                       className="new-vendor-input"
                                       value={data.company_contact_no}
                                       onChange={(e) => {
                                          const { data } = this.state;
                                          if (e.target.value.length <= 10) {
                                             if (e.target.value != 0) {
                                                const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                                const check = e.target.value.match(regEx);
                                                if (!check) {
                                                   return
                                                }
                                             }
                                             data.company_contact_no = e.target.value;
                                          }
                                          this.setState({ data });
                                       }}
                                    />}
                                 </div>
                                 <div className="black mb-20 wt-30p">
                                    <div className="black">Email</div>
                                    {<input
                                       type="text"
                                       placeholder="email"
                                       className="new-vendor-input"
                                       value={data.email}
                                       onChange={(e) => {
                                          const { data } = this.state;
                                          let re = /\S+@\S+\.\S+/;
                                          let regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                                          if (re.test(e.target.value) || regex.test(e.target.value) || e.target.value.length === 0) {
                                             data.email = e.target.value;
                                             delete data.Emailerror
                                             this.setState({ data });
                                          } else {
                                             data.email = e.target.value;
                                             data.Emailerror = "Invalid Email"
                                             this.setState({ data });
                                          }
                                       }}
                                    />}
                                    {data.Emailerror && <p style={{ color: 'red', fontSize: '13px' }}>{data.Emailerror}</p>}
                                 </div>
                                 <div className="black mb-20 wt-30p">
                                    <div className="black">Standard Comm. Mtd</div>
                                    {<input
                                       type="text"
                                       placeholder="standard communication mtd"
                                       className="new-vendor-input"
                                       value={data.stdCommunicationMethod}
                                       onChange={(e) => {
                                          const { data } = this.state;
                                          data.stdCommunicationMethod = e.target.value;
                                          this.setState({ data });
                                       }}
                                    />}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className='first-table'>
                     <div className="Table-header">
                        <div className="table-heading">Control</div>
                        <div className='first-table-body'>
                           <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                              <p>Tax Information</p>
                              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: "10px" }}>
                                 <div className="black mb-20 wt-30p">
                                    <div className="black">GST No.<span className='error'>*</span></div>
                                    {<input
                                       type="text"
                                       placeholder="GST no"
                                       className="new-vendor-input"
                                       value={data.gst_no}
                                       onChange={(e) => {
                                          const { data } = this.state;
                                          if (e.target.value.length <= 15) {
                                             data.gst_no = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '');
                                          }
                                          this.setState({ data });
                                       }}
                                    />}
                                 </div>
                                 <div className="black mb-20 wt-30p">
                                    <div className="black">PAN No.<span className='error'>*</span></div>
                                    {<input
                                       type="text"
                                       placeholder="pan no"
                                       className="new-vendor-input"
                                       value={data.pan_no}
                                       onChange={(e) => {
                                          const { data } = this.state;
                                          const regex = /^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$/;
                                          if (e.target.value.length <= 10) {
                                             if (regex.test(e.target.value) || e.target.value.length == 0) {
                                                data.pan_no = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '').toUpperCase();
                                                this.setState({ panError: '' })
                                             }
                                             else {
                                                data.pan_no = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '').toUpperCase();
                                                this.setState({ panError: 'Invalid PAN No.' })
                                             }
                                          }
                                          this.setState({ data });
                                       }}
                                    />}
                                    {this.state.panError && <p style={{ color: 'red', fontSize: '13px' }}>{this.state.panError}</p>}
                                 </div>
                                 <div className="black mb-20 wt-30p">
                                    <div className="black">Aadhar No.(In case of Individual)<span className='error'>*</span></div>
                                    {<input
                                       type="text"
                                       placeholder="adhar no"
                                       className="new-vendor-input"
                                       value={data.aadhar_card_no}
                                       onChange={(e) => {
                                          const { data } = this.state;
                                          if (e.target.value.length <= 12) {
                                             if (e.target.value != 0) {
                                                const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                                const check = e.target.value.match(regEx);
                                                if (!check) {
                                                   return
                                                }
                                             }
                                             data.aadhar_card_no = e.target.value;
                                          }
                                          this.setState({ data });
                                       }}
                                    />}
                                 </div>
                                 <div className="dInBlock mb-20 wt-30p">
                                    <div className='dropdown'>
                                       <div className="black">Pan & Aadhar Linkage<span className='error'>*</span></div>
                                       <Select
                                          value={[{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }].filter((obj) => (data.panAadharLinkage && data.panAadharLinkage.toUpperCase()) === obj.label)}
                                          options={[{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }]}
                                          onChange={(value) => {
                                             const { data } = this.state;
                                             data.panAadharLinkage = value.label;
                                             this.setState({ data });
                                          }}
                                          placeholder={'Pan & Aadhar Linkage'}
                                          className="company-details-vendor-select"
                                       />
                                    </div>
                                 </div>
                                 <div className="dInBlock mb-20 wt-30p">
                                    <div className='dropdown'>
                                       <div className="black">Tax Return Status for 2 Years</div>
                                       <Select
                                          value={[{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }].filter((obj) => data.taxRetunStatusFor2years === obj.label)}
                                          options={[{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }]}
                                          onChange={(value) => {
                                             const { data } = this.state;
                                             data.taxRetunStatusFor2years = value.label;
                                             this.setState({ data });
                                          }}
                                          placeholder={'Tax Return Status for 2 Years'}
                                          className="company-details-vendor-select"

                                       />
                                    </div>
                                 </div>
                                 <div className="dInBlock mb-20 wt-30p">
                                    <div className='dropdown'>
                                       <div className="black">Supplier is Specified person<span className='error'>*</span></div>
                                       <Select
                                          value={[{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }].filter((obj) => data.supplierPerson === obj.label)}
                                          options={[{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }]}
                                          onChange={(value) => {
                                             const { data } = this.state;
                                             data.supplierPerson = value.label;
                                             this.setState({ data });
                                          }}
                                          placeholder={'Supplier is Specified person'}
                                          className="company-details-vendor-select"
                                       />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className='first-table'>
                     <div className="Table-header">
                        <div className="table-heading">Payment Transaction - Bank Details</div>
                        <div className='first-table-body'>
                           <div className="black mb-20 wt-30p">
                              <div className="black">Bank KEY / IFSC Code<span className='error'>*</span></div>
                              {<input
                                 type="text"
                                 placeholder="ifsc code"
                                 className="new-vendor-input"
                                 value={data.IFSC_code}
                                 onChange={(e) => {
                                    const { data } = this.state;
                                    data.IFSC_code = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '').toUpperCase();
                                    this.setState({ data }, () => {
                                       this.getBankDetailsFromIFSCCode()
                                    });
                                 }}
                              />}
                           </div>
                           <div className="black mb-20 wt-30p">
                              <div className="black">Bank Name<span className='error'>*</span></div>
                              {<input
                                 type="text"
                                 placeholder="bank name"
                                 className="new-vendor-input"
                                 value={data.bank_name}
                                 onChange={(e) => {
                                    const { data } = this.state;
                                    data.bank_name = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}0-9]/g, '');
                                    this.setState({ data });
                                 }}
                              />}
                           </div>

                           <div className="black mb-20 wt-30p">
                              <div className="black">Bank Account Number<span className='error'>*</span></div>
                              {<input
                                 type="text"
                                 placeholder="account number"
                                 className="new-vendor-input"
                                 value={data.account_number}
                                 onChange={(e) => {
                                    const { data } = this.state;
                                    if (e.target.value.length > 16) {
                                       return
                                    }
                                    else {
                                       if (e.target.value != 0) {
                                          const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                          const check = e.target.value.match(regEx);
                                          if (!check) {
                                             return
                                          }
                                       }
                                       data.account_number = e.target.value;
                                       this.setState({ data });
                                    }
                                 }}
                              />}
                           </div>
                           <div className="black mb-20 wt-30p">
                              <div className="black">IBAN Number</div>
                              {<input
                                 type="text"
                                 placeholder="iban number"
                                 className="new-vendor-input"
                                 value={data.IBANNumber}
                                 onChange={(e) => {
                                    const { data } = this.state;
                                    data.IBANNumber = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '');
                                    this.setState({ data });
                                 }}
                              />}
                           </div>
                           <div className="black mb-20 wt-30p">
                              <div className="black">Swift Code</div>
                              {<input
                                 type="text"
                                 placeholder="swift code"
                                 className="new-vendor-input"
                                 value={data.swiftCode}
                                 onChange={(e) => {
                                    const { data } = this.state;
                                    data.swiftCode = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '');
                                    this.setState({ data });
                                 }
                                 }
                              />}
                           </div>
                           <div className="dInBlock mb-20 wt-30p">
                              <div className="black">Country<span className='error'>*</span></div>
                              <Select
                                 value={(country_options || []).filter(obj => { return data.bankcountryName === obj.label })}
                                 options={country_options || []}
                                 onChange={(value) => {
                                    const { data } = this.state;
                                    data.bankcountryName = value.label;
                                    this.setState({ data });
                                 }}
                                 placeholder={'Country'}
                                 className='new-vendor-select'
                                 style={{ height: '50px' }}
                              />
                           </div>

                        </div>
                     </div>
                  </div>

                  <div className='first-table'>
                     <div className="Table-header">
                        <div className="table-heading">Accounting Information</div>
                        <div className='first-table-body'>
                           <div className="black mb-20 wt-45p">
                              <div className="black">Sort Key<span className='error'>*</span></div>
                              {<input
                                 type="text"
                                 placeholder="sort key"
                                 className="new-vendor-input"
                                 value={data.accountingSortKey}
                                 onChange={(e) => {
                                    const { data } = this.state;
                                    data.accountingSortKey = e.target.value;
                                    this.setState({ data });
                                 }}
                              />}
                           </div>
                           <div className="dInBlock wt-45p">
                              <div className="black">Cash Management Group<span className='error'>*</span></div>
                              <Select
                                 value={(CASHMANAGEMENT || []).filter(obj => { return data.cashMgmtGroup === obj.label })}
                                 options={(CASHMANAGEMENT || [])}
                                 onChange={(value) => {
                                    const { data } = this.state;
                                    data.cashMgmtGroup = value.label;
                                    this.setState({ data });
                                 }}
                                 placeholder={'Cash Management Group'}
                                 className='new-vendor-select'
                                 style={{ height: '50px' }}
                              />
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className='first-table'>
                     <div className="Table-header">
                        <div className="table-heading">Payment Transaction Accounting</div>
                        <div className='first-table-body'>
                           <div className="dInBlock wt-30p">
                              <div className="black">Payment Terms<span className='error'>*</span></div>
                              <Select
                                 value={(this.state.payment_terms_options || []).filter(obj => { return data.paymentTerms === obj.label })}
                                 options={this.state.payment_terms_options || []}
                                 onChange={(value) => {
                                    const { data } = this.state;
                                    data.paymentTerms = value.label;
                                    this.setState({ data });
                                 }}
                                 placeholder={'Payment Terms'}
                                 className='new-vendor-select'
                                 style={{ height: '50px' }}
                              />
                           </div>
                           <div className="dInBlock wt-30p">
                              <div className="black">Check Double Inv.<span className='error'>*</span></div>

                              <input
                                 type="text"
                                 placeholder="Check double inv"
                                 className="new-vendor-input"
                                 value={data.doubleInvCheck}
                                 onChange={(e) => {
                                    const { data } = this.state;
                                    data.doubleInvCheck = e.target.value;
                                    this.setState({ data });
                                 }}
                              />
                           </div>
                           <div className="dInBlock wt-30p">
                              <div className="black">Payment Method<span className='error'>*</span></div>
                              <Select
                                 value={(PAYMENT_METHOD).filter(obj => { return data.paymentMethod === obj.label })}
                                 options={(PAYMENT_METHOD || [])}
                                 onChange={(value) => {
                                    const { data } = this.state;
                                    data.paymentMethod = value.label;
                                    this.setState({ data });
                                 }}
                                 placeholder={'Payment Method'}
                                 className='new-vendor-select'
                                 style={{ height: '50px' }}
                              />
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className='first-table'>
                     <div className="Table-header">
                        <div className="table-heading">Corresponding Acconting</div>
                        <div className='first-table-body'>
                           <div className="dInBlock wt-45p">
                              <div className="black">Clrk's Internet</div>
                              <input
                                 type="text"
                                 placeholder="Clrk's Internet"
                                 className="new-vendor-input"
                                 value={data.clrkInternet}
                                 onChange={(e) => {
                                    const { data } = this.state;
                                    data.clrkInternet = e.target.value;
                                    this.setState({ data });
                                 }}
                              />
                           </div>
                           <div className="dInBlock wt-45p">
                              <div className="black">Account Memo(MSMED)</div>
                              <Select
                                 value={(ACCOUNT_MEMO || []).filter(obj => { return data.accountMemo === obj.label })}
                                 options={(ACCOUNT_MEMO || [])}
                                 onChange={(value) => {
                                    const { data } = this.state;
                                    data.accountMemo = value.label;
                                    this.setState({ data });
                                 }}
                                 placeholder={'Account Memo'}
                                 className='new-vendor-select'
                                 style={{ height: '50px' }}
                              />
                           </div>
                        </div>
                     </div>
                  </div>

                  {data.withholdTax && (data.withholdTax == 'YES') &&
                     <div className='first-table'>
                        <div className="Table-header">
                           <div className="table-heading">With Holding Tax Accounting</div>
                           <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <div className='first-table-body'>
                                 <div className="dInBlock wt-100p">
                                    <div className="black">WH Tax Country</div>
                                    <Select
                                       value={(country_options || []).filter(obj => { return data.taxWHCountry === obj.label })}
                                       options={country_options || []}
                                       onChange={(value) => {
                                          const { data } = this.state;
                                          data.taxWHCountry = value.label;
                                          this.setState({ data });
                                       }}
                                       placeholder={'WH Tax Country'}
                                       className='new-vendor-select'
                                       style={{ height: '50px' }}
                                    />
                                 </div>
                              </div>

                              {(data.WH_Tax_Accounting || []).map((d, index) => {
                                 return (
                                    <div className='first-table-body'>
                                       <div className="dInBlock wt-25p">
                                          <div className="black">Wth.t.type</div>
                                          <Select
                                             value={WTHTTYPE.filter(obj => { return d.taxWHType === obj.label })}
                                             options={WTHTTYPE}
                                             onChange={(value) => {
                                                const { data } = this.state;
                                                data.WH_Tax_Accounting[index].taxWHType = value.label;
                                                this.setState({ data });
                                             }}
                                             placeholder={'Wth.t.type'}
                                             className='new-vendor-select'
                                             style={{ height: '50px' }}
                                          />
                                       </div>

                                       <div className="dInBlock wt-25p">
                                          <div className="black">Liable</div>
                                          <Select
                                             value={([{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }]).filter(obj => { return d.taxLiable === obj.label })}
                                             options={[{ label: 'YES', value: 'YES' }, { label: 'NO', value: 'NO' }]}
                                             onChange={(value) => {
                                                const { data } = this.state;
                                                data.WH_Tax_Accounting[index].taxLiable = value.label;
                                                this.setState({ data });
                                             }}
                                             placeholder={'Liable'}
                                             className='new-vendor-select'
                                             style={{ height: '50px' }}
                                          />
                                       </div>

                                       <div className="dInBlock wt-25p">
                                          <div className="black">Wth.t.ty Rec Text</div>
                                          <input
                                             type="text"
                                             placeholder="Wth.t.ty Rec Text"
                                             className="new-vendor-input"
                                             value={d.taxRec}
                                             onChange={(e) => {
                                                const { data } = this.state;
                                                data.WH_Tax_Accounting[index].taxRec = e.target.value;
                                                this.setState({ data });
                                             }}
                                          />
                                       </div>

                                       <div style={{ display: 'flex', width: '10%', marginTop: '15px', marginLeft: '20px' }}>
                                          {(index + 1) === data.WH_Tax_Accounting.length ?
                                             <div className="mb-10 mt-10 fRight">
                                                {index !== 0 && <img src={cancelBtn} style={{ width: '20px', cursor: 'pointer' }} onClick={() => this.removeWHfield(index)} />}
                                                <img src={addBtn} style={{ width: '20px', cursor: 'pointer', marginLeft: '10px' }} onClick={() => this.addWHfield()} />
                                             </div> :
                                             <div className="mb-10 mt-10 fRight wt-20p">
                                                {index !== 0 && <img src={cancelBtn} style={{ width: '20px', cursor: 'pointer' }} onClick={() => this.removeWHfield(index)} />}
                                             </div>}
                                       </div>

                                    </div>
                                 )
                              })}
                           </div>
                        </div>
                     </div>
                  }

                  <div className='first-table'>
                     <div className="Table-header">
                        <div className="table-heading">Purchasing Organization Data</div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                           <div className='first-table-body'>
                              <div className="dInBlock wt-30p">
                                 <div className="black">Order Currency<span className='error'>*</span></div>
                                 <Select
                                    value={(currency_options || []).filter(obj => { return data.currencyOrder === obj.label })}
                                    options={(currency_options || [])}
                                    onChange={(value) => {
                                       const { data } = this.state;
                                       data.currencyOrder = value.label;
                                       this.setState({ data });
                                    }}
                                    placeholder={'Order Currency'}
                                    className='new-vendor-select'
                                    style={{ height: '50px' }}
                                 />
                              </div>
                              <div className="dInBlock wt-30p">
                                 <div className="black">Terms of Payment<span className='error'>*</span></div>
                                 <input
                                    type="text"
                                    placeholder="Terms of Payment"
                                    className="new-vendor-input"
                                    value={data.paymentTerms}
                                    onChange={(e) => {
                                       const { data } = this.state;
                                       data.paymentTerms = e.target.value;
                                       this.setState({ data })
                                    }}
                                 />
                              </div>
                              <div className="dInBlock wt-30p">
                                 <div className="black">Incoterms 1<span className='error'>*</span></div>
                                 <input
                                    type="text"
                                    placeholder="Incoterms 1"
                                    className="new-vendor-input"
                                    value={data.incoTerm1}
                                    onChange={(e) => {
                                       const { data } = this.state;
                                       data.incoTerm1 = e.target.value;
                                       this.setState({ data })
                                    }}
                                 />
                              </div>
                           </div>

                           <div className='first-table-body'>
                              <div className="dInBlock wt-30p">
                                 <div className="black">Incoterms 2<span className='error'>*</span></div>
                                 <input
                                    type="text"
                                    placeholder="Incoterms 2"
                                    className="new-vendor-input"
                                    value={data.incoTerm2}
                                    onChange={(e) => {
                                       const { data } = this.state;
                                       data.incoTerm2 = e.target.value;
                                       this.setState({ data })
                                    }}
                                 />
                              </div>
                              <div className="dInBlock wt-30p">
                                 <div className="black">Schema Group Vendor</div>
                                 <input
                                    type="text"
                                    placeholder="Schema Group Vendor"
                                    className="new-vendor-input"
                                    value={data.groupVendorSchema}
                                    onChange={(e) => {
                                       const { data } = this.state;
                                       data.groupVendorSchema = e.target.value;
                                       this.setState({ data });
                                    }}
                                 />
                              </div>

                              <div className="dInBlock wt-30p">
                                 <div className="black">GR-Based Inv. Verify<span className='error'>*</span></div>
                                 <input
                                    type="text"
                                    placeholder="GR-Based Inv. Verify"
                                    className="new-vendor-input"
                                    value={data.verifyGRBased}
                                    onChange={(e) => {
                                       const { data } = this.state;
                                       data.verifyGRBased = e.target.value;
                                       this.setState({ data })
                                    }}
                                 />
                              </div>
                           </div>

                           <div className='first-table-body'>
                              <div className="dInBlock wt-30p">
                                 <div className="black">Srv.Based Inv. Verify<span className='error'>*</span></div>
                                 <input
                                    type="text"
                                    placeholder="Srv.Based Inv. Verify"
                                    className="new-vendor-input"
                                    value={data.verifySrvBased}
                                    onChange={(e) => {
                                       const { data } = this.state;
                                       data.verifySrvBased = e.target.value;
                                       this.setState({ data })
                                    }}
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>


                  <div className='first-table'>
                     <div className="Table-header">
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                           <div className='first-table-body'>
                              <div className="dInBlock wt-30p">
                                 <input
                                    type="text"
                                    className="new-vendor-input"
                                    value={data.requester}
                                    disabled
                                 />
                                 <div className="black" style={{ textAlign: 'center', marginTop: '10px', fontSize: '18px', fontWeight: 'bold' }}>Requestor</div>
                              </div>
                              <div className="dInBlock wt-30p">
                                 <input
                                    type="text"
                                    className="new-vendor-input"
                                    value={data.verifier}
                                    disabled
                                 />
                                 <div className="black" style={{ textAlign: 'center', marginTop: '10px', fontSize: '18px', fontWeight: 'bold' }}>Verifier</div>
                              </div>
                              <div className="dInBlock wt-30p">
                                 <input
                                    type="text"
                                    className="new-vendor-input"
                                    value={data.approver}
                                    disabled
                                 />
                                 <div className="black" style={{ textAlign: 'center', marginTop: '10px', fontSize: '18px', fontWeight: 'bold' }}>Approver</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="mb-25 mt-25 fRight" style={{ marginLeft: 'auto' }}>
                  {this.state.verifiedError && <div className='error'>{this.state.verifiedError}</div>}
                  {this.state.errorMsg && <div className='error'>{this.state.errorMsg}</div>}

                  <button
                     className="mb-25 button-classic mr-20"
                     onClick={() => history.goBack()}
                  >
                     Cancel
                  </button>

                  <button
                     className="mb-25 button-classic mr-20"
                     onClick={this.savePrimaryDetails}
                  >
                     Save
                  </button>

                  <button
                     className={data.save_SAP_form ? "mb-25 button-classic mr-20" : "mb-25 button-classic mr-20 disabled-download"}
                     onClick={() => this.handleDownloadPdf()}
                     disabled = {!data.save_SAP_form}
                  >
                     Download
                  </button>

               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      loader: state.loader,
      msgpopup: state.msgpopup,
      vendorForm: state.vendorForm,
      productList: state.master.itemList,
   };
};

export default connect(mapStateToProps)(SapVendorForm);
