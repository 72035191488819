import React, { Component } from 'react';
import Search from '../../common/components/search-text/search-text';
import Button from '../../home/button-classic/button-classic';
import { connect } from "react-redux";
import ACTIONS from '../../common/action_constant';
import AddMaterialConversion from './add_material_conversion';
import { toPascalCase } from "../../../utils/validate";
import { withRouter } from 'react-router-dom';
import Delete from '../../../assets/images/delete.svg';
import Edit from '../../../assets/images/edit.svg'
import { AWL_COMPANY_IDS } from '../../../utils/common';


class MaterialConversion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            offset: 0,
            previous_data:null,
        }
    }

    componentWillMount() {
        this.getMaterialConversion();
    }

    getMaterialConversion() {
        const { dispatch, company_id } = this.props;
        const { search, offset } = this.state
        const param = {
            offset,
            limit: 1000,
            companyId: company_id
        }
        if (search) {
            param.search = search
        }
        dispatch({
            type: ACTIONS.HOME.GET_MATERIAL_CONVERSION,
            param
        })
    }

    changeHandler = (value) => {
        this.setState({ search: value });
    }

    toggleAdd = () => {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen, editData: '' });
    }

    onEditRoute = (route) => {
        this.setState({ editData: route, isOpen: true })
    }

    onSave = (data) => {
        const { search, offset } = this.state;
        const { dispatch, company_id } = this.props;
        const param = {
            offset,
            limit: 1000,
            companyId: company_id
        }
        if (search) {
            param.search = search
        }
        dispatch({
            type: ACTIONS.HOME.ADD_MATERIAL_CONVERSION,
            data,
            param,
            onSuccess: () => {
                this.setState({ isOpen: false }, this.getMaterialConversion);
            }
        })
    }

    onSearch = () => {
        this.setState({ offset: 0 }, this.getMaterialConversion);
    }

    onUpdate = (data) => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTIONS.HOME.UPDATE_MATERIAL_CONVERSION,
            data,
            onSuccess: () => {
                this.setState({ isOpen: false, editData: '', previous_data:null }, this.getMaterialConversion);
            }
        })
    }

    downloadTemp = (withData) => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTIONS.HOME.DOWNLOAD_MATERIAL_CONVERSION,
            withData,
            onSuccess: ((data) => {
                this.setState({ downloadUrl: data ? data[0].Link : '' }, () => {
                    this.downloadLink.click();
                });
            })
        })
    }

    uploadMaterialConversion = (e) => {
        let formData = new FormData();
        formData.append('file', e.target.files[0]);
        const { dispatch } = this.props;
        dispatch({
            type: ACTIONS.HOME.UPLOAD_MATERIAL_CONVERSION, data: formData,
            onSuccess: () => {
                this.getMaterialConversion();
            }
        });
    }

    uploadMC = () => {
        this.uploadExcel.click();
    }

    editMC = (data) => {
        this.setState({
            editData: data,
            isOpen: true,
            previous_data:data
        });
    }

    deleteMC = (data) => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTIONS.HOME.DELETE_MATERIAL_CONVERSION,
            data,
            onSuccess: () => {
                this.getMaterialConversion();
            }
        })
    }

    previous = () => {
        if (this.state.offset > 0) {
            this.setState({
                offset: this.state.offset - 1000
            }, this.getMaterialConversion);
        }
    }
    next = () => {
        this.setState({
            offset: this.state.offset + 1000
        }, this.getMaterialConversion);
    }

    clearFilterOnCancel = () => {
        this.setState({ search: '', offset: 0 }, this.getMaterialConversion);
    }

    handleOnClickForAuditBtn = () => {
        const {history, company_id} = this.props;
        const masterType = 19
        const navUrl = `/auditScreen/${company_id}/${masterType}`;
        history.push(navUrl)
    }

    render() {
        const { materialConversionList, home } = this.props;

        return (
            <div className={'location-network'}>
                <div>
                    <div style={{ display: 'block', paddingBottom: '15px' }}>
                        <Search id="search" value={this.state.search} placeholder="Search..." changeHandler={this.changeHandler} click={this.onSearch} canClear={this.state.search !== ''} handleClearFilter={this.clearFilterOnCancel} />
                    </div>

                    <Button value={'+ Material Conversion'} click={this.toggleAdd} />
                    <Button value={'Download Temp.'} click={() => this.downloadTemp(true)} />
                    <a href={this.state.downloadUrl} style={{ display: 'none' }} ref={(e) => { this.downloadLink = e; }} />
                    <Button value={'Download Details'} click={() => this.downloadTemp(false)} />
                    <a href={this.state.downloadUrl} style={{ display: 'none' }} ref={(e) => { this.downloadLink = e; }} />
                    <Button value={'Upload'} click={this.uploadMC} />
                    <input type='file' style={{ display: 'none' }} ref={(e) => { this.uploadExcel = e; }} onChange={this.uploadMaterialConversion} onClick={(e) => { e.target.value = null }} />
                    <Button value={'Audit'} click={this.handleOnClickForAuditBtn} />
                </div>
                <div className={'chain-details-section'}>
                    <div className={'list-section header'}>
                        <div className={'tabel-header'}>Material</div>
                        <div className={'tabel-header'}>Alternative UOM</div>
                        {AWL_COMPANY_IDS.includes(home && home.basicDetails && home.basicDetails.company_id) ? 
                        <>
                        <div className={'tabel-header'}>Denominator (X)</div>
                        <div className={'tabel-header'}>Counter (Y)</div>
                        </>
                        :
                        <>
                        <div className={'tabel-header'}>Counter (X)</div>
                        <div className={'tabel-header'}>Denominator (Y)</div>
                        </>
                        }
                        <div className={'tabel-header'}>Actions</div>
                    </div>
        
                        {(materialConversionList || []).map((mc, index) => {
                            return(<div className={'list-section content'} key={`route_${index}`}>
									<div className={'tabel-header'}>{mc.Material}</div>
									<div className={'tabel-header'}>{mc['Alternative Unit of Measure']}</div>
                                {AWL_COMPANY_IDS.includes(home && home.basicDetails && home.basicDetails.company_id) ?
                                    <>
                                        <div className={'tabel-header'}>{mc.Denominator}</div>
                                        <div className={'tabel-header'}>{mc.Counter}</div>
                                    </>
                                    :
                                    <>
                                        <div className={'tabel-header'}>{mc.Counter}</div>
                                        <div className={'tabel-header'}>{mc.Denominator}</div>
                                    </>
                                }
                                    <div className={'tabel-header'}> 
                                        <div className={'action-icon'} style={{backgroundImage: `url(${Edit})`}} onClick={() => this.editMC(mc)} />
                                        <div className={'action-icon'} style={{backgroundImage: `url(${Delete})`}} onClick={() => this.deleteMC(mc)} />
                                    </div>
                            </div>)})
                        }
                </div>
                <div className="submit-section-pagination">
                    <div className="next">
                        <Button value="Next" click={this.next} />
                    </div>
                    <div className="pre">
                        <Button value="Previous" click={this.previous} />
                    </div>
                </div>
                {this.state.isOpen && 
                 <AddMaterialConversion 
                  companyId = {home && home.basicDetails && home.basicDetails.company_id} 
                  toggleAdd={this.toggleAdd} 
                  onSave={this.onSave} 
                  company_id={this.props.company_id} 
                  editData={this.state.editData} 
                  onUpdate={this.onUpdate} 
                  previous_data={this.state.previous_data}
                  />}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        materialConversionList : state.home.materialConversion || [],
        home: state.home,
    };
};

export default connect(mapStateToProps)(withRouter(MaterialConversion));
