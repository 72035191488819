import React, { PropTypes } from 'react';
import Delete from "../../../../../assets/images/delete.svg";
import AddRole from "./add_role/add_role";
import { getEmail, getUserId } from '../../../../../utils/common';

export default class Roles extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         selectedOption: 1
      }
   }

   toggleAdd = () => {
      this.setState({
         showAdd: !this.state.showAdd
      });
   }

   toggleDelete = (id) => {
      this.setState({
         showDelete: !this.state.showDelete,
         roleId: id
      })
   }

   deleteRole = (id, role_name,company_id) => {
      let paramData = {
         "company_id": this.props.companyDetails && this.props.companyDetails.id,
         "role_id": id,
          user_id: getUserId(),
          user_email: getEmail(),
          master_type: 6,
          role_name: role_name,
         //  company_id:  company_id,
          delete_flag: true,
         }
      this.props.deleteRole(paramData);
   }

   downloadProviderRolesDeatils = () => {
      this.props.downloadProviderRolesDeatils(this.props.companyId)
   }



   uploadProviderRolesFile = (e) => {
      this.props.uploadProviderRolesFile(e, this.props.companyId);
   }

   render() {
      window.scroll(0, 0);
      return (
         <div className="role-root">
            <div className="flex">
               <h3>ROLES</h3>
               {this.props && this.props.companyType !== "1" && <div className="dept_role_download">
                  <button className="button-classic" onClick={this.downloadProviderRolesDeatils} >  Download Roles</button>
               </div>}
               {this.props && this.props.companyType !== "1" && <div className="dept_role_upload">
                  <button className="button-classic" onClick={() => { this.uploadExcel.click() }}> Upload Roles</button>
                  <input type='file' style={{ display: 'none' }} ref={(e) => { this.uploadExcel = e; }} onChange={this.uploadProviderRolesFile} onClick={(e) => { e.target.value = null }} />
               </div>}
            </div>

            <div className="company-list-wrap" style={{ 'width': '100%' }}>
               <div style={{ 'display': 'flex' }}>
                  <div className="list-section header roles">
                     <div className="admin-details">
                        Role Name
                     </div>
                     <div className="status center">
                        Action
                     </div>
                  </div>
                  <div className="list-section header" style={{ 'width': '50%' }}>
                     <div className="admin-details">
                        Role Name
                     </div>
                     <div className="status center">
                        Action
                     </div>
                  </div>
               </div>
               {
                  this.props.roles.length == 0 && <div className="no-records">No records found, you can add by clicking on Add Role button</div>
               }
               {
                  this.props.roles.map((rol, index) => {
                     return (
                        <div className="list-section content roles" key={index}>
                           <div className="company-name-section">
                              <div className="compnay-details">
                                 {rol.role_name}
                              </div>
                           </div>
                           {/* <div className = "account-type">
													<div>{rol.company_type == 1 ? "Seeker" : rol.company_type == 2? "Provider" : "3PL"  }</div>
												</div> */}
                           <div className="status center" >
                              <div className='delete-icon' style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.deleteRole(rol.role_id,rol.role_name,rol.company_id)}></div>
                              {/* <button className = "add-button" onClick = {() => this.deleteRole(rol.role_id)}>Delete</button> */}
                           </div>
                        </div>
                     )
                  })
               }
               <div className="submit-section">
                  <div className="add-button" onClick={this.toggleAdd}>
                     ADD ROLE
                  </div>
               </div>
            </div>

            {
               (this.state.showAdd) && <AddRole addRole={this.props.addRole} companyDetails={this.props.companyDetails} toggleAdd={this.toggleAdd} />
            }
            {this.state.showDelete && <DeleteDepartment selectedId={this.state.roleId} deleteRole={this.props.deleteRole} toggleDelete={this.toggleDelete} />}
         </div>
      )
   }
}
