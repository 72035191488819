import React, { Component } from 'react';
import Select from 'react-select';
import CheckBox from '../../../../components/checkbox/checkbox';
import Delete from '../../../../../assets/images/delete.svg';
import Button from '../../../button-classic/button-classic';

class costImpactPenality extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <div className={'fixed-define-process'}>
                <div className={'heading fixed-define'}>Cost Impact Penality</div>
                <div className={'fuel-escalation-table non-register-vendor'}>
                    <div className={'list-section'}>
                        <div className={'table-header event'}>Event</div>
                        <div className={'table-header amount'}>%</div>
                        <div className={'table-header uom'}>% of</div>
                        <div className={'table-header action'}>Activate</div>
                        <div className={'table-header action'}>Action</div>
                    </div>
                    {this.props.data && this.props.data.map((row, index) => {
                        return(<div className={'list-section'} key={index}>
                            <div className={'table-row event'}>{row.event}</div>
                            <div className={'table-row amount'}>{row.percentage}</div>
                            <div className={'table-row uom'}>{row.percentage_of}</div>
                            <div className={'table-row action'}><CheckBox status={row.is_active}/></div>
                            <div className={'table-row delete-icon action'} style={{backgroundImage: `url(${Delete})`}} onClick={() => this.props.deleteDebitMaster(row.id, this.props.isType)}></div>
                        </div>) 
                        }) }
                    <div className={'approval-btn'}>
                        <Button value={'Add'} click={this.props.onAdd}></Button>
                    </div>
                </div>
            </div>
        )
    }
}  
export default costImpactPenality;