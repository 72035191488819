import React, { Component} from 'react';
import {connect} from 'react-redux';
import "./sub-navigation-panel.less";

class SubNavigationPanel extends Component {
	constructor(props){
		super(props);
		this.state = {
			subNavOptions : [{
				heading : "Route Import",
				id : "1"
			},{
				heading : "Mode Import",
				id : "2"
			},{
				heading : "Shipment Log",
				id : "3"
			}],
			selectedOption : "1",
		}
	}


	setOption = (option) => {
		this.setState({
		  selectedOption: option
		});
		this.props.selectedSubTab(option);
	  };

	componentDidMount(){
		const { dispatch } = this.props;
		// this.props.selectedSubTab(this.state.selectedOption);
	}

	render() {
		return (
			<div className = "sub-navigation-panel-root">
            	<div className = "sub-navigation-holder">
					{this.state.subNavOptions.map((option, index) => {
						return (
						<div  key = {index} className={this.state.selectedOption === option.id ? "selected-option options" : "options"} onClick = {()=>this.setOption(option.id)}>{option.heading}
						</div>
						)
					})}	
				</div> 
			</div>
		);
	}
}
  
export default SubNavigationPanel;