import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ACTION from "../../common/action_constant";
import "./demurrage.less";
import "./AdvanceFilter.css";
import MainComponent from "../../home/main/main";
import Button from "../../home/button-classic/button-classic";
import CheckBox from "./../../components/checkbox/checkbox";
import InputText from "../../common/components/input-text/input-text";
import Loader from "../../common/components/loader/loader";
import MSGPopUp from "../../common/components/msg-popup/msg-popup";

import AddNewDemurrage from "./AddNewDemurrage";

import moment from "moment";
import Select from "react-select";
import AdvanceFilter from './AdvanceFilter'
import Save from '../../../assets/images/save.svg'
import Cancel from '../../../assets/images/cancel.svg'
import Edit from '../../../assets/images/edit.svg'

const DELAY_UOM_TYPES = [{ id: 2, label: 'Efficiency', value: 2 },
                        { id: 1, label: 'Days', value: 1 }];

const DEMURRAGE_ITEM = { max: null, min: null, frieght: null, frieght_uom: 'PERCENTAGE' };

class Demurrage extends Component {
    constructor(props){
        super(props);
        this.state = {
            isAdded: false,
            addItemPopup: false,
            selectedDemurrages: [],
            typeUOM: DELAY_UOM_TYPES[0],
            demurragesData: (props.dataGovernance && props.dataGovernance.demurragesData) ? props.dataGovernance.demurragesData : [],
            showAdvanceFilter: false,
            categories: [],
            modes: [],
            newDemurrage: {
                id: -1,
                company_id: null,
                vendor_id: null,
                vendor_code: null,
                vendor_name: null,
                mode_name: null,
                mode_id: null,
                demurrage_start_date: new Date().getTime(),
                demurrage_charges: [{...DEMURRAGE_ITEM}]
            }
        }
    }

    componentDidMount() {
        this.getDemurrageData();
        this.getVendorsData();
        this.getModeTypesData();
    }

    componentWillReceiveProps(nextProps) {
        const { dataGovernance } = this.props;
        let newCategories = [];
        let newModes = [];
          
        if (dataGovernance !== nextProps.dataGovernance) {
            if (dataGovernance.demurragesData !== nextProps.dataGovernance.demurragesData) {
                this.setState({
                    demurragesData: nextProps.dataGovernance.demurragesData
                });
            }
        }

        if(dataGovernance.vendorTypes !=undefined && dataGovernance.modeTypes !=undefined) {
            const { vendorTypes, modeTypes } = dataGovernance;
            if(vendorTypes.length>0 && this.state.categories.length == 0) {
                newCategories = Object.values(vendorTypes.reduce((acc,cur)=>Object.assign(acc,{[cur.company_category_id]:cur}),{}))
                this.setState({
                    categories: newCategories.map(el => ({...el, selected: false, id: el.company_category_id})),
                });
            }
            if(modeTypes.length>0 && this.state.modes.length == 0) {
                newModes = Object.values(modeTypes.reduce((acc,cur)=>Object.assign(acc,{[cur.id]:cur}),{}))
                this.setState({
                    modes: newModes.map(el => ({...el, selected: false})),
                });
            }
        }
    }

    getDemurrageData = () => {
        const { dispatch } = this.props;
        let inputData = {limit: 100, offset: 0};
        
        let categoryFilter = this.state.categories.filter(el => el.selected);
        
        if(categoryFilter.length>0) {
            let cat = [];
            categoryFilter.forEach(element => {
                cat.push(element.id)
            });
            inputData.category = cat;
        }
        let modeFilter = this.state.modes.filter(el => el.selected);
        if(modeFilter.length>0) {
            let mode = [];
            modeFilter.forEach(element => {
                mode.push(element.id)
            });
            inputData.mode_id = mode;
        }
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_DEMURRAGE,
            params: inputData,
            onSuccess: (() => { this.setState({ editId: null, isAdded: false  }) })
        })
    }

    getModeTypesData = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_MODE_TYPES,
            // params: 
        })
    }

    getVendorsData = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_VENDORS_TYPES,
        })
    }


    showPopUp = () => {
        this.setState({
            addItemPopup: !this.state.addItemPopup,
            isAdded: true,
        })
    }

    addNewDemurrage = () => {
        const { newDemurrage } = this.state;
        newDemurrage.demurrage_charges.push({...DEMURRAGE_ITEM});
        this.setState({
            newDemurrage
        })
    }

    cancelNewSlab = () => { 
        this.setState({ 
            isAdded: false,
            addItemPopup: false
        });
        this.resetData();
    }

    onChangeInput = (value, id, type, index) => {
        const { newDemurrage } = this.state;
        
        if (type === 'vendor_obj') {
            newDemurrage.vendor_name = value.company;
            newDemurrage.vendor_id = value.company_id;
            newDemurrage.vendor_code = value.sap_vendor_code;
            newDemurrage.company_category = value.company_category;
            newDemurrage.company_category_id = value.company_category_id;
            newDemurrage.seeker_id = value.seeker_id;
        } else if (type === 'mode_type_obj') {
            newDemurrage.mode_name = value.name;
            newDemurrage.mode_id = value.id;
        } else if (type === 'demurrage_start_date') {
            newDemurrage.demurrage_start_date = value;
        }
        else {
            newDemurrage.demurrage_charges[index][type] = value;
        }

        this.setState({
            newDemurrage
        })
    }

    editDemurrage = (index) => {
        this.setState({
            editId: (this.state.editId===index) ? null : index
        });
    }

    handleCkSelect = (e, id) => {
        const { selectedDemurrages } = this.state;
        if (selectedDemurrages.includes(id)) {
            selectedDemurrages.splice(id, 1);
        } else {
            selectedDemurrages.push(id);
        }
        
        this.setState({ selectedDemurrages });
    }

    handleOnDataUpdate = (e, id, pIndex, eIndex, type) => {
        const { demurragesData } = this.state;
        demurragesData[pIndex].demurrage_charges[eIndex][type] = e;

        this.setState({
            demurragesData
        })
    }

    saveDemurrage = (data) => {
        let { newDemurrage, isAdded, typeUOM } = this.state;
        const { dispatch } = this.props;
        const newData = {...newDemurrage};
        delete newData.id;
        
        if (isAdded) {
            dispatch({
                type: ACTION.DATA_GOVERNANCE.ADD_DEMURRAGE,
                params: newData,
                onSuccess: (() => {
                    this.setState({ isAdded: false, editId: null, addItemPopup: false });
                    this.getDemurrageData();
                })
            })
        } else {
            this.updateDemurrage(data);
        }
    }

    updateDemurrage = (data) => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.UPDATE_DEMURRAGE,
            params: data,
            onSuccess: (() => {
                this.setState({ isAdded: false, editId: null });
                this.getDemurrageData();
            })
        });
    }

    deleteDelayPenalty = () => {
        const { demurragesData, selectedDemurrages } = this.state;
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.DELETE_DEMURRAGE,
            params: selectedDemurrages,
            onSuccess: (() => {
                this.getDemurrageData();
            })
        });
    }

    downloadDelayPenalty = () => {
        const { demurragesData, typeUOM } = this.state;
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.DOWNLOAD_DELAY_PENALTY,
            params: {type: typeUOM.value},
            onSuccess: ((data) => {
                this.setState({
                    downloadLink: data.link
                });
                this.downloadBtn.click();
            })
        })
    }

    handleDelayTypeChange = (value) => {
        let { demurragesData } = this.state;
        // demurragesData = demurragesData.filter((data) => { return data.id ? data : null; })
        this.setState({ 
            typeUOM: value, 
            isAdded: false,
            // demurragesData,
        }, () => {
            this.getDemurrageData();
        })
    }

    uploadPenaltyFile = (e) => {
        let formData = new FormData();
        formData.append('file', e.target.files[0]);
        const { dispatch } = this.props;
        dispatch({ 
            type: ACTION.DATA_GOVERNANCE.UPLOAD_DELAY_PENALTY, 
            params: this.state.typeUOM.value,
            data: formData, 
            onSuccess: (() => {
                this.getDemurrageData();
            })
        });
    }

    closeMsgPopup = () =>{
        const {dispatch} = this.props;
        dispatch({ type : ACTION.MSG_DISPLAY.HIDE_MSG });
    }

    resetData = () => {
        let { newDemurrage } = this.state;
        newDemurrage = {
                company_id: null,
                vendor_id: null,
                vendor_code: null,
                vendor_name: null,
                mode_name: null,
                mode_id: null,
                demurrage_start_date: '', //new Date().getTime(),
                demurrage_charges: [{...DEMURRAGE_ITEM}]
            };
        this.setState({ newDemurrage });
    }

    handleAdvanceFilter = () => {
        this.setState({showAdvanceFilter: !this.state.showAdvanceFilter})
    }

    handleTagClick = (key, value) => {
        this.setState({
            [key]: this.state[key].map((el) => value == el.id?{...el, selected: !el.selected}:el)
        })
    }

    handleFilterSubmit = () => {
        this.setState({
            showAdvanceFilter: false
        })
        this.getDemurrageData();
    }

    renderTableBody = (demurragesData) => {
        const { typeUOM, editId, isAdded, selectedDemurrages } = this.state;
        
        return (
          <Fragment>
            {demurragesData && (demurragesData || []).map((demurrage, pIndex) => {
                return (
                    <div className="list-section content divTableRow" key={`${demurrage.demurrage_master_id}-${pIndex}`}>
                        <div className="divTableCell select-row"> 
                        {!isAdded && (pIndex !== editId) && 
                            <input 
                                type="checkbox" 
                                readOnly
                                checked={selectedDemurrages.includes(demurrage.demurrage_master_id)}
                                onClick={(e) => this.handleCkSelect(e, demurrage.demurrage_master_id)}
                            />
                        }
                        </div>
                        <div className="divTableCell"> {demurrage.company_category} </div>
                        <div className="divTableCell"> {demurrage.vendor_name} </div>
                        <div className="divTableCell"> {demurrage.vendor_code} </div>
                        <div className="divTableCell"> {demurrage.mode_name} </div>
                        <div className="divTableCell"> {demurrage.demurrage_start_date} </div>
                        <div className="divTableCell">
                        {(demurrage.demurrage_charges || []).map((slab, eIndex) => {
                            return (<div className="sub-header-inline penaltyPercent" key={`min-${demurrage.demurrage_master_id}-${pIndex}-${eIndex}`}>
                            {(editId===pIndex) ?
                                <InputText type="number" placeholder="no." 
                                    id={`pInput-${pIndex}-${eIndex}`} 
                                    key={`pInput-${pIndex}-${eIndex}`}
                                    value={slab.min} 
                                    changeHandler={(value, id) => this.handleOnDataUpdate(value, id, pIndex, eIndex, 'min')}
                                />
                              :
                                `${slab.min || '-'}`
                            }
                            </div>)
                        })}
                        </div>
                        <div className="divTableCell">
                        {(demurrage.demurrage_charges || []).map((slab, eIndex) => {
                            return (<div className="sub-header-inline penaltyPercent" key={`max-${demurrage.demurrage_master_id}-${pIndex}-${eIndex}`}>
                            {(editId===pIndex) ?
                                <InputText type="number" placeholder="no." 
                                    id={`pInput-${pIndex}-${eIndex}`}
                                    key={`pInput-${pIndex}-${eIndex}`}
                                    value={slab.max} 
                                    changeHandler={(value, id) => this.handleOnDataUpdate(value, id, pIndex, eIndex, 'max')}
                                />
                              :
                                `${slab.max || '-'}`
                            }
                            </div>)
                        })}
                        </div>
                        <div className="divTableCell">
                        {(demurrage.demurrage_charges || []).map((slab, eIndex) => {
                            return (<div className="sub-header-inline penaltyPercent" key={`freight-${demurrage.demurrage_master_id}-${pIndex}-${eIndex}`}>
                            {(editId===pIndex) ?
                                <InputText type="number" placeholder="no." 
                                    id={`pInput-${pIndex}-${eIndex}`}
                                    key={`pInput-${pIndex}-${eIndex}`}
                                    value={slab.frieght} 
                                    changeHandler={(value, id) => this.handleOnDataUpdate(value, id, pIndex, eIndex, 'frieght')}
                                />
                              :
                                `${slab.frieght || 0}`
                            }
                            </div>)
                        })}
                        </div>
                        <div className="divTableCell"> 
                        {(editId===pIndex) 
                            ?
                            <>
                                <div className={'action-icon'} style={{backgroundImage: `url(${Save})`}} onClick={() => this.saveDemurrage(demurrage)} />
                                <div className={'action-icon'} style={{backgroundImage: `url(${Cancel})`}} onClick ={() => this.setState({editId: null})} />
                            </>
                            :
                            <div className={'action-icon'} style={{backgroundImage: `url(${Edit})`}} onClick={() => this.editDemurrage(pIndex)} />
                        }
                        </div>
                    </div>
                )
            })}
          </Fragment>
        )
    }
    
    render() {
        const { addItemPopup, typeUOM, newDemurrage, demurragesData, isAdded } = this.state;
        const { dataGovernance } = this.props;
        let allDemurrageData = demurragesData || [];

        return (
            <div className="discount-wrap demurrages">
                <div className="wrapper">
                    <MainComponent>
                        {this.props.loader.isLoading && <Loader />}
                        {this.props.msgpopup.active && <MSGPopUp close = {this.closeMsgPopup} type = {this.props.msgpopup.type} msg = {this.props.msgpopup.msg}/>}
                        <div className="sub-heading-section"> 
                            <div className="sub-heading"> Demurrages </div>
                        </div>

                        <div>
                            <div className="cta-action-btns">
                                <button className="button-classic" 
                                    // onClick={this.downloadDelayPenalty}
                                > 
                                    Download Template
                                </button>
                                <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
                                
                                <button className="button-classic" 
                                    // onClick={() => {this.uploadExcel.click()}}
                                > Upload Excel </button>
                                <input type='file' style={{ display: 'none'}}  ref={(e) => { this.uploadExcel = e ;}} onChange={this.uploadPenaltyFile} onClick={(e) => {e.target.value = null }}/>

                                <button className="button-classic" 
                                    // onClick={this.deleteDelayPenalty}
                                >
                                    Delete Record(s)
                                </button>
                            </div>
                            <div className="cta-action-btns cta-btn-right">
                                <Button value="Define Slab" click={this.showPopUp} />
                                <Button value="Advanced Filters" click={() => this.handleAdvanceFilter()} />
                                {
                                    this.state.showAdvanceFilter && <AdvanceFilter handleTagClick={this.handleTagClick} categories={this.state.categories} modes={this.state.modes} handleSubmit={this.handleFilterSubmit}/>
                                }
                            </div>

                            <div className="divTable company-list-wrap">
                                <div className="list-section table-header divTableHeading divTableRow">
                                    <div className="divTableCell select-row"> </div>
                                    <div className="divTableCell"> Category </div>
                                    <div className="divTableCell"> Vendor Name </div>
                                    <div className="divTableCell"> Vendor Code </div>
                                    <div className="divTableCell"> Mode </div>
                                    <div className="divTableCell"> Demurrage Date </div>
                                    <div className="divTableCell"> Min (Days)</div>
                                    <div className="divTableCell"> Max (Days)</div>
                                    <div className="divTableCell"> Freight (%)</div>
                                    <div className="divTableCell"> Action </div>
                                </div>
                                <div className="divTableBody">
                                    {this.renderTableBody(allDemurrageData)}
                                </div>
                            </div>
                            {addItemPopup &&
                                <AddNewDemurrage
                                    newDemurrage={newDemurrage}
                                    typeUOM={typeUOM}
                                    onChangeInput={this.onChangeInput}
                                    addNewSlabs={this.addNewDemurrage}
                                    cancelNewSlab={this.cancelNewSlab}
                                    handleSave={this.saveDemurrage}
                                    modesList={dataGovernance.modeTypes || []}
                                    vendorsList={dataGovernance.vendorTypes || []}
                                />
                            }
                        </div>
                    </MainComponent>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loader: state.loader,
        msgpopup: state.msgpopup,
        dataGovernance: state.dataGovernance,
    }
}

export default connect(mapStateToProps)(Demurrage);
