import React, { Component } from 'react';
import InputText from '../../../../common/components/input-text/input-text';
import Select from 'react-select';
import Button from '../../../../home/button-classic/button-classic';
import { cargoType, productUnit, physicalState, stackingPermitted, companyName, chainType, handlingInstruction } from '../../../../../utils/constants';
import { DEEPAK_COMPANY_ID } from '../../../../../utils/common';
import { getCookie } from "../../../../../utils/cookies.js";

class AddItem extends Component {
    constructor(props){
        super(props);
        this.state = {
            data : {
                productMasterId : this.props.data.productMasterId,
                companyType: this.props.data.companyType,
                chainType: this.props.data.chainType,
                product: this.props.data.product,
                flowPriority: this.props.data.flowPriority,
                physicalState: this.props.data.physicalState,
                insideCount: this.props.data.insideCount,
                length: this.props.data.length,
                breadth: this.props.data.breadth,
                height: this.props.data.height,
                unit: this.props.data.unit,
                volume: this.props.data.volume,
                actualWeight: this.props.data.actualWeight,
                volumentricWeight: this.props.data.volumentricWeight,
                volumetricFlag: this.props.data.volumetricFlag,
                chargedWeight: this.props.data.chargedWeight,
                mpgCode: this.props.data.mpgCode,
                materialName: this.props.data.materialName,
                transporterPacking: this.props.data.transporterPacking,
                unitPacking: this.props.data.unitPacking,
                stackingPermitted: this.props.data.stackingPermitted,
                auto_loading: this.props.data.auto_loading || false
            }
        }
    }

    handleChange = (id, selectedOption ) => {
        let { data } = this.state;
        data[id] = selectedOption.label;
        this.setState({ data });
    }

    onChangeHandler = ( value, id ) => {
        let { data } = this.state;
        data[id] = value;
        if(['length', 'breadth', 'height'].indexOf(id) > -1) {
            data['volume'] = Number(data['length']) * Number(data['breadth']) * Number(data['height']);
        }
        this.setState({ data });
    }

    onSave = () => {
        const { data } = this.state;
        this.props.toggleAdd("Edit");
        this.props.updateInventoryItem(data);
    }

    render() {
        let { data } = this.state;
        const company_id = JSON.parse(getCookie("user")).company_id;
        const { packagingDetails } = this.props;
        const packagingOptions = (packagingDetails || []).map(data => ({label: data.name , value: data.id})) || [];
        return (<div className = "add-user-wrap">
            <div onClick = {() => this.props.toggleAdd("Edit")} className = "overlay"></div>
            <div id = "modal" className = "modal-popup">
                <div className = "pop-heading">
                    Edit Item
                </div>
                <div className = "content add-item-details">
                    <div className="select-wrap">
                        <div className="label">Company</div>
                        <Select className = "item-select" 
                                value={companyName.find((val) => { return val.label == data.companyType })}
                                onChange={this.handleChange.bind(this, "companyType")}
                                options={companyName} 
                        />
                    </div>
                    <div className="select-wrap">
                        <div className="label">Storage Type</div>
                        <Select className = "item-select" 
                            value={chainType.find((val) => { return val.label == data.chainType })}
                            options={chainType} 
                            onChange={this.handleChange.bind(this, "chainType")}
                        />
                    </div>
                    <InputText label='Major Product Group' id='mpgCode' value={data.mpgCode} placeholder='Major Product Group' changeHandler = {this.onChangeHandler}/>
                    <InputText label='Material Code' id='product' value={data.product} placeholder='Material Code' changeHandler = {this.onChangeHandler}/>
                    <InputText label='Product Description' id='materialName'  value={data.materialName} placeholder='Product Description' changeHandler = {this.onChangeHandler}/>
                    <InputText label='Flow Priority' placeholder='Flow Priority' id='flowPriority' value={data.flowPriority} changeHandler = {this.onChangeHandler}/>
                    <div className="select-wrap">
                        <div className="label">Physical State</div>
                        <Select className = "item-select" 
                            value={physicalState.find((val) => { return val.value == data.physicalState }) }
                            options={physicalState} 
                            onChange={this.handleChange.bind(this, "physicalState")}
                        />
                    </div>
                    <InputText label='Inside Count' placeholder='Inside Count' id='insideCount' value={data.insideCount} changeHandler = {this.onChangeHandler}/>
                    <InputText label='Length' placeholder='Length' id='length' value={data.length} changeHandler = {this.onChangeHandler}/>
                    <InputText label='Breadth' placeholder='Breadth' id='breadth' value={data.breadth} changeHandler = {this.onChangeHandler}/>
                    <InputText label='Height' placeholder='Height' id='height' value={data.height} changeHandler = {this.onChangeHandler}/>
                    <InputText label='Volume' placeholder="Volume" id='volume' value={data.volume} changeHandler = {this.onChangeHandler}/>
                    <div className="select-wrap">
                        <div className="label">Unit</div>
                        <Select className = "item-select"
                            value={productUnit.find((val) => { return val.value == data.unit })}
                            options={productUnit} 
                            onChange={this.handleChange.bind(this, "unit")}
                        />
                    </div>
                    <InputText label="Actual Weight" id='actualWeight' value={data.actualWeight} placeholder="Actual Weight" changeHandler = {this.onChangeHandler}/>
                    <InputText label="Charged Weight" id='chargedWeight' value={data.chargedWeight} placeholder="Charged Weight" changeHandler = {this.onChangeHandler}/>
                    <div className="select-wrap">
                        <div className="label">Cargo Type</div>
                        <Select className = "item-select" 
                            value={cargoType.find((val) => { return val.label.toLowerCase() === data.volumetricFlag.toLowerCase()})}
                            options={cargoType} 
                            onChange={this.handleChange.bind(this, "volumetricFlag")}
                        />
                    </div>
                    <InputText label="Volumetric Weight" id='volumentricWeight' value={data.volumentricWeight} placeholder="Volumetric Weight" changeHandler = {this.onChangeHandler}/>
                    <div className="select-wrap">
                        <div className="label">Unit Packing</div>
                        <Select className = "item-select" 
                            value={packagingOptions.find((val) => { return val.label === data.unitPacking})}
                            options={packagingOptions} 
                            onChange={this.handleChange.bind(this, "unitPacking")}
                        />
                    </div>

                    {DEEPAK_COMPANY_ID.includes(company_id) && <div className="select-wrap">
                        <div className="label">Handling Instruction</div>
                        <Select className = "item-select" 
                            value={handlingInstruction.find((val) => { return val.label === this.state.handlingInstruction})}
                            options={handlingInstruction} 
                            onChange={this.handleChange.bind(this, "handlingInstruction")}
                        />
                    </div>}

                    <div className="select-wrap">
                        <div className="label">Transporter Packing</div>
                        <Select className = "item-select" 
                            value={packagingOptions.find((val) => { return val.label === data.transporterPacking})}
                            options={packagingOptions} 
                            onChange={this.handleChange.bind(this, "transporterPacking")}
                        />
                    </div>

                    
                    {/* <InputText label="Transporter Packing" id='transporterPacking' value={data.transporterPacking} placeholder="Transporter Packing" changeHandler = {this.onChangeHandler}/>
                    <InputText label="Unit Packing" id='unitPacking' value={data.unitPacking} placeholder="Unit Packing" changeHandler = {this.onChangeHandler}/> */}
                    <InputText label="Stacking Permitted" id='stackingPermitted' value={data.stackingPermitted} placeholder="Stacking Permitted" changeHandler = {this.onChangeHandler}/>
                    {/* <div className="select-wrap">
                        <div className="label">Stacking Permitted</div>
                        <Select className = "item-select" 
                            value={stackingPermitted.find((val) => {return val.value == data.stackingPermitted })}
                            options={stackingPermitted} 
                            onChange={this.handleChange.bind(this, "stackingPermitted")}
                        />
                    </div> */}
                    {DEEPAK_COMPANY_ID.includes(company_id) && <div className="select-wrap">
                        <div className={data.auto_loading ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick={() => this.onChangeHandler(!data.auto_loading, 'auto_loading')}>
                            {data.auto_loading && <div className="inner-circle"></div>}
                        </div>
                        <div className="title" style={{'marginLeft': '5px', 'marginTop': '2px'}}> Auto Loading</div>
                    </div>}
                </div>
                <div className="submit-button">
                    <div className="button-section"><Button value="Save" click={this.onSave}/></div>
                    <div className="button-section"><Button value="Cancel" click={() => this.props.toggleAdd("Edit")}/></div>
                </div>
            </div>
        </div>
        );
    }
}

export default AddItem;