

const initialState = {

}

const companyUserInfoScreenReducer = (state = initialState, action) => {
  const updatedState = { ...state }
  switch (action.type) {

    default:
      return state
  }
}


export default companyUserInfoScreenReducer

