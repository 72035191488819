import React, { Component } from 'react';
import "./add-subplants.less";
import InputText from "../../../../common/components/input-text/input-text";
import { legthValidation, validationCheck } from "../../../../../utils/validate"
import { AWL_COMPANY_IDS, getEmail, getUserId } from '../../../../../utils/common';
import Select from 'react-select';

const tk11Options = [
    {
        label: 'Without Vehicle Type',
        value: 1
    },
    {
        label: 'With Vehicle Type',
        value: 2
    }
]

class AddSubPlants extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subPlantName: this.props.subplantDetails ? this.props.subplantDetails.name : "",
            sapCode: this.props.subplantDetails ? this.props.subplantDetails.sap_code : "",
            planningPoint: this.props.subplantDetails ? this.props.subplantDetails.planning_point : "",
            subPlantId: (this.props.subplantDetails && this.props.subplantDetails.subplant_id) ? this.props.subplantDetails.subplant_id : "",
            is_tk11_active: (this.props.subplantDetails && this.props.subplantDetails.is_tk11_active) ? this.props.subplantDetails.is_tk11_active : false,
            tk11_structure: (this.props.subplantDetails && this.props.subplantDetails.tk11_structure) ? this.props.subplantDetails.tk11_structure : null,
            tax_applicability: (this.props.subplantDetails && this.props.subplantDetails.tax_applicability) ? this.props.subplantDetails.tax_applicability : false,
            plant_category: (this.props.subplantDetails && this.props.subplantDetails.plant_category) ? this.props.subplantDetails.plant_category : null,
        }
    }

    onChangeHandler = (value,id,isType) => {
		 const result = validationCheck(value, this.state[id], isType);
		 const errMsg = legthValidation(id, value);
        this.setState({ [id] : result, errormessage : errMsg });
    }

    addActiontoggle = () => {
        this.setState({
            addActions: !this.state.addActions
        })
    }
    addSubplant = () => {
        const {subPlantName, sapCode, planningPoint, subPlantId, is_tk11_active, tk11_structure, tax_applicability, plant_category} = this.state;
        const {company_id, branch_id,previousSubPlantData} = this.props;
        const data = {
            company_id,
            branch_id,
            name : subPlantName,
            sap_code : sapCode,
            planning_point : planningPoint,
            subplant_id: subPlantId ? subPlantId : undefined,
            is_tk11_active,
            tk11_structure,
            tax_applicability,
            plant_category,
            master_type:11,
            user_email:getEmail(),
            user_id:getUserId()
        }
        if(subPlantId){
            data.previous_data = previousSubPlantData
            data.previous_data.update_flag = true
            data.update_flag = true
            this.props.updateSubPlants(data);
        } else {
            data.new_flag = true
            this.props.addSubPlants(data);
        }
        this.props.toggleAdd();
        // console.log(data);
    }

    onSelectTK11 = (option) => {
        this.setState({
            tk11_structure: option.value
        })
    }
    onSelect = (option) => {
        this.setState({
            plant_category: option.label,
        })
    }

    render() {
        const {plantCategory} = this.props;
        const CategoryPlant = 
        (plantCategory || []).map((data) => ({
        label: data.name,
        value: data.id,
        })) || [];
        return (

            <div className="add-user-wrap">
                <div className="overlay"></div>
                <div id="modal" className="modal-popup">
                    <div className="pop-heading">
                        {
                            this.state.subPlantId ? 'Edit Sub Plant' : 'Add Sub Plants'
                        }
                    </div>
                    <div className="content">
                        <label className="error">{this.state.error}</label>
                        <InputText isValidate={'alphanumeric'} placeholder="Sub Plants Name" id={"subPlantName"} label="Sub Plant Name" value={this.state.subPlantName} changeHandler={this.onChangeHandler} />
                        <InputText isValidate={'alphanumeric'} placeholder="SAP Code" id={"sapCode"} label="SAP Code" value={this.state.sapCode} changeHandler={this.onChangeHandler} additionalProps={{ onBlur: this.onBlurChange }} iserror={this.state.iserror} errormessage={this.state.errormessage} />
                        <InputText isValidate={'alphanumeric'} placeholder="Planning Point" id={"planningPoint"} label="Planning Point" value={this.state.planningPoint} changeHandler={this.onChangeHandler} additionalProps={{ onBlur: this.onBlurChange }} iserror={this.state.iserror} errormessage={this.state.errormessage} />
                        {
                            AWL_COMPANY_IDS.includes(this.props.company_id) &&
                            <>
                                <div className="input-text-wrap"> <p className='label'>STATUS</p>
                                    <div className="switch" >
                                        <input type="checkbox" checked={this.state.is_tk11_active} id={'Status'} onChange={() => { this.onChangeHandler(!this.state.is_tk11_active, 'is_tk11_active', null) }} />
                                        <label htmlFor={'Status'}></label>
                                    </div>
                                </div>
                                <div className="input-text-wrap"> <p className='label'>Tax applicability</p>
                                    <div className="switch" >
                                        <input type="checkbox" checked={this.state.tax_applicability} id={'taxApplicability'} onChange={() => { this.onChangeHandler(!this.state.tax_applicability, 'tax_applicability', null) }} />
                                        <label htmlFor={'taxApplicability'}></label>
                                    </div>
                                </div>
                                <div className="input-text-wrap">
                                    <div className="label">
                                        TK11 Structure
                                    </div>
                                    <Select
                                        className="select service_mode"
                                        options={tk11Options}
                                        label="TK11 Structure"
                                        value={tk11Options.filter(
                                            (val) => val.value == this.state.tk11_structure
                                        )}
                                        placeholder="Select Structure"
                                        onChange={(option) => this.onSelectTK11(option)}
                                    />
                                </div>
                                <div className="select-wrap-depot">
                                    <div className="label">
                                    {"Plant Category"}
                                    </div>
                                    <Select
                                    className="select"
                                    value={CategoryPlant && CategoryPlant.find((val) => val.label == this.state.plant_category)}
                                    onChange={(option) => this.onSelect(option)}
                                    options={CategoryPlant}
                                    />
                                </div>
                            </>
                        }
                        <div className="submit-section mt-40">
                            <button className="add-button discard" onClick={this.props.toggleAdd}>Cancel</button>
                            <button className="add-button" onClick={this.addSubplant}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddSubPlants;