import React, { Component } from 'react'
import Edit from '../../../../../assets/images/edit.svg'
import Delete from '../../../../../assets/images/delete.svg'
import Button from "../../../../home/button-classic/button-classic";
import Search from '../../../../common/components/search-text/search-text'
import moment from "moment";
import AddItem from "./add-item/add-item";
import EditItem from "./edit-item/edit-item";
import { getUserCompanyId } from '../../../../../utils/common';
import Switch from "react-switch";
import InputText from '../../../../common/components/input-text/input-text';
import {ReactComponent as Download} from '../../../../../assets/images/download.svg'
import {ReactComponent as Upload} from '../../../../../assets/images/upload-blue.svg'

import "../../vehicle-driver-master.less";
import { withRouter } from 'react-router';

 class TruckMaster extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      isEdit: false,
      limit: 20,
      offset: 0,
      editedData: "",
      search: "",
      };
  }
componentDidMount() {
  this.getTruckInfo();
}
getTruckInfo = () => {
  const data = {
    offset: this.state.offset,
    limit: this.state.limit,
  };
  if (this.state.search) {
    data.search = this.state.search;
  }
  this.props.getTruckInfo(data);
};

toggleAdd = (isType, data = "") => {
  if (isType == "Edit") {
    this.setState({ isEdit: !this.state.isEdit, editedData: data });
  } else {
    this.setState({ isOpen: !this.state.isOpen });
  }
};

clearFilterOnCancel = (e, type) => {
  this.setState({ search: "" }, () => {
    this.getTruckInfo();
  });
};
changeHandler = (val, id) => {
  this.setState({
    [id]: val,
  });
};

submitSearch = () => {
  this.getTruckInfo();
};
previous = () => {
  if (this.state.offset > 0) {
    this.setState(
      {
        offset: this.state.offset - 20,
      },
      () => {
        const data = {
          limit: this.state.limit,
          offset: this.state.offset,
        };
        this.props.getTruckInfo(data);
      }
    );
  }
};

next = () => {
  this.setState(
    {
      offset: this.state.offset + 20,
    },
    () => {
      const data = {
        limit: this.state.limit,
        offset: this.state.offset,
      };
      this.props.getTruckInfo(data);
    }
  );
};
checkBlock = (blockedData) => {
  const cId = getUserCompanyId()
  let colorCode = '';
  if(blockedData && blockedData.find(bd => bd.company_id === cId) && blockedData.find(bd => bd.company_id === cId).is_blocked){
    colorCode = 'text-red item-label';
  } else {
    colorCode = 'item-label';
  }
  return colorCode;
}

   handleSwitch = (checked, val) => {
    if(checked){
      this.setState({isReasonPopup: true, selectedVehicle: val})
    } else {
      let data = {
        ...val,
        is_blocked: false
      }
      this.props.updateTruckInfo(data);
    }
   };

   onSaveForReasonPopup = () => {
     const { selectedVehicle } = this.state;
     let data = {
       ...selectedVehicle,
       block_remarks: this.state.block_remarks,
       is_blocked: true
     }
     this.props.updateTruckInfo(data);
     this.setState({ selectedVehicle: null, isReasonPopup: false, block_remarks: "" })
   }

    handleOnClickForAuditBtn = () => {
     const {history} = this.props;
     const companyId = getUserCompanyId()
     const masterType = 5
     const navUrl = `/auditScreen/${companyId}/${masterType}`;
     history.push(navUrl)
    }

render() {
  return (
  
    <div className={"Pfizer-Item"}>
      <div className="header" style={{display:'flex'}}>
        <Search
          id={"search"}
          value={this.state.search}
          placeholder={"Search"}
          changeHandler={this.changeHandler}
          click={this.submitSearch}
          canClear={this.state.search !== ""}
          handleClearFilter={(e) => this.clearFilterOnCancel(e, "search")}
        />
        <Button value={" + Add"} click={this.toggleAdd} />
        
        <button
          className="button-classic"
          onClick={this.handleOnClickForAuditBtn}
        >
          Audit
        </button>

        <div style={{ display: 'flex' }}>
          <button
            className="button-classic"
            onClick={this.props.downloadVehicleTemplate}
            style={{ alignItems: 'center', textAlign: 'center', display: 'flex' }}
          > <Download />Download Template
          </button>

          <button
              className="button-classic"
              onClick={() => { this.uploadVehicle.click() }}
              style={{ alignItems: 'center', textAlign: 'center', display: 'flex' }}
            >
              <Upload style={{ width: '15px', height: '15px', marginRight: '5px' }} />
              Upload Template
              <input type='file' style={{ display: 'none' }} ref={(e) => { this.uploadVehicle = e; }} onChange={(e) => { this.props.uploadVehicleTemplate(e) }} onClick={(e) => { e.target.value = null }} />
            </button>
        </div>

      </div>
      <div className="item-table">
        <div
          className="list-section item-header column"
          style={{ minWidth: "fit-content" }}
        >
          <div className="item-label">Sl No</div>
          <div className = "item-label ">Vehicle Reg No</div>
          <div className = "item-label">Vehicle Engine No</div>
          <div className = "item-label">Vehicle Chassis No</div>
          <div className = "item-label">Vehicle_Owner</div>
          <div className = "item-label">Vehicle Owner Contact</div>
          <div className = "item-label">Vehicle Registration Date</div>
          <div className = "item-label">Fitness Certificate Date</div>
          <div className = "item-label">Puc Validity Date</div>
          <div className = "item-label">Rc Validity Date</div>
          <div className = "item-label">Insurance Validity Date</div>
          <div className = "item-label">Vehicle Net Weight</div>
          <div className = "item-label">Vehicle Tare Weight</div>
          <div className = "item-label">Vehicle Passing Capacity</div>
          <div className = "item-label">Vehicle Service Type</div>
          <div className = "item-label">Fule Type</div>
          <div className = "item-label">Container No</div>
          <div className = "item-label">Container Weight</div>
          <div className = "item-label">Seal No</div>
          <div className = "item-label">Excise No</div>
          <div className = "item-label">Gross Weight</div>
          <div className = "item-label">Status</div>
          <div className = "item-label">Actions</div>
        </div>
        {(!this.props.truckInfo || this.props.truckInfo.length === 0) && <div className={'no_data_found'}>{'No Data Found'}</div>}
          
        {this.props.truckInfo &&
          this.props.truckInfo.map((val, index) => {
            return (
              <div
                className="list-section item-header"
                style={{ minWidth: "fit-content" }}
              >

                <div className="item-label">{index + 1}</div>
              <div className = {this.checkBlock(val.block_data)}>{val.vehicle_reg_no}</div>
                <div className="item-label">{val.vehicle_engine_no || '-'}</div>
                <div className="item-label">{val.vehicle_chassis_no || '-'}</div>
                <div className="item-label">{val.vehicle_owner || '-'}</div>
                <div className="item-label">{val.vehicle_owner_mobile || '-'}</div>
                <div className="item-label">{moment(val.vehicle_registration_date ).format("DD/MM/YYYY")|| '-'}</div>
                <div className="item-label">{moment(val.fitness_certificate_date).format("DD/MM/YYYY") || '-'}</div>
                <div className="item-label">{moment(val.puc_validity_date).format("DD/MM/YYYY") || '-'}</div>
                <div className="item-label">{moment(val.rc_validity_date).format("DD/MM/YYYY") || '-'}</div>
                <div className="item-label">{moment(val.insurance_validity_date ).format("DD/MM/YYYY")|| '-'}</div>
                <div className="item-label">{val.vehicle_net_weight || '-'}</div>
                <div className="item-label">{val.vehicle_tare_weight || '-'}</div>
                <div className="item-label">{val.vehicle_passing_capacity || '-'}</div>
                <div className="item-label">{val.vehicle_service_type || '-'}</div>
                <div className="item-label">{val.fuel_name || '-'}</div>
                <div className="item-label">{val.container_no || '-'}</div>
                <div className="item-label">{val.container_weight || '-'}</div>
                <div className="item-label">{val.seal_no || '-'}</div>
                <div className="item-label">{val.excise_sNo || '-'}</div>
                <div className="item-label">{val.gross_weight || '-'}</div>
                <div className="item-label">
                  <Switch
                    onChange={(checked) => this.handleSwitch(checked, val)}
                    onColor="#528aea"
                    checked={val.is_blocked}
                  />
                </div>
                <div className="item-label">
                  <div
                    className={"delete-icon"}
                    style={{ backgroundImage: `url(${Edit})` }}
                    onClick={() => this.toggleAdd("Edit", val)}
                  ></div>
                  {/* <div
                    className={"delete-icon"}
                    style={{ backgroundImage: `url(${Delete})` }}
                    onClick={() =>
                      this.props.deleteTruckInfo(val)
                    }
                  ></div> */}
                </div>
              </div>
            );
          })}
      </div>
      <div className="submit-section-pagination">
        <div className="next">
          <Button value="Next" click={this.next} />
        </div>
        {this.state.offset != 0 && (
          <div className="pre">
            <Button value="Previous" click={this.previous} />
          </div>
          
        )}
      </div>
      {this.state.isOpen && (
        <AddItem
          toggleAdd={this.toggleAdd}
          addTruckInfo={this.props.addTruckInfo}
          getVehicleTypes={this.props.getVehicleTypes}
          getFuleTypes={this.props.getFuleTypes}
          VehicleTypes={this.props.VehicleTypes}
          FuleTypes={this.props.FuleTypes}
        />
      )}
      {this.state.isEdit && (
        <EditItem
          toggleAdd={this.toggleAdd}
          data={this.state.editedData}
          updateTruckInfo={this.props.updateTruckInfo}
          getVehicleTypes={this.props.getVehicleTypes}
          getFuleTypes={this.props.getFuleTypes}
          VehicleTypes={this.props.VehicleTypes}
          FuleTypes={this.props.FuleTypes}
        />
      )}

      {this.state.isReasonPopup &&
        <div className="add-user-wrap">
          <div onClick={this.props.toggleAdd} className="overlay"></div>
          <div className="reason-modal-popup">
            <div style={{ margin: '10px auto' }}>
              <InputText
                placeholder={"Black listed remark "}
                label={"Black listed remark "}
                id={"block_remarks"}
                value={this.state.block_remarks}
                changeHandler={(value) => {
                  this.setState({ block_remarks: value });
                }}
              />
            </div>
            <div className="submit-button" style={{ margin: '20px auto' }}>
              <div className="button-section">
                <Button
                  value="Save"
                  click={this.onSaveForReasonPopup} 
                />
              </div>
              <div className="button-section">
                <Button
                  value="Cancel"
                  click={() => this.setState({ selectedVehicle: null, isReasonPopup: false, block_remarks: "" })}
                />
              </div>
            </div>
          </div>
        </div>
      }

    </div>
  );
}
}
export default withRouter(TruckMaster);