import React, { Component } from 'react';
import InputText from '../../../../common/components/input-text/input-text';
import Select from 'react-select';
import Button from '../../../../home/button-classic/button-classic';
import { cargoType, productUnit, physicalState, stackingPermitted, companyName, chainType, handlingInstruction } from '../../../../../utils/constants';
import { getCookie } from "../../../../../utils/cookies.js";
import { DEEPAK_COMPANY_ID, CARGILL_COMPANY_IDS } from '../../../../../utils/common';

class AddItem extends Component {
    constructor(props){
        super(props);
        this.state = {
            companyType: '',
            chainType: '',
            product: '',
            flowPriority: '',
            physicalState: '',
            insideCount: '',
            length: '',
            breadth: '',
            height: '',
            unit: '',
            volume: '',
            actualWeight: '',
            volumentricWeight: '',
            volumetricFlag: '',
            chargedWeight: '',
            mpgCode: '',
            materialName: '',
            transporterPacking: '',
            unitPacking: '',
            stackingPermitted: '',
            company_id:JSON.parse(getCookie("user")).company_id,
            handlingInstruction: '',
            auto_loading: false
        }
    }

    handleChange = (id, selectedOption ) => {
        this.setState({ [id] : selectedOption.label });
    }

    onChangeHandler = ( value, id ) => {
        const data = this.state;
        data[id] = value;
        if(['length', 'breadth', 'height'].indexOf(id) > -1) {
            data['volume'] = Number(data['length']) * Number(data['breadth']) * Number(data['height']);
        }
        this.setState({ [id] : value, volume : data.volume });
    }

    onSave = () => {
        const data = this.state;
        this.props.toggleAdd();
        this.props.addInventoryItem(data);
    }

    render() {
        const { company_id } = this.state
        const { packagingDetails } = this.props;
        const packagingOptions = (packagingDetails || []).map(data => ({label: data.name , value: data.id})) || [];
        const isCargill = CARGILL_COMPANY_IDS.includes(company_id);
        const companyOptions = isCargill ? [{ label: 'Cargill', value: 6}] : companyName;
        return (<div className = "add-user-wrap">
            <div onClick = {this.props.toggleAdd} className = "overlay"></div>
            <div id = "modal" className = "modal-popup">
                <div className = "pop-heading">
                    Add Item
                </div>
                <div className = "content add-item-details">
                    <div className="select-wrap">
                        <div className="label">Company</div>
                        <Select className = "item-select" 
                                value={companyOptions.find((val) => { return val.value == this.state.companyType })}
                                onChange={this.handleChange.bind(this, "companyType")}
                                options={companyOptions} 
                        />
                    </div>
                    <div className="select-wrap">
                        <div className="label">Storage Type</div>
                        <Select className = "item-select" 
                            value={chainType.find((val) => { return val.value == this.state.chainType })}
                            options={chainType} 
                            onChange={this.handleChange.bind(this, "chainType")}
                        />
                    </div>
                    <InputText label='Major Product Group' id='mpgCode' value={this.state.mpgCode} placeholder='Major Product Group' changeHandler = {this.onChangeHandler}/>
                    <InputText label='Material Code' id='product' value={this.state.product} placeholder='Material Code' changeHandler = {this.onChangeHandler}/>
                    <InputText label='Product Description' id='materialName'  value={this.state.materialName} placeholder='Product Description' changeHandler = {this.onChangeHandler}/>
                    <InputText label='Flow Priority' placeholder='Flow Priority' id='flowPriority' value={this.state.flowPriority} changeHandler = {this.onChangeHandler}/>
                    <div className="select-wrap">
                        <div className="label">Physical State</div>
                        <Select className = "item-select" 
                            value={physicalState.find((val) => { return val.value == this.state.physicalState }) }
                            options={physicalState} 
                            onChange={this.handleChange.bind(this, "physicalState")}
                        />
                    </div>
                    <InputText label='Inside Count' placeholder='Inside Count' id='insideCount' value={this.state.insideCount} changeHandler = {this.onChangeHandler}/>
                    <InputText label='Length' placeholder='Length' id='length' value={this.state.length} changeHandler = {this.onChangeHandler}/>
                    <InputText label='Breadth' placeholder='Breadth' id='breadth' value={this.state.breadth} changeHandler = {this.onChangeHandler}/>
                    <InputText label='Height' placeholder='Height' id='height' value={this.state.height} changeHandler = {this.onChangeHandler}/>
                    <InputText label='Volume' placeholder="Volume" id='volume' value={this.state.volume} changeHandler = {this.onChangeHandler}/>
                    <div className="select-wrap">
                        <div className="label">Unit</div>
                        <Select className = "item-select"
                            value={productUnit.find((val) => { return val.value == this.state.unit })}
                            options={productUnit} 
                            onChange={this.handleChange.bind(this, "unit")}
                        />
                    </div>
                    <InputText label="Actual Weight" id='actualWeight' value={this.state.actualWeight} placeholder="Actual Weight" changeHandler = {this.onChangeHandler}/>
                    <InputText label="Charged Weight" id='chargedWeight' value={this.state.chargedWeight} placeholder="Charged Weight" changeHandler = {this.onChangeHandler}/>
                    <div className="select-wrap">
                        <div className="label">Cargo Type</div>
                        <Select className = "item-select" 
                            value={cargoType.find((val) => { return val.label === this.state.volumetricFlag})}
                            options={cargoType} 
                            onChange={this.handleChange.bind(this, "volumetricFlag")}
                        />
                    </div>
                    <InputText label="Volumetric Weight" id='volumentricWeight' value={this.state.volumentricWeight} placeholder="Volumetric Weight" changeHandler = {this.onChangeHandler}/>
                    <div className="select-wrap">
                        <div className="label">Unit Packing</div>
                        <Select className = "item-select" 
                            value={packagingOptions.find((val) => { return val.value === this.state.unitPacking})}
                            options={packagingOptions} 
                            onChange={this.handleChange.bind(this, "unitPacking")}
                        />
                    </div>
                    {DEEPAK_COMPANY_ID.includes(company_id) && <div className="select-wrap">
                        <div className="label">Handling Instruction</div>
                        <Select className = "item-select" 
                            value={handlingInstruction.find((val) => { return val.label === this.state.handlingInstruction})}
                            options={handlingInstruction} 
                            onChange={this.handleChange.bind(this, "handlingInstruction")}
                        />
                    </div>}
                   
                    <div className="select-wrap">
                        <div className="label">Transporter Packing</div>
                        <Select className = "item-select" 
                            value={packagingOptions.find((val) => { return val.label === this.state.transporterPacking})}
                            options={packagingOptions} 
                            onChange={this.handleChange.bind(this, "transporterPacking")}
                        />
                    </div>
                    {/* <InputText label="Transporter Packing" id='transporterPacking' value={this.state.transporterPacking} placeholder="Transporter Packing" changeHandler = {this.onChangeHandler}/>
                    <InputText label="Unit Packing" id='unitPacking' value={this.state.unitPacking} placeholder="Unit Packing" changeHandler = {this.onChangeHandler}/> */}
                    <InputText label="Stacking Permitted" id='stackingPermitted' value={this.state.stackingPermitted} placeholder="Stacking Permitted" changeHandler = {this.onChangeHandler}/>
                    {/* <div className="select-wrap">
                        <div className="label">Stacking Permitted</div>
                        <Select className = "item-select" 
                            value={stackingPermitted.find((val) => {return val.value == this.state.stackingPermitted })}
                            options={stackingPermitted} 
                            onChange={this.handleChange.bind(this, "stackingPermitted")}
                        />
                    </div> */}
                    {DEEPAK_COMPANY_ID.includes(company_id) && <div className="select-wrap">
                        <div className={this.state.auto_loading ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick={() => this.onChangeHandler(!this.state.auto_loading, 'auto_loading')}>
                            {this.state.auto_loading && <div className="inner-circle"></div>}
                        </div>
                        <div className="title" style={{'marginLeft': '5px', 'marginTop': '2px'}}> Auto Loading</div>
                    </div>}
                </div>
                <div className="submit-button">
                    <div className="button-section"><Button value="Save"  click={this.onSave}/></div>
                    <div className="button-section"><Button value="Cancel" click={this.props.toggleAdd}/></div>
                </div>
            </div>
        </div>
        );
    }
}

export default AddItem;