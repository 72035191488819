export var getItemFromSessionStorage = function getItemFromSessionStorage(key) {
  if (sessionStorage.getItem(key)) {
    return JSON.parse(sessionStorage.getItem(key));
  } else {
    return undefined;
  }
};
export var setItemToSessionStorage = function setItemToSessionStorage(key, value) {
  if (value != null && value != undefined) {
    return sessionStorage.setItem(key, JSON.stringify(value));
  }
};
export var removeItemToSessionStorage = function removeItemToSessionStorage(key) {
  return sessionStorage.removeItem(key);
};