import React, { Component } from "react";
import InputText from "../../../../../common/components/input-text/input-text";
import Button from "../../../../../home/button-classic/button-classic";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { connect } from "react-redux";
import ACTION from "../../../../../common/action_constant";
import { getCookie } from "../../../../../../utils/cookies.js";
import Switch from "react-switch";
import { getUserCompanyId,AWL_COMPANY_IDS } from "../../../../../../utils/common";
import { validationCheck } from "../../../../../../utils/validate";
import { setItemToSessionStorage } from "../../../../../../utils/sessionStorage.js";
class AddItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
      company_id: JSON.parse(getCookie("user")).company_id,
        user_id: this.props.data.user_id,
        driver_id: this.props.data.driver_id,
        user_name:this.props.data.user_name,
        driver_name: this.props.data.driver_name,
        cell_no: this.props.data.cell_no,
        dl_no: this.props.data.dl_no,
        driver_acc_no: this.props.data.driver_acc_no,
        driver_ifsc_code: this.props.data.driver_ifsc_code,
        driver_alternative_location_id: this.props.data.driver_alternative_location_id,
        preferredroute: this.props.data.preferredroute || [],
        // user_name: this.props.data.user_name,
        driver_detail_validity_date: this.props.data.driver_detail_validity_date,
        block_data:this.props.data.block_data || [],
        dlName:this.props.data.dlName,
        is_blocked: this.props.data.is_blocked || false,
        block_remarks: this.props.data.block_remarks || '',
        blocked_by: JSON.parse(getCookie("user")).user_name,
      },
      blockedUser: false
    };
  }

  componentDidMount() {
    // this.props.getCompanyTransporters();
    setItemToSessionStorage('driverIn', this.props.data);
  }

  handleChange = (id, selectedOption) => {
    let { data } = this.state;
    data[id] = selectedOption.label;
    this.setState({ data });
  };

  onChangeHandler = (value, id, isType) => {
    let { data } = this.state;
    if (id === 'driver_ifsc_code' || id === 'dl_no'){
      value = value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g,'');
		}
    const result = validationCheck(value, this.state.data[id], isType);
      data[id] = result;
   this.setState({ data });
  };
  
  SelectDate = (id, selectedOption) => {
    let { data } = this.state;
    data[id] =  selectedOption.valueOf();
    this.setState({ data });
  };
  onSave = () => {
    const { data } = this.state;
    if (this.validateCheck()) {
      data.driver_detail_validity_date = data.driver_detail_validity_date ? data.driver_detail_validity_date.valueOf() : undefined;
      this.props.toggleAdd("Edit");
      this.props.updateDriverInfo(data);

    } else {
      this.setState({ errorMsg: "Please add all mandatory fields" });
    }
  };
  validateCheck = () => {
    const { data } = this.state;
    if (data.driver_name && data.cell_no && data.dl_no && data.driver_detail_validity_date) {
      return true;
    } else return false;
  };
  addLocation = () => {
    let { data } = this.state;
    data.preferredroute.push({ source: "", destination: "" });
    this.setState({ data });
  };
  deleteLocation = (index) => {
    const { data } = this.state;
    data.preferredroute.splice(index, 1);
    this.setState({ data });
  };

  uploadfiles = (e, name) => {
    const { dispatch } = this.props;
    const { driverinfo } = this.state.data;
    console.log("edit data",driverinfo)
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    dispatch({
      type: ACTION.DATA_GOVERNANCE.UPLOAD_VEHICLE_FIELS,
      formData,
      onSuccess: (details) => {
        const { data } = this.state;
        data[name] = details;
        this.setState({ data });
      },
    });
  };
  onUpload = () => {
    this.uploadUrl.click();
  };
  handleSwitch = (checked) => {
    let {data}=this.state;
    data.is_blocked=checked;
    data.block_remarks='';
    this.setState( { data, blockedUser: true } );
  };
  
  render() {
    let block=this.state.data.is_blocked;
    let { data, blockedUser } = this.state;
    let {block_data}=this.state.data;
let {details}=this.state.data
console.log(data.driver_license_image)
    let isBlocked = block_data.filter(block => block.company_id === getUserCompanyId() && block.is_blocked === true)
    if(isBlocked && isBlocked.length > 0 && !blockedUser){
      data.is_blocked = isBlocked[0].is_blocked;
      data.block_remarks = isBlocked[0].block_remarks;
    }
    return (
      <div className="add-user-wrap">
        <div onClick={this.props.toggleAdd} className="overlay"></div>
        <div id="modal" className="modal-popup">
          <div className="pop-heading">Edit Driver Info</div>
          <div className="content add-item-details">
            <div className="select-wrap">
              <InputText
                label="driver_name"
                id="driver_name"
                value={data.driver_name.toUpperCase()}
                placeholder="Driver Name"
                changeHandler={this.onChangeHandler}
                length={"45"}
                required
              />
            </div>

            <div className="select-wrap">
              <InputText
                label="Driver Contact"
                id="cell_no"
                value={data.cell_no}
                placeholder="Driver Contact"
                changeHandler={this.onChangeHandler}
                length={"10"}
                isValidate={"numeric"}
                required
              />
            </div>
            <div className="select-wrap">
            <InputText
              label="Driver Location"
              id="driver_alternative_location_id"
              value={data.driver_alternative_location_id}
              placeholder="Driver Location"
              changeHandler={this.onChangeHandler}
              length={"45"}
            />
          </div>
            <div className="select-wrap">
              <InputText
                label="Driver A/C No"
                id="driver_acc_no"
                value={data.driver_acc_no}
                placeholder="Driver A/C No"
                changeHandler={this.onChangeHandler}
                length={"17"}
              />
            </div>

            <div className="select-wrap">
              <InputText
                label="IFSC"
                id="driver_ifsc_code"
                value={data.driver_ifsc_code}
                placeholder="IFSC"
                changeHandler={this.onChangeHandler}
                length={"11"}
              />
            </div>
            <InputText
              label="DL No"
              placeholder="DL No"
              id="dl_no"
              value={data.dl_no}
              changeHandler={this.onChangeHandler}
              length={"16"}
              required
            />
           

            <div style={{display:"flex"}}>
            <div className="select-wrap">
            <div className="data-picker">
              <div className="label">Driver Dl Validity Date{" "}
                <span className="error-color">*</span>
              </div>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                // value={data.driver_detail_validity_date}
                value={data.driver_detail_validity_date ? moment(data.driver_detail_validity_date).format('DD/MM/yyyy') : null}
                onChange={this.SelectDate.bind(
                  this,
                  "driver_detail_validity_date"
                )}
                placeholderText={"DD/MM/YYYY"}
                minDate={new Date()}
                required
              />
            </div>
          </div>
          <div className="select-wrap" style={{ marginTop: "25px" }}>
          <span
            style={{
              fontSize: "13px",
              color: "#868e96",
              marginBottom: "10px",
              marginRight: "5%",
            }}
          >
            DL Image
          </span>
          <Button value={"Upload DL"} click={this.onUpload} />
          <input
            type="file"
            style={{ display: "none" }}
            ref={(e) => {
              this.uploadUrl = e;
            }}
            accept={'.jpeg, .jpg, .png'}
            onChange={(e) => {
              this.uploadfiles(e, "dlName");
            }}
            onClick={(e) => {
              e.target.value = null;
            }}
          />
          <div>
            {data.dlName && data.dlName.name ? (
              <a
                href={data.dlName.url}
                download
                className={"theme-color fs-12 mt-5"}
              >
                {data.dlName.name}
              </a>
            ) : null}
          </div>
        </div>
            </div>
            <div
            className="fBold fs-16 mt-10 mb-10 header-medium-color col-2 flex-1 "
            style={{  color: "#868e96" }}
           >
           {AWL_COMPANY_IDS.includes(data.company_id) ? <span> Driver Block</span>:
            block ? "Black listed" : "White listed" }
            <div className="mt-10">
              <Switch
                onChange={this.handleSwitch}
                onColor="#528aea"
                checked={data.is_blocked}
              />
            </div>
           </div>
           
           {data.is_blocked ? (
                  <div className="flex col-1">
                    <InputText
                      placeholder={AWL_COMPANY_IDS.includes(data.company_id) ? "Block remark":"Black listed remark "  }
                      label={AWL_COMPANY_IDS.includes(data.company_id) ? "Block remark":"Black listed remark "  }
                      id={"block_remarks"}
                      value={data.block_remarks}
                      changeHandler={(value) => {
                        let { data } = this.state;
                        data.block_remarks=value;
                        this.setState({ data });
                      }}
                    />
                  </div>
           ) : null}
            <div
              className="label"
              style={{ fontSize: "20px", display: "inline-block" }}
            >
              Add Location
            </div>
            {
              <div className="button-add" onClick={this.addLocation}>
                ADD
              </div>
            }
            {data.preferredroute.map((value, index) => {
              return (
                <div className="details flex space-between" key={index}>
                  <div className="fiscal-section" style={{ width: "24%" }}>
                    <InputText
                      placeholder="Source"
                      id={"source"}
                      label="Source"
                      value={value.source}
                      changeHandler={(value) => {
                        let { preferredroute } = this.state.data;
                        preferredroute[index].source = value;
                        this.setState({ preferredroute });
                      }}
                    />
                  </div>
                  <div className="fiscal-section" style={{ width: "24%" }}>
                    <InputText
                      placeholder="Destinations"
                      id={"destination"}
                      label="Destination"
                      value={value.destination}
                      changeHandler={(value) => {
                        let { preferredroute } = this.state.data;
                        preferredroute[index].destination = value;
                        this.setState({ preferredroute });
                      }}
                    />
                  </div>

                  <div
                    className="button-add"
                    style={{ marginTop: "30px" }}
                    onClick={() => this.deleteLocation(index)}
                  >
                    X
                  </div>
                </div>
              );
            })}
          </div>
          <div className="submit-button">
            {this.state.errorMsg && (
              <div className="error-color">{this.state.errorMsg}</div>
            )}
            <div className="button-section">
              <Button value="Save" click={this.onSave} />
            </div>
            <div className="button-section">
              <Button
                value="Cancel"
                click={() => this.props.toggleAdd("Edit")}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(AddItem);
