import React, { Component } from 'react';
import Button from "../../../button-classic/button-classic";
import Search from '../../../../common/components/search-text/search-text';
import AddAutomove from "./add_automove";


class AutoMove extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
            search: ''
        }
    }

    componentDidMount() {
        const {company_id} = this.props;
        const data = {
            companyId: company_id
        }
        this.props.getMoves(data);
        this.props.getAutomoveDetails();
    }

    addAutomove = (editData = '') => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen, editData });
    }

    render() {
        const { automoveDetails, indentingMoves } = this.props;
        return(
            <div className="plants-depots-main-wrap">
                <div className="search-holder">
                <div className="add-plants-section flex">
                    <Button value="+ Automove" click={() => this.addAutomove('')} />
                    {(automoveDetails[0] || []).is_auto_moves ? 
                      <div className='success m-10'>{'Active'}</div> : <div className="error m-10">{'Inactive'}</div>}
                </div>
                </div>
                <div className="heading">
                    <div className="depot-users-list depot-users-list-first" >
                        <div className="halflist">
                            <div className="head usn first">Move Name</div>
                            <div className="head usn second">Move ID</div>
                        </div>
                    </div>
                    <div className="depot-users-list depot-users-list-second" >
                        <div className="halflist">
                            <div className="head usn first">Move Name</div>
                            <div className="head usn second">Move ID</div>
                        </div>
                    </div>
                </div>
                {((automoveDetails[0] || []).auto_moves_id || []).map((moves, index) => {
                    if (index % 2 == 0) {
                        return (
                            <div className="depot-users-list depot-users-list-first"key={`moves-${index}`} >
                                <div className="halflist">
                                    <div className="head usn first">{moves.name}</div>
                                    <div className="head usn second">{moves.move_id}</div>
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div className="depot-users-list depot-users-list-second" key={`moves-${index}`}>
                                <div className="halflist">
                                    <div className="head usn first">{moves.name}</div>
                                    <div className="head usn second">{moves.move_id}</div>
                                </div>
                            </div>
                        )
                    }
                })}

                {(!automoveDetails || automoveDetails.length === 0) && <div className={'no_data_found'}>{'No Data Found'}</div>}

                {this.state.isOpen && <AddAutomove toggleAdd={this.addAutomove} addAutomoveDetails={this.props.addAutomoveDetails} data={this.props.automoveDetails} moves={indentingMoves}/>}
        </div>
        )
    }
}

export default AutoMove;