import React, { Component } from 'react';
import Dropup from '../../../assets/images/drop-up-arrow.svg';
import Button from '../../home/button-classic/button-classic';
import Delete from '../../../assets/images/delete.svg';
import AddEvent from './add-event/add-event';
import './accountmaster.less';
import { connect } from "react-redux";
import ACTION from "../../common/action_constant";
import Moment from 'moment';
import Loader from '../../common/components/loader/loader';
import MSGPopUp from '../../common/components/msg-popup/msg-popup';
import { toPascalCase } from "../../../utils/validate";



class AccountMaster extends Component {
	constructor() {
		super();
		this.state = {
			checkDropDown: [false, false, false, false, false, false, false],
			non_Participation_RegVendor: [{
				event_id: '',
				event: '',
				creadtedOn: ''
			}],
			isOpen: false,
			eventType: null
		}

	}

	getAccountEvents = (id) => {
		const { dispatch } = this.props;
		dispatch({ type: ACTION.MASTER.ACCOUNTS.GET_ACCOUNT_DETAILS, data: id });
	}

	toggleCheck = (id) => {
		let { checkDropDown } = this.state;
		checkDropDown[id] = !checkDropDown[id];
		this.setState({ checkDropDown }, () => {
			if (checkDropDown[id]) {
				this.getAccountEvents(id);
			}
		});
	}

	deleteAccountEvent = (type, event_id) => {
		let data = {
			type,
			event_id
		}
		const { dispatch } = this.props;
		dispatch({ type: ACTION.MASTER.ACCOUNTS.DELETE_ACCOUNT_DETAILS, data });
	}

	onClickEvent = (type) => {
		this.setState({ isOpen: !this.state.isOpen, eventType: type });
	}

	onCloseEvent = () => {
		this.setState({ isOpen: !this.state.isOpen });
	}

	onAddEvent = (data) => {
		this.onCloseEvent();
		const { dispatch } = this.props;
		dispatch({ type: ACTION.MASTER.ACCOUNTS.ADD_ACCOUNT_DETAILS, data });
	}

	getHeader = () => {
		return (
			<div className={'list-section'}>
				<div className={'table-header event-id'}>Event Id</div>
				<div className={'table-header event'}>Event</div>
				<div className={'table-header event-id'}>Created On</div>
				<div className={'table-header action'}>Action</div>
			</div>
		)
	}

	getItemDetails = (value, index, type) => {
		return (
			<div className={'list-section'} key={index}>
				<div className={'table-row event-id'}>{value.event_id}</div>
				<div className={'table-row event'}>{toPascalCase(value.event_name)}</div>
				<div className={'table-header event-id'}>{Moment(value.created_date).format('DD MMM, YYYY')}</div>
				<div className={'table-row delete-icon action'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.deleteAccountEvent(type, value.event_id)}></div>
			</div>
		)
	}

	closeMsgPopup = () => {
		const { dispatch } = this.props;
		dispatch({ type: ACTION.MSG_DISPLAY.HIDE_MSG })
	}

	render() {
		const { eventDetails } = this.props;

		return (
			<div className={'account-master'}>
				{
					this.props.loader.isLoading && <Loader />
				}
				{
					this.props.msgpopup.active && <MSGPopUp close={this.closeMsgPopup} type={this.props.msgpopup.type} msg={this.props.msgpopup.msg} />
				}
				<div className={'header'}>For Non Participation In Contracting Process
                    <div className={this.state.checkDropDown[1] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(1)}></div>
				</div>
				<div className={`drop-section ${this.state.checkDropDown[1] ? "drop-up" : "drop-close"}`}>
					<div className={''}>
						{this.getHeader()}
						{eventDetails.type1 && eventDetails.type1.map((val, index) => {
							return (this.getItemDetails(val, index, 1))
						})}
						<div className={'approval-btn'}>
							<Button value={'Add'} click={(e) => this.onClickEvent(1)}></Button>
						</div>
					</div>
				</div>

				<div className={'header'}>For Non Participation In Indenting Process
                    <div className={this.state.checkDropDown[2] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(2)}></div>
				</div>
				<div className={`drop-section ${this.state.checkDropDown[2] ? "drop-up" : "drop-close"}`}>
					<div className={''}>
						{this.getHeader()}
						{eventDetails.type2 && eventDetails.type2.map((val, index) => {
							return (this.getItemDetails(val, index, 2))
						})}
						<div className={'approval-btn'}>
							<Button value={'Add'} click={() => this.onClickEvent(2)}></Button>
						</div>
					</div>
				</div>

				<div className={'header'}>For Failure In Placement / Outbound Operation
                    <div className={this.state.checkDropDown[3] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(3)}></div>
				</div>
				<div className={`drop-section ${this.state.checkDropDown[3] ? "drop-up" : "drop-close"}`}>
					<div className={''}>
						{this.getHeader()}
						{eventDetails.type3 && eventDetails.type3.map((val, index) => {
							return (this.getItemDetails(val, index, 3))
						})}
						<div className={'approval-btn'}>
							<Button value={'Add'} click={() => this.onClickEvent(3)}></Button>
						</div>
					</div>
				</div>


				<div className={'header'}>For Wrong Input of Placement / Outbund Operation
                    <div className={this.state.checkDropDown[4] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(4)}></div>
				</div>
				<div className={`drop-section ${this.state.checkDropDown[4] ? "drop-up" : "drop-close"}`}>
					<div className={''}>
						{this.getHeader()}
						{eventDetails.type4 && eventDetails.type4.map((val, index) => {
							return (this.getItemDetails(val, index, 4))
						})}
						<div className={'approval-btn'}>
							<Button value={'Add'} click={() => this.onClickEvent(4)}></Button>
						</div>
					</div>
				</div>

				<div className={'header'}>Approved Unit Penalty Policy For Mismatch On Indented Service Check List
                    <div className={this.state.checkDropDown[7] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(7)}></div>
				</div>
				<div className={`drop-section ${this.state.checkDropDown[7] ? "drop-up" : "drop-close"}`}>
					<div className={''}>
						{this.getHeader()}
						{eventDetails.type7 && eventDetails.type7.map((val, index) => {
							return (this.getItemDetails(val, index, 7))
						})}
						<div className={'approval-btn'}>
							<Button value={'Add'} click={() => this.onClickEvent(7)}></Button>
						</div>
					</div>
				</div>

				<div className={'header'}>Bill Charges
                    <div className={this.state.checkDropDown[5] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(5)}></div>
				</div>
				<div className={`drop-section ${this.state.checkDropDown[5] ? "drop-up" : "drop-close"}`}>
					<div className={''}>
						{this.getHeader()}
						{eventDetails.type5 && eventDetails.type5.map((val, index) => {
							return (this.getItemDetails(val, index, 5))
						})}
						<div className={'approval-btn'}>
							<Button value={'Add'} click={() => this.onClickEvent(5)}></Button>
						</div>
					</div>
				</div>

				<div className={'header'}>Other Charges
                    <div className={this.state.checkDropDown[6] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(6)}></div>
				</div>
				<div className={`drop-section ${this.state.checkDropDown[6] ? "drop-up" : "drop-close"}`}>
					<div className={''}>
						{this.getHeader()}
						{eventDetails.type6 && eventDetails.type6.map((val, index) => {
							return (this.getItemDetails(val, index, 6))
						})}
						<div className={'approval-btn'}>
							<Button value={'Add'} click={() => this.onClickEvent(6)}></Button>
						</div>
					</div>
				</div>

				{this.state.isOpen && <AddEvent close={this.onCloseEvent} toggleAdd={this.onCloseEvent} onAddEvent={this.onAddEvent} isType={this.state.eventType} />}
			</div>
		)
	}
}


const mapStateToProps = state => {
	return {
		eventDetails: state.master,
		loader: state.loader,
		msgpopup: state.msgpopup
	};
};


export default connect(mapStateToProps)(AccountMaster);

