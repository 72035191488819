import React, { Component } from "react";
import "./awbthreshold.less";
import ACTIONS from "../../../common/action_constant";
import { updateThreshold } from "../../home.saga";
import InputText from "../../../common/components/input-text/input-text";
import { getUserCompanyId } from "../../../../utils/common";
import { getCookie } from "../../../../utils/cookies";

class AwbThreshholdPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company_id: this.props.companyId,
      lsp_name: this.props.lsp,
      inbound_threshold: this.props.selectedLSPInbound,
      outbound_threshold: this.props.selectedLSPOutbound,
      datas: "",
      lsp_id:this.props.selectedLSPId,
      email:this.props.selectedLSPEmail
    };
  }

  handleInboundChange = (e) => {
    this.setState({ inbound_threshold: e.target.value });
  };

  handleOutboundChange = (e) => {
    this.setState({ outbound_threshold: e.target.value });
  };

  updateThreshold = () => {
    const { dispatch,companyId } = this.props;
    // const companyId = getUserCompanyId();
    const { email } = this.state;

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || !emailPattern.test(email)) {
    
    alert("Please enter a valid email address.");
    return;
    }

    dispatch({
      type: ACTIONS.HOME.AWB_THRESHOLD_UPDATES,
      data: {
        company_id: companyId,
        data: [
          {
            lsp_name: this.state.lsp_name,
            inbound_threshold: parseInt(this.state.inbound_threshold||0),
            outbound_threshold: parseInt(this.state.outbound_threshold||0),
            primary_consignee_email:email
          },
        ],
      },
      onSuccess: (data) => {
        this.setState({ datas: data });
        this.props.getData()
        this.props.modalOpen()
      },
    });
    // this.props.modalOpen()
    // this.props.getData()
  };

  handleChangeEmail = (e) => {
    this.setState({email: e.target.value})
  }

  render() {
    // console.log("threshold state",this.state)
    return (
      <div className="vendor_mapping_detail-popUp">
        <div className="overlay-popUp"></div>
        <div id="modal" className="modal-popup-popUp">
          <div className="pop-heading-popUp">
            <svg
              onClick={this.props.modalOpen}
              style={{ cursor: "pointer" }}
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
              fill="#528aea"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="#528aea"
                clip-rule="evenodd"
                d="M11.4939 20.5644C11.1821 20.8372 10.7083 20.8056 10.4356 20.4939L3.43557 12.4939C3.18814 12.2111 3.18814 11.7889 3.43557 11.5061L10.4356 3.50613C10.7083 3.1944 11.1822 3.16281 11.4939 3.43557C11.8056 3.70834 11.8372 4.18216 11.5644 4.49388L5.65283 11.25L20 11.25C20.4142 11.25 20.75 11.5858 20.75 12C20.75 12.4142 20.4142 12.75 20 12.75L5.65283 12.75L11.5644 19.5061C11.8372 19.8179 11.8056 20.2917 11.4939 20.5644Z"
                fill="#030D45"
              />
            </svg>
            <div
              style={{
                marginLeft: "5px",
                marginRight: "10px",
                color: "#528aea",
              }}
            >
              Back
            </div>
            <div style={{ marginLeft: "50px" }}>Change Threshhold</div>
          </div>
          <div className="vendor-detail-popUp">
            <div className="table">
              <table className="table-view">
                <thead>
                  <tr className="table-tr">
                    <th
                      className="tCenter"
                      style={{ borderTopLeftRadius: "8px" }}
                    >
                      LSP Name
                    </th>
                    <th className="tCenter">Inbound Threshhold %</th>
                    <th
                      className="tCenter"
                    >
                      Outbound Threshhold %
                    </th>
                    <th
                      className="tCenter"
                      style={{ borderTopRightRadius: "8px" }}
                    >
                      Communication Email
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* {this.props.perData.tableSummaryData1.map((aa) => {
                    return (
                      <tr>
                        <td>{this.state.lsp}</td>
                        <td>
                          <input onChange={this.handleInboundChange} />
                        </td>
                        <td>
                          <input onChange={this.handleOutboundChange} />
                        </td>
                      </tr>
                    );
                  })} */}
                </tbody>
                <tr>
                  <td>{this.state.lsp_name}</td>
                  <td>
                    <input onChange={this.handleInboundChange} value={this.state.inbound_threshold}/>
                  </td>
                  <td>
                    <input onChange={this.handleOutboundChange} value={this.state.outbound_threshold}/>
                  </td>
                  <td>
                    <input type='email' onChange={this.handleChangeEmail} value={this.state.email}/>
                  </td>
                </tr>
              </table>
            </div>
            <div className="button_route">
              <div className="cancel-button" onClick={this.props.modalOpen}>
                Cancel
              </div>
              <div className="save-button" onClick={this.updateThreshold}>
                Save
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AwbThreshholdPopUp;
