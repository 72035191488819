import React, { Component } from 'react';
import InputText from '../../../../common/components/input-text/input-text';
import { decimalNumber } from '../../../../../utils/validate';
import Button from '../../../button-classic/button-classic';

class EditBillNumber extends Component {
    constructor(props){
        super(props);
        this.state = {
            bill_type : this.props.data.bill_type,
            transporter_name: this.props.data.transporter_name,
            bill_range_from: this.props.data.bill_range_from,
            bill_range_to: this.props.data.bill_range_to,
            selectBillNumber: {
                AutoGenerated: false,
                Manual: false
            }
        }
    }

    updateBillNumber = () => {
        let data = {
            bill_type : this.state.bill_type,
            transporter_name: this.state.transporter_name,
            bill_range_from: this.state.bill_range_from,
            bill_range_to: this.state.bill_range_to,
            load_type: this.props.data.load_type,
            mode_type: this.props.data.mode_type
        }
        this.props.updateBillNumber(data);
        this.props.toggleEdit();
    }

    onChangeHandler = (value, id) => {
        this.setState({ [id] : value });
    }
   onAmountChangeHandler = (value, id) => {
      if (decimalNumber(value) || value == '') {
         this.setState({ [id]: value });
      }
   }

    clickCustomeCheckBoxService = (id) => {
        let { selectBillNumber, bill_type } = this.state;
        for(var i in selectBillNumber) {
            if(i == id ) {
                selectBillNumber[i] = !selectBillNumber[i];
                bill_type = selectBillNumber[i] ? i : ''
            } else {
                selectBillNumber[i] = false;
            } 
        }
        this.setState({ selectBillNumber , bill_type });
    }

   validData = ({ transporter_name, bill_range_from, bill_range_to,selectBillNumber } = this.state) => {
      return ((selectBillNumber.AutoGenerated ? transporter_name : selectBillNumber.Manual ? transporter_name && (bill_range_from && bill_range_to) : null));
    }
    

    render() {
        let { selectBillNumber, bill_type } = this.state;
        for(let i in selectBillNumber) {
            if(i == bill_type) {
                selectBillNumber[i] = true;
            }
        }

       let {  transporter_name, bill_range_from, bill_range_to } = this.state;
       const disable = this.validData();
        return (
            <div className = "add-company-wrap add-provider-root add-row">
            <div onClick = {this.props.toggleEdit} className = "overlay">
                <div className = "modal-popup" onClick={(e) => { e.stopPropagation() }}>
                    <div className = "heading">
                    Edit Billing Number
                    </div>
                    <form className = "form-section bill-number" autoComplete = "nopes">
                        <div style={{marginBottom : '10px'}}>
                            <div className={'check-label'}>Select Bill Type</div>
                            <div className = "section-item all">
                                <CustomeCheckBox id = {'AutoGenerated'} toggle = {this.clickCustomeCheckBoxService}  selected={this.state.selectBillNumber.AutoGenerated}/>
                                <div className = "title"> {'Auto Generated'}</div>
                            </div>
                            <div className = "section-item all">
                                <CustomeCheckBox id = {'Manual'} toggle = {this.clickCustomeCheckBoxService} selected={this.state.selectBillNumber.Manual}/>
                                <div className = "title"> {'Manual'}</div>
                            </div>
                        </div>
                        {/* <div className="select-wrap">
                            <div className="label">Transporters</div>
                            <Select className={'select'} options={billingType} onChange={this.handleChange.bind(this, 'billing_type')}/>
                        </div> */}
                            <InputText label={'Transporter'}  placeholder={'Transporter'} id={'transporter_name'} value={this.state.transporter_name} changeHandler = {this.onChangeHandler}/>
                            {this.state.bill_type == 'Manual' && <InputText label={'Range From'}  placeholder={'from'} id={'bill_range_from'} value={this.state.bill_range_from} changeHandler = {this.onAmountChangeHandler} type={"number"} />}
                            {this.state.bill_type == 'Manual' && <InputText label={'Range To'} placeholder={'to'} id={'bill_range_to'} value={this.state.bill_range_to} changeHandler = {this.onAmountChangeHandler} type={"number"}/>}
                    </form>
                    
                    <div className = "submit-section submit-area">
                       {
                        <div className="button-section">
                          <Button className="add-button" click={this.updateBillNumber} value="Update" disabled={!disable}/>
                       </div> 
                       }
                        <div className ="button-section" >
                            <div className ="add-button" onClick={this.props.toggleEdit}>Close</div>
                        </div>
                    </div>
                </div>
            </div>
         </div>
        )
    }
}

const CustomeCheckBox = (props) =>{
    return(
        <div className = {props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick = {()=>props.toggle(props.id)}>
            {props.selected && <div className = "inner-circle"></div>}
        </div>
    )
}

export default EditBillNumber;