import React, { Component } from "react";
import Select from "react-select";
import ReactToPrint from 'react-to-print';
import Button from "../button-classic/button-classic";
import './company_category.less'
import Delete from '../../../assets/images/delete.svg';
import View from "../../../assets/images/view.svg";
import Edit from '../../../assets/images/edit.svg';
import backIcon from "../../../assets/images/left-arrow.svg";
import AdvanceFilter from './AdvanceFilter'
import "./AdvanceFilter.css";
import InputText from "../../common/components/input-text/input-text";
import { getCookie } from "../../../utils/cookies";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Checkbox from "../../components/checkbox/checkbox";
import ACTIONS from "../../common/action_constant";
import { connect } from 'react-redux';
import "../basic_details/basic_details.less";
// import pdfMake from 'pdfmake';

// import Editor from './ckeditorSample';
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
// import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
// import ExportPdf from '@ckeditor/ckeditor5-export-pdf/src/exportpdf';
// import ExportToPDF from '@ckeditor/ckeditor5-export-pdf/src/exportpdf';

import SampleFile from './SampleFile';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Loader from "../../common/components/loader/loader";
import { ABFRL_COMPANY_IDS, CARGILL_COMPANY_IDS } from '../../../utils/common';
import VendorDetails from './vendor_details';
import BlockListedRemarks from './blockRemarks';
// import { ABFRL_COMPANY_IDS } from '../../../utils/common';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from "moment";

const delay_penalty_list_header = ['Category', 'Vendor Code', 'Mode', 'Delay Penalty'];
const dph_list_header = ['Code', 'Mode', 'Region', 'My Rate', '(Inc/Dec) %', 'Diesel Rate', 'Type'];
const liablity_list_header = ['Category','Liability','Vendor Code','Mode','Types Of Shortages'] //'Liability Rules'];
const oda_list_header = ['Category','Mode','Type','From','To','ODD/ESS'];
const tat_route_list_header = ['From City','From State','To City','To State','Hub','TAT'];
const volumetric_list_header = ['Vendor Code','Mode','Division Factor'];
const demurrage_list_header = ['Category','Vendor Code','Mode','Demurrage Date', 'Charges'];
const other_charge_list_header = ['Load type', 'Mode type', 'Movement Type', 'Distribution Type', 'Charges', 'Sub Charges'];
const dph_lanes_list_header = ['From City', 'To City', 'To State', 'To Region', 'Approved Rate'];

const headers = {
 delay_penalty_list_header: delay_penalty_list_header,
 dph_list_header: dph_list_header,
 liablity_list_header: liablity_list_header,
 oda_list_header: oda_list_header,
 tat_route_list_header: tat_route_list_header,
 volumetric_list_header: volumetric_list_header,
 demurrage_list_header: demurrage_list_header,
 other_charge_list_header: other_charge_list_header,
}

const delay_penalty_list_keys = ['company_category', 'vendor_code', 'mode_type', 'vendor_penalty'];
const dph_list_keys = ['vendor_code', 'mode_type', 'region_rate', 'my_rate', 'freight_rate', 'diesel_rate', 'rate_type'];
const liablity_list_keys = ['company_category', 'liability_type', 'vendor_code', 'mode_name', 'types_of_shortages'] //, 'claim_type'];
const oda_list_keys = ['company_category', 'mode_type', 'oda_type', 'from', 'to', 'oda_value'];
const tat_route_list_keys = ['from.city_name','from.state_name','to.city_name','to.state_name','hubs','tat'];
const volumetric_list_keys = ['vendor_code','mode_type', 'division_value'];
const demurrage_list_keys = ['company_category', 'vendor_code', 'mode_name', 'demurrage_start_date', 'demurrage_charges'];
const other_charge_list_keys = ['load_type', 'mode_type', 'movement_type', 'distribution_type', 'charges', 'sub_charges'];
const dph_lanes_list_keys = ['from', 'to', 'tostate', 'toregion', 'rate'];

const keys = {
  delay_penalty_list_keys: delay_penalty_list_keys,
  dph_list_keys: dph_list_keys,
  liablity_list_keys: liablity_list_keys,
  oda_list_keys: oda_list_keys,
  tat_route_list_keys: tat_route_list_keys,
  volumetric_list_keys: volumetric_list_keys,
  demurrage_list_keys: demurrage_list_keys,
  other_charge_list_keys: other_charge_list_keys,
}

const companyCategoryDetails =  {
    company_category: '',
    company_category_id: '',
    company: '',
    company_id: '',
    vendor_code: '',
    type: '',
    mode: '',
    credit_period: 0
}


const LOADS = [{label: 'LCL', value: 1}, {label: 'FTL', value: 2}, {label: 'Courier', value: 3}, {label:'Container', value: 4}]

const InputBox = (props) => {
  return <input 
            readOnly={props.readOnly} 
            className="input-text align-middle" 
            type={props.type || "text"} 
            id={props.id} 
            placeholder={props.placeholder} 
            value={props.value} 
            onChange={(e) => props.changeHandler(e.target.value, props.id)} 
          />
}

class MyDatePicker extends Component {
  render() {
    return (
      <div className="data-picker">
        <div className="label">{this.props.label}</div>
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={this.props.startDate}
          onChange={this.props.handleChange.bind(this, this.props.id)}
        />
      </div>
    );
  }
}

class CompanyCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isViewMode: false,
      viewItem: {},
      viewIndex: null,
      viewVendorInfo: false,
      details: {
        company_Id: "",
        // company_Id: company_Id,
        // company_vendor_details:  ABFRL_COMPANY_IDS.includes(company_Id) ? [
        company_vendor_details:  (JSON.parse(getCookie("user")).company_id == '782bd60a53f148b3b342474da97f5be8' || JSON.parse(getCookie("user")).company_id =='62cd3ea65c60478182b5aa9da9f525fc') ? [
          {
            company_category: '',
            company_category_id: '',
            company: '',
            company_id: '',
            vendor_code: '',
            email:''
          }
        ]
        :
        [
          {
            company_category: '',
            company_category_id: '',
            company: '',
            company_id: '',
            vendor_code: '',
            type: '',
            mode: '',
            credit_period: 0
          }
        ]
      },
      isErroMessageCategoryName: "",
      showAdvanceFilter: false,
      types: [],
      modes: [],
      companyCategory: [],
      tentureFrom: null,
      tentureTo: null,
      address: '',
      fileNames: [{
				fileObject: "",
				name: "",
				isMSADoc: false
			}],
    }
    this.setChildData = this.setChildData.bind(this)
    // this.pdfRef = React.createRef();
    this.scrolltoPdf = React.createRef();
    this.scrollToTop = React.createRef();
  }

  setChildData(node) {
    this.childRef = node
  }

  componentDidMount() {
    this.props.fetchAllCompanyCategory();
    this.props.getCompanyTransporters();
    this.props.fetchCompanyCategoryDetails();
    this.props.getAllDropdownData();

    // this.props.fetchAllTypes();
    // 404 
    // this.props.fetchAllModes();
  }

  onDeleteCompanyCategory = (index) => {
    const { details } = this.state;
    details.company_vendor_details.splice(index, 1)
    this.setState({ details });
  }

  onAddCompanyCategory = () => {
    const { details } = this.state;
    let companyCategoryDetailsABFRL =  {
      company_category: '',
      company_category_id: '',
      company: '',
      company_id: '',
      vendor_code: '',
      email:'',
      is_blacklisted: false
  }
  let companyCategory = ABFRL_COMPANY_IDS.includes(JSON.parse(getCookie("user")).company_id) ? companyCategoryDetailsABFRL : companyCategoryDetails
  const row = Object.assign({}, companyCategory);
	  details.company_vendor_details.unshift(row);
    this.setState({ details });
  }
  
  downloadDetails = () => {
    // const { details } = this.state;
    this.props.downloadVendorDetails()
    this.props.fetchCompanyCategoryDetails();
    
  }

  downloadAllDetails = () => {
    this.props.downloadAllVendorDetails();
  }

  componentWillReceiveProps(props) {
    let details = {
      company_vendor_details: props.companyCategoryDetails && props.companyCategoryDetails.details && props.companyCategoryDetails.details.company_vendor_details.length > 0 ? props.companyCategoryDetails.details.company_vendor_details : (JSON.parse(getCookie("user")).company_id === '782bd60a53f148b3b342474da97f5be8' || JSON.parse(getCookie("user")).company_id ==='62cd3ea65c60478182b5aa9da9f525fc') ? [
        {
          company_category: '',
          company_category_id: '',
          company: '',
          company_id: '',
          vendor_code: '',
          email:''
        }
      ]
      :
      [
        {
          company_category: '',
          company_category_id: '',
          company: '',
          company_id: '',
          vendor_code: '',
          type: '',
          mode: '',
          credit_period: 0
        }
      ]
    }
    this.setState({
      details
    })
    if (props && props.companyCategory) {
      const companyCategory = [];
      for (let i in props.companyCategory) {
        companyCategory.push(
          {
            label: props.companyCategory[i].company_category,
            value: props.companyCategory[i].id,
            selected: false,
          });
      }
      this.setState({ companyCategory });
    }

    if (props && props.companyTransporters) {
      const companyTransporters = [];
      for (let i in props.companyTransporters) {
        companyTransporters.push(
          {
            label: props.companyTransporters[i].company_name,
            value: props.companyTransporters[i].id
          });
      }
      this.setState({ companyTransporters });
    }

  }

fileClicked = (e) => {
  e.preventDefault();
  document.getElementById("file").click();
};

fileUploaded = e => {
  e.persist();
  this.setState({
    fileName: e.target.files[0]
  });
  const fileObject = e.target.files[0];
  const reader = new FileReader();
  reader.onload = e => {
    const details = Object.assign({}, this.state.details);
    details.company_logo = e.target.result;
    this.setState({
      details
    });
  };
  reader.readAsDataURL(fileObject);
};

  saveCompanyCategory = () => {
    const { details } = this.state;
    // const { basicDetails } = this.props;
    let temp = {}
    temp["details"] = details;
    // temp["company_id"] = basicDetails.id;
    temp["company_id"] = JSON.parse(getCookie("user")).company_id
    // console.log(JSON.parse(getCookie("user")).company_id, 'company ID')
    if (this.validationCompnayCategory()) {
      this.props.saveCompanyCategoryDetails(temp);
    }
  }
  validationCompnayCategory = () => {
    let company_vendor_details = this.state.details.company_vendor_details;
    let company_Id = JSON.parse(getCookie("user")).company_id
    for (let i in company_vendor_details) {
      // let isABFRL = ABFRL_COMPANY_IDS.includes(company_Id)
      // let options = isABFRL ? !company_vendor_details[i].email : (!company_vendor_details[i].type || !company_vendor_details[i].mode)
      // test with Non-ABFRL companies
      if (!company_vendor_details[i].company_category_id || !company_vendor_details[i].company_id ) {
        this.setState({ isErroMessageCategoryName: "Please select all fields" });
        // return false;
      }
    }
    this.setState({
      isErroMessageCategoryName: "",
    });
    return true;
  }

  handleAdvanceFilter = () => {
    this.setState({showAdvanceFilter: !this.state.showAdvanceFilter})
  }

  handleFilterSubmit = () => {
    this.setState({
        showAdvanceFilter: false
    }, () => this.props.fetchCompanyCategoryDetails());
  }

  handleTagClick = (key, value) => {
    this.setState({
        [key]: this.state[key].map((el) => value == el.value?{...el, selected: !el.selected}:el)
    })
  }

  generateHeaders = (header) => {
    return (
      <div className="divTableCell"> {header} </div>
    )
  }

  initiateCKEditor = (isActive) => {
    setTimeout(() => {
      if (isActive) {
        console.log('sas', document.querySelector( '#sampleContractFile' ))
        ClassicEditor.create( document.querySelector( '#sampleContractFile' ) , 
        {
          toolbar: {
            items: ['heading','|','bold','italic','link','bulletedList','numberedList','|','indent','outdent','|','imageUpload','blockQuote','insertTable','mediaEmbed','undo','redo','exportPdf','fontColor','alignment']
          },
          language: 'en',
          image: {
            toolbar: [
              'imageTextAlternative',
              'imageStyle:full',
              'imageStyle:side'
            ]
          },
          table: {
            contentToolbar: [
              'tableColumn',
              'tableRow',
              'mergeTableCells'
            ]
          },
          licenseKey: '',
          // {
            // plugins: [ ExportPdf ],
            // toolbar: [
            //     'exportPdf', '|', 
            // ],
            // toolbar: {
            //   items: [
            //     'heading',
            //     '|',
            //     'bold',
            //     'italic',
            //     'link',
            //     'bulletedList',
            //     'numberedList',
            //     '|',
            //     'indent',
            //     'outdent',
            //     '|',
            //     'imageUpload',
            //     'blockQuote',
            //     'insertTable',
            //     'mediaEmbed',
            //     'undo',
            //     'redo',
            //     'exportPdf',
            //     'fontColor',
            //     'alignment'
            //   ]
            // }
            // exportPdf: {
            //     stylesheets: [
            //         // './path/to/fonts.css',
            //         'EDITOR_STYLES',
            //         // './path/to/style.css'
            //     ],
            //     fileName: 'my-file.pdf',
            //     converterOptions: {
            //         format: 'A4',
            //         margin_top: '20mm',
            //         margin_bottom: '20mm',
            //         margin_right: '12mm',
            //         margin_left: '12mm',
            //         page_orientation: 'portrait'
            //     }
            // }
        // }
        })
        .then( editor => {
          if (editor) {
            window.editor = editor;
            console.log('list',  window.editor, Array.from( editor.ui.componentFactory.names() ) );
            editor.setData( this.childRef );
          }
        })
        .catch( error => {
            console.error( 'error', error );
        });
      }
    }, 2000)
  }

  urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], filename, {type:mimeType});})
    );
  }

  loadImage = (url) => {
    return new Promise((resolve) => {
      let img = new Image();
      img.onload = () => resolve(img);
      img.src = url;
    })
  }

  generatePdf = () => {
    const div = document.getElementById('sampleContractFile');
    // const div = document.getElementsByClassName('sampleFile');

    window.scrollTo(0, 0);

    console.log('div', div);

    const HTML_Width = parseFloat(div.style.width);
    const HTML_Height = parseFloat(div.offsetHeight);
    const top_left_margin = 40;
    const PDF_Width = HTML_Width+(top_left_margin*2);
    const PDF_Height = (PDF_Width*1.5)+(top_left_margin*2);
    const canvas_image_width = HTML_Width;
    let canvas_image_height = HTML_Height;
    
    const totalPDFPages = Math.ceil(HTML_Height/PDF_Height);
    
      this.setState({
        isDownloading: true
      });

      html2canvas(div, {
        logging: false,
        useCORS: true,
        scale: 2,
        // imageTimeout: 0
        dpi: 144,
      }).then(canvas => {

          canvas.getContext('2d');
          // const ctx = canvas.getContext("2d");
          // ctx.scale(5, 5)
          // ctx["imageSmoothingEnabled"] = false;
          // ctx["mozImageSmoothingEnabled"] = false
          // ctx["oImageSmoothingEnabled"] = false
          // ctx["webkitImageSmoothingEnabled"] = false
          // ctx["msImageSmoothingEnabled"] = false
          
          const imgData = canvas.toDataURL('image/png', 1.0);
          // const pdf = new jsPDF('p', 'mm', 'a4'); //pdfOptions
          // console.log('ht+wt', imgData, canvas.height+", "+canvas.width, totalPDFPages, imgData);
          // pdf.setFontSize(14);
          // // let position = 0;
          
          // const pdfExportSetting = {
          //   pageSize: 'A4',
          //   pages: totalPDFPages,
          //   // footer: ((currentPage, pageCount) => {
          //   //   return {
          //   //     text: currentPage.toString() + "/" + pageCount,
          //   //     alignment: "center",
          //   //     fontSize: 8
          //   //   };
          //   // }),
          //   pageOrientation: 'portrait',
          //   content: [{ image: imgData, width: 500 }],
          //   pageBreakBefore: ((currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) => {
          //            return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
          //         })
          // };
          // pdfMake.createPdf(pdfExportSetting).download('abfrl-contract.pdf')

          let newPdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height], true);
          newPdf.setFontSize(14);
          newPdf.addImage(imgData, 'PNG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height, undefined, 'FAST');
          
          for (let i = 1; i <= totalPDFPages; i++) { 
            newPdf.addPage(PDF_Width, PDF_Height);
            // console.log('height', -(PDF_Height*i), (top_left_margin*4), -(PDF_Height*i)+(top_left_margin*4))
            newPdf.addImage(imgData, 'PNG', top_left_margin, -(PDF_Height*i)+(top_left_margin*4), canvas_image_width, canvas_image_height, undefined, 'FAST');
          }
          newPdf.save("ABFRL-CONTRACT.pdf");

          // const imgProps= pdf.getImageProperties(imgData);
          // const pdfWidth = pdf.internal.pageSize.getWidth();
          // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          // pdf.addImage(imgData, 'JPEG', 5, 5, pdfWidth, pdfHeight);
          // pdf.save('before-abfrl.pdf');
          
          // this.urltoFile(newPdf, 'abfrl.pdf','image/pdf')
          //   .then((file) => { 
          //       console.log('pdf new file', file, newPdf.output('blob'))
          //       newPdf.save('abfrl.pdf');
          //   });

          this.setState({
            isDownloading: false
          })
      });
  }

  toggleAdd = () => {
    this.setState({viewVendorInfo: false, viewIndex: null})
  }

  handleSwitch = (checked) => {
    let {details}=this.state;
    details.is_blocked=checked;
    this.setState( { details } );
  };

  onCancel = () => {
    const { details,dataIndex  } = this.state;
    details.company_vendor_details[dataIndex]['is_blacklisted'] = !details.company_vendor_details[dataIndex]['is_blacklisted'];
    this.setState({
      details,
      isRemarksPopUp: false,
      dataIndex: false
    })
  }

  onSave = () => {
    this.setState({
      isRemarksPopUp: false,
      dataIndex: false
    })
  }

  onChangeHandler = (value) => {
    const { details,dataIndex  } = this.state;
    details.company_vendor_details[dataIndex]['blacklisted_remarks'] = value;
    this.setState ({
      details
    })
  }

  render() {
    const { companyCategory, companyTransporters, details, isViewMode, viewItem, tentureFrom, tentureTo, address } = this.state;
    const { typesArray, modesArray, vendorMDGDetails, allDropdownTypes, company_id } = this.props;
    // const companyId = JSON.parse(getCookie("user")).company_id;
    const isCargill = CARGILL_COMPANY_IDS.includes(company_id);
    return (
      <div className="basic-details-wrap company-category">
        {!isViewMode && <div className={'details mb-10'}>
          <label className="error">{this.state.isErroMessageCategoryName}</label>
          <div className="mt-1p">
            <div className="button-default" onClick={this.onAddCompanyCategory} >
              ADD
            </div>
             <div className="button-default" onClick={this.downloadDetails} >
              Download
            </div>
            <div className="button-default" onClick={this.downloadAllDetails} >
              Download All Details
            </div>
           {!isCargill && <div className="button-default f-r" onClick={() => this.handleAdvanceFilter()} >
              Advance Filters
            </div>}
            {
                this.state.showAdvanceFilter && <AdvanceFilter handleTagClick={this.handleTagClick} categories={companyCategory}
                types={typesArray} modes={modesArray} handleSubmit={this.handleFilterSubmit}/>
            }
            <div className="button-white f-r"  onClick={() => this.saveCompanyCategory()}  >
              Save Details
            </div>
          </div>
          <SubTitle title="Vendor Company Category" />
          {(details && details.company_vendor_details).map((value, index) => {
            return (<div style={{display: "flex", alignItems: "center", verticalAlign: 'center'}} key={`c_${index}`} className="details">
              <div className="select-wrap w-p-24">
                <div className="label">Vendor Company Name</div>

                <Select
                  className="select w-225"
                  options={companyTransporters}
                  value={value.company_id && companyTransporters && companyTransporters.length > 0 && companyTransporters.find(val => { return val.value === value.company_id }) || null}
                  onChange={(selectedOption) => {
                    const { details } = this.state;
                    value.company = selectedOption.label;
                    value.company_id = selectedOption.value;
                    this.setState({ details });
                  }}
                />
              </div>
              {value.company_id  &&  <>
              {!isCargill && 
              <>
              <div className="select-wrap w-p-12">
                  <div className="label">Vendor Company Category</div>
                  <Select
                    className="select w-100"
                    options={companyCategory}
                    value={value.company_category_id && companyCategory && companyCategory.length > 0 && companyCategory.find(val => { return val.value === value.company_category_id }) || null}
                    onChange={(selectedOption) => {
                      const { details } = this.state;
                      value.company_category = selectedOption.label;
                      value.company_category_id = selectedOption.value;
                      this.setState({ details });
                    }}
                  />
                </div>
                <div className="select-wrap w-p-12 m-l-5 m-r-8">
                  <div className="label">Vendor Code</div>
                  <InputBox
                    type="text"
                    placeholder=""
                    readOnly={true}
                    value={value.sap_vendor_code}
                    changeHandler={(value) => {}}
                  />
                </div>
                <div className="select-wrap w-p-12 m-l-5 m-r-8">
                  <div className="label">Carrier Code</div>
                  <InputBox
                    type="text"
                    placeholder=""
                    readOnly={true}
                    value={value.carrier_code || ''}
                    changeHandler={(value) => {}}
                  />
                </div>
                </>}
                <div className="select-wrap w-p-5 m-l-5 m-r-8 align-top" style={{marginTop: '12px'}}>
                  <div className="label">Blacklisted</div>
                  <Checkbox type={2} status={value.is_blacklisted} disabled={false} user={value.is_blacklisted} id={index} handleChange={(e) => {
                    const {details} = this.state;
                    details.company_vendor_details[index]['is_blacklisted'] = value.is_blacklisted ? !value.is_blacklisted : true;
                    this.setState({details, isRemarksPopUp: true, dataIndex: index })}}/>
                </div>
               {!((JSON.parse(getCookie("user")).company_id == '782bd60a53f148b3b342474da97f5be8' || JSON.parse(getCookie("user")).company_id =='62cd3ea65c60478182b5aa9da9f525fc')) && !isCargill && <>
                <div className="select-wrap w-p-12">
                  <div className="label">Type</div>
                  <Select
                    className="select w-100"
                    options={typesArray}
                    value={typesArray.filter(el => el.value == value.type) || null}
                    onChange={(selectedOption) => {
                      const { details } = this.state;
                      value.type = selectedOption.value;
                      this.setState({ details });
                    }}
                  />
                </div>
                <div className="select-wrap w-p-12">
                  <div className="label">Mode</div>
                  <Select
                    className="select w-100"
                    options={modesArray}
                    isMulti
                    value={modesArray.filter(el => el.value == value.mode) || null}
                    onChange={(selectedOption) => {
                      const { details } = this.state;
                      value.mode = selectedOption.value;
                      this.setState({ details });
                    }}
                  />
                </div>
                </>
               }
                 {(JSON.parse(getCookie("user")).company_id == '782bd60a53f148b3b342474da97f5be8' || JSON.parse(getCookie("user")).company_id =='62cd3ea65c60478182b5aa9da9f525fc') && <div className="select-wrap w-p-22">
                  <div className="label">Communication Email</div>
                    <InputBox
                      className="fs-14"
                      type="email"
                      placeholder="Email"
                      readOnly={false}
                      value={value.email}
                      id={index}
                      // changeHandler={e => this.setState({email:e.target.value})}
                      changeHandler={(value, id) => {
                        let details = {...this.state.details}
                        details.company_vendor_details[id]['email'] = value
                        return this.setState({details: details})
                      }}
                    />
                  </div>
                }
              </>
              }
              <div className="file-lable-section" style={{'display': 'flex'}}>
								<div className="file-upload-details select-wrap">
									<div className="title label" style={{'width': '100%'}}>MSA Document</div>
									<div className="file-holder">
                  <input
                        type="file"
                        id="msaDocfile"
                        name="file-upload-button"
                        onChange={e => {
                          let name = e.target.files[0] && e.target.files[0].name
                          let url = `https://s3.us-east-2.amazonaws.com/tms-2.0-contracts/dev/${name}`

                          if (name && url) {
                            value.name = name;
                            value.url = url;
                          }
                        }}

                      />
									</div>
								</div>
                <div style={{ 'width': '200px', 'marginLeft': '20px' }} className="single-date-input" >
                  <div className="title" style={{ fontSize: "10px", color: "#868e96" }}>Expiry Date<span className={'error-color fs-14'}>*</span></div>
                  <SingleDatePicker
                    id={index}
                    noBorder={true}
                    displayFormat={"DD MMM YYYY"}
                    hideKeyboardShortcutsPanel={true}
                    placeholder={"Date"}
                    numberOfMonths={1}
                    date={value.msa_validity_date && moment(value.msa_validity_date) || null}
                    onDateChange={(from) => {
                      const { details } = this.state;
                      const date = from && from.format('YYYY-MM-DD HH:mm') || null
                      value.msa_validity_date = date && new Date(date).getTime() || null;
                      // this.setState({ details });
                    }}
                    focused={this.state.focusedMSAStart && (this.state.index === index)}
                    onFocusChange={({ focused }) => this.setState({ focusedMSAStart: focused, index: index })}
                  // isOutsideRange={() => true}
                  />
                </div>
                {isCargill &&
                <div className="select-wrap w-p-16 m-l-5 m-r-8">
                  <div className="label">{'Credit Period (Days)'}</div>
                  <InputBox
                    type="number"
                    placeholder=""
                    readOnly={false}
                    id={index}
                    value={value.credit_period || ''}
                    changeHandler={(value, id) => {
                        let details = {...this.state.details}
                        details.company_vendor_details[id]['credit_period'] = value ? Number(value) : 0;
                        return this.setState({details: details})
                    }}
                  />
                </div>}
							</div>
              
              <div className="select-wrap w-p-3 align-middle">
                <div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})`, marginBottom: "-20px" }} onClick={() => this.onDeleteCompanyCategory(index)}></div>
              </div> 
              <div className="select-wrap w-p-3 align-middle">
                {/* <div className={'delete-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.onDeleteCompanyCategory(index)}></div> */}
                {ABFRL_COMPANY_IDS.includes(this.props.company_id) &&  <div className={'delete-icon'} style={{ backgroundImage: `url(${View})` }} onClick={() => {this.setState({ isViewMode: true, viewItem: value }, () => { 
                  // this.initiateCKEditor(true) 
                }); this.props.fetchVendorMDGDetails(value)}}></div>}
              </div>
              <div style={{ marginBottom: "-20px", marginLeft:'-20px' }} className="select-wrap w-p-3 curP">
                <span className="theme-color fs-12" onClick={() => this.setState({viewVendorInfo: true, viewIndex: value.company_id})}>Info</span>
              </div>
            </div>)
          })
          }
        </div>}
        {/* <div className="ml-23p">
          <Button value="Save Details" click={this.saveCompanyCategory} />
        </div> */}

        {
          isViewMode &&
          <div>
            {this.state.isDownloading && <Loader />}            

            <div ref={this.scrollToTop}>
              <div>
                <div className = "go-back-prev-screen" onClick={() => { this.setState({ isViewMode: false, address: '', tentureFrom: null, tentureTo: null, viewItem: {} }) }} style = {{backgroundImage: `url(${backIcon})`}}></div>
              </div>
              {
                Object.keys(vendorMDGDetails || {}).map((item, index) => {
                  if (vendorMDGDetails[item].length > 0 || Object.keys(vendorMDGDetails[item] || {}).length > 0) {
                    const tab = item.replace(/_/g, ' ');
                    const header = headers[`${item}_header`];
                    return (
                      <div className={`company-list-wrap ${tab}`} 
                        onClick={() => { 
                          const ele = document.getElementsByClassName(item);
                          if (ele && ele.length > 0) {
                            document.getElementsByClassName(item)[0].scrollIntoView()
                          }
                        }}
                      >
                        {tab.toUpperCase()}
                      </div>
                    )
                  }
                })
              }
              <div className="top-margin-20" style={{ float: 'right', marginRight: '20px',display:'flex' }}>
							 {/*<div style={{ marginRight: '10px'}}>
                <button className="button-classic" onClick={this.props.DownloadContract}> Download Contract </button>
							 </div>*/}
               <div style={{ marginRight: '10px'}}>
                <button className="button-classic" onClick={() => { this.scrolltoPdf.current.scrollIntoView({ behavior: 'smooth' }) }}> Get Contract </button>
               </div>
              <ReactToPrint content={() => this.componentRef} trigger={() => { return <input className="button-classic" type="button" value="Print" />; }} />
              </div>
            </div>
            <div className="p-5 m-5" 
              ref={el => (this.componentRef = el)}
            >
              <h2>{viewItem.company}</h2>
              {
                // Object.keys(vendorMDGDetails || {}).length > 0 &&
                Object.keys(vendorMDGDetails || {}).map((item, index) => {
                  if (vendorMDGDetails[item].length > 0 || Object.keys(vendorMDGDetails[item] || {}).length > 0) {
                    const tab = item.replace(/_/g, ' ');
                    const header = headers[`${item}_header`];
                    return (
                      <div className={`top-margin-20 ${item}`}>
                        <div className="top-margin-20">{tab.toUpperCase()}</div>

                        <div className={`divTable company-list-wrap`}>
                          <div className="list-section table-header divTableHeading divTableRow">
                              { (header || []).map((head) => { return (<div className="divTableCell"> {head} </div>) }) }
                          </div>
                          <div className="divTableBody">
                            {
                              Array.isArray(vendorMDGDetails[item]) ?
                              <React.Fragment>
                              {(vendorMDGDetails[item] || []).map((detail, dIndex) => {
                                const keyItems = keys[`${item}_keys`];
                                return (
                                  <div className="list-section content divTableRow" key={`${(index+1)}-${(dIndex+1)}`}>
                                    {
                                      (keyItems || []).map((data, dId) => {
                                        if (item==='liablity_list') {
                                          // return null;
                                          if (data==='types_of_shortages') {
                                            return (
                                              <div className="divTableCell">
                                                {(detail[data] || []).map((dItem, sIdx) => {
                                                    return (<div className="mb-10 flex" key={`sub-${(sIdx+1)}-${dItem.shortage_id}`}>
                                                      <div className="mr-10 wt-200"> {dItem.shortage_name}</div>
                                                      {
                                                        dItem.claim_type && dItem.claim_type.length > 0 && 
                                                        <div>
                                                          {(dItem.claim_type || []).map((cItem, cId) => {
                                                            return (
                                                              <div key={`claim-${(cId+1)}-${cItem.claim_type_id}`} className="mb-10">
                                                                <span className="mr-10">Claim Type: </span> <span>{cItem.claim_type_name}</span>
                                                                {
                                                                  Object.keys(cItem.rules || {}).length > 0 && 
                                                                  <React.Fragment>
                                                                    <span> {cItem.rules.rule || ''} {cItem.rules.range_unit || 'Rs'} </span>
                                                                    {
                                                                      cItem.rules.inputs === 1 && <span> <b>{cItem.rules.range[0] || '0'}</b> </span>
                                                                    }
                                                                    {
                                                                      cItem.rules.inputs > 1 && <span> <b>{cItem.rules.min || '0'} - {cItem.rules.max || '0'}</b> </span>
                                                                    }
                                                                  </React.Fragment>
                                                                }
                                                              </div>
                                                            )
                                                          })}
                                                        </div>
                                                      }
                                                    </div>)
                                                })}
                                              </div>
                                            )
                                          } else {
                                            return <div className="divTableCell" key={`data-${(dId+1)}`}> {detail[data]} </div>
                                          }
                                        } else if (item==='delay_penalty_list') {
                                          if (data==='vendor_penalty') {
                                            const typeOfEfficiency = (detail.type===2) ? (detail.delay_penalty_ontime_efficiency || {}) : (detail.delay_penalty_days || {});
                                            const arrData = (detail.type===2) ? (typeOfEfficiency.vendor_ontime_efficiency || []) : (typeOfEfficiency.vendor_delay_penalty_days || []);
                                            return (
                                              <div className="divTableCell">
                                                {(arrData || []).map((dItem, idx) => {
                                                    return (<div className="mb-10" key={`sub-${(idx+1)}`}>
                                                      {idx===0 && <div className="mb-10">Type: {typeOfEfficiency.name}</div>}
                                                      <div className="flex">
                                                        <div className="mr-10">Min: {dItem.min}</div>
                                                        <div className="mr-10">Max: {dItem.max}</div>
                                                        <div className="mr-10">Value: {dItem.penalty_percentage}</div>
                                                      </div>
                                                    </div>)
                                                })}
                                              </div>
                                            )
                                          } else {
                                            return <div className="divTableCell" key={`data-${(dId+1)}`}> {detail[data]} </div>
                                          }
                                        } else if (item === 'demurrage_list') {
                                          if (data === 'demurrage_charges') {
                                            return (
                                              <div className="divTableCell">
                                                {(detail[data] || []).map((dItem, idx) => {
                                                    return (<div className="mb-10 flex" key={`demurrage-${(idx+1)}`}>
                                                      <div className="mr-10">Min: {dItem.min}</div>
                                                      <div className="mr-10">Max: {dItem.max}</div>
                                                      <div className="mr-10">Freight: {dItem.frieght} {dItem.frieght_uom}</div>
                                                    </div>)
                                                })}
                                              </div>
                                            )
                                          } else {
                                            return <div className="divTableCell" key={`data-${(dId+1)}`}> {detail[data]} </div>
                                          }
                                        } else if (item === 'other_charge_list') {
                                          if (data === 'load_type') {
                                            const load = LOADS.filter(el => { return el.value===detail.load_type })
                                            return ( <div className="divTableCell">{load.length>0 ? load[0].label : ''}</div> )
                                          } else if (data === 'mode_type') {
                                            const mode_name = (allDropdownTypes.modesArray || []).filter(el => { return detail[data].includes(el.id) });
                                            return (
                                              <div className="divTableCell">
                                                {(mode_name).map((dItem, idx) => {
                                                    return (<div className="mb-10" key={`oc-mode-${(idx+1)}`}> {dItem.name} </div>)
                                                })}
                                              </div>
                                            )
                                          } else if (data === 'movement_type') {
                                            const movs = (allDropdownTypes.movement_type || []).filter(el => { return detail[data].includes(el.dropdown_value) });
                                            return (
                                              <div className="divTableCell">
                                                {(movs).map((dItem, idx) => {
                                                    return (<div className="mb-10" key={`oc-mode-${(idx+1)}`}> {dItem.dropdown_name} </div>)
                                                })}
                                              </div>
                                            )
                                          } else if (data === 'distribution_type') {
                                            const dist_type = (allDropdownTypes.distribution_type || []).filter(el => { return detail[data].includes(el.dropdown_value) });
                                            return ( 
                                              <div className="divTableCell">
                                                {(dist_type).map((dItem, idx) => {
                                                    return (<div className="mb-10" key={`oc-dist-${(idx+1)}`}>{dItem.dropdown_name}</div>)
                                                })}
                                              </div>
                                            )
                                          } else if (data === 'charges') {
                                            const charge_type = (allDropdownTypes.charges || []).filter(el => { return detail.charge_id===(el.dropdown_value) });
                                            const charges_uom = (allDropdownTypes.charges_uom || []).filter(el => { return detail.charge_uom===(el.dropdown_value) });
                                            return ( 
                                              <div className="divTableCell">
                                                <div className="mb-10"> Name: {charge_type.length > 0 ? charge_type[0].dropdown_name : ''} </div>
                                                <div className="mb-10"> UOM: {charges_uom.length > 0 ? charges_uom[0].dropdown_name : ''} </div>
                                              </div>
                                            )
                                          } else if (data === 'sub_charges') {
                                            return ( 
                                              <div className="divTableCell">
                                              {
                                                (detail.sub_charges || []).map((obj, index) => {
                                                  return (<div className="mb-10" key={`levy-${obj.charge_id}-${dIndex}-${index}`}> 
                                                      <div className="mb-5 fs-11"><span className="header-color">Levy: </span>{obj.levy_logic} </div>
                                                      <div className="mb-5 fs-11"><span className="header-color">Levy UOM:</span> {obj.levy_uom} </div>
                                                      {obj.minimum_value && <div className="mb-5 fs-11"><span className="header-color">Min. value: </span>{obj.minimum_value || '-'} </div>}
                                                      {obj.levy_uom && <div className="mb-5 fs-11"><span className="header-color">Value UOM:</span> {obj.levy_uom} </div>}
                                                      <div>
                                                      {Object.keys(obj.levy_logic_values || {}).map((levy) => {
                                                          return ( <div className="fs-11"> {levy} : {obj.levy_logic_values[levy]} </div> )
                                                      }) }
                                                      </div>
                                                  </div>)
                                                })
                                              }
                                              </div>
                                            )
                                          }
                                        } else {
                                          return <div className="divTableCell" key={`datas-${(dId+1)}`}> {detail[data]} </div>
                                        }
                                      })
                                    }
                                  </div>
                                )
                              })}
                              </React.Fragment>
                              :
                              <React.Fragment>
                                {
                                  Object.entries(vendorMDGDetails[item]).map(([key, value], idx)=> {
                                    const keyItems = keys[`${item}_keys`];
                                    if (item === 'tat_route_list') {
                                      return (<React.Fragment>
                                        {idx===0 && <div className="list-section content divTableRow mb-20" key={`tata-${key+1}-${idx+1}`}>
                                          <div className='mb-10 mt-10'>{key.replace(/_/g, ' ')}</div>
                                        </div>}
                                        {
                                          (value || []).map((valItem, vId) => {
                                            return (<div className="list-section content divTableRow" key={`tatVal-${key}-${idx+1}-${vId+1}`}>
                                              {(keyItems || []).map((data, dId) => {
                                                let dataItem = valItem[data];
                                                if (data==='from.city_name' || data==='to.city_name' || data==='from.state_name' || data==='to.state_name') {
                                                  const splitWord = data.split('.');
                                                  dataItem = valItem[splitWord[0]][splitWord[1]];
                                                } 
                                                return (<div className="divTableCell" key={`sub-tat-${vId+1}-${idx+1}-${dId+1}`}> {dataItem} </div>)
                                              })}
                                            </div>)
                                          })
                                        }
                                      </React.Fragment>)
                                    } else {
                                      return (<React.Fragment>
                                        {idx===0 && <div className="list-section content divTableRow mb-20" key={`odaa-${key+1}-${idx+1}`}>
                                          <div className='mb-10 mt-10'>{key.replace(/_/g, ' ')}</div>
                                        </div>}
                                        {
                                          (value || []).map((valItem, vId) => {
                                            return (<div className="list-section content divTableRow" key={`oda-row-${key}-${idx+1}-${vId+1}`}>
                                              {(keyItems || []).map((data, dId) => {
                                                return (<div className="divTableCell" key={`oda-${vId+1}-${idx+1}-${dId+1}`}> {valItem[data]} </div>)
                                              })}
                                            </div>)
                                          })
                                        }
                                      </React.Fragment>)
                                    }
                                  })
                                }
                              </React.Fragment>
                            }
                          </div>
                        </div>
                      </div>
                    )
                  }
                  return null
                })
              }

              {
                Object.keys(vendorMDGDetails || {}).map((item, index) => {
                  if (item === 'dph_list' && vendorMDGDetails[item].length > 0) {
                    const tab = item.replace(/_/g, ' ');
                    return (
                      <div className={`top-margin-20 ${item}`}>
                        <div className="top-margin-20">DPH LANES LIST</div>

                        <div className={`divTable company-list-wrap`}>
                          <div className="list-section table-header divTableHeading divTableRow">
                              { (dph_lanes_list_header).map((head) => { return (<div className="divTableCell"> {head} </div>) }) }
                          </div>
                          <div className="divTableBody">
                            {(vendorMDGDetails[item] || []).map((detail, dIndex) => {
                              const keyItems = dph_lanes_list_keys;
                              return (
                                <div className="list-section content divTableRow" key={`lanes-${(dIndex+1)}`}>
                                  {(keyItems || []).map((dataArr, dId) => {
                                    return (<div className="divTableCell" key={`lanes-${(dIndex+1)}`}>
                                      {(detail.lane || []).map((data) => {
                                        // const oldRate = (data[dataArr].rate || 0) - parseFloat((data[dataArr].last_month_approved_rate_diff) || 0);
                                        return (
                                          <div> {data[dataArr]} </div>
                                        )
                                      })}
                                    </div>)
                                  })}
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    )
                  }
                })
              }
            </div>
            {
              // <div id="editor"
              // >
              //   <SampleFile vendorMDGDetails={vendorMDGDetails} 
              //     setChildData={this.setChildData} 
              //     company={viewItem.company} 
              //     headers={headers}
              //     delay_penalty_list_keys={delay_penalty_list_keys}
              //     keys={keys}
              //   />
              // </div>
            }

            {
              // <CKEditor
              //   editor={ClassicEditor}
              //   data={''}
              //   onInit={ editor => {
              //     // You can store the "editor" and use when it is needed.
              //     console.log( 'Editor is ready to use!', editor );
              //   } }
              //   onChange={ ( event, editor ) => {
              //     // const data = editor.getData();
              //     console.log( { event, editor } );
              //   } }
              //   onBlur={ editor => {
              //     console.log( 'Blur.', editor );
              //   } }
              //   onFocus={ editor => {
              //     console.log( 'Focus.', editor );
              //   } }
              // />
            }

            <div>

            </div>

            <div className="tCenter" ref={this.scrolltoPdf}>
              <div>
                <h2> Pre-requists to download contract</h2>
              </div>
              <div>
                <h3> Contract Tenture </h3>
                <div className="dateTenture">
                From : <MyDatePicker
                    id="from"
                    startDate={this.state.tentureFrom}
                    handleChange={(id, date) => { this.setState({ tentureFrom: new Date(date).getTime() }) }}
                />
                </div>
                <div className="dateTenture">
                To : <MyDatePicker
                    id="to"
                    startDate={this.state.tentureTo}
                    handleChange={(id, date) => { this.setState({ tentureTo: new Date(date).getTime() }) }}
                />
                </div>
              </div>

              <div className="top-margin-20">
                Address: <div><InputText type="text" changeHandler={(value, id) => this.setState({ address: value })} value={this.state.address} placeholder="Address" /></div>
              </div>
            </div>

            <div style={{ margin: '0 10%' }}>
              <div className="preview-header">Preview Document</div>
            </div>
          {
            <div id="sampleEditor" style={{ 
              height: '700px', 
              border: '1px solid #e0e0e0', 
              margin: '0 10%', 
              padding: '4% 5%', 
              overflow: 'scroll' 
            }}>
              <SampleFile vendorMDGDetails={vendorMDGDetails} company={viewItem.company} 
                headers={headers}
                delay_penalty_list_keys={delay_penalty_list_keys}
                keys={keys}
                setChildData={this.setChildData}
                tentureFrom={tentureFrom}
                tentureTo={tentureTo}
                address={address}
                allDropdownTypes={this.props.allDropdownTypes}
               />
            </div>
          }
            <div style={{ textAlign: 'center' }}>
              <button onClick={this.generatePdf} disabled={!tentureFrom || !tentureTo || !address} className="top-margin-20 button-classic" style={{ textAlign: 'center' }}>Download Full Contract</button>
            </div>
            <div style={{ marginTop: '10px', position: 'fixed', bottom: '1%', right: '1%' }}>
              <button className="button-classic" onClick={() => { this.scrollToTop.current.scrollIntoView({ behavior: 'smooth' }) }}>Scroll To Top</button>
            </div> 
          </div>
        }
        {this.state.viewVendorInfo && <VendorDetails toggleAdd={this.toggleAdd} id={this.state.viewIndex}/>}
        {this.state.isRemarksPopUp && <BlockListedRemarks onCancel={this.onCancel} onChangeHandler={this.onChangeHandler} onSave={this.onSave} blacklisted_remarks={details.company_vendor_details[this.state.dataIndex].blacklisted_remarks}/>}
      </div>
    );
  }
}
const SubTitle = props => {
  return (
    <div className="sub-title-section-wrap">
      <div className="circle" />
      <div className="text">{props.title}</div>
      <div className="underline" />
    </div>
  );
};
const mapStateToProps = state => {
  return {
      loader: state.loader,
      msgpopup: state.msgpopup,
      dataGovernance: state.dataGovernance,
  }
}

export default connect(mapStateToProps)(CompanyCategory);
