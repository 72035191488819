import React, { Component, Fragment } from 'react';
import "./contracts_management.less";
import Select from 'react-select';
import YesNoComponent from "../../common/components/yes-no-component/yes_no_component";
import DaysHoursMin from "../../common/components/days-hours-minutes/days-hours-min";
import { connect } from 'react-redux';
import ACTIONS from "../../common/action_constant";
import MSGPopUp from "../../common/components/msg-popup/msg-popup";
import Loader from "../../common/components/loader/loader";
import SelectBranches from "./select-branches/select-branches";
import FuelEscalationDetails from './fuel-escalation-details/fuel-escalation-details';
import validate from "../../../utils/validate";
import Rupee from "../../../assets/images/rupee.svg";
import Dropup from "../../../assets/images/drop-up-arrow.svg";
import deleteIcon from "../../../assets/images/delete.svg";
import plusIcon from "../../../assets/images/plus-theme.svg";
import { DEEPAK_COMPANY_ID, GREENPLY_COMPANY_IDS, scroll, TAT_UNITS, getUserCompanyId, EMAMI } from "../../../utils/common";

import { challengePriceWithCounter, companyIds } from "../../../utils/constants";
import Delete from "../../../assets/images/delete.svg";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import Moment from 'moment';
import { isCompanyAdmin, AWL_COMPANY_IDS, CARGILL_COMPANY_IDS, ROQUETTE, CENTURY_PLY } from '../../../utils/common';
import SelectT1 from '../../components/common/Select/SelectT1';


class ContractsManagement extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isError: false,
         errorMessage: '',
         applyToOtherBranches: false,
         selectedOption: null,
         selectedRfq: null,
         selectedBiddingCategory: null,
         selectedTime: null,
         selectedVendorBidding: null,
         selectedDataStorage: null,
         selectedVehiclePlacement: null,
         selectedLppCalculation: null,
         selectedTransporterTerms: null,
         selectedBiddingRateType: null,
         selectedBiddingRateEvents: null,
         selectedChallengePrice: null,
         selectedBidExtension: null,
         selectedMinimumBidders: null,
         // selectedLightCapabilityStatus: null,
         selectedLightCapabilityStatus: null,
         selectedLoiIssueRights: null,
         selectedBiddingTimeManage: null,
         selectedTransportService: null,
         selectedCargo: null,
         type2Name: null,
         type2email: null,
         selectedApproverEmail: null,
         type1: [],
         type2: [],
         type3: [],
         type4: [],
         type5: [],
         type6: [],
         approver_emails_list: [],
         transporter_service_type: null,
         cargo_unit_type: null,
         type3Name: null,
         type3email: null,
         type3pre_alert: null,
         type4Name: null,
         type4email: null,
         approver_List: null,
         deleteApproverList: [],
         selectedChallengePriceCounter: null,
         selectedBidEndTime: null,
         productCategoryName: [],
         tempProductCategorObj: [],
         tempSelectedProductCategor: [],
         selectedPackagingTypeName: [],
         tempPackagingTypeObj: [],
         tempSelectedPakagingType: [],
         selectedPricingBasisWithPrice: null,
         packagingTypeObjList: [],
         pricingBasisName: [],
         tempPricingBasisObj: [],
         tempSelectedPricingBasis: [],
         vehicleTypeName: [],
         tempVehicleTypeObj: [],
         tempSelectedVehicleTypeName: [],
         vehicleBodyTypeName: [],
         tempVehicleBodyObj: [],
         tempSelectedVehicleBodyName: [],
         uomName: [],
         tempUomNameObj: [],
         tempSelectedUomName: [],
         loadabilityValues: [],
         tempLoadabilityObj: [],
         tempSelectedLoadabilityValues: [],
         errormessage: '',
         responseObj: {
            "company_id": null,
            "branch_id": null,
            "rfq_issue_type": null,
            "rfq_issue_id": null,
            "rfq_issue_name": null,
            "visibility_of_non_bidders_before_bid_closure": false,
            "bidding_category_type": null,
            "bidding_category_id": null,
            "bidding_category_name": null,
            "rfq_deadline_alert_management": false,
            "time_type": null,
            "time_id": null,
            "time_name": null,
            "vendor_bidding_screen_type": null,
            "vendor_bidding_screen_id": null,
            "vendor_bidding_screen_name": null,
            "share_rfq_info_with_vendors": false,
            "data_storage_type": null,
            "data_storage_id": null,
            "data_storage_name": null,
            "bid_increment_amount": null,
            "group_bid_decremental_amount": null,
            "vehicle_placement_type": null,
            "vehicle_placement_id": null,
            "vehicle_placement_name": null,
            "lpp_calculation_type": null,
            "lpp_calculation_id": null,
            "lpp_calculation_name": null,
            "qbr_process": false,
            "l1_rate_decremental": false,
            "l1_rate_decremental_amount": null,
            "l1_rate_decremental_first_bidder": false,
            "l1_rate_decremental_amount_first_bidder": null,
            "counter_offer_applicable": false,
            "transporter_terms_type": null,
            "transporter_terms_id": null,
            "transporter_terms_name": null,
            "rfq_amend": false,
            "rfq_amend_count": "",
            "bidding_rate_types_type": null,
            "bidding_rate_types_id": null,
            "bidding_rate_types_name": null,
            "bidding_rate_events_type": null,
            "bidding_rate_events_id": null,
            "bidding_rate_events_name": null,
            "rate_end_with_zero": false,
            "challenge_price_type": null,
            "challenge_price_id": null,
            "challenge_price_name": null,
            "bid_extension_type": null,
            "bid_extension_id": null,
            "bid_extension_name": null,
            "l1_accept_mandatory": false,
            "bid_ranking_include_time_of_bid": false,
            "auto_extension_only_l1_bid": false,
            "auto_send_loi_only_l1_bid": false,
            "rate_record_capture_based_system_config": false,
            "minimum_bidders_to_create_rfq": false,
            "minimum_bidders_count_to_create_rfq": 5,
            "show_ceiling_price_before_bid_start": false,
            "cancel_loi_enable_or_disable": false,
            "minimum_count_send_to_cancel_loi": 3,
            "maximum_count_to_withdraw_bid": 1,
            "hide_ceiling_price": false,
            "min_bid_time_permit": {
               "allow": false,
               "days": null,
               "hours": null,
               "minutes": null
            },
            "min_bid_time_extension_permit": {
               "allow": false,
               "days": null,
               "hours": null,
               "minutes": null
            },
            "min_loi_acceptance_permit": {
               "allow": false,
               "days": null,
               "hours": null,
               "minutes": null
            },
            "minimum_bidders_type": null,
            "minimum_bidders_id": null,
            "minimum_bidders_name": "1",
            "light_compatibility_type": null,
            "light_compatibility_id": null,
            "light_compatibility_name": null,
            "loi_issue_rights": false,
            "loi_issue_rights_type": null,
            "loi_issue_rights_id": null,
            "loi_issue_rights_name": null,
            "bid_time_management_type": null,
            "bid_time_management_id": null,
            "bid_time_management_name": null,
            "bid_time_management_move": null,
            "bid_time_management_auto": null,
            "bid_time_management_count": null,
            "bid_time_management_relay": null,
            "allow_business_volume": false,
            "loi_with_challenge_price": false,
            "challenge_price_with_accept_or_reject": false,
            "challenge_price_with_counter": null,
            "auto_loi_accept": false,
            "bid_end_time_type": null,
            "bid_end_time_id": null,
            "bid_end_time_name": "1",
            "approval_matrix": [],
            loi_acceptance_period: null,
            "enable_tolerance": false,
            "enable_tolerance_amount": null,
            "indent_cutoff_config": false,
            "indent_cutoff_config_value": null,
            "indent_cutoff_config_value_uom": null,
            "vehicle_placement_config": false,
            "vehicle_placement_config_value": null,
            "vehicle_placement_config_value_uom": null,
            "cp_rate_decremental": false,
            "cp_rate_decremental_amount": null,
            "show_bid_started_message": false,
            "pricing_basis_qbr_rate": null,
            "pricing_bassis_with_qbr_rate_id": null,
            "pricing_bassis_with_qbr_rate_name": null,
            "pricing_bassis_with_qbr_rate_type": null,
            "visibility_of_bidding_status_after_loi_Sent": false,
            "round_bidding": false,
            "withdraw_bid": true,
            "price_increase": false,
            "ip_address_enable": false,
            "email_sms_enable": false,
            "rfq_amendment": false,
            "share_of_business": false,
            "chat_box_enable": false,
            "total_value_visibility_on_provider": false,
            "total_value_visibility_of_L1_on_provider": false,
            "visibility_of_lot_ranking_for_provider": false,
            "challenge_price_accept_or_reject_time": false,
            "live_bidding_transporter_addition": false,
            "transporter_ranking_range": "",
            "is_loi_ceiling_accepted": false,
            "deduction_price": null,
            "bidding_via_whatsapp": false,
         },
         auto_decrement_range: [{
            fromRange: "1",
            toRange: "",
            decrementAmount: ""
         }],
         checkDropDown: [false, false, false, false, false, false, false, false, false],
         first: {
            "days": "",
            "hours": "",
            "mins": ""
         },
         second: {
            "days": "",
            "hours": "",
            "mins": ""
         },
         third: {
            "days": "",
            "hours": "",
            "mins": ""
         },
         selectedUserRole: null,
         approver_values: [],
         currencyObj: {
            "usdtoinr": "",
            "eurtoinr": "",
            "gbptoinr": ""
         },
         rfqType: 1,
         contractsRFQType: 1,
         contractTenureType: 1
      }
   }
   componentDidMount() {
      const { dispatch } = this.props;
      let data = {
         company_id: this.props.company_id,
         branch_id: this.props.branch_id
      }
      // this.getBasicContractDetails();
      this.getAutoBidDecrementDetails()
      // dispatch({ type: ACTIONS.CONTRACTS.GET_BASIC_CONTRACT_SETTING, data });
      dispatch({ type: ACTIONS.CONTRACTS.GET_OTHER_CONTRACT_SETTING });
      dispatch({ type: ACTIONS.CONTRACTS.GET_APPROVER_TYPES });
      dispatch({ type: ACTIONS.CONTRACTS.GET_CURRENCY_DETAILS, data });
      const isCmpyAdmin = isCompanyAdmin();
      dispatch({ type: ACTIONS.CONTRACTS.GET_MASTER_CONTRACT_DATA, isSuperAdmin: !isCmpyAdmin, data });
      dispatch({ type: ACTIONS.CONTRACTS.GET_PLANT_WISE_CONTRACT_DATA, data: { ...data, rfqType: this.state.rfqType }, isSuperAdmin: !isCmpyAdmin });
      this.getInsideTabDropDownName(115);
      // if(!isCmpyAdmin) {
      dispatch({ type: ACTIONS.HOME.GET_RFQ_TYPES });
      dispatch({
         type: ACTIONS.HOME.GET_MODULES_LIST, company_id: this.props.company_id, company_type: 1, onSuccess: (data) => {
            this.setState({ rfqType: ((data[0] || {}).rfq_types || [])[0], contractsRFQType: ((data[0] || {}).rfq_types || [])[0] }, this.getBasicContractDetails)
         }
      });
      // }
      dispatch({
         type: ACTIONS.HOME.GET_CONTRACT_TENURE_TYPES
      })
   }

   getInsideTabDropDownName = (type) => {
      const { dispatch, company_id } = this.props;
      dispatch({
          type: ACTIONS.MASTER.MASTER_DROP_DOWN.GET_INSIDE_TAB_DROPDOWN,
          param: `${company_id}&dropdown_type_id=${type}`,
      })
  }

   componentWillReceiveProps(newprops) {
      if (newprops.contracts.basicContract) {
         let obj = {
            label: (newprops.contracts.basicContract.challenge_price_with_counter === -1) ? 'Any' : newprops.contracts.basicContract.challenge_price_with_counter,
            value: newprops.contracts.basicContract.challenge_price_with_counter,
         }

         this.setState({
            selectedChallengePriceCounter: obj
         })

      } else {
         this.setState({
            selectedChallengePriceCounter: null
         })
      }
      if ((this.props.contracts !== newprops.contracts) && newprops.contracts.basicContract) {
         this.setState({
            approver_List: newprops.contracts.basicContract.approver_email_list
         });
      }
      if (newprops.contracts.basicContract) {
         let obj = {
            label: newprops.contracts.basicContract.rfq_issue_name,
            value: newprops.contracts.basicContract.rfq_issue_id,
            type: newprops.contracts.basicContract.rfq_issue_type
         }
         this.setState({
            selectedRfq: obj
         })
      } else {
         this.setState({
            selectedRfq: null
         })
      }

      if (newprops.contracts.basicContract) {
         let obj = {
            label: newprops.contracts.basicContract.bidding_category_name,
            value: newprops.contracts.basicContract.bidding_category_id,
            type: newprops.contracts.basicContract.bidding_category_type
         }
         this.setState({
            selectedBiddingCategory: obj
         })
      } else {
         this.setState({
            selectedBiddingCategory: null
         })

      }
      if (newprops.contracts.basicContract) {
         let obj = {
            label: newprops.contracts.basicContract.time_name,
            value: newprops.contracts.basicContract.time_id,
            type: newprops.contracts.basicContract.time_type
         }
         this.setState({
            selectedTime: obj
         })
      } else {
         this.setState({
            selectedTime: null
         })
      }
      if (newprops.contracts.basicContract) {
         let obj = {
            value: newprops.contracts.basicContract.vendor_bidding_screen_id,
            label: newprops.contracts.basicContract.vendor_bidding_screen_name,
            type: newprops.contracts.basicContract.vendor_bidding_screen_type
         }
         this.setState({
            selectedVendorBidding: obj
         })
      } else {
         this.setState({
            selectedVendorBidding: null
         })
      }
      if (newprops.contracts.basicContract) {
         let obj = {
            value: newprops.contracts.basicContract.data_storage_id,
            label: newprops.contracts.basicContract.data_storage_name,
            type: newprops.contracts.basicContract.data_storage_type
         }
         this.setState({
            selectedDataStorage: obj
         })
      } else {
         this.setState({
            selectedDataStorage: null
         })
      }
      if (newprops.contracts.basicContract) {
         let obj = {
            value: newprops.contracts.basicContract.vehicle_placement_id,
            label: newprops.contracts.basicContract.vehicle_placement_name,
            type: newprops.contracts.basicContract.vehicle_placement_type
         }
         this.setState({
            selectedVehiclePlacement: obj
         })
      } else {
         this.setState({
            selectedVehiclePlacement: null
         })
      }
      if (newprops.contracts.basicContract) {
         let obj = {
            type: newprops.contracts.basicContract.lpp_calculation_type,
            value: newprops.contracts.basicContract.lpp_calculation_id,
            label: newprops.contracts.basicContract.lpp_calculation_name
         }
         this.setState({
            selectedLppCalculation: obj
         })
      } else {
         this.setState({
            selectedLppCalculation: null
         })
      }
      if (newprops.contracts.basicContract) {
         let obj = {
            type: newprops.contracts.basicContract.transporter_terms_type,
            value: newprops.contracts.basicContract.transporter_terms_id,
            label: newprops.contracts.basicContract.transporter_terms_name
         }
         this.setState({
            selectedTransporterTerms: obj
         })
      } else {
         this.setState({
            selectedTransporterTerms: null
         })
      }
      if (newprops.contracts.basicContract) {
         let obj = {
            type: newprops.contracts.basicContract.bidding_rate_types_type,
            value: newprops.contracts.basicContract.bidding_rate_types_id,
            label: newprops.contracts.basicContract.bidding_rate_types_name
         }
         this.setState({
            selectedBiddingRateType: obj
         })
      } else {
         this.setState({
            selectedBiddingRateType: null
         })
      }
      if (newprops.contracts.basicContract) {
         let obj = {
            type: newprops.contracts.basicContract.bidding_rate_events_type,
            value: newprops.contracts.basicContract.bidding_rate_events_id,
            label: newprops.contracts.basicContract.bidding_rate_events_name
         }
         this.setState({
            selectedBiddingRateEvents: obj
         })
      } else {
         this.setState({
            selectedBiddingRateEvents: null
         })
      }
      if (newprops.contracts.basicContract) {
         let obj = {
            type: newprops.contracts.basicContract.challenge_price_type,
            value: newprops.contracts.basicContract.challenge_price_id,
            label: newprops.contracts.basicContract.challenge_price_name
         }
         this.setState({
            selectedChallengePrice: obj
         })
      } else {
         this.setState({
            selectedChallengePrice: null
         })
      }
      if (newprops.contracts.basicContract) {
         let obj = {
            type: newprops.contracts.basicContract.bid_extension_type,
            value: newprops.contracts.basicContract.bid_extension_id,
            label: newprops.contracts.basicContract.bid_extension_name
         }
         this.setState({
            selectedBidExtension: obj
         })
      } else {
         this.setState({
            selectedBidExtension: null
         })
      }
      if (newprops.contracts.basicContract) {
         let obj = {
            type: newprops.contracts.basicContract.minimum_bidders_type,
            value: newprops.contracts.basicContract.minimum_bidders_id,
            label: newprops.contracts.basicContract.minimum_bidders_name
         }
         this.setState({
            selectedMinimumBidders: obj
         })
      } else {
         this.setState({
            selectedMinimumBidders: null
         })
      }
      if (newprops.contracts.basicContract) {
         let obj = {
            type: newprops.contracts.basicContract.light_compatibility_type,
            value: newprops.contracts.basicContract.light_compatibility_id,
            label: newprops.contracts.basicContract.light_compatibility_name
         }
         this.setState({
            selectedLightCapabilityStatus: obj
         })
      } else {
         this.setState({
            selectedLightCapabilityStatus: null
         })
      }
      if (newprops.contracts.basicContract) {
         let obj = {
            type: newprops.contracts.basicContract.light_compatibility_type,
            value: newprops.contracts.basicContract.light_compatibility_id,
            label: newprops.contracts.basicContract.light_compatibility_name
         }
         this.setState({
            selectedLightCapabilityStatus: obj
         })
      } else {
         this.setState({
            selectedLightCapabilityStatus: null
         })
      }
      if (newprops.contracts.basicContract) {
         let obj = {
            type: newprops.contracts.basicContract.loi_issue_rights_type,
            value: newprops.contracts.basicContract.loi_issue_rights_id,
            label: newprops.contracts.basicContract.loi_issue_rights_name
         }
         this.setState({
            selectedLoiIssueRights: obj
         })
      } else {
         this.setState({
            selectedLoiIssueRights: null
         })
      }

      if (newprops.contracts.basicContract) {
         let obj = {
            type: newprops.contracts.basicContract.bid_time_management_type,
            value: newprops.contracts.basicContract.bid_time_management_id,
            label: newprops.contracts.basicContract.bid_time_management_name
         }
         this.setState({
            selectedBiddingTimeManage: obj
         })
      } else {
         this.setState({
            selectedBiddingTimeManage: null
         })
      }
      if (newprops.contracts.basicContract) {
         if (!newprops.contracts.basicContract.withdraw_bid && newprops.contracts.basicContract.withdraw_bid !== false) {
            this.setState({
               responseObj: { ...newprops.contracts.basicContract, withdraw_bid: true }
            })
         } else {
            this.setState({
               responseObj: newprops.contracts.basicContract
            })
         }

      }
      if (!newprops.contracts.basicContract) {
         const data = this.initializeObject();
         this.setState({
            responseObj: data
         });
      }
      if (!this.state.type1 && newprops.type1) {
         this.setState({
            type1: newprops.type1
         });
      }
      if (!this.state.type2 && newprops.type2) {
         this.setState({
            type2: newprops.type2
         });
      }
      if (!this.state.type2 && newprops.type2) {
         this.setState({
            type3: newprops.type3
         });
      }
      if (!this.state.type2 && newprops.type2) {
         this.setState({
            type4: newprops.type4
         });
      }

      if (!this.state.type5 && newprops.contracts.type5) {
         this.setState({
            type5: newprops.type5
         });
      }
      if (!this.state.type6 && newprops.contracts.type6) {
         this.setState({
            type6: newprops.contracts.type6
         });
      }
      if (newprops.contracts.otherContract && !this.state.transporter_service_type)
         this.setState({
            transporter_service_type: newprops.contracts.otherContract.transporter_service.type,
            cargo_unit_type: newprops.contracts.otherContract.cargo_unit.type
         })

      if (newprops.contracts.basicContract) {
         let obj = {
            type: newprops.contracts.basicContract.bid_end_time_type,
            value: newprops.contracts.basicContract.bid_end_time_id,
            label: newprops.contracts.basicContract.bid_end_time_name
         }
         this.setState({
            selectedBidEndTime: obj
         })
      } else {
         this.setState({
            selectedBidEndTime: null
         })
      }
      if (newprops.contracts.currencyDetails && newprops.contracts.currencyDetails.length > 0) {
         let obj = {
            usdtoinr: newprops.contracts.currencyDetails && newprops.contracts.currencyDetails[0] && newprops.contracts.currencyDetails[0].usdtoinr,
            eurtoinr: newprops.contracts.currencyDetails && newprops.contracts.currencyDetails[1] && newprops.contracts.currencyDetails[1].eurtoinr,
            gbptoinr: newprops.contracts.currencyDetails && newprops.contracts.currencyDetails[2] && newprops.contracts.currencyDetails[2].gbptoinr
         }
         this.setState({
            currencyObj: obj
         });
      } else {
         let obj = {
            usdtoinr: "",
            eurtoinr: "",
            gbptoinr: ""
         };
         this.setState({
            currencyObj: obj
         });
      }

      if (newprops.contracts.autoDecrementDetails && newprops.contracts.autoDecrementDetails.auto_decrement_range && newprops.contracts.autoDecrementDetails.auto_decrement_range.length > 0) {
         this.setState({ auto_decrement_range: newprops.contracts.autoDecrementDetails.auto_decrement_range })
      }
      else {
         this.setState({
            auto_decrement_range:
               [{
                  fromRange: '1',
                  toRange: "",
                  decrementAmount: ""
               }]
         })
      }

      if (newprops.contracts.basicContract) {
         let obj = {
            type: newprops.contracts.basicContract.pricing_bassis_with_qbr_rate_type,
            value: newprops.contracts.basicContract.pricing_bassis_with_qbr_rate_id,
            label: newprops.contracts.basicContract.pricing_bassis_with_qbr_rate_name
         }
         this.setState({
            selectedPricingBasisWithPrice: obj
         })
      } else {
         this.setState({
            selectedPricingBasisWithPrice: null
         })
      }
      if (newprops.contracts.plantWiseContract && newprops.contracts.plantWiseContract.packing_types && (newprops.contracts.plantWiseContract.packing_types[0] || []).packing_list.length > 0) {
         let packagingType = newprops.contracts.plantWiseContract.packing_types[0].packing_list.map((ref) => { return { name: ref.name, id: ref.id } })
         this.setState({ selectedPackagingTypeName: packagingType });
      } else {
         this.setState({ selectedPackagingTypeName: [] });
      }
      if (newprops.contracts.plantWiseContract && newprops.contracts.plantWiseContract.product_category[0] && (newprops.contracts.plantWiseContract.product_category[0] || []).product_list.length > 0) {
         let productcategory = newprops.contracts.plantWiseContract.product_category[0].product_list.map((ref) => { return { name: ref.name, id: ref.id, density: (ref.density) ? ref.density : 1.0 } })
         this.setState({ productCategoryName: productcategory });
      } else {
         this.setState({ productCategoryName: [] });
      }

      if (newprops.contracts.plantWiseContract && newprops.contracts.plantWiseContract.pricing_basis[0] && (newprops.contracts.plantWiseContract.pricing_basis[0] || []).pricing_basis_list.length > 0) {
         let pricingbasis = newprops.contracts.plantWiseContract.pricing_basis[0].pricing_basis_list.map((ref) => { return { name: ref.name, id: ref.id } })
         this.setState({ pricingBasisName: pricingbasis });
      } else {
         this.setState({ pricingBasisName: [] });
      }

      if (newprops.contracts.plantWiseContract && newprops.contracts.plantWiseContract.vehicle_types[0] && (newprops.contracts.plantWiseContract.vehicle_types[0] || []).vehicle_types_list.length > 0) {
         let vehicleName = newprops.contracts.plantWiseContract.vehicle_types[0].vehicle_types_list.map((ref) => { return { name: ref.name, id: ref.id, vehicle_capacity_MT: (ref.vehicle_capacity_MT) ? ref.vehicle_capacity_MT : 0, vehicle_capacity: ref.vehicle_capacity ? ref.vehicle_capacity : 0, wheel_count: ref.wheel_count || 0, sap_vehicle_capacity: ref.sap_vehicle_capacity || '', sap_vehicle_type: ref.sap_vehicle_type || '', equipment_type: ref.equipment_type || undefined, equipment_type_id: ref.equipment_type_id || undefined } })
         this.setState({ vehicleTypeName: vehicleName });
      } else {
         this.setState({ vehicleTypeName: [] });
      }

      if (newprops.contracts.plantWiseContract && newprops.contracts.plantWiseContract.vehicle_body_types[0] && (newprops.contracts.plantWiseContract.vehicle_body_types[0] || []).vehicle_body_types_list.length > 0) {
         let vehicleBodyName = newprops.contracts.plantWiseContract.vehicle_body_types[0].vehicle_body_types_list.map((ref) => { return { name: ref.name, id: ref.id } })
         this.setState({ vehicleBodyTypeName: vehicleBodyName });
      } else {
         this.setState({ vehicleBodyTypeName: [] });
      }

      if (newprops.contracts.plantWiseContract && newprops.contracts.plantWiseContract.business_volume[0] && (newprops.contracts.plantWiseContract.business_volume[0] || []).business_volume_list.length > 0) {
         let uom = newprops.contracts.plantWiseContract.business_volume[0].business_volume_list.map((ref) => { return { name: ref.name, id: ref.id } })
         this.setState({ uomName: uom });
      } else {
         this.setState({ uomName: [] });
      }
   }

   getAutoBidDecrementDetails = () => {
      const { dispatch } = this.props;
      dispatch({
         type: ACTIONS.CONTRACTS.GET_AUTO_DECREMENT_DATA,
         data: `?companyId=${this.props.company_id}`
      })
   }

   addAutoBidDecrement = () => {
      const { auto_decrement_range } = this.state;
      const { dispatch, contracts } = this.props;

      const payload = {
         company_id: this.props.company_id,
         branch_id: this.props.branch_id,
         auto_decrement_range: auto_decrement_range,
         ...(contracts.autoDecrementDetails && contracts.autoDecrementDetails.id && { id: contracts.autoDecrementDetails.id })
      }

      dispatch({
         type: ACTIONS.CONTRACTS.ADD_AUTO_DECREMENT_DATA,
         data: payload,
         onSuccess: (() => {
            this.getAutoBidDecrementDetails()
         })
      })
   }

   changeYesNo = (val, key, subKey) => {
      let tempObj = this.state.responseObj;
      if (subKey) {
         tempObj[key][subKey] = val;

      } else {
         tempObj[key] = val;
      }
      this.setState({
         responseObj: tempObj
      })
   }
   accessibilityYesNo = (val, key) => {
      this.setState({
         [key]: val
      })
   }
   initializeObject = () => {
      let responseObj = {
         "company_id": null,
         "branch_id": null,
         "rfq_issue_type": null,
         "rfq_issue_id": null,
         "rfq_issue_name": null,
         "visibility_of_non_bidders_before_bid_closure": false,
         "bidding_category_type": null,
         "bidding_category_id": null,
         "bidding_category_name": null,
         "rfq_deadline_alert_management": false,
         "time_type": null,
         "time_id": null,
         "time_name": null,
         "vendor_bidding_screen_type": null,
         "vendor_bidding_screen_id": null,
         "vendor_bidding_screen_name": null,
         "share_rfq_info_with_vendors": false,
         "data_storage_type": null,
         "data_storage_id": null,
         "data_storage_name": null,
         "bid_increment_amount": null,
         "group_bid_decremental_amount": null,
         "vehicle_placement_type": null,
         "vehicle_placement_id": null,
         "vehicle_placement_name": null,
         "lpp_calculation_type": null,
         "lpp_calculation_id": null,
         "lpp_calculation_name": null,
         "qbr_process": false,
         "l1_rate_decremental": false,
         "l1_rate_decremental_amount": null,
         "l1_rate_decremental_first_bidder": false,
         "l1_rate_decremental_amount_first_bidder": null,
         "counter_offer_applicable": false,
         "transporter_terms_type": null,
         "transporter_terms_id": null,
         "transporter_terms_name": null,
         "rfq_amend": false,
         "rfq_amend_count": null,
         "bidding_rate_types_type": null,
         "bidding_rate_types_id": null,
         "bidding_rate_types_name": null,
         "bidding_rate_events_type": null,
         "bidding_rate_events_id": null,
         "bidding_rate_events_name": null,
         "rate_end_with_zero": false,
         "challenge_price_type": null,
         "challenge_price_id": null,
         "challenge_price_name": null,
         "bid_extension_type": null,
         "bid_extension_id": null,
         "bid_extension_name": null,
         "tat_applicable": false,
         "min_bid_time_permit": {
            "allow": false,
            "days": null,
            "hours": null,
            "minutes": null
         },
         "min_bid_time_extension_permit": {
            "allow": false,
            "days": null,
            "hours": null,
            "minutes": null
         },
         "min_loi_acceptance_permit": {
            "allow": false,
            "days": null,
            "hours": null,
            "minutes": null
         },
         "minimum_bidders_type": null,
         "minimum_bidders_id": null,
         "minimum_bidders_name": "1",
         "light_compatibility_type": null,
         "light_compatibility_id": null,
         "light_compatibility_name": null,
         "loi_issue_rights": false,
         "loi_issue_rights_type": null,
         "loi_issue_rights_id": null,
         "loi_issue_rights_name": null,
         "bid_time_management_type": null,
         "bid_time_management_id": null,
         "bid_time_management_name": null,
         "bid_time_management_move": null,
         "bid_time_management_auto": null,
         "bid_time_management_count": null,
         "bid_time_management_relay": null,
         "approver_email_list": [],
         "approval_matrix": [],
         "delete_email_list": null,
         "allow_business_volume": false,
         "loi_with_challenge_price": false,
         "challenge_price_with_accept_or_reject": false,
         "auto_loi_accept": false,
         "bid_end_time_type": null,
         "bid_end_time_id": null,
         "bid_end_time_name": "1",
         "loi_acceptance_period": null,
         "enable_tolerance": false,
         "enable_tolerance_amount": null,
         "indent_cutoff_config": false,
         "indent_cutoff_config_value": null,
         "indent_cutoff_config_value_uom": null,
         "vehicle_placement_config": false,
         "vehicle_placement_config_value": null,
         "vehicle_placement_config_value_uom": null,
         "l1_accept_mandatory": false,
         "bid_ranking_include_time_of_bid": false,
         "show_ceiling_price_before_bid_start": false,
         "auto_extension_only_l1_bid": false,
         "auto_send_loi_only_l1_bid": false,
         "minimum_bidders_to_create_rfq": false,
         "minimum_bidders_count_to_create_rfq": 5,
         "cancel_loi_enable_or_disable": false,
         "rate_record_capture_based_system_config": false,
         "minimum_count_send_to_cancel_loi": 3,
         "maximum_count_to_withdraw_bid": 1,
         "cp_rate_decremental": false,
         "cp_rate_decremental_amout": null,
         "show_bid_started_message": false,
         "hide_ceiling_price": false,
         "visibility_of_bidding_status_after_loi_Sent": false,
         "round_bidding": false,
         "withdraw_bid": true,
         "price_increase": false,
         "ip_address_enable": false,
         "email_sms_enable": false,
         "rfq_amendment": false,
         "share_of_business": false,
         "chat_box_enable": false,
         "total_value_visibility_on_provider": false,
         "total_value_visibility_of_L1_on_provider": false,
         "visibility_of_lot_ranking_for_provider": false,
         "challenge_price_accept_or_reject_time": false,
         "live_bidding_transporter_addition": false,
         "transporter_ranking_range": "",
         "is_loi_ceiling_accepted": false,
         "deduction_price": null
      }
      return responseObj;
   }

   closeMsgPopup = () => {
      const { dispatch } = this.props;
      dispatch({ type: ACTIONS.MSG_DISPLAY.HIDE_MSG })
   }
   showMsgPopup = (data) => {
      this.props.dispatch({
         type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
         data: { type: 1, msg: data || "Something Went Wrong" }
      })
   }
   clickCustomeCheckBoxService = () => {
      this.setState({
         applyToOtherBranches: !this.state.applyToOtherBranches
      })
   }

   createCollapsibleSection = (type) => {
      let data;
      if (type == 1) {
         data = {
            "company_id": this.props.company_id,
            "branch_id": this.props.branch_id,
            alert_type: 1,
            transporter_service_type: this.state.transporter_service_type,
            transporter_service_id: this.state.selectedTransportService ? this.state.selectedTransportService.value : null,
            transporter_service_name: this.state.selectedTransportService ? this.state.selectedTransportService.label : null,
            cargo_unit_type: this.state.cargo_unit_type,
            cargo_unit_id: this.state.selectedCargo ? this.state.selectedCargo.value : null,
            cargo_unit_name: this.state.selectedCargo ? this.state.selectedCargo.label : null,
            rate: 1000
         }

      }
      else if (type == 2) {
         data =
         {
            "company_id": this.props.company_id,
            "branch_id": this.props.branch_id,
            alert_type: 2,
            "name": this.state.type2Name,
            "email": this.state.type2email,
         }


      }
      else if (type == 3) {
         data =
         {
            "company_id": this.props.company_id,
            "branch_id": this.props.branch_id,
            alert_type: 3,
            "name": this.state.type3Name,
            "email": this.state.type3email,
            pre_alert: this.state.type3pre_alert
         }


      }
      else if (type == 4) {
         data =
         {
            "company_id": this.props.company_id,
            "branch_id": this.props.branch_id,
            alert_type: 4,
            "name": this.state.type4Name,
            "email": this.state.type4email
         }


      }
      else if (type == 5) {
         //moved to createCollapsibleSectionForFuelEscalation
      }
      else if (type == 6) {
         if (!this.state.selectedUserRole) {
            this.showMsgPopup("Enter All the fields")
            return;
         } else {
            data = {
               company_id: this.props.company_id,
               branch_id: this.props.branch_id,
               alert_type: 6,
               user_name: this.state.selectedUserRole.label,
               user_id: this.state.selectedUserRole.value,
               accessibility: this.state.selectedRoleAccessibility
            }
         }
      }

      if (validate.isObjElementEmpty(data)) {
         this.setState({ error: "Please make sure you have entered everything" });
         const { dispatch } = this.props;
         dispatch({ type: ACTIONS.MSG_DISPLAY.SHOW_MSG, data: { type: 2, msg: "Please make sure you have entered everything" } });
         return;
      }
      const { dispatch } = this.props;
      dispatch({ type: ACTIONS.CONTRACTS.ADD_COLLAPSIBLE_SECTION, data });
   }
   addApproverEmailList = () => {
      this.state.selectedApproverEmail.forEach((item) => {
         this.state.approver_emails_list.push({ email: item.value });
      });
      if (this.props.contracts.basicContract) {
         this.editBasicContract();
      } else {
         this.addBasicContract();
      }
      this.state.approver_emails_list = [];
   }

   removeApproverEmail = (element) => {
      if (element.email !== null) {
         this.state.deleteApproverList.push({ email: element.email });
         var index = this.state.approver_List.findIndex(obj => obj.email === element.email);
         this.state.approver_List.splice(index, 1);
         this.editBasicContract();
         // this.setState({approver_List:this.state.approver_List});
      }
   }
   createCollapsibleSectionForFuelEscalation = (data) => {
      const { dispatch } = this.props;
      dispatch({ type: ACTIONS.CONTRACTS.ADD_COLLAPSIBLE_SECTION, data });
   }
   addBasicContract = () => {

      let data = this.state.responseObj;
      try {
         data.branch_id = this.props.branch_id;
         data.company_id = this.props.company_id;
         data.rfq_issue_name = this.state.selectedRfq && this.state.selectedRfq.label || null;
         data.rfq_issue_id = this.state.selectedRfq && this.state.selectedRfq.value || null;
         data.rfq_issue_type = this.state.selectedRfq && this.state.selectedRfq.type || null;
         data.bidding_category_name = this.state.selectedBiddingCategory && this.state.selectedBiddingCategory.label || null;
         data.bidding_category_id = this.state.selectedBiddingCategory && this.state.selectedBiddingCategory.value || null;
         data.bidding_category_type = this.state.selectedBiddingCategory && this.state.selectedBiddingCategory.type || null;
         data.time_id = this.state.selectedTime && this.state.selectedTime.value || null;
         data.time_name = this.state.selectedTime && this.state.selectedTime.label || null;
         data.time_type = this.state.selectedTime && this.state.selectedTime.type || null;
         data.vendor_bidding_screen_id = this.state.selectedVendorBidding && this.state.selectedVendorBidding.value || null;
         data.vendor_bidding_screen_name = this.state.selectedVendorBidding && this.state.selectedVendorBidding.label || null;
         data.vendor_bidding_screen_type = this.state.selectedVendorBidding && this.state.selectedVendorBidding.type || null;
         data.data_storage_id = this.state.selectedDataStorage && this.state.selectedDataStorage.value || null;
         data.data_storage_name = this.state.selectedDataStorage && this.state.selectedDataStorage.label || null;
         data.data_storage_type = this.state.selectedDataStorage && this.state.selectedDataStorage.type || null;
         data.vehicle_placement_id = this.state.selectedVehiclePlacement && this.state.selectedVehiclePlacement.value || null;
         data.vehicle_placement_name = this.state.selectedVehiclePlacement && this.state.selectedVehiclePlacement.label;
         data.vehicle_placement_type = this.state.selectedVehiclePlacement && this.state.selectedVehiclePlacement.type;
         data.lpp_calculation_id = this.state.selectedLppCalculation && this.state.selectedLppCalculation.value;
         data.lpp_calculation_name = this.state.selectedLppCalculation && this.state.selectedLppCalculation.label;
         data.lpp_calculation_type = this.state.selectedLppCalculation && this.state.selectedLppCalculation.type;
         data.transporter_terms_id = this.state.selectedTransporterTerms && this.state.selectedTransporterTerms.value;
         data.transporter_terms_name = this.state.selectedTransporterTerms && this.state.selectedTransporterTerms.label;
         data.transporter_terms_type = this.state.selectedTransporterTerms && this.state.selectedTransporterTerms.type;
         data.bidding_rate_events_id = this.state.selectedBiddingRateEvents && this.state.selectedBiddingRateEvents.value;
         data.bidding_rate_events_name = this.state.selectedBiddingRateEvents && this.state.selectedBiddingRateEvents.label;
         data.bidding_rate_events_type = this.state.selectedBiddingRateEvents && this.state.selectedBiddingRateEvents.type;
         data.bidding_rate_types_id = this.state.selectedBiddingRateType && this.state.selectedBiddingRateType.value;
         data.bidding_rate_types_name = this.state.selectedBiddingRateType && this.state.selectedBiddingRateType.label;
         data.bidding_rate_types_type = this.state.selectedBiddingRateType && this.state.selectedBiddingRateType.type;
         data.challenge_price_id = this.state.selectedChallengePrice && this.state.selectedChallengePrice.value;
         data.challenge_price_name = this.state.selectedChallengePrice && this.state.selectedChallengePrice.label;
         data.challenge_price_type = this.state.selectedChallengePrice && this.state.selectedChallengePrice.type;
         data.minimum_bidders_id = this.state.selectedMinimumBidders && this.state.selectedMinimumBidders.value;
         data.minimum_bidders_name = this.state.selectedMinimumBidders && this.state.selectedMinimumBidders.label;
         data.minimum_bidders_type = this.state.selectedMinimumBidders && this.state.selectedMinimumBidders.type;
         data.light_compatibility_id = this.state.selectedLightCapabilityStatus && this.state.selectedLightCapabilityStatus.value;
         data.light_compatibility_name = this.state.selectedLightCapabilityStatus && this.state.selectedLightCapabilityStatus.label;
         data.light_compatibility_type = this.state.selectedLightCapabilityStatus && this.state.selectedLightCapabilityStatus.type;
         data.loi_issue_rights_id = this.state.selectedLoiIssueRights && this.state.selectedLoiIssueRights.value;
         data.loi_issue_rights_name = this.state.selectedLoiIssueRights && this.state.selectedLoiIssueRights.label;
         data.loi_issue_rights_type = this.state.selectedLoiIssueRights && this.state.selectedLoiIssueRights.type;
         data.bid_time_management_id = this.state.selectedBiddingTimeManage && this.state.selectedBiddingTimeManage.value;
         data.bid_time_management_name = this.state.selectedBiddingTimeManage && this.state.selectedBiddingTimeManage.label;
         data.bid_time_management_type = this.state.selectedBiddingTimeManage && this.state.selectedBiddingTimeManage.type;
         data.bid_extension_id = this.state.selectedBiddingTimeManage && this.state.selectedBiddingTimeManage.value;
         data.bid_extension_name = this.state.selectedBiddingTimeManage && this.state.selectedBiddingTimeManage.label;
         data.bid_extension_type = this.state.selectedBiddingTimeManage && this.state.selectedBiddingTimeManage.type;
         data.approver_email_list = this.state.approver_emails_list;
         data.challenge_price_with_counter = this.state.selectedChallengePriceCounter && this.state.selectedChallengePriceCounter.label;
         data.bid_end_time_id = this.state.selectedBidEndTime && this.state.selectedBidEndTime.value;
         data.bid_end_time_name = this.state.selectedBidEndTime && this.state.selectedBidEndTime.label;
         data.bid_end_time_type = this.state.selectedBidEndTime && this.state.selectedBidEndTime.type;
         data.pricing_bassis_with_qbr_rate_id = this.state.selectedPricingBasisWithPrice && this.state.selectedPricingBasisWithPrice.value;
         data.pricing_bassis_with_qbr_rate_name = this.state.selectedPricingBasisWithPrice && this.state.selectedPricingBasisWithPrice.label;
         data.pricing_bassis_with_qbr_rate_type = this.state.selectedPricingBasisWithPrice && this.state.selectedPricingBasisWithPrice.type;
         if (!data.group_bid_decremental_amount) {
            data.group_bid_decremental_amount = null;
         }
         data.rfq_type = this.state.contractsRFQType;
         if ([...CENTURY_PLY, ...ROQUETTE].includes(this.props.company_id)) {
            data.contract_tenure_type = this.state.contractTenureType
         }
      } catch (e) {
         // const {dispatch} = this.props;
         // dispatch({type : ACTIONS.MSG_DISPLAY.SHOW_MSG, data : {type : 2 , msg : "Please make sure you have entered everything"}});
         return;
      }
      // if(validate.isObjElementEmpty(data)){
      //     const {dispatch} = this.props;
      //     dispatch({type : ACTIONS.MSG_DISPLAY.SHOW_MSG, data : {type : 2 , msg : "Please make sure you have entered everything"}});
      //     return;
      // }
      const { dispatch } = this.props;
      dispatch({ type: ACTIONS.CONTRACTS.ADD_CONTRACT, data });
   }

   checkAmount = (data) => {
      if (data.l1_rate_decremental && !data.l1_rate_decremental_amount) {
         scroll(this.l1ratedecrementalamount.offsetTop - 150);
         this.setState({ isError: true });
         return;
      } else if (data.l1_rate_decremental_first_bidder && !data.l1_rate_decremental_amount_first_bidder) {
         scroll(this.l1ratedecrementalamountfirstbidder.offsetTop - 150);
         this.setState({ isError: true });
         return;
      } else if (data.enable_tolerance && !data.enable_tolerance_amount) {
         scroll(this.enabletoleranceamount.offsetTop - 100);
         if (!this.enabletoleranceamount <= 100) {
            alert("Please enter within 100%")
         }
         this.setState({ isError: true });
         return;
      } else if (data.cp_rate_decremental && !data.cp_rate_decremental_amount) {
         scroll(this.cpratedecrementalamount.offsetTop - 150);
         this.setState({ isError: true });
         return;
      } else if (data.is_loi_ceiling_accepted && !data.deduction_price) {
         scroll(this.deductionPrice.offsetTop - 150);
         this.setState({ isError: true });
         return;
      }
      // else if(data.rfq_amend) {
      //     if(!data.rfq_amend_count){
      //         scroll(this.rfqamendcount.offsetTop - 50);
      //         this.setState({ isError: true });
      //         return;
      //     }
      // }
      return true;
   }

   editBasicContract = () => {
      let data = this.state.responseObj;
      if (!this.checkAmount(data)) {
         return;
      }
      try {
         data.rfq_issue_name = this.state.selectedRfq && this.state.selectedRfq.label || null;
         data.rfq_issue_id = this.state.selectedRfq && this.state.selectedRfq.value || null;
         data.rfq_issue_type = this.state.selectedRfq && this.state.selectedRfq.type || null;
         data.bidding_category_name = this.state.selectedBiddingCategory && this.state.selectedBiddingCategory.label || null;
         data.bidding_category_id = this.state.selectedBiddingCategory && this.state.selectedBiddingCategory.value || null;
         data.bidding_category_type = this.state.selectedBiddingCategory && this.state.selectedBiddingCategory.type || null;
         data.time_id = this.state.selectedTime && this.state.selectedTime.value || null;
         data.time_name = this.state.selectedTime && this.state.selectedTime.label || null;
         data.time_type = this.state.selectedTime && this.state.selectedTime.type || null;
         data.vendor_bidding_screen_id = this.state.selectedVendorBidding && this.state.selectedVendorBidding.value || null;
         data.vendor_bidding_screen_name = this.state.selectedVendorBidding && this.state.selectedVendorBidding.label || null;
         data.vendor_bidding_screen_type = this.state.selectedVendorBidding && this.state.selectedVendorBidding.type || null;
         data.data_storage_id = this.state.selectedDataStorage && this.state.selectedDataStorage.value || null;
         data.data_storage_name = this.state.selectedDataStorage && this.state.selectedDataStorage.label || null;
         data.data_storage_type = this.state.selectedDataStorage && this.state.selectedDataStorage.type || null;
         data.vehicle_placement_id = this.state.selectedVehiclePlacement && this.state.selectedVehiclePlacement.value || null;
         data.vehicle_placement_name = this.state.selectedVehiclePlacement && this.state.selectedVehiclePlacement.label;
         data.vehicle_placement_type = this.state.selectedVehiclePlacement && this.state.selectedVehiclePlacement.type;
         data.lpp_calculation_id = this.state.selectedLppCalculation && this.state.selectedLppCalculation.value;
         data.lpp_calculation_name = this.state.selectedLppCalculation && this.state.selectedLppCalculation.label;
         data.lpp_calculation_type = this.state.selectedLppCalculation && this.state.selectedLppCalculation.type;
         data.transporter_terms_id = this.state.selectedTransporterTerms && this.state.selectedTransporterTerms.value;
         data.transporter_terms_name = this.state.selectedTransporterTerms && this.state.selectedTransporterTerms.label;
         data.transporter_terms_type = this.state.selectedTransporterTerms && this.state.selectedTransporterTerms.type;
         data.bidding_rate_events_id = this.state.selectedBiddingRateEvents && this.state.selectedBiddingRateEvents.value;
         data.bidding_rate_events_name = this.state.selectedBiddingRateEvents && this.state.selectedBiddingRateEvents.label;
         data.bidding_rate_events_type = this.state.selectedBiddingRateEvents && this.state.selectedBiddingRateEvents.type;
         data.bidding_rate_types_id = this.state.selectedBiddingRateType && this.state.selectedBiddingRateType.value;
         data.bidding_rate_types_name = this.state.selectedBiddingRateType && this.state.selectedBiddingRateType.label;
         data.bidding_rate_types_type = this.state.selectedBiddingRateType && this.state.selectedBiddingRateType.type;
         data.challenge_price_id = this.state.selectedChallengePrice && this.state.selectedChallengePrice.value;
         data.challenge_price_name = this.state.selectedChallengePrice && this.state.selectedChallengePrice.label;
         data.challenge_price_type = this.state.selectedChallengePrice && this.state.selectedChallengePrice.type;
         data.minimum_bidders_id = this.state.selectedMinimumBidders && this.state.selectedMinimumBidders.value;
         data.minimum_bidders_name = this.state.selectedMinimumBidders && this.state.selectedMinimumBidders.label;
         data.minimum_bidders_type = this.state.selectedMinimumBidders && this.state.selectedMinimumBidders.type;
         data.light_compatibility_id = this.state.selectedLightCapabilityStatus && this.state.selectedLightCapabilityStatus.value;
         data.light_compatibility_name = this.state.selectedLightCapabilityStatus && this.state.selectedLightCapabilityStatus.label;
         data.light_compatibility_type = this.state.selectedLightCapabilityStatus && this.state.selectedLightCapabilityStatus.type;
         data.loi_issue_rights_id = this.state.selectedLoiIssueRights && this.state.selectedLoiIssueRights.value;
         data.loi_issue_rights_name = this.state.selectedLoiIssueRights && this.state.selectedLoiIssueRights.label;
         data.loi_issue_rights_type = this.state.selectedLoiIssueRights && this.state.selectedLoiIssueRights.type;
         data.bid_time_management_id = this.state.selectedBiddingTimeManage && this.state.selectedBiddingTimeManage.value;
         data.bid_time_management_name = this.state.selectedBiddingTimeManage && this.state.selectedBiddingTimeManage.label;
         data.bid_time_management_type = this.state.selectedBiddingTimeManage && this.state.selectedBiddingTimeManage.type;
         data.bid_extension_id = this.state.selectedBiddingTimeManage && this.state.selectedBiddingTimeManage.value;
         data.bid_extension_name = this.state.selectedBiddingTimeManage && this.state.selectedBiddingTimeManage.label;
         data.bid_extension_type = this.state.selectedBiddingTimeManage && this.state.selectedBiddingTimeManage.type;
         data.approver_email_list = this.state.approver_emails_list;
         data.delete_email_list = this.state.deleteApproverList;
         data.challenge_price_with_counter = this.state.selectedChallengePriceCounter && this.state.selectedChallengePriceCounter.value;
         data.bid_end_time_id = this.state.selectedBidEndTime && this.state.selectedBidEndTime.value;
         data.bid_end_time_name = this.state.selectedBidEndTime && this.state.selectedBidEndTime.label;
         data.bid_end_time_type = this.state.selectedBidEndTime && this.state.selectedBidEndTime.type;
         data.pricing_bassis_with_qbr_rate_id = this.state.selectedPricingBasisWithPrice && this.state.selectedPricingBasisWithPrice.value;
         data.pricing_bassis_with_qbr_rate_name = this.state.selectedPricingBasisWithPrice && this.state.selectedPricingBasisWithPrice.label;
         data.pricing_bassis_with_qbr_rate_type = this.state.selectedPricingBasisWithPrice && this.state.selectedPricingBasisWithPrice.type;
         data.rfq_type = this.state.contractsRFQType;
         if (!data.group_bid_decremental_amount) {
            data.group_bid_decremental_amount = null;
         }
         if ([...ROQUETTE, ...CENTURY_PLY].includes(this.props.company_id)) {
            data.contract_tenure_type = this.state.contractTenureType
         }
      } catch (e) {
      }
      // if(validate.isObjElementEmpty(data)){
      //     const {dispatch} = this.props;
      //     dispatch({type : ACTIONS.MSG_DISPLAY.SHOW_MSG, data : {type : 2 , msg : "Please make sure you have entered everything"}});
      //     return;
      // }
      this.setState({ isError: false });
      const { dispatch } = this.props;
      dispatch({ type: ACTIONS.CONTRACTS.EDIT_CONTRACT, data });
      this.setState({
         deleteApproverList: []
      });
   }

   handleDropDownChange = (key, selectedOption) => {
      this.setState({ [key]: selectedOption });
   }

   inputChanged = (key, e) => {
      let tempObj = this.state.responseObj;
      if (key === "enable_tolerance_amount") {
         if (e.target.value > 100) {
            alert("Please enter within 100%")
         } else {
            tempObj[key] = e.target.value;
            this.setState({
               responseObj: tempObj
            });
         }
      // } else if (key === "pricing_basis_qbr_rate") {
      //    if (e.target.value > 5000) {
      //       alert("You can't add a quote rate for a Rate/MT of more than Rs. 5000.")
      //    } else {
      //       tempObj[key] = e.target.value;
      //       this.setState({
      //          responseObj: tempObj
      //       });
      //    }
      } else if (key === "group_bid_decremental_amount") {
         tempObj[key] = e.target.value && Number(e.target.value) || e.target.value;
         this.setState({
            responseObj: tempObj
         });
      } else {
         tempObj[key] = e.target.value;
         this.setState({
            responseObj: tempObj
         });
      }

   }
   inputTextChanged = (key, e) => {
      //for thw change of input not in response object
      this.setState({
         [key]: e.target.value
      });
   }

   deleteCollapsibleSection = (section, alertType) => {
      let data = {
         branchId: section.branch_id,
         id: section.id,
         alertType: alertType
      }
      const { dispatch } = this.props;
      dispatch({ type: ACTIONS.CONTRACTS.DELETE_COLLAPSIBLE_SECTION, data });
   }

   getBranches = () => {
      const { dispatch } = this.props;
      let data = this.props.company_id + "&branchId=" + this.props.branch_id; //company_id
      dispatch({ type: ACTIONS.CONTRACTS.GET_BRANCH_LIST, data });
   }
   applyBranches = (dataList) => {
      if (this.state.responseObj.setting_id) {
         const { dispatch } = this.props;
         dispatch({
            type: ACTIONS.CONTRACTS.APPLY_SETTINGS_TO_BRANCHES,
            data: {
               setting_id: dataList.setting_id,
               branch_list: dataList.branch_list,
               companyId: this.props.company_id,
               branchId: this.props.branch_id
            }
         });
      }
   }
   dayChanged = (obj, key) => {
      let state = Object.assign({}, this.state);
      state[key] = obj;
      this.setState(state);
   }

   toggleCheck = (ind) => {
      const { dispatch } = this.props;
      let tempList = this.state.checkDropDown;
      tempList[ind] = !tempList[ind];
      this.setState({
         checkDropDown: tempList
      });
      let data = {
         branchId: this.props.branch_id,
         alertType: ind
      }
      dispatch({
         type: ACTIONS.CONTRACTS.GET_COLLAPSIBLE_SECTION,
         data
      });
      if (ind == 6) {
         dispatch({ type: ACTIONS.HOME.GET_ROLES, data: this.props.company_id });
      }

      if (ind == 7) {
         this.getEmailList();
      }
   }

   getEmailList = () => {
      const { dispatch } = this.props;
      const data = {
         company_id: this.props.company_id,
         company_type: 1
      }
      dispatch({ type: ACTIONS.CONTRACTS.GET_EMAIL_LIST, data });
   }
   getRfqs = (search) => {
      const { dispatch } = this.props;
      const data = {
         branch_id: this.props.branch_id,
         search
      }
      dispatch({ type: ACTIONS.CONTRACTS.GET_RFQS, data });
   }

   addApproverLevel = (index) => {
      const { approver_values, responseObj } = this.state;
      // if(!responseObj.approval_matrix) {
      //    responseObj.approval_matrix = [];
      // }
      // responseObj.approval_matrix.push([]);
      // approver_values.push({
      //    emails: [],
      //    approver_type: []
      // })
      if (!responseObj.approval_matrix[index]["levels"]) {
         responseObj.approval_matrix[index]["levels"] = [];
      }
      responseObj.approval_matrix[index].levels.push({
         level: (responseObj.approval_matrix[index]["levels"].length) + 1,
         emails: []
      })
      this.setState({ responseObj });
   }

   onSelectApprover = (option) => {
      const { responseObj } = this.state;
      let isApproverExist = false;
      if (!responseObj.approval_matrix) {
         responseObj.approval_matrix = []
      }

      (responseObj.approval_matrix || []).forEach(apprverList => {
         if (apprverList.approver_type === option.value) {
            isApproverExist = true;
         }
      })

      if (!isApproverExist) {
         responseObj.approval_matrix.push({
            approver_type: option.value
         })
      }
      this.setState({ responseObj, approverType: '' });
   }

   onSelectEmail = (option, mainIndex, subIndex) => {
      const { approver_values } = this.state;
      if (!(approver_values && approver_values[mainIndex])) {
         for (var i = 0; i <= mainIndex; i++) {
            approver_values.push({
               "approver_type": (i + 1),
               "levels": []
            })
         }
      }
      if (!approver_values[mainIndex]['levels'][subIndex]) {
         for (var j = 0; j <= subIndex; j++) {
            approver_values[mainIndex]['levels'].push({
               emails: []
            })
         }
      }
      approver_values[mainIndex]['levels'][subIndex]['emails'] = option;
      this.setState({ approver_values });
   }

   addLevelApprover = (mainIndex, subIndex) => {
      const { approver_values, responseObj } = this.state;
      if (approver_values[mainIndex].levels[subIndex]) {
         (approver_values[mainIndex].levels[subIndex].emails || []).forEach(eml => {
            if (responseObj.approval_matrix[mainIndex].levels[subIndex].emails.indexOf(eml.value) === -1) {
               responseObj.approval_matrix[mainIndex].levels[subIndex].emails.push(eml.value);
            }
         })
         this.setState({
            responseObj
         }, () => {
            approver_values[mainIndex].levels[subIndex].emails = [];
            if (this.props.contracts.basicContract) {
               this.editBasicContract();
            } else {
               this.addBasicContract();
            }
         })
      }
   }

   deleteLevelApprover = (levelIndex, subIndex, subEmailIndex) => {
      const { responseObj } = this.state;
      responseObj.approval_matrix[levelIndex].levels[subIndex].emails.splice(subEmailIndex, 1);
      if (responseObj.approval_matrix[levelIndex].levels[subIndex].emails.length === 0) {
         responseObj.approval_matrix[levelIndex].levels.splice(subIndex, 1);
         (responseObj.approval_matrix[levelIndex].levels || []).forEach((levelList, lIndex) => {
            levelList.level = (+lIndex + 1)
         })
      }
      if (responseObj.approval_matrix[levelIndex].levels.length === 0) {
         responseObj.approval_matrix.splice(levelIndex, 1);
      }
      this.setState({ responseObj }, () => {
         this.editBasicContract();
      })
   }
   inputChangedCurrency = (key, e) => {
      let tempCurrencyObj = this.state.currencyObj;
      tempCurrencyObj[key] = e.target.value;
      this.setState({
         currencyObj: tempCurrencyObj
      });
   }
   currencyConverter = () => {
      let data = this.state.currencyObj;
      const { dispatch } = this.props;
      data.branch_id = this.props.branch_id;
      data.company_id = this.props.company_id;
      dispatch({ type: ACTIONS.CONTRACTS.ADD_CURRENCY, data });
   }


   selectPackagingTypeName = (value) => {
      const { tempPackagingTypeObj, selectedPackagingTypeName } = this.state;
      const exists = selectedPackagingTypeName.find(p => p.name === value.name);
      const isExistsSelect = tempPackagingTypeObj.find(p => p.name === value.name);
      if (!exists) {
         let data = {
            "id": value.id,
            "name": value.name
         }
         if (!isExistsSelect) {
            tempPackagingTypeObj.push(data);
         } else {
            tempPackagingTypeObj.splice(tempPackagingTypeObj.findIndex(a => a.id === value.id), 1);
         }
      } else {
         alert("alreday Exists");
      }
      this.setState({
         tempPackagingTypeObj,
         errormessage: ''
      })
   }

   seletedPackagingTypeName = (value) => {
      const { tempSelectedPakagingType } = this.state;
      const exists = tempSelectedPakagingType.find(p => p.name === value.name);
      if (!exists) {
         tempSelectedPakagingType.push(value);
      }
      this.setState({
         tempSelectedPakagingType,
         errormessage: ''
      })
   }

   selectProductName = (value) => {
      const { tempProductCategorObj, productCategoryName } = this.state;
      const exists = productCategoryName.find(p => p.name === value.name);
      const isExistsSelect = tempProductCategorObj.find(p => p.name === value.name);
      const { company_id } = this.props;
      if (!exists) {
         let data = {
            "id": value.id,
            "name": value.name
         }
         if (["55afbf6dc2154d12a6ed5b9306d39eb5"].indexOf(company_id) > -1) {
            data.density = (value.density) ? value.density : 1.0;
         }
         if (!isExistsSelect) {
            tempProductCategorObj.push(data);
         } else {
            tempProductCategorObj.splice(tempProductCategorObj.findIndex(a => a.id === value.id), 1);
         }
      } else {
         alert("alreday Exists");
      }
      this.setState({
         tempProductCategorObj,
         errormessage: ''
      })
   }

   seletedProductName = (value) => {
      const { tempSelectedProductCategor } = this.state;
      const exists = tempSelectedProductCategor.find(p => p.name === value.name);
      if (!exists) {
         tempSelectedProductCategor.push(value);
      }
      this.setState({
         tempSelectedProductCategor,
         errormessage: ''
      })
   }
   addSingleDetails = (name) => {
      const { selectedPackagingTypeName, tempPackagingTypeObj, productCategoryName, tempProductCategorObj, pricingBasisName, tempPricingBasisObj, tempVehicleTypeObj, vehicleTypeName, uomName, tempUomNameObj, tempVehicleBodyObj, vehicleBodyTypeName, tempLoadabilityObj, loadabilityValues } = this.state;
      let error = "Please add data";
      switch (name) {
         case "productCategory":
            if (tempProductCategorObj.length > 0) {
               const tempObj = [...tempProductCategorObj, ...productCategoryName];
               let unique = tempObj.filter((it, i, ar) => ar.indexOf(it) === i);
               this.setState({
                  productCategoryName: unique
               })
            } else {
               alert(error);
            }
            this.setState({
               tempProductCategorObj: []
            })
            break;
         case "packaging":
            if (tempPackagingTypeObj.length > 0) {
               const tempObj = [...tempPackagingTypeObj, ...selectedPackagingTypeName];
               let unique = tempObj.filter((it, i, ar) => ar.indexOf(it) === i);
               this.setState({
                  selectedPackagingTypeName: unique
               })
            } else {
               alert(error);
            }
            this.setState({
               tempPackagingTypeObj: []
            })
            break;
         case "pricing":
            if (tempPricingBasisObj.length > 0) {
               const tempObj = [...tempPricingBasisObj, ...pricingBasisName];
               let unique = tempObj.filter((it, i, ar) => ar.indexOf(it) === i);
               this.setState({
                  pricingBasisName: unique
               })
            } else {
               alert(error);
            }
            this.setState({
               tempPricingBasisObj: []
            })
            break;
         case "vehicleType":
            if (tempVehicleTypeObj.length > 0) {
               const tempObj = [...tempVehicleTypeObj, ...vehicleTypeName];
               let unique = tempObj.filter((it, i, ar) => ar.indexOf(it) === i);
               this.setState({
                  vehicleTypeName: unique
               })
            } else {
               alert(error);
            }
            this.setState({
               tempVehicleTypeObj: []
            })
            break;
         case "vehicleBody":
            if (tempVehicleBodyObj.length > 0) {
               const tempObj = [...tempVehicleBodyObj, ...vehicleBodyTypeName];
               let unique = tempObj.filter((it, i, ar) => ar.indexOf(it) === i);
               this.setState({
                  vehicleBodyTypeName: unique
               })
            } else {
               alert(error);
            }
            this.setState({
               tempVehicleBodyObj: []
            })
            break;
         case "uom":
            if (tempUomNameObj.length > 0) {
               const tempObj = [...tempUomNameObj, ...uomName];
               let unique = tempObj.filter((it, i, ar) => ar.indexOf(it) === i);
               this.setState({
                  uomName: unique
               })
            } else {
               alert(error);
            }
            this.setState({
               tempUomNameObj: []
            })
            break;
         case "loadability":
            if (tempLoadabilityObj.length > 0) {
               const tempObj = [...tempLoadabilityObj, ...loadabilityValues];
               let unique = tempObj.filter((it, i, ar) => ar.indexOf(it) === i);
               this.setState({
                  loadabilityValues: unique
               })
            } else {
               alert(error);
            }
            this.setState({
               tempLoadabilityObj: []
            })
            break;
      }
   }

   addAllDetails = (name) => {
      const { selectedPackagingTypeName, productCategoryName, pricingBasisName, vehicleTypeName, vehicleBodyTypeName, uomName, loadabilityValues, tempLoadabilityObj } = this.state;
      const { contracts } = this.props;
      let error = "Please add data";
      let isSuperAdmin = !isCompanyAdmin();
      switch (name) {
         case "productCategory":
            if (contracts.masterDetails && contracts.masterDetails.product_category.length > 0) {
               let obj = (isSuperAdmin) ? contracts.masterDetails.product_category.map((ref) => { return { name: ref.name, id: ref.id } }) : contracts.masterDetails.product_category[0].product_list.map((ref) => { return { name: ref.name, id: ref.id } });
               const tempObj = [...obj];
               let unique = tempObj.filter((it, i, ar) => ar.indexOf(it) === i);
               this.setState({
                  productCategoryName: unique,
                  tempProductCategorObj: []
               })
            } else {
               alert(error);
               return
            }
            break;
         case "packaging":
            if (contracts.masterDetails && contracts.masterDetails.packing_types.length > 0) {
               let obj = (isSuperAdmin) ? contracts.masterDetails.packing_types.map((ref) => { return { name: ref.name, id: ref.id } }) : contracts.masterDetails.packing_types[0].packing_list.map((ref) => { return { name: ref.name, id: ref.id } });
               const tempObj = [...obj];
               let unique = tempObj.filter((it, i, ar) => ar.indexOf(it) === i);
               this.setState({
                  selectedPackagingTypeName: unique,
                  tempPackagingTypeObj: []
               })
            } else {
               alert(error);
               return
            }
            break;
         case "pricing":
            if (contracts.masterDetails && contracts.masterDetails.pricing_basis.length > 0) {
               let obj = (isSuperAdmin) ? contracts.masterDetails.pricing_basis.map((ref) => { return { name: ref.name, id: ref.id } }) : contracts.masterDetails.pricing_basis[0].pricing_basis_list.map((ref) => { return { name: ref.name, id: ref.id } });
               const tempObj = [...obj];
               let unique = tempObj.filter((it, i, ar) => ar.indexOf(it) === i);
               this.setState({
                  pricingBasisName: unique,
                  tempPricingBasisObj: []
               })
            } else {
               alert(error);
               return
            }
            break;
         case "vehicleType":
            if (contracts.masterDetails && contracts.masterDetails.vehicle_types.length > 0) {
               let obj = (isSuperAdmin) ? contracts.masterDetails.vehicle_types.map((ref) => { return { name: ref.name, id: ref.id } }) : contracts.masterDetails.vehicle_types[0].vehicle_types_list.map((ref) => { return { name: ref.name, id: ref.id } });
               const tempObj = [...obj];
               let unique = tempObj.filter((it, i, ar) => ar.indexOf(it) === i);
               this.setState({
                  vehicleTypeName: unique,
                  tempVehicleTypeObj: []
               })
            } else {
               alert(error);
               return
            }
            break;
         case "vehicleBody":
            if (contracts.masterDetails && contracts.masterDetails.vehicle_body_types.length > 0) {
               let obj = (isSuperAdmin) ? contracts.masterDetails.vehicle_body_types.map((ref) => { return { name: ref.name, id: ref.id } }) : contracts.masterDetails.vehicle_body_types[0].vehicle_body_types_list.map((ref) => { return { name: ref.name, id: ref.id } });
               const tempObj = [...obj];
               let unique = tempObj.filter((it, i, ar) => ar.indexOf(it) === i);
               this.setState({
                  vehicleBodyTypeName: unique,
                  tempVehicleBodyObj: []
               })
            } else {
               alert(error);
               return
            }
            break;
         case "uom":
            if (contracts.masterDetails && contracts.masterDetails.business_volume.length > 0) {
               let obj = (isSuperAdmin) ? contracts.masterDetails.business_volume.map((ref) => { return { name: ref.name, id: ref.id } }) : contracts.masterDetails.business_volume[0].business_volume_list.map((ref) => { return { name: ref.name, id: ref.id } });
               const tempObj = [...obj];
               let unique = tempObj.filter((it, i, ar) => ar.indexOf(it) === i);
               this.setState({
                  uomName: unique,
                  tempUomNameObj: []
               })
            } else {
               alert(error);
               return
            }
            break;
         case "loadability":
            if (contracts.masterDetails && contracts.masterDetails.loadability.length > 0) {
               let obj = (isSuperAdmin) ? contracts.masterDetails.loadability.map((ref) => { return { name: ref.name, id: ref.id } }) : contracts.masterDetails.loadability_list[0].loadability_list.map((ref) => { return { name: ref.name, id: ref.id } });
               const tempObj = [...obj];
               let unique = tempObj.filter((it, i, ar) => ar.indexOf(it) === i);
               this.setState({
                  loadabilityValues: unique,
                  tempLoadabilityObj: []
               })
            } else {
               alert(error);
               return
            }
            break;
      }
   }

   deleteSingleDetails = (name) => {
      let error = "We don't have data to get delete"
      const { selectedPackagingTypeName, tempSelectedPakagingType, tempSelectedProductCategor, productCategoryName, tempSelectedPricingBasis, pricingBasisName, tempSelectedVehicleTypeName, vehicleTypeName, tempSelectedVehicleBodyName, vehicleBodyTypeName, uomName, tempSelectedUomName, tempSelectedLoadabilityValues, loadabilityValues } = this.state;
      switch (name) {
         case "productCategory":
            if (productCategoryName.length > 0) {
               let tempObj = productCategoryName.filter(val => !tempSelectedProductCategor.includes(val));
               this.setState({
                  productCategoryName: tempObj,
                  tempSelectedProductCategor: []
               })
            } else {
               alert(error)
            }
            break;
         case "packaging":
            if (selectedPackagingTypeName.length > 0) {
               let tempObj = selectedPackagingTypeName.filter(val => !tempSelectedPakagingType.includes(val));
               this.setState({
                  selectedPackagingTypeName: tempObj,
                  tempSelectedPakagingType: []
               })
            } else {
               alert(error)
            }
            break;
         case "pricing":
            if (pricingBasisName.length > 0) {
               let tempObj = pricingBasisName.filter(val => !tempSelectedPricingBasis.includes(val));
               this.setState({
                  pricingBasisName: tempObj,
                  tempSelectedPricingBasis: []
               })
            } else {
               alert(error)
            }
            break;
         case "vehicleType":
            if (vehicleTypeName.length > 0) {
               let tempObj = vehicleTypeName.filter(val => !tempSelectedVehicleTypeName.includes(val));
               this.setState({
                  vehicleTypeName: tempObj,
                  tempSelectedVehicleTypeName: []
               })
            } else {
               alert(error)
            }
            break;
         case "vehicleBody":
            if (vehicleBodyTypeName.length > 0) {
               let tempObj = vehicleBodyTypeName.filter(val => !tempSelectedVehicleBodyName.includes(val));
               this.setState({
                  vehicleBodyTypeName: tempObj,
                  tempSelectedVehicleBodyName: []
               })
            } else {
               alert(error)
            }
            break;
         case "uom":
            if (uomName.length > 0) {
               let tempObj = uomName.filter(val => !tempSelectedUomName.includes(val));
               this.setState({
                  uomName: tempObj,
                  tempSelectedUomName: []
               })
            } else {
               alert(error)
            }
            break;
         case "loadability":
            if (loadabilityValues.length > 0) {
               let tempObj = loadabilityValues.filter(val => !tempSelectedLoadabilityValues.includes(val));
               this.setState({
                  loadabilityValues: tempObj,
                  tempSelectedLoadabilityValues: []
               })
            } else {
               alert(error)
            }
            break;
      }
   }

   deleteAllDetails = (name) => {
      let error = "We don't have data to get delete"
      const { selectedPackagingTypeName, productCategoryName, pricingBasisName, vehicleTypeName, vehicleBodyTypeName, uomName, loadabilityValues } = this.state;
      switch (name) {
         case "productCategory":
            if (productCategoryName.length > 0) {
               this.setState({
                  productCategoryName: [],
                  tempProductCategorObj: []
               })
            } else {
               alert(error);
               return
            }
            break;
         case "packaging":
            if (selectedPackagingTypeName.length > 0) {
               this.setState({
                  selectedPackagingTypeName: [],
                  tempPackagingTypeObj: []
               })
            } else {
               alert(error);
               return
            }
            break;
         case "pricing":
            if (pricingBasisName.length > 0) {
               this.setState({
                  tempSelectedPricingBasis: [],
                  tempPricingBasisObj: [],
                  pricingBasisName: []
               })
            } else {
               alert(error);
               return
            }
            break;
         case "vehicleType":
            if (vehicleTypeName.length > 0) {
               this.setState({
                  tempSelectedVehicleTypeName: [],
                  tempVehicleTypeObj: [],
                  vehicleTypeName: []
               })
            } else {
               alert(error);
               return
            }
            break;
         case "vehicleBody":
            if (vehicleBodyTypeName.length > 0) {
               this.setState({
                  tempSelectedVehicleBodyName: [],
                  tempVehicleBodyObj: [],
                  vehicleBodyTypeName: []
               })
            } else {
               alert(error);
               return
            }
            break;
         case "uom":
            if (uomName.length > 0) {
               this.setState({
                  tempSelectedUomName: [],
                  tempUomNameObj: [],
                  uomName: []
               })
            } else {
               alert(error);
               return
            }
            break;
         case "loadability":
            if (loadabilityValues.length > 0) {
               this.setState({
                  tempSelectedLoadabilityValues: [],
                  tempLoadabilityObj: [],
                  loadabilityValues: []
               })
            } else {
               alert(error);
               return
            }
            break;

      }
   }
   saveContractDetails = (name) => {
      const { selectedPackagingTypeName, productCategoryName, pricingBasisName, vehicleTypeName, vehicleBodyTypeName, uomName, loadabilityValues } = this.state;
      let data = {};
      let error = "Your are not added data";
      data.company_id = this.props.company_id;
      data.branch_id = this.props.branch_id;
      const isCmpyAdmin = isCompanyAdmin();
      data.is_admin = isCmpyAdmin;
      if (isCmpyAdmin) {
         data.rfqType = this.state.rfqType;
      }
      const { dispatch } = this.props;
      switch (name) {
         case "productCategory":
            data.product_type_name = productCategoryName;
            dispatch({
               type: ACTIONS.CONTRACTS.ADD_PRODUCT_CATEGORY,
               data,
               onSuccess: () => this.getPlantWiseContractDetails(data)
            });
            break;
         case "packaging":
            data.packaging_type_name = selectedPackagingTypeName;
            dispatch({
               type: ACTIONS.CONTRACTS.ADD_PACKING_TYPE,
               data,
               onSuccess: () => this.getPlantWiseContractDetails(data)
            });
            break;
         case "pricing":
            data.pricing_basis_name = pricingBasisName;
            dispatch({
               type: ACTIONS.CONTRACTS.ADD_PRICING_BASIS,
               data,
               onSuccess: () => this.getPlantWiseContractDetails(data)
            });
            break;
         case "vehicleType":
            data.vehicle_type_name = vehicleTypeName;
            dispatch({
               type: ACTIONS.CONTRACTS.ADD_VEHICLE_TYPE,
               data,
               onSuccess: () => this.getPlantWiseContractDetails(data)
            });
            break;
         case "vehicleBody":
            data.vehicle_body_type_name = vehicleBodyTypeName;
            dispatch({
               type: ACTIONS.CONTRACTS.ADD_VEHICLE_BODY_TYPE,
               data,
               onSuccess: () => this.getPlantWiseContractDetails(data)
            });
            break;
         case "uom":
            data.uom_name = uomName;
            dispatch({
               type: ACTIONS.CONTRACTS.ADD_UOM,
               data,
               onSuccess: () => this.getPlantWiseContractDetails(data)
            });
            break;
         case "loadability":
            data.loadability_type = loadabilityValues;
            dispatch({
               type: ACTIONS.CONTRACTS.ADD_LOADABILITY,
               data,
               onSuccess: () => this.getPlantWiseContractDetails(data)
            });
            break;
      }
   }

   selectPricingBasisName = (value) => {
      const { tempPricingBasisObj, pricingBasisName } = this.state;
      const exists = pricingBasisName.find(p => p.name === value.name);
      const isExistsSelect = tempPricingBasisObj.find(p => p.name === value.name);
      if (!exists) {
         let data = {
            "id": value.id,
            "name": value.name
         }
         if (!isExistsSelect) {
            tempPricingBasisObj.push(data);
         } else {
            tempPricingBasisObj.splice(tempPricingBasisObj.findIndex(a => a.id === value.id), 1);
         }
      } else {
         alert("alreday Exists");
      }
      this.setState({
         tempPricingBasisObj,
         errormessage: ''
      })
   }

   seletedPricingBasisName = (value) => {
      const { tempSelectedPricingBasis } = this.state;
      const exists = tempSelectedPricingBasis.find(p => p.name === value.name);
      if (!exists) {
         tempSelectedPricingBasis.push(value);
      }
      this.setState({
         tempSelectedPricingBasis,
         errormessage: ''
      })
   }

   selectVehicleTypeName = (value) => {
      const { tempVehicleTypeObj, vehicleTypeName } = this.state;
      const exists = vehicleTypeName.find(p => p.name === value.name);
      const isExistsSelect = tempVehicleTypeObj.find(p => p.name === value.name);
      const { company_id } = this.props;
      if (!exists) {
         let data = {
            "id": value.id,
            "name": value.name,
         }
         if (["3f068bae400d4713b6b784a6282596e4"].indexOf(company_id) > -1) {
            data.vehicle_capacity_MT = (value.vehicle_capacity_MT) ? value.vehicle_capacity_MT : 0;
         }
         if (AWL_COMPANY_IDS.includes(company_id)) {
            data.vehicle_capacity = value.vehicle_capacity ? value.vehicle_capacity : 0;
         }
         if (!isExistsSelect) {
            tempVehicleTypeObj.push(data);
         } else {
            tempVehicleTypeObj.splice(tempVehicleTypeObj.findIndex(a => a.id === value.id), 1);
         }
      } else {
         alert("alreday Exists");
      }
      this.setState({
         tempVehicleTypeObj,
         errormessage: ''
      })
   }

   seletedVehicleTypeName = (value) => {
      const { tempSelectedVehicleTypeName } = this.state;
      const exists = tempSelectedVehicleTypeName.find(p => p.name === value.name);
      if (!exists) {
         tempSelectedVehicleTypeName.push(value);
      }
      this.setState({
         tempSelectedVehicleTypeName,
         errormessage: ''
      })
   }

   selectVehicleBodyTypeName = (value) => {
      const { tempVehicleBodyObj, vehicleBodyTypeName } = this.state;
      const exists = vehicleBodyTypeName.find(p => p.name === value.name);
      const isExistsSelect = tempVehicleBodyObj.find(p => p.name === value.name);
      if (!exists) {
         let data = {
            "id": value.id,
            "name": value.name
         }
         if (!isExistsSelect) {
            tempVehicleBodyObj.push(data);
         } else {
            tempVehicleBodyObj.splice(tempVehicleBodyObj.findIndex(a => a.id === value.id), 1)
         }
      } else {
         alert("alreday Exists");
      }
      this.setState({
         tempVehicleBodyObj,
         errormessage: ''
      })
   }

   seletedVehicleBodyTypeName = (value) => {
      const { tempSelectedVehicleBodyName } = this.state;
      const exists = tempSelectedVehicleBodyName.find(p => p.name === value.name);
      if (!exists) {
         tempSelectedVehicleBodyName.push(value);
      }
      this.setState({
         tempSelectedVehicleBodyName,
         errormessage: ''
      })
   }

   selectUomName = (value) => {
      const { tempUomNameObj, uomName } = this.state;
      const exists = uomName.find(p => p.name === value.name);
      const isExistsSelect = tempUomNameObj.find(p => p.name === value.name);
      if (!exists) {
         let data = {
            "id": value.id,
            "name": value.name
         }
         if (!isExistsSelect) {
            tempUomNameObj.push(data);
         } else {
            tempUomNameObj.splice(tempUomNameObj.findIndex(a => a.id === value.id), 1);
         }
      } else {
         alert("alreday Exists");
      }
      this.setState({
         tempUomNameObj,
         errormessage: ''
      })
   }

   seletedUomName = (value) => {
      const { tempSelectedUomName } = this.state;
      const exists = tempSelectedUomName.find(p => p.name === value.name);
      if (!exists) {
         tempSelectedUomName.push(value);
      }
      this.setState({
         tempSelectedUomName,
         errormessage: ''
      })
   }

   selectLoadability = (value) => {
      const { tempLoadabilityObj, loadabilityValues } = this.state;
      const exists = loadabilityValues.find(p => p.name === value.name);
      const isExistsSelect = tempLoadabilityObj.find(p => p.name === value.name);
      if (!exists) {
         let data = {
            "id": value.id,
            "name": value.name
         }
         if (!isExistsSelect) {
            tempLoadabilityObj.push(data);
         } else {
            tempLoadabilityObj.splice(tempLoadabilityObj.findIndex(a => a.id === value.id), 1);
         }
      } else {
         alert("alreday Exists");
      }
      this.setState({
         tempLoadabilityObj,
         errormessage: ''
      })
   }

   seletedLoadabilityValues = (value) => {
      const { tempSelectedLoadabilityValues } = this.state;
      const exists = tempSelectedLoadabilityValues.find(p => p.name === value.name);
      if (!exists) {
         tempSelectedLoadabilityValues.push(value);
      }
      this.setState({
         tempSelectedLoadabilityValues,
         errormessage: ''
      })
   }

   getPlantWiseContractDetails = (data) => {
      const { dispatch } = this.props;
      dispatch({ type: ACTIONS.CONTRACTS.GET_PLANT_WISE_CONTRACT_DATA, data, isSuperAdmin: !isCompanyAdmin() })
   }

   getBasicContractDetails = () => {
      const { dispatch } = this.props;
      let data = {
         company_id: this.props.company_id,
         branch_id: this.props.branch_id,
         rfq_type: this.state.contractsRFQType || 1
      }
      if ([...ROQUETTE, ...CENTURY_PLY].includes(this.props.company_id)) {
         data.contract_tenure_type = this.state.contractTenureType || 1
      }
      dispatch({ type: ACTIONS.CONTRACTS.GET_BASIC_CONTRACT_SETTING, data });
   }

   autoBidInputChange = (index, id, e) => {
      const { auto_decrement_range } = this.state;
      auto_decrement_range[index][id] = e.target.value;
      this.setState({ auto_decrement_range })
   }

   addAutoBidField = (index) => {
      const { auto_decrement_range } = this.state;
      auto_decrement_range.push({
         fromRange: JSON.stringify(parseInt(auto_decrement_range[index].toRange) + 1),
         toRange: "",
         decrementAmount: ""
      });
      this.setState({ auto_decrement_range })
   }

   removeAutoBidField = (index) => {
      const { auto_decrement_range } = this.state;
      auto_decrement_range.splice(index, 1);
      this.setState({ auto_decrement_range })
   }

   clearAllAutoBidData = () => {
      const new_auto_bid_range = [{
         fromRange: '1',
         toRange: "",
         decrementAmount: ""
      }]

      this.setState({ auto_decrement_range: new_auto_bid_range })
   }

   render() {
      let tempRfq = [];
      let tempBiddingCategory = [];
      let tempTime = [];
      let emailList = [];
      let tempVendorBiddingScreen = [];
      let tempDataStorage = [];
      let tempVehiclePlacement = [];
      let tempLppCalculation = [];
      let tempTransporterTerms = [];
      let tempBiddingRateTypes = [];
      let tempBiddingRateEvents = [];
      let tempChallengePrice = [];
      let tempBidExtension = [];
      let tempMinimumBidders = [];
      let tempBidEndTime = [];
      let tempLightCompatibility = [];
      let tempLoiIssueRights = [];
      let tempBidTimeManagement = [];
      let transportServcies = [];
      let cargoUnits = [];
      let roleOptions = [];
      let tempPricingBasisTypeWithPrice = [];
      let tempPackagingTypeNames = [];
      let tempPricingBasisNames = [];
      let tempProductCategoryNames = [];
      let tempUomNames = [];
      let tempVehicleBodyTypeNames = [];
      let tempVehicleTypeNames = [],
         tempLoadability = [];
      const basicContracts = this.props.contracts.basicContract;
      const otherContracts = this.props.contracts.otherContract;
      const { approverTypes } = this.props.contracts;
      const isCmpyAdmin = isCompanyAdmin();
      let isSetPercentage = companyIds.includes(this.props.company_id);
      this.props.contracts.otherContract && this.props.contracts.otherContract.rfq_issue.list.forEach(element => {
         tempRfq.push({
            label: element.name,
            value: element.id,
            type: this.props.contracts.otherContract.rfq_issue.type
         })
      });
      this.props.contracts.otherContract && this.props.contracts.otherContract.bidding_category.list.forEach(element => {
         tempBiddingCategory.push({
            label: element.name,
            value: element.id,
            type: this.props.contracts.otherContract.bidding_category.type
         })
      });

      this.props.contracts.otherContract && this.props.contracts.otherContract.time.list.forEach(element => {
         tempTime.push({
            label: element.name,
            value: element.id,
            type: this.props.contracts.otherContract.time.type
         })
      });
      this.props.contracts.otherContract && this.props.contracts.otherContract.vendor_bidding_screen.list.forEach(element => {
         tempVendorBiddingScreen.push({
            label: element.name,
            value: element.id,
            type: this.props.contracts.otherContract.vendor_bidding_screen.type
         })
      });
      this.props.contracts.otherContract && this.props.contracts.otherContract.data_storage.list.forEach(element => {
         tempDataStorage.push({
            label: element.name,
            value: element.id,
            type: this.props.contracts.otherContract.data_storage.type
         })
      });
      this.props.contracts.otherContract && this.props.contracts.otherContract.vehicle_placement.list.forEach(element => {
         tempVehiclePlacement.push({
            label: element.name,
            value: element.id,
            type: this.props.contracts.otherContract.vehicle_placement.type
         })
      });
      this.props.contracts.otherContract && this.props.contracts.otherContract.lpp_calculation.list.forEach(element => {
         tempLppCalculation.push({
            label: element.name,
            value: element.id,
            type: this.props.contracts.otherContract.lpp_calculation.type
         })
      });
      this.props.contracts.otherContract && this.props.contracts.otherContract.transporter_terms.list.forEach(element => {
         tempTransporterTerms.push({
            label: element.name,
            value: element.id,
            type: this.props.contracts.otherContract.transporter_terms.type
         })
      });
      this.props.contracts.otherContract && this.props.contracts.otherContract.bidding_rate_types.list.forEach(element => {
         tempBiddingRateTypes.push({
            label: element.name,
            value: element.id,
            type: this.props.contracts.otherContract.bidding_rate_types.type
         })
      });
      this.props.contracts.otherContract && this.props.contracts.otherContract.bidding_rate_events.list.forEach(element => {
         tempBiddingRateEvents.push({
            label: element.name,
            value: element.id,
            type: this.props.contracts.otherContract.bidding_rate_events.type
         })
      });
      this.props.contracts.otherContract && this.props.contracts.otherContract.challenge_price.list.forEach(element => {
         tempChallengePrice.push({
            label: element.name,
            value: element.id,
            type: this.props.contracts.otherContract.challenge_price.type
         })
      });
      this.props.contracts.otherContract && this.props.contracts.otherContract.bid_extension.list.forEach(element => {
         tempBidExtension.push({
            label: element.name,
            value: element.id,
            type: this.props.contracts.otherContract.bid_extension.type
         })
      });
      this.props.contracts.otherContract && this.props.contracts.otherContract.minimum_bidders.list.forEach(element => {
         tempMinimumBidders.push({
            label: element.name,
            value: element.id,
            type: this.props.contracts.otherContract.minimum_bidders.type
         })
      });
      this.props.contracts.otherContract && this.props.contracts.otherContract.light_compatibility.list.forEach(element => {
         tempLightCompatibility.push({
            label: element.name,
            value: element.id,
            type: this.props.contracts.otherContract.light_compatibility.type
         })
      });
      this.props.contracts.otherContract && this.props.contracts.otherContract.loi_issue_rights.list.forEach(element => {
         tempLoiIssueRights.push({
            label: element.name,
            value: element.id,
            type: this.props.contracts.otherContract.loi_issue_rights.type
         })
      });
      this.props.contracts.otherContract && this.props.contracts.otherContract.bid_time_management.list.forEach(element => {
         tempBidTimeManagement.push({
            label: element.name,
            value: element.id,
            type: this.props.contracts.otherContract.bid_time_management.type
         })
      });
      otherContracts && otherContracts.transporter_service.list.forEach((element) => {
         transportServcies.push({
            label: element.name,
            value: element.id,
            type: otherContracts.transporter_service.type
         });
      });
      otherContracts && otherContracts.cargo_unit.list.forEach((element) => {
         cargoUnits.push({
            label: element.name,
            value: element.id,
            type: otherContracts.cargo_unit.type
         });
      });
      this.props.home.roles && this.props.home.roles.forEach((element) => {
         roleOptions.push({
            label: element.role_name,
            value: element.role_id
         });
      });

      this.props.contracts.otherContract && this.props.contracts.otherContract.bid_end_time && this.props.contracts.otherContract.bid_end_time.list.forEach(element => {
         tempBidEndTime.push({
            label: element.name,
            value: element.id,
            type: this.props.contracts.otherContract.bid_end_time.type
         })
      });

      if (this.props.contracts && this.props.contracts.emailList && this.props.contracts.emailList.length > 0) {
         (this.props.contracts.emailList || []).forEach((element) => {
            emailList.push({
               label: element,
               value: element
            });
         });
      }
      this.props.contracts.otherContract && this.props.contracts.otherContract.pricing_basis_type.list.forEach(element => {
         tempPricingBasisTypeWithPrice.push({
            label: element.name,
            value: element.id,
            type: this.props.contracts.otherContract.pricing_basis_type.type
         })
      });

      this.props.contracts.masterDetails && (!isCmpyAdmin ? this.props.contracts.masterDetails.packing_types : (this.props.contracts.masterDetails.packing_types[0] || []).packing_list).forEach(element => {
         tempPackagingTypeNames.push({
            name: element.name,
            id: element.id,
         })
      });
      this.props.contracts.masterDetails && (!isCmpyAdmin ? this.props.contracts.masterDetails.pricing_basis : (this.props.contracts.masterDetails.pricing_basis[0] || []).pricing_basis_list).forEach(element => {
         tempPricingBasisNames.push({
            name: element.name,
            id: element.id,
         })
      });

      this.props.contracts.masterDetails && (!isCmpyAdmin ? this.props.contracts.masterDetails.product_category : (this.props.contracts.masterDetails.product_category[0] || []).product_list).forEach(element => {
         tempProductCategoryNames.push({
            name: element.name,
            id: element.id,
         })
      });

      this.props.contracts.masterDetails && (!isCmpyAdmin ? this.props.contracts.masterDetails.business_volume : ((this.props.contracts.masterDetails.business_volume[0] || []).business_volume_list)).forEach(element => {
         tempUomNames.push({
            name: element.name,
            id: element.id,
         })
      });
      this.props.contracts.masterDetails && (!isCmpyAdmin ? this.props.contracts.masterDetails.vehicle_body_types : (this.props.contracts.masterDetails.vehicle_body_types[0] || []).vehicle_body_types_list).forEach(element => {
         tempVehicleBodyTypeNames.push({
            name: element.name,
            id: element.id,
         })
      });
      this.props.contracts.masterDetails && (!isCmpyAdmin ? this.props.contracts.masterDetails.vehicle_types : (this.props.contracts.masterDetails.vehicle_types[0] || []).vehicle_types_list).forEach(element => {
         tempVehicleTypeNames.push({
            name: element.name,
            id: element.id,
            vehicle_capacity: element.vehicle_capacity
         })
      });
      this.props.contracts.masterDetails && (!isCmpyAdmin ? this.props.contracts.masterDetails.loadability : (this.props.contracts.masterDetails.loadability_list[0] || []).loadability_list).forEach(element => {
         tempLoadability.push({
            name: element.name || element.loadability,
            id: element.id
         })
      });
      if (this.state.selectedChallengePriceCounter && this.state.selectedChallengePriceCounter.value === 0) {
         this.state.responseObj.challenge_price_with_accept_or_reject = true;
      }
      let letArrow = "<", leftDoubleArrow = "<<";
      const { insideTabDropDownName } = this.props;
      return (
         <>
            <div className={"home-navigation-holder mainTabs"}>
               {
                  ((this.props.home.rfqTypes || []).filter(rfq => (this.props.home.moduleList && (this.props.home.moduleList[0] || {}).rfq_types || []).includes(rfq.rfq_type)) || []).map(tab => {
                     return (<div key={tab.rfq_type} className={this.state.contractsRFQType === tab.rfq_type
                        ? "selected-option options"
                        : "options"} onClick={() => {
                           this.setState({ contractsRFQType: tab.rfq_type }, () => this.getBasicContractDetails());
                        }}>{tab.name}</div>)
                  })
               }
            </div>
            {[...CENTURY_PLY, ...ROQUETTE].includes(this.props.company_id) && <div className={"home-navigation-holder mainTabs"}>
               {
                  (this.props.home.contractTenureTypes || []).map(tab => {
                     return (<div key={tab.id} className={this.state.contractTenureType === tab.id
                        ? "selected-option options"
                        : "options"} onClick={() => {
                           this.setState({ contractTenureType: tab.id }, () => this.getBasicContractDetails());
                        }}>{tab.name}</div>)
                  })
               }
            </div>}
            <div className="contracts-management-wrap">
               <div className="contracts-holder">

                  {
                     this.props.loader.isLoading && <Loader />
                  }
                  {
                     this.props.msgpopup.active && <MSGPopUp close={this.closeMsgPopup} type={this.props.msgpopup.type} msg={this.props.msgpopup.msg} />
                  }
                  <div className="contracts-management-grid-wrap">
                     <div className="contracts-management-row">
                        <div className="description-block">RFQ Issue</div>
                        <div className="action-block">
                           <Select className="select" value={this.state.selectedRfq} onChange={this.handleDropDownChange.bind(this, "selectedRfq")} options={tempRfq} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Visibility Of Non-Bidders Before Bid Closure</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'visibility_of_non_bidders_before_bid_closure'} value={this.state.responseObj && this.state.responseObj.visibility_of_non_bidders_before_bid_closure} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Bidding Category</div>
                        <div className="action-block">
                           <Select className="select" value={this.state.selectedBiddingCategory} onChange={this.handleDropDownChange.bind(this, "selectedBiddingCategory")} options={tempBiddingCategory} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">RFQ Deadline Additional Alert Management</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'rfq_deadline_alert_management'} value={this.state.responseObj && this.state.responseObj.rfq_deadline_alert_management} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Time</div>
                        <div className="action-block">
                           <Select className="select" value={this.state.selectedTime} onChange={this.handleDropDownChange.bind(this, "selectedTime")} options={tempTime} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Hide Transporters Name(Bidding Process)</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'hide_transporters_name'} value={this.state.responseObj && this.state.responseObj.hide_transporters_name} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Hide Challenge Price</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'hide_challenge_price'} value={this.state.responseObj && this.state.responseObj.hide_challenge_price} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">My Vendor Bidding Screen</div>
                        <div className="action-block">
                           <Select className="select" value={this.state.selectedVendorBidding} onChange={this.handleDropDownChange.bind(this, "selectedVendorBidding")} options={tempVendorBiddingScreen} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Share RFQ Closing Information With All Vendors</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'share_rfq_info_with_vendors'} value={this.state.responseObj && this.state.responseObj.share_rfq_info_with_vendors} />
                        </div>
                     </div>
                     {/* <div className = "contracts-management-row">
                            <div className = "description-block">Data storage time frame</div>
                            <div className = "action-block"><Select className = "select" value={this.state.selectedDataStorage} onChange={this.handleDropDownChange.bind(this, "selectedDataStorage")} options={tempDataStorage}/></div>
                        </div> */}
                     <div className="contracts-management-row">
                        <div className="description-block">My Bid Decremental Combination Amount</div>
                        <div className="action-block">{!isSetPercentage ? <div className={'rupee-icon'} style={{ backgroundImage: `url(${Rupee})` }}></div> : <div className={'rupee-icon'}>%</div>}<input onChange={this.inputChanged.bind(this, "bid_increment_amount")} value={this.state.responseObj && this.state.responseObj.bid_increment_amount || ''} className="amount" type="text" /></div>
                     </div>
                     {(AWL_COMPANY_IDS.includes(this.props.company_id) || AWL_COMPANY_IDS.includes(getUserCompanyId())) && <div className="contracts-management-row">
                        <div className="description-block">My group bid Decremental Amount</div>
                        <div className="action-block"><div className={'rupee-icon'} style={{ backgroundImage: `url(${Rupee})` }}></div><input onChange={this.inputChanged.bind(this, "group_bid_decremental_amount")} value={this.state.responseObj && this.state.responseObj.group_bid_decremental_amount || ''} className="amount" type="number" onKeyDown={e => (["e", "E", "+", "-"].includes(e.key)) && e.preventDefault()} /></div>
                     </div>}
                     {/* <div className = "contracts-management-row">
                            <div className = "description-block">Vehicle placement flexibility</div>
                            <div className = "action-block"><Select className = "select" value={this.state.selectedVehiclePlacement} onChange={this.handleDropDownChange.bind(this, "selectedVehiclePlacement")} options={tempVehiclePlacement}/></div>
                        </div>*/}

                     {/* <div className = "contracts-management-row">
                            <div className = "description-block">LPP calculation</div> 
                            <div className = "action-block"><Select className = "select" value={this.state.selectedLppCalculation} onChange={this.handleDropDownChange.bind(this, "selectedLppCalculation")} options={tempLppCalculation}/></div> 
                        </div>*/}
                     <div className="contracts-management-row">
                        <div className="description-block">QBR Process</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'qbr_process'} value={this.state.responseObj && this.state.responseObj.qbr_process} />
                        </div>
                     </div>

                     <div className="contracts-management-row">
                        <div className="description-block">QBR Show Rate</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'qbr_show_rate'} value={this.state.responseObj && this.state.responseObj.qbr_show_rate} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Visibility Of TAT For Transporters</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'tat_applicable'} value={this.state.responseObj && this.state.responseObj.tat_applicable} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">L1 Rate Decremental Process</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'l1_rate_decremental'} value={this.state.responseObj && this.state.responseObj.l1_rate_decremental} />{!isSetPercentage ? <div className={'rupee-icon'} style={{ backgroundImage: `url(${Rupee})` }}></div> : <div className={'rupee-icon'}>%</div>}<input onChange={this.inputChanged.bind(this, "l1_rate_decremental_amount")} value={this.state.responseObj && this.state.responseObj.l1_rate_decremental_amount || ''} className="amount" type="text" ref={(e) => { this.l1ratedecrementalamount = e; }} />
                           {this.state.isError && this.state.responseObj && this.state.responseObj.l1_rate_decremental && !this.state.responseObj.l1_rate_decremental_amount && <div className={'contract-error'}>{'Please Enter L1 Decremental Amount'}</div>}
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">L1 Decremental For First Bidder</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'l1_rate_decremental_first_bidder'} value={this.state.responseObj && this.state.responseObj.l1_rate_decremental_first_bidder} />{!isSetPercentage ? <div className={'rupee-icon'} style={{ backgroundImage: `url(${Rupee})` }}></div> : <div className={'rupee-icon'}>%</div>}<input onChange={this.inputChanged.bind(this, "l1_rate_decremental_amount_first_bidder")} value={this.state.responseObj && this.state.responseObj.l1_rate_decremental_amount_first_bidder || ''} className="amount" type="text" ref={(e) => { this.l1ratedecrementalamountfirstbidder = e; }} />
                           {this.state.isError && this.state.responseObj && this.state.responseObj.l1_rate_decremental_first_bidder && !this.state.responseObj.l1_rate_decremental_amount_first_bidder && <div className={'contract-error'}>{'Please Enter The  L1 Decremental Amount For First Bidder'}</div>}
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block"> Enable Tolerance </div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'enable_tolerance'} value={this.state.responseObj && this.state.responseObj.enable_tolerance} /><div className={'tolerance'} >%</div><input onChange={this.inputChanged.bind(this, "enable_tolerance_amount")} value={this.state.responseObj && this.state.responseObj.enable_tolerance_amount || ''} className="amount" type="text" ref={(e) => { this.enabletoleranceamount = e; }} />
                           {this.state.isError && this.state.responseObj && this.state.responseObj.enable_tolerance && !this.state.responseObj.enable_tolerance_amount && <div className={'contract-error'}>{'Please Enter The tolerance %'}</div>}
                        </div>
                     </div>

                     <div className="contracts-management-row">
                        <div className="description-block">Mandatory L1 accept</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'l1_accept_mandatory'} value={this.state.responseObj && this.state.responseObj.l1_accept_mandatory} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Counter To Offer Applicability</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'counter_offer_applicable'} value={this.state.responseObj && this.state.responseObj.counter_offer_applicable} />
                        </div>
                     </div>

                     {/* <div className = "contracts-management-row">
                            <div className = "description-block">Transporter terms & conditions check</div>
                            <div className = "action-block"><Select className = "select" value={this.state.selectedTransporterTerms} onChange={this.handleDropDownChange.bind(this, "selectedTransporterTerms")} options={tempTransporterTerms}/></div>
                        </div>*/}
                     {/* <div className = "contracts-management-row">
                            <div className = "description-block">RFQ amend application</div>
                            <div className = "action-block">
                                <YesNoComponent changeYesNo = {this.changeYesNo} id = {'rfq_amend'}value = {this.state.responseObj && this.state.responseObj.rfq_amend}/><input onChange = {this.inputChanged.bind(this, "rfq_amend_count")} value = {this.state.responseObj && this.state.responseObj.rfq_amend_count || ''} className = "amount" type = "text" ref={(e) => { this.rfqamendCount = e; }}/>
                                {this.state.isError && this.state.responseObj && this.state.responseObj.rfq_amend && !this.state.responseObj.rfq_amend_count &&  <div className={'contract-error'}>{'Please enter RFQ amendment amount'}</div>}
                            </div>
                        </div> */}
                     <div className="contracts-management-row">
                        <div className="description-block">Approved Bidding Rate Control Process</div>
                        <div className="action-block">
                           <div className="bidding-rate">
                              {/* <Select className = "select" value={this.state.selectedBiddingRateEvents} onChange={this.handleDropDownChange.bind(this, "selectedBiddingRateEvents")} options={tempBiddingRateEvents}/> */}
                              <Select className="select" value={this.state.selectedBiddingRateType} onChange={this.handleDropDownChange.bind(this, "selectedBiddingRateType")} options={tempBiddingRateTypes} />
                           </div>
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Interested In Rate Ending With Zero</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'rate_end_with_zero'} value={this.state.responseObj && this.state.responseObj.rate_end_with_zero} />
                        </div>
                     </div>
                     {/* <div className = "contracts-management-row">
                            <div className = "description-block">Challenge price uploading</div>
                            <div className = "action-block"><Select className = "select" value={this.state.selectedChallengePrice} onChange={this.handleDropDownChange.bind(this, "selectedChallengePrice")} options={tempChallengePrice}/></div>
                        </div>*/}

                     {/* <div className = "contracts-management-row">
                            <div className = "description-block">Minimum bidding timespan permitted</div>
                            <div className = "action-block dhm"><YesNoComponent changeYesNo = {this.changeYesNo} id = {'min_bid_time_permit'} subId = {'allow'} value = {this.state.responseObj.min_bid_time_permit && this.state.responseObj.min_bid_time_permit.allow}/><DaysHoursMin id = {'min_bid_time_permit'} val = {this.state.responseObj && this.state.responseObj.min_bid_time_permit || ''} dayChanged = {this.dayChanged}/></div>
                        </div>*/}
                     {/* <div className = "contracts-management-row">
                            <div className = "description-block">Minimum bid extension timespan permitted</div>
                            <div className = "action-block dhm"><YesNoComponent changeYesNo = {this.changeYesNo} id = {'min_bid_time_extension_permit'}  subId = {'allow'} value = {this.state.responseObj.min_bid_time_extension_permit && this.state.responseObj.min_bid_time_extension_permit.allow}/><DaysHoursMin id = {'min_bid_time_extension_permit'} val = {this.state.responseObj && this.state.responseObj.min_bid_time_extension_permit || ''} dayChanged = {this.dayChanged}/></div>
                        </div>*/}
                     {/* <div className = "contracts-management-row">
                            <div className = "description-block">Minimum LOI acceptance timespan permitted</div>
                            <div className = "action-block dhm"><YesNoComponent changeYesNo = {this.changeYesNo} id = {'min_loi_acceptance_permit'} subId = {'allow'}  value = {this.state.responseObj.min_loi_acceptance_permit && this.state.responseObj.min_loi_acceptance_permit.allow}/><DaysHoursMin id = {'min_loi_acceptance_permit'}  val = {this.state.responseObj && this.state.responseObj.min_loi_acceptance_permit || ''} dayChanged = {this.dayChanged}/></div>
                        </div>*/}
                     <div className="contracts-management-row">
                        <div className="description-block">Mandatory Minimum Bidders Per RFQ To Issue LOI</div>
                        <div className="action-block"><Select className="select" value={this.state.selectedMinimumBidders} onChange={this.handleDropDownChange.bind(this, "selectedMinimumBidders")} options={tempMinimumBidders} /></div>
                     </div>
                     {/* <div className = "contracts-management-row">
                            <div className = "description-block">Light compatibility status</div>
                            <div className = "action-block"><Select className = "select" value={this.state.selectedLightCapabilityStatus} onChange={this.handleDropDownChange.bind(this, "selectedLightCapabilityStatus")} options={tempLightCompatibility}/></div>
                        </div>*/}

                     <div className="contracts-management-row">
                        <div className="description-block">LOI Issue Rights - Deligated To Users By Approvers</div>
                        <div className="action-block">
                           <div className="bidding-rate">
                              <YesNoComponent changeYesNo={this.changeYesNo} id={'loi_issue_rights'} value={this.state.responseObj && this.state.responseObj.loi_issue_rights} /><Select className="select" value={this.state.selectedLoiIssueRights} onChange={this.handleDropDownChange.bind(this, "selectedLoiIssueRights")} options={tempLoiIssueRights} />
                           </div>
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Allow Transporter To Enter Business Volume</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'allow_business_volume'} value={this.state.responseObj && this.state.responseObj.allow_business_volume} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Bidding Time Management For Multiple Line Items</div>
                        <div className="action-block">
                           <div className="bidding-time">
                              <Select className="select" value={this.state.selectedBiddingTimeManage} onChange={this.handleDropDownChange.bind(this, "selectedBiddingTimeManage")} options={tempBidTimeManagement} />
                              {
                                 this.state.selectedBiddingTimeManage && this.state.selectedBiddingTimeManage.label && this.state.selectedBiddingTimeManage.label.toLowerCase() != ('single and sure time plan') &&
                                 <div className="days-hours-min-root time-holder">
                                    {
                                       (this.state.selectedBiddingTimeManage.label.toLowerCase() === 'auto bid extension') ?

                                          <div className="days-hours-min-root time-holder">
                                             <div className="days-hours-holder">
                                                <input className="dhm-input" type="text" value={this.state.responseObj.bid_time_management_move || ''} onChange={this.inputChanged.bind(this, "bid_time_management_move")} />
                                                <div className="dhm-label">Time Lap/Move (Min)</div>
                                             </div>
                                             <div className="days-hours-holder">
                                                <input className="dhm-input" type="text" value={this.state.responseObj.bid_time_management_auto || ''} onChange={this.inputChanged.bind(this, "bid_time_management_auto")} />
                                                <div className="dhm-label">Auto Extn (Min)</div>
                                             </div>
                                             <div className="days-hours-holder">
                                                <input className="dhm-input" type="text" value={this.state.responseObj.bid_time_management_count || ''} onChange={this.inputChanged.bind(this, "bid_time_management_count")} />
                                                <div className="dhm-label times">No. Of Times</div>
                                             </div>
                                          </div> : null
                                    }
                                    {
                                       (this.state.selectedBiddingTimeManage.label.toLowerCase() === 'relay time closing') ?
                                          <div className="days-hours-min-root time-holder">
                                             <div className="days-hours-holder">
                                                <input className="dhm-input" type="text" value={this.state.responseObj.bid_time_management_move || ''} onChange={this.inputChanged.bind(this, "bid_time_management_move")} />
                                                <div className="dhm-label">Time Lap/Move (Min)</div>
                                             </div>
                                          </div> : null
                                    }

                                    {
                                       (this.state.selectedBiddingTimeManage.label.toLowerCase() === 'autobid and relaytime extension') ?

                                          <div className="days-hours-min-root time-holder">
                                             <div className="days-hours-holder">
                                                <input className="dhm-input" type="text" value={this.state.responseObj.bid_time_management_move || ''} onChange={this.inputChanged.bind(this, "bid_time_management_move")} />
                                                <div className="dhm-label">Time Lap/Move (Min)</div>
                                             </div>
                                             <div className="days-hours-holder">
                                                <input className="dhm-input" type="text" value={this.state.responseObj.bid_time_management_auto || ''} onChange={this.inputChanged.bind(this, "bid_time_management_auto")} />
                                                <div className="dhm-label">Auto Extn (Min)</div>
                                             </div>
                                             <div className="days-hours-holder">
                                                <input className="dhm-input" type="text" value={this.state.responseObj.bid_time_management_count || ''} onChange={this.inputChanged.bind(this, "bid_time_management_count")} />
                                                <div className="dhm-label times">No. Of Times</div>
                                             </div>
                                             <div className="days-hours-holder">
                                                <input className="dhm-input" type="text" value={this.state.responseObj.bid_time_management_relay || ''} onChange={this.inputChanged.bind(this, "bid_time_management_relay")} />
                                                <div className="dhm-label times">Relay Time</div>
                                             </div>
                                          </div> : null
                                    }

                                    {
                                       (this.state.selectedBiddingTimeManage.label.toLowerCase() === 'auto bid extension comparison l1') ?

                                          <div className="days-hours-min-root time-holder">
                                             <div className="days-hours-holder">
                                                <input className="dhm-input" type="text" value={this.state.responseObj.bid_time_management_move || ''} onChange={this.inputChanged.bind(this, "bid_time_management_move")} />
                                                <div className="dhm-label">Time Lap/Move (Min)</div>
                                             </div>
                                             <div className="days-hours-holder">
                                                <input className="dhm-input" type="text" value={this.state.responseObj.bid_time_management_auto || ''} onChange={this.inputChanged.bind(this, "bid_time_management_auto")} />
                                                <div className="dhm-label">Auto Extn (Min)</div>
                                             </div>
                                             <div className="days-hours-holder">
                                                <input className="dhm-input" type="text" value={this.state.responseObj.bid_time_management_count || ''} onChange={this.inputChanged.bind(this, "bid_time_management_count")} />
                                                <div className="dhm-label times">No. Of Times</div>
                                             </div>
                                          </div> : null
                                    }

                                    {/* <div className = "days-hours-holder">
                                                <input className = "dhm-input" type = "text" value = {this.state.responseObj.bid_time_management_move || ''} onChange = {this.inputChanged.bind(this, "bid_time_management_move")}/>
                                                <div className = "dhm-label">Time lap/move (min)</div>
                                            </div>
                                            <div className = "days-hours-holder">
                                                <input className = "dhm-input" type = "text" value = {this.state.responseObj.bid_time_management_auto || ''} onChange = {this.inputChanged.bind(this, "bid_time_management_auto")}/>
                                                <div className = "dhm-label">Auto Extn (min)</div>
                                            </div>
                                            <div className = "days-hours-holder">
                                                <input className = "dhm-input" type = "text" value = {this.state.responseObj.bid_time_management_count || ''} onChange = {this.inputChanged.bind(this, "bid_time_management_count")}/>
                                                <div className = "dhm-label times">No. of times</div>
                                            </div> */}
                                 </div>
                              }
                           </div>
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">LOI With Challenge Price</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'loi_with_challenge_price'} value={this.state.responseObj && this.state.responseObj.loi_with_challenge_price} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Challenge Price With Counter</div>
                        <div className="action-block"><Select className="select" value={this.state.selectedChallengePriceCounter} onChange={this.handleDropDownChange.bind(this, "selectedChallengePriceCounter")} options={challengePriceWithCounter} /></div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Challenge Price With Accept / Reject</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'challenge_price_with_accept_or_reject'} value={this.state.responseObj && this.state.responseObj.challenge_price_with_accept_or_reject} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Auto Send LOI to L1 Transporter</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'auto_send_loi_only_l1_bid'} value={this.state.responseObj && this.state.responseObj.auto_send_loi_only_l1_bid} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Auto Accept LOI for All Transporter</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'auto_loi_accept'} value={this.state.responseObj && this.state.responseObj.auto_loi_accept} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Auto Select Transporters</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'auto_select_transporters'} value={this.state.responseObj && this.state.responseObj.auto_select_transporters} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Auto Set Bidding End Time</div>
                        <div className="action-block"><Select className="select" value={this.state.selectedBidEndTime} onChange={this.handleDropDownChange.bind(this, "selectedBidEndTime")} options={tempBidEndTime} /></div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Auto Indent</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'auto_indent'} value={this.state.responseObj && this.state.responseObj.auto_indent} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">LOI Acceptance Time</div>
                        <div className="action-block">
                           <TimePicker
                              showSecond={false}
                              placeholder={'Time'}
                              className={'wt-150 ht-30'}
                              value={this.state.responseObj && this.state.responseObj.loi_acceptance_period && Moment(this.state.responseObj.loi_acceptance_period, 'HH:mm') || null}
                              onChange={(value) => {
                                 let { responseObj } = this.state;
                                 responseObj.loi_acceptance_period = Moment(value).format('HH:mm');
                                 this.setState({ responseObj });
                              }
                              }
                           />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Indent CuttOff Time</div>
                        <div className="action-block indent-time">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'indent_cutoff_config'} value={this.state.responseObj && this.state.responseObj.indent_cutoff_config} />
                           {this.state.responseObj && this.state.responseObj.indent_cutoff_config &&
                              <Fragment>
                                 <input className="amount" type="text" placeholder="Time" value={this.state.responseObj.indent_cutoff_config_value || ''} onChange={this.inputChanged.bind(this, "indent_cutoff_config_value")} />
                                 <Select className="select-uom" value={this.state.responseObj.indent_cutoff_config_value_uom && TAT_UNITS.find(tat => tat.value === this.state.responseObj.indent_cutoff_config_value_uom)} onChange={(option) => {
                                    const { responseObj } = this.state;
                                    responseObj.indent_cutoff_config_value_uom = option.value;
                                    this.setState({ responseObj });
                                 }} options={TAT_UNITS} />
                              </Fragment>}
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Vehicle Placement Time</div>
                        <div className="action-block indent-time">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'vehicle_placement_config'} value={this.state.responseObj && this.state.responseObj.vehicle_placement_config} />
                           {this.state.responseObj && this.state.responseObj.vehicle_placement_config &&
                              <Fragment>
                                 <input className="amount" type="text" placeholder="Time" value={this.state.responseObj.vehicle_placement_config_value || ''} onChange={this.inputChanged.bind(this, "vehicle_placement_config_value")} />
                                 <Select className="select-uom" value={this.state.responseObj.vehicle_placement_config_value_uom && TAT_UNITS.find(tat => tat.value === this.state.responseObj.vehicle_placement_config_value_uom)} onChange={(option) => {
                                    const { responseObj } = this.state;
                                    responseObj.vehicle_placement_config_value_uom = option.value;
                                    this.setState({ responseObj });
                                 }} options={TAT_UNITS} />
                              </Fragment>}
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Show Ceiling Price Before Bid Start</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'show_ceiling_price_before_bid_start'} value={this.state.responseObj && this.state.responseObj.show_ceiling_price_before_bid_start} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Bid Ranking(Include Time Of Bid)</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'bid_ranking_include_time_of_bid'} value={this.state.responseObj && this.state.responseObj.bid_ranking_include_time_of_bid} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Auto Extension Below L1 Bid</div>

                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'auto_extension_only_l1_bid'} value={this.state.responseObj && this.state.responseObj.auto_extension_only_l1_bid} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Cancel LOI enable/disable</div>
                        <div className="action-block indent-time">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'cancel_loi_enable_or_disable'} value={this.state.responseObj && this.state.responseObj.cancel_loi_enable_or_disable} />
                           <Fragment>
                              <input className="amount" type="text" placeholder="Eg.3 Times" value={this.state.responseObj.minimum_count_send_to_cancel_loi || ''} onChange={this.inputChanged.bind(this, "minimum_count_send_to_cancel_loi")} />
                           </Fragment>
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Minimum Bidders To Create An RFQ</div>
                        <div className="action-block indent-time">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'minimum_bidders_to_create_rfq'} value={this.state.responseObj && this.state.responseObj.minimum_bidders_to_create_rfq} />
                           {this.state.responseObj && this.state.responseObj.minimum_bidders_to_create_rfq &&
                              <Fragment>
                                 <input className="amount" type="text" placeholder="Eg. 5" value={this.state.responseObj.minimum_bidders_count_to_create_rfq || ''} onChange={this.inputChanged.bind(this, "minimum_bidders_count_to_create_rfq")} />
                              </Fragment>}
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Rate Record Capture Based System Config</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'rate_record_capture_based_system_config'} value={this.state.responseObj && this.state.responseObj.rate_record_capture_based_system_config} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">CP Decremental Process</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'cp_rate_decremental'} value={this.state.responseObj && this.state.responseObj.cp_rate_decremental} /><div className={'rupee-icon'} style={{ backgroundImage: `url(${Rupee})` }}></div><input onChange={this.inputChanged.bind(this, "cp_rate_decremental_amount")} value={this.state.responseObj && this.state.responseObj.cp_rate_decremental_amount || ''} className="amount" type="text" ref={(e) => { this.cpratedecrementalamount = e; }} />
                           {this.state.isError && this.state.responseObj && this.state.responseObj.cp_rate_decremental && !this.state.responseObj.cp_rate_decremental_amount && <div className={'contract-error'}>{'Please Enter CP Decremental Amount'}</div>}
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Bid Started enable/disable</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'show_bid_started_message'} value={this.state.responseObj && this.state.responseObj.show_bid_started_message} />
                        </div>
                     </div>
                     {!(CARGILL_COMPANY_IDS.includes(this.props.company_id)) && <div className="contracts-management-row">
                        <div className="description-block">Pricing Basis With QBR Rate</div>
                        <div className="action-block">
                           <div className="bidding-time">
                              <Select className="select" value={this.state.selectedPricingBasisWithPrice} onChange={this.handleDropDownChange.bind(this, "selectedPricingBasisWithPrice")} options={tempPricingBasisTypeWithPrice} />
                              {
                                 this.state.selectedPricingBasisWithPrice && this.state.selectedPricingBasisWithPrice.label && this.state.selectedPricingBasisWithPrice.label.toLowerCase() != ('') &&
                                 <div className="days-hours-min-root time-holder">
                                    {
                                       (this.state.selectedPricingBasisWithPrice.label.toLowerCase() === 'rate per mt' || this.state.selectedPricingBasisWithPrice.label.toLowerCase() === 'both (rate/mt and rate/trip)') ?

                                          <div className="days-hours-min-root time-holder">
                                             <div className="days-hours-holder">
                                                <input className="dhm-input" type="number" value={this.state.responseObj.pricing_basis_qbr_rate || ''} onChange={this.inputChanged.bind(this, "pricing_basis_qbr_rate")} />
                                                <div className="dhm-label">QBR Rate</div>
                                             </div>
                                          </div> : null
                                    }

                                 </div>
                              }
                           </div>
                        </div>
                     </div>}
                     <div className="contracts-management-row">
                        <div className="description-block">Hide Ceiling Price</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'hide_ceiling_price'} value={this.state.responseObj && this.state.responseObj.hide_ceiling_price} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Visibility Of Bidding Status After LOI Sent</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'visibility_of_bidding_status_after_loi_Sent'} value={this.state.responseObj && this.state.responseObj.visibility_of_bidding_status_after_loi_Sent} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Round Bidding</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'round_bidding'} value={this.state.responseObj && this.state.responseObj.round_bidding} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Withdraw Bid</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'withdraw_bid'} value={this.state.responseObj && this.state.responseObj.withdraw_bid} />
                           {this.state.responseObj && this.state.responseObj.withdraw_bid && <input onChange={this.inputChanged.bind(this, "maximum_count_to_withdraw_bid")} value={this.state.responseObj && this.state.responseObj.maximum_count_to_withdraw_bid || ''} className="amount" type="number" />}
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Bid Price Increase</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'price_increase'} value={this.state.responseObj && this.state.responseObj.price_increase} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Show IPAddress</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'ip_address_enable'} value={this.state.responseObj && this.state.responseObj.ip_address_enable} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">RFQ Email And SMS Notification to the Transporter</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'email_sms_enable'} value={this.state.responseObj && this.state.responseObj.email_sms_enable} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">RFQ Amendment</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'rfq_amendment'} value={this.state.responseObj && this.state.responseObj.rfq_amendment} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Share Of Business</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'share_of_business'} value={this.state.responseObj && this.state.responseObj.share_of_business} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Chatbox Yes/No</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'chat_box_enable'} value={this.state.responseObj && this.state.responseObj.chat_box_enable} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Total Value Visibility for Transporter</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'total_value_visibility_on_provider'} value={this.state.responseObj && this.state.responseObj.total_value_visibility_on_provider} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Total Value Of L1 Visibility For Transporter</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'total_value_visibility_of_L1_on_provider'} value={this.state.responseObj && this.state.responseObj.total_value_visibility_of_L1_on_provider} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Visibility Of Lot Ranking For Transporter</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'visibility_of_lot_ranking_for_provider'} value={this.state.responseObj && this.state.responseObj.visibility_of_lot_ranking_for_provider} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">CP Accept/Reject with Time</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'challenge_price_accept_or_reject_time'} value={this.state.responseObj && this.state.responseObj.challenge_price_accept_or_reject_time} />
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Live bidding Transporter Addition</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'live_bidding_transporter_addition'} value={this.state.responseObj && this.state.responseObj.live_bidding_transporter_addition} />
                        </div>
                     </div>
                     {this.state.responseObj && EMAMI.includes(this.state.responseObj.company_id) &&
                        <div className="contracts-management-row">
                           <div className="description-block">Transporter ranking based on range</div>
                           <div className="action-block">
                              <div className={'rupee-icon'} style={{ backgroundImage: `url(${Rupee})` }}></div><input className="amount" type="number" value={this.state.responseObj.transporter_ranking_range || ''} onChange={this.inputChanged.bind(this, "transporter_ranking_range")} />
                           </div>
                        </div>
                     }
                     <div className="contracts-management-row">
                        <div className="description-block">Accept LOI Without Bidding</div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'is_loi_ceiling_accepted'} value={this.state.responseObj && this.state.responseObj.is_loi_ceiling_accepted} /><div className={'rupee-icon'} style={{ backgroundImage: `url(${Rupee})` }}></div><input onChange={this.inputChanged.bind(this, "deduction_price")} value={this.state.responseObj && this.state.responseObj.deduction_price || ''} className="amount" type="text" ref={(e) => { this.deductionPrice = e; }} />
                           {this.state.isError && this.state.responseObj && this.state.responseObj.is_loi_ceiling_accepted && !this.state.responseObj.deduction_price && <div className={'contract-error'}>{'Please Enter Deduction Price'}</div>}
                        </div>
                     </div>
                     <div className="contracts-management-row">
                        <div className="description-block">Enable bidding via whatsapp </div>
                        <div className="action-block">
                           <YesNoComponent changeYesNo={this.changeYesNo} id={'bidding_via_whatsapp'} value={this.state.responseObj && this.state.responseObj.bidding_via_whatsapp} />
                        </div>
                     </div>
                  </div>
               </div>
               {this.state.isError && <div className={'contract-error'}>{this.state.errorMessage}</div>}
               <div className="button-holder">
                  {!this.props.contracts.basicContract && <div className="add-button" onClick={this.addBasicContract}>ADD</div>}
                  {this.props.contracts.basicContract && <div className="add-button" onClick={this.editBasicContract}>SAVE</div>}
               </div>

               {/* <div className = "golden-box">
                    <div className = "heading">Decremental cost combination 
                        <div className = {this.state.checkDropDown[1] ? "arrow-icon rotate" : "arrow-icon"}  style={{backgroundImage:`url(${ Dropup })`}} onClick = {() => this.toggleCheck(1)}>
                            {/* <input type = "checkbox" checked={this.state.checkDropDown[1]} onChange = {() => this.toggleCheck(1)}/> 
                        </div>
                    </div>  
                    <div className = {this.state.checkDropDown[1] ? "drop-open" : "drop-close"}>  
                        <div className = "golden-box-content">
                            <div className = "input-holder"><Select className = "select" onChange={this.handleDropDownChange.bind(this, "selectedTransportService")}  value = {this.state.selectedTransportService} options = {transportServcies}/><span className = "labels">Transport Service</span></div>
                            <div className = "input-holder"><Select className = "select" onChange={this.handleDropDownChange.bind(this, "selectedCargo")}  value = {this.state.selectedCargo} options = {cargoUnits}/><span className = "labels">Unit</span></div>
                            <div className = "input-holder"><div className="rupee-icon" style={{backgroundImage:`url${Rupee}`}}></div><input className = "input" type = "text" value = {this.state.rate} onChange = {this.inputTextChanged.bind(this, "rate")}/><span className = "labels">Rate</span></div>
                            <div className = "input-holder"><div className = "add-button" onClick = {()=>this.createCollapsibleSection(1)}>+Add</div></div>
                        </div>
                        <div className = "golden-box-display">
                            <div className = "headers"><div className = "labels">Transporter Service</div></div>
                            <div className = "headers"><div className = "labels">Cargo</div></div>
                            <div className = "headers"><div className = "labels">Rate</div></div>
                            <div className = "headers"><div className = "labels">Action</div></div>
                        </div> 
                        {this.props.contracts.type1 && this.props.contracts.type1.length == 0 && <div className = "golden-box-display">Nothing here, Please add</div>}
                        {this.props.contracts && this.props.contracts.type1 && this.props.contracts.type1.map((element) => {
                            return(
                                <div className = "golden-box-display">
                                    <div className = "details"><div className = "labels">{element.transporter_service_name}</div></div>
                                    <div className = "details"><div className = "labels">{element.cargo_unit_name}</div></div>
                                    <div className = "details"><div className = "labels">{element.rate}</div></div>
                                    <div className = "details"><div className = "add-button" onClick = {()=>this.deleteCollapsibleSection(element, 1)}>Delete</div></div>
                                </div>
                            )
                        })}
                    </div>
                </div> */}

               {/* <div className = "golden-box">
                    <div className = "heading">Non-existing contract while indenting 
                        <div className = {this.state.checkDropDown[2] ? "open-arrow" : "closed-arrow"}>^
                            <input type = "checkbox" checked={this.state.checkDropDown[2]} onChange = {() => this.toggleCheck(2)}/>
                        </div>
                    </div> 
                    <div className = {this.state.checkDropDown[2] ? "drop-open" : "drop-close"}>  
                        <div className = "golden-box-content">
                            <div className = "input-holder"><input value = {this.state.type2Name} onChange = {this.inputTextChanged.bind(this, "type2Name")} className = "input" type = "text" /><span className = "labels">Name</span></div>
                            <div className = "input-holder"><input value = {this.state.type2email} onChange = {this.inputTextChanged.bind(this, "type2email")} className = "input" type = "text" /><span className = "labels">Email</span></div>
                            <div className = "input-holder"><div className = "add-button" onClick = {()=>this.createCollapsibleSection(2)}>+Add</div><span className = "labels"></span></div>
                        </div>
                        <div className = "golden-box-display">
                            <div className = "headers"><div className = "labels">Name</div></div>
                            <div className = "headers"><div className = "labels">Email</div></div>
                            <div className = "headers"><div className = "labels">Action</div></div>
                        </div> 
                        {this.props.contracts.type2 && this.props.contracts.type2.length == 0 && <div className = "golden-box-display">Nothing here, Please add</div>}
                            {this.props.contracts &&this.props.contracts.type2 && this.props.contracts.type2.map((element) => {
                                return(
                                    <div className = "golden-box-display">
                                        <div className = "details"><div className = "labels">{element.name}</div></div>
                                        <div className = "details"><div className = "labels">{element.email}</div></div>
                                        <div className = "details"><div className = "add-button" onClick = {()=>this.deleteCollapsibleSection(element, 2)}>Delete</div></div>
                                    </div>
                                )
                            })}
                    </div>
                </div> */}


               {/* <div className = "golden-box">
                    <div className = "heading">Regular contract expiry pre-alert
                        <div className = {this.state.checkDropDown[3] ? "arrow-icon rotate" : "arrow-icon"}  style={{backgroundImage:`url(${ Dropup })`}} onClick = {() => this.toggleCheck(3)}>
                            {/* <input type = "checkbox" checked={this.state.checkDropDown[3]} onChange = {() => this.toggleCheck(3)}/> 
                        </div>
                    </div>   
                    <div className = {this.state.checkDropDown[3] ? "drop-open" : "drop-close"}>  
                        <div className = "golden-box-content">
                            <div className = "input-holder"><input value = {this.state.type3Name} onChange = {this.inputTextChanged.bind(this, "type3Name")} className = "input" type = "text" /><span className = "labels">Name</span></div>
                            <div className = "input-holder"><input value = {this.state.type3email} onChange = {this.inputTextChanged.bind(this, "type3email")} className = "input" type = "text" /><span className = "labels">Email</span></div>
                            <div className = "input-holder"><input value = {this.state.type3pre_alert} onChange = {this.inputTextChanged.bind(this, "type3pre_alert")} className = "input" type = "number" /><span className = "labels">Pre-alert</span></div>
                            <div className = "input-holder"><div className = "add-button" onClick = {()=>this.createCollapsibleSection(3)}>+Add</div></div>
                        </div>
                        <div className = "golden-box-display">
                            <div className = "headers"><div className = "labels">Name</div></div>
                            <div className = "headers"><div className = "labels">Email</div></div>
                            <div className = "headers"><div className = "labels">Pre Alert</div></div>
                            <div className = "headers"><div className = "labels">Action</div></div>
                        </div> 
                        {this.props.contracts.type3 && this.props.contracts.type3.length == 0 && <div className = "golden-box-display">Nothing here, Please add</div>}
                        {this.props.contracts && this.props.contracts.type3 && this.props.contracts.type3.map((element) => {
                            return(
                                <div className = "golden-box-display">
                                    <div className = "details"><div className = "labels">{element.name}</div></div>
                                    <div className = "details"><div className = "labels">{element.email}</div></div>
                                    <div className = "details"><div className = "labels">{element.pre_alert}</div></div>
                                    <div className = "details"><div className = "add-button" onClick = {()=>this.deleteCollapsibleSection(element, 3)}>Delete</div></div>
                                </div>
                            )
                        })}
                    </div>
                </div> */}

               {/* <div className = "golden-box">
                    <div className = "heading">LOI contacts 
                    <div className = {this.state.checkDropDown[4] ? "arrow-icon rotate" : "arrow-icon"}  style={{backgroundImage:`url(${ Dropup })`}} onClick = {() => this.toggleCheck(4)}>
                        {/* <input type = "checkbox" checked={this.state.checkDropDown[4]} onChange = {() => this.toggleCheck(4)}/> 
                    </div>
                    </div>   
                    <div className = {this.state.checkDropDown[4] ? "drop-open" : "drop-close"}>  
                        <div className = "golden-box-content">
                            <div className = "input-holder"><input value = {this.state.type4Name} onChange = {this.inputTextChanged.bind(this, "type4Name")} className = "input" type = "text" /><span className = "labels">Name</span></div>
                            <div className = "input-holder"><input value = {this.state.type4email} onChange = {this.inputTextChanged.bind(this, "type4email")} className = "input" type = "text" /><span className = "labels">Email</span></div>
                            <div className = "input-holder"><div className = "add-button" onClick = {()=>this.createCollapsibleSection(4)}>+Add</div></div>
                        </div>
                        <div className = "golden-box-display">
                            <div className = "headers"><div className = "labels">Name</div></div>
                            <div className = "headers"><div className = "labels">Email</div></div>
                            <div className = "headers"><div className = "labels">Action</div></div>
                        </div> 
                        {this.props.contracts &&this.props.contracts.type4 && this.props.contracts.type4.map((element) => {
                            return(
                                <div className = "golden-box-display">
                                    <div className = "details"><div className = "labels">{element.name}</div></div>
                                    <div className = "details"><div className = "labels">{element.email}</div></div>
                                    <div className = "details"><div className = "add-button" onClick = {()=>this.deleteCollapsibleSection(element, 4)}>Delete</div></div>
                                </div>
                            )
                        })}
                    </div>
                </div> */}

               <div className="golden-box">
                  <div className="heading">LOI Approver
                     <div className={this.state.checkDropDown[7] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(7)}>
                     </div>
                  </div>
                  <div className={this.state.checkDropDown[7] ? "drop-open" : "drop-close"}>
                     <div className="golden-box-content">
                        <div className="input-holder">
                           {(this.state.selectedApproverEmail && this.state.selectedApproverEmail.length > 0 && this.state.selectedApproverEmail.map((mail) => {
                              return (
                                 <React.Fragment>
                                    <div>{mail.value}</div>
                                    <span className="labels">Email</span><br />
                                 </React.Fragment>
                              )
                           }))}

                        </div>
                        <div className="contracts-management-row">
                           <div className="description-block">Email</div>
                           <div className="action-block">
                              <Select isMulti={true} className="select" value={this.state.selectedApproverEmail} onChange={this.handleDropDownChange.bind(this, "selectedApproverEmail")} options={emailList} />
                           </div>
                        </div>
                        <div className="input-holder"><div className="add-button" onClick={() => this.addApproverEmailList()}>Add</div></div>
                     </div>
                     <div className="golden-box-display">
                        <div className="headers"><div className="labels">LOI Approver Email</div></div>
                        <div className="headers"><div className="labels">Action</div></div>
                     </div>
                     {this.state.approver_List !== null && this.state.approver_List && this.state.approver_List.map((element) => {
                        return (
                           <div className="golden-box-display">
                              <div className="details"><div className="labels">{element.email}</div></div>
                              <div className="details"><div className="add-button" onClick={() => this.removeApproverEmail(element)}>Delete</div></div>
                           </div>
                        )
                     })}
                  </div>
               </div>
               <div className="golden-box">
                  <div className="heading">Currency Converter
                     <div className={this.state.checkDropDown[8] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(8)}>
                     </div>
                  </div>
                  <div className={this.state.checkDropDown[8] ? "drop-open" : "drop-close"}>
                     <div className="golden-box-content">
                        <div className="contracts-management-row currecy_converter">
                           <div className="action-block currencey_name">
                              <div className="currency_label">USD TO INR </div> <input className="input_currency" type="number" value={this.state.currencyObj.usdtoinr} onChange={this.inputChangedCurrency.bind(this, "usdtoinr")} />
                           </div>
                           <div className="action-block currencey_name">
                              <div className="currency_label">EUR TO INR</div> <input className="input_currency" type="number" value={this.state.currencyObj.eurtoinr} onChange={this.inputChangedCurrency.bind(this, "eurtoinr")} />
                           </div>
                           <div className="action-block currencey_name">
                              <div className="currency_label">GBP TO INR</div> <input className="input_currency" type="number" value={this.state.currencyObj.gbptoinr} onChange={this.inputChangedCurrency.bind(this, "gbptoinr")} />
                           </div>
                           <div className="input-holder"><div className="add-button" onClick={() => this.currencyConverter()}>Save</div></div>
                        </div>
                     </div>

                  </div>
               </div>

               <div className="golden-box">
                  <div className="heading">Auto Bid Decrement Range
                     <div className={this.state.checkDropDown[12] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(12)}>
                     </div>
                  </div>
                  <div className={this.state.checkDropDown[12] ? "drop-open" : "drop-close"}>
                     <div className="golden-box-content" style={{ display: 'flex', flexDirection: 'column' }}>
                        <table className="tg">
                           <thead>
                              <tr>
                                 <th className="tg-0lax">Celling Price Range</th>
                                 <th className="tg-0lax">Auto bid Decrement</th>
                                 <th className="tg-0lax">Actions</th>
                              </tr>
                           </thead>
                           <tbody>
                              {(this.state.auto_decrement_range || []).map((data, idx) => {
                                 return (
                                    <tr>
                                       <td className="tg-0lax">
                                          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                             <input style={{ width: '70px', border: 'none', outline: 'none', padding: '5px' }} type="number" value={data.fromRange} placeholder='From' />
                                             -
                                             <input style={{ width: '70px', border: 'none', outline: 'none', padding: '5px' }} type="number" value={data.toRange} placeholder='To' onChange={(e) => { this.autoBidInputChange(idx, 'toRange', e) }} />
                                          </div>
                                       </td>
                                       <td className="tg-0lax">
                                          <input style={{ width: 'auto', border: 'none', outline: 'none', padding: '5px' }} type="number" value={data.decrementAmount} placeholder='Amount' onChange={(e) => { this.autoBidInputChange(idx, 'decrementAmount', e) }} />
                                       </td>
                                       <td className="tg-0lax">
                                          <div style={{ display: 'flex' }}>
                                             {(idx === this.state.auto_decrement_range.length - 1) && <img src={plusIcon} style={{ width: '20px', cursor: 'pointer' }} onClick={() => this.addAutoBidField(idx)} />}
                                             <img
                                                src={deleteIcon}
                                                style={{ width: '20px', marginLeft: "10px", cursor: 'pointer' }}
                                                onClick={() => {
                                                   if (idx === 0) {
                                                      this.clearAllAutoBidData()
                                                   } else {
                                                      this.removeAutoBidField(idx)
                                                   }
                                                }}
                                             />
                                          </div>
                                       </td>
                                    </tr>
                                 )
                              })}
                           </tbody>
                        </table>
                        <div className="input-holder" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                           <div className="add-button"
                              onClick={() => this.addAutoBidDecrement()}
                           >
                              Save
                           </div>
                        </div>
                     </div>

                  </div>
               </div>
               {(!isCompanyAdmin() ? this.props.company_id === this.props.branch_id : true) && <div>
                  <div style={{ 'textAlign': 'center', 'fontSize': '22px' }}>Master Configurations</div>
                  {isCompanyAdmin() && <div className={"home-navigation-holder mainTabs"}>
                     {
                        ((this.props.home.rfqTypes || []).filter(rfq => (this.props.home.moduleList && (this.props.home.moduleList[0] || {}).rfq_types || []).includes(rfq.rfq_type)) || []).map(tab => {
                           return (<div key={tab.rfq_type} className={this.state.rfqType === tab.rfq_type
                              ? "selected-option options"
                              : "options"} onClick={() => {
                                 this.setState({ rfqType: tab.rfq_type }, () => this.getPlantWiseContractDetails({
                                    company_id: this.props.company_id,
                                    branch_id: this.props.branch_id,
                                    rfqType: this.state.rfqType
                                 }));
                              }}>{tab.name}</div>)
                        })
                     }
                  </div>}
                  <div className="golden-box">
                     <div className="heading">Product Category
                        <div className={this.state.checkDropDown[9] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(9)}>
                        </div>
                     </div>
                     <div className={this.state.checkDropDown[9] ? "drop-open" : "drop-close"}>
                        <div className="product-header-name">All Product Category </div>
                        <div className={'product-list'}>
                           <div className={'col-2 mr-10 '}>
                              {(tempProductCategoryNames && tempProductCategoryNames.length > 0 && tempProductCategoryNames.map((packaName, id) => {
                                 return (
                                    <React.Fragment>
                                       <div key={id}>
                                          <span className="intial-product-list" style={{ 'color': (this.state.tempProductCategorObj || []).find(productCategory => productCategory.name == (packaName.name)) ? '#528aea' : 'black' }} onClick={() => this.selectProductName(packaName)}>{packaName.name}</span>
                                       </div>
                                    </React.Fragment>
                                 )
                              }))}

                           </div>
                           <div className={'col-1 mr-5'}>

                              <React.Fragment>
                                 <div>
                                    <div className="add-product-button right-single-arrow" onClick={() => this.addSingleDetails("productCategory")}>{'>'}</div>
                                    <div className="add-product-button right-double-arrow" onClick={() => this.addAllDetails("productCategory")}>{'>>'}</div>

                                 </div>
                              </React.Fragment>

                              <React.Fragment>
                                 <div>
                                    <div className="add-product-button left-single-arrow" onClick={() => this.deleteSingleDetails("productCategory")}>{letArrow}</div>
                                    <div className="add-product-button" onClick={() => this.deleteAllDetails("productCategory")}>{leftDoubleArrow}</div>

                                 </div>
                              </React.Fragment>
                           </div>

                           <div className={'col-2 mr-10'}>
                              {(this.state.productCategoryName && this.state.productCategoryName.length > 0 && this.state.productCategoryName.map((packaName, id) => {
                                 return (
                                    <React.Fragment>
                                       <div key={id}>
                                          <div className="seleted-product-list" onClick={() => this.seletedProductName(packaName)}>{packaName.name}</div>
                                       </div>
                                    </React.Fragment>
                                 )
                              }))}
                           </div>
                           <div className="input-holder"><div className="add-button" onClick={() => this.saveContractDetails("productCategory")}>save</div></div>
                        </div>

                        <span className="product-error-message">{this.state.errormessage}</span>

                     </div>
                  </div>

                  <div className="golden-box">
                     <div className="heading">Packaging Type
                        <div className={this.state.checkDropDown[5] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(5)}>
                        </div>
                     </div>
                     <div className={this.state.checkDropDown[5] ? "drop-open" : "drop-close"}>
                        <div className="product-header-name">All Packaging Name</div>
                        <div className={'product-list'}>
                           <div className={'col-2 mr-10 '}>
                              {(tempPackagingTypeNames && tempPackagingTypeNames.length > 0 && tempPackagingTypeNames.map((packaName, id) => {
                                 return (
                                    <React.Fragment>
                                       <div key={id}>
                                          <span className="intial-product-list" style={{ 'color': (this.state.tempPackagingTypeObj || []).find(packingType => packingType.name == (packaName.name)) ? '#528aea' : 'black' }} onClick={() => this.selectPackagingTypeName(packaName)}>{packaName.name}</span>
                                       </div>
                                    </React.Fragment>
                                 )

                              }))}

                           </div>
                           <div className={'col-1 mr-5'}>

                              <React.Fragment>
                                 <div>
                                    <div className="add-product-button right-single-arrow" onClick={() => this.addSingleDetails("packaging")}>{'>'}</div>
                                    <div className="add-product-button right-double-arrow" onClick={() => this.addAllDetails("packaging")}>{'>>'}</div>

                                 </div>
                              </React.Fragment>

                              <React.Fragment>
                                 <div>
                                    <div className="add-product-button left-single-arrow" onClick={() => this.deleteSingleDetails("packaging")}>{letArrow}</div>
                                    <div className="add-product-button" onClick={() => this.deleteAllDetails("packaging")}>{leftDoubleArrow}</div>

                                 </div>
                              </React.Fragment>
                           </div>

                           <div className={'col-2 mr-10'}>
                              {(this.state.selectedPackagingTypeName && this.state.selectedPackagingTypeName.length > 0 && this.state.selectedPackagingTypeName.map((packaName, id) => {
                                 return (
                                    <React.Fragment>
                                       <div key={id}>
                                          <div className="seleted-product-list" onClick={() => this.seletedPackagingTypeName(packaName)}>{packaName.name}</div>
                                       </div>
                                    </React.Fragment>
                                 )
                              }))}
                           </div>
                           <div className="input-holder"><div className="add-button" onClick={() => this.saveContractDetails("packaging")}>save</div></div>
                        </div>

                        <span className="product-error-message">{this.state.errormessage}</span>
                     </div>
                  </div>
                  <div className="golden-box">
                     <div className="heading">Pricing Basis
                        <div className={this.state.checkDropDown[4] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(4)}>
                        </div>
                     </div>
                     <div className={this.state.checkDropDown[4] ? "drop-open" : "drop-close"}>
                        <div className="product-header-name">All Pricing Basis</div>
                        <div className={'product-list'}>
                           <div className={'col-2 mr-10 '}>
                              {(tempPricingBasisNames && tempPricingBasisNames.length > 0 && tempPricingBasisNames.map((packaName, id) => {
                                 return (
                                    <React.Fragment>
                                       <div key={id}>
                                          <span className="intial-product-list" style={{ 'color': (this.state.tempPricingBasisObj || []).find(pb => pb.name == (packaName.name)) ? '#528aea' : 'black' }} onClick={() => this.selectPricingBasisName(packaName)}>{packaName.name}</span>
                                       </div>
                                    </React.Fragment>
                                 )

                              }))}

                           </div>
                           <div className={'col-1 mr-5'}>

                              <React.Fragment>
                                 <div>
                                    <div className="add-product-button right-single-arrow" onClick={() => this.addSingleDetails("pricing")}>{'>'}</div>
                                    <div className="add-product-button right-double-arrow" onClick={() => this.addAllDetails("pricing")}>{'>>'}</div>

                                 </div>
                              </React.Fragment>

                              <React.Fragment>
                                 <div>
                                    <div className="add-product-button left-single-arrow" onClick={() => this.deleteSingleDetails("pricing")}>{letArrow}</div>
                                    <div className="add-product-button" onClick={() => this.deleteAllDetails("pricing")}>{leftDoubleArrow}</div>

                                 </div>
                              </React.Fragment>
                           </div>

                           <div className={'col-2 mr-10'}>
                              {(this.state.pricingBasisName && this.state.pricingBasisName.length > 0 && this.state.pricingBasisName.map((packaName, id) => {
                                 return (
                                    <React.Fragment>
                                       <div key={id}>
                                          <div className="seleted-product-list" onClick={() => this.seletedPricingBasisName(packaName)}>{packaName.name}</div>
                                       </div>
                                    </React.Fragment>
                                 )
                              }))}
                           </div>
                           <div className="input-holder"><div className="add-button" onClick={() => this.saveContractDetails("pricing")}>save</div></div>
                        </div>

                        <span className="product-error-message">{this.state.errormessage}</span>

                     </div>
                  </div>
                  <div className="golden-box">
                     <div className="heading">Vehicle Type
                        <div className={this.state.checkDropDown[3] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(3)}>
                        </div>
                     </div>
                     <div className={this.state.checkDropDown[3] ? "drop-open" : "drop-close"}>
                        <div className="product-header-name">All Vehicle Type</div>
                        <div className={'product-list'}>
                           <div className={'col-2 mr-10 '}>
                              {(tempVehicleTypeNames && tempVehicleTypeNames.length > 0 && tempVehicleTypeNames.map((packaName, id) => {
                                 return (
                                    <React.Fragment>
                                       <div key={id}>
                                          <span className={`intial-product-list`} style={{ 'color': (this.state.tempVehicleTypeObj || []).find(vehicle => vehicle.name == (packaName.name)) ? '#528aea' : 'black' }} onClick={() => this.selectVehicleTypeName(packaName)}>{packaName.name}</span>
                                       </div>
                                    </React.Fragment>
                                 )

                              }))}

                           </div>
                           <div className={'col-1 mr-5'}>

                              <React.Fragment>
                                 <div>
                                    <div className="add-product-button right-single-arrow" onClick={() => this.addSingleDetails("vehicleType")}>{'>'}</div>
                                    <div className="add-product-button right-double-arrow" onClick={() => this.addAllDetails("vehicleType")}>{'>>'}</div>

                                 </div>
                              </React.Fragment>

                              <React.Fragment>
                                 <div>
                                    <div className="add-product-button left-single-arrow" onClick={() => this.deleteSingleDetails("vehicleType")}>{letArrow}</div>
                                    <div className="add-product-button" onClick={() => this.deleteAllDetails("vehicleType")}>{leftDoubleArrow}</div>

                                 </div>
                              </React.Fragment>
                           </div>

                           <div className={'col-2 mr-10'}>
                              {(this.state.vehicleTypeName && this.state.vehicleTypeName.length > 0 && this.state.vehicleTypeName.map((packaName, id) => {
                                 return (
                                    <React.Fragment>
                                       <div key={id}>
                                          <div className="seleted-product-list" onClick={() => this.seletedVehicleTypeName(packaName)}>{packaName.name}</div>
                                       </div>
                                    </React.Fragment>
                                 )
                              }))}
                           </div>
                           <div className="input-holder"><div className="add-button" onClick={() => this.saveContractDetails("vehicleType")}>save</div></div>
                        </div>

                        <span className="product-error-message">{this.state.errormessage}</span>

                     </div>
                  </div>
                  <div className="golden-box">
                     <div className="heading">Vehicle Details
                        <div className={this.state.checkDropDown[11] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(11)}>
                        </div>
                     </div>
                     <div className={this.state.checkDropDown[11] ? "drop-open" : "drop-close"}>
                        <div className="product-header-name flex">
                           <div className="col-4">Configured Vehicles</div>
                           <div className="col-4">Vehicle Capacity</div>
                           <div className="col-4">Wheel Count</div>
                           <div className="col-4">SAP Vehicle Capacity</div>
                           <div className="col-4">SAP Vehicle Type</div>
                           <div className="col-4">Equipment Type</div>
                           {/* <div className="col-4">Action</div>  */}
                        </div>
                        <div className="product-header-name">
                           {(this.state.vehicleTypeName && this.state.vehicleTypeName.length > 0 && this.state.vehicleTypeName.map((packaName, id) => {
                              return (
                                 <React.Fragment>
                                    <div key={id} className='flex'>
                                       <div className="product-details-list col-4">{packaName.name}</div>
                                       <div className='col-4'>
                                          <input
                                             onChange={(e) => {
                                                const { vehicleTypeName } = this.state;
                                                const val = e.target.value && Number(e.target.value) || e.target.value;
                                                if (DEEPAK_COMPANY_ID.includes(getUserCompanyId())) {
                                                   vehicleTypeName[id].carrying_capacity = val;
                                                } else if (GREENPLY_COMPANY_IDS.includes(getUserCompanyId())) {
                                                   vehicleTypeName[id].vehicle_capacity_MT = val;
                                                } else {
                                                   vehicleTypeName[id].vehicle_capacity = val;
                                                }
                                                this.setState({ vehicleTypeName })
                                             }}
                                             value={this.state.vehicleTypeName && this.state.vehicleTypeName[id].vehicle_capacity || ''}
                                             className="product-fields"
                                             type="number"
                                          />
                                       </div>
                                       <div className='col-4'>
                                          <input
                                             onChange={(e) => {
                                                const { vehicleTypeName } = this.state;
                                                vehicleTypeName[id].wheel_count = e.target.value && Number(e.target.value) || e.target.value;
                                                this.setState({ vehicleTypeName })
                                             }}
                                             value={this.state.vehicleTypeName && this.state.vehicleTypeName[id].wheel_count || ''}
                                             className="product-fields"
                                             type="number"
                                          />
                                       </div>
                                       <div className='col-4'>
                                          <input
                                             onChange={(e) => {
                                                const { vehicleTypeName } = this.state;
                                                vehicleTypeName[id].sap_vehicle_capacity = e.target.value;
                                                this.setState({ vehicleTypeName })
                                             }}
                                             value={this.state.vehicleTypeName && this.state.vehicleTypeName[id].sap_vehicle_capacity || ''}
                                             className="product-fields"
                                          />
                                       </div>
                                       <div className='col-4'>
                                          <input
                                             onChange={(e) => {
                                                const { vehicleTypeName } = this.state;
                                                vehicleTypeName[id].sap_vehicle_type = e.target.value;
                                                this.setState({ vehicleTypeName })
                                             }}
                                             value={this.state.vehicleTypeName && this.state.vehicleTypeName[id].sap_vehicle_type || ''}
                                             className="product-fields"
                                          />
                                       </div>
                                       <div className='col-4 equipment-option' style={{'height': '50px', 'border': '1.5px solid #627fe9', 'borderRadius': '5px' }}>
                                          <Select
                                             placeholder="Select.." 
                                             classNamePrefix="equipment_select"
                                             menuPortalTarget={document.body} 
                                             styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                             value={this.state.vehicleTypeName && (insideTabDropDownName || []).find(el => el.dropdown_name == this.state.vehicleTypeName[id].equipment_type)}
                                             onChange={(option) => {
                                                const { vehicleTypeName } = this.state;
                                                vehicleTypeName[id].equipment_type = option.dropdown_name;
                                                vehicleTypeName[id].equipment_type_id = option.dropdown_id;
                                                this.setState({ vehicleTypeName })
                                             }}
                                             getOptionLabel={option => option.dropdown_name}
                                             getOptionValue={option => option.dropdown_id}
                                             options={insideTabDropDownName || []} 
                                          />
                                       </div>
                                    </div>
                                 </React.Fragment>
                              )
                           }))}
                        </div>
                        <div>
                           <div className="input-holder"><div className="add-button" onClick={() => this.saveContractDetails("vehicleType")}>Save</div></div>
                        </div>
                        <span className="product-error-message">{this.state.errormessage}</span>

                     </div>
                  </div>
                  <div className="golden-box">
                     <div className="heading">Vehicle Body Type
                        <div className={this.state.checkDropDown[2] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(2)}>
                        </div>
                     </div>
                     <div className={this.state.checkDropDown[2] ? "drop-open" : "drop-close"}>
                        <div className="product-header-name">All Vehicle Body Type</div>
                        <div className={'product-list'}>
                           <div className={'col-2 mr-10 '}>
                              {(tempVehicleBodyTypeNames && tempVehicleBodyTypeNames.length > 0 && tempVehicleBodyTypeNames.map((packaName, id) => {
                                 return (
                                    <React.Fragment>
                                       <div key={id}>
                                          <span className="intial-product-list" style={{ 'color': (this.state.tempVehicleBodyObj || []).find(vehicleBody => vehicleBody.name == (packaName.name)) ? '#528aea' : 'black' }} onClick={() => this.selectVehicleBodyTypeName(packaName)}>{packaName.name}</span>
                                       </div>
                                    </React.Fragment>
                                 )

                              }))}

                           </div>
                           <div className={'col-1 mr-5'}>

                              <React.Fragment>
                                 <div>
                                    <div className="add-product-button right-single-arrow" onClick={() => this.addSingleDetails("vehicleBody")}>{'>'}</div>
                                    <div className="add-product-button right-double-arrow" onClick={() => this.addAllDetails("vehicleBody")}>{'>>'}</div>

                                 </div>
                              </React.Fragment>

                              <React.Fragment>
                                 <div>
                                    <div className="add-product-button left-single-arrow" onClick={() => this.deleteSingleDetails("vehicleBody")}>{letArrow}</div>
                                    <div className="add-product-button" onClick={() => this.deleteAllDetails("vehicleBody")}>{leftDoubleArrow}</div>

                                 </div>
                              </React.Fragment>
                           </div>

                           <div className={'col-2 mr-10'}>
                              {(this.state.vehicleBodyTypeName && this.state.vehicleBodyTypeName.length > 0 && this.state.vehicleBodyTypeName.map((packaName, id) => {
                                 return (
                                    <React.Fragment>
                                       <div key={id}>
                                          <div className="seleted-product-list" onClick={() => this.seletedVehicleBodyTypeName(packaName)}>{packaName.name}</div>
                                       </div>
                                    </React.Fragment>
                                 )
                              }))}
                           </div>
                           <div className="input-holder"><div className="add-button" onClick={() => this.saveContractDetails("vehicleBody")}>save</div></div>
                        </div>

                        <span className="product-error-message">{this.state.errormessage}</span>

                     </div>
                  </div>

                  <div className="golden-box">
                     <div className="heading">UOM
                        <div className={this.state.checkDropDown[1] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(1)}>
                        </div>
                     </div>
                     <div className={this.state.checkDropDown[1] ? "drop-open" : "drop-close"}>
                        <div className="product-header-name">All UOM</div>
                        <div className={'product-list'}>
                           <div className={'col-2 mr-10 '}>
                              {(tempUomNames && tempUomNames.length > 0 && tempUomNames.map((packaName, id) => {
                                 return (
                                    <React.Fragment>
                                       <div key={id}>
                                          <span className="intial-product-list" style={{ 'color': (this.state.tempUomNameObj || []).find(uom => uom.name == (packaName.name)) ? '#528aea' : 'black' }} onClick={() => this.selectUomName(packaName)}>{packaName.name}</span>
                                       </div>
                                    </React.Fragment>
                                 )

                              }))}

                           </div>
                           <div className={'col-1 mr-5'}>

                              <React.Fragment>
                                 <div>
                                    <div className="add-product-button right-single-arrow" onClick={() => this.addSingleDetails("uom")}>{'>'}</div>
                                    <div className="add-product-button right-double-arrow" onClick={() => this.addAllDetails("uom")}>{'>>'}</div>

                                 </div>
                              </React.Fragment>

                              <React.Fragment>
                                 <div>
                                    <div className="add-product-button left-single-arrow" onClick={() => this.deleteSingleDetails("uom")}>{letArrow}</div>
                                    <div className="add-product-button" onClick={() => this.deleteAllDetails("uom")}>{leftDoubleArrow}</div>

                                 </div>
                              </React.Fragment>
                           </div>

                           <div className={'col-2 mr-10'}>
                              {(this.state.uomName && this.state.uomName.length > 0 && this.state.uomName.map((packaName, id) => {
                                 return (
                                    <React.Fragment>
                                       <div key={id}>
                                          <div className="seleted-product-list" onClick={() => this.seletedUomName(packaName)}>{packaName.name}</div>
                                       </div>
                                    </React.Fragment>
                                 )
                              }))}
                           </div>
                           <div className="input-holder"><div className="add-button" onClick={() => this.saveContractDetails("uom")}>save</div></div>
                        </div>

                        <span className="product-error-message">{this.state.errormessage}</span>

                     </div>
                  </div>

                  <div className="golden-box">
                     <div className="heading">Loadability
                        <div className={this.state.checkDropDown[10] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(10)}>
                        </div>
                     </div>
                     <div className={this.state.checkDropDown[10] ? "drop-open" : "drop-close"}>
                        <div className="product-header-name">All Loadability</div>
                        <div className={'product-list'}>
                           <div className={'col-2 mr-10 '}>
                              {(tempLoadability && tempLoadability.length > 0 && tempLoadability.map((loadability, id) => {
                                 return (
                                    <React.Fragment>
                                       <div key={id}>
                                          <span className="intial-product-list" style={{ 'color': (this.state.tempLoadabilityObj || []).find(el => el.name == (loadability.name)) ? '#528aea' : 'black' }} onClick={() => this.selectLoadability(loadability)}>{loadability.name}</span>
                                       </div>
                                    </React.Fragment>
                                 )

                              }))}

                           </div>
                           <div className={'col-1 mr-5'}>

                              <React.Fragment>
                                 <div>
                                    <div className="add-product-button right-single-arrow" onClick={() => this.addSingleDetails("loadability")}>{'>'}</div>
                                    <div className="add-product-button right-double-arrow" onClick={() => this.addAllDetails("loadability")}>{'>>'}</div>

                                 </div>
                              </React.Fragment>

                              <React.Fragment>
                                 <div>
                                    <div className="add-product-button left-single-arrow" onClick={() => this.deleteSingleDetails("loadability")}>{letArrow}</div>
                                    <div className="add-product-button" onClick={() => this.deleteAllDetails("loadability")}>{leftDoubleArrow}</div>

                                 </div>
                              </React.Fragment>
                           </div>

                           <div className={'col-2 mr-10'}>
                              {(this.state.loadabilityValues && this.state.loadabilityValues.length > 0 && this.state.loadabilityValues.map((el, id) => {
                                 return (
                                    <React.Fragment>
                                       <div key={id}>
                                          <div className="seleted-product-list" onClick={() => this.seletedLoadabilityValues(el)}>{el.name}</div>
                                       </div>
                                    </React.Fragment>
                                 )
                              }))}
                           </div>
                           <div className="input-holder"><div className="add-button" onClick={() => this.saveContractDetails("loadability")}>save</div></div>
                        </div>

                        <span className="product-error-message">{this.state.errormessage}</span>

                     </div>
                  </div>
               </div>}


               {/* <div className="golden-box">
               <div className="heading">Approver Matrix
                    <div className={this.state.checkDropDown[7] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(7)}>
                  </div>
               </div>
               <div className={this.state.checkDropDown[7] ? "drop-open" : "drop-close"}>
                  <div className="select-approver">
                     <Select 
                        placeholder="Select Approver Type" 
                        value={this.state.approverType}
                        onChange={this.onSelectApprover}
                        options={approverTypes || []} 
                     />
                     </div>
                  {(this.state.responseObj.approval_matrix || []).map((approverList, apporverIndex) => {
                  return(<div className={'golden-box golden-box-sub'}>
                     <div className={'heading sub-heading'}>
                        {approverList.approver_type === 1 ? "LOI Approver" : "Performa Approver"}
                     </div>

                        {(approverList.levels || []).map((emailLevel, emailInd) => {
                        return(<div>
                           <div className="level-header">{`Level ${emailInd + 1}`}</div>
                           <div className="approval-row">
                              <Select 
                                 isMulti={true} 
                                 value={this.state.approver_values[apporverIndex] && this.state.approver_values[apporverIndex]['levels'] && this.state.approver_values[apporverIndex]['levels'][emailInd] && this.state.approver_values[apporverIndex]['levels'][emailInd].emails || [] }
                                 className="select Email"
                                 onChange={(option) => this.onSelectEmail(option, apporverIndex, emailInd)}
                                 options={emailList} 
                              />
                              <div className="add-button" onClick={() => this.addLevelApprover(apporverIndex, emailInd)}>Add</div>
                           </div>
                              <div className="golden-box-display">
                              <div className="headers"><div className="labels">Approver Email</div></div>
                              <div className="headers"><div className="labels">Action</div></div>
                           </div>
                           {(emailLevel.emails || []).map((element, eleInd) => {
                              return (
                                 <div className="golden-box-display">
                                    <div className="details"><div className="labels">{element}</div></div>
                                    <div className="details"><div className = "delete-icon" style={{backgroundImage: `url(${Delete})`}} onClick = {() => this.deleteLevelApprover(apporverIndex, emailInd, eleInd)}></div></div>
                                 </div>
                              )
                           })}
                        </div>)})}
                        <div className="golden-box-content approver-matrix">
                           <div className="add-button add-level" onClick={() => this.addApproverLevel(apporverIndex)}>Add Level</div>
                        </div>
                  </div>)
                  })} 
               </div> 
            </div> */}



               {/* <div className = "golden-box">
                    <div className = "heading">Fuel escalation details 
                        <div className = {this.state.checkDropDown[5] ? "arrow-icon rotate" : "arrow-icon"}  style={{backgroundImage:`url(${ Dropup })`}} onClick = {() => this.toggleCheck(5)}>
                        {/* <input type = "checkbox" checked={this.state.checkDropDown[5]} onChange = {() => this.toggleCheck(5)}/> 
                        </div>
                    </div>   
                    <div className = {this.state.checkDropDown[5] ? "drop-open" : "drop-close"}>  
                        <FuelEscalationDetails showMsgPopup = {this.showMsgPopup}deleteCollapsibleSection = {this.deleteCollapsibleSection} escalationData = {this.props.contracts.type5} branch_id = {this.props.branch_id} company_id = {this.props.company_id} createCollapsibleSection = {this.createCollapsibleSectionForFuelEscalation}/>
                    </div>
                </div> */}

               {/* <div className = "golden-box">
                    <div className = "heading">User shipment accessibility
                        <div className = {this.state.checkDropDown[6] ? "arrow-icon rotate" : "arrow-icon"}  style={{backgroundImage:`url(${ Dropup })`}} onClick = {() => this.toggleCheck(6)}>
                        {/* <input type = "checkbox" checked={this.state.checkDropDown[6]} onChange = {() => this.toggleCheck(6)}/> 
                        </div>
                    </div>  
                    <div className = {this.state.checkDropDown[6] ? "drop-open" : "drop-close"}>  
                        <div className = "golden-box-content">
                            <div className = "add-role-section">
                                <div className = "add-items">
                                    <Select placeholder = "Users" className = "select" value={this.state.selectedUserRole} onChange={this.handleDropDownChange.bind(this, "selectedUserRole")} options={roleOptions} />
                                </div>
                                <div className = "add-items">
                                     <YesNoComponent changeYesNo = {this.accessibilityYesNo} id = {'selectedRoleAccessibility'} value = {this.state.selectedRoleAccessibility}/>
                                </div>
                                <div className = "add-items">
                                    <div className = "add-button" onClick = {()=>this.createCollapsibleSection(6)}>+ Add</div>
                                </div>
                             </div>
                        </div>
                        <div className = "user-shipment-accessibility-list-wrap header">
                                <div className = "user-name">Username</div>
                                <div className = "role-name">Role</div>
                                <div className = "accessibility-status">Accessibility</div>
                                <div className = "list-action">Action</div>
                         </div>
                         {this.props.contracts.type6 && this.props.contracts.type6.length > 0 && 
                         this.props.contracts.type6.map((data , index)=>{
                             return(
                                <div key = {index}className = "user-shipment-accessibility-list-wrap">
                                    <div className = "user-name">{data.user_name}</div>
                                    <div className = "role-name">Role</div>
                                    <div className = "accessibility-status">
                                         <YesNoComponent id = {''} value = {data.accessibility}/>
                                    </div>
                                    <div className = "list-action" onClick = {()=>this.deleteCollapsibleSection(data, 6)} >Delete</div>
                                </div>
                             )
                         })}
                    </div>
                </div> */}
               <div className="apply-settings">
                  <CustomCheckBox toggle={this.clickCustomeCheckBoxService} selected={this.state.applyToOtherBranches} />
                  <div className="label">Apply These Settings To Other Plants/Depots As Well</div>
               </div>
               {this.state.applyToOtherBranches && <SelectBranches company_id={this.props.company_id} setting_id={this.state.responseObj.setting_id} toggle={this.clickCustomeCheckBoxService} applyBranches={this.applyBranches} branchList={this.props.contracts.branchList} getBranches={this.getBranches} />}
            </div>
         </>
      );
   }
}
const CustomCheckBox = (props) => {
   return (
      <div className={props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick={() => props.toggle()}>
         {props.selected && <div className="inner-circle"></div>}
      </div>
   )
}

const mapStateToProps = state => {
   return {
      home: state.home,
      loader: state.loader,
      msgpopup: state.msgpopup,
      contracts: state.contracts,
      insideTabDropDownName: state.master.insideTabDropDownName || []
   }
}

export default connect(mapStateToProps)(ContractsManagement);
