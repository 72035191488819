import React, {PropTypes} from 'react';
import './yes_no.less';

export default class YesNoComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        selectedOption : this.props.value
    }
  }

  componentWillReceiveProps(newProps){
    this.setState({
      selectedOption : newProps.value
    })
  }
  setOption = (val) => {
      this.setState({
        selectedOption : val
      });
      this.props.changeYesNo(val, this.props.id,this.props.subId);
  }

  render() {
    return (
      <div className = "yes-no-root">
        <div className = "yes-no-holder">
            <div className = {!this.state.selectedOption ? "selected-option option" : "option"} onClick = {()=>this.setOption(false)}>No</div>
            <div className = {this.state.selectedOption ? "selected-option option" : "option"} onClick = {()=>this.setOption(true)}>Yes</div>
        </div>
      </div>
    )
  }
}
