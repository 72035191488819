import React, { Component, Fragment } from 'react';
import InputText from '../../common/components/input-text/input-text';
import Button from '../button-classic/button-classic';
import './vendor_details.less';
import { connect } from "react-redux";
import ACTIONS from '../../common/action_constant';
import { getCookie } from "../../../utils/cookies";
import Moment from "moment";

const FILE_TYPES = [
    {id: 1, name: 'PAN Card'},
    {id: 2, name: 'Bank Detials And Cancelled Cheque'},
    {id: 3, name: 'Memorandam Of Association'},
    {id: 4, name: 'Article For Association'},
    {id: 5, name: 'Compliance Declaration Form'},
    {id: 6, name: 'GST Certificates'},
    {id: 7, name: 'FSSAI'},
    {id: 8, name: 'MSA'},
]
class VendorDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyId: JSON.parse(getCookie("user")).company_id
        }
    }

    componentDidMount(){
        this.getBasicDetails();
        this.getProviderUsers();
    }
    
    getBasicDetails = () => {
        const { dispatch } = this.props;
        let data = "?companyId=" + this.props.id + "&companyType=2";
        dispatch({ type: ACTIONS.HOME.GET_BASIC_DETAILS_FOR_VENDOR_SCREEN, data });
    };

    getProviderUsers = () => {
        const { dispatch } = this.props;
        const data ={
          id: this.props.id,
          company_type: 2,
          vendorScreen: true,
          seekerId: this.state.companyId
        }  
        dispatch({type : ACTIONS.HOME.GET_PROVIDER_USERS, data})
      }

    getFileName = (id) => {
        let fileName = '';
        FILE_TYPES.map((file) => {
            if(file.id === id){
                fileName = file.name;
            }
        })
        return fileName;
    }

    render() {
        const { basicDetails, providersUsers } = this.props;
        return (
            <div className="vendor_detail">
                <div onClick={this.props.toggleAdd} className="overlay"></div>
                <div id="modal" className="modal-popup">
                    <div className="pop-heading">
                        {`Vendor Details`}
                    </div>
                    <div className='ml-20 mt-10 sub-heading'> Admin Details </div>
                    <div className='flex'>
                        <div className='col-4 p-10 mt-10 bg-white'>
                            <div className='vendor-header'>Name: </div>
                            <div className='vendor-data'>{basicDetails.admin_name || ''}</div>
                        </div>
                        <div className='col-4 p-10 mt-10 bg-white'>
                            <div className='vendor-header'>Email: </div>
                            <div className='vendor-data'>{basicDetails.email || ''}</div>
                        </div>
                        <div className='col-4 p-10 mt-10 bg-white'>
                            <div className='vendor-header'>Mobile: </div>
                            <div className='vendor-data'>{basicDetails.contact || ''}</div>
                        </div>
                    </div>
                    <div className='ml-20 mt-10 sub-heading'> Company Details - Basic</div>
                    <div className='flex'>
                        <div className='col-4 p-10 mt-10 bg-white'>
                            <div className='vendor-header'>Company Name: </div>
                            <div className='vendor-data'>{basicDetails.company_name || ''}</div>
                        </div>
                        <div className='col-4 p-10 mt-10 bg-white'>
                            <div className='vendor-header'>Website: </div>
                            <div className='vendor-data'>{basicDetails.company_website || ''}</div>
                        </div>
                        <div className='col-4 p-10 mt-10 bg-white'>
                            <div className='vendor-header'>Company Email: </div>
                            <div className='vendor-data'>{basicDetails.company_email || ''}</div>
                        </div>
                    </div>
                    <div className='ml-20 mt-10 sub-heading'> SAP Vendor Details </div>
                    {(basicDetails.sap_vendor_details || []).map((sap) => {
                        if(sap.company_id == this.state.companyId){
                            return(
                                <div className='flex'>
                                    <div className='col-4 p-10 mt-10 bg-white'>
                                        <div className='vendor-header'>Company Name: </div>
                                        <div className='vendor-data'>{sap.company || ''}</div>
                                    </div>
                                    {sap.branch_id && sap.branch_id.length > 0 && <div className='col-4 p-10 mt-10 bg-white'>
                                        <div className='vendor-header'>Branch: </div>
                                        <div className='vendor-data'>{((sap.branch_id || []).map(branch => branch.label) || []).join(',') || ''}</div>
                                    </div>}
                                    <div className='col-4 p-10 mt-10 bg-white'>
                                        <div className='vendor-header'>SAP Vendor Code: </div>
                                        <div className='vendor-data'>{sap.sap_vendor_code || ''}</div>
                                    </div>
                                </div>
                            )
                        }
                    })}
                    <div className='ml-20 mt-10 sub-heading'> Uploaded Documents </div>
                    {(basicDetails.documents || []).map((doc) => {
                        return(
                            <div className='flex' style={{boxShadow: '0 0 27px 4px rgba(69,68,68,.07)'}}>
                                <div className='col-4 p-10 mt-10 bg-white'>
                                    <div className='vendor-header'>{this.getFileName(doc.fileId)}</div>
                                    <div className='vendor-data'>
                                        <a href={doc.url} download>
                                            {doc.name}
                                        </a>
                                    </div>
                                </div>
                                {doc.fileId === 2 &&
                                <>
                                <div className='col-4 p-10 mt-10 bg-white'>
                                    <div className='vendor-header'>Bank Name: </div>
                                    <div className='vendor-data'>{basicDetails.bank_name || ''}</div>
                                </div>
                                <div className='col-4 p-10 mt-10 bg-white'>
                                    <div className='vendor-header'>Bank IFSC Code: </div>
                                    <div className='vendor-data'>{basicDetails.bank_ifsc_code || ''}</div>
                                </div>
                                <div className='col-4 p-10 mt-10 bg-white'>
                                    <div className='vendor-header'>Account Number: </div>
                                    <div className='vendor-data'>{basicDetails.bank_account_no || ''}</div>
                                </div>
                                </>
                                }
                                {doc.fileId === 3 &&
                                <>
                                <div className='col-4 p-10 mt-10 bg-white'>
                                    <div className='vendor-header'>Incorporation Date: </div>
                                    <div className='vendor-data'>{basicDetails.incorporation_date ? Moment(basicDetails.incorporation_date).format('DD MMM YYYY') : ''}</div>
                                </div>
                                </>
                                }
                                {doc.fileId === 7 &&
                                <>
                                <div className='col-4 p-10 mt-10 bg-white'>
                                    <div className='vendor-header'>FSSAI Certificate Number: </div>
                                    <div className='vendor-data'>{basicDetails.fssai_certificate_number || ''}</div>
                                </div>
                                <div className='col-4 p-10 mt-10 bg-white'>
                                    <div className='vendor-header'>Expiry Date: </div>
                                    <div className='vendor-data'>{basicDetails.fssai_validity_date ? Moment(basicDetails.fssai_validity_date).format('DD MMM YYYY') : ''}</div>
                                </div>
                                </>
                                }
                                {
                                    doc.fileId === 8 && 
                                    <div className='col-4 p-10 mt-10 bg-white'>
                                        <div className='vendor-header'>Expiry Date: </div>
                                        <div className='vendor-data'>{basicDetails.msa_validity_date ? Moment(basicDetails.msa_validity_date).format('DD MMM YYYY') : ''}</div>
                                    </div>
                                }

                            </div>
                        )
                    })}
                    <div className='ml-20 mt-10 sub-heading'> Tax Details</div>
                    <div className='flex'>
                        <div className='col-4 p-10 mt-10 bg-white'>
                            <div className='vendor-header'>PAN No.: </div>
                            <div className='vendor-data'>{basicDetails.pan_no || ''}</div>
                        </div>
                        <div className='col-4 p-10 mt-10 bg-white'>
                            <div className='vendor-header'>Last Financial Year Turnover: </div>
                            <div className='vendor-data'>{(basicDetails.last_fiscal_turnover || {}).value + ' ' + (basicDetails.last_fiscal_turnover || {}).name}</div>
                        </div>
                        <div className='col-4 p-10 mt-10 bg-white'>
                            <div className='vendor-header'>Tax ID: </div>
                            <div className='vendor-data'>{(basicDetails.tax_id || {}).name}</div>
                        </div>
                        <div className='col-4 p-10 mt-10 bg-white'>
                            <div className='vendor-header'>Tax No.: </div>
                            <div className='vendor-data'>{basicDetails.tax_no || ''}</div>
                        </div>
                    </div>
                    <div className='ml-20 mt-10 sub-heading'> GSTIN Details </div>
                    {(basicDetails.gstin_details || []).map((gst) => {
                        return(
                            <div className='flex'>
                                <div className='col-4 p-10 mt-10 bg-white'>
                                    <div className='vendor-header'>State: </div>
                                    <div className='vendor-data'>{gst.state || ''}</div>
                                </div>
                                <div className='col-4 p-10 mt-10 bg-white'>
                                    <div className='vendor-header'>GSTIN: </div>
                                    <div className='vendor-data'>{gst.gstin || ''}</div>
                                </div>
                                <div className='col-4 p-10 mt-10 bg-white'>
                                    <div className='vendor-header'>GST %: </div>
                                    <div className='vendor-data'>{gst.gst_percentage || ''}</div>
                                </div>
                                <div className='col-4 p-10 mt-10 bg-white'>
                                    <div className='vendor-header'>Vendor Code: </div>
                                    <div className='vendor-data'>{gst.vendor_code || ''}</div>
                                </div>
                            </div>
                        )
                    })}
                    <div className='ml-20 mt-10 sub-heading'> Establishment Details</div>
                    <div className='flex'>
                        <div className='col-4 p-10 mt-10 bg-white'>
                            <div className='vendor-header'>Establishment Category: </div>
                            <div className='vendor-data'>{(basicDetails.establishment_category || {}).name}</div>
                        </div>
                        <div className='col-4 p-10 mt-10 bg-white'>
                            <div className='vendor-header'>Year of Registration: </div>
                            <div className='vendor-data'>{basicDetails.year_of_formation || ''}</div>
                        </div>
                    </div>
                    <div className='ml-20 mt-10 sub-heading'> User Details</div>
                    {(providersUsers || []).map((user) => {
                        return(
                        <>
                        <div className='flex'>
                            <div className='col-4 p-10 mt-10 bg-white'>
                                <div className='vendor-header'>Name: </div>
                                <div className='vendor-data'>{user.user_name || ''}</div>
                            </div>
                            <div className='col-4 p-10 mt-10 bg-white'>
                                <div className='vendor-header'>Email: </div>
                                <div className='vendor-data'>{user.user_email || ''}</div>
                            </div>
                            <div className='col-4 p-10 mt-10 bg-white'>
                                <div className='vendor-header'>Contact: </div>
                                <div className='vendor-data'>{user.user_contact || ''}</div>
                            </div>
                            <div className='col-4 p-10 mt-10 bg-white'>
                                <div className='vendor-header'>Location: </div>
                                <div className='vendor-data'>{user.location || ''}</div>
                            </div>
                        </div>
                        <div className='flex'>
                            <div className='col-4 p-10 mt-10 bg-white'>
                                <div className='vendor-header'>Mode of Transport: </div>
                                {(user.modeOfTransport || []).map((mode) => {
                                    return(
                                        <div className='vendor-data'>{mode.name || ''}</div>
                                    )
                                })}
                            </div>
                            <div className='col-4 p-10 mt-10 bg-white'>
                                <div className='vendor-header'>Module Access: </div>
                                {(user.modules_access_list || []).map((module) => {
                                    if(module.enable){
                                        return(
                                            <div className='vendor-data'>{module.module_name || ''}</div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                        {(user.company_list || []).map((list) => {
                            return (
                                <div className='flex'>
                                    <div className='col-4 p-10 mt-10 bg-white'>
                                        <div className='vendor-header'>Company Associated: </div>
                                        <div className='vendor-data'>{list.name || ''}</div>
                                    </div>
                                    <div className='col-4 p-10 mt-10 bg-white'>
                                        <div className='vendor-header'>Company Branches: </div>
                                        {(list.branches || []).map((branch) => {
                                            return(
                                                <div className='vendor-data'>{branch.branch_name || ''}</div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                        
                    </>
                    )})}
                    
                    <div className='mt-10 mb-20 tCenter bg-theme p-10'>
                        <Button value="Close" click={this.props.toggleAdd} />
                    </div>
                </div> 
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        basicDetails : state.home.basicDetailsForVendorScreen  || [],
        providersUsers : state.home.providersUsers || []
    };
};

export default connect(mapStateToProps)(VendorDetails);
