import React, { Component} from 'react';
import './inventory.less';
import PfizerItem from './pfizer-item/pfizer-item';
import Bacardi from './bacardi/bacardi';


class Inventory extends Component {
	constructor(props){
		super(props);
		this.state = {
		}
	}

	render() {
		return (
			<div className = "root">
				<PfizerItem  
					getCommonConstants={this.props.getCommonConstants}
					getInventoryItem={this.props.getInventoryItem}
					addInventoryItem={this.props.addInventoryItem}
					updateInventoryItem={this.props.updateInventoryItem}
					deleteInventoryItem={this.props.deleteInventoryItem}
					itemData={this.props.itemData}
					downloadInventoryItem={this.props.downloadInventoryItem}
					downloadUrl={this.props.downloadUrl}
					uploadInventoryItem={this.props.uploadInventoryItem}
					getPackagingDetails={this.props.getPackagingDetails}
					packagingDetails={this.props.packagingDetails}
					uploadRateChart={this.props.uploadRateChart}
					downloadRateChart={this.props.downloadRateChart}
					getRateChartDetails={this.props.getRateChartDetails}
					onAddRateChart={this.props.onAddRateChart}
					onDeleteRate={this.props.onDeleteRate}
					onUpdateRateChart={this.props.onUpdateRateChart}
				/>
				{/* <Bacardi /> */}
			</div>
		);
	}
}

  
export default Inventory;