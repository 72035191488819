import React, { Component } from 'react';
import "./plants-depots-indents.less";
import Addindents from "./add-indents/add-indents";
import ShowConfigures from "./configure/plants-depots-indents-configure";
import FlowDetail from  "./flow-detail";
import backIcon from "../../../../assets/images/left-arrow.svg";
import Select from 'react-select';
import SearchText from "../../../common/components/search-text/search-text";
import Edit from "../../../../assets/images/edit.svg";
import Delete from "../../../../assets/images/delete.svg";
import View from "../../../../assets/images/view.svg";
import Copy from "../../../../assets/images/copy.svg";
import { toPascalCase } from "../../../../utils/validate";
import { CARGILL_COMPANY_IDS, PANTALOONS_COMPANY_IDS } from '../../../../utils/common';
import { withRouter } from 'react-router-dom';


const boundTypeOptions = [
    { value: 'inbound', label: 'Inbound', id : 1 },
    { value: 'outbound', label: 'Outbound', id : 2 }
];

class PlantsDepotsindents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indents: "",
            selectedindentsToEdit: {},
            showEditPopUp: false,
            offset: 0,
            selectedBoundType: "",
            selectedServiceType: "",
            searchText: "",
            serviceCategoryOptions : [
                { value: 'FTL', label: 'FTL', id : 1 },
                { value: 'LCL', label: 'LCL', id : 2 },
                { value: 'Courier', label: 'Courier', id : 3 },
                { value: 'Container', label: 'Container', id : 4 }
            ]
        }
    }
    handleBoundTypeChange = (selectedOption) => {
        this.setState({ selectedBoundType: selectedOption }, () => {
            this.getIndentingFlows();
        });
    }
    handleServiceTypeChange = (selectedOption) => {
        this.setState({ selectedServiceType: selectedOption }, () => {
            this.getIndentingFlows();
        });
    }
    componentDidMount() {
        this.getIndentingFlows();
    }
    componentWillReceiveProps (props) {
        const { serviceCategoryOptions } = this.state
        if(PANTALOONS_COMPANY_IDS.includes(props.company_id)){
            let temp = serviceCategoryOptions.filter((d) => d.id !== 4)

            if(!temp.find((d)=>d.id == 5)){
                temp.push({value: 'Milkrun', label: 'Milkrun', id: 5 })
            }
            if(!temp.find((d)=>d.id == 6)){
                temp.push({ value: 'Dedicated', label: 'Dedicated', id: 6 })
            }
            this.setState({serviceCategoryOptions : temp})
        }
    }
    getIndentingFlows = () => {
        const {company_id, branch_id} = this.props;
        const {selectedBoundType, selectedServiceType, searchText} = this.state;
        let data = {
            companyId: company_id,
            branchId: branch_id,
            boundType: selectedBoundType && selectedBoundType.id,
            serviceType: selectedServiceType && selectedServiceType.id,
            search: searchText
        }
        this.props.getIndentingFlows(data);
    };
    // submitSearch = () => {
    //     if (this.state.indents != null || this.state.indents.length > 0)
    //         this.getindents(this.state.indents);
    // };
    checkBoxChange = (e, id, type, indents) => {
        e.preventDefault();
        e.stopPropagation()
        const data = {
            company_type: indents.company_type,
            company_id: indents.company_id,
            "branch_id": indents.branch_id,
            "indents_id": indents.indents_id,
            is_active: e.target.checked
        }

    };
    // clearFilterOnCancel = () => {
    //     this.setState({ indents: "" }, () => {
    //         this.getindents(null);
    //     });
    // };
    previous = () => {
        if (this.state.offset > 0) {
            this.setState(
                {
                    offset: this.state.offset - 10
                },
                () => {
                    this.getindents();
                }
            );
        }
    };
    next = () => {
        this.setState(
            {
                offset: this.state.offset + 10
            },
            () => {
                this.getindents();
            }
        );
    };

    deleteindents = (indents) => {
        let paramData = {
            "company_id": indents.company_id,
            "company_type": indents.company_type,
            "branch_id": indents.branch_id,
            "indents_id": indents.indents_id
        }
        this.getindents();
    }
    // changeHandler = (val, id) => {
    //     this.setState({
    //         [id]: val
    //     })
    // }
    toggleAdd = () => {
        this.setState({
            showAddComponent: !this.state.showAddComponent,
            flowId: ''
        }, this.getIndentingFlows)
    }
    toggleConfigure = () => {
        this.setState({
            showConfigureComponent: !this.state.showConfigureComponent
        })
    }

    viewFlowDetail = (flow) => {
        this.setState({
            viewFlowDetail: !this.state.viewFlowDetail,
            flowId: flow.flow_id
        })
    }
    toggleEdit = (flow) => {
        this.setState({
            showAddComponent: !this.state.showAddComponent,
            viewFlowDetail: !this.state.viewFlowDetail,
            //showEditPopUp: !this.state.showEditPopUp,
        })
    }
    toggleDirectEdit = (flow) => {
        this.setState({
            showAddComponent: !this.state.showAddComponent,
            //viewFlowDetail: !this.state.viewFlowDetail,
            flowId: flow.flow_id
        })
    }
    toggleDirectDelete = (flow) => {
        this.props.deleteMove(flow);
    }

    changeHandler = (val,id) =>{
		this.setState({
			[id]: val
		});
    }
    
    submitSearch = () =>{
		this.getIndentingFlows()
	}

    clearFilterOnCancel = (e, type) => {
		this.setState({ [type]: '' }, () => {
			this.getIndentingFlows();
		});
	}
    // getRoles = (id) => {
    //     this.props.getRoles(id);
    // }

    copyFlowId = (flow) => {
        localStorage.setItem('flowId', flow.flow_id);
        this.setState({ content: flow.flow_id });
    }

    handleOnClickForAuditBtn = () => {
        const {history, company_id,branch_id} = this.props;
        const masterType = 7
        const companyId = company_id;
        console.log('companyId:', companyId)
        const navUrl = `/auditScreen/${companyId}/${masterType}/${branch_id}`;
        history.push(navUrl)
    }

    render() {
        const {indentingFlows} = this.props;
        const {selectedBoundType, selectedServiceType, flowId, content, serviceCategoryOptions} = this.state;
        return (
            <div className="plants-depots-main-wrap">
                {
                    this.state.viewFlowDetail ?
                    <div>
                        <div className = "mt-10 mb-10 view-flow-box">
                            <div className = "go-back-prev-screen float-left" onClick={this.viewFlowDetail} style={{backgroundImage: `url(${backIcon})`}}></div>
                            {/* <div><input className="button-classic" type="button" value="+ Edit Flow" onClick={this.toggleEdit} /></div> */}
                            <div className = "delete-icon float-right" style={{backgroundImage: `url(${Edit})`}} onClick = {this.toggleEdit}></div> 
                        </div>
                        <FlowDetail
                            // getRoles = {this.getRoles}
                            getIndentingFlowDetails={this.props.getIndentingFlowDetails}
                            indentingFlowDetails={this.props.indentingFlowDetails}
                            flowId = {flowId}
                        /> 
                    </div>
                    :
                    this.state.showAddComponent ?
                    <Addindents
                        // getRoles = {this.getRoles}
                        getItems={this.props.getItems}
                        indentingItems={this.props.indentingItems}
                        toggleAdd={this.toggleAdd}
                        roles={this.props.roles}
                        company_id={this.props.company_id}
                        company_type={this.props.company_type}
                        branch_id={this.props.branch_id}
                        verifyindents={this.props.verifyindents}
                        departments={this.props.departments}
                        actions={this.props.actions}
                        addindents={this.props.addindents}
                        is_verified={this.props.is_verified}
                        getUsers={this.props.getUsers}
                        indentingUsers={this.props.indentingUsers || []}
                        getChecklists={this.props.getChecklists}
                        indentingChecklists={this.props.indentingChecklists}
                        addAction={this.props.addAction}
                        getMoves={this.props.getMoves}
                        getSubMoves={this.props.getSubMoves}
                        indentingMoves={this.props.indentingMoves}
                        indentingSubMoves={this.props.indentingSubMoves}
                        getActions={this.props.getActions}
                        indentingActions={this.props.indentingActions}
                        getWarehouses={this.props.getWarehouses}
                        addWarehouse={this.props.addWarehouse}
                        indentingWarehouses={this.props.indentingWarehouses}
                        getSubPlants={this.props.getSubPlants}
                        subPlants={this.props.subPlants}
                        submitMoves={this.props.submitMoves}
                        deleteMove={this.props.deleteMove}
                        getIndentingFlowDetails={this.props.getIndentingFlowDetails}
                        indentingFlowDetails={this.props.indentingFlowDetails}
                        flowId = {flowId}
                        getFields={this.props.getFields}
                        indentingFields={this.props.indentingFields}
                        getDepartmentUserDetails={this.props.getDepartmentUserDetails}
                        depotAndUserDetails={this.props.depotAndUserDetails}
                        getBranches={this.props.getBranches}
                        branchList={this.props.branchList}
                        applyBranches={this.props.applyBranches}
                        getServiceModes={this.props.getServiceModes}
                        serviceModes={this.props.serviceModes}
                        indentingReasons={this.props.indentingReasons}
                        getIndentingReasons={this.props.getIndentingReasons}
                    /> :
                    this.state.showConfigureComponent ?
                    <ShowConfigures
                        toggleConfigure={this.toggleConfigure}
                        getMoves={this.props.getMoves}
                        getSubMoves={this.props.getSubMoves}
                        addMove={this.props.addMove}
                        indentingMoves={this.props.indentingMoves}
                        indentingSubMoves={this.props.indentingSubMoves}
                        getActions={this.props.getActions}
                        addAction={this.props.addAction}
                        indentingActions={this.props.indentingActions}
                        getChecklists={this.props.getChecklists}
                        addChecklist={this.props.addChecklist}
                        indentingChecklists={this.props.indentingChecklists}
                        getWarehouses={this.props.getWarehouses}
                        addWarehouse={this.props.addWarehouse}
                        indentingWarehouses={this.props.indentingWarehouses}
                        getVendors={this.props.getVendors}
                        addVendor={this.props.addVendor}
                        indentingVendors={this.props.indentingVendors}
                        company_id={this.props.company_id}
                        branch_id={this.props.branch_id}
                    /> : 
                    <div>
                        <div className="search-holder">
                            <input className="button-classic" type="button" value="+ Add Flow" onClick={this.toggleAdd} />
                            <button
                                className="button-classic"
                                onClick={this.handleOnClickForAuditBtn}
                            >
                                Audit
                            </button>
                            <input className="button-classic" type="button" value="Configure" onClick={this.toggleConfigure} />
                        </div>
                        <div className="search-holder">
                            <div className = "search-filter-section">
                                <SearchText id="searchText" value={this.state.searchText} placeholder = "Search" changeHandler={this.changeHandler} click={this.submitSearch} canClear={this.state.searchText !== ''} handleClearFilter={(e) => this.clearFilterOnCancel(e, "searchText")} />
                            </div>
                            <Select
                                className="select"
                                value={selectedBoundType}
                                onChange={this.handleBoundTypeChange}
                                options={CARGILL_COMPANY_IDS.includes(this.props.company_id) ? [...boundTypeOptions, { value: 'external', label: 'External', id: 3 }] : boundTypeOptions}
                                placeholder="Select Bound Type"
                            />
                            <Select
                                className="select"
                                value={selectedServiceType}
                                onChange={this.handleServiceTypeChange}
                                options={serviceCategoryOptions}
                                placeholder="Select Service Category"
                            />
                        </div>
                        <div className="heading">
                            <div className="flow-head email">Name</div>
                            <div className="flow-head email">Bound Type</div>
                            <div className="flow-head email">Service Category</div>
                            <div className="flow-head email">Action</div>

                        </div>
                        {indentingFlows && indentingFlows.length == 0 &&
                            <div className="depot-users-list no-records"> No Flows Found. Please Click On Add Flow Button To Create Flow.
                        </div>
                        }
                        {
                            indentingFlows && indentingFlows.map((flow) => {
                                return (
                                    <div className="depot-users-list">
												  <div className="flow-head email">{toPascalCase(flow.name)}</div>
                                        <div className="flow-head email">{(boundTypeOptions.find(o => o.id === flow.bound_type) || {}).label}</div>
                                        <div className="flow-head email">{serviceCategoryOptions.find(o => o.id === flow.service_type).label}</div>
                                        <div className="flow-head email">
                                            {/* <input className="button-classic" type="button" value="View Detail" onClick={this.viewFlowDetail.bind(this, flow)} /> */}
                                            <div className = "delete-icon" style={{backgroundImage: `url(${View})`}} onClick = {this.viewFlowDetail.bind(this, flow)}></div>
                                            <div className = "delete-icon" style={{backgroundImage: `url(${Edit})`}} onClick = {this.toggleDirectEdit.bind(this, flow)}></div>
                                            <div className = "delete-icon" style={{backgroundImage: `url(${Delete})`}} onClick = {this.toggleDirectDelete.bind(this, flow)}></div>
                                            <div className = "delete-icon tooltip" style={{backgroundImage: `url(${Copy})`}} onClick = {() => this.copyFlowId(flow)}>
                                                {content && content === flow.flow_id && <span className="tooltiptext">{"copied"}</span>}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        );
    }
}

export default withRouter(PlantsDepotsindents);