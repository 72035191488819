import React, { Component, } from 'react';
import InputText from "../../../../common/components/input-text/input-text";
import Cx from "classnames";
import Select from 'react-select';
import Button from '../../../button-classic/button-classic';
import Delete from "../../../../../assets/images/delete.svg";

class AddAutomove extends Component {
    constructor(props){
        super(props);
        this.state = {
            moves: this.props.moves || [],
            selectedMoves: this.props.data ? ((this.props.data[0] || []).auto_moves_id || []).map(obj => {return {label: obj.name, value: obj.move_id}}) : [{label: '', value: ''}],
            autoMoves: [],
            is_auto_moves: this.props.data ? (this.props.data[0] || []).is_auto_moves : true,
            isDisabled: false
        }
    }

    onChangeHandler = (value, id) =>{
        this.setState({
            [id] : value.toUpperCase()
        })
    }

    onSave = () => {
        this.props.addAutomoveDetails(this.state);
        this.props.toggleAdd();
    }

    filterMoves = () => {
        let {moves, autoMoves} = this.state;
        (moves || []).forEach((auto) => {
            autoMoves.push({label: auto.name, value: auto.move_id});
        })
        this.setState({autoMoves});
    }

    addAutoMove = () => {
        let { selectedMoves } = this.state;
            selectedMoves.push({label: '',value: ''});
        this.setState({selectedMoves});
    }

    deleteAutoMove = (index) => {
        let { selectedMoves } = this.state;
            selectedMoves.splice(index,1);
        this.setState({selectedMoves});
    }

    checkValidation = (currentMove, index) => {
        let { selectedMoves } = this.state;
        if((selectedMoves || []).filter((obj) => obj.label === currentMove.label).length > 1){
            this.setState({isDisabled: true, errorIndex: index})
        } else {
            this.setState({isDisabled: false, errorIndex: ''})
        }
    }

    render() {
        const { autoMoves, moves, selectedMoves, is_auto_moves, isDisabled, errorIndex } = this.state;
        const { data } = this.props;
        if(autoMoves.length !== moves.length){
            this.filterMoves();
        }
        return (
            <div className = "add-user-wrap add-bay">
                <div onClick = {this.props.toggleAdd} className = "overlay"></div>
                <div id = "modal" className = "modal-popup" style={{width: '400px'}}>
                    <div className = "pop-heading">
                        {`${data ? 'Edit' : 'Add'} Automoves`}
                    </div>
                    <div className = "content add-edit-user">
                    <div className="select-wrap-depot auto_move">
						<CustomCheckBox 
							id="is_auto_move_enabled" 
							selected={is_auto_moves} 
							toggle={() => {
							let { is_auto_moves } = this.state;
							is_auto_moves = !is_auto_moves;
							this.setState({ is_auto_moves })
						}}/>
						<div className="title mb-10 ml-10">Auto Move</div>
					</div>
                        {(selectedMoves || []).map((auto, index) => {
                            return (
                                <>
                                <div className="flex mt-20 mb-5">
                                    <form className = "form-section" autoComplete = "nope">
                                    <Select 
                                        className={'wt-250 mr-20'} 
                                        options={autoMoves} 
                                        placeholder={'Select Move'}
                                        value={auto}
                                        onChange={(options) => {
                                            const { selectedMoves } = this.state;
                                            selectedMoves[index] = options;
                                            if(this.checkValidation(options, index)){
                                                this.setState({ selectedMoves });
                                            }
                                            
                                        }
                                    }
                                    />
                                    </form>
                                    
                                    <div className = "delete-icon" style={{backgroundImage: `url(${Delete})`}} onClick = {() => this.deleteAutoMove(index)}></div>
                                    {(index === selectedMoves.length - 1) && <Button value={'+'} click={this.addAutoMove} />}
                                </div>
                                {isDisabled && errorIndex === index && <div className='error fs-13'>{ 'Automove already exists'}</div>}
                                    
                            </>
                            )
                        })}
                        
                        <div className = "submit-section">
                            <button className = "add-button discard" onClick={this.props.toggleAdd} >Cancel</button>
                            <button className ={Cx("add-button", {
                                'disable-btn' : (selectedMoves.find(obj => obj.value === '') || this.state.isDisabled)
                            })} onClick={this.onSave}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const CustomCheckBox = (props) => {
	return (
		<div className={props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick={() => props.toggle(props.id)}>
			{props.selected && <div className="inner-circle"></div>}
		</div>
	)
}

export default AddAutomove;