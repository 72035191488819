import { put, call, select } from "redux-saga/effects";
import ACTIONS from "../common/action_constant";
import API from "../common/api_config";
import {
  getData,
  postData,
  postDataForGetDetails,
  putData,
  fileUpload,
  deleteData,
} from "../../utils/ajax";
import axiosT1 from "../../utils/axios";




  export function* getDriverInfo(action,companyId) {
	 
	try{
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER
	  });
	
	  const data = yield call(getData, API.getDriverInfo + action.param);
	
	  yield put({ 
		type : ACTIONS.DATA_GOVERNANCE.STORE_DRIVER_INFO, 
		data: data.data
	  });
	  
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER
	  });
	} catch(error) {
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER
	  });
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: { type: 2, msg: error.responseJSON.message }
	  });
	}
  }


export function* addDriverInfo(action) {

	try{
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER
	  });
	  const data = yield call(postData, API.addDriverInfo, action.data );
	  yield put({ 
		type : ACTIONS.DATA_GOVERNANCE.GET_DRIVER_INFO, 
		param : `${action.data.company_id}` 
	  });
	  yield put({ type : ACTIONS.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : "Added Successfully"}});
	} catch(error) {
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER
	  });
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: { type: 2, msg: error.responseJSON.message }
	  });
	}
  }
export function* updateDriverInfo(action) {

	try{
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER
	  });
	  const data = yield call(putData, API.updateDriverInfo, JSON.stringify(action.data));
	  yield put({ 
		type : ACTIONS.DATA_GOVERNANCE.GET_DRIVER_INFO, 
		param : `${action.data.company_id}` 
	  });
	 
	  yield put({ type : ACTIONS.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : "Updated Successfully"}});
	} catch(error) {
		yield put({
		  type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
		  type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		  data: { type: 2, msg: error.responseJSON.message }
		});
	}
  }
  
export function* deleteDriverInfo(action) {
		try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		})
		yield call(deleteData, API.deleteDriverInfo, JSON.stringify({"driver_id":action.data.driver_id,"company_id" :action.data.company_id }));
		yield put({
			type: ACTIONS.DATA_GOVERNANCE.GET_DRIVER_INFO,
             param : `${action.companyId}&count=20&offset=0`
		})
	
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Driver Info deleted Successfully" }
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong" }
		})
	}
}

export function* getTruckInfo(action,companyId) {
	 
	try{
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER
	  });
	  const data = yield call(getData, API.getTruckInfo + action.param);
	
	  yield put({ 
		type : ACTIONS.DATA_GOVERNANCE.STORE_TRUCK_INFO, 
		data: data.data
	  });
	  
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER
	  });
	} catch(error) {
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER
	  });
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: { type: 2, msg: error.responseJSON.message }
	  });
	}
  }


export function* getVehicleTypes(action) {
	try{
	  const data = yield call(getData, API.getVehicleTypes);
	  yield put({ type: ACTIONS.DATA_GOVERNANCE.STORE_VEHICLE_TYPE , data : data.data ? data.data.list : [] });
	}
	catch(error){
	  console.log(error);
	}
  }
export function* getFuleTypes(action) {
	try{
	  const data = yield call(getData, API.getFuleTypes);
	  yield put({ type: ACTIONS.DATA_GOVERNANCE.STORE_FULE_TYPE , data : data.data ? data.data : [] });
	}
	
	catch(error){
	  console.log(error);
	}
  }

export function* deleteTruckInfo(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		})
		yield call(deleteData, API.deleteTruckInfo, JSON.stringify({vehicles_id:action.data.vehicles_id,company_id :action.companyId }));
		yield put({
			type: ACTIONS.DATA_GOVERNANCE.GET_TRUCK_INFO,
      param : `${action.data.company_id}&count=20&offset=0`
		})
		
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Truck deleted Successfully" }
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
	} catch (e) {
		
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong" }
		})
	}
}

export function* addTruckInfo(action) {

	try{
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER
	  });
	  const data = yield call(postData, API.addTruckInfo, action.data );
	  yield put({ 
		type : ACTIONS.DATA_GOVERNANCE.GET_TRUCK_INFO, 
		param : `${action.data.company_id}` 
	  });
	  yield put({ type : ACTIONS.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : "Added Successfully"}});
	} catch(error) {
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER
	  });
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: { type: 2, msg: error.responseJSON.message }
	  });
	}
  }


 export function* updateTruckInfo(action) {
	
	try{
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER
	  });
	   const data = yield call(putData, API.updateTruckInfo, JSON.stringify(action.data));
	  yield put({ 
		type : ACTIONS.DATA_GOVERNANCE.GET_TRUCK_INFO, 
		param : `${action.data.company_id}&count=50&offset=0` 
	  });
	  
	  yield put({ type : ACTIONS.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : "Updated Successfully"}});
	} catch(error) {
		yield put({
		  type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
		  type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		  data: { type: 2, msg: error.responseJSON.message }
		});
	}
  }


  export function* downloadDriverTemplate(action,companyId) { 
	try{
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER
	  });
	
	  const data = yield call(getData, API.downloadDriverTemplateURL);
	  console.log(data)
	  if(action.onSuccess) {
		action.onSuccess(data)
	  }
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER
	  });
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: { type: 1, msg: data.message }
	  });
	} catch(error) {
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER
	  });
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: { type: 2, msg: error.responseJSON && error.responseJSON.message }
	  });
	}
  }

  export function* driverUpload(action) {
	
	try{
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER
	  });
	  const data = yield call(fileUpload, API.driverUploadURL + action.queryString, action.formData);
	
	  if(action.onSuccess){
		action.onSuccess(data);
	  }

	yield put({ type : ACTIONS.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : data.message}});
  
	} catch(error) {
		console.log(error)
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER
	  });
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: { type: 2, msg: error && error.responseJSON &&  error.responseJSON.result ? error.responseJSON.result.message : "Unable to upload the file" }
	  });
	} yield put({
		type: ACTIONS.LOADER.HIDE_LOADER
	  });
  }

  export function* downloadVehicleTemplate(action,companyId) { 
	try{
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER
	  });
	
	  const data = yield call(getData, API.downloadVehicleTemplateURL);
	  if(action.onSuccess) {
		action.onSuccess(data)
	  }
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER
	  });
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: { type: 1, msg: data.message }
	  });
	} catch(error) {
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER
	  });
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: { type: 2, msg: error.responseJSON && error.responseJSON.message }
	  });
	}
  }

  export function* vehicleUploadTemplate(action) {
	
	try{
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER
	  });
	  const data = yield call(fileUpload, API.uploadVehicleTemplateURL + action.queryString, action.formData);
	
	  if(action.onSuccess){
		action.onSuccess(data);
	  }

	yield put({ type : ACTIONS.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : data.message}});
  
	} catch(error) {
		console.log(error)
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER
	  });
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: { type: 2, msg: error && error.responseJSON &&  error.responseJSON.result ? error.responseJSON.result.message : "Unable to upload the file" }
	  });
	} yield put({
		type: ACTIONS.LOADER.HIDE_LOADER
	  });
  }

export function* uploadfiels(action) {
	
	try{
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER
	  });
	  const data = yield call(fileUpload, API.uploadfiels , action.formData);
	
	  if(action.onSuccess){
		action.onSuccess(data);
	  }

	yield put({ type : ACTIONS.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : "File Uploaded Successfully"}});
  
	} catch(error) {
		console.log(error)
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER
	  });
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: { type: 2, msg: error && error.responseJSON &&  error.responseJSON.result ? error.responseJSON.result.message : "Unable to upload the file" }
	  });
	} yield put({
		type: ACTIONS.LOADER.HIDE_LOADER
	  });
	
  }

export function* getDelayPenaltyData(action) {
	let searchString = '';
   if(action.params.category) {
	searchString+='&companyCategoryId='+action.params.category
   }
   if(action.params.mode_id) {
	searchString+='&modeId='+action.params.mode_id
   }
   if(action.params.company_id) {
	searchString+='&company_id='+action.params.company_id
   }
	 if(action.params.limit) {
		searchString+='&limit='+action.params.limit+'&offset='+action.params.offset
	}
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER
    });
    const data = yield call(getData, API.getDelayPenalty + action.params.type + searchString);
    yield put({
      type: ACTIONS.DATA_GOVERNANCE.GET_DELAY_PENALTY_DATA,
      data: data.data
    });
    if (action.onSuccess){
    	action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER
    });
  } catch (e) {
  	yield put({
      type: ACTIONS.LOADER.HIDE_LOADER
    });
  	yield put({
        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
    })
  }
}

export function* postDelayPenaltyData(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER
    });
    const data = yield call(postData, API.postDelayPenalty, action.params);
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER
    });
    if (action.onSuccess) {
    	action.onSuccess(data.data);
    }
    yield put({
        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
        data : {type : 1 , msg : "Penalty Added Successfully!"}
    })
  } catch (e) {
  	yield put({
      type: ACTIONS.LOADER.HIDE_LOADER
    });
  	yield put({
        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
    })
  }
}

export function* updateDelayPenaltyData(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER
    });
    const data = yield call(putData, API.updateDelayPenalty, JSON.stringify(action.params));
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER
    });
    if (action.onSuccess) {
    	action.onSuccess(data.data);
    }
    yield put({
        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
        data : {type : 1 , msg : "Data Updated Successfully!"}
    })
  } catch (e) {
  	yield put({
      type: ACTIONS.LOADER.HIDE_LOADER
    });
  	yield put({
        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
    })
  }
}

export function* deleteDelayPenaltyData(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER
    });
    const data = yield call(deleteData, API.deleteDelayPenalty, JSON.stringify(action.params));
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER
    });
    if (action.onSuccess) {
    	action.onSuccess(data.data);
    }
    yield put({
        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
        data : {type : 1 , msg : "Data Deleted Successfully!"}
    })
  } catch (e) {
  	yield put({
      type: ACTIONS.LOADER.HIDE_LOADER
    });
  	yield put({
        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
    })
  }
}

export function* downloadDelayPenaltyData(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER
    });
    const data = yield call(getData, API.downloadDelayPenalty + action.params.type);
    yield put({
    	type: ACTIONS.DATA_GOVERNANCE.DOWNLOAD_DELAY_DATA,
    	data: data.data,
    })
    if (action.onSuccess) {
    	action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER
    });
  } catch (e) {
  	yield put({
      type: ACTIONS.LOADER.HIDE_LOADER
    });
  	yield put({
        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	})
  }
}

export function* uploadDelayPenaltyData(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER
    });
    const data = yield call(fileUpload, API.uploadDelayPenalty+action.params, action.data);

    if (action.onSuccess) {
    	action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER
    });
    // if (action.onSuccess) {
    // 	action.onSuccess(data.data);
    // }
    yield put({
        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
        data : {type : 1 , msg : "Data Updated Successfully!"}
    });
  } catch (e) {
  	yield put({
      type: ACTIONS.LOADER.HIDE_LOADER
    });
  	yield put({
        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
    })
  }
}

export function* getModeTypes(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		})
		const url = action.params ? API.getModeTypes+action.params : API.getModeTypes
		const data = yield call(getData, url);
		yield put({
			type: ACTIONS.DATA_GOVERNANCE.GOVERNANCE_MODE_TYPES_DATA,
			data: data.data
		})
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
	} catch(e) {
		yield put({
	      type: ACTIONS.LOADER.HIDE_LOADER
	    });
	  	yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	    })
	}
}

export function* getDistributionTypes(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		})
		const url = action.params ? API.getDistributionTypes+action.params : API.getDistributionTypes
		const data = yield call(getData, url);
		yield put({
			type: ACTIONS.DATA_GOVERNANCE.GOVERNANCE_DISTRIBUTION_TYPES_DATA,
			data: data.data
		})
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
	} catch(e) {
		yield put({
	      type: ACTIONS.LOADER.HIDE_LOADER
	    });
	  	yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	    })
	}
}
export function* getChannelTypes(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		})
		const url = action.params ? API.getChannelTypes+action.params : API.getChannelTypes
		const data = yield call(getData, url);
		yield put({
			type: ACTIONS.DATA_GOVERNANCE.GOVERNANCE_CHANNEL_TYPES_DATA,
			data: data.data
		})
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
	} catch(e) {
		yield put({
	      type: ACTIONS.LOADER.HIDE_LOADER
	    });
	  	yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	    })
	}
}

export function* getVendorsTypes(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		})
		const data = yield call(getData, API.getVendorDetails);
		yield put({
			type: ACTIONS.DATA_GOVERNANCE.GET_GOVERNANCE_VENDORS_DATA,
			data: data.data
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
	} catch(e) {
		yield put({
	     	type: ACTIONS.LOADER.HIDE_LOADER
	    });
	  	yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	    })
	}
}

export function* getLiabilityData(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		let searchString = '';
		if(action.params.search) {
			searchString+='&search='+action.params.search
		}
		const data = yield call(getData, API.getLiabilityData+action.params.companyId + searchString);
		yield put({
			type: ACTIONS.DATA_GOVERNANCE.LIABILITY_DETAILS,
			data: data.data
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	    })
	}
}

export function* downloadLiabilityData(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const url = action.params ? API.downloadLiabilities+action.params : API.downloadLiabilities;
		const data = yield call(getData, url);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		if (action.onSuccess) {
	    	action.onSuccess(data.data);
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	    })
	}
}

export function* getClaimTypes(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getClaimTypes);
		yield put({
			type: ACTIONS.DATA_GOVERNANCE.CLAIM_TYPES_DATA,
			data: data.data
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	    })
	}
}

export function* getShortageTypes(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getShortageTypes);
		yield put({
			type: ACTIONS.DATA_GOVERNANCE.SHORTAGES_TYPES_DATA,
			data: data.data
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	    })
	}
}

export function* postLiabilityData(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.postLiabilityData, action.params);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 1, msg : data.data && data.data.message ?  data.data.message : "Data saved Successfully!"}
	    })
	    if (action.onSuccess) {
			action.onSuccess(data.data);
		}
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 2 , msg : e && e.message ?  e.message : "Oops! Something went wrong."}
	    })
	}
}

export function* updateLiabilityData(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(putData, API.updateLiabilityData, JSON.stringify(action.params));
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 1, msg : data.data && data.data.message ?  data.data.message : "Data updated successfully!"}
	    })
	    if (action.onSuccess) {
			action.onSuccess(data.data);
		}
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 2 , msg : e && e.message ?  e.message : "Oops! Something went wrong."}
	    })
	}
}

export function* deleteLiabilityData(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(deleteData, API.deleteLiabilityData, JSON.stringify(action.params));
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 1, msg : data.data && data.data.message ?  data.data.message : "Liability deleted Successfully!"}
	    })
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 2 , msg : e && e.message ?  e.message : "Oops! Something went wrong."}
	    })
	}
}

export function* getLaneMasterData(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getLaneRoutes + action.params);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	        type: ACTIONS.DATA_GOVERNANCE.GET_LANE_MASTER_DATA,
	        data: data.data
	    })
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	    })
	}
}

export function* postVolumetricWeight(action){
  try{
  yield put({
    type :ACTIONS.LOADER.SHOW_LOADER
  });

  const data = yield call(postData, API.postVolumetricWeight, action.params);
  yield put({
      type: ACTIONS.LOADER.HIDE_LOADER
  });
  yield put({
    type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
    data : {type : 1 , msg : "Volumetric Details Added Successfully!"}
  })

  if(action.onSuccess){
    action.onSuccess(data.status);
  }
  }catch(e){
     yield put({
      type: ACTIONS.LOADER.HIDE_LOADER
     })

     yield put({
      type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
     })
  }
}


export function* getVolumetricWeightData(action){
try{
  yield put({
  type: ACTIONS.LOADER.SHOW_LOADER
   });
   let searchString = '';
   if(action.params.search) {
	searchString+='&search='+action.params.search
   }
   if(action.params.company_id) {
	searchString+='&company_id='+action.params.company_id
   }
   const data = yield call(getData, API.getVolumetricWeightData + action.params.type + searchString)

   yield put({
    type : ACTIONS.DATA_GOVERNANCE.GET_VOLUMETRIC_WEIGHT_DATA,
    data :data.data
  })
   yield put({
     type : ACTIONS.LOADER.HIDE_LOADER
   })

  }catch(e){
    yield put({
      type :ACTIONS.LOADER.HIDE_LOADER
    });
    yield put({
      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data :{type : 2, msg : e && e.responseJSON ? e.responseJSON.message : "Oops! Something went wrong."}
    })
  }
}

export function* deleteVolumetricWeightData(action){

  try{

    yield put({ 
      type :ACTIONS.LOADER.SHOW_LOADER
    })

    const data = yield call(deleteData, API.deleteVolumetricWeightData, JSON.stringify(action.params));

    yield put({
      type :ACTIONS.LOADER.HIDE_LOADER
    })
    if(action.onSuccess){
      action.onSuccess(data.status)
    }
    yield put({
      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data :{type : 1, msg :"Data Deleted Successfully!"}
    })
  }catch(e){
    yield put({
      type : ACTIONS.LOADER.HIDE_LOADER
    })
    yield put({
      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data :{type :2 , msg :e && e.responseJSON ?e.responseJSON :"Oops! Something went wrong."}
    })
  }

}

export function* getCityList(action) {
	try {
		yield put({ 
	      type :ACTIONS.DATA_GOVERNANCE.SHOW_DATA_LOADER
	    })
	    const data = yield call(getData, API.getCityList + action.params);
	    yield put({
	    	type: ACTIONS.DATA_GOVERNANCE.GET_CITY_LIST_DATA,
	    	data: data.data,
	    })
	    yield put({
	      type :ACTIONS.DATA_GOVERNANCE.HIDE_DATA_LOADER
	    })
	  }catch(e){
	    yield put({
	      type : ACTIONS.DATA_GOVERNANCE.HIDE_DATA_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	  }
}

export function* getHubList(action) {
	try {
		yield put({ 
	      type :ACTIONS.DATA_GOVERNANCE.SHOW_DATA_LOADER
	    })
	    const data = yield call(getData, API.getHubList + action.params);
	    yield put({
	    	type: ACTIONS.DATA_GOVERNANCE.GET_HUB_LIST_DATA,
	    	data: data.data,
	    })
	    yield put({
	      type :ACTIONS.DATA_GOVERNANCE.HIDE_DATA_LOADER
	    })
	  }catch(e){
	    yield put({
	      type : ACTIONS.DATA_GOVERNANCE.HIDE_DATA_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	  }
}

export function* getCityPincodes(action) {
	try {
		yield put({ 
	      type :ACTIONS.DATA_GOVERNANCE.SHOW_DATA_LOADER
	    })
	    const data = yield call(getData, API.getCityPincodes + action.params);
	    yield put({
	    	type: ACTIONS.DATA_GOVERNANCE.GET_CITY_PINCODES_DATA,
	    	data: data.data,
	    })
	    yield put({
	      type :ACTIONS.DATA_GOVERNANCE.HIDE_DATA_LOADER
	    })
	} catch(e) {
	    yield put({
	      type : ACTIONS.DATA_GOVERNANCE.HIDE_DATA_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

export function* postLane(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(postData, API.postLanes, action.params);
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data added Successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

export function* updateLane(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(putData, API.updateLanes, JSON.stringify(action.params));
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data updated Successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

export function* deleteLane(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(deleteData, API.deleteLanes, JSON.stringify(action.params));
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data Deleted Successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

  export function * updateVolumetricWeightData(action){
	  try{
        yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		})
		const data = yield call(putData ,API.updateVolumetricWeightData, JSON.stringify(action.params));
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		if(action.onSuccess){
			action.onSuccess(data.status)
		  }

		// yield put({
		// 	type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
		// 	data :{type :1, msg :"Data updated Sucessfully!"}
		// })
	  }catch(e){
        yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		// yield put({
		// 	type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
		// 	data :{type :2, msg :e && e.responseJSON ? e.responseJSON :"Oops! Something went wrong."}
		// })
	  }
  }

  export function * downLoadVolumetricLink(action){
	try{
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const data = yield call(getData, API.downLoadVolumetricLink + action.params)
         yield put({
			 type :ACTIONS.DATA_GOVERNANCE.DOWNLOAD_VOLUMETRIC_DATA,
			 data : data
		 });
		if(action.onSuccess){
			action.onSuccess(data);
		}
		yield put({
			type : ACTIONS.LOADER.HIDE_LOADER
		})
	}catch(e){
       yield put({
		   type : ACTIONS.LOADER.HIDE_LOADER
	   })
	}
  }

 export function* uploadVolumetricFile(action) {
	try{
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		
		const data =  yield call (fileUpload, API.uploadVolumetricFile + action.params, action.data);
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
	    if(action.onSuccess){
			action.onSuccess(data);
		}
		yield put({
			type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
		 	data :{type :1, msg :"Excel uploaded Sucessfully!"}
		})
	}catch (e){
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
		 	data :{type :2, msg :e && e.responseJSON ? e.responseJSON :"Oops! Something went wrong."}
		})
	}
}

export function* getOdaMasters(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(getData, API.getOdaMasterData+action.params);
	    const modifiedData = (data && Object.keys(data.data).length > 0 && data.data.oda_details) ? data.data.oda_details : [];
	    
	    yield put({
	    	type: ACTIONS.DATA_GOVERNANCE.GET_ODA_MASTER_DATA,
	    	data: modifiedData,
	    	count: data.data && data.data.oda_count ? data.data.oda_count : 0,
	    });
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

export function* postOdaMasters(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(postData, API.postOdaMasterData, action.params);
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data added successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

export function* deleteOdaMasters(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(deleteData, API.deleteOdaMaster, JSON.stringify(action.params));
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data deleted successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

export function* putOdaMasters(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(putData, API.putOdaMasterData, JSON.stringify(action.params));
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data updated successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

export function* getTypesOfOda(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER,
		})
		const data = yield call(getData, API.getAllTypesOfOda);
		yield put({
	      type: ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type: ACTIONS.DATA_GOVERNANCE.ALL_TYPES_OF_ODA_DATA,
	      data: data.data,
	    })
	} catch(e) {
		yield put({
	     	type: ACTIONS.LOADER.HIDE_LOADER
	    });
	  	yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 2 , msg : e && e.message ?  e.message : "Oops! Something went wrong."}
	    })
	}
}
// GL CODE MAPPING
export function* getGLCodeMasters(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(getData, API.getGLCodeMasterData+`${action.params}`);
	    // const modifiedData = (data && Object.keys(data.data).length > 0 && data.data.oda_details) ? data.data.oda_details : [];
	    
	    yield put({
	    	type: ACTIONS.DATA_GOVERNANCE.GET_GLCODE_MASTER_DATA,
	    	// data: modifiedData,
	    	data: data.data,
	    	// count: data.data && data.data.oda_count ? data.data.oda_count : 0,
	    });
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

export function* postGLCodeMasters(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(postData, API.postGLCodeMasterData, action.params);
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data added successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

export function* deleteGLCodeMasters(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		console.log(action.params)
	    const data = yield call(deleteData, API.deleteGLCodeMaster, JSON.stringify(action.params));
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data deleted successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

export function* putGLCodeMasters(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(putData, API.putGLCodeMasterData, JSON.stringify(action.params));
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data updated successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}





export function* getPodVendorsTypes(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		})
		const data = yield call(getData, API.getPodVendorDetails);
		yield put({
			type: ACTIONS.DATA_GOVERNANCE.GET_GOVERNANCE_VENDORS_DATA,
			data: data.data
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
	} catch(e) {
		yield put({
	     	type: ACTIONS.LOADER.HIDE_LOADER
	    });
	  	yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	    })
	}
}

export function* deletePodData(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(deleteData, API.deletePodClause, JSON.stringify(action.params));
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data Deleted Successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

export function* uploadSlabFile(action) {
	try{
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const data =  yield call (fileUpload, API.uploadSlabData, action.data);
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
	    if(action.onSuccess){
			action.onSuccess(data);
		}
		yield put({
			type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
		 	data :{type :1, msg :"Excel uploaded Sucessfully!"}
		})
	}catch (e){
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
		 	data :{type :2, msg :e && e.responseJSON && e.responseJSON.message ? e.responseJSON.message :"Oops! Something went wrong."}
		})
	}
}

export function* uploadOdaFile(action) {
	try{
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const data =  yield call (fileUpload, API.uploadOdaData, action.data);
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
	    if(action.onSuccess){
			action.onSuccess(data);
		}
		yield put({
			type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
		 	data :{type :1, msg :"Excel uploaded Sucessfully!"}
		})
	}catch (e){
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
		 	data :{type :2, msg :e && e.responseJSON && e.responseJSON.message ? e.responseJSON.message :"Oops! Something went wrong."}
		})
	}
}

export function * downloadSlabTemplate(action) {
	try{
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const data = yield call(getData, API.downloadSlabData + action.params)
         yield put({
			 type :ACTIONS.DATA_GOVERNANCE.DOWNLOAD_ODA_DATA,
			 data : data
		 });
		if(action.onSuccess){
			action.onSuccess(data.data);
		}
		yield put({
			type : ACTIONS.LOADER.HIDE_LOADER
		})
	}catch(e){
       yield put({
		   type : ACTIONS.LOADER.HIDE_LOADER
	   })
	   yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
		 	data :{type :2, msg :e && e.responseJSON && e.responseJSON.message ? e.responseJSON.message :"Oops! Something went wrong."}
		})
	}
}

export function * downloadOdaTemplate(action) {
	try{
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const data = yield call(getData, API.downloadOdaData + action.params)
         yield put({
			 type :ACTIONS.DATA_GOVERNANCE.DOWNLOAD_ODA_DATA,
			 data : data
		 });
		if(action.onSuccess){
			action.onSuccess(data.data);
		}
		yield put({
			type : ACTIONS.LOADER.HIDE_LOADER
		})
	}catch(e){
       yield put({
		   type : ACTIONS.LOADER.HIDE_LOADER
	   })
	   yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
		 	data :{type :2, msg :e && e.responseJSON && e.responseJSON.message ? e.responseJSON.message :"Oops! Something went wrong."}
		})
	}
}

export function* uploadLaneMaster(action) {
	try{
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const data =  yield call (fileUpload, API.uploadLane, action.data);
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
	    if(action.onSuccess){
			action.onSuccess(data);
		}
		yield put({
			type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
		 	data :{type :1, msg :"Excel uploaded Sucessfully!"}
		})
	}catch (e){
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
		 	data :{type :2, msg :e && e.message ? e.message :"Oops! Something went wrong."}
		})
	}
}

export function * downloadLaneMaster(action) {
	try{
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const data = yield call(getData, API.downloadLane+action.params)
         yield put({
			 type :ACTIONS.DATA_GOVERNANCE.DOWNLOAD_ODA_DATA,
			 data : data
		 });
		if(action.onSuccess){
			action.onSuccess(data.data);
		}
		yield put({
			type : ACTIONS.LOADER.HIDE_LOADER
		})
	}catch(e){
       yield put({
		   type : ACTIONS.LOADER.HIDE_LOADER
	   })
	   yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
		 	data :{type :2, msg :e && e.message ? e.message :"Oops! Something went wrong."}
		})
	}
}

export function* updatePodData(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(putData, API.updatePodClause, JSON.stringify(action.params));
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data updated Successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

export function* postPodData(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(postData, API.postPodClause, action.params);
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data added Successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.responseJSON && e.responseJSON.message ?e.responseJSON.message :"Oops! Something went wrong."}
	    })
	}
}

export function* getPodClauseData(action) {
 try {
   yield put({
	 type: ACTIONS.LOADER.SHOW_LOADER
   });
   const data = yield call(getData, API.getPodClauseData);
   yield put({
	 type: ACTIONS.DATA_GOVERNANCE.GET_POD_CLAUSE_MASTER_DATA,
	 data: data.data
   });
   if (action.onSuccess){
	   action.onSuccess(data.data);
   }
   yield put({
	 type: ACTIONS.LOADER.HIDE_LOADER
   });
 } catch (e) {
	 yield put({
	 type: ACTIONS.LOADER.HIDE_LOADER
   });
	 yield put({
	   type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	   data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
   })
 }
}

export function* getPodStatus(action) {
 try {
	 yield put({
		 type: ACTIONS.LOADER.SHOW_LOADER
	 })
	 const data = yield call(getData, API.getPodStatusUrl);
	 yield put({
		 type: ACTIONS.DATA_GOVERNANCE.GET_POD_STATUS_DATA,
		 data: data.data
	 })
	 yield put({
		 type: ACTIONS.LOADER.HIDE_LOADER
	 })
 } catch(e) {
	 yield put({
		  type: ACTIONS.LOADER.HIDE_LOADER
	 });
	   yield put({
		 type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
		 data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	 })
 }
}

export function* getPodCycles(action) {
 try {
	 yield put({
		 type: ACTIONS.LOADER.SHOW_LOADER
	 })
	 const data = yield call(getData, API.getPodCyclesUrl);
	 yield put({
		 type: ACTIONS.DATA_GOVERNANCE.GET_POD_CYCLES_DATA,
		 data: data.data
	 })
	 yield put({
		 type: ACTIONS.LOADER.HIDE_LOADER
	 })
 } catch(e) {
	 yield put({
		  type: ACTIONS.LOADER.HIDE_LOADER
	 });
	   yield put({
		 type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
		 data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	 })
 }
}


export function* postCityMaster(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(postData, API.postCityMaster, action.params);
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data added Successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

export function* updateCityMaster(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(putData, API.updateCityMaster, JSON.stringify(action.params));
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data updated Successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

export function* deleteCityMaster(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(deleteData, API.deleteCityMaster, JSON.stringify(action.params));
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data Deleted Successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

export function* getCityMaster(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getCityMaster + action.params);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	        type: ACTIONS.DATA_GOVERNANCE.GET_CITY_MASTER_DATA,
	        data: data.data
	    })
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	    })
	}
}

export function* uploadCityMaster(action) {
	try{
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const data =  yield call (fileUpload, API.uploadCityMaster, action.data);
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
	    if(action.onSuccess){
			action.onSuccess(data);
		}
		yield put({
			type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
		 	data :{type :1, msg :"Excel uploaded Sucessfully!"}
		})
	}catch (e){
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
		 	data :{type :2, msg :e && e.message ? e.message :"Oops! Something went wrong."}
		})
	}
}

export function* downloadCityMaster(action) {
	try{
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const data = yield call(getData, API.downloadCityMaster+action.params)
         yield put({
			 type :ACTIONS.DATA_GOVERNANCE.DOWNLOAD_ODA_DATA,
			 data : data
		 });
		if(action.onSuccess){
			action.onSuccess(data.data);
		}
		yield put({
			type : ACTIONS.LOADER.HIDE_LOADER
		})
	}catch(e){
       yield put({
		   type : ACTIONS.LOADER.HIDE_LOADER
	   })
	   yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
		 	data :{type :2, msg :e && e.message ? e.message :"Please select the Vendor"}
		})
	}
}


export function* getRMVendorsTypes(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		})
		const data = yield call(getData, API.getRMVendors);
		yield put({
			type: ACTIONS.DATA_GOVERNANCE.GET_RM_VENDORS_TYPES_DATA,
			data: data.data
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
	} catch(e) {
		yield put({
	     	type: ACTIONS.LOADER.HIDE_LOADER
	    });
	  	yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	    })
	}
}

export function* getRMModesTypes(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		})
		const data = yield call(getData, API.getRMModes);
		yield put({
			type: ACTIONS.DATA_GOVERNANCE.GET_RM_MODES_TYPES_DATA,
			data: data.data
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
	} catch(e) {
		yield put({
	     	type: ACTIONS.LOADER.HIDE_LOADER
	    });
	  	yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	    })
	}
}

export function* getRMRegions(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		})
		const data = yield call(getData, API.getRMRegions);
		yield put({
			type: ACTIONS.DATA_GOVERNANCE.GET_RM_REGIONS_DATA,
			data: data.data || []
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
	} catch(e) {
		yield put({
	     	type: ACTIONS.LOADER.HIDE_LOADER
	    });
	  	yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	    })
	}
}

export function* getRMData(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getRMData + action.params);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	        type: ACTIONS.DATA_GOVERNANCE.GET_RM_MASTER_DATA,
	        data: data.data
	    })
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	    })
	}
}

export function* postRMData(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(postData, API.postRMData, action.params);
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data added Successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

export function* updateRMData(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(putData, API.updateRMData, JSON.stringify(action.params));
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data updated Successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

export function* deleteRMData(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(deleteData, API.deleteRMData, JSON.stringify(action.params));
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data Deleted Successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

export function* downloadRMTemplate(action) {
	try{
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const data = yield call(getData, API.downloadRMTemplate)
		if(action.onSuccess){
			action.onSuccess(data.data);
		}
		yield put({
			type : ACTIONS.LOADER.HIDE_LOADER
		})
	}catch(e){
       yield put({
		   type : ACTIONS.LOADER.HIDE_LOADER
	   })
	   yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
		 	data :{type :2, msg :e && e.message ? e.message :"Oops! Something went wrong."}
		})
	}
}

export function* getRMLanes(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(getData, API.getRMLanes + action.params);
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data Retrived Successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}
export function* uploadRateMastersData(action) {
	try {
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER
	  });
	  const data = yield call(fileUpload, API.uploadRateMasters, action.data);
  
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER
	  });
	  if (action.onSuccess) {
		action.onSuccess(data.data);
		}
	  yield put({
		  type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
		  data : {type : 1 , msg : "Data Updated Successfully!"}
	  });
	} catch (e) {
		yield put({
		type: ACTIONS.LOADER.HIDE_LOADER
	  });
		yield put({
		  type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
		  data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	  })
	}
}

export function* getRapidDieselRates(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		})
		const data = yield call(getData, API.getRapidDieselPrice);
	    yield put({
	      type: ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	    	type: ACTIONS.DATA_GOVERNANCE.RAPID_RATES_DATA,
	    	data: data.data && data.data.rates ? data.data.rates : {},
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	} catch(e){
	    yield put({
	      type: ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data: {type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

export function* getHubs(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(getData, API.getHubs + action.params);
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	    	type: ACTIONS.DATA_GOVERNANCE.GET_HUBS_DATA,
	    	data: data.data,
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

export function* readVolumetricWeightType(action) {
	try {
		console.log('action', action);
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(getData, API.readVolumetricWeightTypes+action.params)
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	    	type: ACTIONS.DATA_GOVERNANCE.GET_VOLUMETRIC_WEIGHT_TYPE_DATA,
	    	data: data.data && data.data.types ? data.data.types : [],
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
}

export function* postVolumetricWeightType(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(postData, API.postVolumetricWeightTypes, action.params)
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
}

export function* deleteVolumetricWeightType(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(deleteData, API.deleteVolumetricWeightTypes+action.params)
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
}

export function* postApproveMonthlyRates(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(postData, API.approveMonthlyRates, action.params)
		console.log('data.status', data.status)
	    if (data.status === 400) {
	    	yield put({
				type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data: {type: 2, msg: data&&data.message ? data.message : "Oops! Something went wrong."}
			})
	    } else {
	    	yield put({
				type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data: {type: 1, msg: data&&data.message ? data.message : "Approved Sucessfully!"}
			})
	    }
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	} catch(e) {
		console.log('data e',  e&&e.responseJSON, e.responseJSON.message)
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.responseJSON ? e.responseJSON.message : "Oops! Something went wrong."}
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	}
}

export function* addNewDemurrage(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(postData, API.addDemurrage, action.params)
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
}

export function* getDemurrages(action) {
   let searchString = '';
   if(action.params.limit) {
	searchString+='?limit='+action.params.limit;
	searchString+='&offset='+action.params.offset;
   }
   if(action.params.search) {
	searchString+='&search='+action.params.search
   }
   if(action.params.mode_id) {
	searchString+='&mode_id='+(action.params.mode_id ? action.params.mode_id : 1)
   }
   if(action.params.category) {
   	searchString+='&vendor_category='+action.params.category
   }
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(getData, API.getDemurrage + searchString)
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	    	type: ACTIONS.DATA_GOVERNANCE.DEMURRAGE_DATA,
	    	data: data && data.data ? data.data : [],
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
}

export function* updateDemurrages(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(putData, API.updateDemurrage, JSON.stringify(action.params));
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data updated Successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

export function* getMDGOtherCharges(action) {
   let searchString = '';
   if(action.params.limit) {
	searchString+='?limit='+action.params.limit;
	searchString+='&offset='+action.params.offset;
   }
   if(action.params.search) {
	searchString+='&search='+action.params.search
   }
   if(action.params.company_id) {
   	searchString+='&company_id='+action.params.company_id
   }
   if(action.params.vendorId) {
   	searchString+='&vendorId='+action.params.vendorId
   }
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(getData, API.getOtherCharges + searchString)
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	    	type: ACTIONS.DATA_GOVERNANCE.OTHER_CHARGES_DATA,
	    	data: data && data.data ? data.data : [],
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
}

export function* addNewOtherCharges(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(postData, API.postOtherCharges, action.params)
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
}

export function* updateOtherCharges(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(putData, API.updateOtherCharges, JSON.stringify(action.params));
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data updated Successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

export function* deleteOtherCharges(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(deleteData, API.deleteOtherCharges+action.params, JSON.stringify({...action.data}))
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data)
	    }
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 1, msg:data.result && data.result.message}
		})
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
}

export function* getOtherChargesDropdownList(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const url = Object.keys(action.params || {}).length>0 ? API.getOtherChargesDropdown+action.params : API.getOtherChargesDropdown
		const data = yield call(getData, url)
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	    	type: ACTIONS.DATA_GOVERNANCE.OTHER_CHARGES_DROPDOWN_DATA,
	    	data: data.data ? data.data : [],
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data || [])
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
}

export function* getHubsMasterDropdown(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(getData, API.getHubsDropdown+action.params)
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	    	type: ACTIONS.DATA_GOVERNANCE.HUBS_MASTER_DROPDOWN_DATA,
	    	data: data.data ? data.data : [],
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data || [])
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
}

export function* deleteHubsMasterData(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(deleteData, API.deleteHub+action.params, JSON.stringify({...action.data}))
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
}

export function* updateHubsMaster(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(putData, API.edithubs+action.params, JSON.stringify(action.data));
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data updated Successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}

export function* addNewHubMaster(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(postData, API.addHubs+action.params, action.data);
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
}

export function* downloadHubsMasterData(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER
    });
    const data = yield call(getData, API.downloadHubs + action.params);
    yield put({
    	type: ACTIONS.DATA_GOVERNANCE.DOWNLOAD_HUBS_MASTER_DATA,
    	data: data.data,
    })
    if (action.onSuccess) {
    	action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER
    });
  } catch (e) {
  	yield put({
      type: ACTIONS.LOADER.HIDE_LOADER
    });
  	yield put({
        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	})
  }
}

export function* uploadHubsMasterData(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER
    });
    const data = yield call(fileUpload, API.uploadHubs+action.params, action.data);

    if (action.onSuccess) {
    	action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER
    });
    // if (action.onSuccess) {
    // 	action.onSuccess(data.data);
    // }
    yield put({
        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
        data : {type : 1 , msg : "Data Updated Successfully!"}
    });
  } catch (e) {
  	yield put({
      type: ACTIONS.LOADER.HIDE_LOADER
    });
  	yield put({
        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
    })
  }
}

export function* downloadDPHVendorLanes(action) {
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER
    });
    const data = yield call(getData, API.downloadVendorLanes + action.params);
    // yield put({
    // 	type: ACTIONS.DATA_GOVERNANCE.DOWNLOAD_VENDOR_LANES_LINK,
    // 	data: data.data,
    // })
    if (action.onSuccess) {
    	action.onSuccess(data.data);
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER
    });
  } catch (e) {
  	yield put({
      type: ACTIONS.LOADER.HIDE_LOADER
    });
  	yield put({
        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	})
  }
}


export function* getDPHDiscountStructure(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(getData, API.getDiscountStructure+action.params)
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	    	type: ACTIONS.DATA_GOVERNANCE.DPH_DISCOUNT_STRUCTURE_DATA,
	    	data: data.data ? data.data : [],
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data || [])
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
}

export function* deleteDPHDiscountStructure(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(deleteData, API.deleteDiscountStructure+action.params, JSON.stringify({...action.data}))
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
}

export function* updateDPHDiscountStructure(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(putData, API.updateDiscountStructure+action.params, JSON.stringify(action.data));
	    yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data updated Successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg : e && e.responseJSON ?  e.responseJSON.message :"Oops! Something went wrong."}
	    })
	}
}

export function* addDPHDiscountStructure(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(postData, API.postDiscountStructure+action.params, action.data);
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
		})
	}
}

export function* getHSDContractDetails(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		// const data = yield call(getData, API.hsdContractDetails+`?companyId=${action.param.companyId}&hsdCycleType=${action.param.hsdCycleType}`)
		const data = yield call(getData, API.hsdContractDetails+action.param);
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	    	type: ACTIONS.DATA_GOVERNANCE.HSD_CONTRACT_DATA,
	    	data: data.data ? data.data : [],
		})
		if(action.onSuccess){
	      action.onSuccess(data.data || [])
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
}

export function* getHSDLaneDetails(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(getData, API.hsdLaneDetails+action.params)
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	    	type: ACTIONS.DATA_GOVERNANCE.HSD_CONTRACT_LANE_DATA,
	    	data: data.data ? data.data : [],
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data || [])
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
}


export function* editHSDContractDetails(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
		})
		const data = yield call(putData, API.hsdEditContractDetails+action.params, JSON.stringify(action.data));
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
		}
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data :{type : 1, msg :"Data updated Successfully!"}
		})
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
		})
	}
}


export function* hsdSaveApprovalDetails(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(postData, API.hsdSaveApprovalDetails+action.params, action.data);
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data :{type : 1, msg : "Rate updated Successfully!"}
		})
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
		}
		
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
		})
	}
}

export function* postHSDdetails(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(postData, API.postHSDdetailsURL ,action.params);
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data :{type : 1, msg : data && data.message ?  data.message: "data updated successfully"}
		})
	  	yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
		   }
		
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg : e && e.message ?  e.message : "Oops! Something went wrong."}
		})
	}
}

export function* downloadHSDTemplate(action) {
	// debugger
  try {
    yield put({
      type: ACTIONS.LOADER.SHOW_LOADER,
    });
    let url = !!action.withData
      ? `${API.downloadExcelHSDUrl}/details?`
      : `${API.downloadExcelHSDUrl}/temp`;
			if(!!action.withData){
				const data = yield call(getData, url+`company_id=${action.params.company_id}&`+`rfq_id=${action.params.rfq_id}`);	
				if (action.onSuccess) {
					action.onSuccess(data.data);
				}
			}else{
				const data = yield call(getData, url);
				if (action.onSuccess) {
					action.onSuccess(data.data);
				}
			}
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
  } catch (e) {
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER,
    });
    yield put({
      type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      data: {
        type: 2,
        msg: e && e.message ? e.message : "Oops! Something went wrong.",
      },
    });
  }
}

export function* uploadHSDDetails(action) {
	const url=API.uploadHsdLanesURL+`?`+`company_id=${action.params.company_id}&`+
	`userId=${action.params.userId}&`+`userEmail=${action.params.userEmail}`
	try {
    const resp = yield call(
      axiosT1.post,
      url,
      action.file,
      );

    if (action.onSuccess) {
      const uploadDetails = resp.data || []
      action.onSuccess(uploadDetails)
    }

  } catch (error) {
    action.onCatchError(error)
  }
}

export function* getHSDZoneDetails(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(getData, API.hsdZoneDetails+action.params)
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data || [])
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
}

export function* getHSDConsolidatedDetails(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
		})
		const data = yield call(postData, API.hsdConsolidatedView, action.data)
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data || [])
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
}

export function* getConsolidatedViewRFQDetails(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(getData, API.getConsolidatedViewRFQDetails+action.params)
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	    	type: ACTIONS.DATA_GOVERNANCE.HSD_CONSOLIDATED_RFQ_DATA,
	    	data: data.data ? data.data : [],
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data || [])
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
}

export function* getTATFormVendorDetails(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(getData, API.tatFormVendorDetails+action.params)
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	    	type: ACTIONS.DATA_GOVERNANCE.TAT_FORM_VENDOR_DATA,
	    	data: data.data ? data.data : [],
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data || [])
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
}

export function* updateTATFormDetails(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
		})
		const data = yield call(postData, API.tatFormUpdateDetails, action.data)
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data || [])
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
}

export function* getDailyDieselPriceHike(action) {
	try {
		console.log('action', action);
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(getData, API.dailyDPHUrl+action.params)
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
		yield put({
	    	type: ACTIONS.DATA_GOVERNANCE.STORE_DAILY_DPH,
	    	data: data.data ? data.data : [],
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
}
export function* postNetworkMaster(action){
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.postNetworkMaster, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 1, msg : data.data && data.data.message ?  data.data.message : "Data saved Successfully!"}
	    })
	    if (action.onSuccess) {
			action.onSuccess(data.data);
		}
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 2 , msg : e && e.message ?  e.message : "Oops! Something went  wrong."}
	    })
	}
}
export function* getNetworkMasterData(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		let searchString = "";
		 
		if(action.params.limit) {
			searchString+='&limit='+action.params.limit;
			searchString+='&offSet='+action.params.offSet;
		   }
		// `&limit=${action.params.limit}&offset=${action.params.offset}`;
		if(action.params.search) {
			searchString+='&search='+action.params.search
		}
		const data = yield call(getData, API.getNetworkMasterData+action.params.companyId + searchString);
		
		yield put({
			type: ACTIONS.DATA_GOVERNANCE.NETWORK_MASTER_DETAILS,
			data: data && (data.data  || [])
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
	} catch(e) {
		
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 1, msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	    })
	}
}
	
export function* updateNetworkMasterdata(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(putData, API.updateNetworkMasterdata, JSON.stringify(action.params));
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 1, msg : data.data && data.data.message ?  data.data.message : "Data updated successfully!"}
	    })
	    if (action.onSuccess) {
			action.onSuccess(data.data);
		}
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 2 , msg : e && e.message ?  e.message : "Oops! Something went wrong."}
	    })
	}
}
export function* downloadnetworkmasterData(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const url = action.params ? API.downloadnetworkmasterData+action.params : API.downloadnetworkmasterData;
		const data = yield call(getData, url);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		if (action.onSuccess) {
	    	action.onSuccess(data.data);
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	    })
	}
}
export function* networkmasterupload(action) {
	
	try{
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER
	  });
	  const data = yield call(fileUpload, API.networkmasterupload+action.companyId, action.formData);
	
	  if(action.onSuccess){
		action.onSuccess(data);
	  }

	yield put({ type : ACTIONS.MSG_DISPLAY.SHOW_MSG, data : { type : 1 , msg : data.message||"File Uploaded Successfully"}});
  
	} catch(error) {
		console.log(error)
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER
	  });
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: { type: 2, msg: error && error.responseJSON &&  error.responseJSON.result ? error.responseJSON.result.message : "Unable to upload the file" }
	  });
	} yield put({
		type: ACTIONS.LOADER.HIDE_LOADER
	  });
  }
  export function* downloadNetworkMaster(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const url = action.params ? API.downloadNetworkMaster+action.params : API.downloadNetworkMaster;
		const data = yield call(getData, url);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		if (action.onSuccess) {
	    	action.onSuccess(data.data);
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
	        type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
	        data : {type : 2 , msg : e && e.responseJSON ?  e.responseJSON.message : "Oops! Something went wrong."}
	    })
	}
}
//dropdown api
export function* getorigin_zoneNM(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(getData, API.getorigin_zoneNM)
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	    	type: ACTIONS.DATA_GOVERNANCE.SAVE_ORIGIN_NETWORK_MASTER_DROPDOWN_DATA,
	    	data: data.data ? data.data && (data.data || []).map(el => {
				return {
					label: el.origin,
					value: el.id,
					...el
				}
			}) : [],
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data || [])
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
  }
 
  export function* getmodeNM(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(getData, API.getmodeNM)
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	    	type: ACTIONS.DATA_GOVERNANCE.SAVE_MODE_NETWORK_MASTER_DROPDOWN_DATA,
	    	data: data.data ? data.data && (data.data || []).map(el => {
				return {
					label: el.mode,
					value: el.id,
					...el
				}
			}) : [],
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data || [])
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
  }
  export function* getRoutenameNM(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(getData, API.getRoutenameNM)
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	    	type: ACTIONS.DATA_GOVERNANCE.SAVE_ROUTE_NAME_NETWORK_MASTER_DROPDOWN_DATA,
	    	data: data.data ? data.data && (data.data || []).map(el => {
				return {
					label: el.routeName,
					value: el.id,
					...el
				}
			}) : [],
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data || [])
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
  }
  
  export function* getsourceNM(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(getData, API.getNetworkNM+action.params)
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	    	type: ACTIONS.DATA_GOVERNANCE.SAVE_SOURCE_NETWORK_MASTER_DROPDOWN_DATA,
	    	data: data.data ? data.data && (data.data || []).map(el => {
				return {
					label: el.network,
					value: el.id,
					...el
				}
			}) : [],
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data || [])
	    }
	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.message ? e.message : "Oops! Something went wrong."}
		})
	}
  }

export function* postDphAmendmentMonthlyRates(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
		const data = yield call(postData, API.dphAmendmentMonthlyRates, action.params)
		console.log('data.status', data.status)
	    if (data.status === 400) {
	    	yield put({
				type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data: {type: 2, msg: data&&data.message ? data.message : "Oops! Something went wrong."}
			})
	    } else {
	    	yield put({
				type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data: {type: 1, msg: data&&data.message ? data.message : "Approved Sucessfully!"}
			})
	    }
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	} catch(e) {
		console.log('data e',  e&&e.responseJSON, e.responseJSON.message)
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {type: 2, msg: e&&e.responseJSON ? e.responseJSON.message : "Oops! Something went wrong."}
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	}
}
export function* getDphAmendmentMonthlyRates(action) {
	try {
		yield put({ 
	      type :ACTIONS.LOADER.SHOW_LOADER
	    })
	    const data = yield call(getData, API.getDphAmendmentMonthlyRates + action.params);
		yield put({
	      type :ACTIONS.LOADER.HIDE_LOADER
	    })
	    if(action.onSuccess){
	      action.onSuccess(data.data)
	    }
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type : 1, msg :"Data Retrived Successfully!"}
	    })
	} catch(e){
	    yield put({
	      type : ACTIONS.LOADER.HIDE_LOADER
	    })
	    yield put({
	      type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
	      data :{type :2 , msg :e && e.message ?e.message :"Oops! Something went wrong."}
	    })
	}
}