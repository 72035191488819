import React, { Component, Fragment } from 'react';
import "./demurrage.less";

import Button from "../../home/button-classic/button-classic";
import InputText from "../../common/components/input-text/input-text";

import Select from "react-select";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DATES = [ {label: 'On', value: 'on'},
                {label: 'After', value: 'After'},
                {label: 'Before', value: 'Before'}
                ]

class MyDatePicker extends Component {
  render() {
    return (
      <div className="data-picker">
        <div className="label">{this.props.label}</div>
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={this.props.startDate}
          onChange={this.props.handleChange.bind(this, this.props.id)}
        />
      </div>
    );
  }
}

class AddNewDemurrage extends Component {

	constructor(props){
        super(props);
        this.state = {
            isError: '',
            showAddSlab: true,
        }
    }

    isDisabled = () => {
        const { newDemurrage } = this.props;
        
        if (newDemurrage.vendor_name && newDemurrage.mode_id && newDemurrage.demurrage_start_date) {
            return false;
        }
        return true;
    }

	render() {
		const { newDemurrage, modesList, vendorsList } = this.props;
        const slabAdded = newDemurrage;
    
        return(
			<div className="add-user-wrap">
                <div className="overlay"></div>
                <div id="modal" className="modal-popup">
                    <div className="pop-heading"> Add Demurrage </div>
                    <div className="content">

                        <div className="list-section table-header divTableRow">
                            <div className="divTableCell bordered"> Vendor Name </div>
                            <div className="divTableCell bordered"> Vendor Code </div>
                            <div className="divTableCell bordered"> Mode </div>
                            <div className="divTableCell bordered"> Demurrage Date </div>
                            <div className="divTableCell bordered"> Min (Days) </div>
                            <div className="divTableCell bordered"> Max (Days) </div>
                            <div className="divTableCell bordered"> Frieght (%)</div>
                        </div>

                        {(slabAdded.demurrage_charges || []).map((slab, index) => {
                            return (
                                <div key={index} className="list-section content divTableRow">
                                    {index===0 ? <div className="divTableCell"> 
                                        <Select
                                            className="select"
                                            options={vendorsList || []}
                                            // value={typeUOM && typeUOM.value ? DELAY_UOM_TYPES.find(val => { return  val.value === typeUOM.value }) : null}
                                            onChange={(value) => {  
                                                this.props.onChangeInput(value, null, 'vendor_obj', index)
                                            }}
                                        />
                                    </div>:<div className="divTableCell"> </div>}
                                    {index===0 ? <div className="divTableCell"> {slabAdded.vendor_code?slabAdded.vendor_code:''} </div>:<div className="divTableCell"> </div>}
                                    {index===0 ? <div className="divTableCell"> 
                                        <Select
                                            className="select"
                                            options={modesList || []}
                                            onChange={(value) => {  
                                                this.props.onChangeInput(value, null, 'mode_type_obj', index)
                                            }}
                                        />
                                    </div>:<div className="divTableCell"> </div>}
                                    {index===0 ? <div className="divTableCell">
                                        <Select
                                            className="select"
                                            options={DATES}
                                            onChange={(value) => {  
                                                this.props.onChangeInput(value.value, null, 'demurrage_start_date', index)
                                            }}
                                        />
                                        {
                                        //  <MyDatePicker
                                            //id="demurrage_start_date"
                                            //startDate={slabAdded.demurrage_start_date}
                                            //handleChange={(id, date) => { this.props.onChangeInput(new Date(date).getTime(), null, 'demurrage_start_date', index) }}
                                        // />
                                        }
                                    </div>:<div className="divTableCell"></div>}
                                    <div className="divTableCell slabRange"> 
                                        <InputText type="number" changeHandler={(value, id) => this.props.onChangeInput(value, id, 'min', index)} value={slab.min} placeholder="From" />
                                    </div>
                                    <div className="divTableCell slabRange"> 
                                        <InputText type="number" changeHandler={(value, id) => this.props.onChangeInput(value, id, 'max', index)} value={slab.max} placeholder="To" />
                                    </div>
                                    <div className="divTableCell slabRange">
                                        <InputText type="number" changeHandler={(value, id) => this.props.onChangeInput(value, id, 'frieght', index)} value={slab.frieght} placeholder="Frieght" />
                                    </div>
                                    {/*<div className="divTableCell slabRange">
                                        <Select
                                            className="select"
                                            options={[{label: 'Percentage', value: 'Percentage', id: 1}, {label: 'Paisa', name: 'Paisa', id: 2 }]}
                                            // value={typeUOM && typeUOM.value ? DELAY_UOM_TYPES.find(val => { return  val.value === typeUOM.value }) : null}
                                            onChange={(value) => {  
                                                this.props.onChangeInput(value.value, null, 'vendor_obj', index)
                                            }}
                                        />
                                    </div>*/}
                                </div>
                            );
                        })}
                        
                        <div className="submit-section mt-40">
                            {
                                this.state.showAddSlab && <button className="add-button" onClick={this.props.addNewSlabs}>+ Add Slab</button>
                            }
                            <button className="add-button discard" onClick={this.props.cancelNewSlab}>Cancel</button>
                            <button 
                                className="add-button" 
                                // disabled={this.isDisabled()} 
                                onClick={() => {
                                    if (this.isDisabled()) {
                                        this.setState({ isError: 'Please fill all the fields to proceed.' });
                                    } else {
                                        this.setState({ isError: '' });
                                        this.props.handleSave();
                                    }
                                }}
                            >Save
                            </button>
                        </div>
                        
                        {this.state.isError.length > 0 && <div className="error">{this.state.isError}</div>}
                    </div>
                </div>
            </div>
		)
	}
}

export default AddNewDemurrage;