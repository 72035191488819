import React, { Component, } from 'react';
import InputText from "../../../../common/components/input-text/input-text";
import Cx from "classnames";
import { getEmail, getUserId } from '../../../../../utils/common';

class AddWarehouse extends Component {
    constructor(props){
        super(props);
        this.state = {
            storage_location: this.props.data ? this.props.data.storage_location : '',
            warehouse_name: this.props.data ? this.props.data.warehouse_name : '',
            plant_code:  this.props.data ? this.props.data.plant_code : '',
        }
    }

    onChangeHandler = (value, id) =>{
        this.setState({
            [id] : value.toUpperCase()
        })
    }

    onSave = () => {
        this.props.onAddWarehouse(this.state);
        this.props.toggleAdd();
    }

    updateWarehouse = () => {
        let details = this.state;
        const {previousWarehouseStorageData} = this.props
        if(this.props.data.id)
            details.id = this.props.data.id;
        if(details){
            details.previous_data = previousWarehouseStorageData
            if(details.previous_data){
                details.previous_data.update_flag = true;
                details.previous_data.master_type = 17;
                details.previous_data.user_email = getEmail();
                details.previous_data.user_id = getUserId();
                details.previous_data.isUpdate = true;
            }
        }
        this.props.onAddWarehouse(details, true);
        this.props.toggleAdd();
    }

    render() {
        const { storage_location, warehouse_name, warehouse_description, plant_code } = this.state;
        const { data } = this.props;

        return (
            <div className = "add-user-wrap add-bay">
                <div onClick = {this.props.toggleAdd} className = "overlay"></div>
                <div id = "modal" className = "modal-popup">
                    <div className = "pop-heading">
                        {`${data ?'Edit' : 'Add'} Warehouse`}
                    </div>
                    <div className = "content add-edit-user">
                        <form className = "form-section" autoComplete = "nope">
                            <InputText placeholder = "Name" id = {"warehouse_name"} label = "Warehouse Name" value = {warehouse_name} changeHandler = {this.onChangeHandler}/>
                            {/* <InputText placeholder = "Storage Location" id = {"warehouse_description"} label = "Warehouse Description" value = {warehouse_description} changeHandler = {this.onChangeHandler} /> */}
                            <InputText placeholder = "Storage Location" id = {"storage_location"} label = "Storage Location" value = {storage_location} changeHandler = {this.onChangeHandler} />
                            <InputText placeholder = "Branch Code" id = {"plant_code"} label = "Branch Code" value = {plant_code} changeHandler = {this.onChangeHandler} />
                        </form>

                        <div className = "submit-section">
                            <button className = "add-button discard" onClick={this.props.toggleAdd} >Cancel</button>
                            {!data ? <button className ={Cx("add-button", {
                                'disable-btn' : !(storage_location && warehouse_name && plant_code)
                            })} onClick={this.onSave}>Save</button>
                            :  <button className ={Cx("add-button", {
                                'disable-btn' : !(storage_location && warehouse_name && plant_code)
                            })} onClick={this.updateWarehouse}>Upate</button>
                        }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddWarehouse;