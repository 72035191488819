import React, { Component } from 'react';
import AddItem from './add-item/add-item';
import EditItem from './edit-item/edit-item';
import Edit from '../../../../assets/images/edit.svg';
import Delete from '../../../../assets/images/delete.svg';
import Button from '../../../home/button-classic/button-classic';
import Search from '../../../common/components/search-text/search-text';
import { connect } from "react-redux";
import { getCookie } from "../../../../utils/cookies";
import ACTION from "../../../common/action_constant";
import { timers } from 'jquery';
class SkuMaster extends Component {
    constructor() {
        super();
        this.state = {
			isOpen : false,
			isEdit : false,
			limit : 20,
			offset : 0,
			editedData : '',
			search: ''
		}
	}
    componentDidMount = () => {
		window.addEventListener('scroll', this.handleScroll);
		this.getSkuDetails();
	}
    toggleAdd = (isType, data='') => {
		if(isType == "Edit") {
			this.setState({isEdit : !this.state.isEdit, editedData : data});
		} else {
			this.setState({isOpen : !this.state.isOpen});
		}
	}
      
	getSkuDetails = () => {
		const data = {
			offset : this.state.offset,
			limit : this.state.limit
		}
		if(this.state.search) {
			data.search = this.state.search;
		}
		this.props.getSkuDetails(data);
	}
    clearFilterOnCancel = (e, type) => {
		this.setState({ search: '' }, () => {
			this.getSkuDetails();
		});
	}
    changeHandler = (val,id) =>{
		this.setState({
			[id]: val
		});
	}

	submitSearch = () => {
		this.getSkuDetails();
    }
    previous = () => {
        if (this.state.offset > 0) {
          this.setState(
            {
              offset: this.state.offset - 20,
            },
            () => {
              const data = {
                limit: this.state.limit,
                offset: this.state.offset,
              };
              this.props.getSkuDetails(data);
            }
          );
        }
      };
    
      next = () => {
        this.setState(
          {
            offset: this.state.offset + 20,
          },
          () => {
            const data = {
              limit: this.state.limit,
              offset: this.state.offset,
            };
            this.props.getSkuDetails(data);
          }
        );
      };

      downloadTemplate=()=>{
        const { dispatch } = this.props;
        const company_id = JSON.parse(getCookie('user')).company_id;
        dispatch({ 
          type : ACTION.MASTER.VEHICLE_PLANNING.DOWNLOAD_SKU_TEMP,
          company_id, 
          onSuccess: ((data) => {
            this.setState({
                downloadLink: data[0].Link
            });
            this.downloadBtn.click();
          })
        });
      }
      downloadDetails=()=>{
        const { dispatch } = this.props;
        const company_id = JSON.parse(getCookie('user')).company_id;
        dispatch({ 
          type : ACTION.MASTER.VEHICLE_PLANNING.DOWNLOAD_SKU_DETAILS, 
          company_id,
          onSuccess: ((data) => {
            this.setState({
                downloadLink: data[0].Link
            });
            this.downloadBtn.click();
          })
        });
      }
    uploadSkuDetails = (e) => {
		let formData = new FormData();
        formData.append('file', e.target.files[0]);
		this.props.uploadSkuDetails(formData);
	}
    onUpload = () => {
		this.uploadUrl.click();
        
  }

    checkStatus = (active) => {
      if(active){
        return "item-label success";
      } else return "item-label error"
    }

    render() {
        return(
        <div className={'Pfizer-Item'}>
        <div className = "header">
            <Search id={'search'} value={this.state.search} placeholder={'Search'} changeHandler={this.changeHandler} click = {this.submitSearch} canClear={this.state.search !== ''} handleClearFilter={(e) => this.clearFilterOnCancel(e, "seaech")}/>
            <Button value={' + Add'} click={this.toggleAdd}/>
            <Button value={'Upload'} click={this.onUpload}/>
            <input type='file' style={{ display: 'none'}}  ref={(e) => { this.uploadUrl = e ;}} onChange={this.uploadSkuDetails} onClick={(e) => {e.target.value = null }}/>
            <Button value={"Download Details"} click={this.downloadDetails} />
            <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
                      
            <Button value={"Download Temp."} click={this.downloadTemplate} />
            <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
            
        </div>
        <div className = "item-table">
            <div className = "list-section item-header column" style={{minWidth:"fit-content"}}>
                <div className = "item-label">Part No</div>
                <div className = "item-label">Part Name</div>
                <div className = "item-label ">Warehouse</div>
                <div className = "item-label ">Warehouse Loc</div>
                <div className = "item-label">Packaging Type</div>
                <div className = "item-label">SPQ</div>
                <div className = "item-label">Load Type</div>
                <div className = "item-label">Length</div>
                <div className = "item-label">Width</div>
                <div className = "item-label">Height</div>
                <div className = "item-label">Weight</div>
                <div className = "item-label">Total M2</div>
                <div className = "item-label">Total M3</div>
                <div className = "item-label">Pallete Length</div>
                <div className = "item-label">Pallete Width</div>
                <div className = "item-label">Pallete Height</div>
                <div className = "item-label">Weight Per Pallete</div>
                <div className = "item-label">No. of Bins on Pallete</div>
                <div className = "item-label">Pallete Total M2</div>
                <div className = "item-label">Pallete Total M3</div>
                <div className = "item-label">Max Stack</div>
                <div className = "item-label">Freq Of Dispatch</div>
                <div className = "item-label">Vendor Code</div>
                <div className = "item-label">Vendor Name</div>
                <div className = "item-label">Delivery Location</div>
                <div className = "item-label">Plant Name</div>
                <div className = "item-label">Unloading MHE</div>
                <div className = "item-label">Unloading Dock</div>
                <div className = "item-label">Status</div>
                <div className = "item-label">Actions</div>
            </div>
            {(!this.props.skuDatalist || this.props.skuDatalist.length === 0) && <div className={'no_data_found'}>{'No Data Found'}</div>}
            {this.props.skuDatalist && this.props.skuDatalist.map((val)=>{
                return(
                    <div className = "list-section item-header" style={{minWidth:"fit-content"}}>
                <div className = "item-label">{val.part_number || '-'}</div>
                <div className = "item-label">{val.part_description || '-'}</div>
                <div className = "item-label ">{val.warehouse || '-'}</div>
                <div className = "item-label ">{val.warehouse_loc || '-'}</div>
                <div className = "item-label">{val.packing_type || '-'}</div>
                <div className = "item-label">{val.spq || '-'}</div>
                <div className = "item-label">{val.load_type || '-'}</div>
                <div className = "item-label">{val.bin_length || '-'}</div>
                <div className = "item-label">{val.bin_width || '-'}</div>
                <div className = "item-label">{val.bin_height || '-'}</div>
                <div className = "item-label">{val.weight || '-'}</div>
                <div className = "item-label">{val.bin_m2 || '-'}</div>
                <div className = "item-label">{val.bin_m3 || '-'}</div>
                <div className = "item-label">{(val.packing_type === 'Bin' && val.pallete_length) || '-'}</div>
                <div className = "item-label">{(val.packing_type === 'Bin' && val.pallete_width)|| '-'}</div>
                <div className = "item-label">{(val.packing_type === 'Bin' && val.pallete_height) || '-'}</div>
                <div className = "item-label">{(val.packing_type === 'Bin' && val.weight_per_pallete) || '-'}</div>
                <div className = "item-label">{(val.packing_type === 'Bin' && val.no_of_bins_on_pallete) || '-'}</div>
                <div className = "item-label">{(val.packing_type === 'Bin' && val.pallete_m2) || '-'}</div>
                <div className = "item-label">{(val.packing_type === 'Bin' && val.pallete_m3) || '-'}</div>
                <div className = "item-label">{val.bin_stack_permit || '-'}</div>
                <div className = "item-label">{val.freq_of_dispatch || '-'}</div>
                <div className = "item-label">{val.vendor_code || '-'}</div>
                <div className = "item-label">{val.vendor_name || '-'}</div>
                <div className = "item-label">{val.delivery_loc || '-'}</div>
                <div className = "item-label">{val.plant_name || '-'}</div>
                <div className = "item-label">{val.unloading_mhe || '-'}</div>
                <div className = "item-label">{val.unloading_dock || '-'}</div>
                <div className = {this.checkStatus(val.is_active)}>{val.is_active ? 'Active' : 'Inactive'}</div>
                <div className = "item-label">
                    <div className={'delete-icon'} style={{backgroundImage:`url(${Edit})`}} onClick={() => this.toggleAdd("Edit", val, )}></div>
                    <div className={'delete-icon'} style={{backgroundImage:`url(${Delete})`}} onClick={() => this.props.deleteSkuDetails(val,val.company_id)}></div>
                </div>
            </div>
                )
            })}
           
            
        </div>
        <div className="submit-section-pagination">
            <div className="next">
                <Button value="Next" click={this.next} />
            </div>
            {this.state.offset != 0 && <div className="pre">
                <Button value="Previous" click={this.previous} />
            </div>}
        </div>
        {this.state.isOpen && <AddItem toggleAdd={this.toggleAdd} addSkuDetails={this.props.addSkuDetails} />}
        {this.state.isEdit && <EditItem toggleAdd={this.toggleAdd} data={this.state.editedData} updateSkuDetails={this.props.updateSkuDetails} />}
    </div>
    )
    }
}

export default connect()(SkuMaster);
