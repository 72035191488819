import React, { Component } from 'react';
import "./select-branches.less";

class AddRole extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: "",
			branches: []
		}
	}
	onChangeHandler = (value, id) => {
		this.setState({
			[id]: value
		})
	}

	apply = () => {
		let stateBranch = this.state.branches;
		let data = {
			"setting_id": this.props.setting_id,
			"branch_list": []
		}
		for (let i = 0; i < stateBranch.length; i++) {
			if (stateBranch[i].selected && stateBranch[i].id !== -1) {
				data.branch_list.push(
					{
						branch_name: stateBranch[i].name,
						branch_id: stateBranch[i].id
					}
				)
			}
		}
		this.props.applyBranches(data);
		this.props.toggle();
	}
	clickCustomeCheckBox = (ind) => {
		let tempBranch = this.state.branches;
		tempBranch[ind].selected = !tempBranch[ind].selected;

		if (tempBranch[ind].id === -1) {
			for (let i = 0; i < tempBranch.length; i++) {
				tempBranch[i].selected = tempBranch[ind].selected;
			}
		} else {
			const selectAllIndex = tempBranch.findIndex(obj => obj.id === -1);
			tempBranch[selectAllIndex].selected = this.areAllcheckboxesSelected();
		}
		this.setState({
			branches: tempBranch
		});
	}
	areAllcheckboxesSelected = () => {
		const tempBranch = this.state.branches;
		let allChecked = true;

		for (let i = 0; i < tempBranch.length; i++) {
			if (tempBranch[i].id !== -1 && !tempBranch[i].selected) {
				allChecked = false;
				break;
			}
		}

		return allChecked;
	}
	componentDidMount() {
		window.scroll(0, 0);
		this.props.getBranches(this.props.company_id);
	}
	componentWillReceiveProps(newProps) {
		let tempBranch = [];
		// Select All Appended at first
		tempBranch.push({
			id: -1,
			name: 'Select All',
			selected: false
		})
		newProps.branchList && newProps.branchList.forEach((branch, ind) => {
			let obj = {
				id: branch.branch_id,
				name: branch.branch_name,
				selected: false
			}
			tempBranch.push(obj);
		});
		this.setState({
			branches: tempBranch
		});
	}

	render() {
		const { props, state } = this;
		return (
			<div className="add-company-wrap branch-list-root">
				<div onClick={this.props.toggle} className="overlay"></div>
				<div className="modal-popup small-modal">
					<div className="heading">
						Select All Branches To  Apply
                    </div>
					<label className="error">{this.state.error}</label>
					{this.state.branches.length == 0 && <div className="branch-detail">Nothing to select!</div>}
					{this.state.branches && this.state.branches.map((element, index) => {
						const selectAll = (element.id === -1) ? 'selectAll' : '';
						return (
							<div key={index} className="branch-detail">
								<CustomCheckBox id={index} toggle={this.clickCustomeCheckBox} selected={element.selected} />
								<div className={`branch ${selectAll}`}>{element.name}</div>
							</div>
						)
					})}
					<div className="add-button" onClick={this.props.toggle}>Discard</div>
					{this.state.branches.length != 0 && <div className="add-button" onClick={this.apply}>Apply</div>}
				</div>
			</div>
		);
	}
}
const CustomCheckBox = (props) => {
	return (
		<div className={props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick={() => props.toggle(props.id)}>
			{props.selected && <div className="inner-circle"></div>}
		</div>
	)
}


export default AddRole;