import React, { Component, Fragment } from 'react';

import Button from "../../home/button-classic/button-classic";
import InputText from "../../common/components/input-text/input-text";

import Select from "react-select";
import { getSecureRandomKey } from '../../../utils';


const DISTANCES = ["26-75", "76-150", "151-300"];

const WEIGHTS = ["0-100 Kgs", "101-250 Kgs", "251-500 Kgs", "501-1000 Kgs", "Above 1000 Kgs"];


class NewOdaSlab extends Component {

	constructor(props){
        super(props);
        this.state = {
            slabData: props.slabData,
            oda_distance: props.slabData && props.slabData.oda_distance ? props.slabData.oda_distance : DISTANCES,
            oda_distance_uom: "KM",
            oda_weights: props.slabData && props.slabData.oda_weights ? props.slabData.oda_weights : WEIGHTS,
            oda_weights_uom: "Kgs",
        };
    }

	render() {
        return(
			<div className="add-user-wrap">
                <div className="overlay"></div>
                <div id="modal" className="modal-popup">
                    <div className="pop-heading"> Slab Wise </div>
                    <div className="content modalContentList">

                        <div className="cta-action-btns">
                            <button className="button-classic" onClick={this.props.downloadSlab}> Download </button>
                            <a className="download-btn" id="slabDownloadBtn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.props.downloadLink} download />
                            
                            <button className="button-classic" onClick={() => {this.uploadExcel.click()}}> Upload </button>
                            <input type='file' style={{ display: 'none'}}  ref={(e) => { this.uploadExcel = e ;}} onClick={(e) => {e.target.value = null }}  onChange={this.props.uploadSlab} />
                        </div>

                        <div className="divTable company-list-wrap">
                            <div className="list-section table-header divTableHeading divTableRow">
                                {
                                    // (this.state.oda_weights || []).length > 0 &&
                                    // <div className="divTableCell">Weights(Kgs)/ Distance(KM)</div>
                                }
                                {/* <div className="divTableCell">KM / KG</div> */}
                                {
                                    this.state.oda_weights.map((wt) => {
                                    return (
                                        <div className="divTableCell">{wt}</div>
                                    )
                                })}
                            </div>
                            <div className="divTableBody">
                            {this.state.slabData && this.state.slabData.odas &&
                                (this.state.slabData.odas || []).map((first, fIndex) => {
                                    return (<div className="list-section content divTableRow bordered" key={getSecureRandomKey()}>
                                        {(first || []).map((second, sIndex) => {
                                            return (
                                                <Fragment>
                                                    {sIndex===0 && <div className="divTableCell bordered" key={`${second}-${sIndex}-${fIndex}`}>{this.state.oda_distance[fIndex]}</div>}
                                                    <div className="divTableCell bordered" key={`${second}-${sIndex}`}> {second}</div>
                                                </Fragment>
                                            )
                                        })}
                                    </div>)
                                })
                            }
                            </div>
                        </div>

                        <div className="submit-section mt-40">
                            {/*<button className="add-button" onClick={this.props.uploadSla}>Upload</button>
                            <button className="add-button" onClick={this.props.uploadSla}>Download</button>*/}
                            <button className="add-button discard" onClick={this.props.cancelNewSlab}>Cancel</button>
                            {/*<button className="add-button">Save</button>*/}
                        </div>
                        
                    </div>
                </div>
            </div>
		)
	}
}

export default NewOdaSlab;