import React, { Component, Fragment } from 'react';
import Button from "../button-classic/button-classic";
import InputText from "../../common/components/input-text/input-text";

import Select from "react-select";


class AdvanceFilter extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="advance-filter">
                <div className="advance-filter-title">FILTERS</div>
                <div className="m-t-10">
                    <span className="m-5">Category</span>
                    <div className="m-5 m-t-0">
                        {
                            this.props.categories.map((category, index) => (
                            <span key={index} onClick={() => this.props.handleTagClick('companyCategory', category.value)} className={`tag-elements${category.selected?'-active':''}`}>{category.label}</span>
                            ))
                        }
                    </div>
                </div>
                <hr className="h-l m-t-10" />
                <div className="m-t-10">
                    <span className="m-5">Mode</span>
                    <div className="m-5 m-t-0">
                    {
                            this.props.modes.map((mode, index) => (
                            <span key={index} onClick={() => this.props.handleTagClick('modes', mode.id)} className={`tag-elements${mode.selected?'-active':''}`}>{mode.name}</span>
                            ))
                        }
                    </div>
                </div>
                <hr className="h-l m-t-10" />
                <div className="m-t-10">
                    <span className="m-5">Type</span>
                    <div className="m-5 m-t-0">
                    {
                            this.props.types.map((type, index) => (
                            <span key={index} onClick={() => this.props.handleTagClick('types', type.id)} className={`tag-elements${type.selected?'-active':''}`}>{type.name}</span>
                            ))
                        }
                    </div>
                </div>
                <div className="m-t-10 align-center">
                    <button className="apply-filter-button" onClick={() => this.props.handleSubmit()}>APPLY</button>
                </div>
            </div>
        )
    }
}

export default AdvanceFilter;