import React, { Component, Fragment } from "react";
import "./basic_details.less";
import InputText from "../../common/components/input-text/input-text";
import Select from "react-select";
import Button from "../button-classic/button-classic";
import Loader from "../../common/components/loader/loader";
import Attachment from '../../../assets/images/attachment.svg';
import Delete from "../../../assets/images/delete.svg";
import { connect } from "react-redux";
import MSGPopUp from "../../common/components/msg-popup/msg-popup";
import ProviderUsers from "../provider-users/provider-users";
import { legthValidation, validationCheck } from '../../../utils/validate';
import Rupee from '../../../assets/images/rupee.svg';
import ACTIONS from "../../common/action_constant";
import { PAN_CARD_DOC, BANK_DETAILS_DOC, MEMO_ASSOCIATION_DOC, ARTICLE_ASSOCIATION_DOC, COMPLIANCE_DEC_DOC, GST_CERT_DOC, SEEKER_TYPE, PROVIDER_TYPE, FSSAI_DOC, MSA_DOC, SURVEYORS_TYPE, LASHERS_TYPE, HANDLING_AGENT_TYPE, CARGILL_COMPANY_IDS, EMAMI_COMPANY_IDS, CENTURY_PLY, getEmail } from "../../../utils/common";
import { toPascalCase } from "../../../utils/validate";
import { DEEPAK_COMPANY_ID, AWL_COMPANY_IDS, PANTALOONS_COMPANY_IDS, getUserCompanyId } from '../../../utils/common';
import Checkbox from "../../components/checkbox/checkbox";
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Moment from 'moment';
import { getCookie } from "../../../utils/cookies";
import AddGroup from "./add-group-company";
import ACTION from "../../common/action_constant";
import { getItemFromSessionStorage } from '../../../utils/sessionStorage';
import { setItemToSessionStorage } from "../../../utils/sessionStorage";
import { getEmamiSeekerCompanyId} from "../../../utils";


const sapVendorDetails = {
	sap_vendor_code: '',
	company: '',
	company_id: '',
	sap_carrier_code: ''
}

const gstinDetails = {
	gstin: "",
	state: "",
	code: ""
}

class BasicDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			details: {
				company_name: "",
				company_email: "",
				company_website: "",
				state: "",
				state_code: "",
				zipcode: "",
				email: "",
				contact: "",
				pan_no: "",
				admin_name: "",
				gst_mechanism_id: "",
				gst_mechanism: "",
				gstin: "",
				tax_no: "",
				year_of_formation: "",
				last_fiscal_turnover_value: "",
				sap_vendor_code: "",
				sap_carrier_code: "",
				gstin_details: [{
					gstin: "",
					state: "",
					code: "",
					vendor_code: "",
					gst_percentage: "",
					gst_mechanism: "",
					gst_mechanism_id: ""
				}],
				is_gst_applicable: false,
				sap_vendor_details: [
					{
						sap_vendor_code: '',
						company: '',
						company_id: '',
						sap_carrier_code: '',
						email: '',
						contact: '',
						payment_terms: null,
						gstin: "",
						branch_address : ""
						// paymentE_terms:[]

					}
				],
				bank_name: "",
				bank_account_no: "",
				bank_ifsc_code: "",
				group_company: [{
					"company_id": "",
					"group_company_name": ""
				}],

			},
			emamiPaymentTerm: null,
			fileNames: [{
				fileObject: "",
				name: "",
				isPanCardDoc: false,
				isBankDetailsDoc: false,
				isMemoAssociationDoc: false,
				isArticleAssociationDoc: false,
				isComplianceDecDoc: false,
				isGSTCertDoc: false,
				isMSADoc: false
			}],
			stateSelectedOption: {
				label: "",
				value: ""
			},
			emamiPaymentTermsList: [],
			company_logo: "",
			loader: false,
			iserror: '',
			errormessage: '',
			isEdited: false,
			stateList: [],
			seekersList: [],
			filesLinks: [],
			vendorDocuments: [],
			isErroMessageSapVendorCode: "",
			allSeekerBranches: [{ value: 'All', label: 'All' }],
			allEmamiBranches:[{ value: 'All', label: 'All' }],
			isSeekerBranch: false,

			isModalOpen: false,
    		group_company_name: '',
			enableTaxcodeDropDown:this.props.enableTaxcodeDropDown,
			taxcodeOptionsList:this.props.taxcodeOptionsList,
			adaniID: '872',
			vendor_code: "",
			gst_percentage: "",
			gst_mechanism: "",
			gst_mechanism_id: ""
		};
	}
	componentDidMount() {
		const emamiID=getEmamiSeekerCompanyId()
		this.props.getBasicDetails();
		this.props.getBusinessSeg();
		this.props.getEstCategory();
		this.props.fetchAllStates();
		this.props.fetchAllSeekers();
		this.getStateListData()
		this.getEmamiPaymentTermsList(emamiID)
		this.getAllEmamiBranches(emamiID)
		this.props.fetchTaxCodeOptions();
	}

	getStateListData = () => {
		const { dispatch, companyId } = this.props;
		dispatch({
			type: ACTIONS.VENDORS.GET_STATE_LIST_DETAILS,
			data: 'default',
			onSuccess: (data) => {
				let temp = []
				data && data.list && data.list.length > 0 &&
					data.list.map((state) => {
						temp.push({ label: state.name, value: state.code, gstCode: state.gst_state_code });
					})
				this.setState({ stateList: temp || [] })
			}
		})
	}

	onChangeHandler = (value, id, isType) => {

		const propsDetails = this.props.details;
		let details = Object.assign({}, this.state.details);
		if (id === 'bank_ifsc_code' && value.length > 11) {
			return;
		}
		details[id] = validationCheck(value, details[id], isType);
		const errMsg = legthValidation(id, value);
		let editing = this.state.isEdited;
		if (Object.keys(propsDetails).length > 0) {
			if (details[id] !== propsDetails[id] || (propsDetails[id] === undefined && details[id].length > 0)) {
				editing = true;
			}
		}
		this.setState({

			details, errormessage: errMsg, isEdited: editing
		});
		this.props.isFormEdited(editing);
	};

	getAllSeekerBranch = (id, index, details) => {
		const { dispatch } = this.props;
		dispatch({
			type: ACTIONS.PLANTS.GET_ALL_BRANCH,
			data: {
				companyId: id,
				companyType: 1
			},
			onSuccess: (data) => {
				let tempBranch = [{ value: 'All', label: 'All' }];
				if (data) {
					data.map((branch) => {
						tempBranch.push(
							{
								label: branch.branch_name,
								value: branch.branch_id
							}
						)
					})
				}
				this.setState({ isSeekerBranch: true, allSeekerBranches: tempBranch })
			}
		});

	}

	onBlurChange = (e) => {
		const errMsg = legthValidation(e.target.id, e.target.value);
		if (errMsg && e.target.value) {
			this.setState({
				iserror: e.target.id,
				errormessage: errMsg
			})
		} else if (this.state.iserror) {
			this.setState({ iserror: '' });
		}
	}

	fileClicked = (e) => {
		e.preventDefault();
		document.getElementById("file").click();
	};

	fileUploaded = e => {
		e.persist();
		this.setState({
			fileName: e.target.files[0]
		});
		const fileObject = e.target.files[0];
		const reader = new FileReader();
		reader.onload = e => {
			const details = Object.assign({}, this.state.details);
			details.company_logo = e.target.result;
			this.setState({
				details
			});
		};
		reader.readAsDataURL(fileObject);
	};


	pancardDocClicked = (e) => {
		e.preventDefault();
		document.getElementById("pancardDocfile").click();
	};

	bankDetailsAndCancelledChecqueDocClicked = (e) => {
		e.preventDefault();
		document.getElementById("bankDetailsAndCancelledChecqueDocfile").click();
	};

	memorandamOfAssociationDocClicked = (e) => {
		e.preventDefault();
		document.getElementById("memorandamOfAssociationDocfile").click();
	};

	articleForAssociationDocClicked = (e) => {
		e.preventDefault();
		document.getElementById("articleForAssociationDocfile").click();
	};

	complianceDeclarationDocClicked = (e) => {
		e.preventDefault();
		document.getElementById("complianceDeclarationDocfile").click();
	};

	gstCertificatesDocClicked = (e) => {
		e.preventDefault();
		document.getElementById("gstCertificatesDocfile").click();
	};

	fssaiDocClicked = (e) => {
		e.preventDefault();
		document.getElementById("fssaiDocfile").click();
	}

	msaDocClicked = (e) => {
		e.preventDefault();
		document.getElementById("msaDocfile").click();
	}

	multipleFilesUpload = (e, id) => {
		e.persist();
		var indexId = Math.floor(Math.random() * 100) + 1;
		let fileNames = this.state.fileNames;
		for (let i = 0; i < e.target.files.length; i++) {
			const reader = new FileReader();
			const fileObj = e.target.files[i];
			reader.onload = e => {
				let fileObject = fileObj;
				let name = e.target.result;
				if (id === "pancardDocfile") {
					fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isPanCardDoc": true, Id: 1, indexId: indexId });
					this.setState({
						fileNames
					});
				}
				if (id === "bankDetailsAndCancelledChecqueDocfile") {
					fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isBankDetailsDoc": true, Id: 2, indexId: indexId });
					this.setState({
						fileNames
					});
				}
				if (id === "memorandamOfAssociationDocfile") {
					fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isMemoAssociationDoc": true, Id: 3, indexId: indexId });
					this.setState({
						fileNames
					});
				}
				if (id === "articleForAssociationDocfile") {
					fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isArticleAssociationDoc": true, Id: 4, indexId: indexId });
					this.setState({
						fileNames
					});
				}
				if (id === "complianceDeclarationDocfile") {
					fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isComplianceDecDoc": true, Id: 5, indexId: indexId });
					this.setState({
						fileNames
					});
				}
				if (id === "gstCertificatesDocfile") {
					fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isGSTCertDoc": true, Id: 6, indexId: indexId });
					this.setState({
						fileNames
					});
				}
				if (id === "fssaiDocfile") {
					fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isfssaiDoc": true, Id: 7, indexId: indexId });
					this.setState({
						fileNames
					});
				}
				if (id === "msaDocfile") {
					fileNames.push({ "fileObject": fileObject, "name": fileObject.name, "isMSADoc": true, Id: 8, indexId: indexId });
					this.setState({
						fileNames
					});
				}
			}
			reader.readAsDataURL(fileObj);
		}
	}

	removeDocument = (index, e) => {
		e.preventDefault();
		const { fileNames } = this.state;

		const documents = fileNames;
		let remainingDocs = documents.filter((value, i) => {
			return (value && value.indexId !== documents[index].indexId)

		});

		this.setState({
			...this.state,
			fileNames: remainingDocs
		});

		if (remainingDocs.length === 0) {
			this.setState({
				...this.state, fileNames: [{
					fileObject: "",
					name: "",
					isPanCardDoc: false,
					isBankDetailsDoc: false,
					isMemoAssociationDoc: false,
					isArticleAssociationDoc: false,
					isComplianceDecDoc: false,
					isGSTCertDoc: false
				}]
			})
		}
	}

	uploadVendorDocs = () => {
		const { fileNames } = this.state;
		const fileListData = [];
		if (fileNames.length > 1) {
			const formData = new FormData();
			for (let i = 0; i < fileNames.length; i++) {

				if (fileNames[i].name !== "") {
					formData.append("file", this.state.fileNames[i].fileObject);
					fileListData.push({ "id": this.state.fileNames[i].Id });
				}
			}
			formData.append("companyId", this.props.details.company_id)
			formData.append("filesName", JSON.stringify(fileListData));
			this.props.uploadMultipleFiles(formData);
		}
		this.setState({
			...this.state, fileNames: [{
				fileObject: "",
				name: "",
				isPanCardDoc: false,
				isBankDetailsDoc: false,
				isMemoAssociationDoc: false,
				isArticleAssociationDoc: false,
				isComplianceDecDoc: false,
				isGSTCertDoc: false
			}]
		})
	}

	deleteFile = (index, e) => {
		if (index !== null) {
			let data = {
				id: index.id,
				fileName: index.name,
				companyId: this.props.details.company_id
			}
			this.props.deleteFile(data);
		}

	}

	submitCompanyImg = () => {
		if (this.state.fileName) {
			let formData = new FormData();
			formData.append("file", this.state.fileName);
			this.props.companyImgUpload(formData);
		}
	};

	handleChange = (id, selectedOption) => {
		this.setState({ [id]: selectedOption });
	};


	componentWillReceiveProps(props) {
		if (!this.state.isEdited) {
			if (props.details) {
				let details = {
					company_id: props.details.id || "",
					company_name: props.details.company_name || "",
					company_email: props.details.company_email || "",
					zipcode: props.details.zipcode || "",
					company_website: props.details.company_website || "",
					email: props.details.email || "",
					business_segment:props.details.business_segment || "",
					gst_mechanism: props.details.gst_mechanism || "",
					gst_mechanism_id: props.details.gst_mechanism_id || "",
					contact: props.details.contact || "",
					pan_no: props.details.pan_no || "",
					admin_name: props.details.admin_name || "",
					cin_no: props.details.cin_no || "",
					gstin: props.details.gstin || "",
					company_address: props.details.company_address || "",
					// gstin: props.details.gstin || "",
					is_gst_applicable: props.details.is_gst_applicable || false,
					tax_no: props.details.tax_no || "",
					year_of_formation: props.details.year_of_formation || "",
					company_logo: props.details.company_logo || "",
					bank_account_no: props.details.bank_account_no || "",
					bank_ifsc_code: props.details.bank_ifsc_code || "",
					bank_name: props.details.bank_name || "",
					// po_taxcode: props.details.po_taxcode || null,
					fssai_certificate_number: props.details.fssai_certificate_number || "",
					fssai_validity_date: props.details.fssai_validity_date || "",
					msa_validity_date: props.details.msa_validity_date || "",
					incorporation_date: props.details.incorporation_date || "",
					last_fiscal_turnover_value: props.details.last_fiscal_turnover
						? props.details.last_fiscal_turnover.value
						: "",
					sap_vendor_code: props.details.sap_vendor_code || "",
					sap_carrier_code: props.details.sap_carrier_code || "",
					gstin_details: props.details.gstin_details && props.details.gstin_details.length > 0 ? props.details.gstin_details : [{
						gstin: "",
						state: "",
						code: "",
						vendor_code: "",
						gst_percentage: "",
						gst_mechanism: "",
						gst_mechanism_id: ""
					}],
					group_company: props.details.group_company || this.state.details.group_company,
					sap_vendor_details: props.details.sap_vendor_details && props.details.sap_vendor_details.length > 0 ? props.details.sap_vendor_details
						:
						this.state.details.sap_vendor_details
					// [
					// 	{
					// 		sap_vendor_code: '',
					// 		company: '',
					// 		company_id: '',
					// 		sap_carrier_code: ''
					// 	}
					// ],
				};
				let stateSelectedOption = {
					label: props.details.state || "",
					value: props.details.state_code || "",
				}
				this.setState({
					details,
					stateSelectedOption,
					vendorDocuments: props.details.documents || [],
				},()=>{
				});
			}
			if (props.details && props.details.business_segment) {
				let business_segment = {
					value: props.details.business_segment.id,
					label: props.details.business_segment.name
				};
				this.setState({
					business_segment
				});
			}
			// if (props.details && props.details.po_taxcode) {
			// 	let po_taxcode=props.details.po_taxcode
			// 	this.setState({
			// 		po_taxcode
			// 	});
			// }
			if (props.details && props.details.establishment_category) {
				let establishment_category = {
					value: props.details.establishment_category.id,
					label: props.details.establishment_category.name
				};
				this.setState({
					establishment_category
				});
			}
			if (props.details && props.details.tax_id) {
				let tax_id = {
					value: props.details.tax_id.id,
					label: props.details.tax_id.name
				};
				this.setState({
					tax_id
				});
			}
			if (props.details && props.details.last_fiscal_turnover) {
				let last_fiscal_turnover = {
					value: props.details.last_fiscal_turnover.unit,
					label: props.details.last_fiscal_turnover.name
				};
				this.setState({
					last_fiscal_turnover
				});
			}
		}

		
		if (props.seekersList) {
			const seekersList = [];
			for (var i in props.seekersList) {
				seekersList.push({ label: props.seekersList[i].company_name, value: props.seekersList[i].id });
			}
			this.setState({ seekersList });
		}
		const vendorDocuments = [];
		if (props.details && props.details.documents) {

			for (let i in props.details.documents) {
				vendorDocuments.push({
					fileId: props.details.documents[i].fileId,
					url: props.details.documents[i].url,
					name: props.details.documents[i].name,
					id: props.details.documents[i].id
				})
			}
			this.setState({ vendorDocuments });

		}

	}



	validationSapVendor = () => {
		const { stateSelectedOption, details } = this.state
		const companyType = this.props.details && this.props.details.company_type;
		let sap_vendor_details = Object.assign({}, this.state.details.sap_vendor_details);
		let gstin_details = Object.assign({}, this.state.details.gstin_details);
		if(companyType===2){
			if (stateSelectedOption.label === "" || stateSelectedOption.value === "") {
				return false;
			}
		}
		for (var i in sap_vendor_details) {
			if (sap_vendor_details[i].sap_vendor_code === "" && sap_vendor_details[i].company_id) {
				this.setState({ isErroMessageSapVendorCode: "Please Enter SAP Vendor Code" });
				return false;
			} else if (sap_vendor_details[i].company_id && DEEPAK_COMPANY_ID.includes(sap_vendor_details[i].company_id) && sap_vendor_details[i].sap_carrier_code === "") {
				this.setState({ isErroMessageSapVendorCode: "Please Enter SAP Carrier Code" });
				return false;
			}
		}
		for (var i in gstin_details) {
			if (gstin_details[i].vendor_code === "" && companyType===2) {
				this.setState({ isErroMessageSapVendorCode: "Please Enter GST Vendor Code" });
				return false;
			} else if (gstin_details[i].gst_mechanism === "") {
				this.setState({ isErroMessageSapVendorCode: "Please Enter GST Mechanism" });
				return false;
			} else if (gstin_details[i].gst_percentage === "") {
				this.setState({ isErroMessageSapVendorCode: "Please Enter GST Percentage" });
				return false;
				// } else if(gstin_details[i].gstin === ""){
				// 	this.setState({ isErroMessageSapVendorCode: "Please Enter GSTIN" });
				// 	return false;
				// } else if(gstin_details[i].state === ""){
				// 	this.setState({ isErroMessageSapVendorCode: "Please Enter GST State" });
				// 	return false;
			}
		}
		if(details.gstin && details.gstin.length !== 15) {
			this.setState({ isErroMessageSapVendorCode: "GSTIN length should be 15" });
			return false;
		}
		this.setState({
			isErroMessageSapVendorCode: ""
		});
		return true;
	}

	checkUplaodFieldsMandatory = () => {
		let uplaodFlag = true;
		const { vendorDocuments, details } = this.state;
		(vendorDocuments || []).forEach(doc => {
			if (doc.fileId === FSSAI_DOC && !(details.fssai_validity_date && details.fssai_certificate_number)) {
				uplaodFlag = false;
			} else if (doc.fileId === MEMO_ASSOCIATION_DOC && !details.incorporation_date) {
				uplaodFlag = false;
			} else if (doc.fileId === BANK_DETAILS_DOC && !(details.bank_name && details.bank_ifsc_code && details.bank_account_no)) {
				uplaodFlag = false;
			} else if (doc.fileId === MSA_DOC && !(details.msa_validity_date)) {
				uplaodFlag = false;
			}
		});
		this.setState({ isUploadFieldsError: !uplaodFlag });
		return uplaodFlag;
	}

	 processPaymentTerms=(data) =>{
		return data.map(item => {
		  if (EMAMI_COMPANY_IDS.includes(item.company_id)) {
			item.payment_terms = item.payment_terms? item.payment_terms.short_name :' ';
		  }
		  return item;
		});
	  }
	  

	updateBasicDetails = () => {
		const previousData = getItemFromSessionStorage('finalData')
		const { stateSelectedOption } = this.state		
		const { details } = this.props;		
		if (this.validationSapVendor() && this.checkUplaodFieldsMandatory()) {
			let state = Object.assign({}, this.state);
			let temp = {};
			temp["unit"] = state.last_fiscal_turnover
				? state.last_fiscal_turnover.value
				: "";
			temp["value"] = state.details.last_fiscal_turnover_value;
			temp["name"] = state.last_fiscal_turnover
				? state.last_fiscal_turnover.label
				: "";
			let finalData = Object.assign({}, this.state.details);
			finalData["last_fiscal_turnover"] = temp;
			finalData["state"] = stateSelectedOption.label || "";
			finalData["state_code"] = stateSelectedOption.value || "";
			delete finalData["last_fiscal_turnover_value"];
			const processedData = this.processPaymentTerms(finalData.sap_vendor_details);
			finalData.sap_vendor_details=processedData || finalData.sap_vendor_details
			if (state.tax_id) {
				finalData["tax_id"] = {
					id: state.tax_id.value || "",
					name: state.tax_id.label || ""
				};
			}
			if (state.establishment_category) {
				finalData["establishment_category"] = {
					id: state.establishment_category.value || "",
					name: state.establishment_category.label || ""
				};
			}
			if (state.business_segment) {
				finalData["business_segment"] = {
					id: state.business_segment.value || "",
					name: state.business_segment.label || ""
				};
			}
			if (state.business_segment && finalData.previous_data) {
				finalData.previous_data["business_segment"] = {
					id: previousData.business_segment.value || "",
					name: previousData.business_segment.label || ""
				};
			}
			if (this.props.intra_po_taxcode) {
			  const updatePOTaxCode = {...(this.props.intra_po_taxcode || {})}
				delete updatePOTaxCode.company_id
				finalData.intra_po_taxcode = updatePOTaxCode
			}
			if (this.props.inter_po_taxcode ) {
			  const updatePOTaxCode = {...(this.props.inter_po_taxcode || {})}
				delete updatePOTaxCode.company_id
				finalData.inter_po_taxcode = updatePOTaxCode
			}
			if (state.company_logo && finalData.previous_data) {
				finalData.previous_data["company_logo"] = {
					name: previousData.name || "", 
					url: previousData.url || ""    
				};
			}

			if (state.vendorDocuments) {
				if(details.documents){
					finalData["vendorDocuments"] = {
						fileId: details.documents.fileId,
						url: details.documents.url,
						name: details.documents.name,
						id: details.documents.id 
					};
				}
			}
			if (state.vendorDocuments && finalData.previous_data) {
				finalData.previous_data["vendorDocuments"] = {
					fileId: previousData.details.documents.fileId,
					url: previousData.details.documents.url,
					name: previousData.details.documents.name,
					id: previousData.details.documents.id    
				};
			}
			finalData['company_logo'] = details.company_logo;
			finalData['vendorDocuments'] = details.documents;
			finalData['master_type'] = 3;
			finalData['user_email'] = getEmail();
			finalData['update_flag']= true;
			previousData['update_flag'] = true;
			previousData['user_email'] = getEmail();
			finalData['previous_data'] = previousData;
			this.setState({ isEdited: false }, () => {
				this.props.isFormEdited(this.state.isEdited);
			});
			this.props.updateBasicDetails(
				Object.assign({}, this.props.details, finalData)
			);

		} else {
			window.scrollTo(0, 0);
		}

	};
	closeMsgPopup = () => {
		const { dispatch } = this.props;
		dispatch({ type: ACTIONS.MSG_DISPLAY.HIDE_MSG })
	};

	onAddGSTIN = () => {
		const { details } = this.state;
		const row = Object.assign({}, gstinDetails);
		details.gstin_details.push(row);
		this.setState({ details });
	}

	onAddSapVendor = () => {
		const { details } = this.state;
		const row = Object.assign({}, sapVendorDetails);
		details.sap_vendor_details.push(row);
		this.setState({ details });
	}

	onDeleteSapVendor = (index) => {
		const { details } = this.state;
		details.sap_vendor_details.splice(index, 1)
		this.setState({ details });
	}

	onDeleteGSTIN = (index) => {
		const { details } = this.state;
		details.gstin_details.splice(index, 1)
		this.setState({ details });
	}

	handleOpenModal = () => {
		this.setState({ isModalOpen: true });
	};

	handleCloseModal = () => {
		this.setState({ isModalOpen: false, group_company_name: '' });
	};

	handleInputChange = (event) => {
		this.setState({ group_company_name: event.target.value });
	};

	handleSaveModal = (id) => {
		const { group_company_name } = this.state;
		const { dispatch } = this.props
		// Perform actions with the input value here
		dispatch({
			type: ACTIONS.HOME.ADD_GROUP_NAME,
			data: {
				company_id: id,
				group_company_name: this.state.group_company_name
			},
			onSuccess: () => {

				this.setState({ group_company_name: '' })
				this.getSeekergroupNames(id);
			}
		})


		this.setState({ isModalOpen: false, group_company_name: '' });
	};

	getSeekergroupNames = (companyId) => {
		const { dispatch } = this.props;
		dispatch({
			type: ACTIONS.HOME.GET_GROUP_NAME_DROPDOWN,
			param: {
				companyId
			}
		})
	}

	handleOnChangeForGroupCompanyName = (value) => {
		this.setState({ group_company_name: value })
	}

	onStateChange = (stateSelectedOption) => {
		this.setState({ stateSelectedOption })
	}

	getEmamiPaymentTermsList = () => {
		const { dispatch } = this.props;
		const queryParams = {
			companyId: EMAMI_COMPANY_IDS[0]
		}
		const onSuccess = (data = []) => {
			const tempArray = data.map(obj => ({
				...obj,
				label: obj.name,
				value: obj.id
			}))
			this.setState({ emamiPaymentTermsList: tempArray })
		}
		dispatch({
			type: ACTION.HOME.GET_EMAMI_PAYMENT_TERMS,
			queryParams,
			onSuccess
		});
	}

	onEPaymentTermChange = (value) => {
		this.setState({ emamiPaymentTerm: value })
	}

	getAllEmamiBranches = (id, index, details) => {
		const { dispatch } = this.props;
		dispatch({
			type: ACTIONS.PLANTS.GET_ALL_BRANCH,
			data: {
				companyId: id,
				companyType: 1
			},
			onSuccess: (data) => {
				let tempBranch = [{ value: 'All', label: 'All' }];
				if (data) {
					data.map((branch) => {
						tempBranch.push(
							{
								label: branch.branch_name,
								value: branch.branch_id
							}
						)
					})
				}
				this.setState({allEmamiBranches: tempBranch })
			}
		});

	}


	render() {
		const companyType = this.props.details && this.props.details.company_type;
		const companyId = this.props.details && this.props.details.company_id
		const {intra_typecodeOptionsList, inter_typecodeOptionsList}=this.props;
		let optionsBusinessSeg = [],
			optionsEstDetails = [],
			optionsTaxId = [
				{ value: "1", label: "Service Tax Number" },
				{ value: "2", label: "TIN" }
			],
			optionsTurnOver = [
				{ value: "1", label: "Thousands" },
				{ value: "2", label: "Lakhs" },
				{ value: "3", label: "Crores" }
			],
			optionsGSTPercentage = [
				{ value: "1", label: "5" },
				{ value: "2", label: "12" },
				{ value: "3", label: "18" }
			];

		const details = this.state.details;
		const {stateList, seekersList, isModalOpen, group_company_name, stateSelectedOption, emamiPaymentTermsList, emamiPaymentTerm} = this.state;


		if (!details) return <Loader />;
		if (this.props.businessSeg && this.props.businessSeg.length > 0) {
			for (let i = 0; i < this.props.businessSeg.length; i++) {
				optionsBusinessSeg.push({
					value: this.props.businessSeg[i].id,
					label: this.props.businessSeg[i].name
				});
			}
		}
		if (this.props.estCategory && this.props.estCategory.length > 0) {
			for (let i = 0; i < this.props.estCategory.length; i++) {
				optionsEstDetails.push({
					value: this.props.estCategory[i].id,
					label: this.props.estCategory[i].name
				});
			}
		}

		const userInfo = JSON.parse(getCookie('user'));

		return (
			<div className="basic-details-wrap">
				<div className="heading-section">Admin Details</div>
				<div className="details">
					<InputText
						placeholder="Name"
						id={"admin_name"}
						label="Name"
						value={toPascalCase(details.admin_name)}
						changeHandler={this.onChangeHandler}
					/>
					<InputText
						placeholder="Email"
						id={"email"}
						label="Email"
						value={details.email}
						changeHandler={this.onChangeHandler}
					/>
					<InputText
						placeholder="Mobile No"
						id={"contact"}
						label="Mobile No."
						value={details.contact}
						changeHandler={this.onChangeHandler}
						length={'10'}
						isValidate={'numeric'}
						additionalProps={{ onBlur: this.onBlurChange }}
						iserror={this.state.iserror}
						errormessage={this.state.errormessage}
					/>
				</div>
				<div className="company-details">
					<div className="title">Company Details</div>
					<SubTitle title="Basic Details" />
					<div className="details basic-company-details">
						<InputText
							placeholder="Company Name"
							readonly={true}
							id={"company_name"}
							label="Company Name"
							value={toPascalCase(details.company_name)}
							changeHandler={this.onChangeHandler}
						/>
						<InputText
							placeholder="Website"
							id={"company_website"}
							label="Website"
							value={details.company_website}
							changeHandler={this.onChangeHandler}
						/>
						<InputText
							placeholder="Company Email"
							id={"company_email"}
							label="Company Email"
							value={details.company_email}
							changeHandler={this.onChangeHandler}
							additionalProps={{ onBlur: this.onBlurChange }}
							iserror={this.state.iserror}
							errormessage={this.state.errormessage}
						/>


						{/* {companyType == 2 &&
							<InputText
								placeholder="Address"
								id={"company_address"}
								label="Address"
								value={details.company_address}
								changeHandler={this.onChangeHandler}
								additionalProps={{ onBlur: this.onBlurChange }}
								iserror={this.state.iserror}
								errormessage={this.state.errormessage}
								className={'wt-100%'}
							/>
						} */}

						{companyType == 2 &&
							// <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
							// 	<div style={{ color: '#868e96', fontSize: '10px' }}>Address</div>
							// 	<input
							// 		type="text"
							// 		style={{ outline: 'none', border: 'none', height: '35px', borderBottom: '1px solid #d8dbf0', paddingBottom: '13px', fontSize: '16px', marginTop: '10px', marginBottom: '10px' }}
							// 		value={details.company_address}
							// 		onChange={(e) => this.onChangeHandler(e.target.value, "company_address")}
							// 	/>
							// </div>
							<div>
								<InputText
									placeholder="Address"
									type="text"
									label="Address"
									value={details.company_address}
									changeHandler={(value) => this.onChangeHandler(value, "company_address")}
								/>
								<InputText
									placeholder="Zip code"
									type="text"
									label="Zip Code"
									value={details.zipcode}
									length={6}
									changeHandler={(value) => this.onChangeHandler(value, "zipcode", "numeric")}
								/>
								<div style={{ display: "inline-block" }}>
									<div className="label" style={{ color: "#868e96" }}>State<span className={'error-color fs-14'}>*</span></div>
									<Select
										className="select p-5"
										options={stateList}
										value={this.state.stateSelectedOption}
										onChange={this.onStateChange}
										required={true}
									/>
									{stateSelectedOption.label === "" || stateSelectedOption.value === "" ? <div className="error error-font">{'Please Enter State'}</div> : ''}
								</div>

							</div>
						}

						{companyType == 2 &&
							<InputText
								placeholder="CIN number"
								id={"cin_no"}
								label="CIN number"
								value={details.cin_no}
								changeHandler={(value) => this.onChangeHandler(value, "cin_no", "alphanumeric")}
								additionalProps={{ onBlur: this.onBlurChange }}
								iserror={this.state.iserror}
								errormessage={this.state.errormessage}
							/>
						}

						{companyType == 2 &&
							<InputText
								placeholder="GST IN"
								id={"gstin"}
								label="GST IN"
								value={details.gstin}
								changeHandler={(value) => this.onChangeHandler(value, "gstin", "alphanumeric")}
								additionalProps={{ onBlur: this.onBlurChange }}
								iserror={this.state.iserror}
								errormessage={this.state.errormessage}
							/>
						}
						{companyType == 2 &&
							<div className="select-wrap-w-25">
								<div className="label ">GST Mechanism</div>
								<Select
									className="select p-5 wt-100"
									value={[{ value: 1, label: 'FCM' }, { value: 2, label: 'RCM' }].find(val => { return val.label === details.gst_mechanism })}
									onChange={(selectedOption) => {
										const { details } = this.state;
										details.gst_mechanism = selectedOption.label;
										details.gst_mechanism_id = selectedOption.value;
										this.setState({ details });
									}}
									options={[{ value: 1, label: 'FCM' }, { value: 2, label: 'RCM' }]}
								/>
								{/* {details.gstin_details[index].gst_mechanism === "" ? <div className="error error-font">{'Please Enter GST Mechanism'}</div> : ''} */}

							</div>
						}
						{/* {this.props.company_type == 2 && <InputText
              placeholder="SAP Vendor Code"
              id={"sap_vendor_code"}
              label="SAP Vendor Code"
              value={details.sap_vendor_code}
              changeHandler={this.onChangeHandler}
            />} */}
					</div>
					{(this.props.company_type == PROVIDER_TYPE || this.props.company_type == SURVEYORS_TYPE || this.props.company_type == LASHERS_TYPE || this.props.company_type == HANDLING_AGENT_TYPE) &&
						<div className={'details mb-10'}>
							<SubTitle title="Group Company" />
							{(details.group_company || []).map((gData, gIndex) => {
								return (<div className="sapdiv-parent">
									<div className="sapvendor-div">

										<div className="select-wrap" style={{ width: '50%' }}>
											<div className="label" style={{ width: '100%' }}>Company Name</div>
											<Select
												className="select"
												options={seekersList}
												value={gData.company_id ? seekersList.find(val => { return val.value === gData.company_id }) : null}
												onChange={(selectedOption) => {
													const { details } = this.state;
													details.group_company[gIndex].company_id = selectedOption.value;
													if (AWL_COMPANY_IDS.includes(selectedOption.value)) {
														this.getSeekergroupNames(selectedOption.value);
													}
													this.setState({ details });
												}}
											/>
										</div>
										{AWL_COMPANY_IDS.includes(gData.company_id) &&
											<div className="select-wrap" style={{ width: '100%' }}>
												<div className="label">Group Company Names</div>
												<div className=" groups-div" >
													<Select
														className="selectgrp"
														options={this.props.groupNames}
														getOptionLabel={option => option.group_company_name}
														getOptionValue={option => option.group_company_name}
														value={gData.group_company_name ? this.props.groupNames.find(val => { return val.group_company_name === gData.group_company_name }) : null}
														onChange={(selectedOption) => {
															const { details } = this.state;
															details.group_company[gIndex].group_company_name = selectedOption.group_company_name;
															this.setState({ details });
														}}
													/>
													<div className="button-add mt-1rem" onClick={this.handleOpenModal} style={{ 'minWidth': '43px' }}>+</div>
												</div>
											</div>
										}
										{details.group_company.length > 1 && <div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})`, marginTop: '40px' }} onClick={() => {
											const { details } = this.state;
											details.group_company.splice(gIndex, 1);
											this.setState({ details });
										}}>
										</div>}
									</div>
								</div>)
							})}
							<div className="button-add" onClick={() => {
								const { details } = this.state;
								details.group_company.push({});
								this.setState({
									details
								})
							}}>ADD</div>
						</div>}
					{(this.props.company_type == PROVIDER_TYPE || this.props.company_type == SURVEYORS_TYPE || this.props.company_type == LASHERS_TYPE || this.props.company_type == HANDLING_AGENT_TYPE) &&
						<div className={'details mb-10'}>
							<label className="error">{this.state.isErroMessageSapVendorCode}</label>
							<SubTitle title="SAP Vendor Code Details" />
							{details.sap_vendor_details.map((value, index) => {
								return (<div className="details" key={index}>
									<div className="sapdiv-parent">
										<div className="sapvendor-div">

											<div className="select-wrap">
												<div className="label">Company Name</div>
												<Select
													className="select"
													options={seekersList}
													value={value.company_id ? seekersList.find(val => { return val.value === value.company_id }) : null}
													onChange={(selectedOption) => {
														const { details } = this.state;
														details.sap_vendor_details[index].company = selectedOption.label;
														details.sap_vendor_details[index].company_id = selectedOption.value;
														if(EMAMI_COMPANY_IDS.includes(selectedOption.value)){
															this.getAllEmamiBranches(selectedOption.value)
														}
														else{
															this.getAllSeekerBranch(selectedOption.value);
														}
														if (AWL_COMPANY_IDS.includes(selectedOption.value)) {
															this.getSeekergroupNames(selectedOption.value);
														}
														this.setState({ details, selectedcompanyId: selectedOption.value });
														
													}}
												/>
											</div>
											{(AWL_COMPANY_IDS.includes(details.sap_vendor_details[index].company_id) || CARGILL_COMPANY_IDS.includes(details.sap_vendor_details[index].company_id) || CENTURY_PLY.includes(details.sap_vendor_details[index].company_id)) && this.state.allSeekerBranches && this.state.allSeekerBranches.length > 0 &&
												<div className="select-wrap" >
													<div className="label">Branch Name</div>
													<Select
														className="select-branch"
														options={this.state.allSeekerBranches}
														value={value.branch_id ? value.branch_id : { value: 'All', label: 'All' }}
														onChange={(selectedOption) => {
															const { details } = this.state;
															details.sap_vendor_details[index].branch_id = selectedOption;
															this.setState({ details });
														}}
														isMulti={true}

													/>
												</div>
											}
												{EMAMI_COMPANY_IDS.includes(details.sap_vendor_details[index].company_id)&& this.state.allSeekerBranches && this.state.allSeekerBranches.length > 0 &&
												<div className="select-wrap">
													<div className="label">Branch Name</div>
													<Select
														className="select-branch"
														options={this.state.allEmamiBranches}
														value={value.branch_id ? value.branch_id : { value: 'All', label: 'All' }}
														onChange={(selectedOption) => {
															const { details } = this.state;
															details.sap_vendor_details[index].branch_id = selectedOption;
															this.setState({ details });
														}}
														isMulti={true}

													/>
												</div>
											}
											{details.sap_vendor_details[index].company_id &&
												<InputText
													placeholder="SAP Vendor Code"
													id={"sap_vendor_code"}
													label="SAP Vendor Code"
													value={value.sap_vendor_code}
													changeHandler={(value) => {
														const { details } = this.state;
														details.sap_vendor_details[index].sap_vendor_code = value;
														this.setState({ details });
													}}
													required />
											}
											{
												EMAMI_COMPANY_IDS.includes(details.sap_vendor_details[index].company_id)  ? (
													<div className="select-wrap">
														<div className="label">Payment Terms</div>
														<Select
															className="select-branch"
															options={this.state.emamiPaymentTermsList}
															value={value.payment_terms ? emamiPaymentTermsList.find(val => { return val.short_name === value.payment_terms }) : null}
															// value={value.payment_terms}
															onChange={(selectedOption) => {
																const { details } = this.state;
																details.sap_vendor_details[index].payment_terms = selectedOption;
																this.setState({ details, emamiPaymentTerm: selectedOption });
															}}
														// onChange={(value)=>this.onEPaymentTermChange(value)}
														/>
													</div>
												) : (
													<>
														{details.sap_vendor_details[index].company_id && details.sap_vendor_details[index].hasOwnProperty('payment_terms') &&
															<InputText
																placeholder="Payment Terms"
																id={"payment_terms"}
																label="Payment Terms"
																value={value.payment_terms}
																changeHandler={(value) => {
																	const { details } = this.state;
																	details.sap_vendor_details[index].payment_terms = value;
																	this.setState({ details });
																}}
															/>
														}
													</>

												)
											}


											{details.sap_vendor_details[index].company_id &&
												<InputText
													placeholder="GST IN"
													id={"gstin"}
													label="GST IN."
													value={value.gstin}
													changeHandler={(value) => {
														const { details } = this.state;
														details.sap_vendor_details[index].gstin = value;
														this.setState({ details });
													}}
												// length={'10'}
												// isValidate={'numeric'}
												// additionalProps={{ onBlur: this.onBlurChange }}
												// iserror={this.state.iserror}
												// errormessage={this.state.errormessage}
												/>
											}
												{/* {details.sap_vendor_details[index].company_id && */}
												<InputText
													placeholder="Address/ZipCode/State"
													id={"branch-address"}
													label="Branch Address"
													value={value.branch_address}
													changeHandler={(value) => {
														const { details } = this.state;
														details.sap_vendor_details[index].branch_address = value;
														this.setState({ details });
													}}
												/>
											{/* // } */}
											{/* {AWL_COMPANY_IDS.includes(this.state.selectedcompanyId)&& */}
											{/* {AWL_COMPANY_IDS.includes(value.company_id) && 
								<div className="group-tab">
											<div className="label">Group Company Names</div>
											<div className=" groups-div" >
														<Select
															className="selectgrp"
															options={this.props.groupNames}
															getOptionLabel={option => option.group_company_name}
															getOptionValue={option => option.group_company_name}
															value={value.group_company_name ? this.props.groupNames.find(val => { return val.group_company_name === value.group_company_name }) : null}
															onChange={(selectedOption) => {
																const { details } = this.state;
																details.sap_vendor_details[index].group_company_name = selectedOption.group_company_name;
																this.setState({ details });
															}}
														/>
										<div className="button-add mt-1rem" onClick={this.handleOpenModal}style={{ 'minWidth': '43px' }}>
											+
										</div>
										</div>	
								</div>
								} */}

										</div>
										<div className="button-add close" onClick={() => this.onDeleteSapVendor(index)} style={{ 'minWidth': '43px' }}>
											X
										</div>
									</div>

									{isModalOpen && (
										<AddGroup
											group_company_name={group_company_name}
											handleSaveModal={this.handleSaveModal}
											handleCloseModal={this.handleCloseModal}
											company_id={value.company_id}
											handleOnChangeForGroupCompanyName={this.handleOnChangeForGroupCompanyName}
										/>

									)}

									{details.sap_vendor_details[index].company_id && DEEPAK_COMPANY_ID.includes(details.sap_vendor_details[index].company_id) &&
										<InputText
											placeholder="SAP Carrier Code"
											id={"sap_carrier_code"}
											label="SAP Carrier Code"
											value={value.sap_carrier_code}
											changeHandler={(value) => {
												const { details } = this.state;
												details.sap_vendor_details[index].sap_carrier_code = value;
												this.setState({ details });
											}}
											required />
									}

								</div>)
							})
							}
							<div className="button-add" onClick={this.onAddSapVendor}>
								ADD
							</div>
						</div>}

					{(this.props.company_type == PROVIDER_TYPE || this.props.company_type == SURVEYORS_TYPE || this.props.company_type == LASHERS_TYPE || this.props.company_type == HANDLING_AGENT_TYPE) &&
						<div className="documents-upload-section">
							<div className="file-lable-section">
								<div className="file-upload-details">
									<div className="desc">
										Upload Your Documents
									</div>
									<div className="title">PAN Card</div>
									<div className="file-holder">
										<input type="file" id="pancardDocfile" name="file-upload-button" onChange={e => this.multipleFilesUpload(e, "pancardDocfile")} multiple />
										<span className="file-choose" onClick={this.pancardDocClicked}>
											Choose File(s)
										</span>
										<span className="file-name">
											{(this.state.fileNames !== undefined && this.state.fileNames[0].fileObject !== "" && this.state.fileNames[0].name !== "")
												? "Files added"
												: "No File Chosen"}
										</span>
									</div>
								</div>
								{/* <div className="generate-button" onClick={this.uploadVendorDocs}>
									Upload
              					</div> */}
							</div>
							<div className="file-lable-section">
								{
									(this.state.vendorDocuments !== undefined && this.state.vendorDocuments.length > 0) ?
										this.state.vendorDocuments.map((value, index) => {
											return (
												(value.fileId === PAN_CARD_DOC) &&
												<div key={index} className="attachment-container-row">
													<div className={'attachment-file-icon'} style={{ backgroundImage: `url(${Attachment})` }}></div>
													<div className="attachment-name">
														<a href={value.url} download>
															{value.name}
														</a>
														<div className={details.company_logo ? "company-icon img" : "company-icon"}
															style={{ backgroundImage: `url(${details.company_logo})` }}
														/>
													</div>

													<div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={this.deleteFile.bind(this, value)} ></div>
												</div>
											)

										})
										:
										<div></div>
								}
							</div>
							<div className="file-lable-section">

								{
									(this.state.fileNames !== undefined && this.state.fileNames.length > 0) ?
										this.state.fileNames.map((value, index) => {

											return (
												(value.isPanCardDoc === true) &&
												<div key={index} className="attachment-container-row">
													<div className={'attachment-file-icon'} style={{ backgroundImage: `url(${Attachment})` }}></div>
													<div className="attachment-name">{value.name}
														<div className={details.company_logo ? "company-icon img" : "company-icon"}
															style={{ backgroundImage: `url(${details.company_logo})` }}
														/>
													</div>

													<div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={this.removeDocument.bind(this, index)}></div>
												</div>
											)
										})
										:
										<div></div>
								}
							</div>

							<div className="file-lable-section" style={{ 'display': 'flex' }}>
								<div className="file-upload-details">
									<div className="title" style={{ 'width': '100%' }}>Bank Detials And Cancelled Cheque</div>
									<div className="file-holder">
										<input type="file" id="bankDetailsAndCancelledChecqueDocfile" name="file-upload-button" onChange={e => this.multipleFilesUpload(e, "bankDetailsAndCancelledChecqueDocfile")} multiple />
										<span className="file-choose" onClick={this.bankDetailsAndCancelledChecqueDocClicked}>
											Choose File(s)
										</span>
										<span className="file-name">
											{(this.state.fileNames !== undefined && this.state.fileNames[0].fileObject !== "" && this.state.fileNames[0].name !== "")
												? "Files added"
												: "No File Chosen"}
										</span>
									</div>
								</div>
								<div style={{ 'width': '200px', 'marginLeft': '20px' }}>
									<div className="title">Bank Name<span className={'error-color fs-14'}>*</span></div>
									<input type="text" value={details.bank_name} onChange={(e) => this.onChangeHandler(e.target.value, "bank_name", "alphanumeric")} />
								</div>
								<div style={{ 'width': '200px', 'marginLeft': '20px' }}>
									<div className="title">IFSC Code<span className={'error-color fs-14'}>*</span></div>
									<input type="text" value={details.bank_ifsc_code} onChange={(e) => this.onChangeHandler(e.target.value, "bank_ifsc_code", "alphanumeric")} />
									{(details.bank_ifsc_code && details.bank_ifsc_code.length < 11) && <div className={"error-color fs-14 tCenter"}>IFSC Code length should be 11</div>}
								</div>
								<div style={{ 'width': '200px', 'marginLeft': '20px' }}>
									<div className="title">Account No.<span className={'error-color fs-14'}>*</span></div>
									<input type="text" value={details.bank_account_no} onChange={(e) => this.onChangeHandler(e.target.value, "bank_account_no", "alphanumeric")} maxLength={17}/>
								</div>
							</div>
							{(this.state.isUploadFieldsError && !(details.bank_name && details.bank_ifsc_code && details.bank_account_no)) && <div className="error-color fs-14 tCenter">{'Please Enter the Bank Details'}</div>}
							<div className="file-lable-section">

								{
									(this.state.vendorDocuments !== undefined && this.state.vendorDocuments.length > 0) ?
										this.state.vendorDocuments.map((value, index) => {
											return (
												(value.fileId === BANK_DETAILS_DOC) &&
												<div key={index} className="attachment-container-row">
													<div className={'attachment-file-icon'} style={{ backgroundImage: `url(${Attachment})` }}></div>
													<div className="attachment-name">
														<a href={value.url} download>
															{value.name}
														</a>
														<div className={details.company_logo ? "company-icon img" : "company-icon"}
															style={{ backgroundImage: `url(${details.company_logo})` }}
														/>
													</div>

													<div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={this.deleteFile.bind(this, value)} ></div>
												</div>
											)

										})
										:
										<div></div>
								}
							</div>
							<div className="file-lable-section">

								{
									(this.state.fileNames !== undefined && this.state.fileNames.length > 0) ?
										this.state.fileNames.map((value, index) => {

											return (
												(value.isBankDetailsDoc === true) &&
												<div key={index} className="attachment-container-row">
													<div className={'attachment-file-icon'} style={{ backgroundImage: `url(${Attachment})` }}></div>
													<div className="attachment-name">{value.name}
														<div className={details.company_logo ? "company-icon img" : "company-icon"}
															style={{ backgroundImage: `url(${details.company_logo})` }}
														/>
													</div>

													<div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={this.removeDocument.bind(this, index)}></div>
												</div>
											)
										})
										:
										<div></div>
								}
							</div>


							<div className="file-lable-section" style={{ 'display': 'flex' }}>
								<div className="file-upload-details">
									<div className="title">Memorandam Of Association</div>
									<div className="file-holder">
										<input type="file" id="memorandamOfAssociationDocfile" name="file-upload-button" onChange={e => this.multipleFilesUpload(e, "memorandamOfAssociationDocfile")} multiple />
										<span className="file-choose" onClick={this.memorandamOfAssociationDocClicked}>
											Choose File(s)
										</span>
										<span className="file-name">
											{(this.state.fileNames !== undefined && this.state.fileNames[0].fileObject !== "" && this.state.fileNames[0].name !== "")
												? "Files added"
												: "No File Chosen"}
										</span>
									</div>
								</div>
								<div style={{ 'marginLeft': '20px' }} className="single-date-input">
									<div className="title">Incorporation Date<span className={'error-color fs-14'}>*</span></div>
									<SingleDatePicker
										noBorder={true}
										displayFormat={"DD MMM YYYY"}
										hideKeyboardShortcutsPanel={true}
										placeholder={"Date"}
										numberOfMonths={1}
										date={details.incorporation_date && Moment(details.incorporation_date) || null}
										onDateChange={(from) => {
											const { details } = this.state;
											const date = from && from.format('YYYY-MM-DD HH:mm') || null
											details.incorporation_date = date && new Date(date).getTime() || null;
											this.setState({ details });
										}}
										focused={this.state.focusedStart}
										onFocusChange={({ focused }) => this.setState({ focusedStart: focused })}
										isOutsideRange={(date) => date > new Date()}
									/>
								</div>
							</div>
							{(this.state.isUploadFieldsError && !details.incorporation_date) && <div className={'error-color fs-14 tCenter'}>{'Please Enter the Memorandam Of Association Details'}</div>}
							<div className="file-lable-section">

								{
									(this.state.vendorDocuments !== undefined && this.state.vendorDocuments.length > 0) ?
										this.state.vendorDocuments.map((value, index) => {
											return (
												(value.fileId === MEMO_ASSOCIATION_DOC) &&
												<div key={index} className="attachment-container-row">
													<div className={'attachment-file-icon'} style={{ backgroundImage: `url(${Attachment})` }}></div>
													<div className="attachment-name">
														<a href={value.url} download>
															{value.name}
														</a>
														<div className={details.company_logo ? "company-icon img" : "company-icon"}
															style={{ backgroundImage: `url(${details.company_logo})` }}
														/>
													</div>

													<div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={this.deleteFile.bind(this, value)}></div>
												</div>
											)

										})
										:
										<div></div>
								}
							</div>
							<div className="file-lable-section">

								{
									(this.state.fileNames !== undefined && this.state.fileNames.length > 0) ?
										this.state.fileNames.map((value, index) => {

											return (
												(value.isMemoAssociationDoc === true) &&
												<div key={index} className="attachment-container-row">
													<div className={'attachment-file-icon'} style={{ backgroundImage: `url(${Attachment})` }}></div>
													<div className="attachment-name">{value.name}
														<div className={details.company_logo ? "company-icon img" : "company-icon"}
															style={{ backgroundImage: `url(${details.company_logo})` }}
														/>
													</div>

													<div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={this.removeDocument.bind(this, index)}></div>
												</div>
											)
										})
										:
										<div></div>
								}
							</div>


							<div className="file-lable-section">
								<div className="file-upload-details">
									<div className="title">Article For Association</div>
									<div className="file-holder">
										<input type="file" id="articleForAssociationDocfile" name="file-upload-button" onChange={e => this.multipleFilesUpload(e, "articleForAssociationDocfile")} multiple />
										<span className="file-choose" onClick={this.articleForAssociationDocClicked}>
											Choose File(s)
										</span>
										<span className="file-name">
											{(this.state.fileNames !== undefined && this.state.fileNames[0].fileObject !== "" && this.state.fileNames[0].name !== "")
												? "Files added"
												: "No File Chosen"}
										</span>
									</div>
								</div>
							</div>
							<div className="file-lable-section">

								{
									(this.state.vendorDocuments !== undefined && this.state.vendorDocuments.length > 0) ?
										this.state.vendorDocuments.map((value, index) => {
											return (
												(value.fileId === ARTICLE_ASSOCIATION_DOC) &&
												<div key={index} className="attachment-container-row">
													<div className={'attachment-file-icon'} style={{ backgroundImage: `url(${Attachment})` }}></div>
													<div className="attachment-name">
														<a href={value.url} download>
															{value.name}
														</a>
														<div className={details.company_logo ? "company-icon img" : "company-icon"}
															style={{ backgroundImage: `url(${details.company_logo})` }}
														/>
													</div>

													<div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={this.deleteFile.bind(this, value)}></div>
												</div>
											)

										})
										:
										<div></div>
								}
							</div>
							<div className="file-lable-section">

								{
									(this.state.fileNames !== undefined && this.state.fileNames.length > 0) ?
										this.state.fileNames.map((value, index) => {

											return (
												(value.isArticleAssociationDoc === true) &&
												<div key={index} className="attachment-container-row">
													<div className={'attachment-file-icon'} style={{ backgroundImage: `url(${Attachment})` }}></div>
													<div className="attachment-name">{value.name}
														<div className={details.company_logo ? "company-icon img" : "company-icon"}
															style={{ backgroundImage: `url(${details.company_logo})` }}
														/>
													</div>

													<div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={this.removeDocument.bind(this, index)}></div>
												</div>
											)
										})
										:
										<div></div>
								}
							</div>


							<div className="file-lable-section">
								<div className="file-upload-details">
									<div className="title">Compliance Declaration Form</div>
									<div className="file-holder">
										<input type="file" id="complianceDeclarationDocfile" name="file-upload-button" onChange={e => this.multipleFilesUpload(e, "complianceDeclarationDocfile")} multiple />
										<span className="file-choose" onClick={this.complianceDeclarationDocClicked}>
											Choose File(s)
										</span>
										<span className="file-name">
											{(this.state.fileNames !== undefined && this.state.fileNames[0].fileObject !== "" && this.state.fileNames[0].name !== "")
												? "Files Added"
												: "No File Chosen"}
										</span>
									</div>
								</div>
							</div>
							<div className="file-lable-section">

								{
									(this.state.vendorDocuments !== undefined && this.state.vendorDocuments.length > 0) ?
										this.state.vendorDocuments.map((value, index) => {
											return (
												(value.fileId === COMPLIANCE_DEC_DOC) &&
												<div key={index} className="attachment-container-row">
													<div className={'attachment-file-icon'} style={{ backgroundImage: `url(${Attachment})` }}></div>
													<div className="attachment-name">
														<a href={value.url} download>
															{value.name}
														</a>
														<div className={details.company_logo ? "company-icon img" : "company-icon"}
															style={{ backgroundImage: `url(${details.company_logo})` }}
														/>
													</div>

													<div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={this.deleteFile.bind(this, value)}></div>
												</div>
											)

										})
										:
										<div></div>
								}
							</div>
							<div className="file-lable-section">

								{
									(this.state.fileNames !== undefined && this.state.fileNames.length > 0) ?
										this.state.fileNames.map((value, index) => {

											return (
												(value.isComplianceDecDoc === true) &&
												<div key={index} className="attachment-container-row">
													<div className={'attachment-file-icon'} style={{ backgroundImage: `url(${Attachment})` }}></div>
													<div className="attachment-name">{value.name}
														<div className={details.company_logo ? "company-icon img" : "company-icon"}
															style={{ backgroundImage: `url(${details.company_logo})` }}
														/>
													</div>

													<div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={this.removeDocument.bind(this, index)}></div>
												</div>
											)
										})
										:
										<div></div>
								}
							</div>

							<div className="file-lable-section">
								<div className="file-upload-details">
									<div className="title">GST Certificates</div>
									<div className="file-holder">
										<input type="file" id="gstCertificatesDocfile" name="file-upload-button" onChange={e => this.multipleFilesUpload(e, "gstCertificatesDocfile")} multiple />
										<span className="file-choose" onClick={this.gstCertificatesDocClicked}>
											Choose File(s)
										</span>
										<span className="file-name">
											{(this.state.fileNames !== undefined && this.state.fileNames[0].fileObject !== "" && this.state.fileNames[0].name !== "")
												? "Files added"
												: "No File Chosen"}
										</span>
									</div>
								</div>
							</div>
							<div className="file-lable-section">

								{
									(this.state.vendorDocuments !== undefined && this.state.vendorDocuments.length > 0) ?
										this.state.vendorDocuments.map((value, index) => {
											return (
												(value.fileId === GST_CERT_DOC) &&
												<div key={index} className="attachment-container-row">
													<div className={'attachment-file-icon'} style={{ backgroundImage: `url(${Attachment})` }}></div>
													<div className="attachment-name">
														<a href={value.url} download>
															{value.name}
														</a>
														<div className={details.company_logo ? "company-icon img" : "company-icon"}
															style={{ backgroundImage: `url(${details.company_logo})` }}
														/>
													</div>

													<div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={this.deleteFile.bind(this, value)}></div>
												</div>
											)

										})
										:
										<div></div>
								}
							</div>
							<div className="file-lable-section">

								{
									(this.state.fileNames !== undefined && this.state.fileNames.length > 0) ?
										this.state.fileNames.map((value, index) => {

											return (
												(value.isGSTCertDoc === true) &&
												<div key={index} className="attachment-container-row">
													<div className={'attachment-file-icon'} style={{ backgroundImage: `url(${Attachment})` }}></div>
													<div className="attachment-name">{value.name}
														<div className={details.company_logo ? "company-icon img" : "company-icon"}
															style={{ backgroundImage: `url(${details.company_logo})` }}
														/>
													</div>

													<div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={this.removeDocument.bind(this, index)}></div>
												</div>
											)
										})
										:
										<div></div>
								}
							</div>
							<div className="file-lable-section" style={{ 'display': 'flex' }}>
								<div className="file-upload-details">
									<div className="title" style={{ 'width': '100%' }}>FSSAI</div>
									<div className="file-holder">
										<input type="file" id="fssaiDocfile" name="file-upload-button" onChange={e => this.multipleFilesUpload(e, "fssaiDocfile")} multiple />
										<span className="file-choose" onClick={this.fssaiDocClicked}>
											Choose File(s)
										</span>
										<span className="file-name">
											{(this.state.fileNames !== undefined && this.state.fileNames[0].fileObject !== "" && this.state.fileNames[0].name !== "")
												? "Files added"
												: "No File Chosen"}
										</span>
									</div>
								</div>
								<div style={{ 'width': '200px', 'marginLeft': '20px' }}>
									<div className="title" style={{ 'width': '100%' }}>FSSAI Certificate Number<span className={'error-color fs-14'}>*</span></div>
									<input type="text" value={details.fssai_certificate_number || ''} onChange={(e) => this.onChangeHandler(e.target.value, "fssai_certificate_number", "alphanumeric")} maxLength={14} />
								</div>
								<div style={{ 'width': '200px', 'marginLeft': '20px' }} className="single-date-input">
									<div className="title">Expiry Date<span className={'error-color fs-14'}>*</span></div>
									<SingleDatePicker
										noBorder={true}
										displayFormat={"DD MMM YYYY"}
										hideKeyboardShortcutsPanel={true}
										placeholder={"Date"}
										numberOfMonths={1}
										date={details.fssai_validity_date && Moment(details.fssai_validity_date) || null}
										onDateChange={(from) => {
											const { details } = this.state;
											const date = from && from.format('YYYY-MM-DD HH:mm') || null
											details.fssai_validity_date = date && new Date(date).getTime() || null;
											this.setState({ details });
										}}
										focused={this.state.focusedFssaiStart}
										onFocusChange={({ focused }) => this.setState({ focusedFssaiStart: focused })}
									// isOutsideRange={() => true}
									/>
								</div>
							</div>
							<div className="file-lable-section">

								{
									(this.state.vendorDocuments !== undefined && this.state.vendorDocuments.length > 0) ?
										this.state.vendorDocuments.map((value, index) => {
											return (
												(value.fileId === FSSAI_DOC) &&
												<div key={index} className="attachment-container-row">
													<div className={'attachment-file-icon'} style={{ backgroundImage: `url(${Attachment})` }}></div>
													<div className="attachment-name">
														<a href={value.url} download>
															{value.name}
														</a>
														<div className={details.company_logo ? "company-icon img" : "company-icon"}
															style={{ backgroundImage: `url(${details.company_logo})` }}
														/>
													</div>

													<div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={this.deleteFile.bind(this, value)} ></div>
												</div>
											)

										})
										:
										<div></div>
								}
							</div>
							{CARGILL_COMPANY_IDS.includes(getUserCompanyId()) && <>
								<div className="file-lable-section" style={{ 'display': 'flex' }}>
									<div className="file-upload-details">
										<div className="title" style={{ 'width': '100%' }}>MSA Document</div>
										<div className="file-holder">
											<input type="file" id="msaDocfile" name="file-upload-button" onChange={e => this.multipleFilesUpload(e, "msaDocfile")} multiple />
											<span className="file-choose" onClick={this.msaDocClicked}>
												Choose File(s)
											</span>
											<span className="file-name">
												{(this.state.fileNames !== undefined && this.state.fileNames[0].fileObject !== "" && this.state.fileNames[0].name !== "")
													? "Files added"
													: "No File Chosen"}
											</span>
										</div>
									</div>
									<div style={{ 'width': '200px', 'marginLeft': '20px' }} className="single-date-input">
										<div className="title">Expiry Date<span className={'error-color fs-14'}>*</span></div>
										<SingleDatePicker
											noBorder={true}
											displayFormat={"DD MMM YYYY"}
											hideKeyboardShortcutsPanel={true}
											placeholder={"Date"}
											numberOfMonths={1}
											date={details.msa_validity_date && Moment(details.msa_validity_date) || null}
											onDateChange={(from) => {
												const { details } = this.state;
												const date = from && from.format('YYYY-MM-DD HH:mm') || null
												details.msa_validity_date = date && new Date(date).getTime() || null;
												this.setState({ details });
											}}
											focused={this.state.focusedMSAStart}
											onFocusChange={({ focused }) => this.setState({ focusedMSAStart: focused })}
										// isOutsideRange={() => true}
										/>
									</div>
								</div>
							</>}
							{(this.state.isUploadFieldsError && !(details.msa_validity_date)) && <div className={"error-color fs-14 tCenter"}>Please Enter the MSA Details</div>}
							<div className="file-lable-section">

								{
									(this.state.vendorDocuments !== undefined && this.state.vendorDocuments.length > 0) ?
										this.state.vendorDocuments.map((value, index) => {
											return (
												(value.fileId === MSA_DOC) &&
												<div key={index} className="attachment-container-row">
													<div className={'attachment-file-icon'} style={{ backgroundImage: `url(${Attachment})` }}></div>
													<div className="attachment-name">
														<a href={value.url} download>
															{value.name}
														</a>
														<div className={details.company_logo ? "company-icon img" : "company-icon"}
															style={{ backgroundImage: `url(${details.company_logo})` }}
														/>
													</div>

													<div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={this.deleteFile.bind(this, value)} ></div>
												</div>
											)

										})
										:
										<div></div>
								}
							</div>
							<div className="file-lable-section">

								{
									(this.state.fileNames !== undefined && this.state.fileNames.length > 0) ?
										this.state.fileNames.map((value, index) => {

											return (
												(value.isfssaiDoc === true) &&
												<div key={index} className="attachment-container-row">
													<div className={'attachment-file-icon'} style={{ backgroundImage: `url(${Attachment})` }}></div>
													<div className="attachment-name">{value.name}
														<div className={details.company_logo ? "company-icon img" : "company-icon"}
															style={{ backgroundImage: `url(${details.company_logo})` }}
														/>
													</div>

													<div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={this.removeDocument.bind(this, index)}></div>
												</div>
											)
										})
										:
										<div></div>
								}
							</div>
							<div className="file-lable-section">

								{
									(this.state.fileNames !== undefined && this.state.fileNames.length > 0) ?
										this.state.fileNames.map((value, index) => {

											return (
												(value.isMSADoc === true) &&
												<div key={index} className="attachment-container-row">
													<div className={'attachment-file-icon'} style={{ backgroundImage: `url(${Attachment})` }}></div>
													<div className="attachment-name">{value.name}
														<div className={details.company_logo ? "company-icon img" : "company-icon"}
															style={{ backgroundImage: `url(${details.company_logo})` }}
														/>
													</div>

													<div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={this.removeDocument.bind(this, index)}></div>
												</div>
											)
										})
										:
										<div></div>
								}
							</div>
							<div className="tCenter">
								<div className="generate-button" onClick={this.uploadVendorDocs}>
									Upload
								</div>
							</div>
						</div>
					}

					<div className="file-upload-section">
						<div className="file-lable-section">
							<div
								className={
									details.company_logo ? "company-icon img" : "company-icon"
								}
								style={{ backgroundImage: `url(${details.company_logo})` }}
							/>
							<div className="file-upload-details">
								<div className="title">Company Logo</div>
								<div className="desc">
									Upload Your Logo In jpg., .gif, .png Format
								</div>
								<div className="file-holder">
									<input id="file" type="file" onChange={this.fileUploaded} />
									<span className="file-choose" onClick={this.fileClicked}>
										Choose File
									</span>
									<span className="file-name">
										{this.state.fileName
											? this.state.fileName.name
											: "No File Chosen"}
									</span>
								</div>
							</div>
							{this.props && !this.props.isTransporter && <div className="generate-button" onClick={this.submitCompanyImg}>
								Upload
							</div>}
						</div>
					</div>
					<div>
						<SubTitle title="Tax Details" />
						<div className="details">
							<InputText
								placeholder="PAN No"
								readonly={true}
								id={"pan_no"}
								label="PAN No"
								value={details.pan_no}
								changeHandler={this.onChangeHandler}
							/>
							{/* <InputText
                placeholder="GSTN"
                id={"gstin"}
                label="gstin"
                value={details.gstin}
                changeHandler={this.onChangeHandler}
                length={'15'}
                additionalProps={{onBlur : this.onBlurChange}}
                iserror={this.state.iserror}
                errormessage={this.state.errormessage}
              /> */}
							<div className="fiscal-section">
								<div className={'rupee-icon'} style={{ backgroundImage: `url(${Rupee})` }}></div>
								<InputText
									placeholder="Last Fiscal Year Turnover"
									id={"last_fiscal_turnover_value"}
									label="Last Fiscal Year Turnover"
									value={details.last_fiscal_turnover_value}
									changeHandler={this.onChangeHandler}
									isValidate={'numeric'}
								/>
								<div className="select-wrap">
									<Select
										className="select"
										value={this.state.last_fiscal_turnover}
										onChange={this.handleChange.bind(
											this,
											"last_fiscal_turnover"
										)}
										options={optionsTurnOver}
									/>
								</div>
							</div>
						</div>
						<div className="details">
							<div className="select-wrap">
								<div className="label">Tax Id</div>
								<Select
									className="select"
									value={this.state.tax_id}
									onChange={this.handleChange.bind(this, "tax_id")}
									options={optionsTaxId}
								/>
							</div>
							<InputText
								placeholder="Tax no"
								id={"tax_no"}
								label="Tax No"
								value={details.tax_no}
								changeHandler={this.onChangeHandler}
								length={'12'}
								isValidate={'alphanumeric'}
								additionalProps={{ onBlur: this.onBlurChange }}
								iserror={this.state.iserror}
								errormessage={this.state.errormessage}
							/>
						</div>

						{this.props.company_type == PROVIDER_TYPE &&
							<div className="details">
							<div className="select-wrap">
								<div className="label">Intra Tax-code (Within State)</div>
								<Select
                        className="select"
                        value={this.props.intra_po_taxcode}
                        onChange={(data)=>{this.props.handlePOtaxCodeChange(data,'is_intra')}}
                        options={this.props.intra_typecodeOptionsList}
                      />
							</div>
							<div className="select-wrap">
								<div className="label">Inter Tax-code (Outside State)</div>
								<Select
                      className="select"
                      value={this.props.inter_po_taxcode}
                      onChange={(data)=>{this.props.handlePOtaxCodeChange(data ,'is_inter')}}
                      options={this.props.inter_typecodeOptionsList}
                      />
							</div>
						</div>}

						
					</div>

					<div className="">
						<SubTitle title={'GSTIN Details'} />
						<div className="head active mt-10">GST Applicable
							<Checkbox type={this.props.company_type} status={details.is_gst_applicable} disabled={false} user={details.admin_name} id={details.id || '1'} handleChange={(e) => {
								const { details } = this.state;
								details.is_gst_applicable = !details.is_gst_applicable;
								this.setState({ details })
							}} />
						</div>

						{details.gstin_details.map((value, index) => {
							return (<div className="details flex space-between" key={index} >
								<div className="select-wrap-w-25">
									{/* {this.props.company_type == 1 &&  <span className="label-mb-0">Vendor Code</span>}
									{this.props.company_type == 1 && <div className="mt-0 mb-10 p-5">{ details.sap_vendor_code}test</div>} */}
									<div className="label ">State</div>
									<Select
										className="select p-5"
										options={stateList}
										value={(stateList.find(val => { return val.value === value.code })) || (stateList.find(val => { return val.label === value.state }))}
										onChange={(selectedOption) => {
											const { details } = this.state;
											details.gstin_details[index].state = selectedOption.label;
											details.gstin_details[index].code = selectedOption.value;
											details.gstin_details[index].gstin = selectedOption.gstCode + details.pan_no;
											this.setState({ details });
										}}
									/>
								</div>
								<div className="fiscal-section p-5" style={{ width: '24%' }}>
									<InputText
										placeholder="GSTIN"
										id={"gstin"}
										label="GSTIN"
										value={value.gstin}
										changeHandler={(value) => {
											const { details } = this.state;
											details.gstin_details[index].gstin = value;
											if (value.length === 2 || value.length === 1) {
												stateList.map((state) => {
													if (state.gstCode === parseInt(value)) {
														details.gstin_details[index].state = state.label;
														details.gstin_details[index].code = state.value;
													}
												})
											}
											this.setState({ details });
										}}
										length={15}

									/>
									{details.gstin_details[index].gstin && details.gstin_details[index].gstin.length !== 15 ?
										<div className="error error-font">{'Please Enter Complete GSTIN'}</div> : ''}
								</div>
								<div className="select-wrap-w-25">
									<div className="label ">GST Mechanism</div>
									<Select
										className="select p-5 wt-100"
										value={[{ value: 1, label: 'FCM' }, { value: 2, label: 'RCM' }].find(val => { return val.label === value.gst_mechanism })}
										onChange={(selectedOption) => {
											const { details } = this.state;
											details.gstin_details[index].gst_mechanism = selectedOption.label;
											details.gstin_details[index].gst_mechanism_id = selectedOption.value;
											this.setState({ details });
										}}
										options={[{ value: 1, label: 'FCM' }, { value: 2, label: 'RCM' }]}
									/>
									{details.gstin_details[index].gst_mechanism === "" ? <div className="error error-font">{'Please Enter GST Mechanism'}</div> : ''}

								</div>
								<div className="select-wrap-w-25">
									<div className="label ">GST (%)</div>
									<Select
										className="select p-5 wt-100"
										value={optionsGSTPercentage.find(val => { return val.label === value.gst_percentage })}
										onChange={(selectedOption) => {
											const { details } = this.state;
											details.gstin_details[index].gst_percentage = selectedOption.label;
											this.setState({ details });
										}}
										options={optionsGSTPercentage}
									/>
									{details.gstin_details[index].gst_percentage === "" ? <div className="error error-font">{'Please Enter GST Percentage'}</div> : ''}

								</div>

								{this.props.company_type == 2 && <div className="label mr-20">
									<span className="label-mb-0 label-inline">Vendor Code</span><div className="mb-5"></div>
									<span className="fiscal-section p-5" style={{ width: '24%' }}>
										<InputText
											placeholder="Vendor Code"
											id={"vendor_code"}
											value={value.vendor_code}
											changeHandler={(value) => {
												const { details } = this.state;
												details.gstin_details[index].vendor_code = value;
												this.setState({ details });
											}}
										/></span>
								</div>}

								{/* {this.props.company_type == 2 &&  <div className="label">
									<span className="label-mb-0 label-inline">GST Percentage</span><div className="mb-5"></div>
									<span className="mt-0 mb-10 p-5 pl-0 font-1rem border-bottom pb-13px">{ details.gstin_details[index].gst_percentage ? details.gstin_details[index].gst_percentage : <span className="label-inline font-1rem">*To be auto-fetched</span>  } </span>
								</div>} */}
								<div className="button-add mt-1rem" onClick={() => this.onDeleteGSTIN(index)} style={{ 'minWidth': '43px' }}>
									X
								</div>

							</div>)
						})
						}
						{this.props && !this.props.isTransporter && <div className="button-add" onClick={this.onAddGSTIN}>
							ADD
						</div>}
					</div>

					<div>
						<SubTitle title="Establishment Details" />
						<div className="details">
							<div className="select-wrap">
								<div className="label">Establishment Category</div>
								<Select
									className="select"
									value={this.state.establishment_category}
									onChange={this.handleChange.bind(
										this,
										"establishment_category"
									)}
									options={optionsEstDetails}
								/>
							</div>
							{this.props.company_type != 2 && <div className="select-wrap">
								<div className="label">Business Segment</div>
								<Select
									className="select"
									value={this.state.business_segment}
									onChange={this.handleChange.bind(this, "business_segment")}
									options={optionsBusinessSeg}
								/>
							</div>}
							<InputText
								placeholder="Year Of Registration"
								id={"year_of_formation"}
								label="Year Of Registration"
								value={details.year_of_formation}
								changeHandler={this.onChangeHandler}
								isValidate={'numeric'}
								length={'4'}
							/>
						</div>
					</div>
				</div>

				{(this.props && (!this.props.isTransporter || (this.props.company_type == SURVEYORS_TYPE || this.props.company_type == LASHERS_TYPE || this.props.company_type == HANDLING_AGENT_TYPE)) && !((userInfo || {}).access_depo_disposal)) && <Button value="Update Details" click={this.updateBasicDetails} />}
				{(this.props.company_type != 1) &&
					<ProviderUsers company_name={this.props.details && this.props.details.company_name} company_id={this.props.details && this.props.details.id} providersUsersList={this.props.providersUsersList} getProviderUsers={this.props.getProviderUsers} fetchAllSeekers={this.props.fetchAllSeekers} seekersList={this.props.seekersList}
						addSeekerUser={this.props.addSeekerUser} editSeeker={this.props.editSeeker} fetchBranches={this.props.fetchBranches} branchList={this.props.branchList}
						getDepartments={this.props.getDepartments} departments={this.props.departments} getAllBranch={this.props.getAllBranch} allPlantBranches={this.props.allPlantBranches} downloadUserDeatils={this.props.downloadUserDeatils} uploadProviderUserFile={this.props.uploadProviderUserFile} companyType={this.props.company_type}
						companyDetails={this.props.details}
						modeOfTransportList={this.props.modeOfTransportList}
					/>}
			</div>
		);
	}
}
const CustomCheckBox = (props) => {
	return (
		<div className={props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick={() => props.toggle(props.id)}>
			{props.selected && <div className="inner-circle"></div>}
		</div>
	)
}
const SubTitle = props => {
	return (
		<div className="sub-title-section-wrap">
			<div className="circle" />
			<div className="text">{props.title}</div>
			<div className="underline" />
		</div>
	);
};

export default connect()(BasicDetails);

