import React, { Component} from 'react';
import {connect} from 'react-redux';

class Contract extends Component {
	constructor(props){
		super(props);
	}
	componentDidMount(){
		const { dispatch } = this.props;
	}
	render() {
		return (
			<div className = "root">
                <div className = "banner">Contract</div>
			</div>
		);
	}
}

  
export default Contract;