import React, { Component } from "react";
import InputText from "../../../../common/components/input-text/input-text";
import Select from "react-select";
import validate from "../../../../../utils/validate";
import "../../home-navigation-panel.less";

class AddMove extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      company_id: "",
      move_id: null,
      selectCompany: null,
      error: "",
      iserror: "",
      errormessage: "",
    };
  }
  componentDidMount() {
    this.props.getMoveName();
  }
  handleChange = (selectCompany) => {
    this.setState({ selectCompany: selectCompany });
  };
  // onChangeHandler = (value, id, isType) =>{
  // 	 const result = validate.validationCheck(value, this.state[id], isType);
  // 	 const errMsg = validate.legthValidation(id, value);
  //     this.setState({
  //         [id] : result,
  //         errormessage : errMsg
  //     })
  // }

  onBlurChange = (e) => {
    let errMsg = validate.legthValidation(e.target.id, e.target.value);
    if (errMsg && e.target.value) {
      this.setState({
        iserror: e.target.id,
        errormessage: errMsg,
      });
    } else if (this.state.iserror) {
      this.setState({ iserror: "" });
    }
  };

  addMove = () => {
    let paramObj = {
      company_id: this.props.company_id,
      name: this.state.selectCompany.label,
      move_id: this.state.selectCompany.value,
    };
    let errorMsg = "";
    const validationError = {
      Name: validate.isEmpty(paramObj.name),
    };
    const validationFields = [];
    Object.keys(validationError).forEach((key) => {
      if (validationError[key]) {
        validationFields.push(key);
      }
    });
    if (validationFields.length) {
      errorMsg = `Please Enter Valid ${validate.join(
        validationFields,
        (item, idx) => {
          if (validationFields.length === 1) {
            return "";
          }
          if (idx > 0 && idx < validationFields.length - 1) {
            return ", ";
          } else if (idx === validationFields.length - 1) {
            return " and ";
          }
          return "";
        }
      )}.`;
    }
    let myDiv = document.getElementById("modal");
    if (errorMsg) {
      myDiv.scrollTop = 0;
      this.setState({
        error: errorMsg,
      });
      return;
    }
    this.props.addMove(paramObj);
    this.props.toggleAdd();
  };
  populatemoveNamesList = () => {
    return this.props.moveNamesList.map((company) => {
      return {
        value: company.move_id,
        label: company.name,
        // type: company.type
      };
    });
  };
  render() {
    const buttonDisable = this.state.name
      ? "add-button"
      : "add-button btn-disabled";

    const options = this.props.moveNamesList && this.populatemoveNamesList();
    return (
      <div className="add-user-wrap add-action-wrap-popup">
        <div onClick={this.props.toggleAdd} className="overlay"></div>
        <div id="modal" className="modal-popup">
          <div className="pop-heading">Add Move</div>
          <div className="content">
            <div>
              <div className="header">Name</div>
              <div className="sub-container">
                <Select
                  className="select"
                  value={this.state.selectCompany}
                  onChange={this.handleChange}
                  options={options}
                />
              </div>
            </div>
            <div className="submit-section">
              <button
                className="add-button discard"
                onClick={this.props.toggleAdd}
              >
                Cancel
              </button>
              <button className="add-button" onClick={this.addMove}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddMove;
