import React, { Component } from "react";
import Edit from "../../../../../assets/images/edit.svg";
import Delete from "../../../../../assets/images/delete.svg";
import Button from "../../../../home/button-classic/button-classic";
import Search from "../../../../common/components/search-text/search-text";
import AddItem from "./add-item/add-item";
import EditItem from "./edit-item/edit-item";
import "./Driver-master.less";
import { data } from "jquery";
import {ReactComponent as Download} from '../../../../../assets/images/download.svg'
import {ReactComponent as Upload} from '../../../../../assets/images/upload-blue.svg'
import { getUserCompanyId } from "../../../../../utils/common";
import { withRouter } from "react-router";
class DriverMaster extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      isEdit: false,
      limit: 20,
      offset: 0,
      editedData: "",
      search: "",
    };
  }

  componentDidMount() {
    this.getDriverInfo();
  }
  getDriverInfo = () => {
    const data = {
      offset: this.state.offset,
      limit: this.state.limit,
    };
    if (this.state.search) {
      data.search = this.state.search;
    }
    this.props.getDriverInfo(data);
  };

  toggleAdd = (isType, data = "") => {
    if (isType == "Edit") {
      this.setState({ isEdit: !this.state.isEdit, editedData: data });
    } else {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  clearFilterOnCancel = (e, type) => {
    this.setState({ search: "" }, () => {
      this.getDriverInfo();
    });
  };
  changeHandler = (val, id) => {
    this.setState({
      [id]: val,
    });
  };

  submitSearch = () => {
    this.getDriverInfo();
  };

  previous = () => {
    if (this.state.offset > 0) {
      this.setState(
        {
          offset: this.state.offset - 20,
        },
        () => {
          const data = {
            limit: this.state.limit,
            offset: this.state.offset,
          };
          this.props.getDriverInfo(data);
        }
      );
    }
  };

  next = () => {
    this.setState(
      {
        offset: this.state.offset + 20,
      },
      () => {
        const data = {
          limit: this.state.limit,
          offset: this.state.offset,
        };
        this.props.getDriverInfo(data);
      }
    );
  };

  checkBlock = (blockedData) => {
    const cid = getUserCompanyId()
    let colorCode = '';
    if(blockedData && blockedData.find(bd => bd.company_id === cid) && blockedData.find(bd => bd.company_id === cid).is_blocked){
      colorCode = 'text-red item-label';
    } else {
      colorCode = 'item-label';
    }
    return colorCode;
  }
  
  handleOnClickForAuditBtn = () => {
    const {history} = this.props;
    const companyId = getUserCompanyId()
    const masterType = 4
    const navUrl = `/auditScreen/${companyId}/${masterType}`;
    history.push(navUrl)
  }
  
 render() {
    return (
      <div className={"Pfizer-Item"}>
        <div className="header" style={{display:'flex'}}>
          <Search
            id={"search"}
            value={this.state.search}
            placeholder={"Search"}
            changeHandler={this.changeHandler}
            click={this.submitSearch}
            canClear={this.state.search !== ""}
            handleClearFilter={(e) => this.clearFilterOnCancel(e, "search")}
          />
          <Button value={" + Add"} click={this.toggleAdd} />

          <button
            className="button-classic"
            onClick={this.handleOnClickForAuditBtn}
          >
            Audit
          </button>

          <div style={{display:'flex'}}>
            <button
              className="button-classic"
              onClick={this.props.downloadDriverTemplate}
              style={{ alignItems: 'center', textAlign: 'center', display: 'flex' }}
            > <Download />Download Template
            </button>

            <button
              className="button-classic"
              onClick={() => { this.uploadDriver.click() }}
              style={{ alignItems: 'center', textAlign: 'center', display: 'flex' }}
            >
              <Upload style={{ width: '15px', height: '15px', marginRight: '5px' }} />
              Upload Template
              <input type='file' style={{ display: 'none' }} ref={(e) => { this.uploadDriver = e; }} onChange={(e) => { this.props.uploadDriverTemplate(e) }} onClick={(e) => { e.target.value = null }} />
            </button>
          </div>

        </div>
        <div className="item-table">
          <div
            className="list-section item-header column"
            style={{ minWidth: "fit-content" }}
          >
            <div className="item-label fBold fs-14">Sl No</div>
            <div className="item-label fBold fs-14">Driver Name</div>
            <div className="item-label fBold fs-14">Driver Contact</div>
            <div className="item-label fBold fs-14">Licence No</div>
            <div className="item-label fBold fs-14">Driver A/C No</div>
            <div className="item-label fBold fs-14">Ifsc Code</div>
            <div className="item-label fBold fs-14">Driver Location </div>
            <div className="item-label fBold fs-14">Actions</div>
          </div>
          {(!this.props.driverIn || this.props.driverIn.length === 0) && (
            <div className={"no_data_found"}>{"No Data Found"}</div>
          )}

          {this.props.driverIn &&
            this.props.driverIn.map((val, index) => {
              return (
                <div
                  className="list-section item-header"
                  style={{ minWidth: "fit-content" }}
                >
                  <div className="item-label">{index + 1}</div>
              <div className = {this.checkBlock(val.block_data)}>{val.driver_name}</div>
                  <div className="item-label">{val.cell_no || "-"}</div>
                  <div className="item-label">{val.dl_no || "-"}</div>
                  <div className="item-label">{val.driver_acc_no || "-"}</div>
                  <div className="item-label">
                    {val.driver_ifsc_code || "-"}
                  </div>
                  <div className="item-label">
                    {val.driver_alternative_location_id || "-"}
                  </div>

                  <div className="item-label">
                    <div
                      className={"delete-icon"}
                      style={{ backgroundImage: `url(${Edit})` }}
                      onClick={() => this.toggleAdd("Edit", val)}
                    ></div>
                    {/* <div
                      className={"delete-icon"}
                      style={{ backgroundImage: `url(${Delete})` }}
                      onClick={() => this.props.deleteDriverInfo(val)}
                    ></div> */}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="submit-section-pagination">
          <div className="next">
            <Button value="Next" click={this.next} />
          </div>
          {this.state.offset != 0 && (
            <div className="pre">
              <Button value="Previous" click={this.previous} />
            </div>
          )}
        </div>
        {this.state.isOpen && (
          <AddItem
            toggleAdd={this.toggleAdd}
            addDriverDetails={this.props.addDriverDetails}
            addDriverInfo={this.props.addDriverInfo}
          />
        )}
        {this.state.isEdit && (
          <EditItem
            toggleAdd={this.toggleAdd}
            data={this.state.editedData}
            updateDriverInfo={this.props.updateDriverInfo}
          />
        )}
      </div>
    );
  }
}
export default withRouter(DriverMaster);
