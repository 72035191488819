import React, { Component, Fragment } from 'react';
import InputText from '../../../common/components/input-text/input-text';
import Select from 'react-select';
//import Button from '../../../';
import CheckBox from '../../../components/checkbox/checkbox';
import Delete from '../../../../assets/images/delete.svg';
import Edit from '../../../../assets/images/edit.svg';
//import FixedDefineProcess from './fixed-define-process/fixed-define-process';
//import CostImpactPenality from './cost-impact-penality/cost-impact-penality';
import Dropup from "../../../../assets/images/drop-up-arrow.svg";
//import AddRow from "../add-row/add-row";
//import Detention from './detention/add_detention';
//import Damages from '../credit-master/add-damage';
//import PodStatus from './pod-status/pod-status';
//import DeleteDebitMaster from './../../../components/delete-confirmation/delete-confirmation';
import { detentionType } from '../../../../utils/constants';
//import ShortagePilferage from '../debit-master/shortage_pilferage_tolerance/shoratge_pilferage';
import { uomEquality } from '../../../../utils/common';
import { connect } from 'react-redux';


const type = [
    { label: 'Fixed Define Process', value: 'Fixed Define Process' },
    { label: 'Cost Impact Penality', value: 'Cost Impact Penality' }
]

class CommonMasterDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            insideTabDropDownName: [],
            selectedOption: null,
            checkDropDown: [],
            isOpen: false,
            isType: null,
            isSubType: null,
            isAddDetention: false,
            isEditDetentionData: '',
            isAddDamages: false,
            isEditDamagesData: '',
            editLateDeliveryData: ''
        }
    }

    componentWillReceiveProps(newProps) {
        // this.setState({ data: newProps.accountData });
        // if (newProps.mainTabOption !== this.props.mainTabOption) {
        //     this.setState({ checkDropDown: [] });
        // }
        if(newProps.master && newProps.master.insideTabDropDownName && newProps.master.insideTabDropDownName.length > 0){
            this.setState({insideTabDropDownName : newProps.master.insideTabDropDownName})
        }
    }

    onAdd = (id, subType, data = '') => {
        this.setState({ isOpen: !this.state.isOpen, isType: id, isSubType: subType, editLateDeliveryData: data }, () => {
            if (id !== 7) {
                this.props.getAccountEvents(id);
            }
        });
    }

    toggleAddDamages = (id, isEditDamagesData = '') => {
        const { isAddDamages } = this.state;
        this.setState({ isAddDamages: !isAddDamages, isType: id, isEditDamagesData }, () => {
            if (isEditDamagesData) {
                this.props.getMaterailDetails(isEditDamagesData.sub_type);
            }
        })
    }

    onAddDetention = (isEditDetentionData = '') => {
        const isAddDetention = !this.state.isAddDetention;
        this.setState({ isAddDetention, isEditDetentionData });
    }

    onClose = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    typeSelectedforFailure = (selectedOption) => {
        this.setState({ selectedOptionforFailure: selectedOption });
    }

    typeSelectedforWrongPlacement = (selectedOption) => {
        this.setState({ selectedOptionforWrongPacement: selectedOption });
    }

    typeChange = (selectedValue) => {
        this.setState({ typeSelected: selectedValue });
    }

    toggleCheck = (dropdown,idx) => {
        let { insideTabDropDownName } = this.state;

        if(insideTabDropDownName[idx].is_opened){
            insideTabDropDownName[idx].is_opened = false
        } 
        else{
            insideTabDropDownName[idx].is_opened = true
        }

        this.setState(insideTabDropDownName)

        
        // checkDropDown[id] = !checkDropDown[id];
        // this.setState({ checkDropDown, isType: id }, () => {
        //     if (checkDropDown[id] && ![10, 9, 11].includes(id)) {
        //         this.props.getDebitMaster(id);
        //     } else if (id == 10) {
        //         this.props.getCreditMaster(id);
        //     } else if (id == 9) {
        //         this.props.getDetentionDetails();
        //     } else if (id == 11) {
        //         this.props.getShortagePilferage();
        //         this.props.getProductDetails();
        //     }
        // });
    }

    

    getVehicleType = (vehicleId) => {
        const vehicleType = this.props.accountData.vehicleType.filter(val => val.id == vehicleId);
        return (vehicleType[0] || []).name;
    }

    toggleDeleteDebitMaster = (row_id, num) => {
        this.setState({
            showDeleteDebitMaster: !this.state.showDeleteDebitMaster,
            row_id: row_id,
            num: num
        })
    }

    deleteDebitMaster = () => {
        this.props.deleteDebitMaster(this.state.row_id, this.state.num);
        this.toggleDeleteDebitMaster();
    }

    toggleDeleteCreditMaster = (credit_master_id, num) => {
        this.setState({
            showDeleteCreditMaster: !this.state.showDeleteCreditMaster,
            credit_master_id: credit_master_id,
            num: num
        })
    }

    deleteCreditMaster = () => {
        this.props.deleteCreditMaster(this.state.credit_master_id, this.state.num);
        this.toggleDeleteCreditMaster()
    }

    toggleDeleteDetentionDetails = (detention_id) => {
        this.setState({
            showDeleteDetentionDetails: !this.state.showDeleteDetentionDetails,
            detention_id: detention_id,
        })
    }

    deleteDetentionDetails = () => {
        this.props.deleteDetentionDetails(this.state.detention_id);
        this.toggleDeleteDetentionDetails();
    }

    toggleAddShortage = (value = '') => {
        const { isAddShortage } = this.state;
        this.setState({ isAddShortage: !isAddShortage, editShortagePilferageData: value });
    }


    render() {
        const { insideTabDropDownName } = this.state;
        //const { master } = this.props;


      //  console.log(master)
        console.log(insideTabDropDownName)

        return (
            <div className={'debit-master'}>

                {(insideTabDropDownName || []).map((dropdown, idx) => {
                    return (
                        <>
                            <div className={'header'} >{dropdown.dropdown_name}
                                <div className={dropdown.is_opened ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(dropdown, idx)}></div>
                            </div>
                            <div className={`drop-section ${dropdown.is_opened ? "drop-up" : "drop-close"}`}>
                                {/* <div className={'fuel-escalation-table'}>

                                    <div className={'approval-btn'}>
                                        <Button value={'Add'} click={() => this.onAdd(7)}></Button>
                                    </div>
                                </div> */}
                            </div>
                        </>
                    )
                })}

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        master: state.master
    };
};

export default connect(mapStateToProps)(CommonMasterDropDown);