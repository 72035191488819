import React, { Component } from 'react';
import MainComponent from "../main/main";
import "./company-details.less";
import CompanyDetailsNavigation from "../home-navigation-panel/home-navigation-panel";
import queryString from 'query-string';
import backIcon from "../../../assets/images/left-arrow.svg";
import { isCompanyAdmin } from "../../../utils/common.js";

class CompanyDetails extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }

    handleBackToPrevScreen = () => {
        if (history) {
            history.back();
        }
    }
    
    render() {
        const tab = queryString.parse(this.props.location.search).tab || 1;
        const isCmpyAdmin = isCompanyAdmin();
        return (
            <div className = "company-details-wrap">
            	<div className = "wrapper">
                    <MainComponent>
                        {!isCmpyAdmin && <div className = "go-back-prev-screen" onClick={this.handleBackToPrevScreen} style = {{backgroundImage: `url(${backIcon})`}}></div>}
                        <div className = "">
                            <CompanyDetailsNavigation tab = {tab} id = {this.props.match.params.id} type = {this.props.match.params.type} isCmpyAdmin={isCmpyAdmin}/>
                        </div>
                    </MainComponent>
                </div>
             </div>   
        );
    }
}



export default CompanyDetails;