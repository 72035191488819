import $ from 'jquery';
import { getCookie } from "../utils/cookies";
function getToken() {
  var data = getCookie('user') ? getCookie('user') : "";
  var token = data ? JSON.parse(data).token : "";
  return token;
}
function getData(url, gps) {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url: url,
      headers: gps === true ? {
        "token": getToken(),
        "moduleid": 6
      } : {
        "token": getToken()
      },
      success: function success(data) {
        resolve(data);
      },
      error: function error(_error) {
        reject(_error);
      }
    });
  });
}
function getDataFromPIN(url) {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url: url,
      success: function success(data) {
        resolve(data);
      },
      error: function error(_error2) {
        reject(_error2);
      }
    });
  });
}
function getDataSSO(url) {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url: url,
      headers: {
        "token": getCookie('token'),
        "module": 6
      },
      success: function success(data) {
        resolve(data);
      },
      error: function error(_error3) {
        reject(_error3);
      }
    });
  });
}
function postData(url, data, gps) {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url: url,
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      headers: gps === true ? {
        "token": getToken(),
        "moduleid": 6
      } : {
        "token": getToken()
      },
      data: JSON.stringify(data),
      success: function success(data) {
        resolve(data);
      },
      error: function error(_error4) {
        reject(_error4);
      }
    });
  });
}
function postDataWithOutToken(url, data) {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url: url,
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      data: JSON.stringify(data),
      success: function success(data) {
        resolve(data);
      },
      error: function error(_error5) {
        reject(_error5);
      }
    });
  });
}
function fileUpload(url, data) {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url: url,
      type: 'POST',
      headers: {
        "token": getToken()
      },
      processData: false,
      contentType: false,
      data: data,
      success: function success(data) {
        resolve(data);
      },
      error: function error(_error6) {
        reject(_error6);
      }
    });
  });
}
function putData(url, data, gps) {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url: url,
      type: 'PUT',
      data: data,
      contentType: "application/json; charset=utf-8",
      headers: gps === true ? {
        "token": getToken(),
        "moduleid": 6
      } : {
        "token": getToken()
      },
      success: function success(data) {
        resolve(data);
      },
      error: function error(_error7) {
        reject(_error7);
      }
    });
  });
}
function putDataForMoglix(url, data) {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url: url,
      type: 'PUT',
      data: data,
      headers: {
        "token": getToken()
      },
      success: function success(data) {
        resolve(data);
      },
      error: function error(_error8) {
        reject(_error8);
      }
    });
  });
}
function putDatajson(url, data) {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url: url,
      type: 'PUT',
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      headers: {
        "token": getToken()
      },
      data: JSON.stringify(data),
      success: function success(data) {
        resolve(data);
      },
      error: function error(_error9) {
        reject(_error9);
      }
    });
  });
}
function postDataForGetDetails(url, data) {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url: url,
      type: 'POST',
      data: data,
      headers: {
        "token": getToken()
      },
      success: function success(data) {
        resolve(data);
      },
      error: function error(_error10) {
        reject(_error10);
      }
    });
  });
}
function deleteData(url, data, stringify, gps) {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url: url,
      type: 'DELETE',
      contentType: 'application/json; charset=utf-8',
      headers: gps === true ? {
        "token": getToken(),
        "moduleid": 6
      } : {
        "token": getToken()
      },
      data: stringify == "stringify" ? JSON.stringify(data) : data,
      success: function success(data) {
        resolve(data);
      },
      error: function error(_error11) {
        reject(_error11);
      }
    });
  });
}
function postDataWithoutToken(url, data, broadcastToken) {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url: url,
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      headers: {
        "token": broadcastToken || getToken()
      },
      data: JSON.stringify(data),
      success: function success(data) {
        resolve(data);
      },
      error: function error(_error12) {
        reject(_error12);
      }
    });
  });
}
export { getData, postData, putData, postDataForGetDetails, fileUpload, deleteData, postDataWithoutToken, getDataSSO, putDataForMoglix, getDataFromPIN, postDataWithOutToken, putDatajson };