import React, { Component } from "react";
import InputText from "../../../../common/components/input-text/input-text";
// import Select from 'react-select';
import Button from "../../../../home/button-classic/button-classic";
import { data } from "jquery";
// import { PackagingType, LoadType, unloadingMhe } from '../../../../../utils/constants';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Select from "react-select";
import { validationCheck } from "../../../../../utils/validate";
import ACTION from "../../../../common/action_constant";
import {connect} from 'react-redux';

class AddItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        driver_id: this.props.data.driver_id,
        transporter_name: this.props.data.transporter_name,
        transporter_id: this.props.data.transporter_id,
        driver_name: this.props.data.driver_name,
        age: this.props.data.age,
        contact: this.props.data.contact,
        emergency_contact: this.props.data.emergency_contact,
        dl_no: this.props.data.dl_no,
        dl_expiry: this.props.data.dl_expiry,
        joining_date: this.props.data.joining_date,
        operation_months: this.props.data.operation_months,
        total_experience: this.props.data.total_experience,
        vaccine_fisrtdose_on: this.props.data.vaccine_fisrtdose_on,
        vaccine_seconddose_on: this.props.data.vaccine_fisrtdose_on,
        adhar_no: this.props.data.adhar_no,
        address: this.props.data.address,
        driver_type_name: this.props.data.driver_type_name,
        attachments: this.props.data.attachments
      },
    };
  }

  componentDidMount() {
    this.props.getCompanyTransporters();
  }

  handleChange = (id, selectedOption) => {
    let { data } = this.state;
    data[id] = selectedOption.label;
    if(id === "transporter_name"){
      data['transporter_id'] = selectedOption.value;
    }
    this.setState({ data });
  };

  presentMonth = (date) => {
    let { data } = this.state;
    const currentDate = new Date();
    let months;
    months = (currentDate.getFullYear() - date.getFullYear()) * 12;
    months -= date.getMonth();
    months += currentDate.getMonth();
    data.operation_months = months
    this.setState({data});
  }

  onChangeHandler = (value, id, isType) => {
    let { data } = this.state;
    const result = validationCheck(value, this.state.data[id], isType);
    if(id === 'age'){
      data.age = (value > 0 && value < 100 || value == "") ? value : data.age;
    } else {
      data[id] = result;
    }
   this.setState({ data });
  };
  SelectDate = (id, selectedOption) => {
    let { data } = this.state;
    data[id] = moment(selectedOption).format("DD/MM/YYYY");
    if(id === "joining_date"){
      this.presentMonth(selectedOption)
    }
    this.setState({ data });
  };
  onSave = () => {
    const { data } = this.state;
    if(this.validateCheck()){
      this.props.toggleAdd("Edit");
      this.props.updateDriverDetails(data);
    }else {
      this.setState({errorMsg: "Please add all mandatory fields"})
    }
  };
  validateCheck = () => {
    const { data } = this.state;
    if(data.transporter_name && data.driver_name && data.age && data.contact && data.emergency_contact && data.dl_no, data.driver_type_name){
      return true;
    } else return false;
  }
  checkExpiry = (date) => {
    const currentDate = new Date();
    var dateMomentObject = moment(date, "DD/MM/YYYY");
    var dateObject = dateMomentObject.toDate();
    var differenceInTime = currentDate.getTime() - dateObject.getTime();
    var differenceInDays = differenceInTime / (1000 * 3600 * 24);
    let colorCoding = '';
    if(differenceInDays < 0 && differenceInDays > -30){
      colorCoding = 'text-orange item-label';
    } else if(differenceInDays > 0){
     colorCoding = 'text-red item-label';
    }
    else {
     colorCoding = 'item-label';
    }
    return colorCoding;
  }

  onUpload = () => {
    this.uploadUrl.click();
  };

  uploadfiles = (e) => {
		const { dispatch } = this.props;
		let formData = new FormData();
		formData.append("file", e.target.files[0]);
		dispatch({
		  type: ACTION.DATA_GOVERNANCE.UPLOAD_VEHICLE_FIELS,
		  formData,
		  onSuccess: (details) => {
        let { data } = this.state;
        data.attachments.push(details)
			  this.setState({data})
		  }
		});
	};

  removeAttachment = (index) => {
    let { data } = this.state;
    data.attachments.splice(index, 1);
    this.setState({ data })
  }

  render() {
    let { data } = this.state;
    const { companyTransporters } = this.props;
    const companyTranspoList =
    (companyTransporters || []).map((data) => ({
      label: data.company_name,
      value: data.id,
    })) || [];
    return (
      <div className="add-user-wrap">
        <div onClick={this.props.toggleAdd} className="overlay"></div>
        <div id="modal" className="modal-popup">
          <div className="pop-heading">Edit Driver Master</div>
          <div className="content add-item-details">
          <div className="select-wrap">
            <div className="label">Transporter<span className='error-color'>*</span></div>
            <Select
              className="item-select"
              value={companyTranspoList.find((val) => {
                return val.label == data.transporter_name;
              })}
              options={companyTranspoList}
              onChange={this.handleChange.bind(this, "transporter_name")}
            />
          </div>
          <div className="select-wrap">
            <div className="label">Driver Type<span className='error-color'>*</span></div>
            <Select
              className="item-select"
              value={[{value: 1, label: 'Driver'}, {value: 2, label: 'Co-Driver'}].find((val) => {
                return val.label == data.driver_type_name;
              })}
              options={[{value: 1, label: 'Driver'}, {value: 2, label: 'Co-Driver'}]}
              onChange={this.handleChange.bind(this, "driver_type_name")}
            />
          </div>
            <div className="select-wrap">
              <InputText
                label="Driver Name"
                id="driver_name"
                value={data.driver_name}
                placeholder="Driver Name"
                changeHandler={this.onChangeHandler}
                required
              />
            </div>

            <div className="select-wrap">
              <InputText
                label="Age "
                id="age"
                value={data.age}
                placeholder="Age "
                changeHandler={this.onChangeHandler}
                required
              />
            </div>

            <div className="select-wrap">
              <InputText
                label="Driver Contact"
                id="contact"
                value={data.contact}
                placeholder="Driver Contact"
                changeHandler={this.onChangeHandler}
                length={'10'}
						    isValidate={'numeric'}
                required
              />
            </div>

            <div className="select-wrap">
              <InputText
                label="Emergency Contact"
                id="emergency_contact"
                value={data.emergency_contact}
                placeholder="Emergency Contact"
                changeHandler={this.onChangeHandler}
                length={'10'}
						    isValidate={'numeric'}
                required
              />
            </div>

            <InputText
              label="Liecense No"
              placeholder="Liecense No"
              id="dl_no"
              value={data.dl_no}
              changeHandler={this.onChangeHandler}
              required
            />

            <div className="select-wrap">
              <div className="data-picker">
                <div className="label">DL Expiry Date</div>
                <DatePicker
                  label={"dl_expiry"}
                  dateFormat="dd/MM/yyyy"
                  value={data.dl_expiry}
                  onChange={this.SelectDate.bind(this, "dl_expiry")}
                  className={this.checkExpiry(data.dl_expiry)}
                />
              </div>
            </div>
            <div className="select-wrap">
              <div className="data-picker">
                <div className="label">Joining Date</div>
                <DatePicker
                  label={"joining Date"}
                  dateFormat="dd/MM/yyyy"
                  value={data.joining_date}
                  // selected={data.insurance_validity}
                  onChange={this.SelectDate.bind(this, "joining_date")}
                  maxDate={new Date()}
                />
              </div>
            </div>
            <div className="select-wrap">
              <div className="data-picker">
                <div className="label">First Dose Date</div>
                <DatePicker
                  label={"First Dose Date"}
                  dateFormat="dd/MM/yyyy"
                  value={data.vaccine_fisrtdose_on}
                  // selected={data.insurance_validity}
                  onChange={this.SelectDate.bind(this, "vaccine_fisrtdose_on")}
                />
              </div>
            </div>
            <div className="select-wrap">
              <div className="data-picker">
                <div className="label">Second Dose Date</div>
                <DatePicker
                  label={"Second Dose Date"}
                  dateFormat="dd/MM/yyyy"
                  value={data.vaccine_seconddose_on}
                  // selected={data.insurance_validity}
                  onChange={this.SelectDate.bind(this, "vaccine_seconddose_on")}
                />
              </div>
            </div>

            <InputText
              label="Present Operation(Months)"
              placeholder="Present Operation(Months)"
              id="operation_months"
              value={data.operation_months}
              changeHandler={this.onChangeHandler}
              isValidate={'numeric'}
              readonly={true}
            />
            <InputText
              label="Total Exp"
              placeholder="Total Exp"
              id="total_experience"
              value={data.total_experience}
              changeHandler={this.onChangeHandler}
              isValidate={'numeric'}
            />

            <InputText
              label="ID Proof (Addhar Number)"
              placeholder="ID Proof (Addhar Number)"
              id="adhar_no"
              value={data.adhar_no}
              changeHandler={this.onChangeHandler}
            />
            <InputText
              label="Residential Address"
              placeholder="Residential Address"
              id="address"
              value={data.address}
              changeHandler={this.onChangeHandler}
            />
          <div className="select-wrap">
            <div className="data-picker">
              <div className="label">
                Attach Documents
              </div>
            <Button value={"Upload"} click={this.onUpload} />
            <input
              type="file"
              style={{ display: "none" }}
              ref={(e) => {
                this.uploadUrl = e;
              }}
              onChange={(e) => {this.uploadfiles(e)}}
              onClick={(e) => {
                e.target.value = null;
              }}
            />
            {(data.attachments || []).map((att, index) => {
            return(
            <div>
              {att && att.name ? (
                <a
                  href={att.url}
                  download
                  className={"theme-color fs-12 mt-5"}
                >
                  {att.name}
                </a>
              ) : null}
              <span className="error pl-20 curP" onClick={() => this.removeAttachment(index)}>X</span>
            </div>
            )})}
            </div>
          </div>
          </div>
          
          <div className="submit-button">
          {this.state.errorMsg && <div className="error-color mb-10">{this.state.errorMsg}</div>}
            {data.emergency_contact && data.contact && (data.contact.length !== 10 || data.emergency_contact.length !== 10) && <div className="error-color mb-10">{`Invalid ${data.contact.length !== 10 ? 'contact' : (data.emergency_contact.length !== 10 ? 'emergency contact' : '')} number`}</div>}
            <div className="button-section">
              <Button value="Save" click={this.onSave} />
            </div>
            <div className="button-section">
              <Button
                value="Cancel"
                click={() => this.props.toggleAdd("Edit")}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(AddItem);
