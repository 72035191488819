import React, { Component } from "react";
import ACTIONS from "../../../../common/action_constant";
import { connect } from "react-redux";
import InputText from "../../../../common/components/input-text/input-text";
import validate from "../../../../../utils/validate";
import "../../home-navigation-panel.less";

class DebitNote extends Component {
    constructor(props) {
      super(props);
      this.state = {
        distance: '',
        higher_tolerance: '',
        lower_tolerance: '',
        invoice_percentage: '',
        default_value: '',
        company_id: props.company_id
      };
    }

    componentDidMount() {
        this.getDebitNote();
    }

    getDebitNote = () => {
        const { dispatch, company_id } = this.props;
        dispatch({
          type: ACTIONS.HOME.GET_DEBIT_NOTE_CONFIG,
          param: `?company_id=${company_id}`,
          onSuccess: (data) => {
            this.setState({
                ...data
            })
          }
        })
      }
    
      onSave = () => {
        const { dispatch } = this.props;
        const data = this.state;
        dispatch({
          type: ACTIONS.HOME.SAVE_DEBIT_NOTE_CONFIG,
          data,
          onSuccess: this.getDebitNote
        })
      }

    onChangeHandler = (value, id) => {
        if(['lower_tolerance', 'higher_tolerance', 'invoice_percentage'].includes(id) && value > 100) {
            return;
        }
		this.setState({
			[id]: value ? +value : value
		});
	}

    render(){
        return(
            <div style={{"padding": '50px'}}>
                <form className="form-section" autoComplete="nope">
                        <div className="flex route-import-root">
                            <div className="mb-10 mr-20">
                                <div className="header">Distance</div>
                                <div className="sub-container">
                                    <InputText type="number" className="wt-100p tCenter" placeholder="Distance" id={"distance"} value={this.state.distance} changeHandler={this.onChangeHandler} />
                                </div>
                            </div>

                            <div className="mb-10">
                                <div className="header">Lower Tolerance Percentage</div>
                                <div className="sub-container">
                                    <InputText type="number" className="wt-100p tCenter" placeholder="Lower Tolerance" id={"lower_tolerance"} value={this.state.lower_tolerance} changeHandler={this.onChangeHandler} />
                                </div>
                            </div>

                            <div className="mb-10 mr-20">
                                <div className="header">Higher Tolerance Percentage</div>
                                <div className="sub-container">
                                    <InputText type="number" className="wt-100p tCenter" placeholder="Higher Tolerance" id={"higher_tolerance"} value={this.state.higher_tolerance} changeHandler={this.onChangeHandler} />
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="mb-10 mr-20">
                                <div className="header">Invoice Percentage</div>
                                <div className="sub-container">
                                    <InputText type="number" className="wt-100p tCenter" placeholder="Invoice Percentage" id={"invoice_percentage"} value={this.state.invoice_percentage} changeHandler={this.onChangeHandler} />
                                </div>
                            </div>

                            <div className="mb-10">
                                <div className="header">Default Value</div>
                                <div className="sub-container">
                                    <InputText type="number" className="wt-100p tCenter" placeholder="Default Value" id={"default_value"} value={this.state.default_value} changeHandler={this.onChangeHandler} />
                                </div>
                            </div>
                    </div>
                </form>
                <div className="submit-section" style={{display: 'block'}}>
                    <button className="add-button" onClick={this.onSave}>Save</button>
                </div>
        </div>
        )
    }

}

export default connect()(DebitNote);