import React, { Component } from "react";
import SearchText from "../../../common/components/search-text/search-text";
import Select from "react-select";
import Button from "../../button-classic/button-classic";
import "./plants-depots-list.less";
import history from "../../../history";
import { toPascalCase } from "../../../../utils/validate";
import Delete from '../../../../assets/images/delete.svg';
import { CARGILL_COMPANY_IDS } from "../../../../utils/common";
import { withRouter } from "react-router-dom";

class PlantsDepotsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			plant: "",
			offset: 0,
			search: ""
		};
	}
	componentDidMount() {
		this.getPlantsList();
		this.props.getBranchTypes();
		this.props.getZones();
	}
	previous = () => {
		if (this.state.offset > 0) {
			this.setState(
				{
					offset: this.state.offset - 10
				},
				() => {
					this.getPlantsList();
				}
			);
		}
	};
	clearFilter = () => {
		this.setState({
			selectedbranchType: null,
			selectedZone: null,
			plant: "",
			offset: 0,
			search: ""
		}, () => {
			this.getPlantsList()
		})
	}
	next = () => {
		this.setState(
			{
				offset: this.state.offset + 10
			},
			() => {
				this.getPlantsList();
			}
		);
	};
	getPlantsList = () => {
		let params =
			"?companyId=" +
			this.props.companyId +
			"&companyType=" +
			this.props.companyType +
			"&limit=10&offset=" +
			this.state.offset;
		if (this.state.search != "") {
			params = params + "&search=" + this.state.search;
		}
		if (this.state.selectedbranchType) {
			params = params + "&type=" + this.state.selectedbranchType.value;
		}
		if (this.state.selectedZone) {
			params = params + "&zone=" + this.state.selectedZone.value;
		}
		this.props.getPlantsDepotsList(params);
	};
	handleChangeSelectChange = (id, selectedOption) => {
		this.setState({ [id]: selectedOption }, () => {
			this.getPlantsList();
		});
	};
	clearFilterOnCancel = () => {
		this.setState({ search: "" }, () => {
			this.getPlantsList();
		});
	};
	goToAddNewPlant = () => {
		this.props.setCurrentDepot({});
	};
	editPlant = (e, depot) => {
		this.props.setCurrentDepot(depot);
	};
	changeHandler = (val, id) => {
		this.setState({
			[id]: val
		})
	}

	downloadBranchDeatils = () =>{
        this.props.downloadBranchDeatils(this.props.companyId)
	}

	downloadSeekerBranchDeatils = () => {
		this.props.downloadSeekerBranchDeatils(this.props.companyId, true)
	} 

	downloadSeekerBranchTemp = () => {
		this.props.downloadSeekerBranchDeatils(this.props.companyId, false)
	}

	uploadSeekerBranchFile =(e)=>{
        this.props.uploadSeekerBranchFile(e, this.props.companyId);
	}
	
	uploadProviderBranchFile =(e)=>{
        this.props.uploadProviderBranchFile(e, this.props.companyId);
    }

	deletePlantDepot = (e, data) => {
		e.preventDefault();
		e.stopPropagation();
		let params =
			"?companyId=" +
			this.props.companyId +
			"&companyType=" +
			this.props.companyType +
			"&limit=10&offset=" +
			this.state.offset;
		if (this.state.search != "") {
			params = params + "&search=" + this.state.search;
		}
		if (this.state.selectedbranchType) {
			params = params + "&type=" + this.state.selectedbranchType.value;
		}
		if (this.state.selectedZone) {
			params = params + "&zone=" + this.state.selectedZone.value;
		}
		this.props.deletePlantDepot(data, params);
	}

	syncWithVIMS = (e, branch, company) => {
		e.preventDefault();
		e.stopPropagation();
		let branches = [];
		branches.push(branch);
		this.props.syncChainWithVIMS(branches, company);
	}

	handleOnClickForAuditBtn = ()=>{
    const { history,match} = this.props;
	const companyId =  match.params.id
	const masterType = 1
	const navUrl = `/auditScreen/${companyId}/${masterType}`
	history.push(navUrl)
	}

	render() {
		const company_id = this.props.company_id;
		const isCargill = CARGILL_COMPANY_IDS.includes(company_id);
		let optionBranchType = [],
			optionZone = [];
		let plantsDepotsList = [];
		if (this.props.plantsDepots.plantsDepotsList.length > 0) {
			plantsDepotsList = this.props.plantsDepots.plantsDepotsList;
		}

		if (this.props.plantsDepots.zones) {
			for (let i = 0; i < this.props.plantsDepots.zones.length; i++) {
				optionZone.push({
					value: this.props.plantsDepots.zones[i].id,
					label: this.props.plantsDepots.zones[i].name
				});
			}
		}
		if (this.props.plantsDepots.branchTypes) {
			for (let i = 0; i < this.props.plantsDepots.branchTypes.length; i++) {
				optionBranchType.push({
					value: this.props.plantsDepots.branchTypes[i].id,
					label: this.props.plantsDepots.branchTypes[i].name
				});
			}
		}
		return (
			<div className="plants-depots-wrap">
				<div className="search-filter-section">
					<SearchText
						id="search"
						value={this.state.search}
						placeholder={this.props.companyType === 1 ? "Search Plant/Depot" : "Search Branches"}
						changeHandler={this.changeHandler}
						click={this.getPlantsList}
						canClear={(this.state.search !== '')}
						handleClearFilter={this.clearFilterOnCancel}
					/>
					<Select
						className="select"
						value={this.state.selectedbranchType}
						onChange={this.handleChangeSelectChange.bind(
							this,
							"selectedbranchType"
						)}
						options={optionBranchType}
					/>
					<Select
						className="select"
						value={this.state.selectedZone}
						onChange={this.handleChangeSelectChange.bind(this, "selectedZone")}
						options={optionZone}
					/>
					<div className="add-plants-section">
						<Button value={this.props.companyType === 1 ? "+ Plant/Depot" : "+ Branches" }click={() => this.goToAddNewPlant()} />
						
					</div>
					<button
						className="button-classic"
						onClick={this.handleOnClickForAuditBtn}
					>
						Audit
					</button>
				</div>
				<div className ="flex">
				<div className="filter-clear-section"><Button value="X Clear Filter" click={() => this.clearFilter()} /></div>
				{ this.props && this.props.companyType === "1" &&<div className="download-section">
				<button className="button-classic" onClick={this.downloadSeekerBranchDeatils}>  Download Details</button></div>}
				{ this.props && this.props.companyType === "1" &&<div className="download-section">
				<button className="button-classic" onClick={this.downloadSeekerBranchTemp}>  Download Temp.</button></div>}
				{this.props && this.props.companyType  === "1"  && <div className="download-section" >
				<button className="button-classic" onClick={() => {this.uploadSeekerExcel.click()}}> Upload</button>
                  <input type='file' style ={{display :'none'}} ref={(e) => { this.uploadSeekerExcel = e ;}} onChange={this.uploadSeekerBranchFile} onClick={(e) => {e.target.value = null }}/>
				</div>}
				{ this.props && this.props.companyType !== "1" &&<div className="download-section">
				<button className="button-classic" onClick={this.downloadBranchDeatils}>  Download Branch</button></div>}
				{this.props && this.props.companyType  !== "1"  && <div className="download-section" >
				<button className="button-classic" onClick={() => {this.uploadExcel.click()}}> Upload Branch</button>
                  <input type='file' style ={{display :'none'}} ref={(e) => { this.uploadExcel = e ;}} onChange={this.uploadProviderBranchFile} onClick={(e) => {e.target.value = null }}/>

				</div>}
				</div>

				{plantsDepotsList.length == 0 && (
					<div className="No-data">No record found</div>
				)}
				{plantsDepotsList.length > 0 && (
					<div className="plants-depots-list-section">
						<div className="plants-depots-list" style={{'display': 'flex'}}>
							<div className="branch-name header">Branch Name</div>
							<div className="branch-code header">Branch Code</div>
							<div className="sap-branch-code header">SAP Branch Code</div>
							<div className="type header">Type</div>
							<div className="pin-code header">PIN Code</div>
							<div className="zone header">Zone</div>
							<div className="region header">Region</div>
							<div className="address header">Address</div>
							<div className="pin-code header">Action</div>
						</div>
						{plantsDepotsList.map((plantDepot, index) => {
							return (
								<div
									className="plants-depots-list content"
									style={{'display': 'flex'}}
									key={index}
									onClick={() => this.editPlant(this, plantDepot)}
								>
									<div className="branch-name no-whitespace" style={{'whiteSpace': 'unset'}}>{plantDepot.branch_name}
										{/* <abbr title={toPascalCase(plantDepot.branch_name)}>{toPascalCase(plantDepot.branch_name)}</abbr> */}
									</div>
									<div className="branch-code">{toPascalCase(plantDepot.branch_code)}</div>
									<div className="sap-branch-code">
										{plantDepot.sap_branch_code}
									</div>
									<div className="type">
										<abbr title={toPascalCase(plantDepot.branch_type_name)}>
											{toPascalCase(plantDepot.branch_type_name)}
										</abbr>
									</div>
									<div className="pin-code">{plantDepot.pincode}</div>
									<div className="zone">{toPascalCase(plantDepot.zone_name)}</div>
									<div className="region">
										<abbr title={toPascalCase(plantDepot.region)}>
										{toPascalCase(plantDepot.region)}
										</abbr>
									</div>
									<div className="address" style={isCargill ? {whiteSpace: 'normal'} : {}}>
										<abbr title={toPascalCase(plantDepot.address)}>{toPascalCase(plantDepot.address)}</abbr>
									</div>
									<div className="pin-code" style={{width: '90px'}}>
										{/* <div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={(e) => this.deletePlantDepot(e, plantDepot)}></div> */}
										<div className={(plantDepot.sync_errors && plantDepot.sync_errors.length > 0) ? 'fs-12 curP sync-button error-color' : 'fs-12 curP sync-button theme-color'} onClick={(e) => this.syncWithVIMS(e, plantDepot.branch_id, plantDepot.company_id)}>Sync to VIMS</div>
									</div>
								</div>
							);
						})}
					</div>
				)}
				<div className="submit-section-pagination">
					<div className="next">
						<Button value="Next" click={this.next} />
					</div>
					<div className="pre">
						<Button value="Previous" click={this.previous} />
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(PlantsDepotsList);




