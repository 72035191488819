import ACTION from "../../common/action_constant";

const comapanyMaster = (state = {fuelDetails : [] }, action) => {
	let tempState = Object.assign({}, state);
	if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_CREDIT_MASTER){
		tempState.fuelDetails  = action.data;
		return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE1) {
        tempState.type1 = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE2) {
        tempState.type2 = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE3) {
        tempState.type3 = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE4) {
        tempState.type4 = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE5) {
        tempState.type5 = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE6) {
        tempState.type6 = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE7) {
        tempState.type7 = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE8) {
        tempState.type8 = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE9) {
        tempState.type9 = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE10) {
        tempState.type10 = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE11) {
        tempState.type11 = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_BILL_NUMBER_DETAILS) {
        tempState.type12 = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE13) {
        tempState.type13 = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_VEHICLE_DETAILS) {
        tempState.vehicleType = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_MATERIAL_DETAILS) {
        tempState.materialList = action.data;
        return tempState;
    } else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_DETENTION_DETAILS) {
        tempState.detentionDetails = action.data;
        return tempState;
    } else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_MULTI_PICKUP_DETAILS) {
        tempState.multiPickUp = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_HOLIDAY_LIST) {
        tempState.holidayList = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.DOWNLOAD_HOLIDAY_LIST_URL) {
        tempState.holidayDownlUrl = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_FREIGHT_RATE) {
        tempState.freightRateDetails = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_MINIMUM_FREIGHT_RATE) {
        tempState.minFreightRateDetails = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_TRANSPORTER_LIST){
        tempState.transporterList = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_CREDIT_MASTER_PAYABLES_RAS) {
        tempState.rasDetails = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_CREDIT_MASTER_PAYABLES_RHC) {
        tempState.rhcDetails = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_CREDIT_MASTER_PAYABLES_FUEL_SURCHARGE) {
        tempState.fuelSurchargeDetails = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_CREDIT_MASTER_PAYABLES_FOV) {
        tempState.fovDetails = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_STATE_LIST) {
        tempState.stateList = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_CREDIT_MASTER_PAYABLES_ODA) {
        tempState.odaDetails = action.data;
        return tempState;
    }else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_ZIPCODE_LIST) {
        tempState.zipCodes = action.data;
        return tempState;
    } else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_SHORTAGE_PILFERAGE_TOLERANCE_LIST) {
        tempState.shoratge_pilferage_tolerance = action.data || [];
        return tempState;
    } else if(action.type === ACTION.COMPANY_ACCOUNTS.STORE_LOADING_CHARGES){
        tempState.loadingCharges = action.data || [];
        return tempState;
    }
	return state;
};

export default comapanyMaster;
