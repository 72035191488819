import React, { Component } from 'react';
import InputText from "../../../../common/components/input-text/input-text";
import Select from 'react-select';
import validate from "../../../../../utils/validate";
import "../../home-navigation-panel.less";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import Moment from 'moment';

class AddOperationTime extends Component {
    constructor(props){
        super(props);
        this.state = {
            branch_name : this.props.editData ? this.props.editData.branch_name : '',
            company_id : this.props.editData ? this.props.editData.company_id : '',
            error : "",
            iserror : "",
            errormessage : "",
            plant_code: this.props.editData ? this.props.editData.plant_code : '',
            shift_start_time: this.props.editData ? this.props.editData.shift_start_time : '',
            shift_end_time: this.props.editData ? this.props.editData.shift_end_time : '',
            placement_time_from: this.props.editData ? this.props.editData.placement_time_from : '',
            placement_time_to: this.props.editData ? this.props.editData.placement_time_to : '',
            total_hrs_working: this.props.editData ? this.props.editData.total_hrs_working : '',
            shift: this.props.editData ? this.props.editData.shift : '' 
        }
    }

    onChangeHandler = (value, id, isType) =>{
		 const result = validate.validationCheck(value, this.state[id], isType);
		 const errMsg = validate.legthValidation(id, value);
        this.setState({
            [id] : result,
            errormessage : errMsg
        })
    }

    onBlurChange = (e) => {
        let errMsg = validate.legthValidation(e.target.id, e.target.value);
        if( errMsg && e.target.value){
            this.setState ({
                iserror: e.target.id,
                errormessage: errMsg
            })
        } else if(this.state.iserror){
            this.setState({iserror: ''});
        }
    }

    addOperationTime = () =>{
        let paramObj = {
            "company_id" : this.props.company_id,
            "branch_name":this.state.branch_name,
            "plant_code":this.state.plant_code,
            "shift_start_time": this.state.shift_start_time,
            "shift_end_time": this.state.shift_end_time,
            "total_hrs_working": this.state.total_hrs_working,
            "shift": this.state.shift && this.state.shift.value,
            "placement_time_from": this.state.placement_time_from,
            "placement_time_to": this.state.placement_time_to,
        }
        let errorMsg = "";
        const validationError = {
            "Branch Name" : validate.isEmpty(paramObj.branch_name),
            "Plant Code": validate.isEmpty(paramObj.plant_code),
            "Shift Start Time" : validate.isEmpty(paramObj.shift_start_time),
            "Shift End Time": validate.isEmpty(paramObj.shift_end_time),
            "Total Working Hours" : validate.isEmpty(paramObj.total_hrs_working),
            "Shift": validate.isEmpty(paramObj.shift),
            "Placement Time From" : validate.isEmpty(paramObj.placement_time_from),
            "Placement Time To": validate.isEmpty(paramObj.placement_time_to),
        }
        const validationFields = [];
    	Object.keys(validationError).forEach((key) => {
    		if (validationError[key]) {
    			validationFields.push(key);
    		}
        });
        if (validationFields.length) {
    		errorMsg = `Please enter valid ${validate.join(validationFields, (item, idx) => {
    			if (validationFields.length === 1) {
    				return "";
    			}
    			if (idx > 0 && idx < validationFields.length - 1) {
    				return ", ";
    			} else if (idx === validationFields.length - 1) {
    				return " and ";
    			}
    			return "";
    		})}.`;
        }
        let myDiv = document.getElementById('modal');
        if (errorMsg) {
            myDiv.scrollTop = 0;
    		this.setState({
    			error : errorMsg
    		});
    		return;
    	}
        Object.keys(this.props.editData).length > 0 ? this.props.editOperationTime(paramObj) : this.props.addOperationTime(paramObj);
        this.props.toggleAdd5();
    }

    render() {
        const buttonDisable = (this.state.name) ? 'add-button' : 'add-button btn-disabled';
        return (
            <div className = "add-user-wrap ">
                <div onClick = {this.props.toggleAdd5} className = "overlay"></div>
                <div id = "modal" className = "modal-popup">
                    <div className = "pop-heading">
                        Add Operation Time
                    </div>
                    <div className="content">
                        <div className = "details-section">
                            <div className="details">
                                <InputText placeholder = "Branch Name" id = {"branch_name"} label = "Branch Name" value = {this.state.branch_name} changeHandler = {this.onChangeHandler}/>
                                <InputText placeholder = "Plant Code" id = {"plant_code"} label = "Plant Code" value = {this.state.plant_code} changeHandler = {this.onChangeHandler}/>
                                <div className="details-header" style={{width: '160px'}}>
                                    <div className="mb-10">Shift Start Time</div>    
                                    <TimePicker
                                        showSecond={false}
                                        placeholder={'Time'}
                                        className={'wt-150 ht-30'}
                                        value={this.state.shift_start_time && Moment(this.state.shift_start_time, 'HH:mm') || null}
                                        onChange={(value) => {this.setState({ shift_start_time: value ? Moment(value).format('HH:mm') : '' })}}
                                        format={'HH:mm'}
                                    />
                                </div> 
                                <div className="details-header" style={{width: '160px'}}>
                                    <div className="mb-10">Shift End Time</div>    
                                    <TimePicker
                                        showSecond={false}
                                        placeholder={'Time'}
                                        className={'wt-150 ht-30'}
                                        value={this.state.shift_end_time && Moment(this.state.shift_end_time, 'HH:mm') || null}
                                        onChange={(value) => {this.setState({ shift_end_time: value ? Moment(value).format('HH:mm') : '' })}}
                                        format={'HH:mm'}
                                    />
                                </div>    
                            </div>
                            <div className="details">
                                <InputText type='number' placeholder = "Total Working Hours" id = {"total_hrs_working"} label = "Total Working Hours" value = {this.state.total_hrs_working} changeHandler = {this.onChangeHandler}/>
                                <div className="details-header" style={{width: '33%'}}>
                                <div className="mb-10"> Shift </div>
                                        <Select
                                            isClearable={true}
                                            className="select"
                                            placeholder={'Shift'}
                                            value={this.state.shift} //{label: mode_type, value: mode_type}}
                                            options={(['1','2','3']).map(obj => { return { label : obj, value: obj}})}
                                            onChange={(value) => this.setState({shift: value})}
                                        />
                                    </div>
                                    <div className="details-header" style={{width: '160px'}}>
                                    <div className="mb-10">Placement From Time</div>    
                                    <TimePicker
                                        showSecond={false}
                                        placeholder={'Time'}
                                        className={'wt-150 ht-30'}
                                        value={this.state.placement_time_from && Moment(this.state.placement_time_from, 'HH:mm') || null}
                                        onChange={(value) => {this.setState({ placement_time_from:value ? Moment(value).format('HH:mm') : '' })}}
                                        format={'HH:mm'}
                                    />
                                </div> 
                                <div className="details-header" style={{width: '160px'}}>
                                    <div className="mb-10">Placement To Time</div>    
                                    <TimePicker
                                        showSecond={false}
                                        placeholder={'Time'}
                                        className={'wt-150 ht-30'}
                                        value={this.state.placement_time_to && Moment(this.state.placement_time_to, 'HH:mm') || null}
                                        onChange={(value) => {this.setState({ placement_time_to: value ? Moment(value).format('HH:mm') : '' })}}
                                        format={'HH:mm'}
                                    />
                                </div>    
                            </div>
                            {this.state.error && <div className="error mr-20" style={{float: 'right'}}>{this.state.error}</div>}
                        <div className = "submit-section">
                            <button className = "add-button discard" onClick = {this.props.toggleAdd5}>Cancel</button>
                            <button className = "add-button" onClick = {this.addOperationTime}>{Object.keys(this.props.editData).length > 0 ? 'Update' : 'Save'}</button>
                        </div>
                    </div>
                </div>
            </div>
            // </div>
        );
    }
}

export default AddOperationTime;