import React, { Component } from 'react'
import '../vendor-mapping/InputModal.less'
import InputText from '../../common/components/input-text/input-text';
import Button from '../button-classic/button-classic';
import Select from "react-select";
import { connect } from 'react-redux';
import ACTIONS from '../../common/action_constant';

class AddEdit extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: props.isEditData ? { ...props.isEditData } : {
                from: '',
                to: '',
                vehicle_type_name: '',
				vehicle_type_id: '',
                body_type_name: '',
                body_type_id: '',
                transporter_name: '',
				transporter_id: '',
                consignee_name: '',
				consignee_id: '',
				consignee_code: ''
			}
		}
	}

	onChangeHandler = (value, id) => {
		const { data } = this.state;
		data[id] = value;
		this.setState({ data });
	}

	onAddVendor = () => {
		const { data } = this.state;
		data.vendor_name.push({
			name: '',
			sap_code: ''
		});
		this.setState({ data });
	}

	onDeleteVendor = (index) => {
		const { data } = this.state;
		data.vendor_name.splice(index, 1);
		this.setState({ data });
	}

	onSelect = (option, key) => {
		const { data } = this.state;
		if(key === 'consignee') {
			data['consignee_name'] = option.branch_name;
			data['consignee_id'] = option.branch_id;
			data['consignee_code'] = option.branch_code;
		} else if(key === 'body_type') {
			data['body_type_name'] = option.name;
			data['body_type_id'] = option.id;
		} else if(key === 'vehicle_type') {
			data['vehicle_type_name'] = option.name;
			data['vehicle_type_id'] = option.id;
		} else if(key === 'transporter') {
			data['transporter_name'] = option.company_name;
			data['transporter_id'] = option.id;
		} else {
			data[key] = option;
		}
        this.setState({ data });
	}

	addLaneVendorDetails = () => {
		const { data } = this.state;
		if (this.props.isEditData) {
			this.props.updateLaneVendorDetails(data);
		} else {
			this.props.addLaneVendorDetails(data);
		}
	}

	enableButton = () => {
		const { data } = this.state;
		let sFlag = false;
		if(!(data['from'] && data['to'] && data['vehicle_type_name'] && data['body_type_name'] && data['transporter_name'] && data['consignee_name'])) {
			sFlag = true;
		}
		return sFlag;
	}

	render() {

		const { data } = this.state;
		const { companyTransporters, netWorkDetails, plantWiseContract } = this.props;
        const vehicleTypes = ((plantWiseContract.vehicle_types || [])[0] || {}).vehicle_types_list || [];
        const bodyTypes = ((plantWiseContract.vehicle_body_types || [])[0] || {}).vehicle_body_types_list || [];
		
		return (
			<div className="vendor_mapping_detail lane-mapping">
				<div className="overlay"></div>
				<div id="modal" className="modal-popup" >
					<div className="pop-heading">
						Add Lane Vendor Mapping
					</div>

					<div className="vendor-detail">

						<div className="details-section">
							<InputText
								placeholder="from"
								label="from"
								value={data.from}
								id={"from"}
								changeHandler={this.onChangeHandler}
							/>
							<InputText
								placeholder="to"
								label="to"
								value={data.to}
								id={"to"}
								changeHandler={this.onChangeHandler}
							/>
                            <div className="select-wrap-depot">
                                <div className="label">Vehicle Type</div>
                                <Select
                                    placeholder="Vehicle Type"
                                    label="Vehicle Type"
                                    options={vehicleTypes}
                                    value={data.vehicle_type_id && (vehicleTypes || []).filter(el => el.id === data.vehicle_type_id) || null}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.id}
                                    onChange={(option) => this.onSelect(option, 'vehicle_type')}
                                />
                            </div>
                            <div className="select-wrap-depot ml-5">
                                <div className="label">Body Type</div>
                                <Select
                                    placeholder="Body Type"
                                    label="Body Type"
                                    options={bodyTypes}
                                    value={data.body_type_id && (bodyTypes || []).filter(el => el.id === data.body_type_id) || null}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.id}
                                    onChange={(option) => this.onSelect(option, 'body_type')}
                                />
                            </div>
                            <div className="select-wrap-depot">
                                <div className="label">Transporter</div>
                                <Select
                                    placeholder="Transporter"
                                    label="Transporter"
                                    options={companyTransporters}
                                    value={data.transporter_name && (companyTransporters || []).find(el => el.company_name === data.transporter_name) || null}
                                    getOptionLabel={option => option.company_name}
                                    getOptionValue={option => option.id}
                                    onChange={(option) => this.onSelect(option, 'transporter')}
                                />
                            </div>
                            <div className="select-wrap-depot">
                                <div className="label">Consignee</div>
                                <Select
                                    placeholder="Consignee"
                                    label="Consignee"
                                    options={netWorkDetails}
                                    value={data.consignee_id && (netWorkDetails || []).find(el => el.branch_id == data.consignee_id) || null}
                                    getOptionLabel={option => option.company_name}
                                    getOptionValue={option => option.location_network_id}
                                    onChange={(option) => this.onSelect(option, 'consignee')}
                                />
                            </div>
						</div>
						<div className={'button_route'}>
							<Button value={'Cancel'} click={this.props.toggle} />
							<Button value={this.props.isEditData ? 'Update' : 'Save'} click={this.addLaneVendorDetails} disabled={this.enableButton()}/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default connect()(AddEdit);
