import ACTION from "../common/action_constant";

const broadcastReducer = (state = {}, action) => {
  let tempState = Object.assign({}, state);
  if (action.type === ACTION.BROADCAST.GET_ALL_EMAILS_DATA) {
    tempState.emailsList = action.emailsList || [];
    //console.log("tempstate:", typeof tempState);
    return tempState;
  } 
  else if (action.type === ACTION.BROADCAST.POST_ALL_EMAILS_DATA) {
    tempState.postData = action.data;
    return tempState;
  }
  else if (action.type === ACTION.BROADCAST.COPY_ALL_EMAILS_DATA) {
     tempState = action.data;
     return tempState;
  }
  return state;
};

export default broadcastReducer;
