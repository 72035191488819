import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./route_import.less";
import Select from 'react-select';
import validate from "../../../../utils/validate";

class RouteImport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedOption: null,
			selectCompany: null,
			csvFile: "",
			error: "",
			fileName: null
		}
	}


	handleChange = (selectedOption) => {
		this.setState({ selectedOption });
	}
	handleChangeOption = (selectCompany) => {
		this.setState({ selectCompany });
	}


	fileUploaded = (event) => {
		const self = this;
		const fileObject = event.target.files[0];
		const reader = new FileReader();
		this.setState({ fileName: fileObject.name });
		if (fileObject.type.indexOf('csv') > 0 || fileObject.type.indexOf('CSV') > 0) {
			// this.setState({error:null});
			this.setState({ csvFile: event.target.files[0] });
		}
		else {
			const err = 'Please upload a valid .csv file';
			this.setState({ error: err });
		}
		reader.onload = (e) => {
		}
		reader.readAsDataURL(fileObject);
	}

	submitCSV = () => {
		if (this.state.csvFile) {
			let errorMsg = "";
			if (!this.state.selectCompany) {
				errorMsg = "Please select the company from the list to upload";
				this.setState({ error: errorMsg });
			}

			if (errorMsg) {
				return;
			}
			let formData = new FormData();
			this.setState({ error: null });
			formData.append('file', this.state.csvFile);
			formData.append('type', this.state.selectCompany.type);
			formData.append('company_id', this.state.selectCompany.value);
			this.props.exportRouteExcel(formData);
			this.setState({ error: "" });

		}
		else {
			const err = 'Please select a .csv file to upload';
			// if(!this.state.error)
			this.setState({ error: err });

		}
	}

	generateRouteExcel = () => {
		let data = this.state.selectedOption ? this.state.selectedOption.type : null;
		let errorMsg = "";
		if (!data) {
			errorMsg = ("Please choose the company from the list to import");
			this.setState({ error: errorMsg });
			return;
		}
		this.props.generateRouteExcel(data);
		this.setState({ error: "" });
	}

	populateCompanyList = () => {
		return (
			this.props.companyList.map((company) => {
				return {
					value: company.id,
					label: company.company_name,
					type: company.company_type
				}
			}
			)
		)
	}

	fileClicked = () => {
		document.getElementById("file").click();
	}
	componentDidMount() {
		const { dispatch } = this.props;
	}
	render() {
		const options = this.props.companyList && this.populateCompanyList();
		const options1 = options;
		return (
			<div className="route-import-root">
				{this.state.error && <label className="error">{this.state.error}</label>}
				<div className="container-1">
					<div className="banner">Generate Route Import Excel</div>
					<div className="header">Transport Service Type</div>
					<div className="sub-container">
						<Select className="select" value={this.state.selectedOption} onChange={this.handleChange} options={options} />
						<div className="generate-button" onClick={this.generateRouteExcel}>Generate Excel</div>
					</div>
				</div>
				<div className="container-1">
					<div className="banner">Upload Route Import Excel</div>
					<div className="file-dropdown-holder">
						<div>
							<div className="header">Company</div>
							<div className="sub-container">
								<Select className="select" value={this.state.selectCompany} onChange={this.handleChangeOption} options={options1} />
							</div>
						</div>
						<div className="file-upload">
							<div className="header">Upload SAP Route Excel</div>
							<div className="sub-container">
								<div className="file-holder">
									<input id="file" type="file" onChange={this.fileUploaded} />
									<span className="file-choose" onClick={this.fileClicked}>Choose File</span>
									<span className="file-name">{this.state.fileName ? this.state.fileName : "No File Chosen"}</span>
								</div>
								<div className="generate-button" onClick={this.submitCSV}>Upload</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}


export default RouteImport;