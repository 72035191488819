import React, { Component } from "react";
import AddItem from "./add-item/add-item";
import EditItem from "./edit-item/edit-item";
import Edit from "../../../../assets/images/edit.svg";
import Delete from "../../../../assets/images/delete.svg";
import Info from "../../../../assets/images/information.svg";
import Button from "../../../home/button-classic/button-classic";
import Search from "../../../common/components/search-text/search-text";
import "./truck.less";
import Cx from "classnames";
import moment from "moment";
import { connect } from "react-redux";
import { getCookie } from "../../../../utils/cookies";
import ACTION from "../../../common/action_constant";

class TruckMaster extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      isEdit: false,
      limit: 20,
      offset: 0,
      editedData: "",
      search: "",
      expiryDate: {
        insurance_validity: 1,
        national_permit_validity: 1,
        fitness_certificate_validity: 1,
        puc_validity: 1,
        goods_permit_validity: 1,
        tax_validity: 1
      }
    };
  }
  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
    this.getTruckDetails();
    this.props.getVehicleSize();
    this.getTruckDriverDetails();
    this.props.getAllBranch();
  };

  getTruckDetails = () => {
    const data = {
      offset: this.state.offset,
      limit: this.state.limit,
    };
    if (this.state.search) {
      data.search = this.state.search;
    }
    this.props.getTruckDetails(data);
  };
  getTruckDriverDetails = () => {
    const data = {
      offset: this.state.offset,
      limit: this.state.limit,
    };
    if (this.state.search) {
      data.search = this.state.search;
    }
    this.props.getTruckDriverDetails(data);
  };
  toggleAdd = (isType, data = "") => {
    if (isType == "Edit") {
      this.setState({ isEdit: !this.state.isEdit, editedData: data });
    } else {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };
  previous = () => {
    if (this.state.offset > 0) {
      this.setState(
        {
          offset: this.state.offset - 20,
        },
        () => {
          const data = {
            limit: this.state.limit,
            offset: this.state.offset,
          };
          this.props.getTruckDetails(data);
        }
      );
    }
  };

  next = () => {
    this.setState(
      {
        offset: this.state.offset + 20,
      },
      () => {
        const data = {
          limit: this.state.limit,
          offset: this.state.offset,
        };
        this.props.getTruckDetails(data);
      }
    );
  };
  downloadTruckDetails = () => {
    this.props.downloadTruckDetails();
    this.props.downloadUrl.click();
  };

  onUpload = () => {
    this.uploadUrl.click();
  };

  uploadTruckDetails = (e) => {
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    this.props.uploadTruckDetails(formData);
  };
  clearFilterOnCancel = (e, type) => {
    this.setState({ search: "" }, () => {
      this.getTruckDetails();
    });
  };
  changeHandler = (val, id) => {
    this.setState({
      [id]: val,
    });
  };

  submitSearch = () => {
    this.getTruckDetails();
  };
  

  downloadTemplate=()=>{
    const { dispatch } = this.props;
    const company_id = JSON.parse(getCookie('user')).company_id;
    dispatch({ 
      type : ACTION.MASTER.VEHICLE_PLANNING.DOWNLOAD_TRUCK_TEMP,
      company_id, 
      onSuccess: ((data) => {
        this.setState({
            downloadLink: data[0].Link
        });
        this.downloadBtn.click();
      })
    });
  }
  downloadDetails=()=>{
    const { dispatch } = this.props;
    const company_id = JSON.parse(getCookie('user')).company_id;
    dispatch({ 
      type : ACTION.MASTER.VEHICLE_PLANNING.DOWNLOAD_TRUCK_DETAILS, 
      company_id,
      onSuccess: ((data) => {
        this.setState({
            downloadLink: data[0].Link
        });
        this.downloadBtn.click();
      })
    });
  }
  
  checkExpiry = (date) => {
    const currentDate = new Date();
    var dateMomentObject = new Date(date);
    var differenceInTime = currentDate.getTime() - dateMomentObject.getTime();
    var differenceInDays = differenceInTime / (1000 * 3600 * 24);
    let colorCoding = '';
    if(differenceInDays < 0 && differenceInDays > -30){
      colorCoding = 'text-orange item-label';
    } else if(differenceInDays > 0){
      colorCoding = 'text-red item-label';
    }
    else {
      colorCoding = 'item-label';
    }
    return colorCoding;
  }

  render() {
    const { attIndex } = this.state; 
    return (
      <div className={"Pfizer-Item"}>
        <div className="header">
          <Search
            className="wt-200"
            id={"search"}
            value={this.state.search}
            placeholder={"Search"}
            changeHandler={this.changeHandler}
            click={this.submitSearch}
            canClear={this.state.search !== ""}
            handleClearFilter={(e) => this.clearFilterOnCancel(e, "search")}
          />
          <Button value={" + Add"} click={this.toggleAdd} />
          <Button value={"Upload"} click={this.onUpload} />
          <input
            type="file"
            style={{ display: "none" }}
            ref={(e) => {
              this.uploadUrl = e;
            }}
            onChange={this.uploadTruckDetails}
            onClick={(e) => {
              e.target.value = null;
            }}
          />
          <Button value={"Download Details"} click={this.downloadDetails} />
          <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
                    
          <Button value={"Download Temp."} click={this.downloadTemplate} />
          <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
                    
        </div>

        <div className="item-table">
          <div
            className="list-section item-header column"
            style={{ minWidth: "fit-content" }}
          >
            <div className="item-label">Sr.No</div>
            <div className="item-label">Vehicle No</div>
            <div className="item-label">Vehicle Age</div>
            <div className="item-label">Type Of Use</div>
            <div className="item-label">Operation Type</div>
            <div className="item-label">Transporter</div>
            <div className="item-label">Location</div>
            <div className="item-label">Driver Name</div>
            <div className="item-label">Driver Contact</div>
            <div className="item-label">Insurance Exp Date</div>
            <div className="item-label">National Permit Exp Date</div>
            <div className="item-label">Fitness Certificate Exp Date</div>
            <div className="item-label">PUC Exp Date</div>
            <div className="item-label">Goods Permit Exp Date</div>
            <div className="item-label">Tax Exp Date</div>
            <div className="item-label">Date of Service</div>
            <div className="item-label">Last KM of Service</div>
            <div className="item-label">Next Service Date</div>
            <div className="item-label">Due KM of Next Service</div>
            <div className="item-label">In House Maintenance Date</div>
            <div className="item-label">
              Demand Maintenace by Driver Date
            </div>
            <div className="item-label">Vehicle Size</div>
            <div className="item-label">Length</div>
            <div className="item-label">Width</div>
            <div className="item-label">Height</div>
            <div className="item-label">Max Load Capacity(%)</div>
            <div className="item-label">Total M2</div>
            <div className="item-label">Total M3</div>
            <div className="item-label">Gps Vendor</div>
            <div className="item-label">Attachments</div>
            <div className="item-label">Actions</div>
          </div>
          {(!this.props.truckDatalist || this.props.truckDatalist.length === 0) && <div className={'no_data_found'}>{'No Data Found'}</div>}
          
          {this.props.truckDatalist &&
            this.props.truckDatalist.map((val, index) => {
              const age = val.truck_age && val.truck_age.split('/');
              return (
                <div
                  className="list-section item-header"
                  style={{ minWidth: "fit-content" }}
                  key={index}
                >
                  <div className="item-label">{index + 1}</div>
                  <div className="item-label">{val.vehicle_reg_no || '-'}</div>
                  <div className="item-label">{age ? `${age[0]} Year(s), ${age[1]} Month(s)` : '-'}</div>
                  <div className="item-label">{val.type_of_use || '-'}</div>
                  <div className="item-label">{val.type_of_operation || '-'}</div>
                  <div className="item-label">{val.transporter || '-'}</div>
                  <div className="item-label">{val.location || '-'}</div>
                  <div className="item-label">{val.driver_name || '-'}</div>
                  <div className="item-label">{val.contact || '-'}</div>
                  <div className={this.checkExpiry(val.insurance_validity)}>{val.insurance_validity ? moment(val.insurance_validity).format("DD/MM/yyyy") : '-'}</div>
                  <div className={this.checkExpiry(val.national_permit_validity)}>{val.national_permit_validity ? moment(val.national_permit_validity).format("DD/MM/yyyy") : '-'}</div>
                  <div className={this.checkExpiry(val.fitness_certificate_validity)}>{val.fitness_certificate_validity ? moment(val.fitness_certificate_validity).format("DD/MM/yyyy")  : '-'}</div>
                  <div className={this.checkExpiry(val.puc_validity)}>{val.puc_validity ? moment(val.puc_validity).format("DD/MM/yyyy")  : '-'}</div>
                  <div className={this.checkExpiry(val.goods_permit_validity)}>{val.goods_permit_validity ? moment(val.goods_permit_validity).format("DD/MM/yyyy")  : '-'}</div>
                  <div className={this.checkExpiry(val.tax_validity)}>{val.tax_validity ? moment(val.tax_validity).format("DD/MM/yyyy")  : '-'}</div>
                  <div className="item-label">{val.last_service_date ? moment(val.last_service_date).format("DD/MM/yyyy")  : '-'}</div>
                  <div className="item-label">{val.last_service_km|| '-'}</div>
                  <div className="item-label">{val.last_service_date ? moment(val.last_service_date).format("DD/MM/yyyy")  : '-'}</div>
                  <div className="item-label">{val.next_service_due_km|| '-'}</div>
                  <div className="item-label">
                    {val.inhouse_maintenance_date ? moment(val.inhouse_maintenance_date).format("DD/MM/yyyy")  : '-'}
                  </div>
                  <div className="item-label">
                    {val.demand_maintenance_date ? moment(val.demand_maintenance_date).format("DD/MM/yyyy") : '-'}
                  </div>
                  <div className="item-label">{val.vehicle_size || '-'}</div>
                  <div className="item-label">{val.length || '-'}</div>
                  <div className="item-label">{val.width || '-'}</div>
                  <div className="item-label">{val.height || '-'}</div>
                  <div className="item-label">{val.load_capacity || '-'}</div>
                  <div className="item-label ">{val.total_m2|| '-'}</div>
                  <div className="item-label ">{val.total_m3|| '-'}</div>
                  <div className="item-label ">{(val.gps_details ? val.gps_details.name : '--')|| ('-')}</div>
                  <div className="item-label theme-color fBold curP" onClick={() => this.setState({openAttachments: true, attIndex: index})}>View Attachments</div>
                  <div className="item-label">
                    <div
                      className={"delete-icon"}
                      style={{ backgroundImage: `url(${Edit})` }}
                      onClick={() => this.toggleAdd("Edit", val)}
                    ></div>
                    <div
                      className={"delete-icon"}
                      style={{ backgroundImage: `url(${Delete})` }}
                      onClick={() => this.props.deleteTruckDetails(val)}
                    ></div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="submit-section-pagination">
          <div className="next">
            <Button value="Next" click={this.next} />
          </div>
          {this.state.offset != 0 && (
            <div className="pre">
              <Button value="Previous" click={this.previous} />
            </div>
          )}
        </div>
        {this.state.isOpen && (
          <AddItem
            toggleAdd={this.toggleAdd}
            addTruckDetails={this.props.addTruckDetails}
            vehicleSizeList={this.props.vehicleSizeList}
            getCompanyTransporters={this.props.getCompanyTransporters}
            getVehicleSize={this.props.getVehicleSize}
            vehicleSize={this.props.vehicleSize}
            companyTransporters={this.props.companyTransporters}
            driverData={this.props.driverData}
            allPlantBranches={this.props.allPlantBranches}
          />
        )}
        {this.state.isEdit && (
          <EditItem
            toggleAdd={this.toggleAdd}
            data={this.state.editedData}
            updateTruckDetails={this.props.updateTruckDetails}
            getCompanyTransporters={this.props.getCompanyTransporters}
            getVehicleSize={this.props.getVehicleSize}
            vehicleSize={this.props.vehicleSize}
            companyTransporters={this.props.companyTransporters}
            getTransporterDetails={this.props.getTransporterDetails}
            driverData={this.props.driverData}
            allPlantBranches={this.props.allPlantBranches}
          />
        )}
        {this.state.openAttachments &&
        <div className="add-user-wrap">
        <div onClick={this.props.toggleAdd} className="overlay"></div>
          <div id="modal" className="modal-popup" style={{width: '400px', minHeight: '300px'}}>
            <div className="pop-heading">Attached Documents</div>
            <div className="content add-item-details">
            {this.props.truckDatalist[attIndex].attachments && this.props.truckDatalist[attIndex].attachments.length > 0 ? 
            (this.props.truckDatalist[attIndex].attachments || []).map((att) => {
                return(
                <div>
                  {att && att.name ? (
                    <a
                      href={att.url}
                      download
                      className={"theme-color fs-12 mt-5"}
                    >
                      {att.name}
                    </a>
                  ) : null}
                </div>
                )}) : 
                <div className="tCenter">No Attachments Found</div>}
            </div>
            <div className="submit-button" style={{float: 'none'}}>
              <div>
                <Button
                  value="Cancel"
                  click={() => this.setState({openAttachments: false, attIndex: ''})}
                />
              </div>
            </div>
          </div>
        </div>}
      </div>
    );
  }
}

export default connect()(TruckMaster);