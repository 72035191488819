import React, { Fragment } from 'react';
import './index.scss';
import BackButton from '../../components/common/Button/BackButton';
import { ReactComponent as DownloadIcon } from '../../icon/downloadIcon.svg';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
const HeaderSection = (props) => {
  const {
    handleOnClickBackBtn,
    handleOnClickDownloadAuditLogsBtn,
    fromDate,
    toDate,
    isFromDateFocused,
    isToDateFocused,
    // dateRangePickerFocused,
    // handleOnFocusedForDateRangePicker,
    // handleOnChangeForDateRangePicker
    handleOnFocusedForFromDate,
    handleOnFocusedForToDate ,
    handleOnChangeForFromDate,
    handleOnChangeForToDate,
    handleOnClickForClearBtn

  } = props;

  const shouldRenderClearBtn = ()=>{
    const C1 = !!fromDate
    const C2 = !!toDate
    if(C1  || C2) {
      return true;
    }
    return false;
  }
  const isFutureDate = (day) => {
    const currentDate = moment();
    return day.isAfter(currentDate, 'day');
  };

  return (
    <Fragment>
      <div className='flex items-center justify-between'>
        {/* left side */}
        <div className='flex items-center gap-15'>
          <BackButton
            className='text-lg'
            onClick={handleOnClickBackBtn}
          />
          <h5
            className='typographyT1'
          >
            Audit
          </h5>
        </div>
        {/* right side */}
        <div className='flex items-center gap-15'>
          <button
            className='outlineBtnT1 flex items-center gap-5 bg-white'

            onClick={handleOnClickDownloadAuditLogsBtn}
          >
            <DownloadIcon
              fill='#627FE9'
              width='20px'
              height='20px'
            />
            Download
          </button>
          <div className='auditDateWrapper' >
            {/* <DateRangePicker
              startDate={fromDate} 
              startDateId="auditStartDate" 
              endDate={toDate} 
              endDateId="auditEndDate" 
              onDatesChange={({ startDate, endDate }) => {
                startDate = !!startDate ? startDate.startOf('day') : null
                endDate = !!endDate ? endDate.endOf('day') : null
                handleOnChangeForDateRangePicker(startDate, endDate)
              }} 
              focusedInput={dateRangePickerFocused} 
              onFocusChange={handleOnFocusedForDateRangePicker}
              isOutsideRange = {()=>false}
              displayFormat="DD/MM/YYYY"
              inputFormat="DD/MM/YYYY"
              showClearDates = {true}
            /> */}

            <SingleDatePicker
              date={fromDate}
              placeholder='From Date'
              id={'auditLogFromDate'}
              onDateChange={(date) => {
                date = !!date ? date.startOf('day') : null
                handleOnChangeForFromDate(date)
              }} 
              focused={isFromDateFocused}
              onFocusChange={({ focused }) => {
                handleOnFocusedForFromDate(focused)
              }}
              isOutsideRange={isFutureDate}
              displayFormat="DD/MM/YYYY"
              numberOfMonths={1}
              key={'From Date'}
            />
            <h6 className='typographyT4'>
              To
            </h6>
            <SingleDatePicker
              date={toDate}
              placeholder='To Date'
              id={'auditLogToDate'}
              onDateChange={(date) => {
                date = !!date ? date.endOf('day') : null
                handleOnChangeForToDate(date)
              }} 
              focused={isToDateFocused}
              onFocusChange={({ focused }) => {
                handleOnFocusedForToDate(focused)
              }}
              isOutsideRange={isFutureDate}
              displayFormat="DD/MM/YYYY"
              numberOfMonths={1}
              key={'To Date'}
            />
            {shouldRenderClearBtn() && <button 
            className='iconBtnT1'
            onClick={handleOnClickForClearBtn}
            >
              X
            </button>}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default HeaderSection