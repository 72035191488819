import React, { Component } from 'react';
import Button from "../../../button-classic/button-classic";
import Delete from "../../../../../assets/images/delete.svg";
import Edit from "../../../../../assets/images/edit.svg";
import AddStandardTime from "./AddStandardTime";

class StandardTime extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false
        }
    }

    componentDidMount() {
        this.getMoves();
        this.props.getMoveStanadardTime();
    }

    getMoves = () => {
        const data = {
            companyId: this.props.company_id,
            branchId: this.props.branch_id
        }
        this.props.getMoves(data);
    }

    addStandardTime = (editData = '') => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen, editData });
    }

    render() {
        const { moveStandardTime, company_id, branch_id, saveStandardTime, updateStandardTime } = this.props;

        return(
            <div className="plants-depots-main-wrap">
                <div className="search-holder">
                <div className="add-plants-section">
                    {moveStandardTime && moveStandardTime.length > 0 ? <Button value="Edit Standard Time" click={() => this.addStandardTime(moveStandardTime)} /> :
                    <Button value="+ Standard Time" click={() => this.addStandardTime('')} />
                    }
                </div>
                </div>
                <div className="heading">
                    <div className="flow-head">Move From</div>
                    <div className="flow-head">Move To</div>
                    <div className="flow-head">Time</div>
                </div>
                {(moveStandardTime || []).map((moveST, index) => {
                return(
                    <div className={"depot-users-list"} key={`gang_${index}`}>
                        <div className="flow-head">{moveST.from_move_name}</div>
                        <div className="flow-head">{moveST.to_move_name}</div>
                        <div className="flow-head">{moveST.Standard_time}</div>
                    </div>)
                })}
                
                {(!moveStandardTime || moveStandardTime.length === 0) && <div className={'no_data_found'}>{'No Data Found'}</div>}

                {this.state.isOpen && <AddStandardTime 
                                        branch_id={branch_id}
                                        company_id={company_id}
                                        indentingMoves={this.props.indentingMoves}
                                        toggleAdd={this.addStandardTime} 
                                        saveStandardTime={saveStandardTime}
                                        editData={this.state.editData}
                                        updateStandardTime={updateStandardTime}
                                    />}
            </div>
        )
    }
}

export default StandardTime;