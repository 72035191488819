import React, { Component } from 'react';
import LrUpload from './LrUpload/lrUpload';
import LrSummary from './LrSummary/LrSummary';
import './lr-mngnt.less';
import { lrManageType } from '../../../utils/constants';
import LrRange from './LrRange/LrRange';
import { LR_FOR_PANTALOON_COMPANY_ID, PANTALOONS_COMPANY_IDS } from '../../../utils/common';
import AwbLrRange from './AwbLrRange/AwbLrRange';
import AwbSummary from './AwbSummary/AwbSummary';
import DigitalLR from './DigitalLR/DigitalLR';

class LrMngnt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LrTabs: [
        {
          label: "LR Range",
          id: "3"
        },
        {
          label: "LR Upload",
          id: "1",
        },
        {
          label: "LR Summary",
          id: "2"
        },
      ],
      AwbLrTabs: [
        {
          label: "Awb Range",
          id: "3"
        },
        {
          label: "Awb Summary",
          id: "2"
        },
      ],
      LrSelectedOption: "3",
      tableData1: [
        { id: 1, name: "LSP 1", fileName: "ABABAB", BoundType: "Inbound", UploadDateTime: "28/JUL/2000, 01:00 PM", SucessFailL: "Success", FailedReason: "NA", SendLr: "Send to monglix" },
        { id: 2, name: "LSP 2", fileName: "BABABA", BoundType: "Inbound", UploadDateTime: "28/JUL/2000, 01:00 PM", SucessFailL: "Fail", FailedReason: "Unloading Failed", SendLr: "Send to monglix" },
      ],
      summaryData: {
        tableSummaryData1: [
          { id: 3, name: "LSP 1", inboundTotal: 3000, inboundAva: 1950, inboundCons: 1000, inboundRev: 50, inboundAvaPer: "65%", inboundThresholdPer: 50, outboundThresholdPer: 50 },
          { id: 4, name: "LSP 2", inboundTotal: 3000, inboundAva: 1950, inboundCons: 1000, inboundRev: 50, inboundAvaPer: "65%", inboundThresholdPer: 50, outboundThresholdPer: 50 },
          { id: 5, name: "LSP 3", inboundTotal: 3000, inboundAva: 1950, inboundCons: 1000, inboundRev: 50, inboundAvaPer: "65%", inboundThresholdPer: 50, outboundThresholdPer: 50 },
        ],
        tableSummaryData2: [
          { id: 3, outboundTotal: 2350, outboundAva: 1950, outboundCons: 3000, outboundRev: 100, outboundAvaPer: "83%", outboundThresholdPer: 50 },
          { id: 4, outboundTotal: 2350, outboundAva: 1950, outboundCons: 3000, outboundRev: 100, outboundAvaPer: "83%", outboundThresholdPer: 50 },
          { id: 5, outboundTotal: 2350, outboundAva: 1950, outboundCons: 3000, outboundRev: 100, outboundAvaPer: "83%", outboundThresholdPer: 50 },
        ],
      },
      mainTabOption : 1
    }
  }

  LrOnTabChange = (LrTabId) => {
    this.setState({
      LrSelectedOption: LrTabId
    })
  }

  onMainTabChange = (id) => {
    this.setState({ mainTabOption : id });
}

  componentWillMount() {
    this.state.LrTabs[0].length = this.state.tableData1.length
    this.state.LrTabs[1].length = this.state.summaryData.tableSummaryData1.length
  }

  render() {

    const isPantaloons = LR_FOR_PANTALOON_COMPANY_ID.includes(this.props.companyId) || PANTALOONS_COMPANY_IDS.includes(this.props.companyId);
    const filteredTabs = this.state.mainTabOption===1?this.state.LrTabs :this.state.AwbLrTabs.filter(tab => this.props.companyType != 2 || tab.id != "3");
    return (
      <div className={'main-LR'}>
        <div>

        {isPantaloons&&<div className={"home-navigation-holder mainTabs"}>
                    {
                        lrManageType.map(tab => {
                        return(<div key={tab.value} className={this.state.mainTabOption === tab.value
                            ? "selected-option options"
                            : "options"} onClick={() => this.onMainTabChange(tab.value)}>{tab.label}</div>)
                        })
                    }
                </div>}


                {(this.state.mainTabOption === 1||this.state.mainTabOption===2) && (
            <div className={'lr-management'}>
              {filteredTabs.map(tab => (
                <div
                  key={tab.id}
                  className={this.state.LrSelectedOption === tab.id ? "LrSelected-option options" : "options"}
                  onClick={() => this.LrOnTabChange(tab.id)}
                >
                  {tab.label}
                </div>
              ))}
            </div>
          )}
        </div>
        {
          this.state.LrSelectedOption === "3" && this.state.mainTabOption===1
          &&
          <LrRange
            tableData={this.state.tableData1}
            dispatch={this.props.dispatch}
            companyId={this.props.companyId}
            //basicDetails = {this.props.basicDetails}
            companyType = {this.props.companyType}
            getBasicDetails={this.props.getBasicDetails}
          />
        }
        {
          this.state.LrSelectedOption === "1"&&this.state.mainTabOption===1
          &&
          <LrUpload
            tableData={this.state.tableData1}
            dispatch={this.props.dispatch}
            companyId={this.props.companyId}
            //basicDetails = {this.props.basicDetails}
            companyType = {this.props.companyType}
            getBasicDetails={this.props.getBasicDetails}
          />
        }
        {
          this.state.LrSelectedOption === "2" &&this.state.mainTabOption===1&&
          <LrSummary
            tableSummary={this.state.summaryData}
            thresholdData={this.state.threshold}
            dispatch={this.props.dispatch}
            companyId={this.props.companyId}
          />

        }
        {
          this.state.LrSelectedOption === "3"&&this.state.mainTabOption===2
          &&
          <AwbLrRange
            tableData={this.state.tableData1}
            dispatch={this.props.dispatch}
            companyId={this.props.companyId}
            //basicDetails = {this.props.basicDetails}
            companyType = {this.props.companyType}
            getBasicDetails={this.props.getBasicDetails}
          />
        }
        {/* {
          this.state.LrSelectedOption === "1"&&this.state.mainTabOption===2
          &&
          <LrUpload
            tableData={this.state.tableData1}
            dispatch={this.props.dispatch}
            companyId={this.props.companyId}
            //basicDetails = {this.props.basicDetails}
            companyType = {this.props.companyType}
            getBasicDetails={this.props.getBasicDetails}
          />
        } */}
        {
          this.state.LrSelectedOption === "2" &&this.state.mainTabOption===2&&
          <AwbSummary
            tableSummary={this.state.summaryData}
            thresholdData={this.state.threshold}
            dispatch={this.props.dispatch}
            companyId={this.props.companyId}
          />

        }
        {
          this.state.LrSelectedOption === "3" && this.props.companyType == 2
          &&
          <DigitalLR
            tableData={this.state.tableData1}
            dispatch={this.props.dispatch}
            companyId={this.props.companyId}
            //basicDetails = {this.props.basicDetails}
            companyType = {this.props.companyType}
            getBasicDetails={this.props.getBasicDetails}
          />
        }
      </div>
    )
  }

}

export default LrMngnt;