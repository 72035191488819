import React, { Component } from 'react';
import Button from "../../../button-classic/button-classic";
import Delete from "../../../../../assets/images/delete.svg";
import Edit from "../../../../../assets/images/edit.svg";
import AddWarehouse from "./add_warehouse";
import Search from '../../../../common/components/search-text/search-text';
import { getEmail, getUserId } from '../../../../../utils/common';
import { withRouter } from 'react-router-dom';

class WarehouseStorage extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
            search: '',
            offset: 0,
            previousWarehouseStorageData : null
        }
    }

    componentDidMount() {
        this.props.getWarehouseDetails();
    }

    addWarehouse = (editData = '') => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen, editData, previousWarehouseStorageData : editData});
    }

    toggleDirectDelete = (warehouse) => {
        const data = {
            warehouse_name: warehouse.warehouse_name,
            plant_code: warehouse.plant_code,
            storage_location: warehouse.storage_location,
            company_id: warehouse.company_id,
            isDelete: true,
            delete_flag : true,
            master_type : 17,
            user_email : getEmail(),
            user_id : getUserId()
        }
        this.props.onAddWarehouse(data);
    }

    previous = () => {
		if (this.state.offset > 0) {
			this.setState({
				offset: this.state.offset - 10
			}, () => {
				this.props.getWarehouseDetails(this.state.search ,this.state.offset);
			});
		}
	}
	next = () => {
		this.setState({
			offset: this.state.offset + 10
		}, () => {
			this.props.getWarehouseDetails(this.state.search, this.state.offset);
		});
	}

    onAuditClick = () => {
        const {history,match,depotCode} = this.props;
        // const companyId = getUserCompanyId()
        const companyId = match.params.id
        // const branchId = match.params.branchId
        const branchCode = depotCode
        const masterType = 17
        const navUrl = `/auditScreen/${companyId}/${masterType}/${branchCode}`;
        history.push(navUrl)
      }

    render() {

        const { warehouseStorageData } = this.props;
        const {previousWarehouseStorageData} = this.state
        return(
            <div className="plants-depots-main-wrap">
                <div className="search-holder">
                <div className="add-plants-section">
                    <div style={{display: 'block', paddingBottom: '15px'}}>
                        <Search id = "search" value = {this.state.search} placeholder = "Search..." changeHandler = {(e) => this.setState({search: e})} click = {() => this.props.getWarehouseDetails(this.state.search, this.state.offset)} canClear={this.state.search !== ''} handleClearFilter={() => this.setState({search: ''}, this.props.getWarehouseDetails('', this.state.offset))}/>
                    </div>
                    <Button value="+ Warehouse" click={() => this.addWarehouse('')} />
                    <input class="button-classic ml-10 mr-10" type="button" value="Download Temp." onClick={() => this.props.downloadWarehouse(false)} />
                    <a className="download-btn" id="warehouseDownloadBtn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.props.downloadLink} download />
                    <button className="button-classic ml-10 mr-10" onClick={() => {this.uploadExcel.click()}}> Upload </button>
                    <input type='file' style ={{display :'none'}} ref={(e) => { this.uploadExcel = e ;}} onChange={this.props.uploadWarehouseDetails} onClick={(e) => {e.target.value = null }}/>
                    <input class="button-classic ml-10 mr-10" type="button" value="Download" onClick={() => this.props.downloadWarehouse(true)} />
                    <a className="download-btn" id="warehouseDownloadBtn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.props.downloadLink} download />
                    <Button value="Audit" click={()=>this.onAuditClick()} />
                </div>
                </div>
                <div className="heading">
                    <div className="flow-head">Warehouse Name</div>
                    <div className="flow-head">Branch Code</div>
                    <div className="flow-head">Storage Location</div>
                    <div className="flow-head">Actions</div>
                </div>
                {(warehouseStorageData || []).map((warehouse, index) => {
                return(
                    <div className={"depot-users-list"} key={`warehouse-${index}`}>
                        <div className="flow-head">{warehouse.warehouse_name}</div>
                        <div className="flow-head">{warehouse.plant_code}</div>
                        <div className="flow-head">{warehouse.storage_location}</div>
                        <div className="flow-head">
                            <div className = "delete-icon" style={{backgroundImage: `url(${Edit})`}} onClick = {() => this.addWarehouse(warehouse)}></div>
                            <div className = "delete-icon" style={{backgroundImage: `url(${Delete})`}} onClick = {() => this.toggleDirectDelete(warehouse)}></div>
                        </div>
                    </div>)
                })}

                {(!warehouseStorageData || warehouseStorageData.length === 0) && <div className={'no_data_found'}>{'No Data Found'}</div>}

                {this.state.isOpen && <AddWarehouse 
                toggleAdd={this.addWarehouse} 
                onAddWarehouse={this.props.onAddWarehouse} 
                data={this.state.editData}
                previousWarehouseStorageData = {previousWarehouseStorageData}
                />}

                <div className="submit-section-pagination">
                    <div className="next">
                        <Button value="Next" click={this.next} />
                    </div>
                    <div className="pre">
                        <Button value="Previous" click={this.previous} />
                    </div>
                </div>
        </div>
        )
    }
}

export default withRouter(WarehouseStorage);