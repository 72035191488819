import React, { Component } from 'react';
import DriverInfo from './Driver-master/DriverMaster'
import TruckInfo from './Truck-master/TruckMaster'

class Vehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Tabs : [
        {
          label: "Driver Details",
          id: "1"
        },
        {
          label: "Vehicle Details",
          id: "2"
        }
        
      ],
      selectedOption : "1"
    }
  }
componentDidMount(){
const { dispatch } = this.props; 

}
onTabChange = (tabId) => {
    this.setState({
      selectedOption : tabId
    })
  }
render() {
    return (
        <div className = "sub-navigation-panel-root">
        <div className = "sub-navigation-holder">
      {
        this.state.Tabs.map(tab => {
          return(<div key={tab.id} className={this.state.selectedOption === tab.id
            ? "selected-option options"
            : "options"} onClick={() => this.onTabChange(tab.id)}>{tab.label}</div>)
        })
      }
    </div>
    {this.state.selectedOption === "1" && (
      <DriverInfo
      getDriverInfo={this.props.getDriverInfo}
      driverIn={this.props.driverInfo}
      deleteDriverInfo={this.props.deleteDriverInfo}
      addDriverInfo={this.props.addDriverInfo}
      updateDriverInfo={this.props.updateDriverInfo}
      downloadDriverTemplate={this.props.downloadDriverTemplate}
      uploadDriverTemplate={this.props.uploadDriverTemplate}
      />
    )}
    {this.state.selectedOption === "2" && 
      <TruckInfo
      getTruckInfo={this.props.getTruckInfo}
      truckInfo={this.props.truckInfo}
      deleteTruckInfo={this.props.deleteTruckInfo}
      addTruckInfo={this.props.addTruckInfo}
      updateTruckInfo={this.props.updateTruckInfo}
      getVehicleTypes={this.props.getVehicleTypes}
      getFuleTypes={this.props.getFuleTypes}
      FuleTypes={this.props.fuleTypesDataList}
      VehicleTypes={this.props.vehicleSizeTypesData}
      downloadVehicleTemplate ={this.props.downloadVehicleTemplate}
      uploadVehicleTemplate={this.props.uploadVehicleTemplate}
      />} 
      
  </div>
);
}
}

export default Vehicle;
