import React, { Fragment, Component } from 'react';
import './index.scss';
import cx from "classnames";
import { ReactComponent as ArrowIcon } from '../../icon/arrowIcon.svg';
import moment from "moment";
import { isValueThere } from '../../../utils';
const data = Array.from({ length: 10 })
const data1 = Array.from({ length: 8 })
const TableSection = (props) => {
  const {
    auditList = [],
    indentingActions,
    indentingChecklists,
    indentingFields,
    indentingMoves,
    indentingUsers,
    depotAndUserDetails,
    indentingReasons,
  } = props

  return (
    <Fragment>
      <div className='tableT1Wrapper' style={{ maxHeight: 'calc(100vh - 290px)' }}>
        <table className='tableT1'>
          <thead className='theadT1'>
            <tr className='trT1'>
              <th className='thT1'>Date</th>
              <th className='thT1'>By</th>
              <th className='thT1'>
                <div
                className='typographyT2 w-max'
                >
                  No of Changes
                </div>
              </th>
              <th className='thT1'>Value</th>
              <th className='thT1'>Previous</th>
              <th className='thT1'>Current</th>
              <th className='thT1'>Action</th>
              <th className='thT1'></th>
            </tr>
          </thead>
          <tbody className='tbodyT1'>
            {
              auditList.map((auditEle, ind) => {
                return <Fragment key={ind}>
                  <TableRow index={ind}
                    auditEle={auditEle}
                    indentingActions={indentingActions}
                    indentingChecklists={indentingChecklists}
                    indentingFields = {indentingFields}
                    indentingMoves = {indentingMoves}
                    indentingUsers = {indentingUsers}
                    depotAndUserDetails = {depotAndUserDetails}
                    indentingReasons = {indentingReasons}
                  />
                </Fragment>
              })
            }

          </tbody>
        </table>

      </div>
    </Fragment>
  )
}

export default TableSection



class TableRow extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  handleToggleOpenClose = () => {
    this.setState((prevState) => {
      const updateState = { ...prevState };
      updateState.isOpen = !prevState.isOpen
      return updateState
    });
  }

  handleGetEditedLogsFirstElement = (edited_logs = []) => {
    let editedLogsFirstElement = {}
    const C1 = Array.isArray(edited_logs) && edited_logs.length > 0
    if (C1) {
      editedLogsFirstElement = edited_logs[0] || {}
    }
    return editedLogsFirstElement
  }

  handleGetEditedLogsExceptFirstElement = (edited_logs = []) => {
    let editedLogsExceptFirstElement = []
    const C1 = Array.isArray(edited_logs) && edited_logs.length > 0
    if (C1) {
      editedLogsExceptFirstElement = edited_logs.filter((_, i) => i !== 0)
    }
    return editedLogsExceptFirstElement
  }


  toPascalCaseWithSpaces = (inputString) => {
    let trimmedString = inputString.trim();
    let cleanedString = trimmedString.replace(/[^a-zA-Z0-9]+/g, ' ');
    let pascalCaseString = cleanedString.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match) {
      return match.toUpperCase();
    });
    return pascalCaseString;
  }

  handleGetValueName = (ele = {}) => {
    let valueName = Object.keys(ele)
    const C1 = Array.isArray(valueName) && valueName.length > 0

    if (C1) {
      valueName = valueName[0].split("-").join(' ')
      valueName = this.toPascalCaseWithSpaces(valueName)
    }
    return Array.isArray(valueName) ? "-" : valueName
  }

  getServiceCategoryLabel(value) {
    switch (value) {
        case 1:
            return 'FTL';
        case 2:
            return 'LCL';
        case 3:
            return 'Courier';
        case 4:
            return 'Container';
        default:
            return '-';
    }
  }

  getIndentTypesLabel(value) {
    switch (value) {
      case 1:
        return 'In-house Cargo';
      case 2:
        return 'External cargo controlled';
      case 3:
        return 'External cargo non-controlled';
      case 4:
        return 'In-house & External';
      case 5:
        return 'Multi Modal';
      default:
        return '-';
    }
  }

  getServiceModeLabel(value) {
    switch (value) {
      case 1:
        return 'Road';
      case 2:
        return 'Full / Mini Rake';
      case 3:
        return 'Rail Container';
      case 9:
        return 'Partial Rake';
      case 8:
        return 'Sea Container';
      default:
        return '-';
    }
  }

  getBondTypeLabel(value) {
    switch (value) {
      case 1:
        return 'Inbound';
      case 2:
        return 'Outbound';
      default:
        return '-';
    }
  }

  getParkingTypes(value) {
    switch (value) {
      case 1:
        return 'Consumer Pack (CP)';
      case 2:
        return 'Loose Pack/Bulk Pack';
      case 3:
        return 'Others - Engineering Spares';
      case 4:
        return 'Others - Packing/Chemicals';
      case 5:
        return 'Internal Movement';
      case 6:
        return 'NON-PO';
      case 7:
        return 'Planner';
      case 8:
        return 'Bonded Sale';
      default:
        return '-'
    }
  }

  getAddressTypes(value) {
    switch (value) {
      case 1:
        return 'Plant';
      case 2:
        return 'Hub';
      case 3:
        return 'CFA';
      case 4:
        return 'Depot';
      case 5:
        return 'I & I';
      case 6:
        return 'Project';
      case 7:
        return 'Dealer';
      case 8:
        return 'HW';
      case 9:
        return 'Export';
      case 10:
        return 'Toll unit';
      case 11:
        return 'Trade';
      case 12:
        return 'Yard';
      case 13:
        return 'Port';
      case 14:
        return 'Customer';
      case 15:
        return 'Distributor';
      case 16:
        return 'Transfer';
      case 17:
        return 'Supplier';
      default:
        return '-'
    }
  }

  getActionsIds = (actionId) => {
    const indentingActions = this.props;
    // const statusObj = (indentingActions.indentingActions || []).find(item => item.action_id === actionId[0])
    const actions = (indentingActions.indentingActions || []).filter(item => actionId.includes(item.action_id))
    const actionNames = actions ? actions.map(obj => obj.name) : [];
    const result = actionNames.length > 0 ? actionNames.join(', ') : '-';
    return result;
  };

  getChecklistsIds = (ChecklistsIds) => {
    const indentingChecklists = this.props;
    const checklists = (indentingChecklists.indentingChecklists || []).filter(item => ChecklistsIds.includes(item.checklist_id))
    const checklistNames = checklists ? checklists.map(obj => obj.name) : [];
    const result = checklistNames.length > 0 ? checklistNames.join(', ') : '-';
    return result;
  };

  getFieldIds = (FieldIds) => {
    const indentingFields = this.props;
    const Fields = (indentingFields.indentingFields || []).filter(item => FieldIds.includes(item.field_id))
    const FieldsName = Fields ? Fields.map(obj => obj.fieldName) : [];
    const uniqueFieldsArray= [...new Set(FieldsName)]; 
    const result = uniqueFieldsArray.length > 0 ? uniqueFieldsArray.join(', ') : '-';
    return result;
  };

  getMoveIds = (MoveIds) => {
    const moveIdsArray = Array.isArray(MoveIds) ? MoveIds : [MoveIds];
    const indentingMoves = this.props;
    const Moves = (indentingMoves.indentingMoves || []).filter(item => moveIdsArray.includes(item.move_id))
    const MovesName = Moves ? Moves.map(obj => obj.name) : '-';
    const result = MovesName.length > 0 ? MovesName.join(', ') : '-';
    return result;
  };

  getUserIds = (userIds) => {
    const indentingUsers = this.props;
    const User = (indentingUsers.indentingUsers || []).filter(item => userIds.includes(item.user_id))
    const userName = User ? User.map(obj => obj.user_name) : [];
    const result = userName.length > 0 ? userName.join(', ') : '-';
    return result;
  }

  getDepIds = (depIds) => {
    const depotAndUserDetails = this.props;
    const Departments = (depotAndUserDetails.depotAndUserDetails || []).filter(item => depIds.includes(item.department_id))
    const DepartmentsName = Departments ? Departments.map(obj => obj.department_name) : [];
    const result = DepartmentsName.length > 0 ? DepartmentsName.join(', ') : '-';
    return result;
  }

  getReasonsIds = (reasonIds) => {
    const indentingReasons = this.props;
    const Reasons = (indentingReasons.indentingReasons || []).filter(item => reasonIds.includes(item.reason_id))
    const ReasonsName = Reasons ? Reasons.map(obj => obj.name) : [];
    const result = ReasonsName.length > 0 ? ReasonsName.join(', ') : '-';
    return result;
  }

  handleGetPreviousValue = (ele = {}) => {
    let key = Object.keys(ele)
    const C1 = Array.isArray(key) && key.length > 0
    if (C1) {
      key = key[0]

      if (key === "yards") {
           let yards = Array.isArray(ele[key].previous_value)
           ?ele[key].previous_value
           :[]
         yards = yards.map((ele) => {
          return ele.yard_name
        })
        return yards.join(",")
      }

      if (key === "terms_and_conditions_doc") {
        // const terms_and_conditions_doc = Array.isArray(ele[key].previous_value)
        //                                  ?ele[key].previous_value
        //                                  :[]

        let terms_and_conditions_doc = []
        if(Array.isArray(ele[key].previous_value)) {
          terms_and_conditions_doc = ele[key].previous_value
        }
        else if (ele[key].previous_value && 
          typeof ele[key].previous_value === "object" && 
          Object.keys(ele[key].previous_value).length > 0) {
            terms_and_conditions_doc = [ele[key].previous_value];
        }

        if(terms_and_conditions_doc.length > 0) {
          return <InfoCardT1 
          key={'previous_value terms_and_conditions_doc'}
          dataList={terms_and_conditions_doc}
          />
        }

        return '-'
      }
      if (key === "company_logo") {
        const company_logo = ele[key].previous_value

        if(!!company_logo) {
          return <InfoCardT1 
          key={'previous_value company_logo'}
          dataList={[{
            name:company_logo.slice(-20),
            url:company_logo
          }]}
          />
        }

        return '-'
      }
      if (key === "vendorDocuments") {
        const vendorDocuments = Array.isArray(ele[key].previous_value)
                                         ?ele[key].previous_value
                                         :[]

        if(vendorDocuments.length > 0) {
          return <InfoCardT1 
          key={'previous_value vendorDocuments'}
          dataList={vendorDocuments}
          />
        }

        return '-'
      }
      

      if (key === "branch_yards") {
        const previous_value = Array.isArray(ele[key].previous_value)
          ? ele[key].previous_value
          : []

          if(previous_value.length > 0) {
            return <InfoCard
            key={'previous_value branch_yards'}
            dataList={previous_value}
            targetKey={'branch_yard_name'}
          />
          }
          
        return '-'
      }

      if (key === "weekly_off") {
        const weekDays = {
          1: "Sunday",
          2: "Monday",
          3: "Tuesday",
          4: "Wednesday",
          5: "Thursday",
          6: "Friday",
          7: "Saturday",
        }
        let parts = ele[key].previous_value.split(",");
        let tempPreviousValue = parts.map(numStr => parseInt(numStr.trim()));
        let previous_value = Array.isArray(tempPreviousValue)
          ? tempPreviousValue
          : []

        previous_value = previous_value.map((ele) => {
          const newEle = {
            label: weekDays[ele],
            value: ele
          }
          return newEle
        })

        if (previous_value.length > 0) {
          return <InfoCard
            key={'previous_value weekly_off'}
            dataList={previous_value}
            targetKey={'label'}
          />
        }

        return '-'
      }

      if((key === "contacts") || (key === "contactsSeekerCompany")){
        const previous_value = Array.isArray(ele[key].previous_value)
        ? ele[key].previous_value
        : []

        if(previous_value.length > 0) {
          return <InfoCardT2
          key={'previous_value contacts'}
          dataList={previous_value}
        />
        }

      return '-'

      }

      if ((key === "network_type") || (key === "chain_type")) {
        const chainType = {
          1: 'External',
          2: 'Internal',
          3: 'All',
        }

        let C1 = typeof ele[key].previous_value === 'number'
        if (C1){
          return chainType[ele[key].previous_value]
        }

        return '-'
      }

      if (key === "movement_type") {
        const movementType = {
          1: 'Inward',
          2: 'Outward',
          3: 'Interdepo',
          4: 'All',
        }

        let C1 = typeof ele[key].previous_value === 'number'
        if (C1) {
          return movementType[ele[key].previous_value]
        }

        return '-'
      }

      if (key === "fitness_certificate_img") {
        const fitness_certificate_img = Array.isArray(ele[key].previous_value)
                                         ?ele[key].previous_value
                                         :[]
        if(fitness_certificate_img.length > 0) {
          return <InfoCardT1 
          key={'previous_value fitness_certificate_img'}
          dataList={fitness_certificate_img}
          />
        }

         return '-'                               
      }

      if (key === "rc_validity_img") {
        const rc_validity_img = Array.isArray(ele[key].previous_value)
                                         ?ele[key].previous_value
                                         :[]
        if(rc_validity_img.length > 0) {
          return <InfoCardT1 
          key={'previous_value rc_validity_img'}
          dataList={rc_validity_img}
          />
        }
        
         return '-'                               
      }

      if (key === "insurance_validity_img") {
        const insurance_validity_img = Array.isArray(ele[key].previous_value)
                                         ?ele[key].previous_value
                                         :[]
        if(insurance_validity_img.length > 0) {
          return <InfoCardT1 
          key={'previous_value insurance_validity_img'}
          dataList={insurance_validity_img}
          />
        }
        
         return '-'                               
      }

      if(key === "gstin_details") {
        const gstin_details = Array.isArray(ele[key].previous_value)
                                     ?ele[key].previous_value
                                      :[]
                                       if(gstin_details.length > 0) {
          return <InfoCardT7 
          key={'previous_value gstin_details'}
          dataList={gstin_details}
          />
        }
        
         return '-'                               
      }

      if(key === "business_segment") {
        const business_segment = Array.isArray(ele[key].previous_value)
                                     ?ele[key].previous_value
                                      :[]
                                       if(business_segment.length > 0) {
          return <InfoCardT5 
          key={'previous_value business_segment'}
          dataList={business_segment}
          />
        }
        
         return '-'                               
      }

      if(key === "establishment_category") {
        const establishment_category = Array.isArray(ele[key].previous_value)
                                     ?ele[key].previous_value
                                      :[]
                                       if(establishment_category.length > 0) {
          return <InfoCardT6 
          key={'previous_value establishment_category'}
          dataList={establishment_category}
          />
        }
        
         return '-'                               
      }

      if(key === "last_fiscal_turnover") {
        const last_fiscal_turnover = Array.isArray(ele[key].previous_value)
                                     ?ele[key].previous_value
                                      :[]
                                       if(last_fiscal_turnover.length > 0) {
          return <InfoCardT8 
          key={'previous_value last_fiscal_turnover'}
          dataList={last_fiscal_turnover}
          />
        }
        
         return '-'                               
      }

      if(key === "approval_types") {
        const approval_types = Array.isArray(ele[key].previous_value)
                                     ?ele[key].previous_value
                                      :[]
                                       if(approval_types.length > 0) {
          return <InfoCardT9 
          key={'previous_value approval_types'}
          dataList={approval_types}
          />
        }
        
         return '-'                               
      }
      if(key === "group_company") {
        const group_company = Array.isArray(ele[key].previous_value)
                                     ?ele[key].previous_value
                                      :[]
                                       if(group_company.length > 0) {
          return <InfoCardT13
          key={'previous_value group_company'}
          dataList={group_company}
          />
        }
        
         return '-'                               
      }

      if(key === "roles") {

        const roles = Array.isArray(ele[key].previous_value)
                                     ?ele[key].previous_value
                                      :[]
                                       if(roles.length > 0) {
          return <InfoCardT11
          key={'previous_value roles'}
          dataList={roles}
          />
        }
        
         return '-'                               
      }

      if(key === "sap_vendor_details") {
        const sap_vendor_details = Array.isArray(ele[key].previous_value)
                                     ?ele[key].previous_value
                                      :[]
        if (sap_vendor_details.length > 0) {
          return <InfoCardT10
            key={'previous_value sap_vendor_details'}
            dataList={sap_vendor_details}
          />
        }
        
         return '-'                               
      }
      if(key === "tax_id") {
        const tax_id = Array.isArray(ele[key].previous_value)
                                     ?ele[key].previous_value
                                      :[]
                                       if(tax_id.length > 0) {
          return <InfoCardT6 
          key={'previous_value tax_id'}
          dataList={tax_id}
          />
        }
        
         return '-'                               
      }

      if (key === "puc_validity_img") {
        const puc_validity_img = Array.isArray(ele[key].previous_value)
                                         ?ele[key].previous_value
                                         :[]
        if(puc_validity_img.length > 0) {
          return <InfoCardT1 
          key={'previous_value puc_validity_img'}
          dataList={puc_validity_img}
          />
        }
        
         return '-'                               
      }

      if (key == "vendor_name") {
        const vendor_name = Array.isArray(ele[key].previous_value)
                                    ?ele[key].previous_value
                                    :[]
        if(vendor_name.length > 0) {
          return <InfoCardT3 
          key={'previous_value vendor_name'}
          dataList={vendor_name}
          /> 
        }
      }

      if (key === "dlName") {
        const dlName = Array.isArray(ele[key].previous_value)
                                         ?ele[key].previous_value
                                         :[]
        if(dlName.length > 0) {
          return <InfoCardT1 
          key={'previous_value dlName'}
          dataList={dlName}
          />
        }
        
         return '-'                               
      }

      if (key === "preferredroute") {
        const preferredroute = Array.isArray(ele[key].previous_value)
                                    ?ele[key].previous_value
                                    :[]
        if(preferredroute.length > 0) {
          return <InfoCardT4 
          key={'previous_value preferredroute'}
          dataList={preferredroute}
          /> 
        }
      }


      if (key == "associated_branch") {
        const associated_branch = Array.isArray(ele[key].previous_value)
                                    ?ele[key].previous_value
                                    :[]
        if(associated_branch.length > 0) {
          return <InfoCardT15 
          key={'previous_value associated_branch'}
          dataList={associated_branch}
          /> 
        }
      }

      if ((key === "driver_detail_validity_date") || 
      (key === "insurance_validity_date") || 
      (key === "vehicle_registration_date") ||
      (key === "fitness_certificate_date") ||
      (key === "puc_validity_date") ||
      (key === "rc_validity_date")
      ){
        let C1 = typeof ele[key].previous_value === 'number'
        if (C1) {
          return [ele[key].previous_value 
          ? moment(ele[key].previous_value).format('DD/MM/YYYY')
          : '-']
        }
      }

      if (key === "service_type") {
        let C1 = typeof ele[key].previous_value === 'number';
        if (C1) {
            return this.getServiceCategoryLabel(ele[key].previous_value);
        }
        return '-';
      }

      if (key === "address_type") {
        let C1 = typeof ele[key].previous_value === 'number';
        if (C1) {
            return this.getAddressTypes(ele[key].previous_value);
        }
        return '-';
      }

      if (key === "indent_type") {
        let C1 = typeof ele[key].previous_value === 'number';
        if (C1) {
            return this.getIndentTypesLabel(ele[key].previous_value);
        }
        return '-';
      }

      if (key === "service_mode") {
        let C1 = typeof ele[key].previous_value === 'number';
        if (C1) {
            return this.getServiceModeLabel(ele[key].previous_value);
        }
        return '-';
      }

      if (key === "bound_type") {
        let C1 = typeof ele[key].previous_value === 'number';
        if (C1) {
            return this.getBondTypeLabel(ele[key].previous_value);
        }
        return '-';
      }

      if (key === "actionsIds") {
        const actionsIds = Array.isArray(ele[key].previous_value)
                              ?ele[key].previous_value
                              :[]
        if (actionsIds) {
          return this.getActionsIds(ele[key].previous_value);
        }                           
      }

      if (key === "checklistIds") {
        const checklistIds = Array.isArray(ele[key].previous_value)
                              ?ele[key].previous_value
                              :[]
        if (checklistIds) {
          return this.getActionsIds(ele[key].previous_value);
        } 
      }

      if (key === "field_ids") {
        const field_ids = Array.isArray(typeof ele[key].previous_value === "string")
                            ?ele[key].previous_value
                            :[]
        if (field_ids) {
          return this.getFieldIds(ele[key].previous_value);
        }                    
      }

      if (key === "next_moves") {
        const next_moves = ele[key].previous_value.map(move => move.move_id);
        if (next_moves.length > 0) {
            return this.getMoveIds(next_moves);
        }
      }

      if (key === "userIds") {
        const userIds = Array.isArray(ele[key].previous_value)
                            ?ele[key].previous_value
                            :[]
        if (userIds) {
          return this.getUserIds(ele[key].previous_value);
        }
      }

      if (key === "departmentIds") {
        const departmentIds = Array.isArray(ele[key].previous_value)
                            ?ele[key].previous_value
                            :[]
        if (departmentIds) {
          return this.getDepIds(ele[key].previous_value);
        }
      }

      if (key === "packing_type") {
        let C1 = typeof ele[key].previous_value === 'number';
        if (C1) {
            return this.getParkingTypes(ele[key].previous_value);
        }
        return '-';
      }

      if (key === "reasons") {
        const reasons = Array.isArray(ele[key].previous_value)
                            ?ele[key].previous_value
                            :[]
        if (reasons) {
          return this.getReasonsIds(ele[key].previous_value);
        }
      }

      if (key === "gpsCompanies"){
        const gpsCompanies = Array.isArray(ele[key].previous_value)
        ?ele[key].previous_value
        :[]
        console.log('gpsCompanies:', gpsCompanies)
        if(!!gpsCompanies && gpsCompanies.length){
            return (!!(gpsCompanies || [])?gpsCompanies[0].gps_company:"-")
        }
      }

      if (key === "is_metro") {
        if (typeof ele[key].previous_value === 'boolean') {
            if (ele[key].previous_value === true) {
                return 'metro';
            } else {
                return 'non-metro';
            }
        } else {
            return '-';
        }
      }

      if (key === "products") {
        const previous_value = Array.isArray(ele[key].previous_value)
        ? ele[key].previous_value
        : []

        if(previous_value.length > 0) {
          return <InfoCardT14
          key={'previous_value contacts'}
          dataList={previous_value}
        />
        }
        return '-'
      }

      const SC1 = typeof ele[key].previous_value === "string"
      const SC2 = typeof ele[key].previous_value === "number"
      const SC4 = typeof ele[key].previous_value === "boolean"
      const SC3 = SC1 || SC2 
      return this.renderingLogic(SC3,SC4, ele[key].previous_value)
      
    }
    return "-"
  }

  renderingLogic = (SC3,SC4, element) =>{
    // return SC3 ? ele[key].previous_value
      //            : (SC4 ? (ele[key].previous_value).toString()
      //            :"-")
    if (SC3) {
      return element
    }
    if (SC4) {
      return element.toString()
    }
    return "-"
  }

  handleGetCurrentValue = (ele = {}) => {
    let key = Object.keys(ele)
    const C1 = Array.isArray(key) && key.length > 0
    if (C1) {
      key = key[0]

      if (key === "yards") {
        const yards = ele[key].current_value.map((ele) => {
          return ele.yard_name
        })
        return yards.join(",")
      }

      if (key === "terms_and_conditions_doc") {
        let terms_and_conditions_doc = []
        if(Array.isArray(ele[key].current_value)) {
          terms_and_conditions_doc = ele[key].current_value
        }
        else if (ele[key].current_value && 
          typeof ele[key].current_value === "object" && 
          Object.keys(ele[key].current_value).length > 0) {
            terms_and_conditions_doc = [ele[key].current_value];
        }

        if(terms_and_conditions_doc.length > 0) {
          return <InfoCardT1 
          key={'current_value terms_and_conditions_doc'}
          dataList={terms_and_conditions_doc}
          />
        }

        return '-'
      }

      if (key === "company_logo") {
        const company_logo = ele[key].current_value

        if(!!company_logo) {
          return <InfoCardT1 
          key={'current_value company_logo'}
          dataList={[{
            name:company_logo.slice(-20),
            url:company_logo
          }]}
          />
        }

        return '-'
      }

      // if (key === "vendorDocuments") {
      //   const vendorDocuments = ele[key].current_value

      //   if(!!vendorDocuments) {
      //     return <InfoCardT1 
      //     key={'current_value vendorDocuments'}
      //     dataList={[{
      //       name:vendorDocuments.slice(-20),
      //       url:vendorDocuments
      //     }]}
      //     />
      //   }

      if (key === "vendorDocuments") {
        const vendorDocuments = Array.isArray(ele[key].current_value)
                                         ?ele[key].current_value
                                         :[]

        if(vendorDocuments.length > 0) {
          return <InfoCardT1 
          key={'current_value vendorDocuments'}
          dataList={vendorDocuments}
          />
        }

        return '-'
      }
      if (key === "branch_yards") {
        const current_value = Array.isArray(ele[key].current_value)
          ? ele[key].current_value
          : []

          if(current_value.length > 0){
            return<InfoCard
            key={'current_value branch_yards'}
            dataList={current_value}
            targetKey={'branch_yard_name'}
          />
          }
         return '-'
      }

      if (key === "weekly_off") {
        const weekDays = {
          1:"Sunday",
          2:"Monday",
          3:"Tuesday",
          4:"Wednesday",
          5:"Thursday",
          6:"Friday",
          7:"Saturday",
          }
          
          let parts = ele[key].current_value.split(",");
          let tempCurrentValue = parts.map(numStr => parseInt(numStr.trim()));
          let current_value = Array.isArray(tempCurrentValue)
              ? tempCurrentValue
              : []

          current_value = current_value.map((ele)=>{
            const newEle = {
              label:weekDays[ele],
             value:ele
            }
            return newEle
         })

          if(current_value.length > 0) {
            return <InfoCard
            key={'current_value weekly_off'}
            dataList={current_value}
            targetKey={'label'}
          />
          }

        return '-'
      }

      if((key === "contacts") || (key === "contactsSeekerCompany")){
        const current_value = Array.isArray(ele[key].current_value)
        ? ele[key].current_value
        : []

        if(current_value.length > 0) {
          return <InfoCardT2
          key={'current_value contacts'}
          dataList={current_value}
        />
        }

      return '-'

      }

      if ((key === "network_type") || (key === "chain_type")) {
        const chainType = {
          1: 'External',
          2: 'Internal',
          3: 'All',
        }

        let C1 = typeof ele[key].current_value === 'number'
        if (C1) {
          return chainType[ele[key].current_value]
        }

        return '-'
      }

      if (key === "movement_type") {
        const movementType = {
          1: 'Inward',
          2: 'Outward',
          3: 'Interdepo',
          4: 'All',
        }

        let C1 = typeof ele[key].current_value === 'number'
        if (C1) {
          return movementType[ele[key].current_value]
        }

        return '-'
      }

      if (key === "fitness_certificate_img") {
        let fitness_certificate_img = []
        if(Array.isArray(ele[key].current_value)) {
          fitness_certificate_img = ele[key].current_value
        }
        else if (ele[key].current_value && 
          typeof ele[key].current_value === "object" && 
          Object.keys(ele[key].current_value).length > 0) {
            fitness_certificate_img = [ele[key].current_value];
        }
                                        
        if(fitness_certificate_img.length > 0) {
          return <InfoCardT1 
          key={'current_value fitness_certificate_img'}
          dataList={fitness_certificate_img}
          />
        }
        
         return '-'                               
      }

      if (key === "gstin_details") {
        const gstin_details = Array.isArray(ele[key].current_value)
                                         ?ele[key].current_value
                                         :[]
        if(gstin_details.length > 0) {
          return <InfoCardT7
          key={'current_value gstin_details'}
          dataList={gstin_details}
          />
        }
        
         return '-'                               
      }

      if (key === "business_segment") {
        const business_segment = Array.isArray(ele[key].current_value)
                                         ?ele[key].current_value
                                         :[]
        if(business_segment.length > 0) {
          return <InfoCardT5 
          key={'current_value business_segment'}
          dataList={business_segment}
          />
        }
        
         return '-'                               
      }

      if (key === "establishment_category") {
        const establishment_category = Array.isArray(ele[key].current_value)
                                         ?ele[key].current_value
                                         :[]
        if(establishment_category.length > 0) {
          return <InfoCardT6 
          key={'current_value establishment_category'}
          dataList={establishment_category}
          />
        }
        
         return '-'                               
      }

      if (key === "last_fiscal_turnover") {
        const last_fiscal_turnover = Array.isArray(ele[key].current_value)
                                         ?ele[key].current_value
                                         :[]
        if(last_fiscal_turnover.length > 0) {
          return <InfoCardT8
          key={'current_value last_fiscal_turnover'}
          dataList={last_fiscal_turnover}
          />
        }
        
         return '-'                               
      }

      if (key === "approval_types") {
        const approval_types = Array.isArray(ele[key].current_value)
                                         ?ele[key].current_value
                                         :[]
        if(approval_types.length > 0) {
          return <InfoCardT9
          key={'current_value approval_types'}
          dataList={approval_types}
          />
        }
        
         return '-'                               
      }

      if (key === "group_company") {
        const group_company = Array.isArray(ele[key].current_value)
                                         ?ele[key].current_value
                                         :[]
        if(group_company.length > 0) {
          return <InfoCardT13
          key={'current_value group_company'}
          dataList={group_company}
          />
        }
        
         return '-'                               
      }

      if (key === "roles") {
        const roles = Array.isArray(ele[key].current_value)
                                         ?ele[key].current_value
                                         :[]
        if(roles.length > 0) {
          return <InfoCardT11
          key={'current_value roles'}
          dataList={roles}
          />
        }
        
         return '-'                               
      }

      if (key === "sap_vendor_details") {
        const sap_vendor_details = Array.isArray(ele[key].current_value)
                                         ?ele[key].current_value
                                         :[]
        if(sap_vendor_details.length > 0) {
          return <InfoCardT10
          key={'current_value sap_vendor_details'}
          dataList={sap_vendor_details}
          />
        }
        
         return '-'                               
      }

      if (key === "tax_id") {
        const tax_id = Array.isArray(ele[key].current_value)
                                         ?ele[key].current_value
                                         :[]
        if(tax_id.length > 0) {
          return <InfoCardT6 
          key={'current_value tax_id'}
          dataList={tax_id}
          />
        }
        
         return '-'                               
      }
      

      if (key === "rc_validity_img") {
        let rc_validity_img = [];
        if (Array.isArray(ele[key].current_value)){
          rc_validity_img = ele[key].current_value
        }
        else if (ele[key].current_value && 
          typeof ele[key].current_value === "object" && 
          Object.keys(ele[key].current_value).length > 0) {
            rc_validity_img = [ele[key].current_value];
        }
                                         
        if(rc_validity_img.length > 0) {
          return <InfoCardT1 
          key={'current_value rc_validity_img'}
          dataList={rc_validity_img}
          />
        }
        
         return '-'                               
      }

      if (key === "insurance_validity_img") {
        let insurance_validity_img = [];

        if(Array.isArray(ele[key].current_value)){
          insurance_validity_img = ele[key].current_value
        }
        else if (ele[key].current_value && 
          typeof ele[key].current_value === "object" && 
          Object.keys(ele[key].current_value).length > 0) {
            insurance_validity_img = [ele[key].current_value];
        }
        if(insurance_validity_img.length > 0) {
          return <InfoCardT1 
          key={'current_value insurance_validity_img'}
          dataList={insurance_validity_img}
          />
        }
        
         return '-'                               
      }

      if (key === "puc_validity_img") {
        let puc_validity_img = [];
    
        if (Array.isArray(ele[key].current_value)) {
          puc_validity_img = ele[key].current_value;
        } else if (ele[key].current_value && 
          typeof ele[key].current_value === "object" && 
          Object.keys(ele[key].current_value).length > 0) {
            puc_validity_img = [ele[key].current_value];
        }
    
        if (puc_validity_img.length > 0) {
            return (
                <InfoCardT1 
                    key={'current_value puc_validity_img'}
                    dataList={puc_validity_img}
                />
            );
        } else {
            return '-';
        }
    }

      if (key == "vendor_name") {
        const vendor_name = Array.isArray(ele[key].current_value)
                                    ?ele[key].current_value
                                    :[]
        if(vendor_name.length > 0) {
          return <InfoCardT3 
          key={'current_value vendor_name'}
          dataList={vendor_name}
          /> 
        }
      }

      if (key === "dlName") {
        let dlName = [];
    
        if (Array.isArray(ele[key].current_value)) {
            dlName = ele[key].current_value;
        } else if (ele[key].current_value && 
          typeof ele[key].current_value === "object" && 
          Object.keys(ele[key].current_value).length > 0) {
            dlName = [ele[key].current_value];
        }
    
        if (dlName.length > 0) {
            return (
                <InfoCardT1 
                    key={'current_value dlName'}
                    dataList={dlName}
                />
            );
        } else {
            return '-';
        }
    }

      if (key == "preferredroute") {
        const preferredroute = Array.isArray(ele[key].current_value)
                                    ?ele[key].current_value
                                    :[]
        if(preferredroute.length > 0) {
          return <InfoCardT4 
          key={'current_value preferredroute'}
          dataList={preferredroute}
          /> 
        }
      }

      if (key == "associated_branch") {
        const associated_branch = Array.isArray(ele[key].current_value)
                                    ?ele[key].current_value
                                    :[]
        if(associated_branch.length > 0) {
          return <InfoCardT15 
          key={'current_value associated_branch'}
          dataList={associated_branch}
          /> 
        }
      }

      if ((key === "driver_detail_validity_date") || 
      (key === "insurance_validity_date") || 
      (key === "vehicle_registration_date") ||
      (key === "fitness_certificate_date") ||
      (key === "puc_validity_date") ||
      (key === "rc_validity_date"))
      {
        let C1 = typeof ele[key].current_value === 'number'
        if (C1) {
          return [ele[key].current_value 
          ? moment(ele[key].current_value).format('DD/MM/YYYY')
          : '-']
        }
      }
      if (key === "service_type") {
        let C1 = typeof ele[key].current_value === 'number';
        if (C1) {
            return this.getServiceCategoryLabel(ele[key].current_value);
        }
        return '-';
      }

      if (key === "address_type") {
        let C1 = typeof ele[key].current_value === 'number';
        if (C1) {
            return this.getAddressTypes(ele[key].current_value);
        }
        return '-';
      }

      if (key === "indent_type") {
        let C1 = typeof ele[key].current_value === 'number';
        if (C1) {
            return this.getIndentTypesLabel(ele[key].current_value);
        }
        return '-';
      }

      if (key === "service_mode") {
        let C1 = typeof ele[key].current_value === 'number';
        if (C1) {
            return this.getServiceModeLabel(ele[key].current_value);
        }
        return '-';
      }

      if (key === "bound_type") {
        let C1 = typeof ele[key].current_value === 'number';
        if (C1) {
            return this.getBondTypeLabel(ele[key].current_value);
        }
        return '-';
      }

      if (key === "actionsIds") {
        const actionsIds = Array.isArray(ele[key].current_value)
                              ?ele[key].current_value
                              :[]
        if (actionsIds) {
          return this.getActionsIds(ele[key].current_value);
        }                           
      }

      if (key === "checklistIds") {
        const checklistIds = Array.isArray(ele[key].current_value)
                              ?ele[key].current_value
                              :[]
        if (checklistIds) {
          return this.getActionsIds(ele[key].current_value);
        } 
      }

      if (key === "field_ids") {
        const field_ids = Array.isArray(typeof ele[key].current_value === "string")
                            ?ele[key].current_value
                            :[]
        if (field_ids) {
          return this.getFieldIds(ele[key].current_value);
        }                    
      }

      if (key === "next_moves") {
        const next_moves = ele[key].current_value.map(move => move.move_id);
        if (next_moves.length > 0) {
            return this.getMoveIds(next_moves);
        }
      }

      if (key === "userIds") {
        const userIds = Array.isArray(ele[key].current_value)
                            ?ele[key].current_value
                            :[]
        if (userIds) {
          return this.getUserIds(ele[key].current_value);
        }
      }

      if (key === "departmentIds") {
        const departmentIds = Array.isArray(ele[key].current_value)
                            ?ele[key].current_value
                            :[]
        if (departmentIds) {
          return this.getDepIds(ele[key].current_value);
        }
      }

      if (key === "packing_type") {
        let C1 = typeof ele[key].current_value === 'number';
        if (C1) {
            return this.getParkingTypes(ele[key].current_value);
        }
        return '-';
      }

      if (key === "reasons") {
        const reasons = Array.isArray(ele[key].current_value)
                            ?ele[key].current_value
                            :[]
        if (reasons) {
          return this.getReasonsIds(ele[key].current_value);
        }
      }

      if (key === "gpsCompanies"){
        const gpsCompanies = Array.isArray(ele[key].current_value)
                              ?ele[key].current_value
                              :[]
        
        if (gpsCompanies){
            return (!!gpsCompanies[0].gps_company?gpsCompanies[0].gps_company:"-")
        }
      }

      if (key === "is_metro") {
        if (typeof ele[key].current_value === 'boolean') {
            if (ele[key].current_value === true) {
                return 'metro';
            } else {
                return 'non-metro';
            }
        } else {
            return '-';
        }
      }

      if (key === "products") {
        const current_value = Array.isArray(ele[key].current_value)
        ? ele[key].current_value
        : []

        if(current_value.length > 0) {
          return <InfoCardT14
          key={'previous_value contacts'}
          dataList={current_value}
        />
        }
        return '-'
      }

      const SC1 = typeof ele[key].current_value === "string"
      const SC2 = typeof ele[key].current_value === "number"
      const SC4 = typeof ele[key].current_value === "boolean"
      const SC3 = SC1 || SC2
      return this.renderingLogic(SC3,SC4, ele[key].current_value)

    }
    return "-"
  }

  shouldRenderArrowIcon = ()=>{
    const {auditEle} =this.props

    const C1 = (auditEle.edited_logs || []).length > 1

    if(C1){
      return true
    }

    return false
  }

  render() {
    const {
      auditEle,
      index
    } = this.props;
    const { isOpen } = this.state;
    const editedLogsFirstElement = this.handleGetEditedLogsFirstElement(auditEle.edited_logs)
    const valueName = this.handleGetValueName(editedLogsFirstElement)
    const previousValue = this.handleGetPreviousValue(editedLogsFirstElement)
    const currentValue = this.handleGetCurrentValue(editedLogsFirstElement)
    return <Fragment>
      <tr className={cx('trT1 cursor-pointer', {
        'bg-EFF2FD': (index + 1) % 2 === 0
      })}
        onClick={() => {
          this.handleToggleOpenClose()
        }}
      >
        <td className='tdT1'>
          <div
            className='typographyT3 w-max'
          >
          {!!auditEle.updated_on
            ? moment(auditEle.updated_on).format('DD/MM/YYYY, HH:mm A')
            : '-'}
          </div>
        </td>
        <td className='tdT1'>
          {auditEle.user_email}
        </td>
        <td className='tdT1'>
          {auditEle.no_of_changes}
        </td>
        <td className='tdT1'>
          <div
            className='typographyT3 w-max'
          >
          {valueName || '-'}
          </div>
        </td>
        <td className='tdT1'>
          {isValueThere(previousValue) ? previousValue : '-'}
        </td>
        <td className='tdT1'>
          {isValueThere(currentValue) ? currentValue : '-'}
        </td>
        <td className='tdT1'>
          {auditEle.action}
        </td>
        <td className='tdT1'>
        {this.shouldRenderArrowIcon() && <ArrowIcon
            fill='#5973D3'
            width='24px'
            height='24px'
            style={{
              transform: isOpen ? 'rotate(-180deg)' : ''
            }}
          />}
        </td>
      </tr>


      {!!isOpen && <Fragment>

        {this.handleGetEditedLogsExceptFirstElement(auditEle.edited_logs).map((ELele, ind) => {
          const valueName = this.handleGetValueName(ELele)
          const previousValue = this.handleGetPreviousValue(ELele)
          const currentValue = this.handleGetCurrentValue(ELele)
          return <Fragment key={ind}>
            <tr className={cx('trT1', {
              'bg-EFF2FD': (index + 1) % 2 === 0
            })}
            >
              <td className='tdT1'></td>
              <td className='tdT1'></td>
              <td className='tdT1'></td>
              <td className='tdT1'>
                <div
                  className='typographyT3 w-max'
                >
                  {valueName || '-'}
                </div>
              </td>
              <td className='tdT1'>
                {isValueThere(previousValue) ? previousValue : '-'}
              </td>
              <td className='tdT1'>
                {isValueThere(currentValue) ? currentValue : '-'}
              </td>
              <td className='tdT1'></td>
              <td className='tdT1'></td>
            </tr>
          </Fragment>
        })}


      </Fragment>}
    </Fragment>
  }
}


const InfoCard = (props) => {
  const {
      dataList 
    , targetKey
  } = props;
  return <Fragment>
    {dataList.map((ele, ind, arr) => {
      return <Fragment key={ind}>
        <div className='typographyT3'>
           {ele[targetKey]}
           {arr.length === ind + 1 ? '.' : ','}
        </div>
      </Fragment>
    })}
  </Fragment>
}

const InfoCardT1 = (props) => {
  const {
    dataList
  } = props;
  return <Fragment>
    {dataList.map((ele, ind, arr) => {
      return <Fragment key={ind}>
        <a
          href={ele.url}
          className='typographyT3'
          target='_blank'
        >
          {ele.name}
        </a>
        {arr.length === ind + 1
          ? ''
          : <Fragment>
            <div className='p-5'>
            </div>
          </Fragment>}
      </Fragment>
    })}

  </Fragment>
}

const InfoCardT2 = (props) => {
  const {
      dataList 
  } = props;
  return <Fragment>
    {dataList.map((ele , ind, arr) => {
      if(ele === undefined || ele === null){
        ele = {}
      }
      return <Fragment key={ind}>
        <table className='m-0'>
          <tbody>
            <tr>
              <th>Name</th>
              <td>{ele.name || ele.nameCompany || '-'}</td>
            </tr>
            <tr>
              <th>Role</th>
             <td>{ele.role || ele.roleCompany || '-'}</td>
            </tr>
            <tr>
              <th>Mobile</th>
             <td>{ele.mobile || ele.mobileCompany || '-'}</td>
            </tr>
            <tr>
              <th>Email</th>
             <td>{ele.email || ele.emailCompany || '-'}</td>
            </tr>
          </tbody>
        </table>
        {arr.length === ind + 1 
        ?'' 
        : <Fragment>
          <div className='p-5'>
          </div>
          </Fragment>}
   
      </Fragment>
    })}
  </Fragment>
}

const InfoCardT3 = (props) => {
  const {
    dataList 
  } = props;
  return <Fragment>
    {dataList.map((ele , ind, arr) => {
      if(ele === undefined || ele === null){
        ele = {}
      }
      return <Fragment key={ind}>
        <table className='m-0'>
          <tbody>
            <tr>
              <th>Name</th>
              <td>{ele.name || '-'}</td>
            </tr>
            <tr>
              <th>Sap code</th>
             <td>{ele.sap_code || '-'}</td>
            </tr>
          </tbody>
        </table>
        {arr.length === ind + 1 
        ?'' 
        : <Fragment>
          <div className='p-5'>
          </div>
          </Fragment>}  
      </Fragment>
    })}
  </Fragment>
} 

const InfoCardT4 = (props) => {
  const {
    dataList
  } = props;
  return <Fragment>
    {dataList.map((ele , ind, arr) => {
      if(ele === undefined || ele === null){
        ele = {}
      }
      return <Fragment key={ind}>
        <table className='m-0'>
          <tbody>
            <tr>
              <th>Source</th>
              <td>{ele.source || '-'}</td>
            </tr>
            <tr>
              <th>Destination</th>
             <td>{ele.destination || '-'}</td>
            </tr>
          </tbody>
        </table>
        {arr.length === ind + 1
        ?''
        : <Fragment>
          <div className='p-5'>
          </div>
          </Fragment>}  
      </Fragment>
    })}
  </Fragment>
}

const InfoCardT7 = (props) => {
  const {
    dataList
  } = props;
  return <Fragment>
    {dataList.map((ele , ind, arr) => {
      if(ele === undefined || ele === null){
        ele = {}
      }
      return <Fragment key={ind}>
        <table className='m-0'>
          <tbody>
            <tr>
              <th>Gstin</th>
              <td>{ele.gstin || '-'}</td>
            </tr>
            <tr>
              <th>State</th>
             <td>{ele.state || '-'}</td>
            </tr>
            <tr>
              <th
              style={{
                width:'max-content'
              }}
              >Gst Mechanism</th>
             <td>{ele.gst_mechanism || '-'}</td>
            </tr>
            <tr>
              <th> 
                <div
                 style={{
                  width:'max-content'
                }}
                >Gst Percentage</div>
                </th>
             <td>{ele.gst_percentage || '-'}</td>
            </tr>
          </tbody>
        </table>
        {arr.length === ind + 1
        ?''
        : <Fragment>
          <div className='p-5'>
          </div>
          </Fragment>}  
      </Fragment>
    })}
  </Fragment>
}

const InfoCardT5 = (props) => {
  const {
    dataList 
  } = props;
  return <Fragment>
    {dataList.map((ele , ind, arr) => {
      if(ele === undefined || ele === null){
        ele = {}
      }
      return <Fragment key={ind}>
        <table className='m-0'>
          <tbody>
            <tr>
              <th>Id</th>
              <td>{ele.id || '-'}</td>
            </tr>
            <tr>
              <th>Name</th>
             <td>{ele.name || '-'}</td>
            </tr>
          </tbody>
        </table>
        {arr.length === ind + 1 
        ?'' 
        : <Fragment>
          <div className='p-5'>
          </div>
          </Fragment>}  
      </Fragment>
    })}
  </Fragment>
}

const InfoCardT6 = (props) => {
  const {
    dataList 
  } = props;
  return <Fragment>
    {dataList.map((ele , ind, arr) => {
      if(ele === undefined || ele === null){
        ele = {}
      }
      return <Fragment key={ind}>
        <table className='m-0'>
          <tbody>
            <tr>
              <th>Id</th>
              <td>{ele.id || '-'}</td>
            </tr>
            <tr>
              <th>Name</th>
             <td>{ele.name || '-'}</td>
            </tr>
          </tbody>
        </table>
        {arr.length === ind + 1 
        ?'' 
        : <Fragment>
          <div className='p-5'>
          </div>
          </Fragment>}  
      </Fragment>
    })}
  </Fragment>
}

const InfoCardT8 = (props) => {
  const {
    dataList 
  } = props;
  return <Fragment>
    {dataList.map((ele , ind, arr) => {
      if(ele === undefined || ele === null){
        ele = {}
      }
      return <Fragment key={ind}>
        <table className='m-0'>
          <tbody>
            <tr>
              <th>Unit</th>
              <td>{ele.unit || '-'}</td>
            </tr>
            <tr>
              <th>Value</th>
             <td>{ele.value || '-'}</td>
            </tr>
            <tr>
              <th>Name</th>
             <td>{ele.name || '-'}</td>
            </tr>
          </tbody>
        </table>
        {arr.length === ind + 1 
        ?'' 
        : <Fragment>
          <div className='p-5'>
          </div>
          </Fragment>}  
      </Fragment>
    })}
  </Fragment>
}

const InfoCardT9 = (props) => {
  const {
    dataList 
  } = props;
  return <Fragment>
    {dataList.map((ele , ind, arr) => {
      if(ele === undefined || ele === null){
        ele = {}
      }
      return <Fragment key={ind}>
        <table className='m-0'>
          <tbody>
            <tr>
              <th>Approval Type</th>
              <td>{ele.approval_type || '-'}</td>
            </tr>
            <tr>
              <th>From</th>
             <td>{ele.from || '-'}</td>
            </tr>
            <tr>
              <th>To</th>
             <td>{ele.to || '-'}</td>
            </tr>
          </tbody>
        </table>
        {arr.length === ind + 1 
        ?'' 
        : <Fragment>
          <div className='p-5'>
          </div>
          </Fragment>}  
      </Fragment>
    })}
  </Fragment>
}

const InfoCardT10 = (props) => {
  const {
    dataList 
  } = props;
  return <Fragment>
    {dataList.map((ele , ind, arr) => {
      return <Fragment key={ind}>
        <table className='m-0'>
          <tbody>
            <tr>
              <th><div style={{width:'max-content'}}>Sap Vendor Code</div></th>
              <td>{ele.sap_vendor_code || '-'}</td>
            </tr>
            <tr>
              <th>Company</th>
             <td>{ele.company || '-'}</td>
            </tr>
            <tr>
              <th><div style={{width:'max-content'}}>Payment Terms</div></th>
             <td>{typeof ele.payment_terms === 'string' ?(ele.payment_terms || '-'): '-'}</td>
            </tr>
            <tr>
              <th>Gstin</th>
             <td>{ele.gstin || '-'}</td>
            </tr>
          </tbody>
        </table>
        {arr.length === ind + 1 
        ?'' 
        : <Fragment>
          <div className='p-5'>
          </div>
          </Fragment>}  
      </Fragment>
    })}
  </Fragment>
}

const InfoCardT11 = (props) => {
  const {
    dataList 
  } = props;
  return <Fragment>
    
        <table className='m-0'>
          <tbody>
            <tr>
              <th className='thBold'>Name</th>
            </tr>
           {dataList.map((ele , ind, arr) => {
            return <Fragment key={ind}>
              <tr>
              <td>{ele.name || '-'}</td>
              </tr>
            </Fragment>
            })}  
          </tbody>
        </table>
      </Fragment>
}

const InfoCardT12 = (props) => {
  const {
    dataList
  } = props;
  return <Fragment>
    {dataList.map((ele , ind, arr) => {
      return <Fragment key={ind}>
              <div className={"audit-text-wrap"} style={{width:'100px'}}>
              {ele.name || '-'}
              </div>
        {arr.length === ind + 1
        ?''
        : <Fragment>
          <div className='p-5'>
          </div>
          </Fragment>}  
      </Fragment>
    })}
  </Fragment>
}

const InfoCardT13 = (props) => {
  const {
    dataList
  } = props;
  return <Fragment>
    {dataList.map((ele , ind, arr) => {
      if(ele === undefined || ele === null){
        ele = {}
      }
      return <Fragment key={ind}>
        <table className='m-0'>
          <tbody>
            <tr>
              <th>Company Id</th>
              <td>{ele.company_id || '-'}</td>
            </tr>
          </tbody>
        </table>
        {arr.length === ind + 1
        ?''
        : <Fragment>
          <div className='p-5'>
          </div>
          </Fragment>}  
      </Fragment>
    })}
  </Fragment>
}

const InfoCardT14 = (props) => {
  const {
      dataList 
  } = props;
  return <Fragment>
    {dataList.map((ele , ind, arr) => {
      if(ele === undefined || ele === null){
        ele = {}
      }
      return <Fragment key={ind}>
        <table className='m-0'>
          <tbody>
            <tr>
              <th>Vehicle Type Name</th>
              <td>{ele.vehicle_type_name || '-'}</td>
            </tr>
            <tr>
              <th>Product Name</th>
              <td>{ele.product_name || '-'}</td>
            </tr>
            <tr>
              <th>Product Id</th>
              <td>{ele.product_id || '-'}</td>
            </tr>
          </tbody>
        </table>
        {arr.length === ind + 1 
        ?'' 
        : <Fragment>
          <div className='p-5'>
          </div>
          </Fragment>}
   
      </Fragment>
    })}
  </Fragment>
}



const InfoCardT15 = (props) => {
  const {
    dataList 
  } = props;
  return <Fragment>
    
        <table className='m-0'>
          <tbody>
            <tr>
              <th className='thBold'>ID</th>
            </tr>
           {dataList.map((ele , ind, arr) => {
            return <Fragment key={ind}>
              <tr>
              <td>{ele || '-'}</td>
              </tr>
            </Fragment>
            })}  
          </tbody>
        </table>
      </Fragment>
}
