import React, { Component } from 'react';
import "./plants-depots-subplants.less";
import Checkbox from "../../../components/checkbox/checkbox";
import SearchText from "../../../common/components/search-text/search-text";
import Addsubplants from "./add-subplants/add-subplants";
import Button from "../../button-classic/button-classic";
import Edit from "../../../../assets/images/edit.svg";
import Delete from "../../../../assets/images/delete.svg";
import Select from 'react-select';
import ACTIONS from "../../../common/action_constant";
import { connect } from "react-redux";
import { AWL_COMPANY_IDS, getEmail, getUserCompanyId } from '../../../../utils/common';
import { withRouter } from 'react-router';

const tk11Options = [
   {
       label: 'Without Vehicle Type',
       value: 1
   },
   {
       label: 'With Vehicle Type',
       value: 2
   }
]
   
class PlantsDepotsSubPlants extends Component {
   constructor(props) {
      super(props);
      this.state = {
         indents: "",
         selectedindentsToEdit: {},
         showEditPopUp: false,
         offset: 0,
         selectedBoundType: "",
         selectedServiceType: "",
         previousSubPlantData:null
      }
   }

   componentDidMount() {
      const { company_id, branch_id } = this.props;
      let data = {
         companyId: company_id,
         branchId: branch_id
      }
      this.props.getSubPlants(data);
      this.getPlantsCategory();
   }
   toggleAdd = () => {
      this.setState({
         showAddComponent: !this.state.showAddComponent,
         editData: null
      })
   }

   
   downloadTempSubplant=(withData)=>{
      const { dispatch } = this.props;
      const param = {
      companyId: this.props.company_id, branchId: this.props.branch_id
      }
      dispatch({ 
      type : ACTIONS.PLANTS.DOWNLOAD_SUBPLANT, 
      param,
      withData,
      onSuccess: ((data) => {
         this.setState({
            downloadLink: data[0].Link
            });
         this.downloadBtn.click();
         })
      });
   }

   uploadSubplantDetails = (e) =>{
      debugger
      const user_email = getEmail()
      console.log('user_email:', user_email)
      let formData = new FormData();
      const { dispatch , company_id, branch_id} = this.props;
      let params = {
         companyId: company_id,
         branchId: branch_id,
         user_email
      }
      formData.append('file', e.target.files[0]);
      formData.append('companyId', company_id);
      formData.append('branchId', branch_id);
      dispatch({
         type :ACTIONS.PLANTS.UPLOAD_SUBPLANT,
         data: formData, 
         params,
         onSuccess: ((data) => {
           if(data)
           this.props.getSubPlants(params);
         })
      })
   }

   onEditSubplant = (subPlant) => {
      this.setState({ editData: subPlant, previousSubPlantData:subPlant, showAddComponent: true })
   }

   onSwitchClick = (subPlant) => {
      const tempSubPlant = JSON.parse(JSON.stringify(subPlant))
      this.props.updateSubPlants({
         ...tempSubPlant,
         is_tk11_active: tempSubPlant.is_tk11_active ? false : true
      })

   }

   onSwitchTaxApp = (subPlant) => {
      const tempSubPlant = JSON.parse(JSON.stringify(subPlant))
      this.props.updateSubPlants({
         ...tempSubPlant,
         tax_applicability: tempSubPlant.tax_applicability ? false : true
      })

   }

   getPlantsCategory = () => {
      const { dispatch ,company_id} = this.props;
      const queryString = `?company_id=${company_id}`;
      dispatch({ type: ACTIONS.PLANTS.GET_ADD_PLANT_CATEGORY, 
        queryString
      });
    }

    onAuditClick = () => {
      const {history,match} = this.props;
      // const companyId = getUserCompanyId()
      const companyId = match.params.id
      const branchId = match.params.branchId
      const masterType = 11
      const navUrl = `/auditScreen/${companyId}/${masterType}/${branchId}`;
      history.push(navUrl)
    }

   render() {
      const { subPlants } = this.props;
      const { selectedBoundType, selectedServiceType,previousSubPlantData } = this.state;
      return (
         <div className="plants-depots-main-wrap">
            {
               this.state.showAddComponent ?
                  <Addsubplants
                     toggleAdd={this.toggleAdd}
                     company_id={this.props.company_id}
                     company_type={this.props.company_type}
                     branch_id={this.props.branch_id}
                     addSubPlants={this.props.addSubPlants}
                     previousSubPlantData={previousSubPlantData}
                     subplantDetails={this.state.editData}
                     updateSubPlants={this.props.updateSubPlants}
                     deleteSubPlants={this.props.deleteSubPlants}
                     plantCategory = {this.props.plantCategory}
                  /> :
                  <div className="suPlantsDetails">
                     <div className="search-holder" style={{width: '75%'}}>
                        {/* <div class="search-text-wrap"></div> */}
                        <input class="button-classic" type="button" value="+ Add Sub Plants" onClick={this.toggleAdd} />
                        <input class="button-classic" type="button" value="Download Temp." onClick={() => this.downloadTempSubplant(false)} />
                        <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
                        <button className="button-classic" onClick={() => {this.uploadExcel.click()}}> Upload </button>
                        <input type='file' style ={{display :'none'}} ref={(e) => { this.uploadExcel = e ;}} onChange={this.uploadSubplantDetails} onClick={(e) => {e.target.value = null }}/>
                        <input class="button-classic" type="button" value="Download" onClick={() => this.downloadTempSubplant(true)} />
                        <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
                        <button className="button-classic" onClick={()=>this.onAuditClick()}>Audit</button>
                     </div>
                     <div className="heading">
                        <div className="head branch">Name</div>
                        <div className="head branch">SAP Code</div>
                        <div className="head branch">Planning Point</div>
                        {
                           AWL_COMPANY_IDS.includes(this.props.company_id) &&
                           <>
                              <div className="head branch">Status</div>
                              <div className="head branch">Tax Applicability</div>
                              <div className="head email">TK11 Structure</div>
                           </>
                        }
                         <div className="head email">Actions</div>
                     </div>
                     {subPlants && subPlants.length == 0 &&
                        <div className="depot-users-list no-records"> No Sub Plants Found. Please Click On Add Flow Button To Create Flow.
                        </div>
                     }
                     {
                        subPlants && subPlants.map((subPlant, index) => {
                           return (
                              <div className="depot-users-list">
                                 <div className="head branch">{subPlant.name}</div>
                                 <div className="head branch">{subPlant.sap_code}</div>
                                 <div className="head branch">{subPlant.planning_point}</div>
                                 {
                                    AWL_COMPANY_IDS.includes(this.props.company_id) &&
                                    <>
                                       <div className="head branch">
                                       <div className="switch">
                                          <input type="checkbox" checked={subPlant.is_tk11_active} id={`Status${index}`} onClick={() => {
                                             this.onSwitchClick(subPlant)
                                          }} />
                                          <label htmlFor={`Status${index}`}></label>
                                       </div>
                                       </div>
                                       <div className="head branch">
                                       <div className="switch">
                                          <input type="checkbox" checked={subPlant.tax_applicability} id={`Tax${index}`} onClick={() => {
                                             this.onSwitchTaxApp(subPlant)
                                          }} />
                                          <label htmlFor={`Tax${index}`}></label>
                                       </div>
                                       </div>
                                       <div className="head email">{subPlant.tk11_structure && ((tk11Options || []).find(el => el.value == subPlant.tk11_structure) || {}).label || ''}</div>
                                    </>
                                 }
                                 <div className={'tabel-header'} style={{width: '90px'}}>
                                    <div className={'delete-icon'} style={{backgroundImage: `url(${Edit})`}} onClick={() => this.onEditSubplant(subPlant)}></div>
                                 </div>
                                 <div className={'tabel-header'} style={{width: '80px'}}>
                                    <div className={'delete-icon'} style={{backgroundImage: `url(${Delete})`}} onClick={() => this.props.deleteSubPlants(subPlant)}/>
                                 </div>
                              </div>
                           )
                        })
                     }
                  </div>
            }
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      subplantTemplate: state.plantsDepots.subplantTemplate || [],
      plantCategory: state.plantsDepots.plantCategory || [],
   };
 };

export default connect(mapStateToProps)(withRouter(PlantsDepotsSubPlants));
