import React, { Component} from 'react';
import './vendorForm.less';
import history from "../history";
import translogo from "../../assets/images/trans-logo.svg";
import Loader from "../common/components/loader/loader";
import validate from "../../utils/validate";


class FormHeader extends Component {
	constructor(props){
        super(props);
        this.state = {
        }
    }

	componentDidMount(){
    }
    
	render() {
		return (
			<div className = "login">
            	<div className="icon"><img src={translogo} /></div>
			</div>
		);
	}
}

export default FormHeader;
