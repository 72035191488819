import React, {PropTypes} from 'react';
import './loader.less';

export default class LoaderComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className = "loader">
        <div className = "loader-img"></div>
      </div>
    )
  }
}
