import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ACTION from "../../common/action_constant";
import "./otherCharges.less";

import MainComponent from "../../home/main/main";
import Button from "../../home/button-classic/button-classic";
import CheckBox from "./../../components/checkbox/checkbox";
import InputText from "../../common/components/input-text/input-text";
import Loader from "../../common/components/loader/loader";
import MSGPopUp from "../../common/components/msg-popup/msg-popup";
import { ToastContainer, toast } from 'react-toastify';
import AddNewOtherCharges from "./AddNewOtherCharges";

import moment from "moment";
import Select from "react-select";

import Save from '../../../assets/images/save.svg'
import Cancel from '../../../assets/images/cancel.svg'
import Edit from '../../../assets/images/edit.svg'
import { getCookie } from "../../../utils/cookies";
import { PANTALOONS_COMPANY_IDS, getUserCompanyId } from '../../../utils/common';

const DELAY_UOM_TYPES = [{ id: 2, label: 'Efficiency', value: 2 },
                        { id: 1, label: 'Days', value: 1 }];

const DEMURRAGE_ITEM = { max: null, min: null, frieght: null, frieght_uom: 'PERCENTAGE' };

const LOADS = [{label: 'LCL', value: 1}, {label: 'FTL', value: 2}, {label: 'Courier', value: 3}, {label:'Container', value: 4}]

const SUB_CHARGE = {
                        charge_id: 2, 
                        levy_logic: "all", 
                        levy_uom: "fixed", 
                        minimum_value: null, 
                        minimum_uom: null, 
                        levy_logic_values: { all: 100 }, 
                        single_slab_key: null, 
                        mapping_slab_keys: null
                    }

class OtherCharges extends Component {
    constructor(props){
        super(props);
        this.state = {
            isAdded: false,
            addItemPopup: false,
            selectedDemurrages: [],
            typeUOM: DELAY_UOM_TYPES[0],
            demurragesData: (props.dataGovernance && props.dataGovernance.otherCharges) ? props.dataGovernance.otherCharges : [],
            showAdvanceFilter: false,
            categories: [],
            modes: [],
            allDropdownTypes: [],
            vendorId: '',
            newDemurrage: {
                id: -1,
                company_id: JSON.parse(getCookie("user")).company_id || '',
                type: "100",
                load_type: '',
                // load_type:'', 
                mode_type: [], 
                transporter: [ ], 
                movement_type: '',
                distribution_type: [ ], 
                charge_id: '',
                charge_name: '',
                charge_uom: '',
                sub_charges: [
                    {
                        charge_id: 1, 
                        levy_logic: '', 
                        levy_uom: '', 
                        minimum_value: null, 
                        minimum_uom: null, 
                        levy_logic_values: { all: 100 }, 
                        single_slab_key: null, 
                        mapping_slab_keys: null
                    }
                ]
            }
        }
    }

    componentDidMount() {
        const Pantaloons = PANTALOONS_COMPANY_IDS.includes(getUserCompanyId())
        this.getDemurrageData();
        this.getAllDropdownData();
        this.getVendorsData();
        if(Pantaloons){
            this.getModeTypesData();
    }
        if(!Pantaloons){
            this.getModes();
    }
    }
    componentWillReceiveProps(nextProps) {
        const { dataGovernance } = this.props;
        let newCategories = [];
        let newModes = [];
          
        if (dataGovernance !== nextProps.dataGovernance) {
            if (dataGovernance.otherCharges !== nextProps.dataGovernance.otherCharges) {
                this.setState({
                    demurragesData: nextProps.dataGovernance.otherCharges
                });
            }
        }

        if(dataGovernance.vendorTypes !=undefined && dataGovernance.modeTypes !=undefined) {
            const { vendorTypes, modeTypes } = dataGovernance;
            if(vendorTypes.length>0 && this.state.categories.length == 0) {
                newCategories = Object.values(vendorTypes.reduce((acc,cur)=>Object.assign(acc,{[cur.company_category_id]:cur}),{}))
                this.setState({
                    categories: newCategories.map(el => ({...el, selected: false, id: el.company_category_id})),
                });
            }
            if(modeTypes.length>0 && this.state.modes.length == 0) {
                newModes = Object.values(modeTypes.reduce((acc,cur)=>Object.assign(acc,{[cur.id]:cur}),{}))
                this.setState({
                    modes: newModes.map(el => ({...el, selected: false})),
                });
            }
        }
    }

    getDemurrageData = () => {
        const { dispatch } = this.props;
        let inputData = {limit: 100, offset: 0, company_id: JSON.parse(getCookie("user")).company_id};
        if (this.state.vendorId) {
            inputData.vendorId = this.state.vendorId;
        }
                
        let categoryFilter = this.state.categories.filter(el => el.selected);
        
        if(categoryFilter.length>0) {
            let cat = [];
            categoryFilter.forEach(element => {
                cat.push(element.id)
            });
            inputData.category = cat;
        }
        let modeFilter = this.state.modes.filter(el => el.selected);
        if(modeFilter.length>0) {
            let mode = [];
            modeFilter.forEach(element => {
                mode.push(element.id)
            });
            inputData.mode_id = mode;
        }
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_MDG_OTHERCHARGES,
            params: inputData,
            onSuccess: (() => { this.setState({ editId: null, isAdded: false  }) })
        })
    }

    getModeTypesData = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_MODE_TYPES,
            params: `?type=Reconciliation`
        })
    }

    getModes = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_RM_MODES_TYPES,
        })
    }

    getVendorsData = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_VENDORS_TYPES,
        })
    }

    getAllDropdownData = () => {
        const { dispatch } = this.props;
        // [1, 2, 3, 4, 5, 6, 7].map((type) => {
        //     return 
            dispatch({
                type: ACTION.DATA_GOVERNANCE.GET_OTHERCHARGES_DROPDOWNLIST,
                // params: `?DropdownType=${type}`,
                onSuccess: ((data) => {
                    if (Object.keys(data).length > 0) {
                        // const { allDropdownTypes } = this.state;
                        // allDropdownTypes[data[0].dropdown_type] = data;
                        this.setState({
                            allDropdownTypes: data
                        })
                        
                    }
                })
            })
        // })
    }

    showPopUp = () => {
        this.setState({
            addItemPopup: !this.state.addItemPopup,
            isAdded: true,
        })
    }

    addNewDemurrage = () => {
        const { newDemurrage } = this.state;
        newDemurrage.sub_charges.push({...SUB_CHARGE});
        this.setState({
            newDemurrage
        })
    }

    cancelNewSlab = () => { 
        this.setState({ 
            isAdded: false,
            addItemPopup: false
        });
        this.resetData();
    }

     isNumber = (value)=> {
        return typeof +value === 'number' && !isNaN(+value);
      }

    renameKey = (object, key, newKey) => {
        const clonedObj = Object.assign({}, object);
    //  const targetKey = clonedObj[key];
        let newKeyLabel = newKey;
        const newObj = {};
        (Object.keys(clonedObj) || []).forEach((val, idx) => {
            if(key === idx) {
                if(this.isNumber(newKeyLabel)){
                    newKeyLabel = `NUMBER_${idx}_CODE${newKeyLabel}`
                }
                newObj[newKeyLabel] = clonedObj[Object.keys(clonedObj)[key]];
            } else {
                newObj[val] = clonedObj[val];
            }
        })
    //   delete clonedObj[Object.keys(clonedObj)[key]];
    //   clonedObj[newKey] = targetKey;
        return newObj;
    }

    onChangeInput = (value, id, type, index, objIndex, levyKey) => {
        const { newDemurrage } = this.state;
        
        if (type === 'vendor_obj' && !PANTALOONS_COMPANY_IDS.includes(getUserCompanyId())) {
            newDemurrage.transporter = (value || []).map((el) => {return el.value});
        }else if(type === 'vendor_obj' && PANTALOONS_COMPANY_IDS.includes(getUserCompanyId())) {
            newDemurrage.transporter = value.value;
        } else if (type === 'mode_type_obj'&& !PANTALOONS_COMPANY_IDS.includes(getUserCompanyId())) {
            newDemurrage.mode_type = (value || []).map((el) => {return el.value});
        }else if (type === 'mode_type_obj'&& PANTALOONS_COMPANY_IDS.includes(getUserCompanyId())) {
            newDemurrage.mode_type = value.value;
        } else if (type === 'charges') {
            newDemurrage.charge_id = value.value;
            newDemurrage.charge_name = value.name;
        } else if (type==='distribution_type' && !PANTALOONS_COMPANY_IDS.includes(getUserCompanyId())) {
            newDemurrage.distribution_type = (value || []).map((el) => {return el.value});
        }else if (type==='distribution_type' && PANTALOONS_COMPANY_IDS.includes(getUserCompanyId())) {
            newDemurrage.distribution_type = value.value;
        } else if (index===undefined) {
            newDemurrage[type] = value;
        } else if (type === 'add_levy') {
            newDemurrage.sub_charges[index].levy_logic_values = { ...newDemurrage.sub_charges[index].levy_logic_values, '': '' }
        } else if (type==='levy_logic_values' || type==='levy_logic_key') {
            if (type==='levy_logic_key') {
                newDemurrage.sub_charges[index].levy_logic_values = this.renameKey(
                                                                        newDemurrage.sub_charges[index].levy_logic_values,
                                                                        objIndex,
                                                                        value
                                                                    );
            } else if (type==='levy_logic_values') {
                newDemurrage.sub_charges[index].levy_logic_values[levyKey] = value;
            }            
        } else {
            newDemurrage.sub_charges[index][type] = value;
        }

        this.setState({
            newDemurrage
        })
    }

    onDeleteLevy = (index, levyKey) => {
        const { newDemurrage } = this.state;
        delete newDemurrage.sub_charges[index].levy_logic_values[levyKey];
        this.setState({ 
            newDemurrage
        })
    }

    editDemurrage = (index, otherChargeObj) => {
        console.log("otherChargeObj",otherChargeObj,index)
        this.setState({
            editId: (this.state.editId===index) ? null : index,
            newDemurrage: {
                ...otherChargeObj, 
                id: -1,
                company_id: JSON.parse(getCookie("user")).company_id || ''
            }
        }, () => {
            this.showPopUp();
        });

    }

    handleCkSelect = (e, id) => {
        const { selectedDemurrages } = this.state;
        if (selectedDemurrages.includes(id)) {
            selectedDemurrages.splice(id, 1);
        } else {
            selectedDemurrages.push(id);
        }
        
        this.setState({ selectedDemurrages });
    }

    handleOnDataUpdate = (e, id, pIndex, eIndex, type) => {
        const { demurragesData } = this.state;
        demurragesData[pIndex].sub_charges[eIndex][type] = e;

        this.setState({
            demurragesData
        })
    }

    getFilteredNumberCode = (obj = {}) => {
        const pattern = /NUMBER_.*?_CODE/;
        const newObj = {};
        for (const key in obj) {
            if (pattern.test(key)) {
                const updatedKey = key.replace(/NUMBER_.*?_CODE/, "");
                newObj[updatedKey] = obj[key];
            } else {
                newObj[key] = obj[key];
            }
        }
        return newObj;
    }

    saveDemurrage = (data) => {
        let { newDemurrage, isAdded, typeUOM } = this.state;
        const { dispatch } = this.props;
        let newData = {...newDemurrage};
        delete newData.id;

        newData.sub_charges = (newData.sub_charges || []).map((sc)=>{
          const newEle = {...sc};
          newEle.levy_logic_values = this.getFilteredNumberCode({...(newEle.levy_logic_values || {})})
          return newEle
        })

        if (!newData.othercharge_id) {
            dispatch({
                type: ACTION.DATA_GOVERNANCE.ADD_NEW_OTHERCHARGES,
                params: newData,
                onSuccess: (() => {
                    this.setState({ isAdded: false, editId: null, addItemPopup: false,newDemurrage:{} });
                    this.getDemurrageData();
                })
            })
        } else {
            this.updateDemurrage(newData);
        }
    }

    updateDemurrage = (data) => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.UPDATE_OTHERCHARGES,
            params: data,
            onSuccess: (() => {
                this.setState({ isAdded: false, editId: null, addItemPopup: false });
                this.resetData();
                this.getDemurrageData();
            })
        });
    }

    deleteCharges = () => {
        const { demurragesData, selectedDemurrages } = this.state;
        const { dispatch } = this.props;
        if (selectedDemurrages.length > 0) {
            dispatch({
                type: ACTION.DATA_GOVERNANCE.DELETE_OTHERCHARGES,
                params: `?company_id=${JSON.parse(getCookie("user")).company_id}`,
                data: {othercharge_id: selectedDemurrages[0]},
                onSuccess: ((data) => {
                    // toast.success(data.result && data.result.message);
                    this.getDemurrageData();
                })
            });
        }
    }

    downloadDelayPenalty = () => {
        const { demurragesData, typeUOM } = this.state;
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.DOWNLOAD_DELAY_PENALTY,
            params: {type: typeUOM.value},
            onSuccess: ((data) => {
                this.setState({
                    downloadLink: data.link
                });
                this.downloadBtn.click();
            })
        })
    }

    handleDelayTypeChange = (value) => {
        let { demurragesData } = this.state;
        // demurragesData = demurragesData.filter((data) => { return data.id ? data : null; })
        this.setState({ 
            typeUOM: value, 
            isAdded: false,
            // demurragesData,
        }, () => {
            this.getDemurrageData();
        })
    }

    uploadPenaltyFile = (e) => {
        let formData = new FormData();
        formData.append('file', e.target.files[0]);
        const { dispatch } = this.props;
        dispatch({ 
            type: ACTION.DATA_GOVERNANCE.UPLOAD_DELAY_PENALTY, 
            params: this.state.typeUOM.value,
            data: formData, 
            onSuccess: (() => {
                this.getDemurrageData();
            })
        });
    }

    closeMsgPopup = () =>{
        const {dispatch} = this.props;
        dispatch({ type : ACTION.MSG_DISPLAY.HIDE_MSG });
    }

    resetData = () => {
        let { newDemurrage } = this.state;
        newDemurrage = {
                id: -1,
                company_id: null,
                type: "100",
                load_type: null, 
                mode_type: [ ], 
                transporter: [ ], 
                movement_type: '',
                distribution_type: [ ], 
                charge_id: '',
                charge_name: '',
                charge_uom: '',
                sub_charges: [
                    {
                        charge_id: null, 
                        levy_logic: "", 
                        levy_uom: "", 
                        minimum_value: null, 
                        minimum_uom: null, 
                        levy_logic_values: { all: 100 }, 
                        single_slab_key: null, 
                        mapping_slab_keys: null
                    }
                ]
            };
        this.setState({ newDemurrage });
    }

    handleAdvanceFilter = () => {
        this.setState({showAdvanceFilter: !this.state.showAdvanceFilter})
    }

    handleTagClick = (key, value) => {
        this.setState({
            [key]: this.state[key].map((el) => value == el.id?{...el, selected: !el.selected}:el)
        })
    }

    handleFilterSubmit = () => {
        this.setState({
            showAdvanceFilter: false
        })
        this.getDemurrageData();
    }

    renderTableBody = (demurragesData) => {
        const { typeUOM, editId, isAdded, selectedDemurrages, allDropdownTypes } = this.state;
        const vendorTypes = this.props.dataGovernance.vendorTypes;
        const modeTypes = this.props.dataGovernance.modeTypes;
        
        return (
          <Fragment>
            {demurragesData && (demurragesData.charge_details || []).length > 0 &&
                (demurragesData.charge_details || []).map((demurrage, pIndex) => {
                    const load_name = LOADS.filter(el => { return el.value===demurrage.load_type }).length>0 ? LOADS.filter(el => { return el.value===demurrage.load_type })[0].label : '';
                    const chargeName = (allDropdownTypes.charges || []).filter(el => { return el.dropdown_value===demurrage.charge_id });
                    const chargeUOM = (allDropdownTypes.charges_uom || []).filter(el => { return el.dropdown_value===demurrage.charge_uom });
                return (
                    <div className="list-section content divTableRow" key={`${demurrage.othercharge_id}-${pIndex}`}>
                        <div className="divTableCell select-row"> 
                        {!isAdded && (pIndex !== editId) && 
                            <input 
                                type="checkbox" 
                                readOnly
                                checked={selectedDemurrages.includes(demurrage.othercharge_id)}
                                onClick={(e) => this.handleCkSelect(e, demurrage.othercharge_id)}
                            />
                        }
                        </div>
                        <div className="divTableCell"> {load_name} </div>
                        {!PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && <div className="divTableCell"> 
                            {(demurrage.mode_type || []).map((obj, index) => {
                                const name = modeTypes.filter(el => el.value===obj);
                                return <div className="mb-5"> {name.length>0 ? name[0].label : null} {index!==(demurrage.mode_type.length-1) ? ', ' : null} </div>
                            } )} 
                        </div>}
                        {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && 
                        <div>
                            {!demurrage.mode_type.length>0 &&
                            <div className="divTableCell"> 
                                {modeTypes.filter(el => el.value === demurrage.mode_type).map((obj, index) => obj.label)}
                            </div>}

                            {<div>
                                {demurrage.mode_type.length>0 &&
                            <div className="divTableCell"> 
                                {(demurrage.mode_type || []).map((obj, index) => {
                                const name = modeTypes.filter(el => el.value===obj);
                                return <div className="mb-5"> {name.length>0 ? name[0].label : null} {index!==(demurrage.mode_type.length-1) ? ', ' : null} </div>
                            } )} 
                            </div>}
                                </div>}
                        </div>
                        }
                        {!PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) &&<div className="divTableCell"> 
                            {(demurrage.transporter || []).map((obj, index) => { 
                                const name = vendorTypes.filter(el => el.value===obj);
                                return <div className="mb-5"> {name.length>0 ? name[0].company : null} {index!==(demurrage.transporter.length-1) ? ', ' : null} </div> 
                            })} 
                        </div>}
                        {/* {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) &&
                         <div className="divTableCell"> 
                        {vendorTypes.find(item => item.value === demurrage.transporter) !== undefined
                        ?vendorTypes.find(item => item.value === demurrage.transporter).label || vendorTypes.find(item => item.value === demurrage.transporter[0]).label
                        : ''}
                        </div>} */}
                        {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && (
                            <div className="divTableCell">
                                {Array.isArray(demurrage.transporter) && demurrage.transporter.length === 1 ? (
                                    vendorTypes.find(item => item.value === demurrage.transporter[0]) && vendorTypes.find(item => item.value === demurrage.transporter[0]).label
                                ) :
                                    vendorTypes.find(item => item.value === demurrage.transporter) !== undefined && vendorTypes.find(item => item.value === demurrage.transporter).label
                                }
                            </div>
                        )}
                        {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && 
                         <div className="divTableCell">
                           {demurrage.bound_type==1 ? 'Inbound' : demurrage.bound_type==2 ? 'Outbound' : '-'}   
                         </div>
                        }
                        {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && 
                        <div className="divTableCell">
                            {demurrage.distribution_type}
                        </div>}
                        {!PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) &&
                        <div className="divTableCell"> 
                            {demurrage.movement_type} 
                        </div>}
                        {!PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && 
                        <div className="divTableCell">
                            {demurrage.distribution_type.join(', ')}</div>}
                        <div className="divTableCell" style={{ width: '150px' }}> 
                            <div className="mb-5 fs-11"><span className="header-color">Type:</span> {demurrage.charge_id} </div>
                            <div className="mb-5 fs-11"><span className="header-color">Name:</span> {chargeName.length > 0 ? chargeName[0].dropdown_name : demurrage.charge_id} </div>
                            <div className="mb-5 fs-11"><span className="header-color">UOM:</span> {chargeUOM.length > 0 ? chargeUOM[0].dropdown_name : demurrage.charge_uom} </div>
                        </div>
                        <div className="divTableCell" style={{ width: '230px' }}>
                            {(demurrage.sub_charges || []).map((obj) => {
                                return (<Fragment> 
                                    <div className="mb-5 fs-11"><span className="header-color">Levy: </span>{obj.levy_logic} </div>
                                    <div className="mb-5 fs-11"><span className="header-color">Levy UOM:</span> {obj.levy_uom} </div>
                                    {obj.minimum_value && <div className="mb-5 fs-11"><span className="header-color">Min. value: </span>{obj.minimum_value || '-'} </div>}
                                    {obj.levy_uom && <div className="mb-5 fs-11"><span className="header-color">Value UOM:</span> {obj.levy_uom} </div>}
                                    <div className="levy-logic-container">
                                    {Object.keys(obj.levy_logic_values || {}).map((levy) => {
                                        return ( <div className="fs-11"> {levy} : {obj.levy_logic_values[levy]} </div> )
                                    }) }
                                    </div>
                                </Fragment>)
                            })}
                        </div>
                        <div className="divTableCell"> 
                        {(editId===pIndex) 
                            ?
                            <>
                                <div className={'action-icon'} style={{backgroundImage: `url(${Save})`}} onClick={() => this.saveDemurrage(demurrage)} />
                                <div className={'action-icon'} style={{backgroundImage: `url(${Cancel})`}} onClick ={() => this.setState({editId: null})} />
                            </>
                            :
                            <div className={'action-icon'} style={{backgroundImage: `url(${Edit})`}} onClick={() => this.editDemurrage(pIndex, demurrage)} />
                        }
                        </div>
                    </div>
                )
            })}
          </Fragment>
        )
    }

    onDeleteCharges = (index) => {
        const { newDemurrage } = this.state;
        newDemurrage.sub_charges.splice(index, 1);
        this.setState({ newDemurrage });
    }
    
    render() {

        const { addItemPopup, typeUOM, newDemurrage, demurragesData, isAdded } = this.state;
        const { dataGovernance } = this.props;
        let allDemurrageData = demurragesData || [];

        return (
            <div className="discount-wrap demurrages">
              <ToastContainer />
                <div className="wrapper">
                    <MainComponent>
                        {this.props.loader.isLoading && <Loader />}
                        {this.props.msgpopup.active && <MSGPopUp close = {this.closeMsgPopup} type = {this.props.msgpopup.type} msg = {this.props.msgpopup.msg}/>}
                        <div className="sub-heading-section"> 
                            <div className="sub-heading"> Other Charges </div>
                        </div>

                        <div>
                            <div className="cta-action-btns">

                                <Button value="Add Charges" click={this.showPopUp} />
                                <button className="button-classic" 
                                    onClick={()=>this.deleteCharges()}
                                >
                                    Delete Record(s)
                                </button>
                            </div>
                            {/*<div className="cta-action-btns cta-btn-right">
                                <Button value="Advanced Filters" click={() => this.handleAdvanceFilter()} />
                                
                            </div>*/}
                            <div className="cta-action-btns cta-btn-right filterSelect">
                                <Select
                                    isClearable={true}
                                    className="select"
                                    placeholder={'Vendor Select'}
                                    options={dataGovernance.vendorTypes || []}
                                    value={(dataGovernance.vendorTypes || []).filter(obj => {return obj.value===this.state.vendorId})}
                                    onChange={(value) => { 
                                        this.setState({ 
                                            offset: 0, vendorId: value===null ? '' : value.value 
                                        }, () => { this.getDemurrageData() }) 
                                    }}
                                />
                            </div>

                            <div className="divTable company-list-wrap">
                                <div className="list-section table-header divTableHeading divTableRow">
                                    <div className="divTableCell select-row"> </div>
                                    <div className="divTableCell"> Load type </div>
                                    <div className="divTableCell"> Service Mode </div>
                                    <div className="divTableCell"> Vendors </div>
                                    {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && <div className="divTableCell"> Bound Type </div>}
                                    {!PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && <div className="divTableCell"> Movement Type </div>}
                                    <div className="divTableCell"> Distribution Type </div>
                                    <div className="divTableCell"> Charges </div>
                                    <div className="divTableCell"> Sub Charges </div>
                                    <div className="divTableCell"> Action </div>
                                </div>
                                <div className="divTableBody">
                                    {this.renderTableBody(allDemurrageData)}
                                </div>
                            </div>
                            {addItemPopup &&
                                <AddNewOtherCharges
                                    newDemurrage={newDemurrage}
                                    typeUOM={typeUOM}
                                    onChangeInput={this.onChangeInput}
                                    addNewSlabs={this.addNewDemurrage}
                                    cancelNewSlab={this.cancelNewSlab}
                                    handleSave={this.saveDemurrage}
                                    modesList={dataGovernance.modeTypes || []}
                                    vendorsList={dataGovernance.vendorTypes || []}
                                    allDropdownTypes={this.state.allDropdownTypes}
                                    onDeleteLevy={this.onDeleteLevy}
                                    onDeleteCharges={this.onDeleteCharges}
                                />
                            }
                        </div>
                    </MainComponent>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loader: state.loader,
        msgpopup: state.msgpopup,
        dataGovernance: state.dataGovernance,
    }
}

export default connect(mapStateToProps)(OtherCharges);
