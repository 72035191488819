import React, { useState, useEffect } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

const MultiSelectAll = ({options, defaultValue, check, selectedOptions, setSelectedOptions}) => {
//   const [selectedOptions, setSelectedOptions] = useState(value);

  useEffect(() => {
    setSelectedOptions(defaultValue);
  }, []);

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
      return `${placeholderButtonLabel}: ${value.length} selected`;
  }

  function onChange(value, event) {
      this.setState(value);
      console.log(value, "qazpl") 
      console.log(selectedOptions, "qazpl123") 
  }

  return (
    <ReactMultiSelectCheckboxes
      options={options} 
      value={selectedOptions}
      onChange={(value)=>setSelectedOptions(value)}
    //   onChange={onChange}
    //   setState={setSelectedOptions}
    />
  );
};

export default MultiSelectAll;
