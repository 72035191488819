import React, { Component, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import ACTION from "../../common/action_constant";
import "./delayPenaltyStructure.less";
import "./AdvanceFilter.css";
import MainComponent from "../../home/main/main";
import Button from "../../home/button-classic/button-classic";
import CheckBox from "./../../components/checkbox/checkbox";
import InputText from "../../common/components/input-text/input-text";
import Loader from "../../common/components/loader/loader";
import MSGPopUp from "../../common/components/msg-popup/msg-popup";

import AddNewSlabs from "./AddNewSlabs";

import Select from "react-select";
import AdvanceFilter from './AdvanceFilter'
import Save from '../../../assets/images/save.svg'
import Cancel from '../../../assets/images/cancel.svg'
import Edit from '../../../assets/images/edit.svg';
import { getCookie } from "../../../utils/cookies";
import { DISTRIBUTION_TYPE_DELAY_PENALTY_DEFINE_SLAB, PANTALOONS_COMPANY_IDS, SERVICE_TYPE_DELAY_PENALTY_DEFINE_SLAB, getUserCompanyId } from '../../../utils/common';

const DELAY_UOM_TYPES = [{ id: 2, label: 'Efficiency', value: 2 },
{ id: 1, label: 'Days', value: 1 }];

const SLAB_ITEM = { max: null, min: null, penalty_percentage: null, isBusinessStop: false };



class DelayPenaltyStructure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addItemPopup: false,
            typeUOM: DELAY_UOM_TYPES[0],
            selectedPenalties: [],
            newSlabData: {
                id: -1,
                type: DELAY_UOM_TYPES[0].value,
                company_category_id: null,
                company_category: null,
                vendor_name: null,
                vendor_id: null,
                vendor_code: null,
                vendor_email: null,
                mode_type: null,
                distribution_type: null,
                service_type: null,
                mode_id: null,
                seeker_id: null,
                same_city_logic: false,
                delay_penalty_ontime_efficiency: {
                    id: 2,
                    name: "Efficiency",
                    vendor_ontime_efficiency: [{ ...SLAB_ITEM }]
                },
                delay_penalty_days: {
                    id: 1,
                    name: "Days",
                    vendor_delay_penalty_days: [{ ...SLAB_ITEM }]
                }
            },
            isAdded: false,
            delayPenaltyData: (props.dataGovernance && props.dataGovernance.delayPenaltyData) ? props.dataGovernance.delayPenaltyData : [],
            showAdvanceFilter: false,
            categories: [],
            modes: [],
            setDistributionType: [],
            offset: 0,
            limit: 10
        }
    }

    componentDidMount() {
        const Pantaloons = PANTALOONS_COMPANY_IDS.includes(getUserCompanyId())
        this.getPenaltyData();
        this.getVendorsData();
        if (Pantaloons) {
            this.getModeTypesData();
        }
        this.getAllDropdownData();
        if (!Pantaloons) {
            this.getModes();
        }
    }

    componentWillReceiveProps(nextProps) {
        const { dataGovernance } = this.props;
        let newCategories = [];
        let newModes = [];

        if (dataGovernance !== nextProps.dataGovernance) {
            if (dataGovernance.delayPenaltyData !== nextProps.dataGovernance.delayPenaltyData) {
                this.setState({
                    delayPenaltyData: nextProps.dataGovernance.delayPenaltyData
                });
            }
        }

        if (dataGovernance.vendorTypes != undefined && dataGovernance.modeTypes != undefined) {
            const { vendorTypes, modeTypes } = dataGovernance;
            if (vendorTypes.length > 0 && this.state.categories.length == 0) {
                newCategories = Object.values(vendorTypes.reduce((acc, cur) => Object.assign(acc, { [cur.company_category_id]: cur }), {}))
                this.setState({
                    categories: newCategories.map(el => ({ ...el, selected: false, id: el.company_category_id })),
                });
            }
            if (modeTypes.length > 0 && this.state.modes.length == 0) {
                newModes = Object.values(modeTypes.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {}))
                this.setState({
                    modes: newModes.map(el => ({ ...el, selected: false })),
                });
            }
        }
    }

    getPenaltyData = () => {
        const { limit, offset } = this.state;
        const { dispatch } = this.props;
        let inputData = {
            type: this.state.typeUOM.value,
            company_id: `${JSON.parse(getCookie("user")).company_id}`,
            limit: limit,
            offset: offset,
        };

        let categoryFilter = this.state.categories.filter(el => el.selected);

        if (categoryFilter.length > 0) {
            let cat = [];
            categoryFilter.forEach(element => {
                cat.push(element.id)
            });
            inputData.category = cat;
        }
        let modeFilter = this.state.modes.filter(el => el.selected);
        if (modeFilter.length > 0) {
            let mode = [];
            modeFilter.forEach(element => {
                mode.push(element.id)
            });
            inputData.mode_id = mode;
        }
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_DELAY_PENALTY,
            params: inputData,
            onSuccess: (() => { this.setState({ editId: null, isAdded: false }) })
        })
    }

    handleServiceCityToggleChange = (data) => {
        const { newSlabData } = this.state;
        this.setState({
            newSlabData: {
                ...newSlabData,
                same_city_logic: data
            }
        });
    }

    getModeTypesData = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_MODE_TYPES,
            params: `?type=Reconciliation`
        })
    }

    getModes = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_RM_MODES_TYPES,
        })
    }

    getVendorsData = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_VENDORS_TYPES,
        })
    }


    showPopUp = () => {
        this.resetData();
        this.setState({
            addItemPopup: !this.state.addItemPopup
        })
    }

    addNewSlabs = () => {
        const { newSlabData, typeUOM } = this.state;
        if (typeUOM && typeUOM.id && typeUOM.id === 2) {
            newSlabData.delay_penalty_ontime_efficiency.vendor_ontime_efficiency.push({ ...SLAB_ITEM });
        } else {
            newSlabData.delay_penalty_days.vendor_delay_penalty_days.push({ ...SLAB_ITEM });
        }
        this.setState({
            newSlabData
        })
    }

    handleSave = (data) => {
        this.editPenalty(0);

        this.setState({
            isAdded: true,
            addItemPopup: false,
        });
    }

    cancelNewSlab = () => {
        this.setState({
            isAdded: false,
            addItemPopup: false
        });
        this.resetData();
    }

    onChangeInput = (value, id, type, index) => {
        const { newSlabData, typeUOM } = this.state;
        let item = {};
        if (typeUOM.id === 2) {
            if (type === 'vendor_obj') {
                newSlabData.vendor_name = value.company;
                newSlabData.vendor_id = value.company_id;
                newSlabData.vendor_code = value.vendor_code;
                newSlabData.company_category = value.company_category;
                newSlabData.company_category_id = value.company_category_id;
                newSlabData.seeker_id = value.seeker_id;
            } else if (type === 'mode_type_obj') {
                newSlabData.mode_type = value.name;
                newSlabData.mode_id = value.id;
            } else if (type === 'vendor_email') {
                newSlabData.vendor_email = value;
            } else if (type === 'bound_type') {
                newSlabData.bound_type = value;
            } else if (type === 'distribution_type') {
                newSlabData.distribution_type = value;
            }
            // else if (type === 'service_type'){
            //     newSlabData.service_type = value;
            // }
            else {
                newSlabData.delay_penalty_ontime_efficiency.vendor_ontime_efficiency[index][type] = value;
            }
        } else {
            if (type === 'vendor_obj') {
                newSlabData.vendor_name = value.company;
                newSlabData.vendor_id = value.company_id;
                newSlabData.vendor_code = value.vendor_code;
                newSlabData.company_category = value.company_category;
                newSlabData.company_category_id = value.company_category_id;
                newSlabData.seeker_id = value.seeker_id;
            } else if (type === 'mode_type_obj') {
                newSlabData.mode_type = value.name;
                newSlabData.mode_id = value.id;
            } else if (type === 'vendor_email') {
                newSlabData.vendor_email = value;
            } else if (type === 'bound_type') {
                newSlabData.bound_type = value;
            } else if (type === 'distribution_type') {
                newSlabData.distribution_type = value.value;
            }
            else if (type === 'service_type') {
                newSlabData.service_type = value.value;
            }
            else {
                newSlabData.delay_penalty_days.vendor_delay_penalty_days[index][type] = value;
            }
        }

        this.setState({
            newSlabData
        })
    }

    editPenalty = (index) => {
        this.setState({
            editId: (this.state.editId === index) ? null : index
        });
    }

    handleCkSelect = (e, id) => {
        const { selectedPenalties } = this.state;
        if (selectedPenalties.includes(id)) {
            selectedPenalties.splice(id, 1);
        } else {
            selectedPenalties.push(id);
        }

        this.setState({ selectedPenalties });
    }

    handleOnDaysChange = (e, id, pIndex, eIndex) => {
        const { delayPenaltyData } = this.state;
        delayPenaltyData[pIndex].delay_penalty_days.vendor_delay_penalty_days[eIndex].penalty_percentage = e;

        this.setState({
            delayPenaltyData
        })
    }

    handleOnEffChange = (e, id, pIndex, eIndex) => {
        const { delayPenaltyData } = this.state;
        delayPenaltyData[pIndex].delay_penalty_ontime_efficiency.vendor_ontime_efficiency[eIndex].penalty_percentage = e;

        this.setState({
            delayPenaltyData
        })
    }

    savePenalty = (penalty) => {
        console.log("on save", penalty)
        let { newSlabData, isAdded, typeUOM } = this.state;
        const { dispatch } = this.props;
        const newData = { ...newSlabData };
        delete newData.id;

        if (typeUOM.value === 1) {
            delete newData.delay_penalty_ontime_efficiency;
        } else {
            delete newData.delay_penalty_days;
        }
        if (isAdded) {
            dispatch({
                type: ACTION.DATA_GOVERNANCE.POST_DELAY_PENALTY,
                params: newData,
                onSuccess: (() => {
                    this.setState({ isAdded: false, editId: null });
                    this.getPenaltyData();
                })
            })
        } else {
            this.updateDelayPenalty(penalty);
        }
    }

    updateDelayPenalty = (penalty) => {
        const { dispatch } = this.props;
        penalty.type = this.state.typeUOM.value;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.UPDATE_DELAY_PENALTY,
            params: penalty,
            onSuccess: (() => {
                this.setState({ isAdded: false, editId: null });
                this.getPenaltyData();
            })
        });
    }

    deleteDelayPenalty = () => {
        const { delayPenaltyData, selectedPenalties } = this.state;
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.DELETE_DELAY_PENALTY,
            params: selectedPenalties,
            onSuccess: (() => {
                this.getPenaltyData();
            })
        });
    }

    downloadDelayPenalty = () => {
        const { delayPenaltyData, typeUOM } = this.state;
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.DOWNLOAD_DELAY_PENALTY,
            params: { type: typeUOM.value },
            onSuccess: ((data) => {
                this.setState({
                    downloadLink: data.link
                });
                this.downloadBtn.click();
            })
        })
    }

    handleDelayTypeChange = (value) => {
        let { delayPenaltyData } = this.state;
        // delayPenaltyData = delayPenaltyData.filter((data) => { return data.id ? data : null; })
        this.setState({
            typeUOM: value,
            isAdded: false,
            newSlabData: { ...this.state.newSlabData, type: value.value }
            // delayPenaltyData,
        }, () => {
            this.getPenaltyData();
        })
    }

    uploadPenaltyFile = (e) => {
        let formData = new FormData();
        formData.append('file', e.target.files[0]);
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.UPLOAD_DELAY_PENALTY,
            params: this.state.typeUOM.value,
            data: formData,
            onSuccess: (() => {
                this.getPenaltyData();
            })
        });
    }

    closeMsgPopup = () => {
        const { dispatch } = this.props;
        dispatch({ type: ACTION.MSG_DISPLAY.HIDE_MSG });
    }

    resetData = () => {
        let { newSlabData } = this.state;
        newSlabData = {
            id: -1,
            type: DELAY_UOM_TYPES[0].value,
            company_category_id: null,
            company_category: null,
            vendor_name: null,
            vendor_id: null,
            vendor_code: null,
            mode_type: null,
            mode_id: null,
            seeker_id: null,
            vendor_email: null,
            delay_penalty_ontime_efficiency: {
                id: 2,
                name: "Efficiency",
                vendor_ontime_efficiency: [{ max: null, min: null, penalty_percentage: null, isBusinessStop: false }]
            },
            delay_penalty_days: {
                id: 1,
                name: "Days",
                vendor_delay_penalty_days: [{ max: null, min: null, penalty_percentage: null, isBusinessStop: false }]
            }
        };
        this.setState({ newSlabData });
    }

    handleAdvanceFilter = () => {
        this.setState({ showAdvanceFilter: !this.state.showAdvanceFilter })
    }

    handleTagClick = (key, value) => {
        this.setState({
            [key]: this.state[key].map((el) => value == el.id ? { ...el, selected: !el.selected } : el)
        })
    }

    handleFilterSubmit = () => {
        this.setState({
            showAdvanceFilter: false
        })
        this.getPenaltyData();
    }

    getAllDropdownData = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_OTHERCHARGES_DROPDOWNLIST,
            onSuccess: ((data) => {
                if (Object.keys(data).length > 0) {
                    let k = data.distribution_type;
                    // console.log('k:', k)
                    this.setState({
                        distribution_type: k
                    })
                }
            })
        })
    }
    renderDistributionType = (distribution_type) => {
        const distributionType = DISTRIBUTION_TYPE_DELAY_PENALTY_DEFINE_SLAB.filter(x => distribution_type == x.distribution_type_id)
        const tempDist = Array.isArray(distributionType) && distributionType.length > 0 && distributionType[0].distribution_type || '-'
        return tempDist
    }
    shouldRenderServiceType = (service_type) => {
        const serviceType = SERVICE_TYPE_DELAY_PENALTY_DEFINE_SLAB.filter(x => service_type == x.service_type_id)
        const tempService = Array.isArray(serviceType) && serviceType.length > 0 && serviceType[0].service_type || '-'
        return tempService
    }
    renderTableBody = (delayPenaltyData) => {
        const { typeUOM, editId, isAdded, selectedPenalties } = this.state;

        return (
            <Fragment>
                {delayPenaltyData && (delayPenaltyData || []).map((penalty, pIndex) => {
                    console.log('penalty:', penalty)
                    return (
                        <div className="list-section content divTableRow" key={`${penalty.vendor_delay_penalty_id}-${pIndex}`}>
                            <div className="divTableCell select-row">
                                {!isAdded && (pIndex !== editId) &&
                                    <input
                                        type="checkbox"
                                        readOnly
                                        checked={selectedPenalties.includes(penalty.vendor_delay_penalty_id)}
                                        onClick={(e) => this.handleCkSelect(e, penalty.vendor_delay_penalty_id)}
                                    />
                                }
                            </div>
                            <div className="divTableCell"> {penalty.company_category} </div>
                            <div className="divTableCell"> {penalty.vendor_name} </div>
                            <div className="divTableCell"> {penalty.vendor_code} </div>
                            <div className="divTableCell"> {penalty.vendor_email} </div>
                            <div className="divTableCell"> {penalty.mode_type} </div>
                            {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && <div className="divTableCell"> {penalty.distribution_type ? this.renderDistributionType(penalty.distribution_type) : '-'} </div>}
                            {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && <div className="divTableCell"> {penalty.service_type ? this.shouldRenderServiceType(penalty.service_type) : '-'} </div>}
                            {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && <div className="divTableCell"> {penalty.same_city_logic === true ? 'YES' : 'NO'} </div>}
                            {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && <div className="divTableCell"> {penalty.bound_type === 1 ? 'Inbound' : (penalty.bound_type === 2 ? 'Oubound' : '')} </div>}
                            {(typeUOM && typeUOM.id === 1) && <div className="divTableCell"> {penalty.vendor_applicable} </div>}
                            {(typeUOM && typeUOM.id === 2)
                                ?
                                <div className="divTableCell rules-tab">
                                    {penalty.delay_penalty_ontime_efficiency && (penalty.delay_penalty_ontime_efficiency.vendor_ontime_efficiency || []).map((slab, eIndex) => {
                                        return (
                                            <div className="sub-header-inline penaltyPercent" key={`penalty-${penalty.vendor_delay_penalty_id}-${pIndex}-${eIndex}`}>
                                                <div className="penalty-slab">{slab.max}% {slab.min && (slab.min === null || slab.min === 'null') ? '+' : `- ${slab.min} %`}</div>
                                                {(editId === pIndex)
                                                    ?
                                                    <Fragment>
                                                        <InputText
                                                            type="number"
                                                            id={`pInput-${pIndex}-${eIndex}`}
                                                            key={`pInput-${pIndex}-${eIndex}`}
                                                            placeholder="No."
                                                            value={slab.penalty_percentage}
                                                            changeHandler={(value, id) => {
                                                                if (isAdded) {
                                                                    this.onChangeInput(value, id, 'penalty_percentage', eIndex);
                                                                } else {
                                                                    this.handleOnEffChange(value, id, pIndex, eIndex);
                                                                }
                                                            }}
                                                            onBlur={(e) => {
                                                                if (isAdded) {
                                                                    this.onChangeInput(e.target.value, null, 'penalty_percentage', eIndex);
                                                                } else {
                                                                    this.handleOnEffChange(e.target.value, null, pIndex, eIndex);
                                                                }
                                                            }}
                                                        />
                                                        {
                                                            // <input value={this.state.inputPenalty} onBlur={(e) => { this.setState({ inputPenalty: e.target.value }) }} />
                                                        }
                                                    </Fragment>
                                                    :
                                                    <div className="penalty-value">{slab.penalty_percentage}%</div>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                                :
                                <div className="divTableCell rules-tab">
                                    {penalty.delay_penalty_days && (penalty.delay_penalty_days.vendor_delay_penalty_days || []).map((slab, eIndex) => {
                                        return (
                                            <div className="sub-header-inline penaltyPercent" key={`penalty-${penalty.vendor_delay_penalty_id}-${pIndex}-${eIndex}`}>
                                                <div className="penalty-slab">{slab.max} - {slab.min}</div>
                                                {(editId === pIndex)
                                                    ?
                                                    <InputText
                                                        type="number"
                                                        key={`pInput-${pIndex}-${eIndex}`}
                                                        placeholder="No."
                                                        value={slab.penalty_percentage}
                                                        changeHandler={(value, id) => {
                                                            if (isAdded) {
                                                                this.onChangeInput(value, id, 'penalty_percentage', eIndex);
                                                            } else {
                                                                this.handleOnDaysChange(value, id, pIndex, eIndex);
                                                            }
                                                        }}
                                                    />
                                                    :
                                                    <div className="penalty-value">{slab.penalty_percentage}%</div>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                            <div className="divTableCell">
                                {(editId === pIndex)
                                    ?
                                    <>
                                        <div className={'action-icon'} style={{ backgroundImage: `url(${Save})` }} onClick={() => this.savePenalty(penalty)} />
                                        <div className={'action-icon'} style={{ backgroundImage: `url(${Cancel})` }} onClick={() => this.setState({ editId: null })} />
                                    </>
                                    :
                                    <div className={'action-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.editPenalty(pIndex)} />
                                }
                            </div>
                        </div>
                    )
                })}
            </Fragment>
        )
    }

    previous = () => {
        const { limit, offset } = this.state;
        const newOffset = Math.max(0, offset - limit); //offset should not go below Zero
        this.setState({ offset: newOffset }, () => {
            this.getPenaltyData();
        });

    };

    next = () => {
        const { limit, offset } = this.state;
        this.setState({ offset: offset + limit }, () => {

            this.getPenaltyData();
        });
    };

    render() {
        const { addItemPopup, typeUOM, newSlabData, delayPenaltyData, isAdded, distribution_type, distributionValue } = this.state;
        const { dataGovernance } = this.props;

        let allDelayData = delayPenaltyData || [];

        if (allDelayData.filter((data) => { return data.id === -1 }).length <= 0 && isAdded) {
            allDelayData.unshift(newSlabData);
        }

        return (
            <div className="discount-wrap">
                <div className="wrapper">
                    <MainComponent>
                        {this.props.loader.isLoading && <Loader />}
                        {this.props.msgpopup.active && <MSGPopUp close={this.closeMsgPopup} type={this.props.msgpopup.type} msg={this.props.msgpopup.msg} />}
                        <div className="sub-heading-section">
                            <div className="sub-heading"> Delay Penalty Structure </div>
                        </div>

                        <div>
                            <div className="">
                                <Select
                                    className="select"
                                    options={DELAY_UOM_TYPES}
                                    value={typeUOM && typeUOM.value ? DELAY_UOM_TYPES.find(val => { return val.value === typeUOM.value }) : null}
                                    onChange={(value) => this.handleDelayTypeChange(value)}
                                />
                            </div>
                            <div className="cta-action-btns">
                                <button className="button-classic" onClick={this.downloadDelayPenalty}> Download Template</button>
                                <a className="download-btn" style={{ display: 'none' }} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />

                                <button className="button-classic" onClick={() => { this.uploadExcel.click() }}> Upload Excel </button>
                                <input type='file' style={{ display: 'none' }} ref={(e) => { this.uploadExcel = e; }} onChange={this.uploadPenaltyFile} onClick={(e) => { e.target.value = null }} />

                                <button className="button-classic" onClick={this.deleteDelayPenalty}>Delete Record(s)</button>
                            </div>
                            <div className="cta-action-btns cta-btn-right">
                                <Button value="Define Slab" click={this.showPopUp} />
                                <Button value="Advanced Filters" click={() => this.handleAdvanceFilter()} />
                                {
                                    this.state.showAdvanceFilter && <AdvanceFilter handleTagClick={this.handleTagClick} categories={this.state.categories} modes={this.state.modes} handleSubmit={this.handleFilterSubmit} />
                                }
                            </div>

                            <div className="divTable company-list-wrap">
                                <div className="list-section table-header divTableHeading divTableRow">
                                    <div className="divTableCell select-row"> </div>
                                    <div className="divTableCell"> Category </div>
                                    <div className="divTableCell"> Vendor Name </div>
                                    <div className="divTableCell"> Vendor Code </div>
                                    <div className="divTableCell"> Email </div>
                                    {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) ? <div className="divTableCell"> Service Mode </div> : <div className="divTableCell"> Mode Type </div>}
                                    {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && <div className="divTableCell"> Distribution Type </div>}
                                    {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && <div className="divTableCell"> Service Type </div>}
                                    {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && <div className="divTableCell"> Same City </div>}
                                    {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && <div className="divTableCell"> Bound Type </div>}
                                    {(typeUOM && typeUOM.id === 1) && <div className="divTableCell"> Applicable </div>}
                                    <div className="divTableCell rules-tab"> <div>Delay Penalty {(typeUOM && typeUOM.id) ? `(${typeUOM.label})` : ''}</div>
                                        <div className='dpcell'>
                                            <div className="sub-header-inline"> Slab 1 </div>
                                            <div className="sub-header-inline"> Slab 2 </div>
                                            <div className="sub-header-inline"> Slab 3 </div>
                                            <div className="sub-header-inline"> Slab 4 </div>
                                        </div>
                                    </div>
                                    <div className="divTableCell"> Action </div>
                                </div>
                                <div className="divTableBody">
                                    {this.renderTableBody(allDelayData)}
                                </div>
                            </div>
                            {addItemPopup &&
                                <AddNewSlabs
                                    newSlabData={newSlabData}
                                    typeUOM={typeUOM}
                                    onChangeInput={this.onChangeInput}
                                    addNewSlabs={this.addNewSlabs}
                                    cancelNewSlab={this.cancelNewSlab}
                                    handleSave={this.handleSave}
                                    modesList={dataGovernance.modeTypes || []}
                                    vendorsList={dataGovernance.vendorTypes || []}
                                    handleServiceCityToggleChange={this.handleServiceCityToggleChange}
                                // distribution_type={this.state.distribution_type || []}
                                />
                            }
                        </div>
                        <div className="flex flex-between">
                            <div className="pre ml-30">
                                <Button value="Previous" click={this.previous} />
                            </div>
                            <div className="next">
                                <Button value="Next" click={this.next} />
                            </div>
                        </div>
                    </MainComponent>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loader: state.loader,
        msgpopup: state.msgpopup,
        dataGovernance: state.dataGovernance,
    }
}

export default connect(mapStateToProps)(DelayPenaltyStructure);
