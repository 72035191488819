import React, {PropTypes} from 'react';
import Deparment from "./department/department";
import Roles from "./roles/roles";
import './roles/roles.less';
import { withRouter } from "react-router";

class DepartmentRoles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Tabs : [
        {
          label: "Department",
          id: "1"
        },
        {
          label: "Roles",
          id: "2"
        }
      ],
      selectedOption : "1"
    }
  }

  onTabChange = (tabId) => {
    this.setState({
      selectedOption : tabId
    })
  }

  handleOnClickForAuditBtn = () => {
    const {history,companyId} = this.props;
    const masterType = 6
    const navUrl = `/auditScreen/${companyId}/${masterType}`;
    history.push(navUrl)
  }

  render() {
    return (
      <div className = "dept-roles-root">
        <div className={"home-navigation-holder"}>
          {
            this.state.Tabs.map(tab => {
              return(
                <div key={tab.id} className={`${this.state.selectedOption === tab.id ? "selected-option options" : "options"} ${tab.id == 2 ? "roles-class" : ""}`} onClick={() => this.onTabChange(tab.id)}>
                  {tab.label}
                </div>
                )
            })
          }
        </div>
          <button
            className="button-classic"
            onClick={this.handleOnClickForAuditBtn}
          >
            Audit
          </button>
        {this.state.selectedOption === "1" && <Deparment editDepartment={this.props.editDepartment} addDepartment = {this.props.addDepartment} deleteDepartment = {this.props.deleteDepartment} departments = {this.props.departments} roles = {this.props.roles} companyDetails = {this.props.companyDetails}  companyType = {this.props.companyType} downloadProviderDepartmentDeatils ={this.props.downloadProviderDepartmentDeatils} companyId ={this.props.companyId}  uploadProviderDepartmentFile ={this.props.uploadProviderDepartmentFile}/>}
        {this.state.selectedOption === "2" && <Roles addRole = {this.props.addRole} deleteRole = {this.props.deleteRole} roles = {this.props.roles} companyDetails = {this.props.companyDetails} companyType = {this.props.companyType} downloadProviderRolesDeatils ={this.props.downloadProviderRolesDeatils} companyId ={this.props.companyId} uploadProviderRolesFile ={this.props.uploadProviderRolesFile}/>} 
      </div>
    )
  }
}
export default withRouter (DepartmentRoles);
