import React, { Component } from 'react';
import Select from 'react-select';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import Delete from '../../../../../assets/images/delete.svg';
import Add from '../../../../../assets/images/add.svg';
import Moment from 'moment';


class AddStandardTime extends Component {
    constructor(props) {
    super(props);
        this.state = {
            moves: this.props.indentingMoves && (this.props.indentingMoves || []).map(move => {
                return {
                    label: move.name,
                    value: move.move_id
                }
            }),
            moves_standard_time: this.props.editData && this.props.editData.length > 0 && this.props.editData || [
                {
                    from_move_id: '',
                    from_move_name: '',
                    to_move_name: '',
                    to_move_id: '',
                    Standard_time: ''
                }
            ]
        }
    }

    onAddStandardTime = () => {
        const { moves_standard_time } = this.state;
        moves_standard_time.push({
            from_move_id: '',
            from_move_name: '',
            to_move_name: '',
            to_move_id: '',
            Standard_time: ''
        })
        this.setState({ moves_standard_time });
    }

    onDeleteST = (index) => {
        const { moves_standard_time } = this.state;
        moves_standard_time.splice(index, 1);
        this.setState({ moves_standard_time });
    }

    onSave = () => {
        const { moves_standard_time } = this.state;
        const { company_id, branch_id, editData } = this.props;
        const data = {
            company_id,
            branch_id,
            moves_standard_time
        }
        if(editData) {
            this.props.updateStandardTime(data);
        } else {
            this.props.saveStandardTime(data);
        }
        
        this.props.toggleAdd('');
    }

    render() {
        const {  moves_standard_time } = this.state;
        const { editData } = this.props;
        
        return(
            <div className = "add-user-wrap add-bay standard-time">
                <div onClick = {() => this.props.toggleAdd('')} className = "overlay"></div>
                <div id = "modal" className = "modal-popup">
                    <div className = "pop-heading">
                        {`${editData ?  'Edit' : 'Add' } Standard Time`}
                    </div>
                    <div className = "content add-edit-user">
                        {(moves_standard_time|| []).map((stMove, index) => {
                         return (
                            <div className="select-container">
                                <Select 
                                    className={'wt-250 mr-20'} 
                                    options={this.state.moves} 
                                    placeholder={'Select From Move'}
                                    value={stMove.from_move_id && this.state.moves.find(move => move.value === stMove.from_move_id) || stMove.from_move_id}
                                    onChange={(options) => {
                                            const { moves_standard_time } = this.state;
                                            moves_standard_time[index].from_move_id = options.value;
                                            moves_standard_time[index].from_move_name = options.label;
                                            this.setState({ moves_standard_time });
                                        }
                                    }
                                />
                                <Select 
                                    className={'wt-250 mr-20'} 
                                    options={this.state.moves} 
                                    placeholder={'Select To Move'}
                                    value={stMove.to_move_id && this.state.moves.find(move => move.value === stMove.to_move_id) || stMove.to_move_id}
                                    onChange={(options) => {
                                        const { moves_standard_time } = this.state;
                                        moves_standard_time[index].to_move_id = options.value;
                                        moves_standard_time[index].to_move_name = options.label;
                                        this.setState({ moves_standard_time });
                                    }
                                }
                                />
                                <TimePicker
                                    showSecond={false}
                                    placeholder={'Time'}
                                    className={'wt-150 ht-30'}
                                    value={stMove.Standard_time && Moment(stMove.Standard_time, 'HH:mm') || null}
                                    onChange={(value) => {
                                        let { moves_standard_time } = this.state;
                                        moves_standard_time[index].Standard_time = Moment(value).format('HH:mm');
                                        this.setState({ moves_standard_time });
                                    }
                                    }
                                />
                                {moves_standard_time.length - 1 === index &&  <div className={'delete-icon'} style={{backgroundImage: `url(${Add})`}} onClick={this.onAddStandardTime}></div>}
                                {moves_standard_time.length > 1 && <div className={'delete-icon'} style={{backgroundImage: `url(${Delete})`}} onClick={() => this.onDeleteST(index)}></div>}
                            </div>
                        )})}
                        <div className = "submit-section">
                            <button className = "add-button discard" onClick={this.props.toggleAdd} >Cancel</button>
                            <button className = {"add-button"} onClick={this.onSave}>{`${editData ? 'Upadate': 'Save'}`}</button> 
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddStandardTime;