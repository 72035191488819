import React, { Component, } from 'react';
import InputText from "../../../../common/components/input-text/input-text";
import Cx from "classnames";
import { getEmail, getUserCompanyId, getUserId } from '../../../../../utils/common';

class AddBay extends Component {
    constructor(props){
        super(props);
        this.state = {
            warehouse_id: this.props.data ? this.props.data.warehouse_id : '',
            bay_name: this.props.data ? this.props.data.bay_name : '',
            bay_description: this.props.data ? this.props.data.bay_description : '',
            branch_code:  this.props.data ? this.props.data.branch_code : '',
            // bay_id: this.props.data ? this.props.data.bay_id : '',
            previous_data: this.props.data ? this.props.previous_data:null,
        }
    }

    onChangeHandler = (value, id) =>{
        this.setState({
            [id] : value.toUpperCase()
        })
    }

    onSave = () => {
        this.props.onAddBay(this.state);
        this.props.toggleAdd();
    }

    updateLoadingBay = () => {
        let details = this.state;
        if(this.props.data.id)
            details.id = this.props.data.id;
            details.previous_data.user_id = getUserId();
            details.previous_data.user_email = getEmail();
            details.previous_data.update_flag = true;
            details.previous_data.master_type = 13;
            details.previous_data.company_id = getUserCompanyId()
        this.props.updateLoadingBay(details);
        this.props.toggleAdd();
    }

    render() {
        const { warehouse_id, bay_name, bay_description, branch_code } = this.state;
        const { data } = this.props;

        return (
            <div className = "add-user-wrap add-bay">
                <div onClick = {this.props.toggleAdd} className = "overlay"></div>
                <div id = "modal" className = "modal-popup">
                    <div className = "pop-heading">
                        {`${data ?'Edit' : 'Add'} Bay`}
                    </div>
                    <div className = "content add-edit-user">
                        <form className = "form-section" autoComplete = "nope">
                            <InputText placeholder = "Name" id = {"bay_name"} label = "Bay Name" value = {bay_name} changeHandler = {this.onChangeHandler}/>
                            <InputText placeholder = "Description" id = {"bay_description"} label = "Bay Description" value = {bay_description} changeHandler = {this.onChangeHandler} />
                            <InputText placeholder = "Warehouse Id" id = {"warehouse_id"} label = "Warehouse Id" value = {warehouse_id} changeHandler = {this.onChangeHandler} />
                            <InputText placeholder = "Branch Code" id = {"branch_code"} label = "Branch Code" value = {branch_code} changeHandler = {this.onChangeHandler} />
                        </form>

                        <div className = "submit-section">
                            <button className = "add-button discard" onClick={this.props.toggleAdd} >Cancel</button>
                            {!data ? <button className ={Cx("add-button", {
                                'disable-btn' : !(warehouse_id && bay_description && bay_name && branch_code)
                            })} onClick={this.onSave}>Save</button>
                            :  <button className ={Cx("add-button", {
                                'disable-btn' : !(warehouse_id && bay_description && bay_name && branch_code)
                            })} onClick={this.updateLoadingBay}>Upate</button>
                        }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddBay;
