import React, { Component } from 'react';
// import "./add-company.less";
import Select from 'react-select';
import validate from "../../../../../../utils/validate";
import { getEmail } from '../../../../../../utils/common';
import { getItemFromSessionStorage } from '../../../../../../utils/sessionStorage';
import { setItemToSessionStorage } from '../../../../../../utils/sessionStorage';

class EditDepartment extends Component {
   constructor(props) {
      super(props);
      this.state = {
         department_name: this.props.Department != undefined ? this.props.Department.department_name : "",
         roles: [],
         selectedRoles: this.props.Department != undefined ? this.props.Department.roles.map((rol) => {
            return {
               label: rol.name,
               value: rol.role_id
            }
         }) : [],
         selected: null
      }
   }

   componentWillMount() {
      let tempRoles = [];
      this.props.roles && this.props.roles.forEach(role => {
         tempRoles.push(
            {
               label: role.role_name,
               value: role.role_id
            }
         )
      });
      this.setState({
         roles: tempRoles
      });
   }
   componentDidMount() {
      window.scroll(0, 0);
      setItemToSessionStorage('Department', this.props.Department);
   }

   removeRole = (e,ind) => {
      e.preventDefault()
      let tempRoles = this.state.selectedRoles;
      tempRoles.splice(ind, 1);
      this.setState({ selectedRoles: tempRoles });
   }

   handleChange = (role, ind) => {
      let tempRoles = this.state.selectedRoles.slice();
      for (let i = 0; i < tempRoles.length; i++) {
         if (role.value == tempRoles[i].value) {
            // tempRoles[ind] = {
            //     value : "",
            //     label : ""
            // }
            // this.setState({ selectedRoles : tempRoles});
            return;
         }
      }
      tempRoles[ind] = role;
      this.setState({ selectedRoles: tempRoles });
   }

   populateRoles = () => {
      return this.state.selectedRoles.map((role, index) => {
         return (
            <div className="roles-holder" key={index}>
               <Select className="select" value={role} onChange={(role) => this.handleChange(role, index)} options={this.state.roles} />
               <button className="add-button" onClick={(e) => this.removeRole(e,index)}>Delete</button>
            </div>
         )
      })

   }

   editDepartment = () => {
      const previousData = getItemFromSessionStorage('Department');
		previousData['update_flag']= true;
      previousData['user_email'] = getEmail();
      let paramObj = {
         company_id: this.props.Department.company_id,
         department_id: this.props.Department.department_id,
         department_name: this.state.department_name,
         roles: this.state.selectedRoles.map((role) => {
            return {
               name: role.label,
               role_id: role.value
            }
         }),
         ['master_type'] : 6,
		   ['user_email'] : getEmail(),
		   ['update_flag'] : true,
         ['previous_data'] : previousData
      }

      if (validate.isEmpty(paramObj.department_name)) {
         this.setState({
            error: "Please Enter A Valid Department Name"
         });
         return;
      }
      if (paramObj.roles.length == 0) {
         this.setState({
            error: "Please Select Atleast One Role From The List"
         });
         return;
      }
      else if (!(paramObj.roles[paramObj.roles.length - 1]).name) {
         this.setState({
            error: "Roles Cannot Be Empty Or Duplicates, Please Check!"
         });
         return;
      }

      this.props.editDepartment(paramObj);
      this.props.toggleEdit();
   }

   addAnotherRole = () => {
      let sample = this.state.selectedRoles;
      if (sample.length >= this.state.roles.length) {
         return;
      }
      if (sample.length != 0 && validate.isEmpty(sample[sample.length - 1].value)) {
         return;
      }
      let obj = {
         role_name: "",
         role_id: ""
      }
      sample.push(obj);
      this.setState({
         selectedRoles: sample
      }, () => { this.populateRoles() });
   }
   onChangeHandler = (value, id) => {
      this.setState({
         [id]: value
      })
   }
   render() {
      const { props, state } = this;
      return (
         <div className="add-company-wrap">
            <div onClick={props.close} className="overlay"></div>
            <div className="modal-popup small-modal edit-department">
               <div className="heading">
                  Edit Department
                    </div>
               <label className="error">{this.state.error}</label>
               <form className="form-section" autoComplete="nopes">
                  <InputText placeholder="Department Name" id={"department_name"} label="Department Name" value={state.department_name} changeHandler={this.onChangeHandler} maxLength={30} />
                  <div><span className="sub-label">Roles</span>{this.populateRoles()}</div>
                  <div className="addMore" onClick={this.addAnotherRole}><span>Add More</span></div>
               </form>
               <div>
                  <div className="submit-section submit-area">
                     <div className="button-section">
                        <div className="add-button" onClick={this.editDepartment}>Save</div>
                     </div>
                     <div className="button-section">
                        <div className="add-button" onClick={this.props.toggleEdit}>Close</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}


const InputText = (props) => {
   return (
      <div className="input-text-wrap">
         <div className="label">
            {props.label}
         </div>
         <div className="input-element">
            <input type="text" id={props.id} placeholder={props.placeholder} className="text-input" value={props.value} onChange={(e) => props.changeHandler(e.target.value, props.id)} autoComplete="nopes"maxLength={props.maxLength} />
         </div>
      </div>
   )
}


export default EditDepartment;