import React, { Component } from 'react';
import InputText from '../../../../common/components/input-text/input-text';
import Select from 'react-select';
import Button from '../../../button-classic/button-classic';
import '../../../route_code/route_code.less';
// import { AWL_COMPANY_IDS } from '../../../utils/common';
import Cx from "classnames";
import { setItemToSessionStorage } from '../../../../../utils/sessionStorage';

class AddInventory extends Component {
		constructor(props) {
			super(props);
			this.state = {
				data: {					
					"zone": this.props.editData ? this.props.editData.zone : '',
					"total_area_in_sqft": this.props.editData ? this.props.editData.total_area_in_sqft : '',
                    "carpet_area_in_sqft" : this.props.editData ? this.props.editData.carpet_area_in_sqft : '',
					"storage_capacity_in_mt": this.props.editData ? this.props.editData.storage_capacity_in_mt : '',					
					"company_id": this.props.editData ? this.props.editData.company_id:this.props.companyId,
					"branch_name": this.props.editData ? this.props.editData["branch_name"] : '',
					"branch_code":this.props.editData ? this.props.editData["branch_code"] : '',
					"branch_id":this.props.editData ? this.props.editData["branch_id"] : '',				
				},
			}
		}
		
		componentDidMount(){
			setItemToSessionStorage('InventoryIn', this.props.editData)
		}

		onChangeHandler = (value, id) => {
			let { data } = this.state;
			if (id === 'total_area_in_sqft') {
				data[id] = value.replace(/[^0-9.]/g, '');
			}else 
			if(id === 'carpet_area_in_sqft' || id === 'storage_capacity_in_mt'){
				data[id] = value.replace(/[^0-9.]/g, '');
			} 
			else {
				data[id] = value
			}
			this.setState({ data });
		}

		onSave = () => {
			const { data } = this.state;
			this.props.onSave(data);
		}

		onUpdate = () => {
			const { data } = this.state;
			const { editData } = this.props;
			this.props.onUpdate(data);
		}

		render() {
			const { data } = this.state;
			const { companyId,fetchDepotName } = this.props;
			
			const updatefetchDepotName = (fetchDepotName || []).map((data) => ({
			label: data.branch_name,
			value: data.branch_id,
			code : data.branch_code
			})) || [];

            const disable = !(data['storage_capacity_in_mt'] && data['branch_name'] && data['branch_name'] 
			&& data['branch_id']);
			return (
				<div className="route_code_detail">
					<div onClick={this.props.toggleAdd} className="overlay"></div>
					<div id="modal" className="modal-popup">
						<div className="pop-heading">
							{`${this.props.editData ? 'Edit' : 'Add'} Inventory Master`}
						</div>
						<div className="route-detail">
							<div className="details-section">

								<div className="select-wrap-depot">
						        	<div className="label">{'Depo Details'}<span className="error-color">*</span></div>
									<Select
									options={updatefetchDepotName}
									className={Cx("selectpack",{"disabled": this.props.editData})}
									value={data['branch_name'] && updatefetchDepotName.find((el) => el.label === data['branch_name'])}
									getOptionLabel={(option) => option.label+'-'+option.code }
									onChange={(opt) => {
										const { data } = this.state;
										data['branch_name'] = opt.label;
										data['branch_id'] = opt.value;
										data['branch_code'] = opt.code;
										this.setState({ data });										
									}}
									/>
                            	</div>								
								<InputText
									placeholder="Storage Capacity"
									id={"storage_capacity_in_mt"}
									label="Storage Capacity (in mt)"
									changeHandler={this.onChangeHandler}
									value={data['storage_capacity_in_mt']}
                                    required={true}
								/> 
								<InputText
                                    
									placeholder="Total Area"
									id={"total_area_in_sqft"}
									label="Total Area (sqrt)"
									changeHandler={this.onChangeHandler}
									value={data['total_area_in_sqft']}
								/>
							</div>
						
							<div className="details-section">
                                {/* <InputText
                                    
									placeholder="Zone"
									id={"zone"}
									label="Zone"
									changeHandler={this.onChangeHandler}
									value={data['zone']}
								/> */}
								<InputText								
									placeholder="Carpet Area"
									id={"carpet_area_in_sqft"}
									label="Carpet Area (sqrt)"
									changeHandler={this.onChangeHandler}
									value={data["carpet_area_in_sqft"]}

								/>
							</div>
							<div className={'button_route'}>
								<Button value={'Cancel'} click={this.props.toggleAdd} />
								{!this.props.editData ?
									<Button value={'Save'} click={this.onSave}
									 disabled={disable}
									 />
									:
									<Button value={'Update'} click={this.onUpdate} 
									disabled={disable}
									/>
								}

							</div>
						</div>
					</div>	
				</div>
			)
		}
	}

export default AddInventory;
