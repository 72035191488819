import React, { Component } from 'react';
import AddItem from './add-item/add-item';
import EditItem from './edit-item/edit-item';
import Edit from '../../../../assets/images/edit.svg';
import Delete from '../../../../assets/images/delete.svg';
import Button from '../../../home/button-classic/button-classic';
import Search from '../../../common/components/search-text/search-text';
import { companyName, chainType } from '../../../../utils/constants';
import { getCookie } from "../../../../utils/cookies";
import RateChart from './RateChart';

class PfizerItem extends Component {
    constructor() {
        super();
        this.state = {
			isOpen : false,
			isEdit : false,
			count : 50,
			offset : 0,
			editedData : '',
			search: ''
		}
	}
	

	componentDidMount = () => {
		window.addEventListener('scroll', this.handleScroll);
		this.getInventoryItem();
		this.props.downloadInventoryItem();
		this.props.getPackagingDetails()
	}

	getInventoryItem = () => {
		const data = {
			offset : this.state.offset,
			count : this.state.count
		}
		if(this.state.search) {
			data.search = this.state.search;
		}
		this.props.getInventoryItem(data);
	}

    toggleAdd = (isType, data='') => {
		if(isType == "Edit") {
			this.setState({isEdit : !this.state.isEdit, editedData : data});
		} else {
			this.setState({isOpen : !this.state.isOpen});
		}
	}

	getCompanyName = (id) => {
		let company = companyName.find((val) => {
			return val.value == id;
		});
		return company && company.label ? company.label : '';
	}

	getChain = (type) => {
		let chain = chainType.find((val) => {
			return val.value == type;
		});
		return chain && chain.label ? chain.label : '';
	}

	onDownload = () => {
		this.downloadUrl.click();
	}

	onUpload = () => {
		this.uploadUrl.click();
	}

	uploadInventoryItem = (e) => {
		let formData = new FormData();
        formData.append('file', e.target.files[0]);
		this.props.uploadInventoryItem(formData);
	}

	uploadRateChart = (e) => {
		let formData = new FormData();
		const user = getCookie('user') && JSON.parse(getCookie('user')) || {};
        formData.append('file', e.target.files[0]);
		formData.append('user_id',user.user_id);
		formData.append('user_name',user.admin_name);
		this.props.uploadRateChart(formData);
	}
	
	componentWillUnmount() {
	window.removeEventListener('scroll', this.handleScroll)
	}

	changeHandler = (val,id) =>{
		this.setState({
			[id]: val
		});
	}

	submitSearch = () => {
		this.getInventoryItem();
	}

	clearFilterOnCancel = (e, type) => {
		this.setState({ search: '' }, () => {
			this.getInventoryItem();
		});
	}

	handleScroll = () => {
		// var wrappedElement =  document.getElementById('root');
		// if (wrappedElement.getBoundingClientRect().bottom < window.innerHeight) {
		// 	console.log(this.state.count);
		// 	this.setState({
		// 		count : this.state.count+50
		// 	},()=>{
		// 		const data = {
		// 			count : this.state.count,
		// 			offset : this.state.offset
		// 		}
		// 		this.props.getInventoryItem(data);
		// 	});
		// }
	}

	previous = () =>{
		if(this.state.offset > 0){
			this.setState({
				offset : this.state.offset-50
			},()=>{
				const data = {
					count : this.state.count,
					offset : this.state.offset
				}
				this.props.getInventoryItem(data);
			});
		}
	}

	next = () =>{
		this.setState({
			offset : this.state.offset+50
		},()=>{
			const data = {
				count : this.state.count,
				offset : this.state.offset
			}
			this.props.getInventoryItem(data);
		});
	}

	onClickRateChart = (val) => {
		this.props.getRateChartDetails(val, this.openRateChart);
	}

	openRateChart = (data, val) => {
		this.setState({
			rateChartData: data || [],
			materialName: val.materialName,
			materialDetails: val
		});
	}

	onAddRateChart = (data) => {
		this.props.onAddRateChart(data, this.state.materialDetails, this.openRateChart);
	}

	onDeleteRate = (data) => {
		this.props.onDeleteRate(data, this.state.materialDetails, this.openRateChart);
	}

	onUpdateRateChart = (data) => {
		this.props.onUpdateRateChart(data, this.state.materialDetails, this.openRateChart);
	}

	onCancel = () => {
		this.setState({
			rateChartData: [],
			materialName: '',
			materialDetails: ''
		});
	}

    render() {
        return(<div className={'Pfizer-Item'}>
            <div className = "header">
				<Search id={'search'} value={this.state.search} placeholder={'Search'} changeHandler={this.changeHandler} click = {this.submitSearch} canClear={this.state.search !== ''} handleClearFilter={(e) => this.clearFilterOnCancel(e, "seaech")}/>
                <Button value={' + Item'} click={this.toggleAdd}/>
				{/* <Button value={'Generate'} /> */}
				<Button value={'Upload'} click={this.onUpload}/>
				<input type='file' style={{ display: 'none'}}  ref={(e) => { this.uploadUrl = e ;}} onChange={this.uploadInventoryItem} onClick={(e) => {e.target.value = null }}/>
				<Button value={'Download'} click={this.onDownload}/>
				<a href={this.props.downloadUrl} ref={(e) => { this.downloadUrl = e ;}} download style={{display: 'none'}}/>
				<Button value={'Upload Rate Chart'} click={() => { this.uploadRateChartUrl.click() }}/>
				<input type='file' style={{ display: 'none'}}  ref={(e) => { this.uploadRateChartUrl = e ;}} onChange={this.uploadRateChart} onClick={(e) => {e.target.value = null }}/>
				<Button value={'Download Rate Chart'} click={this.props.downloadRateChart}/>
			</div>
			<div className = "item-table">
				<div className = "list-section item-header column">
					<div className = "item-label">Company</div>
					<div className = "item-label">Chain</div>
					<div className = "item-label product-label">Product Group</div>
					<div className = "item-label product-label">Product</div>
					<div className = "item-label">Physical State</div>
					<div className = "item-label">Inside Count</div>
					<div className = "item-label">Unit</div>
					<div className = "item-label">Volume</div>
					<div className = "item-label">Actual Weight</div>
					<div className = "item-label">Charged Weight</div>
					<div className = "item-label">Actions</div>
				</div>
				{this.props.itemData && this.props.itemData.map((val,index) => {
					return(<div className = "list-section item-header" key={index}>
					<div className = "item-label">{val.companyType}</div>
					<div className = "item-label">{val.chainType}</div>
					<div className = "item-label product-label">{val.mpgCode}</div>
					<div className = "item-label product-label">{`${val.product} - ${val.materialName}`}</div>
					<div className = "item-label">{val.physicalState}</div>
					<div className = "item-label">{val.insideCount}</div>
					<div className = "item-label">{val.unit}</div>
					<div className = "item-label">{val.volume}</div>
					<div className = "item-label">{val.actualWeight}</div>
					<div className = "item-label volumetric">{val.chargedWeight}</div>
					<div className = "item-label">
						<div className={'delete-icon'} style={{backgroundImage:`url(${Edit})`}} onClick={() => this.toggleAdd("Edit", val )}></div>
						<div className={'delete-icon'} style={{backgroundImage:`url(${Delete})`}} onClick={() => this.props.deleteInventoryItem(val.productMasterId)}></div>
						<br/><a onClick={() => this.onClickRateChart(val)} className={"theme-color fs-14 mt-5 curP"} style={{'marginTop': '5px'}}>Rate Chart</a>
					</div>
				</div>)})}
			</div>
			<div className="submit-section-pagination">
				<div className="next">
					<Button value="Next" click={this.next} />
				</div>
				{this.state.offset != 0 && <div className="pre">
					<Button value="Previous" click={this.previous} />
				</div>}
        	</div>
			{this.state.isOpen && <AddItem toggleAdd={this.toggleAdd} addInventoryItem={this.props.addInventoryItem} packagingDetails={this.props.packagingDetails}/>}
			{this.state.isEdit && <EditItem toggleAdd={this.toggleAdd} data={this.state.editedData} updateInventoryItem={this.props.updateInventoryItem} packagingDetails={this.props.packagingDetails}/>}
			{this.state.materialName && <RateChart materialName={this.state.materialName} onAddRateChart={this.onAddRateChart} onCancel={this.onCancel} rateChartData={this.state.rateChartData} onDeleteRate={this.onDeleteRate} onUpdateRateChart={this.onUpdateRateChart}/>}
        </div>
        )
    }
}

export default PfizerItem;
