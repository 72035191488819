import React, {PropTypes} from 'react';
import { connect } from "react-redux";
import './msg-popup.less';
import Button from "../../../home/button-classic/button-classic";

class MessagePopUpComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className = "msg-pop-up" onClick = {this.props.close}>
        <div className = "msg-section">
        <div className = {this.props.type == 1 ? "msg" : "msg error"}>
          {this.props.msg}
        </div>
        {
          (this.props.isEditMode) ?
            <div className = "button-section">
              <Button click = {this.props.handleRedirection} value = "Yes"/>
              <Button click = {this.props.close} value = "No"/>
            </div>
          :
            <div className = "button-section">
              <Button click = {this.props.close} value = "Close"/>
            </div>
        }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isFormEditing: state.msgpopup.isFormEditing,
  };
};

export default connect(mapStateToProps)(MessagePopUpComponent);