import React, { Component, Fragment } from "react";
import InputText from "../../../../../common/components/input-text/input-text";
import Select from "react-select";
import Button from "../../../../../home/button-classic/button-classic";
import { getCookie } from "../../../../../../utils/cookies.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Switch from "react-switch";
import { ReactComponent as Upload } from "../../../../../../assets/images/upload-blue.svg";
import ACTION from "../../../../../common/action_constant";
import { connect } from "react-redux";
import "../../../vehicle-driver-master.less";
import { validationCheck } from "../../../../../../utils/validate";


const compartmentDetails = [
  {
    compartment_no: 1,
    capacity: "",
    capacity_uom: "L",
    pl: "",
    pl_uom: "cm",
    dl: "",
    dl_uom: "cm",
  },
  {
    compartment_no: 2,
    capacity: "",
    capacity_uom: "L",
    pl: "",
    pl_uom: "cm",
    dl: "",
    dl_uom: "cm",
  },
  {
    compartment_no: 3,
    capacity: "",
    capacity_uom: "L",
    pl: "",
    pl_uom: "cm",
    dl: "",
    dl_uom: "cm",
  },
  {
    compartment_no: 4,
    capacity: "",
    capacity_uom: "L",
    pl: "",
    pl_uom: "cm",
    dl: "",
    dl_uom: "cm",
  },
  {
    compartment_no: 5,
    capacity: "",
    capacity_uom: "L",
    pl: "",
    pl_uom: "cm",
    dl: "",
    dl_uom: "cm",
  },
  {
    compartment_no: 6,
    capacity: "",
    capacity_uom: "L",
    pl: "",
    pl_uom: "cm",
    dl: "",
    dl_uom: "cm",
  },
  {
    compartment_no: 7,
    capacity: "",
    capacity_uom: "L",
    pl: "",
    pl_uom: "cm",
    dl: "",
    dl_uom: "cm",
  },
  {
    compartment_no: 8,
    capacity: "",
    capacity_uom: "L",
    pl: "",
    pl_uom: "cm",
    dl: "",
    dl_uom: "cm",
  },
];
class AddItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company_id: JSON.parse(getCookie("user")).company_id,
      user_id: JSON.parse(getCookie("user")).user_id,
      user_name: JSON.parse(getCookie("user")).name,
      vehicle_reg_no: "",
      vehicle_engine_no: "",
      vehicle_chassis_no: "",
      vehicle_owner: "",
      admin_name: "",
      vehicle_owner_mobile: "",
      vehicle_registration_date: "",
      fitness_certificate_date: "",
      puc_validity_date: "",
      rc_validity_date: "",
      insurance_validity_date: "",
      vehicle_net_weight: "",
      vehicle_tare_weight: "",
      vehicle_passing_capacity: "",
      vehicle_service_type: "",
      gps_availability: false,
      gpsCompanies: [],
      // gps_availability: false,
      container_no: "",
      container_weight: "",
      seal_no: "",
      excise_sNo: "",
      gross_weight: "",
      preferredroute: [],
      compartment_details: [...compartmentDetails],
      // user_name: "",
      is_blocked: false,
      block_remarks: "",
      blocked_by: "",
      vehicle_class_type: "",
      compartment_capacity: "",
      tanker_capacity: "",
      fitness_no: "",
      fitness_certificate_img: "",
      puc_no: "",
      puc_validity_img: "",
      rc_no: "",
      rc_validity_img: "",
      calibration_no: "",
      calibration_date: "",
      calibration_img: "",
      explosive_no: "",
      explosive_date: "",
      explosive_img: "",
      insurance_no: "",
      insurance_validity_img: "",
      national_permit_no: "",
      national_permit_date: "",
      national_permit_img: "",
      half_year_no: "",
      half_year_date: "",
      half_year_img: "",
      safety_valve_no: "",
      safety_valve_date: "",
      safety_valve_img: "",
      suraksha_certificate_no: "",
      suraksha_certificate_date: "",
      suraksha_certificate_img: "",
      hazardous_no: "",
      hazardous_date: "",
      hazardous_img: "",
      tanker_cleaning_certificate_no: "",
      tanker_cleaning_certificate_date: "",
      tanker_cleaning_certificate_img: "",
      vehicle_age: "",
      fuel_name:"",
     
      tabs: [
        {label: "Vehicle details", id: 1},
        {label: "Compartment Details", id: 2}
      ],
      selectedOption : 1,
      has_compartment: false
    };
  }
  componentDidMount() {
    this.props.getVehicleTypes();
    this.props.getFuleTypes();  
    this.getGpsVendorData(); 
  }
  getGpsVendorData = () => {
    const { dispatch } = this.props;
    dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING.GET_GPS_VENDOR_DATA});
  }
  handleChange = (id, selectedOption) => {
    let { has_compartment, vehicles_service_id ,gps_details} = this.state;

    if(id === 'gps_details'){ 
      gps_details = {id : selectedOption.value, name: selectedOption.label}
      this.setState({ gps_details });
      return;
    }
   
    if(id === 'vehicle_service_type'){
      has_compartment = selectedOption.has_compartment;
      vehicles_service_id = selectedOption.value;
    }
    this.setState({ [id]: selectedOption.label, has_compartment, vehicles_service_id });
  };
  
  validateCheck = () => {
    const {
      company_id,
      vehicle_reg_no,
      vehicle_engine_no,
      vehicle_chassis_no,
      vehicle_owner,
      vehicle_owner_mobile,
      vehicle_registration_date,
      fitness_certificate_date,
      puc_validity_date,
      rc_validity_date,
      insurance_validity_date,
      gross_weight,
      vehicle_tare_weight,
      vehicle_net_weight,
      vehicle_passing_capacity,
      fitness_certificate_img,
      rc_validity_img,
      insurance_validity_img,
      fitness_no,
      rc_no,
      insurance_no,
      explosive_no,
      explosive_date,
      national_permit_no,
      national_permit_date,
      calibration_no,
      calibration_date,
      selectedOption,
      fuel_name,
      vehicle_service_type,
    } = this.state;
    if ( selectedOption === 1 ?
      (company_id &&
        vehicle_reg_no &&
      vehicle_engine_no &&
      vehicle_chassis_no &&
      vehicle_owner &&
      vehicle_owner_mobile &&
      vehicle_registration_date &&
      fitness_certificate_date &&
      puc_validity_date&&
      rc_validity_date &&
      insurance_validity_date &&
      gross_weight&&
      vehicle_tare_weight&&
      vehicle_net_weight &&
      vehicle_passing_capacity &&
      fitness_certificate_img&&
      rc_validity_img &&
      fuel_name &&
      vehicle_service_type &&
      insurance_validity_img) :
      (vehicle_reg_no &&
      vehicle_engine_no &&
      vehicle_chassis_no &&
      vehicle_owner &&
      vehicle_owner_mobile &&
      vehicle_registration_date &&
      fitness_certificate_date &&
      rc_validity_date &&
      insurance_validity_date &&
      vehicle_net_weight &&
      vehicle_passing_capacity &&
      fitness_certificate_img &&
      rc_validity_img &&
      insurance_validity_img &&
      fitness_no &&
      rc_no &&
      insurance_no &&
      explosive_no &&
      explosive_date &&
      national_permit_no &&
      national_permit_date &&
      calibration_date &&
      calibration_no &&
      fuel_name &&
      vehicle_service_type)
    ) {
      return true;
    } else return false;
  };
 
  onChangeHandler = (value, id, isType,e) => {
    const result = validationCheck(value, this.state[id], isType);
    if(id === 'vehicle_tare_weight' || id === 'gross_weight') {
      this.state.vehicle_net_weight = (id === 'vehicle_tare_weight')  ? (+this.state.gross_weight  - +value) : (+value - +this.state.vehicle_tare_weight);
  }
		this.setState({ [id]: result});
    if( this.state.vehicle_net_weight < 0){
      this.setState({ weightErrorMsg: "Tare weight should be less than gross weight" });
    }else{
      this.setState({ weightErrorMsg:null });
    }
	}

  onSave = () => {
  let  data  = this.state;
    console.log(data)
    if (this.validateCheck()) {
      data.rc_validity_date = data.rc_validity_date ? data.rc_validity_date.valueOf() : undefined;
      data.vehicle_registration_date = data.vehicle_registration_date ? data.vehicle_registration_date.valueOf() : undefined;
      data.fitness_certificate_date = data.fitness_certificate_date ? data.fitness_certificate_date.valueOf() : undefined;
      data.insurance_validity_date = data.insurance_validity_date ? data.insurance_validity_date.valueOf() : undefined;
      data.puc_validity_date = data.puc_validity_date ? data.puc_validity_date.valueOf() : undefined;
      
      this.props.toggleAdd();
      this.props.addTruckInfo(data);
    } else {
      this.setState({ errorMsg: "Please add all mandatory fields" });
    }
  };

  SelectDate = (id, selectedOption) => {
    this.setState({ [id]: selectedOption});
  };
  
  addLocation = () => {
    let { preferredroute } = this.state;
    preferredroute.push({ source: "", destination: "" });
    this.setState({ preferredroute });
  };
  deleteLocation = (index) => {
    const { preferredroute } = this.state;
    preferredroute.splice(index, 1);
    this.setState({ preferredroute });
  };

  addGps = () => {
    let { gpsCompanies } = this.state;
    gpsCompanies.push({ gps_company: "" });
    this.setState({ gpsCompanies });
  };
  deleteGps = (index) => {
    const { gpsCompanies } = this.state;
    gpsCompanies.splice(index, 1);
    this.setState({ gpsCompanies });
  };


  uploadfiles = (e, name) => {
    const { dispatch } = this.props;
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    dispatch({
      type: ACTION.DATA_GOVERNANCE.UPLOAD_VEHICLE_FIELS,
      formData,
      onSuccess: (data) => {
        this.setState({[name] : data});
      },
    });
  
  };
  onUpload = (name) => {
    this[name].click();
  };

  handleCompartments = (value, index, name) => {
    const { compartment_details } = this.state;
    compartment_details[index][name] = value;
    this.setState({ compartment_details });
  }
  handleSwitchChange = (name) => (checked) => {
    // let {gps_availability}=this.state;
    this.setState({ gps_availability: checked });
  };
  handleSwitch = (name) => (checked) => {
    // let {gps_availability}=this.state;
    this.setState({ is_blocked: checked });
  };
  onTabChange = (tabId) => {
    this.setState({
      selectedOption : tabId
    })
  }
  render() {
    const { gps_availability, gpsCompanies, is_blocked, } = this.state;
    const { preferredroute, showing } = this.state;
    const { data } = this.state;
    const {gpsVendorData } = this.props.master;
    const gpsVendorList = 
    (gpsVendorData || []).map((data) => ({ 
      label: data.name,
      value: data.id
    })) || [];
    const vehicleSizeListName =
      (this.props.VehicleTypes || []).map((data) => ({
        label: data.name,
        value: data.id,
        has_compartment: data.has_compartment
      })) || [];
    const FuleTypeListName =
      (this.props.FuleTypes || []).map((data) => ({
        label: data.name,
        value: data.id,
        has_compartment: data.has_compartment
      })) || [];
    return (
      <div className="add-user-wrap">
        <div onClick={this.props.toggleAdd} className="overlay"></div>
        <div id="modal" className="modal-popup" style={{width: '1200px'}}>
          <div className="pop-heading">Add Vehicle Info</div>
          {this.state.has_compartment &&
          <div className = "sub-navigation-holder">
          {
            this.state.tabs.map(tab => {
              return(
              <div key={tab.id} className={this.state.selectedOption === tab.id
                ? "selected-option options"
                : "options"} onClick={() => this.onTabChange(tab.id)}>{tab.label}</div>
              )})
          }
        </div>}
          {this.state.selectedOption === 1 &&
            <Fragment>
            <div className="content add-item-details">
            <div className="select-wrap-vehicle">
              <InputText
                label="Vehicle Reg No"
                id="vehicle_reg_no"
                value={this.state.vehicle_reg_no}
                placeholder="Vehicle Reg No"
                changeHandler={this.onChangeHandler}
                required
              />
            </div>

            <div className="select-wrap-vehicle">
              <InputText
                label="Vehicle Engine No"
                id="vehicle_engine_no"
                value={this.state.vehicle_engine_no}
                placeholder="Vehicle Engine No"
                changeHandler={this.onChangeHandler}
                required
              />
            </div>

            <div className="select-wrap-vehicle">
              <InputText
                label="Chassis No"
                id="vehicle_chassis_no"
                value={this.state.vehicle_chassis_no}
                placeholder="Chassis No"
                // changeHandler={(e) => this.setState({age: e})}
                changeHandler={this.onChangeHandler}
                required
              />
            </div>

            {/* <div style={{ display: 'flex' }}> */}
              <div className="select-wrap-vehicle">
                <InputText
                  label="Vehicle Owner"
                  id="vehicle_owner"
                  value={this.state.vehicle_owner}
                  placeholder="Vehicle Owner"
                  changeHandler={this.onChangeHandler}
                  required
                />
              </div>

              <div className="select-wrap-vehicle">
                <InputText
                  label="Contact No"
                  id="vehicle_owner_mobile"
                  value={this.state.vehicle_owner_mobile}
                  placeholder="Contact No"
                  changeHandler={this.onChangeHandler}
                  length={'10'}
						    isValidate={'numeric'}
                // type="number"
                required
                />
              </div>
              <div className="select-wrap-vehicle">
                <div className="label">
                  Vehicle Type <span className={"error error-font"}>*</span>
                </div>
                <Select
                  className="item-select"
                  value={vehicleSizeListName.find((val) => {
                    return val.label == this.state.vehicle_service_type;
                  })}
                  options={vehicleSizeListName}
                  onChange={this.handleChange.bind(this, "vehicle_service_type")}
                />
              </div>
              <div className="select-wrap-vehicle">
                <div className="label">
                  Fule Type <span className={"error error-font"}>*</span>
                </div>
                <Select
                  className="item-select"
                  value={FuleTypeListName.find((val) => {
                    return val.label == this.state.fuel_name;
                  })}
                  options={FuleTypeListName}
                  onChange={this.handleChange.bind(this, "fuel_name")}
                />
              </div>

              {this.state.has_compartment &&
              <Fragment>
                <div className="select-wrap-vehicle">
                  <InputText
                    label="Vehicle Class Type"
                    id="vehicle_class_type"
                    value={this.state.vehicle_class_type}
                    placeholder="Vehicle Class Type"
                    changeHandler={this.onChangeHandler}
                    
                  />
                </div>

                <div className="select-wrap-vehicle">
                  <InputText
                    label="Compartment Capacity"
                    id="compartment_capacity"
                    value={this.state.compartment_capacity}
                    placeholder="Compartment Capacity"
                    changeHandler={this.onChangeHandler}
                    
                  />
                </div>

                <div className="select-wrap-vehicle">
                  <InputText
                    label="Tanker Capacity"
                    id="tanker_capacity"
                    value={this.state.tanker_capacity}
                    placeholder="Tanker Capacity"
                    changeHandler={this.onChangeHandler}
                    
                  />
                </div>
              </Fragment>
              }

            <div className="select-wrap-vehicle">
                <div className="data-picker">
                  <div className="label">
                    Vehicle Registration Date
                    <span className="error-color">*</span>
                  </div>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.vehicle_registration_date}
                    onChange={this.SelectDate.bind(
                      this,
                      "vehicle_registration_date"
                    )}
                    placeholderText={"DD/MM/YYYY"}
                    maxDate={new Date()}
                  />
                </div>
                <div></div>
              </div>
              
              {this.state.has_compartment &&
                <div className="select-wrap-vehicle">
                  <InputText
                    label="Fitness No."
                    id="fitness_no"
                    value={this.state.fitness_no}
                    placeholder="Fitness No."
                    changeHandler={this.onChangeHandler}
                    required
                  />
                </div>}
              <div className="select-wrap-vehicle">
                <div className="data-picker">
                <div className="label">
                    Fitness Certificate Validity Date{" "}
                    <span className="error-color">*</span>
                  </div>
                  <div className={'flex'}>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.fitness_certificate_date}
                    onChange={this.SelectDate.bind(
                      this,
                      "fitness_certificate_date"
                    )}
                    placeholderText={"DD/MM/YYYY"}
                  />
                  <span >
                  <Upload
                 className=" ml-10 curP"
                 onClick={() => this.onUpload('uploadFitnessUrl')}
                 value="Upload file"
               />
                  </span>
                  <span className="error-color">*</span></div>
                
                <input
                type="file"
                style={{ display: "none" }}
                id="fitness_certificate_upload"
                ref={(e) => {
                  this.uploadFitnessUrl = e;
                }}
                onChange={(e) => {
                  this.uploadfiles(e, "fitness_certificate_img");
                }}
                onClick={(e) => {
                  e.target.value = null;
                }}
                  />
               </div>
                
               
                <div>
                  {this.state.fitness_certificate_img &&
                    this.state.fitness_certificate_img.name ? (
                    <a
                      href={this.state.fitness_certificate_img.url}
                      download
                      className={"theme-color fs-12 mt-5"}
                    >
                      {this.state.fitness_certificate_img.name}
                    </a>
                  ) : null}
                </div>
              </div>
              {this.state.has_compartment &&
                <div className="select-wrap-vehicle">
                  <InputText
                    label="PUC No."
                    id="puc_no"
                    value={this.state.puc_no}
                    placeholder="PUC No."
                    changeHandler={this.onChangeHandler}
                    required
                  />
                </div>}
              <div className="select-wrap-vehicle">
                <div className="data-picker">
                  <div className="label">
                    Puc Validity Date <span className="error-color">*</span>
                  </div>
                  <div className={'flex'}>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.puc_validity_date}
                    onChange={this.SelectDate.bind(this, "puc_validity_date")}
                    placeholderText={"DD/MM/YYYY"}
                  />
                  <span >
                    <Upload
                  className="ml-10  curP"
                  onClick={() => this.onUpload('uploadPUCUrl')}
                  value="Upload file"
                /></span>
                  
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="puc_validity_upload"
                  ref={(e) => {
                    this.uploadPUCUrl = e;
                  }}
                  onChange={(e) => {
                    this.uploadfiles(e, "puc_validity_img");
                  }}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                /></div>
                  
                </div>
                
                <div>
                  {this.state.puc_validity_img &&
                    this.state.puc_validity_img.name ? (
                    <a
                      href={this.state.puc_validity_img.url}
                      download
                      className={"theme-color fs-12 mt-5"}
                    >
                      {this.state.puc_validity_img.name}
                    </a>
                  ) : null}
                </div>
              </div>
              {/* </div> */}

              {this.state.has_compartment &&
                <div className="select-wrap-vehicle">
                  <InputText
                    label="RC Validity No."
                    id="rc_no"
                    value={this.state.rc_no}
                    placeholder="RC Validity No."
                    changeHandler={this.onChangeHandler}
                    required
                  />
                </div>}
            {/* <div style={{ display: 'flex' }}> */}
            <div className="select-wrap-vehicle">
            <div className="data-picker">
            <div className="label">
                RC Validity Date{" "}
                <span className="error-color">*</span>
              </div>
              <div className={'flex'}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={this.state.rc_validity_date}
                onChange={this.SelectDate.bind(
                  this,
                  "rc_validity_date"
                )}
                placeholderText={"DD/MM/YYYY"}
              />
              <span >
              <Upload
             className=" ml-10 curP"
             onClick={() => this.onUpload('uploadRCUrl')}
             value="Upload file"
           />
                </span><span className="error-color">*</span></div>
            
            <input
            type="file"
            id="rc_validity_upload"
            style={{ display: "none" }}
            ref={(e) => {
              this.uploadRCUrl = e;
            }}
            onChange={(e) => {
              this.uploadfiles(e, "rc_validity_img");
            }}
            onClick={(e) => {
              e.target.value = null;
            }}
              />
           </div>
            
           
            <div>
              {this.state.rc_validity_img &&
                this.state.rc_validity_img.name ? (
                <a
                  href={this.state.rc_validity_img.url}
                  download
                  className={"theme-color fs-12 mt-5"}
                >
                  {this.state.rc_validity_img.name}
                </a>
              ) : null}
            </div>
          </div>

          {this.state.has_compartment &&
          <Fragment>
                <div className="select-wrap-vehicle">
                  <InputText
                    label="Calibration No."
                    id="calibration_no"
                    value={this.state.calibration_no}
                    placeholder="Calibration No."
                    changeHandler={this.onChangeHandler}
                    required
                  />
                </div>
            {/* <div style={{ display: 'flex' }}> */}
            <div className="select-wrap-vehicle">
            <div className="data-picker">
            <div className="label">
                Calibration Date{" "}
                <span className="error-color">*</span>
              </div>
              <div className={'flex'}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={this.state.calibration_date}
                onChange={this.SelectDate.bind(
                  this,
                  "calibration_date"
                )}
                placeholderText={"DD/MM/YYYY"}
              />
              <span >
              <Upload
             className=" ml-10 curP"
             onClick={() => this.onUpload('uploadCalUrl')}
             value="Upload file"
           />
              </span>
              </div>
            
            <input
            type="file"
            style={{ display: "none" }}
            id="calibration_upload"
            ref={(e) => {
              this.uploadCalUrl = e;
            }}
            onChange={(e) => {
              this.uploadfiles(e, "calibration_img");
            }}
            onClick={(e) => {
              e.target.value = null;
            }}
              />
           </div>
            
           
            <div>
              {this.state.calibration_img &&
                this.state.calibration_img.name ? (
                <a
                  href={this.state.calibration_img.url}
                  download
                  className={"theme-color fs-12 mt-5"}
                >
                  {this.state.calibration_img.name}
                </a>
              ) : null}
            </div>
          </div>
          </Fragment>}

          {this.state.has_compartment &&
          <Fragment>
                <div className="select-wrap-vehicle">
                  <InputText
                    label="Explosive No."
                    id="explosive_no"
                    value={this.state.explosive_no}
                    placeholder="Explosive No."
                    changeHandler={this.onChangeHandler}
                    required
                  />
                </div>
            {/* <div style={{ display: 'flex' }}> */}
            <div className="select-wrap-vehicle">
            <div className="data-picker">
            <div className="label">
                Explosive Date{" "}
                <span className="error-color">*</span>
              </div>
              <div className={'flex'}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={this.state.explosive_date}
                onChange={this.SelectDate.bind(
                  this,
                  "explosive_date"
                )}
                placeholderText={"DD/MM/YYYY"}
              />
              <span >
              <Upload
             className=" ml-10 curP"
             onClick={() => this.onUpload('uploadExpUrl')}
             value="Upload file"
           />
              </span>
              <span className="error-color">*</span></div>
            
            <input
            type="file"
            style={{ display: "none" }}
            id="explosive_upload"
            ref={(e) => {
              this.uploadExpUrl = e;
            }}
            onChange={(e) => {
              this.uploadfiles(e, "explosive_img");
            }}
            onClick={(e) => {
              e.target.value = null;
            }}
              />
           </div>
            
           
            <div>
              {this.state.explosive_img &&
                this.state.explosive_img.name ? (
                <a
                  href={this.state.explosive_img.url}
                  download
                  className={"theme-color fs-12 mt-5"}
                >
                  {this.state.explosive_img.name}
                </a>
              ) : null}
            </div>
          </div>
          </Fragment>}
          
          {this.state.has_compartment &&
            <div className="select-wrap-vehicle">
              <InputText
                label="Insurance No."
                id="insurance_no"
                value={this.state.insurance_no}
                placeholder="Insurance No."
                changeHandler={this.onChangeHandler}
                required
              />
            </div>}
          <div className="select-wrap-vehicle">
          <div className="data-picker">
          <div className="label">
          Insurance validity Date
              <span className="error-color">*</span>
            </div>
            <div className={'flex'}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={this.state.insurance_validity_date}
              onChange={this.SelectDate.bind(
                this,
                "insurance_validity_date"
              )}
              placeholderText={"DD/MM/YYYY"}
            />
            <span >
            <Upload
           className=" ml-10 curP"
           onClick={() => this.onUpload('uploadInsuranceUrl')}
           value="Upload file"
         />
            </span>
            <span className="error-color">*</span></div>
          
          <input
          type="file"
          style={{ display: "none" }}
          id="insurance_validity_upload"
          ref={(e) => {
            this.uploadInsuranceUrl = e;
          }}
          onChange={(e) => {
            this.uploadfiles(e, "insurance_validity_img");
          }}
          onClick={(e) => {
            e.target.value = null;
          }}
            />
         </div>
          
         
          <div>
            {this.state.insurance_validity_img &&
              this.state.insurance_validity_img.name ? (
              <a
                href={this.state.insurance_validity_img.url}
                download
                className={"theme-color fs-12 mt-5"}
              >
                {this.state.insurance_validity_img.name}
              </a>
            ) : null}
          </div>
        </div>

        {this.state.has_compartment &&
          <Fragment>
                <div className="select-wrap-vehicle">
                  <InputText
                    label="National Permit No."
                    id="national_permit_no"
                    value={this.state.national_permit_no}
                    placeholder="National Permit No."
                    changeHandler={this.onChangeHandler}
                    required
                  />
                </div>
            {/* <div style={{ display: 'flex' }}> */}
            <div className="select-wrap-vehicle">
            <div className="data-picker">
            <div className="label">
                National Permit Date{" "}
                <span className="error-color">*</span>
              </div>
              <div className={'flex'}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={this.state.national_permit_date}
                onChange={this.SelectDate.bind(
                  this,
                  "national_permit_date"
                )}
                placeholderText={"DD/MM/YYYY"}
              />
              <span >
                <Upload
             className=" ml-10 curP"
             onClick={() => this.onUpload('uploadNPUrl')}
             value="Upload file"
           /></span>
              </div>
            
            <input
            type="file"
            style={{ display: "none" }}
            id="national_permit_upload"
            ref={(e) => {
              this.uploadNPUrl = e;
            }}
            onChange={(e) => {
              this.uploadfiles(e, "national_permit_img");
            }}
            onClick={(e) => {
              e.target.value = null;
            }}
              />
           </div>
            
           
            <div>
              {this.state.national_permit_img &&
                this.state.national_permit_img.name ? (
                <a
                  href={this.state.national_permit_img.url}
                  download
                  className={"theme-color fs-12 mt-5"}
                >
                  {this.state.national_permit_img.name}
                </a>
              ) : null}
            </div>
          </div>
          </Fragment>}

          {this.state.has_compartment &&
          <Fragment>
                <div className="select-wrap-vehicle">
                  <InputText
                    label="Half Year No."
                    id="half_year_no"
                    value={this.state.half_year_no}
                    placeholder="Half Year No."
                    changeHandler={this.onChangeHandler}
                    
                  />
                </div>
            {/* <div style={{ display: 'flex' }}> */}
            <div className="select-wrap-vehicle">
            <div className="data-picker">
            <div className="label">
                Half Year Date{" "}
                
              </div>
              <div className={'flex'}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={this.state.half_year_date}
                onChange={this.SelectDate.bind(
                  this,
                  "half_year_date"
                )}
                placeholderText={"DD/MM/YYYY"}
              />
              <span >
              <Upload
             className=" ml-10 curP"
             onClick={() => this.onUpload('uploadHYUrl')}
             value="Upload file"
           />
              </span>
              </div>
            
            <input
            type="file"
            style={{ display: "none" }}
            id="half_year_uplaod"
            ref={(e) => {
              this.uploadHYUrl = e;
            }}
            onChange={(e) => {
              this.uploadfiles(e, "half_year_img");
            }}
            onClick={(e) => {
              e.target.value = null;
            }}
              />
           </div>
            
           
            <div>
              {this.state.half_year_img &&
                this.state.half_year_img.name ? (
                <a
                  href={this.state.half_year_img.url}
                  download
                  className={"theme-color fs-12 mt-5"}
                >
                  {this.state.half_year_img.name}
                </a>
              ) : null}
            </div>
          </div>
          </Fragment>}

          {this.state.has_compartment &&
          <Fragment>
                <div className="select-wrap-vehicle">
                  <InputText
                    label="Safety Valve No."
                    id="safety_valve_no"
                    value={this.state.safety_valve_no}
                    placeholder="Safety Valve No."
                    changeHandler={this.onChangeHandler}
                    
                  />
                </div>
            {/* <div style={{ display: 'flex' }}> */}
            <div className="select-wrap-vehicle">
            <div className="data-picker">
            <div className="label">
                Safety Valve Date{" "}
                
              </div>
              <div className={'flex'}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={this.state.safety_valve_date}
                onChange={this.SelectDate.bind(
                  this,
                  "safety_valve_date"
                )}
                placeholderText={"DD/MM/YYYY"}
              />
              <span >
              <Upload
             className=" ml-10 curP"
             onClick={() => this.onUpload('uploadSVUrl')}
             value="Upload file"
           />
              </span>
              </div>
            
            <input
            type="file"
            style={{ display: "none" }}
            ref={(e) => {
              this.uploadSVUrl = e;
            }}
            id="safety_valve_upload"
            onChange={(e) => {
              this.uploadfiles(e, "safety_valve_img");
            }}
            onClick={(e) => {
              e.target.value = null;
            }}
              />
           </div>
            
           
            <div>
              {this.state.safety_valve_img &&
                this.state.safety_valve_img.name ? (
                <a
                  href={this.state.safety_valve_img.url}
                  download
                  className={"theme-color fs-12 mt-5"}
                >
                  {this.state.safety_valve_img.name}
                </a>
              ) : null}
            </div>
          </div>
          </Fragment>}

          {this.state.has_compartment &&
          <Fragment>
                <div className="select-wrap-vehicle">
                  <InputText
                    label="Suraksha Certificate No."
                    id="suraksha_certificate_no"
                    value={this.state.suraksha_certificate_no}
                    placeholder="Suraksha Certificate No."
                    changeHandler={this.onChangeHandler}
                    
                  />
                </div>
            {/* <div style={{ display: 'flex' }}> */}
            <div className="select-wrap-vehicle">
            <div className="data-picker">
            <div className="label">
                Suraksha Certificate Date{" "}
              </div>
              <div className={'flex'}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={this.state.suraksha_certificate_date}
                onChange={this.SelectDate.bind(
                  this,
                  "suraksha_certificate_date"
                )}
                placeholderText={"DD/MM/YYYY"}
              />
              <span >
              <Upload
             className=" ml-10 curP"
             onClick={() => this.onUpload('uploadSCUrl')}
             value="Upload file"
           />
              </span>
              </div>
            
            <input
            type="file"
            style={{ display: "none" }}
            ref={(e) => {
              this.uploadSCUrl = e;
            }}
            id="suraksha_certificate_uplaod"
            onChange={(e) => {
              this.uploadfiles(e, "suraksha_certificate_img");
            }}
            onClick={(e) => {
              e.target.value = null;
            }}
              />
           </div>
            
           
            <div>
              {this.state.suraksha_certificate_img &&
                this.state.suraksha_certificate_img.name ? (
                <a
                  href={this.state.suraksha_certificate_img.url}
                  download
                  className={"theme-color fs-12 mt-5"}
                >
                  {this.state.suraksha_certificate_img.name}
                </a>
              ) : null}
            </div>
          </div>
          </Fragment>}
          {this.state.has_compartment &&
          <Fragment>
                <div className="select-wrap-vehicle">
                  <InputText
                    label="Hazardous No."
                    id="hazardous_no"
                    value={this.state.hazardous_no}
                    placeholder="Hazardous No."
                    changeHandler={this.onChangeHandler}
                    
                  />
                </div>
            {/* <div style={{ display: 'flex' }}> */}
            <div className="select-wrap-vehicle">
            <div className="data-picker">
            <div className="label">
                Hazardous Date{" "}
              </div>
              <div className={'flex'}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={this.state.hazardous_date}
                onChange={this.SelectDate.bind(
                  this,
                  "hazardous_date"
                )}
                placeholderText={"DD/MM/YYYY"}
              />
              <span >
              <Upload
             className=" ml-10 curP"
             onClick={() => this.onUpload('uploadHCUrl')}
             value="Upload file"
           />
              </span>
              </div>
            
            <input
            type="file"
            style={{ display: "none" }}
            id="hazardous_upload"
            ref={(e) => {
              this.uploadHCUrl = e;
            }}
            onChange={(e) => {
              this.uploadfiles(e, "hazardous_img");
            }}
            onClick={(e) => {
              e.target.value = null;
            }}
              />
           </div>
            
           
            <div>
              {this.state.hazardous_img &&
                this.state.hazardous_img.name ? (
                <a
                  href={this.state.hazardous_img.url}
                  download
                  className={"theme-color fs-12 mt-5"}
                >
                  {this.state.hazardous_img.name}
                </a>
              ) : null}
            </div>
          </div>
          </Fragment>}
          {this.state.has_compartment &&
          <Fragment>
                <div className="select-wrap-vehicle">
                  <InputText
                    label="Tanker Cleaning Certificate No."
                    id="tanker_cleaning_certificate_no"
                    value={this.state.tanker_cleaning_certificate_no}
                    placeholder="Tanker Cleaning Certificate No."
                    changeHandler={this.onChangeHandler}
                    
                  />
                </div>
            {/* <div style={{ display: 'flex' }}> */}
            <div className="select-wrap-vehicle">
            <div className="data-picker">
            <div className="label">
                Tanker Cleaning Certificate Date{" "}
              </div>
              <div className={'flex'}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={this.state.tanker_cleaning_certificate_date}
                onChange={this.SelectDate.bind(
                  this,
                  "tanker_cleaning_certificate_date"
                )}
                placeholderText={"DD/MM/YYYY"}
              />
              <span >
              <Upload
             className=" ml-10 curP"
             onClick={() => this.onUpload('uploadTCUrl')}
             value="Upload file"
           />
              </span>
              </div>
            
            <input
            type="file"
            style={{ display: "none" }}
            id="tanker_cleaning_certificate_uplaod"
            ref={(e) => {
              this.uploadTCUrl = e;
            }}
            onChange={(e) => {
              this.uploadfiles(e, "tanker_cleaning_certificate_img");
            }}
            onClick={(e) => {
              e.target.value = null;
            }}
              />
           </div>
            
           
            <div>
              {this.state.tanker_cleaning_certificate_img &&
                this.state.tanker_cleaning_certificate_img.name ? (
                <a
                  href={this.state.tanker_cleaning_certificate_img.url}
                  download
                  className={"theme-color fs-12 mt-5"}
                >
                  {this.state.tanker_cleaning_certificate_img.name}
                </a>
              ) : null}
            </div>
          </div>
          </Fragment>}
            {this.state.has_compartment &&
              <div className="select-wrap-vehicle">
                  <InputText
                    label="Vehicle Age"
                    id="vehicle_age"
                    value={this.state.vehicle_registration_date && moment().diff(this.state.vehicle_registration_date, 'years', true).toFixed(0) || ''}
                    placeholder="Vehicle Age"
                    changeHandler={this.onChangeHandler}
                    readOnly={true}
                  />
                </div>}
              {/* </div> */}
              <div className="select-wrap-vehicle">
              <InputText
                label="Gross Weight"
                placeholder="Gross Weight"
                id="gross_weight"
                value={this.state.gross_weight}
                changeHandler={this.onChangeHandler}
                type="number"
						    isValidate={'numeric'}
                required
              /></div>
              <div className="select-wrap-vehicle">
            <InputText
              label="Vehicle Tare Weight"
              placeholder="Vehicle Tare Weight"
              id="vehicle_tare_weight"
              value={this.state.vehicle_tare_weight}
              changeHandler={this.onChangeHandler}
              type="number"
              isValidate={'numeric'}
              required
            />
            {this.state.weightErrorMsg && (
              <div className="error-color" style={{fontSize:"13px"}}>{this.state.weightErrorMsg}</div>
            )}
            </div>
           
            <div className="select-wrap-vehicle">
              <InputText
                label="Vehicle Net Weight"
                placeholder="Vehicle Net Weight"
                id="vehicle_net_weight"
                value={this.state.vehicle_net_weight}
                readonly={true}
              changeHandler={this.onChangeHandler}
                type="number"
                required
              />
              </div>
            <div className="select-wrap-vehicle">
            <InputText
              label="Vehicle Passing Capacity"
              placeholder="Vehicle Passing Capacity"
              id="vehicle_passing_capacity"
              value={this.state.vehicle_passing_capacity}
              changeHandler={this.onChangeHandler}
              required
              type="number"
              isValidate={'numeric'}
            /></div>
            <div className="select-wrap-vehicle">
            <InputText
              label="Container No"
              placeholder="Container No"
              id="container_no"
              value={this.state.container_no}
              changeHandler={this.onChangeHandler}
              type="number"
              isValidate={'numeric'}
            />
            </div>
            <div className="select-wrap-vehicle">
            <InputText
              label="Container Weight"
              placeholder="Container Weight"
              id="container_weight"
              value={this.state.container_weight}
              changeHandler={this.onChangeHandler}
              type="number"
              isValidate={'numeric'}
            />
            </div>
            <div className="select-wrap-vehicle">
            <InputText
              label="Seal No"
              placeholder="Seal No"
              id="seal_no"
              value={this.state.seal_no}
              changeHandler={this.onChangeHandler}
            />
            </div>
            <div className="select-wrap-vehicle">
            <InputText
              label="Excise No"
              placeholder="Excise No"
              id="excise_sNo"
              value={this.state.excise_sNo}
              changeHandler={this.onChangeHandler}
            />
          </div>
          </div>
          

          <div
            className="fBold fs-16 mt-10 mb-10 header-medium-color col-2 flex-1 mr-30"
            style={{ marginLeft: "20px", color: "#868e96" }}
          >
            GPS Available
            <div className="mt-10">
              <Switch
                onChange={this.handleSwitchChange()}
                onColor="#528aea"
                checked={gps_availability}
              />
            </div>
          </div>
          {/* {gps_availability && <div className="select-wrap">
            
              <div className="label">Gps Vendors <span className={"error error-font"}>*</span></div>
              <Select
                className="item-select"
                options={gpsVendorList}
                onChange={this.handleChange.bind(this, "gps_details")}
              />
            </div>} */}
            <br/>


          {gps_availability ? (
            <div className="flex">
              <div className="fBold fs-16 mt-10 mb-10 header-medium-color col-2 flex-1 mr-30">
                <div>
                  <div
                    style={{
                      marginLeft: "20px",
                      color: "#868e96",
                      display: "inline",
                    }}
                  >
                    GPS Companies
                    <div
                      className="button-add"
                      onClick={this.addGps}
                      style={{ margin: "auto", marginLeft: "10px" }}
                    >
                      ADD
                    </div>
                  </div>
                </div>
                {gpsCompanies.map((item, index) => (
                  <div
                    className="flex col-1"
                    style={{ marginLeft: "20px", marginTop: "15px" }}
                  >
                    {/* <InputText
                      placeholder="GPS Companies"
                      label="GPS Companies"
                      id={"GPS Companies"}
                      value={item.gps_companies}
                      changeHandler={(value) => {
                        let { gpsCompanies } = this.state;
                        gpsCompanies[index].gps_company = value;
                        this.setState({ gpsCompanies });
                      }}
                    /> */}
                    <Select
                      className="item-select wt-220"
                      options={gpsVendorList}
                      value={(gpsVendorList || []).filter(obj => obj.label === item.gps_company)}
                      onChange={(value) => {
                        let { gpsCompanies } = this.state;
                        gpsCompanies[index].gps_company = value.label;
                        this.setState({ gpsCompanies });
                      }}
                    />
                    <div
                      className="button-add mt-1rem"
                      onClick={() => this.deleteGps(index)}
                      style={{
                        minWidth: "76px",
                        marginTop: "20px",
                        marginLeft: "148px",
                      }}
                    >
                      X
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          <div
            className="label"
            style={{
              fontSize: "20px",
              marginLeft: "20px",
              color: "#868e96",
              display: "inline",
              marginRight: "10px",
            }}
          >
            Add Location
          </div>
          {
            <div
              className="button-add"
              onClick={this.addLocation}
              style={{ margin: "auto" }}
            >
              ADD
            </div>
          }
          {preferredroute.map((value, index) => {
            return (
              <div className="details flex space-between" key={index}>
                <div
                  className="fiscal-section"
                  style={{
                    width: "24%",
                    marginLeft: "20px",
                    marginTop: "15px",
                  }}
                >
                  <InputText
                    placeholder="Source"
                    id={"source"}
                    label="SOURCE"
                    value={value.source}
                    changeHandler={(value) => {
                      let { preferredroute } = this.state;
                      preferredroute[index].source = value;
                      this.setState({ preferredroute });
                    }}
                  />
                </div>
                <div
                  className="fiscal-section"
                  style={{
                    width: "24%",
                    marginLeft: "20px",
                    marginTop: "15px",
                  }}
                >
                  <InputText
                    placeholder="Destinations"
                    id={"destination"}
                    label="Destination"
                    value={value.destination}
                    changeHandler={(value) => {
                      let { preferredroute } = this.state;
                      preferredroute[index].destination = value;
                      this.setState({ preferredroute });
                    }}
                  />
                </div>

                <div
                  className="button-add"
                  style={{ marginTop: "30px" }}
                  onClick={() => this.deleteLocation(index)}
                >
                  X
                </div>
              </div>
            );
          })}
          </Fragment>}
          {this.state.has_compartment && this.state.selectedOption === 2 &&
                  <div className="flex-between border-bottom-light pv-20 ht-400 overflowDiv">
                  <div className="mt-10">
                  {/* <span className="fSemibold"> {`Compartment Total: 
                    ${totalCapacity}`}</span> */}
                    <table className="mt-10">
                      <thead>
                         <tr>
                           <th></th>
                           <th>Liters</th>
                           <th>PL CM</th>
                           <th>DL CM</th>
                         </tr>
                      </thead>
                      <tbody>
                        {(this.state.compartment_details || []).map((val, index) => (
                        <tr>
                          <td className="fSemibold wt-150">{`Compartment ${val.compartment_no}`}</td>
                          <td>
                            <div className="compartment">
                            <InputText
                              type="number"
                              id={"capacity"}
                              placeholder="Capacity"
                              value={val.capacity}
                              changeHandler={(e) => this.handleCompartments(e, index, "capacity")}
                              
                            />
                            </div>
                          </td>
                          <td>
                          <div className="compartment">
                            <InputText
                              type="number"
                              id={"pl"}
                              placeholder="PL"
                              value={val.pl}
                              changeHandler={(e) => this.handleCompartments(e, index, "pl")}
                             
                            />
                            </div>
                          </td>
                          <td>
                          <div className="compartment">
                            <InputText
                              type="number"
                              id={"dl"}
                              placeholder="DL"
                              value={val.dl}
                              changeHandler={(e) => this.handleCompartments(e, index, "dl")}
                              className="compartment"
                            />
                            </div>
                          </td>
                        </tr>))}
                      </tbody>
                    </table>
                    
                  </div>
                  </div>}

          <div className="submit-button">
            {this.state.errorMsg && (
              <div className="error-color">{this.state.errorMsg}</div>
            )}
            <div className="button-section">
              <Button value="Save" click={this.onSave} />
            </div>
            <div className="button-section">
              <Button value="Cancel" click={this.props.toggleAdd} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
	return {
		master: state.master,
	};
};
export default connect(mapStateToProps)(AddItem);
