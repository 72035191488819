import ACTION from "../common/action_constant";

const dataGovernance = (
	state = {	
		delayPenaltyData: [],
		liabilityData: [],
		networkmasterData:[],
		originNetworkMasterDropdown:[],
		modeNetworkMasterDropdown:[],
  		routenameNetworkMasterDropdown:[],
  		sourceNetworkMasterDropdown:[],
		downloadData: {},
		laneMasterData: {},
		cityMasterData: {},
		volumetricData: [],
		downLoadLink: {},
		podClauseData: [],
		vendorTypes: [],
		channelTypes: [],
		distributionTypes: [],
		modeTypes: [],
		podCycles: [{
			id: 1,
			value: 'Monthly',
		}, {
			id: 2,
			value: 'Quaterly',
		}, {
			id: 3,
			value: 'Annualy',
		}],
		podSubmission: [{
			id: 1,
			value: '24 Hours',
		}, {
			id: 2,
			value: '48 Hours',
		}],
		podStatus: [],
		loading: false,
		allTypesOfOdaData: [],
		rateMastersData: [],
		regionsData: [],
		hubsData: [],
		volumeTypes: [],
		rapidRates: {},
		demurragesData: [],
		odaCount: 0,
		otherCharges: [],
		otherChargesDropdown: [],
		ODAhubsList: [],
		hubsMasterDropdown: [],
		hubsMasterdownloadLink: '',
		dphDiscountRates: [],
		glCodeMasterData:[],
		hsdMasterData: [],
		hsdLaneData: [],
		hsdConsolidatedRFQs: [],
		driverInfoData:[],
		truckInfoData:[],
		vehicleTypesData:[],
		tatVendorDetails: [],
		fuleTypesData:[],
		dailyDPHData: []
		// uploadFiles:{}
		// dphVendorLanesLink: '',

	}, action) => {
	let tempState = Object.assign({}, state);



	if (action.type === ACTION.DATA_GOVERNANCE.GET_DELAY_PENALTY_DATA) {
		tempState.delayPenaltyData = action.data;
		return tempState;
	}

	else if (action.type === ACTION.DATA_GOVERNANCE.DOWNLOAD_DELAY_DATA) {
		tempState.downloadData = action.data;
		return tempState;
	}

	else if (action.type === ACTION.DATA_GOVERNANCE.GOVERNANCE_MODE_TYPES_DATA) {
		tempState.modeTypes = action.data;
		return tempState;
	}

	else if (action.type === ACTION.DATA_GOVERNANCE.GOVERNANCE_CHANNEL_TYPES_DATA) {
		tempState.channelTypes = action.data;
		return tempState;
	}

	else if (action.type === ACTION.DATA_GOVERNANCE.GOVERNANCE_DISTRIBUTION_TYPES_DATA) {
		tempState.distributionTypes = action.data;
		return tempState;
	}

	else if (action.type === ACTION.DATA_GOVERNANCE.GET_GOVERNANCE_VENDORS_DATA) {
		tempState.vendorTypes = action.data;
		return tempState;
	}

	else if (action.type === ACTION.DATA_GOVERNANCE.GET_GOVERNANCE_CHANNELS_DATA) {
		tempState.channelTypes = action.data;
		return tempState;
	}

	else if (action.type === ACTION.DATA_GOVERNANCE.LIABILITY_DETAILS) {
		tempState.liabilityData = action.data;
		return tempState;

	} 
	else if (action.type === ACTION.DATA_GOVERNANCE.NETWORK_MASTER_DETAILS) {
		tempState.networkmasterData = action.data;
		return tempState;

	} 
	
	else if (action.type === ACTION.DATA_GOVERNANCE.GET_VOLUMETRIC_WEIGHT_DATA) {
		tempState.volumetricData = action.data;
		return tempState;
	}

	else if (action.type === ACTION.DATA_GOVERNANCE.CLAIM_TYPES_DATA) {
		tempState.claimTypes = action.data;
		return tempState;
	}

	else if (action.type === ACTION.DATA_GOVERNANCE.SHORTAGES_TYPES_DATA) {
		const allTypes = action.data;
		(allTypes || []).forEach((item) => {
			item.value = item.id;
			item.label = item.name;
		});
		tempState.shortageTypes = allTypes;
		return tempState;
	}

	else if (action.type === ACTION.DATA_GOVERNANCE.GET_CITY_LIST_DATA) {
		tempState.citiesList = action.data;
		return tempState;
	}

	else if (action.type === ACTION.DATA_GOVERNANCE.GET_CITY_PINCODES_DATA) {
		tempState.citiesList = action.data;
		return tempState;
	}

	else if (action.type === ACTION.DATA_GOVERNANCE.GET_LANE_MASTER_DATA) {
		tempState.laneMasterData = action.data && action.data.length > 0 ? action.data[0] : {};
		return tempState;
	}

	else if (action.type === ACTION.DATA_GOVERNANCE.GET_CITY_MASTER_DATA) {
		tempState.cityMasterData = action.data && action.data.length > 0 ? action.data[0] : {};
		return tempState;
	}
	else if (action.type === ACTION.DATA_GOVERNANCE.DOWNLOAD_VOLUMETRIC_DATA) {
		tempState.downLoadLink = action.data;
		return tempState;
	}
	else if (action.type === ACTION.DATA_GOVERNANCE.GET_POD_CLAUSE_MASTER_DATA) {
		tempState.podClauseData = action.data;
		return tempState;
	}
	else if (action.type === ACTION.DATA_GOVERNANCE.GET_POD_STATUS_DATA) {
		tempState.podStatus = action.data;
		return tempState;
	}
	else if (action.type === ACTION.DATA_GOVERNANCE.GET_POD_CYCLES_DATA) {
		tempState.podCycles = action.data;
		return tempState;
	}
	else if (action.type === ACTION.DATA_GOVERNANCE.SHOW_DATA_LOADER) {
		tempState.loading = true;
		return tempState;

	}
	else if (action.type === ACTION.DATA_GOVERNANCE.HIDE_DATA_LOADER) {
		tempState.loading = false;
		return tempState;

	}
	else if (action.type === ACTION.DATA_GOVERNANCE.GET_ODA_MASTER_DATA) {
		tempState.odaMasterData = action.data;
		tempState.odaCount = action.count;
		return tempState;
	}
	else if (action.type === ACTION.DATA_GOVERNANCE.ALL_TYPES_OF_ODA_DATA) {
		tempState.allTypesOfOdaData = action.data;
		tempState.allTypesOfOdaData.map((oda) => {
			oda.value = oda.id;
			oda.label = oda.name;
		})
		return tempState;
	}
	else if (action.type === ACTION.DATA_GOVERNANCE.DOWNLOAD_ODA_DATA) {
		tempState.downLoadLink = action.data;
		return tempState;
	}
	// GL CODE
	else if (action.type === ACTION.DATA_GOVERNANCE.GET_GLCODE_MASTER_DATA) {
		tempState.glCodeMasterData = action.data;
		// tempState.odaCount = action.count;
		return tempState;
	}
	else if (action.type === ACTION.DATA_GOVERNANCE.GET_RM_MASTER_DATA) {
		tempState.rateMastersData = action.data;
		return tempState;
	}
	else if (action.type === ACTION.DATA_GOVERNANCE.GET_RM_VENDORS_TYPES_DATA) {
		tempState.vendorTypes = action.data;
		return tempState;
	}
	else if (action.type === ACTION.DATA_GOVERNANCE.GET_RM_MODES_TYPES_DATA) {
		tempState.modeTypes = action.data;
		return tempState;
	}
	else if (action.type === ACTION.DATA_GOVERNANCE.GET_RM_REGIONS_DATA) {
		tempState.regionsData = action.data || [];
		return tempState;
	}
	else if (action.type === ACTION.DATA_GOVERNANCE.DOWNLOAD_RM_TEMPLATE) {
		tempState.downLoadLink = action.data;
		return tempState;
	}
	else if (action.type === ACTION.DATA_GOVERNANCE.GET_HUBS_DATA) {
		tempState.hubsData = action.data;
		return tempState;
	}
	else if (action.type === ACTION.DATA_GOVERNANCE.GET_VOLUMETRIC_WEIGHT_TYPE_DATA) {
		tempState.volumeTypes = action.data;
		return tempState;
	}
	else if (action.type === ACTION.DATA_GOVERNANCE.RAPID_RATES_DATA) {
		tempState.rapidRates = action.data;
		return tempState;
	}
	else if (action.type === ACTION.DATA_GOVERNANCE.DEMURRAGE_DATA) {
		tempState.demurragesData = action.data;
		return tempState;
	} 
	else if (action.type === ACTION.DATA_GOVERNANCE.OTHER_CHARGES_DATA) {
		tempState.otherCharges = action.data;
		return tempState;
	} 
	else if (action.type === ACTION.DATA_GOVERNANCE.OTHER_CHARGES_DROPDOWN_DATA) {
		tempState.otherChargesDropdown = action.data;
		return tempState;
	} 
	else if (action.type === ACTION.DATA_GOVERNANCE.GET_HUB_LIST_DATA) {
		tempState.ODAhubsList = action.data;
		return tempState;
	} 
	else if ( action.type === ACTION.DATA_GOVERNANCE.HUBS_MASTER_DROPDOWN_DATA) {
		tempState.hubsMasterDropdown = action.data;
		return tempState;
	} 
	else if ( action.type === ACTION.DATA_GOVERNANCE.SAVE_ORIGIN_NETWORK_MASTER_DROPDOWN_DATA) {
		tempState.originNetworkMasterDropdown = action.data;
		return tempState;
	} 
	else if ( action.type === ACTION.DATA_GOVERNANCE.SAVE_MODE_NETWORK_MASTER_DROPDOWN_DATA) {
		tempState.modeNetworkMasterDropdown = action.data;
		return tempState;
	} 
	else if ( action.type === ACTION.DATA_GOVERNANCE.SAVE_ROUTE_NAME_NETWORK_MASTER_DROPDOWN_DATA) {
		tempState.routenameNetworkMasterDropdown = action.data;
		return tempState;
	} 
	else if ( action.type === ACTION.DATA_GOVERNANCE.SAVE_SOURCE_NETWORK_MASTER_DROPDOWN_DATA) {
		tempState.sourceNetworkMasterDropdown = action.data;
		return tempState;
	} 

	else if ( action.type === ACTION.DATA_GOVERNANCE.DOWNLOAD_HUBS_MASTER_DATA) {
		tempState.hubsMasterdownloadLink = action.data;
		return tempState;
	}
	else if ( action.type === ACTION.DATA_GOVERNANCE.DPH_DISCOUNT_STRUCTURE_DATA) {
		tempState.dphDiscountRates = action.data;
		return tempState;
	}
	// else if (action.type === ACTION.DATA_GOVERNANCE.DOWNLOAD_VENDOR_LANES_LINK) {
	// 	tempState.dphVendorLanesLink = action.data;
	// 	return tempState;
	// }
	else if ( action.type === ACTION.DATA_GOVERNANCE.HSD_CONTRACT_LANE_DATA){
		tempState.hsdLaneData = action.data;
		return tempState;
	}
	else if ( action.type === ACTION.DATA_GOVERNANCE.HSD_CONTRACT_DATA){
		tempState.hsdMasterData = action.data;
		return tempState;
	}
	else if ( action.type === ACTION.DATA_GOVERNANCE.HSD_CONSOLIDATED_RFQ_DATA){
		tempState.hsdConsolidatedRFQs = action.data;
		return tempState;
	}
	else if ( action.type === ACTION.DATA_GOVERNANCE.STORE_DRIVER_INFO){
		tempState.driverInfoData = action.data;
		return tempState;
	}else if ( action.type === ACTION.DATA_GOVERNANCE.STORE_TRUCK_INFO){
		tempState.truckInfoData = action.data;
		return tempState;
	}else if ( action.type === ACTION.DATA_GOVERNANCE.STORE_VEHICLE_TYPE){
		tempState.vehicleTypesData = action.data;
		return tempState;
	}else if ( action.type === ACTION.DATA_GOVERNANCE.TAT_FORM_VENDOR_DATA){
		tempState.tatVendorDetails = action.data;
		return tempState;
	}else if ( action.type === ACTION.DATA_GOVERNANCE.STORE_DAILY_DPH){
		tempState.dailyDPHData = action.data;
		return tempState;
	}
	else if ( action.type === ACTION.DATA_GOVERNANCE.STORE_FULE_TYPE){
		tempState.fuleTypesData = action.data;
		return tempState;
	}
	
	return state;
};

export default dataGovernance;
