import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import "./sendmail.less";
import { getCookie } from "../../../utils/cookies";
import { isThisHour } from "date-fns";
import { toPascalCase } from "../../../utils/validate";


class SendMail extends Component {
	constructor(props) {
		super(props);
		this.props.getMails();
		this.state = {
			emails: [],
			mail: "",
			subject: "",
			content: "",
			error: null,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.broadcast.emailsList !== this.props.broadcast.emailsList) {
			this.setState({
				emails: nextProps.broadcast.emailsList
			});
		}

		if (nextProps.mail !== null && this.state.emails !== []) {
			this.setState({
				emails: nextProps.mail,
				content: nextProps.cont
			});
		}
	}

	isValid(email) {
		var error = null;
		if (!this.isEmail(email)) {
			error = `${email} Is Not A Valid Email Address.`;
		}
		if (this.isAdded(email)) {
			error = `${email} Has Already Been Added.`;
		}
		if (error) {
			this.setState({ error });
			return false;
		}
		return true;
	}

	isEmail = email => {
		return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
	};

	isAdded = email => {
		return this.state.emails.includes(email);
	};

	onMailChange = e => {
		this.setState({ mail: e.target.value, error: null });
	};

	onChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onAdd = e => {
		var email = this.state.mail.trim();
		if (email && this.isValid(email)) {
			//this.setState({ emails: this.props.e.emailsList });
			this.setState({ emails: [...this.state.emails, email], mail: "" });
		}
		console.log("Mail Added :", this.state.mail);
	};

	onCancel = () => {
		this.setState({
			emails: [],
			mail: "",
			subject: "",
			content: "",
			error: null
		});
	};
	onDefaultAdd = e => {
		//  this.refs.btn.setAttribute("disabled", "disabled");
		//  this.refs.btn.setAttribute("cursor", "none");
		this.setState({
			emails: [...this.state.emails, ...this.props.broadcast.emailsList]
		});
		//this.setState({ emails: this.props.e.emailsList });
	};

	//   onCopiedAdd = e => {
	//     //  this.refs.btn.setAttribute("disabled", "disabled");
	//     //  this.refs.btn.setAttribute("cursor", "none");
	//     this.setState({
	//       emails: [this.props.broadcast.data[0].email_list[0]]
	//     });
	//     //this.setState({ emails: this.props.e.emailsList });
	//   };

	onDelete = removedEmail => {
		this.setState({
			emails: this.state.emails.filter(email => email !== removedEmail)
		});
	};

	onClear = e => {
		this.setState({ emails: [] });
		//window.location.reload();
		//this.props.mail = [];
		//this.setState({ mail: this.props.broadcast.data[0].email_list[0] });
		//  console.log("state", this.state);
	};

	componentDidMount() {
		if(this.props.mail !== null){
			this.setState({ emails: this.props.mail });
		}
	}

	fileUpload = (e) => {
		console.log(e.target.files);
		this.setState({ files: e.target.files });
	}

	sendMail = () => {
		const { emails, mail, content, subject, messageContent, files } = this.state;
		// console.log(JSON.stringify(content).replace(/\n/g, '<br/>'));
		//{ "company_name": "SushmithaProvider", "email": "sushmithapatted123@gmail.com", "contact": "9620059682", "pan_no": "abcde12345", "admin_name": "admin@teg.com", "company_type": 2, "service_type": [{ "id": 5, "name": "All" }], "category_type": [{ "id": 2, "name": "Fleet Owner & Transporter" }], "is_active": true, "is_admin": true, "created": 1551946172362, "company_logo": "", "id": "d634ef1f01b843c0a1586c10305ca1e8", "company_id": "d634ef1f01b843c0a1586c10305ca1e8", "user_id": "0ef2b519cfe8464680aea41ac0eda641", "company_email": "", "company_website": "", "gstin": "", "tax_no": "", "year_of_formation": "", "sap_vendor_code": "99008", "gstin_details": [{ "gstin": "", "state": "", "code": "" }], "sap_vendor_details": [{ "sap_vendor_code": "99008", "company": "SushmithaSeeker", "company_id": "f9e6c0bb90d24a31905141be0712cba9" }], "last_fiscal_turnover": { "unit": "", "value": "", "name": "" }, "token": "0b3b09e26c114e628364b83402988adf", "user_type": 3, "name": "admin@teg.com" }
		const usertype1 =
			JSON.parse(getCookie("user")).user_type === 1 ? "Caliper" : JSON.parse(getCookie("user")).user_type === 2 ? "Seeker" : "Provider";
		const usertype2 = usertype1 === "Caliper" ? "Caliper User" : usertype1 === "Seeker" ? "Provider" : "Seeker";
		// const data = {
		//   subject:subject,
		//   message: content.replace(/\n/g,"<br />"),
		//   from_type: usertype1,
		//   to_type: usertype2,
		//   emailsList: emails
		// };
		let form = new FormData();
		if (files) {
			for (let i = 0; i < files.length; i++) {
				form.append("file", files[i]);
			}
		}
		form.append('subject', subject);
		form.append('message', content.replace(/\n/g, "<br />"));
		form.append('from_type', usertype1);
		form.append('to_type', usertype2);
		form.append('emailsList', emails);

		this.props.postAllMails(form);

		this.setState({
			emails: [],
			mail: "",
			subject: "",
			content: "",
			error: null,
			files: ""
		});

	};

	render = () => {
		//  let x = this.props.e;
		//  console.log("render", this.props.emaillist.emailsList, this.state.emails);
		//this.setState({ emails: this.props.e.emailsList });
		return (
			<div className="root">
				<div className="to" data-placeholder='To:'></div>
				<div className="main">
					<div className="element">
						<input
							name="mail"
							className="text-input"
							placeholder="Add Email-ID"
							value={this.state.mail}
							onChange={this.onMailChange}
						/>
						<button className="generate-button" onClick={this.onAdd}>
							ADD
            </button>
						{this.state.error && <p className="error">{this.state.error}</p>}
					</div>

					{/* {this.state.emails && this.state.emails.length ?
          <div className="element">
            <div className="count-box">
              <label>Total: </label>
            </div>
            <div className="count-box">
              {this.state.emails && this.state.emails.length
                ? this.state.emails.length
                : null}
            </div>
          </div>
          : null} */}
					<div>
						<div className="list-div inline-button">
							{/* <label id="to">To:</label> */}
							{/* <div>{this.state.emails.length}</div> */}
							{/* {this.setState({ emails: x.emailsList })} */}
							{/* {console.log("return", this.state.emails)} */}
							{this.state.emails &&
								this.state.emails.map((em, i) => (
									<div key={this.state.emails[i]}>
										<div className="list">
											{"  "}
											{em}
											{"  "}
											<button
												className="btn-cls"
												type="button"
												onClick={() => this.onDelete(em)}
											>
												&times;
                      </button>
										</div>
									</div>
								))}
						</div>
						<div className="inline-button">
							{this.state.emails && this.state.emails.length === 0 ? (
								<button
									className="generate-button-default"
									onClick={this.onDefaultAdd}
									ref="btn"
								>
									DEFAULT LIST
                </button>
							) : (
									<button
										className="generate-button-default"
										onClick={this.onClear}
									>
										CLEAR
                </button>
								)}
						</div>
						<div id="total">
							{this.state.emails && this.state.emails.length ?
								<div>
									<div className="count-box">
										<label>Total: </label>
									</div>
									<div className="count-box">
										{this.state.emails && this.state.emails.length
											? this.state.emails.length
											: null}
									</div>
								</div>
								: null}
						</div>
					</div>

					<div className="element-attach">
						{
							this.state.files && Object.keys(this.state.files).map((file) => {
								return (
									<Fragment>
										<div className={'mb-10'}>{`${+file + 1}. ${this.state.files[file].name}`}</div>
									</Fragment>)
							})
						}
						<input ref={e => { this.uploadFile = e }} type="file" style={{ "display": "none" }} onChange={this.fileUpload} onClick={(e) => { e.target.value = null }} multiple />
						<div className="generate-button mt-10" onClick={() => this.uploadFile.click()}>
							+ Attachements
                </div>
					</div>

					<div className="element">
						<input
							name="subject"
							className="text-input"
							placeholder="Subject"
							value={this.state.subject}
							onChange={this.onChange}
						/>
					</div>

					<div className="element">
						<textarea
							name="content"
							className="text-input-content"
							rows="8"
							cols="95"
							value={this.state.content}
							placeholder="Content"
							onChange={this.onChange}
						/>
					</div>

					<div className="element">
						<button className="generate-button" onClick={this.onCancel}>
							CANCEL
            </button>
						{(this.state.emails && this.state.emails.length !== 0 && this.state.content !== "") ?
							<button className="generate-button" onClick={this.sendMail}>
								SEND MAIL
            </button> : null}
					</div>
				</div>
			</div>
		);
	};
}

export default SendMail;
