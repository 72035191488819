import React, { Component } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Moment from 'moment';

class WeighBridge extends Component {
    constructor() {
        super();
        this.state = {
            valid_from: null,
            valid_to: null
        }
    }

    UNSAFE_componentWillMount(){
        this.props.getWeighBridgeDetails();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { valid_from, valid_to } = this.state;
        const { weighBridgeDetails } = nextProps;
        if(weighBridgeDetails && weighBridgeDetails.length > 0 && (weighBridgeDetails[0].from_date !== valid_from || weighBridgeDetails[0].to_date !== valid_to)) {
            this.setState({
                valid_from: weighBridgeDetails[0].from_date,
                valid_to: weighBridgeDetails[0].to_date
            })
        }
    }

    onSave = () => {
        const { valid_from, valid_to } = this.state;
        const data = {
            valid_from,
            valid_to,
            weighbridge_num: 100
        }
        this.props.saveWeighBridge(data);
    }

    render() {
        return (
            <div>
                <DateRangePicker
                    startDate={this.state.valid_from ? Moment(this.state.valid_from) : null}
                    startDateId="startDate"
                    endDate={this.state.valid_to ? Moment(this.state.valid_to) : null}
                    endDateId="endDate"
                    onDatesChange={({ startDate, endDate }) => {
                    let { valid_from, valid_to } = this.state;
                    valid_from = startDate ? startDate.valueOf() : valid_from;
                    valid_to = endDate ? endDate.valueOf() : valid_to;
                    this.setState({ valid_from, valid_to });
                    }}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                    displayFormat={'DD MMM YYYY'}
                    noBorder={true}
                    minimumNights={0}
                    // isOutsideRange={() => false}
                />
                <button className = {"add-button"} onClick={this.onSave}>Save</button> 
            </div>
        )
    }
}

export default WeighBridge;