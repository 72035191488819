import React, { Component } from 'react';
import SearchText from "../../../../common/components/search-text/search-text";
import Button from "../../../button-classic/button-classic";
import AddMove from "./add-move/add-move";
import AddAction from "./add-action/add-action";
import AddChecklist from "./add-checklist/add-checklist";
import { runInThisContext } from 'vm';
import AddWarehouse from './add-warehouse/add-warehouse';
import AddVendor from './add-vendor/add-vendor';

class PlantsDepotsIndentsConfigures extends Component {
    constructor(props){
        super(props);
        this.state = {
            move : "",
            offset : 0,
            isupdated:true,
            navigationOptions: [
                // {
                //   heading: "Moves",
                //   id: "1"
                // },
                // {
                //   heading: "Actions",
                //   id: "2"
                // },
                // {
                //   heading: "Checklists",
                //   id: "3"
                // },
                {
                    heading: "Warehouse",
                    id: "4"
                },
                {
                    heading: "Vendors",
                    id: "5"
                },
            ],
            selectedOption: "4",
        }
    }
    setOption = (option) => {
        this.setState({
            selectedOption: option
        });
    };
    componentDidMount () {
        if(this.state.isupdated==true){
            // this.getMoves(null);
            // this.getActions(null);
            // this.getChecklists(null);
            this.getWarehouses(null);
            this.getVendors(null);
            this.setState({ isupdated: false });
        }
    }
    async updatedata(){
        // if(this.state.selectedOption=="1"){
        //     this.getMoves(null);
        // }
        // if(this.state.selectedOption=="2"){
        //     this.getActions(null);
        // }
        // if(this.state.selectedOption=="3"){
        //     this.getChecklists(null);
        // }
        if(this.state.selectedOption=="4"){
            this.getWarehouses(null);
        }
        if(this.state.selectedOption=="5"){
            this.getVendors(null);
        }
    }
    async componentDidUpdate() {
        let {isupdated} = this.state;
        // if(isupdated==true){
        //     await this.updatedata();
        //     this.setState({ isupdated: false });
        // }
    }
    // getMoves = (text) => {
    //     let data = {
    //         companyId : this.props.company_id,
    //     }
    //     this.props.getMoves(data);
    // };
    // getActions = (text) => {
    //     let data = {
    //         companyId : this.props.company_id,
    //     }
    //     this.props.getActions(data);
    // };
    // getChecklists = (text) => {
    //     let data = {
    //         companyId : this.props.company_id,
    //     }
    //     this.props.getChecklists(data);
    // };
    getWarehouses = (text) => {
        let data = {
            companyId : this.props.company_id,
            branchId : this.props.branch_id,
        }
        this.props.getWarehouses(data);
    };
    getVendors = (text) => {
        let data = {
            companyId : this.props.company_id,
            branchId : this.props.branch_id,
        }
        this.props.getVendors(data);
    };
    submitSearch = () => {
        if(this.state.move != null || this.state.move.length > 0)
            this.getMoves(this.state.move);
    };
    clearFilterOnCancel = () => {
        this.setState({ move: "" }, () => {
            this.getMoves(null);
        });
    };
	changeHandler = (val,id) =>{
		this.setState({
			[id] : val
		})
    }
    // toggleAdd = () => {
    //     this.setState({
    //         showAddComponent : !this.state.showAddComponent,isupdated: true
    //     })
    // }
    // toggleAdd2 = () => {
    //     this.setState({
    //         showAddComponent2 : !this.state.showAddComponent2,isupdated: true
    //     })
    // }
    // toggleAdd3 = () => {
    //     this.setState({
    //         showAddComponent3 : !this.state.showAddComponent3,isupdated: true
    //     })
    // }
    toggleAdd4 = () => {
        this.setState({
            showAddComponent4 : !this.state.showAddComponent4,isupdated: true
        })
    }
    toggleAdd5 = () => {
        this.setState({
            showAddComponent5 : !this.state.showAddComponent5,isupdated: true
        })
    }
    render() {
        return (
            <div className = "plants-depots-main-wrap">
                <div className="home-navigation-holder home-navigation-holder-sub">
                {this.state.navigationOptions.map((option, index) => {
                    if(option.id < 3 && option.id > 5) return ;
                    return (
                    <div
                        key={index}
                        className={
                        this.state.selectedOption === option.id
                            ? "selected-option options"
                            : "options"
                        }
                        onClick={() => this.setOption(option.id)}
                    >
                        {option.heading}
                    </div>
                    );
                })}
                </div>
                {this.state.selectedOption == 1 && (
                    <div className = "moves">
                        <div className = "search-holder">
                            <input class="button-classic" type="button" value="+ New Move" onClick = {this.toggleAdd} />
                        </div>
                        <div className = "">
                            <h1>Moves</h1>
                        </div>
                        <div className = "heading">
                            <div className ="depot-users-list depot-users-list-first" >
                                <div className="halflist">
                                    <div className = "head usn first">Id</div>
                                    <div className = "head usn second">Name</div>
                                </div> 
                            </div>
                            <div className ="depot-users-list depot-users-list-second" >
                                <div className="halflist">
                                    <div className = "head usn first">Id</div>
                                    <div className = "head usn second">Name</div>
                                </div> 
                            </div>
                        </div>
                        { this.props.indentingMoves && this.props.indentingMoves.length == 0 &&
							  <div className="depot-users-list no-records"> That Is All, Nothing To Display!
                            </div>
                        }
                        
                        {
                            this.props.indentingMoves && this.props.indentingMoves.map((user,index) => {
                                if(index%2==0){
                                    return (
                                        <div className ="depot-users-list depot-users-list-first" >
                                            <div className="halflist">
                                                <div className = "head usn first">{user.move_id}</div>
                                                <div className = "head usn second">{user.name}</div>
                                            </div> 
                                        </div>                            
                                    )
                                }else{
                                    return (
                                        <div className ="depot-users-list depot-users-list-second" >
                                            <div className="halflist">
                                                <div className = "head usn first">{user.move_id}</div>
                                                <div className = "head usn second">{user.name}</div>
                                            </div> 
                                        </div>                            
                                    )
                                }
                            })
                        }
                        {
                            this.state.showAddComponent && 
                            <AddMove  
                                toggleAdd = {this.toggleAdd}
                                company_id = {this.props.company_id}
                                addMove = {this.props.addMove}
                            />
                        }
                    </div>
                )}
                {this.state.selectedOption == 2 && (
                    <div className = "actions">
                        <div className = "search-holder">
                            <input class="button-classic" type="button" value="+ New Action" onClick = {this.toggleAdd2} />
                        </div>
                        <div className = "">
                            <h1>Actions</h1>
                        </div>
                        <div className = "heading">
                            <div className ="depot-users-list depot-users-list-first" >
                                <div className="halflist">
                                    <div className = "head usn first">Id</div>
                                    <div className = "head usn second">Name</div>
                                </div> 
                            </div>
                            <div className ="depot-users-list depot-users-list-second" >
                                <div className="halflist">
                                    <div className = "head usn first">Id</div>
                                    <div className = "head usn second">Name</div>
                                </div> 
                            </div>
                        </div>
                        { this.props.indentingActions && this.props.indentingActions.length == 0 &&
							  <div className="depot-users-list no-records"> That Is All, Nothing To Display!
                            </div>
                        }
                        {
                        this.props.indentingActions && this.props.indentingActions.map((user,index) => {
                                if(index%2==0){
                                    return (
                                        <div className ="depot-users-list depot-users-list-first" >
                                            <div className="halflist">
                                                <div className = "head usn first">{user.action_id}</div>
                                                <div className = "head usn second">{user.name}</div>
                                            </div> 
                                        </div>                            
                                    )
                                }else{
                                    return (
                                        <div className ="depot-users-list depot-users-list-second" >
                                            <div className="halflist">
                                                <div className = "head usn first">{user.action_id}</div>
                                                <div className = "head usn second">{user.name}</div>
                                            </div> 
                                        </div>                            
                                    )
                                }
                            })
                        }
                        {
                            this.state.showAddComponent2 && 
                            <AddAction  
                                toggleAdd2 = {this.toggleAdd2}
                                company_id = {this.props.company_id}
                                addAction = {this.props.addAction}
                            />
                        }
                    </div>
                )}
                {this.state.selectedOption == 3 && (
                    <div className = "checklists">
                        <div className = "search-holder">
                            <input class="button-classic" type="button" value="+ New Checklist" onClick = {this.toggleAdd3} />
                        </div>
                        <div className = "">
                            <h1>Checklists</h1>
                        </div>
                        <div className = "heading">
                            <div className ="depot-users-list depot-users-list-first" >
                                <div className="halflist">
                                    <div className = "head usn first">Id</div>
                                    <div className = "head usn second">Name</div>
                                </div> 
                            </div>
                            <div className ="depot-users-list depot-users-list-second" >
                                <div className="halflist">
                                    <div className = "head usn first">Id</div>
                                    <div className = "head usn second">Name</div>
                                </div> 
                            </div>
                        </div>
                        { this.props.indentingChecklists && this.props.indentingChecklists.length == 0 &&
							  <div className="depot-users-list no-records"> That Is All, Nothing To Display!
                            </div>
                        }
                        {
                        this.props.indentingChecklists && this.props.indentingChecklists.map((user,index) => {
                            if(index%2==0){
                                return (
                                    <div className ="depot-users-list depot-users-list-first" >
                                        <div className="halflist">
                                            <div className = "head usn first">{user.checklist_id}</div>
                                            <div className = "head usn second">{user.name}</div>
                                        </div> 
                                    </div>                            
                                )
                            }else{
                                return (
                                    <div className ="depot-users-list depot-users-list-second" >
                                        <div className="halflist">
                                            <div className = "head usn first">{user.checklist_id}</div>
                                            <div className = "head usn second">{user.name}</div>
                                        </div> 
                                    </div>                            
                                )
                            }
                            })
                        }
                        {
                            this.state.showAddComponent3 && 
                            <AddChecklist  
                                toggleAdd3 = {this.toggleAdd3}
                                company_id = {this.props.company_id}
                                addChecklist = {this.props.addChecklist}
                            />
                        }
                    </div>
                )}
                {this.state.selectedOption == 4 && (
                    <div className = "checklists">
                        <div className = "search-holder">
                            <input class="button-classic" type="button" value="+ New warehouse" onClick = {this.toggleAdd4} />
                        </div>
                        <div className = "">
                            <h1>Warehouses</h1>
                        </div>
                        <div className = "heading">
                            <div className = "head usn">Id</div>
                            <div className = "head usn">Name</div>
                            <div className = "head usn">No. of Bays</div>
                        </div>
                        { this.props.indentingWarehouses && this.props.indentingWarehouses.length == 0 &&
							  <div className="depot-users-list no-records"> That Is All, Nothing To Display!
                            </div>
                        }
                        {
                        this.props.indentingWarehouses && this.props.indentingWarehouses.map((user) => {
                                return(
                                    <div className = "depot-users-list">
                                        <div className = "head usn">{user.warehouse_id}</div>
                                        <div className = "head usn">{user.name}</div>
                                        <div className = "head usn">{user.no_of_bays}</div>
                                    </div>
                                )
                            })
                        }
                        {
                            this.state.showAddComponent4 && 
                            <AddWarehouse  
                                toggleAdd4 = {this.toggleAdd4}
                                company_id = {this.props.company_id}
                                branch_id = {this.props.branch_id}
                                addWarehouse = {this.props.addWarehouse}
                            />
                        }
                    </div>
                )}
                {this.state.selectedOption == 5 && (
                    <div className = "vendors">
                        <div className = "search-holder">
                            <input class="button-classic" type="button" value="+ New vendor" onClick = {this.toggleAdd5} />
                        </div>
                        <div className = "">
                            <h1>Vendors</h1>
                        </div>
                        <div className = "heading">
                            <div className = "head usn">Id</div>
                            <div className = "head usn">Name</div>
                            <div className = "head usn">Contact</div>
                            <div className = "head usn">No. of People</div>
                        </div>
                        { this.props.indentingVendors && this.props.indentingVendors.length == 0 &&
							  <div className="depot-users-list no-records"> That Is All, Nothing To Display!
                            </div>
                        }
                        {
                        this.props.indentingVendors && this.props.indentingVendors.map((user) => {
                                return(
                                    <div className = "depot-users-list">
                                        <div className = "head usn">{user.vendor_id}</div>
                                        <div className = "head usn">{user.name}</div>
                                        <div className = "head usn">{user.contact}</div>
                                        <div className = "head usn">{user.no_of_people}</div>
                                    </div>
                                )
                            })
                        }
                        {
                            this.state.showAddComponent5 && 
                            <AddVendor  
                                toggleAdd5  = {this.toggleAdd5}
                                company_id  = {this.props.company_id}
                                branch_id   = {this.props.branch_id}
                                addVendor   = {this.props.addVendor}
                            />
                        }
                    </div>
                )}
            </div>
        );
    }
}

export default PlantsDepotsIndentsConfigures;