import React, { Component, } from 'react';
import InputText from "../../../../common/components/input-text/input-text";
import Cx from "classnames";
import { getEmail, getUserId } from '../../../../../utils/common';

class AddWBConversion extends Component {
    constructor(props){
        super(props);
        this.state = {
            Site: this.props.data ? this.props.data.Site : '',
            Description: this.props.data ? this.props.data.Description : '',
            Plant: this.props.data ? this.props.data.Plant : '',
            Vendor: this.props.data ? this.props.data.Vendor : ''
        }
    }

    onChangeHandler = (value, id) =>{
        this.setState({
            [id] : ['Plant', 'Description'].indexOf(id) > -1 ? value.toUpperCase() : value
        })
    }

    onSave = () => {
        this.props.onAddWBConversion(this.state);
        this.props.toggleAdd();
    }

    onUpdate = () => {
        let details = this.state;
        const {previousWBConversionData} = this.props
        if(this.props.data.id)
            details.id = this.props.data.id;
        if(details){
            details.previous_data = previousWBConversionData
            if(details.previous_data){
                details.previous_data.update_flag = true;
                details.previous_data.master_type = 16;
                details.previous_data.user_email = getEmail();
                details.previous_data.user_id = getUserId()
            }
        }
        this.props.updateWBConversion(details);
        this.props.toggleAdd();
    }

    render() {

        const { Site, Description, Vendor, Plant } = this.state;
        const { data } = this.props;

        return (
            <div className = "add-user-wrap add-bay">
                <div onClick = {this.props.toggleAdd} className = "overlay"></div>
                <div id = "modal" className = "modal-popup">
                    <div className = "pop-heading">
                        {`${data ? 'Edit' : 'Add' } Weigh Bridge`}
                    </div>
                    <div className = "content add-edit-user">
                        <form className = "form-section" autoComplete = "nope">
                            <InputText placeholder = "Site" id = {"Site"} label = "Site" value = {Site} changeHandler = {this.onChangeHandler}/>
                            <InputText placeholder = "Plant" id = {"Plant"} label = "Plant" value = {Plant} changeHandler = {this.onChangeHandler} />
                            <InputText placeholder = "Description" id = {"Description"} label = "Description" value = {Description} changeHandler = {this.onChangeHandler} />
                            <InputText placeholder = "Vendor" id = {"Vendor"} label = "Vendor" value = {Vendor} changeHandler = {this.onChangeHandler} />
                        </form>

                        <div className = "submit-section">
                            <button className = "add-button discard" onClick={this.props.toggleAdd} >Cancel</button>
                            {!data  ? <button className = {Cx("add-button", {
                                'disable-btn' : !(Site && Plant && Description && Vendor)
                            })} onClick={this.onSave}>Save</button> :
                            <button className = {Cx("add-button", {
                                'disable-btn' : !(Site && Plant && Description && Vendor)
                            })} onClick={this.onUpdate}>Update</button>
                    
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddWBConversion;