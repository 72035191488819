import React, { Component } from "react";
import Search from "../../../../common/components/search-text/search-text";
import Button from "../../../button-classic/button-classic";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
// import ACTIONS from "../../common/action_constant";
// import { toPascalCase } from "../../../utils/validate";
// import { DateRangePicker } from "react-dates";
import Delete from "../../../../../assets/images/delete.svg";
import Edit from "../../../../../assets/images/edit.svg";
// import {
//   AWL_COMPANY_IDS,
//   convertEpochToNormalDate,
//   downloadFile,
//   getEpochFromDate,
//   getEpochToDate,
//   getOneMonthBeforeDate,
// } from "../../../utils/common";
import { SingleDatePicker } from "react-dates";
import Moment from "moment";
import "../../../material-mrp/material-mrp.less";
// import ACTION from "../../common/action_constant";
import LoaderComponent from "../../../../common/components/loader/loader";
import { withRouter } from "react-router-dom";
import axios from "axios";
import actions from "../../../../common/action_constant";

var COMPANY_ID = "999";

class AdditionalCost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
      offset: 0,
      limit: 10,
      fromMDate: null,
      toMDate: null,
      focusedInput: null,
      additionalCostChargesData: [],
    };
  }

  componentDidMount = () => {
    const { searchString } = this.state;
    this.getAdditionalCostChargesGridData(searchString);
  };

  viewStateData = (stateData) => {
    this.setState({ stateData, isShowPopup: true });
  };

  getAdditionalCostChargesGridData = (searchString) => {
    const { dispatch } = this.props;
    const { limit, offset } = this.state;
    const queryParams = {
      search: searchString,
      limit: limit,
      offset: offset,
    };
    const onSuccess = (data = []) => {
      this.setState({ additionalCostChargesData: data });
    };
    dispatch({
      type: actions.HOME.GET_ADDITIONAL_COST_CHARGE_DETAILS,
      queryParams,
      onSuccess,
    });
  };

  deleteAdditionalCostChargeDetails = (item) => {
    const { dispatch } = this.props;
    dispatch({
      type: actions.HOME.DELETE_ADDITIONAL_COST_CHARGE_DETAILS,
      queryString: `?region=${item.source_location}`,
      onSuccess: () => {
        this.getAdditionalCostChargesGridData();
      },
    });
  };

  clearFilterOnCancel = () => {
    const { searchString } = this.state;
    this.setState({ searchString: "" }, () => {
      const search = "";
      this.getAdditionalCostChargesGridData(search);
    });
  };

  onSearch = () => {
    const { searchString } = this.state;
    this.getAdditionalCostChargesGridData(searchString);
  };

  onSearchChangeHandler = (event) => {
    this.setState({ searchString: event });
  };

  previous = () => {
    const { fromMDate, toMDate, searchString } = this.state;
    if (this.state.offset > 0) {
      this.setState(
        {
          offset: this.state.offset - 10,
        },
        () => {
          const epochToDate = getEpochToDate(toMDate);
          const epochFromDate = getEpochFromDate(fromMDate);
          this.getAdditionalCostChargesGridData(
            epochFromDate,
            epochToDate,
            searchString,
          );
        },
      );
    }
  };
  next = () => {
    const { fromMDate, toMDate, searchString } = this.state;
    this.setState(
      {
        offset: this.state.offset + 10,
      },
      () => {
        const epochToDate = getEpochToDate(toMDate);
        const epochFromDate = getEpochFromDate(fromMDate);
        this.getAdditionalCostChargesGridData(
          epochFromDate,
          epochToDate,
          searchString,
        );
      },
    );
  };

  render() {
    const {
      data,
      isShowPopup,
      stateData,
      additionalCostChargesData,
      fromMDate,
      toMDate,
      searchString,
    } = this.state;
    const { loader } = this.props;
    return (
      <div className={"location-network"}>
        <ToastContainer />
        {!!loader.loaderT1 && <LoaderComponent />}
        <div className="material-mrp-main">
          <div style={{ display: "flex", paddingBottom: "15px" }}>
            <Search
              id="search"
              value={searchString}
              placeholder="Search..."
              changeHandler={this.onSearchChangeHandler}
              click={this.onSearch}
              canClear={searchString !== ""}
              handleClearFilter={this.clearFilterOnCancel}
              className="material-search"
            />
            <Button
              value={"+ Additional Cost"}
              // click={() => this.downloadTemp(true)}
              click={this.downloadMRPTemplate}
              className="mt-20"
            />
          </div>
          <div style={{maxWidth:'fit-content'}}>
            <div className={"material-details-section"}>
              <div className={"list-section header"}>
                <div className={"tabel-header gap1221"}>
                  Source Location Region
                </div>
                <div className={"tabel-header gap1221"}>percentage</div>
                <div className={"tabel-header"}>Actions</div>
              </div>

              {(additionalCostChargesData || []).map((mc, index) => {
                return (
                  <div
                    className={"list-section content"}
                    key={`route_${index}`}
                  >
                    <div className={"tabel-header gap1221"}>
                      {mc.source_location}
                    </div>
                    <div className={"tabel-header gap1221"}>{mc.percentage}%</div>
                    <div className={"tabel-header"}>
                      <div
                        className={"action-icon"}
                        style={{ backgroundImage: `url(${Edit})` }}
                        onClick={() => this.editMC(mc)}
                      />
                      <div
                        className={"action-icon"}
                        style={{ backgroundImage: `url(${Delete})` }}
                        onClick={() =>
                          this.deleteAdditionalCostChargeDetails(mc)
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="submit-section-pagination">
            <div className="next">
              <Button
                value="Next"
                // disabled={
                //   Object.keys(additionalCostChargesData || {}).length <= 0 
                //   || (cityMasterData.tat_routes_count <= this.state.offset + this.state.limit)
                // }
                click={this.next}
              />
            </div>
            <div className="pre">
              <Button
                value="Previous"
                disabled={this.state.offset === 0}
                click={this.previous}
              />
            </div>
          </div>
        </div>
        {isShowPopup && (
          <div className="material-modal">
            <div className="route_code_detail">
              <div className="overlay"></div>
              <div id="modal" className="modal-popup modal-pad">
                <div className="pop-heading">
                  {"State Wise Material Details"}
                </div>
                <div className="route-stages-section point-of-contact">
                  <table className="routestages-table">
                    <thead>
                      <th>State</th>
                      <th>MRP(Rs)</th>
                    </thead>
                    <tbody>
                      {stateData &&
                        stateData.map((val, index) => {
                          return (
                            <tr key={index}>
                              <td>{val && val.name}</td>
                              <td>{val && val.mrp}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className={"mt-10 mb-10 tCenter"}>
                  <Button
                    value={"Close"}
                    click={() =>
                      this.setState({ isShowPopup: false, stateData: "" })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loader: state.loader,
  };
};

export default connect(mapStateToProps)(withRouter(AdditionalCost));
