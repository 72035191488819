import { put, call } from "redux-saga/effects";
import ACTIONS from "../../common/action_constant";
import API from "../../common/api_config";
import {
	getData,
	putData,
	postData,
	deleteData
} from "../../../utils/ajax";
import history from "../../history";

function* getBasicContractSetting(action) {
	let url = action.data.company_id + "&branchId=" + action.data.branch_id;
	if(action.data.rfq_type) {
		url += `&rfqType=${action.data.rfq_type}`
	}
	if(action.data.contract_tenure_type) {
		url += `&contractTenureType=${action.data.contract_tenure_type}`
	}
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getBasicContractSetting + url);
		yield put({
			type: ACTIONS.CONTRACTS.STORE_BASIC_CONTRACT_SETTING,
			data: data.data
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
	}
}
function* getOtherContractSetting(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getOtherContractSetting);
		yield put({
			type: ACTIONS.CONTRACTS.STORE_OTHER_CONTRACT_SETTING,
			data: data.data
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
	}
}
function* updateContract(action) {
	let data = {
		company_id: action.data.company_id,
		branch_id: action.data.branch_id,
		rfq_type: action.data.rfq_type,
		contract_tenure_type: action.data.contract_tenure_type || undefined
	}
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		yield call(putData, API.createUpdateBasicContract, JSON.stringify(action.data));
		yield put({
			type: ACTIONS.CONTRACTS.GET_BASIC_CONTRACT_SETTING,
			data: data
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Updated Successfully" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})

	}
}

function* createNewContract(action) {
	let data = {
		company_id: action.data.company_id,
		branch_id: action.data.branch_id,
		rfq_type: action.data.rfq_type,
		contract_tenure_type: action.data.contract_tenure_type || undefined
	}
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		yield call(postData, API.createUpdateBasicContract, action.data);
		yield put({
			type: ACTIONS.CONTRACTS.GET_BASIC_CONTRACT_SETTING,
			data: data
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "New Contract added Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})

	}
}


function* getCollapsibleSection(action) {
	let url = API.getCollapsibleSection + action.data.branchId + "&alertType=" + action.data.alertType;
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, url);
		let actionConst;

    if(data.data.type == 1){
        actionConst = ACTIONS.CONTRACTS.STORE_COLLAPSIBLE_SECTION_1;
    }
    else if(data.data.type == 2){
        actionConst = ACTIONS.CONTRACTS.STORE_COLLAPSIBLE_SECTION_2;
    }
    else if(data.data.type == 3){
        actionConst = ACTIONS.CONTRACTS.STORE_COLLAPSIBLE_SECTION_3;
    }
    else if(data.data.type == 4){
        actionConst = ACTIONS.CONTRACTS.STORE_COLLAPSIBLE_SECTION_4;
    }
    else if(data.data.type == 5){
        actionConst = ACTIONS.CONTRACTS.STORE_COLLAPSIBLE_SECTION_5;
    }
    else if(data.data.type == 6){
        actionConst = ACTIONS.CONTRACTS.STORE_COLLAPSIBLE_SECTION_6;
    }
    if(actionConst) {
      yield put({
        type: actionConst,
        data: data.data.list
      });
    }
    yield put({
      type: ACTIONS.LOADER.HIDE_LOADER
    });
  } catch (e) {
  }
}


function* addCollapsibleSection(action) {
	let newData = { "branchId": action.data.branch_id, "alertType": action.data.alert_type };
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.addCollapsibleSection, action.data);
		yield put({
			type: ACTIONS.CONTRACTS.GET_COLLAPSIBLE_SECTION_DATA,
			data: newData
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Added Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})

	}
}
function* deleteCollapsibleSection(action) {
	const url = API.deleteCollasibleSection + action.data.branchId + "&id=" + action.data.id;
	let newData = { "branchId": action.data.branchId, "alertType": action.data.alertType };
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(deleteData, url);
		yield put({
			type: ACTIONS.CONTRACTS.GET_COLLAPSIBLE_SECTION_DATA,
			data: newData
		})
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Deleted Successfully!" }
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}
function* getBranchesList(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getBranchList + action.data);
		yield put({
			type: ACTIONS.CONTRACTS.STORE_BRANCH_LIST,
			data: data.data
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
	}
}
function* applySettingsToBranches(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		yield call(putData, API.applySettingsToBranches, JSON.stringify(action.data));
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Updated Successfully" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})

	}
}

function* getRFQList(action) {
	try {
		const url = "branchId=" + action.data.branch_id + "search=" + action.data.search;
		const data = yield call(getData, API.getRFQ + url);
		yield put({
			type: ACTIONS.CONTRACTS.STORE_RFQ_LIST,
			data: data.data
		});
	} catch (e) {
	}
}

function* getLOIApproverEmail(action) {
	const url = action.data.company_id + "&companyType=" + action.data.company_type;
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getLOIApproverEmail + url);
		yield put({
			type: ACTIONS.CONTRACTS.GET_EMAIL_LIST_DATA,
			data: data.data
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
	}
}

function* getApproverTypes(action) {
  try {
    const data = yield call(getData, API.getApproverTypes);
    yield put({
      type: ACTIONS.CONTRACTS.SAVE_APPROVER_TYPES,
      data: data.data && (data.data || []).map(approve => {
        return {
          label: approve.name,
          value: approve.approval_type
        }
      }) || []
    });
  } catch(e) {
    console.log(e);
  }
}

function* getChargesForApproval(action) {
	try {
	  const data = yield call(getData, API.getAllChargesForApproval + action.data, true);
	  if(action.onSuccess){
		action.onSuccess(data)
	  }
	} catch(e) {
	  console.log(e);
	}
  }

 function* addCurrency(action) {
	   let data = {
	        company_id: action.data.company_id,
	        branch_id: action.data.branch_id
	    }
	   try {
	        yield put({
	            type: ACTIONS.LOADER.SHOW_LOADER
	        });
	         yield call(postData, API.addCurrencyDetails, action.data);
	        yield put({
	            type: ACTIONS.CONTRACTS.GET_CURRENCY_DETAILS,
	            data: data
	        });
	        yield put({
	            type: ACTIONS.LOADER.HIDE_LOADER
	        });
	        yield put({
	            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
	            data: { type: 1, msg: "Added Successfully!" }
	        })
	    } catch (e) {
	        yield put({
	            type: ACTIONS.LOADER.HIDE_LOADER
	        });
	        yield put({
	            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
	            data: { type: 2, msg: e.responseJSON.message }
	        })
	
	    }
	}
	function* getCurrencyDetails(action) {
	    const url = action.data.company_id + "&branchId=" + action.data.branch_id;
	    try {
	        yield put({
	            type: ACTIONS.LOADER.SHOW_LOADER
	        });
	        const data = yield call(getData, API.getCurrencyDetails + url);
	
	        yield put({
	            type: ACTIONS.CONTRACTS.STORE_CURRENCY_DETAILS,
	            data: data.data
	        });
	        yield put({
	            type: ACTIONS.LOADER.HIDE_LOADER
	        });
	    } catch (e) {
	    }
	}

function* addProductCategory(action) {
   try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
         yield call(postData, API.addProductCategoryDetails, action.data);
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
		if(action.onSuccess) {
			action.onSuccess();
		}
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 1, msg: "Added Successfully!" }
        })
    } catch (e) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: e.responseJSON.message }
        })

    }
}

function* addPackagingType(action) {
   try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		yield call(postData, API.addPackagingTypeDetails, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if(action.onSuccess) {
			action.onSuccess();
		}
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 1, msg: "Added Successfully!" }
        })
    } catch (e) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: e.responseJSON.message }
        })

    }
}
	
function* addPricingBasis(action) {
   try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
         yield call(postData, API.addPricingBasisDetails, action.data);
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
		if(action.onSuccess) {
			action.onSuccess();
		}
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 1, msg: "Added Successfully!" }
        })
    } catch (e) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: e.responseJSON.message }
        })

    }
}

function* addVehicleType(action) {
   try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        yield call(postData, API.addVehicleTypeDetails, action.data);
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
		if(action.onSuccess) {
			action.onSuccess();
		}
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 1, msg: "Added Successfully!" }
        })
    } catch (e) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: e.responseJSON.message }
        })
    }
}

function* addVehicleBodyType(action) {
   try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        yield call(postData, API.addVehicleBodyTypeDetails, action.data);
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
		if(action.onSuccess) {
			action.onSuccess();
		}
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 1, msg: "Added Successfully!" }
        })
    } catch (e) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: e.responseJSON.message }
        })

    }
}

function* addUom(action) {
   try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
         yield call(postData, API.addUomDetails, action.data);
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
		if(action.onSuccess) {
			action.onSuccess();
		}
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 1, msg: "Added Successfully!" }
        })
    } catch (e) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: e.responseJSON.message }
        })

    }
}

function* addLoadability(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		yield call(postData, API.addLoadabilityDetails, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if(action.onSuccess) {
			action.onSuccess();
		}
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Added Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* getMasterContractConfig(action) {
//	const url = action.data.company_id + "&branchId=" + action.data.branch_id;
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const params = action.data.company_id;
		//+ "&branchId=" + action.data.branch_id;
		// const isCompanyLevel = (action.data.company_id == action.data.branch_id);
		const url = action.isSuperAdmin ? API.getMasterContractConfig : (API.getCompanyMasterContractData + params);
		const data = yield call(getData, url);
		if(action.onSuccess){
			action.onSuccess(data.data)
		}
		yield put({
			type: ACTIONS.CONTRACTS.STORE_MASTER_CONTRACT_DATA,
			data: data.data
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
	}
}

function* getPlantWiseContractData(action) {
	try {
		const params = action.data.company_id + "&branchId=" + action.data.branch_id;
		// const isCompanyLevel = (action.data.company_id == action.data.branch_id);
		const url = action.isSuperAdmin ? (API.getCompanyMasterContractData + params) : (API.getPlantWiseContractData + params + "&rfqType=" + action.data.rfqType);
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, url);
		yield put({
			type: ACTIONS.CONTRACTS.STORE_PLANT_WISE_CONTRACT_DATA,
			data: data.data
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
	}
}

function* getBasicMSPContractSetting(action) {
	const url = action.data.company_id + "&branchId=" + action.data.branch_id;
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getMSPContractBasic + url);
		yield put({
			type: ACTIONS.CONTRACTS.STORE_BASIC_CONTRACT_SETTING,
			data: data.data
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
	}
}

function* updateMSPContract(action) {
	let data = {
		company_id: action.data.company_id,
		branch_id: action.data.branch_id
	}
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		yield call(putData, API.upadteMSPContractBasic, JSON.stringify(action.data));
		yield put({
			type: ACTIONS.CONTRACTS.GET_BASIC_MSP_CONTRACT_SETTING,
			data: data
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Updated Successfully" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})

	}
}

function* createNewMSPContract(action) {
	let data = {
		company_id: action.data.company_id,
		branch_id: action.data.branch_id
	}
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		yield call(postData, API.addMSPContractBasic, action.data);
		yield put({
			type: ACTIONS.CONTRACTS.GET_BASIC_MSP_CONTRACT_SETTING,
			data: data
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "New Contract added Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})

	}
}

function* applyMSPSettingsToBranches(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		yield call(putData, API.applyMSPSettingsToBranches, JSON.stringify(action.data));
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Updated Successfully" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})

	}
}

//Auto Decrement Sagas//

function* getAutoDecrementData(action) {
	// const url = action.data.company_id + "&branchId=" + action.data.branch_id;
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getAutoDecrementDataUrl + action.data);
		yield put({
			type: ACTIONS.CONTRACTS.STORE_AUTO_DECREMENT_DATA,
			data: data.data
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
	}
}

function* addAutoDecrementData(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		yield call(postData, API.addAutoDecrementDataUrl, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if(action.onSuccess) {
			action.onSuccess();
		}
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Added Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

export {
    getBasicContractSetting,
    getOtherContractSetting,
    createNewContract,
    updateContract,
    getCollapsibleSection,
    addCollapsibleSection,
    getBranchesList,
    applySettingsToBranches,
    deleteCollapsibleSection,
    getRFQList,
    getLOIApproverEmail,
	getApproverTypes,
	addCurrency,
    getCurrencyDetails,
	addProductCategory,
	addPackagingType,
	addPricingBasis,
	addVehicleType,
	addVehicleBodyType,
	addUom,
	getMasterContractConfig,
	getPlantWiseContractData,
	addLoadability,
	getBasicMSPContractSetting,
	updateMSPContract,
	createNewMSPContract,
	applyMSPSettingsToBranches,
	getAutoDecrementData,
	addAutoDecrementData,
	getChargesForApproval
  };
  
