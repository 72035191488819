import ACTION from "../common/action_constant";

const masterReducer = (state = {}, action) => {
	let tempState = Object.assign({}, state);
	if(action.type == ACTION.MASTER.SAP_MODULE.STORE_ROUTE_EXCEL){
		const routeExcelUrl = {
			routeImportUrl : action.data.url
		};
		tempState = Object.assign({}, state, routeExcelUrl);
		return tempState;
	} else if(action.type == ACTION.MASTER.LOCATION_NETWORK.STORE_NETWORK_CHAIN) {
		tempState.netWorkDetails = action.data;
		return tempState;
	} else if(action.type == ACTION.MASTER.LOCATION_NETWORK.STORE_DEPOT_CONSIGNEE) {
		tempState.depotConsigneeDetails = action.data;
		return tempState;
	}
	else if(action.type == ACTION.MASTER.INVENTORY_ITEM.STORE_COMMON_CONSTANTS){
		tempState.commonConstants = action.data;
		return tempState;
	} else if(action.type == ACTION.MASTER.INVENTORY_ITEM.STORE_INVENTORY_ITEM){
		tempState.itemList = action.data;
		return tempState;
	} else if(action.type == ACTION.MASTER.INVENTORY_ITEM.STORE_INVENTORY_DOWNLOAD_LINK){
		tempState.dowlUrl = action.data;
		return tempState;
	} else if (action.type == ACTION.MASTER.ACCOUNTS.STORE_ACCOUNT_DETAILS_1) {
		tempState.type1 = action.data;
		return tempState;
	} else if (action.type == ACTION.MASTER.ACCOUNTS.STORE_ACCOUNT_DETAILS_2) {
		tempState.type2 = action.data;
		return tempState;
	} else if (action.type == ACTION.MASTER.ACCOUNTS.STORE_ACCOUNT_DETAILS_3) {
		tempState.type3 = action.data;
		return tempState;
	} else if (action.type == ACTION.MASTER.ACCOUNTS.STORE_ACCOUNT_DETAILS_4) {
		tempState.type4 = action.data;
		return tempState;
	} else if (action.type == ACTION.MASTER.ACCOUNTS.STORE_ACCOUNT_DETAILS_5) {
		tempState.type5 = action.data;
		return tempState;
	} else if (action.type == ACTION.MASTER.ACCOUNTS.STORE_ACCOUNT_DETAILS_6) {
		tempState.type6 = action.data;
		return tempState;
	} else if (action.type == ACTION.MASTER.ACCOUNTS.STORE_ACCOUNT_DETAILS_7) {
		tempState.type7 = action.data;
		return tempState;
	} else if (action.type == ACTION.MASTER.INVENTORY_ITEM.STORE_PACKAGING_DETAILS){
		tempState.packagingDetails = action.data;
		return tempState;
	} else if(action.type == ACTION.MASTER.TRANSPORTERS.STORE_COMPANY_TRANSPORTERS) {
		tempState.companyTransporters = action.data;
		return tempState;
	} else if(action.type == ACTION.MASTER.TRANSPORTERS.STORE_BRANCH_TRANSPORTERS) {
		tempState.branchTransporters = action.data;
		return tempState;
	}
	else if(action.type == ACTION.MASTER.VEHICLE_PLANNING.STORE_TRUCK_DETAILS){
		tempState.truckData = action.data;
		return tempState;
	}else if(action.type == ACTION.MASTER.VEHICLE_PLANNING.STORE_VEHICLE_SIZE_DETAILS){
		tempState.vehicleSizeData = action.data;
		return tempState;
	}else if(action.type == ACTION.MASTER.VEHICLE_PLANNING.STORE_TRANSPORTER_DETAILS){
		tempState.transportList = action.data;
		return tempState;
	}else if(action.type == ACTION.MASTER.VEHICLE_PLANNING.STORE_TRUCK_DOWNLOAD_LINK ){
		tempState.downloadUrl = action.data;
		return tempState;
	}else if(action.type == ACTION.MASTER.VEHICLE_PLANNING.STORE_TRUCK_DOWNLOAD_TEMP_LINK ){
		tempState.downloadTempUrl = action.data;
		return tempState;
	}else if(action.type == ACTION.MASTER.VEHICLE_PLANNING.STORE_TRUCK_DRIVER_DETAILS){
		tempState.driverData = action.data;
		return tempState;
	}else if(action.type == ACTION.MASTER.VEHICLE_PLANNING.STORE_SKU_DETAILS){
		tempState.skuData = action.data;
		return tempState;
	}else if(action.type == ACTION.MASTER.VEHICLE_PLANNING.STORE_SKU_DOWNLOAD_LINK ){
		tempState.downloadSkuUrl = action.data;
		return tempState;
	}else if(action.type == ACTION.MASTER.VEHICLE_PLANNING.STORE_SKU_DOWNLOAD_TEMP_LINK ){
		tempState.downloadSkuTempUrl = action.data;
		return tempState;
	}
	// else if(action.type == ACTION.MASTER.VEHICLE_PLANNING.STORE_TRUCK_DETAILS){
	// 	tempState.transportData = action.data;
	// 	return tempState;
	// }
	else if(action.type == ACTION.MASTER.VEHICLE_PLANNING.STORE_DRIVER_DETAILS){
		tempState.driverData = action.data;
		return tempState;
	}else if(action.type == ACTION.MASTER.VEHICLE_PLANNING.STORE_DRIVER_DOWNLOAD_LINK ){
		tempState.downloadDriverUrl = action.data;
		return tempState;
	}else if(action.type == ACTION.MASTER.VEHICLE_PLANNING.STORE_DRIVER_DOWNLOAD_TEMP_LINK ){
		tempState.downloadTempUrl = action.data;
		return tempState;
	}else if(action.type == ACTION.MASTER.LOCATION_NETWORK.STORE_STATE) {
		tempState.stateList = action.data;
		return tempState;
	}
	else if(action.type === ACTION.MASTER.LOCATION_NETWORK.STORE_DPL_BRANCH_LIST_DETAILS) {
        tempState.DplBranchListDetails = action.data;
        return tempState;
	}else if(action.type == ACTION.MASTER.LOCATION_NETWORK.STORE_COUNTRY) {
		tempState.countryList = action.data;
		return tempState;
	}else if(action.type == ACTION.MASTER.VEHICLE_PLANNING.GET_GPS_VENDOR_DATA_SUCCESS) {
		tempState.gpsVendorData = action.data;
		return tempState;
	}
	else if(action.type == ACTION.MASTER.MASTER_DROP_DOWN.STORE_DROP_DOWN_NAME) {
		tempState.dropDownNameForMaster = action.data;
		return tempState;
	}
	else if(action.type == ACTION.MASTER.MASTER_DROP_DOWN.STORE_INSIDE_TAB_DROPDOWN) {
		tempState.insideTabDropDownName = action.data;
		return tempState;
	}
	return state;
};

export default masterReducer;

