import React, { Component } from 'react';
// import "./add-company.less";
import Select from "react-select";
import Checkbox from "../../../components/checkbox/checkbox";
import validate from "../../../../utils/validate";
import InputText from "../../../common/components/input-text/input-text";

class EditProviderUser extends Component {
   constructor(props) {
      super(props);
      this.state = {
         error: "",
         user_name: this.props.selectedUserToEdit && this.props.selectedUserToEdit.user_name,
         user_email: this.props.selectedUserToEdit && this.props.selectedUserToEdit.user_email,
         phone: this.props.selectedUserToEdit && this.props.selectedUserToEdit.user_contact,
         user_password: this.props.selectedUserToEdit && this.props.selectedUserToEdit.user_password,
         location: this.props.selectedUserToEdit && this.props.selectedUserToEdit.location || '',
         is_active: this.props.selectedUserToEdit && this.props.selectedUserToEdit.is_active,
         selectedSeekersView: this.props.selectedUserToEdit && this.props.selectedUserToEdit.company_list && this.props.selectedUserToEdit.company_list.map((company) => { return { label: company.name, value: company.id, branches: company.branches && company.branches.length > 0 ? company.branches.map((branch) => { return { label: branch.branch_name, value: branch.branch_id } }) : [] } }),
         seekers: this.props.seekersList.map((seek) => { return { label: seek.company_name, value: seek.id } }),
         branchList: [],
         selectedRole: {
            label: this.props.selectedUserToEdit.role_name,
            value: this.props.selectedUserToEdit.role_id
         },
         selectedDepartment: {
            label: this.props.selectedUserToEdit.department_name,
            value: this.props.selectedUserToEdit.department_id
         },
         branchView: this.props.selectedUserToEdit && this.props.selectedUserToEdit.branches && this.props.selectedUserToEdit.branches.map((branch) => {
            return {
               value: branch.branch_id,
               label: branch.branch_name
            }
         }),
         roles: [],
         departments: [],
         additional_service_type: (this.props.selectedUserToEdit || {}).additional_service_type || [],
         modeOfTransport: this.props.selectedUserToEdit && this.props.selectedUserToEdit.modeOfTransport || [],
         modules_access_list: this.props.selectedUserToEdit && this.props.selectedUserToEdit.modules_access_list || []
      }
   }
   componentDidMount() {
      window.scroll(0, 0);
   }
   componentWillReceiveProps(newProps) {
      if (newProps.branchList) {
         let branches = newProps.branchList.map((branch) => { return { label: branch.branch_name, value: branch.branch_id } })
         this.setState({ branchList: branches });
      }
   }
   onChangeHandler = (value, id) => {
      this.setState({
         [id]: value
      })
   }
   removeSeeker = (ind) => {
      let tempRoles = this.state.selectedSeekersView;
      tempRoles.splice(ind, 1);
      this.setState({ selectedSeekersView: tempRoles });
   }
   handleChange = (seeker, ind) => {
      let tempSeeker = this.state.selectedSeekersView.slice();
      for (let i = 0; i < tempSeeker.length; i++) {
         if (seeker.value == tempSeeker[i].value) {
            return;
         }
      }
      tempSeeker[ind] = seeker;
      this.setState({ selectedSeekersView: tempSeeker });
   }
   handleChangeBranch = (branchList, id) => {
      let { selectedSeekersView } = this.state;
      selectedSeekersView[id]['branches'] = branchList;
      this.setState({ selectedSeekersView });
   }
   checkBoxChange = (e, id, type, user) => {
      this.setState({
         is_active: e.target.checked
      })
   }
   populateRoles = () => {
      return this.state.selectedSeekersView.map((seek, index) => {
         return (
            <div className="users-holder" key={index}>
               <Select className="select" style={{ width: '300px' }} value={seek} onChange={(seek) => this.handleChange(seek, index)} options={this.state.seekers} />
               <Select className="select" style={{ width: '300px' }} value={seek.branches} options={this.state.branchList} isMulti onFocus={() => this.props.fetchBranches(seek.value)} onChange={(branch) => this.handleChangeBranch(branch, index)} />
               <button className="add-button " onClick={() => this.removeSeeker(index)}>Delete</button>
            </div>
         )
      })

   }
   addAnotherSeeker = () => {
      let sample = this.state.selectedSeekersView;
      if (sample.length >= this.state.seekers.length) {
         return;
      }
      if (sample.length != 0 && validate.isEmpty(sample[sample.length - 1].value)) {
         return;
      }
      let obj = {
         label: "",
         value: ""
      }
      sample.push(obj);
      this.setState({
         selectedSeekersView: sample
      }, () => { this.populateRoles() });
   }
   editSeeker = () => {
      let paramObj = {
         "company_id": this.props.selectedUserToEdit.company_id,
         "company_type": this.props.selectedUserToEdit.company_type,
         "user_id": this.props.selectedUserToEdit.user_id,
         "user_name": this.state.user_name,
         "user_email": this.state.user_email,
         "user_contact": this.state.phone,
         "user_password": this.state.user_password,
         "company_list": this.state.selectedSeekersView.map((seek) => { return { id: seek.value, name: seek.label, branches: seek.branches && seek.branches.length > 0 ? seek.branches.map((branch) => { return { branch_name: branch.label, branch_id: branch.value } }) : [] } }),
         "is_active": this.state.is_active,
         "location": this.state.location,
         "modeOfTransport": this.state.modeOfTransport || [],
         "modules_access_list": this.state.modules_access_list || []
        // "department_id": this.state.selectedDepartment && this.state.selectedDepartment.value,
         //"department_name": this.state.selectedDepartment && this.state.selectedDepartment.label,
         //"role_id": this.state.selectedRole && this.state.selectedRole.value,
        // "role_name": this.state.selectedRole && this.state.selectedRole.label,
         // "branches": this.state.branchView.map((branch) => {
         //    return {
         //       branch_id: branch.value,
         //       branch_name: branch.label
         //    }
         // }),
      }
      let errorMsg = "";
      const validationError = {
         "Name": validate.isEmpty(paramObj.user_name),
         "Email": !validate.email(paramObj.user_email),
         "Phone": !validate.numeric(paramObj.user_contact),
         "Password": validate.isEmpty(paramObj.user_password),
         "Location": validate.isEmpty(paramObj.location),
        // "Department": validate.isEmpty(paramObj.department_name),
         //"Role": validate.isEmpty(paramObj.role_name)
      }
      const validationFields = [];
      Object.keys(validationError).forEach((key) => {
         if (validationError[key]) {
            validationFields.push(key);
         }
      });

      if (validationFields.length) {
         errorMsg = `Please Enter Valid ${validate.join(validationFields, (item, idx) => {
            if (validationFields.length === 1) {
               return "";
            }
            if (idx > 0 && idx < validationFields.length - 1) {
               return ", ";
            } else if (idx === validationFields.length - 1) {
               return " and ";
            }
            return "";
         })}.`;
      }
      if (errorMsg) {
         this.setState({
            error: errorMsg
         });
         return;
      }

      if (!(paramObj.company_list[paramObj.company_list.length - 1]).name) {
         this.setState({
            error: "Company list cannot be empty, make sure you have selected!"
         });
         return;
      }

      // if (!(paramObj.branches[paramObj.branches.length - 1])) {
      //    this.setState({
      //       error: "Branch cannot be empty, make sure you have selected!"
      //    });
      //    return;
      // }
      this.props.editSeeker(paramObj);
      this.props.toggleEdit();
   }

   componentWillMount() {
      let tempDepartments = [] ,tempRoles = [], roles = [], tempBranch = [];
      this.props.departments && this.props.departments.forEach(dept => {
         tempDepartments.push(
            {
               label: dept.department_name,
               value: dept.department_id,
               roles: dept.roles
            }
         )
      });
      for (let i = 0; i < tempDepartments.length; i++) {
         if (this.state.selectedDepartment.value == tempDepartments[i].value) {
            tempRoles = tempDepartments[i].roles
         }
      }
      tempRoles && tempRoles.forEach(role => {
         roles.push(
            {
               label: role.name,
               value: role.role_id
            }
         )
      });

      this.props.allPlantBranches && this.props.allPlantBranches.forEach(branch => {
         tempBranch.push(
            {
               label: branch.branch_name,
               value: branch.branch_id
            }
         )
      });

      this.setState({
         departments: tempDepartments,
         roles: roles,
         branches: tempBranch
      });
   }
   handleChangeDepartment = (dept) => {
      let tempRoles = [];

      dept.roles && dept.roles.forEach(role => {
         tempRoles.push(
            {
               label: role.name,
               value: role.role_id
            }
         )
      });
      this.setState({ selectedDepartment: dept, roles: tempRoles, selectedRole: tempRoles[0] });
   }

   handleChangeRoles = (role) => {
		this.setState({ selectedRole: role });
   }


   handleChangePlant = (branch) => { this.setState({ branchView: branch }); }


   populateBranch = () => {
		return (
			<div className="dropdown-holder" >
            <span className="users-holder">Branches</span>
				<Select isMulti={true} className="select" value={this.state.branchView} onChange={(branch) => this.handleChangePlant(branch)} options={this.state.branches} />
			</div>
		)
	}

   onSelectAll = () => {
      let { isAllSelected, modules_access_list } = this.state;
      isAllSelected = !isAllSelected;
      modules_access_list.map(val => {
          val.enable = isAllSelected;
      })
      this.setState({ isAllSelected, modules_access_list })
   }

   onSelectModule = (index) => {
      let { modules_access_list, isAllSelected } = this.state;
      modules_access_list[index].enable = !modules_access_list[index].enable;
      if(isAllSelected) {
          isAllSelected = false;
      } else {
          isAllSelected = modules_access_list.filter(val => { return val.enable }).length ===  modules_access_list.length; 
          
      }
      this.setState({ modules_access_list,  isAllSelected });
   }

   render() {
      const { props, state } = this;
      return (
         <div className="add-company-wrap add-provider-root">
            <div onClick={props.toggleEdit} className="overlay"></div>
            <div className="modal-popup">
               <div className="heading">
                  Edit user for transporters
                    </div>
               <label className="error">{this.state.error}</label>
               <form className="form-section" autoComplete="nopes">
                  <InputText placeholder="User Name" id={"user_name"} label="User Name" value={state.user_name} changeHandler={this.onChangeHandler} />
                  <InputText placeholder="User Email" id={"user_email"} label="User Email" value={state.user_email} changeHandler={this.onChangeHandler} />
                  <InputText placeholder="Phone" id={"phone"} label="Phone" value={state.phone} changeHandler={this.onChangeHandler} />
                  <InputText placeholder="Password" id={"user_password"} label="Password" value={state.user_password} changeHandler={this.onChangeHandler} />
                  <InputText placeholder="Location" id={"location"} label="Location" value={state.location} changeHandler={this.onChangeHandler} />
                  <Select 
                     isMulti
                     placeholder="Select Service Type"
                     className="select select_service_type" 
                     value={this.state.additional_service_type} 
                     onChange={(option) => this.setState({ additional_service_type: option })} 
                     getOptionLabel={(option) => option.name}
                     getOptionValue={(option) => option.company_type}
                     options={(((this.props.companyDetails || {}).additional_service_type) || []).filter(val => val.company_type != this.props.company_type)}
                  />
                  <Select 
                     isMulti
                     placeholder="Select Mode Of Transport"
                     className="select select_service_type" 
                     value={this.state.modeOfTransport} 
                     onChange={(option) => this.setState({ modeOfTransport: option })} 
                     getOptionLabel={(option) => option.name}
                     getOptionValue={(option) => option.id}
                     options={this.props.modeOfTransportList}
                  />
               </form>
               <div className="active-status">
                  <div className="label">Active status</div> <Checkbox type={1} status={this.state.is_active} id={'user'} handleChange={this.checkBoxChange} />
               </div>

               {/* <div className="flex">
							<div className="dropdown-holder"><span className="users-holder">Departments</span><Select className ="select" value={this.state.selectedDepartment} onChange={this.handleChangeDepartment} options={this.state.departments}/></div>
							<pre>        </pre>
                     {this.populateBranch()}
                     <pre>        </pre>
							<div className="dropdown-holder"><span className="users-holder">Role</span><Select className ="select" className ="select"  value={this.state.selectedRole} onChange={this.handleChangeRoles} options={this.state.roles} /></div>
							<pre>        </pre>
						</div> */}

               <div className="dropdown-holder">
                  <div>Company</div>
                  {this.populateRoles()} <div className="add-button plus" onClick={this.addAnotherSeeker}>+</div>
               </div>
               <div className="select-container action-check">
                  <span className="sub-head">Module Config.</span>
               </div>
               <div className="select-container action-check flex">
                  {this.state.modules_access_list.length > 0 && <div className = "action-holder tk11-dashboard flex" style={{width: '100%'}}>
                     <CustomCheckBox selected={this.state.isAllSelected} toggle={this.onSelectAll} />
                     <div className = "title small-title">All</div>
                  </div>}
                  {(this.state.modules_access_list || []).map((moduleList, index) => {
                     return(
                        <div className="action-holder tk11-dashboard flex" key={`module_${index}`} style={{width: '100%'}}>
                           <CustomCheckBox id={index} selected={moduleList.enable} toggle={this.onSelectModule} />
                           <div className="title small-title">{moduleList.module_name}</div>
                        </div>)
                  })
                  }
               </div>
               <div className="submit-section submit-area">
                  <div className="button-section">
                     <div className="add-button" onClick={this.editSeeker}>Save</div>
                  </div>
                  <div className="button-section" >
                     <div className="add-button" onClick={this.props.toggleEdit}>Close</div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

const CustomCheckBox = (props) => {
	return (
		<div className={props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick={() => props.toggle(props.id)}>
			{props.selected && <div className="inner-circle"></div>}
		</div>
	)
}

export default EditProviderUser;