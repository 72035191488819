import React, { Component, Fragment } from "react";
import "./index.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { isValueThere } from "../../../utils";
import cx from 'classnames'
import { PROVIDER_TYPE, SEEKER_TYPE } from "../../../utils/common";
import CheckIcon from "../../icon/CheckIcon";
import { ReactComponent as Design01 } from '../../icon/design01.svg';
import { ReactComponent as Design02 } from '../../icon/design02.svg';

const getModuleList = () => {
  const moduleList = [
    {
      label: "Contracting",
      value: 1,
    },
    {
      label: "Indenting",
      value: 2,
    },
    {
      label: "VIMS",
      value: 3,
    },
    {
      label: "Accounts",
      value: 4,
    },
  ];

  return moduleList;
};

const getCompanyList = () => {
  const companyList = [
    {
      label: "DPL",
      value: 1,
    },
    {
      label: "Beams",
      value: 2,
    },
    {
      label: "Emami",
      value: 3,
    },
    {
      label: "Desmet",
      value: 4,
    },
  ];

  return companyList;
};

const getUserList = () => {
  const userList = [
    {
      label: "User 01",
      value: 1,
    },
    {
      label: "User 02",
      value: 2,
    },
    {
      label: "User 03",
      value: 3,
    },
    {
      label: "User 04",
      value: 4,
    },
  ];

  return userList;
};


const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'white',
    borderColor: state.isFocused ? '#597EF7' : '#DAE0F5',
    boxShadow: state.isFocused ? '0 0 0 1px #597EF7' : 'none',
    '&:hover': {
      borderColor: '#597EF7',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#597EF7' : state.isFocused ? '#DAE0F5' : 'white',
    color: state.isSelected ? 'white' : 'black',
    verticalAlign: 'middle',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  menu: (provided) => ({
    ...provided,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#30343C',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#83858A',
    fontSize: '14px',
    lineHeight: '16px',
  }),
  input: (provided) => {
    console.log('provided:', provided)
    return {
      ...provided,
      color: '#30343C',
      fontSize: '16px',
      padding: '0',
      lineHeight: '16px',
      margin: '0px'
    }
  },
};

const CONTRACTING = 1;
const INDENTING = 2;
const VIMS = 3;
const ACCOUNTS = 4;


export class LoginAs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      moduleList: getModuleList() || [],
      selectedModule: {
        label: "Contracting",
        value: 1,
      },
      companyList: getCompanyList() || [],
      selectedCompany: null,
      userList: getUserList() || [],
      selectedUser: null,
      selectedUserType: SEEKER_TYPE,
      remarks: ''
    };
  }



  handleOnClickForUserTypeBtn = (userType) => {
    this.setState((prevState) => {
      const updatedState = { ...prevState };
      if (userType === SEEKER_TYPE) {
        updatedState.selectedUserType = SEEKER_TYPE
      }
      if (userType === PROVIDER_TYPE) {
        updatedState.selectedUserType = PROVIDER_TYPE
      }
      return updatedState;
    });
  };

  isContinueBtnDisabled = () => {
    const { selectedModule, selectedCompany, selectedUser, selectedUserType } =
      this.state;

    const C1 = isValueThere(selectedUserType);
    const C2 = isValueThere(selectedCompany);
    const C3 = isValueThere(selectedUser);
    const C4 = isValueThere(selectedModule);

    if (C1 && C2 && C3 && C4) {
      return false;
    }

    return true;
  };

  handleOnClickForModuleBtn = (item) => {
    this.setState((prevState) => {
      const updatedState = { ...prevState };
      updatedState.selectedModule = item;
      return updatedState;
    });
  }

  handleOnChangeForRemarks = (event) => {
    this.setState((prevState) => {
      const updatedState = { ...prevState };
      updatedState.remarks = event.target.value;
      return updatedState;
    });
  }

  handleModuleRedirection = () => {
    const { match } = this.props;
    const { selectedUserType, selectedModule } = this.state;

    let redirectUrl = null;
    const token = match.params.token
    const moduleId = selectedModule.value
    const userType = selectedUserType
    let queryParams = {
      token,
      moduleId,
      userType
    };
    const C1 = !isValueThere(token);

    if (C1) {
      return;
    }


    if (selectedUserType === SEEKER_TYPE) {

      if (selectedModule.value === CONTRACTING) {
        redirectUrl = process.env.SEEKER_CONTRACTING_URL
      }
      if (selectedModule.value === INDENTING) {
        redirectUrl = process.env.SEEKER_INDENT_URL
      }
      if (selectedModule.value === VIMS) {
        redirectUrl = process.env.SEEKER_VIMS_URL
      }
      if (selectedModule.value === ACCOUNTS) {
        redirectUrl = process.env.SEEKER_ACCOUNTS_URL
      }

    }

    if (selectedUserType === PROVIDER_TYPE) {

      if (selectedModule.value === CONTRACTING) {
        redirectUrl = process.env.PROVIDER_CONTRACTING_URL
      }
      if (selectedModule.value === INDENTING) {
        redirectUrl = process.env.PROVIDER_INDENT_URL
      }
      if (selectedModule.value === VIMS) {
        redirectUrl = process.env.PROVIDER_VIMS_URL
      }
      if (selectedModule.value === ACCOUNTS) {
        redirectUrl = process.env.PROVIDER_ACCOUNTS_URL
      }

    }
    const queryString = new URLSearchParams(queryParams).toString();
    redirectUrl = `${redirectUrl}/redirect?${queryString}`
    console.log('redirectUrl:', redirectUrl)

    if (isValueThere(redirectUrl)) {
      window.location.href = redirectUrl
    }


  };

  handleSubmit = (e) => {

    e.preventDefault();
    console.log(" this.state:", this.state);

    this.handleModuleRedirection();
  };

  render() {
    const {
      moduleList,
      selectedModule,
      companyList,
      selectedCompany,
      userList,
      selectedUser,
      selectedUserType,
      remarks
    } = this.state;
    return (
      <Fragment>
        <div className="login-as-container">
          <div
            className="bg-design-wrapper"
          >
            <div className="card-wrapper">
              <h5 className="card-title">Request Access</h5>
              <form className="form-wrapper" onSubmit={this.handleSubmit}>
                <div>
                  <div className="btn-group">
                    <button
                      type="button"
                      className={cx({
                        "active": selectedUserType === SEEKER_TYPE
                      })}
                      onClick={() => {
                        this.handleOnClickForUserTypeBtn(SEEKER_TYPE)
                      }}
                    >
                      Seeker Access
                    </button>
                    <button
                      type="button"
                      className={cx({
                        "active": selectedUserType === PROVIDER_TYPE
                      })}
                      onClick={() => {
                        this.handleOnClickForUserTypeBtn(PROVIDER_TYPE)
                      }}
                    >
                      Provider Access
                    </button>
                  </div>
                </div>
                <div>
                  <label className="label" htmlFor="">
                    Which Company? *
                  </label>
                  <Select
                    styles={customStyles}
                    options={companyList}
                    value={selectedCompany}
                    onChange={(data) => {
                      this.setState({ selectedCompany: data });
                    }}
                  />
                </div>
                <div>
                  <label className="label" htmlFor="">
                  Which User? * 
                  </label>
                  <Select
                    styles={customStyles}
                    options={userList}
                    value={selectedUser}
                    onChange={(data) => {
                      this.setState({ selectedUser: data });
                    }}
                  />
                </div>
                <div>
                  <label className="label"> Which Module? * </label>
                  <div
                    className="chip-btn-group-wrapper"
                  >
                    {
                      getModuleList().map((item) => {
                        return <Fragment key={item.value}>
                          <button
                            type="button"
                            className={cx(
                              "chip-btn",
                              {
                                "active": selectedModule.value === item.value
                              })}
                            onClick={() => {
                              this.handleOnClickForModuleBtn(item)
                            }}
                          >
                            <span>
                              {selectedModule.value === item.value
                                ? <div
                                  style={{
                                    marginBottom: "-4px"
                                  }}
                                >
                                  <CheckIcon
                                    width={"16px"}
                                    height={"16px"}
                                    fill={"#fff"}
                                  />
                                </div>
                                : null}
                            </span>
                            {item.label}
                          </button>
                        </Fragment>
                      })
                    }
                  </div>
                </div>
                <div>
                  <label className="label">Why this access is needed? </label>
                  <textarea
                    rows="3"
                    value={remarks}
                    onChange={this.handleOnChangeForRemarks}
                    placeholder="Add Remarks..."
                    className="remarks"
                  >
                  </textarea>
                </div>
                <button
                  className="form-submit-btn"
                  disabled={this.isContinueBtnDisabled()}
                  type="submit"
                >
                  Submit a Request
                </button>
              </form>
            </div>
            <div
              className="design01"
            >
              <Design01 />
            </div>
            <div
              className="design02"
            >
              <Design02 />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, null)(withRouter(LoginAs));
