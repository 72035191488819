import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ACTION from "../../common/action_constant";
import "./odaMasters.less";

import MainComponent from "../../home/main/main";
import Button from "../../home/button-classic/button-classic";
import CheckBox from "./../../components/checkbox/checkbox";
import InputText from "../../common/components/input-text/input-text";
import Loader from "../../common/components/loader/loader";
import MSGPopUp from "../../common/components/msg-popup/msg-popup";

import NewOdaSlab from "./newOdaSlab";
import Save from '../../../assets/images/save.svg';
import Cancel from '../../../assets/images/cancel.svg';
import Edit from '../../../assets/images/edit.svg';
import Download from '../../../assets/images/direct-download.svg'
import Select from "react-select";
import Input from '../../common/components/input-text/input-text';

import { getCookie } from "../../../utils/cookies";
import { ABFRL_COMPANY_IDS } from '../../../utils/common';

class OdaMasters extends Component {
    constructor(props){
        super(props);
        this.state = {
            addItemPopup: false,
            selectedOdas: [],
            serviceType: 1,
            isAdded: false,
            offset: 0,
            limit: 20,
            vendorId: '',
            vendorName: '',
            isSlab: 1,
            isCity: false,
            data: { 
                //     vendor_name: '',
                //     vendor_id: '',
                //     vendor_code: '',
                //     mode_type: '',
                //     mode_id: '',
                //     company_category: '',
                //     company_category_id: '',
                //     oda_type: '',
                //     oda_type_id: '',
                //     from: '',
                //     from_id: '',
                //     to: '',
                //     to_id: '',
                //     // oda_distance: ["26-75", "76-150", "151-300"],
                //     // oda_distance_uom: "KM",
                //     // oda_weights: ["0-100", "101-250", "251-500", "501-1000", "Above 1000"],
                //     // oda_weights_uom: "Kgs",
                //     // odas: []
            },
            odaMasterData: (props.dataGovernance && props.dataGovernance.odaMasterData) ? props.dataGovernance.odaMasterData : [],
            error: '',
            isCitySlab: false
        }
    }

    componentDidMount() {
        this.getModeTypes();
        this.getVendorsData();
        this.getAllTypesOfODA();
        this.getOdaMasterData(this.state.isSlab===2);
    }

    componentWillReceiveProps(nextProps) {
        const { dataGovernance } = this.props;
        
        if (dataGovernance !== nextProps.dataGovernance) {
            if (dataGovernance.odaMasterData !== nextProps.dataGovernance.odaMasterData) {
                this.setState({
                    odaMasterData: nextProps.dataGovernance.odaMasterData
                });
            }
        }
    }

    getServiceName = (id) => {
        let name = '';
        switch(id) {
            case 1:
                name = 'LCL';
                break;
            case 2:
                name = 'FTL';
                break;
            case 3:
                name = 'Courier';
                break;
            default:
                break;
        }
        return name;
    }

    getCities = (searchValue) => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_CITY_LIST,
            params: `cityName=${searchValue}&lookingFor=city`,
        });
    }

    getHubs = (searchValue, vendorId) => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_HUB_LIST,
            params: `companyId=${JSON.parse(getCookie("user")).company_id}&vendorId=${vendorId}&limit=100&offset=0&search=${searchValue}`,
        });
    }

    getOdaMasterData = (isSlab) => {
        const { dispatch } = this.props;
        const { offset, limit, vendorId, isCity } = this.state;
        let params = `company_id=${JSON.parse(getCookie("user")).company_id}&lan_type=${this.getServiceName(this.state.serviceType)}&limit=${limit}&offset=${offset}`;
        isCity === true ?
        params += `&is_citywise=${isCity}`:
        params += `&is_slab=${isSlab}`;

        if (vendorId) {
            params = params + `&vendor_id=${vendorId}`;
        }
        

        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_ODA_MASTERS,
            params,
            onSuccess: (() => { this.setState({ editId: null, isAdded: false, error: '' }) })
        })
        
    }

    getVendorsData = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_VENDORS_TYPES,
        })
    }

    getModeTypes = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_MODE_TYPES,
        })
    }

    getAllTypesOfODA = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_ALL_TYPES_OF_ODA
        });
    }

    handleSave = (data) => {
        this.editOda(0);

        this.setState({
            isAdded: true,
            addItemPopup: false,
        });
    }

    editOda = (index) => {
        this.setState({
            editId: (this.state.editId===index) ? null : index
        });
    }

    handleCkSelect = (e, id) => {
        const { selectedOdas } = this.state;
        if (selectedOdas.includes(id)) {
            selectedOdas.splice(id, 1);
        } else {
            selectedOdas.push(id);
        }
        
        this.setState({ selectedOdas });
    }

    saveOda = (oda) => {
        let { isAdded, isCity } = this.state;
        const { dispatch } = this.props;
        const data = {
            company_id: JSON.parse(getCookie("user")).company_id,
            oda_details: {
                ...oda,
                lan_type: this.getServiceName(this.state.serviceType),
                is_citywise: isCity ? isCity : undefined,
            },
        };
        if (isAdded) {
            if(oda.oda_type_id !== 3 ? ((oda.oda_type_id == 6) ? (oda.minimum_freight && oda.minimum_weight) : oda.oda_value) : (oda.oda_rate)){

            dispatch({
                type: ACTION.DATA_GOVERNANCE.POST_ODA_MASTERS,
                params: data,
                onSuccess: (() => {
                    this.setState({ isAdded: false, editId: null, isCitySlab: false });
                    this.getOdaMasterData(this.state.isSlab===2);
                })
            })
        }
    } else {
            this.updateOda(data);
        }
    }

    updateOda = (oda) => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.UPDATE_ODA_MASTERS,
            params: oda,
            onSuccess: (() => {
                this.setState({ isAdded: false, editId: null });
                this.getOdaMasterData(this.state.isSlab===2);
            })
        });
    }

    deleteOda = () => {
        const { selectedOdas } = this.state;
        const { dispatch } = this.props;
        const data = {
                company_id: JSON.parse(getCookie("user")).company_id,
                oda_ids: selectedOdas,
                lan_type: this.getServiceName(this.state.serviceType)
            };
        dispatch({
            type: ACTION.DATA_GOVERNANCE.DELETE_ODA_MASTERS,
            params: data,
            onSuccess: (() => {
                this.getOdaMasterData(this.state.isSlab===2);
            })
        });
    }

    downloadOda = (withData) => {
        const { odaMasterData, vendorId, isCity } = this.state;
        const { dispatch } = this.props;
        const company_id = JSON.parse(getCookie("user")).company_id;
        if(vendorId){
            dispatch({
                type: ACTION.DATA_GOVERNANCE.DOWNLOAD_ODA,
                params: withData ? `companyId=${company_id}&lan_type=${this.getServiceName(this.state.serviceType)}&reportType=0&withData=${withData}&vendor_id=${vendorId}&is_citywise=${isCity}` : `companyId=${company_id}&lan_type=${this.getServiceName(this.state.serviceType)}&reportType=0&withData=${withData}&is_citywise${isCity}`,
                onSuccess: ((data) => {
                    if(data.length > 0) {
                        this.setState({
                            downloadLink: data[0].Link
                        }, () => { 
                            this.downloadBtn.click();
                        });
                    }
                    this.setState({ error: null})
                })
            })
        }
        else {
            this.setState({ error: "Please select Vendor"})
        }
    }
    downloadSlab = () => {
        const { odaMasterData, isCity } = this.state;
        const { dispatch } = this.props;
        const company_id = JSON.parse(getCookie("user")).company_id;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.DOWNLOAD_SLAB,
            params: isCity ? `company_id=${company_id}&is_citywise=${isCity}` :`company_id=${company_id}`,
            onSuccess: ((data) => {
                if(data.length > 0) {
                    this.setState({
                        downloadLink: data[0].Link
                    }, () => { 
                        document.getElementById('slabDownloadBtn').setAttribute('href', data[0].Link);
                        document.getElementById('slabDownloadBtn').click();
                        // this.downloadBtn.click();
                    });
                }
            })
        })
    }
    uploadODA = (e) => {
        let formData = new FormData();
        formData.append('file', e.target.files[0]);
        const { dispatch } = this.props;
        const { isCity } = this.state;
        const company_id = JSON.parse(getCookie("user")).company_id;

        formData.append('is_citywise', isCity);
        formData.append('company_id', company_id);
        formData.append('lan_type', this.getServiceName(this.state.serviceType));


        dispatch({ 
            type: ACTION.DATA_GOVERNANCE.UPLOAD_ODA, 
            data: formData, 
            onSuccess: (() => {
                this.getOdaMasterData(this.state.isSlab===2);
            })
        });
    }
    uploadSlab = (e) => {
        let formData = new FormData();
        formData.append('file', e.target.files[0]);
        const { dispatch } = this.props;
        const { isCity, vendorId, vendorName } = this.state;
        const company_id = JSON.parse(getCookie("user")).company_id;
        const otherParams = Object.keys(this.state.data).length > 0 ? this.state.data : this.state.openSlab;
        const data = {
            company_id: JSON.parse(getCookie("user")).company_id,
            oda_details: {
                ...otherParams,
                lan_type: this.getServiceName(this.state.serviceType),
                is_citywise: isCity ? isCity : undefined,
                vendor_id: vendorId ? vendorId : undefined,
                vendor_name: vendorName ? vendorName : undefined,
                // oda_distance: ["26-75", "76-150", "151-300"],
                // oda_weights: ["0-100 Kgs", "101-250 Kgs", "251-500 Kgs", "501-1000 Kgs", "Above 1000 Kgs"],
            },
        };
        
        formData.append('jsonData', JSON.stringify(data));
        
        dispatch({ 
            type: ACTION.DATA_GOVERNANCE.UPLOAD_SLAB, 
            data: formData, 
            onSuccess: (() => {
                this.setState({ 
                    openSlab: null,
                    editId: null,
                    isAdded: false,
                    isCitySlab: false,
                })
                this.getOdaMasterData(this.state.isSlab===2);
            })
        });
    }

    closeMsgPopup = () =>{
        const {dispatch} = this.props;
        dispatch({ type : ACTION.MSG_DISPLAY.HIDE_MSG });
    }

    clickCustomCheckBox = (id, name) =>{
        if(id===3){
            this.setState({isCity: true})
        }
        else{
            this.setState({isCity: false})
        }
        this.setState({
            [name]: id,
            offset: 0,
        }, () => {
            this.getOdaMasterData((id===2));
        })
    }

    onVendorChange = (value, index) => {
        const { odaMasterData } = this.state;
        odaMasterData[index].vendor_name = value.label;
        odaMasterData[index].vendor_id = value.value;
        odaMasterData[index].seeker_id = value.seeker_id;
        odaMasterData[index].company_category = value.company_category;
        odaMasterData[index].company_category_id = value.company_category_id;
        this.setState({ odaMasterData });
    }

    onModeChange = (value, index) => {
        const { odaMasterData } = this.state;
        odaMasterData[index].mode_type = value.label;
        odaMasterData[index].mode_id = value.value;
        this.setState({ odaMasterData });
    }

    onODAChange = (value, index) => {
        const { odaMasterData } = this.state;
        odaMasterData[index].oda_type = value.label;
        odaMasterData[index].oda_type_id = value.value;
        if (value.value===3) {
            odaMasterData[index].oda_weight_uom = 'Kgs';
            odaMasterData[index].oda_rate_uom = 'Rs/Kg';
        }
        if (value.value===4) {
            odaMasterData[index].oda_distance_uom = 'KMS';
            odaMasterData[index].oda_rate_uom = 'Rs/KM';
        }
        this.setState({ odaMasterData });
    }

    handleAddOda = (isCitySlab) => {
        const { odaMasterData, data } = this.state;
        let temp = data;
        temp = {};
        (odaMasterData || []).unshift(temp);
        this.setState({
            odaMasterData,
            editId: 0,
            isAdded: true,
            isCitySlab,
        });
    }

    handleCancelOda = (oda) => {
        if (!oda.oda_id) {
            const { odaMasterData } = this.state;
            (odaMasterData || []).shift();
            this.setState({odaMasterData})
        }
        this.setState({
            editId: null,
            isAdded: false
        });
    }

    getCityWiseSlab = (odaDetails, vendorId, vendorName) => {
        let slabData = [];
        let vendorDetails = [];
        if(vendorId.length === 0){
            this.setState({error: 'Please Select Vendor'})
        }else{
            (odaDetails || []).forEach((data) => {
            if(data.vendor_id && data.vendor_id === vendorId){
                vendorDetails.push(data);
            }})
            slabData = (vendorDetails || []).filter((oda) => oda.odas)
            this.state.isCitySlab ? 
            this.setState({openSlab: slabData.length > 0 ? slabData[slabData.length-1] : vendorDetails[0], error: '', vendorId, vendorName}) : 
            this.setState({openSlab: slabData.length > 0 ? slabData[slabData.length-1] : null, error: '', vendorId, vendorName})
        }
    }

    previous = () => {
        if (this.state.offset > 0) {
            this.setState({
                offset: this.state.offset - 20
            }, () => {
                this.getOdaMasterData(this.state.isSlab===2)
            });
        }
    }
    next = () => {
        this.setState({
            offset: this.state.offset + 20
        }, () => {
            this.getOdaMasterData(this.state.isSlab===2)
        });
    }

    renderTableBody = (odaData) => {
        const { editId, isAdded, selectedOdas, isSlab } = this.state;
        const { dataGovernance } = this.props;
        const company_id = JSON.parse(getCookie("user")).company_id; 
        let cities = [];

        if (dataGovernance.citiesList && dataGovernance.citiesList.length > 0) {
            (dataGovernance.citiesList || []).forEach((items) => {
                cities = cities.concat(items.cities || items.states || []);
            });
        }
        if(isSlab===3){
            if(isAdded === false){
                odaData = (odaData || []).filter((oda) => oda.oda_value)
            }
        }
        return (
          <Fragment>
            {odaData && odaData.length > 0 && (odaData).map((oda, index) => {
                const isAddOrEdit = (editId===index);
                return (
                    <div className="list-section content divTableRow" key={`oda-${(index + 1)}`}>
                        <div className="divTableCell select-row"> 
                        {!isAdded && (index !== editId) && 
                            <input 
                                type="checkbox" 
                                checked={selectedOdas.includes(oda.oda_id)}
                                onClick={(e) => this.handleCkSelect(e, oda.oda_id)}
                            />
                        }
                        </div>
                        <div className="divTableCell"> 
                            {isAddOrEdit ? <Fragment>{oda.company_category ? oda.company_category : 'Auto-fetch'}</Fragment> : <Fragment>{oda.company_category}</Fragment>}
                        </div>
                        <div className="divTableCell"> 
                            {isAddOrEdit ? 
                                <Select
                                    className="select"
                                    value={(dataGovernance.vendorTypes || []).filter((vendor) => { return vendor.value===oda.vendor_id })}
                                    options={dataGovernance.vendorTypes || []}
                                    onChange={(value) => { this.onVendorChange(value, index) }}
                                />
                                :
                                <Fragment>{oda.vendor_name}</Fragment>
                            }
                        </div>
                        <div className="divTableCell"> 
                            {isAddOrEdit ? <Fragment>{oda.vendor_code ? oda.vendor_code : 'Auto-fetch'}</Fragment> : <Fragment>{oda.vendor_code}</Fragment>} 
                        </div>
                        <div className="divTableCell"> 
                            {isAddOrEdit ? 
                                <Select
                                    className="select"
                                    value={(dataGovernance.modeTypes || []).filter((mode) => { return mode.label===oda.mode_type })}
                                    options={dataGovernance.modeTypes || []}
                                    onChange={(value) => { this.onModeChange(value, index) }}
                                />
                                :
                                <Fragment>{oda.mode_type} </Fragment>
                            }
                        </div>
                        <div className="divTableCell">
                            {isAddOrEdit ? 
                            <>
                                <Select
                                    className="select"
                                    value={(dataGovernance.allTypesOfOdaData || []).filter((odd) => { return odd.label===oda.oda_type })}
                                    options={dataGovernance.allTypesOfOdaData || []}
                                    onChange={(value) => { this.onODAChange(value, index) }}
                                />
                            </>
                                :
                                <Fragment> {oda.oda_type} </Fragment>
                            }
                        </div>
                        {(parseInt(oda.oda_type_id)===7 && ABFRL_COMPANY_IDS.includes(company_id) && !this.state.isCitySlab)?
                            <div className="divTableCell">
                                 {isAddOrEdit ? 
                                    <InputText 
                                        type="number"
                                        placeholder="Enter Pincode"
                                        value={oda.pincode} 
                                        changeHandler={(value, id) => {
                                            const { odaMasterData } = this.state;
                                            odaMasterData[index].pincode = value;
                                            this.setState({ odaMasterData });
                                        }}
                                        className={'wt-100px-imp'}
                                    />
                                    :
                                    <Fragment> {oda.pincode?oda.pincode:"-"} </Fragment>    
                                }
                            </div>
                            :
                            <div className="divTableCell"></div>}
                        {!ABFRL_COMPANY_IDS.includes(company_id) &&
                        <div className="divTableCell"> 
                          {isAddOrEdit ? 
                            <Select
                                className="select"
                                options={cities || []}
                                placeholder={oda.from || 'Search'}
                                value={(cities || []).filter(obj => { return (obj.label === oda.from)})}
                                isSearchable={true}
                                onInputChange={(value, items) => {
                                    this.setState({ fromSearch: value })
                                    if (value.length > 1) { this.getCities(value) }
                                }}
                                isDisabled={this.props.dataGovernance.loading}
                                isLoading={this.props.dataGovernance.loading}
                                autoFocus={this.state.fromSearch}
                                menuIsOpen={this.state.fromSearch}
                                inputValue={this.state.fromSearch}
                                onChange={(value) => { 
                                    const { odaMasterData } = this.state;
                                    odaMasterData[index].from = value.label;
                                    odaMasterData[index].from_id = value.id;
                                    this.setState({ odaMasterData, fromSearch: '' });
                                }}
                            />
                            :
                                <Fragment>{oda.from}</Fragment>
                          }
                        </div>}
                        <div className="divTableCell"> 
                            {isAddOrEdit ? 
                                <Select
                                    className="select"
                                    options={cities || []}
                                    placeholder={oda.to || 'Search'}
                                    value={(cities || []).filter(obj => { return (obj.label === oda.to)})}
                                    isSearchable={true}
                                    onInputChange={(value, items) => {
                                        this.setState({ toSearch: value })
                                        if (value.length > 1) {
                                            this.getCities(value)
                                        }
                                    }}
                                    isDisabled={this.props.dataGovernance.loading}
                                    isLoading={this.props.dataGovernance.loading}
                                    autoFocus={this.state.toSearch}
                                    menuIsOpen={this.state.toSearch}
                                    inputValue={this.state.toSearch}
                                    onChange={(value) => { 
                                        const { odaMasterData } = this.state;
                                        odaMasterData[index].to = value.label;
                                        odaMasterData[index].to_id = value.id;
                                        this.setState({ odaMasterData, toSearch: '' });
                                    }}
                                />
                                :
                                    <Fragment>{oda.to}</Fragment>
                              }
                        </div>
                        <div className="divTableCell"> 
                            {isAddOrEdit ? 
                                <Select
                                    className="select"
                                    options={(dataGovernance.ODAhubsList || []).map(el => { return { ...el, label:el.address, value: el.hub_id } })}
                                    placeholder={oda && oda.to_hub ? oda.to_hub.address : 'Search'}
                                    value={(dataGovernance.ODAhubsList || []).filter(obj => { return ( oda && oda.to_hub && oda.to_hub.address ? obj.label === oda.to_hub.address : null )})}
                                    isSearchable={true}
                                    onInputChange={(value, items) => {
                                        this.setState({ toHubSearch: value })
                                        if (value.length > 1) {
                                            this.getHubs(value, oda.vendor_id);
                                        }
                                    }}
                                    isDisabled={this.props.dataGovernance.loading}
                                    isLoading={this.props.dataGovernance.loading}
                                    autoFocus={this.state.toHubSearch}
                                    menuIsOpen={this.state.toHubSearch}
                                    inputValue={this.state.toHubSearch}
                                    onChange={(value) => { 
                                        const { odaMasterData } = this.state;
                                        odaMasterData[index].to_hub = { hub_id: value.hub_id, address: value.address };
                                        this.setState({ odaMasterData, toHubSearch: '' });
                                    }}
                                />
                                :
                                    <Fragment>{oda && oda.to_hub ? oda.to_hub.address : '-'}</Fragment>
                              }
                        </div>
                        <div className="divTableCell laneInput"> 
                            {(parseInt(oda.oda_type_id)===1 || (parseInt(oda.oda_type_id)===7 && ABFRL_COMPANY_IDS.includes(company_id) && !this.state.isCitySlab )|| (parseInt(oda.oda_type_id)===5 && !this.state.isCitySlab)) && 
                                <Fragment>
                                {isAddOrEdit ? 
                                    <InputText 
                                        type="number"
                                        placeholder="Value"
                                        value={oda.oda_value} 
                                        changeHandler={(value, id) => {
                                            const { odaMasterData } = this.state;
                                            odaMasterData[index].oda_value = value;
                                            this.setState({ odaMasterData });
                                        }}
                                    />
                                    :
                                    <Fragment> {oda.oda_value} </Fragment>
                                }
                                </Fragment>
                            }
                            {(parseInt(oda.oda_type_id)===2 || (parseInt(oda.oda_type_id)===5 && this.state.isCitySlab)) && 
                                ((isSlab===1 && oda.vendor_id && oda.company_category && oda.mode_type && oda.from && oda.to) ||
                                (isSlab===2 && oda.vendor_id && oda.company_category) || (isSlab===3 && oda.vendor_id && oda.company_category )) &&
                                <Fragment>
                                {isSlab === 2 && isAddOrEdit ? 
                                    <div className="curP" 
                                        onClick={() => { this.setState({ openSlab: this.state.openSlab===oda ? null : oda }); }}>
                                        {'View Slabs'}
                                    </div>
                                    :
                                    <Fragment>{oda.oda_value} {oda.oda_value_uom}</Fragment>
                                }
                                {isSlab === 3 && isAddOrEdit ? 

                                    <div className="curP" 
                                        onClick={() => {this.getCityWiseSlab(odaData,oda.vendor_id,oda.vendor_name)}}>
                                        {'View Slabs'}
                                    </div>
                                    :
                                    <Fragment>{oda.oda_value_uom}</Fragment>
                                }
                                </Fragment>
                            }
                            {(parseInt(oda.oda_type_id)===3) && 
                                <Fragment>
                                {isAddOrEdit ? 
                                    <Fragment><InputText 
                                        type="number"
                                        placeholder="Rs/Kg"
                                        value={oda.oda_rate} 
                                        changeHandler={(value, id) => {
                                            const { odaMasterData } = this.state;
                                            odaMasterData[index].oda_weight = 1;
                                            odaMasterData[index].oda_rate = value;
                                            this.setState({ odaMasterData });
                                        }}
                                    />{oda.oda_rate_uom}</Fragment>
                                    :
                                    <Fragment> {oda.oda_rate} {oda.oda_rate_uom} </Fragment>
                                }
                                </Fragment>
                            }
                            {(parseInt(oda.oda_type_id)===4) && 
                                <Fragment>
                                {isAddOrEdit ? 
                                    <Fragment><InputText 
                                        type="number"
                                        placeholder="Rs/KM"
                                        value={oda.oda_rate} 
                                        changeHandler={(value, id) => {
                                            const { odaMasterData } = this.state;
                                            odaMasterData[index].oda_distance = 1;
                                            odaMasterData[index].oda_rate = value;
                                            this.setState({ odaMasterData });
                                        }}
                                    /> {oda.oda_rate_uom} </Fragment>
                                    :
                                    <Fragment>{oda.oda_rate} {oda.oda_rate_uom}</Fragment>
                                }
                                </Fragment>
                            }
                            {
                                isSlab !== 1 && oda.odas && oda.odas.length > 0 &&
                                <div className="curP" 
                                    onClick={() => { this.setState({ openSlab: this.state.openSlab===oda ? null : oda }); }}
                                > View Slabs </div>
                            }
                        </div>

                        {/* {(ABFRL_COMPANY_IDS.includes(company_id) && (parseInt(oda.oda_type_id) === 6)) ?
                            <div className="divTableCell">
                                {isAddOrEdit ?
                                    <input
                                        type="number"
                                        className='tCenter'
                                        placeholder="Min Freight"
                                        value={oda.minimum_freight || ''}
                                        onChange={(e) => {
                                            const { odaMasterData } = this.state;
                                            odaMasterData[index].minimum_freight = e.target.value;
                                            this.setState({ odaMasterData });
                                        }}
                                    />
                                    :
                                    <Fragment>{oda.minimum_freight || '-'}</Fragment>
                                }
                            </div>
                            :
                            (ABFRL_COMPANY_IDS.includes(company_id) && <div className='divTableCell'>-</div>)
                        }

                        {(ABFRL_COMPANY_IDS.includes(company_id) && (parseInt(oda.oda_type_id) === 6)) ?
                            <div className="divTableCell">
                                {isAddOrEdit ?
                                    <input
                                        type="number"
                                        className='tCenter wt-40'
                                        placeholder="Min Weight"
                                        value={oda.minimum_weight || ''}
                                        onChange={(e) => {
                                            const { odaMasterData } = this.state;
                                            odaMasterData[index].minimum_weight = e.target.value;
                                            this.setState({ odaMasterData });
                                        }}
                                    />
                                    :
                                    <Fragment>{oda.minimum_weight || '-'}</Fragment>
                                }
                            </div>
                            :
                            (ABFRL_COMPANY_IDS.includes(company_id) && <div className='divTableCell'>-</div>)
                        } */}

                        <div className="divTableCell"> 
                        {(editId===index) 
                            ?
                                <Fragment>
                                    <div className={'action-icon'} style={{backgroundImage: `url(${Save})`}}  onClick={() => this.saveOda(oda)} /> 
                                    <div className={'action-icon'} style={{backgroundImage: `url(${Cancel})`}} onClick={() => this.handleCancelOda(oda)} />
                                </Fragment>
                            :
                                <div className={'action-icon'} style={{backgroundImage: `url(${Edit})`}} onClick={() => this.editOda(index)} /> 
                        }
                        </div>
                    </div>
               )
            })}
          </Fragment>
        )
    }
    
    render() {
        const { addItemPopup, odaMasterData, isAdded, serviceType, isSlab, vendorId, vendorName } = this.state;
        const { dataGovernance } = this.props;
        const company_id = JSON.parse(getCookie("user")).company_id;
        return (
            <div className="discount-wrap">
                <div className="wrapper">
                    <MainComponent>
                        
                        {this.props.loader.isLoading && <Loader />}
                        {this.props.msgpopup.active && <MSGPopUp close = {this.closeMsgPopup} type = {this.props.msgpopup.type} msg = {this.props.msgpopup.msg}/>}
                        <div className="sub-heading-section"> 
                            <div className="sub-heading"> ODA Master </div>
                        </div>

                        <div>
                            <div className="serviceTypes">
                                <div className="cta-action-btns">
                                    <div className = "section-item">
                                        <CustomeCheckBox id={1} toggle={(id) => this.clickCustomCheckBox(id, 'serviceType')} selected={(serviceType===1)}/>
                                        <div className="title"> LCL</div>
                                    </div>
                                    <div className = "section-item">
                                        <CustomeCheckBox id={2} toggle={(id) => this.clickCustomCheckBox(id, 'serviceType')} selected={(serviceType===2)}/>
                                        <div className="title"> FTL</div>
                                    </div>
                                    <div className = "section-item">
                                        <CustomeCheckBox  id={3} toggle={(id) => this.clickCustomCheckBox(id, 'serviceType')} selected={(serviceType===3)}/>
                                        <div className="title"> Courier</div>
                                    </div>
                                </div>
                                <div className="cta-action-btns cta-btn-right filterSelect">
                                    <Select
                                        isClearable={true}
                                        className="select"
                                        placeholder={'Vendor Select'}
                                        options={dataGovernance.vendorTypes || []}
                                        value={(dataGovernance.vendorTypes || []).filter(obj => {return obj.value===this.state.vendorId})}
                                        onChange={(value) => { this.setState({ offset: 0, vendorId: value===null ? '' : value.value, vendorName:  value=== null ? '' : value.label}, () => { this.getOdaMasterData((isSlab===2)) }) }}
                                    />
                                </div>
                            </div>
                            <div className="cta-action-btns">
                                {isSlab!==2 && <div>
                                    <button className="button-classic" disabled={isSlab===2} onClick={() => { this.downloadOda(false)}}> Download Template</button>
                                    <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
                                    
                                    <button className="button-classic" disabled={isSlab===2} onClick={() => {this.uploadExcel.click()}}> Upload Excel </button>
                                    <input type='file' style={{ display: 'none'}}  ref={(e) => { this.uploadExcel = e ;}} onClick={(e) => {e.target.value = null }}  onChange={this.uploadODA} />
                                </div>}
                                {isSlab===3 &&
                                    <button className="button-classic curP" onClick={() => { this.getCityWiseSlab(odaMasterData,vendorId,vendorName)}}> View Slab </button>
                                }
                            </div>
                            <div className="cta-action-btns cta-btn-right">
                                <button className="button-classic" onClick={() => {this.handleAddOda(false)}}>Add Record</button>
                                <button className="button-classic" onClick={this.deleteOda}>Delete Record(s)</button>
                                {/*<Button value="Advanced Filters" />*/}
                            
                            <div className = 'fRight'>
                                {isSlab!==2 && <div className={'download-icon'} style={{backgroundImage: `url(${Download})`}} onClick={() => {this.downloadOda(true)}}/>}
                                <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
                            </div>
                            {this.state.error && this.state.error.length > 0 && <div className="ml-130 error  mt-10">{this.state.error}</div>}
                            {isSlab===3 && <div><button className="button-classic" onClick={() => {this.handleAddOda(true)}}>Add Slab</button></div>}
                            </div>
                            <div className="serviceTypes">
                                <div className="cta-action-btns">
                                    <div className = "section-item">
                                        <CustomeCheckBox id={1} toggle={(id) => this.clickCustomCheckBox(id, 'isSlab')} selected={(isSlab===1)}/>
                                        <div className="title"> General </div>
                                    </div>
                                    <div className = "section-item">
                                        <CustomeCheckBox id={2} toggle={(id) => this.clickCustomCheckBox(id, 'isSlab')} selected={(isSlab===2)}/>
                                        <div className="title"> Slabwise </div>
                                    </div>
                                    <div className = "section-item">
                                        <CustomeCheckBox id={3} toggle={(id) => this.clickCustomCheckBox(id, 'isSlab')} selected={(isSlab===3)}/>
                                        <div className="title"> Cityslab </div>
                                    </div>
                                </div>
                            </div>

                            <div className="divTable company-list-wrap">
                                <div className="list-section table-header divTableHeading divTableRow">
                                    <div className="divTableCell select-row"> </div>
                                    <div className="divTableCell"> Category </div>
                                    <div className="divTableCell"> Vendor Name </div>
                                    <div className="divTableCell"> Vendor Code </div>
                                    <div className="divTableCell"> Mode </div>
                                    <div className="divTableCell"> Type </div>
                                    {ABFRL_COMPANY_IDS.includes(company_id) && !this.state.isCitySlab&&
                                    <div className="divTableCell"> Pincode </div>
                                    }
                                    {!ABFRL_COMPANY_IDS.includes(company_id) ?
                                    <Fragment>
                                    <div className="divTableCell"> From </div>
                                    <div className="divTableCell"> To </div>
                                    </Fragment> :
                                    <div className="divTableCell"> City </div> }
                                    <div className="divTableCell"> Hub </div>
                                    <div className="divTableCell"> ODD/ESS </div>
                                    {/* {ABFRL_COMPANY_IDS.includes(company_id) && <div className="divTableCell"> Min Freight </div>}
                                    {ABFRL_COMPANY_IDS.includes(company_id) && <div className="divTableCell"> Min Weight(In KG) </div>} */}
                                    <div className="divTableCell"> Actions </div>
                                </div>
                                <div className="divTableBody">
                                    {this.renderTableBody(odaMasterData)}
                                </div>
                            </div> 

                            {
                                this.state.openSlab && 
                                <NewOdaSlab 
                                    slabData={this.state.openSlab}
                                    downloadSlab={this.downloadSlab}
                                    uploadSlab={this.uploadSlab}
                                    downloadLink={this.state.downloadLink}
                                    cancelNewSlab={() => { this.setState({ openSlab: null }) }}
                                />
                            }
                        </div>
                        <div className="submit-section-pagination">
                            <div className="next">
                                <Button value="Next" disabled={dataGovernance.odaCount === 0 || (dataGovernance.odaCount <= (this.state.offset+this.state.limit))} click={this.next} />
                            </div>
                            <div className="pre">
                                <Button value="Previous" disabled={this.state.offset===0} click={this.previous} />
                            </div>
                        </div>
                    </MainComponent>
                </div>
            </div>
        );
    }
}

const CustomeCheckBox = (props) =>{
    return(
        <div className = {props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick = {()=>props.toggle(props.id)}>
            {props.selected && <div className = "inner-circle"></div>}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loader: state.loader,
        msgpopup: state.msgpopup,
        dataGovernance: state.dataGovernance,
    }
}

export default connect(mapStateToProps)(OdaMasters);
