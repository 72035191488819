import React, { Component } from 'react';
import Search from '../../../../common/components/search-text/search-text';
import Button from '../../../../home/button-classic/button-classic';
import { connect } from "react-redux";
import ACTIONS from '../../../../common/action_constant'
import AddVolumetric from '../volumetric/add_volumetric';
// import { toPascalCase } from "../../../utils/validate";

import Delete from '../../../../../assets/images/delete.svg';
import Edit from '../../../../../assets/images/edit.svg'
import { getCookie } from '../../../../../utils/cookies';
import { getEmail, getUserCompanyId, getUserId } from '../../../../../utils/common';
import { withRouter } from 'react-router';
// import { AWL_COMPANY_IDS } from '../../../../../utils/common';


class Volumetric extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            offset: 0,
            limit: 20,
            packUOMdropdown:[]
        }
    }
    componentDidMount(){
        this.getPackUOMDropdown();
        this.fetchDepotName();
    }

    componentWillMount() {
        this.getVolumeticMaster()
     
    }
    getPackUOMDropdown = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTIONS.PLANTS.GET_PACK_UOM_DROPDOWN,
        })
      }

    fetchDepotName = () => {
        const {dispatch ,companyId} = this.props;
        const queryString = `?companyId=${companyId}`;

        dispatch({
            type: ACTIONS.PLANTS.GET_DEPO_NAME,
            queryString
        })
    }
      handleInputChange = (event) => {
		this.setState({ group_company_name: event.target.value });
	  };


    getVolumeticMaster() {
        const user = JSON.parse(getCookie("user"));
        const { dispatch, companyId } = this.props;
        const { search, offset, limit  } = this.state;
        const queryString = `?company_id=${companyId}&search=${search}&limit=${limit}&offSet=${offset}`;
        dispatch({
          type: ACTIONS.PLANTS.GET_VOLUMETRIC_MASTER,
          queryString,
        });
      }

    changeHandler = (value) => {
        this.setState({ search: value });
    }

    toggleAdd = () => {
      
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen, editData: '' });
    }

    onEditRoute = (route) => {
        this.setState({ editData: route, isOpen: true })
    }

    onSave = (data) => {
        const { search, offset } = this.state;
        const { dispatch, company_id } = this.props;
        
        dispatch({
            type: ACTIONS.PLANTS.ADD_VOLUMETRIC_MASTER,
            data,
           
            onSuccess: () => {
                this.setState({ isOpen: false }, this.getVolumeticMaster());
            }
        })
    }


    onSearch = () => {
        this.setState({ offset: 0 }, this.getVolumeticMaster);
      };

    
    onUpdate = (data) => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTIONS.PLANTS.UPDATE_VOLUMETRIC_MASTER,
            data,
            onSuccess: () => {
                this.setState({ isOpen: false, editData: '' }, this.getVolumeticMaster);
            }
        })
    }
    // downloadTemp = (withData) => {
    //     const { dispatch } = this.props;
    //     dispatch({
    //         type: ACTIONS.HOME.DOWNLOAD_MATERIAL_CONVERSION,
    //         withData,
    //         onSuccess: ((data) => {
    //             this.setState({ downloadUrl: data ? data[0].Link : '' }, () => {
    //                 this.downloadLink.click();
    //             });
    //         })
    //     })
    // }

    handleOnClickForAuditBtn = () => {
        const {history} = this.props;
        const companyId = this.props.companyId;
        const masterType = 9
        const navUrl = `/auditScreen/${companyId}/${masterType}`;
                history.push(navUrl)
      }

    uploadMaterialConversion = (e) => {
        let formData = new FormData();
        formData.append('file', e.target.files[0]);
        const { dispatch } = this.props;
        dispatch({
            type: ACTIONS.HOME.UPLOAD_MATERIAL_CONVERSION, data: formData,
            onSuccess: () => {
                this.getMaterialConversion();
            }
        });
    }

    uploadMC = () => {
        this.uploadExcel.click();
    }

    editMC = (data) => {
        this.setState({
            editData: data,
            isOpen: true,
        });
    }

    deleteMC = (data) => {

        const { dispatch } = this.props;
        const user_email = getEmail();
        const user_id = getUserId();
        dispatch({
            type: ACTIONS.PLANTS.DELETE_VOLUMETRIC_MASTER,
            queryString:`?company_id=${data.company_id}&material_code=${data.material_code}&depot_code=${data.depot_code}&masterType=${9}&user_email=${user_email}&user_id=${user_id}`,
            onSuccess: () => {
                this.getVolumeticMaster();
            }
        })
    }

    previous = () => {
        if (this.state.offset >= this.state.limit) {
          this.setState(
            (prevState) => ({
              offset: prevState.offset - prevState.limit,
            }),
            this.getVolumeticMaster
          );
        }
      };
      
      next = () => {
        this.setState(
          (prevState) => ({
            offset: prevState.offset + prevState.limit,
          }),
          this.getVolumeticMaster
        );
      };
      
    clearFilterOnCancel = () => {
        this.setState({ search: '', offset: 0 }, this.getVolumeticMaster);
      };


      downloadTemp = (withData) => {
        const { dispatch,companyId} = this.props;
        const queryString = `company_id=${companyId}`;
        dispatch({
            type: ACTIONS.PLANTS.DOWNLOAD_VOLUMETRIC_MASTER,
            queryString,
            withData,
            onSuccess: ((data) => {
                this.setState({ downloadUrl: data ? data[0].Link : '' }, () => {
                    this.downloadLink.click();
                });
            })
        })
    }
    uploadVolumetricMaster = (e) => {
        let formData = new FormData();
        formData.append('file', e.target.files[0]);
        const { dispatch,companyId} = this.props;
        const queryString = `company_id=${companyId}&user_email=${getEmail()}&user_id=${getUserId()}`;
        dispatch({
            type: ACTIONS.PLANTS.UPLOAD_VOLUMETRIC_MASTER_DATA, data: formData,
            queryString,
            onSuccess: () => {
                this.getVolumeticMaster();
            }
        });
    }
    uploadVM = () => {
        this.uploadExcel.click();
    }

    render() {
        const { plantsDepots, home } = this.props;    
         const{volumetricMaster,packUOMdropdown,DepotName}=plantsDepots
        return (
            <div className={'location-network'}>
                <div>
                    <div style={{ display: 'block', paddingBottom: '15px' }}>
                        <Search id="search" value={this.state.search} placeholder="Search..." changeHandler={this.changeHandler} click={this.onSearch} canClear={this.state.search !== ''} handleClearFilter={this.clearFilterOnCancel} />
                        <Button value={'+ Volumetric'} click={this.toggleAdd}  />
                        <Button value={'Download Temp.'} click={() => this.downloadTemp(true)} />
                        <a href={this.state.downloadUrl} style={{ display: 'none' }} ref={(e) => { this.downloadLink = e; }} />
                        <Button value={'Download Details'} click={() => this.downloadTemp(false)} />
                        <a href={this.state.downloadUrl} style={{ display: 'none' }} ref={(e) => { this.downloadLink = e; }} />
                        <Button value={'Upload'} click={this.uploadVM} />
                        <button className="button-classic" onClick={this.handleOnClickForAuditBtn} >Audit </button>
                        <input type='file' style={{ display: 'none' }} ref={(e) => { this.uploadExcel = e; }} onChange={this.uploadVolumetricMaster} onClick={(e) => { e.target.value = null }} />         
                    </div>

                </div>
                < table className={'chain-details-section'}>
                   
                    <tr className={'list-section header'}>
                        <td className={'tabel-header vol-th'}>Material Code</td>
                        <td className={'tabel-header vol-th'}>Material Description</td>                                              
                        <td className={'tabel-header vol-th'}>New Weight</td>
                        <td className={'tabel-header vol-th'}>Material Type</td>                       
                        <td className={'tabel-header vol-th'}>Pack Size</td>
                        <td className={'tabel-header vol-th'}>Packing UOM</td>
                        <td className={'tabel-header vol-th'}>Gross Weight</td>
                        <td className={'tabel-header vol-th'}>Weight For SCD</td>
                        <td className={'tabel-header vol-th'}>Tolerance Quantity</td>
                        <td className={'tabel-header vol-th'}>Depot Code</td>
                        <td className={'tabel-header vol-th'}>Actions</td>
                    </tr>
        
                        {(volumetricMaster|| []).map((mc, index) => {
                            return(
                            <div className={'list-section content'} key={`route_${index}`}>
									    <div className={'tabel-header vol-td'}>{mc.material_code}</div>                                   
                                        <div className={'tabel-header vol-td vol-description-td'}>{mc.material_desc}</div>
                                        <div className={'tabel-header vol-td'}>{mc.new_weight}</div>
                                        <div className={'tabel-header vol-td'}>{mc.material_type}</div> 
                                        <div className={'tabel-header vol-td'}>{mc.pack_size}</div> 
                                        <div className={'tabel-header vol-td'}>{mc.packing_uom}</div> 
                                        <div className={'tabel-header vol-td'}>{mc.gross_weight}</div> 
                                        <div className={'tabel-header vol-td'}>{mc.weight_for_scd}</div> 
                                        <div className={'tabel-header vol-td'}>{mc.tolerance_qty}</div>
                                        <div className={'tabel-header vol-td vol-branch-td'}>{mc.depot_code}</div>   
                                    <div className={'tabel-header vol-act'}> 
                                        <div className={'action-icon'} style={{backgroundImage: `url(${Edit})`}} onClick={() => this.editMC(mc)} />
                                        <div className={'action-icon'} style={{backgroundImage: `url(${Delete})`}} onClick={() => this.deleteMC(mc)} />
                                    </div>
                            </div>)})
                        }
                       
                </table>
                <div className="submit-section-pagination">
                    <div className="next">
                        <Button value="Next" click={this.next} disabled={(Array.isArray(volumetricMaster))&&volumetricMaster.length<this.state.limit} />
                    </div>
                    <div className="pre">
                        <Button value="Previous" disabled={this.state.offset===0} click={this.previous} />
                    </div>
                </div>
                {this.state.isOpen && 
                <AddVolumetric  toggleAdd={this.toggleAdd} onSave={this.onSave} companyId={this.props.companyId} editData={this.state.editData} onUpdate={this.onUpdate} packUOMdropdown={packUOMdropdown} 
                fetchDepotName = {DepotName}
                />
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        plantsDepots: state.plantsDepots|| [],       
        home: state.home,
        packUOMdropdown: state.packUOMdropdown,
        fetchDepotName: state.fetchDepotName
    };
};

export default connect(mapStateToProps)(withRouter(Volumetric));
