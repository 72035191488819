import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import Select from 'react-select';
import InputText from '../../../common/components/input-text/input-text';
import { chainType } from '../../../../utils/constants';
import Delete from '../../../../assets/images/delete.svg';
import Add from '../../../../assets/images/add.svg';
import { decimalNumber, numeric } from '../../../../utils/validate'
import Button from '../../button-classic/button-classic';
import ACTION from "../../../common/action_constant";
import { getCookie } from "../../../../utils/cookies";
import { ABFRL_COMPANY_IDS, PANTALOONS_COMPANY_IDS } from "../../../../utils/common";

import './add-freight-rate.less'
const zoneObj = {
   from_zone_id: '',
   to_zone_id: '',
   rate: ''
}

const slab_rate = {
   rate: '',
   min_applicable_weight: "0",
   max_applicable_weight: "0"
}

const typesArray = [{
   value: 'Actual', label: 'Actual'
},
{
   value: 'Volumetric', label: 'Volumetric'
}]

class FreightRate extends Component {
   constructor(props) {
      super(props);
      this.state = {
         categories: [],
         vendors: [],
         modes: [],
         data: {
            vendor_id: props.editFreightData ? props.editFreightData.vendor_id : '',
            category_id: props.editFreightData ? props.editFreightData.category_id : '',
            type: props.editFreightData ? props.editFreightData.type : '',
            mode: props.editFreightData ? props.editFreightData.mode : '',
            weight_remarks: props.editFreightData ? props.editFreightData.weight_remarks : '',
            min_docket_charge: props.editFreightData ? props.editFreightData.min_docket_charge : '',
            min_docket_remarks: props.editFreightData ? props.editFreightData.min_docket_remarks : '',
            chain_type: props.editFreightData ? props.editFreightData.chain_type : '',
            transporter: props.editFreightData ? props.editFreightData.transporter.length > 0 && props.editFreightData.transporter.map(val => { return { label: val.company_name, value: val.id } }) : [],
            slab: props.editFreightData ? props.editFreightData.slab : [{
               rate: '',
               min_applicable_weight: '',
               max_applicable_weight: ''
            }],
            min_chargeable_weight: props.editFreightData ? props.editFreightData.min_chargeable_weight : '',
            // max_applicable_weight: props.editFreightData ? props.editFreightData.max_applicable_weight : '',
            // min_applicable_weight: props.editFreightData ? props.editFreightData.min_applicable_weight : '',
            zone_rates: props.editFreightData ? props.editFreightData.zone_rates :
               [{
                  from_zone_id: '',
                  to_zone_id: '',
                  rate: ''
               }]
         },
         zoneOptions: props.zones && props.zones.length > 0 ? props.zones.map(val => { return { label: val.name, value: val.id } }) : []
      }
   }

   componentWillMount() {
      this.getVendorsData();
      this.getModeTypesData();
   }

   componentWillReceiveProps(nextProps) {
      const { dataGovernance } = this.props;
      let newCategories = [];
      let newModes = [];
      let newVendors = [];

      if (dataGovernance.vendorTypes != undefined && dataGovernance.modeTypes != undefined) {
         const { vendorTypes, modeTypes } = dataGovernance;
         if (vendorTypes.length > 0 && this.state.categories.length == 0) {
            newCategories = Object.values(vendorTypes.reduce((acc, cur) => Object.assign(acc, { [cur.company_category_id]: cur }), {}))
            this.setState({
               categories: newCategories.map(el => ({ value: el.company_category_id, label: el.company_category })),
            });
         }
         if (vendorTypes.length > 0 && this.state.vendors.length == 0) {
            this.setState({
               vendors: vendorTypes,
            });
         }
         if (modeTypes.length > 0 && this.state.modes.length == 0) {
            newModes = Object.values(modeTypes.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {}))
            this.setState({
               modes: newModes.map(el => ({ ...el, selected: false })),
            });
         }
      }
   }

   onChangeHandler = (value, field) => {
      if (decimalNumber(value) || value == '') {
         const { data } = this.state;
         data[field] = value;
         this.setState({ data });
      }
   }

   onSave = () => {
      const { data } = this.state;
      const { mainTabOption } = this.props;
      data.transporter = data.transporter.map((val) => { return { company_name: val.label, id: val.value } });
      data.zone_rates = [3, 4].indexOf(mainTabOption) > -1 ? [] : data.zone_rates;
      this.props.addCreditDetails(data);
      this.props.toggleAdd(14);
   }

   onUpdate = () => {
      const { data } = this.state;
      const { editFreightData, mainTabOption } = this.props;
      data.transporter = data.hasOwnProperty('transporter') && data.transporter != '' ? data.transporter.map((val) => { return { company_name: val.label, id: val.value } }) : [];
      data.credit_master_id = editFreightData.credit_master_id;
      data.company_id = editFreightData.company_id;
      data.is_active = true;
      data.mode_type = editFreightData.mode_type;
      data.load_type = editFreightData.load_type;
      data.type = editFreightData.type;
      data.zone_rates = [3, 4].indexOf(mainTabOption) > -1 ? [] : data.zone_rates;
      this.props.updateCreditDetails(data, 14);
      this.props.toggleAdd(14);
   }

   onAddZoneRate = () => {
      const { data } = this.state;
      const row = Object.assign({}, zoneObj);
      data.zone_rates.push(row);
      this.setState({ data });
   }

   onDeleteZoneRate = (index) => {
      const { data } = this.state;
      data.zone_rates.splice(index, 1);
      this.setState({ data });
   }

   onTransporterSearch = (value) => {
      if (value) {
         this.props.getTransporterList(value);
      }
   }

   onAddSlabs = () => {
      const { data } = this.state;
      const row = Object.assign({}, slab_rate);
      data.slab.push(row);
      this.setState({ data });
   }

   onDeleteSlabs = (index) => {
      const { data } = this.state;
      data.slab.splice(index, 1);
      this.setState({ data });
   }

   onChangeSlab = (index, value, field) => {
      if (decimalNumber(value) || value == '') {
         const { data } = this.state;
         data.slab[index][field] = value;
         this.setState({ data });
      }
   }

   validateVal = (val) => {
      return val.toString().match(/^\d{1,8}(\.\d{1,3})?$/)
   }

   validData = ({ data } = this.state) => {
      let check, check1;
      //return (data.chain_type && data.transporter.length !== 0 && this.validateVal(data.min_chargeable_weight) && (data.slab && data.slab.every((data, i) => (check = (data.min_applicable_weight[i] && data.max_applicable_weight[i] && data.rate[i] !== null && data.rate !== '' ))) || data.zone_rates && data.zone_rates.every((data, i) => (check1 = (data.from_zone_id && data.to_zone_id && data.rate[i]!==null && data.rate!=='' )))));
      return (data.chain_type && data.transporter.length !== 0 && this.validateVal(data.min_chargeable_weight));
   }

   validAbData = ({ data } = this.state) => {
      return (data.vendor_id && data.category_id !== '' && this.validateVal(data.min_chargeable_weight) && data.mode !== '' && data.type !== '' && data.weight_remarks);
   }

   getModeTypesData = () => {
      const { dispatch } = this.props;
      dispatch({
         type: ACTION.DATA_GOVERNANCE.GET_MODE_TYPES,
         onSuccess: ((data) => {
            this.setState({ modes: data })
         })
         // params: 
      })
   }

   getVendorsData = () => {
      const { dispatch } = this.props;
      dispatch({
         type: ACTION.DATA_GOVERNANCE.GET_VENDORS_TYPES,
         onSuccess: ((data) => {
            const newCategories = Object.values(data.reduce((acc, cur) => Object.assign(acc, { [cur.company_category_id]: cur }), {}))
            this.setState({
               categories: newCategories.map(el => ({ value: el.company_category_id, label: el.company_category })),
               vendors: data
            });
         })
      })
   }

   render() {
      const { data, zoneOptions, vendors, modes, categories } = this.state;
      const { transporterList, mainTabOption } = this.props;
      let check , check1;
      const company_id = JSON.parse(getCookie("user")).company_id;
      const disable = !(ABFRL_COMPANY_IDS.includes(company_id) || PANTALOONS_COMPANY_IDS.includes(company_id)) ? this.validData() : this.validAbData();
      console.log(company_id)
      return (
         <div className="add-company-wrap add-provider-root add-row add-freight-rate">
            <div onClick={this.props.toggleAdd} className="overlay">
               <div className="modal-popup" onClick={(e) => { e.stopPropagation() }}>
                  <div className="heading">
                     {'Add Freight Rate'}
                  </div>
                  {
                     !(ABFRL_COMPANY_IDS.includes(company_id) || PANTALOONS_COMPANY_IDS.includes(company_id)) ? (
                        <form className="form-section" autoComplete="nopes">
                           <div className="select-wrap">
                              <div className="label">Chain Type</div>
                              <Select className={'select'}
                                 value={chainType.find(val => { return val.label === this.state.data.chain_type })}
                                 options={chainType}
                                 onChange={(options) => {
                                    const { data } = this.state;
                                    data.chain_type = options.label;
                                    this.setState({ data });
                                 }} />
                           </div>
                           <div className="select-wrap">
                              <div className="label">Transporter</div>
                              <Select className={'select'}
                                 isMulti
                                 options={transporterList ? transporterList.map(val => {
                                    return { label: val.company_name, value: val.id }
                                 }) : []}
                                 value={data.transporter}
                                 onChange={(options) => {
                                    const { data } = this.state;
                                    data.transporter = options;
                                    this.setState({ data });
                                 }}
                                 onInputChange={this.onTransporterSearch}
                              />
                           </div>
                           <InputText placeholder={'Min. Chargeable Weight (KG)'} label={'Min. Chargeable Weight (KG)'} id={'min_chargeable_weight'} value={data.min_chargeable_weight} changeHandler={this.onChangeHandler} type={'number'} />
                           {/* {[3, 4].indexOf(mainTabOption) > -1 && data.slab.map((slabDetail, index) => {
                        return (<Fragment key={index}>
                           <InputText placeholder={'Min. Applicable Weight (kg)'} label={'Min. Applicable Weight (kg)'} id={'min_applicable_weight'} value={slabDetail.min_applicable_weight} changeHandler={this.onChangeSlab.bind(this, index)} type={'number'} />
                           <InputText placeholder={'Max. Applicable Weight (kg)'} label={'Max. Applicable Weight (kg)'} id={'max_applicable_weight'} value={slabDetail.max_applicable_weight} changeHandler={this.onChangeSlab.bind(this, index)} type={'number'} />
                           <div className={'select-wrap zone-rate'}>
                              <InputText placeholder={'Slab (rate/kg)'} label={'Slab (rate/kg)'} id={'rate'} value={slabDetail.rate} changeHandler={this.onChangeSlab.bind(this, index)} type={'number'} />
                              {data.slab.length > 1 && <div className="button-add" style={{ 'minWidth': '43px' }} onClick={() => this.onDeleteSlabs(index)}>
                                 X
                                    </div>}
                           </div>
                        </Fragment>)
                     })
                     }
                     {[3, 4].indexOf(mainTabOption) > -1 && <div className="button-add" onClick={this.onAddSlabs}>+ ADD</div>} */}

                           {[3, 4].indexOf(mainTabOption) === -1 && data.zone_rates.map((zoneRate, index) => {
                              return (<div key={index}>
                                 <div className={'select-wrap'}>
                                    <Select
                                       className={'select'}
                                       placeholder={'Select From Zone'}
                                       options={zoneOptions}
                                       value={zoneOptions.find((val) => { return val.value === zoneRate.from_zone_id })}
                                       onChange={(options) => {
                                          const { data } = this.state;
                                          data.zone_rates[index]['from_zone_id'] = options.value;
                                          this.setState({ data });
                                       }}
                                    />
                                 </div>
                                 <div className={'select-wrap'}>
                                    <Select
                                       className={'select'}
                                       placeholder={'Select To Zone'}
                                       options={zoneOptions}
                                       value={zoneOptions.find((val) => { return val.value === zoneRate.to_zone_id })}
                                       onChange={(options) => {
                                          const { data } = this.state;
                                          data.zone_rates[index]['to_zone_id'] = options.value;
                                          this.setState({ data });
                                       }}
                                    />
                                 </div>
                                 <div className={'select-wrap zone-rate'}>
                                    <InputText
                                       placeholder={'Rate/KG'}
                                       id={'rate'}
                                       value={zoneRate.rate}
                                       type={'number'}
                                       changeHandler={(value, field) => {
                                          const { data } = this.state;
                                          data.zone_rates[index][field] = value;
                                          this.setState({ data });
                                       }}
                                    />
                                    {data.zone_rates.length > 1 && <div className="button-add" style={{ 'minWidth': '43px' }} onClick={() => this.onDeleteZoneRate(index)}>
                                       X
                                        </div>}
                                 </div>
                                 {data.zone_rates.length - 1 === index && <div className="button-add" onClick={this.onAddZoneRate}>
                                    + ADD
                                    </div>}
                              </div>)
                           })}
                        </form>
                     ) : (
                           <form className="form-section" autoComplete="nopes">
                              <div className="select-wrap w-p-25">
                                 <div className="label">Vendor Company Name</div>
                                 <Select
                                    className="select w-200"
                                    options={vendors}
                                    value={vendors.filter(el => el.value == this.state.data.vendor_id) || null}
                                    onChange={(selectedOption) => {
                                       const { data } = this.state;
                                       data.vendor_name = selectedOption.label;
                                       data.vendor_id = selectedOption.value;
                                       data.company_category = selectedOption.company_category;
                                       data.company_category_id = selectedOption.company_category_id;
                                       data.category_id = selectedOption.company_category_id;
                                       this.setState({ data });
                                    }}
                                 />
                              </div>
                              <div className="select-wrap w-p-25">
                                 <div className="label">Category</div>
                                 <Select
                                    className="select w-200"
                                    options={categories}
                                    value={categories.filter(el => el.value == this.state.data.category_id) || null}
                                    onChange={(selectedOption) => {
                                       const { data } = this.state;
                                       data.category_id = selectedOption.value;
                                       this.setState({ data });
                                    }}
                                 />
                              </div>
                              <div className="select-wrap w-p-25">
                                 <div className="label">Mode</div>
                                 <Select
                                    className="select w-200"
                                    options={modes}
                                    value={modes.filter(el => el.value == this.state.data.mode) || null}
                                    onChange={(selectedOption) => {
                                       const { data } = this.state;
                                       data.mode = selectedOption.value;
                                       data.mode_name = selectedOption.label;
                                       this.setState({ data });
                                    }}
                                 />
                              </div>
                              <div className="select-wrap w-p-25">
                                 <div className="label">Type</div>
                                 <Select
                                    className="select w-200"
                                    options={typesArray}
                                    value={typesArray.filter(el => el.value == this.state.data.type) || null}
                                    onChange={(selectedOption) => {
                                       const { data } = this.state;
                                       data.type_name = selectedOption.label;
                                       data.type = selectedOption.value;
                                       this.setState({ data });
                                    }}
                                 />
                              </div>
                              <div className={'select-wrap'}>
                                 <div className="label">Min chargeable weight *</div>
                                 <div className={'inline-flex'}>
                                    <InputText
                                       placeholder={'rate'}
                                       id={'rate'}
                                       value={this.state.data.min_chargeable_weight}
                                       type={'number'}
                                       changeHandler={(value, field) => {
                                          const { data } = this.state;
                                          data.min_chargeable_weight = value;
                                          this.setState({ data });
                                       }}
                                    />
                                    {
                                       this.state.data.type == 'Volumetric' ? <div className="m-t-10">
                                          * (Volumetric weight)
                                    </div> : <div className="m-t-10">(per Kg)</div>
                                    }
                                 </div>
                              </div>
                              <div className={'select-wrap w-p-50'}>
                                 <div className="label">Weight remarks *</div>
                                 <InputText
                                    style={{ width: '100%' }}
                                    placeholder={'Weight remarks *'}
                                    id={'weight-remarks'}
                                    value={this.state.data.weight_remarks}
                                    type={'text'}
                                    changeHandler={(value, field) => {
                                       const { data } = this.state;
                                       data.weight_remarks = value;
                                       this.setState({ data });
                                    }}
                                 />
                              </div>
                              <div className={'select-wrap'}>
                                 <div className="label">Min Docket Charge</div>
                                 <InputText
                                    placeholder={'Min Docket Charge'}
                                    id={'weight-remarks'}
                                    value={this.state.data.min_docket_charge}
                                    type={'text'}
                                    changeHandler={(value, field) => {
                                       const { data } = this.state;
                                       data.min_docket_charge = value;
                                       this.setState({ data });
                                    }}
                                 />
                              </div>
                              <div className={'select-wrap'}>
                                 <div className="label">Min Docket remarks</div>
                                 <InputText
                                    placeholder={'Min Docket Remarks'}
                                    id={'weight-remarks'}
                                    value={this.state.data.min_docket_remarks}
                                    type={'text'}
                                    changeHandler={(value, field) => {
                                       const { data } = this.state;
                                       data.min_docket_remarks = value;
                                       this.setState({ data });
                                    }}
                                 />
                              </div>
                           </form>
                        )
                  }
                  <div className="submit-section submit-area">
                     {

                        <div className="button-section">
                           {!this.props.editFreightData && <Button className="add-button" click={this.onSave} disabled={!disable} value="Save" />}
                           {this.props.editFreightData && <Button className="add-button" click={this.onUpdate} disabled={!disable} value="Update" />}
                        </div>
                     }
                     <div className="button-section" >
                        <div className="add-button" onClick={this.props.toggleAdd}>Close</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      )
   }
}

const mapStateToProps = state => {
   return {
      dataGovernance: state.dataGovernance,
   };
};

export default connect(mapStateToProps)(FreightRate);