import React, { Component, Fragment } from "react";
import "./hsd-lanes-popup.less";
import Edit from "../../../assets/images/edit.svg";
import Save from "../../../assets/images/save.svg";
import Cancel from "../../../assets/images/cancel.svg";
import { SingleDatePicker } from "react-dates";
import moment from "moment";

class HsdDetailsEditPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vendor: {},
      selectedHSDLanes: this.props.selectedHSDLanes || [],
      isAllSelect: false,
    };
  }

  render() {
    const { hsdLaneDetailsEditData } = this.props;
    return (
      <Fragment>
        <div className="add-user-wrap">
          <div className="overlay"></div>
          <div id="modal" className="modal-popup" style={{ width: "95%" }}>
            <div className=" flex">
              <button
                className={"close-button fRight"}
                onClick={() => this.props.closeHsdDetailsPopup()}
              >
                Close
              </button>
            </div>

            <div className="content p-t-0">
              <table className="divTable company-list-wrap mr-20">
                <thead
                  className="list-section table-header divTableRow"
                  style={{ backgroundColor: "#e9f2ff" }}
                >
                  <th className="divTableCell tCenter" style={{ width: "10%" }}>
                    {" "}
                    Zone{" "}
                  </th>
                  <th className="divTableCell tCenter" style={{ width: "10%" }}>
                    {" "}
                    Route Code{" "}
                  </th>
                  <th className="divTableCell tCenter" style={{ width: "10%" }}>
                    {" "}
                    Contract Start Date{" "}
                  </th>
                  <th className="divTableCell tCenter" style={{ width: "10%" }}>
                    {" "}
                    Contract End Date{" "}
                  </th>
                  <th className="divTableCell tCenter" style={{ width: "10%" }}>
                    {" "}
                    Zone Average{" "}
                  </th>
                  <th className="divTableCell tCenter" style={{ width: "10%" }}>
                    {" "}
                    HSD Grace{" "}
                  </th>
                  <th className="divTableCell tCenter" style={{ width: "10%" }}>
                    {" "}
                    Mileage{" "}
                  </th>
                  <th className="divTableCell tCenter" style={{ width: "10%" }}>
                    {" "}
                    Carrying Capacity{" "}
                  </th>
                  <th className="divTableCell tCenter" style={{ width: "10%" }}>
                    {" "}
                    Transporter Code{" "}
                  </th>
                  <th className="divTableCell tCenter" style={{ width: "10%" }}>
                    {" "}
                    Rtkm{" "}
                  </th>
                  <th className="divTableCell tCenter" style={{ width: "10%" }}>
                    {" "}
                    Minimum Guarantee{" "}
                  </th>
                  <th className="divTableCell tCenter" style={{ width: "10%" }}>
                    {" "}
                    Actions{" "}
                  </th>
                </thead>
                <tbody className="divTableBody">
                  {(hsdLaneDetailsEditData || []).map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className="list-section divTableRow hsd-checkbox"
                      >
                        <td className="divTableCell tCenter">
                          {this.props.hsdEditIndex === index ? (
                            <input
                              type="text"
                              name="zone"
                              value={this.props.HSDEditLineitem.zone}
                              onChange={this.props.handleHSDDetailsChange}
                              style={{ width: "100%" }}
                            />
                          ) : (
                            item.zone
                          )}
                        </td>
                        <td className="divTableCell tCenter">
                          {this.props.hsdEditIndex === index ? (
                            <input
                              type="text"
                              name="route_code"
                              value={this.props.HSDEditLineitem.route_code}
                              onChange={this.props.handleHSDDetailsChange}
                              style={{ width: "100%" }}
                            />
                          ) : (
                            item.route_code
                          )}
                        </td>
                        <td className="tCenter">
                          <div>
                            {this.props.hsdEditIndex === index ? (
                              <SingleDatePicker
                                noBorder={true}
                                displayFormat={"DD/MMM/YYYY"}
                                hideKeyboardShortcutsPanel={true}
                                placeholder={"Date"}
                                numberOfMonths={1}
                                date={
                                  this.props.HSDEditLineitem
                                    .contract_start_date &&
                                  moment(
                                    this.props.HSDEditLineitem
                                      .contract_start_date,
                                  )
                                }
                                onDateChange={(date) => {
                                  this.props.handleHsdDateChange(
                                    date,
                                    "contract_start_date",
                                  );
                                }}
                                focused={this.props.contract_start_date_focused}
                                onFocusChange={
                                  ({ focused }) => {
                                    this.props.handledatefocuschange1(focused);
                                  }
                                  // ({ focused }) => this.setState({ focusedFssaiStart: focused })
                                }
                                isOutsideRange={() => false}
                              />
                            ) : (
                              moment(item.contract_start_date).format(
                                "DD/MM/YYYY",
                              )
                            )}
                          </div>
                        </td>
                        <td className="tCenter">
                          {this.props.hsdEditIndex === index ? (
                            <SingleDatePicker
                              noBorder={true}
                              displayFormat={"DD MMM YYYY"}
                              hideKeyboardShortcutsPanel={true}
                              placeholder={"Date"}
                              numberOfMonths={1}
                              date={
                                (this.props.HSDEditLineitem.contract_end_date &&
                                  moment(
                                    this.props.HSDEditLineitem
                                      .contract_end_date,
                                  )) ||
                                null
                              }
                              onDateChange={(date) => {
                                this.props.handleHsdDateChange(
                                  date,
                                  "contract_end_date",
                                );
                              }}
                              focused={this.props.contract_end_date_focused}
                              onFocusChange={({ focused }) => {
                                this.props.handledatefocuschange2(focused);
                              }}
                              isOutsideRange={() => false}
                            />
                          ) : (
                            moment(item.contract_end_date).format("DD/MM/YYYY")
                          )}
                        </td>
                        <td className="divTableCell tCenter">
                          {this.props.hsdEditIndex === index ? (
                            <input
                              type="text"
                              name="zone_average"
                              value={this.props.HSDEditLineitem.zone_average}
                              onChange={this.props.handleHSDDetailsChange}
                              style={{ width: "100%" }}
                            />
                          ) : (
                            item.zone_average
                          )}
                        </td>
                        <td className="divTableCell tCenter">
                          {this.props.hsdEditIndex === index ? (
                            <input
                              type="text"
                              name="hsd_grace"
                              value={this.props.HSDEditLineitem.hsd_grace}
                              onChange={this.props.handleHSDDetailsChange}
                              style={{ width: "100%" }}
                            />
                          ) : (
                            item.hsd_grace
                          )}
                        </td>
                        <td className="divTableCell tCenter">
                          {this.props.hsdEditIndex === index ? (
                            <input
                              type="text"
                              name="mileage"
                              value={this.props.HSDEditLineitem.mileage}
                              onChange={this.props.handleHSDDetailsChange}
                              style={{ width: "100%" }}
                            />
                          ) : (
                            item.mileage
                          )}
                        </td>
                        <td className="divTableCell tCenter">
                          {this.props.hsdEditIndex === index ? (
                            <input
                              type="text"
                              name="carrying_capacity"
                              value={
                                this.props.HSDEditLineitem.carrying_capacity
                              }
                              onChange={this.props.handleHSDDetailsChange}
                              style={{ width: "100%" }}
                            />
                          ) : (
                            item.carrying_capacity
                          )}
                        </td>
                        <td className="divTableCell tCenter">
                          {this.props.hsdEditIndex === index ? (
                            <input
                              type="text"
                              name="transporter_code"
                              value={
                                this.props.HSDEditLineitem.transporter_code
                              }
                              onChange={this.props.handleHSDDetailsChange}
                              style={{ width: "100%" }}
                            />
                          ) : (
                            item.transporter_code
                          )}
                        </td>
                        <td className="divTableCell tCenter">
                          {this.props.hsdEditIndex === index ? (
                            <input
                              type="text"
                              name="rtkm"
                              value={this.props.HSDEditLineitem.rtkm}
                              onChange={this.props.handleHSDDetailsChange}
                              style={{ width: "100%" }}
                            />
                          ) : (
                            item.rtkm
                          )}
                        </td>
                        <td className="divTableCell tCenter">
                          {this.props.hsdEditIndex === index ? (
                            <input
                              type="text"
                              name="minimum_guarantee"
                              value={
                                this.props.HSDEditLineitem.minimum_guarantee
                              }
                              onChange={this.props.handleHSDDetailsChange}
                              style={{ width: "100%" }}
                            />
                          ) : (
                            item.minimum_guarantee
                          )}
                        </td>

                        <td>
                          {this.props.hsdEditIndex === index ? (
                            <div className="flex">
                              <div
                                className={"action-icon"}
                                style={{ backgroundImage: `url(${Save})` }}
                                onClick={this.props.handleSubmitHSDDetails}
                              />
                              <div
                                className={"action-icon"}
                                style={{ backgroundImage: `url(${Cancel})` }}
                                onClick={this.props.handleHsdEditCancel}
                              />
                            </div>
                          ) : (
                            <div
                              className={"action-icon"}
                              style={{ backgroundImage: `url(${Edit})` }}
                              onClick={() =>
                                this.props.handleHSDDetailsEdit(index, item)
                              }
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default HsdDetailsEditPopup;
