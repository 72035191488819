import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import "./dph-amendment.less";
import MainComponent from "../../home/main/main";
import Select from "react-select";
import UpArrow from "../../../assets/images/incr.svg";
import DownArrow from "../../../assets/images/decr.svg";
import Save from "../../../assets/images/save.svg";
import Cancel from "../../../assets/images/cancel.svg";
import Edit from "../../../assets/images/edit.svg";
import { getCookie } from "../../../utils/cookies";
import {
  ABFRL_COMPANY_IDS,
  getUserCompanyId,
  LR_FOR_PANTALOON_COMPANY_ID,
  PANTALOONS_COMPANY_IDS,
} from "../../../utils/common";
import DatePicker from "react-datepicker";
import Moment from "moment";
import CheckBox from "./../../components/checkbox/checkbox";
import ACTION from "../../common/action_constant";
import Loader from "../../common/components/loader/loader";
import MSGPopUp from "../../common/components/msg-popup/msg-popup";
import { withRouter } from "react-router";
import history from "../../history";
import DphAmendmentDetails from "./dph-amendment-details";

var downloadBtn;
var uploadExcel;

const RATE_TYPES = [
  { label: "PAISA", value: "PAISA", id: 1 },
  { label: "PERCENTAGE", value: "PERCENTAGE", id: 1 },
];

const InputBox = (props) => {
  return (
    <input
      className="input-text"
      type={props.type || "text"}
      id={props.id}
      placeholder={props.placeholder}
      value={props.value}
      onChange={(e) => props.changeHandler(e.target.value, props.id)}
    />
  );
};

const defaultData = {
  category_id: "",
  bound_type: "",
  vendor_id: "",
  vendor_name: "",
  dropdown_id: "",
  dropdown_name: "",
  vendor_code: "",
  seeker_id: "",
  mode_id: "",
  mode_type: "",
  base_region: "",
  validate_to_date: null,
  validate_from_date: null,
  my_rate: "",
  diesel_rate: "",
  freight_rate: "",
  freight_increment: "",
  lane: [],
};
const DphAmendment = (props) => {
  const [actionRow, setActionRow] = useState(false);
  const [rowData, setRowData] = useState(defaultData);
  const [zonesArray, setZonesArray] = useState([]);
  const [lanesArray, setLanesArray] = useState([]);
  const [selectedRmIds, setSelectedRmIds] = useState([]);
  const [showLanesPopup, setShowLanesPopup] = useState(false);
  const [showError, setShowError] = useState(false);
  // to be handled from props
  const [distribution_mode_name, set_distribution_mode_name] = useState("");
  const { dispatch, dataGovernance, loader, msgpopup } = props;
  const {
    rateMastersData,
    vendorTypes,
    modeTypes,
    regionsData,
    rapidRates,
    dailyDPHData,
  } = dataGovernance;
  const [downloadLink, setDownloadLink] = useState("");
  const [distributionTypes, setDistributionType] = useState([]);
  const [company_id, set_company_id] = useState(
    JSON.parse(getCookie("user")).company_id
  );
  // const [isGenerate, setGenerate] = useState(true);
  // const [isApproved, setApproved] = useState(true);
  // console.log("isGenerate", isGenerate);

  const [isEditing, setIsEditing] = useState("");
  const [editData, setEditData] = useState({ my_rate: "", lanes: [] });

  const [selectedMonth, setSelectedMonth] = useState(new Date());

  const toggleEdit = (field, index) => {
    setIsEditing(isEditing === field ? "" : field);
  };

  const handleChange = (field, value, index) => {
    if (field === "my_rate") {
      setEditData((prevState) => ({ ...prevState, my_rate: value }));
    } else {
      setEditData((prevState) => {
        const updatedLanes = [...prevState.lanes];
        if (!updatedLanes[index]) {
          updatedLanes[index] = {};
        }
        updatedLanes[index] = {
          ...updatedLanes[index],
          [field]: value,
        };
        return { ...prevState, lanes: updatedLanes };
      });
    }
  };

  const handleDateChange = (event) => {
    const selectedMonth = event.target.value;
    setSelectedMonth(new Date(selectedMonth));
  };

  const formatDateForInput = () => {
    const year = selectedMonth.getFullYear();
    const month = String(selectedMonth.getMonth() + 1).padStart(2, "0");
    return `${year}-${month}`;
  };

  const currentMonth = new Date();

  const BOUND_TYPES = [
    {
      label: "Inbound",
      value: 1,
    },
    {
      label: "Outbound",
      value: 2,
    },
  ];

  const isPantaloons = LR_FOR_PANTALOON_COMPANY_ID.includes(getUserCompanyId());

  const Pantaloons = PANTALOONS_COMPANY_IDS.includes(getUserCompanyId());

  const isAbfrl = ABFRL_COMPANY_IDS.includes(getUserCompanyId());

  const [months, setMonths] = useState([
    new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0),
    new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 0, 0), //.getMonth() + 1,
    new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 0), //.getMonth() + 1,
    new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 2, 0), //.getMonth() + 1,
    new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 3, 0), //.getMonth() + 1,
    new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 4, 0), //.getMonth() + 1,
  ]);

  useEffect(() => {
    getDieselRates();
    getVendors();
    if (!Pantaloons) {
      getModes();
    }
    getRegionsData();
    getRateMastersData();
    if (isPantaloons) {
      getDailyDPH();
    }
    if (Pantaloons) {
      getPantaloonModes();
      getAllDropdownData();
    }
  }, []);

  useEffect(() => {}, [vendorTypes]);

  useEffect(() => {}, [modeTypes]);

  useEffect(() => {
    let zonesData = [];

    if (isPantaloons) {
      (dailyDPHData || []).map((zone) => {
        zonesData.push({
          label: zone.zone_name,
          value: zone.base_region,
        });
      });
    } else {
      if (Object.keys(regionsData).length > 0) {
        let panIndia = {
          value: "pan_india",
          label: `Pan India BR: ${
            (regionsData["pan_india"] || {})["new_rate"]
          }`,
        };
        zonesData.push(panIndia);

        let east = {
          value: "east",
          label: `East BR: ${(regionsData["east"] || {})["new_rate"]}`,
        };
        zonesData.push(east);

        if (regionsData["mumbai"]) {
          let mumbai = {
            value: "mumbai",
            label: `Mumbai BR: ${(regionsData["mumbai"] || {})["new_rate"]}`,
          };
          zonesData.push(mumbai);
        }

        let west = {
          value: "west",
          label: `West BR: ${(regionsData["west"] || {})["new_rate"]}`,
        };
        zonesData.push(west);

        let north = {
          value: "north",
          label: `North BR: ${(regionsData["north"] || {})["new_rate"]}`,
        };
        zonesData.push(north);

        let south = {
          value: "south",
          label: `South BR: ${(regionsData["south"] || {})["new_rate"]}`,
        };
        zonesData.push(south);

        let north_east = {
          value: "north_east",
          label: `North East BR: ${
            (regionsData["north_east"] || {})["new_rate"]
          }`,
        };
        zonesData.push(north_east);

        let bangalore = {
          value: "bangalore",
          label: `Bangalore BR: ${
            (regionsData["bangalore"] || {})["new_rate"]
          }`,
        };
        zonesData.push(bangalore);

        let bangalore_hyderabad = {
          value: "bangalore_hyderabad",
          label: `Bangalore-Hyderabad BR: ${
            (regionsData["bangalore_hyderabad"] || {})["new_rate"]
          }`,
        };
        zonesData.push(bangalore_hyderabad);

        let gurgaon = {
          value: "gurgaon",
          label: `Gurgaon BR: ${(regionsData["gurgaon"] || {})["new_rate"]}`,
        };
        zonesData.push(gurgaon);

        let delhi_bangalore = {
          value: "delhi_bangalore",
          label: `Delhi-Bangalore BR: ${
            (regionsData["delhi_bangalore"] || {})["new_rate"]
          }`,
        };
        zonesData.push(delhi_bangalore);
      }
    }

    setZonesArray(zonesData);
  }, [regionsData]);

  const getVendors = () => {
    const { dispatch } = props;
    dispatch({
      type: ACTION.DATA_GOVERNANCE.GET_RM_VENDORS_TYPES,
    });
  };

  const getPantaloonModes = () => {
    const { dispatch } = props;
    dispatch({
      type: ACTION.DATA_GOVERNANCE.GET_MODE_TYPES,
      params: `?type=Reconciliation`,
    });
  };

  const getModes = () => {
    const { dispatch } = props;
    dispatch({
      type: ACTION.DATA_GOVERNANCE.GET_RM_MODES_TYPES,
    });
  };

  const getRegionsData = () => {
    const { dispatch } = props;
    dispatch({
      type: ACTION.DATA_GOVERNANCE.GET_RM_REGIONS,
    });
  };

  const getAllDropdownData = () => {
    const { dispatch } = props;
    dispatch({
      type: ACTION.DATA_GOVERNANCE.GET_OTHERCHARGES_DROPDOWNLIST,
      onSuccess: (data) => {
        if (Object.keys(data).length > 0) {
          const modifiedTransporterList =
            data.distribution_type &&
            data.distribution_type.map((el) => {
              const newObj = { ...el };
              (newObj.label = el.dropdown_name),
                (newObj.value = el.dropdown_id),
                (newObj.name = el.dropdown_name),
                (newObj.id = el.dropdown_id);
              return newObj;
            });
          setDistributionType(modifiedTransporterList);
        }
      },
    });
  };

  const getRateMastersData = () => {
    const { dispatch } = props;
    dispatch({
      type: ACTION.DATA_GOVERNANCE.GET_RM_DATA,
      params: "?limit=100",
    });
  };

  const addRecord = () => {
    setActionRow(true);
  };

  const handleActionRow = (isShown) => {
    setRowData(defaultData);
    setActionRow(isShown);
    setShowError(false);
  };

  const downloadLanes = () => {
    const { dispatch } = props;
    dispatch({
      type: ACTION.DATA_GOVERNANCE.DOWNLOAD_RM_TEMPLATE,
      onSuccess: (data) => {
        setDownloadLink(data.link);
        downloadBtn.click();
      },
    });
  };

  const handleRmIdsSelection = (id) => {
    let selectedRmIdsCopy = [...selectedRmIds];
    if (selectedRmIdsCopy.includes(id)) {
      selectedRmIdsCopy.splice(id, 1);
    } else {
      selectedRmIdsCopy.push(id);
    }
    setSelectedRmIds(selectedRmIdsCopy);
  };

  const deleteRMData = () => {
    const { dispatch } = props;
    if (selectedRmIds.length == 0) {
      return;
    }
    dispatch({
      type: ACTION.DATA_GOVERNANCE.DELETE_RM_DATA,
      params: selectedRmIds,
      onSuccess: () => {
        setSelectedRmIds([]);
        getRateMastersData();
      },
    });
  };

  const handleSubmitForm = () => {
    if (
      !rowData.vendor_id ||
      !rowData.mode_id ||
      !rowData.base_region ||
      !rowData.diesel_rate ||
      !rowData.freight_rate
    ) {
      setShowError(true);
      return;
    } else {
      setShowError(false);
    }
    if (
      rowData.hasOwnProperty("rate_master_id") &&
      rowData.hasOwnProperty("awaiting_for_approval")
    ) {
      delete rowData["awaiting_for_approval"];
    }
    if (rowData.hasOwnProperty("rate_master_id")) {
      rowData.lane = undefined;
    }
    const { dispatch } = props;
    dispatch({
      type: rowData.hasOwnProperty("rate_master_id")
        ? ACTION.DATA_GOVERNANCE.UPDATE_RM_DATA
        : ACTION.DATA_GOVERNANCE.POST_RM_DATA,
      params: rowData,
      onSuccess: () => {
        handleActionRow(false);
        getRateMastersData();
      },
    });
  };

  const handleEditRow = (row) => {
    // debugger
    console.log("rows", row);
    setRowData(row);
    setActionRow(true);
  };

  const handleInputChange = (key, value) => {
    var tempValue = value;
    if (Moment.isDate(value)) {
      tempValue = Moment(value).format("DD/MM/YYYY");
    }
    setRowData({
      ...rowData,
      [key]: tempValue,
    });
  };

  const handleSelectInputChange = (key, selected, label_key) => {
    const data = {
      ...rowData,
      [key]: selected.value,
      [label_key]: selected.label,
    };
    if (key === "vendor_id") {
      data["seeker_id"] = selected.seeker_id;
      data["vendor_code"] = selected.sap_vendor_code;
    }
    setRowData(data);
  };
  const distributiontypeoptions = (row) => {
    if (row.distribution_type) {
      if (row.distribution_type === 1) {
        return "Primary(Vendors to DC)";
      }
      if (row.distribution_type === 2) {
        return "Secondary(DC to stores)";
      }
      if (row.distribution_type === 3) {
        return "Store to store";
      }
      if (row.distribution_type === 4) {
        return "Store to DC";
      }
      if (row.distribution_type === 5) {
        return "DC to DC";
      }
    } else {
      return "-";
    }
  };

  const getDieselRates = () => {
    const { dispatch } = props;
    dispatch({
      type: ACTION.DATA_GOVERNANCE.GET_RAPID_RATES,
    });
  };

  const onDistributionChange = (value) => {
    setdistributionDropdown(value);
  };

  const getDailyDPH = () => {
    const { dispatch } = props;
    const user = JSON.parse(getCookie("user"));
    dispatch({
      type: ACTION.DATA_GOVERNANCE.GET_DAILY_DIESEL_PRICE_HIKE,
      params: `?companyId=${user.company_id}`,
    });
  };

  const showLanes = (isAdd, vendor_id, mode_type, lanesArray, rowData) => {
    if (isAdd) {
      const { dispatch } = props;
      dispatch({
        type: ACTION.DATA_GOVERNANCE.GET_RM_LANES,
        // params: `?vendorId=${vendor_id}&modeId=${mode_id}`,
        params: `?vendorId=${vendor_id}&mode_type=${mode_type}`,
        onSuccess: (data) => {
          setLanesArray(data);
          setShowLanesPopup(true);

          setRowData({
            ...rowData,
            lane: data,
          });
        },
      });
    } else {
      setLanesArray(lanesArray);
      setRowData(rowData);
      setShowLanesPopup(true);
      // history.push(`/dphAmendmentDetails/`+ vendor_id)
      // setGenerate(true)
      // setApproved(true)
    }
  };

  const uploadRateMastersFile = (e) => {
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    const { dispatch } = props;
    dispatch({
      type: ACTION.DATA_GOVERNANCE.UPLOAD_RM_TEMPLATE,
      data: formData,
      onSuccess: () => {
        getRateMastersData();
      },
    });
  };

  const approveRates = (value, id) => {
    const user = JSON.parse(getCookie("user"));
    const { dispatch } = props;
    const year = selectedMonth.getFullYear();
    const month = selectedMonth.getMonth() + 1;
    dispatch({
      type: ACTION.DATA_GOVERNANCE.POST_MONTHLY_RATES,
      params: {
        rate_master_id: rowData.rate_master_id,
        is_approved: true,
        user_id: user.user_id,
        is_generate: false,
        is_amend: true,
        month: month,
        year: year,
      },
      onSuccess: () => {
        // getRateMastersData();
        // if (isAbfrl) {
        // setGenerate(false)
        // setApproved(false);
        // setShowLanesPopup(false);
        // }
      },
    });
  };
  const generateRates = (value, id) => {
    // setGenerate(true);
    const user = JSON.parse(getCookie("user"));
    const { dispatch } = props;
    const year = selectedMonth.getFullYear();
    const month = selectedMonth.getMonth() + 1;
    dispatch({
      type: ACTION.DATA_GOVERNANCE.POST_MONTHLY_RATES,
      params: {
        rate_master_id: rowData.rate_master_id,
        is_approved: false,
        is_generate: true,
        user_id: user.user_id,
        is_amend: true,
        month: month,
        year: year,
      },
      onSuccess: () => {
        // getRateMastersData();
        // if (isAbfrl) {
        // setGenerate(false);
        // setApproved(true);
        // setShowLanesPopup(false);
        // }
      },
    });
  };

  const getUpdatedDphAmendment = () => {
    const { dispatch } = props;
    const year = selectedMonth.getFullYear();
    const month = selectedMonth.getMonth() + 1;
    dispatch({
      type: ACTION.DATA_GOVERNANCE.GET_DPH_AMENDMENT_MONTHLY_RATES,
      params: `?rate_master_id=${rowData.rate_master_id}&month=${month}&year=${year}`,
      onSuccess: (data) => {
        console.log("dphUpdate", data);
        setLanesArray(data.lane);
        setRowData({
          ...rowData,
          my_rate: data.my_rate,
        });
      },
    });
  };

  const handleAmendSubmit = (lanesData, rowData, field) => {
    // console.log("lanesData", lanesData);
    // console.log("rowData", rowData);
    const { dispatch } = props;
    const user = JSON.parse(getCookie("user"));
    const year = selectedMonth.getFullYear();
    const month = selectedMonth.getMonth() + 1;

    const updatedLaneData = lanesData.map((lane, index) => {
      return {
        from: lane.from,
        to: lane.to,
        toregion: lane.toregion,
        tostate: lane.tostate,
        old_freight_rate: parseFloat(
          (editData.lanes[index] && editData.lanes[index].old_freight_rate) ||
            lane.old_freight_rate
        ),
        // new_freight_rate: parseFloat(
        //   (editData.lanes[index] && editData.lanes[index].new_freight_rate) ||
        //     lane.new_freight_rate
        // ),
      };
    });

    const postData = {
      rate_master_id: rowData.rate_master_id,
      user_id: user.userId,
      month: month,
      year: year,
      my_rate: parseFloat(editData.my_rate) || parseFloat(rowData.my_rate),
      lane: updatedLaneData,
    };
    dispatch({
      type: ACTION.DATA_GOVERNANCE.DPH_AMENDMENT_MONTHLY_RATES,
      params: postData,
      onSuccess: () => {
        getUpdatedDphAmendment(lanesData, rowData, year, month);
        setIsEditing(isEditing === field);
        // getRateMastersData();
        // if (isAbfrl) {
        //   setGenerate(false);
        //   setShowLanesPopup(false);
        // }
      },
    });
  };

  const onClickAmendSave = (lanesData, rowData) => {
    const { dispatch } = props;
    const user = JSON.parse(getCookie("user"));
    const year = selectedMonth.getFullYear();
    const month = selectedMonth.getMonth() + 1;

    const postData = {
      rate_master_id: rowData.rate_master_id,
      user_id: user.userId,
      month: month,
      year: year,
      my_rate: parseFloat(editData.my_rate) || parseFloat(rowData.my_rate),
      lane: lanesData,
    };
    dispatch({
      type: ACTION.DATA_GOVERNANCE.DPH_AMENDMENT_MONTHLY_RATES,
      params: postData,
      onSuccess: () => {
        getUpdatedDphAmendment(lanesData, rowData, year, month);
      },
    });
  };

  const closePopup = () => {
    setLanesArray([]);
    // setRowData(defaultData)
    setShowLanesPopup(false);
    // setActionRow(false);
    // setGenerate(true)
    // getRateMastersData();
  };

  const getDetailsDiv = () => {
    return Object.keys(rapidRates).length > 0 ? (
      <div className="details-block">
        <div className="m-b-5">
          <span className="pan-average-label">
            Pan India Average
            <span className="pan-average-value">
              {parseFloat(rapidRates.pan_india || "").toFixed(2)}
            </span>
          </span>
        </div>

        <div className="zone-average">Zone wise Average</div>
        <div className="zone-wise">
          <span className="zone-wise-label">
            Bangalore{" "}
            <span className="zone-wise-value">
              {" "}
              {parseFloat(rapidRates.bangalore || "").toFixed(2)}{" "}
            </span>
          </span>

          <span className="zone-wise-label">
            West{" "}
            <span className="zone-wise-value">
              {" "}
              {parseFloat(rapidRates.west || "").toFixed(2)}{" "}
            </span>
          </span>

          <span className="zone-wise-label">
            North{" "}
            <span className="zone-wise-value">
              {" "}
              {parseFloat(rapidRates.north || "").toFixed(2)}{" "}
            </span>
          </span>

          <span className="zone-wise-label value-highlight">
            South{" "}
            <span className="zone-wise-value">
              {" "}
              {parseFloat(rapidRates.south || "").toFixed(2)}{" "}
            </span>
          </span>
        </div>
        <div className="zone-wise">
          <span className="zone-wise-label">
            Bangalore-Hyderabad{" "}
            <span className="zone-wise-value">
              {" "}
              {parseFloat(rapidRates.bangalore_hyderabad || "").toFixed(2)}{" "}
            </span>
          </span>

          <span className="zone-wise-label">
            Gurgaon{" "}
            <span className="zone-wise-value">
              {" "}
              {parseFloat(rapidRates.gurgaon || "").toFixed(2)}{" "}
            </span>
          </span>
        </div>
      </div>
    ) : (
      ""
    );
  };

  const getRegionDiv = (region, rowItem) => {
    return Object.keys(regionsData).length > 0 ? (
      <Fragment>
        <div>
          {regionsData[region] != undefined &&
          regionsData[region].hasOwnProperty("label")
            ? regionsData[region]["label"]
            : isPantaloons && region === "gurgaon_only"
            ? "GURGAON"
            : region.toUpperCase()}
        </div>
        <div>
          <div style={{ color: "grey" }}> BR: {rowItem.my_rate || ""} </div>
          {!isPantaloons ? (
            <div style={{ color: "black" }}>
              {" "}
              New: {(regionsData[region] || {})["new_rate"]}
            </div>
          ) : (
            <div style={{ color: "black" }}>
              {" "}
              New: {rowItem.daily_diesel_rate}
            </div>
          )}
        </div>
        <div>
          {/*Diff. : { Number(diff(regionsData[region]['old_rate'], regionsData[region]['new_rate']).toFixed(3)) } */}
          Diff. : {rowItem.rate_diff}
          <div
            className={"indicate-icon"}
            style={{
              backgroundImage: `url(${
                rowItem.is_rate_diff_inc ? UpArrow : DownArrow
              })`,
            }}
          />
        </div>
      </Fragment>
    ) : (
      "No data found"
    );
  };

  const getBaseRate = (region) => {
    return Object.keys(regionsData).length > 0
      ? regionsData[region]["base_rate"]
      : "No data found";
  };

  const diff = (a, b) => (a > b ? a - b : b - a);

  const renderActionRow = () => {
    return (
      <div className="list-section content divTableRow">
        <div className="divTableCell select-row" />
        <div className="divTableCell">
          <Select
            className="select"
            options={vendorTypes}
            value={vendorTypes.filter((el) => el.value == rowData.vendor_id)}
            onChange={(value) =>
              handleSelectInputChange("vendor_id", value, "vendor_name")
            }
          />{" "}
        </div>
        {isPantaloons ? (
          <div className="divTableCell">
            <Select
              className="select"
              options={BOUND_TYPES}
              value={BOUND_TYPES.filter((el) => el.value == rowData.bound_type)}
              onChange={(value) =>
                handleSelectInputChange("bound_type", value, "bound")
              }
            />{" "}
          </div>
        ) : (
          <>
            <div className="divTableCell">
              {vendorTypes.length > 0 && rowData.vendor_id != ""
                ? vendorTypes.filter(
                    (el) => el.company_id == rowData.vendor_id
                  )[0]["company_category"]
                : ""}{" "}
            </div>
            <div className="divTableCell">
              {vendorTypes.length > 0 && rowData.vendor_id != ""
                ? vendorTypes.filter(
                    (el) => el.company_id == rowData.vendor_id
                  )[0]["sap_vendor_code"]
                : ""}
            </div>
          </>
        )}
        {ABFRL_COMPANY_IDS.includes(company_id) && (
          <div className="divTableCell">
            <Select
              className="select"
              // options={distribution_mode_name}
              options={["Interstore", "Primary", "Secondary"].map((mov) => {
                return { label: mov, value: mov };
              })}
              // onChange={(value) => { set_distribution_mode_name(()=>{ distribution_mode_name: value })}}
              onChange={(value) =>
                handleSelectInputChange(
                  "distribution_mode_id",
                  value,
                  "distribution_mode_name"
                )
              }
            />{" "}
          </div>
        )}
        {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && (
          <div className="divTableCell">
            <Select
              className="select"
              options={distributionTypes}
              // value={rowData.dropdown_name}
              value={distributionTypes.filter(
                (el) => el.value == rowData.dropdown_id
              )}
              // onChange={onDistributionChange}
              onChange={(value) =>
                handleSelectInputChange("dropdown_id", value, "dropdown_name")
              }
            />
          </div>
        )}
        {!PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && (
          <div className="divTableCell">
            <Select
              className="select"
              options={modeTypes}
              value={modeTypes.filter((el) => el.value == rowData.mode_id)}
              onChange={(value) =>
                handleSelectInputChange("mode_id", value, "mode_type")
              }
            />{" "}
          </div>
        )}
        {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && (
          <div className="divTableCell">
            <Select
              className="select"
              options={modeTypes}
              value={modeTypes.filter((el) => el.value == rowData.mode_id)}
              onChange={(value) =>
                handleSelectInputChange("mode_id", value, "mode_type")
              }
            />{" "}
          </div>
        )}
        {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && (
          <div className="divTableCell">
            <DatePicker
              dateFormat="DD/MM/YYYY"
              value={rowData.validate_from_date}
              onChange={(value) =>
                handleInputChange("validate_from_date", value)
              }
              placeholderText={"DD/MM/YYYY"}
            />
          </div>
        )}
        {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && (
          <div className="divTableCell">
            <DatePicker
              dateFormat="DD/MM/YYYY"
              value={rowData.validate_to_date}
              onChange={(value) => handleInputChange("validate_to_date", value)}
              placeholderText={"DD/MM/YYYY"}
            />
          </div>
        )}
        <div className="divTableCell">
          <Select
            className="select"
            options={zonesArray}
            value={zonesArray.filter((el) => el.value == rowData.base_region)}
            onChange={(value) =>
              handleSelectInputChange("base_region", value, "region_rate")
            }
          />
        </div>

        <div className="divTableCell">
          <Select
            className="select"
            onChange={(value) =>
              handleSelectInputChange("rate_type", value, "rate_type")
            }
            options={RATE_TYPES}
            value={RATE_TYPES.filter((el) => el.value === rowData.rate_type)}
          />
          <InputBox
            type="number"
            placeholder="Rate"
            value={rowData.my_rate}
            changeHandler={(value) => handleInputChange("my_rate", value)}
          />{" "}
        </div>
        <div className="divTableCell">
          {" "}
          <div>
            <InputBox
              type="number"
              placeholder="Diesel"
              value={rowData.diesel_rate}
              changeHandler={(value) => handleInputChange("diesel_rate", value)}
            />
          </div>
          <div className="m-t-5">
            <InputBox
              type="number"
              placeholder="Freight"
              value={rowData.freight_rate}
              changeHandler={(value) =>
                handleInputChange("freight_rate", value)
              }
            />
          </div>{" "}
        </div>
        {isPantaloons ? (
          <div className="divTableCell">
            {" "}
            <div className="m-t-5">
              <InputBox
                type="number"
                placeholder="Freight Increment"
                value={rowData.freight_increment}
                changeHandler={(value) =>
                  handleInputChange("freight_increment", value)
                }
              />
            </div>{" "}
          </div>
        ) : (
          <div className="divTableCell">
            {" "}
            {rowData.vendor_id != "" &&
            rowData.mode_id != "" &&
            rowData.lane ? (
              <span
                className="lanes-link"
                onClick={() =>
                  showLanes(
                    true,
                    rowData.vendor_id,
                    rowData.mode_type,
                    [],
                    rowData
                  )
                }
              >
                {rowData.lane.length == 0 ? "Assign" : "Details"}
              </span>
            ) : (
              ""
            )}
          </div>
        )}
        <div className="divTableCell">
          <Fragment>
            <div
              className={"action-icon"}
              style={{ backgroundImage: `url(${Save})` }}
              onClick={() => handleSubmitForm()}
            />
            <div
              className={"action-icon"}
              style={{ backgroundImage: `url(${Cancel})` }}
              onClick={() => handleActionRow(false)}
            />
          </Fragment>
        </div>
      </div>
    );
  };

  const closeMsgPopup = () => {
    dispatch({ type: ACTION.MSG_DISPLAY.HIDE_MSG });
  };

  const downloadVendorLanes = () => {
    const user = JSON.parse(getCookie("user"));
    dispatch({
      type: ACTION.DATA_GOVERNANCE.DOWNLOAD_VENDOR_LANES,
      params: `?company_id=${user.company_id}&vendor_id=${rowData.vendor_id}&mode_id=${rowData.mode_id}&distribution_mode=${rowData.distribution_mode}`,
      onSuccess: (data) => {
        console.log("data", data);
        if (data.length > 0) {
          const element = document.createElement("a");
          element.setAttribute("href", data[0].Link);
          element.setAttribute("download", true);

          element.click();
        }
      },
    });
  };

  return (
    <div className="rate-masters">
      <div className="wrapper">
        <MainComponent>
          {loader.isLoading && <Loader />}
          {msgpopup.active && (
            <MSGPopUp
              close={closeMsgPopup}
              type={msgpopup.type}
              msg={msgpopup.msg}
            />
          )}
          <div className="sub-heading-section">
            <div className="sub-heading"> Diesel Price Hike Amendment </div>
            <div className="action-row">
              <div className="cta-action-btns">
                <div>
                  <button
                    className="button-classic"
                    onClick={() => downloadLanes()}
                  >
                    {" "}
                    Download Template
                  </button>
                  <a
                    className="download-btn"
                    style={{ display: "none" }}
                    ref={(input) => (downloadBtn = input)}
                    href={downloadLink}
                    download
                  />

                  <button
                    className="button-classic"
                    onClick={() => uploadExcel.click()}
                  >
                    {" "}
                    Upload Excel{" "}
                  </button>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={(e) => (uploadExcel = e)}
                    onChange={(e) => uploadRateMastersFile(e)}
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                  />
                </div>
                <div>
                  <button
                    className="button-classic"
                    onClick={() => deleteRMData()}
                  >
                    Delete Record(s)
                  </button>
                  {/*/<button className="button-classic secondary" onClick={() => { }}> Submit for Approval </button>
                                    <input type='file' style={{ display: 'none' }} ref={(e) => { }} onChange={() => { }} onClick={(e) => { e.target.value = null }} />*/}

                  {/*<button className="button-classic secondary" onClick={() => { }}>Advanced Filters</button>*/}
                  <button
                    className="button-classic secondary"
                    onClick={() => addRecord()}
                  >
                    {" "}
                    Add Record
                  </button>
                </div>
                {showError && (
                  <div className="m-t-10">
                    <span className="error">Enter all fields</span>
                  </div>
                )}
              </div>
              {!isPantaloons && getDetailsDiv()}
            </div>
            <div className="rates-table">
              <div className="divTable company-list-wrap">
                <div
                  className={
                    "list-section table-header divTableHeading divTableRow"
                  }
                >
                  <div className={"divTableCell"}></div>
                  <div className={"divTableCell"}>Vendor Name</div>
                  {isPantaloons ? (
                    <div className={"divTableCell"}>Bound Type</div>
                  ) : (
                    <>
                      <div className={"divTableCell"}>Category</div>
                      <div className={"divTableCell"}>Code</div>
                    </>
                  )}
                  {/* <div className={'divTableCell'}>Service Mode</div> */}
                  {(ABFRL_COMPANY_IDS.includes(company_id) ||
                    PANTALOONS_COMPANY_IDS.includes(getUserCompanyId())) && (
                    <div className={"divTableCell"}>Distribution Type</div>
                  )}
                  <div className={"divTableCell"}>Service Mode</div>
                  {Pantaloons && (
                    <div className={"divTableCell"}>Tenure FromDate</div>
                  )}
                  {Pantaloons && (
                    <div className={"divTableCell"}>Tenure ToDate</div>
                  )}
                  <div className={"divTableCell"}>Region</div>
                  <div className={"divTableCell"}>My Rate</div>
                  <div className={"divTableCell"}>(Inc/Dec) %</div>
                  {isPantaloons ? (
                    <div className={"divTableCell"}>Freight Increment (%)</div>
                  ) : (
                    <div className={"divTableCell"}>Lanes</div>
                  )}
                  <div className={"divTableCell"}>Actions</div>
                </div>
                <div className="divTableBody">
                  {actionRow && renderActionRow(props)}
                  {rateMastersData.map((row, index) => {
                    const idx = vendorTypes.findIndex(
                      (el) => el.company_id === row.vendor_id
                    );
                    let comp = {};
                    if (idx > -1) {
                      comp = vendorTypes[idx];
                    }
                    return (
                      <div
                        className="list-section content divTableRow"
                        key={index}
                      >
                        <div className="divTableCell select-row">
                          <input
                            type="checkbox"
                            readOnly
                            checked={
                              selectedRmIds.includes(row.rate_master_id)
                                ? true
                                : false
                            }
                            onClick={(e) =>
                              handleRmIdsSelection(row.rate_master_id)
                            }
                          />
                        </div>
                        <div className="divTableCell">
                          {vendorTypes.length > 0
                            ? comp.company || ""
                            : "" //vendorTypes.filter(el => el.company_id == row.vendor_id)[0]['company_category'] : ''
                          }{" "}
                        </div>
                        {isPantaloons ? (
                          <div className="divTableCell">
                            {row.bound_type == 1
                              ? "Inbound"
                              : row.bound_type == 2
                              ? "Outbound"
                              : "-"}
                          </div>
                        ) : (
                          <>
                            <div className="divTableCell">
                              {vendorTypes.length > 0
                                ? comp.company_category || ""
                                : "" //vendorTypes.filter(el => el.company_id == row.vendor_id)[0]['company_category'] : ''
                              }{" "}
                            </div>
                            <div className="divTableCell">
                              {vendorTypes.length > 0
                                ? comp.sap_vendor_code || ""
                                : "" //vendorTypes.filter(el => el.company_id == row.vendor_id)[0]['sap_vendor_code'] : ''
                              }{" "}
                            </div>
                          </>
                        )}
                        {Pantaloons && (
                          <div className="divTableCell">
                            {/* {distributiontypeoptions(row)} */}
                            {row.dropdown_name}
                          </div>
                        )}
                        {ABFRL_COMPANY_IDS.includes(company_id) && (
                          <div className="divTableCell">
                            {vendorTypes.length > 0
                              ? row.distribution_mode_name || ""
                              : ""}
                          </div>
                        )}
                        {!ABFRL_COMPANY_IDS.includes(company_id) &&
                          !PANTALOONS_COMPANY_IDS.includes(company_id) && (
                            <div className="divTableCell">
                              {modeTypes.length > 0 &&
                              modeTypes.filter((el) => el.id == row.mode_id)[0]
                                ? modeTypes.filter(
                                    (el) => el.id == row.mode_id
                                  )[0]["name"]
                                : ""}{" "}
                            </div>
                          )}
                        {Pantaloons && (
                          <div className="divTableCell">
                            {row.validate_from_date}
                          </div>
                        )}
                        {Pantaloons && (
                          <div className="divTableCell">
                            {row.validate_to_date}
                          </div>
                        )}
                        <div
                          className="divTableCell"
                          style={{ minWidth: "110px" }}
                        >
                          {row.base_region != ""
                            ? getRegionDiv(row.base_region.toLowerCase(), row)
                            : ""}
                        </div>
                        <div className="divTableCell">
                          {" "}
                          BR{" "}
                          {row.my_rate
                            ? row.my_rate
                            : getBaseRate(row.base_region)}{" "}
                          <br />{" "}
                          {row.rate_type && (
                            <span>
                              Type: {row.rate_type.toLowerCase() || ""}
                            </span>
                          )}{" "}
                        </div>
                        <div className="divTableCell">
                          {" "}
                          <div>Diesel {row.diesel_rate}</div>
                          <div>Freight {row.freight_rate} </div>{" "}
                        </div>
                        {isPantaloons ? (
                          <div className="divTableCell">
                            {row.freight_increment || "-"}
                          </div>
                        ) : (
                          <div className="divTableCell">
                            {" "}
                            <span
                              className="lanes-link"
                              onClick={() => {
                                row.lane.length != 0
                                  ? showLanes(
                                      false,
                                      row.vendor_id,
                                      row.mode_type,
                                      row.lane,
                                      row
                                    )
                                  : null;
                              }}
                            >
                              {row.lane.length == 0 ? "-" : "Details"}
                            </span>{" "}
                          </div>
                        )}
                        <div className="divTableCell">
                          <div
                            className={"action-icon"}
                            style={{ backgroundImage: `url(${Edit})` }}
                            onClick={() => handleEditRow(row)}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </MainComponent>
        {showLanesPopup && (
          <DphAmendmentDetails
            closePopup={closePopup}
            approveRates={approveRates}
            lanesData={lanesArray}
            rowData={rowData}
            months={months}
            downloadVendorLanes={downloadVendorLanes}
            generateRates={generateRates}
            isAbfrl={isAbfrl}
            // isGenerate={isGenerate}
            // isApproved={isApproved}
            isEditing={isEditing}
            editData={editData}
            handleChange={handleChange}
            toggleEdit={toggleEdit}
            selectedMonth={selectedMonth}
            handleDateChange={handleDateChange}
            formatDateForInput={formatDateForInput}
            handleAmendSubmit={handleAmendSubmit}
            onClickAmendSave={onClickAmendSave}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log("state", state);
  return {
    loader: state.loader,
    msgpopup: state.msgpopup,
    dataGovernance: state.dataGovernance,
  };
};

export default connect(mapStateToProps)(withRouter(DphAmendment));
