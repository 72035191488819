import { put, call } from "redux-saga/effects";
import ACTIONS from "../../common/action_constant";
import API from "../../common/api_config";
import {
	getData,
	putData,
	postData,
	deleteData,
	fileUpload,
	putDatajson
} from "../../../utils/ajax";
import history from "../../history";
import axiosT1 from '../../../utils/axios'

function* getPlantsDepots(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getPlantDepots + action.data);
		yield put({
			type: ACTIONS.PLANTS.STORE_PLANTS_DEPOTS,
			data: data.data.list
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
	}
}
function* getZones(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.zones );
		yield put({
			type: ACTIONS.PLANTS.STORE_ZONES,
			data: data.data.list
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
	}
}
function* getCompanyWiseZones(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getCompanyWiseZoneUrl + action.data);
		yield put({
			type: ACTIONS.PLANTS.STORE_COMPANY_WISE_ZONES,
			data: data.data
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
	}
}
function* getBranchTypes(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.branchTypes);
		yield put({
			type: ACTIONS.PLANTS.STORE_BRANCH_TYPES,
			data: data.data.list
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
	}
}
function* updatePlant(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		yield call(putData, API.updatePlantDepotbasicDetails, JSON.stringify(action.data));
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Updated Successfully" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})

	}
}
function* createNew(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.updatePlantDepotbasicDetails, action.data);
		history.push("/home/" + action.data.company_id + "/" + action.data.company_type + "/" + data.data.branch_id)
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "New Plant Added Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})

	}
}
//users depot api
function* getDepotUsers(action) {
	let url;
	if (action.paramdata.search)
		url = action.paramdata.company_id + "&branchId=" + action.paramdata.branch_id + "&companyType=" + action.paramdata.company_type + "&search=" + action.paramdata.search + "&limit=10&offset" + action.paramdata.offset;
	else
		url = action.paramdata.company_id + "&branchId=" + action.paramdata.branch_id + "&companyType=" + action.paramdata.company_type + "&limit=10&offset=" + action.paramdata.offset;

	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getDepotUsers + url);
		yield put({
			type: ACTIONS.PLANTS.STORE_DEPOT_USERS,
			data: data.data.list
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
	}
}
function* getBranches(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getBranches);
		yield put({
			type: ACTIONS.PLANTS.STORE_BRANCHES,
			data: data.data.list
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
	}
}

export function* getTransporterArray(action) {
	try {
	  const resp = yield call(
		axiosT1.post,
		API.getHome,
		{},
		{
		  params: action.queryParams
		});
  
		if(action.onSuccess){
		  const transportList = resp.data.data.list || []
		  action.onSuccess(transportList)
		}
  
	} catch (e) {
	  console.log('e:', e)
	}
  }

function* verifyUser(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.verifyUser, action.data);
		yield put({
			type: ACTIONS.PLANTS.STORE_VERIFIED_USER,
			data: data.data
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Verified Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})

	}
}
function* getActions(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getActions);
		yield put({
			type: ACTIONS.PLANTS.STORE_ACTIONS,
			data: data.data.list
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
	}
}
function* updateUser(action) {
	let data = {
		company_id: action.data.company_id,
		branch_id: action.data.branch_id,
		company_type: action.data.company_type,
		search: null,
		offset: 0
	}
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		yield call(putData, API.addEditUser, JSON.stringify(action.data));
		yield put({
			type: ACTIONS.PLANTS.GET_DEPOT_USERS,
			paramdata: data
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "User Details Updated Successfully" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})

	}
}
function* createUser(action) {
	let data1 = {
		company_id: action.data.company_id,
		branch_id: action.data.branch_id,
		company_type: action.data.company_type,
		search: null,
		offset: 0
	}
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.addEditUser, action.data);
		yield put({
			type: ACTIONS.PLANTS.GET_DEPOT_USERS,
			paramdata: data1
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "New User Added Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})

	}
}
function* toggleUserStatus(action) {
	let data = {
		company_id: action.data.company_id,
		branch_id: action.data.branch_id,
		company_type: action.data.company_type,
		search: null,
		offset: 0
	}
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		yield call(putData, API.toggleUserStatus, JSON.stringify(action.data));
		yield put({
			type: ACTIONS.PLANTS.GET_DEPOT_USERS,
			paramdata: data
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})

	}
}
function* deleteUser(action) {
	let data1 = {
		company_id: action.data.company_id,
		branch_id: action.data.branch_id,
		company_type: action.data.company_type,
		search: null,
		offset: 0
	}
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(deleteData, API.deleteUser, JSON.stringify(action.data));
		yield put({
			type: ACTIONS.PLANTS.GET_DEPOT_USERS,
			paramdata: data1
		})
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "User Deleted Successfully!" }
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}
function* getIndentingFlows(action) {
	console.log("action ANirudh", action);
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		var queryString = "?" + Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
		const data = yield call(getData, API.getIndentingFlows + queryString);
		yield put({
			type: ACTIONS.PLANTS.STORE_INDENTING_FLOWS,
			data: data.data.flows
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: (e.responseJSON && e.responseJSON.message) || "We Are Facing Some Technical Issue. Please Try After Sometime." }
		})
	}
}

function* getIndentingItems(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		var queryString = "?" + Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
		const data = yield call(getData, API.getIndentingItems + queryString);
		yield put({
			type: ACTIONS.PLANTS.STORE_INDENTING_ITEMS,
			data: data.data.items
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: (e && e.responseJSON && e.responseJSON.message) || "We Are Facing Some Technical Issue. Please Try After Sometime." }
		})
	}
}

function* getIndentingUsers(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		var queryString = "?" + Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
		const data = yield call(getData, API.getIndentingUsers + queryString);
		yield put({
			type: ACTIONS.PLANTS.STORE_INDENTING_USERS,
			data: data.data.users
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: (e && e.responseJSON && e.responseJSON.message) || "We Are Facing Some Technical Issue. Please Try After Sometime." }
		})
	}
}

function* addMove(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.addMove, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Move Added Successfully!" }
		})
		yield put({
			type: ACTIONS.PLANTS.GET_INDENTING_MOVES,
			data: action.param
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}
function* addAction(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.addAction, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Action added Successfully!" }
		})
		yield put({
			type: ACTIONS.PLANTS.GET_INDENTING_ACTIONS,
			data: action.param
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}
function* addChecklist(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.addChecklist, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Checklist added Successfully!" }
		})
		yield put({
			type: ACTIONS.PLANTS.GET_INDENTING_CHECKLISTS,
			data: action.param
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}
function* addWarehouse(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.addWarehouse, action.data);
		if(action.onSuccess) {
			action.onSuccess();
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Warehouse added Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}
function* addVendor(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.addVendor, action.data);
		if(action.onSuccess) {
			action.onSuccess();
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Vendor added Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}
function* getIndentingMoves(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		var queryString = "?" + Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
		const data = yield call(getData, API.getIndentingMoves + queryString);
		yield put({
			type: ACTIONS.PLANTS.STORE_INDENTING_MOVES,
			data: data.data.moves
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}
function* getIndentingSubMoves(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		var queryString = "?" + Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
		const data = yield call(getData, API.getIndentingSubMoves + queryString);
		yield put({
			type: ACTIONS.PLANTS.STORE_INDENTING_SUBMOVES,
			data: data.data
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}
function* getIndentingActions(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		var queryString = "?" + Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
		const data = yield call(getData, API.getIndentingActions + queryString);
		yield put({
			type: ACTIONS.PLANTS.STORE_INDENTING_ACTIONS,
			data: data.data.actions
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : 'We Are Facing Some Technical Issue. Please Try After Sometime.' }
		})
	}
}
function* getIndentingChecklists(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		var queryString = "?" + Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
		const data = yield call(getData, API.getIndentingChecklists + queryString);
		yield put({
			type: ACTIONS.PLANTS.STORE_INDENTING_CHECKLISTS,
			data: data.data.checklists
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: (e && e.responseJSON && e.responseJSON.message) || "We Are Facing Some Technical Issue. Please Try After Sometime." }
		})
	}
}
function* getIndentingWarehouses(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		var queryString = "?" + Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
		const data = yield call(getData, API.getIndentingWarehouses + queryString);
		yield put({
			type: ACTIONS.PLANTS.STORE_INDENTING_WAREHOUSES,
			data: data.data.warehouses
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: (e && e.responseJSON && e.responseJSON.message) || "We Are Facing Some Technical Issue. Please Try After Sometime." }
		})
	}
}
function* getVolumeticMaster(action){
	
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		
		const data = yield call(getData, API.getVolumetricMasterDetailsURL + action.queryString);
		yield put({
			type: ACTIONS.PLANTS.SET_VOLUMETRIC_MASTER,
			data: data.data.list
		});
	
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if(action.onSuccess){
			action.onSuccess(data);
		}
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}

}

function* addVolumetricMaster(action) {
	console.log(action)
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.addVolumetricMasterDetailsURL, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: (data && data.message) || " Added Successfully!" }
		})
		if(action.onSuccess){
			action.onSuccess();
		}
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}

}


 function* updateVolumetricMaster(action) {
	try {
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER,
	  });
	  const data = yield call(
		putDatajson,
		API.updateVolumetricMasterDetailsURL,
	    action.data	
	  );
	  if (action.onSuccess) {
		action.onSuccess();
	  }
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER,
	  });
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: {
		  type: 1,
		  msg:
			(data && data.message) ||
			"Material Conversion Record Updated Successfully",
		},
	  });
	} catch (e) {
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER,
	  });
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: {
		  type: 2,
		  msg:
			e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
		},
	  });
	}
  }
  function* deleteVolumetricMaster(action) {
	try {
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER,
	  });
  
	  const data = yield call(
		deleteData,
		API.deleteVolumetricMasterDetailsURL + action.queryString
		
	  );
	  if (action.onSuccess) {
		action.onSuccess(data);
	  }
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER,
	  });
  
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: {
		  type: 1,
		  msg:
			(data && data.message) ||
			"Volumetric Record Deleted Successfully",
		},
	  });
	} catch (e) {
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER,
	  });
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: {
		  type: 2,
		  msg: e && e.message ? e.message : "Oops! Something went wrong.",
		},
	  });
	}
}


function* getPackUOMDropdown() {
	try {
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER,
	  });
	  let url = `${API.getPackUOMDropdown}`;
	  const data = yield call(getData, url);
	  
	  yield put({
		type: ACTIONS.PLANTS.SET_PACK_UOM_DROPDOWN,
		data: data && data.data || []
	  })
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER,
	  });
	} catch (e) {
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER,
	  });
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: {
		  type: 2,
		  msg:
			e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
		},
	  });
	}
  }

  export function* downloadVolumetricMaster(action) {
	try {
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER,
	  });
	  let url = action.withData
		? API.downloadTemplateVolumetric
		: API.downloadDataVolumetric;
	  const data = yield call(getData, url + action.queryString);
  
	  if (action.onSuccess) {
		action.onSuccess(data.data);
	  }
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER,
	  });
	} catch (e) {
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER,
	  });
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: {
		  type: 2,
		  msg: e && e.message ? e.message : "Oops! Something went wrong.",
		},
	  });
	}
  }
  
  export function* uploadVolumetricMasterDetails(action) {
	try {
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER,
	  });
	  let url = `${API.uploadDataVolumetric}`;
	  const data = yield call(
		fileUpload,
		url+ action.queryString ,
		action.data
	  );
  
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER,
	  });
	  if (action.onSuccess) {
		action.onSuccess(data);
	  }
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: { type: 1, msg:(data && data.message)|| "Excel uploaded Sucessfully!" },
	  });
	} catch (e) {
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER,
	  });
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: {
		  type: 2,
		  msg: (data && data.message) || "Oops! Something went wrong.",
		},
	  });
	}
  }

function* getIndentingVendors(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		var queryString = "?" + Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
		const data = yield call(getData, API.getIndentingVendors + queryString);
		yield put({
			type: ACTIONS.PLANTS.STORE_INDENTING_VENDORS,
			data: data.data.vendors
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: (e && e.responseJSON && e.responseJSON.message) || "We Are Facing Some Technical Issue. Please Try After Sometime." }
		})
	}
}
function* getIndentingFlowDetails(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		var queryString = "?" + Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
		const data = yield call(getData, API.getIndentingFlowDetails + queryString);
		yield put({
			type: ACTIONS.PLANTS.STORE_INDENTING_FLOW_DETAILS,
			data: data.data
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* getSubPlants(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		var queryString = "?" + Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
		const data = yield call(getData, API.getSubPlants + queryString);
		yield put({
			type: ACTIONS.PLANTS.STORE_SUBPLANTS,
			data: data.data.sub_plants
		});
		if(action.onSuccess) {
			action.onSuccess(data)
		  }
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* submitMoves(action) {
	try {
		let message;
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.submitMoves, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if (action.data.flow_id) {
			message = "Flow Updated Successfully!";
			yield put({
				type: ACTIONS.PLANTS.GET_FLOW_DETAILS,
				data: {
					flowId: action.data.flow_id
				}
			})
		} else {
			message = "Flow Added Successfully!";
		}
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: message }
		})
	} catch (e) {
		console.log(e);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && (e.responseJSON.errors && e.responseJSON.errors.length && e.responseJSON.errors[0] || e.responseJSON.message) }
		})
	}
}

function* deleteMove(action) {
	const url = API.deleteMove + action.data.flow_id;
	let data1 = {
		branchId: action.data.branch_id,
		companyId: action.data.company_id
	}
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(deleteData, url);
		yield put({
			type: ACTIONS.PLANTS.GET_INDENTING_FLOWS,
			data: data1
		})
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Flow Deleted Successfully!" }
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* addSubPlants(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.addSubPlants, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.PLANTS.GET_SUBPLANTS,
			data: {
				branchId: action.data.branch_id,
				companyId: action.data.company_id
			}
		})
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Sub Plant Added Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.errors && e.responseJSON.errors.length && e.responseJSON.errors[0] }
		})
	}
}
function* getActionTypes(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getActionTypes);
		yield put({
			type: ACTIONS.PLANTS.STORE_ACTION_TYPES,
			data: data.data.types
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
	}
}

function* getMoveName(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getMoveName);
		yield put({
			type: ACTIONS.PLANTS.STORE_MOVE_NAME,
			data: data.data
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
	}
}
function* getAllBranch(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getAllBranch + action.data.companyId + "&companyType=" + action.data.companyType);
		yield put({
			type: ACTIONS.PLANTS.STORE_ALL_BRANCH,
			data: data.data.list
		});
		if(action.onSuccess){
			action.onSuccess(data.data.list)
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
	}
}

function* getIndentingFields(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		var queryString = "?" + Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
		const data = yield call(getData, API.getIndentFields + queryString);
		yield put({
			type: ACTIONS.PLANTS.STORE_INDENTING_FIELDS,
			data: data.data || []
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: (e && e.responseJSON && e.responseJSON.message) || "We Are Facing Some Technical Issue. Please Try After Sometime." }
		})
	}
}

function* addIndentingFields(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.postIndentFields, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Field Added Successfully!" }
		})
		yield put({
			type: ACTIONS.PLANTS.GET_INDENTING_FIELDS,
			data: action.param
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* addLoadingBay(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.addBayDetails, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		if (action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data Added Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* addLoadingGang(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.addGangDetails, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		if (action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data added Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* getBayDetails(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});

		let queryString = Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
		const result = yield call(getData, API.getBayDetails + queryString);

		yield put({
			type: ACTIONS.PLANTS.SAVE_LOADING_BAY,
			data: result && result.data && result.data.bays || []
		})
		if(action.onSuccess) {
			action.onSuccess(result)
		  }

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* getGangDetails(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});

		let queryString = Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');

		const data = yield call(getData, API.getGangDetails + queryString);

		yield put({
			type: ACTIONS.PLANTS.SAVE_LAODING_GANG,
			data: data && data.data && data.data.vendors || []
		})
		if(action.onSuccess) {
			action.onSuccess(data)
		  }
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

	} catch (e) {

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* deleteLoadingBay(action) {
	try {

		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});

		const data = yield call(deleteData, API.deleteBayDetails, JSON.stringify(action.data));

		if (action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data deleted Successfully!" }
		})

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

	} catch (e) {

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* deleteLoadingGang(action) {
	try {

		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});

		const data = yield call(deleteData, API.deleteGangDetails, JSON.stringify(action.data));

		if (action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data deleted Successfully!" }
		})

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

	} catch (e) {

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* updateLoadingBay(action) {
	try {

		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});

		const data = yield call(postData, API.editBayDetails, action.data);

		if (action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data Updated Successfully!" }
		})

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

	} catch (e) {

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* updateLoadingGang(action) {
	try {

		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});

		const data = yield call(postData, API.editGangDetails, action.data);

		if (action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data Updated Successfully!" }
		})

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

	} catch (e) {

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* getIndentingEditFields(action) {
	try {

		let queryString = Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');

		const data = yield call(getData, API.getIndentingEditFields + queryString);

		yield put({
			type: ACTIONS.PLANTS.SAVE_INDENTING_EDIT_FIELDS,
			data: data && data.data && data.data.field_list || []
		})
	} catch (e) {
		console.log(e);
	}
}

function* saveMoveStandardTime(action) {
	try {

		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});

		const data = yield call(postData, API.postMoveStandardTime, action.data);

		if (action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data Posted Successfully!" }
		})

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

	} catch (e) {

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* getMoveStandardTime(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});

		let queryString = Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');

		const data = yield call(getData, API.getMoveStandardTime + queryString);

		yield put({
			type: ACTIONS.PLANTS.SAVE_MOVE_STANDARD_TIME,
			data: data && data.data && data.data.list && data.data.list.moves_standard_time || []
		})

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

	} catch (e) {

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* updateMoveStandardTime(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});

		const data = yield call(postData, API.updateMoveStandardTime, action.data);

		if (action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data Updated Successfully!" }
		})

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

	} catch (e) {

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* getApproverList(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});

		let queryString = Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');

		const data = yield call(getData, API.getApproverList + queryString);

		yield put({
			type: ACTIONS.PLANTS.SAVE_APPROVER_LIST,
			data: data && data.data || {}
		})

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

	} catch (e) {

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* getAllApproverList(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});

		let queryString = Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');

		const data = yield call(getData, API.getAllApproverList + queryString);

		yield put({
			type: ACTIONS.PLANTS.SAVE_APPROVER_LIST,
			data: data && data.data || {}
		})

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

	} catch (e) {

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}
function* getRegionMasterDropdowns(action) {
	try {
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER,
	  });
	  let url = `${API.getRegionMaster}`;
	  const data = yield call(getData, url);
	  // const regionList = data && (data.data || []).map((ele)=>{
	  //   return {
	  //     ...ele,
	  //     label:ele.region,
	  //     value:ele.region_type,
	  //   } 
	  // })
	  yield put({
		type: ACTIONS.PLANTS.SAVE_REGION_DROPDOWN,
		data: data && data.data ||[] //regionlist instead of this 
	  })
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER,
	  });
	} catch (e) {
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER,  
	  });
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: {
		  type: 2,
		  msg: 
			e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",  
		}, 
	  });  
	}
  }

function* getDepartmentsEmailList(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});

		let queryString = Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');

		const data = yield call(getData, API.readDepartmentUsers + queryString);

		yield put({
			type: ACTIONS.PLANTS.DEPARTMENT_EMAIL_LIST_DATA,
			data: data && data.data && data.data.list ? data.data.list : []
		})

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

	} catch (e) {

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* updateApproverList(action) {
	try {

		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});

		const data = yield call(putData, API.updateApproverList,  JSON.stringify(action.data));

		if(action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data Updated Successfully!" }
		})

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* postApproverList(action) {
	try {

		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});

		const data = yield call(postData, API.postApproverList, action.data);

		if(action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data Added Successfully!" }
		})

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* applyApproverToOtherBranches(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});

		const data = yield call(putData, API.postApproverToBranch, JSON.stringify(action.data));

		if(action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Settings Applied Successfully" }
		})

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}	
}

function* saveWeighBridge(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});

		const data = yield call(postData, API.postWeighbridge, action.data);

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data Updated Successfully!" }
		})

		if(action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

	} catch(e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* addWBConversion(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.addWBConversion, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		if (action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data added Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* getWBConversion(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
	
		let queryString = Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
	
		const data = yield call(getData, API.getWBConversion + queryString);
	
		yield put({
			type: ACTIONS.PLANTS.SAVE_WB_CONVERSION,
			data: data && data.data && data.data.list || []
		})
	
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if (action.onSuccess) {
			action.onSuccess(data.data.list);
		}
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg:  e.responseJSON && e.responseJSON.message || '' }
		})
	}
}

function* updateWBConversion(action){
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(putData, API.updateWBConversion, JSON.stringify(action.data));
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		if (action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data Updated Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* deleteWBConversion(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.deleteWBConversion, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		if (action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data Deleted Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* getWarehouseList(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const queryString = Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');

		const data = yield call(getData, API.getWarehouseList + queryString);
		yield put({
			type: ACTIONS.PLANTS.SAVE_WAREHOUSE_LIST,
			data: data.data && data.data.list || []
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		console.log('ERROR', e);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	}
}

function* getWeighbridgeDetails(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const queryString = Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');

		const data = yield call(getData, API.getWeighbridgeDetails + queryString);
		yield put({
			type: ACTIONS.PLANTS.SAVE_WEIGH_BRIDGE_DETAILS,
			data: data.data || []
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		console.log('ERROR', e);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	}
}

function* uploadTermConditions(action) {
	    try {
	        yield put({
	            type: ACTIONS.PLANTS.STORE_TERM_CONDITION,
	            data: { url: "" }
	        });
	        yield put({
		            type: ACTIONS.LOADER.SHOW_LOADER
		        });
	        const data = yield call(fileUpload, API.uploadImg, action.data);
	        yield put({
	            type: ACTIONS.PLANTS.STORE_TERM_CONDITION,
	            data
	        });
	if(action.onSuccess){
		action.onSuccess(data)
	}
	        yield put({
	            type: ACTIONS.LOADER.HIDE_LOADER
	        });
	    } catch (e) {
		console.log('ERROR', e);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	    }
	}
	
export function* downloadSubplantTemplate(action) {
	let data = null;
	try {
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const url = `${API.downloadSubplantTemplate}?companyId=${action.param.companyId}&branchId=${action.param.branchId}`
		if(action.withData){
			data = yield call(getData, url )
		} else {
			data = yield call(getData,  API.downloadSubplantTemplate + '/temp' );
		}
		yield put({
			type :ACTIONS.PLANTS.SUBPLANT_TEMPLATE,
			data : data
		});
		if(action.onSuccess){
			action.onSuccess(data.data);
		}
		yield put({
			type : ACTIONS.LOADER.HIDE_LOADER
		})
	} catch(e) {
		yield put({
			type : ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data :{type :2, msg :e && e.message ? e.message :"Oops! Something went wrong."}
		})
	}
}

export function* uploadSubplantDetails(action) {
	try{
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const url = `${API.uploadSubplantDetails}?companyId=${action.params.companyId}&branchId=${action.params.branchId}&user_email=${action.params.user_email}`
		const data =  yield call (fileUpload, url, action.data);
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		if(action.onSuccess){
			action.onSuccess(data);
		}
		yield put({
			type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data :{type :1, msg :"Excel uploaded Sucessfully!"}
		})
	}catch (e){
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data :{type :2, msg :e && e.message ? e.message :"Oops! Something went wrong."}
		})
	}
}

export function* deleteSubplantDetails(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});

		const data = yield call(deleteData, API.deleteSubplantDetails, JSON.stringify(action.data));
		if(action.onSuccess){
			action.onSuccess(data);
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data && data.data && data.data.message || "Subplant Details Deleted Successfully" }
		})
		yield put({
			type: ACTIONS.PLANTS.GET_SUBPLANTS,
			data: {
				branchId: action.data.branch_id,
				companyId: action.data.company_id
			}
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data :{type :2, msg :e && e.message ? e.message :"Oops! Something went wrong."}
		})
	}
}

function* editSubplantDetails(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		yield call(putData, API.editSubplantDetails, JSON.stringify(action.data));
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.PLANTS.GET_SUBPLANTS,
			data: {
				branchId: action.data.branch_id,
				companyId: action.data.company_id
			}
		})
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Updated Successfully" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})

	}
}

export function* downloadLoadingBayTemplate(action) {
	let data = null;
	try {
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const url = `${API.downloadLoadingBayDetails }?companyId=${action.param.companyId}&branchCode=${action.param.branchCode}`
		if(action.withData){
			data = yield call(getData, url )
		} else {
			data = yield call(getData,  API.downloadLoadingBayTemplate );
		}
		yield put({
			type :ACTIONS.PLANTS.SUBPLANT_TEMPLATE,
			data : data
		});
		if(action.onSuccess){
			action.onSuccess(data.data);
		}
		yield put({
			type : ACTIONS.LOADER.HIDE_LOADER
		})
	} catch(e) {
		yield put({
			type : ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data :{type :2, msg :e && e.message ? e.message :"Oops! Something went wrong."}
		})
	}
}

export function* uploadLoadingBayDetails(action) {
	try{
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const url = `${API.uploadLoadingBayDetails}?companyId=${action.param.companyId}&branchCode=${action.param.branchCode}`
		const data =  yield call (fileUpload, url, action.data);
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		if(action.onSuccess){
			action.onSuccess(data);
		}
		yield put({
			type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data :{type :1, msg :"Excel uploaded Sucessfully!"}
		})
	}catch (e){
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data :{type :2, msg :e && e.message ? e.message :"Oops! Something went wrong."}
		})
	}
}

export function* downloadPlantDetails(action) {
	try {
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const url = action.withData ? `${API.downloadPlantDetails}?${action.params}` : API.downloadPlantTemplate;
		const data = yield call(getData, url);
		
		yield put({
			type :ACTIONS.PLANTS.PLANT_DEPOT_DETAILS,
			data : data
		});
		if(action.onSuccess){
			action.onSuccess(data.data);
		}
		yield put({
			type : ACTIONS.LOADER.HIDE_LOADER
		})
	} catch(e) {
		yield put({
			type : ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data :{type :2, msg :e && e.message ? e.message :"Oops! Something went wrong."}
		})
	}
}

export function* downloadLoadingVendorTemplate(action) {
	let data = null;
	try {
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const url = `${API.downloadLoadingVendorDetails }?companyId=${action.param.companyId}&branchCode=${action.param.branchCode}`
		if(action.withData){
			data = yield call(getData, url )
		} else {
			data = yield call(getData,  API.downloadLoadingVendorTemplate );
		}
		if(action.onSuccess){
			action.onSuccess(data.data);
		}
		yield put({
			type : ACTIONS.LOADER.HIDE_LOADER
		})
	} catch(e) {
		yield put({
			type : ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data :{type :2, msg :e && e.message ? e.message :"Oops! Something went wrong."}
		})
	}
}

export function* uploadLoadingVendorDetails(action) {
	try{
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const url = `${API.uploadLoadingVendorDetails}?companyId=${action.param.companyId}&branchCode=${action.param.branchCode}`
		const data =  yield call (fileUpload, url, action.data);
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		if(action.onSuccess){
			action.onSuccess(data);
		}
		yield put({
			type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data :{type :1, msg :"Excel uploaded Sucessfully!"}
		})
	}catch (e){
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data :{type :2, msg :e && e.message ? e.message :"Oops! Something went wrong."}
		})
	}
}

export function* uploadPlantDetails(action) {
	try{
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		// const url = `${API.uploadPlantDetails}?companyId=${action.param.companyId}&branchCode=${action.param.branchCode}`
		const data =  yield call (fileUpload, API.uploadPlantDetails + action.params, action.data);
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		if(action.onSuccess){
			action.onSuccess(data);
		}
		yield put({
			type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data :{type :1, msg :"Excel uploaded Sucessfully!"}
		})
	}catch (e){
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data :{type :2, msg :e && e.message ? e.message :"Oops! Something went wrong."}
		})
	}
}

function* deletePlantsDepots(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const { data } = action;
		const URL = `${API.deleteSyncChain}`;
		yield call(deleteData, URL, JSON.stringify({ company_id: data.company_id, branch_ids: [data.branch_id] }));
		const url = `${API.deletePlantDepots}${data.company_id}&companyType=${data.company_type}`;
		yield call(deleteData, url , JSON.stringify({branch_code: data.branch_code, branch_id: data.branch_id}));
		if(action.onSuccess) {
			action.onSuccess();
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type : ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data :{type :2, msg :e && e.message ? e.message :"Oops! Something went wrong."}
		})
	}
}

function* getWarehouseDetails(action) {
	let url;
	if (action.data.search){
		url = "?branch_code=" + action.data.branch_code + "&company_id=" + action.data.company_id + "&search=" + action.data.search + "&limit=10&offset=" + action.data.offset;
	}
	else{
		url = "?branch_code=" + action.data.branch_code + "&company_id=" + action.data.company_id + "&limit=10&offset=" + action.data.offset;
	}

	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getWarehouseDetails + url);
		yield put({
			type: ACTIONS.PLANTS.STORE_WAREHOUSE_DETAILS,
			data: data.data.masters
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if(action.onSuccess){
			action.onSuccess(data.data.masters);
		}
	} catch (e) {
	}
}

function* editWarehouseDetails(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		if(action.data.isUpdate){
			yield call(putData, API.editWarehouseDetails,  JSON.stringify(action.data));
		} else if(action.data.isDelete){
			yield call(deleteData, API.editWarehouseDetails, JSON.stringify(action.data));
		} else {
			yield call(postData, API.editWarehouseDetails, action.data);
		}
		if (action.onSuccess) {
			action.onSuccess();
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Updated Successfully" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

export function* uploadWarehouseDetails(action) {
	try{
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const url = `${API.uploadWarehouseDetails}?company_id=${action.param.company_id}&branch_code=${action.param.branch_code}&user_email=${action.param.user_email}&user_id=${action.param.user_id}`
		const data =  yield call (fileUpload, url, action.data);
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		if(action.onSuccess){
			action.onSuccess(data);
		}
		yield put({
			type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data :{type :1, msg :"Excel uploaded Sucessfully!"}
		})
	}catch (e){
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data :{type :2, msg :e && e.message ? e.message :"Oops! Something went wrong."}
		})
	}
}

export function* downloadWarehouseTemplate(action) {
	let data = null;
	try {
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const url = `${API.downloadWarehouseData }?company_id=${action.param.company_id}&branch_code=${action.param.branch_code}`
		if(action.withData){
			data = yield call(getData, url )
		} else {
			data = yield call(getData,  API.downloadWarehouseTemplate );
		}
		if(action.onSuccess){
			action.onSuccess(data.data);
		}
		yield put({
			type : ACTIONS.LOADER.HIDE_LOADER
		})
	} catch(e) {
		yield put({
			type : ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data :{type :2, msg :e && e.message ? e.message :"Oops! Something went wrong."}
		})
	}
}

function* getIndentingOperationTime(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		var queryString = "?" + Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
		const data = yield call(getData, API.operationTimeDetails + queryString);
		yield put({
			type: ACTIONS.PLANTS.STORE_INDENTING_OPERATION_TIME,
			data: data.data.list
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : 'We Are Facing Some Technical Issue. Please Try After Sometime.' }
		})
	}
}

function* addOperationTime(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.addOperationTime, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Operation Time Added Successfully!" }
		})
		yield put({
			type: ACTIONS.PLANTS.GET_INDENTING_OPERATION_TIME,
			data: action.param
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* editOperationTime(action){
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.editOperationTime, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.PLANTS.GET_INDENTING_OPERATION_TIME,
			data: action.param
		})

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data Updated Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* deleteOperationTime(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.deleteOperationTime, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Move Added Successfully!" }
		})
		yield put({
			type: ACTIONS.PLANTS.GET_INDENTING_OPERATION_TIME,
			data: action.param
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* branchTransfer(action){
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const url = `${API.branchTransfer}?companyId=${action.param.company_id}&companyType=${action.param.company_type}&branch_code=${action.param.branchCode}`
		const data = yield call(postData, url , action.data);
		yield put({
			type: ACTIONS.PLANTS.GET_DEPOT_USERS,
			paramdata: action.param
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data Updated Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* deactivateBranchUser(action){
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const url = `${API.deactivateBranchUser}?companyId=${action.param.company_id}&companyType=${action.param.company_type}&branch_code=${action.param.branchCode}`
		const data = yield call(postData, url, action.data);
		yield put({
			type: ACTIONS.PLANTS.GET_DEPOT_USERS,
			paramdata: action.param
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data Updated Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* getDepartmentAndUserDetails(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		var queryString = "?" + Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
		const data = yield call(getData, API.depotAndUserDetails + queryString);
		yield put({
			type: ACTIONS.PLANTS.SAVE_DEPOT_AND_USER_DETAILS,
			data: data.data || []
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: (e && e.responseJSON && e.responseJSON.message) || "We Are Facing Some Technical Issue. Please Try After Sometime." }
		})
	}
}

function* downloadUserDetailsTemplate(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.downloadUserDetailsTemplate);
		if(action.onSuccess) {
			action.onSuccess(data && data.data || []);
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		console.log('e', e);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: (e && e.responseJSON && e.responseJSON.message) || "We Are Facing Some Technical Issue. Please Try After Sometime." }
		})
	}
}

function* uploadUserDetails(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		var queryString = "?" + Object.keys(action.param).map(key => key + '=' + action.param[key]).join('&');
		const data = yield call(fileUpload, API.uploadUserDetails + queryString, action.data);
		if(action.onSuccess) {
			action.onSuccess();
		}
		yield put({
			type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data : { type :1, msg :"Data Uploaded Sucessfully!" }
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		console.log('e', e);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: (e && e.responseJSON && e.responseJSON.message) || "We Are Facing Some Technical Issue. Please Try After Sometime." }
		})
	}
}

function* applyIndentFlowSettingsToBranches(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(putData, API.applyFlowSettingToBranches, JSON.stringify(action.data));
		yield put({
			type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data : { type :1, msg : data.status || "Flow Updated Sucessfully!" }
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: (e && e.responseJSON && e.responseJSON.message) || "We Are Facing Some Technical Issue. Please Try After Sometime." }
		})
	}
}

function* getAutomoveDetails(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});

		let queryString = Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
		const result = yield call(getData, API.getAutomoveDetails + queryString);
		yield put({
			type: ACTIONS.PLANTS.SAVE_AUTOMOVE,
			data: result && result.data && result.data.list || []
		})

		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* addAutomoveDetails(action){
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.addAutomoveDetails, action.data);
		if(action.onSuccess){
			action.onSuccess()
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data Updated Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* getLOBListData(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		var queryString = "?" + Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
		const data = yield call(getData, API.getLOBList + queryString);
		yield put({
			type: ACTIONS.PLANTS.STORE_LOB_LIST,
			data: data.data
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : 'We Are Facing Some Technical Issue. Please Try After Sometime.' }
		})
	}
}

function* getConsigneesCityList(action) {
	let url;
	if (action.data.search)
		url = action.data.company_id + "&search=" + action.data.search;
	else
		url = action.data.company_id;

	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getConsigneeCityList + url);
		yield put({
			type: ACTIONS.PLANTS.STORE_CITIES_LIST,
			data: data.data
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
	}
}

function* getIndentConfigDetails(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		var queryString = "?" + Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
		const data = yield call(getData, API.getIndentConfigURL + queryString);
		if(action.onSuccess) {
			action.onSuccess(data && data.data);
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: (e && e.responseJSON && e.responseJSON.message) || "We Are Facing Some Technical Issue. Please Try After Sometime." }
		})
	}
}

function* addIndentConfigDetails(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.addIndentConfigURL, action.data);
		if(action.onSuccess) {
			action.onSuccess();
		}
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data Added Successfully!" }
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: (e && e.responseJSON && e.responseJSON.message) || "We Are Facing Some Technical Issue. Please Try After Sometime." }
		})
	}
}

function* saveIndentConfigDetails(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(putData, API.saveIndentConfigURL, JSON.stringify(action.data));
		if(action.onSuccess) {
			action.onSuccess();
		}
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data Updated Successfully!" }
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: (e && e.responseJSON && e.responseJSON.message) || "We Are Facing Some Technical Issue. Please Try After Sometime." }
		})
	}
}

function* getWeighbridgeMasterListing(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const queryString = Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');

		const data = yield call(getData, API.weighbridgeMasterListingUrl + queryString);
		yield put({
			type: ACTIONS.PLANTS.SAVE_WEIGH_BRIDGE_MASTER_LISTING,
			data: data.data || []
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
	} catch (e) {
		console.log('ERROR', e);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	}
}

function* addWBMaster(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.createWeighbridgeMasterUrl, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		if (action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data added Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* updateWBMaster(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(putData, API.updateWeighbridgeMasterUrl, JSON.stringify(action.data));
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		if (action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data Updated Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* deleteWBMaster(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(deleteData, API.deleteWeighbridgeMasterUrl, JSON.stringify(action.data));
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		if (action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data Deleted Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* getIndentingDashboardList(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getIndentingDashboardListUrl, action.data);
		yield put({
			type: ACTIONS.PLANTS.STORE_INDENTING_DASHBOARD_LIST,
			data: data.data && (data.data.list || [])
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
	}
}

function* getParkingLotListing(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const queryString = Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');

		const data = yield call(getData, API.parkingLotListingUrl + queryString);
		yield put({
			type: ACTIONS.PLANTS.SAVE_PARKING_LOT_LISTING,
			data: data.data && data.data.length && data.data[0].parking_area || []
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		console.log('ERROR', e);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	}
}

function* addParkingLot(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.createParkingLotUrl, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		if (action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data added Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* updateParkingLot(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(putData, API.updateParkingLotUrl, JSON.stringify(action.data));
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		if (action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data Updated Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* deleteParkingLot(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const queryString = Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');

		const data = yield call(deleteData, API.deleteParkingLotUrl + queryString);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		if (action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data Deleted Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* getAllYards(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getAllYardsUrl + action.params);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if(action.onSuccess){
			action.onSuccess(data.data)
		}
	} catch (e) {
		console.log('ERROR', e);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	}
}

function* getReportsDashboardList(action) {
	try {
		const url = `${API.getReportsDashboardsListURL}?company_id=${(action.params || {}).company_id}`
		const data = yield call(getData, url);
		yield put({
			type: ACTIONS.PLANTS.SAVE_REPORTS_DASHBOARDS_LIST,
			data: data.data ||  {}
		});
	} catch(e) {
		console.log('ERROR', e);
	}
}
function* getMFSMasterDetails(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		
		
		const data = yield call(getData, API.getMfsMasterDetailsUrl + action.queryString);
		
		yield put({
			type: ACTIONS.PLANTS.SAVE_MFS_MASTER_DETAILS,
			data:data.data || []
		});
		if(action.onSuccess){
			action.onSuccess(data);
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		
	} catch (e) {
		console.log('ERROR', e);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	}
}
function* createMfsMasterDetails(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.createMfsMasterDetailsUrl, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		if (action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data added Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}
function* updateMfsMasterDetails(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(putData, API.updateMfsMasterDetailsUrl, JSON.stringify(action.data));
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		if (action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data Updated Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}
function* deleteMfsMasterDetails(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		
		const data = yield call(deleteData, API.deleteMfsMasterDetailsUrl + action.params);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		if (action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data Deleted Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}
function* getMfsMasterUomDetails(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});


		const data = yield call(getData, API.getMfsMasterUomDetailsUrl ,{});

		yield put({
			type: ACTIONS.PLANTS.SAVE_MFS_MASTER_UOM_DETAILS,
			data: data.data || []
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

	} catch (e) {
		console.log('ERROR', e);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	}
}

export function* downloadMfsMaster(action) {
	try {
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER,
	  });
	  let url = action.withData
		? API.downloadTemplateMFS
		: API.downloadDataMFS;
	  const data = yield call(getData, url + action.queryString);
  
	  if (action.onSuccess) {
		action.onSuccess(data.data);
	  }
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER,
	  });
	} catch (e) {
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER,
	  });
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: {
		  type: 2,
		  msg: e && e.message ? e.message : "Oops! Something went wrong.",
		},
	  });
	}
  }
  
  export function* uploadMFSMasterDetails(action) {
	try {
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER,
	  });
	  let url = `${API.uploadDataMFS}`;
	  const data = yield call(
		fileUpload,
		url+ action.queryString ,
		action.data
	  );
  
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER,
	  });
	  if (action.onSuccess) {
		action.onSuccess(data);
	  }
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: { type: 1, msg:(data && data.message)|| "Excel uploaded Sucessfully!" },
	  });
	} catch (e) {
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER,
	  });
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: {
		  type: 2,
		  msg: (data && data.message) || "Oops! Something went wrong.",
		},
	  });
	}
  }

function* getServiceModes(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getServiceModesUrl);
		let updateData = !!data.data && (data.data || []).map(el => {
			return {
				...el,
				name: el.name,
				value: el.id,
				id: el.id
			}
		}) || []
		yield put({
			type: ACTIONS.PLANTS.SET_SERVICE_MODES,
			data: updateData
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		console.log('ERROR', e);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	}
}

function* getSlabRange(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		let queryString = Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
		
		const data = yield call(getData, API.getSlabRangeUrl + queryString);

		yield put({
			type: ACTIONS.PLANTS.SAVE_SLAB_RANGE_DETAILS,
			data: (data.data && data.data.list) || []
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

	} catch (e) {
		console.log('ERROR', e);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	}
}

function* createSlabRangeDetails(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.addSlabRangeUrl, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		if (action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data added Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function* deleteSlabRange(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		
		const data = yield call(deleteData, API.deleteSlabRangeUrl, JSON.stringify(action.data));
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});

		if (action.onSuccess) {
			action.onSuccess();
		}

		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message || "Data Deleted Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON.message }
		})
	}
}

function * getPlantsCategory(action){
	try{
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.addPlantCategoryUrl + action.queryString);
		yield put({
			type: ACTIONS.PLANTS.SET_ADD_PLANT_CATEGORY,
			data: data.data
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
	}
}
	function * fetchDepotName(action) {
				try {
					yield put ({
						type: ACTIONS.LOADER.SHOW_LOADER
					});
					const data = yield call(getData, API.fetchDepotName +action.queryString);
					yield put({
						type: ACTIONS.PLANTS.SET_DEPO_NAME,
		data: data.data
				});
				yield put({
					type: ACTIONS.LOADER.HIDE_LOADER

		})
		
			}catch (e) {

		}
}

function * getInventoryMaster(action) {
	try {
			yield put({
				type: ACTIONS.LOADER.SHOW_LOADER
			});
	const data = yield call(getData, API.getInventoryMasterUrl + action.queryString);
			yield put({
				type:ACTIONS.PLANTS.SET_INVENTORY_MASTER,
				data:data.data.list
			});
			yield put({
				type: ACTIONS.LOADER.HIDE_LOADER
			});
		} catch (e) {
		}
	}
	function * addInventoryMaster(action) {
		try {
			yield put({
				type: ACTIONS.LOADER.SHOW_LOADER
			});
			const data = yield call(postData, API.addInventoryMasterUrl, action.data);
			yield put({
				type: ACTIONS.LOADER.HIDE_LOADER
			});
			yield put({
				type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data: { type: 1, msg: (data && data.message) || " Added Successfully!" }
			})
			if(action.onSuccess){
				action.onSuccess();
			}
		} catch (e) {
			yield put({
				type: ACTIONS.LOADER.HIDE_LOADER
			});
			yield put({
				type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data: { type: 2, msg: e.responseJSON.message }
			})
		}
	}
	function * updateInventoryMaster(action){
		try {
			yield put({
				type: ACTIONS.LOADER.SHOW_LOADER
			});
			const data = yield call(postData, API.updateInventoryMasterUrl, action.data);
			if(action.onSuccess){
				action.onSuccess();
			}
			yield put({
				type: ACTIONS.LOADER.HIDE_LOADER
			});
			yield put({
				type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data: {type: 1, msg: (data && data.message) ||
					"Record Updated Successfully",
				}
			});
		} catch (e) {
			yield put({
				type: ACTIONS.LOADER.HIDE_LOADER,
			  });
			  yield put({
				type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
				},
			  });
		}
	}
	
	function * deleteInventoryMaster(action) {
		try {
			yield put({
				type: ACTIONS.LOADER.SHOW_LOADER
			});
			const data = yield call(deleteData, API.deleteInventoryMasterUrl + action.queryString);
			if (action.onSuccess) {
				action.onSuccess(data);
			}
			yield put({
				type: ACTIONS.LOADER.HIDE_LOADER
			});
			yield put({
				type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data: {type: 1, msg: (data && data.message) ||
					"Record Deleted Successfully",
				}
			});
		} catch (e) {
			yield put({
				type: ACTIONS.LOADER.HIDE_LOADER,
			  });
			  yield put({
				type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data: {
				  type: 2,
				  msg: e && e.message ? e.message : "Oops! Something went wrong.",
				},
			  });
		}
	}

	function * getSalesDistrictMappingMaster(action) {
		try {
				yield put({
					type: ACTIONS.LOADER.SHOW_LOADER
				});
		const data = yield call(getData, API.getSalesdistrictMappingMasterUrl + action.queryString);
				yield put({
					type:ACTIONS.PLANTS.SET_DISTRICT_MAPPING_MASTER,
					data:data.data.list
				});
				yield put({
					type: ACTIONS.LOADER.HIDE_LOADER
				});
			} catch (e) {
			}
		}
		function * addSalesDistrictMappingMaster(action) {
			try {
				yield put({
					type: ACTIONS.LOADER.SHOW_LOADER
				});
				const data = yield call(postData, API.addSalesdistrictMappingMasterUrl, action.data);
				yield put({
					type: ACTIONS.LOADER.HIDE_LOADER
				});
				yield put({
					type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
					data: { type: 1, msg: (data && data.message) || " Added Successfully!" }
				})
				if(action.onSuccess){
					action.onSuccess();
				}
			} catch (e) {
				yield put({
					type: ACTIONS.LOADER.HIDE_LOADER
				});
				yield put({
					type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
					data: { type: 2, msg: e.responseJSON.message }
				})
			}
		}
		function * updateSalesDistrictMappingMaster(action){
			try {
				yield put({
					type: ACTIONS.LOADER.SHOW_LOADER
				});
				const data = yield call(postData, API.updateSalesdistrictMappingMasterUrl, action.data);
				if(action.onSuccess){
					action.onSuccess();
				}
				yield put({
					type: ACTIONS.LOADER.HIDE_LOADER
				});
				yield put({
					type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
					data: {type: 1, msg: (data && data.message) ||
						"Record Updated Successfully",
					}
				});
			} catch (e) {
				yield put({
					type: ACTIONS.LOADER.HIDE_LOADER,
				  });
				  yield put({
					type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
					data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Something Went Wrong",
					},
				  });
			}
		}
		function * deleteSalesDistrictMappingMaster(action) {
			try {
				yield put({
					type: ACTIONS.LOADER.SHOW_LOADER
				});
				const data = yield call(deleteData, API.deleteSalesdistrictMappingMasterUrl + action.queryString);
				if (action.onSuccess) {
					action.onSuccess(data);
				}
				yield put({
					type: ACTIONS.LOADER.HIDE_LOADER
				});
				yield put({
					type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
					data: {type: 1, msg: (data && data.message) ||
						"Record Deleted Successfully",
					}
				});
			} catch (e) {
				yield put({
					type: ACTIONS.LOADER.HIDE_LOADER,
				  });
				  yield put({
					type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
					data: {
					  type: 2,
					  msg: e && e.message ? e.message : "Oops! Something went wrong.",
					},
				  });
			}
		}

	function * getAllMoves(action) {
		try {
			yield put({
				type: ACTIONS.LOADER.SHOW_LOADER
			});
			const data = yield call(getData, API.fetchAllMoves + action.queryString);
				yield put({
					type:ACTIONS.PLANTS.SET_INDENTING_MOVES,
					data:data.data
				});
				yield put({
					type: ACTIONS.LOADER.HIDE_LOADER
				});
			} catch (e) {
			}
		}

	function* addReasons(action) {
			try {
				yield put({
					type: ACTIONS.LOADER.SHOW_LOADER
				});
				var queryString = "?" + Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
				const data = yield call(postData, API.addReasonsUrl+queryString);
				yield put({
					type: ACTIONS.LOADER.HIDE_LOADER
				});
				yield put({
					type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
					data: { type: 1, msg: "Action added Successfully!" }
				})
				yield put({
					type: ACTIONS.PLANTS.GET_INDENTING_REASONS,
					data: action.param
				})
			} catch (e) {
				yield put({
					type: ACTIONS.LOADER.HIDE_LOADER
				});
				yield put({
					type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
					data: { type: 2, msg: e.responseJSON.message }
				})
			}
		}
		function* updateReasons(action) {
			try {
				yield put({
					type: ACTIONS.LOADER.SHOW_LOADER
				});
				var queryString = "?" + Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
				
				const data = yield call(putData, API.updateReasonsUrl+queryString);
				yield put({
					type: ACTIONS.LOADER.HIDE_LOADER
				});
				yield put({
					type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
					data: { type: 1, msg: "Action added Successfully!" }
				})
				yield put({
					type: ACTIONS.PLANTS.GET_INDENTING_REASONS,
					data: action.param
				})
			} catch (e) {
				yield put({
					type: ACTIONS.LOADER.HIDE_LOADER
				});
				yield put({
					type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
					data: { type: 2, msg: e.responseJSON.message }
				})
			}
		}
		function* getReasons(action) {
			try {
				yield put({
					type: ACTIONS.LOADER.SHOW_LOADER
				});
				var queryString = "?" + Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
				
				const data = yield call(getData, API.getReasonsUrl+queryString);
				yield put({
					type: ACTIONS.PLANTS.STORE_REASONS,
					data: data.data.reasons
				});
				yield put({
					type: ACTIONS.LOADER.HIDE_LOADER
				});
			} catch (e) {
			}
		}
		function* deleteReasons(action) {
			try {
				yield put({
					type: ACTIONS.LOADER.SHOW_LOADER
				});
				var queryString = "?" + Object.keys(action.data).map(key => key + '=' + action.data[key]).join('&');
				
				const data = yield call(deleteData, API.deleteReasonUrl+queryString);
				yield put({
					type: ACTIONS.LOADER.HIDE_LOADER
				});
				yield put({
					type: ACTIONS.PLANTS.GET_INDENTING_REASONS,
					data: action.param
				})
				if (action.onSuccess) {
					action.onSuccess();
				}
				yield put({
					type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
					data: { type: 1, msg: data.message || "Data Deleted Successfully!" }
				})
			} catch (e) {
				yield put({
					type: ACTIONS.LOADER.HIDE_LOADER
				});
				yield put({
					type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
					data: { type: 2, msg: e.responseJSON.message }
				})
			}
		}
			function* lorealdownloadUserDetailsTemplate(action) {
      try {
        yield put({
          type: ACTIONS.LOADER.SHOW_LOADER,
        });
        const data = yield call(getData, API.lorealdownloadUserDetailsTemplate);
        if (action.onSuccess) {
          action.onSuccess((data && data.data) || []);
        }
        yield put({
          type: ACTIONS.LOADER.HIDE_LOADER,
        });
      } catch (e) {
        console.log("e", e);
        yield put({
          type: ACTIONS.LOADER.HIDE_LOADER,
        });
        yield put({
          type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
          data: {
            type: 2,
            msg:
              (e && e.responseJSON && e.responseJSON.message) ||
              "We Are Facing Some Technical Issue. Please Try After Sometime.",
          },
        });
      }
    }

	function* uploadLorealUserDetails(action) {
      try {
        yield put({
          type: ACTIONS.LOADER.SHOW_LOADER,
        });
        var queryString =
          "?" +
          Object.keys(action.param)
            .map((key) => key + "=" + action.param[key])
            .join("&");
        const data = yield call(
          fileUpload,
          API.lorealuploadUserDetails + queryString,
          action.data
        );
        if (action.onSuccess) {
          action.onSuccess();
        }
        yield put({
          type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
          data: { type: 1, msg: "Data Uploaded Sucessfully!" },
        });
        yield put({
          type: ACTIONS.LOADER.HIDE_LOADER,
        });
      } catch (e) {
        console.log("e", e);
        yield put({
          type: ACTIONS.LOADER.HIDE_LOADER,
        });
        yield put({
          type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
          data: {
            type: 2,
            msg:
              (e && e.responseJSON && e.responseJSON.message) ||
              "We Are Facing Some Technical Issue. Please Try After Sometime.",
          },
        });
      }
    }
		

export {
	getPlantsDepots,
	getZones,
	getBranchTypes,
	updatePlant,
	createNew,
	getDepotUsers,
	getBranches,
	verifyUser,
	getActions,
	updateUser,
	createUser,
	toggleUserStatus,
	deleteUser,
	getIndentingFlows,
	getIndentingItems,
	getIndentingUsers,
	getIndentingMoves,
	getIndentingSubMoves,
	getIndentingActions,
	getIndentingChecklists,
	getIndentingWarehouses,
	getIndentingVendors,
	addMove,
	addAction,
	addChecklist,
	addWarehouse,
	addVendor,
	submitMoves,
	deleteMove,
	getIndentingFlowDetails,
	getSubPlants,
	addSubPlants,
	getActionTypes,
	getAllBranch,
	getIndentingFields,
	addIndentingFields,
	addLoadingBay,
	getBayDetails,
	getGangDetails,
	addLoadingGang,
	deleteLoadingBay,
	deleteLoadingGang,
	updateLoadingGang,
	updateLoadingBay,
	getIndentingEditFields,
	saveMoveStandardTime,
	getMoveStandardTime,
	updateMoveStandardTime,
	getApproverList,
	updateApproverList,
	postApproverList,
	applyApproverToOtherBranches,
	saveWeighBridge,
	getDepartmentsEmailList,
	getAllApproverList,
	getWBConversion,
	addWBConversion,
	deleteWBConversion,
	updateWBConversion,
	getWarehouseList,
	getWeighbridgeDetails,
	uploadTermConditions,
	editSubplantDetails,
	deletePlantsDepots,
	getWarehouseDetails,
	editWarehouseDetails,
	getIndentingOperationTime,
	addOperationTime,
	editOperationTime,
	deleteOperationTime,
	branchTransfer,
	deactivateBranchUser,
	getDepartmentAndUserDetails,
	downloadUserDetailsTemplate,
	uploadUserDetails,
	applyIndentFlowSettingsToBranches,
	getAutomoveDetails,
	addAutomoveDetails,
	getLOBListData,
	getMoveName,
	getConsigneesCityList,
	getIndentConfigDetails,
	saveIndentConfigDetails,
	addIndentConfigDetails,
	getWeighbridgeMasterListing,
	addWBMaster,
	updateWBMaster,
	deleteWBMaster,
	getIndentingDashboardList,
	getParkingLotListing,
	addParkingLot,
	updateParkingLot,
	deleteParkingLot,
	getAllYards,
	getVolumeticMaster,
	addVolumetricMaster,
	updateVolumetricMaster,
	deleteVolumetricMaster,
	getPackUOMDropdown,
	getReportsDashboardList,
	getMFSMasterDetails,
	createMfsMasterDetails,
	updateMfsMasterDetails,
	deleteMfsMasterDetails,
	getServiceModes,
	getCompanyWiseZones,
	getMfsMasterUomDetails,
	getRegionMasterDropdowns,
	getSlabRange,
	createSlabRangeDetails,
	deleteSlabRange,
	getPlantsCategory,
	fetchDepotName,
	addReasons,
	getReasons,
	updateReasons,
	deleteReasons,
	getInventoryMaster,
	addInventoryMaster,
	updateInventoryMaster,
	deleteInventoryMaster,
	getSalesDistrictMappingMaster,
	addSalesDistrictMappingMaster,
	updateSalesDistrictMappingMaster,
	deleteSalesDistrictMappingMaster,
	getAllMoves,
	lorealdownloadUserDetailsTemplate,
	uploadLorealUserDetails
};
