import React, { Fragment } from "react";
import Select from "react-select";
import { DateRangePicker, SingleDatePicker } from "react-dates";
import moment from "moment";
import ACTIONS from "../../../common/action_constant";
import Download from "../../../../assets/images/download.svg";
import Plus from "../../../../assets/images/add.svg";
import Delete from "../../../../assets/images/delete.svg";
import Cancel from "../../../../assets/images/cancel.svg";
import "./clusterMapping.less";
import Loader from "../../../common/components/loader/loader";
import e from "cors";
import { connect } from "react-redux";
import { getUserCompanyName } from "../../../../utils/common";
import Edit from '../../../../assets/images/edit.svg'
import Save from '../../../../assets/images/save.svg'

import SearchText from "../../../common/components/search-text/search-text";
import MainComponent from "../../main/main";
import { Link } from "react-router-dom";
import Axios from "axios";
import { message } from 'antd';
import MessagePopUp from "../../../common/components/msg-popup/msg-popup";


class ClusterMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      plantsOptions: [],
      company_id: "",
      addClusterSection: false,
      tableData: [],
      fileName: null,
      addedClusters: [
        {
          locCodeParent: "",
          clusterName: "",
          cluster_date: "",
          childDetails: [
            {
              locChild: "",
              locCodeChild: "",
              zoneChild: ""
            }
          ]
        }
      ]
    };
  }

  componentDidMount() {
    this.getAllClusterMappingData()
    this.getAllLOCOptionsData()
    this.getIPAddress()
  }

  getAllClusterMappingData = () => {
    const { dispatch } = this.props;
    const { selectedPlant } = this.state

    dispatch({
      type: ACTIONS.PARENTCHILD.GET_ALL_CLUSTER_MAPPING_DATA,
      ...(selectedPlant ? { plantName: selectedPlant } : {}),
      onSuccess: ((data) => {
        if (data && data.list) {
          if(data.list.list && data.list.list.length > 0){
            this.setState({ tableData: data.list.list })
          }
          if(data.list.plant_list && data.list.plant_list.length > 0){ 
            let temp = [] 
            data.list.plant_list.map((d) => {
              temp.push({label: d, value: d})
            })
            this.setState({ plantsOptions: temp })
          }
        }
      })
    })
  }

  getAllLOCOptionsData = () => {
    const { dispatch } = this.props;

    dispatch({
      type: ACTIONS.PARENTCHILD.GET_LOC_CHILD_OPTIONS,
      onSuccess: ((data) => {
        if (data && data.list) {
          let temp = []
          data.list.map((d) => {
            temp.push({
              label: d.location_child,
              value: d.location_child,
              location_code_child: d.location_code_child,
              zone_child: d.zone_child
            })
          })
          this.setState({ locOptions: temp })
        }
      })
    })
  }

  changeHandler = (val, id) => {
    this.setState({
      [id]: val
    });
  }

  onAddCluster = () => {
    const { addedClusters } = this.state;

    addedClusters.push({
      locCodeParent: "",
      clusterName: "",
      cluster_date: "",
      childDetails: [
        {
          locChild: "",
          locCodeChild: "",
          zoneChild: ""
        }
      ]
    })

    this.setState({ addedClusters })

  }

  onRemoveCluster = (index) => {

    const { addedClusters } = this.state;

    let tempCluster = addedClusters;

    tempCluster = tempCluster.filter((c, cIndex) => cIndex !== index)

    this.setState({ addedClusters: tempCluster })

  }

  onAddChild = (clusterIndex) => {
    const { addedClusters } = this.state;
    addedClusters[clusterIndex].childDetails.push({
      locChild: "",
      locCodeChild: "",
      zoneChild: ""
    })
    this.setState({ addedClusters })
  }

  onRemoveChild = (clusterIndex, childIndex) => {
    const { addedClusters } = this.state;
    addedClusters[clusterIndex].childDetails.splice(childIndex, 1)
    this.setState({ addedClusters })
  }

  handleChange = (value, id, parentIndex, childIndex) => {
    const { addedClusters } = this.state

    if (childIndex) {
      addedClusters[parentIndex].childDetails[childIndex][id] = value
    }
    else {
      addedClusters[parentIndex][id] = value
    }
    this.setState({ addedClusters })
  }

  addClusterMappingData = () => {
    const { addedClusters } = this.state;
    const { dispatch } = this.props;
    let payload = [];

    addedClusters.map((parentData) => {
      parentData.childDetails.map((childData) => {
        payload.push({
          location_code_parent: parentData.locCodeParent,
          cluster_code: parentData.clusterName,
          location_child: childData.locChild,
          location_code_child: childData.locCodeChild,
          zone_child: childData.zoneChild,
          cluster_date: parentData.cluster_date
        })
      })
    })

    dispatch({
      type: ACTIONS.PARENTCHILD.ADD_CLUSTER_MAPPING_DATA,
      data: { list: payload },
      onSuccess: ((data) => {
        this.getAllClusterMappingData()
        let newState = [
          {
            locCodeParent: "",
            clusterName: "",
            cluster_date: "",
            childDetails: [
              {
                locChild: "",
                locCodeChild: "",
                zoneChild: ""
              }
            ]
          }
        ]
        this.setState({
          addedClusters: newState
        })
      })
    })
  }

  deleteClusterMappingData = (data) => {
    const { dispatch } = this.props;

    dispatch({
      type: ACTIONS.PARENTCHILD.DELETE_CLUSTER_MAPPING_DATA,
      data: data.cluster_id,
      onSuccess: (() => {
        this.getAllClusterMappingData()
      })
    })
  }

  handleEditChange = (value, id, tIdx) => {
    const { tableData } = this.state;
    tableData[tIdx][id] = value;
    this.setState({ tableData })
  }

  editDataSaveIcon = (data) => {
    const { dispatch } = this.props;
    delete data.is_Edit
    delete data.is_selected
    delete data.focused
    dispatch({
      type: ACTIONS.PARENTCHILD.EDIT_CLUSTER_MAPPING_DATA,
      data: data,
      onSuccess: (() => {
        this.getAllClusterMappingData()
      })
    })
  }

  downloadTemplate = () => {
    const { dispatch } = this.props;

    dispatch({
      type: ACTIONS.PARENTCHILD.DOWNLOAD_TEMPLATE_IN_UPLOAD_LOCATION,
      data: 2,
      onSuccess: ((data) => {
        const element = document.createElement('a');
        element.setAttribute('href', data.link);
        element.setAttribute('download', true);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      })
    })
  }

  getIPAddress = async () => {
    const res = await Axios.get('https://geolocation-db.com/json/')
    this.setState({ ip_address: res.data.IPv4 })
  }

  fileClicked = (e) => {
    e.preventDefault();
    document.getElementById("file").click();
  };

  fileUpload = e => {
    e.persist();
    this.setState({
      fileName: e.target.files[0]
    });
  };

  uploadFile = async () => {
    const { dispatch } = this.props;
    const { ip_address } = this.state;
    const formData = new FormData();

    if (!this.state.fileName) {
      message.error('Please Upload file first!')
    } else {
      formData.append("file", this.state.fileName);
      formData.append('ip_address', ip_address)
      formData.append('type', 2)

      dispatch({
        type: ACTIONS.PARENTCHILD.UPLOAD_FILE_IN_UPLOAD_LOCATION,
        file: formData,
        onSuccess: ((data) => {
          this.setState({ fileName: null })
          this.getAllClusterMappingData()
        }),
      });
    }
  };

  closeMsgPopup = () => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.MSG_DISPLAY.HIDE_MSG })
  }

  handleChecked = (Idx) => {
    const { tableData } = this.state;
    tableData[Idx].is_selected = tableData[Idx].is_selected ? false : true
    this.setState({ tableData })
  }

  allDataChecked = () => {
    const { tableData } = this.state;
    if (!tableData.find((d) => !d.is_selected)) {
      tableData.map((d) => d.is_selected = false)
    } else {
      tableData.map((d) => d.is_selected = true)
    }
    this.setState({ tableData })
  }

  deleteAllData = () => {
    const { tableData } = this.state;
    const { dispatch } = this.props;
    const filteredData = tableData.filter((d) => d.is_selected)
    let temp = []
    filteredData.map((d) => {
      temp.push({ cluster_id: d.cluster_id })
    })
    let payload = {
      type: 2,
      list: temp
    }
    dispatch({
      type: ACTIONS.PARENTCHILD.DELETE_ALL_CHILD_DATA,
      data: payload,
      onSuccess: (() => {
        this.getAllClusterMappingData()
      })
    })
  }

  render() {
    const { tableData, locOptions, plantsOptions } = this.state
    const { loader } = this.props;
    const customStyles = {
      control: (base) => ({
        ...base,
        borderColor: "#528aea",
      }),
    };
    return (
      <div className="master">
        {loader.isLoading && <Loader />}
        {
          this.props.msgpopup.active && <MessagePopUp close={this.closeMsgPopup} type={this.props.msgpopup.type} msg={this.props.msgpopup.msg} />
        }
        <MainComponent>
          <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <Link to='/locationMgmt' className="link">
              <h4>
                Back
              </h4>
            </Link>
            <h2>
              Cluster Mapping
            </h2>
          </div>
          <div className="CM">
            <div className="upload">
              <div className="border">
                <div className="flex-item flex-between">
                  <div className="flex-item">
                  </div>
                </div>
                <div style={{ marginTop: '10px' }} className="flex-item">

                  <div className="template" onClick={() => this.downloadTemplate()}>
                    <div
                      className={"download-icon"}
                      style={{
                        backgroundImage: `url(${Download})`,
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <div className="template-text">Template</div>
                  </div>

                  <div className="file-holder">
                    <input id="file" type="file" onChange={this.fileUpload} />
                    <span className="file-choose" onClick={this.fileClicked}>
                      Browser
                    </span>
                    <span className="file-name">
                      {this.state.fileName
                        ? this.state.fileName.name
                        : "No file selected"}
                    </span>
                  </div>

                  <div className="upload-button" onClick={this.uploadFile}>
                    Upload
                  </div>

                </div>
              </div>

              {!this.state.addClusterSection && <div className="upload-button" style={{ marginTop: '20px', width: '120px' }} onClick={() => { this.setState({ addClusterSection: true }) }}>
                + Add Cluster
              </div>}

              {
                this.state.addClusterSection &&
                <Fragment>
                  <div className="border" style={{ marginTop: '20px', width: '110%', padding: '20px 0px' }}>
                    <div style={{ borderBottom: '1px solid #528aea' }}>
                      <p style={{ padding: '0px 0px 10px 20px' }}>
                        Add Cluster
                      </p>
                    </div>
                    <div>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '95%', margin: '0 2.5% 0 2.5%' }}>
                        {
                          this.state.addedClusters.map((cluster, clIndex) => {
                            return (
                              <div style={{ display: 'flex', borderBottom: '1px solid #528aea', paddingBottom: '20px', marginTop: '15px' }}>
                                <div style={{ display: 'flex' }}>
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '14px', paddingBottom: '5px' }}>LOC Code Parent</span>
                                    <input
                                      id="locCodeParent"
                                      type={"text"}
                                      className="input-box"
                                      placeholder="LOC Code Parent"
                                      value={cluster.locCodeParent}
                                      onChange={(e) => this.handleChange(e.target.value, 'locCodeParent', clIndex)}
                                    />
                                  </div>
                                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                                    <span style={{ fontSize: '14px', paddingBottom: '5px' }}>Cluster Name</span>
                                    <input
                                      id="clusterName"
                                      type={"text"}
                                      className="input-box"
                                      placeholder="Cluster Name"
                                      value={cluster.clusterName}
                                      onChange={(e) => this.handleChange(e.target.value, 'clusterName', clIndex)}
                                    />
                                  </div>

                                  <div style={{ display: 'flex', flexDirection: 'column', paddingRight: '20px', marginLeft: '10px', borderRight: '1px solid #528aea' }}>
                                    <span style={{ fontSize: '14px', paddingBottom: '5px' }}>Date</span>
                                    <div className="singleDatePickerCluster">
                                      <SingleDatePicker
                                        noBorder={true}
                                        displayFormat={"DD/MM/YYYY"}
                                        hideKeyboardShortcutsPanel={true}
                                        placeholder={"Date"}
                                        numberOfMonths={1}
                                        date={cluster.cluster_date && moment(cluster.cluster_date) || null}
                                        onDateChange={(from) => {
                                          const { addedClusters } = this.state;
                                          addedClusters[clIndex].cluster_date = moment(from).valueOf() || null;
                                          this.setState({ addedClusters });
                                        }}
                                        focused={cluster.focused}
                                        onFocusChange={({ focused }) => {
                                          const { addedClusters } = this.state
                                          addedClusters[clIndex].focused = focused
                                          this.setState({addedClusters})
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  {
                                    cluster.childDetails.map((child, chIndex) => {
                                      return (
                                        <div style={{ display: 'flex', marginLeft: '20px' }}>
                                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ fontSize: '14px', paddingBottom: '5px' }}>LOC Child</span>
                                            <Select
                                              styles={customStyles}
                                              options={locOptions || []}
                                              onChange={(item) => {
                                                const { addedClusters } = this.state;
                                                addedClusters[clIndex].childDetails[chIndex].locChild = item.label
                                                addedClusters[clIndex].childDetails[chIndex].locCodeChild = item.location_code_child
                                                addedClusters[clIndex].childDetails[chIndex].zoneChild = item.zone_child
                                                this.setState({ addedClusters })
                                              }}
                                              className="dropdown-cluster"
                                              placeholder="LOC Child"
                                              selectedItem={(option) => option.label === child.locChild}
                                            />
                                          </div>
                                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                                            <span style={{ fontSize: '14px', paddingBottom: '5px' }}>LOC Code Child</span>
                                            <input
                                              id="locCodeChild"
                                              type={"text"}
                                              className="input-box"
                                              placeholder="LOC Code Child"
                                              value={child.locCodeChild}
                                              onChange={(e) => this.handleChange(e.target.value, 'locCodeChild', clIndex, chIndex)}
                                            />
                                          </div>
                                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                                            <span style={{ fontSize: '14px', paddingBottom: '5px' }}>Zone Child</span>
                                            <input
                                              id="zoneChild"
                                              type={"text"}
                                              className="input-box"
                                              placeholder="Zone Child"
                                              value={child.zoneChild}
                                              onChange={(e) => this.handleChange(e.target.value, 'zoneChild', clIndex, chIndex)}
                                            />
                                          </div>
                                          <div style={{ display: 'flex', marginLeft: '10px', gap: '10px', paddingTop: '20px' }}>
                                            {(chIndex === (cluster.childDetails.length - 1)) ?
                                              <img src={Plus} width="18px" style={{ cursor: 'pointer' }} onClick={() => this.onAddChild(clIndex)} />
                                              :
                                              <div style={{ width: "18px" }}></div>
                                            }
                                            {(chIndex != 0) ?
                                              <img src={Cancel} width="18px" style={{ cursor: 'pointer' }} onClick={() => this.onRemoveChild(clIndex, chIndex)} />
                                              :
                                              <div style={{ width: "18px" }}></div>
                                            }
                                          </div>
                                          <div style={{ display: 'flex', paddingRight: '5px', borderRight: '1px solid #528aea' }} />
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                                {(clIndex != 0) &&
                                  <div style={{ paddingLeft: '20px', alignSelf: 'center', marginTop: '22px' }}>
                                    <img src={Cancel} width="20px" style={{ cursor: 'pointer' }} onClick={() => { this.onRemoveCluster(clIndex) }} />
                                  </div>
                                }

                              </div>
                            )
                          })
                        }
                      </div>
                      <div style={{ marginTop: '20px', padding: '0px 0px 10px 20px', color: '#528aea', display: 'flex', cursor: 'pointer', width: '15%' }} onClick={this.onAddCluster}>
                        <img src={Plus} width="22px" />
                        <p style={{ marginLeft: '8px', paddingTop: '5px' }}>Add Cluster</p>
                      </div>
                    </div>
                    <div className="flex-item" style={{ justifyContent: 'flex-end', paddingRight: '10px' }}>
                      <div className="cancel-button" onClick={() => { this.setState({ addClusterSection: false }) }}>
                        Cancel
                      </div>
                      <div className="upload-button" onClick={() => this.addClusterMappingData()}>
                        Add
                      </div>
                    </div>
                  </div>
                </Fragment>
              }

              <div style={{ display: 'flex', marginTop: '20px' }}>
                <div
                  className={"delete-icon"}
                  style={{ backgroundImage: `url(${Delete})`, marginLeft: '10px', marginRight: '20px' }}
                  onClick={() => this.deleteAllData()}
                />

                <Select
                  styles={customStyles}
                  options={plantsOptions || []}
                  onChange={(item) => {
                    this.setState({ selectedPlant: item.label }, () => {
                      this.getAllClusterMappingData()
                    })
                  }}
                  placeholder="Plant Name"
                  selectedItem={(option) => option.label === this.state.selectedPlant}
                  className='dropdown-cluster'
                />
              </div>

              <div className="table">
                <table className="table-view">
                  <thead>
                    <tr className="table-tr">
                      <th>
                        <CustomCheckBox toggle={() => this.allDataChecked()} selected={tableData.length > 0 && !tableData.find((d) => !d.is_selected)} />
                      </th>
                      <th className="tCenter" width="150px">
                        LOC Code Parent
                      </th>
                      <th className="tCenter" width="150px">
                        Cluster Name
                      </th>
                      <th className="tCenter" width="150px">
                        LOC Child
                      </th>
                      <th className="tCenter" width="150px">
                        LOC Code Child
                      </th>
                      <th className="tCenter" width="150px">
                        Zone Child
                      </th>
                      <th className="tCenter" width='150px'>
                        Date
                      </th>
                      <th className="tCenter" width="80px">
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {(tableData || []).map((data, tIdx) => {
                      return (
                        <tr className="tr-1">
                          <td>
                            {/* <input 
                                type="checkbox" 
                                checked={data.is_selected}
                                onChange={(e) => this.handleChecked(tIdx)}
                            /> */}
                            <CustomCheckBox id={tIdx} toggle={() => this.handleChecked(tIdx)} selected={data.is_selected} />
                          </td>
                          <td>
                            {
                              data.is_Edit == true ?
                                <input
                                  id="location_code_parent"
                                  type={"text"}
                                  className="input-box"
                                  placeholder="LOC Code Parent"
                                  value={data.location_code_parent}
                                  onChange={(e) => this.handleEditChange(e.target.value, 'location_code_parent', tIdx)}
                                />
                                :
                                <>{data.location_code_parent || '-'}</>
                            }
                          </td>
                          <td>
                            {data.is_Edit == true ?
                              <input
                                id="cluster_code"
                                type={"text"}
                                className="input-box"
                                placeholder="Cluster Name"
                                value={data.cluster_code}
                                onChange={(e) => this.handleEditChange(e.target.value, 'cluster_code', tIdx)}
                              />
                              :
                              <>{data.cluster_code || '-'}</>
                            }
                          </td>
                          <td>
                            {data.is_Edit == true ?
                              <Select
                                styles={customStyles}
                                options={locOptions || []}
                                onChange={(item) => {
                                  const { tableData } = this.state;
                                  tableData[tIdx].location_child = item.label;
                                  tableData[tIdx].location_code_child = item.location_code_child
                                  tableData[tIdx].zone_child = item.zone_child
                                  this.setState({ tableData })
                                }}
                                className="dropdown-cluster"
                                placeholder="LOC Child"
                                value={(locOptions || []).filter((option) => { return option.label == data.location_child })}
                                selectedItem={(option) => option.label === data.location_child}
                              />
                              :
                              <>{data.location_child || '-'}</>
                            }
                          </td>
                          <td>
                            {data.is_Edit == true ?
                              <input
                                id="location_code_child"
                                type={"text"}
                                className="input-box"
                                placeholder="LOC Code Child"
                                value={data.location_code_child}
                                onChange={(e) => this.handleEditChange(e.target.value, 'location_code_child', tIdx)}
                              />
                              :
                              <>{data.location_code_child || '-'}</>
                            }
                          </td>
                          <td>
                            {data.is_Edit == true ?
                              <input
                                id="zone_child"
                                type={"text"}
                                className="input-box"
                                placeholder="Zone Child"
                                value={data.zone_child}
                                onChange={(e) => this.handleEditChange(e.target.value, 'zone_child', tIdx)}
                              />
                              :
                              <>{data.zone_child || '-'}</>
                            }
                          </td>
                          <td className="singleDatePickerParent">
                            {data.is_Edit == true ?
                              <SingleDatePicker
                                noBorder={true}
                                displayFormat={"DD/MM/YYYY"}
                                hideKeyboardShortcutsPanel={true}
                                placeholder={"Date"}
                                numberOfMonths={1}
                                date={data.cluster_date && moment(data.cluster_date) || null}
                                onDateChange={(from) => {
                                  const { tableData } = this.state;
                                  tableData[tIdx].cluster_date = moment(from).valueOf() || null;
                                  this.setState({ tableData });
                                }}
                                focused={data.focused}
                                onFocusChange={({ focused }) => {
                                  const { tableData } = this.state;
                                  tableData[tIdx].focused = focused;
                                  this.setState({ tableData });
                                }}
                              />
                              :
                              <>{data.cluster_date && moment(data.cluster_date).format('DD/MM/YYYY') || '-'}</>
                            }
                          </td>
                          <td>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              {data.is_Edit ?
                                <img src={Save}
                                  width="22px"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => this.editDataSaveIcon(data)}
                                />
                                :
                                <img src={Edit} width="22px" style={{ cursor: 'pointer' }} onClick={() => {
                                  const { tableData } = this.state;
                                  tableData[tIdx].is_Edit = true;
                                  this.setState({ tableData })
                                }} />
                              }
                              <div
                                className={"delete-icon"}
                                style={{ backgroundImage: `url(${Delete})`, marginLeft: '10px' }}
                                onClick={() => this.deleteClusterMappingData(data)}
                              />
                            </div>
                          </td>
                        </tr>
                      )
                    })
                    }
                  </tbody>

                </table>
              </div>
            </div>
          </div>
        </MainComponent>
      </div>
    );
  }
}

const CustomCheckBox = (props) => {
  return (
    <div className={props.selected ? "custome-checkbox-wrap-parent-child selected" : "custome-checkbox-wrap-parent-child"} onClick={() => props.toggle(props.id)}>
      {props.selected && <div className="inner-circle"></div>}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    loader: state.loader,
    msgpopup: state.msgpopup,
  };
};

export default connect(mapStateToProps)(ClusterMapping);
