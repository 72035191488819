import React, { Component } from 'react';
import AddSalesdistrictMapping from './Add-Sales-district-MappingMaster';
import { withRouter } from 'react-router';
import Search from '../../../common/components/search-text/search-text';
import Button from '../../button-classic/button-classic';
import Edit from "./../../../../assets/images/edit.svg";
import Delete from "./../../../../assets/images/delete.svg";

class SalesdistrictMappingMaster  extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
            search: ''
        }
    }

    componentDidMount() {
        this.props.getSalesdistrictMapping();
    }

    addSalesDistrict = (editData = '') => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen, editData });
    }

    toggleDirectDelete = (sd) => {
        const data = {
            company_id: sd.company_id,
            region_code: sd.region_code,
        }
        this.props.deleteSalesDistrictMapping(data);
    }

    render() {

        const {SalesdistrictMappingData} = this.props;
        return(
            <div className="plants-depots-main-wrap">
                <div className="search-holder">
                <div className="add-plants-section">
                    <div style={{display: 'block', paddingBottom: '15px'}}>
                        <Search 
                        id = "search" 
                        value = {this.state.search} placeholder = "Search..." 
                        changeHandler = {(e) => this.setState({search: e})} 
                        click = {() => this.props.getSalesdistrictMapping(this.state.search)} 
                        canClear={this.state.search !== ''} 
                        handleClearFilter={() => this.setState({search: ''}, this.props.getSalesdistrictMapping())}
                        />
                    </div>
                    <Button value="+ Sales District" click={() => this.addSalesDistrict('')} />
                </div>
                </div>
                <div className="heading">
                    <div className="flow-head">Sales District</div>
                    <div className="flow-head">Description</div>
                    <div className="flow-head">Region Code</div>
                    <div className="flow-head">State</div>
                    <div className="flow-head">Actions</div>
                </div>
                {(SalesdistrictMappingData || []).map((sd, index) => {
                return(
                    <div className={"depot-users-list"} key={`sd-${index}`}>
                        <div className="flow-head">{sd.sales_district}</div>
                        <div className="flow-head">{sd.description}</div>
                        <div className="flow-head">{sd.region_code}</div>
                        <div className="flow-head">{sd.state}</div>
                        <div className="flow-head">
                             <div className = "delete-icon" style={{backgroundImage: `url(${Edit})`}} onClick = {() => this.addSalesDistrict(sd)}></div>
                             <div className = "delete-icon" style={{backgroundImage: `url(${Delete})`}} onClick = {() => this.toggleDirectDelete(sd)}></div>
                        </div>
                    </div>
                    )
                })}

                {(!SalesdistrictMappingData || SalesdistrictMappingData.length === 0) && <div className={'no_data_found'}>{'No Data Found'}</div>}

                {this.state.isOpen && <AddSalesdistrictMapping toggleAdd={this.addSalesDistrict} onAddSalesDistrictMapping={this.props.onAddSalesDistrictMapping} updateSalesDistrictMapping={this.props.updateSalesDistrictMapping} editData={this.state.editData}/>}
        </div>
        )
    }
}

export default (withRouter(SalesdistrictMappingMaster));
