import React, { Component, Fragment } from 'react';
import ReactToPrint from 'react-to-print';
import "./hsd-lanes-popup.less";
import Select from "react-select";
import { getCookie } from "../../../utils/cookies";

const monthNames = [
    {label: "January", value: 1},
    {label: "February", value: 2},
    {label: "March", value: 3},
    {label: "April", value: 4},
    {label: "May", value: 5},
    {label: "June", value: 6},
    {label: "July", value: 7},
    {label: "August", value: 8},
    {label: "September", value: 9},
    {label: "October", value: 10},
    {label: "November", value: 11},
    {label: "December", value: 12}
]

class HSDConsolidatedViewPopup extends Component{
    constructor(props){
        super(props);
            this.state= {
                selected_month: {},
                selected_cycle: {},
                vendor: {},
                selectedRfqs: []
            }
        }
    
    handleChangeRfq = (rfq) => { this.setState({ selectedRfqs: rfq }); }
    
    render() {
        const { consolidatedView, hsdConsolidatedRFQs, isSapDPL } = this.props;
        const { selected_month, selected_cycle, vendor, selectedRfqs } = this.state;
        const currentMonth = (monthNames || []).filter((obj) => {return obj.value === (new Date().getMonth() + 1)});
        const month = (selected_month && Object.keys(selected_month).length > 0) ? selected_month.value : (new Date().getMonth() === 12 ? 1 : new Date().getMonth() + 1);
        const year = new Date().getFullYear();
        const lastDay = new Date(year, month, 0).getDate();
        const user = JSON.parse(getCookie("user"));
        let rfqList = hsdConsolidatedRFQs.map((rfq) => ({
            label: rfq.rfq_name,
            value: rfq.rfq_id
        }))
        return (
            <Fragment>
                <div className="add-user-wrap">
                    <div className="overlay"></div>
                        <div id="modal" className="modal-popup" style={{width: '95%'}}>
                            <div className="fRight"><button className={"close-button"} onClick={() => this.props.closePopup()}>X</button></div>
                                <div className="heading" style={{color: '#528aea'}}>
                                
                                    <div className="divTableCell" style={{width: '300px'}}>
                                            <div className="divTableCell"> Month <span className='error-font'>*</span></div>
                                            <Select
                                                className="select wt-100p"
                                                placeholder={'Search Month'}
                                                options={(monthNames || [])}
                                                value={(monthNames || []).filter((obj) => {return obj.label === selected_month.label})}
                                                onChange={(value) => { 
                                                    const { selected_month } = this.state;
                                                    selected_month.value = value.value;
                                                    selected_month.label = value.label;
                                                    this.setState({ selected_month });
                                                }}
                                            />
                                        </div>
                                        <div className="divTableCell" style={{width: '300px'}}>
                                            <div className="divTableCell"> Cycle Type <span className='error-font'>*</span></div>
                                            <Select
                                                className="select wt-100p"
                                                placeholder={'Search Cycle'}
                                                options={[{value: 1, label: `1-${month}-${year} to 15-${month}-${year}`},{value: 2, label: `16-${month}-${year} to ${lastDay}-${month}-${year}`}, {value: 3, label: `1-${month}-${year} to ${lastDay}-${month}-${year}`}]}
                                                value={selected_cycle}
                                                onChange={(value) => { 
                                                    const { selected_cycle } = this.state;
                                                    selected_cycle.value = value.value;
                                                    selected_cycle.label = value.label;
                                                    this.setState({ selected_cycle });
                                                }}
                                            />
                                        </div>
                                        <div className="divTableCell" style={{float: "right", width: '300px'}}>
                                            <div className="divTableCell"> RFQ <span className='error-font'>*</span></div>
                                            <Select
                                                className="select wt-100p"
                                                placeholder={'Search RFQs'}
                                                options={rfqList || []}
                                                value={this.state.selectedRfqs}
                                                onChange={(rfq) => this.handleChangeRfq(rfq)}
                                                isMulti={true}
                                            />
                                        </div>
                                        <div className="divTableCell" style={{float: "right", width: '300px'}}>
                                            <div className="divTableCell"> Vendor </div>
                                            <Select
                                                className="select wt-100p"
                                                placeholder={'Search Vendor'}
                                                options={this.props.vendorTypes || []}
                                                value={(this.props.vendorTypes || []).filter(obj => {return obj.label===vendor.vendor_name})}
                                                onChange={(value) => { 
                                                    const { vendor } = this.state;
                                                    vendor.vendor_name = value.label;
                                                    vendor.vendor_id = value.value;
                                                    this.setState({ vendor });
                                                }}
                                            />
                                        </div>
                                        <div className="wt-250 mt-40" style={{marginLeft: 'auto'}}>
                                            <ReactToPrint content={() => this.componentRef} trigger={() => { return <input className="close-button" style={{margin: '5px'}} type="button" value="Print" />; }} />
                                            <button className={(selected_month.value && selected_cycle.value && selectedRfqs.length > 0) ? "close-button" : "close-button disable-btn"} style={{margin: '10px'}} onClick={() => this.props.consolidatedData(selected_month.value, selected_cycle.value, selectedRfqs, vendor.vendor_id)}>Submit</button>
                                        </div>
                                    
                                    </div>
                                    <div className="content p-t-0" ref={el => (this.componentRef = el)}>
                                        <img src={user.company_logo} className="company-logo" />
                                        <div className="tCenter mt-10" style={{fontSize: '18px', fontWeight: '500', color: '#528aea'}}>Freight Master</div>
                                        <div className="tCenter">{`Month: ${(selected_month && Object.keys(selected_month).length > 0) ? selected_month.label : currentMonth[0].label} ${selected_cycle.label ? selected_cycle.label : ''}`} </div>
                                        <div className="tCenter" style={{fontSize: '18px', fontWeight: '500', color: '#528aea'}}>Logistic Department</div>
                                    <div className="divTable company-list-wrap">
                                        <div className="list-section table-header divTableRow" style={{backgroundColor: '#e9f2ff'}}>
                                            <div className="divTableCell tCenter" style={{width: '1%'}}> SR No. </div>
                                            <div className="divTableCell tCenter" style={{width: '10%'}}> MATERIAL </div>
                                            <div className="divTableCell tCenter" style={{width: '10%'}}> TRANSPORTER NAME </div>
                                            <div className="divTableCell tCenter" style={{width: '10%'}}> VEHICLE TYPE </div>
                                            <div className="divTableCell tCenter" style={{width: '10%'}}> ORIGIN LOCATION  </div>
                                            <div className="divTableCell tCenter" style={{width: '10%'}}> DESTINATION LOCATION </div>
                                            <div className="divTableCell tCenter" style={{width: '8%'}}> RATE/MT </div>
                                            <div className="divTableCell tCenter" style={{width: '8%'}}> MINIMUM RATE GUARANTEE </div>
                                            <div className="divTableCell tCenter" style={{width: '10%'}}> EFFECTIVE DATE OF FREIGHT RATE APPLICABLE</div>
                                        </div>
                                        <div className="divTableBody">
                                        {(consolidatedView || []).map((data, index) => {
                                            let product = [];
                                            if(isSapDPL){
                                                (data.product_category_multiple || []).forEach((prd, indexPrd) => {
                                                   product.push(prd.name)
                                                })
                                            }
                                        return (
                                            <Fragment>
                                            <div key={index} className="list-section divTableRow">
                                                <div className="divTableCell tCenter"> {index+1} </div>
                                                <div className="divTableCell tCenter"> {isSapDPL ? product : data.material_name} </div>
                                                <div className="divTableCell tCenter"> {data.transporter_name} </div>
                                                <div className="divTableCell tCenter"> {data.vehicle_type} </div>
                                                <div className="divTableCell tCenter"> {data.from} </div>
                                                <div className="divTableCell tCenter"> {data.to} </div>
                                                <div className="divTableCell tCenter"> {(data.rate || 0).toFixed(2)} </div>
                                                <div className="divTableCell tCenter"> {(data.minimum_guarantee || 0).toFixed(2)} </div>
                                                <div className="divTableCell tCenter"> {data.date_range} </div>
                                                
                                                </div>
                                        </Fragment>
                                        )})}
                                    </div>
                                </div>
                                <div className="divTable company-list-wrap">
                                    <table style={{width: '90%'}}>
                                        <thead style={{backgroundColor: '#e9f2ff'}}>
                                            <th style={{width: '20%'}}>**</th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{backgroundColor: '#e9f2ff'}}>Signature</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td style={{backgroundColor: '#e9f2ff'}}>Designation</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td style={{backgroundColor: '#e9f2ff'}}>Date</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        
                    </div>
                </div>
            </Fragment>
        )
    }
}    
                
                 

export default HSDConsolidatedViewPopup;