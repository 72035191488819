import { put, call, select } from "redux-saga/effects";
import ACTIONS from "../common/action_constant";
import API from "../common/api_config";
import {
	getData,
	postData,
	postDataForGetDetails,
	putData,
	fileUpload,
	deleteData,
	getDataFromPIN,
	postDataWithOutToken,
} from "../../utils/ajax";
import { debug } from "winston";


export function* getAllVendorList(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getAllVendors + action.params);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type: ACTIONS.VENDORS.GET_VENDORS_DATA,
			data: data.data
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Oops! Something Went Wrong." }
		})
	}
}
export function* getVendorModeDropdown(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getVendorModeDropdown + action.params);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type: ACTIONS.VENDORS.GET_VENDORS_MODE_DATA,
			data: data.data
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Oops! Something Went Wrong." }
		})
	}
}

export function* fetchVendorOnBoard(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.fetchAllOnboardVendors + action.params);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type: ACTIONS.VENDORS.FETCH_VENDOR_ONBOARD_DATA,
			data: data.data
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Oops! Something Went Wrong." }
		})
	}
}

export function* postPrimaryVendorDetails(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.addPrimaryVendorDetails, action.data);
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data && data.message ? data.message : "Company added Successfully" }
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* postPrimaryVendorDetailsFirstTime(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postDataWithOutToken, API.addPrimaryVendorFirstTime, action.payload);
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data && data.message ? data.message : "Company added Successfully" }
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* getAllVendorModes(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getAllVendorModes + action.params);
		yield put({
			type: ACTIONS.VENDORS.ALL_VENDOR_MODES,
			data: data.data && data.data.mode_types ? data.data.mode_types : []
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* getAllCharges(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getChargesList + action.params);
		yield put({
			type: ACTIONS.VENDORS.ALL_CHARGES,
			data: data.data && data.data.charges ? data.data.charges : []
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		}); 
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* updateVendorApproval(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(putData, API.vendorApproval, JSON.stringify(action.params));
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.data && data.data.message ? data.data.message : "Updated Successfully!" }
		})
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops! Something Went Wrong." }
		})
	}
}

export function* activateVendor(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(putData, API.activateVendor, JSON.stringify(action.params));
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.data && data.data.message ? data.data.message : "Updated Successfully!" }
		})
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e && e.message ? e.message : "Oops! Something Went Wrong." }
		})
	}
}

export function* downloadContract(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.downloadContract + action.params);
		yield put({
			type: ACTIONS.VENDORS.DOWNLOAD_CONTRACT_FORM,
			data: data.data,
		})
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Oops! Something went wrong." }
		})
	}
}


export function* getAllVendorApproverList(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getApproverLevels + action.params);
		yield put({
			type: ACTIONS.VENDORS.APPROVAL_LEVELS_DATA,
			data: data.data,
		})
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		// yield put({
		// 	type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		// 	data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Oops! Something went wrong." }
		// })
	}
}

export function* getVendorApprovalCounts(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.vendorApprovalCount + action.params);
		yield put({
			type: ACTIONS.VENDORS.VENDOR_APPROVAL_COUNT,
			data: data.data,
		})
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		// yield put({
		// 	type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		// 	data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Oops! Something went wrong." }
		// })
	}
}
export function* editVendorEmail(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(putData, API.editVendorEmail, JSON.stringify(action.params));
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data && data.message ? data.message : "Company updated Successfully" }
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* retriggerVendorEmail(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		let url = `${API.retriggerVendorEmail}?companyId=${action.params.companyId}&vendorId=${action.params.vendorId}&email=${action.params.email}`;
		const data = yield call(postData, url, action.params);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.data && data.data.message ? data.data.message : "Updated Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e && e.message ? e.message :( e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!") }
		})	
	}
}

export function* getBankDetailsFromIFSC(action) {
	try {
		const data = yield call(getDataFromPIN, API.getBankDetailsFromIFSCUrl + action.params);
		if (action.onSuccess) {
			action.onSuccess(data);
		}
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	}
}

export function* postVerificationCodeWithData(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.verifyVendorContactsURL, action.data);
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data && data.message ? data.message : "Verification code sent!" }
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

// vendorDetails
export function* getVendorDetailsForSap(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getVendorDetailsForSapUrl + action.queryString);
		
		yield put({
			type: ACTIONS.VENDORS.SET_VENDOR_DETAILS_FOR_SAP,
			data:data.data || []
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		
	} catch (e) {
		console.log('ERROR', e);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	}
}

export function* getPaymentTermsList(action) {
         try {
           yield put({
             type: ACTIONS.LOADER.SHOW_LOADER,
           });

           const data = yield call(
             getData,
             API.getPaymentTermsForSapUrl + action.queryString
           );

           yield put({
             type: ACTIONS.VENDORS.SET_PAYMENT_TERMS_LIST,
             data: data.data || [],
           });
           yield put({
             type: ACTIONS.LOADER.HIDE_LOADER,
           });
         } catch (e) {
           console.log("ERROR", e);
           yield put({
             type: ACTIONS.LOADER.HIDE_LOADER,
           });
         }
       }
	export function* getCurrencyList(action) {
		try {
			yield put({
				type: ACTIONS.LOADER.SHOW_LOADER,
			});

			const data = yield call(
				getData,
				API.getCurrencyListForSapUrl + action.queryString
			);

			yield put({
				type: ACTIONS.VENDORS.SET_CURRENCY_LIST,
				data: data || {},
			});
			yield put({
				type: ACTIONS.LOADER.HIDE_LOADER,
			});
		} catch (e) {
			console.log("ERROR", e);
			yield put({
				type: ACTIONS.LOADER.HIDE_LOADER,
			});
		}
	}

	export function* postSaveSapDeatails(action) {  
		try {
			yield put({
			type: ACTIONS.LOADER.SHOW_LOADER,
			});
			const data = yield call(
				postData,
				API.postSapDeatailsUrl,
				action.data
				);
				console.log('data:', data)

			yield put({
				type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data: { type: 1, msg: data.message }
			})
		if (action.onSuccess) 
		{
			action.onSuccess();
		}
		
			
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER,
			});
		} catch (e) {
			yield put({
			type: ACTIONS.LOADER.HIDE_LOADER,
			});
			yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: {
				type: 2,
				msg:
				e.responseJSON && e.responseJSON.message
					? e.responseJSON.message
					: "Oops, Something went wrong!",
			},
			});
		}
		}

export function* postSapSendDetailsForCode(action) {
	try {
		yield put({
		type: ACTIONS.LOADER.SHOW_LOADER,
		});
		const data = yield call(
		postData,
		API.postSapSendDetailsForCodeUrl,
		action.data
		);
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.message }
		})
		if (action.onSuccess) 
		{
			action.onSuccess();
		}
	yield put({
		type: ACTIONS.LOADER.HIDE_LOADER,
		});
	} catch (e) {
		yield put({
		type: ACTIONS.LOADER.HIDE_LOADER,
		});
		yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: {
			type: 2,
			msg:
			e.responseJSON && e.responseJSON.message
				? e.responseJSON.message
				: "Oops, Something went wrong!",
		},
		});
	}
	}
export function* getVendorSourceDropdown(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER,
		});

		const data = yield call(
			getData,
			API.getVendorSourceDropdown + action.company_id
		);

		yield put({
			type: ACTIONS.VENDORS.SET_VENDOR_SOURCE_LIST,
			data: data.data || [],
			
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER,
		});
	} catch (e) {
		console.log("ERROR", e);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER,
		});
	}
}

//Exworks Details
export function* postExWorksDeatails(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(putData, API.postExWorksDeatailsUrl, JSON.stringify(action.payload));
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data.data && data.data.message ? data.data.message : "Updated Successfully!" }
		})
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops! Something Went Wrong." }
		})
	}
}

export function* getIncotermsOptions(action) {
	try {
		let url = API.getIncoTermsoptionsUrl;
		
		const data = yield call(getData, url);
		if(action.onSuccess) {
		  action.onSuccess( data.data || []);
		}
		yield put({
			type: ACTIONS.VENDORS.SAVE_INCO_TERMS_OPTIONS,
			data: data.data || []
		})
	} catch(error) {
		console.log('ERROR', error);
	}
  }

  export function* getBillSequenceTestingList(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER,
		});

		const data = yield call(
			getData,
			API.getBillSequenceTestUrl + action.data, true
		);
		if(action.onSuccess) {
			action.onSuccess( data.data || []);
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER,
		});
	} catch (e) {
		console.log("ERROR", e);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER,
		});
	}
}

export function* getBillSequenceListing(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER,
		});

		const data = yield call(
			getData,
			API.getBillSequenceListingUrl + action.params, true
		);
		yield put({
			type: ACTIONS.VENDORS.SET_BILL_SEQUENCE_LIST,
			data: data.data && data.data.bill_sequences || [],
			
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER,
		});
	} catch (e) {
		console.log("ERROR", e);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER,
		});
	}
}

export function* getBillSequenceDropdown(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER,
		});

		const data = yield call(
			getData,
			API.getBillSequenceDropdownUrl + action.params, true
		);
		yield put({
			type: ACTIONS.VENDORS.SET_BILL_SEQUENCE_DROPDOWNS,
			data: data.data && data.data.dropdowns || [],
			
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER,
		});
	} catch (e) {
		console.log("ERROR", e);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER,
		});
	}
}

export function* getBillSequence(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER,
		});

		const data = yield call(
			getData,
			API.getBillSequenceUrl + action.data, true
		);
		if(action.onSuccess){
			action.onSuccess(data && data.data)
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER,
		});
	} catch (e) {
		console.log("ERROR", e);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER,
		});
	}
}

export function* postBillSequence(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.postBillSequenceUrl, action.data, true);
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data && data.message ? data.message : "Bill Format added Successfully" }
		});
		if(action.onSuccess){
			action.onSuccess(data)
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* updateBillSequence(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(putData, `${API.getBillSequenceUrl}${action.data.id}`, JSON.stringify(action.data), true);
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data && data.message ? data.message : "Bill Format added Successfully" }
		});
		if(action.onSuccess){
			action.onSuccess(data)
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* deleteBillSequence(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(deleteData, `${API.getBillSequenceUrl}${action.data}`, null, null, true);
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data && data.message ? data.message : "Bill Format added Successfully" }
		});
		if(action.onSuccess){
			action.onSuccess(data)
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}
