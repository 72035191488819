import React, { Component } from 'react';
import Button from "../../../button-classic/button-classic";
import Delete from "../../../../../assets/images/delete.svg";
import Edit from "../../../../../assets/images/edit.svg";
import Search from '../../../../common/components/search-text/search-text';
import AddWBMaster from './add_wb_master';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";


class WeighBridgeMaster extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
            search: '',
            previousWBMasterData :null
        }
    }

    componentDidMount() {
        this.props.getWeighBridgeMasterListing();
    }

    addWBMaster = (editData = '') => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen, editData,previousWBMasterData:editData });
    }

    toggleDirectDelete = (wb) => {
        const data = {
            weighbridge_id: wb.weighbridge_id,
            vendor_name : wb.vendor_name
        }
        this.props.deleteWBMaster(data);
    }

    onAuditClick = () => {
        const {history,match,depotCode} = this.props;
        console.log("props12",this.props)
        // const companyId = getUserCompanyId()
        const companyId = match.params.id
        const branchId = match.params.branchId
        const branchCode = depotCode
        const masterType = 15
        const navUrl = `/auditScreen/${companyId}/${masterType}/${branchId}/${branchCode}`;
        history.push(navUrl)
      }

    render() {
        const {previousWBMasterData} = this.state
        const { weighBridgeMasterListing } = this.props;
        return(
            <div className="plants-depots-main-wrap">
                <div className="search-holder">
                <div className="add-plants-section">
                    <div style={{display: 'block', paddingBottom: '15px'}}>
                            <Search id="search" value={this.state.search} placeholder="Search..." changeHandler={(e) => this.setState({ search: e })} click={() => this.props.getWeighBridgeMasterListing(this.state.search)} canClear={this.state.search !== ''} handleClearFilter={() => this.setState({ search: '' }, this.props.getWeighBridgeMasterListing())}/>
                    </div>                                        
                    <Button value="+ WeighBridge Master" click={() => this.addWBMaster('')} />
                    <Button value="Audit" click={()=>this.onAuditClick()} />
                
                </div>
                </div>
                <div className="heading">
                    <div className="flow-head">WeighBridge Name</div>
                    <div className="flow-head">Branch Code</div>
                    <div className="flow-head">Plant Name</div>
                    <div className="flow-head">Vendor Name</div>
                    <div className="flow-head">Actions</div>
                </div>
                {(weighBridgeMasterListing || []).map((wb, index) => {
                return(
                    <div className={"depot-users-list"} key={`wb-${index}`}>
                        <div className="flow-head">{wb.weighbridge_name}</div>
                        <div className="flow-head">{wb.branch_code}</div>
                        <div className="flow-head">{wb.plant_name}</div>
                        <div className="flow-head">{wb.vendor_name}</div>
                        <div className="flow-head">
                            <div className = "delete-icon" style={{backgroundImage: `url(${Edit})`}} onClick = {() => this.addWBMaster(wb)}></div>
                            <div className = "delete-icon" style={{backgroundImage: `url(${Delete})`}} onClick = {() => this.toggleDirectDelete(wb)}></div>
                        </div>
                    </div>)
                })}

                {(!weighBridgeMasterListing || weighBridgeMasterListing.length === 0) && <div className={'no_data_found'}>{'No Data Found'}</div>}      

                {this.state.isOpen && 
                <AddWBMaster 
                {...this.props}
                toggleAdd={this.addWBMaster} 
                onAddWBMaster={this.props.onAddWBMaster} 
                updateWBMaster={this.props.updateWBMaster} 
                data={this.state.editData} 
                previousWBMasterData={previousWBMasterData} 
                />}          
        </div>
        )
    }
}

export default connect()(withRouter(WeighBridgeMaster));
