import React, { Component } from 'react';
import Delete from '../../../assets/images/delete.svg';
import Edit from '../../../assets/images/edit.svg';
import Dropup from "../../../assets/images/drop-up-arrow.svg";
import './indent-reasons.less';
import InputText from '../../common/components/input-text/input-text';
import Button from '../../home/button-classic/button-classic';
import EditReason from './edit-indent-reasons';
import DeleteIndentingReasons from './../../components/delete-confirmation/delete-confirmation';
import IndentMgmtConfig from '../IndentMgmtConfig/IndentMgmtConfig';

const tabs = [
	{ heading: 'Indent Reasons', id: 1 },
    { heading: 'Indent Config', id: 2 },
]

class IndentReasons extends Component {
	constructor() {
		super();
		this.state = {
			checkDropDown: [false, false, false, false],
			type: null,
			name: '',
			isEdit: false,
			editReason: '',
			reasonId: '',
			selectedOption: 1
		}
	}


	toggleCheck = (type) => {
		const { checkDropDown } = this.state;
		for (var i in checkDropDown) {
			if (+i === type) {
				checkDropDown[i] = !checkDropDown[i];
			} else {
				checkDropDown[i] = false;
			}
		}
		this.setState({ checkDropDown, name: '', type }, () => {
			if (this.state.checkDropDown) {
				this.props.getIndentReasons(type);
			}
		});
	}

	onChangeHandler = (value, id) => {
		this.setState({ [id]: value });
	}

	toggleEdit = (type, value) => {
		let { isEdit, editReason, reasonId } = this.state;
		editReason = value ? (value.reason || value.name) : '';
		reasonId = value ? value.reason_id : '';
		isEdit = !isEdit;
		this.setState({ isEdit, editReason, reasonId });
	}

	onAddReasons = (type) => {
		const data = {
			name: this.state.name,
			reason: this.state.name
		}
		this.props.addIndentReasons(type, data);
		this.setState({ name: '' });
	}

	addReasonsInput = (type) => {
		return (<div className={'mt-10'}>
			<InputText placeholder="Reason" id={"name"} label="Reason" value={this.state.name} changeHandler={this.onChangeHandler} />
			<Button value={'Add'} click={() => this.onAddReasons(type)}></Button>
		</div>);
	}

	getColums = () => {
		return (
			<div className={'list-section'}>
				<div className={'table-header'}>Reasons</div>
				<div className={'table-header'}>Actions</div>
			</div>
		)
	}

	getNoRowsData = () => {
		return (
			<div className={'list-section no-data'}>
				{'No Records Found'}
			</div>
		)
	}

	updateReasons = () => {
		const data = {
			reason: this.state.editReason,
			name: this.state.editReason
		}
		this.props.updateIndentReasons(this.state.type, data, this.state.reasonId);
		this.toggleEdit();
	}


	toggleDelete = (type, id) => {
		this.setState({
			showDelete: !this.state.showDelete,
			type: type,
			reason_id: id
		})
	}

	deleteIndentReasons = () => {
		this.props.deleteIndentReasons(this.state.type, this.state.reason_id);
		this.toggleDelete();
	}

	getRows = (type, data) => {
		let ele = data.map((row, index) => {
			return (
				<div className={'list-section'} key={index}>
					<div className={'table-row'}>{row.reason || row.name}</div>
					<div className={'table-row'}>
						<div className={'delete-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.toggleEdit(type, row)}></div>
						<div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleDelete(type, row.reason_id)}></div>
					</div>
				</div>
			)
		})
		return ele;
	}

	setOption = (id) => {
        this.setState({ selectedOption: id });
    }

	render() {
		const { indentReasons } = this.props;
		const { selectedOption } = this.state;
		return (
			<div className = "plants-depots-main-wrap">
                <div className="home-navigation-holder home-navigation-holder-sub">
                {tabs.map((option, index) => {
                    return (
                    <div
                        key={index}
                        className={
                        this.state.selectedOption === option.id
                            ? "selected-option options"
                            : "options"
                        }
                        onClick={() => this.setOption(option.id)}
                    >
                        {option.heading}
                    </div>
                    );
                })}
                </div>
                {selectedOption === 2 && 
                    <IndentMgmtConfig 
                        companyId={this.props.companyId}
						branchId={this.props.companyId}
                    />}
                {selectedOption === 1 &&
					<div className={'indent-reasons'}>
						<div className={'header'} >Cancel Reasons
							<div className={this.state.checkDropDown[1] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(1)}></div>
						</div>
						<div className={`drop-section ${this.state.checkDropDown[1] ? "drop-up" : "drop-close"}`}>
							{this.addReasonsInput(1)}
							<div className={'fuel-escalation-table'}>
								{this.getColums()}
								{indentReasons && indentReasons.length > 0 ? this.getRows(1, indentReasons) : this.getNoRowsData()}
							</div>
						</div>

						<div className={'header'} >Regret Reasons
							<div className={this.state.checkDropDown[2] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(2)}></div>
						</div>
						<div className={`drop-section ${this.state.checkDropDown[2] ? "drop-up" : "drop-close"}`}>
							{this.addReasonsInput(2)}
							<div className={'fuel-escalation-table'}>
								{this.getColums()}
								{indentReasons && indentReasons.length > 0 ? this.getRows(2, indentReasons) : this.getNoRowsData()}
							</div>
						</div>

						<div className={'header'} >Reject Reasons
							<div className={this.state.checkDropDown[3] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(3)}></div>
						</div>
						<div className={`drop-section ${this.state.checkDropDown[3] ? "drop-up" : "drop-close"}`}>
							{this.addReasonsInput(3)}
							<div className={'fuel-escalation-table'}>
								{this.getColums()}
								{indentReasons && indentReasons.length > 0 ? this.getRows(3, indentReasons) : this.getNoRowsData()}
							</div>
						</div>

						{this.state.isEdit && <EditReason reason={this.state.editReason} toggleEdit={this.toggleEdit} onChangeHandler={this.onChangeHandler} updateReasons={this.updateReasons} />}
						{this.state.showDelete && <DeleteIndentingReasons
							toggleDelete={this.toggleDelete}
							delete={this.deleteIndentReasons}
						/>}
					</div>}
				</div>
		)
	}
}

export default IndentReasons;