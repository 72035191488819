import ACTION from "../../common/action_constant";

const plantsDepotsReducer = (state = {plantsDepotsList : [],volumetricMaster:[] }, action) => {
	let tempState = Object.assign({}, state);
	if(action.type === ACTION.PLANTS.STORE_PLANTS_DEPOTS){
		tempState.plantsDepotsList  = action.data;		
		return tempState;
	}else if(action.type === ACTION.PLANTS.STORE_ZONES){
		tempState.zones  = action.data;		
		return tempState;
	}else if(action.type === ACTION.PLANTS.STORE_BRANCH_TYPES){
		tempState.branchTypes  = action.data;		
		return tempState;
	}else if(action.type === ACTION.PLANTS.SET_CURRENT_DEPOT){
		tempState.currentDepot  = action.data;		
		return tempState;
	}else if(action.type === ACTION.PLANTS.SET_TRACK_DETAILS){
		tempState.trackDetails  = action.data;		
		return tempState;
	}else if(action.type === ACTION.PLANTS.STORE_DEPOT_USERS){
		tempState.depotUsers  = action.data;		
		return tempState;
	}else if(action.type === ACTION.PLANTS.STORE_BRANCHES){
		tempState.branches  = action.data;		
		return tempState;
	}else if(action.type === ACTION.PLANTS.STORE_VERIFIED_USER){
		tempState.verifiedUser  = action.data;		
		return tempState;
	}else if(action.type === ACTION.PLANTS.STORE_ACTIONS){
		tempState.actions  = action.data;		
		return tempState;
	}else if(action.type === ACTION.PLANTS.STORE_INDENTING_FLOWS){
		tempState.indentingFlows  = action.data;		
		return tempState;
	}else if(action.type === ACTION.PLANTS.STORE_INDENTING_ITEMS){
		tempState.indentingItems  = action.data;		
		return tempState;
	}else if(action.type === ACTION.PLANTS.STORE_INDENTING_USERS){
		tempState.indentingUsers  = action.data;		
		return tempState;
	}else if(action.type === ACTION.PLANTS.STORE_INDENTING_CHECKLISTS){
		tempState.indentingChecklists  = action.data;		
		return tempState;
	}else if(action.type === ACTION.PLANTS.STORE_INDENTING_VENDORS){
		tempState.indentingVendors  = action.data;		
		return tempState;
	}else if(action.type === ACTION.PLANTS.STORE_INDENTING_ACTIONS){
		tempState.indentingActions  = action.data;		
		return tempState;
	}else if(action.type === ACTION.PLANTS.STORE_INDENTING_MOVES){
		tempState.indentingMoves  = action.data || [];		
		return tempState;
	}else if(action.type === ACTION.PLANTS.STORE_INDENTING_SUBMOVES){
		tempState.indentingSubMoves  = action.data || [];		
		return tempState;
	}else if(action.type === ACTION.PLANTS.STORE_INDENTING_WAREHOUSES){
		tempState.indentingWarehouses  = action.data;		
		return tempState;
	}
	else if(action.type === ACTION.PLANTS.STORE_INDENTING_FLOW_DETAILS){
		tempState.indentingFlowDetails  = action.data;		
		return tempState;
	}else if(action.type === ACTION.PLANTS.STORE_SUBPLANTS){
		tempState.subPlants  = action.data;		
		return tempState;
	}
	else if(action.type === ACTION.PLANTS.STORE_ACTION_TYPES){
		tempState.actiontypeslist  = action.data;		
		return tempState;
	}
	else if(action.type === ACTION.PLANTS.STORE_ALL_BRANCH) {
		tempState.allPlantBranches = action.data;
		return tempState;
	}
	else if(action.type === ACTION.PLANTS.STORE_INDENTING_FIELDS) {
		tempState.indentingFields = action.data;
		return tempState;
	}
	else if(action.type === ACTION.PLANTS.SAVE_LOADING_BAY) {
		tempState.loadingBayData = action.data;
		return tempState;
	}
	else if(action.type === ACTION.PLANTS.SAVE_LAODING_GANG) {
		tempState.loadingGangData = action.data;
		return tempState;
	}
	else if(action.type === ACTION.PLANTS.SAVE_INDENTING_EDIT_FIELDS) {
		tempState.indentEditFields = action.data;
		return tempState;
	}
	else if(action.type === ACTION.PLANTS.SAVE_MOVE_STANDARD_TIME) {
		tempState.moveStandardTime = action.data;
		return tempState;
	}
	else if(action.type === ACTION.PLANTS.SAVE_APPROVER_LIST) {
		tempState.approverMatrix = action.data || {};
		return tempState;
	}
	else if(action.type === ACTION.PLANTS.DEPARTMENT_EMAIL_LIST_DATA) {
		tempState.deptEmailList = action.data || [];
		return tempState;
	}
	else if(action.type === ACTION.PLANTS.SAVE_WB_CONVERSION) {
		tempState.WBConversionData = action.data || [];
		return tempState;
	} else if(action.type === ACTION.PLANTS.SAVE_WAREHOUSE_LIST) {
		tempState.warehouseList = action.data || [];
		return tempState;
	} else if(action.type === ACTION.PLANTS.SAVE_WEIGH_BRIDGE_DETAILS) {
		tempState.weighBridgeDetails = action.data || [];
		return tempState;
	}else if(action.type === ACTION.PLANTS.STORE_TERM_CONDITION) {
		tempState.termAndConditions = action.data || [];
		return tempState;
	} else if(action.type === ACTION.PLANTS.SUBPLANT_TEMPLATE) {
		tempState.subplantTemplate = action.data || [];
		return tempState;
	} else if(action.type === ACTION.PLANTS.PLANT_DEPOT_DETAILS) {
		tempState.plantDepotDetails = action.data || [];
		return tempState;
	} 
	else if(action.type === ACTION.PLANTS.STORE_WAREHOUSE_DETAILS) {
		tempState.warehouseStorageData = action.data;
		return tempState;
	}else if(action.type === ACTION.PLANTS.STORE_INDENTING_OPERATION_TIME){
		tempState.indentingOperationTime  = action.data;	
		return tempState;
	} else if(action.type === ACTION.PLANTS.SAVE_DEPOT_AND_USER_DETAILS) {
		tempState.depotAndUserDetails = action.data || [];
		return tempState;
	} else if(action.type === ACTION.PLANTS.SAVE_AUTOMOVE) {
		tempState.automoveDetails = action.data || [];
		return tempState;
	} else if(action.type === ACTION.PLANTS.STORE_LOB_LIST) {
		tempState.lobList = action.data || [];
		return tempState;
	} else if(action.type === ACTION.PLANTS.STORE_CITIES_LIST) {
		tempState.citiesList = action.data || [];
		return tempState;
	}
	else if(action.type === ACTION.PLANTS.STORE_MOVE_NAME) {
		tempState.moveNamesList = action.data || [];
		return tempState;
	}
	else if (action.type === ACTION.PLANTS.SAVE_WEIGH_BRIDGE_MASTER_LISTING) {
		tempState.weighBridgeMasterListing = action.data || [];
		return tempState;
	}
	else if (action.type === ACTION.PLANTS.STORE_INDENTING_DASHBOARD_LIST) {
		tempState.indentingDashboardListing = action.data || [];
		return tempState;
	}
	else if (action.type === ACTION.PLANTS.SAVE_PARKING_LOT_LISTING) {
		tempState.parkingLotListing = action.data || [];
		return tempState;
	}
	else if (action.type === ACTION.PLANTS.SET_VOLUMETRIC_MASTER) {
		tempState.volumetricMaster = action.data || [];
		return tempState;
	}
	else if (action.type === ACTION.PLANTS.SET_PACK_UOM_DROPDOWN) {
		tempState.packUOMdropdown = action.data || [];
		return tempState;
	}
	else if(action.type === ACTION.PLANTS.SAVE_REPORTS_DASHBOARDS_LIST) {
		tempState.reportsDashboardsList = action.data || {};
		return tempState;
	}
	else if (action.type === ACTION.PLANTS.SAVE_MFS_MASTER_DETAILS) {
		tempState.mfsMasterListing = action.data || [];
		return tempState;
	}
	else if (action.type === ACTION.PLANTS.SET_SERVICE_MODES) {
		tempState.serviceModes = action.data || [];
		return tempState;
	}
	else if(action.type === ACTION.PLANTS.STORE_COMPANY_WISE_ZONES){
		tempState.zoneList  = action.data;		
		return tempState;
	}
	else if (action.type === ACTION.PLANTS.SAVE_MFS_MASTER_UOM_DETAILS) {
		tempState.mfsMasterUomListing = action.data || [];
		return tempState;
	}else if(action.type === ACTION.PLANTS.SAVE_REGION_DROPDOWN) {
		tempState.regionmaster = action.data || [];
		return tempState;
	}
	else if(action.type === ACTION.PLANTS.SAVE_SLAB_RANGE_DETAILS) {
		tempState.slabRange = action.data || [];
		return tempState;
	}
	else if(action.type === ACTION.PLANTS.SET_ADD_PLANT_CATEGORY) {
		tempState.plantCategory = action.data || [];
		return tempState;
	}
	else if(action.type === ACTION.PLANTS.SET_DEPO_NAME) {
		tempState.DepotName = action.data || [];
		return tempState;
	}else if(action.type === ACTION.PLANTS.STORE_REASONS) {
		tempState.indentingReasons = action.data || [];
		return tempState;
	}
	else if(action.type === ACTION.PLANTS.SET_INVENTORY_MASTER){
		tempState.inventoryMaster = action.data || [];
		return tempState;
	}
	else if(action.type === ACTION.PLANTS.SET_DISTRICT_MAPPING_MASTER){
		tempState.SalesdistrictMappingData = action.data || [];
		return tempState;
	}
	else if(action.type === ACTION.PLANTS.SET_INDENTING_MOVES){
		tempState.indentingAdminMoves = action.data || [];
		return tempState;
	}
	return state;
};

export default plantsDepotsReducer;

