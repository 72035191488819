import React, { Component } from "react";
import AddItem from "./add-item/add-item";
import EditItem from "./edit-item/edit-item";
import Edit from "../../../../assets/images/edit.svg";
import Delete from "../../../../assets/images/delete.svg";
import Button from "../../../home/button-classic/button-classic";
import Search from "../../../common/components/search-text/search-text";
import "./DriverMaster.less";
import moment from "moment";
import { connect } from "react-redux";
import { getCookie } from "../../../../utils/cookies";
import ACTION from "../../../common/action_constant";

class DriverMaster extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      isEdit: false,
      limit: 20,
      offset: 0,
      editedData: "",
      search: "",
    };
  }
  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
    this.getDriverDetails();
  };
  toggleAdd = (isType, data = "") => {
    if (isType == "Edit") {
      this.setState({ isEdit: !this.state.isEdit, editedData: data });
    } else {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  getDriverDetails = () => {
    const data = {
      offset: this.state.offset,
      limit: this.state.limit,
    };
    if (this.state.search) {
      data.search = this.state.search;
    }
    this.props.getDriverDetails(data);
  };

  clearFilterOnCancel = (e, type) => {
    this.setState({ search: "" }, () => {
      this.getDriverDetails();
    });
  };
  changeHandler = (val, id) => {
    this.setState({
      [id]: val,
    });
  };

  submitSearch = () => {
    this.getDriverDetails();
  };

  downloadTemplate=()=>{
    const { dispatch } = this.props;
    const company_id = JSON.parse(getCookie('user')).company_id;
    dispatch({ 
      type : ACTION.MASTER.VEHICLE_PLANNING.DOWNLOAD_DRIVER_TEMP,
      company_id, 
      onSuccess: ((data) => {
        this.setState({
            downloadLink: data[0].Link
        });
        this.downloadBtn.click();
      })
    });
  }
  downloadDetails=()=>{
    const { dispatch } = this.props;
    const params = {
      company_id: JSON.parse(getCookie('user')).company_id,
      withData: true,
    }
    dispatch({ 
      type : ACTION.MASTER.VEHICLE_PLANNING.DOWNLOAD_DRIVER_DETAILS, 
      params,
      onSuccess: ((data) => {
        this.setState({
            downloadLink: data[0].Link
        });
        this.downloadBtn.click();
      })
    });
  }

  previous = () => {
    if (this.state.offset > 0) {
      this.setState(
        {
          offset: this.state.offset - 20,
        },
        () => {
          const data = {
            limit: this.state.limit,
            offset: this.state.offset,
          };
          this.props.getDriverDetails(data);
        }
      );
    }
  };

  next = () => {
    this.setState(
      {
        offset: this.state.offset + 20,
      },
      () => {
        const data = {
          limit: this.state.limit,
          offset: this.state.offset,
        };
        this.props.getDriverDetails(data);
      }
    );
  };

  uploadDriverDetails = (e) => {
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    this.props.uploadDriverDetails(formData);
  };
  onUpload = () => {
    this.uploadUrl.click();
  };
  checkExpiry = (date) => {
    const currentDate = new Date();
    var dateMomentObject = moment(date, "DD/MM/YYYY");
    var dateObject = dateMomentObject.toDate();
    var differenceInTime = currentDate.getTime() - dateObject.getTime();
    var differenceInDays = differenceInTime / (1000 * 3600 * 24);
    let colorCoding = '';
    if(differenceInDays < 0 && differenceInDays > -30){
      colorCoding = 'text-orange item-label';
    } else if(differenceInDays > 0){
     colorCoding = 'text-red item-label';
    }
    else {
     colorCoding = 'item-label';
    }
    return colorCoding;
  }

  render() {
    const { attIndex } = this.state; 
    return (
      <div className={"Pfizer-Item"}>
        <div className="header">
          <Search
            id={"search"}
            value={this.state.search}
            placeholder={"Search"}
            changeHandler={this.changeHandler}
            click={this.submitSearch}
            canClear={this.state.search !== ""}
            handleClearFilter={(e) => this.clearFilterOnCancel(e, "seaech")}
          />
          <Button value={" + Add"} click={this.toggleAdd} />
          <Button value={"Upload"} click={this.onUpload} />
          <input
            type="file"
            style={{ display: "none" }}
            ref={(e) => {
              this.uploadUrl = e;
            }}
            onChange={this.uploadDriverDetails}
            onClick={(e) => {
              e.target.value = null;
            }}
          />
          <Button value={"Download Details"} click={this.downloadDetails} />
          <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
                    
          <Button value={"Download Temp."} click={this.downloadTemplate} />
          <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
        </div>
        <div className="item-table">
          <div
            className="list-section item-header column"
            style={{ minWidth: "fit-content" }}
          >
            <div className="item-label">Sl No</div>
            <div className="item-label">Transporter Name</div>
            <div className="item-label ">Driver Name</div>
            <div className="item-label ">Age</div>
            <div className="item-label">Driver Contact</div>
            <div className="item-label">Emergency Contact</div>
            <div className="item-label">Licence No</div>
            <div className="item-label">Expiry Date</div>
            <div className="item-label">Joining Date </div>
            <div className="item-label">Present Operation (Months)</div>
            <div className="item-label">Total Exp</div>
            <div className="item-label">First Dose</div>
            <div className="item-label">Second Dose</div>
            <div className="item-label">ID Proof (Addhar Number)</div>
            <div className="item-label">Residential Address</div>
            <div className="item-label">Attachments</div>
            <div className="item-label">Actions</div>
          </div>
          {(!this.props.driverDataList || this.props.driverDataList.length === 0) && <div className={'no_data_found'}>{'No Data Found'}</div>}
            
          {this.props.driverDataList &&
            this.props.driverDataList.map((val, index) => {
             
              return (
                <div
                  className="list-section item-header"
                  style={{ minWidth: "fit-content" }}
                >
                  <div className="item-label">{index + 1}</div>
                  <div className="item-label ">{val.transporter_name || '-'}</div>
                  <div className="item-label">{val.driver_name || '-'}</div>
                  <div className="item-label">{val.age || '-'}</div>
                  <div className="item-label">{val.contact || '-'}</div>
                  <div className="item-label">{val.emergency_contact || '-'}</div>
                  <div className="item-label">{val.dl_no || '-'}</div>
                  <div className = {this.checkExpiry(val.dl_expiry)}>{val.dl_expiry || '-'}</div>
                  <div className="item-label">{val.joining_date || '-'}</div>
                  <div className="item-label">{val.operation_months || '-'}</div>
                  <div className="item-label">{val.total_experience || '-'}</div>
                  <div className="item-label">{val.vaccine_fisrtdose_on || '-'}</div>
                  <div className="item-label">{val.vaccine_seconddose_on || '-'}</div>
                  <div className="item-label">{val.adhar_no || '-'}</div>
                  <div className="item-label">{val.address || '-'}</div>
                  <div className="item-label theme-color fBold curP" onClick={() => this.setState({openAttachments: true, attIndex: index})}>View Attachments</div>
                  <div className="item-label">
                    <div
                      className={"delete-icon"}
                      style={{ backgroundImage: `url(${Edit})` }}
                      onClick={() => this.toggleAdd("Edit", val)}
                    ></div>
                    <div
                      className={"delete-icon"}
                      style={{ backgroundImage: `url(${Delete})` }}
                      onClick={() =>
                        this.props.deleteDriverDetails(val.company_id, val)
                      }
                    ></div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="submit-section-pagination">
          <div className="next">
            <Button value="Next" click={this.next} />
          </div>
          {this.state.offset != 0 && (
            <div className="pre">
              <Button value="Previous" click={this.previous} />
            </div>
            
          )}
        </div>
        {this.state.isOpen && (
          <AddItem
            toggleAdd={this.toggleAdd}
            addDriverDetails={this.props.addDriverDetails}
            companyTransporters={this.props.companyTransporters}
            getCompanyTransporters={this.props.getCompanyTransporters}
          />
        )}
        {this.state.isEdit && (
          <EditItem
            toggleAdd={this.toggleAdd}
            data={this.state.editedData}
            updateDriverDetails={this.props.updateDriverDetails}
            companyTransporters={this.props.companyTransporters}
            getCompanyTransporters={this.props.getCompanyTransporters}
          />
        )}
        {this.state.openAttachments &&
        <div className="add-user-wrap">
        <div onClick={this.props.toggleAdd} className="overlay"></div>
          <div id="modal" className="modal-popup" style={{width: '400px', minHeight: '300px'}}>
            <div className="pop-heading">Attached Documents</div>
            <div className="content add-item-details">
            {this.props.driverDataList[attIndex].attachments && this.props.driverDataList[attIndex].attachments.length > 0 ? 
            (this.props.driverDataList[attIndex].attachments || []).map((att) => {
                return(
                <div>
                  {att && att.name ? (
                    <a
                      href={att.url}
                      download
                      className={"theme-color fs-12 mt-5"}
                    >
                      {att.name}
                    </a>
                  ) : null}
                </div>
                )}) : 
                <div className="tCenter">No Attachments Found</div>}
            </div>
            <div className="submit-button" style={{float: 'none'}}>
              <div>
                <Button
                  value="Cancel"
                  click={() => this.setState({openAttachments: false, attIndex: ''})}
                />
              </div>
            </div>
          </div>
        </div>}
      </div>
    );
  }
}
export default connect()(DriverMaster);
