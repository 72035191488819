import React, { Component } from 'react';
import Select from 'react-select';
import CheckBox from '../../../components/checkbox/checkbox';
import InputText from '../../../common/components/input-text/input-text';
import '../reconciliation.less';
import { Uom } from '../../../../utils/common';
import { decimalNumber } from '../../../../utils/validate';
import Button from '../../button-classic/button-classic';

const typeUom = [
    {label: 'Amount', value: 'Amount'},
    {label: 'Percentage', value: 'Percentage'},
    {label : 'Days', value: 'Days'},
    { label : 'Weight', value : 'Weight'}
]

class AddRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data : {
                    type: this.props.isType,
                    event: '',
                    amount: '',
                    uom_type: '',
                    percentage:'',
                    days: '',
                    is_active: false,
                    weight: ''
            },
            selectOptions: []
        }

    }
    handleChange = (id , selectOptions) => {
        let { data } = this.state;
        data[id] = selectOptions.value;
        this.setState({ data });
    }

    checkBoxChange = ( e , id  ) => {
        let { data } = this.state;
        data[id] = e.target.checked;
        this.setState({ data });
    }

    onChangeHandler = (value, id) => {
       if (decimalNumber(value) || value == '') {
        let { data } = this.state;
        data[id] = value;
        this.setState({ data }); }
    }

    addOtherChanges = () => {
        const { data } = this.state;
        this.props.toggleAdd();
        this.props.addCompanyAccounts(data);
    }

    handleUomChange = (selectOption) => {
        this.setState( { uom : selectOption});
    }

    componentWillReceiveProps = (newProps) => {
        let selectOptions = [];
        if(Object.keys(newProps.eventDetails).length) {
            for(let i in newProps.eventDetails.type6 ){
                selectOptions.push({ label : newProps.eventDetails.type6[i].event_name , value: newProps.eventDetails.type6[i].event_name});
            }
            this.setState({ selectOptions });
        }
    }
    validateDays=(val)=>{
       return val.match(/^\d{1,3}?$/)
    }
    validateAmount=(val)=>{
       return val.match(/^\d{1,8}(\.\d{1,3})?$/)
    }
    validatePercentage=(val)=>{
       return val.match(/^\d{1,2}(\.\d{1,3})?$/)
    }
    validateWeight=(val)=>{
       return val.match(/^\d{1,5}(\.\d{1,3})?$/)
    }

   validData = ({ event, days, amount, percentage, weight } = this.state.data) =>{
      return (event && (this.validateDays(days) || this.validateAmount(amount) || this.validatePercentage(percentage) || this.validateWeight(weight)));
    }

    render() {
       let { event ,days ,amount ,percentage ,weight } = this.state.data;
       const disable = this.validData();
        return(<div className = "add-company-wrap add-provider-root add-row">
            <div onClick = {this.props.toggleAdd} className = "overlay">
                <div className = "modal-popup" onClick={(e) => { e.stopPropagation() }}>
                    <div className = "heading">
                        Add Event
                    </div>
                    <form className = "" autoComplete = "nopes">
                       <div className="select-wrap">
                            <div className="label">Event</div>
                            <Select className={'select'} value={this.state.event} onChange={this.handleChange.bind(this, 'event')} options={this.state.selectOptions}/>
                        </div>
                        <div className="select-wrap">
                            <div className="label">UOM</div>
                            <Select className={'select'} options={typeUom} value={this.state.data.uom} onChange={this.handleUomChange}/>
                        </div>
                        {this.state.uom && this.state.uom.label == 'Days' && <InputText placeholder={'Days'} label={'days'} id={'days'} value = {this.state.data.days} changeHandler = {this.onChangeHandler} type={'number'}/>}
                        {this.state.uom && this.state.uom.label == 'Amount' && <InputText placeholder={'Amount'} label={'Amount'} id={'amount'} value = {this.state.data.amount} changeHandler = {this.onChangeHandler} type={'number'}/>}
                        {this.state.uom && this.state.uom.label == 'Percentage' && <InputText placeholder={'Percentage'} label={'Percentage'} id={'percentage'} value = {this.state.data.percentage} changeHandler = {this.onChangeHandler} type={'number'}/>}
                        {this.state.uom && this.state.uom.label == 'Weight' && <div className={'minimum-charge'}>
                            <InputText placeholder={'Weight'} label={'Weight'} id={'weight'} value = {this.state.data.weight} changeHandler = {this.onChangeHandler} type={'number'}/>
                        </div>}
                        {this.state.uom && this.state.uom.label == 'Weight' &&  <Select className={'select'} options={[{ label: 'kgs', value: 'kgs'}]}  onChange={this.handleChange.bind(this, 'uom_type')}/>}
                        <div className="select-wrap">
                            <div className="label">Active</div>
                            <CheckBox status = {this.state.data.is_active} id = {'is_active'} handleChange = {this.checkBoxChange} />
                        </div>
                    </form>
                    <div className = "submit-section submit-area">
                    { 
                       <div className="button-section">
                          <Button className="add-button" click={this.addOtherChanges} value="Save" disabled={!disable} />
                       </div> 
                        }
                        <div className ="button-section" >
                            <div className ="add-button" onClick={this.props.toggleAdd}>Close</div>
                        </div>
                    </div>
                </div>
            </div>
         </div>)
    }
}

export default AddRow;