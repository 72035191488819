import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ACTION from "../../common/action_constant";
import "./hubMasters.less";

import MainComponent from "../../home/main/main";
import Button from "../../home/button-classic/button-classic";
import CheckBox from "./../../components/checkbox/checkbox";
import InputText from "../../common/components/input-text/input-text";
import Loader from "../../common/components/loader/loader";
import MSGPopUp from "../../common/components/msg-popup/msg-popup";

import { getCookie } from "../../../utils/cookies";

import Save from '../../../assets/images/save.svg'
import Cancel from '../../../assets/images/cancel.svg'
import Edit from '../../../assets/images/edit.svg'

import Select from "react-select";


class HubMasters extends Component {
    constructor(props){
        super(props);
        this.state = {
            selectedLanes: [],
            limit: 20,
            offset: 0,
            isAdded: false,
            vendorId: '',
            hubMasterData: (props.dataGovernance && props.dataGovernance.hubsMasterDropdown) ? props.dataGovernance.hubsMasterDropdown : [],
            newData: {
                company_id: JSON.parse(getCookie("user")).company_id,
                created_by: JSON.parse(getCookie("user")).user_email,
                region: '',
                city: '',
                address: '',
                pincode: '',
                vendor_name: '',
                vendor_id: '',
                vendor_code: '',
            }
        }
    }

    componentDidMount() {
        this.getHubMasterData();
        this.getVendorsData();
    }

    componentWillReceiveProps(nextProps) {
        const { dataGovernance } = this.props;
        
        if (dataGovernance !== nextProps.dataGovernance) {
            if (dataGovernance.hubsMasterDropdown !== nextProps.dataGovernance.hubsMasterDropdown) {
                this.setState({
                    hubMasterData: nextProps.dataGovernance.hubsMasterDropdown
                });
            }
        }
    }

    getServiceName = (id) => {
        let name = '';
        switch(id) {
            case 1:
                name = 'LCL';
                break;
            case 2:
                name = 'FTL';
                break;
            case 3:
                name = 'Courier';
                break;
            default:
                break;
        }
        return name;
    }

    getCities = (searchValue) => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_CITY_LIST,
            params: `cityName=${searchValue}&lookingFor=city`,
        });
    }

    getPincodes = (value) => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_CITY_PINCODES,
            params: `cityName=${value}&lookingFor=pincode`,
        });
    }

    getVendorsData = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_VENDORS_TYPES,
        })
    }

    getHubMasterData = (vendorId) => {
        const { dispatch } = this.props;
        const { limit, offset } = this.state;
        const companyId = JSON.parse(getCookie("user")).company_id;
        let params = `companyId=${companyId}&limit=${limit}&offset=${offset}`;
        if (this.state.vendorId) {
            params += `&vendorId=${this.state.vendorId}`
        }

        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_HUBS_MASTER_DROPDOWN,
            params, //`companyId=${companyId}`, //&vendorId=c0d99acbcc2948069373b69ba4cfabba`,
            onSuccess: (() => { 
                this.setState({ editId: null, isAdded: false  }) 
            })
        })
    }


    getHubMasterDropdownData = () => {
        const { dispatch } = this.props;
        const { newData } = this.state;
        const companyId = JSON.parse(getCookie("user")).company_id;

        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_LANE_MASTERS,
            params: `companyId=${companyId}&limit=100&offset=0&vendorId=${newData.vendor_id}`,
            onSuccess: (() => { this.setState({ editId: null, isAdded: false  }) })
        })
    }

    handleLaneSelection = (e, id) => {
        let { selectedLanes } = this.state;
        if (selectedLanes.includes(id)) {
            selectedLanes.splice(id, 1);
        } else {
            selectedLanes = [id];
        }
        // selectedLanes = [id];

        this.setState({ selectedLanes });
    }

    resetData = () => {
        let { newData } = this.state;
        newData = {
            company_id: JSON.parse(getCookie("user")).company_id,
            created_by: JSON.parse(getCookie("user")).user_email,
            region: '',
            city: '',
            address: '',
            pincode: '',
            vendor_name: '',
            vendor_id: '',
            vendor_code: '',
        }
        this.setState({ newData });
    }

    saveLane = (lane) => {
        let { isAdded, newData } = this.state;
        const { dispatch } = this.props;
        const company_id = JSON.parse(getCookie("user")).company_id;
        
        if (isAdded) {
            dispatch({
                type: ACTION.DATA_GOVERNANCE.ADD_NEW_HUB_MASTER,
                data: newData,
                params: `companyId=${company_id}&vendorId=${newData.vendor_id}`,
                onSuccess: (() => {
                    this.setState({ isAdded: false });
                    this.getHubMasterData();
                    this.resetData();
                })
            })
        } else {
            if (Object.keys(lane).length > 0) {
                const { laneMasterData } = this.state;
                const data = {
                    ...lane,
                    company_id: JSON.parse(getCookie("user")).company_id,
                }
                dispatch({
                    type: ACTION.DATA_GOVERNANCE.UPDATE_HUBS_MASTER,
                    data: data,
                    params: `companyId=${company_id}&hubId=${data.hub_id}`,
                    onSuccess: (() => {
                        this.setState({ editId: null })
                        this.getHubMasterData();
                        this.resetData();
                    })
                })
            }
        }
    }

    deleteLanes = () => {
        const { selectedLanes } = this.state;
        const { dispatch } = this.props;
        const companyId = JSON.parse(getCookie("user")).company_id;
        // const data = {
        //     companyId: companyId,
        //     hubId: selectedLanes.length>0 ? selectedLanes[0] : undefined,
        // };
        let params = `companyId=${companyId}`
        if (selectedLanes.length>0) {
            params += `&hubId=${selectedLanes[0]}`
        }
        dispatch({
            type: ACTION.DATA_GOVERNANCE.DELETE_HUBS_MASTER_DATA,
            params,
            onSuccess: (() => {
                this.getHubMasterData();
            })
        });
    }

    downloadLane = () => {
        const { dispatch } = this.props;
        const companyId = JSON.parse(getCookie("user")).company_id;
        const params = `companyId=${companyId}`
        dispatch({
            type: ACTION.DATA_GOVERNANCE.DOWNLOAD_HUBS_MASTER,
            params,
            onSuccess: ((data) => {
                if (data.length > 0) {
                    this.setState({ downloadLink: data[0].Link });
                    this.downloadBtn.click();
                }
            })
        })
    }

    uploadLane = (e) => {
        let formData = new FormData();
        formData.append('file', e.target.files[0]);
        const { dispatch } = this.props;
        const company_id = JSON.parse(getCookie("user")).company_id;
        // const data = { companyId: company_id };
        // formData.append('jsonData', JSON.stringify(data));

        dispatch({ 
            type: ACTION.DATA_GOVERNANCE.UPLOAD_HUBS_MASTER_DATA, 
            data: formData, 
            params: `companyId=${company_id}`,
            onSuccess: (() => {
                this.getHubMasterData();
            })
        });
    }

    closeMsgPopup = () =>{
        const {dispatch} = this.props;
        dispatch({ type : ACTION.MSG_DISPLAY.HIDE_MSG });
    }

    clickCustomCheckBox = (id, name) =>{
        this.setState({
            [name]: id,
        }, () => {
            this.getHubMasterData();
        })
    }

    editLane = (id, data) => {
        this.setState({
            editId: this.state.editId===id ? null : id,
            newData: {...data},
            isAdded: false
        });
        if (this.state.editId===id) {
            this.resetData();
        }
    }

    cancelAction = () => {
        this.setState({
          ...this.state,
          isAdded: false,
        });
        this.resetData();
    }

    onVendorChange = (value) => {
        const { newData } = this.state;
        newData.vendor_name = value.label;
        newData.vendor_id = value.value;
        newData.vendor_code = value.sap_vendor_code;
        this.setState({ newData });
    }

    previous = () => {
        if (this.state.offset > 0) {
            this.setState({
                offset: this.state.offset - 20
            }, () => {
                this.getHubMasterData()
            });
        }
    }
    next = () => {
        this.setState({
            offset: this.state.offset + 20
        }, () => {
            this.getHubMasterData()
        });
    }

    renderNewLaneBody = () => {
        const { dataGovernance } = this.props;
        const { newData, } = this.state;
        let cities = [];

        if (dataGovernance.citiesList && dataGovernance.citiesList.length > 0) {
            (dataGovernance.citiesList || []).forEach((items) => {
                cities = cities.concat(items.cities || items.states || []);
            });
        }
        
        return (
            <div className="list-section content divTableRow">
                <div className="divTableCell select-row"> </div>
                <div className="divTableCell">
                    <Select
                        className="select"
                        value={(dataGovernance.vendorTypes || []).filter((vendor) => { return vendor.value===newData.vendor_id })}
                        options={dataGovernance.vendorTypes || []}
                        onChange={(value) => { this.onVendorChange(value) }}
                    />
                </div>
                <div className="divTableCell"> 
                    <Select
                        className="select"
                        options={cities || []}
                        value={(cities || []).filter(obj => { return (obj.label === newData.city || obj.label === newData.region)})}
                        isSearchable={true}
                        placeholder={newData.city || 'Search'}
                        onInputChange={(value, items) => {
                            this.setState({ fromSearch: value })
                            if (value.length > 1) {
                                this.getCities(value)
                            }
                        }}
                        isDisabled={this.props.dataGovernance.loading}
                        isLoading={this.props.dataGovernance.loading}
                        autoFocus={this.state.fromSearch}
                        menuIsOpen={this.state.fromSearch}
                        inputValue={this.state.fromSearch}
                        onChange={(value) => { 
                            const { newData } = this.state;
                            if (value.city_name) {
                                newData.city = value.label;
                                newData.region = value.state_name;
                                newData.zone_name = value.region;
                            } else {
                                newData.region = value.state_name;
                                newData.zone_name = value.region;
                            }
                            this.setState({ newData, fromSearch: '' });
                        }}
                    />
                </div>
                <div className="divTableCell"> 
                    {newData.region || ''}
                </div>
                <div className="divTableCell"> 
                    {newData.zone_name || ''}
                </div>
                <div className="divTableCell"> 
                    <Select
                        className="select"
                        options={cities || []}
                        isSearchable={true}
                        placeholder={newData.pincode || 'Search'}
                        onInputChange={(value, items) => {
                            this.setState({ pinSearch: value });
                            if (value.length > 1) {
                                this.getPincodes(value);
                            }
                        }}
                        isDisabled={this.props.dataGovernance.loading}
                        isLoading={this.props.dataGovernance.loading}
                        autoFocus={this.state.pinSearch}
                        menuIsOpen={this.state.pinSearch}
                        inputValue={this.state.pinSearch}
                        value={(cities || []).filter(obj => { return obj.pincode === newData.pincode})}
                        onChange={(value) => { 
                            const { newData } = this.state;
                            newData.pincode = value.pincode;
                            this.setState({ newData, pinSearch: '' });
                        }}
                    />
                </div>
                <div className="divTableCell laneInput">
                    <textarea
                        className="text-input-content"
                        rows="5"
                        cols="30"
                        placeholder="Address"
                        value={newData.address}
                        onChange={(e) => {
                            const { newData } = this.state;
                            newData.address = e.target.value;
                            this.setState({ newData });
                        }}
                    />
                </div>
                <div className="divTableCell"> 
                    <div className={'action-icon'} style={{backgroundImage: `url(${Save})`}} onClick={this.saveLane} />
                    <div className={'action-icon'} style={{backgroundImage: `url(${Cancel})`}} onClick ={this.cancelAction} />
                </div>
            </div>
        );
    }

    renderTableBody = (laneData) => {
        const { editId, isAdded, selectedLanes } = this.state;
        const { dataGovernance } = this.props;
        let cities = [];

        if (dataGovernance.citiesList && dataGovernance.citiesList.length > 0) {
            (dataGovernance.citiesList || []).forEach((items) => {
                cities = cities.concat(items.cities || items.states || []);
            });
        }
        
        return (
          <Fragment>
            {(laneData || []).length > 0 && 
                (laneData || []).map((lane, index) => {
                    const fromCity = lane.from || {};
                    const toCity = lane.to || {};
                return (
                    <div className="list-section content divTableRow" key={lane.hub_id}>
                        <div className="divTableCell select-row"> 
                        {!isAdded && (index !== editId) && 
                            <input 
                                type="checkbox" 
                                checked={selectedLanes.includes(lane.hub_id)}
                                onClick={(e) => this.handleLaneSelection(e, lane.hub_id)}
                            />
                        }
                        </div>
                        <div className="divTableCell"> 
                            {(editId===index)
                                ?
                                    <Select
                                        className="select"
                                        value={(dataGovernance.vendorTypes || []).filter((vendor) => { return vendor.value===lane.vendor_id })}
                                        options={dataGovernance.vendorTypes || []}
                                        onChange={(value) => { 
                                            const { hubMasterData } = this.state;
                                            hubMasterData[index].vendor_name = value.label;
                                            hubMasterData[index].vendor_id = value.value;
                                            hubMasterData[index].vendor_code = value.sap_vendor_code;
                                            this.setState({ hubMasterData });
                                            // this.onVendorChange(value) 
                                        }}
                                    />
                                :
                                    <Fragment>{lane.vendor_name || ''}</Fragment>
                            }
                        </div>
                        <div className="divTableCell"> 
                            {(editId===index)
                                ?
                                    <Select
                                        className="select"
                                        options={cities || []}
                                        isSearchable={true}
                                        onInputChange={(value) => {
                                            this.setState({ toSearch: value })
                                            if (value.length > 1) {
                                                this.getCities(value)
                                            }
                                        }}
                                        isDisabled={this.props.dataGovernance.loading}
                                        isLoading={this.props.dataGovernance.loading}
                                        autoFocus={this.state.toSearch}
                                        menuIsOpen={this.state.toSearch}
                                        inputValue={this.state.toSearch}
                                        placeholder={lane.city || 'Search'}
                                        value={(cities || []).filter(obj => { return (obj.label === lane.city)})}
                                        onChange={(value) => { 
                                            const { hubMasterData } = this.state;
                                            if (value.city_name) {
                                                hubMasterData[index].city = value.label;
                                                hubMasterData[index].region = value.state_name;
                                                hubMasterData[index].zone_name = value.region;
                                            } else {
                                                hubMasterData[index].region = value.state_name;
                                                hubMasterData[index].zone_name = value.region;
                                            }
                                            this.setState({ hubMasterData });
                                        }}
                                    />
                                :
                                    <Fragment>{lane.city || ''}</Fragment>
                            }
                        </div>
                        <div className="divTableCell"> {lane.region || ''} </div>
                        <div className="divTableCell"> {lane.zone_name || ''} </div>
                        <div className="divTableCell"> 
                        {(editId===index) ?
                            <Select
                                className="select"
                                options={cities || []}
                                isSearchable={true}
                                placeholder={lane.pincode || 'Search'}
                                onInputChange={(value, items) => {
                                    this.setState({ fromPinSearch: value });
                                    if (value.length > 1) {
                                        this.getPincodes(value);
                                    }
                                }}
                                isDisabled={this.props.dataGovernance.loading}
                                isLoading={this.props.dataGovernance.loading}
                                autoFocus={this.state.fromPinSearch}
                                menuIsOpen={this.state.fromPinSearch}
                                inputValue={this.state.fromPinSearch}
                                value={(cities || []).filter(obj => { return obj.pincode === lane.pincode})}
                                onChange={(value) => { 
                                    const { hubMasterData } = this.state;
                                    hubMasterData[index].pincode = value.pincode;
                                    this.setState({ hubMasterData, fromPinSearch: '' });
                                }}
                            />
                          :
                            <Fragment>{lane.pincode || ''}</Fragment>
                        }
                        </div>
                        
                        <div className="divTableCell laneInput"> 
                            {(editId===index) ?
                                    <textarea rows="5" cols="30"
                                        className="text-input-content"
                                        placeholder="Address"
                                        value={lane.address}
                                        onChange={(e) => {
                                            const { hubMasterData } = this.state;
                                            hubMasterData[index].address = e.target.value;
                                            this.setState({ hubMasterData });
                                        }}
                                    />
                                :
                                 <Fragment> {lane.address || ''} </Fragment>
                            }
                        </div>
                        <div className="divTableCell"> 
                        {(editId===index) 
                            ?
                                <Fragment>
                                    <div className={'action-icon'} style={{backgroundImage: `url(${Save})`}} onClick={() => this.saveLane(lane)} />
                                <div className={'action-icon'} style={{backgroundImage: `url(${Cancel})`}} onClick={() => this.editLane(index)} />
                                </Fragment>
                            :
                            <div className={'action-icon'} style={{backgroundImage: `url(${Edit})`}} onClick={() => this.editLane(index, lane)} />
                        }
                        </div>
                    </div>
                )
            })}
          </Fragment>
        )
    }
    
    render() {
        const { hubMasterData, isAdded } = this.state;
        const { dataGovernance } = this.props;
        return (
            <div className="discount-wrap">
                <div className="wrapper">
                    <MainComponent>
                        {this.props.loader.isLoading && <Loader />}
                        {this.props.msgpopup.active && <MSGPopUp close = {this.closeMsgPopup} type = {this.props.msgpopup.type} msg = {this.props.msgpopup.msg}/>}
                        <div className="sub-heading-section"> 
                            <div className="sub-heading"> Hub Master </div>
                        </div>

                        <div className="cta-action-btns cta-btn-right filterSelect">
                            <Select
                                isClearable={true}
                                className="select"
                                placeholder={'Vendor Select'}
                                options={dataGovernance.vendorTypes || []}
                                value={(dataGovernance.vendorTypes || []).filter(obj => {return obj.value===this.state.vendorId})}
                                onChange={(value) => { this.setState({ offset: 0, vendorId: value===null ? '' : value.value }, () => { this.getHubMasterData(this.state.vendorId) }) }}
                            />
                        </div>

                        <div>
                            <div className="cta-action-btns">
                                <button className="button-classic" onClick={this.downloadLane}> Download Template</button>
                                <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
                                
                                <button className="button-classic" onClick={() => {this.uploadExcel.click()}}> Upload Excel </button>
                                <input type='file' style={{ display: 'none'}}  ref={(e) => { this.uploadExcel = e ;}} onChange={this.uploadLane} onClick={(e) => {e.target.value = null }}/>
                            </div>
                            <div className="cta-action-btns cta-btn-right">
                                <button className="button-classic" onClick={() => { this.setState({ isAdded: true }) }}>Add Record</button>
                                <button className="button-classic" onClick={this.deleteLanes}>Delete Record(s)</button>
                                {/*<Button value="Advanced Filters" />*/}
                            </div>

                            <div className="divTable company-list-wrap">
                                <div className="list-section table-header divTableHeading divTableRow">
                                    <div className="divTableCell select-row"> </div>
                                    <div className="divTableCell"> Vendor </div>
                                    <div className="divTableCell"> City </div>
                                    <div className="divTableCell"> State </div>
                                    <div className="divTableCell"> Zone </div>
                                    <div className="divTableCell"> Pincode </div>
                                    <div className="divTableCell"> Hub </div>
                                    <div className="divTableCell"> Action </div>
                                </div>
                                <div className="divTableBody">
                                    {
                                        isAdded && this.renderNewLaneBody()
                                    }
                                    
                                    {this.renderTableBody(hubMasterData)}
                                </div>
                            </div> 

                            <div className="submit-section-pagination">
                                <div className="next">
                                    <Button value="Next" disabled={hubMasterData.length===0 || hubMasterData.length < this.state.limit} click={this.next} />
                                </div>
                                <div className="pre">
                                    <Button value="Previous" disabled={this.state.offset===0} click={this.previous} />
                                </div>
                            </div>

                        </div>
                    </MainComponent>
                </div>
            </div>
        );
    }
}

const CustomeCheckBox = (props) =>{
    return(
        <div className = {props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick = {()=>props.toggle(props.id)}>
            {props.selected && <div className = "inner-circle"></div>}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loader: state.loader,
        msgpopup: state.msgpopup,
        dataGovernance: state.dataGovernance,
    }
}

export default connect(mapStateToProps)(HubMasters);
