import React, { Component, Fragment } from 'react';
import InputText from '../../../common/components/input-text/input-text';
import Select from 'react-select';
import Button from '../../button-classic/button-classic';
import CheckBox from '../../../components/checkbox/checkbox';
import Delete from '../../../../assets/images/delete.svg';
import Edit from '../../../../assets/images/edit.svg';
import FixedDefineProcess from './fixed-define-process/fixed-define-process';
import CostImpactPenality from './cost-impact-penality/cost-impact-penality';
import Dropup from "../../../../assets/images/drop-up-arrow.svg";
import AddRow from "../add-row/add-row";
import Detention from './detention/add_detention';
import Damages from '../credit-master/add-damage';
import PodStatus from './pod-status/pod-status';
import DeleteDebitMaster from './../../../components/delete-confirmation/delete-confirmation';
import { detentionType } from '../../../../utils/constants';
import ShortagePilferage from '../debit-master/shortage_pilferage_tolerance/shoratge_pilferage';
import { uomEquality } from '../../../../utils/common';


const type = [
   { label: 'Fixed Define Process', value: 'Fixed Define Process' },
   { label: 'Cost Impact Penality', value: 'Cost Impact Penality' }
]

class DebitMaster extends Component {
   constructor(props) {
      super(props);
      this.state = {
         data: this.props.accountData,
         selectedOption: null,
         typeSelected: {
            label: 'Fixed Define Process',
            value: 'Fixed Define Process'
         },
         selectedOptionforFailure: {
            label: 'Fixed Define Process',
            value: 'Fixed Define Process'
         },
         selectedOptionforWrongPacement: {
            label: 'Fixed Define Process',
            value: 'Fixed Define Process'
         },
         checkDropDown: [false, false, false, false, false, false, false, false, false, false, false],
         isOpen: false,
         isType: null,
         isSubType: null,
         isAddDetention: false,
         isEditDetentionData: '',
         isAddDamages: false,
         isEditDamagesData: '',
         editLateDeliveryData: ''
      }
   }

   onAdd = (id, subType, data = '') => {
      this.setState({ isOpen: !this.state.isOpen, isType: id, isSubType: subType, editLateDeliveryData: data }, () => {
         if (id !== 7) {
            this.props.getAccountEvents(id);
         }
      });
   }

   toggleAddDamages = (id, isEditDamagesData = '') => {
      const { isAddDamages } = this.state;
      this.setState({ isAddDamages: !isAddDamages, isType: id, isEditDamagesData }, () => {
         if (isEditDamagesData) {
            this.props.getMaterailDetails(isEditDamagesData.sub_type);
         }
      })
   }

   onAddDetention = (isEditDetentionData = '') => {
      const isAddDetention = !this.state.isAddDetention;
      this.setState({ isAddDetention, isEditDetentionData });
   }

   onClose = () => {
      this.setState({ isOpen: !this.state.isOpen });
   }

   typeSelectedforFailure = (selectedOption) => {
      this.setState({ selectedOptionforFailure: selectedOption });
   }

   typeSelectedforWrongPlacement = (selectedOption) => {
      this.setState({ selectedOptionforWrongPacement: selectedOption });
   }

   typeChange = (selectedValue) => {
      this.setState({ typeSelected: selectedValue });
   }

   toggleCheck = (id) => {
      let { checkDropDown } = this.state;
      checkDropDown[id] = !checkDropDown[id];
      this.setState({ checkDropDown, isType: id }, () => {
         if (checkDropDown[id] && ![10,9,11].includes(id)) {
            this.props.getDebitMaster(id);
         } else if (id == 10) {
            this.props.getCreditMaster(id);
         } else if (id == 9) {
            this.props.getDetentionDetails();
         } else if (id == 11) {
            this.props.getShortagePilferage();
            this.props.getProductDetails();
         }
      });
   }

   componentWillReceiveProps = (newProps) => {
      this.setState({ data: newProps.accountData });
      if (newProps.mainTabOption !== this.props.mainTabOption) {
         this.setState({ checkDropDown: [] });
      }
   }

   getVehicleType = (vehicleId) => {
      const vehicleType = this.props.accountData.vehicleType.filter(val => val.id == vehicleId);
      return (vehicleType[0] || [] ).name;
   }

   toggleDeleteDebitMaster = (row_id, num) => {
      this.setState({
         showDeleteDebitMaster: !this.state.showDeleteDebitMaster,
         row_id: row_id,
         num: num
      })
   }

   deleteDebitMaster = () => {
      this.props.deleteDebitMaster(this.state.row_id, this.state.num);
      this.toggleDeleteDebitMaster();
   }

   toggleDeleteCreditMaster = (credit_master_id, num) => {
      this.setState({
         showDeleteCreditMaster: !this.state.showDeleteCreditMaster,
         credit_master_id: credit_master_id,
         num: num
      })
   }

   deleteCreditMaster = () => {
      this.props.deleteCreditMaster(this.state.credit_master_id, this.state.num);
      this.toggleDeleteCreditMaster()
   }

   toggleDeleteDetentionDetails = (detention_id) => {
      this.setState({
         showDeleteDetentionDetails: !this.state.showDeleteDetentionDetails,
         detention_id: detention_id,
      })
   }

   deleteDetentionDetails = () => {
      this.props.deleteDetentionDetails(this.state.detention_id);
      this.toggleDeleteDetentionDetails();
   }

   toggleAddShortage = (value = '') => {
      const { isAddShortage } = this.state;
      this.setState({ isAddShortage: !isAddShortage, editShortagePilferageData: value });
   }


   render() {
      const { data } = this.state;

      return (
         <div className={'debit-master'}>
            <div className={'header'} >Late Delivery
                    <div className={this.state.checkDropDown[7] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(7)}></div>
            </div>
            <div className={`drop-section ${this.state.checkDropDown[7] ? "drop-up" : "drop-close"}`}>
               <div className={'fuel-escalation-table'}>
                  {([5, 6].indexOf(this.props.mainTabOption) === -1) ? <Fragment>
                     <div className={'list-section'}>
                        {[3, 4, 5, 6].indexOf(this.props.mainTabOption) === -1
                           ? <div className={'table-header event'}>Vehicle Type</div> :
                           <div className={'table-header event'}>Chain Type</div>
                        }
                        <div className={'table-header'}>Amount</div>
                        <div className={'table-header'}>Grace</div>
                        <div className={'table-header'}>Action</div>
                     </div>
                     {data.type7 && data.type7.map((row, index) => {
                        return (<div className={'list-section'} key={index}>
                           {[3, 4, 5, 6].indexOf(this.props.mainTabOption) === -1 ?
                              <div className={'table-row event'}>{row.vehicle_type ? this.getVehicleType(row.vehicle_type) : null}</div> :
                              <div className={'table-row event'}>{row.chain_type}</div>
                           }
                           <div className={'table-row'}>{`${row.amount} / ${row.amount_uom == 'hours' ? 'hour' : 'day'}`}</div>
                           <div className={'table-row'}>{`${row.grace} ${row.grace_uom == 'hours' ? 'hour' : 'day'}`}</div>
                           {/* <div className={'table-row action'}><CheckBox status = {row.is_active}/></div> */}
                           <div className={'table-row'}>
                              <div className={'delete-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.onAdd(7, null, row)}></div>
                              <div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleDeleteDebitMaster(row.id, 7)}></div>
                           </div>
                        </div>)
                     })}
                  </Fragment> :
                     <Fragment>
                        <div className={'list-section'}>
                           <div className={'table-header'}>Transporters</div>
                           <div className={'table-header'}>Applicable for all LRs</div>
                           <div className={'table-header'}>Applicable only for late deliveries</div>
                           <div className={'table-header weight'}>
                              <div className={'label'}>Delivery Performance</div>
                              <div className={'slab-weight'}>
                                 <div>Lower Limit(%)</div>
                                 <div>Upper Limit(%)</div>
                                 <div>Deduction(%)</div>
                              </div>
                           </div>
                           <div className={'table-header'}>Actions</div>
                        </div>
                        {data.type7 && data.type7.map((row, index) => {
                           return (<div className={'list-section  oda-charges'} key={index}>
                              <div className={'table-row'}>
                                 {row.transporters && row.transporters.map(transp => {
                                    return (<div style={{ 'marginTop': '5px' }}>{transp.company_name}</div>);
                                 })}
                              </div>
                              <div className={'table-row align-center'}>{row.applicable_on === 1 ? 'Yes' : 'No'}</div>
                              <div className={'table-row align-center'}>{row.applicable_on === 2 ? 'Yes' : 'No'}</div>
                              <div className={'table-row slab-weight'}>
                                 {row.delivery_performance && row.delivery_performance.length > 0 && row.delivery_performance.map((performance, index) => {
                                    return (
                                       <div className={'slab-weight-data row-data align-center'} style={{ 'marginTop': '5px' }} key={index}>
                                          <div>{performance.min}</div>
                                          <div>{performance.max}</div>
                                          <div>{performance.deduction}</div>
                                       </div>
                                    );
                                 })}
                              </div>
                              <div className={'table-row align-center'}>
                                 <div className={'delete-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.onAdd(7, null, row)}></div>
                                 <div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.props.deleteDebitMaster(row.id, 7)}></div>
                              </div>
                           </div>)
                        })}
                     </Fragment>}
                  <div className={'approval-btn'}>
                     <Button value={'Add'} click={() => this.onAdd(7)}></Button>
                  </div>
               </div>
            </div>

            <div className={'header'}>Damages
                    <div className={this.state.checkDropDown[10] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(10)}></div>
            </div>
            <div className={`drop-section ${this.state.checkDropDown[10] ? "drop-up" : "drop-close"}`}>
               <div className={'fuel-escalation-table'}>
                  <div className={'list-section'}>
                     <div className={'table-header'}>Type</div>
                     <div className={'table-header product'}>Product</div>
                     {/* <div className={'table-header'}>Product Type Excludes</div> */}
                     <div className={'table-header'}>Damage Amount</div>
                     <div className={'table-header'}>Maximum Amount to Claimed from Trasporter</div>
                     <div className={'table-header'}>Minimum Amount to Claimed from Trasporter</div>
                     <div className={'table-header'}>Remarks</div>
                     <div className={'table-header'}>Actions</div>
                  </div>
                  {data.type10 && data.type10.map((value, index) => {
                     return (<div className={'list-section'} key={index}>
                        <div className={'table-row'}>{value.sub_type}</div>
                        {/* <div className={'table-row product'}>{value.product_list_inclusive && value.product_list_inclusive.length ? value.product_list_inclusive.map((val,index) => {
                                        return(<div key={index} className={index != 0 ? 'mt-10': ''}>{val}</div>)
                                    }) : ''}</div> */}
                        <div className={'table-row product'}>
                           {value.product_list_inclusive && value.product_list_inclusive.length > 1 && <span style={{ 'color': '#a9a3a3' }}>{`(${value.product_list_inclusive.length}) `}</span>}
                           {value.product_list_inclusive && value.product_list_inclusive.length ? value.product_list_inclusive[0] : ''}
                           <div className={'product-tooltip'}>
                              {value.product_list_inclusive && value.product_list_inclusive.length ? value.product_list_inclusive.map((val, index) => {
                                 return (<div key={index} className={'list'}>{val}</div>)
                              }) : ''}
                           </div>
                        </div>

                        <div className={'table-row'}>{`${value.condition} ${value.damage_rate}`}</div>
                        <div className={'table-row'}>{value.max_amnt_to_be_claimed_transporter}</div>
                        <div className={'table-row'}>{value.min_amnt_to_be_claimed_transporter}</div>
                        <div className={'table-header'}>{value.remarks}</div>
                        <div className={'table-row'}>
                           <div className={'delete-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.toggleAddDamages(10, value)}></div>
                           <div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleDeleteCreditMaster(value.credit_master_id, 10)}></div>
                        </div>
                     </div>)
                  })}
                  <div className={'approval-btn'}>
                     <Button value={'Add'} click={() => this.toggleAddDamages(10)}></Button>
                  </div>
               </div>
            </div>

            <div className={'non-participation-indenting'}>
               <div className={'header'}>Detention
                        <div className={this.state.checkDropDown[9] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(9)}></div>
               </div>
               <div className={`drop-section ${this.state.checkDropDown[9] ? "drop-up" : "drop-close"}`}>
                  <div className={'fuel-escalation-table'}>
                     <Fragment>
                        <div className={'list-section'}>
                        {[3, 4, 5, 6].indexOf(this.props.mainTabOption) > -1 && <div className={'table-header'}>Chain Type</div>}
                           <div className={'table-header detention-grace'}>Grace</div>
                           <div className={'table-header weight weight-detention'}>
                              {/* <div className={'label'}>Slabs</div> */}
                              <div className={'slab-weight'}>
                                 <div>Detention</div>
                                 <div className="vehicle-type">Vehicle Type</div>
                                 <div>Min</div>
                                 <div>Max</div>
                                 <div>Amount</div>
                              </div>
                           </div>
                           <div className={'table-header detention-grace'}>Actions</div>
                        </div>
                        {this.props.detentionDetails && data.detentionDetails.map((row, index) => {
                           return (<div className={'list-section  oda-charges'} key={index}>
                              {[3, 4, 5, 6].indexOf(this.props.mainTabOption) > -1 && <div className={'table-row align-center'}>{row.chain_type}</div>}
                              <div className={'table-row detention-grace align-center'}>{row.detention_grace} {row.detention_grace_uom}</div>
                              <div className={'table-row slab-weight slab-weight-detention'}>
                                 {row.slabs && (row.slabs || []).map((slab, index) => {
                                    return (
                                       <div className={'slab-weight-data row-data align-center'} style={{ 'marginTop': '5px' }} key={index}>
                                          <div>{slab.detention_at && ((detentionType || []).find(detention => detention.value === slab.detention_at) || {}).label || ''}</div>
                                          <div className="vehicle-type">{slab.vehicles && (slab.vehicles || []).map(vehicle => {
                                             return (<div className="vehicle-type-list" style={{ 'marginTop': '5px' }}>{vehicle.vehicle_name}</div>);
                                          })}</div>
                                          <div>{slab.slab_min} {slab.slab_min_uom}</div>
                                          <div>{slab.slab_max} {slab.slab_max_uom}</div>
                                          <div>{slab.detention_amount} {slab.detention_amount_uom}</div>
                                       </div>
                                    );
                                 })}
                              </div>
                              <div className={'table-row align-center detention-grace'}>
                                 <div className={'delete-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.onAddDetention(row)}></div>
                                 <div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleDeleteDetentionDetails(row.detention_id)}></div>
                              </div>
                           </div>)
                        })}
                     </Fragment>
                     <div className={'approval-btn'}>
                        <Button value={'Add'} click={() => this.onAddDetention()}></Button>
                     </div>
                  </div>
               </div>
            </div>

            <div className={'header'}>Shoratage/Pilferage Tolerance
                    <div className={this.state.checkDropDown[11] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(11)}></div>
            </div>
            <div className={`drop-section ${this.state.checkDropDown[11] ? "drop-up" : "drop-close"}`}>
               <div className={'fuel-escalation-table'}>
                  <div className={'list-section'}>
                     <div className={'table-header product'}>Product</div>
                     <div className={'table-header'}>Physical State</div>
                     <div className={'table-header'}>Tolerance</div>
                     <div className={'table-header'}>UOM</div>
                     <div className={'table-header'}>Actions</div>
                  </div>
                  {(data.shoratge_pilferage_tolerance || []).map((value, index) => {
                     return (<div className={'list-section'} key={index}>
                        <div className={'table-row product'}>
                           {(value.product || []).map(el => (<div>{el.materialName}</div>))}
                        </div>
                        <div className={'table-row'}>{value.physical_state}</div>
                        <div className={'table-row'}>{`${value.tolerance_condition && (uomEquality.find(el => el.label === value.tolerance_condition) || {}).value} ${value.tolerance}`}</div>
                        <div className={'table-row'}>{value.tolerance_uom}</div>
                        <div className={'table-row'}>
                           <div className={'delete-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.toggleAddShortage(value)}></div>
                           <div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.props.deleteShortagePilferage(value)}></div>
                        </div>
                     </div>)
                  })}
                  <div className={'approval-btn'}>
                     <Button value={'Add'} click={() => this.toggleAddShortage()}></Button>
                  </div>
               </div>
            </div>

            <div className={'header'}>For Non Participation In Contracting Process
                    <div className={this.state.checkDropDown[1] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(1)}></div>
            </div>
            <div className={`drop-section ${this.state.checkDropDown[1] ? "drop-up" : "drop-close"}`}>
               <div className={'fuel-escalation-table'}>
                  <div className={'list-section'}>
                     <div className={'table-header event'}>Event</div>
                     <div className={'table-header amount'}>Amount</div>
                     <div className={'table-header uom'}>UOM</div>
                     <div className={'table-header action'}>Activate</div>
                     <div className={'table-header action'}>Action</div>
                  </div>
                  {data.type1 && data.type1.map((row, index) => {
                     return (
                        <div className={'list-section'} key={index}>
                           <div className={'table-row event'}>{row.event}</div>
                           <div className={'table-row amount'}>{row.amount}</div>
                           <div className={'table-row uom'}>{row.uom_type}</div>
                           <div className={'table-row action'}><CheckBox status={row.is_active} /></div>
                           <div className={'table-row delete-icon action'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleDeleteDebitMaster(row.id, 1)}></div>
                        </div>)
                  })}
                  <div className={'approval-btn'}>
                     <Button value={'Add'} click={() => this.onAdd(1)}></Button>
                  </div>
               </div>
            </div>

            <div className={'non-participation-indenting'}>
               <div className={'header'}>For Non Participation In Indenting Process
                        <div className={this.state.checkDropDown[2] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(2)}></div>
               </div>
               <div className={`drop-section ${this.state.checkDropDown[2] ? "drop-up" : "drop-close"}`}>
                  <div className={'type-select'}>
                     <Select className={'select'} options={type} value={this.state.typeSelected} onChange={this.typeChange} />
                  </div>
                  {this.state.typeSelected.label == 'Fixed Define Process' && <FixedDefineProcess onAdd={() => this.onAdd(2, 'FixedDefineProcess')} data={data.type2} deleteDebitMaster={this.props.deleteDebitMaster} isType={2} />}
                  {this.state.typeSelected.label == 'Cost Impact Penality' && <CostImpactPenality onAdd={() => this.onAdd(2, 'costImpactPenality')} data={data.type2} deleteDebitMaster={this.props.deleteDebitMaster} isType={2} />}
               </div>
            </div>

            <div className={'non-participation-indenting'}>
               <div className={'header'}>For Failure In Placement / Outbound Operation
                    <div className={this.state.checkDropDown[3] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(3)}></div>
               </div>
               <div className={`drop-section ${this.state.checkDropDown[3] ? "drop-up" : "drop-close"}`}>
                  <div className={'type-select'}>
                     <Select className={'select'} options={type} value={this.state.selectedOptionforFailure} onChange={this.typeSelectedforFailure} />
                  </div>
                  {this.state.selectedOptionforFailure.label == 'Fixed Define Process' && <FixedDefineProcess onAdd={() => this.onAdd(3, 'FixedDefineProcess')} data={data.type3} deleteDebitMaster={this.props.deleteDebitMaster} isType={3} />}
                  {this.state.selectedOptionforFailure.label == 'Cost Impact Penality' && <CostImpactPenality onAdd={() => this.onAdd(3, 'costImpactPenality')} data={data.type3} deleteDebitMaster={this.props.deleteDebitMaster} isType={3} />}
               </div>
            </div>

            <div className={'non-participation-indenting'}>
               <div className={'header'} >For Wrong Input of Placement / Outbound Operation
                    <div className={this.state.checkDropDown[4] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(4)}></div>
               </div>
               <div className={`drop-section ${this.state.checkDropDown[4] ? "drop-up" : "drop-close"}`}>
                  <div className={'type-select'}>
                     <Select className={'select'} options={type} value={this.state.selectedOptionforWrongPacement} onChange={this.typeSelectedforWrongPlacement} />
                  </div>
                  {this.state.selectedOptionforWrongPacement.label == 'Fixed Define Process' && <FixedDefineProcess onAdd={() => this.onAdd(4, 'FixedDefineProcess')} data={data.type4} deleteDebitMaster={this.props.deleteDebitMaster} isType={4} />}
                  {this.state.selectedOptionforWrongPacement.label == 'Cost Impact Penality' && <CostImpactPenality onAdd={() => this.onAdd(4, 'costImpactPenality')} data={data.type4} deleteDebitMaster={this.props.deleteDebitMaster} isType={4} />}
               </div>
            </div>

            <div className={'non-participation-indenting'}>
               <div className={'header'} >Approved Unit Penalty Policy For Mismatch On Indented Service Check List
                        <div className={this.state.checkDropDown[8] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(8)}></div>
               </div>
               <div className={`drop-section ${this.state.checkDropDown[8] ? "drop-up" : "drop-close"}`}>
                  <div className={'fuel-escalation-table'}>
                     <div className={'list-section'}>
                        <div className={'table-header event'}>Event</div>
                        <div className={'table-header amount'}>Amount</div>
                        <div className={'table-header uom'}>UOM</div>
                        <div className={'table-header action'}>Activate</div>
                        <div className={'table-header action'}>Action</div>
                     </div>
                     {data.type8 && data.type8.map((row, index) => {
                        return (<div className={'list-section'} key={index}>
                           <div className={'table-row event'}>{row.event}</div>
                           <div className={'table-row amount'}>{row.amount}</div>
                           <div className={'table-row uom'}>{row.uom_type}</div>
                           <div className={'table-row action'}><CheckBox status={row.is_active} /></div>
                           <div className={'table-row delete-icon action'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleDeleteDebitMaster(row.id, 8)}></div>
                        </div>)
                     })}
                     <div className={'approval-btn'}>
                        <Button value={'Add'} click={() => this.onAdd(8)}></Button>
                     </div>
                  </div>
               </div>
            </div>

            <div className={'non-participation-indenting'}>
               <div className={'header'}>Bill Charges
                        <div className={this.state.checkDropDown[5] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(5)}></div>
               </div>
               <div className={`drop-section ${this.state.checkDropDown[5] ? "drop-up" : "drop-close"}`}>
                  <div className={'fuel-escalation-table'}>
                     <div className={'list-section'}>
                        <div className={'table-header event'}>Event</div>
                        <div className={'table-header amount'}>Amount</div>
                        <div className={'table-header uom'}>UOM</div>
                        <div className={'table-header action'}>Activate</div>
                        <div className={'table-header action'}>Action</div>
                     </div>
                     {data.type5 && data.type5.map((row, index) => {
                        return (<div className={'list-section'} key={index}>
                           <div className={'table-row event'}>{row.event}</div>
                           <div className={'table-row amount'}>{row.amount}</div>
                           <div className={'table-row uom'}>{row.uom_type}</div>
                           <div className={'table-row action'}><CheckBox status={row.is_active} /></div>
                           <div className={'table-row delete-icon action'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleDeleteDebitMaster(row.id, 5)}></div>
                        </div>)
                     })}
                     <div className={'approval-btn'}>
                        <Button value={'Add'} click={() => this.onAdd(5)}></Button>
                     </div>
                  </div>
               </div>
            </div>

            <div className={'non-participation-indenting'}>
               <div className={'header'}>POD Status
                        <div className={this.state.checkDropDown[6] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(6)}></div>
               </div>
               <div className={`drop-section ${this.state.checkDropDown[6] ? "drop-up" : "drop-close"}`}>
                  {
                     this.state.checkDropDown[6] && (
                        <div className={'fuel-escalation-table'}>
                           <PodStatus />
                        </div>
                     )
                  }
               </div>
            </div>

            <div className={'non-participation-indenting'}>
               <div className={'header'}>Other Charges
                        <div className={this.state.checkDropDown[7] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(7)}></div>
               </div>
               <div className={`drop-section ${this.state.checkDropDown[7] ? "drop-up" : "drop-close"}`}>
                  <div className={'fuel-escalation-table'}>
                     <div className={'list-section'}>
                        <div className={'table-header event'}>Event</div>
                        <div className={'table-header amount'}>Amount</div>
                        <div className={'table-header uom'}>UOM</div>
                        <div className={'table-header action'}>Activate</div>
                        <div className={'table-header action'}>Action</div>
                     </div>
                     {data.type6 && data.type6.map((row, index) => {
                        return (<div className={'list-section'} key={index}>
                           <div className={'table-row event'}>{row.event}</div>
                           <div className={'table-row amount'}>{row.amount}</div>
                           <div className={'table-row uom'}>{row.uom_type}</div>
                           <div className={'table-row action'}><CheckBox status={row.is_active} /></div>
                           <div className={'table-row delete-icon action'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleDeleteDebitMaster(row.id, 6)}></div>
                        </div>)
                     })}
                     <div className={'approval-btn'}>
                        <Button value={'Add'} click={() => this.onAdd(6)}></Button>
                     </div>
                  </div>
               </div>
            </div>
            {/* {this.state.showDelete && <DeleteDebitMaster
               toggleDeleteDebitMaster={this.toggleDeleteDebitMaster}
               row_id={this.state.row_id}
               debit_master_id={this.state.debit_master_id}
               num={this.state.num}
               credit_master_id={this.state.credit_master_id}
               deleteDebitMaster={this.props.deleteDebitMaster}
               toggleDelete={this.toggleDelete}
               toggleDeleteCreditMaster={this.toggleDeleteCreditMaster}
               deleteCreditMaster={this.props.deleteCreditMaster}
               detention_id={this.state.detention_id}
               toggleDeleteDetentionDetails={this.toggleDeleteDetentionDetails}
               deleteDetentionDetails={this.props.deleteDetentionDetails}
            />} */}

            {this.state.showDeleteDebitMaster && <DeleteDebitMaster
               toggleDelete={this.toggleDeleteDebitMaster}
               delete={this.deleteDebitMaster}
            />}

            {this.state.showDeleteCreditMaster && <DeleteDebitMaster
               toggleDelete={this.toggleDeleteCreditMaster}
               delete={this.deleteCreditMaster}
            />}

            {this.state.showDeleteDetentionDetails && <DeleteDebitMaster
               toggleDelete={this.toggleDeleteDetentionDetails}
               delete={this.deleteDetentionDetails}
            />}

            {this.state.isOpen && <AddRow
               toggleAdd={this.onClose}
               isType={this.state.isType}
               eventDetails={this.props.eventDetails}
               addDebitMaster={this.props.addDebitMaster}
               updateDebitMaster={this.props.updateDebitMaster}
               data={data}
               subType={this.state.isSubType}
               editLateDeliveryData={this.state.editLateDeliveryData}
               mainTabOption={this.props.mainTabOption}
               transporterList={this.props.transporterList}
               getTransporterList={this.props.getTransporterList}
            />}
            {this.state.isAddDetention && <Detention
               toggleAdd={this.onAddDetention}
               addDetentionDetails={this.props.addDetentionDetails}
               editData={this.state.isEditDetentionData}
               updateDetentionDetails={this.props.updateDetentionDetails}
               mainTabOption={this.props.mainTabOption}
               vehicleTypes={this.props.accountData.vehicleType || []}
            />}
            {this.state.isAddDamages && <Damages
               toggleAdd={this.toggleAddDamages}
               addCreditDetails={this.props.addCreditDetails}
               updateCreditDetails={this.props.updateCreditDetails}
               isType={this.state.isType}
               materialList={this.props.materialList}
               getMaterailDetails={this.props.getMaterailDetails}
               isEditDamagesData={this.state.isEditDamagesData}
               mainTabOption={this.props.mainTabOption}
            />}
            {this.state.isAddShortage && <ShortagePilferage
               toggleAdd={this.toggleAddShortage}
               editData={this.state.editShortagePilferageData}
               productList={this.props.productList}
               addShortagePilferage={this.props.addShortagePilferage}
               companyId={this.props.companyId}
               getProductDetails = {this.props.getProductDetails}
            />

            }

         </div>
      )
   }
}

export default DebitMaster;