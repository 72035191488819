import React, { Component } from "react";
import InputText from "../../../../common/components/input-text/input-text";
import Select from "react-select";
import validate from "../../../../../utils/validate";
import "../../home-navigation-panel.less";
class AddReason extends Component {
    constructor(props) {
      super(props);
      this.state = {
        name: props.editReason ? props.editReason.name : "",
        company_id: props.editReason ? props.editReason.company_id : "",
        type: props.editReason ? props.editReason.type : null,
        type_name: props.editReason ? props.editReason.type_name : "",
        error: "",
        iserror: "",
        errormessage: "",
        isUpdated: props.isReasonUpdated ? props.isReasonUpdated : false,
        reason_id: props.editReason ? props.editReason.reason_id : ""
      };
    }
    onChangeHandler = (value, id, isType) => {
		const result = validate.validationCheck(value, this.state[id], isType);
		const errMsg = validate.legthValidation(id, value);
		this.setState({
			[id]: result,
			errormessage: errMsg
		})
	}
    addIndentingReason = () => {
		let paramObj = {
			"companyId": this.props.company_id,
			"name": this.state.name,
			"type": this.state.type,
            "typeName": this.state.type_name,
		}
        if(this.state.isUpdated){
            paramObj["reasonId"] = this.state.reason_id
        }
		let errorMsg = "";
		const validationError = {
			"Name": validate.isEmpty(paramObj.name),
		}
		const validationFields = [];
		Object.keys(validationError).forEach((key) => {
			if (validationError[key]) {
				validationFields.push(key);
			}
		});
		if (validationFields.length) {
			errorMsg = `Please Enter Valid ${validate.join(validationFields, (item, idx) => {
				if (validationFields.length === 1) {
					return "";
				}
				if (idx > 0 && idx < validationFields.length - 1) {
					return ", ";
				} else if (idx === validationFields.length - 1) {
					return " and ";
				}
				return "";
			})}.`;
		}
		let myDiv = document.getElementById('modal');
		if (errorMsg) {
			myDiv.scrollTop = 0;
			this.setState({
				error: errorMsg
			});
			return;
		}
        if(this.state.isUpdated){
            this.props.editIndentingReasons(paramObj);
		    this.props.toggleAdd7();
        }else{
            this.props.addIndentingReason(paramObj);
		    this.props.toggleAdd7();
        }
		
	}
    render(){
        return(
            <div className="add-user-wrap add-action-wrap-popup">
                <div onClick={this.props.toggleAdd} className="overlay"></div>
                <div id="modal" className="modal-popup">
                <div className="pop-heading">Add Move</div>
                <div className="content">
					<label className="error">{this.state.error}</label>
					<form className="form-section" autoComplete="nope">
							<div className="container-1">
                            
                            <div className="tCenter mb-10">
                                <div className="header">Name</div>
                                <div className="sub-container">
                                    <InputText className="wt-100p tCenter" placeholder="Name" id={"name"} value={this.state.name} changeHandler={this.onChangeHandler} />
                                </div>
                            </div>
                            <div className="tCenter mb-10">
                                <div className="header">Type ID</div>
                                <div className="sub-container">
                                    <InputText className="wt-100p tCenter" placeholder="Type ID" id={"type"} value={this.state.type} changeHandler={this.onChangeHandler} />
                                </div>
                            </div>
                            <div className="tCenter mb-10">
                                <div className="header">Type Name</div>
                                <div className="sub-container">
                                    <InputText className="wt-100p tCenter" placeholder="Type Name" id={"type_name"} value={this.state.type_name} changeHandler={this.onChangeHandler} />
                                </div>
                            </div>
                        
                        </div>
                    </form>
                    <div className="submit-section" style={{display: 'block'}}>
                        <button className="add-button discard" onClick={this.props.toggleAdd7}>Cancel</button>
                        <button className="add-button" onClick={this.addIndentingReason}>Save</button>
                    </div>
                </div>
            </div>
        </div>
        )
    }

}

export default AddReason;
