import React, { Component, Fragment } from 'react';
import "./add-company.less";
import Button from "../../home/button-classic/button-classic";
import CheckBox from "../../components/checkbox/checkbox";
import InputText from "../../common/components/input-text/input-text";
import { legthValidation, validationCheck } from "../../../utils/validate"
import { getCookie } from "../../../utils/cookies";
import Select from "react-select";
import { DEEPAK_COMPANY_ID, ABFRL_COMPANY_IDS } from '../../../utils/common';

const TYPES = [{ id: 1, name: 'LCL' },
{ id: 2, name: 'FTL' },
{ id: 3, name: 'Courier' },
{ id: 4, name: 'Container' },
{ id: 5, name: 'Tanker'}]


class AddCompany extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			iserror: '',
			errormessage: '',

			company_id: JSON.parse(getCookie('user')).company_id,
			vendor_company_name: props.companyApp ? props.companyApp.vendor_company_name : "",
			contact_person_name: props.companyApp ? props.companyApp.contact_person_name : "",
			email: props.companyApp ? props.companyApp.email : "",
			// contact_no: {
			// 	"phone_no": props.companyApp && props.companyApp.contact_no && props.companyApp.contact_no.phone_no ? props.companyApp.contact_no.phone_no : "",
			// 	"mobile_no": props.companyApp && props.companyApp.contact_no && props.companyApp.contact_no.mobile_no ? props.companyApp.contact_no.mobile_no : ""
			// },
			contact_no: props.companyApp ? (typeof (props.companyApp.contact_no) === "string" ? props.companyApp.contact_no : props.companyApp.contact_no.phone_no) :  "",
			company_logo: props.companyApp ? props.companyApp.company_logo : "",
			type: props.companyApp && props.companyApp.type ? props.companyApp.type : [],

			oda: props.companyApp ? props.companyApp.oda : false,
			ob_vendor_status: props.companyApp ? props.companyApp.ob_vendor_status : "new",
			created_by: JSON.parse(getCookie('user')).user_id || '',
			charges: props.companyApp ? props.companyApp.charges : [],
			scopes: props.companyApp ? props.companyApp.scopes : [],
			additional_email: props.companyApp && props.companyApp.additional_email ? props.companyApp.additional_email : [],
			ob_vendor_id: props.companyApp ? props.companyApp.ob_vendor_id : "",
			modeId: props.companyApp ? props.companyApp.modeId : "",
			modes: props.companyApp ? props.companyApp.modes :[],
		}
	}

	onChangeHandler = (value, id, isType) => {
		const result = validationCheck(value, this.state[id], isType);
		const errMsg = legthValidation(id, value);

		if(this.state.email && this.state.vendor_company_name){
			this.setState({validate_error : ''})
		}
		if (id === 'phone_no') {
			const newvalue = value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}|A-Za-z]/g,'');
			this.setState({ contact_no : newvalue, errormessage: errMsg });
		} 
		else if (id === 'contact_person_name') {
			const newvalue = value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}|0-9]/g,'');
			this.setState({ contact_person_name : newvalue, errormessage: errMsg });
		} 
		else {
			this.setState({ [id]: result, errormessage: errMsg });
		}
	}

	handleTypeSelection = (id) => {
		const { modes } = this.state;
		const selectedIndex = modes.indexOf(id);
	  
		if (selectedIndex === -1) {
		  // If the mode is not already selected, add it to the array
		  this.setState((prevState) => ({
			modes: [...prevState.modes, id],
		  }));
		} else {
		  // If the mode is already selected, remove it from the array
		  this.setState((prevState) => ({
			modes: prevState.modes.filter((modeId) => modeId !== id),
		  }));
		}
	  };
	  
	onBlurChange = (e) => {
		const errMsg = legthValidation(e.target.id, e.target.value);
		if (errMsg && e.target.value) {
			this.setState({
				iserror: e.target.id,
				errormessage: errMsg
			})
		} else if (this.state.iserror) {
			this.setState({ iserror: '' });
		}
	}

	addCompanySubmit = (e) => {
		e.preventDefault();
		let error = null;
		let data = Object.assign({}, this.state);
		const mail = data.email;

		if( !mail || !data.vendor_company_name){
			this.setState({validate_error : 'Please fill mandatory fields!'})
		} 
		else{
			console.log('sdasad', mail, mail.trim().length, data)
			if (data.charges.length === 0 && ABFRL_COMPANY_IDS.includes(JSON.parse(getCookie('user')).company_id)) {
				error = "Please select at least one interested service"
			}
			if (data.type.length === 0 && !(DEEPAK_COMPANY_ID.includes(JSON.parse(getCookie('user')).company_id))) {
				error = "Please select a mode type"
			}
			if (data.modes.length === 0 && (DEEPAK_COMPANY_ID.includes(JSON.parse(getCookie('user')).company_id))) {
				error = "Please select a mode type"
			}
			if (data.contact_no.length === 0) {
				error = "Please enter phone no."
			}
			if (mail.trim().length === 0) {
				error = "Please enter valid email"
			}
			this.setState({
				error
			});
			if (error && error.length > 0) {
				return;
			}
			if (!error && !this.state.errormessage) {
				delete data.error;
				delete data.iserror;
				delete data.errormessage;
	
				this.props.submit(data);
			}
		}
	}

	updateCompanySubmit = () => {
		const { email, vendor_company_name, contact_no, ob_vendor_id, company_id, modeId, contact_person_name, additional_email, type, modes } = this.state;
		let data = {
			email,
			vendor_company_name,
			contact_no,
			ob_vendor_id,
			company_id,
			modeId,
			contact_person_name,
			additional_email,
			type,
			modes
		}

		if(!this.state.errormessage){
			this.props.update(data);
		}
	}

	retriggerEmail = () => {
		const { email, ob_vendor_id, company_id } = this.state;
		let data = {
			companyId: company_id,
			vendorId: ob_vendor_id,
			email
		}
		this.props.sendEmail(data);
	}

	checkCharges = (e, id, type, charge) => {
		const { charges } = this.state;
		const item = charges.filter(obj => { return obj.charge_id === charge.charge_id });
		if (item.length > 0 && charges.findIndex((obj) => obj.charge_id === charge.charge_id) > -1) {
			charges.splice(charges.findIndex((obj) => obj.charge_id === charge.charge_id), 1);
		} else {
			charges.push(charge);
		}
		this.setState({ charges });
	}

	toggleScope = (id) => {
      const { scopes } = this.state;
      if ((scopes || []).filter((obj) => { return obj.id === id.id }).length > 0) {
         scopes.splice(scopes.findIndex(obj => obj.id === id.id), 1)
      } else {
         scopes.push(id)
      }
      this.setState({
         scopes
      })
   }

	render() {
		const { props, state } = this;
		const userCompanyId = JSON.parse(getCookie('user')).company_id;
		return (
			<div className="add-company-wrap new-vendor">
				<div onClick={props.close} className="overlay"></div>
				<div className="modal-popup">
					<div className="heading"> New Vendor </div>
					<form className="form-section input-text-align-top" autoComplete="nopes">
						<InputText placeholder="Company Name" id={"vendor_company_name"} label="Company Name" value={state.vendor_company_name} changeHandler={this.onChangeHandler} required />
						<InputText placeholder="Contact Person name" id={"contact_person_name"} label="Contact Person Name" value={state.contact_person_name}  changeHandler={this.onChangeHandler} />
						<InputText placeholder="Email" id={"email"} label="Email" value={state.email} changeHandler={this.onChangeHandler} additionalProps={{ onBlur: this.onBlurChange }} iserror={state.iserror} errormessage={state.errormessage}  required />
						<InputText placeholder="Contact No." id={"phone_no"} label="Contact No." value={state.contact_no} changeHandler={this.onChangeHandler} length={'10'} isValidate={'numeric'} additionalProps={{ onBlur: this.onBlurChange }} iserror={state.iserror} errormessage={state.errormessage} required/>
						{!DEEPAK_COMPANY_ID.includes(userCompanyId) && <div className="input-text-wrap">
							<div className="label">Modes <sup className={'error error-font'}>*</sup></div>
							{TYPES.map((type) => {
								if(DEEPAK_COMPANY_ID.includes(userCompanyId) ? type.id !== 3 : type.id !== 5){
									return (
										<div className="inBlock mr-12">
											<CustomeCheckBox id={type}
												selected={this.state.modeId == type.id}
												toggle={(id) => { this.setState({ modeId: id.id, type: [] }); this.props.getAllVendors(id.id) }}
											/>
											<div className="title"> {type.name} </div>
										</div>
									)
								} 
								
							})}
						</div>} 

						
							{DEEPAK_COMPANY_ID.includes(userCompanyId) && (
								<div className="input-text-wrap">
									<div className="label">Modes</div>
									{TYPES.map((type) => {
								if(DEEPAK_COMPANY_ID.includes(userCompanyId) ? type.id !== 3 : type.id !== 5){
									return (
									<div className="inBlock mr-12" key={type.id}>
										<CustomeCheckBox
										id={type.id}
										selected={this.state.modes.includes(type.id)}
										toggle={() => this.handleTypeSelection(type.id)}
										/>
										<div className="title">{type.name}</div>
									</div>
									)
								} 
							})}
								</div>
								)}



						{!DEEPAK_COMPANY_ID.includes(userCompanyId) && <div className="input-text-wrap" >
							<div className="label">Type <sup className={'error error-font'}>*</sup></div>
							<Select
								isMulti
								placeholder={'Types'}
								className="vendor-select"
								value={this.state.type}
								options={this.props.vendorModes}
								onChange={(value) => { this.setState({ type: value }); }}
							/>
						</div>}
						{/*<InputText placeholder = "type" id = {"type"} label = "Type" changeHandler = {this.handleTypeSelection} />*/}
					</form>

					<div className="mb-20">
						<div className="mb-10">Additional Emails</div>
						{(state.additional_email || []).map((mail, mIdx) => {
						  return (
							<div className="mr-20" key={`mail-${(mIdx+1)}`}>
							  	<InputText id={`${mail}-${mIdx}`} placeholder="Email id" value={mail}
									changeHandler={(value, id, isType) => {
										const { additional_email } = this.state;
										additional_email[mIdx] = value
										this.setState({ additional_email });
									}}
								/>
								{!this.props.companyApp && <span style={{ color: 'red' }} 
									onClick={() => { 
										if (mIdx > -1) {
											const { additional_email } = this.state;
											additional_email.splice(mIdx, 1);
											this.setState({ additional_email });
										} 
									}}
								> X </span>}
							</div>
						  )
						})}
						<div className="button-section">
							<Button value="+ Add Mail" 
								click={() => { 
									const { additional_email } = this.state;
									additional_email.push('');
									this.setState({ additional_email });
								}} 
							/>
						</div>
					</div>
					
					{ABFRL_COMPANY_IDS.includes(userCompanyId) &&
					<div>
						<div className="mb-10">Key Logistics Arrangement</div>
						<div className="grey mb-10">Scope</div>
						<div className="section-item mb-10">
						{(props.scopesList || []).map((scope) => {
							return (
							<div className="dInBlock mr-20" key={`${scope.name}-${scope.id}`}>
							  <CustomeCheckBox id={scope}
							     toggle={this.toggleScope}
							     selected={state.scopes.filter((obj) => { return obj.id === scope.id }).length > 0}
							  />
							  <div className="title"> {scope.name} </div>
							</div>
							)
						})}

						</div>
					</div>}

					{ABFRL_COMPANY_IDS.includes(userCompanyId) &&
					<div className="">
						<div className="header mb-20">
							Charges To Be Applied:
                            </div>
						<div className="input-spacing mb-10">
							<div className="inBlock wt-150"><b>ODA</b></div>
							<div className="inBlock status">
								<CheckBox
									id={"oda"}
									// type={company.company_type} 
									status={state.oda}
									handleChange={(e) => { this.setState({ oda: e.target.checked }) }}
								/>
							</div>
						</div>

						{(props.chargesList || []).map((charge) => {
							const chargeObj = (state.charges).filter(obj => { return obj.charge_id===charge.charge_id });
							const isChecked = chargeObj.length > 0; //(state.charges.filter(obj => { return obj.charge_id === charge.charge_id }).length > 0);
							return (
								<div className="input-spacing mb-10" key={charge.charge_id}>
									<div className="inBlock wt-150"><b>{charge.name}</b></div>
									<div className="inBlock status wt-60">
										<CheckBox
											id={charge.charge_id}
											// type={company.company_type} 
											status={isChecked}
											handleChange={(e, id, type) => { this.checkCharges(e, id, type, charge) }}
										/>
									</div>
									{
										isChecked &&
										<div className="inBlock input-text-align-top">
											<InputText
												id={charge.charge_id}
												placeholder="Charge"
												value={chargeObj ? chargeObj[0].value : charge.value}
												changeHandler={(value, id, isType) => {
													const { charges } = this.state;
													const index = charges.findIndex((obj) => obj.charge_id === id);
													if (index > -1) {
														charges[index].value = value;
														this.setState({ charges });
													}
												}}
											/>
										</div>
									}
								</div>
							)
						})}
					</div>}

					<div>
						<div>

						</div>
						{(state.error || state.iserror) && <div className="error-section">{state.error}</div>}
						{(state.validate_error) && <div className="error-section">{state.validate_error}</div>}
						<div className="submit-section">
							{this.props.companyApp ?
							<Fragment>
							<div className="button-section">
								<Button value="Update" click={this.updateCompanySubmit} />
							</div>
							<div className="button-section">
								<Button value="Resend Email" click={this.retriggerEmail} />
							</div>
							</Fragment> :
							<div className="button-section">
								<Button value="Save" click={this.addCompanySubmit} />
							</div>}
							<div className="button-section">
								<Button value="Cancel" click={props.close} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}


const CustomeCheckBox = (props) => {
	return (
		<div className={props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick={() => props.toggle(props.id)}>
			{props.selected && <div className="inner-circle"></div>}
		</div>
	)
}


export default AddCompany;