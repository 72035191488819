export function isValueThere(data) {
  var C1 = data !== undefined && data !== null && data !== '';
  if (C1) {
    return true;
  }
  return false;
}
export function isValueThereThenReturnData(data) {
  var C1 = data !== undefined && data !== null && data !== '';
  if (C1) {
    return data;
  }
  return null;
}
export var attachGoogleKeyScript = function attachGoogleKeyScript(GOOGLE_API_KEY) {
  var script = document.createElement("script");
  script.src = 'https://maps.googleapis.com/maps/api/js?key=' + GOOGLE_API_KEY + '&v=3.exp&libraries=places';
  script.async = true;
  script.type = 'text/javascript';
  document.head.appendChild(script);
};
export var geocodeAddress = function geocodeAddress(address) {
  return new Promise(function (resolve, reject) {
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({
      'address': address
    }, function (results, status) {
      if (status === 'OK') {
        resolve(results);
      } else {
        reject('Geocode was not successful for the following reason: ' + status);
      }
    });
  });
};
export function getEmamiSeekerCompanyId() {
  var seekerCompanyId = "810e630cbfce43c79b2693c27c35103c";
  var DEPLOYED_ENV = process.env.DEPLOYED_ENV;
  if (DEPLOYED_ENV === "production") {
    seekerCompanyId = "810e630cbfce43c79b2693c27c35103c";
  } else if (DEPLOYED_ENV === "QA") {
    seekerCompanyId = "66204f3fc0de46a99c7556c7c0b05699";
  } else if (DEPLOYED_ENV === "UAT") {
    seekerCompanyId = "66204f3fc0de46a99c7556c7c0b05699";
  } else if (DEPLOYED_ENV === "dev") {
    seekerCompanyId = "66204f3fc0de46a99c7556c7c0b05699";
  }
  return seekerCompanyId;
}
export var isValidContactsArray = function isValidContactsArray(contacts) {
  // Check if contacts exists and is an array
  if (contacts && Array.isArray(contacts)) {
    // Check if any null values exist in the array
    if (contacts.some(function (contact) {
      return contact === null;
    })) {
      return false; // Contains null values
    } else {
      return true; // Does not contain null values
    }
  } else {
    return false; // contacts is not an array or does not exist
  }
};
export var getSecureRandomKey = function getSecureRandomKey() {
  var array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return array[0] / (0xFFFFFFFF + 1);
};
export function debounce(func, delay) {
  var timeoutId;
  return function () {
    var _this = this;
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(function () {
      func.apply(_this, args);
    }, delay);
  };
}