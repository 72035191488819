import React, { Component, } from 'react';
import InputText from "../../../../common/components/input-text/input-text";
import Cx from "classnames";
import NumericInput from 'react-numeric-input';
import { identifier } from 'babel-types';

class AddSlabRange extends Component {
    constructor(props){
        super(props);
        this.state = {
            lowerlimit: this.props.data ? this.props.data.lowerlimit : 0,
            upperlimit: this.props.data ? this.props.data.upperlimit : 0,
            type: this.props.data ? 2 : 1
        }
    }

    onChangeHandler = (value, id) =>{
        this.setState({
            [id] : value
        })
    }

    onSave = () => {
        this.props.onAddSlab(this.state);
        this.props.toggleAdd();
    }

    updateSlabRange = () => {
        let details = this.state;
        if(this.props.data.range_id)
            details.range_id = this.props.data.range_id;
        this.props.updateSlabRange(details);
        this.props.toggleAdd();
    }

    render() {
        const { lowerlimit, upperlimit } = this.state;
        const { data } = this.props;

        return (
            <div className = "add-user-wrap add-bay">
                <div onClick = {this.props.toggleAdd} className = "overlay"></div>
                <div id = "modal" className = "modal-popup">
                    <div className = "pop-heading">
                        {`${data ?'Edit' : 'Add'} Slab Range`}
                    </div>
                    <div className = "content add-edit-user">
                        <form className = "form-section flex" autoComplete = "nope">
                            <div className='flow-head mr-50'>Min. Range
                                <NumericInput min={0} max={100} placeholder = "Min. Range" id="lowerlimit" label = "Min. Range" value = {lowerlimit} onChange={(value) => this.onChangeHandler(value, "lowerlimit")}/>
                            </div>
                            <div className='flow-head'>Max. Range
                                <NumericInput min={0} max={100} placeholder = "Max. Range" id ="upperlimit" label = "Max. Range" value = {upperlimit} onChange={(value) => this.onChangeHandler(value, "upperlimit")}/>
                            </div>
                        </form>
                        
                        <div className = "submit-section">
                            <button className = "add-button discard" onClick={this.props.toggleAdd} >Cancel</button>
                            {!data ? <button className ={Cx("add-button", {
                                // 'disable-btn' : !(upperlimit)
                            })} onClick={this.onSave}>Save</button>
                            :  <button className ={Cx("add-button", {
                                // 'disable-btn' : !(upperlimit)
                            })} onClick={this.updateSlabRange}>Upate</button>
                        }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddSlabRange;