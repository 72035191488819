import React, { Component, Fragment } from "react";
import "./side-panel.less";
import logo from "../../../assets/images/group-2.svg";
import { NavLink } from "react-router-dom";
import { getCookie, deleteCookie } from "../../../utils/cookies";

import history from "../../history";

import { getUserCompanyId, isABFRL, LR_FOR_PANTALOON_COMPANY_ID, EMAMI, ABFRL_COMPANY_IDS } from "../../../utils/common";

class SidePanel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="side-panel-wrap">
        <div className="panel-option-section">
          <PanelOptions />
        </div>
      </div>
    );
  }
}

class PanelOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubMenu: true,
      showVendors: true,
      userInfo: JSON.parse(getCookie("user")),
    };
  }
  render() {
    const { userInfo } = this.state;

    const isEmami = EMAMI.includes(userInfo.company_id);
    // const company_id = JSON.parse(getCookie('user')).company_id
    const isABFRL = ABFRL_COMPANY_IDS.includes(userInfo.company_id)
    const isPantaloons = LR_FOR_PANTALOON_COMPANY_ID.includes(userInfo.company_id)
    return (
      <div className="options-section">
        {userInfo && userInfo.disable_menus.indexOf("home") === -1 && (
          <NavLink activeClassName="active" className="option" to="/home">
            <div className="icon-section">
              <span className="icon-home" />
            </div>
            <div className="icon-name">Home</div>
          </NavLink>
        )}
        {userInfo && userInfo.disable_menus.indexOf("master") === -1 && (
          <NavLink activeClassName="active" className="option" to="/master">
            <div className="icon-section">
              <span className="icon-master" />
            </div>
            <div className="icon-name">Master</div>
          </NavLink>
        )}
        {userInfo && userInfo.disable_menus.indexOf("broadcast") === -1 && (
          <NavLink activeClassName="active" className="option" to="/broadcast">
            <div className="icon-section">
              <span className="icon-broadcast" />
            </div>
            <div className="icon-name">Broadcast</div>
          </NavLink>
        )}
        {userInfo && (userInfo.email==="admin@tms.com" || userInfo.email==="admin@teg.com") 
        && userInfo.user_type===1 && userInfo.disable_menus.indexOf("broadcast") === -1 &&(
          <NavLink activeClassName="active" className="option" to="/bannerSettings">
            <div className="icon-section">
              <span className="icon-broadcast" />
            </div>
            <div className="icon-name">Banner Settings</div>
          </NavLink>
        )}
        {/*<NavLink activeClassName="active" className="option pointer-event" to="/bill">
          <div className="icon-section">
            <span className="icon-bills" />
          </div>
          <div className="icon-name">Bill</div>
        </NavLink>*/}
        {userInfo && userInfo.disable_menus.indexOf("report") === -1 && (
          <NavLink
            activeClassName="active"
            className="option pointer-event"
            to="/report"
          >
            <div className="icon-section">
              <span className="icon-reports" />
            </div>
            <div className="icon-name">Report</div>
          </NavLink>
        )}
        {/*<NavLink
          activeClassName="active"
          className="option pointer-event"
          to="/vehicle-modification"
        >
          <div className="icon-section">
            <span className="icon-vehicle-modification" />
          </div>
          <div className="icon-name">Vehicle Modification</div>
        </NavLink>*/}

        {userInfo && userInfo.disable_menus.indexOf("vendor") === -1 && (
          <div
            className="option masterDataIcon"
            onClick={() => {
              this.setState({ showVendors: !this.state.showVendors });
            }}
          >
            <div className="icon-section">
              <span className="icon-reports" />
            </div>
            <div className="icon-name">
              Vendors <span>+</span>
            </div>
          </div>
        )}
        {userInfo &&
          userInfo.disable_menus.indexOf("vendor") === -1 &&
          this.state.showVendors && (
            <Fragment>
              <div className="showMenus">
                {userInfo &&
                  userInfo.disable_menus.indexOf("vendor-list/1") === -1 && (
                    <NavLink
                      activeClassName="active"
                      className="option"
                      to="/vendor-list/1"
                    >
                      <div className="icon-name">Vendors</div>
                    </NavLink>
                  )}
                {userInfo &&
                  userInfo.disable_menus.indexOf("vendor-list/2") === -1 && (
                    <NavLink
                      activeClassName="active"
                      className="option"
                      to="/vendor-list/2"
                    >
                      <div className="icon-name">Vendor Approvals</div>
                    </NavLink>
                  )}
              </div>
            </Fragment>
          )}
        {userInfo && userInfo.disable_menus.indexOf("mdg") === -1 && (
          <div
            className="option masterDataIcon"
            onClick={() => {
              this.setState({ showSubMenu: !this.state.showSubMenu });
            }}
          >
            <div className="icon-section">
              <span className="icon-reports" />
            </div>
            <div className="icon-name">
              Master Data Governance <span>+</span>
            </div>
          </div>
        )}

        {/*<NavLink activeClassName="active" className="option" to="/discountStructure">
          <div className="icon-section">
            <span className="icon-reports" />
          </div>
          <div className="icon-name">Discount Structure</div>
        </NavLink>*/}
        {userInfo &&
          userInfo.disable_menus.indexOf("mdg") === -1 &&
          this.state.showSubMenu && (
            <Fragment>
              <div className="showMenus">
              {userInfo && !isPantaloons &&
                  userInfo.disable_menus.indexOf("vehicleDriverMaster") ===
                    -1 && (
                    <NavLink
                      activeClassName="active"
                      className="option"
                      to="/vehicleDriverMaster"
                    >
                      <div className="icon-name">Vehicle Driver Master</div>
                    </NavLink>
                  )}
                {userInfo &&
                  userInfo.disable_menus.indexOf("delayPenaltyStructure") ===
                    -1 && (
                    <NavLink
                      activeClassName="active"
                      className="option"
                      to="/delayPenaltyStructure"
                    >
                      <div className="icon-name">Delay Penalty Structure</div>
                    </NavLink>
                  )}
                {userInfo &&
                  userInfo.disable_menus.indexOf("liabilityStructure") ===
                    -1 && (
                    <NavLink
                      activeClassName="active"
                      className="option"
                      to="/liabilityStructure"
                    >
                      <div className="icon-name">Liability Structure</div>
                    </NavLink>
                  )}
                {userInfo &&
                  userInfo.disable_menus.indexOf(
                    "volumetricWeightStructure"
                  ) === -1 && (
                    <NavLink
                      activeClassName="active"
                      className="option"
                      to="/volumetricWeightStructure"
                    >
                      <div className="icon-name">Volumetric Weight</div>
                    </NavLink>
                  )}
                {userInfo &&
                  userInfo.disable_menus.indexOf("laneMasters") === -1 && (
                    <NavLink
                      activeClassName="active"
                      className="option"
                      to="/laneMasters"
                    >
                      <div className="icon-name">Lane Masters</div>
                    </NavLink>
                  )}
                {userInfo &&
                  userInfo.disable_menus.indexOf("tatMasters") === -1 && (
                    <NavLink
                      activeClassName="active"
                      className="option"
                      to="/tatMasters"
                    >
                      <div className="icon-name">City & TAT Masters</div>
                    </NavLink>
                  )}
                  {userInfo &&
                  userInfo.disable_menus.indexOf("networkMaster") === -1 && (
                    <NavLink
                      activeClassName="active"
                      className="option"
                      to="/networkMaster"
                    >
                      <div className="icon-name">Network Master</div>
                    </NavLink>
                  )}
                {userInfo &&
                  userInfo.disable_menus.indexOf("odaMasters") === -1 && (
                    <NavLink
                      activeClassName="active"
                      className="option"
                      to="/odaMasters"
>
                      <div className="icon-name">ODA Masters</div>
                    </NavLink>
                  )}

                {isABFRL
                  ? userInfo &&
                    userInfo.disable_menus.indexOf("glMasters") === -1 && (
                      <NavLink
                        activeClassName="active"
                        className="option"
                        to="/glMasters"
                      >
                        <div className="icon-name">GL Code Masters</div>
                      </NavLink>
                    )
                  : ""}
                {userInfo &&
                  userInfo.disable_menus.indexOf("hubMasters") === -1 && (
                    <NavLink
                      activeClassName="active"
                      className="option"
                      to="/hubMasters"
                    >
                      <div className="icon-name">HUB Masters</div>
                    </NavLink>
                  )}
                {!isEmami && userInfo && !isPantaloons &&
                  userInfo.disable_menus.indexOf("discountStructureRates") ===
                    -1 && (
                    <NavLink
                      activeClassName="active"
                      className="option"
                      to="/discountStructureRates"
                    >
                      <div className="icon-name">Discount Structure</div>
                    </NavLink>
                  )}
                {userInfo &&
                  userInfo.disable_menus.indexOf("ratemasters") === -1 && (
                    <NavLink
                      activeClassName="active"
                      className="option"
                      to="/ratemasters"
                    >
                      <div className="icon-name">Diesel Price Hike</div>
                    </NavLink>
                  )}
                  {userInfo && isABFRL && (
                <NavLink activeClassName="active" className="option" to="/dphAmendment">
                  <div className="icon-name">Dph Amendment</div>
                </NavLink>
              )}
                {userInfo &&
                  userInfo.disable_menus.indexOf("demurrage") === -1 && (
                    <NavLink
                      activeClassName="active"
                      className="option"
                      to="/demurrage"
                    >
                      <div className="icon-name">Demurrage</div>
                    </NavLink>
                  )}
                {userInfo &&
                  userInfo.disable_menus.indexOf("otherCharges") === -1 && (
                    <NavLink
                      activeClassName="active"
                      className="option"
                      to="/otherCharges"
                    >
                      <div className="icon-name">Other Charges</div>
                    </NavLink>
                  )}
                {!isEmami && userInfo && !isPantaloons &&
                  userInfo.disable_menus.indexOf("hsdMaster") === -1 && (
                    <NavLink
                      activeClassName="active"
                      className="option"
                      to="/hsdMaster"
                    >
                      <div className="icon-name">High Speed Diesel</div>
                    </NavLink>
                  )}
              {userInfo && isEmami && (
                <NavLink activeClassName="active" className="option" to="/locationMgmt">
                  <div className="icon-name">Emami Location Management</div>
                </NavLink>
              )}
              </div>
            </Fragment>
          )}
      </div>
    );
  }
}

export default SidePanel;
