import React, { Component, } from 'react';
import InputText from "../../../../common/components/input-text/input-text";
import Cx from "classnames";
import { getEmail, getUserId } from '../../../../../utils/common';

class AddWBMaster extends Component {
    constructor(props){
        super(props);
        this.state = {
            weighbridge_name: this.props.data ? this.props.data.weighbridge_name : '',
            vendor_name: this.props.data ? this.props.data.vendor_name : '',
            plant_name: this.props.data ? this.props.data.plant_name : '',
            branch_code:  this.props.data ? this.props.data.branch_code : '',
        }
    }

    onChangeHandler = (value, id) =>{
        this.setState({
            [id] : value
        })
    }

    onSave = () => {
        this.props.onAddWBMaster(this.state);
        this.props.toggleAdd();
    }

    updateLoadingBay = () => {
        const {match} = this.props
        let details = this.state;
        const branchId = match.params.branchId
        const {previousWBMasterData} = this.props
        if(this.props.data.weighbridge_id)
            details.weighbridge_id = this.props.data.weighbridge_id;
        if(details){
            console.log("previousWBMasterData",previousWBMasterData)
            details.previous_data = previousWBMasterData
            if(details.previous_data){
                details.previous_data.update_flag = true;
                details.previous_data.master_type = 15;
                details.previous_data.user_email = getEmail();
                details.previous_data.user_id = getUserId()
                details.previous_data.branch_id = branchId
            }
        }
        this.props.updateWBMaster(details);
        this.props.toggleAdd();
    }

    render() {
        const { plant_name, weighbridge_name, vendor_name, branch_code } = this.state;
        const { data } = this.props;

        return (
            <div className = "add-user-wrap add-bay">
                <div onClick = {this.props.toggleAdd} className = "overlay"></div>
                <div id = "modal" className = "modal-popup">
                    <div className = "pop-heading">
                        {`${data ?'Edit' : 'Add'} WeighBridge Master`}
                    </div>
                    <div className = "content add-edit-user">
                        <form className = "form-section" autoComplete = "nope">
                            <InputText placeholder = "Enter Name" id = {"weighbridge_name"} label = "WB Master Name" value = {weighbridge_name} changeHandler = {this.onChangeHandler}/>
                            <InputText placeholder = "Vendor" id = {"vendor_name"} label = "Vendor Name" value = {vendor_name} changeHandler = {this.onChangeHandler} />
                            <InputText placeholder = "Plant" id = {"plant_name"} label = "Plant Name" value = {plant_name} changeHandler = {this.onChangeHandler} />
                            <InputText placeholder = "Branch Code" id = {"branch_code"} label = "Branch Code" value = {branch_code} changeHandler = {this.onChangeHandler} />
                        </form>

                        <div className = "submit-section">
                            <button className = "add-button discard" onClick={this.props.toggleAdd} >Cancel</button>
                            {!data ? <button className ={Cx("add-button", {
                                'disable-btn' : !(plant_name && vendor_name && weighbridge_name && branch_code)
                            })} onClick={this.onSave}>Save</button>
                            :  <button className ={Cx("add-button", {
                                'disable-btn' : !(plant_name && vendor_name && weighbridge_name && branch_code)
                            })} onClick={this.updateLoadingBay}>Update</button>
                        }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddWBMaster;