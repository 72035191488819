import ACTION from "../../action_constant";

const initialState = {msg : "",type : null,active : false}

const msgPopUp = (state = initialState, action) => {
    
    let tempState = Object.assign({}, state);
    if(action.type === ACTION.MSG_DISPLAY.SHOW_MSG) {
		
		tempState.msg = action.data.msg;
		tempState.type = action.data.type;
		tempState.active = true;
		return tempState;
	}
	else if(action.type == ACTION.MSG_DISPLAY.HIDE_MSG){
		tempState = initialState;
		return tempState;
	}
	else if(action.type == ACTION.MSG_DISPLAY.IS_FORM_EDITED) {
		tempState.isFormEditing = action.data;
		return tempState
	}
	return state;
};

export default msgPopUp;
