import React, { useState, useEffect, useRef } from "react";
import "./networkMaster.less";
import { connect } from "react-redux";
import Loader from "../../common/components/loader/loader";
import MSGPopUp from "../../common/components/msg-popup/msg-popup";
import Download from "../../../assets/images/direct-download.svg";
import Upload from "../../../assets/images/upload-blue.svg";
import Select from "react-select";
import MainComponent from "../../home/main/main";
import ACTION from "../../common/action_constant";
import { getCookie } from "../../../utils/cookies";
import { Fragment } from "react";
import Edit from "../../../assets/images/newedit.svg";
import Search from "../../../assets/images/Search.svg";
import Button from "../../home/button-classic/button-classic";

const NetworkMaster = (props) => {
  const [isAdded, setisAdded] = useState(false);
  const [input, setinput] = useState({
    company_id: JSON.parse(getCookie("user")).company_id,
    source: "",
    origin_zone: "",
    destination: "",
    lsp: "",
    service_type: "",
    routeName: "",
    routeNumber: "",
    connectivity: "",
    subRouteNumber: "",
    middleMile: "",
    lastMile: "",
  });
  const [searchValue, setSearchValue] = useState("");
  const downloadNMBtn = useRef(null);
  let uploadNMd = useRef("");

  const [downloadLink, setDownloadLink] = useState("");
  const [editId, seteditId] = useState(null);
  const [isError, setisError] = useState("");
  const[offSet,setoffset]=useState(0)
  const limit= 20;
  const {
    originNetworkMasterDropdown,
    modeNetworkMasterDropdown,
    routenameNetworkMasterDropdown,
    sourceNetworkMasterDropdown,
    networkmasterData,
  } = (props || {}).dataGovernance;

  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setinput((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleSelect = (selectedOption, name) => {
    setinput((prevState) => ({ ...prevState, [name]: selectedOption.label }));
  };

  useEffect(() => {
    getNetworkMasterData();
    getoriginNetworkMasterDropdown();
    getmodeNetworkMasterDropdown();
    getroutenameNetworkMasterDropdown();
    getsourceNetworkMasterDropdown();
  

  }, []);
  // useEffect(() => {
  //   if (
  //     props.dataGovernance !== undefined &&
  //     props.dataGovernance.networkmasterData !== networkmasterData
  //   ) {
  //     setnetworkmasterData(props.dataGovernance.networkmasterData);
  //   }
  // }, [props.dataGovernance]);
  const handleclick = (data) => {
    const { dispatch } = props;
   

    if(isAdded)
    {
    dispatch({
      type: ACTION.DATA_GOVERNANCE.POST_NETWORK_MASTER_DATA,
      data: input,
      onSuccess: () => {
        setinput({
          company_id: JSON.parse(getCookie("user")).company_id,
          source: "",
        origin_zone: "",
        destination: "",
        lsp: "",
        service_type: "",
        routeName: "",
        routeNumber: "",
        connectivity: "",
        subRouteNumber: "",
        middleMile: "",
        lastMile: "",});
        setisAdded(false);
        getNetworkMasterData();
      },
    })
  }
  else
  {
    updatenetworkMaster(data);
    
  }
  

    setisError("");
    setisAdded(false);
  };
  const validation = (data) => {
    if (input.company_id&&
      input.origin_zone &&
      input.connectivity &&
      input.destination &&
      input.lastMile &&
      input.lsp &&
      input.middleMile &&
      input.service_type &&
      input.origin_zone &&
      input.routeName &&
      input.routeNumber &&
      input.source
    ) {
      handleclick(data);
      setisError("");
    } else {
      setisError("Please fill all the details.");
    }
  };

  const getNetworkMasterData = (isClear) => {
   
    const { dispatch } = props;
    const user = JSON.parse(getCookie("user"));
    
    let inputData = {
      companyId: user.company_id,
      limit,
      offSet
    };
    if (searchValue && isClear !== 'clear') {
      inputData.search = searchValue;
    }
    dispatch({
      type: ACTION.DATA_GOVERNANCE.GET_NETWORK_MASTER_DATA,
      params: inputData,
    });
  };

  const onClearSearch = () => {
    setSearchValue('');
    getNetworkMasterData('clear');
  }
 
  const closeMsgPopup = () => {
    const { dispatch } = props;
    dispatch({ type: ACTION.MSG_DISPLAY.HIDE_MSG });
  };

  
  const handlecancle = () => {
    setisAdded(false);
    setinput({
      company_id: JSON.parse(getCookie("user")).company_id,
      source: "",
      origin_zone: "",
      destination: "",
      lsp: "",
      service_type: "",
      routeName: "",
      routeNumber: "",
      connectivity: "",
      subRouteNumber: "",
      middleMile: "",
      lastMile: "",
    });
  };
  const handleDownload = () => {
    const { dispatch } = props;
    dispatch({
      type: ACTION.DATA_GOVERNANCE.DOWNLOAD_NM,
      params: `${JSON.parse(getCookie("user")).company_id}&withData=true`,
      onSuccess: (data) => {
        if (data && data.length > 0) {
          setDownloadLink(data[0].Link);
          downloadNMBtn.current.click();
        }
      },
    });
  };
  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      getNetworkMasterData();
    }
    
  };
  const previous = () => {
    if (offSet > 0) {
        setoffset((offSet - 20));
        getNetworkMasterData()

    }
}
 const next = () => {
    setoffset((offSet + 20));
}

useEffect(() => {
  getNetworkMasterData();
}, [offSet])

  //dropdown  apis
  const getoriginNetworkMasterDropdown=()=>{
    const { dispatch } = props;
    const user = JSON.parse(getCookie("user"));

    let inputData = {
      companyId: user.company_id,
    };
    dispatch({
      type: ACTION.DATA_GOVERNANCE.ORIGIN_NETWORK_MASTER_DROPDOWN_DATA,
      // params: inputData,
    });


  }
  const getmodeNetworkMasterDropdown=()=>{

    const { dispatch } = props;
    const user = JSON.parse(getCookie("user"));

    let inputData = {
      companyId: user.company_id,
    };
    
    dispatch({
      type: ACTION.DATA_GOVERNANCE.MODE_NETWORK_MASTER_DROPDOWN_DATA,
      // params: inputData,
    });
  }
  const getroutenameNetworkMasterDropdown=()=>{
    const { dispatch } = props;
    const user = JSON.parse(getCookie("user"));

    let inputData = {
      companyId: user.company_id,
    };
    
    dispatch({
      type: ACTION.DATA_GOVERNANCE.ROUTE_NAME_NETWORK_MASTER_DROPDOWN_DATA,
      // params: inputData,
    });

  }
  const getsourceNetworkMasterDropdown=()=>{
    const { dispatch } = props;
    const user = JSON.parse(getCookie("user"));

    let inputData = {
      companyId: user.company_id,
    };
    
    dispatch({
      type: ACTION.DATA_GOVERNANCE.SOURCE_NETWORK_MASTER_DROPDOWN_DATA,
      params: inputData,
    });

  }
  const onClickEdit=(data,index)=>{
  setisAdded(true);
  setinput(data);
  seteditId((editId===index) ? null : index);


  }

  const updatenetworkMaster = (data) => {
    const { dispatch } = props;
    dispatch({
      type: ACTION.DATA_GOVERNANCE.UPDATE_NETWORK_MASTER_DATA,
      params: data,
      onSuccess: () => {
        setisAdded(false);
        seteditId(null);
        setinput({
          company_id: JSON.parse(getCookie("user")).company_id,
          source: "",
        origin_zone: "",
        destination: "",
        lsp: "",
        service_type: "",
        routeName: "",
        routeNumber: "",
        connectivity: "",
        subRouteNumber: "",
        middleMile: "",
        lastMile: "",});
        getNetworkMasterData();
      },
    });
  };

  const downloadNetworkmasterTemplate = () => {
    const { dispatch } = props;
    dispatch({
      type: ACTION.DATA_GOVERNANCE.DOWNLOAD_NM_TEMPLATE,
      params: `?companyId=${
        JSON.parse(getCookie("user")).company_id
      }&withData=true`,
      onSuccess: (data) => {
        if (data && data.length > 0) {
          setDownloadLink(data[0].Link);
          downloadNMBtn.current.click();
        }
      },
    });
  };
  const uploadNetworkMaster = (e, name) => {
    const { dispatch } = props;
    let formData = new FormData();
    formData.append("file1", e.target.files[0]);
    formData.append("company_id",JSON.parse(getCookie("user")).company_id);
    dispatch({
      type: ACTION.DATA_GOVERNANCE.UPLOAD_NETWORK_MASTER,
      formData, 
      companyId:JSON.parse(getCookie("user")).company_id,
      onSuccess: () => {
        getNetworkMasterData();
      },
    });
  };
  const renderTableBody = () => {
   
    return (
      <Fragment>
        {networkmasterData &&
          (networkmasterData || []).map((data, index) => {
            // {networkmasterData}
            return (
              <tr
                className=" alternaterow"
                key={`liab-${data.networkMaster_id}-${index}`}
              >
                <td className="divTableCell">{data.origin_zone}</td>
                <td className="divTableCell"> {data.source} </td>
                <td className="divTableCell">{data.destination}</td>
                <td className="divTableCell"> {data.lsp} </td>
                <td className="divTableCell"> {data.service_type} </td>
                <td className="divTableCell"> {data.routeName} </td>
                <td className="divTableCell">{data.routeNumber}</td>
                <td className="divTableCell"> {data.middleMile} </td>
                <td className="divTableCell">{data.connectivity}</td>
                <td className="divTableCell">{data.subRouteNumber}</td>
                <td className="divTableCell"> {data.lastMile} </td>
                <td className="divTableCell">
                <td className={"action-icon"} style={{ backgroundImage: `url(${Edit})` }} onClick={() => onClickEdit(data,index)}/></td>
              </tr>
            );
          })}
      </Fragment>
    );
  };
  return (
    
    <div className="wholeNetWorkMaster">
        <MainComponent>
          {props.loader.isLoading && <Loader />}
          {props.msgpopup.active && (
            <MSGPopUp
              close={closeMsgPopup}
              type={props.msgpopup.type}
              msg={props.msgpopup.msg}
            />
          )}
          < div className="heading">
            <div className="mainHeading"> Network Master </div>
            <div className="col2">
              <div className="boxsearch">
            <input
              type="text"
              placeholder="Search"
              className="newsearch"
              value={searchValue}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
            ></input>
            {searchValue&&<span  id= "xinsearch"onClick={onClearSearch}>x</span>}</div>
              
              <button
                className="templatebutton button-classic"
                onClick={downloadNetworkmasterTemplate}
              >
                Download Template
                <i
                  className={"download-icon"}
                  style={{ backgroundImage: `url(${Download})` }}
                ></i>
              </button>
              <a
                className="download-btn"
                style={{ display: "none" }}
                ref={downloadNMBtn}
                href={downloadLink}
                download
              />
              <button
                className=" uploadTemplate button-classic "
                onClick={() => uploadNMd.click()}
              >
                Upload Template
                <i
                  className={"download-icon"}
                  style={{ backgroundImage: `url(${Upload})` }}
                ></i>
              </button>
              <input
                type="file"
                style={{ display: "none" }}
                ref={(e) => {
                  uploadNMd = e;
                }}
                onChange={(e) => {
                  uploadNetworkMaster(e);
                }}
                onClick={(e) => {
                  e.target.value = null;
                }}
              />
              <button
                className="downloadData button-classic"
                onClick={handleDownload}
              >
                Download Data
              </button>
              <button className="addButton" onClick={() => setisAdded(true)}>
                Add New Record
              </button>
            </div>
            
          </div>
          <div className="bodytable">
          <table className="completeTable" >
            <thead className="tableheader">
              <tr>
                <th className="tableCell"> Network </th>
                <th className="tableCell"> Origin </th>
                <th className="tableCell"> City </th>
                <th className="tableCell"> LSP </th>
                <th className="tableCell"> Mode </th>
                <th className="tableCell"> Route Name </th>
                <th className="tableCell"> Route Number </th>
                <th className="tableCell"> Middle Mile </th>
                <th className="tableCell"> Connectivity </th>
                <th className="tableCell"> Sub Route Number </th>
                <th className="tableCell"> Last Mile </th>
                <th className="tableCell">Action
                </th>
              </tr>
            </thead>
            <tbody className="divTableBody">{renderTableBody()}</tbody>
          </table></div>
          <div className="flex flex-between mt-10" style={{'marginLeft': '30px'}}>
                            
                            <div className="prev">
                                <Button value="Previous" disabled={offSet===0} click={previous}  />
                            </div>
                            <div className="nexts" style={{'marginLeft': '40px'}}>
                              <span id="spanz">{offSet+1}-{offSet+networkmasterData.length}</span>
                                <Button value="Next" disabled={networkmasterData.length===0||networkmasterData.length<20}click={next} />
                            </div>
                        </div>
                        
                          
                         
          {isAdded && (
            <div className="add-user-wrap">
              <div className="overlay"></div>
              <div
                id="modal"
                className="modal-popup popupBody"
                style={{ width: "1100px", minHeight: "410px" }}
              >
                {input.network_id ? (
                  <div className="popupHeading"> update Network Details</div>
                ) : (
                  <div className="popupHeading"> Add Network Details</div>
                )}
                <div className="content">
                  <div className="popupcontainer">
                    <div className="alldropdown">
                      <div className="cta-action-btns filterSelect mr-20">
                        <div className="label">Network</div>
                        <Select
                          className="select"
                          name="origin_zone"
                          options={sourceNetworkMasterDropdown || []}
                          value={input.origin_zone && (sourceNetworkMasterDropdown || []).filter(el => el.label === input.origin_zone)}
                          placeholder={"Search"}
                          onChange={(selectedOption) =>
                            handleSelect(selectedOption, "origin_zone")
                          }
                        />
                      </div>
                      <div className="cta-action-btns filterSelect mr-20">
                        <div className="label">Origin</div>
                        <Select
                          className="select"
                          name="source"
                          options={originNetworkMasterDropdown || []}
                          value={input.source && (originNetworkMasterDropdown || []).filter(el => el.label === input.source)}
                          placeholder={"Search"}
                          onChange={(selectedOption) =>
                            handleSelect(selectedOption, "source")
                          }
                        />
                      </div>
                      <div className="cta-action-btns filterSelect mr-20">
                        <div className="label">city</div>
                        <input
                          type="text"
                          required
                          name="destination"
                          value={input.destination}
                          placeholder="City"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="cta-action-btns filterSelect mr-20">
                        <div className="label">LSP</div>
                        <input
                          type="text"
                          required
                          className="inputs"
                          name="lsp"
                          value={input.lsp}
                          placeholder="LSP"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="cta-action-btns filterSelect mr-20">
                        <div className="label">Mode</div>
                        <Select
                          className="select"
                          name="service_type"
                          required
                          options={modeNetworkMasterDropdown || []}
                          value={input.service_type && (modeNetworkMasterDropdown || []).filter(el => el.label === input.service_type)}
                          
                          placeholder={"Search"}
                          onChange={(selectedOption) =>
                            handleSelect(selectedOption, "service_type")
                          }
                        />
                      </div>
                      <div className="cta-action-btns filterSelect mr-20">
                        <div className="label">Route Name</div>
                        <Select
                          className="select"
                          name="routeName"
                          required
                          
                          options={routenameNetworkMasterDropdown || []}
                          value={input.routeName && (routenameNetworkMasterDropdown || []).filter(el => el.label === input.routeName)}
                          
                          placeholder={"Search"}
                          onChange={(selectedOption) =>
                            handleSelect(selectedOption, "routeName")
                          }
                        />
                      </div>
                      <div className="cta-action-btns filterSelect mr-20">
                        <div className="label">Route Number</div>

                        <input
                          type="text"
                          required
                          name="routeNumber"
                          value={input.routeNumber}
                          placeholder="Route Number"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="cta-action-btns filterSelect mr-20">
                        <div className="label">Middle Mile</div>

                        <input
                          type="text"
                          required
                          name="middleMile"
                          value={input.middleMile}
                          placeholder="Middle Mile"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="cta-action-btns filterSelect mr-20">
                        <div className="label">connectivity</div>

                        <input
                          type="text"
                          required
                          name="connectivity"
                          value={input.connectivity}
                          placeholder="connectivity"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="cta-action-btns filterSelect mr-20">
                        <div className="label">Sub Route Number</div>

                        <input
                          type="text"
                          required
                          name="subRouteNumber"
                          value={input.subRouteNumber}
                          placeholder="Sub Route Number"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="cta-action-btns filterSelect mr-20">
                        <div className="label">Last Mile</div>

                        <input
                          type="text"
                          required
                          name="lastMile"
                          value={input.lastMile}
                          placeholder="Last Miles"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="submit-section mt-40 buttonstab">
                      {input.network_id ? (
                        <button
                          className="add-button"
                          onClick={()=>updatenetworkMaster(input)}
                        >
                          Update
                        </button>
                      ) : (
                        <button className="add-button" onClick={()=>validation(input)}>
                          Save
                        </button>
                      )}
                      <button
                        className="add-button discard"
                        onClick={handlecancle}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                {isError.length > 0 && (
                  <div
                    className="error"
                    style={{ position: "absolute", top: "350px", left: "10px" }}
                  >
                    {isError}
                  </div>
                )}
              </div>
            </div>
          )}
        </MainComponent>
     
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.loader,
    msgpopup: state.msgpopup,
    dataGovernance: state.dataGovernance || {},
  };
};

export default connect(mapStateToProps)(NetworkMaster);
