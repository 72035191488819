import React, { Component } from 'react';
import Select from 'react-select';
import CheckBox from '../../../components/checkbox/checkbox';
import InputText from '../../../common/components/input-text/input-text';
import '../reconciliation.less';
import { Uom } from '../../../../utils/common';
import { uomType, chainType } from '../../../../utils/constants';
import { decimalNumber } from '../../../../utils/validate';
import Button from '../../button-classic/button-classic';
import Delete from '../../../../assets/images/delete.svg';
import Add from '../../../../assets/images/add.svg';

class AddRow extends Component {
   constructor(props) {
      super(props);
      this.state = {
         data: {
            type: '',
            event: '',
            vehicle_type: props.editLateDeliveryData ? props.editLateDeliveryData.vehicle_type : '',
            vehicle_type_name: props.editLateDeliveryData ? props.editLateDeliveryData.vehicle_type_name : '',
            is_active: props.editLateDeliveryData ? props.editLateDeliveryData.is_active : false,
            amount: props.editLateDeliveryData ? (props.editLateDeliveryData.amount || '').toString() : '',
            grace: props.editLateDeliveryData ? (props.editLateDeliveryData.grace || '').toString() : '',
            uom_type: '',
            percentage: '',
            percentage_of: '',
            grace_uom: props.editLateDeliveryData ? props.editLateDeliveryData.grace_uom : '',
            amount_uom: props.editLateDeliveryData ? props.editLateDeliveryData.amount_uom : '',
            chain_type: props.editLateDeliveryData ? props.editLateDeliveryData.chain_type : '',
            transporters: props.editLateDeliveryData && props.editLateDeliveryData.transporters && props.editLateDeliveryData.transporters.length > 0 ? props.editLateDeliveryData.transporters.map(val => { return { label: val.company_name, value: val.id }}) : [],
            applicable_on: props.editLateDeliveryData ? props.editLateDeliveryData.applicable_on : 1,
            delivery_performance: props.editLateDeliveryData ? props.editLateDeliveryData.delivery_performance : [{
               min: '',
               max: '',
               deduction: ''
            }]
         },
         selectOptions: [],
         vehicleOptions: []
      }
   }

   componentDidMount = () => {
      let vehicleOptions = [];
      if (this.props.data.vehicleType) {
         for (var i in this.props.data.vehicleType) {
            vehicleOptions.push({ label: this.props.data.vehicleType[i].name, value: this.props.data.vehicleType[i].id })
         }
         this.setState({ vehicleOptions });
      }
   }

   componentWillReceiveProps = (newProps) => {
      let selectOptions = [];
      if (Object.keys(newProps.eventDetails).length) {
         for (let i in newProps.eventDetails['type' + newProps.isType]) {
            selectOptions.push({ label: newProps.eventDetails['type' + newProps.isType][i].event_name, value: newProps.eventDetails['type' + newProps.isType][i].event_name });
         }
         this.setState({ selectOptions });
      }
   }

   handleChange = (id, selectOptions) => {
      let { data } = this.state;
      data[id] = selectOptions.value;
      if(id === 'vehicle_type') {
         data['vehicle_type_name'] = selectOptions.label;
      }
      this.setState({ data });
   }

   checkBoxChange = (e, id) => {
      let { data } = this.state;
      data[id] = e.target.checked;
      this.setState({ data });
   }

   onChangeHandler = (value, id) => {
      if (decimalNumber(value) || value == '') {
         let { data } = this.state;
         data[id] = value;
         this.setState({ data });
      }
   }

   addDebitMaster = () => {
      let { data } = this.state;
      data.transporters = data.transporters.length > 0 ? data.transporters.map(val => { return { company_name : val.label, id : val.value }}) : data.transporters;
      data.type = this.props.isType;
      this.props.toggleAdd();
      this.props.addDebitMaster(data);
   }

   updateLateDelivery = () => {
      let { data } = this.state;
      data.transporters = data.transporters.length > 0 ? data.transporters.map(val => { return { company_name : val.label, id : val.value }}) : data.transporters;
      data.type = this.props.isType;
      data.id = this.props.editLateDeliveryData.id;
      data.load_type = this.props.editLateDeliveryData.load_type;
      data.mode_type = this.props.editLateDeliveryData.mode_type;
      this.props.toggleAdd();
      this.props.updateDebitMaster(data);
   }

   validateVal = (value) => {
      return value.toString().match(/^\d{1,8}(\.\d{1,3})?$/)
   }
   validateGrace = (value) => {
      return value.toString().match(/^\d{1,3}?$/)
   }

   validData = ({ data } = this.state) => {
      if(([5,6].indexOf(this.props.mainTabOption) === -1) || this.props.isType !== 7) {
         if (data.vehicle_type) {
            return (data.vehicle_type && this.validateVal(data.amount) && this.validateGrace(data.grace) && data.amount_uom && data.grace_uom);
         }
         if (data.chain_type) {
            return ((data.chain_type && this.validateVal(data.amount) && this.validateGrace(data.grace) && data.amount_uom && data.grace_uom) || (data.event && data.chain_type.toString()!=="" && this.validateVal(data.amount) && data.uom_type))
         }
         if (data.event ) {
            if ( data.chain_type) {
               return (data.event && data.chain_type && this.validateVal(data.amount) && data.uom_type)
            }
            else if (!data.chain_type){
               return (data.event && data.chain_type && this.validateVal(data.amount) && data.uom_type) || (data.event && this.validateVal(data.amount) && data.uom_type)
            }
         }
      } else if(([5,6].indexOf(this.props.mainTabOption) > -1) && this.props.isType === 7) {
         return (data.applicable_on && data.transporters.length > 0 && data.delivery_performance.length > 0 && data.delivery_performance[0].min && data.delivery_performance[0].max && data.delivery_performance[0].deduction);
      }
      // else{
      //    return (data.event && this.validateVal(data.amount) && data.uom_type)
      // }
   }

   onChangeDeliveryPerformance = (index, value, field) => {
      const { data } = this.state;
      data.delivery_performance[index][field] = value;
      this.setState({ data });
   }

   onAddDeliveryPerformance = () => {
      const { data } = this.state;
      data.delivery_performance.push({
         min: '',
         max: '',
         deduction: ''
      })
      this.setState({ data });
   }

   onDeleteDeliveryPerformance = (index) => {
      const { data } = this.state;
      data.delivery_performance.splice(index, 1);
      this.setState({ data });
   }

   clickCustomeCheckBoxService = (id) => {
      const { data } = this.state;
      data.applicable_on = id;
      this.setState({ data });
   }

   onTransporterSearch = (value) => {
      if(value) {
         this.props.getTransporterList(value);
      }
   }

   render() {
      const { data } = this.state;
      const { transporterList } = this.props;
      //  const disable = ((data.vehicle_type ? (data.vehicle_type && this.validateVal(data.amount) && this.validateGrace(data.grace) && data.amount_uom && data.grace_uom) : (data.vehicle_type && this.validateVal(data.amount) && this.validateGrace(data.grace) && data.amount_uom && data.grace_uom))
      //     || (data.chain_type ? (data.chain_type && this.validateVal(data.amount) && this.validateGrace(data.grace) && data.amount_uom && data.grace_uom) : (data.chain_type && this.validateVal(data.amount) && this.validateGrace(data.grace) && data.amount_uom && data.grace_uom)) ||
      //     (data.event ? (data.chain_type ? (data.event && data.chain_type && this.validateVal(data.amount) && data.uom_type) : ((data.event && this.validateVal(data.amount) && data.uom_type))) : (data.event && this.validateVal(data.amount) && data.uom_type)));
      const disable = this.validData();
      return (<div className="add-company-wrap add-provider-root add-row">
         <div onClick={this.props.toggleAdd} className="overlay">
            <div className="modal-popup" onClick={(e) => { e.stopPropagation() }}>
               <div className="heading align-center">
                  {`${this.props.editLateDeliveryData ? 'Edit' : 'Add'} Event`}
               </div>
               {(([5,6].indexOf(this.props.mainTabOption) === -1) || this.props.isType !== 7)  && <form className="form-section" autoComplete="nopes">
                  {this.props.isType !== 7 && <div className="select-wrap">
                     <div className="label">Event</div>
                     <Select className={'select'} value={this.state.event} onChange={this.handleChange.bind(this, 'event')} options={this.state.selectOptions} />
                  </div>}
                  {this.props.isType == 7 && [3, 4, 5, 6].indexOf(this.props.mainTabOption) === -1 && <div className="select-wrap">
                     <div className="label">Vehicle Type</div>
                     <Select className={'select'} value={this.state.vehicleOptions.filter(vehicle => { return vehicle.value === data.vehicle_type })} onChange={this.handleChange.bind(this, 'vehicle_type')} options={this.state.vehicleOptions} />
                  </div>
                  }
                  {[3, 4, 6].indexOf(this.props.mainTabOption) > -1 && <div className="select-wrap">
                     <div className="label">Chain Type</div>
                     <Select className={'select'}
                        options={chainType}
                        value={chainType.filter(val => { return val.label === data.chain_type })}
                        onChange={(options) => {
                           const { data } = this.state;
                           data.chain_type = options.label;
                           this.setState({ data });
                        }}
                     />
                  </div>}
                  {this.props.isType == 7 && <InputText placeholder={'Grace'} label={'Grace'} id={'grace'} changeHandler={this.onChangeHandler} value={this.state.data.grace} type={'number'} />}
                  {this.props.isType == 7 && <div className="select-wrap">
                     <div className="label">UOM</div>
                     <Select className={'select'} value={data.grace_uom ? { label: data.grace_uom, value: data.grace_uom } : null} onChange={this.handleChange.bind(this, 'grace_uom')} options={uomType} />
                  </div>
                  }
                  {this.props.subType !== 'costImpactPenality' && <InputText placeholder={'Amount'} label={'Amount'} id={'amount'} value={this.state.data.amount} changeHandler={this.onChangeHandler} type={'number'} />}
                  {this.props.isType == 7 && <div className="select-wrap">
                     <div className="label">UOM</div>
                     <Select className={'select'} value={data.amount_uom ? { label: data.amount_uom, value: data.amount_uom } : null} onChange={this.handleChange.bind(this, 'amount_uom')} options={uomType} />
                  </div>
                  }
                  {this.props.subType == 'costImpactPenality' && <InputText placeholder={'percentage'} label={'%'} id={'percentage'} value={this.state.data.percentage} changeHandler={this.onChangeHandler} type={'number'} />}
                  {this.props.subType == 'costImpactPenality' && <InputText placeholder={'percentage of'} label={'% of'} id={'percentage_of'} value={this.state.data.percentage_of} changeHandler={this.onChangeHandler} type={'number'} />}
                  {this.props.isType !== 7 && this.props.subType !== 'costImpactPenality' && <div className="select-wrap">
                     <div className="label">UOM</div>
                     <Select className={'select'} options={Uom} onChange={this.handleChange.bind(this, 'uom_type')} />
                  </div>}
                  {/* <div className="select-wrap">
                            <div className="label">Active</div>
                            <CheckBox status = {this.state.data.is_active} id = {'is_active'} handleChange = {this.checkBoxChange} />
                        </div> */}
               </form>}
               { ([5,6].indexOf(this.props.mainTabOption) > -1) && this.props.isType === 7  && 
                  <form className="form-section bill-number" autoComplete="nopes">
                     <div className="label">Transporters</div>
                     <Select 
                        isMulti
                        className={'select'}
                        options={transporterList && transporterList.length > 0 ? transporterList.map(trans => {
                           return { label : trans.company_name, value: trans.branch_id }
                       }) : []}
                        value={data.transporters}
                        onChange={(options) => {
                           const { data } = this.state;
                           data.transporters = options;
                           this.setState({ data });
                        }}
                        onInputChange={this.onTransporterSearch}
                     />
                     <div style={{ 'display': 'inline-block', 'verticalAlign' : 'bottom'}}>
                        <div className = "section-item all" style={{'marginLeft' : '30px'}}>
                           <CustomeCheckBox id = {1} toggle = {this.clickCustomeCheckBoxService}  selected={data.applicable_on === 1}/>
                           <div className = "title"> {'Applicable On All LRs'}</div>
                        </div>
                        <div className = "section-item all" style={{'marginLeft' : '30px'}}>
                           <CustomeCheckBox id = {2} toggle = {this.clickCustomeCheckBoxService} selected={data.applicable_on === 2}/>
                           <div className = "title"> {'Applicable Only On Late Deliveries'}</div>
                        </div>
                     </div>
                     <div className={'heading'} style={{'marginTop': '15px'}}>Delivery Performance</div>
                     <div className={'fuel-escalation-table'}>
                        <div className={'list-section'}>
                           <div className={'table-header'}>Lower Limit %(inclusive)</div>
                           <div className={'table-header'}>Upper Limit %(inclusive)</div>
                           <div className={'table-header'}>Deduction %</div>
                           <div className={'table-header'}>Actions</div>
                        </div>
                        {data.delivery_performance && data.delivery_performance.length > 0 && data.delivery_performance.map((performance, ind) => {
                        return(
                           <div className={'list-section deliveryPerformance'}>
                              <div className={'table-row align-center'}>
                                 <InputText 
                                    placeholder={'Lower Limit %'} 
                                    id='min' 
                                    value={performance.min}
                                    changeHandler={this.onChangeDeliveryPerformance.bind(this, ind)}
                                    type={'number'}
                                 />
                              </div>
                              <div className={'table-row align-center'}>
                                 <InputText 
                                    placeholder={'Upper Limit %'} 
                                    id='max' 
                                    value={performance.max}
                                    changeHandler={this.onChangeDeliveryPerformance.bind(this, ind)}
                                    type={'number'}
                                 />
                              </div>
                              <div className={'table-row align-center'}>
                                 <InputText 
                                    placeholder={'Deduction %'} 
                                    id='deduction' 
                                    value={performance.deduction}
                                    changeHandler={this.onChangeDeliveryPerformance.bind(this, ind)}
                                    type={'number'}
                                 />
                              </div>
                              <div className={'table-row'}>
                                 {data.delivery_performance.length - 1 === ind && <div className={'delete-icon'} style={{backgroundImage: `url(${Add})`}} onClick={this.onAddDeliveryPerformance}></div>}
                                 {data.delivery_performance.length > 1 && <div className={'delete-icon'} style={{backgroundImage: `url(${Delete})`}} onClick={() => this.onDeleteDeliveryPerformance(ind)}></div>}
                              </div>
                           </div>)})}
                     </div>
                  </form>
               }
               <div className="submit-section submit-area">
                  {
                     <div className="button-section">
                        {!this.props.editLateDeliveryData && <div className="button-section">
                           <Button className="add-button" click={this.addDebitMaster} disabled={!disable} value="Save" />
                        </div>}
                        {this.props.editLateDeliveryData && <div className="button-section">
                           <Button className="add-button" click={this.updateLateDelivery} disabled={!disable} value="Update" />
                        </div>}
                     </div>
                  }
                  <div className="button-section" >
                     <div className="add-button" onClick={this.props.toggleAdd}>Close</div>
                  </div>

               </div>
            </div>
         </div>
      </div>)
   }
}

const CustomeCheckBox = (props) =>{
   return(
       <div className = {props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick = {()=>props.toggle(props.id)}>
           {props.selected && <div className = "inner-circle"></div>}
       </div>
   )
}

export default AddRow;