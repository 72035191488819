import React, { Component } from "react";
import Button from '../../home/button-classic/button-classic';
import ACTIONS from "../../common/action_constant";
import Select from "react-select";
import { connect } from "react-redux";
import { getCookie } from "../../../utils/cookies";
import moment from 'moment';
import Switch from "react-switch";
import './bill_mngt.less';
import Info from "../../../assets/images/info.svg";
import ReactTooltip from 'react-tooltip';

const YearFormats = [
    {label: 'YYYY', value: '%Y'},
    {label: 'YY', value: '%y'}
]

const DateFormats = [
    {label: 'YYYYMMDD', value: '%Y%m%d'},
    {label: 'YYYY-MM-DD', value: '%Y-%m-%d'},
    {label: 'YYYY/MM/DD', value: '%Y/%m/%d'},
    {label: 'DDMMYY', value: '%d%m%y'},
    {label: 'DD-MM-YY', value: '%d-%m-%y'},
    {label: 'DD/MM/YY', value: '%d/%m/%y'},
]

class AddBill extends Component{
    constructor(props){
        super(props);
        this.state={
            seekerDropDown: [],
            branchDropDown: [],
            selectedSeeker: (this.props.editData && this.props.editData.customer_id) ? this.props.editData.customer_id : "",
            selectedSeekerName: (this.props.editData && this.props.editData.customer_name) ? this.props.editData.customer_name : "",
            branches: (this.props.editData && this.props.editData.branches) ? this.props.editData.branches : [],
            bill_format: (this.props.editData && this.props.editData.bill_format) ? this.props.editData.bill_format : "",
            fys_format: (this.props.editData && this.props.editData.fys_format) ? this.props.editData.fys_format : null,
            fye_format: (this.props.editData && this.props.editData.fye_format) ? this.props.editData.fye_format : null,
            date_format: (this.props.editData && this.props.editData.date_format) ? this.props.editData.date_format : null,
            current_fye: new Date().getFullYear(),
            reset_seq_on_fye: (this.props.editData && this.props.editData.reset_seq_on_fye) ? this.props.editData.reset_seq_on_fye : false,
            reset_seq_to: (this.props.editData && this.props.editData.reset_seq_to) ? this.props.editData.reset_seq_to : null,
            seq_no: (this.props.editData && this.props.editData.seq_no) ? this.props.editData.seq_no : "",
            fys_selected: (this.props.editData && this.props.editData.bill_format) ? this.props.editData.bill_format.includes('__FYS__') : false,
            fye_selected: (this.props.editData && this.props.editData.bill_format) ? this.props.editData.bill_format.includes('__FYE__') : false,
            date_selected: (this.props.editData && this.props.editData.bill_format) ? this.props.editData.bill_format.includes('__DATE__') : false,
            sequence_selected: (this.props.editData && this.props.editData.bill_format) ? this.props.editData.bill_format.includes('__SEQ__') : false,
            fye_buffer_days: (this.props.editData && this.props.editData.fye_buffer_days) ? this.props.editData.fye_buffer_days : "",
            min_seq_length: (this.props.editData && this.props.editData.min_seq_length) ? this.props.editData.min_seq_length : "",
        }
    }

    componentDidMount() {
        // this.getBranchForLR()
        this.fetchAllSeekers()
    }

    getBranchForLR = (seeker) => {
        const { dispatch, editData } = this.props;
        const { branchDropDown } = this.state;
        let selectedSeekers = seeker || editData.customer_id;
        dispatch({
          type: ACTIONS.HOME.GET_BRANCH_FOR_LR,
          data: `${selectedSeekers}&limit=300`,
          onSuccess: (data) => {
            data && data.list && data.list.map(d => {
              branchDropDown.push({ id: d.branch_id, name: d.branch_name})
            })
            this.setState({ branchDropDown });
          },
        })
    }

    fetchAllSeekers = () => {
        const { dispatch } = this.props;
        const { seekerDropDown } = this.state;
        dispatch({
          type: ACTIONS.HOME.GET_ALL_SEEKERS_FOR_LR,
          onSuccess: (data) => {
            data && data.list && data.list.map((ele, idx) => {
              seekerDropDown.push({ value: ele.id, label: ele.company_name })
            })
            this.setState({ seekerDropDown }, this.getBranchForLR());
          }
        });
    }

    handleSelectedSeeker = (e) => {
        this.setState({ selectedSeeker: e.value, selectedSeekerName: e.label, branches: [], branchDropDown: [] }, () => {
          this.getBranchForLR(this.state.selectedSeeker)
        })
    }

    handleSelectBranch = (e) => {
        this.setState({ branches: e})
    }

    handleFormatSelection = (value, name) => {
        this.setState({[name]: value.id})
    }

    handleSwitchChange = () => (checked) => {
        this.setState({ reset_seq_on_fye: checked });
    };

    handleBillFormat = (value) => {
        let {fys_selected, fye_selected, date_selected, sequence_selected, bill_format } = this.state;
        if(value && value.length > 0){
            if(value.includes('__FYS__')){
                fys_selected = true;
            } else{
                fys_selected = false;
            }
            if(value.includes('__FYE__')){
                fye_selected = true;
            } else{
                fye_selected = false;
            }
            if(value.includes('__DATE__')){
                date_selected = true;
            } else{
                date_selected = false;
            }
            if(value.includes('__SEQ__')){
                sequence_selected = true;
            } else {
                sequence_selected = false;
            }
        } else {
            fys_selected = false;
            fye_selected = false;
            date_selected = false;
            sequence_selected = false;
        }
        this.setState({ bill_format: value, fys_selected, fye_selected, date_selected, sequence_selected })
    }

    handleBillFormatSelection = (formatType, format) => {
        let { bill_format } = this.state;
        let addedFormat = bill_format ? bill_format : "";
        if(!this.state[formatType]){
            addedFormat = `${bill_format}${format}`;
        } else{
            addedFormat = bill_format.replace(format,'');
        }
        this.setState({[formatType]: !this.state[formatType], bill_format: addedFormat});
    }

    checkValidation = () => {
        let { seq_no, date_format, fys_format, fye_format, min_seq_length, reset_seq_on_fye, reset_seq_to, bill_format, selectedSeeker, date_selected, fys_selected, fye_selected } = this.state;
        let isEnabled = false;
        if(!(selectedSeeker && bill_format && seq_no && min_seq_length)){
            isEnabled = true;
        }
        if(date_selected && (date_format == null || !date_format)){
            isEnabled = true;
        }
        if(fys_selected && (fys_format == null || !fys_format)){
            isEnabled = true;
        }
        if(fye_selected && (fye_format == null || !fye_format)){
            isEnabled = true;
        }
        return isEnabled
    }

    onSave = () => {
        let { seq_no, date_format, fys_format, fye_format, min_seq_length, reset_seq_on_fye, reset_seq_to, bill_format, selectedSeeker, branches, selectedSeekerName } = this.state;
        const { company_id, company_name } = this.props;
        const user = JSON.parse(getCookie("user"));
        const data = {
            customer_id: selectedSeeker,
            customer_name: selectedSeekerName,
            transporter_id: company_id,
            transporter_name: company_name,
            branches,
            type: 1,
            bill_format,
            seq_no,
            current_fye: new Date().getFullYear(),
            date_format,
            fys_format,
            fye_format,
            min_seq_length,
            reset_seq_on_fye,
            reset_seq_to
        }
        this.props.onSave(data)
    }

    onUpdate = () => {
        let { seq_no, date_format, fys_format, fye_format, min_seq_length, reset_seq_on_fye, reset_seq_to, bill_format, selectedSeeker, branches, selectedSeekerName } = this.state;
        const { company_id, company_name } = this.props;
        const user = JSON.parse(getCookie("user"));
        const data = {
            customer_id: selectedSeeker,
            customer_name: selectedSeekerName,
            transporter_id: company_id,
            transporter_name: company_name,
            branches,
            type: 1,
            bill_format,
            seq_no,
            current_fye: new Date().getFullYear(),
            date_format,
            fys_format,
            fye_format,
            min_seq_length,
            reset_seq_on_fye,
            reset_seq_to,
            id: this.props.editData && this.props.editData.id || ""
        }
        this.props.onUpdate(data)
    }

    render(){
        const { billSequenceDropdown } = this.props;
        const customStyles = {
            control: (base) => ({
              ...base,
              borderColor: "#528aea",
            }),
        };
        return(
            <div>
                {this.props.openAddRecord &&
                <div className = "add-chain bill-series">
                <div className = "overlay">
                <div id = "modal" className = "modal-popup" style={{minHeight: '300px'}}>
                    <div className = "pop-heading">
                        BILL MANAGEMENT
                    </div>
                    <div className="content mt-10 fs-14" style={{paddingTop: '10px', marginTop: '10px', backgroundColor: '#f0f3f7'}}>
                        {/* <span className="sub-header">Note: Please enter the billing format as given below</span> */}
                        <div className="wt-100p flex">
                            <ul className="mt-10 col-3">
                                <span className="sub-header">Bill Format:</span>
                                <li className="noteList">__FYS__ : Financial Year Start</li>
                                <li className="noteList">__FYE__ : Financial Year End</li>
                                <li className="noteList">__SEQ__ : Number Sequence</li>
                                <li className="noteList">__DATE__ : Date</li>
                            </ul>
                            <ul className="mt-10 col-3">
                                <span className="sub-header">Date and Year Format:</span>
                                <li className="noteList">YYYY/MM/DD : {moment(new Date()).format('YYYY/MM/DD')}</li>
                                <li className="noteList">YYYYMMDD : {moment(new Date()).format('YYYYMMDD')}</li>
                                <li className="noteList">YYYY-MM-DD : {moment(new Date()).format('YYYY-MM-DD')}</li>
                                <li className="noteList">YYYY : {new Date().getFullYear()}</li>
                            </ul>
                            <ul className="mt-10 col-3">
                                <span className="sub-header">Date and Year Format:</span>
                                <li className="noteList">DD/MM/YY : {moment(new Date()).format('DD/MM/YY')}</li>
                                <li className="noteList">DDMMYY : {moment(new Date()).format('DDMMYY')}</li>
                                <li className="noteList">DD-MM-YY : {moment(new Date()).format('DD-MM-YY')}</li>
                                <li className="noteList">YY : {new Date().getFullYear() % 100}</li>
                            </ul>
                        </div>
                        <div className="mt-20 wt-100p">Example:</div>
                        <div className="wt-100p flex">
                            <ul className="col-3">
                                Format Entered: <span className="fBolder theme-color">ADI/__FYS__-__FYE__/__SEQ__</span><br/>
                                Generated Bill Sequence
                                <li className="noteList">ADI/2024-2025/0001</li>
                                <li className="noteList">ADI/2024-2025/0002</li>
                                <li className="noteList">ADI/2024-2025/0003</li>
                            </ul>
                            <ul className="col-3">
                                Format Entered: <span className="fBolder theme-color">SDTC.BILL.__SEQ__</span><br/>
                                Generated Bill Sequence
                                <li className="noteList">SDTC.BILL.001</li>
                                <li className="noteList">SDTC.BILL.002</li>
                                <li className="noteList">SDTC.BILL.003</li>
                            </ul>
                            <ul className="col-3">
                                Format Entered: <span className="fBolder theme-color">A/156/__SEQ__/__FYS____FYE__</span><br/>
                                Generated Bill Sequence
                                <li className="noteList">A/156/001/2425</li>
                                <li className="noteList">A/156/002/2425</li>
                                <li className="noteList">A/156/003/2425</li>
                            </ul>
                        </div>
                    </div>
                    <div className="content">
                        <div className="details-section">
                        <div className="details">
                            <div style={{ display: 'flex', marginTop: '10px', width: '100%', justifyContent: 'space-between' }}>
                                <div className="wt-100p mt-20">
                                    <div className="sectionHeader">Seeker Company</div>
                                    <Select
                                        styles={customStyles}
                                        options={this.state.seekerDropDown}
                                        onChange={this.handleSelectedSeeker}
                                        className='branchdrop'
                                        placeholder="Select Seeker"
                                        value={(this.state.seekerDropDown || []).filter((seeker) => { return seeker.value === this.state.selectedSeeker })}
                                    />
                                </div>
                                <div className="wt-100p mt-20">
                                    <div className="sectionHeader">Seeker Branch</div>
                                    <Select
                                        styles={customStyles}
                                        options={this.state.branchDropDown}
                                        onChange={(obj) => this.handleSelectBranch(obj)}
                                        className='branchdrop'
                                        placeholder="Select Branch"
                                        isMulti
                                        value={(this.state.branches || []).map((branch) => { return {id: branch.id, name: branch.name}})}
                                        getOptionLabel={(options) => options['name']}
                                        getOptionValue={(options) => options['id']}
                                        // value={(this.state.branchDropDown || []).filter((branch) => { return branch.value === this.state.selectedBranch })}
                                    />
                                </div>
                                
                            </div>
                            <div className="contact-btn" style={{padding: '10px', display: 'flex', marginTop: '10px', width: '100%', justifyContent: 'space-between', marginRight: '30px', backgroundColor: '#f0f3f7' }}>
                                <div className="wt-40p mt-20">
                                    <div className="sectionHeader">Bill Format</div>
                                    <input
                                        name="bill_format"
                                        placeholder="Enter Bill Sequence"
                                        style={{ width: "90%", height: '45px', padding: '5px', border: '1px solid #528AEA', borderRadius: '4px', color: '#528AEA', outline: 'none', marginRight: '10px' }}
                                        onChange={(e) => {
                                            this.handleBillFormat(e.target.value)
                                        }}
                                        value={this.state.bill_format}
                                    />
                                </div>
                                <div className="wt-60p mt-20">
                                    <div className="sectionHeader ml-10">Please select the formats in required order</div>
                                    <div className="flex">
                                    <div 
                                        className={'button-classic'}
                                        onClick={() => {this.handleBillFormatSelection('fys_selected', '__FYS__')}}
                                        style={this.state.fys_selected ? {backgroundColor: '#528aea', color: 'white', border: '1px solid #528aea'} : {}}
                                    > Financial Year Start
                                    </div>
                                    <div 
                                        className={'button-classic'}
                                        onClick={() => {this.handleBillFormatSelection('fye_selected', '__FYE__')}}
                                        style={this.state.fye_selected ? {backgroundColor: '#528aea', color: 'white', border: '1px solid #528aea'} : {}}
                                    > Financial Year End
                                    </div>
                                    <div 
                                        className={'button-classic'}
                                        onClick={() => {this.handleBillFormatSelection('date_selected', '__DATE__')}}
                                        style={this.state.date_selected ? {backgroundColor: '#528aea', color: 'white', border: '1px solid #528aea'} : {}}
                                    > Date
                                    </div>
                                    <div 
                                        className={'button-classic'}
                                        onClick={() => {this.handleBillFormatSelection('sequence_selected', '__SEQ__')}}
                                        style={this.state.sequence_selected ? {backgroundColor: '#528aea', color: 'white', border: '1px solid #528aea'} : {}}
                                    > Sequence
                                    </div>
                                    </div>
                                </div>
                            </div>   
                            <div style={{ display: 'flex', marginTop: '10px', width: '100%', justifyContent: 'space-between' }}>
                                {this.state.fys_selected &&
                                <div className="wt-100p mt-20">
                                    <div className="sectionHeader">Financial Year Start Format</div>
                                    <Select
                                        styles={customStyles}
                                        options={billSequenceDropdown.__FYS__ || []}
                                        getOptionLabel={(options) => options['name']}
                                        getOptionValue={(options) => options['id']}
                                        onChange={(e) => this.handleFormatSelection(e, 'fys_format')}
                                        className='branchdrop'
                                        placeholder="Select Format"
                                        value={(billSequenceDropdown.__FYS__ || []).filter((format) => { return format.id === this.state.fys_format })}
                                    />
                                </div>}
                                {this.state.fye_selected &&
                                <div className="wt-100p mt-20">
                                    <div className="sectionHeader">Financial Year End Format</div>
                                    <Select
                                        styles={customStyles}
                                        options={billSequenceDropdown.__FYE__ || []}
                                        getOptionLabel={(options) => options['name']}
                                        getOptionValue={(options) => options['id']}
                                        onChange={(e) => this.handleFormatSelection(e, 'fye_format')}
                                        className='branchdrop'
                                        placeholder="Select Format"
                                        value={(billSequenceDropdown.__FYE__ || []).filter((format) => { return format.id === this.state.fye_format })}
                                    />
                                </div>}
                                {this.state.date_selected &&
                                 <div className="wt-100p mt-20">
                                    <div className="sectionHeader">Date Format</div>
                                    <Select
                                        styles={customStyles}
                                        options={billSequenceDropdown.__DATE__ || []}
                                        getOptionLabel={(options) => options['name']}
                                        getOptionValue={(options) => options['id']}
                                        onChange={(e) => this.handleFormatSelection(e, 'date_format')}
                                        className='branchdrop'
                                        placeholder="Select Format"
                                        value={(billSequenceDropdown.__DATE__ || []).filter((format) => { return format.id === this.state.date_format })}
                                    />
                                </div>}
                            </div>
                            <div style={{ display: 'flex', marginTop: '10px', width: '100%', justifyContent: 'space-between' }}>
                                <div className="wt-100p mt-20">
                                    <div className="sectionHeader">Starting Sequence
                                    <span className="info tooltip">
                                        <div className={'action-icon'} style={{backgroundImage: `url(${Info})`}}/>
                                        <span className="tooltiptext">Example: <br/> Starting Sequence = 100 <br/> Generated Bill Seq. = 100,101,102</span>
                                    </span>
                                    </div>
                                    <input
                                        name="seq_no"
                                        placeholder="Enter Bill Sequence"
                                        style={{ width: "400px", height: '45px', padding: '5px', border: '1px solid #528AEA', borderRadius: '4px', color: '#528AEA', outline: 'none', marginRight: '10px' }}
                                        onChange={(e) => {
                                        this.setState({ seq_no: e.target.value })
                                        }}
                                        value={this.state.seq_no}
                                    />
                                </div>
                                <div className="wt-100p mt-20">
                                    <div className="sectionHeader">Min. Sequence Length
                                    <span className="info tooltip">
                                        <div className={'action-icon'} style={{backgroundImage: `url(${Info})`}}/>
                                        <span className="tooltiptext">Example: <br/> Starting Sequence = 5 <br/> Generated Bill Seq. = 00100,00101,00102</span>
                                    </span>
                                    </div>
                                    <input
                                        name="min_seq_length"
                                        placeholder="Enter Min. Sequence"
                                        style={{ width: "400px", height: '45px', padding: '5px', border: '1px solid #528AEA', borderRadius: '4px', color: '#528AEA', outline: 'none', marginRight: '10px' }}
                                        onChange={(e) => {
                                        this.setState({ min_seq_length: e.target.value })
                                        }}
                                        value={this.state.min_seq_length}
                                    />
                                </div>
                                

                            </div>
                            <div style={{ display: 'flex', marginTop: '10px', width: '100%', justifyContent: 'space-between' }}>
                                <div className="wt-100p mt-20 sectionHeader">
                                    Reset Sequence on Financial Year End
                                    <div className="mt-10">
                                    <Switch
                                        onChange={this.handleSwitchChange()}
                                        onColor="#528aea"
                                        checked={this.state.reset_seq_on_fye}
                                    />
                                    </div>
                                </div>
                                {this.state.reset_seq_on_fye && 
                                <div className="wt-100p mt-20">
                                    <div className="sectionHeader">Reset Sequence Numbers
                                    <span className="info tooltip">
                                        <div className={'action-icon'} style={{backgroundImage: `url(${Info})`}}/>
                                        <span className="tooltiptext">Example: <br/> Starting Sequence = 100 <br/> Generated Bill Seq. = 100,101,102</span>
                                    </span>
                                    </div>
                                    <input
                                        name="reset_seq_to"
                                        placeholder="Reset Sequence Number"
                                        style={{ width: "300px", height: '45px', padding: '5px', border: '1px solid #528AEA', borderRadius: '4px', color: '#528AEA', outline: 'none', marginRight: '10px' }}
                                        onChange={(e) => {
                                        this.setState({ reset_seq_to: e.target.value })
                                        }}
                                        value={this.state.reset_seq_to}
                                    />
                                </div>}

                                {this.state.reset_seq_on_fye && 
                                <div className="wt-100p mt-20">
                                    <div className="sectionHeader">Buffer Period (Days)
                                    <span className="info tooltip">
                                        <div className={'action-icon'} style={{backgroundImage: `url(${Info})`}}/>
                                        <span className="tooltiptext">Example: <br/> Buffer Period = 10 Days <br/> Bills will be generated based on previous bill format. New bill format will be effective after 10 Days of Financial Year End.</span>
                                    </span>
                                    </div>
                                    <input
                                        name="fye_buffer_days"
                                        placeholder="Buffer Days"
                                        style={{ width: "300px", height: '45px', padding: '5px', border: '1px solid #528AEA', borderRadius: '4px', color: '#528AEA', outline: 'none', marginRight: '10px' }}
                                        onChange={(e) => {
                                        this.setState({ fye_buffer_days: e.target.value })
                                        }}
                                        value={this.state.fye_buffer_days}
                                    />
                                </div>}

                            </div>
                            
                            </div>
                        </div>
                    </div>
                    <div className="pop-heading">
                        {this.props.generatedBill && this.props.generatedBill.bill_number &&
                        <div className="success series">
                            {`Test Bill Sequence: ${(this.props.generatedBill || {}).bill_number}`}
                        </div>}
                        <div className="flex">
                        <Button 
                            className="button-footer" 
                            style={{padding: '5px 20px'}} 
                            click={() => this.props.generateTestSeq(this.state)}
                            value="Generate Test Sequence"
                        />
                        {this.props.isEditable ? 
                        <Button 
                            className="button-footer" 
                            style={{padding: '5px 20px'}} 
                            click={() => this.onUpdate()}
                            value="Update"
                            disabled={this.checkValidation()}
                        /> :
                        <Button 
                            className="button-footer" 
                            style={{padding: '5px 20px'}} 
                            click={() => this.onSave()}
                            value="Save"
                            disabled={this.checkValidation()}
                        />}
                        <Button 
                            className="button-footer" 
                            style={{padding: '5px 20px'}} 
                            click={() => this.props.onCancel()}
                            value="Cancel"
                        />
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    
};

export default connect(mapStateToProps)(AddBill);