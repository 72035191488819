import React, { PropTypes } from 'react';
import "./department.less";
import AddDepartment from "./add_dept/add_department";
import EditDepartment from "./edit_dept/edit_department";
import Edit from "../../../../../assets/images/edit.svg";
import Delete from "../../../../../assets/images/delete.svg";
import DeleteDepartment from '../../../../components/delete-confirmation/delete-confirmation';
import { getEmail, getUserId } from '../../../../../utils/common';


export default class Department extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         showAdd: false,
         showEdit: false,
         showDelete: false
      }
   }

   toggleAdd = () => {
      this.setState({
         showAdd: !this.state.showAdd
      });
   }

   toggleEdit = (dept) => {
      this.setState({
         showEdit: !this.state.showEdit,
         selectedDepartment: dept
      });
   }

   toggleDelete = (dept) => {
      this.setState({
         showDelete: !this.state.showDelete,
         selectedDepartment: dept
      })

   }

   deleteDepartment = (id,department_name) => {
      let data = {
         "company_id": this.state.selectedDepartment && this.state.selectedDepartment.company_id,
         "department_id": this.state.selectedDepartment.department_id,
         user_id: getUserId(),
         user_email: getEmail(),
         master_type: 6,
         department_name:department_name,
         // company_id : id,
         delete_flag: true,
      }
      this.props.deleteDepartment(data);
      this.toggleDelete();
   }
   
   downloadProviderDepartmentDeatils =()=>{
      this.props.downloadProviderDepartmentDeatils(this.props.companyId)
   }

   uploadProviderDepartmentFile =(e)=>{
      this.props.uploadProviderDepartmentFile(e, this.props.companyId);
  }

   render() {
      return (
         <div className="dept-root">
            <div className ="flex">
           
            <h3>DEPARTMENTS</h3>
           
            {this.props &&  this.props.companyType !== "1" && <div className = "dept_role_download">
                <button className="button-classic" onClick={this.downloadProviderDepartmentDeatils} >  Download Depatrtment</button>
                </div>}
                {this.props &&  this.props.companyType !== "1" &&  <div className = "dept_role_upload">
                 <button className="button-classic" onClick={() => {this.uploadExcel.click()}}> Upload Depatrtment</button>
                   <input type='file' style ={{display :'none'}} ref={(e) => { this.uploadExcel = e ;}} onChange={this.uploadProviderDepartmentFile} onClick={(e) => {e.target.value = null }}/> 
               </div> }
            </div>
            <div className="company-list-wrap">
               <div style={{ display: 'flex' }}>
                  <div className="list-section header">
                     <div className="company-name-section">
                        Department Name
                  </div>
                     {/* <div className = "admin-details">
                    Roles
                  </div>
                  <div className = "account-type">
                    Company Type
                  </div> */}
                     <div className="status">
                        Action
                  </div>
                  </div>
                  <div className="list-section header">
                     <div className="company-name-section">
                        Department Name
                  </div>
                     {/* <div className = "admin-details">
                    Roles
                  </div>
                  <div className = "account-type">
                    Company Type
                  </div> */}
                     <div className="status">
                        Action
                  </div>
                  </div>
               </div>
               {
                  this.props.departments && this.props.departments.length == 0 && <div className="no-records">No Records Found, You Can Add By Clicking On Add Deparment Button (Make Sure You Have Added Roles Already!)</div>
               }
               {
                  this.props.departments && this.props.departments.map((department, index) => {
                     return (
                        <div className="list-section content roles" key={index} >
                           <div className="company-name-section">
                              <div className="compnay-details">
                                 {department.department_name}
                              </div>
                           </div>
                           {/* <div className = "admin-details">
												<div className = "admin-email" >{department.roles.map((role, ind) => {
                          return(
                            <span className = "role" key = {ind}>{role.name}</span>
                          )
                        })}</div>
											</div>
											<div className = "account-type">
												<div>{department.company_type == 1 ? "Seeker" : department.company_type == 2? "Provider" : "3PL"  }</div>
											</div> */}
                           <div className="status">
                              <div className={'delete-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.toggleEdit(department)}></div>
                              <div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleDelete(department)}></div>
                              {/* <div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => { this.deleteDepartment(department.department_id) }}></div> */}
                              {/* <button className = "addMore margin-right" onClick = {() => this.toggleEdit(department)}>Edit</button>
                        <button className = "addMore" onClick = {() => {this.deleteDepartment(department.department_id)}}>Delete</button> */}
                           </div>
                        </div>
                     )
                  })
               }
               <div className="submit-section">
                  <div className="add-button" onClick={this.toggleAdd}>
                     ADD DEPARTMENT
								</div>
               </div>
            </div>

            {
               (this.state.showAdd) && <AddDepartment addDepartment={this.props.addDepartment} companyDetails={this.props.companyDetails} roles={this.props.roles} toggleAdd={this.toggleAdd} />
            }
            {
               this.state.showEdit && <EditDepartment editDepartment={this.props.editDepartment} selected Department={this.state.selectedDepartment} roles={this.props.roles} toggleEdit={this.toggleEdit} />
            }
            {this.state.showDelete && <DeleteDepartment selectedDepartment={this.state.selectedDepartment} delete={this.deleteDepartment(this.state.selectedDepartment.company_id,this.state.selectedDepartment.department_name)} toggleDelete={this.toggleDelete} />}
         </div>
      )
   }
}
