import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import NavigationPanel from "./navigation-panel/navigation-panel";
import MainComponent from "../home/main/main";
import "../master/master.less";
import SendMail from "./sendmail/sendmail";
import CopyMail from "./copymail/copymail";
import ACTION from "../common/action_constant";
import MessagePopUp from "../common/components/msg-popup/msg-popup";
import Loader from "../common/components/loader/loader";
import { getCookie } from "../../utils/cookies";

const TYPE_LIST = [
  {id: 1, label: 'ALL'},
  {id: 3, label: 'SEEKER'},
  {id: 2, label: 'PROVIDER'}
]

class Broadcast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "1",
      selectedSubTab: 1,
      selectedMainTab: 1,
      mail: null,
      cont: null,
      type: 1
    };
  }

  selectedTab = num => {
    this.setState({
      selectedMainTab: num
    });
  };

  selectedSubTab = num => {
    this.setState({
      selectedSubTab: num
    });
  };

  onCopy = i => {
    this.setState({
      selectedMainTab: "1",
      mail: this.props.broadcast.data[i].email_list,
      cont: this.props.broadcast.data[i].message
    });
    //console.log("updated state", this.state.mail);
  };

  getAllMails = () => {
    const { dispatch } = this.props;
    const { type } = this.state;
    //  console.log("Check", this.props);
    //  console.log("testing", dispatch);
    dispatch({
      type: ACTION.BROADCAST.GET_ALL_EMAILS,
      data: {
        //  type: JSON.parse(getCookie("user")).user_type
        //email: data
        type
      }
    });
  };

  postAllMails = data => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTION.BROADCAST.POST_ALL_EMAILS,
      data: data
    });
  };

  copyAllMails = () => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTION.BROADCAST.COPY_ALL_EMAILS
    });
  };

  selectedType = (id) => {
    this.setState({ type: id }, this.getAllMails);
  }

  componentDidMount() {
    this.copyAllMails();
    this.getAllMails();
  }

  closeMsgPopup = () =>{
    const {dispatch} = this.props;
    dispatch({ type : ACTION.MSG_DISPLAY.HIDE_MSG })
}

  render() {
    const { broadcast , loader} = this.props;
    const { type } = this.state;
    const mailslist = this.props.broadcast;
    //console.log("check only", this.props.broadcast, this.state.mail);
    return (
      <div className="master">
        {loader.isLoading && <Loader />}
        {/* <Loader /> */}
        <MainComponent>
          {this.props.msgpopup.active && (
            <MessagePopUp
              close={this.closeMsgPopup}
              type={this.props.msgpopup.type}
              msg={this.props.msgpopup.msg}
            />
          )}
          <NavigationPanel
            // selectedSubTab={this.selectedSubTab}
            selectedTab={this.selectedTab}
            selectedOption={this.state.selectedMainTab}
          />
          {this.state.selectedMainTab == 1 && (
            <Fragment>
            <div className={'flex'}>
              {
                (TYPE_LIST || []).map((typeList,index) => {
                  return(
                    <Fragment>
                    <CustomCheckBox  selected={typeList.id === type} toggle={this.selectedType} id={typeList.id}/>
                    <label className={'type-label'}>{typeList.label}</label>
                    </Fragment>
                  )
                })
              }
            </div>
            <SendMail
              postAllMails={this.postAllMails}
              //   emaillist={mailslist}
              cont={this.state.cont}
              mail={this.state.mail}
              getMails={this.getAllMails}
              selected={this.state.selectedMainTab}
              broadcast={broadcast}
            />
            </Fragment>
          )}
          {this.state.selectedMainTab == 2 && (
            <CopyMail
              getAllMails={this.getAllMails}
              broadcast={broadcast}
              selected={this.state.selectedMainTab}
              copyAllMails={this.copyAllMails}
              onCopy={this.onCopy}
            />
          )}
        </MainComponent>
      </div>
    );
  }
}

const CustomCheckBox = (props) =>{
  return(
      <div className = {props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick = {()=>props.toggle(props.id)}>
          {props.selected && <div className = "inner-circle"></div>}
      </div>
  )
}

const mapStateToProps = state => {
  return {
    loader: state.loader,
    msgpopup: state.msgpopup,
    broadcast: state.broadcast
  };
};

export default connect(mapStateToProps)(Broadcast);
