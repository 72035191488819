import React, { Component } from 'react';
import Select from 'react-select';
import Input from '../../common/components/input-text/input-text';
import { connect } from 'react-redux';
import ACTIONS from "../../common/action_constant";
import { CONTRACT_TENURE_UNITS } from "../../../utils/common";
import Delete from '../../../assets/images/delete.svg';
import Add from '../../../assets/images/add.svg';

class ContractTenureConfig extends Component {
    constructor() {
        super();
        this.state = {
            data: [{}],
            contract_tenure_id: ''
        }
    }

    onSave = () => {
        const { data, contract_tenure_id } = this.state;
        const { dispatch, company_id } = this.props;
        dispatch({
            type: ACTIONS.HOME.SAVE_CONTRACT_TENURE_LIST,
            data: {
                company_id: company_id,
                contract_tenure_id: contract_tenure_id || undefined,
                list: data
            },
            onSuccess: this.getContractTenureList
        })
    }

    componentDidMount() {
        this.getContractTenureList();
        this.getContractTenureTypes();
    }

    getContractTenureList = () => {
        const { dispatch, company_id } = this.props;
        dispatch({
            type: ACTIONS.HOME.GET_CONTRACT_TENURE_LIST,
            params: {
                limit: 20,
                offset: 0,
                companyId: company_id
            },
            onSuccess: (result) => {
                if (result && (result.list || []).length > 0) {
                    this.setState({
                        data: result.list || [],
                        contract_tenure_id: result.contract_tenure_id || ''
                    });
                }
                //  else {
                //     this.getContractTenureTypes();
                // }
            }
        });
    }

    getContractTenureTypes = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTIONS.HOME.GET_CONTRACT_TENURE_TYPES
            // onSuccess: (result) => {
            //     const data = [];
            //     (result || []).map(el => {
            //         data.push({
            //             contract_type: el.name,
            //             contract_type_id: el.id
            //         });
            //     });
            //     this.setState({ data, contract_tenure_id: '' });
            // }
        });
    }

    onkeyDownChange = (e) => ((["e", "E", "+", "-", "."].includes(e.key)) && e.preventDefault());

    onAddContractType = () => {
        const { data } = this.state;
        data.push({});
        this.setState({
            data
        })
    }

    onDeleteCOntractType = (index) => {
        const { data } = this.state;
        data.splice(index, 1);
        this.setState({ data });
    }

    getRemainingContractTypes = () => {
        const { data } = this.state;
        const { contractTenureTypes } = this.props;
        const contractTypesId = data.map(el => el.contract_type_id);
        return (contractTenureTypes || []).filter(el => !contractTypesId.includes(el.id));
    }

    render() {
        const { data } = this.state;
        const { contractTenureTypes } = this.props;

        return (
            <div className='contract-tenure'>
                <table>
                    <thead>
                        <tr>
                            <th width="150" className='tCenter'>Contract Type</th>
                            <th width="300" className='tCenter'>Period</th>
                            <th width="200" className='tCenter'>UOM</th>
                            <th width="100" className='tCenter'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(data || []).map((el, index) =>
                        (<tr>
                            <td className='tCenter'>
                                <Select
                                    options={contractTenureTypes && this.getRemainingContractTypes() || []}
                                    getOptionLabel={(option) => option.name}
                                    value={el.contract_type_id && (contractTenureTypes || []).find(opt => opt.id === el.contract_type_id) || null}
                                    onChange={(option) => {
                                        const { data } = this.state;
                                        data[index]['contract_type'] = option.name
                                        data[index]['contract_type_id'] = option.id;
                                        this.setState({ data });
                                    }}
                                />
                            </td>
                            <td>
                                <div style={{ 'display': 'flex' }}>
                                    <Input
                                        type="number"
                                        className='tCenter'
                                        placeholder="From"
                                        value={el.fromRange || ''}
                                        changeHandler={(value) => {
                                            const { data } = this.state;
                                            data[index]['fromRange'] = value;
                                            this.setState({ data });
                                        }}
                                        additionalProps={{
                                            onKeyDown: this.onkeyDownChange
                                        }}
                                    /> <div style={{ 'margin': '15px 20px 0px 20px' }}>{'-'}</div>
                                    <Input
                                        type="number"
                                        className='tCenter'
                                        placeholder="To"
                                        value={el.toRange || ''}
                                        changeHandler={(value) => {
                                            const { data } = this.state;
                                            data[index]['toRange'] = value;
                                            this.setState({ data });
                                        }}
                                        additionalProps={{
                                            onKeyDown: this.onkeyDownChange
                                        }}
                                    />
                                </div>
                            </td>
                            <td>
                                <Select
                                    options={CONTRACT_TENURE_UNITS}
                                    getOptionLabel={(option) => option.name}
                                    value={el.uom && (CONTRACT_TENURE_UNITS || []).find(opt => opt.value === el.uom) || null}
                                    onChange={(option) => {
                                        const { data } = this.state;
                                        data[index]['uom'] = option.value;
                                        this.setState({ data });
                                    }}
                                />
                            </td>
                            <td>
                                <div style={{ 'display': 'flex' }} className={'table-row'}>
                                    {data.length - 1 === index && contractTenureTypes.length !== data.length && <div className={'delete-icon'} style={{ backgroundImage: `url(${Add})` }} onClick={this.onAddContractType}></div>}
                                    {data.length > 1 && <div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.onDeleteCOntractType(index)}></div>}
                                </div>
                            </td>
                        </tr>))}
                    </tbody>
                </table>
                <div className='tCenter mt-10'>
                    <button className="add-button" onClick={this.onSave}>Save</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        contractTenureTypes: state.home.contractTenureTypes || []
    }
}

export default connect(mapStateToProps)(ContractTenureConfig);