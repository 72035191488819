import React, { Component } from 'react';
import InputText from '../../../../common/components/input-text/input-text';
import Select from 'react-select';
import Button from '../../../../home/button-classic/button-classic';
import {  PackagingType, LoadType, unloadingMhe } from '../../../../../utils/constants';
import { getCookie } from "../../../../../utils/cookies.js";
import { validationCheck } from "../../../../../utils/validate";


class AddItem extends Component {
    constructor(props){
        super(props);
        this.state = {
        
            company_id:JSON.parse(getCookie("user")).company_id,
            part_number:'',
            part_description:'',
            warehouse:'',
            warehouse_loc:'',
            spq:'',
            packing_type:'',
            load_type:'',
            bin_length:'',
            bin_width:'',
            bin_height:'',
            weight:'',
            bin_stack_permit:'',
            freq_of_dispatch:'',
            vendor_code:'',
            vendor_name:'',
            delivery_loc:'',
            plant_name:'',
            unloading_dock:'',
            unloading_mhe:'',
            packing_type_id:'',
            // if bin is selected
            pallete_height:'',
            pallete_length:'',
            pallete_width:'',
            weight_per_pallete:'',
            no_of_bins_on_pallete:''
           
        }
    }

    handleChange = (id, selectedOption ) => {
        this.setState({ [id] : selectedOption.label });
       
    }
    
    
    onChangeHandler = ( value, id, isType ) => {
        const result = validationCheck(value, this.state[id], isType);
        this.setState({[id]: result});
    }

    validateCheck = () => {
        const { part_number, part_description, spq, packing_type, load_type, bin_length, bin_width, bin_height} = this.state;
        if(part_number && part_description && spq && packing_type && load_type && bin_length && bin_width && bin_height) {
            return true;
        } else{
            return false;
        }
    }
    
    onSave = () => {
        if(this.validateCheck()){
            this.props.toggleAdd();
            this.props.addSkuDetails(this.state);
        } else {
            this.setState({errorMsg: "Please enter all Mandatory fields"});
        }
    }

    render() {
         const {packing_type}=this.state
        return (<div className = "add-user-wrap">
            <div onClick = {this.props.toggleAdd} className = "overlay"></div>
            <div id = "modal" className = "modal-popup">
                <div className = "pop-heading">
                    Add Sku master
             
                </div>
                <div className = "content add-item-details">
                    <div className="select-wrap">
                        <InputText label='Part No' id='part_number' value={this.state.part_number} placeholder='Part No' changeHandler = {this.onChangeHandler} required/>
                    </div>
                    <div className="select-wrap">
                    <InputText label='Part Name' id='part_description' value={this.state.part_description} placeholder='Part Name' changeHandler = {this.onChangeHandler} required/>
                    </div>

                    <div className="select-wrap">
                    <InputText label='Warehouse ' id='warehouse' value={this.state.warehouse} placeholder='Warehouse ' changeHandler = {this.onChangeHandler}/>
                    </div>

                    <div className="select-wrap">
                    <InputText label='Warehouse loc' id='warehouse_loc' value={this.state.warehouse_loc} placeholder='Warehouse Loc' changeHandler = {this.onChangeHandler}/>
                    </div>
                    
                    <div className="select-wrap">
                    <InputText label='SPQ' id='spq' value={this.state.spq} placeholder='SPQ' changeHandler = {this.onChangeHandler} required isValidate={'numeric'}/>
                    </div>
                    
                    <div className="select-wrap">
                        <div className="label">Packaging Type<span className='error-color'>*</span></div>
                        <Select className = "item-select" 
                            value={PackagingType.find((val) => { return val.value == this.state.packing_type}) }
                            options={PackagingType}
                         
                            onChange={(value)=>{
                                let {packing_type_id,packing_type} =this.state
                                packing_type=value.label;
                                packing_type_id=JSON.stringify(value.value);
                                this.setState({packing_type_id,packing_type})
                             }}
                        />
                    </div>
                    <div className="select-wrap">
                        <div className="label">Load Type<span className='error-color'>*</span></div>
                        <Select className = "item-select" 
                            value={LoadType.find((val) => { return val.value == this.state.load_type }) }
                            options={LoadType} 
                            onChange={this.handleChange.bind(this, "load_type")}
                        />
                        
                    </div>
                    <InputText label='Length (in mm)' placeholder='Length' id='bin_length' value={this.state.bin_length} changeHandler = {this.onChangeHandler} required isValidate={'numeric'}/>
                    <InputText label='Width (in mm)' placeholder='Width' id='bin_width' value={this.state.bin_width} changeHandler = {this.onChangeHandler} required isValidate={'numeric'}/>
                    <InputText label='Height (in mm)' placeholder='Height' id='bin_height' value={this.state.bin_height} changeHandler = {this.onChangeHandler} required isValidate={'numeric'}/>
                    <InputText label='Weight(optional)' placeholder="Weight" id='weight' value={this.state.weight} changeHandler = {this.onChangeHandler} isValidate={'numeric'}/>
                    <InputText label='Max Stack' placeholder='Max Stack' id='bin_stack_permit' value={this.state.bin_stack_permit} changeHandler = {this.onChangeHandler}/>
                    <InputText label='Freq Of Dispatch' placeholder="Freq Of Dispatch" id='freq_of_dispatch' value={this.state.freq_of_dispatch} changeHandler = {this.onChangeHandler}/>
                      
                    {packing_type=="Bin" &&
                    <div>
                    <div className = "pop-heading">Pallete Details</div>
                    <InputText label='Pallete length' placeholder='Pallete length' id='pallete_length' value={this.state.pallete_length} changeHandler = {this.onChangeHandler} isValidate={'numeric'}/>
                    <InputText label='Pallete width' placeholder='Pallete width' id='pallete_width' value={this.state.pallete_width} changeHandler = {this.onChangeHandler} isValidate={'numeric'}/>
                    <InputText label='Pallete height' placeholder='Pallete height' id='pallete_height' value={this.state.pallete_height} changeHandler = {this.onChangeHandler} isValidate={'numeric'}/>
                    <InputText label='Weight per pallete(optional)' placeholder="Weight per pallete" id='weight_per_pallete' value={this.state.weight_per_pallete} changeHandler = {this.onChangeHandler} isValidate={'numeric'}/>
                    <InputText label='No of bins on pallete' placeholder="No of bins on pallete" id='no_of_bins_on_pallete' value={this.state.no_of_bins_on_pallete} changeHandler = {this.onChangeHandler} isValidate={'numeric'}/>
                    </div>
                }
                   
                    
                    <div className = "pop-heading">Vendor Details</div>
                  <div className="select-wrap">
                  <InputText label=' Vendor Code' placeholder=" Vendor Code" id='vendor_code' value={this.state.vendor_code} changeHandler = {this.onChangeHandler}/>
                    </div>
                    <InputText label="Vendor Name " id='vendor_name' value={this.state.vendor_name} placeholder="Vendor Name " changeHandler = {this.onChangeHandler}/>
                    <div className = "pop-heading"> Unloading Specifications</div>
                   
                    <InputText label=" Delivery Location " id='delivery_loc' value={this.state.delivery_loc} placeholder=" Delivery Location" changeHandler = {this.onChangeHandler}/>
                    <InputText label="Plant Name" id='plant_name' value={this.state.plant_name} placeholder="Plant Name" changeHandler = {this.onChangeHandler}/>
                    <InputText label="Unloading Dock" id='unloading_dock' value={this.state.unloading_dock} placeholder="Unloading Dock" changeHandler = {this.onChangeHandler}/>
                    
                    <div className="select-wrap">
                        <div className="label">Unloading MHE</div>
                        <Select className = "item-select" 
                            value={unloadingMhe.find((val) => { return val.label === this.state.unloading_mhe})}
                            options={unloadingMhe} 
                            onChange={this.handleChange.bind(this, "unloading_mhe")}
                        />
                    </div>
                   
                  
                </div>
                <div className="submit-button">
                    {this.state.errorMsg && <div className="error-color">{this.state.errorMsg}</div>}
                    <div className="button-section"><Button value="Save"  click={this.onSave}/></div>
                    <div className="button-section"><Button value="Cancel" click={this.props.toggleAdd}/></div>
                </div>
            </div>
        </div>
        );
    }
}

export default AddItem;