import React, { Component } from 'react';
import Button from "../../../button-classic/button-classic";
import Delete from "../../../../../assets/images/delete.svg";
import Edit from "../../../../../assets/images/edit.svg";
import Search from '../../../../common/components/search-text/search-text';
import AddParkingLot from './add_parking_lot';

class ParkingLot extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
            search: ''
        }
    }

    componentDidMount() {
        this.props.getParkingLotListing();
    }

    addParkingLot = (editData = '') => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen, editData });
    }

    toggleDirectDelete = (lot) => {
        const data = {
            parking_id: lot.parking_id
        }
        this.props.deleteParkingLot(data);
    }

    render() {

        const { parkingLotListing } = this.props;
        
        return(
            <div className="plants-depots-main-wrap">
                <div className="search-holder">
                <div className="add-plants-section">
                    <div style={{display: 'block', paddingBottom: '15px'}}>
                            <Search id="search" value={this.state.search} placeholder="Search..." changeHandler={(e) => this.setState({ search: e })} click={() => this.props.getParkingLotListing(this.state.search)} canClear={this.state.search !== ''} handleClearFilter={() => this.setState({ search: '' }, this.props.getParkingLotListing())}/>
                    </div>                                        
                    <Button value="+ Parking Lot" click={() => this.addParkingLot('')} />
                </div>
                </div>
                <div className="heading">
                    <div className="flow-head">Parking Name</div>
                    <div className="flow-head">Parking Capacity</div>
                    <div className="flow-head">Priority</div>
                    <div className="flow-head">Actions</div>
                </div>
                {(parkingLotListing || []).map((lot, index) => {
                return(
                    <div className={"depot-users-list"} key={`lot-${index}`}>
                        <div className="flow-head">{lot.name || '-'}</div>
                        <div className="flow-head">{lot.parking_capacity || '-'}</div>
                        <div className="flow-head">{lot.priority || '-'}</div>
                        <div className="flow-head">
                            <div className = "delete-icon" style={{backgroundImage: `url(${Edit})`}} onClick = {() => this.addParkingLot(lot)}></div>
                            <div className = "delete-icon" style={{backgroundImage: `url(${Delete})`}} onClick = {() => this.toggleDirectDelete(lot)}></div>
                        </div>
                    </div>)
                })}

                {(!parkingLotListing || parkingLotListing.length === 0) && <div className={'no_data_found'}>{'No Data Found'}</div>}      

                {this.state.isOpen && <AddParkingLot toggleAdd={this.addParkingLot} onAddParkingLot={this.props.onAddParkingLot} updateParkingLot={this.props.updateParkingLot} data={this.state.editData} />}          
        </div>
        )
    }
}

export default ParkingLot;