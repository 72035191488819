import React, { Component } from 'react';
import Button from "../../../button-classic/button-classic";
import Delete from "../../../../../assets/images/delete.svg";
import Edit from "../../../../../assets/images/edit.svg";
import AddBay from "./add_bay";
import Search from '../../../../common/components/search-text/search-text';
import { withRouter } from 'react-router';
class LoadingBay extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
            search: ''
        }
    }

    componentDidMount() {
        this.props.getLoadingBay();
    }

    addBay = (editData = '') => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen, editData ,previous_data:editData});
    }

    toggleDirectDelete = (bay) => {
        const data = {
            bay_name: bay.bay_name,
            branch_code: bay.branch_code,
            bay_id: bay.bay_id
        }
        this.props.deleteBay(data);
    }

    onAuditClick = () => {
        const {history,branch_id,company_id,depotCode} = this.props;
        const companyId = company_id
        const branchId = branch_id
        const branchCode=depotCode
        const masterType = 13
        const navUrl = `/auditScreen/${companyId}/${masterType}/${branchId}/${branchCode}`;
        history.push(navUrl)
      }

    render() {

        const { loadingBayData } = this.props;
        return(
            <div className="plants-depots-main-wrap">
                <div className="search-holder">
                <div className="add-plants-section">
                    <div style={{display: 'block', paddingBottom: '15px'}}>
                        <Search id = "search" value = {this.state.search} placeholder = "Search..." changeHandler = {(e) => this.setState({search: e})} click = {() => this.props.getLoadingBay(this.state.search)} canClear={this.state.search !== ''} handleClearFilter={() => this.setState({search: ''}, this.props.getLoadingBay())}/>
                    </div>
                    <Button value="+ Loading Bay" click={() => this.addBay('')} />
                    <input class="button-classic ml-10 mr-10" type="button" value="Download Temp." onClick={() => this.props.downloadLoadingBay(false)} />
                    <a className="download-btn" id="loadingBayDownloadBtn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.props.downloadLink} download />
                    <button className="button-classic ml-10 mr-10" onClick={() => {this.uploadExcel.click()}}> Upload </button>
                    <input type='file' style ={{display :'none'}} ref={(e) => { this.uploadExcel = e ;}} onChange={this.props.uploadLoadingBayDetails} onClick={(e) => {e.target.value = null }}/>
                    <input class="button-classic ml-10" type="button" value="Download" onClick={() => this.props.downloadLoadingBay(true)} />
                    <a className="download-btn" id="loadingBayDownloadBtn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.props.downloadLink} download />
                    <button className="button-classic" onClick={()=>this.onAuditClick()}>Audit</button>
                </div>
                </div>
                <div className="heading">
                    <div className="flow-head">Bay Name</div>
                    <div className="flow-head">Bay Description</div>
                    <div className="flow-head">Warehouse Id</div>
                    <div className="flow-head">Actions</div>
                </div>
                {(loadingBayData || []).map((bay, index) => {
                return(
                    <div className={"depot-users-list"} key={`bay-${index}`}>
                        <div className="flow-head">{bay.bay_name}</div>
                        <div className="flow-head">{bay.bay_description}</div>
                        <div className="flow-head">{bay.warehouse_id}</div>
                        <div className="flow-head">
                            <div className = "delete-icon" style={{backgroundImage: `url(${Edit})`}} onClick = {() => this.addBay(bay)}></div>
                            <div className = "delete-icon" style={{backgroundImage: `url(${Delete})`}} onClick = {() => this.toggleDirectDelete(bay)}></div>
                        </div>
                    </div>)
                })}

                {(!loadingBayData || loadingBayData.length === 0) && <div className={'no_data_found'}>{'No Data Found'}</div>}

                {this.state.isOpen && <AddBay toggleAdd={this.addBay} onAddBay={this.props.onAddBay} updateLoadingBay={this.props.updateLoadingBay} data={this.state.editData} previous_data={this.state.previous_data}/>}
        </div>
        )
    }
}

export default (withRouter(LoadingBay));
