import React, { Component } from 'react';
import Dropup from '../../../../assets/images/drop-up-arrow.svg';
import Delete from '../../../../assets/images/delete.svg';
import Edit from '../../../../assets/images/edit.svg';
import Button from '../../button-classic/button-classic';
import Select from 'react-select';
import InputText from '../../../common/components/input-text/input-text';
import CheckBox from '../../../components/checkbox/checkbox';
import AddBillingCycle from './add-billing-cycle/add-billing-cycle';
import AddRow from './add-row';
import AddBill from './add-bill-number/add-bill-number';
import EditBill from './edit-bill-number/edit-bill-number';
import DeleteCompanyAccounts from './../../../components/delete-confirmation/delete-confirmation'

class AccountMaster extends Component {
   constructor(props) {
      super(props);
      this.state = {
         data: this.props.accountData || [],
         checkDropDown: [false, false, false, false],
         isOpen: false,
         isBillNumber: false,
         isRowOpen: false,
         isType: null,
         uom: null,
         isEditBillNumber: false,
         editBillNumberData: '',
         editBillCycle: ''
      }
   }

   toggleCheck = (id, isType = '') => {
      let { checkDropDown } = this.state;
      checkDropDown[id] = !checkDropDown[id];
      this.setState({ checkDropDown, isType }, () => {
         if (isType && checkDropDown[id] && isType !== 12) {
            this.props.getCompanyAccounts(isType);
         } else if (isType && checkDropDown[id] && isType == 12) {
            this.props.getBillNumberDetails();
         } else if (checkDropDown[id] && id === 4) {
            this.props.getHolidayListDetails();
         }
      });
   }

   toggleAdd = (type, editBillCycle = '') => {
      this.setState({ isOpen: !this.state.isOpen, isType: type, editBillCycle });
   }

   componentWillReceiveProps = (newProps) => {
      let { type12 } = this.state;
      if (newProps.accountData.type12 && newProps.accountData.type12.length > 0) {
         type12 = newProps.accountData.type12.length > 0 ? newProps.accountData.type12[0] : type12;
      }
      if (newProps.mainTabOption !== this.props.mainTabOption) {
         this.setState({ checkDropDown: [] });
      }
      this.setState({ data: newProps.accountData, type12 });
   }

   handleChange = (id, selectOptions) => {
      let { type12 } = this.state;
      type12[id] = selectOptions.value;
      this.setState({ type12 });
   }

   onSaveOtherCharges = (isType) => {
      const data = {
         risk_handling_charge: this.state.risk_handling_charge,
         green_tax: this.state.green_tax,
         min_charge_weight: this.state.min_charge_weight,
         uom: this.state.uom,
         type: isType
      }
      this.props.addCompanyAccounts(data);
   }

   onAddRow = (type) => {
      this.setState({ isRowOpen: !this.state.isRowOpen, isType: type });
      this.props.getAccountEvents(6);
   }

   toggleAddBill = () => {
      this.setState({ isBillNumber: !this.state.isBillNumber });
   }

   toggleEditBill = (data = '') => {
      let { isEditBillNumber, editBillNumberData } = this.state;
      this.setState({ isEditBillNumber: !isEditBillNumber, editBillNumberData: data });
   }

   downloadHolidayList = () => {
      this.downloadUrl.click();
   }

   uploadHolidayList = () => {
      this.uploadUrl.click();
   }

   uploadHolidayListFile = (e) => {
      let formData = new FormData();
      formData.append('file', e.target.files[0]);
      this.props.uploadHolidayListDetails(formData);
   }

   toggleDelete = (id, num) => {
      this.setState({
         showDelete: !this.state.showDelete,
         row_id: id,
         num: num,
      })
   }
   deleteCompanyAccounts = () => {
      this.props.deleteCompanyAccounts(this.state.row_id, this.state.num);
      this.toggleDelete();
   }

   toggleDeleteBillNumber = (transporter_name) => {
      this.setState({
         showDeleteBillNumber: !this.state.showDeleteBillNumber,
         transporter_name: transporter_name
      })
   }

   delteBillNumber = () => {
      this.props.delteBillNumber(this.state.transporter_name);
      this.toggleDeleteBillNumber();
   }

   render() {
      const { data } = this.state;
      return (
         <div className={'debit-master'}>
            <div className={'header'} >Billing Cycle
                    <div className={this.state.checkDropDown[0] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(0, 11)}></div>
            </div>
            <div className={`drop-section ${this.state.checkDropDown[0] ? "drop-up" : "drop-close"}`}>
               <div className={'fuel-escalation-table'}>
                  <div className={'list-section'}>
                     <div className={'table-header'}>Billing Cycle</div>
                     <div className={'table-header'}>From</div>
                     <div className={'table-header'}>To</div>
                     <div className={'table-header action'}>Action</div>
                  </div>
                  {data.type11 && data.type11.map((row, index) => {
                     return (<div className={'list-section'} key={index}>
                        <div className={'table-row'}>{row.billing_cycle}</div>
                        <div className={'table-row'}>{row.from}</div>
                        <div className={'table-row'}>{row.to}</div>
                        <div className={'table-row action'}>
                           <div className={'delete-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.toggleAdd(11, row)}></div>
                           <div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleDelete(row.id, 11)}></div>
                        </div>
                     </div>)
                  })}
                  <div className={'approval-btn'}>
                     <Button value={'Add'} click={() => this.toggleAdd(11)}></Button>
                  </div>
               </div>
            </div>

            <div className={'header'}>Bill Number
                    <div className={this.state.checkDropDown[1] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(1, 12)}></div>
            </div>
            <div className={`drop-section ${this.state.checkDropDown[1] ? "drop-up" : "drop-close"}`}>
               <div className={'fuel-escalation-table'}>
                  <div className={'list-section'}>
                     <div className={'table-header'}>Bill Type</div>
                     <div className={'table-header'}>Transporter</div>
                     <div className={'table-header'}>Range From</div>
                     <div className={'table-header'}>Range To</div>
                     <div className={'table-header action'}>Action</div>
                  </div>
                  {data.type12 && data.type12.map((row, index) => {
                     return (<div className={'list-section'} key={index}>
                        <div className={'table-row'}>{row.bill_type}</div>
                        <div className={'table-row'}>{row.transporter_name}</div>
                        <div className={'table-row'}>{row.bill_range_from}</div>
                        <div className={'table-row'}>{row.bill_range_to}</div>
                        <div className={'table-row action'}>
                           <div className={'delete-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.toggleEditBill(row)}></div>
                           <div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleDeleteBillNumber(row.transporter_name)}></div>
                        </div>
                     </div>)
                  })}
                  <div className={'approval-btn'}>
                     <Button value={'Add'} click={() => this.toggleAddBill()}></Button>
                  </div>
               </div>
            </div>

            <div className={'header'} >Holiday List
                    <div className={this.state.checkDropDown[4] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(4)}></div>
            </div>
            <div className={`drop-section ${this.state.checkDropDown[4] ? "drop-up" : "drop-close"}`}>
               <div className={'approval-btn'}>
                  <Button value={'Download'} click={() => this.downloadHolidayList()}></Button>
                  <a href={this.props.holidayDownlUrl} ref={(e) => { this.downloadUrl = e; }} style={{ display: 'none' }} />
                  <Button value={'Upload'} click={() => this.uploadHolidayList()}></Button>
                  <input type='file' style={{ display: 'none' }} ref={(e) => { this.uploadUrl = e; }} onChange={this.uploadHolidayListFile} onClick={(e) => { e.target.value = null }} />
               </div>
               <div className={'fuel-escalation-table'}>
                  <div className={'list-section'}>
                     <div className={'table-header'}>Date</div>
                     <div className={'table-header'}>Holiday</div>
                  </div>
                  {this.props.holidayList && this.props.holidayList.map((val, index) => {
                     return (<div className={'list-section'} key={index}>
                        <div className={'table-row'}>{val.date}</div>
                        <div className={'table-row'}>{val.holiday_description}</div>
                     </div>)
                  })}
               </div>
            </div>

            <div className={'header'}>Other Charges
                    <div className={this.state.checkDropDown[2] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(2, 13)}></div>
            </div>
            <div className={`drop-section ${this.state.checkDropDown[2] ? "drop-up" : "drop-close"}`}>
               <div className={'fuel-escalation-table'}>
                  <div className={'list-section'}>
                     <div className={'table-header event'}>Event</div>
                     <div className={'table-header amount'}>Amount</div>
                     <div className={'table-header amount'}>%</div>
                     <div className={'table-header amount'}>Days</div>
                     <div className={'table-header amount'}>Weight</div>
                     <div className={'table-header uom'}>UOM</div>
                     <div className={'table-header'}>Activate</div>
                     <div className={'table-header'}>Action</div>
                  </div>
                  {data.type13 && data.type13.map((row, index) => {
                     return (<div className={'list-section'} key={index}>
                        <div className={'table-row event'}>{row.event}</div>
                        <div className={'table-row amount'}>{row.amount}</div>
                        <div className={'table-row amount'}>{row.percentage}</div>
                        <div className={'table-row amount'}>{row.days}</div>
                        <div className={'table-row amount'}>{row.min_charge_weight}</div>
                        <div className={'table-row uom'}>{row.uom_type}</div>
                        <div className={'table-row'}><CheckBox status={row.is_active} /></div>
                        <div className={'table-row delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleDelete(row.id, 13)}></div>
                     </div>)
                  })}
                  <div className={'approval-btn'}>
                     <Button value={'Add'} click={() => this.onAddRow(13)}></Button>
                  </div>
               </div>
            </div>
            {this.state.isOpen && <AddBillingCycle toggleAdd={this.toggleAdd} isType={this.state.isType} addCompanyAccounts={this.props.addCompanyAccounts} updateCompanyAccounts={this.props.updateCompanyAccounts} editBillCycle={this.state.editBillCycle} />}
            {this.state.isRowOpen && <AddRow toggleAdd={this.onAddRow} isType={this.state.isType} addCompanyAccounts={this.props.addCompanyAccounts} eventDetails={this.props.eventDetails} />}
            {this.state.isBillNumber && <AddBill toggleAdd={this.toggleAddBill} addBillNumber={this.props.addBillNumber} />}
            {this.state.isEditBillNumber && <EditBill toggleEdit={this.toggleEditBill} data={this.state.editBillNumberData} updateBillNumber={this.props.updateBillNumber} />}
            {this.state.showDelete && <DeleteCompanyAccounts
               delete={this.deleteCompanyAccounts}
               toggleDelete={this.toggleDelete}
            />}

            {this.state.showDeleteBillNumber && <DeleteCompanyAccounts
               delete={this.delteBillNumber}
               toggleDelete={this.toggleDeleteBillNumber}
            />}

         </div>
      )
   }
}

export default AccountMaster;