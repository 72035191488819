import React, { Component } from 'react';
import InputText from '../../../../common/components/input-text/input-text';
import Select from 'react-select';
import Button from '../../../button-classic/button-classic';
import '../../../route_code/route_code.less';
import { getEmail, getUserId } from '../../../../../utils/common';
import { getItemFromSessionStorage, setItemToSessionStorage } from '../../../../../utils/sessionStorage';
import Cx from "classnames";
// import { AWL_COMPANY_IDS } from '../../../utils/common';

class AddVolumetric extends Component {
		constructor(props) {
			super(props);
			this.state = {
				data: {					
					"material_code": this.props.editData ? this.props.editData.material_code : '',
					"material_desc": this.props.editData ? this.props.editData.material_desc : '',
                    "new_weight" : this.props.editData ? this.props.editData.new_weight : '',
					"material_type": this.props.editData ? this.props.editData.material_type : '',
					"material_id": this.props.editData ? this.props.editData.material_id : '',					
					"pack_size": this.props.editData ? this.props.editData.pack_size : '',
					"packing_uom": this.props.editData ? this.props.editData["packing_uom"] : '',
                    "gross_weight" : this.props.editData ? this.props.editData.gross_weight : '', 
                    "weight_for_scd" : this.props.editData ? this.props.editData.weight_for_scd : '', 
                    "tolerance_qty" : this.props.editData ? this.props.editData.tolerance_qty : '',
					"company_id": this.props.editData ? this.props.editData.company_id:this.props.companyId,
					"branch_name": this.props.editData ? this.props.editData["branch_name"] : '',
					"branch_code":this.props.editData ? this.props.editData["branch_code"] : '',
					"depot_code":this.props.editData ? this.props.editData["primary_consignee_code"] : '',
					"branch_id":this.props.editData ? this.props.editData["branch_id"] : '',

				},
				name:"",
			}
		}

		componentDidMount(){
            setItemToSessionStorage('editData', this.props.editData)
        }


		onChangeHandler = (value, id) => {
			let { data } = this.state;
			if (id === 'material_code') {
				data[id] = (value || '').toUpperCase().replace(/[^A-Z0-9]/g, '');
			}else if (id === 'pack_size') {
				data[id] = value.replace(/[^A-Z0-9.]/g, '');
			}else 
			if(id === 'new_weight' || id === 'gross_weight' || id === 'weight_for_scd' || id === 'tolerance_qty'){
					data[id] = value.replace(/[^0-9.]/g, '');			
			} 
			else {
				data[id] = value
			}
			this.setState({ data });
		}

		onSave = () => {
			const { data } = this.state;
			data['depot_code'] = data['branch_code'];
			data.tolerance_qty=+parseFloat(data.tolerance_qty)
			data.new_weight=+parseFloat(data.new_weight)
			data.gross_weight=+parseFloat(data.gross_weight)
			data.weight_for_scd=+parseFloat(data.weight_for_scd)
			data['master_type'] = 9;
			data['user_id']= getUserId();
		    data['user_email'] = getEmail();
		    data['new_flag'] = true;
			this.props.onSave(data);
		}

		onUpdate = () => {
			const { data } = this.state;
			const { editData } = this.props;
			const previousData = getItemFromSessionStorage('editData')
		    previousData['update_flag'] = true;
			previousData['user_email'] = getEmail();
			previousData['user_id']= getUserId();
			data.depot_code=data.branch_code
			data.material_id=editData.material_id
			data.tolerance_qty=+parseFloat(data.tolerance_qty)
			data.new_weight=+parseFloat(data.new_weight)
			data.gross_weight=+parseFloat(data.gross_weight)
			data.weight_for_scd=+parseFloat(data.weight_for_scd)
			data['master_type'] = 9;
			data['user_id']= getUserId();
			data['user_email'] = getEmail();
			data['update_flag'] = true;
			data['previous_data'] = previousData;
			this.props.onUpdate(data);
		}

		render() {
			const { data } = this.state;
			const { companyId, packUOMdropdown,fetchDepotName } = this.props;
			const updatefetchDepotName = (fetchDepotName || []).map((data) => ({
			label: data.branch_name,
			value: data.branch_id,
			code : data.branch_code,
			depot_code:data.primary_consignee_code
			})) || [];

			const updatepackUOMdropdown = (packUOMdropdown||[]).map((ele) => ({
				value: ele.name,
				label: ele.description,
			  }));
            const disable = !(data['material_code']&& data['material_desc'] && data["new_weight"] 
			&& data['material_type'] && data["pack_size"] && data['packing_uom'] 
			&& data['gross_weight'] && data['weight_for_scd'] && data["tolerance_qty"] && data['branch_name'] );
			return (
				<div className="route_code_detail">
					<div onClick={this.props.toggleAdd} className="overlay"></div>
					<div id="modal" className="modal-popup">
						<div className="pop-heading">
							{`${this.props.editData ? 'Edit' : 'Add'} Volumetric`}
						</div>
						<div className="route-detail">
						<div className="details-section">
							<span className = {Cx("details-section",{
										'NotSelectablediv': this.props.editData
									})}>
                                <InputText
                                    
									placeholder={`Material Code`}
									id={"material_code"}
									label={`Material Code`}
									changeHandler={this.onChangeHandler}
									value={data['material_code']}
                                    required={true}
								/>
							</span>
								<InputText
                                    
									placeholder="Material Description"
									id={"material_desc"}
									label="Material Description"
									changeHandler={this.onChangeHandler}
									value={data['material_desc']}
									required={true}
								/>
								<InputText
								
									placeholder="New Weight"
									id={"new_weight"}
									label="New Weight (KG)"
									changeHandler={this.onChangeHandler}
									value={data["new_weight"]}
									required={true}
								/>
							</div>
                            <div className="details-section">
								<InputText
									placeholder="Material Type"
									id={"material_type"}
									label="Material Type"
									changeHandler={this.onChangeHandler}
									value={data['material_type']}
                                    required={true}
								/>                                
								<InputText
                                    
									placeholder={`Pack Size`}
									id={"pack_size"}
									label={`Pack Size`}
									changeHandler={this.onChangeHandler}
									value={data["pack_size"]}
                                    required={true}
								/>
								<div className="select-wrap-depot">
						            <div className="label">{'Packing UOM'}<sup className="error error-font">*</sup></div>
                                    
								<Select
									options={updatepackUOMdropdown}
									className="selectpack"
									value={data['packing_uom'] && updatepackUOMdropdown.find((el) => el.value === data['packing_uom'])}
									onChange={(opt) => {
										const { data } = this.state;
										data['packing_uom'] = opt.value;
										this.setState({ data });
									}}
									/>
                                </div>
							</div>
                            <div className="details-section">
								<InputText

									placeholder="Gross Weight"
									id={"gross_weight"}
									label="Gross Weight  (KG)"
									changeHandler={this.onChangeHandler}
									value={data['gross_weight']}
									required={true}
								/>
                                <InputText
                                
									placeholder="Weight for SCD"
									id={"weight_for_scd"}
									label="Weight for SCD (KG)"
									changeHandler={this.onChangeHandler}
									value={data['weight_for_scd']}
									required={true}
								/>
								<InputText
								 	
									placeholder="Tolerance Quantity"
									id={"tolerance_qty"}
									label="Tolerance Quantity (Unit)"
									changeHandler={this.onChangeHandler}
									value={data["tolerance_qty"]}
									required={true}
								/>
							</div>
							<div className={Cx("select-wrap-depot",{
												'NotSelectablediv': this.props.editData
											})}>
						            <div className="label">{'Depot Name'}<span className="error-color">*</span></div>
                                    
									<Select
									options={updatefetchDepotName}
									className="selectpack"
									value={data['branch_name'] && updatefetchDepotName.find((el) => el.label === data['branch_name'])}
									getOptionLabel={(option) => option.label+'-'+option.code }
									onChange={(opt) => {
										const { data } = this.state;
										data['branch_name'] = opt.label;
										data['branch_id'] = opt.value;
										data['branch_code'] = opt.code;
										data['depot_code'] = opt.depot_code;
										this.setState({ data });
									}}
									/>									
                            </div>
							{/* {this.state.isError && <div className={'error-message'}>
								{'Please Enter all mandatory fields'}
							</div>} */}
							<div className={'button_route'}>
								<Button value={'Cancel'} click={this.props.toggleAdd} />
								{!this.props.editData ?
									<Button value={'Save'} click={this.onSave}
									 disabled={disable}
									 />
									:
									<Button value={'Update'} click={this.onUpdate} 
									disabled={disable}
									/>
								}
							</div>
						</div>

					</div>	
				</div>
			)
		}
	}

export default AddVolumetric;
