import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import './vendorVerify.less';
import Loader from "../common/components/loader/loader";
import ACTION from "../common/action_constant";
import ACTIONS from '../common/action_constant';
import { getCookie } from "../../utils/cookies";
import MSGPopUp from "../common/components/msg-popup/msg-popup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { message } from 'antd';
import { DEEPAK_COMPANY_ID, ABFRL_COMPANY_IDS, TTBIL_COMPANY_IDS, CLUSTERS, AWL_COMPANY_IDS, VEHICLE_BODY_TYPE, STATES, years, FUEL_TYPE } from '../../utils/common';

import TopPanel from '../components/header-panel/header-panel';

const PAYMENT_MANNER = [
    { id: 1, name: "RTGS" },
    { id: 2, name: "NEFT" },
    { id: 3, name: "LC" },
    { id: 4, name: "BG" },
];

const PERIODIC_MANNER = [
    { id: 1, name: "Monthly" },
    { id: 2, name: "Quarterly" },
    { id: 3, name: "Others", value: '' },
];

const PERIODIC_TERMS = [
    { id: 1, name: "30 Days" },
    { id: 2, name: "45 Days" },
    { id: 3, name: "Others", value: '' },
];

const TURNOVER_DETAILS = [
    { turnoverValue: '', turnover_uom: '', turnover_year: (new Date().getFullYear()) - 1 },
    { turnoverValue: '', turnover_uom: '', turnover_year: (new Date().getFullYear()) - 2 },
    { turnoverValue: '', turnover_uom: '', turnover_year: (new Date().getFullYear()) - 3 },
];

const termsAndConditions = "https://s3.us-east-2.amazonaws.com/tms-2.0-contracts/dev/GstinDetails.xlsx";

const CustomeCheckBox = (props) => {
    return (
        <div className={props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick={() => props.toggle(props.id)}>
            {props.selected && <div className="inner-circle"></div>}
        </div>
    )
}

class MyDatePicker extends Component {
    render() {
        return (
            <div className="data-picker">
                <div className="label grey">{this.props.label}</div>
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={this.props.startDate}
                    onChange={this.props.handleChange.bind(this, this.props.id)}
                />
            </div>
        );
    }
}

class VendorForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPage: 1,
            limit: 10,
            offset: 0,
            approvalObj: {},
            isRejected: false,
            isResend: false,
            approvalStatus: '',
            otpField: false,
            reason_for_rejection: '',
            reason_for_resend: '',
            allServices: [],
            companyType: [],
            errorMsg: '',
            rating: 0,
            isAccepted: false,
            isValid: false,
            years: years,
            previous_years: [],
            data: {
                company_id: this.props.match.params.companyId,
                ob_vendor_id: this.props.match.params.vendorId,
                company_logo: '',
                scopes: [],
                type: [],
                company_contact_no: "",
                services_offered: [],
                contact_no: "",
                aadhar_card_no: "",
                pan_no: '',
                gst_no: '',
                gstDetails: [],
                currency: 'INR',
                email: '',
                created_by: '',
                additional_email: [],
                company_type: '',
                company_website: '',
                registration_year: '',
                turnover_details: TURNOVER_DETAILS,
                turnover: '',
                bank_name: '',
                branch_name: '',
                account_number: '',
                account_name: '',
                userList: [{
                    admin_name: '',
                    admin_designation: '',
                    admin_email: '',
                    admin_contact: '',
                    admin_location: ''
                }],
                customerList: [{
                    customer_name: '',
                    location: '',
                    service_provided: '',
                    product_handled: '',
                    email: ''
                }],
                regionalOfficeList: [{
                    regional_office_address: '',
                    regional_office_city: '',
                    regional_office_number: '',
                    regional_office_pincode: '',
                    regional_office_state: ''
                }],
                branchOfficeList: [{
                    branch_office_address: '',
                    branch_office_city: '',
                    branch_office_number: '',
                    branch_office_pincode: '',
                    branch_office_state: ''
                }]
            },
            fileNames: [{ fileObject: "", name: "" }],
            declarationFiles: [],
            dataSecond: {
                charges: this.props.vendorForm.chargesList || [],
                ob_vendor_id: this.props.match.params.vendorId,
                operational_location_type: 'Single',
                contract_comensment_date: '',
                contract_duration: '',
                contract_duration_uom: 'Months',
                consideration_oda: '',
                termination: [
                    {
                        termination_type: "with Cause",
                        notice_period: "",
                        notice_period_uom: "days"
                    },
                    {
                        termination_type: "without Cause",
                        notice_period: "",
                        notice_period_uom: "days"
                    }
                ],
                payment_periodicity: "Monthly",
                payment_terms: "30 Days",
                invoice_discrepancy: "10 Days",
                payment_manner: [],
                operationalArea: STATES,
                serviceProvided: [],
                product_handling_capability: [],
                allServiceOffered: [],
                tankerDetails: [{
                    tanker_type: '',
                    tanker_size: '',
                    specification: '',
                    own_fleet: '',
                    attached_fleet: ''
                }],
                vendorRatingQuestions: [],
                clusters: [],
                zones: [],
                states: [],
                vehicle_details: [{
                    type_of_vehicle: "",
                    body_type: "",
                    fuel_type: "",
                    count: ""
                }]
            },
            challenge_price: {},
            counter_price: {},
        }
    }

    componentDidMount() {
        const cmpApprval = JSON.parse(localStorage.getItem('companyApproval'));
        if (cmpApprval && Object.keys(cmpApprval || {}).length > 0) {
            this.setState({ approvalObj: { ...cmpApprval } })
        }

        this.getBasicVendorDetails();
        this.getAllScope();
        this.getRouteDetails();
        this.getZones();
        this.getStateListData()
        this.getServicesProvided();
        this.getAllServicesOffered();
        this.getInventoryItem();
        this.getVendorRatingQuestions();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.vendorForm.vendorDefaultData !== this.props.vendorForm.vendorDefaultData) {

            if (Object.keys(nextProps.vendorForm.vendorDefaultData).length > 0) {
                const defaultData = nextProps.vendorForm.vendorDefaultData || {};
                let { data, dataSecond, year } = this.state;
                data['vendor_company_name'] = defaultData.vendor_company_name;
                data['contact_person_name'] = defaultData.contact_person_name;
                data['email'] = defaultData.email;
                data['created_by'] = defaultData.created_by;
                data['scopes'] = defaultData.scopes || [];
                data['additional_email'] = defaultData.additional_email || [];
                data.contact_no = defaultData.contact_no ? (typeof (defaultData.contact_no) === "string" ? defaultData.contact_no : defaultData.contact_no.phone_no) : ""
                data.company_contact_no = defaultData.company_contact_no ? defaultData.company_contact_no : data.company_contact_no
                data.company_logo = defaultData.company_logo;
                data.type = defaultData.type || [];
                data.services_offered = defaultData.services_offered || [];
                data.pan_no = defaultData.pan_no || '';
                data.aadhar_card_no = defaultData.aadhar_card_no || '';
                data.gst_no = defaultData.gst_no || '';
                data.email = defaultData.email || '';
                data.created_by = defaultData.created_by || '';
                data.gstDetails = defaultData.gstin_details || [];
                data.company_type = defaultData.company_type || '';
                data.company_website = defaultData.company_website || '';
                data.registration_year = defaultData.registration_year || '';
                data.turnover = defaultData.turnover || '';
                data.turnover_details = defaultData.turnover_details || TURNOVER_DETAILS;
                data.regional_office_address = defaultData.regional_office_address || '';
                data.regional_office_city = defaultData.regional_office_city || '';
                data.regional_office_number = defaultData.regional_office_number || '';
                data.regional_office_pincode = defaultData.regional_office_pincode || '';
                data.regional_office_state = defaultData.regional_office_state || '';
                data.bank_name = defaultData.bank_name || '';
                data.branch_name = defaultData.branch_name || '';
                data.account_name = defaultData.account_name || '';
                data.account_number = defaultData.account_number || '';
                data.IFSC_code = defaultData.IFSC_code || '';
                data.branchOfficeList = (defaultData.branchOfficeList && defaultData.branchOfficeList.length > 0) ? defaultData.branchOfficeList : data.branchOfficeList;
                data.regionalOfficeList = (defaultData.regionalOfficeList && defaultData.regionalOfficeList.length > 0) ? defaultData.regionalOfficeList : data.regionalOfficeList;
                data.userList = (defaultData.userList && defaultData.userList.length > 0) ? defaultData.userList : data.userList;
                data.customerList = (defaultData.customerList && defaultData.customerList.length > 0) ? defaultData.customerList : data.customerList;
                data.save_page_1 = defaultData.save_page_1 || null;
                data.vendor_type = defaultData.vendor_type || [];
                data.no_of_fleets_attached = defaultData.no_of_fleets_attached || '';
                data.no_of_fleets_owned = defaultData.no_of_fleets_owned || '';
                dataSecond.operationalArea = defaultData.vendor_details && defaultData.vendor_details.operationalArea && defaultData.vendor_details.operationalArea.length > 0 ? defaultData.vendor_details.operationalArea : dataSecond.operationalArea;
                dataSecond.save_page_2 = defaultData.vendor_details && defaultData.vendor_details.save_page_2 ? defaultData.vendor_details.save_page_2 : null;
                dataSecond.allServiceOffered = defaultData.vendor_details && defaultData.vendor_details.allServiceOffered ? defaultData.vendor_details.allServiceOffered : dataSecond.allServiceOffered;
                dataSecond.product_handling_capability = defaultData.vendor_details && defaultData.vendor_details.product_handling_capability ? defaultData.vendor_details.product_handling_capability : dataSecond.product_handling_capability;
                dataSecond.tankerDetails = defaultData.vendor_details && defaultData.vendor_details.tankerDetails ? defaultData.vendor_details.tankerDetails : dataSecond.tankerDetails;
                dataSecond.vendorRatingQuestions = defaultData.vendor_details && defaultData.vendor_details.vendorRatingQuestions ? defaultData.vendor_details.vendorRatingQuestions : dataSecond.vendorRatingQuestions;

                dataSecond.charges = nextProps.vendorForm.vendorDefaultData.charges ? [...nextProps.vendorForm.vendorDefaultData.charges] : [];
                dataSecond['created_by'] = defaultData.created_by;

                dataSecond.operational_location_type = defaultData.operational_location_type || dataSecond.operational_location_type;
                dataSecond.contract_comensment_date = defaultData.vendor_details && defaultData.vendor_details.contract_comensment_date ? defaultData.vendor_details.contract_comensment_date : dataSecond.contract_comensment_date;
                dataSecond.contract_duration = defaultData.vendor_details && defaultData.vendor_details.contract_duration ? defaultData.vendor_details.contract_duration : dataSecond.contract_duration;
                dataSecond.contract_duration_uom = defaultData.vendor_details && defaultData.vendor_details.contract_duration_uom ? defaultData.vendor_details.contract_duration_uom : dataSecond.contract_duration_uom;
                dataSecond.consideration_oda = defaultData.vendor_details && defaultData.vendor_details.consideration_oda ? defaultData.vendor_details.consideration_oda : dataSecond.consideration_oda;
                dataSecond.termination = defaultData.vendor_details && defaultData.vendor_details.termination ? defaultData.vendor_details.termination : dataSecond.termination;
                dataSecond.payment_periodicity = defaultData.vendor_details && defaultData.vendor_details.payment_periodicity ? defaultData.vendor_details.payment_periodicity : dataSecond.payment_periodicity;
                dataSecond.payment_terms = defaultData.vendor_details && defaultData.vendor_details.payment_terms ? defaultData.vendor_details.payment_terms : dataSecond.payment_terms;
                dataSecond.invoice_discrepancy = defaultData.invoice_discrepancy || dataSecond.invoice_discrepancy;
                dataSecond.payment_manner = defaultData.vendor_details && defaultData.vendor_details.payment_manner ? defaultData.vendor_details.payment_manner : dataSecond.payment_manner;
                dataSecond.states = (defaultData.vendor_details && defaultData.vendor_details.states && defaultData.vendor_details.states.length > 0) ? defaultData.vendor_details.states : dataSecond.states;
                dataSecond.clusters = (defaultData.vendor_details && defaultData.vendor_details.clusters && defaultData.vendor_details.clusters.length > 0) ? defaultData.vendor_details.clusters : dataSecond.clusters;
                dataSecond.serviceProvided = (defaultData.vendor_details && defaultData.vendor_details.serviceProvided && defaultData.vendor_details.serviceProvided.length > 0) ? defaultData.vendor_details.serviceProvided : dataSecond.serviceProvided;
                dataSecond.Segments = (defaultData.vendor_details && defaultData.vendor_details.Segments && defaultData.vendor_details.Segments.length > 0) ? defaultData.vendor_details.Segments : dataSecond.Segments;
                dataSecond.vehicle_details = (defaultData.vendor_details && defaultData.vendor_details.vehicle_details && defaultData.vendor_details.vehicle_details.length > 0) ? defaultData.vendor_details.vehicle_details : dataSecond.vehicle_details;

                if (defaultData.registration_year) {
                    const temp = this.state.years.filter((yr) => yr.label >= defaultData.registration_year)
                    this.setState({ previous_years: temp })
                }
                this.setState({ data, dataSecond }, () => {
                    this.getAllVendorModes(defaultData.modeId);
                });
            }

        }
    }



    getRouteDetails = () => {
        const { dispatch, match } = this.props;
        const { limit, offset } = this.state;
        dispatch({
            type: ACTION.VENDORS.GET_VENDOR_ONBOARD_AREAS,
            params: `?vendorId=${match.params.vendorId}&companyId=${match.params.companyId}&limit=1000&offset=${offset}`
        });
    }


    getBankDetailsFromIFSCCode = () => {
        const { data } = this.state;
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.VENDORS.GET_BANK_DETAILS_FROM_IFSC,
            params: data.IFSC_code,
            onSuccess: ((bankDetails) => {
                const { data } = this.state;
                if (bankDetails) {
                    data.bank_name = bankDetails.BANK
                    data.branch_name = bankDetails.BRANCH
                }
                this.setState({ data })
            })
        })
    }



    getZones = () => {
        this.props.dispatch({
            type: ACTION.VENDORS.FORM_ZONES
        });
    }


    getStateListData = () => {
        const { dataSecond } = this.state;
        this.props.dispatch({
            type: ACTION.VENDORS.GET_STATE_LIST_DETAILS,
            data: '999',
            onSuccess: (data) => {
                dataSecond.stateListData = data.list;

                const stateOptions = [];
                data.list.map((state) => {
                    stateOptions.push({
                        label: state.name,
                        value: state.name
                    })
                })
                stateOptions.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)
                this.setState({ stateOptions })

                const clusters = [];
                (data.list).map((cluster) => {
                    const temp1 = clusters.find((val) => val.cluster_name == cluster.cluster)
                    if (!(temp1 && Object.keys(temp1).length)) {
                        clusters.push({
                            cluster_name: cluster.cluster,
                            cluster_id: cluster.cluster_id
                        })
                    }
                });

                const zones = [];
                (data.list).map((cluster) => {
                    const temp2 = zones.find((val) => val.zone_name == cluster.zone)
                    if (!(temp2 && Object.keys(temp2).length)) {
                        zones.push({
                            zone_name: cluster.zone,
                            zone_id: cluster.zone_id,
                            ...cluster
                        })
                    }
                });

                const states = [];
                (data.list).map((cluster) => {
                    const temp3 = states.find((val) => val.state_name == cluster.name)
                    if (!(temp3 && Object.keys(temp3).length)) {
                        states.push({
                            state_name: cluster.name,
                            gst_state_code: cluster.gst_state_code,
                            ...cluster
                        })
                    }
                });

                dataSecond.clusters = [...clusters]
                dataSecond.zones = [...zones]
                dataSecond.states = [...states]
                this.setStates({ dataSecond })
            }
        })
    }

    getVendorRatingQuestions = () => {
        const { dataSecond } = this.state;
        const { match } = this.props;
        let param = `?companyId=${match.params.companyId}`;
        this.props.dispatch({
            type: ACTION.VENDORS.GET_ALL_QUESTIONS,
            param,
            onSuccess: (data) => {
                dataSecond.vendorRatingQuestions = data;
                this.setState({ dataSecond })
            }
        })
    }

    getServicesProvided = () => {
        const { dataSecond } = this.state;
        const { match } = this.props;
        let param = `?companyId=${match.params.companyId}`;
        this.props.dispatch({
            type: ACTION.VENDORS.GET_SERVICE_PROVIDED,
            //  param: (DEEPAK_COMPANY_ID.includes(match.params.companyId) || AWL_COMPANY_IDS.includes(match.params.companyId)) && param,
            param: '?companyId=default',
            onSuccess: (data) => {
                dataSecond.serviceProvided = data;
                this.setState({ dataSecond })
            }
        })
    }

    getInventoryItem = () => {
        const { match } = this.props;
        let param = `${match.params.companyId}`;
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.MASTER.INVENTORY_ITEM.GET_INVENTORY_ITEM, param });

    }

    getAllServicesOffered = () => {
        const { allServices } = this.state;
        this.props.dispatch({
            type: ACTION.VENDORS.GET_ALL_SERVICE_OFFERED,
            onSuccess: (data) => {
                this.setState({ allServices: data.services_offered })
            }
        })
    }







    getAllScope = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.VENDORS.GET_ALL_SCOPE
        })
    }

    getAllVendorModes = (id) => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.VENDORS.GET_FORM_VENDOR_MODES,
            params: `?mode_type=${id || 3}`
        })
    }


    getBasicVendorDetails = () => {
        const { dataSecond } = this.state;
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.VENDORS.GET_VENDOR_DETAILS,
            params: `?vendorId=${this.props.match.params.vendorId}`,
            onSuccess: ((data) => {
                let type_of_vehicle = data && data[1][0].type_of_vehicle
                let vehicle_type_options = []
                type_of_vehicle.map((vehicle) => {
                    vehicle_type_options.push({
                        label: vehicle,
                        value: vehicle
                    })
                })
                this.setState({ vehicle_type_options: vehicle_type_options })
            })
        });

    }


    closeMsgPopup = () => {
        const { dispatch } = this.props;
        dispatch({ type: ACTION.MSG_DISPLAY.HIDE_MSG })
    }

    verifyData = () => {
        const { dispatch } = this.props;
        const { data, verificationCode } = this.state;

        let payload = {
            company_id: data.company_id,
            vendor_company_name: data.vendor_company_name,
            contact_no: data.contact_no,
            contact_person_name: data.contact_person_name,
            email: data.email,
            verification_code: verificationCode,
            ob_vendor_id: data.ob_vendor_id
        }

        console.log(payload)

        if (verificationCode) {
            delete this.state.verificationError
            dispatch({
                type: ACTION.VENDORS.VERIFY_VENDOR_CONTACTS,
                data: payload
            })
        } else {
            this.setState({ verificationError: 'Please Enter the Verification Code' })
        }
    }

    close = () => {
        window.close()
      }

    render() {
        const { vendorForm, productList, match } = this.props;
        const { data, isPage, dataSecond, fileNames, approvalObj } = this.state;

        const userData = getCookie('user') ? JSON.parse(getCookie('user') || {}) : '';
        const userCompanyId = match.params.companyId;
        const isSeeker = this.props.match.params.approverType === '1';
        const hasCPAccess = userData && userData.isCP ? (this.props.match.params.approverType === '1' && userData.isCP) : false;
        const isVendorCP = this.props.match.params.hasCP === '1';

        return (
            <div className='main-container'>
                <div className="boarding-form">
                    <div className="header">
                        <div>
                            <TopPanel newVendor />
                        </div>
                    </div>

                    {this.props.loader.isLoading && <Loader />}
                    {this.props.msgpopup.active && <MSGPopUp close={this.closeMsgPopup} type={this.props.msgpopup.type} msg={this.props.msgpopup.msg} />}

                    <div className="form-page-1">
                        <div className='contact-table'>
                            <div className="Table-header">
                                <div className="table-heading">Contact Details</div>
                                <div className='second-table-body-verify'>
                                    <div className="black mb-20 wt-100p">
                                        <div className="black">Contact Person Name</div>
                                        <input
                                            type="text"
                                            placeholder="Contact Person Name"
                                            className="contact-details-vendor-input"
                                            value={data.contact_person_name}
                                            disabled
                                        />
                                    </div>
                                    <div className="black mb-20 wt-100p">
                                        <div className="black">Email ID</div>
                                        <input
                                            type="text"
                                            placeholder="email"
                                            className="contact-details-vendor-input"
                                            value={data.email}
                                            disabled
                                        />
                                    </div>
                                    <div className="dInBlock wt-100p">
                                        <div className="black">Contact Number</div>
                                        <input
                                            type="text"
                                            placeholder="contact no"
                                            className="contact-details-vendor-input"
                                            value={data.contact_no}
                                            // onChange={(e) => {
                                            //     const { data } = this.state;
                                            //     if (e.target.value.length <= 10) {
                                            //         if (e.target.value != 0) {
                                            //             const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                            //             const check = e.target.value.match(regEx);
                                            //             if (!check) {
                                            //                 return
                                            //             }
                                            //         }
                                            //         data.contact_no = e.target.value;
                                            //     }
                                            //     this.setState({ data });
                                            // }}
                                            disabled
                                        />
                                    </div>

                                    <div className="dInBlock wt-100p">
                                        <div className="black">Verification Code</div>
                                        <input
                                            type="text"
                                            placeholder="verification code"
                                            className="contact-details-vendor-input"
                                            value={this.state.verificationCode}
                                            onChange={(e) => {
                                                if (e.target.value.length <= 6) {
                                                    if (e.target.value != 0) {
                                                        const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                                        const check = e.target.value.match(regEx);
                                                        if (!check) {
                                                            return
                                                        }
                                                    }
                                                    this.setState({ verificationCode: e.target.value });
                                                }
                                            }}
                                        />
                                        {this.state.verificationError && <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{this.state.verificationError}</p>}
                                    </div>

                                    <div className="dInBlock">
                                        <button className="button-classic mt-15" onClick={() => this.verifyData()}>Verify</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginLeft: 'auto', marginTop: '20px' }}>
                        <button className="mb-25 button-classic" onClick={this.close}> Cancel </button>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        loader: state.loader,
        msgpopup: state.msgpopup,
        vendorForm: state.vendorForm,
        productList: state.master.itemList,
    };
};

export default connect(mapStateToProps)(VendorForm);
