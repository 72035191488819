import React from 'react';
import InputText from '../../common/components/input-text/input-text';

const editReasons = (props) => {
    return (
        <div className = "add-company-wrap">
            <div onClick = {props.toggleEdit} className = "overlay"></div>
            <div id = "modal" className = "modal-popup small-modal smallest">
                <div className = "pop-heading">
                    Edit Reason
                </div>
                <form className = "form-section mt-10" autoComplete = "nopes">
                    <InputText placeholder = "Reason" id = {"editReason"} label = "Reason" value = {props.reason} changeHandler = {props.onChangeHandler}/>
                </form>
                
                <div className = "submit-section submit-area"> 
                    <div className ="button-section">
                        <div className ="add-button" onClick={props.updateReasons}>Update</div>   
                    </div> 
                    <div className ="button-section" >
                        <div className ="add-button" onClick={props.toggleEdit}>Close</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default editReasons;