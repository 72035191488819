import React from "react";
import Select from "react-select";
import { DateRangePicker } from "react-dates";
import Moment from "moment";
import ACTIONS from "../../../common/action_constant";
import Download from "../../../../assets/images/download.svg";
import Delete from "../../../../assets/images/delete.svg";
import Loader from "../../../common/components/loader/loader";
import "./uploadLocation.less";
import e from "cors";
import { connect } from "react-redux";
import { getUserCompanyName } from "../../../../utils/common";

import SearchText from "../../../common/components/search-text/search-text";
import MainComponent from "../../main/main";
import { Link } from "react-router-dom";
import Axios from "axios";

class UploadLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      company_id: "",
      fileName: ""
    };
  }

  componentDidMount() {
    this.getIPAddress()
  }

  downloadTemplate = () => {
    const { dispatch } = this.props;

    dispatch({
      type: ACTIONS.PARENTCHILD.DOWNLOAD_TEMPLATE_IN_UPLOAD_LOCATION,
      data: 1,
      onSuccess: ((data) => {
        const element = document.createElement('a');
        element.setAttribute('href', data.link);
        element.setAttribute('download', true);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      })
    })
  }

  downloadFile = () => {
    const { dispatch } = this.props;
    const { tableData } = this.state;
    dispatch({
      type:ACTIONS.PARENTCHILD.DOWNLOAD_TEMPLATE_IN_UPLOAD_LOCATION,
      data: `4&masterChildId=${tableData.master_child_id}`,
      onSuccess: ((data) => {
        const element = document.createElement('a');
        element.setAttribute('href', data.link);
        element.setAttribute('download', true);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      })
    })
  }

  changeHandler = (val, id) => {
    this.setState({
      [id]: val
    });
  }

  fileClicked = (e) => {
    e.preventDefault();
    document.getElementById("file").click();
  };

  fileUpload = e => {
    e.persist();
    this.setState({
      fileName: e.target.files[0]
    });
  };

  getIPAddress = async () => {
    const res = await Axios.get('https://geolocation-db.com/json/')
    this.setState({ ip_address: res.data.IPv4 })
  }

  uploadFile = async () => {
    const { dispatch } = this.props;
    const { ip_address, tableData } = this.state;
    const formData = new FormData();

    if (this.state.fileName === "") {
      dispatch({
        type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
        data: { type: 2, msg: "Please Select the file!" },
      })
    } else {
      formData.append("file", this.state.fileName);
      formData.append('ip_address', ip_address)
      formData.append('type', 1)

      if(tableData){
        formData.append('masterChildId', tableData.master_child_id)
      }

      dispatch({
        type: ACTIONS.PARENTCHILD.UPLOAD_FILE_IN_UPLOAD_LOCATION,
        file: formData,
        onSuccess: ((data) => {
          if (data && data.list) {
            this.setState({ tableData: data.list })
            this.setState({fileName: ""})
          }
        }),
      });
    }
  };

  deleteData = () => {
    const { tableData } = this.state;
    const { dispatch } = this.props;

    dispatch({
      type: ACTIONS.PARENTCHILD.DELETE_FILE_IN_UPLOAD_LOCATION,
      data: `?masterChildId=${tableData.master_child_id}`,
      onSuccess: (() => {
        this.setState({ tableData: "" })
      })
    })
  }

  render() {
    const { loader } = this.props;
    const { tableData, status } = this.state;
    const customStyles = {
      control: (base) => ({
        ...base,
        borderColor: "#528aea",
      }),
    };
    return (
      <div className="master">
        {loader.isLoading && <Loader />}
        <MainComponent>
          <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <Link to='/locationMgmt' className="link">
              <h4>
                Back
              </h4>
            </Link>
            <h2>
              Upload Location
            </h2>
          </div>
          <div className="UL">
            <div className="upload">
              <div className="border">
                <div className="flex-item flex-between">
                  <div className="flex-item">
                  </div>
                </div>
                <div style={{ marginTop: '10px' }} className="flex-item">

                  <div className="template" onClick={() => this.downloadTemplate()}>
                    <div
                      className={"download-icon"}
                      style={{
                        backgroundImage: `url(${Download})`,
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <div className="template-text">Template</div>
                  </div>

                  <div className="file-holder">
                    <input id="file" type="file" onChange={this.fileUpload} />
                    <span className="file-choose" onClick={this.fileClicked}>
                      Browser
                    </span>
                    <span className="file-name">
                      {this.state.fileName
                        ? this.state.fileName.name
                        : "No file selected"}
                    </span>
                  </div>

                  <div className="upload-button" onClick={this.uploadFile}>
                    Upload
                  </div>

                </div>
              </div>

              <div className="table">
                <table className="table-view">
                  <thead>
                    <tr className="table-tr">
                      <th className="tCenter" width="150px">
                        File Name
                      </th>
                      <th className="tCenter" width="100px">
                        Success/Failure
                      </th>
                      <th className="tCenter" width="250px">
                        Reason
                      </th>
                      <th className="tCenter" width="80px">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {(this.state.uploadSummaryData || []).map((data, idx) => {
                    // let date = Moment(data.uploaded_on).format('DD/MMM/YYYY')
                    // let time = Moment(data.uploaded_on).format('LT')

                    return ( */}
                    <tr className="tr-1">
                      <td>{(tableData && tableData.master_child_id) || '-'}</td>
                      <td>
                        {tableData ? 
                        <>
                        {tableData.error_count > 0 ?
                          <div style={{color:'red'}}>Failure({tableData.error_count})</div>
                          :
                          <div style={{color:'green'}}>Success({tableData.number_of_record})</div>
                        }
                        </>
                        :
                        <>-</>  
                      }
                      </td>
                      <td>{(tableData && tableData.reason) || '-'}</td>
                      <td>
                        {tableData && tableData.error_count > 0  ?
                          <>
                            <img src={Download} width="28px" style={{ cursor: 'pointer' }} onClick={() => { this.downloadFile() }} />
                            <div
                              className={"delete-icon"}
                              style={{ backgroundImage: `url(${Delete})`, marginLeft: '10px' }}
                              onClick={() => this.deleteData()}
                            >
                            </div>
                          </>
                          :
                          <>-</>  
                        }
                      </td>
                    </tr>
                    {/* );
                  })} */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </MainComponent>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loader: state.loader,
  };
};

export default connect(mapStateToProps)(UploadLocation);
