import React, { Component , Fragment } from 'react';
import Select from 'react-select';
import InputText from '../../../common/components/input-text/input-text';
import { decimalNumber } from '../../../../utils/validate'
import Button from '../../button-classic/button-classic';

class FuelSurcharge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                surcharge: props.fuelSurchargeData ? props.fuelSurchargeData.surcharge : '',
                transporters: props.fuelSurchargeData ? props.fuelSurchargeData.transporters.map(val => { return { label: val.company_name, value: val.id }}) : []
            }
        }
    }

    onChangeamountHandler = (value, field) => {
       if (decimalNumber(value) || value == '') {
        const { data } = this.state;
        data[field] = value; 
        this.setState({ data }); }
    }

    onSave = () => {
        const { data } = this.state;
        data.transporters = data.transporters.length > 0 ? data.transporters.map(val => { return { company_name : val.label, id : val.value }}) : data.transporters;
        this.props.addPayables(data, 21);
        this.props.toggleAdd(21);
    }

    onUpdate = () => {
        const { data } = this.state;
        const { fuelSurchargeData } = this.props;
        data.transporters = data.transporters.length > 0 ? data.transporters.map(val => { return { company_name : val.label, id : val.value }}) : data.transporters;
        data.payable_id = fuelSurchargeData.payable_id;
        data.load_type = fuelSurchargeData.load_type;
        data.mode_type = fuelSurchargeData.mode_type;
        data.type = fuelSurchargeData.type;
        this.props.updatePayables(data, 21);
        this.props.toggleAdd(21);
    }

    onTransporterSearch = (value) => {
        if(value) {
            this.props.getTransporterList(value);
        }
    }

   validateVal = (val) => {
      return val.toString().match(/^\d{1,2}(\.\d{1,3})?$/)
   }

   validData = ({ data } = this.state) => {
      return (this.validateVal(data.surcharge) && data.transporters.length !== 0);
   }
   

    render() {
        const { data } = this.state;
        const { transporterList, fuelSurchargeData} = this.props;
       const disable = this.validData();

        return (
            <div className = "add-company-wrap add-provider-root add-row">
                <div onClick = {this.props.toggleAdd} className = "overlay">
                    <div className = "modal-popup" onClick={(e) => { e.stopPropagation() }}>
                        <div className = "heading">
                            { `${fuelSurchargeData ? 'Edit' : 'Add'} Fuel Surcharge`}
                        </div>
                        <form className = "form-section" autoComplete = "nopes">
                            <div className="select-wrap">
                                <div className="label">Transporter</div>
                                <Select 
                                    isMulti
                                    className={'select'} 
                                    value={!fuelSurchargeData ? data.transporters : data.transporters.map(val => { return { label: val.company_name || val.label, value: val.id || val.value}})}
                                    options={transporterList && transporterList.length > 0 ? transporterList.map(trans => {
                                        return { label : trans.company_name, value: trans.branch_id }
                                    }) : []}
                                    onChange= {(options) => {
                                        const { data } = this.state;
                                        data.transporters = options;
                                        this.setState({ data });
                                    }}
                                    onInputChange={this.onTransporterSearch}
                                />
                            </div>
                            <InputText id='surcharge' value={data.surcharge} placeholder={'Surcharge Percentage'} label={'Surcharge Percentage'} changeHandler={this.onChangeamountHandler} type={'number'}/>
                        </form>
                        <div className = "submit-section submit-area">
                       {
                          
                          <div className="button-section">
                             {!this.props.fuelSurchargeData && <Button className="add-button" click={this.onSave} value="Save" disabled={!disable} />}
                             {this.props.fuelSurchargeData && <Button className="add-button" click={this.onUpdate} value="Update" disabled={!disable} />}
                          </div>
                       }
                            <div className ="button-section" >
                                <div className ="add-button" onClick={this.props.toggleAdd}>Close</div>
                            </div>
                        </div>
                    </div>
                </div>
          </div>   
        )
    }
}

export default FuelSurcharge;