import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import './login.less';
import history from "../history";
import ACTION from "../common/action_constant";
import translogo from "../../assets/images/trans-logo.svg";
import Loader from "../common/components/loader/loader";
import validate from "../../utils/validate";
import MessagePopUp from "../common/components/msg-popup/msg-popup";
import api from '../common/api_config';
import { getCookie } from '../../utils/cookies';
import Recaptcha from 'react-recaptcha';
import { getSecureRandomKey } from '../../utils';
// import AlertIcon from "../../assets/images/esclamation.svg"

class Login extends Component {
   constructor(props) {
      super(props);
      this.state = {
         email: "",
         password: "",
         error: "",
         errorMessage: "",
         isVerified: false,
         showTag: false,
         reCaptchaKey:1,
         captcha_value:'',
         show_checkbox: false,
         force_logout: false
      }
   }

   closeMsgPopup = () => {
      const { dispatch } = this.props;
      dispatch({ type: ACTION.MSG_DISPLAY.HIDE_MSG })
   }

   onChange(fieldName, e) {
      let state = Object.assign({}, this.state);
      state[fieldName] = e.target.value;
      this.setState(state);
   }

   login = () => {
      const {
         captcha_value,
         force_logout
      } = this.state;
      let error = "";
      const loginObj = {
         email: this.state.email,
         password: this.state.password,
         company_type: "",
         device_type: 3,
         module_id: 6,
         captcha_value,
        force_logout
      }
      if (!validate.email(this.state.email)) {
         error = 'Please Enter A Valid TMS Email ID';
      }
      if (!validate.password(this.state.password)) {
         if (error) {
            error = "Please Enter A Valid Email Id and Password";
         }
         else
            error = "Please Enter A Valid Password";
      }
      /* if(!this.state.isVerified){
             error="Please verify you are not robot" ;
           }
           if(error && error.length > 0){
               // this.state.errorMessage
               this.setState({
                 error: error
               });
               return false;
           }*/
      if (error) {
         this.setState({ error });
         return;
      }
      const { dispatch } = this.props;
      dispatch({ type: ACTION.LOGIN.USERLOGIN, data: loginObj,
      onError: (errorData) => {
         const isUserAlreadyLoggedin = errorData == 1
          const hideLoginBtn = errorData == 1
          this.setState({ 
            show_checkbox: true ,
            isUserAlreadyLoggedin,
            hideLoginBtn
          });
      }
    });
      
      this.setState((prevState)=>{
         const updatedState  =  {...prevState};
         updatedState.isVerified = false
         updatedState.reCaptchaKey =  getSecureRandomKey()
         return updatedState
       }) 
   }

   handleLoginOnEnter = (e) => {
      if (e.keyCode === 13 || e.which === 13) {
         if(this.state.isSSOLogin){
            this.validateLogin();
         } else {
            this.login();
         }
         
      }
   }

   ssoDirectLogin = () => {
      localStorage.setItem('isSSOUser', true);
      window.location.replace(`${api.getSSO}/?sso&module=6&next=${window.location.origin}/home`);
   }

   componentWillMount() {
      if (window.parent.frames.length > 0) {
         var antiClickjack = document.getElementById("antiClickjack");
         antiClickjack.innerHTML = '';
      }
   }
   componentDidUpdate() {


   }
   componentDidMount() {
      const {dispatch} = this.props;
      const token = getCookie("token");
      dispatch({
         type: ACTION.LOGIN.GET_SSO_CONFIG,
         params: {}
      })
      setTimeout(function () { //Start the timer
         this.setState({ showTag: true }) //After 1 second, set render to true
      }.bind(this), 1000)
      if(!token) {
         return;
      } else {
         location.replace('/home')
      }
   }

   verifyCallback = (response) => {
      this.setState((prevState)=>{
        const updatedState  =  {...prevState};
        updatedState.captcha_value = response
        updatedState.errorMessage = ""
        updatedState.isVerified = true;
        return updatedState
      })  
    }
  
    callback = () => {
      console.log('Done!!!!');
    };
  
    onExpire = () => {
      this.setState({
        isVerified:false
      });
    };

   ssoLogin = (isSSOLogin) => {
      this.setState({isSSOLogin})
   }

   validateLogin = () => {
      const { email } = this.state;
      const { dispatch } = this.props;
      localStorage.clear();
      const data = {
         email
      }
      dispatch({
         type: ACTION.LOGIN.VALIDATE_SSO_USER,
         data,
         onSuccess: () => {
         localStorage.setItem('isSSOUser', true);
         window.location.replace(`${api.getSSO}/?sso&email=${email}&module=6&next=${window.location.origin}/home`);
         }
      })
   }

   onGenerateAnotherSession = () => {
      const { show_checkbox, invalidContact, invalidOTP } = this.state;
      this.setState({
        show_checkbox: !show_checkbox,
        errorMessage: '',
        force_logout: true
      }, () => {
          this.login();
      })
    }

   render() {
      const { ssoConfigData } = this.props;
      const {
         reCaptchaKey,
         isVerified,
         show_checkbox,
         isUserAlreadyLoggedin,
         hideLoginBtn
      }= this.state;
      const disableTegLogin = (ssoConfigData || {disable_teg_login: true}).disable_teg_login;
      const isCustomDomain = (ssoConfigData || {is_custom_domain: true}).is_custom_domain;
      return (
         <div style={{ background:"rgb(82 138 234 / 85%)", height:"120vh" }}>
            <div className="login-root">
            {/* <Loader/> */}
            <div className="login">
               {
                  this.props.msgpopup.active && <MessagePopUp 
                     close={this.closeMsgPopup} 
                     type={this.props.msgpopup.type} 
                     msg={this.props.msgpopup.msg} 
                  />
               }
               <div className="icon"><img src={translogo} /></div>
               <div className="holder">
                  {this.state.error && <label className="error">{this.state.error}</label>}
                  {/* <div className = "head">Hello!</div> */}
                  <div className="description" style={{ marginRight: "-1rem" }}>Sign In With Your Credentials Or To Register Contact info@caliperbusiness.com</div>
                  <div className="input-holder">
                     {!disableTegLogin && <input type="text" 
                        placeholder="Email Address" 
                        className="input" value={this.state.email} 
                        onChange={this.onChange.bind(this, 'email')} 
                        onKeyPress={(e) => this.handleLoginOnEnter(e)} 
                     />}
                     {/* <div className = "input-box">
                                <InputComponent 
                                error = "" 
                                id = "email" 
                                placeholder = {"Email address"} 
                                value = {this.state.email} 
                                type = "text" 
                                changeHandler = {this.changeHandler} 
                                focus = {false}/>
                            </div> */}
                     {/* <div className = "input-box">
                                <InputComponent 
                                error = "" 
                                id = "password" 
                                placeholder = {"Password"} 
                                value = {this.state.password} 
                                type = "password" 
                                changeHandler = {this.changeHandler} 
                                focus = {false}/>
                            </div> */}
                     {!disableTegLogin && !this.state.isSSOLogin && <input 
                        type="password" 
                        placeholder="Password" 
                        className="input" value={this.state.password} 
                        onChange={this.onChange.bind(this, 'password')} 
                        onKeyPress={(e) => this.handleLoginOnEnter(e)} 
                     />}
                  </div>
                  {/* {this.state.showTag &&     
                  <Recaptcha
                    sitekey="6LcHtbwUAAAAAKkjbo7nNh9ajzF5GEHk9uC5skGG"
                    render="explicit"
                    verifyCallback={this.verifyCallback}
                    onloadCallback={this.callback}
                    expiredCallback={this.onExpire}
                    key={reCaptchaKey}
                  />} */}
                  {<div className="button-holder flex">
                     {!this.state.isSSOLogin && !disableTegLogin && !hideLoginBtn && <button className="login-button mr-20" onClick={this.login}>Login</button>}
                     {!this.state.isSSOLogin && !disableTegLogin && !isCustomDomain && <button className="login-button" style={{minWidth: '175px'}} onClick={() => this.ssoLogin(true)}>Login with SSO</button>}
                     {!this.state.isSSOLogin && isCustomDomain && <button className="login-button" style={{minWidth: '175px'}} onClick={() => this.ssoDirectLogin()}>Login with SSO</button>}
                  </div>}
                  {this.state.isSSOLogin && <div className="button-holder">
                     <button className="login-button" style={{minWidth: '175px'}} onClick={() => this.validateLogin()}>Login with SSO</button>
                     <button className="sso-login-button mr-20" onClick={() => this.ssoLogin(false)}>Back to Signin Page</button>
                  </div>}
               </div>
               
            </div>
            {show_checkbox && 
            <Fragment>
              <div className="fs-14 mt-10 flex flex-center col-1" style={{ marginTop: "8rem", marginLeft: "150px" }}>
                    {/* <input type="checkbox" className="ht-15 wt-10p tCenter mt-20" onClick={this.onGenerateAnotherSession}/> */}
                    <div className="theme-color fBold flex flex-center pt-5 mb-40 tCenter " style={{ background:"wheat", padding: "1rem", "border-radius": "5px", "margin-top": "1rem" }}>
                    {/* <AlertIcon className="wt-20 flex ht-20 alert-icon"/> */}
                      <div style={{ "padding-left":"5rem" }}>
                      
                        {<Fragment>
                            <span className="error-color flex flex-center" style={{ fontSize:"16px" }}>
                            Your Previous Login / Session is still active and not closed properly.
                            To Logout previous sessions,
                            {<Fragment>
                              <a
                                onClick={this.onGenerateAnotherSession}
                                className={"success-color curP"}
                              >
                                Click Here...
                              </a>
                            </Fragment>}
                          </span>
                        </Fragment>}
                        {<div className="error-color flex flex-center fBold mt-5" style={{ fontSize:"16px" }}>Note: Any unsaved data in previous sessions will be lost</div>}
                      </div>
                      
                    </div>
              </div>
            </Fragment>
          }
         </div>
         
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      login: state.login,
      loader: state.loader,
      msgpopup: state.msgpopup,
      ssoConfigData: state.login.ssoConfigData
   };
};

export default connect(mapStateToProps)(Login);