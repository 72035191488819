import React, { Component } from 'react';
import LoadingBay from "./Bay/Bay";
import LoadingGang from "./Gang/Gang";
import StandardTime from "./StandardTime/StandardTime";
import WeighBridge from "./WeighBridge/weighbridge";
import WBconversion from "./WBConversion/WBConversion";
import Warehouse from "./Warehouse/Warehouse";
import AutoMove from './AutoMove/AutoMove';
import WeighBridgeMaster from './WBMaster/master';
import Mfs from './MFS/Mfs';
import ParkingLot from './ParkingLot/parkingLot';
import Range from './Range/Range';
import { EMAMI, isAWL, isBeams } from '../../../../utils/common';
import SalesDistrictMappingMaster from '../sales-district-mapping master/Sales-District-MappingMaster';

class LoadingBayAndGang extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: 1,
            navigationOptions: isAWL()?[
                {
                    id: 1,
                    heading: 'Loading Bay'
                }, 
                {
                    id: 2,
                    heading: 'Loading Vendor'
                }, 
                 {
                    id: 4,
                    heading: 'Weigh Bridge Config.'
                },
                {
                    id: 5,
                    heading: 'Weigh Bridge Conversion'
                },
                {
                    id: 6,
                    heading: 'Warehouse Wise Storage'
                },
                {
                    id: 9,
                    heading: 'MFS Master'
                },
                
            ]:
            isBeams()?[
                {
                    id: 1,
                    heading: 'Loading Bay'
                }, 
                {
                    id: 2,
                    heading: 'Loading Vendor'
                },
                {
                    id: 12,
                    heading: 'Sales District Mapping'
                } 
            ]:
            [
                {
                    id: 1,
                    heading: 'Loading Bay'
                }, 
                {
                    id: 2,
                    heading: 'Loading Vendor'
                }, {
                    id: 3,
                    heading: 'Standard Time Config.'
                }, {
                    id: 4,
                    heading: 'Weigh Bridge Config.'
                },
                {
                    id: 5,
                    heading: 'Weigh Bridge Conversion'
                },
                {
                    id: 6,
                    heading: 'Warehouse Wise Storage'
                },
                {
                    id: 7,
                    heading: 'Automove Config.'
                },
                {
                    id: 8,
                    heading: 'Weigh Bridge Master'
                },
                {
                    id: 9,
                    heading: 'MFS Master'
                },
                ( EMAMI.includes(props.company_id) ? {
                    id: 10,
                    heading: 'Parking Lot Master'
                } : 
                {}),
                {
                    id: 11,
                    heading: 'Range Configuration'
                },
                
            ]
        }
    }

    render() {
        return (
            <div className={'loading_bay_gang'}>
                <div className="home-navigation-holder">
                {this.state.navigationOptions.map((option, index) => {
                    return (
                    <div
                        key={index}
                        className={
                        this.state.selectedOption === option.id
                            ? "selected-option options"
                            : "options"
                        }
                        onClick={() => {
                            this.setState({ selectedOption: option.id })
                        }}
                    >
                        {option.heading}
                    </div>
                    );
                })}
                </div>
                {
                    this.state.selectedOption === 1 && 
                    <LoadingBay 
                        onAddBay={this.props.onAddBay}
                        getLoadingBay={this.props.getLoadingBay}
                        loadingBayData={this.props.loadingBayData}
                        deleteBay={this.props.deleteBay}
                        updateLoadingBay={this.props.updateLoadingBay}
                        downloadLoadingBay={this.props.downloadLoadingBay}
                        uploadLoadingBayDetails={this.props.uploadLoadingBayDetails}
                        downloadLink={this.props.downloadLink}
                        downloadBtn={this.props.downloadBtn}
                        company_id={this.props.company_id}
                        branch_id={this.props.branch_id}
                        depotCode={this.props.depotCode}
                    />
                }
                {
                    this.state.selectedOption === 2 && 
                    <LoadingGang 
                        getLoadingGang={this.props.getLoadingGang}
                        addLoadingGang={this.props.addLoadingGang}
                        loadingGangData={this.props.loadingGangData}
                        deleteLoadingGang={this.props.deleteLoadingGang}
                        updateLodingGang={this.props.updateLodingGang}
                        downloadLoadingVendor={this.props.downloadLoadingVendor}
                        uploadLoadingVendorDetails={this.props.uploadLoadingVendorDetails}
                        downloadLink={this.props.downloadLink}
                        downloadBtn={this.props.downloadBtn}
                        company_id={this.props.company_id}
                        branch_id={this.props.branch_id}
                        depotCode={this.props.depotCode}
                    />
                } 
                {
                    this.state.selectedOption === 3 && 
                    <StandardTime 
                        getMoves={this.props.getMoves}
                        company_id={this.props.company_id}
                        branch_id={this.props.branch_id}
                        indentingMoves={this.props.indentingMoves}
                        saveStandardTime={this.props.saveStandardTime}
                        moveStandardTime={this.props.moveStandardTime}
                        getMoveStanadardTime={this.props.getMoveStanadardTime}
                        updateStandardTime={this.props.updateStandardTime}
                    />
                }
                {
                    this.state.selectedOption === 4 && 
                    <WeighBridge 
                        saveWeighBridge={this.props.saveWeighBridge}
                        getWeighBridgeDetails={this.props.getWeighBridgeDetails}
                        weighBridgeDetails={this.props.weighBridgeDetails}
                    />
                }
                {
                    this.state.selectedOption === 5 && 
                    <WBconversion
                        {...this.props}
                        getWBConversion={this.props.getWBConversion}
                        WBConversionData={this.props.WBConversionData}
                        onAddWBConversion={this.props.onAddWBConversion}
                        updateWBConversion={this.props.updateWBConversion}
                        deleteWBConversion={this.props.deleteWBConversion}
                    />
                }
                {
                    this.state.selectedOption === 6 && 
                    <Warehouse 
                        {...this.props}
                        onAddWarehouse={this.props.onAddWarehouse}
                        getWarehouseDetails={this.props.getWarehouseDetails}
                        warehouseData={this.props.warehouseData}
                        deleteWarehouse={this.props.deleteWarehouse}
                        updateWarehouseDetails={this.props.updateWarehouseDetails}
                        downloadWarehouse={this.props.downloadWarehouse}
                        uploadWarehouseDetails={this.props.uploadWarehouseDetails}
                        downloadLink={this.props.downloadLink}
                        downloadBtn={this.props.downloadBtn}
                        warehouseStorageData={this.props.warehouseStorageData}
                    />
                }
                {
                    this.state.selectedOption === 7 && 
                    <AutoMove 
                        indentingMoves={this.props.indentingMoves}
                        automoveDetails={this.props.automoveDetails}
                        getAutomoveDetails={this.props.getAutomoveDetails}
                        getMoves={this.props.getMoves}
                        company_id={this.props.company_id}
                        addAutomoveDetails={this.props.addAutomoveDetails}
                    />
                }
                {
                    this.state.selectedOption === 8 &&
                    <WeighBridgeMaster
                        {...this.props}
                        getWeighBridgeMasterListing={this.props.getWeighBridgeMasterListing}
                        weighBridgeMasterListing={this.props.weighBridgeMasterListing}
                        onAddWBMaster={this.props.onAddWBMaster}
                        updateWBMaster={this.props.updateWBMaster}
                        deleteWBMaster={this.props.deleteWBMaster}
                    />
                }
                 {
                    this.state.selectedOption === 9 &&
                    <Mfs
                    getMfsMasterListing={this.props.getMfsMasterListing}
                    mfsMasterListing={this.props.mfsMasterListing}
                    onAddMfsMaster={this.props.onAddMfsMaster}
                    updateMfsMaster={this.props.updateMfsMaster}
                    deleteMfsMaster={this.props.deleteMfsMaster}
                    getMfsMasterUomListing={this.props.getMfsMasterUomListing}
                    mfsMasterUomListing={this.props.mfsMasterUomListing}
                    companyId={this.props.company_id}
                    depotCode={this.props.depotCode}
                    company_id={this.props.company_id}
                    branch_id={this.props.branch_id}
                    />
                }
                {
                    this.state.selectedOption === 10 &&
                    <ParkingLot
                        getParkingLotListing={this.props.getParkingLotListing}
                        parkingLotListing={this.props.parkingLotListing}
                        onAddParkingLot={this.props.onAddParkingLot}
                        updateParkingLot={this.props.updateParkingLot}
                        deleteParkingLot={this.props.deleteParkingLot}
                    />
                }
                {
                    this.state.selectedOption === 11 &&
                    <Range
                        getSlabRange={this.props.getSlabRange}
                        slabRange={this.props.slabRange}
                        addSlabRange={this.props.addSlabRange}
                        updateSlabRange={this.props.updateSlabRange}
                        deleteSlabRange={this.props.deleteSlabRange}
                    />
                }
                {
                    this.state.selectedOption === 12 &&
                    <SalesDistrictMappingMaster
                    SalesdistrictMappingData = {this.props.SalesdistrictMappingData}
                    getSalesdistrictMapping = {this.props.getSalesdistrictMapping}
                    onAddSalesDistrictMapping = {this.props.onAddSalesDistrictMapping}
                    updateSalesDistrictMapping = {this.props.updateSalesDistrictMapping}
                    deleteSalesDistrictMapping = {this.props.deleteSalesDistrictMapping}
                    />
                }
            </div>
        )
    }
}

export default LoadingBayAndGang;
