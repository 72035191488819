import React, { Component } from 'react';
// import "./add-company.less";
import validate from "../../../../../../utils/validate";
import { getEmail, getUserId } from '../../../../../../utils/common';
import '../roles.less'

class AddRole extends Component {
	constructor(props) {
		super(props);
		this.state = {
			role_name: "",
			error: ""
		}
	}
	onChangeHandler = (value, id) => {
		this.setState({
			[id]: value
		})
	}

	addRole = () => {
		let paramData = {
			company_id: this.props.companyDetails && this.props.companyDetails.id,
			company_type: this.props.companyDetails && this.props.companyDetails.company_type,
			role_name: this.state.role_name,
			master_type: 6,
			user_id:getUserId(),
			user_email: getEmail(),
			new_flag: true,
		}
		if (validate.isEmpty(paramData.role_name)) {
			this.setState({
				error: "Please Enter A Valid Role Name"
			});
			return;
		}
		this.props.addRole(paramData);
		this.props.toggleAdd();
		this.setState({
			error: ""
		});
	}


	render() {
		const { props, state } = this;
		return (
			<div className="add-company-wrap">
				<div onClick={props.close} className="overlay"></div>
				<div className="modal-popup small-modal add-role-modal">
					<div className="heading">
						Add a new Role
                    </div>
					<label className="error">{this.state.error}</label>
					<form className="form-section" autoComplete="nopes">
						<InputText placeholder="Role Name" id={"role_name"} label="Role Name" value={state.role_name} changeHandler={this.onChangeHandler} maxLength={30} />
					</form>
					<div>
						<div className="submit-section submit-area">
							<div className="button-section">
								<div className="add-button" onClick={this.addRole}>Save</div>
							</div>
							<div className="button-section" >
								<div className="add-button" onClick={this.props.toggleAdd}>Close</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const InputText = (props) => {
	return (
		<div className="input-text-wrap">
			<div className="label">
				{props.label}
			</div>
			<div className="input-element">
				<input type="text" id={props.id} placeholder={props.placeholder} className="text-input" value={props.value} onChange={(e) => props.changeHandler(e.target.value, props.id)} autoComplete="nopes" maxLength={props.maxLength}/>
			</div>
		</div>
	)
}


export default AddRole;