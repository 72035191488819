import React, { Component, Fragment } from 'react';
import Button from '../../button-classic/button-classic';
import InputText from '../../../common/components/input-text/input-text';
import { legthValidation, validationCheck } from '../../../../utils/validate';
//import { legthValidation, validationCheck } from '../../../utils/validate';
import Select from 'react-select';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { fuelStatus, ABFRL_COMPANY_IDS, PANTALOONS_COMPANY_IDS } from '../../../../utils/common';
import Dropup from "../../../../assets/images/drop-up-arrow.svg";
import Increase from "../../../../assets/images/up-arrow.svg";
import Decrease from "../../../../assets/images/down-arrow.svg";
import AddDamage from "./add-damage";
import Moment from 'moment';
import Delete from '../../../../assets/images/delete.svg';
import Edit from '../../../../assets/images/edit.svg';
import EditFuel from './edit-fuel';
import FreightRate from './add-freight-rate';
import RHCCharges from './rhc-charge';
import FuelSurcharge from './fuel_surcharge';
import RASCharge from './ras-charge';
import FOVCharge from './fov-charge';
import ODACharges from './oda-charge';
import Detention from './add_charge';
import { numeric, decimalNumber } from '../../../../utils/validate';
import { getCookie } from "../../../../utils/cookies";
import DeleteCreditMaster from './../../../components/delete-confirmation/delete-confirmation'
import AddMinimumFreightRate from './add-minimum-freight-rate';

class CreditMaster extends Component {
   constructor(props) {
      super(props);
      this.state = {
         data: {
            valid_from: null,
            valid_to: null,
            fuel_rate_variation: '',
            percentage_of_baserate: '40',
            fuel_rate: ''
         },
         checkDropDown: [false, false, false, false, false, false],
         isType: null,
         isOpen: false,
         amount_per_point: '',
         isFreightRate: false,
         isMinimumFreightRate: false,
         isRHCCharges: false,
         isFuelSurcharge: false,
         isRASCharge: false,
         isFOVCharge: false,
         isAddCharge: false
      }
   }

   handleChange = (selectedOption) => {
      let { data } = this.state;
      data.fuel_rate_variation = selectedOption.label;
      this.setState({ data });
   }

   // onChangeHandler = (value, id) => {
   //    let { data } = this.state;
   //    data[id] = value;
   //    this.setState({ data });
   // }
   onChangeHandler = (value, id) => {
      if (decimalNumber(value) || value == '') {
         let { data } = this.state;
         data[id] = value;
         this.setState({ data });
      }
   }
   onAddFuel = (id) => {
      let { data } = this.state;
      data.valid_from = data.valid_from ? data.valid_from.toString() : data.valid_from;
      data.valid_to = data.valid_from ? data.valid_to.toString() : data.valid_from;
      this.props.addCreditDetails(data, id);
      const dataClear = {
         valid_from: null,
         valid_to: null,
         fuel_rate_variation: '',
         percentage_of_baserate: '',
         fuel_rate: ''
      }
      this.setState({ data: dataClear });
   }

   addCreditDetails = (data) => {
      this.props.addCreditDetails(data, this.state.isType);
   }

   toggleCheck = (id, isType) => {
      let checkDropDown = this.state.checkDropDown;
      checkDropDown[id] = !checkDropDown[id];
      this.setState({ checkDropDown },
         () => {
            if (checkDropDown[id] && [2, 4, 5, 6, 7, 8].indexOf(id) === -1) {
               this.props.getCreditMaster(isType);
            } else if (id == 2 && checkDropDown[id]) {
               this.props.getMultiPickUpDropDetails();
            } else if ([4, 5, 6, 7, 8].indexOf(id) > -1 && checkDropDown[id]) {
               this.props.getPayables(isType);
            }
         })

   }

   toggleAdd = (type, editFuel = '') => {
      type = type ? type : this.state.isType;
      this.setState({ isOpen: !this.state.isOpen, isType: type, editFuel });
   }

   onChangeamountHandler = (value, id) => {
      if (numeric(value) || value == '')
         this.setState({ [id]: value });
   }

   onSaveMultiPickup = () => {
      const data = {
         amount_per_point: this.state.amount_per_point
      }
      this.props.addMultiPickUpDropDetails(data);
   }

   onUpdateMultiPickup = () => {
      let data = this.props.multiPickUp[0];
      const details = {
         multipoint_id: data.multipoint_id,
         amount_per_point: this.state.amount_per_point,
         is_deleted: data.is_deleted,
         company_id: data.company_id
      }
      this.props.updateMultiPickUpDropDetails(details);
   }

   componentWillReceiveProps(nextProps) {
      if (nextProps.multiPickUp && nextProps.multiPickUp.length > 0) {
         this.setState({ amount_per_point: nextProps.multiPickUp[0].amount_per_point });
      }
      if (nextProps.mainTabOption !== this.props.mainTabOption) {
         this.setState({ checkDropDown: [false, false, false, false] });
      }
   }

   onAddFreightRate = (type, editFreightData = '') => {
      this.setState({ isFreightRate: !this.state.isFreightRate, isType: type, editFreightData }, () => {
         if (this.state.isFreightRate) {
            this.props.getTransporterList();
         }
      });
   }
   onAddMinimumFreightRate = (type, editMinimumFreightData = '') => {
      this.setState({ isMinimumFreightRate: !this.state.isMinimumFreightRate, isType: type, editMinimumFreightData }, () => {
         if (this.state.isMinimumFreightRate) {
            this.props.getTransporterList();
         }
      });
   }

   getZone = (id) => {
      const result = this.props.zones.find(val => { return val.id === id });
      return result && Object.keys(result).length > 0 ? result.name : '';
   }

   onAddRHCCharges = (type, rhcData = '') => {
      const { isRHCCharges } = this.state;
      this.setState({ isType: type ? type : this.state.isType, rhcData, isRHCCharges: !isRHCCharges });
   }
   onAddFuelSurcharge = (type, fuelSurchargeData = '') => {
      const { isFuelSurcharge } = this.state;
      this.setState({ isType: type ? type : this.state.isType, fuelSurchargeData, isFuelSurcharge: !isFuelSurcharge });
   }

   onAddRAS = (type, rasData = '') => {
      const { isRASCharge } = this.state;
      this.setState({ isType: type ? type : this.state.isType, rasData, isRASCharge: !isRASCharge });
   }

   onAddFOV = (type, fovData = '') => {
      const { isFOVCharge } = this.state;
      this.setState({ isType: type ? type : this.state.isType, fovData, isFOVCharge: !isFOVCharge });
   }

   onAddODA = (type, odaData = '') => {
      const { isODACharge } = this.state;
      this.setState({ isType: type ? type : this.state.isType, odaData, isODACharge: !isODACharge });
   }

   // toggleDelete = (credit_master_id, num) => {
   //    this.setState({
   //       showDelete: !this.state.showDelete,
   //       credit_master_id: credit_master_id,
   //       num: num
   //    })
   // }



   toggleDelete = (credit_master_id, num) => {
      this.setState({
         showDelete: !this.state.showDelete,
         credit_master_id: credit_master_id,
         num: num
      })
   }

   deleteCreditMaster = () => {
      this.props.deleteCreditMaster(this.state.credit_master_id, this.state.num);
      this.toggleDelete();
   }

   toggleDeletePayables = (payable_id, num) => {
      this.setState({
         showPayDelete: !this.state.showPayDelete,
         payable_id: payable_id,
         num: num

      })
   }

   deletePayables = () => {
      this.props.deletePayables(this.state.payable_id, this.state.num);
      this.toggleDeletePayables();
   }

   onAddCharge = (isEditChargeData = '', slabDetails, slabIndex = '') => {
      const isAddCharge = !this.state.isAddCharge;
      if (this.state.isAddCharge) {
         isEditChargeData = '';
         slabIndex = '';
      }
      this.setState({ isAddCharge, isEditChargeData, slabDetails, slabIndex });
   }
   shouldRenderMinimumFreightRate=(mainTabOption)=>{
      // [3].indexOf(mainTabOption) > -1 && (ABFRL_COMPANY_IDS.includes(company_id))
      const company_id = JSON.parse(getCookie("user")).company_id;

      if(ABFRL_COMPANY_IDS.includes(company_id)){
         if([3].indexOf(mainTabOption) > -1){
            return true;
         }
      }
      return false;
   }


   render() {
      const {
         freightRateDetails,
         mainTabOption,
         zones,
         getTransporterList,
         zipCodes,
         loadingCharges,
         minFreightRateDetails,
       } = this.props;
       const {
         rhcDetails,
         fuelSurchargeDetails,
         rasDetails,
         fovDetails,
         stateList,
         odaDetails,
       } = this.props.data;
      const company_id = JSON.parse(getCookie("user")).company_id;
      let freightRateDetailsCopy = [];
      if (freightRateDetails != undefined) {
         freightRateDetailsCopy = [...freightRateDetails];
         if (!(ABFRL_COMPANY_IDS.includes(company_id) || PANTALOONS_COMPANY_IDS.includes(company_id))) {
            freightRateDetailsCopy = freightRateDetailsCopy.filter(el => el.chain_type != '')
         } else {
            freightRateDetailsCopy = freightRateDetailsCopy.filter(el => el.vendor_id != '')
         }
      }
      let minFreightRateDetailsCopy = [];
      if (minFreightRateDetails != undefined) {
         minFreightRateDetailsCopy = [...minFreightRateDetails];
         minFreightRateDetailsCopy = minFreightRateDetailsCopy.filter(
            (el) => el.vendor_id != "",
         );
      }

      return (
         <div className={'credit-master'}>
            {[1, 2].indexOf(mainTabOption) > -1 &&
               <Fragment>
                  <div className={'header'}>Fuel Escalation
                     <div className={this.state.checkDropDown[1] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(1, 9)}></div>
                  </div>
                  <div className={`drop-section ${this.state.checkDropDown[1] ? "drop-up" : "drop-close"}`}>
                     <div className={'fuel-details'}>
                        <div className={'freight-rate'}>
                           <InputText placeholder={'% Of Base Rate'} label={'% Of Base Rate'} id={"percentage_of_baserate"} value={this.state.data.percentage_of_baserate} changeHandler={this.onChangeHandler} type={'number'} />
                        </div>
                        <InputText placeholder={'%'} label={'Fuel Variation %'} id={'fuel_rate'} value={this.state.data.fuel_rate} changeHandler={this.onChangeHandler} type={'number'} />
                        <Select
                           className={'select'}
                           value={this.state.data.fuel_rate_variation ? { label: this.state.data.fuel_rate_variation, value: this.state.data.fuel_rate_variation } : null}
                           onChange={this.handleChange}
                           options={fuelStatus}
                           placeholder={'Select..'}
                        />
                        <div className="select-wrap">
                           <div className="label">Validity</div>
                           <DateRangePicker
                              startDate={this.state.data.valid_from ? Moment(this.state.data.valid_from) : null}
                              startDateId="startDate"
                              endDate={this.state.data.valid_to ? Moment(this.state.data.valid_to) : null}
                              endDateId="endDate"
                              onDatesChange={({ startDate, endDate }) => {
                                 let { data } = this.state;
                                 data.valid_from = startDate ? startDate.valueOf() : data.valid_from;
                                 data.valid_to = endDate ? endDate.valueOf() : data.valid_to;
                                 this.setState({ data });
                              }}
                              focusedInput={this.state.focusedInput}
                              onFocusChange={focusedInput => this.setState({ focusedInput })}
                              displayFormat={'DD MMM YYYY'}
                              noBorder={true}
                              minimumNights={0}
                              isOutsideRange={() => false}
                           />
                        </div>
                        {((this.state.data.percentage_of_baserate.match(/^\d{1,2}(\.\d{1,3})?$/) != null) && (this.state.data.fuel_rate.match(/^\d{1,2}(\.\d{1,3})?$/) != null) && this.state.data.fuel_rate_variation && this.state.data.valid_from && this.state.data.valid_to) ?
                           <Button className={'fuel-btn'} click={() => this.onAddFuel(9)} value={'Add'} /> : <Button className={'fuel-btn'} value={'Add'} disabled />
                        }

                     </div>
                     <div className={'fuel-escalation-table'}>
                        <div className={'list-section'}>
                           <div className={'table-header'}>% Of Base Rate</div>
                           <div className={'table-header'}>Increase/Decrease</div>
                           <div className={'table-header'}>Valid From</div>
                           <div className={'table-header'}>Valid To</div>
                           <div className={'table-header'}>Actions</div>
                        </div>
                        {this.props.fuelDetails && this.props.fuelDetails.map((value, index) => {
                           return (<div className={'list-section'} key={index}>
                              <div className={'table-row'}>{value.percentage_of_baserate}</div>
                              <div className={'table-row'}>{value.fuel_rate}
                                 <span className={'increase-icon'} style={{ backgroundImage: `url(${value.fuel_rate_variation == 'Increase' ? Increase : Decrease})` }}></span></div>
                              <div className={'table-row'}>{value.valid_from ? Moment(+value.valid_from).format('DD MMM YYYY') : null}</div>
                              <div className={'table-row'}>{value.valid_to ? Moment(+value.valid_to).format('DD MMM YYYY') : null}</div>
                              <div className={'table-row'}>
                                 <div className={'delete-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.toggleAdd(9, value)}></div>
                                 <div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleDelete(value.credit_master_id, 9)}></div>
                              </div>
                           </div>)
                        })}
                     </div>
                  </div>
               </Fragment>}

            {[1].indexOf(mainTabOption) > -1 && <Fragment>
               <div className={'header'}>Multi Pickup/Drop Charges
                  <div className={this.state.checkDropDown[2] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(2)}></div>
               </div>
               <div className={`drop-section ${this.state.checkDropDown[2] ? "drop-up" : "drop-close"}`}>
                  <InputText id='amount_per_point' value={this.state.amount_per_point} placeholder={'Amount Per Point'} label={'Amount Per Point'} changeHandler={this.onChangeamountHandler} />
                  {/* {this.props.multiPickUp && this.props.multiPickUp.length == 0 && this.state.amount_per_point ? <Button className={'fuel-btn'} click={this.onSaveMultiPickup} value={'Save'} /> : <Button className={'fuel-btn'} disabled value={'Save'} />} */}
                  {this.props.multiPickUp && this.props.multiPickUp.length == 0 && <Button className={'fuel-btn'} click={this.onSaveMultiPickup} disabled={!this.state.amount_per_point} value={'Save'} />}
                  {this.props.multiPickUp && this.props.multiPickUp.length > 0 && <Button className={'fuel-btn'} click={this.onUpdateMultiPickup} value={'Update'} />}
               </div>
            </Fragment>}

            {[1].indexOf(mainTabOption) > -1 && <Fragment>
               <div className={'header'}>2 Point Loading Charges
                  <div className={this.state.checkDropDown[9] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(9, 33)}></div>
               </div>
               <div className={`drop-section ${this.state.checkDropDown[9] ? "drop-up" : "drop-close"}`}>
                  <div className={'fuel-escalation-table'}>
                     <Fragment>
                        <div className={'list-section'}>
                           {/* <div className={'table-header detention-grace'}>Grace</div> */}
                           <div className={'table-header weight weight-detention'}>
                              <div className={'slab-weight'}>
                                 <div className="vehicle-type">Vehicle Type</div>
                                 <div className="wt-15p">Minimum KM</div>
                                 <div className="wt-15p">Maximum KM</div>
                                 <div className="wt-15p">Amount</div>
                                 <div className="wt-15p">Actions</div>
                              </div>
                           </div>

                        </div>
                        {this.props.loadingCharges && this.props.loadingCharges.length > 0 && this.props.loadingCharges.map((row, index) => {
                           return (<div className={'list-section  oda-charges'} key={index}>
                              <div className={'table-row slab-weight slab-weight-detention'}>
                                 {row.slabs && (row.slabs || []).map((slab, slabindex) => {
                                    return (
                                       <>
                                          <div className={'slab-weight-data row-data align-center'} style={{ 'marginTop': '5px' }} key={slabindex}>
                                             <div className="vehicle-type">{slab.vehicles && (slab.vehicles || []).map(vehicle => {
                                                return (<div className="vehicle-type-list" style={{ 'marginTop': '5px' }}>{vehicle.name}</div>);
                                             })}</div>
                                             <div>{slab.slab_min} {slab.min_km}</div>
                                             <div>{slab.slab_max} {slab.max_km}</div>
                                             <div>{slab.detention_amount} {slab.amount}</div>

                                             <div className={'table-row align-center detention-grace'} style={{ width: '15%' }}>
                                                <div className={'delete-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.onAddCharge(slab, row.slabs, slabindex)}></div>
                                                {/* <div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleDeleteDetentionDetails(row.detention_id)}></div> */}
                                             </div>
                                          </div>
                                       </>
                                    );
                                 })}
                              </div>

                           </div>)
                        })}
                     </Fragment>
                     <div className={'approval-btn'}>
                        <Button value={'Add'} click={() => this.onAddCharge('', this.props.loadingCharges[0].slabs)}></Button>
                     </div>
                  </div>
               </div>
            </Fragment>}

            {[1, 2].indexOf(mainTabOption) === -1 &&
               <Fragment>
                  <div className={'header'}>Minimum Weight rate
                     <div className={this.state.checkDropDown[3] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(3, 14)}></div>
                  </div>
                  <div className={`drop-section ${this.state.checkDropDown[3] ? "drop-up" : "drop-close"}`}>
                     {
                        !(ABFRL_COMPANY_IDS.includes(company_id) || PANTALOONS_COMPANY_IDS.includes(company_id)) ? (<div className={'fuel-escalation-table'}>
                           <div className={'list-section'}>
                              <div className={'table-header'}>Chain Type</div>
                              <div className={'table-header'}>Transporter</div>
                              {[3, 4].indexOf(mainTabOption) === -1 &&
                                 <Fragment>
                                    <div className={'table-header'}>Zone</div>
                                    <div className={'table-header'}>Rate/KG</div>
                                 </Fragment>
                              }
                              <div className={'table-header'}>Min. Chargeable Wt.(KG)</div>
                              {/* {[3, 4].indexOf(mainTabOption) > -1 &&
                              <Fragment>
                                 <div className={'table-header'}>Min Applicable Wt.(kg)</div>
                                 <div className={'table-header'}>Max Applicable Wt.(kg)</div>
                                 <div className={'table-header'}>Slab (rate/kg)</div>
                              </Fragment>
                           } */}
                              <div className={'table-header'}>Actions</div>
                           </div>

                           {freightRateDetailsCopy && freightRateDetailsCopy.map((val, index) => {
                              return (
                                 <div className={'list-section'} key={index}>
                                    <div className={'table-row'}>{val.chain_type}</div>
                                    <div className={'table-row'}>
                                       {val.transporter && val.transporter.map(transp => {
                                          return (<div style={{ 'marginTop': '5px' }}>{transp.company_name}</div>);
                                       })}
                                    </div>
                                    {[3, 4].indexOf(mainTabOption) === -1 &&
                                       <Fragment>
                                          <div className={'table-row'}>
                                             {val.zone_rates && val.zone_rates.length > 0 && val.zone_rates.map(zoneRate => {
                                                return (
                                                   <div style={{ 'marginTop': '5px' }}>{`${this.getZone(zoneRate.from_zone_id)} - ${this.getZone(zoneRate.to_zone_id)}`}</div>
                                                )
                                             })}
                                          </div>
                                          <div className={'table-row'}>
                                             {val.zone_rates && val.zone_rates.length > 0 && val.zone_rates.map(zoneRate => {
                                                return (
                                                   <div style={{ 'marginTop': '5px' }}>{zoneRate.rate}</div>
                                                )
                                             })}
                                          </div>
                                       </Fragment>
                                    }
                                    <div className={'table-row'}>{val.min_chargeable_weight}</div>
                                    {/* {[3, 4].indexOf(mainTabOption) > -1 &&
                                    <Fragment>
                                       <div className={'table-row'}>
                                          {val.slab && val.slab.length > 0 && val.slab.map(slabRate => {
                                             return (
                                                <div style={{ 'marginTop': '5px' }}>{slabRate.min_applicable_weight}</div>
                                             )
                                          })}
                                       </div>
                                       <div className={'table-row'}>
                                          {val.slab && val.slab.length > 0 && val.slab.map(slabRate => {
                                             return (
                                                <div style={{ 'marginTop': '5px' }}>{slabRate.max_applicable_weight}</div>
                                             )
                                          })}
                                       </div>
                                       <div className={'table-row'}>
                                          {val.slab && val.slab.length > 0 && val.slab.map(slabRate => {
                                             return (
                                                <div style={{ 'marginTop': '5px' }}>{slabRate.rate}</div>
                                             )
                                          })}
                                       </div>
                                    </Fragment>} */}
                                    <div className={'table-row'}>
                                       <div className={'delete-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.onAddFreightRate(14, val)}></div>
                                       <div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleDelete(val.credit_master_id, 14)}></div>
                                    </div>
                                 </div>
                              )
                           })}
                        </div>) : (
                           <div className={'fuel-escalation-table'}>
                              <div className={'list-section'}>
                                 <div className={'table-header'}>Vendor</div>
                                 <div className={'table-header'}>Category</div>
                                 <div className={'table-header'}>Mode</div>
                                 <div className={'table-header'}>Type</div>
                                 <div className={'table-header'}>Min. Chargeable Wt.(KG)</div>
                                 <div className={'table-header'}>Actions</div>
                              </div>

                              {freightRateDetailsCopy && freightRateDetailsCopy.map((val, index) => {
                                 return (
                                    <div className={'list-section'} key={index}>
                                       <div className={'table-row'}>{val.vendor_name}</div>
                                       <div className={'table-row'}>{val.company_category}</div>
                                       <div className={'table-row'}>{val.mode_name}</div>
                                       <div className={'table-row'}>{val.type_name}</div>
                                       <div className={'table-row'}>{val.min_chargeable_weight}</div>
                                       <div className={'table-row'}>
                                          <div className={'delete-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.onAddFreightRate(14, val)}></div>
                                          <div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleDelete(val.credit_master_id, 14)}></div>
                                       </div>
                                    </div>
                                 )
                              })}
                           </div>
                        )
                     }
                     <div className={'approval-btn'}>
                        <Button className={'fuel-btn'} click={() => this.onAddFreightRate(14)} value={'Add'} />
                     </div>
                  </div>
               </Fragment>
            }

            {
            this.shouldRenderMinimumFreightRate(mainTabOption) && (
               <Fragment>
                  <div className={"header"}>
                  Minimum Freight Rate
                  <div
                     className={
                        this.state.checkDropDown[140] ? "arrow-icon rotate" : "arrow-icon"
                     }
                     style={{ backgroundImage: `url(${Dropup})` }}
                     onClick={() => this.toggleCheck(140, 140)}
                  ></div>
                  </div>
                  <div
                  className={`drop-section ${this.state.checkDropDown[140] ? "drop-up" : "drop-close"}`}
                  >
                  {
                     <div className={"fuel-escalation-table"}>
                        <div className={"list-section"}>
                        <div className={"table-header"}>Vendor</div>
                        <div className={"table-header"}>Category</div>
                        <div className={"table-header"}>Mode</div>
                        <div className={"table-header"}>Min. Chargeable Freight.(₹)</div>
                        <div className={"table-header"}>Actions</div>
                        </div>

                        {minFreightRateDetailsCopy &&
                        minFreightRateDetailsCopy.map((val, index) => {
                           return (
                              <div className={"list-section"} key={index}>
                              <div className={"table-row"}>{val.vendor_name}</div>
                              <div className={"table-row"}>{val.company_category}</div>
                              <div className={"table-row"}>{val.mode_name}</div>
                              <div className={"table-row"}>
                                 {val.min_chargeable_freight}
                              </div>
                              <div className={"table-row"}>
                                 <div
                                    className={"delete-icon"}
                                    style={{ backgroundImage: `url(${Edit})` }}
                                    onClick={() => this.onAddMinimumFreightRate(140, val)}
                                 ></div>
                                 <div
                                    className={"delete-icon"}
                                    style={{ backgroundImage: `url(${Delete})` }}
                                    onClick={() =>
                                    this.toggleDelete(val.credit_master_id, 140)
                                    }
                                 ></div>
                              </div>
                              </div>
                           );
                        })}
                     </div>
                  }
                  <div className={"approval-btn"}>
                     <Button
                        className={"fuel-btn"}
                        click={() => this.onAddMinimumFreightRate(140)}
                        value={"Add"}
                     />
                  </div>
                  </div>
               </Fragment>
            )
            }

            {[2, 4, 5, 6].indexOf(mainTabOption) > -1 && <Fragment>
               <div className={'header'}>RHC/FOV Charges
                  <div className={this.state.checkDropDown[4] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(4, 20)}></div>
               </div>
               <div className={`drop-section ${this.state.checkDropDown[4] ? "drop-up" : "drop-close"}`}>
                  <div className={'fuel-escalation-table'}>
                     <div className={'list-section'}>
                        <div className={'table-header'}>Transporter1</div>
                        <div className={'table-header'}>% Of Invoice Amount</div>
                        <div className={'table-header'}>Minimum RHC Amount</div>
                        <div className={'table-header'}>Actions</div>
                     </div>
                     {rhcDetails && rhcDetails.length > 0 && rhcDetails.map((rhc, index) => {
                        return (<div className={'list-section'}>
                           <div className={'table-row'}>
                              {rhc.transporters && rhc.transporters.map(transp => {
                                 return (<div style={{ 'marginTop': '5px' }}>{transp.company_name}</div>);
                              })}
                           </div>
                           <div className={'table-row'}>{rhc.freight_bill_percentage}</div>
                           <div className={'table-row'}>{rhc.min_rhc}</div>
                           <div className={'table-row'}>
                              <div className={'delete-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.onAddRHCCharges(20, rhc)}></div>
                              <div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleDeletePayables(rhc.payable_id, 20)}></div>
                           </div>
                        </div>
                        )
                     })}
                  </div>
                  <div className={'approval-btn'}>
                     <Button className={'fuel-btn'} click={() => this.onAddRHCCharges(20)} value={'Add'} />
                  </div>
               </div>
            </Fragment>
            }

            {[5, 6].indexOf(mainTabOption) > -1 && <Fragment>
               <div className={'header'}>Fuel Surcharge
                  <div className={this.state.checkDropDown[5] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(5, 21)}></div>
               </div>
               <div className={`drop-section ${this.state.checkDropDown[5] ? "drop-up" : "drop-close"}`}>
                  <div className={'fuel-escalation-table'}>
                     <div className={'list-section'}>
                        <div className={'table-header'}>Transporter</div>
                        <div className={'table-header'}>Surcharge Percentage</div>
                        <div className={'table-header'}>Actions</div>
                     </div>
                     {fuelSurchargeDetails && fuelSurchargeDetails.length > 0 && fuelSurchargeDetails.map((surcharge, index) => {
                        return (<div className={'list-section'} key={index}>
                           <div className={'table-row'}>
                              {surcharge.transporters && surcharge.transporters.map(transp => {
                                 return (<div style={{ 'marginTop': '5px' }}>{transp.company_name}</div>);
                              })}
                           </div>
                           <div className={'table-row'}>{surcharge.surcharge}</div>
                           <div className={'table-row'}>
                              <div className={'delete-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.onAddFuelSurcharge(21, surcharge)}></div>
                              <div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleDeletePayables(surcharge.payable_id, 21)}></div>
                           </div>
                        </div>)
                     })}
                  </div>
                  <div className={'approval-btn'}>
                     <Button className={'fuel-btn'} click={() => this.onAddFuelSurcharge(21)} value={'Add'} />
                  </div>
               </div>
            </Fragment>}

            {[5, 6].indexOf(mainTabOption) > -1 && <Fragment>
               <div className={'header'}>RAS Charges
                  <div className={this.state.checkDropDown[6] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(6, 22)}></div>
               </div>
               <div className={`drop-section ${this.state.checkDropDown[6] ? "drop-up" : "drop-close"}`}>
                  <div className={'approval-btn'}>
                     {/* <Button value={'Download'} click={() => this.downloadRASList()}></Button>
                     <a href={''} ref={(e) => { this.downloadRASUrl = e ;}} style={{display: 'none'}}/>
                     <Button value={'Upload'} click={() => this.uploadRASList()}></Button>
                     <input type='file' style={{ display: 'none'}}  ref={(e) => { this.uploadRASUrl = e ;}} onChange={this.uploadRASCharge} onClick={(e) => {e.target.value = null }}/> */}
                     <Button className={'fuel-btn'} click={() => this.onAddRAS(22)} value={'Add'} />
                  </div>
                  <div className={'fuel-escalation-table'}>
                     <div className={'list-section'}>
                        <div className={'table-header'}>Transporter</div>
                        {/* <div className={'table-header'}>State</div>
                        <div className={'table-header'}>Charge</div>
                        <div className={'table-header'}>UOM</div> */}
                        <div className={'table-header'}>Min. Charge</div>
                        <div className={'table-header'}>Max. Charge</div>
                        <div className={'table-header weight'}>
                           <div className={'label'}>Rates</div>
                           <div className={'slab-weight'}>
                              <div>ZIP Code</div>
                              <div>Rate</div>
                           </div>
                        </div>
                        <div className={'table-header'}>Actions</div>
                     </div>
                     {rasDetails && rasDetails.length > 0 && rasDetails.map((ras, index) => {
                        return (<div className={'list-section oda-charges'}>
                           <div className={'table-row'}>
                              {ras.transporters && ras.transporters.map(transp => {
                                 return (<div style={{ 'marginTop': '5px' }}>{transp.company_name}</div>);
                              })}
                           </div>
                           {/* <div className={'table-row'}>
                              {ras.states && ras.states.map(state => {
                                 return (<div style={{ 'marginTop': '5px' }}>{state.name}</div>);
                              })}
                           </div>
                           <div className={'table-row'}>{ras.ras_charge}</div>
                           <div className={'table-row'}>{ras.ras_uom}</div> */}
                           <div className={'table-row align-center'}>{ras.min_charge}</div>
                           <div className={'table-row align-center'}>{ras.max_charge}</div>
                           <div className={'table-row slab-weight'}>
                              {ras.rates && ras.rates.length > 0 && ras.rates.map((rate, index) => {
                                 return (
                                    <div className={'slab-weight-data row-data'} style={{ 'marginTop': '5px' }} key={index}>
                                       <div>
                                          {rate.zipcode && rate.zipcode.length > 0 && rate.zipcode.map((code, ind) => {
                                             return (<Fragment>
                                                <span>{code}</span><br />
                                             </Fragment>)
                                          })
                                          }
                                       </div>
                                       <div>{rate.rate}</div>
                                    </div>
                                 );
                              })}
                           </div>
                           <div className={'table-row align-center'}>
                              <div className={'delete-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.onAddRAS(22, ras)}></div>
                              <div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleDeletePayables(ras.payable_id, 22)}></div>
                           </div>
                        </div>)
                     })}
                  </div>
               </div>
            </Fragment>}

            {/* {[6].indexOf(mainTabOption) > -1 && <Fragment>
               <div className={'header'}>FOV Charges
                    <div className={this.state.checkDropDown[7] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(7, 23)}></div>
               </div>
               <div className={`drop-section ${this.state.checkDropDown[7] ? "drop-up" : "drop-close"}`}>
                  <div className={'fuel-escalation-table'}>
                     <div className={'list-section'}>
                        <div className={'table-header'}>Transporter</div>
                        <div className={'table-header'}>{'% Of Invoice Amount'}</div>
                        <div className={'table-header'}>Actions</div>
                     </div>
                     {fovDetails && fovDetails.length > 0 && fovDetails.map((fov, index) => {
                        return (<div className={'list-section'} key={index}>
                           <div className={'table-row'}>
                              {fov.transporters && fov.transporters.map(transp => {
                                 return (<div style={{ 'marginTop': '5px' }}>{transp.company_name}</div>);
                              })}
                           </div>
                           <div className={'table-row'}>{fov.invoice_amount_percentage}</div>
                           <div className={'table-row'}>
                              <div className={'delete-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.onAddFOV(23, fov)}></div>
                              <div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleDeletePayables(fov.payable_id, 23)}></div>
                           </div>
                        </div>)
                     })}
                  </div>
                  <div className={'approval-btn'}>
                     <Button className={'fuel-btn'} click={() => this.onAddFOV(23)} value={'Add'} />
                  </div>
               </div>
            </Fragment>} */}

            {[5, 6].indexOf(mainTabOption) > -1 && <Fragment>
               <div className={'header'}>ODA Charges
                  <div className={this.state.checkDropDown[8] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(8, 24)}></div>
               </div>
               <div className={`drop-section ${this.state.checkDropDown[8] ? "drop-up" : "drop-close"}`}>
                  <div className={'approval-btn'}>
                     {/* <Button value={'Download'} click={() => this.downloadODAList()}></Button>
                     <a href={''} ref={(e) => { this.downloadODAUrl = e ;}} style={{display: 'none'}}/>
                     <Button value={'Upload'} click={() => this.uploadODAList()}></Button>
                     <input type='file' style={{ display: 'none'}}  ref={(e) => { this.uploadODAUrl = e ;}} onChange={this.uploadODACharge} onClick={(e) => {e.target.value = null }}/> */}
                     <Button className={'fuel-btn'} click={() => this.onAddODA(24)} value={'Add'} />
                  </div>

                  <div className={'fuel-escalation-table'}>
                     <div className={'list-section'}>
                        <div className={'table-header'}>Transporter</div>
                        {/* <div className={'table-header'}>Min. ODA Charge</div>
                        <div className={'table-header'}>Max. ODA Charge</div>
                        <div className={'table-header'}>Flat Rate</div>
                        <div className={'table-header weight'}>
                           <div className={'label'}>Slab</div>
                           <div className={'slab-weight'}>
                              <div>Distance</div>
                              <div>Weight</div>
                              <div>Rate</div>
                           </div>
                        </div> */}
                        <div className={'table-header'}>Min. Charge</div>
                        <div className={'table-header'}>Max. Charge</div>
                        <div className={'table-header weight'}>
                           <div className={'label'}>Rates</div>
                           <div className={'slab-weight'}>
                              <div>ZIP Code</div>
                              <div>Rate</div>
                           </div>
                        </div>
                        <div className={'table-header'}>Actions</div>
                     </div>
                     {odaDetails && odaDetails.length > 0 && odaDetails.map((oda, index) => {
                        return (<div className={'list-section oda-charges'} key={index}>
                           <div className={'table-row'}>
                              {oda.transporters && oda.transporters.map(transp => {
                                 return (<div style={{ 'marginTop': '5px' }} className={'align-center'}>{transp.company_name}</div>);
                              })}
                           </div>
                           {/* <div className={'table-row align-center'}>{oda.min_oda_charge}</div>
                           <div className={'table-row align-center'}>{oda.max_oda_charge}</div>
                           <div className={'table-row align-center'}>{oda.flat_rate}</div>
                           <div className={'table-row slab-weight'}>
                              {oda.distance_slab && oda.distance_slab.length > 0 && oda.distance_slab.map((distance, index) => {
                                 return (
                                    <div className={'slab-weight-data row-data'} style={{ 'marginTop': '5px' }}>
                                       <div>{`${distance.min_dist} - ${distance.max_dist}`}</div>
                                       <div>
                                          {distance.weight_slab && distance.weight_slab.length > 0 && distance.weight_slab.map((weight, ind) => {
                                             return (<Fragment>
                                                <span>{`${weight.min_weight} - ${weight.max_weight}`}</span><br />
                                             </Fragment>)
                                          })
                                          }
                                       </div>
                                       <div>
                                          {distance.weight_slab && distance.weight_slab.length > 0 && distance.weight_slab.map((weight, ind) => {
                                             return (<Fragment><span>{weight.rate}</span><br /></Fragment>
                                             )
                                          })
                                          }
                                       </div>
                                    </div>
                                 );
                              })}
                           </div> */}
                           <div className={'table-row align-center'}>{oda.min_charge}</div>
                           <div className={'table-row align-center'}>{oda.max_charge}</div>
                           <div className={'table-row slab-weight'}>
                              {oda.rates && oda.rates.length > 0 && oda.rates.map((rate, index) => {
                                 return (
                                    <div className={'slab-weight-data row-data'} style={{ 'marginTop': '5px' }} key={index}>
                                       <div>
                                          {rate.zipcode && rate.zipcode.length > 0 && rate.zipcode.map((code, ind) => {
                                             return (<Fragment>
                                                <span>{code}</span><br />
                                             </Fragment>)
                                          })
                                          }
                                       </div>
                                       <div>{rate.rate}</div>
                                    </div>
                                 );
                              })}
                           </div>
                           <div className={'table-row align-center'}>
                              <div className={'delete-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.onAddODA(24, oda)}></div>
                              <div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleDeletePayables(oda.payable_id, 24)}></div>
                           </div>
                        </div>)
                     })}
                  </div>
                  {/* <div className={'approval-btn'}>
                     <Button className={'fuel-btn'} click={() => this.onAddODA(24)} value={'Add'} />
                  </div> */}
               </div>
            </Fragment>}

            {this.state.isOpen && <EditFuel
               toggleAdd={this.toggleAdd}
               editFuel={this.state.editFuel}
               updateCreditDetails={this.props.updateCreditDetails}
            />}
            {this.state.isFreightRate && <FreightRate
               toggleAdd={this.onAddFreightRate}
               editFreightData={this.state.editFreightData}
               addCreditDetails={this.addCreditDetails}
               updateCreditDetails={this.props.updateCreditDetails}
               transporterList={this.props.transporterList}
               mainTabOption={mainTabOption} zones={zones}
               getTransporterList={getTransporterList}
            />}
            {this.state.isMinimumFreightRate && <AddMinimumFreightRate
               toggleAdd={this.onAddMinimumFreightRate}
               editMinimumFreightData={this.state.editMinimumFreightData}
               addCreditDetails={this.addCreditDetails}
               updateCreditDetails={this.props.updateCreditDetails}
               transporterList={this.props.transporterList}
               mainTabOption={mainTabOption} zones={zones}
               getTransporterList={getTransporterList}
            />}
            {this.state.isRHCCharges && <RHCCharges
               toggleAdd={this.onAddRHCCharges}
               rhcData={this.state.rhcData}
               updatePayables={this.props.updatePayables}
               addPayables={this.props.addPayables}
               transporterList={this.props.transporterList}
               getTransporterList={getTransporterList}
            />}
            {this.state.isFuelSurcharge && <FuelSurcharge
               toggleAdd={this.onAddFuelSurcharge}
               fuelSurchargeData={this.state.fuelSurchargeData}
               updatePayables={this.props.updatePayables}
               addPayables={this.props.addPayables}
               transporterList={this.props.transporterList}
               getTransporterList={getTransporterList}
            />}
            {this.state.isRASCharge && <RASCharge
               toggleAdd={this.onAddRAS}
               rasData={this.state.rasData}
               updatePayables={this.props.updatePayables}
               addPayables={this.props.addPayables}
               transporterList={this.props.transporterList}
               stateList={stateList}
               getTransporterList={getTransporterList}
               zipCodes={zipCodes}
            />}
            {this.state.isFOVCharge && <FOVCharge
               toggleAdd={this.onAddFOV}
               fovData={this.state.fovData}
               updatePayables={this.props.updatePayables}
               addPayables={this.props.addPayables}
               transporterList={this.props.transporterList}
               getTransporterList={getTransporterList}
            />}
            {this.state.isODACharge && <ODACharges
               toggleAdd={this.onAddODA}
               odaData={this.state.odaData}
               updatePayables={this.props.updatePayables}
               addPayables={this.props.addPayables}
               transporterList={this.props.transporterList}
               getTransporterList={getTransporterList}
               zipCodes={zipCodes}
            />}
            {this.state.showDelete && <DeleteCreditMaster
               toggleDelete={this.toggleDelete}
               delete={this.deleteCreditMaster}
            />}
            {this.state.showPayDelete && <DeleteCreditMaster
               toggleDelete={this.toggleDeletePayables}
               delete={this.deletePayables}
            />}
            {this.state.isAddCharge && <Detention
               toggleAdd={this.onAddCharge}
               addChargeDetails={this.props.addChargeDetails}
               editData={this.state.isEditChargeData}
               updateCreditDetails={this.props.updateCreditDetails}
               mainTabOption={this.props.mainTabOption}
               vehicleTypes={(this.props.accountData || {}).vehicleType || []}
               getConfiguredVehicles={this.props.getConfiguredVehicles}
               configuredVehicles={this.props.configuredVehicles}
               slabDetails={this.state.slabDetails}
               slabindex={this.state.slabIndex}
            />}
         </div>
      )
   }
}

export default CreditMaster;
