import React, { Component } from "react";
import Button from "../../home/button-classic/button-classic";
import DatePicker from "react-datepicker";
import Switch from "react-switch";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

class AddEditBannerPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        end_time: this.props.editData ? this.props.editData.end_time+this.props.gmtOffset  : "",
        is_active: this.props.editData ? this.props.editData.is_active : false,
        message: this.props.editData ? this.props.editData.message : "",
        module_ids: this.props.editData ? this.props.editData.module_ids: [],
        start_time: this.props.editData ? this.props.editData.start_time+this.props.gmtOffset : "",
        type: this.props.editData ? this.props.editData.type : "",
      },
      focusedInput: null,
    };
  }

  handleModuleToggle(moduleNo) {
    const updatedModuleIds = [...this.state.data.module_ids];
    const index = updatedModuleIds.indexOf(moduleNo);

    if (index !== -1) {
      updatedModuleIds.splice(index, 1);
    } else {
      updatedModuleIds.push(moduleNo);
    }
    this.setState({
      data: {
        ...this.state.data,
        module_ids: updatedModuleIds,
      },
    });
  }
  handleModuleToggleAll = (isChecked) => {
    const allModuleIds = [1, 2, 3, 4, 6];
    const updatedModuleIds = isChecked ? allModuleIds : [];
    
    this.setState({
      data: {
        ...this.state.data,
        module_ids: updatedModuleIds,
      },
    });
  };

  onChangeHandler = (event) => {
    const updatedData = {
      ...this.state.data,
      message: event.target.value,
    };

    this.setState({
      data: updatedData,
    });
  };


  onSave = () => {
    const { data } = this.state;
    this.props.onSave(data);
  };

  onUpdate = () => {
    const { data } = this.state;
    this.props.onUpdate(data, this.props.editData.msg_id);
  };
  handleDateFocusChange = (focusedInput) => {
    this.setState({ focusedInput });
  };

  render() {
    const { 
            data,
            focusedInput } = this.state;
    const { editData, 
            toggleAddEditPopup } = this.props;

    const disable = !(
      data["message"] &&
      data["is_active"] &&
      data["module_ids"] &&
      data["start_time"] &&
      data["end_time"]
    );

    return (
      <div className="popup-container121">
        <div className="popup121">
          <div className="header121">
            <h2>{`${editData ? "Edit" : "Create"} Banner Message`}</h2>
          </div>
          <div className="content121">
            <div className="form-group121">
              <label
                className="label121"
                htmlFor="message"
              >
                Message:
              </label>
              <textarea
                className="textarea121"
                id="message"
                rows="4"
                value={data["message"]}
                onChange={this.onChangeHandler}
              />
            </div>

            <div className=" flex-wrap-between ">
              <div className="form-group121">
                {!!data["is_active"] && (
                  <label
                    className="label121"
                    style={{ color: "green" }}
                  >
                    Active
                  </label>
                )}
                {!!!data["is_active"] && (
                  <label
                    className="label121"
                    style={{ color: "red" }}
                  >
                    Inactive
                  </label>
                )}
                <Switch
                  onChange={() =>
                    this.setState((prevState) => ({
                      data: {
                        ...prevState.data,
                        is_active: !prevState.data.is_active,
                      },
                    }))
                  }
                  checked={data["is_active"]}
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                />
              </div>
              <div className="form-group121">
                <label className="label121">Modules</label>
                <div className="checkbox-group121">
                <div>
                  <input 
                    type="checkbox"
                    id="selectAll"
                    checked={data["module_ids"].length === 5} 
                    onChange={(e) => this.handleModuleToggleAll(e.target.checked)}
                    /> 
                  <label htmlFor="selectAll"> Select All </label> 
                </div>
                  <div>
                    <input
                      type="checkbox"
                      id="admin"
                      checked={data["module_ids"].includes(6)}
                      onChange={() => this.handleModuleToggle(6)}
                    />
                    <label htmlFor="admin">Admin</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="contracting"
                      checked={data["module_ids"].includes(1)}
                      onChange={() => this.handleModuleToggle(1)}
                    />
                    <label htmlFor="contracting">Contracting</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="indenting"
                      checked={data["module_ids"].includes(2)}
                      onChange={() => this.handleModuleToggle(2)}
                    />
                    <label htmlFor="indenting">Indenting</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="vims"
                      checked={data["module_ids"].includes(3)}
                      onChange={() => this.handleModuleToggle(3)}
                    />
                    <label htmlFor="vims">Vims</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="recon"
                      checked={data["module_ids"].includes(4)}
                      onChange={() => this.handleModuleToggle(4)}
                    />
                    <label htmlFor="recon">Accounts</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group121">
              <label
                className="label121"
                htmlFor="startTime"
              >
                Start Time:
              </label>
              <div className="date-picker121">
                <DatePicker
                  selected={data["start_time"]}
                  onChange={(date) =>
                    this.setState({
                      data: {
                        ...data,
                        start_time: date,
                      },
                    })
                  }
                  onFocus={() => this.handleDateFocusChange("start")}
                  onBlur={() => this.handleDateFocusChange(null)}
                  showTimeSelect
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeFormat="h:mm aa"
                  timeIntervals={5}
                  minDate={new Date()}
                  minTime={new Date().getTime()}
                  maxTime={setHours(setMinutes(new Date(), 59), 23)}
                  timeCaption="Time"
                  className={`wt-220 ${focusedInput === "start" ? "focused" : ""}`}
                  
                />
              </div>
            </div>
            <div className="form-group121">
              <label
                className="label121"
                htmlFor="endTime"
              >
                End Time:
              </label>
              <div className="date-picker121">
                <DatePicker
                  selected={data["end_time"]}
                  onChange={(date) =>
                    this.setState({
                      data: {
                        ...data,
                        end_time: date,
                      },
                    })
                  }
                  onFocus={() => this.handleDateFocusChange("end")}
                  onBlur={() => this.handleDateFocusChange(null)}
                  showTimeSelect
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeFormat="h:mm aa"
                  timeIntervals={5}
                  minDate={new Date()}
                  timeCaption="Time"
                  className={`wt-220 ${focusedInput === "end" ? "focused" : ""}`}
                />
              </div>
            </div>
          </div>
          <div className="footer121">
            {!this.props.editData ? (
              <Button
                value={"Save"}
                click={this.onSave}
                disabled={disable}
              />
            ) : (
              <Button
                value={"Update"}
                click={this.onUpdate}
                disabled={disable}
              />
            )}
            <Button
              value={"Cancel"}
              click={toggleAddEditPopup}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AddEditBannerPopup;
