import React, { Component, Fragment } from "react";
import "./add-user.less";
import InputText from "../../../../common/components/input-text/input-text";
import Select from "react-select";
import validate from "../../../../../utils/validate";
import CheckBox from "../../../../components/checkbox/checkbox";
import {
  MODULE_LIST,
  LR_FOR_PANTALOON_COMPANY_ID,
  PANTALOONS_COMPANY_IDS,
  GREENPLY_COMPANY_IDS,
  isLoreal
} from "../../../../../utils/common";
import Delete from "../../../../../assets/images/delete.svg";

const approverType = [
  { value: 1, label: "30 To 100" },
  { value: 2, label: "100 To 150" },
  { value: 3, label: "150 and Above" },
];

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      mobile: "",
      email: "",
      username: "",
      password: "",
      roles: [],
      departments: [],
      warehouses: [],
      selectedRole: null,
      selectedDepartment: null,
      actionView: [],
      actions: [],
      states: [],
      selectedStateView:[],
      error: "",
      user_password: "",
      iserror: "",
      errormessage: "",
      branches: [],
      branchView: [],
      transporters:[],
      transporterView:[],
      has_Tk11_access: false,
      is_hod_user: false,
      token_rejection_approval: false,
      is_indent_admin: false,
      is_recheck_approver: false,
      is_weighment_selector: false,
      configurable_loi_period: false,
      indentEditFields:
        (props.indentEditFields || []).map((field) => {
          return {
            label: field.field_name,
            value: field.field_id,
          };
        }) || [],
      admin_field_list: [],
      indentingAdminMoves: (this.props.indentingAdminMoves || []).map(field => {
          return {
          label: field.name,
          value: field.move_id
      }}) || [],
      indent_admin_user_moves: (this.props.indentingAdminMoves || []).map(field => {
          return {
          label: field.name,
          value: field.move_id
      }}) || [],
      modules_access_list: JSON.parse(JSON.stringify(MODULE_LIST)),
      warehouse_list: [
        {
          warehouse_id: "",
          sub_plant: "",
        },
      ],
      indentingActions: (props.indentingActions || []).map((iAction) => {
        return {
          label: iAction.name,
          value: iAction.action_id,
        };
      }),
      user_indent_actions: [],
      indentingDashboards: (props.indentingDashboardListing || []).map(
        (dashboards) => {
          return {
            label: dashboards.indent_dashboard_name,
            value: dashboards.indent_dashboard_name,
          };
        }
      ),
      user_indent_dashboards: [],
    };
  }

  onChangeHandler = (value, id, isType) => {
    const result = validate.validationCheck(value, this.state[id], isType);
    const errMsg = validate.legthValidation(id, value);
    this.setState({
      [id]: result,
      errormessage: errMsg,
    });
  };

  onChangeWarehouse = (index, value, id) => {
    const { warehouse_list } = this.state;
    warehouse_list[index][id] = (value || "").toUpperCase();
    this.setState({
      warehouse_list,
    });
  };

  onBlurChange = (e) => {
    let errMsg = validate.legthValidation(e.target.id, e.target.value);
    if (errMsg && e.target.value) {
      this.setState({
        iserror: e.target.id,
        errormessage: errMsg,
      });
    } else if (this.state.iserror) {
      this.setState({ iserror: "" });
    }
  };

  passwordGenerate = () => {
    let pwd = validate.generatePassword();
    this.setState({ user_password: pwd });
  };

  handleChange = (role) => {
    this.setState({ selectedRole: role });
  };
  handleChangeAction = (action) => {
    this.setState({ actionView: action });
  };
  handleChangeDepartment = (dept) => {
    let tempRoles = [];

    dept.roles &&
      dept.roles.forEach((role) => {
        tempRoles.push({
          label: role.name,
          value: role.role_id,
        });
      });
    this.setState({
      selectedDepartment: dept,
      roles: tempRoles,
      selectedRole: tempRoles[0],
    });
  };
  handleApproverSelection = (type) => {
    this.setState({ selectedApprover: type });
  };
  verifyUser = () => {
    let { username, password, error } = this.state;
    if (username && password) {
      let data = {
        username,
        password,
      };
      this.props.verifyUser(data);
      if (error) {
        this.setState({ error: "" });
      }
    } else {
      this.setState({ error: "Please Enter The Username And Password" });
    }
  };

  handleChangeBranch = (branch) => {
    this.setState({ branchView: branch });
  };
  handleChangeTransporter = (transporter) => {
    this.setState({ transporterView: transporter });
  };

  populateActions = () => {
    return (
      <div className="actions">
        <Select
          isMulti={true}
          className="select"
          value={this.state.actionView}
          onChange={(action) => this.handleChangeAction(action)}
          options={this.state.actions}
        />
      </div>
    );
  };

  populateBranch = () => {
    return (
      <div className="actions">
        <Select
          isMulti={true}
          className="select"
          value={this.state.branchView}
          onChange={(branch) => this.handleChangeBranch(branch)}
          options={this.state.branches}
        />
      </div>
    );
  };

  populateTransporters = () => {
    const { transportList } = this.props
    const { transporters } = this.state
    console.log("transporters", transporters)
    return (
      <div className="actions">
        <Select
          isMulti={true}
          className="select"
          value={this.state.transporterView}
          onChange={(transporter) => this.handleChangeTransporter(transporter)}
          options={transporters}
        />
      </div>
    );
  };

  loadAllBranches = () => {
    let sample = this.state.branchView;
    this.setState(
      {
        branchView: sample,
      },
      () => {
        this.populateBranch();
      }
    );
  };

  loadAllTransporters = () => {
    const { transportList } = this.props
    const modifiedArray = transportList && transportList
      .filter(obj => obj.hasOwnProperty('company_id') && obj.hasOwnProperty('company_name'))
      .map(obj => {
        const newObj = { ...obj };
        newObj.label = obj.company_name;
        newObj.value = obj.company_id;
        return newObj;
      });
    this.setState(
      {
        transporters: modifiedArray,
      },
      () => {
        this.populateTransporters();
      }
    );
  };

  loadAllAction = () => {
    let sample = this.state.actionView;
    this.setState(
      {
        actionView: sample,
      },
      () => {
        this.populateActions();
      }
    );
  };

    loadAllState = () => {
    let sample = this.state.selectedStateView;
    this.setState(
      {
        selectedStateView: sample,
      }
    );
  };

  addUser = () => {
    let paramObj = {
      company_id: this.props.company_id,
      company_type: this.props.company_type,
      branch_id: this.props.branch_id,
      user_name: this.state.name,
      user_email: this.state.email,
      user_contact: this.state.mobile,
      user_password: this.state.user_password,
      department_id:
        this.state.selectedDepartment && this.state.selectedDepartment.value,
      department_name:
        this.state.selectedDepartment && this.state.selectedDepartment.label,
      role_id: this.state.selectedRole && this.state.selectedRole.value,
      role_name: this.state.selectedRole && this.state.selectedRole.label,
      has_Tk11_access: this.state.has_Tk11_access,
      is_hod_user: this.state.is_hod_user,
      token_rejection_approval: this.state.token_rejection_approval,
      is_indent_admin: this.state.is_indent_admin,
      is_recheck_approver: this.state.is_recheck_approver,
      is_weighment_selector: this.state.is_weighment_selector,
      recheck_approver_type: this.state.selectedApprover
        ? this.state.selectedApprover.value
        : undefined,
      configurable_loi_period: this.state.configurable_loi_period,
      modules_access_list: this.state.modules_access_list,
      admin_field_list: this.state.admin_field_list.map((field) => {
        return {
          field_name: field.label,
          field_id: field.value,
        };
      }),
      actions: this.state.actionView.map((action) => {
        return {
          id: action.value,
          name: action.label,
        };
      }),
      states:this.state.selectedStateView.map((elm)=>{
            // console.log("elem",elm)
            return {
               state: elm.label,
               state_code: elm.value
            }
      }),
      branches: this.state.branchView.map((branch) => {
        return {
          branch_id: branch.value,
          branch_name: branch.label,
          branch_code: branch.branch_code,
        };
      }),
      transporters: this.state.transporterView.map((transporter) => {
        return {
          transporter_id: transporter.value,
          transporter_name: transporter.label,
        };
      }),
      sap_username: this.state.username,
      sap_password: this.state.password,
      is_sap_verified: this.props.is_verified,
      warehouse_list:
        ((this.state.warehouse_list || []).filter(
          (wh) => wh.warehouse_id && wh.sub_plant
        ).length > 0 &&
          this.state.warehouse_list) ||
        undefined,
      user_indent_actions: this.state.user_indent_actions || [],
      user_indent_dashboards: this.state.user_indent_dashboards || [],
      report_access: this.state.report_access,
      indenting_reports: this.state.indenting_reports || [],
      indenting_dashboard: this.state.indenting_dashboard || [],
      indent_admin_user_moves: this.state.indent_admin_user_moves.map((field) => {
        return {
          move_name: field.label,
          move_id: field.value,
        };
      }),
    };
    let errorMsg = "";
    const validationError = {
      Name: validate.isEmpty(paramObj.user_name),
      Email: !validate.email(paramObj.user_email),
      Phone: !validate.numeric(paramObj.user_contact),
      "User Password": validate.isEmpty(paramObj.user_password),
      Department: validate.isEmpty(paramObj.department_name),
      Role: validate.isEmpty(paramObj.role_name),
    };
    const validationFields = [];
    Object.keys(validationError).forEach((key) => {
      if (validationError[key]) {
        validationFields.push(key);
      }
    });

    if (validationFields.length) {
      errorMsg = `Please Enter Valid ${validate.join(
        validationFields,
        (item, idx) => {
          if (validationFields.length === 1) {
            return "";
          }
          if (idx > 0 && idx < validationFields.length - 1) {
            return ", ";
          } else if (idx === validationFields.length - 1) {
            return " and ";
          }
          return "";
        }
      )}.`;
    }
    let myDiv = document.getElementById("modal");
    if (errorMsg) {
      myDiv.scrollTop = 0;
      this.setState({
        error: errorMsg,
      });
      return;
    }

    if (!(paramObj.actions[paramObj.actions.length - 1] || {}).name) {
      myDiv.scrollTop = 0;
      this.setState({
        error: "Actions Cannot Be Empty, Make Sure You Have Selected!",
      });
      return;
    }

    if (!paramObj.branches[paramObj.branches.length - 1]) {
      myDiv.scrollTop = 0;
      this.setState({
        error: "Branch Cannot Be Empty, Make Sure You Have Selected!",
      });
      return;
    }

    this.props.addUser(paramObj);
    this.props.toggleAdd();
  };

  componentDidMount() {
    const {transportList}=this.props
    this.loadAllAction();
    this.loadAllBranches();
	  this.loadAllTransporters()
    this.loadAllState()
  }
  componentWillMount() {
    let tempDepartments = [];
    let tempAction = [];
    let tempBranch = [];
    let tempWarehouse = [];
    let tempstateList = []
    this.props.departments &&
      this.props.departments.forEach((dept) => {
        tempDepartments.push({
          label: dept.department_name,
          value: dept.department_id,
          roles: dept.roles,
        });
      });
    this.props.actions &&
      this.props.actions.forEach((action) => {
        tempAction.push({
          label: action.action_name,
          value: action.id,
        });
      });

    this.props.allPlantBranches &&
      this.props.allPlantBranches.forEach((branch) => {
        tempBranch.push({
          label: branch.branch_name,
          value: branch.branch_id,
          branch_code: branch.branch_code,
        });
      });
    tempWarehouse =
      this.props.warehouseList &&
      (this.props.warehouseList || []).map((warehouse) => ({
        label: warehouse,
        value: warehouse,
      }));
       this.props.stateList && this.props.stateList.forEach(obj => {
         tempstateList.push(
            {
               label: obj.name,
               value: obj.code,
            }
         )
      });
    this.setState({
      departments: tempDepartments,
      actions: tempAction,
      branches: tempBranch,
      warehouses: tempWarehouse,
      states:tempstateList
    });
  }

  onSelectAll = () => {
    let { isAllSelected, modules_access_list } = this.state;
    isAllSelected = !isAllSelected;
    modules_access_list.map((val) => {
      val.enable = isAllSelected;
    });
    this.setState({ isAllSelected, modules_access_list });
  };

  onSelectModule = (index) => {
    let { modules_access_list, isAllSelected } = this.state;
    modules_access_list[index].enable = !modules_access_list[index].enable;
    if (isAllSelected) {
      isAllSelected = false;
    } else {
      isAllSelected =
        modules_access_list.filter((val) => {
          return val.enable;
        }).length === modules_access_list.length;
    }
    this.setState({ modules_access_list, isAllSelected });
  };

  addWarehouse = () => {
    const { warehouse_list } = this.state;
    warehouse_list.push({
      warehouse_id: "",
      sub_plant: "",
    });
    this.setState({ warehouse_list });
  };

  deleteWarehouse = (index) => {
    const { warehouse_list } = this.state;
    warehouse_list.splice(index, 1);
    this.setState({ warehouse_list });
  };

  handleReportsDashboard = (el, field) => {
    this.setState({
      [field]: el,
    });
  };

  getTypes = () => {
    const { slabRange, company_id } = this.props;
    const isAdaniWilmar = ["999", "872"].includes(company_id);
    let approverTypes = [];
    if (isAdaniWilmar) {
      (slabRange || []).forEach((slab) => {
        approverTypes.push({
          value: slab.range_id,
          label: `${slab.lowerlimit} To ${slab.upperlimit}`,
        });
      });
    } else {
      approverTypes = approverType;
    }
    return approverTypes;
  };

   handleStateChange(state) {
    this.setState({ selectedStateView: state });
   }

  render() {
    const buttonDisable =
      this.state.username && this.state.password
        ? "add-button"
        : "add-button btn-disabled";
    const { indentEditFields, reportsDashboardsList } = this.props;
    const isAdaniWilmar = ["999", "872"].includes(this.props.company_id);
    const isPantaloons =
      LR_FOR_PANTALOON_COMPANY_ID.includes(this.props.company_id) ||
      PANTALOONS_COMPANY_IDS.includes(this.props.company_id);
    const isGreenPly = GREENPLY_COMPANY_IDS.includes(this.props.company_id);
    const isMovesPresent = (this.state.admin_field_list || []).find((moves) => { return moves.value == 5})
    return (
      <div className="add-user-wrap">
        <div onClick={this.props.toggleAdd} className="overlay"></div>
        <div id="modal" className="modal-popup">
          <div className="pop-heading">Add User</div>
          <div className="content add-edit-user">
            <label className="error">{this.state.error}</label>
            <form className="form-section" autoComplete="nope">
              <InputText
                placeholder="Name"
                id={"name"}
                label="Name"
                value={this.state.name}
                changeHandler={this.onChangeHandler}
              />
              <InputText
                placeholder="Email"
                id={"email"}
                label="Email"
                value={this.state.email}
                changeHandler={this.onChangeHandler}
                additionalProps={{ onBlur: this.onBlurChange }}
                iserror={this.state.iserror}
                errormessage={this.state.errormessage}
              />
              <InputText
                placeholder="Mobile No"
                id={"mobile"}
                label="Mobile No"
                value={this.state.mobile}
                changeHandler={this.onChangeHandler}
                length={"10"}
                isValidate={"numeric"}
                additionalProps={{ onBlur: this.onBlurChange }}
                iserror={this.state.iserror}
                errormessage={this.state.errormessage}
              />
              <InputText
                placeholder="Password"
                id={"user_password"}
                label="Password"
                value={this.state.user_password}
                changeHandler={this.onChangeHandler}
                isGenerate={"true"}
                additionalProps={{ onBlur: this.onBlurChange }}
                generate={this.passwordGenerate}
                iserror={this.state.iserror}
                errormessage={this.state.errormessage}
              />
            </form>
            <div className="select-container">
              <div className="select-holder">
                <span className="sub-head">Departments</span>
                <Select
                  value={this.state.selectedDepartment}
                  onChange={this.handleChangeDepartment}
                  options={this.state.departments}
                />
              </div>
              <pre> </pre>
               {isLoreal() && (
                  <div className="action-holder">
                    <span className="sub-head">Region/State</span>
                    <div className="actions">
                      <Select
                        className="select"
                        isMulti={true}
                        options={this.state.states}
                        value={this.state.selectedStateView}
                        onChange={(state) => this.handleStateChange(state)}
                      />
                    </div>
                    </div>
                    )}
              <div className="action-holder">
                <span className="sub-head">Branches</span>
                {this.populateBranch()}
              </div>
              {isAdaniWilmar &&
                <div className="action-holder">
                  <span className="sub-head">Transporters</span>
                  {this.populateTransporters()}
                </div>
              }
              <div className="select-holder">
                <span className="sub-head">Role</span>
                <Select
                  value={this.state.selectedRole}
                  onChange={this.handleChange}
                  options={this.state.roles}
                />
              </div>
              <pre> </pre>
            </div>
            <div className="select-container action-check">
              <div className="action-holder">
                <span className="sub-head">Actions</span>
                {this.populateActions()}
              </div>
              <div className="action-holder tk11-dashboard">
                <CustomCheckBox
                  id="has_Tk11_access"
                  selected={this.state.has_Tk11_access}
                  toggle={() => {
                    this.setState({
                      has_Tk11_access: !this.state.has_Tk11_access,
                    });
                  }}
                />
                <div className="title"> TK11 Dashboard</div>
              </div>
              <div className="action-holder tk11-dashboard">
                <CustomCheckBox
                  id="token_rejection_approval"
                  selected={this.state.token_rejection_approval}
                  toggle={() => {
                    this.setState({
                      token_rejection_approval: !this.state
                        .token_rejection_approval,
                    });
                  }}
                />
                <div className="title">Reject Token</div>
              </div>
              <div className="action-holder tk11-dashboard">
                <CustomCheckBox
                  id="is_hod_user"
                  selected={this.state.is_hod_user}
                  toggle={() => {
                    this.setState({ is_hod_user: !this.state.is_hod_user });
                  }}
                />
                <div className="title small-title">HOD</div>
              </div>
            </div>

            <div className="select-container action-check">
              <div className="action-holder tk11-dashboard small-actions">
                <CustomCheckBox
                  id="is_indent_admin"
                  selected={this.state.is_indent_admin}
                  toggle={() => {
                    this.setState({
                      is_indent_admin: !this.state.is_indent_admin,
                    });
                  }}
                />
                <div className="title small-title">Admin</div>
              </div>
              {this.state.is_indent_admin && (
                <div className="action-holder">
                  <span className="sub-head">Indenting Fields</span>
                  <div className="actions">
                    <Select
                      isMulti={true}
                      className="select"
                      value={this.state.admin_field_list}
                      onChange={(options) => {
                        this.setState({ admin_field_list: options });
                      }}
                      options={this.state.indentEditFields}
                    />
                  </div>
                </div>
              )}
              {isMovesPresent && isGreenPly && this.state.is_indent_admin && <div className="action-holder">
                  <span className="sub-head">Indenting Moves</span>
                  <div className="actions" >
                        <Select isMulti={true} className="select" value={this.state.indent_admin_user_moves} onChange={(options) => {
                            this.setState({ indent_admin_user_moves: options });
                        } } options={this.state.indentingAdminMoves} />
                  </div>
                </div>
              }
              <div
                className="action-holder tk11-dashboard small-actions"
                style={{ width: "200px" }}
              >
                <CustomCheckBox
                  id="is_indent_admin"
                  selected={this.state.configurable_loi_period}
                  toggle={() => {
                    this.setState({
                      configurable_loi_period: !this.state
                        .configurable_loi_period,
                    });
                  }}
                />
                <div className="title small-title">LOI Acceptance Period</div>
              </div>
              <div className="action-holder tk11-dashboard small-actions">
                <CustomCheckBox
                  id="is_recheck_approver"
                  selected={this.state.is_recheck_approver}
                  toggle={() => {
                    this.setState({
                      is_recheck_approver: !this.state.is_recheck_approver,
                    });
                  }}
                />
                <div className="title small-title">Recheck Approval</div>
              </div>

              <div className="action-holder tk11-dashboard small-actions">
                <CustomCheckBox
                  id="is_weighment_selector"
                  selected={this.state.is_weighment_selector}
                  toggle={() => {
                    this.setState({
                      is_weighment_selector: !this.state.is_weighment_selector,
                    });
                  }}
                />
                <div className="title small-title">Weighment Selector</div>
              </div>
            </div>

            <div className="select-container action-check">
              <div className="action-holder" style={{ width: "270px" }}>
                <span className="sub-head">Indenting Actions</span>
                <div className="actions">
                  <Select
                    isMulti
                    className="select"
                    value={(this.state.indentingActions || []).filter((el) =>
                      this.state.user_indent_actions.includes(el.value)
                    )}
                    onChange={(options) => {
                      console.log(
                        "data",
                        options,
                        (options || []).map((el) => {
                          return el.value;
                        })
                      );
                      this.setState({
                        user_indent_actions: (options || []).map((el) => {
                          return el.value;
                        }),
                      });
                    }}
                    options={this.state.indentingActions}
                  />
                </div>
              </div>
              {(isAdaniWilmar || isPantaloons) && (
                <div className="action-holder" style={{ width: "270px" }}>
                  <span className="sub-head">Indenting Dashboard</span>
                  <div className="actions">
                    <Select
                      isMulti
                      className="select"
                      value={(
                        this.state.indentingDashboards || []
                      ).filter((el) =>
                        this.state.user_indent_dashboards.includes(el.value)
                      )}
                      onChange={(options) => {
                        this.setState({
                          user_indent_dashboards: (options || []).map((el) => {
                            return el.value;
                          }),
                        });
                      }}
                      options={this.state.indentingDashboards}
                    />
                  </div>
                </div>
              )}
              {this.state.is_recheck_approver && (
                <div className="action-holder">
                  <span className="sub-head">Approver Type</span>
                  <div className="actions">
                    <Select
                      className="select"
                      value={this.state.selectedApprover}
                      onChange={this.handleApproverSelection}
                      options={this.getTypes()}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="select-container action-check">
              <div className="action-holder tk11-dashboard small-actions">
                <CustomCheckBox
                  id="report_access"
                  selected={this.state.report_access}
                  toggle={() => {
                    this.setState({ report_access: !this.state.report_access });
                  }}
                />
                <div className="title small-title">Report Access</div>
              </div>
              {this.state.report_access && (
                <>
                  <div className="action-holder">
                    <span className="sub-head">Reports</span>
                    <div className="actions">
                      <Select
                        isMulti={true}
                        className="select"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        value={this.state.indenting_reports}
                        onChange={(el) =>
                          this.handleReportsDashboard(el, "indenting_reports")
                        }
                        options={(reportsDashboardsList || {}).reports || []}
                      />
                    </div>
                  </div>
                  <div className="action-holder">
                    <span className="sub-head">Dashboards</span>
                    <div className="actions">
                      <Select
                        isMulti={true}
                        className="select"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        value={this.state.indenting_dashboard}
                        onChange={(el) =>
                          this.handleReportsDashboard(el, "indenting_dashboard")
                        }
                        options={(reportsDashboardsList || {}).dashboards || []}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="select-container action-check">
              <span className="sub-head">Module Config.</span>
            </div>
            <div className="select-container action-check">
              {this.state.modules_access_list.length > 0 && (
                <div className="action-holder tk11-dashboard">
                  <CustomCheckBox
                    selected={this.state.isAllSelected}
                    toggle={this.onSelectAll}
                  />
                  <div className="title small-title">All</div>
                </div>
              )}
              {(this.state.modules_access_list || []).map(
                (moduleList, index) => {
                  return (
                    <div
                      className="action-holder tk11-dashboard"
                      key={`module_${index}`}
                    >
                      <CustomCheckBox
                        id={index}
                        selected={moduleList.enable}
                        toggle={this.onSelectModule}
                      />
                      <div className="title small-title">
                        {moduleList.module_name}
                      </div>
                    </div>
                  );
                }
              )}
            </div>

            {(this.state.warehouse_list || []).map((wh, index) => (
              <Fragment>
                {index === 0 && (
                  <div className="select-container action-check ph-10">
                    <span className="sub-head">Warehouse Mapping</span>
                  </div>
                )}
                <div className="select-container action-check">
                  <InputText
                    placeholder="Warehouse"
                    id={"warehouse_id"}
                    label="Warehouse"
                    value={wh.warehouse_id}
                    changeHandler={this.onChangeWarehouse.bind(this, index)}
                  />
                  <InputText
                    placeholder="Sub Plant"
                    id={"sub_plant"}
                    label="Sub Plant"
                    value={wh.sub_plant}
                    changeHandler={this.onChangeWarehouse.bind(this, index)}
                  />
                  <div
                    className={"delete-icon mt-5"}
                    style={{ backgroundImage: `url(${Delete})` }}
                    onClick={() => this.deleteWarehouse(index)}
                  ></div>
                  <button
                    className={"add-button mt-5"}
                    onClick={this.addWarehouse}
                  >
                    + Add
                  </button>
                </div>
              </Fragment>
            ))}

            <div className="select-container">
              <InputText
                placeholder="SAP Username"
                id={"username"}
                label="SAP Username"
                value={this.state.username}
                changeHandler={this.onChangeHandler}
              />
              <InputText
                placeholder="SAP Password"
                id={"password"}
                label="SAP Password"
                value={this.state.password}
                changeHandler={this.onChangeHandler}
              />
              <button className={buttonDisable} onClick={this.verifyUser}>
                Verifiy User
              </button>
            </div>

            <div className="submit-section">
              <button
                className="add-button discard"
                onClick={this.props.toggleAdd}
              >
                Cancel
              </button>
              <button className="add-button" onClick={this.addUser}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const CustomCheckBox = (props) => {
  return (
    <div
      className={
        props.selected
          ? "custome-checkbox-wrap selected"
          : "custome-checkbox-wrap"
      }
      onClick={() => props.toggle(props.id)}
    >
      {props.selected && <div className="inner-circle"></div>}
    </div>
  );
};

export default AddUser;
