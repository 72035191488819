import React, { Component } from 'react';
import InputText from "../../../../common/components/input-text/input-text";
import Select from 'react-select';
import validate from "../../../../../utils/validate";
import "../../home-navigation-panel.less";


class AddAction extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			company_id: "",
			selectCompany: null,
			error: "",
			iserror: "",
			errormessage: ""
		}
	}

	onChangeHandler = (value, id, isType) => {
		const result = validate.validationCheck(value, this.state[id], isType);
		const errMsg = validate.legthValidation(id, value);
		this.setState({
			[id]: result,
			errormessage: errMsg
		})
	}

	handleChange = (selectCompany) => {
		this.setState({ selectCompany: selectCompany });
	}

	onBlurChange = (e) => {
		let errMsg = validate.legthValidation(e.target.id, e.target.value);
		if (errMsg && e.target.value) {
			this.setState({
				iserror: e.target.id,
				errormessage: errMsg
			})
		} else if (this.state.iserror) {
			this.setState({ iserror: '' });
		}
	}

	addAction = () => {
		let paramObj = {
			"company_id": this.props.company_id,
			"name": this.state.name,
			"type": this.state.selectCompany.type,
		}
		let errorMsg = "";
		const validationError = {
			"Name": validate.isEmpty(paramObj.name),
		}
		const validationFields = [];
		Object.keys(validationError).forEach((key) => {
			if (validationError[key]) {
				validationFields.push(key);
			}
		});
		if (validationFields.length) {
			errorMsg = `Please Enter Valid ${validate.join(validationFields, (item, idx) => {
				if (validationFields.length === 1) {
					return "";
				}
				if (idx > 0 && idx < validationFields.length - 1) {
					return ", ";
				} else if (idx === validationFields.length - 1) {
					return " and ";
				}
				return "";
			})}.`;
		}
		let myDiv = document.getElementById('modal');
		if (errorMsg) {
			myDiv.scrollTop = 0;
			this.setState({
				error: errorMsg
			});
			return;
		}
		this.props.addAction(paramObj);
		this.props.toggleAdd2();
	}
	populateActiontypeslist = () => {
		return (
			this.props.actiontypeslist.map((company) => {
				return {
					value: company.id,
					label: company.name,
					type: company.type
				}
			}
			)
		)
	}

	render() {
		const buttonDisable = (this.state.name) ? 'add-button' : 'add-button btn-disabled';
		const options1 = this.props.actiontypeslist && this.populateActiontypeslist();
		return (
			<div className="add-user-wrap add-action-wrap-popup">
				<div onClick={this.props.toggleAdd2} className="overlay"></div>
				<div id="modal" className="modal-popup">
					<div className="pop-heading">
						Add Action
                    </div>
					<div className="content">
						<label className="error">{this.state.error}</label>
						<form className="form-section" autoComplete="nope">
							<div className="container-1">
								<div>
									<div className="header">Name</div>
									<div className="sub-container">
										<InputText placeholder="Name" id={"name"} value={this.state.name} changeHandler={this.onChangeHandler} />
									</div>
								</div>
								<div>
									<div className="header">Type</div>
									<div className="sub-container">
										<Select className="select" value={this.state.selectCompany} onChange={this.handleChange} options={options1} />
									</div>
								</div>
							</div>
						</form>
						<div className="submit-section">
							<button className="add-button discard" onClick={this.props.toggleAdd2}>Cancel</button>
							<button className="add-button" onClick={this.addAction}>Save</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AddAction;