import React, { Component } from "react";
import "./fuel-escalation-details.less";
import Async from "react-select/lib/Async";
import APIURL from "../../../common/api_config";
import { getCookie } from "../../../../utils/cookies";
import $ from "jquery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

function getToken() {
  let data = getCookie("user") ? getCookie("user") : "";
  let token = data ? JSON.parse(data).token : "";
  return token;
}

class FuelEscalationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      selectedOption: {},
      escalationVal: "",
      startDate : null,
      endDate : null
    };
  }
  changeHandler = value => {
    this.setState({ selectedOption: value });
  };
  updateType = val => {
    this.setState({ inputValue: val });
  };
  filterData = list => {
    let options = [];
    if (list && list.length > 0) {
      options = list.map(function(rfq) {
        return {
          value: rfq.rfq_id,
          label: rfq.rfq_name,
          number: rfq.rfq_number
        };
      });
    }
    return options;
  };
  search = string => {
    const branch_id = "6f4276c3a4ba42dfa41c7b8e13f339ae";
    this.setState({
      inputValue: string
    });
    return new Promise((resolve, reject) => {
      $.ajax({
        url: APIURL.getRFQ + "branchId=" + branch_id + "&search=" + string,
        headers: {
          token: getToken()
        },
        success: data => {
          resolve(this.filterData(data.data.list));
        },
        error: error => {
          reject(error);
        }
      });
    });
  };
  handleChangeDate = (id, date) => {
    this.setState({
      [id]: date
    });
  };
  validation = () =>{
    if(!this.state.selectedOption.label || !this.state.startDate  || !this.state.endDate  || this.state.escalationVal == ""){
      this.props.showMsgPopup("Please Enter All The Fields")
      return false;
    }
    return true;
  }
  addfuelEscalation = () => {
    if(this.validation()){
      const data = {
        company_id: this.props.company_id,
        branch_id: this.props.branch_id,
        rfq_name: this.state.selectedOption.label,
        rfq_id: this.state.selectedOption.value,
        rfq_number: this.state.selectedOption.number,
        from: new Date(this.state.startDate).getTime(),
        to: new Date(this.state.endDate).getTime(),
        escalation_percent: this.state.escalationVal,
        alert_type: 5
      };
      this.setState({
        startDate : "",
        escalationVal: "",
        endDate : ""
      })
      this.props.createCollapsibleSection(data);
    }
  };
  inputChangeHandler = e => {
    this.setState({
      escalationVal: e.target.value
    });
  };
  deleteCollapsibleSection = (id) =>{
    const data = {
      id : id,
      branch_id : this.props.branch_id
    }
    this.props.deleteCollapsibleSection(data,5)
  }
  render() {
    return (
      <div className="fuel-escalation-wrap">
        <div>
          <Async
            name="typeId"
            className="select-rfq"
            inputValue={this.state.inputValue}
            isClearable={true}
            cacheOptions={true}
            defaultOptions={true}
            isSearchable={true}
            loadOptions={this.search}
            onInputChange={this.updateType}
            onChange={this.changeHandler}
            placeholder={"RFQ no"}
          />
          <MyDatePicker
            label={"From"}
            id="startDate"
            startDate={this.state.startDate}
            handleChange={this.handleChangeDate}
          />
          <MyDatePicker
            label={"To"}
            id="endDate"
            startDate={this.state.endDate}
            handleChange={this.handleChangeDate}
          />
          <div className="input-section-wrap">
            <div className="label">Escalation percent (%)</div>
            <div className="input-section">
              <input
                className="input"
                value={this.state.escalationVal}
                onChange={this.inputChangeHandler}
              />
            </div>
          </div>
          <div className="add-button" onClick={this.addfuelEscalation}>
            + ADD
          </div>
        </div>
        <div className="list-wrap-fuel">
          <div className="list-fuel header">
            <div className="rfq-id">RFQ name</div>
            <div className="from">From</div>
            <div className="to">To</div>
            <div className="escalation-val">Escalation percent (%)</div>
            <div className="action">Action</div>
          </div>
          {this.props.escalationData &&
            this.props.escalationData.length > 0 &&
            this.props.escalationData.map((data,index) => {
              return (
                <div key = {index} className="list-fuel">
                  <div className="rfq-id">{data.rfq_name}</div>
                  <div className="from">{moment(data.from).format('DD-MMM-YYYY')}</div>
                  <div className="to">{moment(data.to).format('DD-MMM-YYYY')}</div>
                  <div className="escalation-val">{data.escalation_percent}</div>
                  <div className="action" onClick = {()=>this.deleteCollapsibleSection(data.id)}>Delete</div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

class MyDatePicker extends Component {
  render() {
    return (
      <div className="data-picker">
        <div className="label">{this.props.label}</div>
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={this.props.startDate}
          onChange={this.props.handleChange.bind(this, this.props.id)}
        />
      </div>
    );
  }
}

export default FuelEscalationDetails;
