import React, { Component, Fragment } from 'react';
import UpArrow from '../../../assets/images/incr.svg';
import DownArrow from '../../../assets/images/decr.svg';
import "./hsd-lanes-popup.less";
import Select from "react-select";
import Button from '../../home/button-classic/button-classic';
import ACTION from "../../common/action_constant";
import { getEmail, getUserCompanyId, getUserId } from '../../../utils/common';
import { connect } from 'react-redux';

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

class HSDLanesPopup extends Component{
    constructor(props){
        super(props);
            this.state= {
                vendor: {},
                selectedHSDLanes: this.props.selectedHSDLanes || [],
                isAllSelect: false
            }
        }

        handleHSDLanesSelection = (id) => {
            const { selectedHSDLanes } = this.state;
            const { hsdLaneData } = this.props;
            let selectedHSDLanesCopy = [...selectedHSDLanes];
            if (selectedHSDLanesCopy.includes(id)) {
                selectedHSDLanesCopy.splice(id, 1);
            } else {
                selectedHSDLanesCopy.push(id);
            }
            let allLRs = (selectedHSDLanesCopy.length === hsdLaneData.length);
            this.setState({selectedHSDLanes: selectedHSDLanesCopy, isAllSelect: allLRs});
        }

    selectAllLanes = (value) => {
        const { selectedHSDLanes } = this.state;
        const { hsdLaneData } = this.props;
        let selectedHSDLanesCopy = [...selectedHSDLanes];
        if(value){
            hsdLaneData.map((lane) => { 
                let approval = this.checkApproval(lane);
                if(!approval){
                    return (
                        selectedHSDLanesCopy.includes(lane.hsd_lane_id) ?
                        selectedHSDLanesCopy : 
                        selectedHSDLanesCopy.push(lane.hsd_lane_id )
                    )
                }
            });
        }else{
            selectedHSDLanesCopy = [];
        }
        this.setState({selectedHSDLanes: selectedHSDLanesCopy, isAllSelect: value})
    }

    checkApproval = (lanes) => {
        const date = new Date().getDate();
        let approved = false;
        // (this.props.months || []).map((dt) => {
            const month = new Date().getMonth() + 1; 
            const year = new Date().getFullYear();
            const mth = (lanes.approvals || []).filter(m => parseInt(m.approved_year) === parseInt(year) && parseInt(m.approved_month)===parseInt(month));
            if (mth.length > 0 && parseInt(month)===parseInt(mth[0].approved_month)) {
                if(date > 16){
                    approved =  mth[0].is_last_fortnight_approved;
                } else {
                    approved =  mth[0].is_first_fortnight_approved;
                }
            } else {
                approved = false;
            }
        // });
        return approved;
    }
    uploadHSD = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      this.uploadHSDFile(formData);
    }
  };
  uploadHSDFile = (formData) => {
        const { dispatch,selectedHSD } = this.props;
        const params={
            company_id:getUserCompanyId(),
            userId:getUserId(),
            userEmail:getEmail(),
        }
        dispatch({
            type: ACTION.DATA_GOVERNANCE.UPLOAD_HSD_DETAILS, 
            file: formData,
            params, 
            onSuccess: () => {
                let params = `?rfqId=${selectedHSD.rfq_id}`
                dispatch({
                    type: ACTION.DATA_GOVERNANCE.HSD_CONTRACT_LANE_DETAILS,
                    params,
                })
            }
        });
    }
    uploadMC = () => {
        this.uploadExcel.click();
      };

      getIsApproval=(data)=>{
        const {cycleType} = this.props;
        const today = new Date().getMonth();
        if(cycleType === 2){
            return (Array.isArray(data.approvals) && data.approvals.length>0  && data.approvals[data.approvals.length - 1].approved_month !== today)
        }
        return this.checkApproval(data)
      }

    render() {
        const { hsdLaneData, cycleType, contract, isSapDPL } = this.props;
        const { vendor, selectedHSDLanes } = this.state;
        const currentMonth = new Date();
        const month = (this.props.months.filter(mt => {return  mt.getMonth() === currentMonth.getMonth() && mt.getFullYear() === currentMonth.getFullYear()}))
        let isApproval = false;
        return (
            <Fragment>
                <div className="add-user-wrap">
                    <div className="overlay"></div>
                        <div id="modal" className="modal-popup" style={{width: '95%'}}>
                        
                                    <div className="heading" style={{color: '#528aea'}}> {contract.rfq_name}
                                    {selectedHSDLanes && selectedHSDLanes.length > 0 &&
                                            <button className={"close-button"} onClick={() => this.props.approveRates(selectedHSDLanes, contract, vendor)}>Generate Rate</button>
                                        }
                                        
                                        {/* <button className={"close-button"} onClick={(value, id) => props.downloadVendorLanes()}>Download</button> */}
                                        <button className={"close-button"} onClick={() => this.props.closePopup()}>Close</button>
                                    </div>
                                    <div className="divTableCell" style={{float: "right", width: '300px'}}>
                                            <div className="divTableCell"> Vendor </div>
                                            <Select
                                                className="select wt-100p"
                                                placeholder={'Search Vendor'}
                                                options={this.props.vendorTypes || []}
                                                value={(this.props.vendorTypes || []).filter(obj => {return obj.label===vendor.vendor_name})}
                                                onChange={(value) => { 
                                                    const { vendor } = this.state;
                                                    vendor.vendor_name = value.label;
                                                    vendor.vendor_id = value.value;
                                                    this.setState({ vendor }, this.props.showHSDLanes(contract,vendor));
                                                }}
                                            />
                                        </div>
                                    <div className="content p-t-0">
                                    <div className="divTableCell" style={{display: 'inherit', padding: '2px'}}>BR: Base Rate</div>
                                    <div className="divTableCell" style={{display: 'inherit', padding: '2px'}}>PR: Previous Rate</div>
                                    <div className="divTableCell" style={{display: 'inherit', padding: '2px'}}>FF: First Fortnight (1st-15th)</div>
                                    <div className="divTableCell" style={{display: 'inherit', padding: '2px'}}>SF: Second Fortnight (16th - 30th/31st)</div>
                                    <div className="divTableCell" style={{display: 'inherit', padding: '2px'}}>ADP: Approved Diesel Price</div>
                                    <div className="divTableCell" style={{display: 'inherit', padding: '2px'}}>MRG: Minimum Rate Guarantee</div>
                                    <div className='flex justify-end'>
                                        <Button value={'Download Temp.'} click={() => this.props.downloadHsd(false)} />
                                        <a href={this.state.downloadUrl} style={{ display: 'none' }} ref={(e) => { this.downloadLink = e; }} />
                                        <Button value={'Download Details'} click={() => this.props.downloadHsd(true)} />
                                        <a href={this.state.downloadUrl} style={{ display: 'none' }} ref={(e) => { this.downloadLink = e; }} />
                                        <Button value={'Upload'} click={this.uploadMC} />
                                            <input
                                                type="file"
                                                style={{ display: "none" }}
                                                ref={(e) => {
                                                    this.uploadExcel = e;
                                                }}
                                                onChange={this.uploadHSD}
                                                onClick={(e) => {
                                                    e.target.value = null;
                                                }}
                                                accept=".xlsx"
                                            />
                                    </div>
                                    <div className="divTable company-list-wrap hsd-checkbox">
                                        <div className="list-section table-header divTableRow" style={{backgroundColor: '#e9f2ff'}}>
                                            <div className="divTableCell tCenter" style={{display: 'inline-flex'}}>
                                                <input type="checkbox" checked={this.state.isAllSelect} style={{float: 'left', marginRight: '2px'}} onClick={(e) => { this.selectAllLanes(e.target.checked) }} />
                                                    VENDOR
                                                </div>
                                            <div className="divTableCell tCenter" style={{width: '10%'}}> VENDOR CODE </div>
                                            <div className="divTableCell tCenter" style={{width: '10%'}}> FROM </div>
                                            <div className="divTableCell tCenter" style={{width: '10%'}}> TO </div>
                                            {isSapDPL &&
                                            <>
                                                <div className="divTableCell tCenter" style={{width: '10%'}}> ZONE </div>
                                                <div className="divTableCell tCenter" style={{width: '10%'}}> ZONE AVG </div>
                                            </>}
                                            <div className="divTableCell tCenter" style={{width: '10%'}}> PRODUCT  </div>
                                            <div className="divTableCell tCenter" style={{width: '10%'}}> HSD Inc/Dec(Rs) </div>
                                            <div className="divTableCell tCenter" style={{width: '10%'}}> VEHICLE TYPE </div>
                                            <div className="divTableCell tCenter" style={{width: '10%'}}> CARRYING CAPACITY </div>
                                            <div className="divTableCell tCenter" style={{width: '10%'}}> MILAGE  </div>
                                            <div className="divTableCell tCenter" style={{width: '10%'}}> MRG  </div>
                                            {(this.props.months || []).map((dt, index) => {
                                                const monthNumber = new Date(dt);
                                                const mm = new Date(monthNumber).getMonth(); 
                                                const yyyy = new Date(monthNumber).getFullYear();
                                                return (
                                                    <Fragment>
                                                        
                                                        <div className="divTableCell tCenter" key={`dt-${(index + 1)}`}> {monthNames[mm]} - {yyyy} 
                                                    
                                                        <div className="divTableCell" style={{width: '5%'}}> BR </div>
                                                        <div className="divTableCell" style={{width: '5%'}}> PR </div>
                                                        <div className="divTableCell" style={{width: '5%'}}> DIFF  </div>
                                                        <div className="divTableCell" style={{width: '5%'}}> ADP  </div>
                                                        {cycleType === 1 ? 
                                                            <Fragment>
                                                                <div className="divTableCell" style={{width: '5%'}}> FF </div>
                                                                <div className="divTableCell" style={{width: '5%'}}> SF </div>
                                                            </Fragment>
                                                            :
                                                            <div className="divTableCell" style={{width: '5%'}}> HSD </div>}
                                                        {/* <div className="divTableCell"> ACTION </div> */}
                                                        </div>
                                                    </Fragment>
                                                         
                                                )})}
                                           
                                        </div>
                                        <div className="divTableBody">
                                        {(hsdLaneData || []).map((data, index) => {
                                        isApproval = this.getIsApproval(data); 
                                        let product = [];
                                        if(isSapDPL){
                                            (data.product_category_multiple || []).forEach((prd, indexPrd) => {
                                                product.push(prd.name)
                                            })
                                        } 
                                        return (
                                            <Fragment>
                                            <div key={index} className="list-section divTableRow hsd-checkbox">
                                                <div className="divTableCell" style={{display: 'inline-flex'}}> 
                                                    {!isApproval && <input
                                                        type="checkbox"
                                                        checked={selectedHSDLanes.includes(data.hsd_lane_id) ? true : false}
                                                        onClick={() => this.handleHSDLanesSelection(data.hsd_lane_id)}
                                                        style={{float: 'left', marginRight: '2px'}}
                                                    />}
                                                    {data.transporter_name}</div>
                                                <div className="divTableCell tCenter"> {data.transporter_code} </div>
                                                <div className="divTableCell tCenter"> {data.from} </div>
                                                <div className="divTableCell tCenter"> {data.to} </div>
                                                {isSapDPL &&
                                                <>
                                                    <div className="divTableCell tCenter"> {data.zone} </div>
                                                    <div className="divTableCell tCenter"> {data.zone_average} </div>
                                                </>}
                                                <div className="divTableCell tCenter"> {isSapDPL ? product.join(',') : data.material_name} </div>
                                                <div className="divTableCell tCenter"> {data.hsd_grace} </div>
                                                <div className="divTableCell tCenter"> {data.vehicle_type} </div>
                                                <div className="divTableCell tCenter"> {data.carrying_capacity} </div>
                                                <div className="divTableCell tCenter"> {data.mileage} </div>
                                                <div className="divTableCell tCenter"> {(data.minimum_guarantee || 0).toFixed(2)} </div>
                                                {/* <div className="divTableCell"> {data.mileage} </div> */}
                                                {(this.props.months || []).map((dt) => {
                                                const month = new Date(dt).getMonth() + 1; 
                                                const year = new Date(dt).getFullYear();
                                                const mth = (data.approvals || []).filter(m => parseInt(m.approved_year) === parseInt(year) && parseInt(m.approved_month)===parseInt(month));
                                                if (mth.length > 0 && parseInt(month)===parseInt(mth[0].approved_month)) {
                                                    return (
                                                        <Fragment>
                                                            <div className="divTableCell"> 
                                                            <div className="divTableCell"> {(mth[0].contract_diesel_rate || 0).toFixed(2)} </div>
                                                            <div className="divTableCell"> {(mth[0].previous_rate || 0).toFixed(2)} </div>
                                                            <div className="divTableCell"> {((mth[0].previous_rate - mth[0].contract_diesel_rate) || 0).toFixed(2)} </div>
                                                            <div className="divTableCell"> {(mth[0].approved_diesel_rate || 0).toFixed(2)} </div>
                                                            {cycleType === 1 ? 
                                                            <Fragment>
                                                                <div className="divTableCell"> {(mth[0].first_fortnight_rate || 0).toFixed(2)} </div>
                                                                <div className="divTableCell"> {(mth[0].last_fortnight_rate || 0).toFixed(2)} </div>
                                                            </Fragment>
                                                            : 
                                                            <div className="divTableCell"> {(mth[0].last_fortnight_rate || 0).toFixed(2)} </div>}
                                                            </div>
                                                        </Fragment>
                                                    )
                                                } 
                                                else {
                                                    return <div className="divTableCell tCenter"> - </div>;
                                                }
                                                })}
                                                {/* {(data.approvals || []).map((rate, index) => {
                                                    return (
                                                        <Fragment>
                                                            <div className="divTableCell"> 
                                                            <div className="divTableCell"> {(rate.contract_lane_rate || 0).toFixed(2)} </div>
                                                            <div className="divTableCell"> {(rate.previous_rate || 0).toFixed(2)} </div>
                                                            <div className="divTableCell"> {((rate.contract_lane_rate - rate.previous_rate) || 0).toFixed(2)} </div>
                                                            {cycleType === 1 ? 
                                                            <Fragment>
                                                                <div className="divTableCell"> {(rate.first_fortnight_rate || 0).toFixed(2)} </div>
                                                                <div className="divTableCell"> {(rate.last_fortnight_rate || 0).toFixed(2)} </div>
                                                            </Fragment>
                                                            : 
                                                            <div className="divTableCell"> {(rate.last_fortnight_rate || 0).toFixed(2)} </div>}
                                                            </div>
                                                        </Fragment>
                                                    )
                                                })} */}
                                                
                                                {/* <div className="divTableCell"> {data.}</div> */}
                                                    {/* <div className={'indicate-icon'} style={{ backgroundImage: `url(${(row.last_month_approved_rate_diff > 0) ? UpArrow : DownArrow})` }} /> */}
                                           
                                            </div>
                                        </Fragment>
                                        )})}
                                    </div>
                                </div>
                            </div>
                        
                    </div>
                </div>
            </Fragment>
        )
    }
}    
const mapStateToProps = state => {
    return {

    }
}                
export default connect(mapStateToProps)(HSDLanesPopup);
