import React, { Component, Fragment } from "react";
import "./SAPVendorForm.less"; // Import a CSS file to style the component (see note below)
import ACTION from "../../common/action_constant";
import { connect } from "react-redux";
import TopPanel from "../../components/header-panel/header-panel";
import { withRouter } from 'react-router-dom';
import Select from "react-select";
import MsgPopup from "../../common/components/msg-popup/msg-popup";
import Loader from "../../common/components/loader/loader";


class SAPVendorForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vendorDetailsForSapForm: {
        head_office_pincode:"",
        head_office_state :"" ,
        head_office_city :"",
        head_office_address:"",
        head_office_number :"",
        
     data: {
          IFSC_code: "",
          bank_name: "",
          branch_name: "",
      },
     gstDetails:[
        {
          state:""
        }
      ],
       
      },

      vendorDetailsForPaymentTerms: {},
      vendorCurrencyDetails: {},
      SAPDetails: {
        paymentMethod:"BANK",
        paymentTerms: "",
        currencyOrder:"",
        incoTerm1:"",
      
        ob_vendor_id: "",
        vendorAcctGroup: "ZTPT",
        //centralBlocking:"",
        companyCode: "2000",
        purchaseOrganisation: "2000",
        reconAccount: "5031010",
      },
      isFormValid: false,
      showSapVendorCode: false,


    };
  }
  componentDidMount() {
    const { dispatch, match } = this.props;
    console.log("match:", match);
    let queryString = `?vendorId=${match.params.vendorId}`;
    console.log(match.vendorId);
    dispatch({ type: ACTION.VENDORS.GET_VENDOR_DETAILS_FOR_SAP, queryString });

    console.log("match:", match);
    let queryString1 = `?companyId=${match.params.companyId}`;
    console.log(match.companyId);
    dispatch({
      type: ACTION.VENDORS.GET_PAYMENT_TERMS_LIST,
      queryString: queryString1,
    });

    let queryString2 = `?companyId=${match.params.companyId}`;
    console.log(match.companyId);
    dispatch({
      type: ACTION.VENDORS.GET_CURRENCY_LIST,
      queryString: queryString2,
    });
    this.getIncotermsOptions();
  }
  getIncotermsOptions = () => {
    const { dispatch } = this.props;
    dispatch({type: ACTION.VENDORS.GET_INCO_TERMS_OPTIONS});
  }
  isSapVendorCodethere = (sap_vendor_code) => {
    return !!sap_vendor_code

  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { vendorDetails } = this.props;
    const { SAPDetails } = this.state;
    if (prevProps.vendorDetails !== vendorDetails) {
      if (!!vendorDetails && !!vendorDetails[0]) {
        this.setState({
          vendorDetailsForSapForm: vendorDetails[0],
          SAPDetails: {
            ...SAPDetails, paymentTerms: vendorDetails[0].paymentTerms,
            currencyOrder: vendorDetails[0].currencyOrder,
            incoTerm1: vendorDetails[0].incoTerm1, paymentMethod: vendorDetails[0].paymentMethod
          },
        });

      }

    }
    const { vendorPaymentDetails } = this.props;
    if (prevProps.vendorPaymentDetails !== vendorPaymentDetails) {
      if (!!vendorPaymentDetails && !!vendorPaymentDetails[0]) {
        this.setState({
          vendorDetailsForPaymentTerms: vendorPaymentDetails[0],
        });
      }
     
    }
    
    const { vendorCurrencyDetails } = this.props;
    if (prevProps.vendorCurrencyDetails !== vendorCurrencyDetails) {
      if (!!vendorCurrencyDetails && !!vendorCurrencyDetails[0]) {
        this.setState({ vendorCurrencyDetails: vendorCurrencyDetails[0] });
      }
    }



  }

  isFalsyValue = (value) => {
    if (value === null) {
      return true;
    } else if (value === undefined) {
      return true;
    } else if (value === "") {
      return true;
    } else if (value.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  handleOnChangeSapDeatails = (event) => {
    const { name, value } = event.target;
    const { SAPDetails } = this.state;
    let updateSAPDetails = { ...SAPDetails, [name]: value };

    this.setState({ SAPDetails: updateSAPDetails }, () => {

      // const isFormValid = this.areFieldsValid();
      // this.setState({ isFormValid });
    });
  };

  handleSaveSapDeatails = () => {
    const { dispatch, match } = this.props;
    const { SAPDetails, vendorDetailsForSapForm } = this.state;
    const payload = {
      ...SAPDetails,
      ob_vendor_id: vendorDetailsForSapForm.ob_vendor_id,
    };

    const onSuccess = () => {
    
      let queryString = `?vendorId=${match.params.vendorId}`;
      dispatch({
        type: ACTION.VENDORS.GET_VENDOR_DETAILS_FOR_SAP,
        queryString,
      });
     
      
    };

    dispatch({
      type: ACTION.VENDORS.POST_SAVE_SAP_DEATAILS,
      data: payload,
      onSuccess,
    });
  };

  handleClickSend = () => {
    const { vendorDetailsForSapForm } = this.state;
    const { dispatch,match,history} = this.props;
    const payload = { ob_vendor_id: vendorDetailsForSapForm.ob_vendor_id };
    const onSuccess = () => {
     let queryString = `?vendorId=${match.params.vendorId}`;
      dispatch({
        type: ACTION.VENDORS.GET_VENDOR_DETAILS_FOR_SAP,
        queryString,
      });
      history.goBack();
   };
    dispatch({ type: ACTION.VENDORS.POST_SEND_SAP_DEATAILS, data: payload, onSuccess });
};

  handleCancelClick = () => {
    const { history } = this.props;
    console.log("history:", history)

    if (history) {
      history.goBack();
    }
  };


  isSaveDisable = () => {

    const { vendorDetailsForSapForm, SAPDetails }= this.state
    let C1 = !!vendorDetailsForSapForm && !!vendorDetailsForSapForm.sap_vendor_code
    if(C1){
      return true
    }

    let C2 = !!SAPDetails.currencyOrder && !!SAPDetails.paymentTerms && !!SAPDetails.incoTerm1

    if (!C2) {
      return true
    }

    let C3 = !!vendorDetailsForSapForm && !!vendorDetailsForSapForm.save_SAP_form

    if (C3) {
      return true
    }
    return false

  };
  isSendDisable = () => {
    const { vendorDetailsForSapForm }= this.state
    let C1 = !!vendorDetailsForSapForm && !!vendorDetailsForSapForm.sap_vendor_code
    if(C1){
      return true
    }

    let C2 = !!vendorDetailsForSapForm && !!vendorDetailsForSapForm.save_SAP_form

    if (C2) {
      return false
    }
    return true
  };

  closeMsgPopup = () => {
    const { dispatch } = this.props;
    dispatch({ type: ACTION.MSG_DISPLAY.HIDE_MSG })
  }
  onChangeIncoterm = (option) => {
    const { SAPDetails } = this.state;
    SAPDetails.incoTerm1 = option.short_name;
    
    this.setState({ SAPDetails });
    console.log("incoterm",SAPDetails.incoTerm1)
  };

  onChangepaymentTerms = (option) => {
    const { SAPDetails } = this.state;
    SAPDetails.paymentTerms = option.short_name;
    
    this.setState({ SAPDetails });
    console.log("paymentTerms",SAPDetails.paymentTerms)
  };
render() {
    const { vendorDetails, vendorCurrencyDetails,vendorPaymentDetails,incotermsOptions } = this.props;
    console.log('incotermsOptions:', incotermsOptions)
    const { vendorDetailsForSapForm, SAPDetails, isFormValid, showSapVendorCode } = this.state;

    const paymentMethod =
    (vendorPaymentDetails || []).map((data) => ({
      label: data.name,
      value: data.id,
      short_name: data.short_name,
    })) || [];

    const incoterms =
      (incotermsOptions || []).map((data) => ({
        label: data.name,
        value: data.id,
        short_name: data.short_name,
      })) || [];
    return (
      <div className="form-container">
        {this.props.loader.isLoading && <Loader />}
        {this.props.msgpopup.active && <MsgPopup close={this.closeMsgPopup} type={this.props.msgpopup.type} msg={this.props.msgpopup.msg} />}
        <div style={{ padding: "20px" }}>
          <TopPanel newVendor />
        </div>
        <div style={{ padding: "7px" }}> </div>
        <div className="heading">SAP Vendor Form</div>
        <div className="container">
          <div className="container-heading">Company Details</div>
          <div className="form-section">
            <div className="form-item">
              <label className="labelname" htmlFor="input1">
                Company Name (Vendor Name/Transporter/LSP)
              </label>
              <input
                className="common"
                type="text"
                id="company_name"
                placeholder="Company Name"
                name="vendor_company_name"
                value={
                  this.isFalsyValue(vendorDetailsForSapForm.vendor_company_name)
                    ? ""
                    : vendorDetailsForSapForm.vendor_company_name
                }
              />
            </div>

            <div className="form-item">
              <label htmlFor="input2">
                Email<span style={{ color: "red" }}>*</span>
              </label>
              <input
                className="common"
                type="text"
                id="email"
                placeholder="Email"
                name="email"
                value={
                  this.isFalsyValue(vendorDetailsForSapForm.email)
                    ? ""
                    : vendorDetailsForSapForm.email
                }
              />
            </div>

            <div className="form-item">
              <label htmlFor="input3">Contact Number</label>
              <input
                className="common"
                type="text"
                id="contact_number"
                placeholder="contact number"
                name="contact_no"
                value={
                  this.isFalsyValue(vendorDetailsForSapForm.contact_no)
                    ? ""
                    : vendorDetailsForSapForm.contact_no
                }
              />
            </div>

            <div className="form-item">
              <label htmlFor="input4">PAN Number</label>
              <input
                className="common"
                type="text"
                id="pan_number"
                placeholder="PAN Number"
                name="pan_no"
                value={
                  this.isFalsyValue(vendorDetailsForSapForm.pan_no)
                    ? ""
                    : vendorDetailsForSapForm.pan_no
                }
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="container-heading"> Head Office Details</div>
              <Fragment >
                <div className="form-section">
                  <div className="form-item">
                    <label className="labelname" htmlFor="input1">
                      Address
                    </label>
                    <input
                      className="common"
                      type="text"
                      id="address"
                      placeholder="Address"
                      name="head_office_address"
                      value={
                        this.isFalsyValue(vendorDetailsForSapForm.head_office_address)
                          ? ""
                          : vendorDetailsForSapForm.head_office_address
                      }
                    />
                  </div>

                  <div className="form-item">
                    <label htmlFor="input3">City</label>
                    <input
                      className="common"
                      type="text"
                      id="input3"
                      placeholder="city"
                      name="head_office_city"
                      value={
                        this.isFalsyValue(vendorDetailsForSapForm.head_office_city)
                          ? ""
                          : vendorDetailsForSapForm.head_office_city


                      }
                    />
                  </div>
                  <div className="form-item">
                    <label htmlFor="input2">State</label>
                    <input
                      className="common"
                      type="text"
                      id="state"
                      placeholder="State"
                      name="head_office_state"
                      value={
                        this.isFalsyValue(vendorDetailsForSapForm.head_office_state)
                          ? ""
                          : vendorDetailsForSapForm.head_office_state

                      }
                    />
                  </div>

                  <div className="form-item">
                    <label htmlFor="input4">Pincode</label>
                    <input
                      className="common"
                      type="text"
                      id="pincode"
                      placeholder="Pincode"
                      name="head_office_pincode"
                      value={
                        this.isFalsyValue(vendorDetailsForSapForm.head_office_pincode)
                          ? ""
                          : vendorDetailsForSapForm.head_office_pincode

                      }
                    />
                  </div>

                  <div className="form-item">
                    <label htmlFor="input5">Contact Number</label>
                    <input
                      className="common"
                      type="text"
                      id="contact_number"
                      placeholder="Contact Number"
                      name="head_office_number"
                      value={
                        this.isFalsyValue(vendorDetailsForSapForm.head_office_number)
                          ? ""
                          : vendorDetailsForSapForm.head_office_number
                       }
                    />
                  </div>
                </div>
              </Fragment>
        </div>
        <div className="GST-div">
          <div className="GST-div-top">
            <div className="GST-div-heading">GST Details</div>
            <div className="GST-div-inp">
         </div>
          </div>
          <hr className="GST-div-hr"></hr>
          <div className="GST-div-bot">
            <table className="GST-table">
              <thead className="GST-table-head">
                <tr>
                  <th>State</th>
                  <th>GSTIN</th>
                  <th>GST Percentage</th>
                </tr>
              </thead>
              <tbody className="GST-table-body">
                {(vendorDetailsForSapForm.gstDetails || []).map(
                  (eleROA, indOfROA) => (
                    <tr key={indOfROA}>
                      <td>{eleROA.state}</td>
                      <td>{eleROA.gstin}</td>
                      <td>{eleROA.gst_percentage}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="container">
          <div className="container-heading">Bank Details</div>

          <Fragment>
            <div className="form-section">
              <div className="form-item">
                <label className="labelname" htmlFor="input1">
                  Bank Name
                </label>
                <input
                  className="common"
                  type="text"
                  id="bank_name"
                  placeholder="Bank Name"
                  name="bank_name"
                  value={
                    this.isFalsyValue(vendorDetailsForSapForm.bank_name)
                      ? ""
                      : vendorDetailsForSapForm.bank_name
                  }
                />
              </div>

              <div className="form-item">
                <label htmlFor="input2">Bank Address</label>
                <input
                  className="common"
                  type="text"
                  id="bank_address"
                  placeholder="Bank Address"
                  value={
                    this.isFalsyValue(vendorDetailsForSapForm.branch_name)
                      ? ""
                      : vendorDetailsForSapForm.branch_name
                  }
                />
              </div>

              <div className="form-item">
                <label htmlFor="input3">Account Number</label>
                <input
                  className="common"
                  type="text"
                  id="account_number"
                  placeholder="Account Number"
                  name="account_number"
                  value={
                    this.isFalsyValue(vendorDetailsForSapForm.account_number)
                      ? ""
                      : vendorDetailsForSapForm.account_number
                  }
                />
              </div>

              <div className="form-item">
                <label htmlFor="input4">IFSC Code</label>
                <input
                  className="common"
                  type="text"
                  id="ifsc_code"
                  placeholder="IFSC Code"
                  name="IFSC_code"
                  value={
                    this.isFalsyValue(vendorDetailsForSapForm.IFSC_code)
                      ? ""
                      : vendorDetailsForSapForm.IFSC_code
                  }
                />
              </div>
            </div>
          </Fragment>

        </div>
        <div className="SAPcontainer">
          <div className="container-heading">SAP Details</div>

          <div className="form-section">
            <div id="Acctlabel" className="form-item">
              <label className="labelname Acct" htmlFor="input1">
                Account Group
              </label>
              <input
                className="common"
                type="text"
                id="account_group"
                placeholder="Account Group"
                name="vendorAcctGroup"
                value={
                  this.isFalsyValue(SAPDetails.vendorAcctGroup)
                    ? ""
                    :SAPDetails.vendorAcctGroup
                }
                onChange={(event) => {
                  this.handleOnChangeSapDeatails(event);
                }}
              />
            </div>

            {/* <div className="form-item">
             <label htmlFor="input2">Central Blocking</label>
                 <select id="central_blocking"  value={
                  this.isFalsyValue(SAPDetails.centralBlocking)
                    ? ""
                    : SAPDetails.centralBlocking
                }  onChange={(event) => {
                  this.handleOnChangeSapDeatails(event);
                }}>
                </select>
               </div> */}

            <div className="form-item">
              <label htmlFor="input3">Company Code</label>
              <input
                type="text"
                className="common"
                id="company_code"
                placeholder="Company Code"
                name="companyCode"
                value={
                  this.isFalsyValue(SAPDetails.companyCode)
                    ? ""
                    : SAPDetails.companyCode
                }
                onChange={(event) => {
                  this.handleOnChangeSapDeatails(event);
                }}
              />
            </div>

            <div className="form-item">
              <label htmlFor="input4">Recon Account</label>
              <input
                type="text"
                id="recon_account"
                placeholder="Recon Account"
                className="common"
                name="reconAccount"
                value={
                  this.isFalsyValue(SAPDetails.reconAccount)
                    ? ""
                    : SAPDetails.reconAccount
                }
                onChange={(event) => {
                  this.handleOnChangeSapDeatails(event);
                }}
              />
            </div>

            

            <div className="form-item">
              <label htmlFor="input6">Purchase Org</label>
              <input
                type="text"
                id="purchase_org"
                placeholder="Purchase Org"
                className="common"
                name="purchaseOrganisation"
                value={
                  this.isFalsyValue(SAPDetails.purchaseOrganisation)
                    ? ""
                    : SAPDetails.purchaseOrganisation
                }
                onChange={(event) => {
                  this.handleOnChangeSapDeatails(event);
                }}
              />
            </div>
          </div>
          <div className="form-section">
            {/*REPLACE WITH DROPDOWNS ELEMENTS LATER*/}
            <div className="form-item">
              <label htmlFor="input1">Order Currency</label>
              <select
                id="order_currency"
                name="currencyOrder"
                className="common"
                value={
                  this.isFalsyValue(SAPDetails.currencyOrder)
                    ? ""
                    : SAPDetails.currencyOrder
                }
                onChange={(event) => {
                  this.handleOnChangeSapDeatails(event);
                }}
              >
                <option value={""}>Please Select</option>
                {(Object.keys(vendorCurrencyDetails) || []).map((ele, ind) => (
                  <Fragment key={ind}>
                    <option value={ele}>{ele}</option>
                  </Fragment>
                ))}
              </select>
            </div>

            <div className="form-item">
              <label htmlFor="input2">Payment Terms</label>
              <Select
                    className="incotermsOptions"
                    options={paymentMethod}
                    value={paymentMethod.find((option) => option.short_name === SAPDetails.paymentTerms)}
                    onChange={(option) => this.onChangepaymentTerms(option)}
                    placeholder="select payment term"
                  />
            </div>

            <div className="form-item">
              <label htmlFor="input3">Inco Terms</label>
              <Select
                    className="incotermsOptions"
                    options={incoterms}
                    value={incoterms.find((option) => option.short_name === SAPDetails.incoTerm1)}
                    onChange={(option) => this.onChangeIncoterm(option)}
                    placeholder="Select Inco Terms"
                  />
            </div>

            <div className="form-item">
              <label htmlFor="input5">Payment Method</label>
              <input
                type="text"
                id="payment_method"
                placeholder="Payment Method"
                className="common"
                name="paymentMethod"
                value={
                  this.isFalsyValue(SAPDetails.paymentMethod
                    )
                    ? ""
                    :SAPDetails.paymentMethod
                }
                onChange={(event) => {
                  this.handleOnChangeSapDeatails(event);
                }}
              />
            </div>

            <div className="form-item">
              <label htmlFor="input3">sap vendor code</label>
              <input
                className="common"
                type="text"
                id="sap_vendor_code"
                placeholder="sap vendor code"
                value={vendorDetailsForSapForm.sap_vendor_code}
                readOnly
              />
            </div>

          </div>
        </div>
        <div className="mb-25 mt-25 fRight button">
          <button className="mb-25 button-classic mr-20" id="btn1" onClick={this.handleCancelClick}
          // disabled={isCancelButtonDisable}
          > Cancel </button>
          <button
            className={`mb-25 button-classic mr-20 `}
            id="btn2"
            onClick={this.handleSaveSapDeatails}
            disabled={this.isSaveDisable()}
          >
            Save
          </button>

          <button
            className={`mb-25 button-classic mr-20`}
            id="btn"
            onClick={this.handleClickSend}
             disabled={this.isSendDisable()}
          >
            Send
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    vendorDetails: state.vendors.vendorDetails || [],
    vendorPaymentDetails: state.vendors.vendorPaymentDetails || [],
    vendorCurrencyDetails: state.vendors.vendorCurrencyDetails || [],
    incotermsOptions: state.vendors.incotermsOptions || [],
    loader: state.loader,
    msgpopup: state.msgpopup,
  };
};

export default connect(mapStateToProps)(withRouter(SAPVendorForm));