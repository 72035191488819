import React, { Component } from 'react';
import YesNoComponent from "../../common/components/yes-no-component/yes_no_component";
import ACTIONS from "../../common/action_constant";
import { connect } from 'react-redux';

class IndentMgmtConfig extends Component {
    constructor() {
        super();
        this.state = {
            data: {
            }
        }
    }

    componentDidMount() {
        this.getIndentConfigDetails();
    }

    getIndentConfigDetails = () => {
        const { dispatch, branchId, companyId } = this.props;
        dispatch({
            type: ACTIONS.INDENTING.GET_INDENT_CONFIG_DETAILS,
            data: {
                companyId,
                branchId
            },
            onSuccess: (data) => {
                if(data.length > 0) {
                    this.setState({
                        data: data[0] || {}
                    });
                }
            }
        })
    }

    addIndentingConfig = () => {
        const { data } = this.state;
        const { dispatch, companyId, branchId } = this.props;
        data.company_id = companyId;
        data.branch_id = branchId;
        dispatch({
            type: ACTIONS.INDENTING.ADD_INDENT_CONFIG_DETAILS,
            data,
            onSuccess: () => {
                this.getIndentConfigDetails();
            }
        })
    }

    saveIndentingConfig = () => {
        const { data } = this.state;
        const { dispatch } = this.props;
        delete data['_id'];
        dispatch({
            type: ACTIONS.INDENTING.SAVE_INDENT_CONFIG_DETAILS,
            data,
            onSuccess: () => {
                this.getIndentConfigDetails();
            }
        })
    }

    changeYesNo = (val, key) => {
        const { data } = this.state;
        data[key] = val;
        this.setState({
            data
        })
    }

    render() {
        const { data } = this.state;
        return (
            <div className="contracts-management-wrap">
                <div className="contracts-holder">
                    <div className="contracts-management-grid-wrap">
                        <div className="contracts-management-row">
                            <div className="description-block">Indent Auto Accept</div>
                            <div className="action-block">
                                <YesNoComponent changeYesNo={this.changeYesNo} id={'indent_auto_accept'} value={data.indent_auto_accept} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-holder">
                    {!data.management_id && <div className="add-button" onClick={this.addIndentingConfig}>ADD</div>}
                    {data.management_id && <div className="add-button" onClick={this.saveIndentingConfig}>SAVE</div>}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
       home: state.home,
       loader: state.loader,
       msgpopup: state.msgpopup,
    }
 }
 
 export default connect(mapStateToProps)(IndentMgmtConfig);