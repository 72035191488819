import React, { Component } from 'react';
import crossMark from "../../../../assets/images/cross-mark.svg";
import './search-text.less';

const SearchText = (props) =>{

	function handleSearchOnEnter(e) {
		if (e.which === 13 || e.keyCode === 13)
			props.click();
	};

	return (
		<div className = "search-text-wrap">
			<input type = "text" value = {props.value} className = "text-search" placeholder = {props.placeholder} onChange = {(e) => props.changeHandler(e.target.value,props.id)} id = {props.id} onKeyPress={(event) => handleSearchOnEnter(event)} />
			{ props.canClear && <div className="cross-img" style = {{backgroundImage: `url(${crossMark})`}} onClick = {props.handleClearFilter}></div> }
			<input type = "button" className = "button-search" value = {"Go"} onClick = {props.click}/>
		</div>
	);
}

export default SearchText;