import React, { Component } from 'react';
import '../add_chain/add_chain.less';
import Select from 'react-select';
import InputText from '../../../common/components/input-text/input-text';
import Delete from '../../../../assets/images/delete.svg';
import Button from '../../../home/button-classic/button-classic';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { getCookie } from "../../../../utils/cookies";
import Refresh from "../../../../assets/images/refresh-button.svg";
import { netWorkTypes, weeklyOff, entityType, addressType, movementTypes, metroType, CLUSTERS } from "../../../../utils/common";
import { DEEPAK_COMPANY_ID, AWL_COMPANY_IDS, PANTALOONS_COMPANY_IDS, ABFRL_COMPANY_IDS } from '../../../../utils/common';
import Moment from 'moment';
import { legthValidation, validationCheck, toPascalCase } from "../../../../utils/validate";
import Geocode from "react-geocode";
import home from '../../home.constants.js';


class EditDepotConsignee extends Component {
	constructor(props) {
		super(props);
		this.state = {
            basicData:this.props.data,
			// basicData: {
			// 	company_id: JSON.parse(getCookie("user")).company_id,
            //     consignee_name:'',
            //     consignee_branch:'',
            //     primary_consignee_code:'',
            //     consignee_email:'',
            //     address:'',
            //     address_type:'',
            //     primary_consignee_contact:'',
				
			// 	inserted_by: JSON.parse(getCookie("user")).user_id,
			// },
			iserror: '',
			errormessage: '',
			isValidate: false,
            validationMsg: ''
		}
    }

    componentDidMount() {

	}

    onloading = (id) => {
        const { loading } = this.state;
        loading[id] = !loading[id];
        this.setState({ loading });
    }
    onUnloading = (id) => {
        const { Unloading } = this.state;
        Unloading[id] = !Unloading[id];
        this.setState({ Unloading });
    }
    onChangeHandler = (value, id, isType) => {
        let { basicData } = this.state;
		if(id === 'secondary_gstin' || id === 'pan_no' || id === 'primary_gstin' || id === 'zip_code'){
			basicData[id] = value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g,'');
		} 
		else if (id === 'primary_consignee_contact'){
			const result = validationCheck(value, basicData[id], isType);
            basicData[id] = result;
		}
		else if (id === 'primary_consignee_name'){
			basicData[id] = value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}0-9]/g,'');
		}
		// else if (id === 'address_type') {
        //     // Convert the value to an integer before setting it in the state
        //     basicData[id] = parseInt(value, 10);
        // }
		else {
			basicData[id] = value
		}
        this.setState({ basicData });
    }
    onBlurChange = (e) => {
        if(e.target.id == 'consignee_email'|| e.target.id === 'primary_consignee_email'){
            const errMsg = legthValidation('email', e.target.value);
            if( errMsg && e.target.value){
                this.setState ({
                    iserror: e.target.id,
                    errormessage: errMsg
                })
            } else if(this.state.iserror){
                this.setState({iserror: ''});
            }
        } else {
            this.getLatLong(e.target.value.replace('#',''));
        }
    }
    handleChange = (id, selcetedOption) => {
        let { basicData } = this.state;
        if(id === 'weekly_off'){
            basicData.weekly_off = [];
            for(let i= 0 ; i < selcetedOption.length; i++){
                basicData.weekly_off.push(selcetedOption[i].value);
            }
        } else {
            basicData[id] = selcetedOption.value
        }
        this.setState( { basicData });
    }
    isValidateFields = () => {
        const { basicData } = this.state;
        let isState = true;
        if(!basicData.consignee_name){
            this.setState({validationMsg: 'consignee name'})
            isState = false;
        } else if(!basicData.consignee_branch){
            this.setState({validationMsg: 'consignee branch'})
            isState = false;
        } else if(!basicData.primary_consignee_code){
            this.setState({validationMsg: 'primary consignee code'})
            isState = false;
        }else if(!basicData.consignee_email){
            this.setState({validationMsg: 'consignee email'})
            isState = false;
        } else if(!basicData.address){
            this.setState({validationMsg: 'address'})
            isState = false;
        }
		 else if(!basicData.address_type){
            this.setState({validationMsg: 'Address Type'})
            isState = false;
        } else if(!basicData.primary_consignee_contact){
            this.setState({validationMsg: 'primary consignee contact'})
            isState = false;
        }
        
        return isState;
    }
    onUpdateLogistics = () => {
        if (this.isValidateFields()) {
        const { basicData } = this.state;
        basicData.inserted_by = JSON.parse(getCookie("user")).user_id;
        basicData.is_metro = basicData.is_metro ? true:false;
        this.props.updateDepotConsignee(basicData);
        this.props.toggleAdd();
    }else {
        this.setState({ isValidate: true });
    }
    }
	onAddLogistics = () => {
		if (this.isValidateFields() ) {
			const data = this.state.basicData;
			this.props.AddDepotConsignee(data);
			this.props.toggleAdd();
		} else {
			this.setState({ isValidate: true });
		}
	}
   
	  onCancel = () => {
		this.props.toggleAdd()
		window.location.reload()
	  }
    render() {
        const details = this.state.basicData;
        const {stateListData, companyId }=this.props;
        return(
            <div className = "add-chain">
                <div onClick = {this.props.toggleAdd} className = "overlay"></div>
                <div id = "modal" className = "modal-popup">
                    <div className = "pop-heading">
                        Update Depot Consignee
                    </div>
					<div className="content">
						<div className="details-section">
							<div className="heading-section network-consignee">Consignee Details</div>
							<div className="details">
								<InputText
									placeholder="Consignee Name"
									id={"consignee_name"}
									label="Consignee Name"
									changeHandler={this.onChangeHandler}
									value={details.consignee_name}
									// required={true}
								/>
								<InputText
									placeholder="Consignee Branch"
									id={"consignee_branch"}
									label="Consignee Branch"
									changeHandler={this.onChangeHandler}
									value={details.consignee_branch}
									// required={true}
								/>
								<InputText
									placeholder="Primary Consignee Code"
									id={"primary_consignee_code"}
									label="Primary Consignee Code"
									changeHandler={this.onChangeHandler}
									value={details.primary_consignee_code}
									// length={'10'}
								/>
								<InputText
									placeholder="Consignee Email"
									id={"consignee_email"}
									label="Consignee Email"
									changeHandler={this.onChangeHandler}
									value={details.consignee_email}
									// length={'15'}
								/>
								<InputText
									placeholder="Address"
									id={"address"}
									label="Address"
									changeHandler={this.onChangeHandler}
									value={details.address}
									// length={'15'}
								/>
								<div className="select-wrap">
									<div className="label">Address Type
									{/* <sup className={'error error-font'}>*</sup> */}
									</div>
									<Select
										className="select"
										options={addressType}
										value={this.state.depot_type}
										onChange={this.handleChange.bind(
											this,
											"address_type"
										)}
									/>
								</div>
								{/* <InputText
									placeholder="Address Type"
									type="number"
									id={"address_type"}
									label="Address Type"
									changeHandler={this.onChangeHandler}
									value={details.address_type}
                                    required={true}
								/> */}
								<InputText
                                    type='number'
									placeholder="Primary Consignee Contact"
									id={"primary_consignee_contact"}
									label="Primary Consignee Contact"
									changeHandler={this.onChangeHandler}
									value={details.primary_consignee_contact}
                                    length={'10'}
								/>
							</div>
						</div>	
					</div>
					
					
					<div className={'Add-logistics-btn'}>
						{this.state.isValidate && <div className={'error'} style={{ fontSize: '14px', marginBottom: '5px', marginLeft: '40px' }}>{`Please Enter The Details For ${this.state.validationMsg} Mandatory Field`}</div>}
						<Button value={'Cancel'} click={this.onCancel} />
						<Button value={'Update Depot Consignee'} click={this.onUpdateLogistics} />
					</div>
				</div>
			</div>
		)
	}
}


export default EditDepotConsignee;
