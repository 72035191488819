import React, { Component } from 'react';
import './masterDropDown.less';
import { connect } from 'react-redux';
import ACTIONS from '../../common/action_constant';
import Loader from "../../common/components/loader/loader";
import MSGPopUp from "../../common/components/msg-popup/msg-popup";
import { modeLoadType, modeType, loadType } from "../../../utils/constants";
import { getCookie } from '../../../utils/cookies';
import CommonMasterDropDown from './common_master_drop_down/commonMasterDropDown';
import Dropup from "../../../assets/images/drop-up-arrow.svg";
import Delete from '../../../assets/images/delete.svg';
import Button from '../../home/button-classic/button-classic';
import Edit from '../../../assets/images/edit.svg';
import Save from '../../../assets/images/save.svg';

class MasterDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: "",
            dropDownNameForMaster: [],
            insideTabDropDownName: [],
            editDataFlag: false
        }
    }

    componentDidMount = () => {
        this.getDropDownTabName()
    }

    componentWillReceiveProps(newProps) {

        if (newProps.master && newProps.master.dropDownNameForMaster && newProps.master.dropDownNameForMaster.length > 0) {
            this.setState({ dropDownNameForMaster: newProps.master.dropDownNameForMaster })
        }
        if (newProps.master && newProps.master.insideTabDropDownName && newProps.master.insideTabDropDownName.length > 0) {
            this.setState({ insideTabDropDownName: newProps.master.insideTabDropDownName })
        }
    }


    getDropDownTabName = () => {
        const { dispatch } = this.props;
        const company_id = JSON.parse(getCookie('user')).company_id;
        dispatch({
            type: ACTIONS.MASTER.MASTER_DROP_DOWN.GET_DROP_DOWN_NAME,
            param: company_id,
        })
    }

    getInsideTabDropDownName = (tab) => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTIONS.MASTER.MASTER_DROP_DOWN.GET_INSIDE_TAB_DROPDOWN,
            param: `${tab.company_id}&dropdown_type_id=${tab.dropdown_type_id}`,
        })
    }

    toggleCheck = (dropdown, idx) => {
        let { dropDownNameForMaster } = this.state;

        dropDownNameForMaster.map((drop, index) => {
            if (index == idx) {
                if (drop.is_opened) {
                    drop.is_opened = false
                }
                else {
                    drop.is_opened = true
                }
            }
            else {
                drop.is_opened = false
            }
        })
        this.setState({ dropDownNameForMaster })
        this.getInsideTabDropDownName(dropdown)
    }

    editData = (index) => {
        let { insideTabDropDownName } = this.state;
        insideTabDropDownName[index].edit_data = true
        this.setState({ insideTabDropDownName })
    }

    onChangeValue = (e, index) => {
        let { insideTabDropDownName } = this.state;
        insideTabDropDownName[index].dropdown_name = e.target.value
        insideTabDropDownName[index].dropdown_value = e.target.value
        this.setState({ insideTabDropDownName })
    }

    onSave = (value) => {
        const{ dispatch } = this.props;

        if(value.edit_data){
            delete value.edit_data
            dispatch({
                type:ACTIONS.MASTER.MASTER_DROP_DOWN.EDIT_MASTER_DROP_DOWN_DATA,
                data: JSON.stringify(value),
                onSuccess:(() => {
                    this.getInsideTabDropDownName(value)
                })
            })
        }

        if(value.new_data) {
            delete value.new_data
            dispatch({
                type:ACTIONS.MASTER.MASTER_DROP_DOWN.ADD_MASTER_DROP_DOWN_DATA,
                params: value.dropdown_type_id,
                data: value,
                onSuccess:(() => {
                    this.getInsideTabDropDownName(value)
                })
            })
        }
    }

    addField = () => {
        let { insideTabDropDownName } = this.state;

        let obj = {
            company_id : insideTabDropDownName[0].company_id,
            dropdown_id : insideTabDropDownName[insideTabDropDownName.length - 1].dropdown_id + 1,
            dropdown_name: "",
            dropdown_type: insideTabDropDownName[0].dropdown_type,
            dropdown_type_id: insideTabDropDownName[0].dropdown_type_id,
            dropdown_value: "",
            new_data: true
        }

        insideTabDropDownName.push(obj)

        this.setState({insideTabDropDownName})
    }

    deleteData = (value) => {
        const { dispatch } = this.props;
        dispatch({
            type:ACTIONS.MASTER.MASTER_DROP_DOWN.DELETE_MASTER_DROP_DOWN_DATA,
            data: value,
            onSuccess: (() => {
                this.getInsideTabDropDownName(value)
            })
        })
    }

    getItemDetails = (value, index, type) => {
        const { editDataFlag } = this.state;
        return (
            <div className={'list-section'} key={index}>
                <div className={'table-row event-id'}>{value.dropdown_id}</div>

                {(value.edit_data || value.new_data)  && <input className={'table-header dropdownInput'} value={value.dropdown_value} onChange={(e) => this.onChangeValue(e, index)} />}
                {!(value.edit_data || value.new_data) && <div className={'table-header event-id'}>{value.dropdown_value}</div>}
                <div className={'table-row action'} style={{ paddingTop: '-10px' }}>
                    {(value.edit_data || value.new_data)  ?
                        <img src={Save} style={{ width: '20px', height: '20px' }} onClick={() => this.onSave(value)} />
                        :
                        <img src={Edit} style={{ width: '20px', height: '20px' }} onClick={() => this.editData(index)} />
                    }
                    <img src={Delete} style={{ width: '20px', height: '20px', marginLeft: '10px' }} onClick={() => this.deleteData(value)} />
                </div>
            </div>
        )
    }

    getHeader = () => {
        return (
            <div className={'list-section'}>
                <div className={'table-header event-id'}>Drop Down Id</div>
                <div className={'table-header event-id'}>Drop Down Value</div>
                <div className={'table-header action'}>Action</div>
            </div>
        )
    }

    render() {
        const { master } = this.props;
        const { dropDownNameForMaster, insideTabDropDownName } = this.state;
        return (
            <div className={"reconciliation"}>
                {
                    this.props.loader.isLoading && <Loader />
                }
                {
                    this.props.msgpopup.active && <MSGPopUp close={this.closeMsgPopup} type={this.props.msgpopup.type} msg={this.props.msgpopup.msg} />
                }

                <div className={'debit-master'}>
                    {(dropDownNameForMaster || []).map((dropdown, idx) => {
                        return (
                            <>
                                <div className={'header'} >{dropdown.drop_type_name}
                                    <div className={dropdown.is_opened ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => this.toggleCheck(dropdown, idx)}></div>
                                </div>
                                <div className={`drop-section ${dropdown.is_opened ? "drop-up" : "drop-close"}`}>
                                    {this.getHeader()}
                                    {insideTabDropDownName.map((data, dataIdx) =>
                                        this.getItemDetails(data, dataIdx)
                                    )}
                                </div>
                                {dropdown.is_opened &&
                                    <div className={'approval-btn'}>
                                        <Button value={'Add'} click={() => this.addField()}></Button>
                                    </div>
                                }
                            </>
                        )
                    })}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        fuelDetails: state.companyAccounts.fuelDetails,
        loader: state.loader,
        msgpopup: state.msgpopup,
        companyAccountsDetails: state.companyAccounts,
        eventDetails: state.master,
        detentionDetails: state.companyAccounts.detentionDetails,
        multiPickUp: state.companyAccounts.multiPickUp,
        materialList: state.companyAccounts.materialList,
        holidayList: state.companyAccounts.holidayList,
        holidayDownlUrl: state.companyAccounts.holidayDownlUrl,
        freightRateDetails: state.companyAccounts.freightRateDetails,
        transporterList: state.companyAccounts.transporterList,
        zones: state.plantsDepots.zones,
        zipCodes: state.companyAccounts.zipCodes,
        productList: state.master.itemList,
        loadingCharges: state.companyAccounts.loadingCharges,
        configuredVehicles: ((state.contracts || {}).masterDetails || {}).vehicle_types,
        master: state.master
    };
};

export default connect(mapStateToProps)(MasterDropDown);



