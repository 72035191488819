import React, { Component } from 'react';
import './newVendor.less';
import Select from "react-select";
import plus from "../../../assets/images/newPlus.svg"
import minus from "../../../assets/images/minus.svg"
import ACTION from "../../common/action_constant";
import { getCookie } from "../../../utils/cookies";
import { connect } from 'react-redux';
import { ABFRL_COMPANY_IDS, DEEPAK_COMPANY_ID } from '../../../utils/common';

class NewVendor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newVendor: {
                company_id: JSON.parse(getCookie('user')).company_id,
                created_by: JSON.parse(getCookie('user')).user_id,
                vendor_company_name: props.companyApp ? props.companyApp.vendor_company_name : "",
                contact_person_name: props.companyApp ? props.companyApp.contact_person_name : "",
                email: props.companyApp ? props.companyApp.email : "",
                contact_no: props.companyApp ? (typeof (props.companyApp.contact_no) === "string" ? props.companyApp.contact_no : props.companyApp.contact_no.phone_no) : "",
                type: props.companyApp && props.companyApp.type ? props.companyApp.type : [],
                Vendor: props.companyApp ? props.companyApp.Vendor : "",
                modes: props.companyApp ? props.companyApp.modes : "",
                // additional_email: props.companyApp && props.companyApp.additional_email ? props.companyApp.additional_email : [{ id: 0, value: '' `}],
                additional_email: props.companyApp && props.companyApp.additional_email ? props.companyApp.additional_email : [""],
                sourceData: props.companyApp ? {label:props.companyApp.sourceData,value:props.companyApp.sourceData} : "",
                others: props.companyApp ? props.companyApp.others : "",
                ob_vendor_id: props.companyApp ? props.companyApp.ob_vendor_id : "",
            },
            validate_error: "",
        }
    }
    getBasicVendorDetails = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.VENDORS.GET_VENDOR_MODE_LIST,
            params: `?company_id=${JSON.parse(getCookie('user')).company_id}`
        });
    }
    addCompanySubmit = () => {
        const { vendor_company_name, contact_person_name, email, contact_no, type, Vendor, additional_email, sourceData, others } = this.state.newVendor
        const { newVendor } = this.state
        const userCompanyId = JSON.parse(getCookie('user')).company_id;
        if(!DEEPAK_COMPANY_ID.includes(userCompanyId)){
            newVendor.modes = newVendor.Vendor.map((val) => {
            return val.modes
        })}
        if (ABFRL_COMPANY_IDS.includes(userCompanyId) && vendor_company_name && contact_person_name && email && contact_no && type.length > 0 && Vendor.length > 0 && additional_email.length > 0 && sourceData) {
            if (sourceData.name == "Others" && others == "") {

                this.setState({ validate_error: 'Specify others feild is maditatory' })

            }
            else {
                this.setState({ validate_error: '' })
                this.props.submit(newVendor);
            }
        }
        else if (DEEPAK_COMPANY_ID.includes(userCompanyId) && vendor_company_name && contact_person_name && email && contact_no && type.length > 0 && additional_email.length > 0 ) {
                this.setState({ validate_error: '' })
                this.props.submit(newVendor);
        }
        else {

            this.setState({ validate_error: 'Please fill mandatory fields!' })
        }

    }
    componentDidMount = () => {
        this.getVendorSourceList();
        this.getModeVendortype();
        console.log("Initial State:", this.state);
    console.log("Props:", this.props);
    }
    getModeVendortype = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.VENDORS.GET_VENDOR_MODE_LIST,
            params: `?companyId=${JSON.parse(getCookie('user')).company_id}`
        });

    }
    getVendorSourceList = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.VENDORS.GET_VENDOR_SOURCE_DROPDOWN,
            company_id: JSON.parse(getCookie('user')).company_id
        })

    }
    handleChange = (e) => {
        const { name, value } = e.target;
        const newvalue = value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}|A-Za-z]/g, '');
        if (name !== 'contact_no') {
            this.setState((prevState) => ({
                newVendor: {
                    ...prevState.newVendor,
                    [name]: value,
                },
            }));
        }
        else if (name == 'contact_no' && value.length <= 10) {
            this.setState((prevState) => ({
                newVendor: {
                    ...prevState.newVendor,
                    [name]: newvalue,
                },
            }));
        }

    }

    handleAddField = () => {
        this.setState((prevState) => ({
            newVendor: {
                ...prevState.newVendor,
                additional_email: [...prevState.newVendor.additional_email, ""],
            },
        }));
    };
    handleRemoveField = (index) => {
        this.setState((prevState) => ({
            newVendor: {
                ...prevState.newVendor,
                additional_email: prevState.newVendor.additional_email.filter((_, i) => i !== index),
            },
        }));
    };

    // handleEmailChange = (index, value) => {
    //     this.setState((prevState) => {
    //         const updatedFields = [...prevState.newVendor.additional_email];
    //         updatedFields[index] = value;
    //         return {
    //             newVendor: {
    //                 ...prevState.newVendor,
    //                 additional_email: updatedFields,
    //             },
    //         };
    //     });
    // };


    //PREVIOUSLY WORKING ONE 
    handleEmailChange = (index, value) => {
        this.setState((prevState) => {
            const updatedFields = [...prevState.newVendor.additional_email];
            updatedFields[index] = value;
            return {
                newVendor: {
                    ...prevState.newVendor,
                    additional_email: updatedFields,
                },
            };
        });
    };
    
     
    





    // handleEmailChange = (index, value) => {
    //     this.setState((prevState) => {
    //         const updatedFields = [...prevState.newVendor.additional_email];
    //         updatedFields[index].value = value;
    //         return {
    //             newVendor: {
    //                 ...prevState.newVendor,
    //                 additional_email: updatedFields,
    //             },
    //         };
    //     });
    // };


    // handleDropdownChange = (name, selectedOption) => {
    //     this.setState((prevState) => ({
    //         newVendor: {
    //             ...prevState.newVendor,
    //             [name]: selectedOption,
    //         },
    //     }));
    // };

    handleDropdownChange = (name, selectedOption) => {
        const newState = {
            newVendor: {
                ...this.state.newVendor,
                [name]: selectedOption,
            },
        };
    
        if (name === 'sourceData' && selectedOption && selectedOption.name !== 'Others') {
            // Reset the 'others' field when a different option is selected
            newState.newVendor.others = '';
        }   
        this.setState(newState);
    };
    
    customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? null : '#0000',
            boxShadow: null,
        }),
    };
    updateCompanySubmit = () => {
        const { vendor_company_name, ob_vendor_id, contact_person_name, modes, email, contact_no, type, Vendor, additional_email, sourceData, others } = this.state.newVendor;

        let data = {
            company_id: JSON.parse(getCookie('user')).company_id,
            updated_by: JSON.parse(getCookie('user')).user_id,
            vendor_company_name,
            ob_vendor_id,
            contact_person_name,
            email,
            contact_no,
            type,
            Vendor,
            modes,
            additional_email,
            sourceData,
            others
        }
        if (!this.state.validate_error) {
            this.props.update(data);
        }
    }
    retriggerEmail = () => {
        const { email, ob_vendor_id } = this.state.newVendor;
        let data = {
            companyId: JSON.parse(getCookie('user')).company_id,
            vendorId: ob_vendor_id,
            email
        }
        this.props.sendEmail(data);
    }


    render() {
        const { state, props } = this
        const { vendorSource, vendorMode } = this.props.vendors;
        // const { list } = this.props.vendors.vendorsList
        const { vendor_company_name, contact_person_name, email, contact_no, type, Vendor, additional_email, sourceData, others } = state.newVendor
        let list = (vendorMode || {}).list;
        const vendorL = ((vendorMode || {}).list || []).map((val) => {
            return val.mode_types
        })
        const userCompanyId = JSON.parse(getCookie('user')).company_id;
        return (
            <div className="newVendor">
                <div onClick={props.close} className="overlay"></div>
                <div className="modal-popup">
                    <h2 className='m-0 p-0 mainHeading'>New vendor</h2>
                    <div className='vendorDetails'>
                        <h2 className='mb-10'>New Vendor details</h2>
                        <div>
                            <div>
                                <div className='labels'>Company Name (Vendor Name/Transporter/LSP) <span>*</span></div>
                                <input className='inputBlock ' style={{ width: "870px" }} name='vendor_company_name' value={vendor_company_name} placeholder='Company Name' onChange={this.handleChange} />
                            </div>
                            <div className='cecBlock mt-10'>
                                <div>
                                    <div className='labels'>Contact Person Name <span>*</span></div>
                                    <input className='inputBlock' placeholder='Contact Person Name' name='contact_person_name' value={contact_person_name} onChange={this.handleChange} />
                                </div>
                                <div>
                                    <div className='labels'>Email <span>*</span></div>
                                    <input className='inputBlock' placeholder='Email' type="email" name='email' value={email} onChange={this.handleChange} />
                                </div>
                                <div>
                                    <div className='labels'>Contact No. <span>*</span></div>
                                    <input className='inputBlock' placeholder='Contact No.' type='number' name='contact_no' value={contact_no} onChange={this.handleChange} />

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='BlockDetails'>
                        <h2>Mode</h2>
                        <div className='cecBlock'>
                            <div >
                                <div className='labels mt-10'>Select Mode</div>                             
                                <Select
                                    isMulti
                                    placeholder={'Select Mode'}
                                    className="select"
                                    name="type"
                                    value={type}
                                    options={(list || []).map((el) => { return { label: el.name, value: el.name, name: el.name, id: el.id } })}
                                    onChange={(selectedOption) =>
                                        this.handleDropdownChange('type', selectedOption)}
                                />

                            </div>
                            {ABFRL_COMPANY_IDS.includes(userCompanyId) &&
                            <div>
                                <div className='labels mt-10'>Type of Vendor</div>
                                <Select
                                    isMulti
                                    className="select"
                                    name="Vendor" placeholder="Select Vendor "
                                    options={(vendorL[0] || []).map((el) => { return { label: el.name, value: el.name, name: el.name, modes: el.id } })}
                                    value={Vendor}
                                    styles={this.customStyles}
                                    onChange={(selectedOption) => this.handleDropdownChange('Vendor', selectedOption)}
                                />                               
                            </div>}
                        </div>
                    </div>
                    <div className='emailDetails'>

                        <div className='Bclr' >
                            <h2 className='labels p-10'>Additional Emails</h2>
                            <div>
                                <div className='labels' style={{ paddingLeft: "10px", marginBottom: "0px" }}> Email </div>
                                
                                {/* {additional_email.map((field, index) => (
                                    <div key={index}>
                                        <input
                                            className='inputBlock ml-10 mb-10'
                                            placeholder='Email'
                                            name='additional_email'
                                            type="email"
                                            value={field.value}
                                            onChange={(e) => this.handleEmailChange(index, e.target.value)}
                                        />
                                        <i>
                                            {additional_email.length > 1 &&
                                                <i className="delete-icon" style={{ backgroundImage: `url(${minus})` }} onClick={() => this.handleRemoveField(index)}></i>}
                                            <i className="delete-icon" style={{ backgroundImage: `url(${plus})` }} onClick={this.handleAddField}></i>
                                        </i>
                                    </div>
                                ))} */}
                                {additional_email.map((value, index) => (
                                    <div key={index}>
                                        <input
                                        className='inputBlock ml-10 mb-10'
                                        placeholder='Email'
                                        name='additional_email'
                                        type="email"
                                        value={value}
                                        onChange={(e) => this.handleEmailChange(index, e.target.value)}
                                        />
                                        <i>
                                            {additional_email.length > 1 &&
                                                <i className="delete-icon" style={{ backgroundImage: `url(${minus})` }} onClick={() => this.handleRemoveField(index)}></i>}
                                            <i className="delete-icon" style={{ backgroundImage: `url(${plus})` }} onClick={this.handleAddField}></i>
                                        </i>
                                    </div>
                                    ))}

                            </div>
                        </div>
                    {ABFRL_COMPANY_IDS.includes(userCompanyId) &&
                       <div className='Bclr1'>
                            <h2 className='labels p-10'>Vendor Source</h2>
                            <div className='source'>
                                <div>
                                    <div className='labels' style={{ paddingLeft: "10px", marginRight: "10px" }}> Select Source </div>
                                    <div className='mr-10'>
                                        <Select className="select ml-10" name="sourceData" placeholder="Select Source "
                                            options={(vendorSource || []).map((el) => { return { label: el.name, value: el.name, name: el.name } })}
                                            value={sourceData}
                                            styles={this.customStyles}
                                            onChange={
                                                (selectedOption) => this.handleDropdownChange('sourceData', selectedOption)
                                            }
                                        /></div>
                                </div>
                                {((sourceData && sourceData.name === "Others")|| sourceData.label==="Others") && <div>
                                    
                                    <div className='labels ml-10' style={{ paddingLeft: "10px", marginLeft: "10px" }}> Please Specify,if others <span>*</span></div>
                                    <input className='inputBlock ml-20' placeholder='Please specify other source' name='others' value={others} onChange={this.handleChange} />
                                </div>}
                            </div>
                        </div>}

                    </div>
                    <div className={'error error-font'}>{this.state.validate_error}</div>
                    <div className="submit-section">
                        <div className="button-section p-5">
                            <button className="button-section1 p-5  w-200" onClick={props.close}>Cancel</button>
                        </div>
                        {this.props.companyApp ? <>
                            <div className="button-section p-5">
                                <button className="button-section1 sections mr-5  p-5 w-200" onClick={this.retriggerEmail}>Resend Email</button>
                            </div>
                            <div className="button-section p-5">
                                <button className="button-section1 sections p-5 w-200" onClick={this.updateCompanySubmit}>Update</button>
                            </div>

                        </> :
                            <div className="button-section p-5">
                                <button className="button-section1 sections p-5 w-200" onClick={this.addCompanySubmit}>Save</button>
                            </div>}
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loader: state.loader,
        vendors: state.vendors,
        msgpopup: state.msgpopup,
        vendorForm: state.vendorForm
    };
};

export default connect(mapStateToProps)(NewVendor);

