import React, { Component } from 'react';
import Button from "../../button-classic/button-classic";
import AddMove from "./add-move/add-move";
import AddAction from "./add-action/add-action";
import AddChecklist from "./add-checklist/add-checklist";
import AddFields from "./add-fields/add-fields";
import AddOperationTime from "./add-operationTime/add-operationTime";
import { toPascalCase } from "../../../../utils/validate";
import { getCookie } from "../../../../utils/cookies";
import CheckBox from "../../../components/checkbox/checkbox";
import Edit from '../../../../assets/images/edit.svg';
import Delete from '../../../../assets/images/delete.svg';
import { AWL_COMPANY_IDS, isAWL, isBeams } from "../../../../utils/common";
import Volumetric from './volumetric/Volumetric';
import { connect } from 'react-redux';
import AddReason from './add-reasons/add-reason';
import "./../home-navigation-panel.less";
import InventoryMaster from './Inventory master/InventoryMaster';
import DebitNote from './debit-note/debit-note';
import DeliveryCharges from './delivery-charges/deliveryCharges';


class NavigationConfigure extends Component {
	constructor(props) {
		super(props);
		this.state = {
			move: "",
			offset: 0,
			isupdated: true,
			navigationOptions: this.companywiseNavigationOptions(),
			selectedOption: "1",
			editData: {},
			editReason: {},
			isReasonUpdated: false
		}
	}
	companywiseNavigationOptions=()=>{
		if(isAWL()){
			return [
				{
					heading: "Moves",
					id: "1"
				},
				{
					heading: "Actions",
					id: "2"
				},
				{
					heading: "Checklists",
					id: "3"
				},
				{
					heading: "Fields",
					id: "4"
				},
				{
					heading: "Volumetric",
					id: "5"
				},
				{
					heading: "Inventory Master",
					id: "8"
				}								
			]
		}
		if(isBeams()){
			return [
				{
					heading: "Moves",
					id: "1"
				},
				{
					heading: "Actions",
					id: "2"
				},
				{
					heading: "Checklists",
					id: "3"
				},
				{
					heading: "Fields",
					id: "4"
				},
				{
					heading: "Volumetric",
					id: "5"
				},
				{
					heading: "Operation Time",
					id: "6"
				},
				{
					heading: "Reason",
					id: "7"
				},
				{
					heading: "Inventory Master",
					id: "8"
				}, 
				{
					heading: "Debit Note",
					id: "9"
				},
				{
					heading: "Delivery Charges",
					id: "10"
				}							
			]
		} 
		else{
			return [
				{
					heading: "Moves",
					id: "1"
				},
				{
					heading: "Actions",
					id: "2"
				},
				{
					heading: "Checklists",
					id: "3"
				},
				{
					heading: "Fields",
					id: "4"
				},
				{
					heading: "Volumetric",
					id: "5"
				},
				{
					heading: "Operation Time",
					id: "6"
				},
				{
					heading: "Reason",
					id: "7"
				},
				{
					heading: "Inventory Master",
					id: "8"
				}, 
				{
					heading: "Debit Note",
					id: "9"
				}							
			]
		}

	}
	setOption = (option) => {
		this.setState({
			selectedOption: option
		});
		sessionStorage.setItem('selectedConfigurationTab', option || '1');
	};
	componentDidMount() {
		// if(this.state.isupdated==true){
		const selectedTab=sessionStorage.getItem('selectedConfigurationTab') || '1' 
		this.getMoves(null);
		this.getActions(null);
		this.getChecklists(null);
		this.getFields(null);
		this.getIndentOperationTime(null);
		this.getReasons(null);
		this.setState({ isupdated: false,selectedOption:selectedTab });
		// }
	}	
	async updatedata() {
		if (this.state.selectedOption == "1") {
			this.getMoves(null);
		}
		if (this.state.selectedOption == "2") {
			this.getActions(null);
		}
		if (this.state.selectedOption == "3") {
			this.getChecklists(null);
		}
		if (this.state.selectedOption == "4") {
			this.getFields(null);
		}
		if (this.state.selectedOption == "6") {
			this.getIndentOperationTime(null);
		}
		if (this.state.selectedOption == "5") {
			// this.getVolumetric(null);	
		}
		if (this.state.selectedOption == "7") {
			this.getReasons(null);
		}
		
	}
	// async componentDidUpdate() {
	// let {isupdated} = this.state;
	// if(isupdated==true){
	//     await this.updatedata();
	//     this.setState({ isupdated: false });
	// }
	// }
	getMoves = (text) => {
		let data = {
			companyId: this.props.company_id,
		}
		this.props.getMoves(data);
	};
	getActions = (text) => {
		let data = {
			companyId: this.props.company_id,
		}
		this.props.getActions(data);
	};
	getChecklists = (text) => {
		let data = {
			companyId: this.props.company_id,
		}
		this.props.getChecklists(data);
	};
	getFields = (text) => {
		let data = {
			companyId: this.props.company_id,
		}
		this.props.getFields(data);
	};
	getIndentOperationTime = (text) => {
		let data = {
			company_id: this.props.company_id,
		}
		this.props.getIndentOperationTime(data);
	};
	getReasons = (text) => {
		let data = {
			companyId: this.props.company_id,
		}
		this.props.getIndentingReasons(data);
	};
	submitSearch = () => {
		if (this.state.move != null || this.state.move.length > 0)
			this.getMoves(this.state.move);
	};
	clearFilterOnCancel = () => {
		this.setState({ move: "" }, () => {
			this.getMoves(null);
		});
	};
	changeHandler = (val, id) => {
		this.setState({
			[id]: val
		})
	}
	toggleAdd = () => {
		this.setState({
			showAddComponent: !this.state.showAddComponent, isupdated: true
		})
	}
	toggleAdd2 = () => {
		this.setState({
			showAddComponent2: !this.state.showAddComponent2, isupdated: true
		})
	}
	toggleAdd3 = () => {
		this.setState({
			showAddComponent3: !this.state.showAddComponent3, isupdated: true
		})
	}

	toggleAdd4 = () => {
		this.setState({
			showAddComponent4: !this.state.showAddComponent4, isupdated: this.state.showAddComponent4
		})
	}


	toggleAdd6 = (index, data) => {
		let editData = {};
		if(index > -1){
			editData = JSON.parse(JSON.stringify(data));
		}
		this.setState({
			editData, showAddComponent6: !this.state.showAddComponent6, isupdated: this.state.showAddComponent6
		});
	}

	toggleReasonEdit=(index, data, isUpdate) => {
		let editReason = {};
		if(index > -1){
			editReason = JSON.parse(JSON.stringify(data));
		}
		this.setState({
			editReason, showAddComponent7: !this.state.showAddComponent7, isReasonUpdated: isUpdate
		});
	}
	toggleReasonDelete=(reason) => {
		const data = {
			reasonId: reason.reason_id
		}
		this.props.deleteIndentingReasons(data)
	}

	statusChange = (data) => {
		data.is_active = !data.is_active;
		this.props.editOperationTime(data);
	}

	render() {
		const user = JSON.parse(getCookie("user"));
		return (
			<div className="plants-depots-main-wrap">
				<div className="home-navigation-holder home-navigation-holder-sub">
					{this.state.navigationOptions.map((option, index) => {
						if (!AWL_COMPANY_IDS.includes(this.props.company_id) && (option.id == 6 || option.id == 8)) return;
						return (
							<div
								key={index}
								className={
									this.state.selectedOption === option.id
										? "selected-option options"
										: "options"
								}
								onClick={() => this.setOption(option.id)}
							>
								{option.heading}
							</div>
						);
					})}
				</div>
				{this.state.selectedOption == 1 && (
					<div className="moves">
						<div className="search-holder">
							<input class="button-classic" type="button" value="+ New Move" onClick={this.toggleAdd} />
						</div>
						<div className="">
							<h1>Moves</h1>
						</div>
						<div className="heading">
							<div className="depot-users-list depot-users-list-first" >
								<div className="halflist">
									<div className="head usn first">Id</div>
									<div className="head usn second">Name</div>
								</div>
							</div>
							<div className="depot-users-list depot-users-list-second" >
								<div className="halflist">
									<div className="head usn first">Id</div>
									<div className="head usn second">Name</div>
								</div>
							</div>
						</div>
						{this.props.indentingMoves && this.props.indentingMoves.length == 0 &&						
							<div className="depot-users-list no-records"> That Is All, Nothing To Display!
                            </div>
						}

						{
							this.props.indentingMoves && this.props.indentingMoves.map((user, index) => {
								if (index % 2 == 0) {
									return (
										<div className="depot-users-list depot-users-list-first" >
											<div className="halflist">
												<div className="head usn first">{user.move_id}</div>
												<div className="head usn second">{toPascalCase(user.name)}</div>
											</div>
										</div>
									)
								} else {
									return (
										<div className="depot-users-list depot-users-list-second" >
											<div className="halflist">
												<div className="head usn first">{user.move_id}</div>
												<div className="head usn second">{toPascalCase(user.name)}</div>
											</div>
										</div>
									)
								}
							})
						}
						{
							this.state.showAddComponent &&
							<AddMove
								toggleAdd={this.toggleAdd}
								company_id={this.props.company_id}
								addMove={this.props.addMove}
								moveNamesList={this.props.moveNamesList}
								getMoveName={this.props.getMoveName}
							/>
						}
					</div>
				)}
				{this.state.selectedOption == 2 && (
					<div className="actions">
						<div className="search-holder">
							<input class="button-classic" type="button" value="+ New Action" onClick={this.toggleAdd2} />
						</div>
						<div className="">
							<h1>Actions</h1>
						</div>
						<div className="heading">
							<div className="depot-users-list depot-users-list-first" >
								<div className="halflist">
									<div className="head usn first">Id</div>
									<div className="head usn second">Name</div>
									<div className="head usn second">Type</div>
								</div>
							</div>
							<div className="depot-users-list depot-users-list-second" >
								<div className="halflist">
									<div className="head usn first">Id</div>
									<div className="head usn second">Name</div>
									<div className="head usn second">Type</div>
								</div>
							</div>
						</div>
						{this.props.indentingActions && this.props.indentingActions.length == 0 &&
							<div className="depot-users-list no-records"> That Is All, Nothing To Display!
                            </div>
						}
						{
							this.props.indentingActions && this.props.indentingActions.map((user, index) => {
								if (index % 2 == 0) {
									return (
										<div className="depot-users-list depot-users-list-first" >
											<div className="halflist">
												<div className="head usn first">{user.action_id}</div>
												<div className="head usn second">{toPascalCase(user.name)}</div>
												<div className="head usn second">{toPascalCase(user.type)}</div>
											</div>
										</div>
									)
								} else {
									return (
										<div className="depot-users-list depot-users-list-second" >
											<div className="halflist">
												<div className="head usn first">{user.action_id}</div>
												<div className="head usn second">{toPascalCase(user.name)}</div>
												<div className="head usn second">{toPascalCase(user.type)}</div>
											</div>
										</div>
									)
								}
							})
						}
						{
							this.state.showAddComponent2 &&
							<AddAction
								toggleAdd2={this.toggleAdd2}
								company_id={this.props.company_id}
								addAction={this.props.addAction}
								actiontypeslist={this.props.actiontypeslist}
							/>
						}
					</div>
				)}
				{this.state.selectedOption == 3 && (
					<div className="checklists">
						<div className="search-holder">
							<input class="button-classic" type="button" value="+ New Checklist" onClick={this.toggleAdd3} />
						</div>
						<div className="">
							<h1>Checklists</h1>
						</div>
						<div className="heading">
							<div className="depot-users-list depot-users-list-first" >
								<div className="halflist">
									<div className="head usn first">Id</div>
									<div className="head usn second">Name</div>
								</div>
							</div>
							<div className="depot-users-list depot-users-list-second" >
								<div className="halflist">
									<div className="head usn first">Id</div>
									<div className="head usn second">Name</div>
								</div>
							</div>
						</div>
						{this.props.indentingChecklists && this.props.indentingChecklists.length == 0 &&
							<div className="depot-users-list no-records"> That Is All, Nothing To Display!
                            </div>
						}
						{
							this.props.indentingChecklists && this.props.indentingChecklists.map((user, index) => {
								if (index % 2 == 0) {
									return (
										<div className="depot-users-list depot-users-list-first" >
											<div className="halflist">
												<div className="head usn first">{user.checklist_id}</div>
												<div className="head usn second">{toPascalCase(user.name)}</div>
											</div>
										</div>
									)
								} else {
									return (
										<div className="depot-users-list depot-users-list-second" >
											<div className="halflist">
												<div className="head usn first">{user.checklist_id}</div>
												<div className="head usn second">{toPascalCase(user.name)}</div>
											</div>
										</div>
									)
								}
							})
						}
						{
							this.state.showAddComponent3 &&
							<AddChecklist
								toggleAdd3={this.toggleAdd3}
								company_id={this.props.company_id}
								addChecklist={this.props.addChecklist}
							/>
						}
					</div>
				)}
				{this.state.selectedOption == 4 && (
					<div className="checklists">
						<div className="search-holder">
							<input class="button-classic" type="button" value="+ New Fields" onClick={this.toggleAdd4} />
						</div>
						<div className="">
							<h1>Fields</h1>
						</div>
						<div className="heading">
							<div className="depot-users-list depot-users-list-first" >
								<div className="halflist">
									<div className="head usn first">Id</div>
									<div className="head usn second">Name</div>
								</div>
							</div>
							<div className="depot-users-list depot-users-list-second" >
								<div className="halflist">
									<div className="head usn first">Id</div>
									<div className="head usn second">Name</div>
								</div>
							</div>
						</div>
						{this.props.indentingFields && this.props.indentingFields.length == 0 &&
							<div className="depot-users-list no-records"> That Is All, Nothing To Display!
                            </div>
						}
						{
							this.props.indentingFields && this.props.indentingFields.map((user, index) => {
								if (index % 2 == 0) {
									return (
										<div className="depot-users-list depot-users-list-first" >
											<div className="halflist">
												<div className="head usn first">{user.field_id}</div>
												<div className="head usn second">{toPascalCase(user.fieldTitle)}</div>
											</div>
										</div>
									)
								} else {
									return (
										<div className="depot-users-list depot-users-list-second" >
											<div className="halflist">
												<div className="head usn first">{user.field_id}</div>
												<div className="head usn second">{toPascalCase(user.fieldTitle)}</div>
											</div>
										</div>
									)
								}
							})
						}
						{
							this.state.showAddComponent4 &&
							<AddFields
								toggleAdd4={this.toggleAdd4}
								company_id={this.props.company_id}
								addFields={this.props.addFields}
							/>
						}
					</div>
				)}
				{this.state.selectedOption == 5 && (
					<Volumetric plantsDepots={this.props.plantsDepots} companyId={this.props.company_id} />				
				)}
				{this.state.selectedOption == 8 && (

					<InventoryMaster plantsDepots={this.props.plantsDepots} companyId={this.props.company_id} />
				)}
				{this.state.selectedOption == 6 && (
					<div className="checklists">
						<div className="search-holder">
							<input class="button-classic" type="button" value="+ New Operation Time" onClick={this.toggleAdd6} />
						</div>
						<div className="mb-10">
							<h1>Operation Time</h1>
						</div>
						<div className="multifield">
								<div className="name">Branch Name</div>
								<div className="code " style={{width: '100px'}}>Code</div>
								<div className="fields ">Shift Start</div>
								<div className="fields ">Shift End</div>
								<div className="code">Working Hours</div>
								<div className="code">Shift</div>
								<div className="code">Placement Time From</div>
								<div className="code">Placement Time To</div>
								<div className="fields">Status</div>
								<div className="fields">Action</div>
						</div>
						{this.props.indentingOperationTime && this.props.indentingOperationTime.length == 0 &&
							<div className="depot-users-list no-records"> That Is All, Nothing To Display!
                            </div>
						}
						{
							this.props.indentingOperationTime && this.props.indentingOperationTime.map((opt, index) => {
								return (
										<div className="field-details" >
											<div className="name">{opt.branch_name || '-'}</div>
											<div className="code ">{opt.plant_code || '-'}</div>
											<div className="fields ">{opt.shift_start_time || '-'}</div>
											<div className="fields ">{opt.shift_end_time || '-'}</div>
											<div className="code ">{opt.total_hrs_working || '-'}</div>
											<div className="code">{opt.shift || '-'}</div>
											<div className="code">{opt.placement_time_from || '-'}</div>
											<div className="code">{opt.placement_time_to || '-'}</div>
											<div className="fields ">
												<CheckBox type={user.company_type} status={opt.is_active} user={user} id={index} handleChange={() => this.statusChange(opt)}/>
											</div>
											<div className="fields">
												<div className={'delete-icon'} style={{backgroundImage: `url(${Edit})`}} onClick={() => this.toggleAdd6(index,opt)}></div>
                                        		<div className={'delete-icon'} style={{backgroundImage: `url(${Delete})`}} onClick={() => this.props.deleteOperationTime(opt)}/>
                                        	</div>
										</div>
									)
							})
						}
						{
							this.state.showAddComponent6 &&
							<AddOperationTime
								toggleAdd6={this.toggleAdd6}
								company_id={this.props.company_id}
								addOperationTime={this.props.addOperationTime}
								editOperationTime={this.props.editOperationTime}
								deleteOperationTime={this.props.deleteOperationTime}
								editData={this.state.editData}
							/>
						}
					</div>
				)}
				{
					this.state.selectedOption == 9 && 
					<DebitNote 
						company_id={this.props.company_id}
					/>
				}
				{
					this.state.selectedOption == 10 && isBeams(this.props.company_id) &&
					<DeliveryCharges
						company_id={this.props.company_id}
					/>
				}
				{this.state.selectedOption == 7 && (
					<div className="moves">
						<div className="search-holder">
							<input class="button-classic" type="button" value="+ New Reasons" onClick={this.toggleReasonEdit} />
						</div>
						<div className="">
							<h1>Reasons</h1>
						</div>
						<div className="heading" style={{marginLeft: '5px'}}>
							<div className="depot-users-list wt-100p" >
								<div className="flex wt-100p">
									<div className="head usn" style={{width: '25%'}}>Id</div>
									<div className="head usn" style={{width: '25%'}}>Name</div>
									<div className="head usn" style={{width: '25%'}}>Type</div>
									<div className="head usn" style={{width: '25%'}}>Actions</div>
								</div>
							</div>
						</div>
						{this.props.indentingReasons && this.props.indentingReasons.length == 0 &&						
							<div className="depot-users-list no-records"> That Is All, Nothing To Display!
                            </div>
						}
						{
							this.props.indentingReasons && this.props.indentingReasons.map((reason, index) => {
								return (
									<div className="depot-users-list wt-100p" >
										<div className=" flex wt-100p">
										<div className="head usn" style={{width: '25%'}}>{reason.type}</div>
										<div className="head usn" style={{width: '25%'}}>{reason.name}</div>
										<div className="head usn" style={{width: '25%'}}>{reason.type_name}</div>
										<div className="head usn flex" style={{width: '25%'}}>
											<div className={'delete-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.toggleReasonEdit(index, reason, true)}></div>
                              				<div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleReasonDelete(reason)}></div>
										</div>
									</div>
								</div>
								)
							})
						}
						{
							this.state.showAddComponent7 &&
							<AddReason
								toggleAdd7={this.toggleReasonEdit}
								company_id={this.props.company_id}
								addIndentingReason={this.props.addIndentingReasons}
								editReason={this.state.editReason}
								isReasonUpdated={this.state.isReasonUpdated}
								editIndentingReasons={this.props.editIndentingReasons}
							/>
						}
					</div>
				)}
			</div>
		);
	}
}
const mapStateToProps = state => {
    return {
        plantsDepots: state.plantsDepots|| [],
        home: state.home,
    };
};

export default connect(mapStateToProps) (NavigationConfigure);
