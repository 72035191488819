import React, { Component } from 'react';
import './reconciliation.less';
import CreditMaster from './credit-master/credit-master';
import DebitMaster from './debit-master/debit-master';
import AccountMaster from './account-master/account-master';
import { connect } from 'react-redux';
import ACTIONS from '../../common/action_constant';
import Loader from "../../common/components/loader/loader";
import MSGPopUp from "../../common/components/msg-popup/msg-popup";
import { modeLoadType, modeType, loadType } from "../../../utils/constants";

class Reconciliation extends Component {
    constructor(props){
        super(props);
        this.state = {
            Tabs : [
                {label: "Credit Master", id: "1"},
                {label: "Debit Master", id: "2"},
                {label: "Account Master", id: "3"}
            ],
            // mainTabs : [
            //     {label: "FTL Road", id: 1},
            //     {label: "FTL Train", id: 2},
            //     {label: "LCL Road", id: 3},
            //     {label: "LCL Train", id: 4},
            //     {label: "Courier Road", id: 5},
            //     {label: "Courier Air", id: 6}
            // ],
            selectedOption : "1",
            mainTabOption : 1
        }
    }

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.GET_VEHICLE_DETAILS });
        dispatch({ type : ACTIONS.PLANTS.GET_ZONES });
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.GET_STATE_LIST });
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.GET_ZIPCODE_LIST });
        this.getTransporterList();
        this.getConfiguredVehicles();
    }

    getMaterailDetails = (type) => {
        const param = type || '';
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.GET_MATERIAL_DETAILS, param, companyId : this.props.company_id });
    }

    getProductDetails = (search='',count=50) => {
        const { dispatch, company_id } = this.props;
        let param = `${company_id}&count=${count}&offset=0`; {/* more the count more time for the dropdown to load */}
        if(!!search){
            param = `${company_id}&count=${count}&offset=0&search=${search}`
        }
		dispatch({ type : ACTIONS.MASTER.INVENTORY_ITEM.GET_INVENTORY_ITEM, param });
    }

    onTabChange = (id) => {
        this.setState({ selectedOption: id });
    }

    onMainTabChange = (id) => {
        this.setState({ mainTabOption : id });
    }

    getModeAndLoadType = () => {
        const { mainTabOption } = this.state;
        const loadModeType =  modeLoadType.find(val =>  val.value === mainTabOption).label.split(" ");
        const loadValue = loadType.find(val => loadModeType[0] === val.label).value;
        const modeValue = modeType.find(val => loadModeType[1] === val.label).value;
        return `loadType=${loadValue}&modeType=${modeValue}`;

    }

    getCreditMaster = (isType) => {
        const param = `${this.props.company_id}&type=${isType}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch( {type : ACTIONS.COMPANY_ACCOUNTS.GET_CREDIT_MASTER, param : param , typeId : isType});
    }

    addCreditDetails = (data , isType) => {
        const param = `${this.props.company_id}&type=${isType}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.POST_CREDIT_MASTER, param : param, data : data, typeId: isType });
    }

    updateCreditDetails = (data, isType) => {
        const param = `${this.props.company_id}&type=${isType}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.UPADTE_CREDIT_MASTER, param : param, data : data, typeId: isType });
    }

    deleteCreditMaster = (value, isType) => {
        const param = `${this.props.company_id}&type=${isType}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch( { type: ACTIONS.COMPANY_ACCOUNTS.DELETE_CREDIT_MASTER, data: isType , param , companyId : this.props.company_id , input : { credit_master_id : value }})
    }

    getDebitMaster = (id) => {
        const param = `${this.props.company_id}&type=${id}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.GET_DEBIT_MASTER, param : param , data: id});
    }

    getAccountEvents = (id) => {
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.MASTER.ACCOUNTS.GET_ACCOUNT_DETAILS, data : id  });
    }

    addDebitMaster = ( data ) => {
        const param = `${this.props.company_id}&type=${data.type}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.POST_DEBIT_MASTER, data, param : param, companyId : this.props.company_id  });
    }

    updateDebitMaster = (data) => {
        const param = `${this.props.company_id}&type=${data.type}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.UPDATE_DEBIT_MASTER, data, param : param, companyId : this.props.company_id  });
    }

    deleteDebitMaster = ( value, isType ) => {
        const param = `${this.props.company_id}&type=${isType}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch( { type: ACTIONS.COMPANY_ACCOUNTS.DELETE_DEBIT_MASTER, data: isType , param , companyId : this.props.company_id , input : { id : value }})
    }

    addCompanyAccounts = ( data ) => {
        const param = `${this.props.company_id}&type=${data.type}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.POST_ACCOUNTS_DETAILS, data, param : param, companyId : this.props.company_id  });
    }

    updateCompanyAccounts = (data) => {
        const param = `${this.props.company_id}&type=${data.type}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.UPDATE_ACCOUNTS_DETAILS, data, param : param, companyId : this.props.company_id  });
    }

    getCompanyAccounts = (id) => {
        const param = `${this.props.company_id}&type=${id}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.GET_ACCOUNTS_DETAILS, param : param , data: id});
    }

    deleteCompanyAccounts = ( value, isType ) => {
        const param = `${this.props.company_id}&type=${isType}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch( { type: ACTIONS.COMPANY_ACCOUNTS.DELETE_ACCOUNTS_DETAILS, data: isType , param , companyId : this.props.company_id , input : { id : value }})
    }

    getBillNumberDetails = () => {
        const query = `${this.props.company_id}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch({ type : ACTIONS.COMPANY_ACCOUNTS.GET_BILL_NUMBER_DETAILS , data : this.props.company_id, query });
    }

    addBillNumber = (data) => {
        const query = `${this.props.company_id}&${this.getModeAndLoadType()}`
        const { dispatch } = this.props;
        dispatch({ type : ACTIONS.COMPANY_ACCOUNTS.POST_BILL_NUMBER_DETAILS, data , companyId : this.props.company_id, query });
    }

    delteBillNumber = (data) => {
        const { dispatch } = this.props;
        const query = `${this.props.company_id}&${this.getModeAndLoadType()}`;
        const param = `${query}&transporterName=${data}`
        dispatch({ type : ACTIONS.COMPANY_ACCOUNTS.DELETE_BILL_NUMBER, data : param , companyId : this.props.company_id, query });
    }

    updateBillNumber = (data) => {
        const { dispatch } = this.props;
        const query = `${this.props.company_id}&${this.getModeAndLoadType()}`;
        const param = `${query}&transporterName=${data.transporter_name}`
        dispatch({ type : ACTIONS.COMPANY_ACCOUNTS.UPDATE_BILL_NUMBER_DETAILS, param ,data , companyId : this.props.company_id, query});
    }

    closeMsgPopup = () =>{
        const {dispatch} = this.props;
        dispatch({ type : ACTIONS.MSG_DISPLAY.HIDE_MSG })
    }

    getDetentionDetails = () => {
        const param = `${this.props.company_id}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.GET_DETENTION_DETAILS, companyId: this.props.company_id , param });
    }
    
    addDetentionDetails = (data) => {
        const param = `${this.props.company_id}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.ADD_DETENTION_DETAILS, data, companyId: this.props.company_id, param });
    }

    updateDetentionDetails = (data) => {
        const param = `${this.props.company_id}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.UPDATE_DETENTION_DETAILS, data, companyId: this.props.company_id, param });
    }

    deleteDetentionDetails = (id) => {
        const { dispatch } = this.props;
        const param = `${this.props.company_id}&${this.getModeAndLoadType()}`;
        const data = `${this.props.company_id}&detention_id=${id}`;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.DELETE_DETENTION_DETAILS, data, companyId: this.props.company_id, param });
    }

    getMultiPickUpDropDetails = () => {
        const param = `${this.props.company_id}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.GET_MULTI_PICKUP_DETAILS, companyId: this.props.company_id, param});
    }

    updateMultiPickUpDropDetails = (data) => {
        const param = `${this.props.company_id}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.UPDATE_MULTI_PICKUP_DETAILS, companyId: this.props.company_id, data, param });
    }

    addMultiPickUpDropDetails = (data) => {
        const param = `${this.props.company_id}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.ADD_MULTI_PICKUP_DETAILS, companyId: this.props.company_id, data, param });
    }

    uploadHolidayListDetails = (data) => {
        const param = `${this.props.company_id}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.UPLOAD_HOLIDAY_LIST, companyId: this.props.company_id , data, param });
    }

    getHolidayListDetails = () => {
        const { dispatch } = this.props;
        const param = `${this.props.company_id}&${this.getModeAndLoadType()}`;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.GET_HOLIDAY_LIST, companyId: this.props.company_id, param });
    }

    getTransporterList = (search = '') => {
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.GET_TRANSPORTER_LIST , search });
    }

    getPayables = (isType) => {
        const param = `${this.props.company_id}&type=${isType}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch( {type : ACTIONS.COMPANY_ACCOUNTS.GET_CREDIT_MASTER_PAYABLES, param : param , typeId : isType});
    }

    addPayables = (data , isType) => {
        const param = `${this.props.company_id}&type=${isType}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.ADD_CREDIT_MASTER_PAYABLES, param : param, data : data, typeId: isType });
    }

    updatePayables = (data, isType) => {
        const param = `${this.props.company_id}&type=${isType}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.UPDATE_CREDIT_MASTER_PAYABLES, param : param, data : data, typeId: isType });
    }

    deletePayables = (value, isType) => {
        const param = `${this.props.company_id}&type=${isType}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch( { type: ACTIONS.COMPANY_ACCOUNTS.DELETE_CREDIT_MASTER_PAYABLES, data: isType , param , companyId : this.props.company_id , input : { payable_id : value }})
    }

    // uploadODARSACharges = (data, isType) => {
    //     const param = `${this.props.company_id}&type=${isType}&${this.getModeAndLoadType()}`;
    //     const { dispatch } = this.props;
    // }

    // downloadODARSACharges = (data, isType) => {
    //     const param = `${this.props.company_id}&type=${isType}&${this.getModeAndLoadType()}`;
    //     const { dispatch } = this.props;
    // }

    getShortagePilferage = () => {
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.GET_SHORTAGE_PILFERAGE_TOLERANCE_LIST, companyId : this.props.company_id });
    }

    addShortagePilferage = (data, isUpdate) => {
        const { dispatch, company_id } = this.props;
        if(!isUpdate) {
            data['company_id'] = company_id;
        }
        data.product =( data.product ||[]).map((ele)=>{
            const newEle = {
                ...ele
            }
            delete newEle.label;
            return newEle
        })
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.SAVE_SHORTAGE_PILFERAGE_TOLERANCE, data, isUpdate, onSuccess: this.getShortagePilferage });
    }

    deleteShortagePilferage = (data) => {
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.DELETE_SHORTAGE_PILFERAGE_TOLERANCE, data, onSuccess: this.getShortagePilferage });
    }

    getConfiguredVehicles = () => {
        const { dispatch } = this.props;
        let data = {
            company_id: this.props.company_id,
            branch_id: this.props.company_id
        }
        dispatch({ type: ACTIONS.CONTRACTS.GET_MASTER_CONTRACT_DATA, data, onSuccess: (details) => {
            if(details && details.vehicle_types && details.vehicle_types[0].vehicle_types_list && details.vehicle_types[0].vehicle_types_list.length > 0){
                this.setState({configuredVehicles: details.vehicle_types[0].vehicle_types_list})
            }
        } });
    }

    addChargeDetails = (data , isType) => {
        const param = `${this.props.company_id}&type=${isType}&${this.getModeAndLoadType()}`;
        const { dispatch } = this.props;
        dispatch({ type: ACTIONS.COMPANY_ACCOUNTS.POST_CREDIT_MASTER, param : param, data : data, typeId: isType });
    }

    render() {
        return (
            <div className={"reconciliation"}>
                {
					this.props.loader.isLoading && <Loader/>
                }
                {
					this.props.msgpopup.active && <MSGPopUp close = {this.closeMsgPopup} type = {this.props.msgpopup.type} msg = {this.props.msgpopup.msg}/>
                }
                <div className={"home-navigation-holder mainTabs"}>
                    {
                        modeLoadType.map(tab => {
                        return(<div key={tab.value} className={this.state.mainTabOption === tab.value
                            ? "selected-option options"
                            : "options"} onClick={() => this.onMainTabChange(tab.value)}>{tab.label}</div>)
                        })
                    }
                </div>
                <div className={"home-navigation-holder"}>
                    {
                        this.state.Tabs.map(tab => {
                        return(<div key={tab.id} className={this.state.selectedOption === tab.id
                            ? "selected-option options"
                            : "options"} onClick={() => this.onTabChange(tab.id)}>{tab.label}</div>)
                        })
                    }
                </div>
                {this.state.selectedOption == "1" && <CreditMaster 
                                                        fuelDetails={this.props.fuelDetails} 
                                                        freightRateDetails={this.props.freightRateDetails}
                                                        minFreightRateDetails={this.props.minFreightRateDetails}
                                                        data={this.props.companyAccountsDetails} 
                                                        getCreditMaster={this.getCreditMaster} 
                                                        deleteCreditMaster={this.deleteCreditMaster}
                                                        getMultiPickUpDropDetails={this.getMultiPickUpDropDetails}
                                                        updateMultiPickUpDropDetails={this.updateMultiPickUpDropDetails}
                                                        addMultiPickUpDropDetails={this.addMultiPickUpDropDetails}
                                                        multiPickUp={this.props.multiPickUp}
                                                        addCreditDetails={this.addCreditDetails}
                                                        updateCreditDetails={this.updateCreditDetails}
                                                        mainTabOption={this.state.mainTabOption}
                                                        getTransporterList={this.getTransporterList}
                                                        transporterList={this.props.transporterList}
                                                        zones={this.props.zones}
                                                        getPayables={this.getPayables}
                                                        addPayables={this.addPayables}
                                                        updatePayables={this.updatePayables}
                                                        deletePayables={this.deletePayables}
                                                        zipCodes={this.props.zipCodes}
                                                        loadingCharges={this.props.loadingCharges}
                                                        getConfiguredVehicles={this.getConfiguredVehicles}
                                                        configuredVehicles={this.state.configuredVehicles}
                                                        addChargeDetails={this.addChargeDetails}
                                                        /> }
                {this.state.selectedOption == "2" && <DebitMaster  
                                                        getDebitMaster={this.getDebitMaster} 
                                                        getCreditMaster={this.getCreditMaster} 
                                                        addCreditDetails={this.addCreditDetails} 
                                                        updateCreditDetails={this.updateCreditDetails}
                                                        deleteCreditMaster={this.deleteCreditMaster} 
                                                        accountData={this.props.companyAccountsDetails} 
                                                        getAccountEvents={this.getAccountEvents} 
                                                        eventDetails={this.props.eventDetails} 
                                                        addDebitMaster={this.addDebitMaster} 
                                                        updateDebitMaster={this.updateDebitMaster}
                                                        deleteDebitMaster={this.deleteDebitMaster} 
                                                        // materialList={this.props.companyAccountsDetails.materialList}
                                                        getDetentionDetails={this.getDetentionDetails}
                                                        detentionDetails={this.props.detentionDetails}
                                                        addDetentionDetails={this.addDetentionDetails}
                                                        updateDetentionDetails={this.updateDetentionDetails}
                                                        deleteDetentionDetails={this.deleteDetentionDetails}
                                                        materialList={this.props.materialList}
                                                        holidayDownlUrl={this.props.holidayDownlUrl}
                                                        getMaterailDetails={this.getMaterailDetails}
                                                        mainTabOption={this.state.mainTabOption}
                                                        transporterList={this.props.transporterList}
                                                        getTransporterList={this.getTransporterList}
                                                        getShortagePilferage={this.getShortagePilferage}
                                                        getProductDetails={this.getProductDetails}
                                                        productList={this.props.productList}
                                                        addShortagePilferage={this.addShortagePilferage}
                                                        deleteShortagePilferage={this.deleteShortagePilferage}
                                                        companyId={this.props.company_id}
                                                        
                                                    /> }
                {this.state.selectedOption == "3" && <AccountMaster 
                                                        getCompanyAccounts={this.getCompanyAccounts} 
                                                        accountData={this.props.companyAccountsDetails} 
                                                        addCompanyAccounts={this.addCompanyAccounts} 
                                                        updateCompanyAccounts={this.updateCompanyAccounts}
                                                        deleteCompanyAccounts={this.deleteCompanyAccounts} 
                                                        updateBillNumber={this.updateBillNumber} 
                                                        getAccountEvents={this.getAccountEvents} 
                                                        eventDetails={this.props.eventDetails} 
                                                        getBillNumberDetails={this.getBillNumberDetails} 
                                                        addBillNumber={this.addBillNumber} 
                                                        delteBillNumber={this.delteBillNumber}
                                                        uploadHolidayListDetails={this.uploadHolidayListDetails}
                                                        getHolidayListDetails={this.getHolidayListDetails}
                                                        holidayList={this.props.holidayList}
                                                        holidayDownlUrl={this.props.holidayDownlUrl}
                                                        mainTabOption={this.state.mainTabOption}
                                                        /> }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        fuelDetails : state.companyAccounts.fuelDetails,
        loader : state.loader,
        msgpopup : state.msgpopup,
        companyAccountsDetails : state.companyAccounts,
        eventDetails : state.master,
        detentionDetails : state.companyAccounts.detentionDetails,
        multiPickUp : state.companyAccounts.multiPickUp,
        materialList : state.companyAccounts.materialList,
        holidayList : state.companyAccounts.holidayList,
        holidayDownlUrl : state.companyAccounts.holidayDownlUrl,
        freightRateDetails : state.companyAccounts.freightRateDetails,
        minFreightRateDetails: state.companyAccounts.minFreightRateDetails,
        transporterList : state.companyAccounts.transporterList,
        zones: state.plantsDepots.zones,
        zipCodes : state.companyAccounts.zipCodes,
        productList: state.master.itemList,
        loadingCharges: state.companyAccounts.loadingCharges,
        configuredVehicles: ((state.contracts || {}).masterDetails || {}).vehicle_types
    };
  };  

export default connect(mapStateToProps)(Reconciliation);



