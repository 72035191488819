import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ACTION from "../../common/action_constant";
import "./liabilityStructure.less";

import MainComponent from "../../home/main/main";
import Button from "../../home/button-classic/button-classic";
import Loader from "../../common/components/loader/loader";
import CheckBox from "./../../components/checkbox/checkbox";
import InputText from "../../common/components/input-text/input-text";
import SearchText from "../../common/components/search-text/search-text";
import MSGPopUp from "../../common/components/msg-popup/msg-popup";
import { getCookie } from "../../../utils/cookies";

import AddLiability from "./addLiability";

import Save from '../../../assets/images/save.svg'
import Cancel from '../../../assets/images/cancel.svg'
import Edit from '../../../assets/images/edit.svg'

import Select from "react-select";

const DELAY_UOM_TYPES = [{ id: 2, label: 'Efficiency', value: 2 },
{ id: 1, label: 'Days', value: 1 }];

const SLAB_ITEM = { max: null, min: null, penalty_percentage: null, isBusinessStop: false };

class LiabilityStructure extends Component {
   constructor(props) {
      super(props);
      this.state = {
         addItemPopup: false,
         typeUOM: DELAY_UOM_TYPES[0],
         selectedLiabilities: [],
         addedData: {},
         isAdded: false,
         searchString: '',
         liabilityData: (props.dataGovernance && props.dataGovernance.liabilityData) ? props.dataGovernance.liabilityData : [],
      }
      this.scrollToDiv = [];
   }

   componentDidMount() {
      this.getVendorsData();
      this.getModeTypesData();
      this.getClaimTypes();
      this.getShortageTypes();
      this.getLiabilityData();
   }

   componentWillReceiveProps(nextProps) {
      const { dataGovernance } = this.props;

      if (dataGovernance !== nextProps.dataGovernance) {
         if (dataGovernance.liabilityData !== nextProps.dataGovernance.liabilityData) {
            this.setState({
               liabilityData: nextProps.dataGovernance.liabilityData
            });
         }
      }
   }

   getClaimTypes = () => {
      const { dispatch } = this.props;
      dispatch({
         type: ACTION.DATA_GOVERNANCE.GET_CLAIM_TYPES,
      })
   }

   getShortageTypes = () => {
      const { dispatch } = this.props;
      dispatch({
         type: ACTION.DATA_GOVERNANCE.GET_SHORTAGES_TYPES,
      })
   }

   getLiabilityData = () => {
      const { dispatch } = this.props;
      const user = JSON.parse(getCookie("user"));
      let inputData = {
         companyId: user.company_id
      }
      if (this.state.searchString) {
         inputData.search = this.state.searchString
      }
      dispatch({
         type: ACTION.DATA_GOVERNANCE.GET_LIABILITY_DATA,
         params: inputData,
      })
   }
   
   getModeTypesData = () => {
      const { dispatch } = this.props;
      dispatch({
         type: ACTION.DATA_GOVERNANCE.GET_MODE_TYPES,
         // params: 
      })
      
   }

   getVendorsData = () => {
      const { dispatch } = this.props;
      dispatch({
         type: ACTION.DATA_GOVERNANCE.GET_VENDORS_TYPES,
      })
   }

   showPopUp = () => {
      this.setState({
         addItemPopup: !this.state.addItemPopup
      })
   }

   handleSave = (data) => {
      const { dispatch } = this.props;

      dispatch({
         type: ACTION.DATA_GOVERNANCE.POST_LIABILITY_DATA,
         params: data,
         onSuccess: (() => {
            this.setState({
               isAdded: false,
               addItemPopup: false,
            });
            this.getLiabilityData();
         })
      })
   }

   handleUpdate = (data) => {
      const { dispatch } = this.props;
      dispatch({
         type: ACTION.DATA_GOVERNANCE.UPDATE_LIABILITY_DATA,
         params: data,
         onSuccess: (() => {
            this.setState({
               isAdded: false,
               editId: null,
               addItemPopup: false,
            });
            this.getLiabilityData();
         })
      })
   }

   cancelNewSlab = () => {
      this.setState({
         isAdded: false,
         addItemPopup: false
      });
   }

   editPenalty = (index) => {
      this.setState({
         editId: (this.state.editId === index) ? null : index
      });
   }

   handleCkSelect = (e, id, liabilityId) => {
      const { selectedLiabilities } = this.state;
      if (selectedLiabilities.includes(liabilityId)) {
         selectedLiabilities.splice(liabilityId, 1);
      } else {
         selectedLiabilities.push(liabilityId);
      }
      this.setState({ selectedLiabilities });
   }

   deleteLiabilityData = () => {
      const { delayPenaltyData, selectedLiabilities } = this.state;
      const { dispatch } = this.props;
      if (selectedLiabilities.length > 0) {
         dispatch({
            type: ACTION.DATA_GOVERNANCE.DELETE_LIABILITY,
            params: selectedLiabilities,
            onSuccess: (() => {
               this.getLiabilityData();
               this.setState({ selectedLiabilities: [] })
            })
         });
      }
   }

   downloadLiabilityData = () => {
      const { dispatch } = this.props;
      dispatch({
         type: ACTION.DATA_GOVERNANCE.DOWNLOAD_LIABILITY,
         params: `?companyId=${JSON.parse(getCookie('user')).company_id}&withData=true`,
         onSuccess: ((data) => {
            if (data && data.length > 0) {
               this.setState({ downloadLink: data[0].Link });
               this.downloadBtn.click();
               this.getLiabilityData();
            }
         })
      })
   }

   uploadPenaltyFile = (e) => {
      let formData = new FormData();
      formData.append('file', e.target.files[0]);
      const { dispatch } = this.props;
      dispatch({
         type: ACTION.DATA_GOVERNANCE.UPLOAD_PENALTY,
         data: formData,
         params: `?type=${this.state.typeUOM.value}`,
      });
   }

   closeMsgPopup = () => {
      const { dispatch } = this.props;
      dispatch({ type: ACTION.MSG_DISPLAY.HIDE_MSG });
   }

   handleSearchChange = (val) => {
      this.setState({
         searchString: val
      })
   }
   handleClearFilter = (val) => {
      this.setState({
         searchString: ''
      }, () => this.getLiabilityData())
   }

   handleLoadSearch = () => {
      this.getLiabilityData();
   }
   renderLiabilitiesEdit = (claim, cIndex, sIndex, index) => {
      const { editId } = this.state;
      return (
         <div className="sub-header-inline mb-10 liabilityInput" key={`${claim}-${cIndex}`}>
            {claim.rules.rule} {' '}
            {(claim.rules.range || []).map((range, idx) => {
               // if (claim.rules.range.length > 1) {
               return (<Fragment key={idx}>
                  {
                     (editId === index)
                        ?
                        <Fragment>
                           {
                              // (claim.rules.range || []).map((rule, id) => {
                              // return (
                              <InputText
                                 type="number"
                                 placeholder="No"
                                 value={range || ''}
                                 changeHandler={(value) => {
                                    const { liabilityData } = this.state;
                                    liabilityData[index].types_of_shortages[sIndex].claim_type[cIndex].rules.range[idx] = parseInt(value);
                                    this.setState({ liabilityData }, () => {
                                    });
                                    this.scrollToMyRef(index);
                                 }}
                              />
                              // )
                              // })
                           }
                        </Fragment>
                        :
                        <span> {range} {(idx === 0 && claim.rules.range.length > 1) ? '-' : ''} </span>
                  }
               </Fragment>)
            })}
            {' '} {claim.rules.range_unit}
         </div>
      )
   }

   renderTableBody = (liabilityData) => {
      const { editId, isAdded, selectedLiabilities } = this.state;

      return (
         <Fragment>
            {liabilityData && (liabilityData || []).map((liability, index) => {
               return (
                  <div className="list-section content divTableRow" key={`liab-${liability.liability_id}-${index}`} ref={ele => (this.scrollToDiv[index] = ele)}>
                     <div className="divTableCell select-row">
                        {!isAdded && (index !== editId) &&
                           <input
                              type="checkbox"
                              defaultChecked={selectedLiabilities.includes(liability.liability_id)}
                              onClick={(e) => this.handleCkSelect(e, index, liability.liability_id)}
                           />
                        }
                     </div>
                     <div className="divTableCell"> {liability.company_category} </div>
                     <div className="divTableCell"> {liability.liability_type} </div>
                     <div className="divTableCell"> {liability.vendor_name} </div>
                     <div className="divTableCell"> {liability.vendor_code} </div>
                     <div className="divTableCell"> {liability.mode_name} </div>
                     <div className="divTableCell">
                        {
                           (liability.types_of_shortages || []).map((shortage, sIndex) => {
                              return (
                                 <div className="mb-10" key={`${shortage}-${sIndex}`}>{shortage.shortage_name}</div>
                              )
                           })
                        }
                     </div>
                     <div className="divTableCell rules-tab">
                        {liability.types_of_shortages &&
                           (liability.types_of_shortages || []).map((shortage, sIndex) => {
                              return (
                                 <div key={`${sIndex}-${shortage.shortage_name}`}>
                                    {(shortage.claim_type || []).map((claim, cIndex) => {
                                       return (
                                          <Fragment key={`sh_${cIndex}`}>
                                             {claim.claim_type_id === 1 && <Fragment>{this.renderLiabilitiesEdit(claim, cIndex, sIndex, index)}</Fragment>}
                                             {claim.claim_type_id === 2 && <Fragment>{this.renderLiabilitiesEdit(claim, cIndex, sIndex, index)}</Fragment>}
                                             {claim.claim_type_id === 3 && <Fragment>{this.renderLiabilitiesEdit(claim, cIndex, sIndex, index)}</Fragment>}
                                          </Fragment>
                                       )
                                    })}
                                 </div>
                              )
                           })}
                     </div>

                     <div className="">
                        {(editId === index)
                           ?
                           <Fragment>
                              <div className={'action-icon'} style={{ backgroundImage: `url(${Save})` }} onClick={() => { this.handleUpdate(liability) }} />
                              <div className={'action-icon'} style={{ backgroundImage: `url(${Cancel})` }} onClick={() => { this.setState({ editId: null }) }} />
                           </Fragment>
                           :
                           <div className={'action-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => { this.setState({ editId: index }) }} />
                        }
                     </div>
                  </div>
               )
            })}
         </Fragment>
      )
   }

   scrollToMyRef = (index) => {
      // if (this.state.editId) {
      console.log('dasd', this.scrollToDiv, index, this.scrollToDiv[index].offsetTop);
      setTimeout(() => {
         window.scrollTo(0, this.scrollToDiv[index].offsetTop);
      }, 2000)
      // }
   }

   render() {
      const { addItemPopup, newLiabilityData, liabilityData, isAdded } = this.state;
      const { dataGovernance } = this.props;

      return (
         <div className="liability-wrap">
            <div className="wrapper">
               <MainComponent>
                  {this.props.loader.isLoading && <Loader />}
                  {this.props.msgpopup.active && <MSGPopUp close={this.closeMsgPopup} type={this.props.msgpopup.type} msg={this.props.msgpopup.msg} />}
                  <div className="sub-heading-section">
                     <div className="sub-heading"> Liability Structure </div>
                  </div>

                  <div>
                     <div className="cta-action-btns">

                        {/*<button className="button-classic" onClick={() => {this.uploadExcel.click()}}> Upload Excel </button>
                                <input type='file' style={{ display: 'none'}}  ref={(e) => { this.uploadExcel = e ;}} onChange={this.uploadPenaltyFile} onClick={(e) => {e.target.value = null }}/>*/}

                     </div>
                     <div className="cta-action-btns cta-btn-right">
                        <button className="button-classic" onClick={this.downloadLiabilityData}>Download Template</button>
                        <a className="download-btn" ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
                        <Button value="Add Record" click={this.showPopUp} />
                        <button className="button-classic" onClick={this.deleteLiabilityData}>Delete Record(s)</button>
                        {/*<Button value="Advanced Filters" />*/}
                        <SearchText
                           placeholder="Search By Vendor, Mode"
                           canClear={true}
                           value={this.state.searchString}
                           changeHandler={(e) => this.handleSearchChange(e)}
                           handleClearFilter={(e) => this.handleClearFilter(e)}
                           click={(e) => this.handleLoadSearch(e)}
                        />
                     </div>

                     <div className="divTable company-list-wrap">
                        <div className="list-section table-header divTableHeading divTableRow">
                           <div className="divTableCell select-row"> </div>
                           <div className="divTableCell"> Category </div>
                           <div className="divTableCell"> Liability </div>
                           <div className="divTableCell"> Vendor Name </div>
                           <div className="divTableCell"> Vendor Code </div>
                           <div className="divTableCell"> Mode </div>
                           <div className="divTableCell"> Types Of Shortages </div>
                           <div className="divTableCell rules-tab"> <div>Liability Rules Applicable</div>
                              <div className="sub-header-inline"> Direct Debit </div>
                              <div className="sub-header-inline"> Write Off </div>
                              <div className="sub-header-inline"> Insurance </div>
                           </div>
                           <div className=""> </div>
                        </div>
                        <div className="divTableBody">
                           {this.renderTableBody(liabilityData)}
                        </div>
                     </div>
                     {
                        addItemPopup &&
                        <AddLiability
                           newLiabilityData={newLiabilityData}
                           cancelNewSlab={this.cancelNewSlab}
                           handleSave={this.handleSave}
                           modesList={dataGovernance.modeTypes || []}
                           vendorsList={dataGovernance.vendorTypes || []}
                           claimTypes={dataGovernance.claimTypes || []}
                           shortageTypes={dataGovernance.shortageTypes || []}
                        />
                     }
                  </div>
               </MainComponent>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      loader: state.loader,
      msgpopup: state.msgpopup,
      dataGovernance: state.dataGovernance,
   }
}

export default connect(mapStateToProps, null)(LiabilityStructure);