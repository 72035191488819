import React, { Component, Fragment } from 'react';
import "./bankDetailsPopup.less";
import { getCookie } from "../../utils/cookies";
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { connect } from 'react-redux';
import ACTION from "../common/action_constant";


class BankDetailsPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            security_bank_name: (props.bank_details && props.bank_details.security_bank_name) || '',
            security_amount_date: (props.bank_details && props.bank_details.security_amount_date) || null,
            security_cheque_no: (props.bank_details && props.bank_details.security_cheque_no) || "",
            security_amount: (props.bank_details && props.bank_details.security_amount) || "",
            guarantee_bank_name: (props.bank_details && props.bank_details.guarantee_bank_name) || "",
            bank_guarantee_number: (props.bank_details && props.bank_details.bank_guarantee_number) || "",
            bank_guarantee_date_from: (props.bank_details && props.bank_details.bank_guarantee_date_from) || null,
            bank_guarantee_date_validity: (props.bank_details && props.bank_details.bank_guarantee_date_validity) || null,
            bank_guarantee_return: (props.bank_details && props.bank_details.bank_guarantee_return) || null,
            bank_guarantee_amount: (props.bank_details && props.bank_details.bank_guarantee_amount) || "",
            is_security_deposit: ((props.bank_details && (props.bank_details.is_security_deposit == false)) ? false : true),
            is_bank_guarantee: (props.bank_details && props.bank_details.is_bank_guarantee) || false,
            errorMessage: "",
            courier_name:(props.bank_details && props.bank_details.courier_name) || '',
            courier_docket_no:(props.bank_details && props.bank_details.courier_docket_no) || '',
        }
    }

    onChangeRadio = (e) => {
        this.setState({ errorMessage: "" }); // Clear the error message
        if (e == 'security_deposit') {
            this.setState({ is_security_deposit: true, 
                            is_bank_guarantee: false,
                            guarantee_bank_name:"",
                            bank_guarantee_number:"",
                            bank_guarantee_date_from:"",
                            bank_guarantee_date_validity:"",
                            bank_guarantee_amount:"",
                            bank_guarantee_return:"",
                            courier_name:"",
                            courier_docket_no:""
                         })
        } else {
            this.setState({ is_security_deposit: false, 
                            is_bank_guarantee: true,
                            security_bank_name:"",
                            security_amount_date:"",
                            security_cheque_no:"",
                            security_amount:"",
                        })
        }
    }
    validateInputFields = () => {
        const {
            is_security_deposit,
            is_bank_guarantee,
            security_bank_name,
            security_cheque_no,
            security_amount_date,
            security_amount,
            guarantee_bank_name,
            bank_guarantee_number,
            bank_guarantee_date_from,
            bank_guarantee_date_validity,
            bank_guarantee_amount,
        } = this.state;
    
        if (is_security_deposit) {
            // Check required fields for security deposit
            if (!security_bank_name || !security_cheque_no || !security_amount_date || !security_amount) {
                return false; // Return false if any required field is empty
            }
        } else if (is_bank_guarantee) {
            // Check required fields for bank guarantee
            if (
                !guarantee_bank_name ||
                !bank_guarantee_number ||
                !bank_guarantee_date_from ||
                !bank_guarantee_date_validity ||
                !bank_guarantee_amount
            ) {
                return false; // Return false if any required field is empty
            }
        }
    
        return true; // All required fields are filled
    };
    
    onSaveBankApproverData = () => {
        const isValid = this.validateInputFields();
    
        if (isValid) {
            // Proceed with saving the data
            const { dispatch, vendorForm } = this.props;
            const payload = {
                security_bank_name: this.state.security_bank_name || "",
                security_amount_date: this.state.security_amount_date || null,
                security_cheque_no: this.state.security_cheque_no || "",
                security_amount: this.state.security_amount || "",
                guarantee_bank_name: this.state.guarantee_bank_name || "",
                bank_guarantee_number: this.state.bank_guarantee_number || "",
                bank_guarantee_date_from: this.state.bank_guarantee_date_from || null,
                bank_guarantee_date_validity: this.state.bank_guarantee_date_validity || null,
                bank_guarantee_return: this.state.bank_guarantee_return || null,
                bank_guarantee_amount: this.state.bank_guarantee_amount || "",
                courier_name:this.state.courier_name||"",
                courier_docket_no:this.state.courier_docket_no||"",
                is_security_deposit: this.state.is_security_deposit || false,
                is_bank_guarantee: this.state.is_bank_guarantee || false,
                userId: (vendorForm.vendorDefaultData && vendorForm.vendorDefaultData.userId) || "",
                ob_vendor_id: (vendorForm.vendorDefaultData && vendorForm.vendorDefaultData.ob_vendor_id) || "",
            };
    
            dispatch({
                type: ACTION.VENDORS.SAVE_BANK_APPROVER_DATA,
                payload: payload,
                onSuccess: (() => {
                    this.props.onCancel();
                    this.props.getBasicVendorDetails();
                })
            });
            // Clear the error message
        this.setState({ errorMessage: "" });
        } else {
            // Set the error message
            this.setState({ errorMessage: 'Please fill in all required fields.' });
        }
    };
    
    // onSaveBankApproverData = () => {
    //     const { dispatch, vendorForm } = this.props;
    //     const payload = {
    //         security_bank_name: this.state.security_bank_name || "",
    //         security_amount_date: this.state.security_amount_date || null,
    //         security_cheque_no: this.state.security_cheque_no || "",
    //         security_amount: this.state.security_amount || "",
    //         guarantee_bank_name: this.state.guarantee_bank_name || "",
    //         bank_guarantee_number: this.state.bank_guarantee_number || "",
    //         bank_guarantee_date_from: this.state.bank_guarantee_date_from || null,
    //         bank_guarantee_date_validity: this.state.bank_guarantee_date_validity || null,
    //         bank_guarantee_return: this.state.bank_guarantee_return || null,
    //         bank_guarantee_amount: this.state.bank_guarantee_amount || "",
    //         is_security_deposit: this.state.is_security_deposit || false,
    //         is_bank_guarantee: this.state.is_bank_guarantee || false,
    //         userId: (vendorForm.vendorDefaultData && vendorForm.vendorDefaultData.userId) || "",
    //         ob_vendor_id: (vendorForm.vendorDefaultData && vendorForm.vendorDefaultData.ob_vendor_id) || "",
    //     }

    //     dispatch({
    //         type: ACTION.VENDORS.SAVE_BANK_APPROVER_DATA,
    //         payload: payload,
    //         onSuccess: (() => {
    //             this.props.onCancel()
    //             this.props.getBasicVendorDetails();
    //         })
    //     })
    // }

    renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                    <select
                        value={month.month()}
                        onChange={(e) => onMonthSelect(month, e.target.value)}
                    >
                        {moment.months().map((label, value) => (
                            <option value={value}>{label}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
                        <option value={moment().year()}>{moment().year()}</option>
                        {[1, 2, 3, 4, 5].map((d) => {
                            return (
                                <option value={moment().year() + d}>{moment().year() + d}</option>
                            )
                        })
                        }
                    </select>
                </div>
            </div>)
    }

    renderPastMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                    <select
                        value={month.month()}
                        onChange={(e) => onMonthSelect(month, e.target.value)}
                    >
                        {moment.months().map((label, value) => (
                            <option value={value}>{label}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
                        {[5, 4, 3, 2, 1].map((d) => {
                            return (
                                <option value={moment().year() - d}>{moment().year() - d}</option>
                            )
                        })
                        }
                        <option value={moment().year()}>{moment().year()}</option>
                    </select>
                </div>
            </div>)
    }

    render() {
        const { props, state } = this;
        return (
            <div className="bank-details-popup">
                <div onClick={props.close} className="bank-overlay"></div>
                <div className="bank-details-content">
                    <div className='bank-heading'>Bank Details</div>

                    <div className='bank-details-main'>
                        <div className="bank-details-header">
                            <div className="bank-details-heading">
                                <p>Security Deposite / Bank Guarantee Details</p>
                                <div className='bank-radio-options'>
                                    <div className='bank-radio-option'>
                                        <input
                                            type="radio"
                                            checked={state.is_security_deposit ? true : false}
                                            onChange={() => this.onChangeRadio('security_deposit')}
                                        />
                                        <p style={{ display: 'flex', paddingLeft: '10px', alignItems: 'center', height: '100%', fontWeight: '400', color: '#0A0D17' }}>Security Deposit</p>
                                    </div>


                                    <div className='bank-radio-option'>
                                        <input
                                            checked={state.is_bank_guarantee ? true : false}
                                            type="radio"
                                            onChange={() => this.onChangeRadio('bank_guarantee')}
                                        />
                                        <p style={{ display: 'flex', paddingLeft: '10px', alignItems: 'center', height: '100%', fontWeight: '400', color: '#0A0D17' }}>Bank Guarantee</p>
                                    </div>
                                </div>
                            </div>

                            {state.is_security_deposit &&
                                <div className='bank-details-body'>
                                    <div className='bank-details-row'>
                                        <div className="dInBlock wt-50p">
                                            <div className="black">Bank Name<span className='error'>*</span></div>
                                            <input
                                                type="text"
                                                placeholder="Bank Name"
                                                className="bank-input"
                                                value={this.state.security_bank_name}
                                                onChange={(e) => {
                                                    this.setState({ security_bank_name: e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}0-9]/g, '') })
                                                }}
                                            />
                                        </div>

                                        <div className="black mb-20 wt-20p singleDatePicker-bank">
                                            <div className="black">Cheque Date<span className='error'>*</span></div>
                                            <SingleDatePicker
                                                noBorder={true}
                                                displayFormat={"DD/MM/YYYY"}
                                                hideKeyboardShortcutsPanel={true}
                                                placeholder={"Date"}
                                                numberOfMonths={1}
                                                date={this.state.security_amount_date && moment(this.state.security_amount_date) || null}
                                                onDateChange={(from) => {
                                                    this.setState({ security_amount_date: moment(from).valueOf() || null });
                                                }}
                                                focused={this.state.focused}
                                                onFocusChange={({ focused }) => this.setState({ focused: focused })}
                                                isOutsideRange={() => { return false }}
                                            />
                                        </div>

                                        <div className="dInBlock wt-20p">
                                            <div className="black">Cheque Number<span className='error'>*</span></div>
                                            <input
                                                type="text"
                                                placeholder="Cheque Number"
                                                className="bank-input"
                                                value={this.state.security_cheque_no}
                                                onChange={(e) => {
                                                    if (e.target.value != 0) {
                                                        const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                                        const check = e.target.value.match(regEx);
                                                        if (!check) {
                                                            return
                                                        }
                                                    }
                                                    this.setState({ security_cheque_no: e.target.value });
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className='bank-details-row'>
                                        <div className="dInBlock wt-30p">
                                            <div className="black">Cheque Amount(In Rupees)<span className='error'>*</span></div>
                                            <input
                                                type="text"
                                                placeholder="Enter here"
                                                className="bank-input"
                                                value={this.state.security_amount}
                                                onChange={(e) => {
                                                    if (e.target.value != 0) {
                                                        const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                                        const check = e.target.value.match(regEx);
                                                        if (!check) {
                                                            return
                                                        }
                                                    }
                                                    this.setState({ security_amount: e.target.value });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            {state.is_bank_guarantee &&
                                <div className='bank-details-body'>
                                    <div className='bank-details-row'>
                                        <div className="dInBlock wt-70p">
                                            <div className="black">Bank Name<span className='error'>*</span></div>
                                            <input
                                                type="text"
                                                placeholder="Bank Name"
                                                className="bank-input"
                                                value={this.state.guarantee_bank_name}
                                                onChange={(e) => {
                                                    this.setState({ guarantee_bank_name: e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}0-9]/g, '') })
                                                }}
                                            />
                                        </div>

                                        <div className="dInBlock wt-30p">
                                            <div className="black">Bank Guarantee Number<span className='error'>*</span></div>
                                            <input
                                                type="text"
                                                placeholder="Bank Guarantee Number"
                                                className="bank-input"
                                                value={this.state.bank_guarantee_number}
                                                onChange={(e) => {
                                                    this.setState({ bank_guarantee_number: e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '') });
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className='bank-details-row' style={{ marginTop: '10px' }}>
                                        <div className="black mb-20 wt-30p singleDatePicker-bank">
                                            <div className="black">Bank Guarantee Date<span className='error'>*</span></div>
                                            <SingleDatePicker
                                                noBorder={true}
                                                displayFormat={"DD/MM/YYYY"}
                                                hideKeyboardShortcutsPanel={true}
                                                placeholder={"Date"}
                                                numberOfMonths={1}
                                                date={this.state.bank_guarantee_date_from && moment(this.state.bank_guarantee_date_from) || null}
                                                onDateChange={(from) => {
                                                    this.setState({ bank_guarantee_date_from: moment(from).valueOf() || null });
                                                }}
                                                focused={this.state.focused}
                                                onFocusChange={({ focused }) => this.setState({ focused: focused })}
                                                isOutsideRange={() => { return false }}
                                                renderMonthElement={this.renderPastMonthElement}
                                            />
                                        </div>

                                        <div className="black mb-20 wt-30p singleDatePicker-bank">
                                            <div className="black">Bank Guarantee Validity<span className='error'>*</span></div>
                                            <SingleDatePicker
                                                noBorder={true}
                                                displayFormat={"DD/MM/YYYY"}
                                                hideKeyboardShortcutsPanel={true}
                                                placeholder={"Date"}
                                                numberOfMonths={1}
                                                date={this.state.bank_guarantee_date_validity && moment(this.state.bank_guarantee_date_validity) || null}
                                                onDateChange={(from) => {
                                                    this.setState({ bank_guarantee_date_validity: moment(from).valueOf() || null });
                                                }}
                                                focused={this.state.focused2}
                                                onFocusChange={({ focused }) => this.setState({ focused2: focused })}
                                                renderMonthElement={this.renderMonthElement}
                                            />
                                        </div>

                                        <div className="black mb-20 wt-30p singleDatePicker-bank">
                                            <div className="black">Bank Guarantee Return:</div>
                                            <SingleDatePicker
                                                noBorder={true}
                                                displayFormat={"DD/MM/YYYY"}
                                                hideKeyboardShortcutsPanel={true}
                                                placeholder={"Date"}
                                                numberOfMonths={1}
                                                date={this.state.bank_guarantee_return && moment(this.state.bank_guarantee_return) || null}
                                                onDateChange={(from) => {
                                                    this.setState({ bank_guarantee_return: moment(from).valueOf() || null });
                                                }}
                                                focused={this.state.focused3}
                                                onFocusChange={({ focused }) => this.setState({ focused3: focused })}
                                                renderMonthElement={this.renderMonthElement}
                                            />
                                        </div>

                                        
                                    </div>
                                 <div className='bank-details-row'>
                                    <div className="dInBlock wt-30p">
                                            <div className="black">Bank Guarantee Amount(In Rupees)<span className='error'>*</span></div>
                                            <input
                                                type="text"
                                                placeholder="Enter here"
                                                className="bank-input wt-100p"
                                                value={this.state.bank_guarantee_amount}
                                                onChange={(e) => {
                                                    if (e.target.value != 0) {
                                                        const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                                                        const check = e.target.value.match(regEx);
                                                        if (!check) {
                                                            return
                                                        }
                                                    }
                                                    this.setState({ bank_guarantee_amount: e.target.value });
                                                }}
                                            />
                                    </div>

                                    <div className="dInBlock wt-30p">
                                            <div className="black">Courier Docket No.</div>
                                            <input
                                                type="text"
                                                placeholder="Courier Docket No."
                                                className="bank-input"
                                                value={this.state.courier_docket_no}
                                                onChange={(e) => {
                                                    this.setState({ courier_docket_no: e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '') });
                                                }}
                                            />
                                    </div>

                                    <div className="dInBlock wt-30p">
                                            <div className="black">Courier Name</div>
                                            <input
                                                type="text"
                                                placeholder="Courier Name"
                                                className="bank-input"
                                                value={this.state.courier_name}
                                                onChange={(e) => {
                                                    this.setState({ courier_name: e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '') });
                                                }}
                                            />
                                    </div>
                                 </div>
                                </div>
                            }

                            <div style={{ display: 'flex', marginLeft: '20px' }}>
                                {state.is_security_deposit &&
                                    <div className="mb-10 mt-10 fRight" style={{ width: '30%' }}>
                                        <button className="button-classic-bank" onClick={() => { this.scannedCopyOfCheque.click() }}> Scanned Copy of Cheque +</button>
                                        <input type='file' style={{ display: 'none' }} ref={(e) => { this.scannedCopyOfCheque = e; }} onChange={(e) => { this.props.bankDocumentUpload(e, 'scanned_copy_of_cheque') }} onClick={(e) => { e.target.value = null }} />
                                    </div>
                                }
                                {state.is_bank_guarantee &&
                                    <div className="mb-10 mt-10 fRight" style={{ width: '30%' }}>
                                        <button className="button-classic-bank" onClick={() => { this.scannedCopyOfGuarantee.click() }}>Scanned Copy of Guarantee +</button>
                                        <input type='file' style={{ display: 'none' }} ref={(e) => { this.scannedCopyOfGuarantee = e; }} onChange={(e) => { this.props.bankDocumentUpload(e, 'scanned_copy_of_guarantee') }} onClick={(e) => { e.target.value = null }} />
                                    </div>
                                }

                                <div className="mb-10 mt-10 fRight" style={{ width: '30%' }}>
                                    <button className="button-classic-bank" onClick={() => { this.otherDocument.click() }}> Other Documents +</button>
                                    <input type='file' style={{ display: 'none' }} ref={(e) => { this.otherDocument = e; }} onChange={(e) => { this.props.bankDocumentUpload(e, 'other_docs') }} onClick={(e) => { e.target.value = null }} />
                                </div>
                            </div>

                            {(this.props.bankApproverFiles || []).map((type, fId) => {
                                return (
                                    <div className='pl-20'>
                                        {fId === 0 && <div className="mb-15"><b>New Files to Upload:</b> </div>}
                                        {type.name &&
                                            <div className="mb-15 flex">
                                                <span className="grey">{type.docName} - </span>
                                                {type.name}
                                                <span className="error" style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={() => { this.props.handleRemoveBankUploadFile(fId) }}> X </span>
                                            </div>}
                                    </div>
                                )
                            })}

                            {((props.vendorForm && props.vendorForm.vendorDefaultData && props.vendorForm.vendorDefaultData.bank_documents && props.vendorForm.vendorDefaultData.bank_documents.length > 0 && props.vendorForm.vendorDefaultData.bank_documents) || []).map((type, fId) => {
                                return (
                                    <div className='pl-20'>
                                        {fId === 0 && <div className="mb-15"><b>Uploaded Files:</b> </div>}

                                        {type.name &&
                                            <div className="mb-15 flex">
                                                <span className="grey">{type.file_name} - </span>
                                                <a href={type.url} download className='files-link' style={{ marginLeft: '10px' }}>  {type.name}</a>
                                            </div>}
                                    </div>
                                )
                            })}

                            <button className="document-upload-bank" style={{ width: '200px' }} onClick={() => this.props.documentUploadForBank()}> Document Upload </button>
                        </div>
                    </div>
                    {state.errorMessage && (
                <div className="error-message" style={{ color: 'red' }}>
                    {state.errorMessage}
                </div>)}
                    <div className='cancel-save'>
                        <button className="button-classic-bank-1" onClick={this.props.onCancel}> Cancel </button>
                        <button className="button-classic-bank-2" onClick={() => this.onSaveBankApproverData()}> Save </button>
                    </div>
                </div>
            </div>
        );
    }
}


// const CustomeCheckBox = (props) => {
//     return (
//         <div className={props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick={() => props.toggle(props.id)}>
//             {props.selected && <div className="inner-circle"></div>}
//         </div>
//     )
// }

const mapStateToProps = state => {
    return {
        //    loader: state.loader,
        //    msgpopup: state.msgpopup,
        //    vendorForm: state.vendorForm,
        //    productList: state.master.itemList,
        // AllStatesForOperational : state.zonesList
    };
};


export default connect(mapStateToProps)(BankDetailsPopup);