import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Button from "../../common/action_constant";
import CheckBox from "../../components/checkbox/checkbox"
import InputText from "../../common/components/input-text/input-text";
import SearchText from "../../common/components/search-text/search-text";
import Loader from "../../common/components/loader/loader";
import "./volumetricWeightStructure.less";
import MainComponent from "../../home/main/main";
import Select from "react-select";
import MSGPopUp from "../../common/components/msg-popup/msg-popup";
import ACTION from "../../common/action_constant";
import Edit from '../../../assets/images/edit.svg';
import Save from '../../../assets/images/save.svg';
import Cancel from '../../../assets/images/cancel.svg';
import { getCookie } from "../../../utils/cookies";
import { getUserCompanyId, LR_FOR_PANTALOON_COMPANY_ID, PANTALOONS_COMPANY_IDS } from '../../../utils/common';

const MATERIAL_TYPES = [
  { label: 'Apparels', value: 1 },
  { label: 'Non-Apparels', value: 2 },
  { label: 'Others', value: 3 }
]

class VolumetricWeightStructure extends Component{
    constructor(props){
        super(props);
        this.state = {
            isAdded: false,
            selected:"general",
            venderId:  null,
            modeType :null,
            newVolumetricWeight :{
              vendor_name:null,
              vendor_id: null,
              brand_id: null,
              brand: null,
              vendor_code:null,
              mode_type:null,
              mode_id:null,
              division_value:null,
              length: null,
              breadth:null,
              height:null,
              type :null,
              name:null,
              selectedCategoryOption: null,
              selectedMaterialType: null,
              selectedModeOption: null,
            },
            selectedVolumetricWeights: [],
            volumetricData :(props.dataGovernance && props.dataGovernance.volumetricData) ? props.dataGovernance.volumetricData : [],
          };
    }

    
    handleChange(event) {
      this.setState({
          selected: event.target.value
      });
      this.getVolumetricWeight(event.target.value);
    }

    cancelAction = () => {
      this.setState({
        ...this.state,
        isAdded: false,
      })
    }

    getModeTypesData = () => {
      const { dispatch } = this.props;
      dispatch({
          type: ACTION.DATA_GOVERNANCE.GET_MODE_TYPES
      });
    }

    getVendorsData = () => {
      const { dispatch } = this.props;
      dispatch({
          type: ACTION.DATA_GOVERNANCE.GET_VENDORS_TYPES,
      });
    }
    
    onChangeInput = (value, type) => {
      let { newVolumetricWeight, selected} = this.state;
      if(type === "vendor_obj"){
        newVolumetricWeight.vendor_name = value.company;
        newVolumetricWeight.vendor_id = value.company_id;
        newVolumetricWeight.seeker_id = value.seeker_id;
      }else if(type === "mode_obj"){
        newVolumetricWeight.mode_id = value.id;
        newVolumetricWeight.mode_type =value.name;
      }else if(type === "division_value"){
        newVolumetricWeight.division_value = Number(value);
      }else if(type === "length"){
        newVolumetricWeight.length =Number(value);
      }else if(type === "breadth"){
        newVolumetricWeight.breadth = Number(value);
      }else if(type === "height"){
        newVolumetricWeight.height = Number(value);
      }else if(type === "volume_type") {
        newVolumetricWeight.volume_type = value.label;
        newVolumetricWeight.volume_type_id = value.id;
      } else if(type === "material_type"){
        newVolumetricWeight.brand_id = value.value;
        newVolumetricWeight.brand = value.label;
      }
       
      newVolumetricWeight.type = selected ==="general"? 1: 2;
      newVolumetricWeight.name = selected ==="general"? "general": "suitsAndBlazers";

      this.setState({
        newVolumetricWeight
      });
    }

    closeMsgPopup = () =>{
        const {dispatch} = this.props;
        dispatch({ type : ACTION.MSG_DISPLAY.HIDE_MSG });
    }
    
    getVolumetricWeight = (value) => {
      const { dispatch } = this.props;
     let isType =(value==="general")? 1: 2;
     let inputData = {
      type:isType,
      company_id: `${JSON.parse(getCookie("user")).company_id}`
     }
     if(this.state.searchString) {
        inputData.search = this.state.searchString
      }
      dispatch({
        type: ACTION.DATA_GOVERNANCE.GET_VOLUMETRIC_WEIGHT,
        params:inputData
      })

    }

    getVolumetricWeightTypes = () => {
      const { dispatch } = this.props;
      console.log('das', JSON.parse(getCookie("user")).company_id);
      dispatch({
        type: ACTION.DATA_GOVERNANCE.GET_VOLUMETRIC_WEIGHT_TYPE,
        params: `company_id=${JSON.parse(getCookie("user")).company_id}`
      });
    }

    saveVolumetricWeight =()=>{
      
    let { newVolumetricWeight , selected} = this.state;
    const { dispatch } = this.props;
    dispatch({
       type :ACTION.DATA_GOVERNANCE.POST_VOLUMETRIC_WEIGHT,
       params:newVolumetricWeight,
       onSuccess :((data)=>{
          // if(data){
            this.setState({ isAdded: false }, () => this.resetValue());
            this.getVolumetricWeight(selected);
          // }
       }),
    });
    }

    resetValue = () => {
        this.setState({
          newVolumetricWeight: {
            vendor_name:null,
            vendor_id: null,
            vendor_code:null,
            mode_type:null,
            mode_id:null,
            division_value:null,
            length: null,
            breadth:null,
            height:null,
            type :null,
            name:null,
            selectedCategoryOption: null,
            selectedMaterialType: null,
            selectedModeOption: null,
          }
        })
    }

    handleCkSelect = (e, id) => {
      const { selectedVolumetricWeights } = this.state;
      let index = selectedVolumetricWeights.indexOf(id);
      if (selectedVolumetricWeights.includes(id)) {
        selectedVolumetricWeights.splice(index, 1);
      } else {
        selectedVolumetricWeights.push(id);
      }
      this.setState({ 
        selectedVolumetricWeights,
        isAdded: false, 
        editId: null  
      });
    }

    updateVolumetricWeight =(volumetric)=>{
      if(!volumetric.seeker_id) {
        volumetric.seeker_id = this.state.seeker_id
      }
      const { dispatch } = this.props;
      let { selectedVolumetricWeights , selected} = this.state;
      dispatch({
        type :ACTION.DATA_GOVERNANCE.UPDATE_VOLUMETRIC_WEIGHT,
        params :volumetric,
        onSuccess: (() => {
          this.resetValue();
          this.setState({
             editId: null,
            }, this.getVolumetricWeight(selected));
        })
      });
      
    }

    deleteVolumetricWeight = () => {
      const { selectedVolumetricWeights, selected } = this.state;
      const { dispatch } = this.props;

      if (selectedVolumetricWeights.length > 0) {
        dispatch({
          type:ACTION.DATA_GOVERNANCE.DELETE_VOLUMETRIC_WEIGHT,
          params :selectedVolumetricWeights,
          onSuccess :((data)=>{
            this.setState({ selectedVolumetricWeights: [] });
            if(data){
              this.getVolumetricWeight(selected);
            }
          })
        })
      }
    }

    downloadVolumetricExcelLink= () => {
     let { selected } = this.state;
     const { dispatch } = this.props;
     let type = (selected === "general")? 1: 2;
     dispatch({
       type :ACTION.DATA_GOVERNANCE.DOWNLOAD_VOLUMETRIC_LINK,
       params : `type=${type}&company_id=${JSON.parse(getCookie("user")).company_id}`,
       onSuccess :((data)=>{
       this.setState({
        downloadLink : data.data.link
       })
       this.downloadBtn.click();
       })
     });
    }

    uploadVolumetricWeightFile = (e) => {
      let { selected } = this.state;
      let formData = new FormData();
      formData.append('file', e.target.files[0]);
      let type = (selected === "general")? 1: 2;
      const { dispatch } = this.props;
      dispatch({
         type :ACTION.DATA_GOVERNANCE.UPLOAD_VOLUMETRIC_FILE,
         params: `type=${type}&company_id=${JSON.parse(getCookie("user")).company_id}`,
         data: formData, 
         onSuccess: ((data) => {
          this.getVolumetricWeight(selected);
         })
      })

    }
    componentDidMount() {
      let { selected } = this.state;
      this.getVendorsData();
      this.getModeTypesData();
      this.getVolumetricWeight(selected);
      console.log('mount');
      this.getVolumetricWeightTypes();
    }

    handleLoadSearch = () => {
      let { selected } = this.state;
      this.getVolumetricWeight(selected);
    }

    componentWillReceiveProps(nextProps) {
      const { dataGovernance } = this.props;
      
      if (dataGovernance !== nextProps.volumetricData) {
          if (dataGovernance.delayPenaltyData !== nextProps.dataGovernance.volumetricData) {
              this.setState({
                volumetricData: nextProps.dataGovernance.volumetricData
              });
          }
      }
    }

    handleOnEffChange  = (e, pIndex, type) => {
      const { volumetricData } = this.state;
      if(type === "length"){
        volumetricData[pIndex].length = Number(e);
      }else if(type === "division_value"){
        volumetricData[pIndex].division_value = Number(e);
      } else if( type === "breadth"){
        volumetricData[pIndex].breadth = Number(e);
      }else if(type === "height"){
        volumetricData[pIndex].height = Number(e);
      }
      this.setState({
        volumetricData
      })
       }

     handleOnChangeModeName =(e, index) =>{
      const { volumetricData } = this.state;
      volumetricData[index].mode_type = e.name;
      this.setState({
        volumetricData,
        selectedModeOption: e,
      })
     }
      
     handleOnChangeVendorName = (e , index) =>{
     const { volumetricData } = this.state;
     volumetricData[index].vendor_name = e.company;
     volumetricData[index].vendor_id = e.company_id;
     volumetricData[index].seeker_id = e.seeker_id;
     this.setState({
        selectedCategoryOption: e
      });
     }

  handleOnChangeMaterialType = (e, index) => {
    const { volumetricData } = this.state;
    volumetricData[index].brand = e.label;
    volumetricData[index].brand_id = e.value;
    this.setState({
      selectedMaterialType: e
    });
  }

    editVolumetric = (index) => {
      this.setState({
          editId: (this.state.editId===index) ? null : index,
          selectedMaterialType: MATERIAL_TYPES.filter(row => row.value == this.state.volumetricData[index].brand_id),
          selectedCategoryOption: this.props.dataGovernance.vendorTypes.filter(row => row.value == this.state.volumetricData[index].vendor_id),
          selectedModeOption: this.props.dataGovernance.modeTypes.filter(row => row.value == this.state.volumetricData[index].mode_id),
          seeker_id: this.props.dataGovernance.vendorTypes.filter(row => row.value == this.state.volumetricData[index].vendor_id)[0].seeker_id || '',
      });
  }

  handleSearchChange = (val) => {
    this.setState({
      searchString: val
    })
  }
  handleClearFilter = (val) => {
    let {selected} = this.state;
    this.setState({
      searchString: ''
    }, () => {
      this.getVolumetricWeight(selected);
    })
  }
    renderTableBody = (volumetricWeightData) => {
      const { selectedVolumetricWeights , selected, editId, isAdded} = this.state;
      const isPantaloons = LR_FOR_PANTALOON_COMPANY_ID.includes(getUserCompanyId())
      const { dataGovernance } = this.props;
      let type = (selected=="general")
        return (
          <Fragment>
            {volumetricWeightData && (volumetricWeightData || []).map((volumetric, index)=>{
              return (
                <div key={index} className="list-section content divTableRow">
                  <div className="divTableCell select-row"> 
                     {volumetric && editId !==index &&
                        <input 
                          type="checkbox" 
                          checked={selectedVolumetricWeights.includes(volumetric.volumetric_id)}
                          onClick={(e) => this.handleCkSelect(e, volumetric.volumetric_id)}
                        />
                      }
                     </div>
                    {type && editId!==index && <div className ="divTableCell"> {volumetric.vendor_name} </div>}
                    {this.state.selected === "general" && (editId===index) && <div className="divTableCell"> 
                      <Select className="select"  
                        options={dataGovernance.vendorTypes || []}
                        value={this.state.selectedCategoryOption}
                        onChange={(value) => { 
                          if(isAdded) {
                            this.onChangeInput(value,'vendor_obj')
                          }else{
                            this.handleOnChangeVendorName(value, index);
                          }
                      }}
                         />
                      </div>}
                      {
                        isPantaloons ? 
                        <>
                        {type && editId !== index && <div className="divTableCell"> {volumetric.brand} </div>}
                        {this.state.selected === "general" && (editId === index) && <div className="divTableCell">
                          <Select className="select"
                            options={MATERIAL_TYPES}
                            value={this.state.selectedMaterialType}
                            onChange={(value) => {
                              if (isAdded) {
                                this.onChangeInput(value, 'material_type')
                              } else {
                                this.handleOnChangeMaterialType(value, index);
                              }
                            }}
                          />
                        </div>}</>
                        :
                        type && <div className="divTableCell"> {volumetric.vendor_code} </div>
                      }
                    {editId!==index && <div className="divTableCell"> {volumetric.mode_type} </div>}
                    {selected==="others" && <div className ="divTableCell">{volumetric.volume_type}</div>}
                    {editId===index && <div className="divTableCell"> 
                      <Select className="select"
                        value={this.state.selectedModeOption}
                        options ={dataGovernance.modeTypes || []}
                        onChange={(value) =>{
                          if (isAdded) {
                          this.onChangeInput(value, 'mode_obj')
                          }else{
                            this.handleOnChangeModeName(value, index)
                          }
                        }} 
                      />
                    </div>}
                    {!type &&  editId!==index && <div className="divTableCell"> {volumetric.length || 1} * {volumetric.breadth || 1} * {volumetric.height || 1} </div>}
                    {this.state.selected === "suitsAndBlazers" && editId===index && <div className="divTableCell inputBoxes lbhBox">  
                    <InputText type="number" placeholder="Length" changeHandler={(value) =>{
                      if (isAdded) {
                        this.onChangeInput(value, 'length')
                      }else{
                        this.handleOnEffChange(value, index, "length")
                      }
                      }} value={volumetric.length} />
                           <InputText type="number" placeholder="Breadth" changeHandler={(value) =>{
                             if(isAdded){
                             this.onChangeInput(value, 'breadth')
                             }else{
                              this.handleOnEffChange(value, index, "breadth") 
                             }
                             
                             }}  value={volumetric.breadth}/>
                           <InputText type="number" placeholder="Height" changeHandler={(value) =>{
                             if(isAdded){
                             this.onChangeInput(value, 'height')
                             }else{
                              this.handleOnEffChange(value, index, "height")   
                             }
                             
                             }}  value={volumetric.height}/>
                           </div>
                           }

                    {editId!==index && <div className="divTableCell"> {volumetric.division_value} </div>}
                    {editId===index  && <div className="divTableCell inputBoxes"> 
                          <InputText type="number" placeholder="Division Factor" changeHandler={(value) =>{
                             if (isAdded) {
                               this.onChangeInput(value, 'division_value')
                             }else{
                              this.handleOnEffChange(value, index, "division_value")
                             }
                             }} value={volumetric.division_value}/>
                        </div>}
                    {!type &&<div className="divTableCell"> {volumetric.total_weight} </div>}
                    {
                      !type &&<div className="divTableCell"> {Math.round(volumetric.total_weight)} </div>
                    }
                    {
                      // !type && <div className="divTableCell"> {(volumetric.total_weight).toFixed(2)} </div>
                    }
                    <div className="divTableCell"> 
                        {(editId===index) 
                            ?
                            <>
                                <div className={'action-icon'} style={{backgroundImage: `url(${Save})`}} onClick={() => this.updateVolumetricWeight(volumetric)} />
                            <div className={'action-icon'} style={{backgroundImage: `url(${Cancel})`}} onClick ={() => this.setState({editId: null})} />
                            </>
                            :
                            <div className={'action-icon'} style={{backgroundImage: `url(${Edit})`}} onClick={() => this.editVolumetric(index)} />
                        }
                        </div>
                    
                </div>
              )
            })}
          </Fragment>
        )
    }

    addNewRecord = () => {
      this.setState({
        isAdded: true
      })
    }

    handleVolumetricTypeSave = () => {
      const { dispatch } = this.props;
      const data = {
        company_id: JSON.parse(getCookie("user")).company_id,
        name: this.state.volumetricType,
      }
      dispatch({ 
        type: ACTION.DATA_GOVERNANCE.POST_VOLUMETRIC_WEIGHT_TYPE,
        params: data,
        onSuccess: ((data) => {
          this.setState({
            volumetricType: '',
            openPopUp: false,
          });
          this.getVolumetricWeightTypes();
        })
      })
    }

    render() {
      const { dataGovernance, msgpopup } = this.props;
      const  { newVolumetricWeight , volumetricData, isAdded} = this.state;
      const isPantaloons = LR_FOR_PANTALOON_COMPANY_ID.includes(getUserCompanyId())
      const isGeneral = this.state.selected === "general";
      const isSuits = this.state.selected === "suitsAndBlazers";
      const isOther = this.state.selected === "others";
        return (
            <div className="volumetric-wrap">
                <div className="wrapper">
                <MainComponent>
                {this.props.loader.isLoading && <Loader />} 
                {this.props.msgpopup.active && <MSGPopUp close = {this.closeMsgPopup} type = {this.props.msgpopup.type} msg = {this.props.msgpopup.msg}/>}
                <div className="sub-heading-section"> 
                  <div className="sub-heading"> Volumetric Weight Structure </div>
                </div>
                <div className="sub-heading-section">
                  <label className ="ml-19px">
                  <input type="radio" value="general" checked={this.state.selected === "general"} onChange={(value) => this.handleChange(value)}/> General </label>
                  {/*<label className ="ml-19px">
                  <input  type="radio" value="suitsAndBlazers" checked={this.state.selected === "suitsAndBlazers"}  onChange={(value) => this.handleChange(value)}/> Suits and Blazers </label>*/}
                  <label className ="ml-19px">
                  <input  type="radio" value="others" checked={this.state.selected === "others"}  onChange={(value) => this.handleChange(value)}/> Others </label>
                </div>

                <div className="cta-action-btns mb-10">
                  <button className="button-classic" onClick ={this.downloadVolumetricExcelLink} > Download Template</button>
                  <a className="download-btn" style={{ display: 'none'}} ref = {input =>this.downloadBtn = input} href={this.state.downloadLink} download></a>
                  <button className="button-classic" onClick={() => {this.uploadExcel.click()}}> Upload Excel </button>
                  <input type='file' style ={{display :'none'}} ref={(e) => { this.uploadExcel = e ;}} onChange={this.uploadVolumetricWeightFile} onClick={(e) => {e.target.value = null }}/>
                  <button className="button-classic" onClick={() => this.deleteVolumetricWeight()}>Delete Record(s)</button>
                  <button className="button-classic" onClick={() => this.addNewRecord()}> Add Record</button>
                  {this.state.selected==="others" && <button className="button-classic" onClick={() => { this.setState({ openPopUp: true }) }}> + Add Volumetric Types</button>}
                </div>
                <SearchText 
                  placeholder="Search Vendor" 
                  canClear={true} 
                  value={this.state.searchString}
                  changeHandler={(e) => this.handleSearchChange(e)}
                  handleClearFilter={(e) => this.handleClearFilter(e)}
                  click={(e) => this.handleLoadSearch(e)}
                />
                <div className="divTable company-list-wrap">
                  <div className="list-section table-header divTableHeading divTableRow">
                    <div className="divTableCell"> </div>
                    {isGeneral && <div className="divTableCell"> Vendor Name </div>}
                    {isGeneral &&
                    isPantaloons ? 
                    <div className="divTableCell">Material Type</div>:
                    <div className="divTableCell"> Vendor Code </div>}
                    <div className="divTableCell"> Mode </div>
                    {isOther && <div className="divTableCell"> Type </div>}
                    {isGeneral && <div className="divTableCell"> Division Factor (L*B*H/x) </div>}
                    {(isSuits || isOther) && <div className="divTableCell"> Dimensions (L*B*H) </div>}
                    {(isSuits || isOther) && <div className="divTableCell"> Division Factor </div>}
                    {(isSuits || isOther) &&<div className="divTableCell">Total Weight </div>}
                    {(isSuits || isOther) &&<div className="divTableCell"> Round Off Weight </div>}
                    <div className="divTableCell"> Action </div>
                  </div> 
                  <div className="divTableBody">
                  {
                    isAdded &&
                    <div className="list-section content divTableRow">
                        <div className="divTableCell"> </div>
                        {isGeneral &&  <div className="divTableCell"> 
                          <Select className="select"
                            options={dataGovernance.vendorTypes || []}
                            onChange={(value) => {  
                              this.onChangeInput(value,'vendor_obj')
                            }}
                          />
                        </div>}
                          {isGeneral && <div className="divTableCell">
                            {isPantaloons && <Select className="select"
                            options={MATERIAL_TYPES || []}
                            onChange={(value) => {
                              this.onChangeInput(value, 'material_type')
                            }}
                          />}</div>}
                        <div className="divTableCell"> 
                          <Select className="select"
                            options ={dataGovernance.modeTypes || []}
                            onChange={(value) =>{this.onChangeInput(value, 'mode_obj')}}
                          />
                        </div>
                        {isOther && <div className="divTableCell">
                          <Select 
                            className="select"
                            options ={dataGovernance.volumeTypes || []}
                            onChange={(value) =>{this.onChangeInput(value, 'volume_type')}}
                          />
                        </div>}
                        {(isSuits || isOther) && <div className="divTableCell inputBoxes lbhBox"> 
                           <InputText type="number" placeholder="Length" changeHandler={(value) =>{this.onChangeInput(value, 'length')}} value={newVolumetricWeight.length} />
                           <InputText type="number" placeholder="Breadth" changeHandler={(value) =>{this.onChangeInput(value, 'breadth')}}  value={newVolumetricWeight.breadth}/>
                           <InputText type="number" placeholder="Height" changeHandler={(value) =>{this.onChangeInput(value, 'height')}}  value={newVolumetricWeight.height}/>
                        </div>}
                        <div className="divTableCell inputBoxes"> 
                          <InputText type="number" placeholder="Division Factor" changeHandler={(value) =>{this.onChangeInput(value, 'division_value')}} value={newVolumetricWeight.division_value}/>
                        </div>
                        {(isSuits || isOther) &&  <div className="divTableCell">Auto Calculate</div>}
                        <div className="divTableCell"> 
                          <div className={'action-icon'} style={{backgroundImage: `url(${Save})`}} onClick ={() => this.saveVolumetricWeight()} />
                          <div className={'action-icon'} style={{backgroundImage: `url(${Cancel})`}} onClick ={() => this.cancelAction()} />
                          {/* <button  className="add-button" onClick ={this.saveVolumetricWeight}>Save</button> */}
                        </div>
                    </div>
                  }
                    {this.renderTableBody(volumetricData)}
                  </div>
                  
              </div>

              {
                this.state.openPopUp &&
                <div className="add-user-wrap">
                  <div className="overlay"></div>
                  <div id="modal" className="modal-popup">
                    <div className="pop-heading"> Define Slab </div>
                    <div className="content">
                      <div>
                        <InputText type="text" placeholder="Type" changeHandler={(value) => { this.setState({ volumetricType: value }) }} value={this.state.volumetricType} />
                        <button className="button-classic mr-10" onClick={() => {this.handleVolumetricTypeSave()}}>Save</button>
                        <button className="button-classic" onClick={() => { this.setState({ openPopUp: false }) }}>Cancel</button>
                      </div>
                      <div>
                        {
                          // (dataGovernance.volumeTypes || []).map((volume) => {
                          //   return (<div>{volume.label}</div>)
                          // })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              }       
                            
           </MainComponent>
           </div>
          </div>
        );

        
            
        
      }

}
const mapStateToProps = state => {
return {
    loader: state.loader,
    dataGovernance: state.dataGovernance,
    msgpopup: state.msgpopup
}
}
export default  connect(mapStateToProps) (VolumetricWeightStructure);

