import React, { Component } from 'react';
import Dropup from "../../../assets/images/drop-up-arrow.svg";
import InputText from '../../common/components/input-text/input-text';
import Delete from "../../../assets/images/delete.svg";
import Add from '../../../assets/images/add.svg';
import Select from 'react-select';
import { debug } from 'winston';

export default class CreateTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data || []
        }
    }

    addTemplate = () => {
        const { templateText, data } = this.state;
        data.push({ [templateText]: []});
        this.setState({ data, [`showSection_${(templateText || '').replaceAll(" ", "")}`]: true });
    }

    onChangeHandler = (value) => {
        this.setState({ templateText: value });
    }

    onSave = () => {
        this.props.onSaveTemplate(this.state.data);
    }


    render() {
        const { data } = this.state;
        const { warehouseParticulars } = this.props;
        const headers = [], headerTypes = [], subTypes = [];
        (warehouseParticulars || []).forEach((val, valIndex) => {
            const header = Object.keys(val)[0];
            headers.push({
                label: header,
                value: header
            });
            (Object.keys(val[header]) || []).forEach(typeVal => {
                headerTypes.push({
                    label: typeVal,
                    value: typeVal,
                    header: header
                });
                (typeVal && Array.isArray(val[header][typeVal]) && val[header] && (val[header][typeVal]) || []).forEach((element) => {
                    subTypes.push({
                        label: element.label,
                        value: element.deprication_period,
                        header: header,
                        typeVal: typeVal
                    })
                })
            });
        });

        return (
                <>
                <div>
                    <InputText
                        placeholder="Template"
                        id={"header"}
                        label="Template"
                        changeHandler={this.onChangeHandler}
                        value={this.state.templateText}
                    />
                    <div className="add-button add-level" onClick={this.addTemplate}>Create Template</div>
                </div>
                {(data || []).map((template, tempIndex) => {
                const templateValue = Object.keys(template)[0];
                return(
                <div className={'golden-box golden-box-sub'} key={tempIndex}>
                    <div className={'heading sub-heading'}>{templateValue}
                    <div className={ this.state[`showSection_${templateValue}`] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => {
                        this.setState({ [`showSection_${(templateValue || '').replaceAll(" ", "")}`] : !this.state[`showSection_${(templateValue || '').replaceAll(" ", "")}`]})}}></div>
                        <div className="fRight" 
                            onClick={() => {
                                const { data } = this.state;
                                data.splice(tempIndex, 1);
                                this.setState({ data });
                        }}>X</div>
                    </div>
                    {this.state[`showSection_${(templateValue || '').replaceAll(" ", "")}`] && 
                    <>
                        <div style={{'margin': '10px'}}>
                            <Select 
                                options={headers}
                                onChange={(option) => {
                                    const { data } = this.state;
                                    data[tempIndex][templateValue].push({
                                        [option.value] : {}
                                    });
                                    this.setState({ data });
                                }}
                                value={null}
                                placeholder={"Select Header"}
                            />
                        </div>
                        {(data[tempIndex][templateValue] || []).map((val, valIndex) => {
                            const headerValue = Object.keys(val)[0];
                            return (
                                <>
                                <div className="level-header particulars-header">
                                    <div style={{'display': 'inline-block', 'width': '98%'}}>{headerValue}</div>
                                    <div className="cancel-icon" onClick={() => {
                                        const { data } = this.state;
                                        delete data[tempIndex][templateValue][valIndex];
                                        this.setState({ data });
                                    }}>X</div>
                                </div>
                                <div style={{'margin': '10px'}}>
                                    <Select 
                                        options={(headerTypes || []).filter(headerType => headerType.header === headerValue)}
                                        onChange={(option) => {
                                            const { data } = this.state;
                                            data[tempIndex][templateValue][valIndex][headerValue][option.label] = [];
                                            this.setState({ data });
                                        }}
                                        value={null}
                                        placeholder={"Select Sub Type"}
                                    />
                                </div>
                                {(Object.keys(val[headerValue]) || []).map((typeVal, typeIndex) => (
                                    <>{typeVal &&
                                        <> 
                                            <div className="level-header">
                                                <div style={{'display': 'inline-block', 'width': '98%'}}>{typeVal}</div>
                                                <div className="cancel-icon" onClick={() => {
                                                    const { data } = this.state;
                                                    delete data[tempIndex][templateValue][valIndex][headerValue][typeVal]
                                                    this.setState({ data });
                                                }}>X</div>
                                            </div>
                                            <div style={{'margin': '10px'}}>
                                                <Select 
                                                    options={(subTypes || []).filter(subType => subType.header === headerValue && subType.typeVal === typeVal)}
                                                    onChange={(option) => {
                                                        const { data } = this.state;
                                                        data[tempIndex][templateValue][valIndex][headerValue][typeVal].push({
                                                            "deprication_period": option.value,
                                                            "label": option.label
                                                        })
                                                        this.setState({ data });
                                                    }}
                                                    value={null}
                                                    placeholder={"Select Line Item"}
                                                />
                                            </div>
                                        </>}
                                        {Array.isArray(val[headerValue][typeVal]) && (val[headerValue][typeVal] || []).map((element, eleInd) => {
                                            return (
                                                <>
                                                {eleInd === 0 && <div className="golden-box-display">
                                                    <div className="headers"><div className="labels">Line Items</div></div>
                                                    <div className="headers"><div className="labels">Deprication Period</div></div>
                                                    <div className="headers"><div className="labels">Action</div></div>
                                                </div>}
                                                {element &&
                                                <div className="golden-box-display">
                                                <div className="details">{element.label}</div>
                                                <div className="details">{element.deprication_period}</div>
                                                <div className="details">
                                                    {/* <div className={'delete-icon'} style={{backgroundImage: `url(${Add})`}} onClick={() => this.onAddSubType(headerValue, typeVal, valIndex)}></div> */}
                                                    <div className = "delete-icon" style={{backgroundImage: `url(${Delete})`}} onClick = {() => {
                                                        const { data } = this.state;
                                                        data[tempIndex][templateValue][valIndex][headerValue][typeVal].splice(eleInd, 1);
                                                        this.setState({ data });
                                                    }}></div>
                                                </div>
                                                </div>}
                                                </>
                                            )
                                        })}
                                    </>))}
                                </>
                            )

                        })}
                    </>}
                </div>)})}
                <div className="details">
                    <div className="add-button add-level" onClick={this.onSave}>Save</div>
                </div>
                </>
        )
    }
}