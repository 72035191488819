import React, { Component } from "react";
import '../vendor-mapping/vendorMappingList.less';
import Search from "../../common/components/search-text/search-text";
import Delete from "../../../assets/images/delete.svg";
import Edit from "../../../assets/images/edit.svg";
import { connect } from 'react-redux';
import ACTIONS from '../../common/action_constant';
import Button from '../../home/button-classic/button-classic';
import { withRouter } from 'react-router';
import { getEmail, getUserId } from "../../../utils/common";
import { getItemFromSessionStorage } from "../../../utils/sessionStorage";
import AddEdit from './add-edit';

class VendorLaneMapping extends Component {
  constructor() {
    super();
    this.state = {
    }
  }

  UNSAFE_componentWillMount() {
    this.getCustomerLaneMappingDetails()
  }

  getCustomerLaneMappingDetails = () => {
    const  { dispatch, companyId } = this.props;
    dispatch({
      type: ACTIONS.HOME.GET_CUSTOMER_LANE_MAPPING,
      params: `?companyId=${companyId}`
    });
  }

  getVehicleAndBodyTypes = () => {
    const { dispatch, companyId } = this.props;
    const data = {
      company_id: companyId,
      branch_id: companyId
    }
    dispatch({ type: ACTIONS.CONTRACTS.GET_MASTER_CONTRACT_DATA,  data });
  }

  getLocationNetwork = () => {
    const { dispatch, companyId } = this.props;
		dispatch({ type: ACTIONS.MASTER.LOCATION_NETWORK.GET_NETWORK_CHAIN, data: companyId  });
  }

  getCompanyTransporters = () => {
    const { dispatch, companyId } = this.props;
    dispatch({type: ACTIONS.MASTER.TRANSPORTERS.GET_COMPANY_TRANSPORTERS, companyId, branchId: companyId});
  }


  toggleAdd = (data = '') => {
    this.getVehicleAndBodyTypes();
    this.getLocationNetwork();
    this.getCompanyTransporters();
    this.setState({ modalOpen: true,  editData: data});
  }

  toggle = () => {
    this.setState({ modalOpen: false, editData: '' });
  }

  addLaneVendorDetails = (data) => {
    const { dispatch, companyId } = this.props;
    dispatch({
      type: ACTIONS.HOME.POST_CUSTOMER_LANE_MAPPING,
      data: {
        ...data,
        company_id: companyId
      },
      onSuccess: () => {
        this.getCustomerLaneMappingDetails();
      }
    })
    this.toggle();
  }

  updateLaneVendorDetails = (data) => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.HOME.UPDATE_CUSTOMER_LANE_MAPPING,
      data,
      onSuccess: () => {
        this.getCustomerLaneMappingDetails();
      }
    });
    this.toggle();
  }

  deleteLaneVendorDetails = (data) => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.HOME.DELETE_CUSTOMER_LANE_MAPPING,
      data: {
        "company_id": data.company_id,
        "lane_id": data.lane_id
      },
      onSuccess: () => {
        this.getCustomerLaneMappingDetails();
      }
    })
  }

  render() {

    const { modalOpen } = this.state;
    const { companyTransporters, netWorkDetails, plantWiseContract, customerLaneMappingDetails } = this.props;

    return (
      <div>
        <div className={"vendor-mapping"}>
          <div className="vendor_btn">

            <button
              value={"+ Vendor"}
              className="button-classic"
              onClick={() => this.toggleAdd()}
            >
              + Vendor
            </button>
            {/* <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download /> */}
          </div>
          <div className={"mapping-details-section"}>
            <div className={"list-section header"}>
              <div className={"tabel-header"}>From</div>
              <div className={"tabel-header"}>To</div>
              <div className={"tabel-header "}>Vehicle Type</div>
              <div className={"tabel-header "}>Body Type</div>
              <div className={"tabel-header "}>Transporter</div>
              <div className={"tabel-header "}>Consignee</div>
              <div className={"tabel-header"}>Action</div>
            </div>

            {(customerLaneMappingDetails || []).map((val,index) => (
            <div className={"list-section content"}>
              <div className={"tabel-header"}>{val.from}</div>
              <div className={"tabel-header"}>{val.to}</div>
              <div className={"tabel-header "}>{val.vehicle_type_name}</div>
              <div className={"tabel-header "}>{val.body_type_name}</div>
              <div className={"tabel-header "}>{val.transporter_name}</div>
              <div className={"tabel-header "}>{val.consignee_name}</div>
              <div className={"tabel-header"} style={{ width: "140px" }}>
                <div
                  className={"delete-icon"}
                  style={{ backgroundImage: `url(${Edit})` }}
                  onClick={() => this.toggleAdd(val)}
                ></div>
                <div
                  className={"delete-icon"}
                  style={{ backgroundImage: `url(${Delete})` }}
                  onClick={() => this.deleteLaneVendorDetails(val)}
                ></div>
              </div>
            </div>))}
          </div>
          <div className = "submit-section-pagination">
            <div className ="next">
              <Button value = "Next" click = {this.next} disabled={this.state.nextDisabled}/>    
            </div>
            <div className ="pre">
              <Button value = "Previous" click = {this.previous} disabled={this.state.prevDisabled}/>
            </div>
          </div>
          {modalOpen && (
            <AddEdit
              isOpen={modalOpen}
              toggleAdd={this.toggleAdd}
              toggle={this.toggle}
              isEditData={this.state.editData}
              companyTransporters={companyTransporters}
              updateLaneVendorDetails={this.updateLaneVendorDetails}
              addLaneVendorDetails={this.addLaneVendorDetails}
              netWorkDetails={netWorkDetails}
              plantWiseContract={plantWiseContract}
            />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    netWorkDetails: state.master.netWorkDetails,
    companyTransporters: state.master.companyTransporters || [],
    plantWiseContract: state.contracts.masterDetails || {},
    customerLaneMappingDetails: state.home.customerLaneMappingDetails || []
  };
};


export default connect(mapStateToProps)(withRouter(VendorLaneMapping));
