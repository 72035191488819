import React, { Component } from "react";
import Button from '../../home/button-classic/button-classic';
import AddBill from './add_bill';
import './bill_mngt.less';
import {connect} from 'react-redux';
import Delete from "../../../assets/images/delete.svg";
import Edit from '../../../assets/images/edit.svg';
import ACTIONS from '../../common/action_constant';
import { getCookie } from "../../../utils/cookies";

class BillManagement extends Component{
    constructor(props){
        super(props);
        this.state={
            openAddRecord: false,
            limit: 50,
            offset: 0,
            generatedBill: '',
            isEditable: false
        }
    }

    componentDidMount(){
        this.getBillSequenceListing();
        this.getBillSequenceDropdown();
    }

    getBillSequenceListing = () => {
        const { dispatch, company_id } = this.props;
        const { limit, offset } = this.state;
        const params = `?transporter_id=${company_id}&limit=${limit}&offset=${offset}`
        dispatch({
            type: ACTIONS.VENDORS.GET_BILL_SEQUENCE_LISTING,
            params
        })
    }

    getBillSequenceDropdown = () => {
        const { dispatch, company_id } = this.props;
        const params = `?transporter_id=${company_id}`
        dispatch({
            type: ACTIONS.VENDORS.GET_BILL_SEQUENCE_DROPDOWNS,
            params
        })
    }

    onAddRecord = () => {
        this.setState({openAddRecord: true, editData: {}, generatedBill: '', isEditable: false})
    }

    onCancel = () => {
        this.setState({openAddRecord: false, editData: {}, generatedBill: '', isEditable: false})
    }

    saveBillFormat = (data) => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTIONS.VENDORS.POST_BILL_SEQUENCE,
            data,
            onSuccess: () => {
                this.setState({openAddRecord: false}, () => 
                this.getBillSequenceListing()
                )
            }
        })
    }

    editRecord = (bill) => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTIONS.VENDORS.GET_BILL_SEQUENCE,
            data: bill.id,
            onSuccess: (billData) => {
                this.setState({editData: billData, openAddRecord: true, isEditable: true})
            }
        })
    }

    onUpdate = (data) => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTIONS.VENDORS.UPDATE_BILL_SEQUENCE,
            data,
            onSuccess: () => {
                this.getBillSequenceListing()
            }
        })
        this.setState({editData: {}, openAddRecord: false, generatedBill: '', isEditable: false})
    }

    deleteRecord = (bill) => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTIONS.VENDORS.DELETE_BILL_SEQUENCE,
            data: bill.id,
            onSuccess: () => {
                this.getBillSequenceListing()
            }
        })
    }

    generateTestSeq = (billDetails) => {
        const { dispatch, company_id } = this.props;
        const user = JSON.parse(getCookie("user"));
        const branch = billDetails.branches && billDetails.branches.length && billDetails.branches[0].id;
        const data = `?customer_id=${billDetails.selectedSeeker}&transporter_id=${company_id}&src_branch_id=${branch}`
        dispatch({
            type: ACTIONS.VENDORS.GET_BILL_SEQUENCE_TEST_LIST,
            data,
            onSuccess: (generatedBill) => {
                this.setState({generatedBill})
            }
        })
    }

    render(){
        const { billSequenceList } = this.props;
        return(
            <div className="bill-series">
                <div className="header">Bill Management</div>
                <div className='contact-btn mt-10'>
					<Button value="+ Add Record" click={this.onAddRecord} className={'button-classic'} style={{padding: '10px'}}/>
				</div>
                <div className="table">
                    <table className="wt-100p">
                        <thead className="table-tr">
                            <th className="tCenter wt-120">Seeker Company</th>
                            <th className="tCenter wt-120">Seeker Branches</th>
                            <th className="tCenter wt-120">Bill Sequence</th>
                            <th className="tCenter wt-120">Reset Applicable</th>
                            <th className="tCenter wt-80">Actions</th>
                        </thead>
                        <tbody>
                            {(billSequenceList || []).map((details, index) => {
                                return(
                                    <tr key={index+1}>
                                        <td className="table-content">{details.customer_name}</td>
                                        <td className="table-content">{(details.branches || []).map((branch) => {
                                            return(
                                                <div>{branch.name}</div>
                                            )
                                        })}</td>
                                        <td className="table-content">{details.bill_format}</td>
                                        <td className="table-content">{details.reset_seq_on_fye ? 'Applicable' : 'Not Applicable'}</td>
                                        <td className="table-content">
                                            <div className="acCenter">
                                                <div className={'action-icon'} style={{backgroundImage: `url(${Edit})`, width: '20px', height: '20px'}} onClick={() => this.editRecord(details)} />
                                                <div className={'action-icon'} style={{backgroundImage: `url(${Delete})`, width: '20px', height: '20px'}} onClick={() => this.deleteRecord(details)} />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                {this.state.openAddRecord &&
                <AddBill 
                    openAddRecord = {this.state.openAddRecord}
                    onCancel = {this.onCancel}
                    onSave = {this.saveBillFormat}
                    editData = {this.state.editData}
                    onUpdate = {this.onUpdate}
                    generateTestSeq = {this.generateTestSeq}
                    generatedBill = {this.state.generatedBill}
                    billSequenceDropdown = {this.props.billSequenceDropdown}
                    isEditable = {this.state.isEditable}
                    company_id = {this.props.company_id}
                    company_name = {this.props.company_name}
                />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        billSequenceList: state.vendors.billSequenceList,
        billSequenceDropdown: state.vendors.billSequenceDropdown
    }
   
};

export default connect(mapStateToProps)(BillManagement);