import {connect} from 'react-redux';

import NavigationPanel from "./navigation-panel/navigation-panel";
import React, { Component } from "react";
import MainComponent from "../../home/main/main";
import Loader from "../../common/components/loader/loader";
import MessagePopUp from "../../common/components/msg-popup/msg-popup";
import { getCookie } from "../../../utils/cookies";
import ACTION from "../../common/action_constant";
import Vehicle from './Vehicle/vehicle'
import { getEmail, getUserId } from '../../../utils/common';
import { getItemFromSessionStorage } from '../../../utils/sessionStorage';


class Vehicledrivermaster extends Component {
  constructor(props){
		super(props);
		this.state = {
			selectedSubTab : 1,
			selectedMainTab : 1
		}
	}
 
	selectedTab = (num) => {
		this.setState({
			selectedMainTab : num
		});
	}

	selectedSubTab = (num) => {
		this.setState({
			selectedSubTab : num
		})
	}



	closeMsgPopup = () =>{
		const {dispatch} = this.props;
		dispatch({ type : ACTION.MSG_DISPLAY.HIDE_MSG })
	}

	

	getDriverInfo = (data) => {
		const companyId = JSON.parse(getCookie('user')).company_id;
        let param = `${companyId}&limit=${data.limit}&offset=${data.offset}`;
        if(data.search) {
			param += `&search=${data.search}`;
		}
        const { dispatch } = this.props;
        dispatch({ type : ACTION.DATA_GOVERNANCE.GET_DRIVER_INFO, param, companyId });
	}
	deleteDriverInfo = (data) => {
	
		const { dispatch } = this.props;
		const companyId = JSON.parse(getCookie('user')).company_id;
		dispatch({ type :  ACTION.DATA_GOVERNANCE.DELETE_DRIVER_INFO,data,companyId});
	
	}
	
	addDriverInfo = (data) => {
	
		const { dispatch } = this.props;
		data.company_id = JSON.parse(getCookie('user')).company_id;
		data['master_type'] = 4;
		data['user_email'] = getEmail();
		data['new_flag'] = true;
		dispatch({ type : ACTION.DATA_GOVERNANCE.ADD_DRIVER_INFO, data });
	}
	updateDriverInfo = (data) => {

		const previousData = getItemFromSessionStorage('driverIn');
		const updatedPreviousData = this.replaceNullKeysWithNull(previousData);
		updatedPreviousData['update_flag'] = true;
		const { dispatch } = this.props;
		data.company_id = JSON.parse(getCookie('user')).company_id;
		data['master_type'] = 4;
		data['user_email'] = getEmail();
		data['update_flag'] = true;
		for (const key in data) {
			if (!updatedPreviousData.hasOwnProperty(key) && data[key] === "" || 
			(!updatedPreviousData.hasOwnProperty(key) && data[key] === false)) {
				data[key] = null;
			}
		}
		data['previous_data'] = updatedPreviousData;
		dispatch({ type : ACTION.DATA_GOVERNANCE.UPDATE_DRIVER_INFO, data });
	}
	 deleteTruckInfo = (data) => {
		
		const { dispatch } = this.props;
		const companyId = JSON.parse(getCookie('user')).company_id;
		dispatch({ type : ACTION.DATA_GOVERNANCE.DELETE_TRUCK_INFO, data,companyId});
	
	}


	getTruckInfo = (data) => {
		const companyId = JSON.parse(getCookie('user')).company_id;
		let param = `${companyId}&limit=${data.limit}&offset=${data.offset}`;
		if(data.search) {
			param += `&search=${data.search}`;
		}
		const { dispatch } = this.props;
		dispatch({ type : ACTION.DATA_GOVERNANCE.GET_TRUCK_INFO, param, companyId });
	}

	addTruckInfo = (data) => {
		const { dispatch } = this.props;
		data.tabs = undefined;
		data.selectedOption = undefined;
		data.company_id = JSON.parse(getCookie('user')).company_id;
		data['master_type'] = 5;
		data['user_email'] = getEmail();
		data['new_flag'] = true;
		dispatch({ type : ACTION.DATA_GOVERNANCE.ADD_TRUCK_INFO, data });
	}

	updateTruckInfo = (data) => {
		
		const previousData = getItemFromSessionStorage('vehicleIn');
		const updatedPreviousData = this.replaceNullKeysWithNull(previousData);
		updatedPreviousData['user_email'] = getEmail();
		updatedPreviousData['update_flag'] = true;
		const { dispatch } = this.props;
		data.company_id = JSON.parse(getCookie('user')).company_id;
		data['master_type'] = 5;
		data['user_email'] = getEmail();
		data['update_flag'] = true;

		for (const key in data) {
			if (!updatedPreviousData.hasOwnProperty(key) && data[key] === "" || 
			(!updatedPreviousData.hasOwnProperty(key) && data[key] === false)) {
				data[key] = null;
			}
		}

		data['previous_data'] = updatedPreviousData;
		dispatch({ type : ACTION.DATA_GOVERNANCE.UPDATE_TRUCK_INFO, data });
	}

	replaceNullKeysWithNull = (obj) => {
		const newObj = {};
		for (const key in obj) {
			newObj[key] = obj[key] === null ? null : obj[key];
		}
		return newObj;
	}

	getVehicleTypes = () => {
		const { dispatch } = this.props;
		dispatch({type: ACTION.DATA_GOVERNANCE.GET_TRUCK_TYPE });
	  }
	  getFuleTypes = () => {
		const { dispatch } = this.props;
		dispatch({type: ACTION.DATA_GOVERNANCE.GET_FULE_TYPE });
	  }
	  uploadfiels = (data) =>{
	
		const { dispatch } = this.props;
			dispatch({type : ACTION.DATA_GOVERNANCE.UPLOAD_VEHICLE_FIELS,data })
	  }
	  

	  downloadDriverTemplate = () => {
		const { dispatch } = this.props;
		dispatch({
			type: ACTION.DATA_GOVERNANCE.DOWNLOAD_DRIVER_TEMPLATE,
			onSuccess: (data => {
				const element = document.createElement('a');
				element.setAttribute('href', data.data[0].Link);
				element.setAttribute('download', true);
				element.click();
			})
		});
	  }

	  uploadDriverTemplate = (e, name) => {
		const { dispatch } = this.props;
		const companyId = JSON.parse(getCookie('user')).company_id;
		const userEmail = getEmail();
		let formData = new FormData();
		formData.append("file", e.target.files[0]);
		dispatch({
		  type: ACTION.DATA_GOVERNANCE.UPLOAD_DRIVER_TEMPLATE,
		  formData,
		  queryString : `?userEmail=${userEmail}&companyId=${companyId}`,
		  onSuccess: (details) => {
			const data = {
				offset: 0,
				limit: 20,
			  };
			this.getDriverInfo(data)
		  },
		});
	  };

	downloadVehicleTemplate = () => {
		const { dispatch } = this.props;
		dispatch({
			type: ACTION.DATA_GOVERNANCE.DOWNLOAD_VEHICLE_TEMPLATE,
			onSuccess: (data => {
				const element = document.createElement('a');
				element.setAttribute('href', data.data[0].Link);
				element.setAttribute('download', true);
				element.click();
			})
		});
	}

	uploadVehicleTemplate = (e, name) => {
		const { dispatch } = this.props;
		const companyId = JSON.parse(getCookie('user')).company_id;
		const userEmail = getEmail();
		let formData = new FormData();
		formData.append("file", e.target.files[0]);
		dispatch({
		  type: ACTION.DATA_GOVERNANCE.UPLOAD_VEHICLE_TEMPLATE,
		  formData,
		  queryString : `?userEmail=${userEmail}&companyId=${companyId}`,
		  onSuccess: (details) => {
			const data = {
				offset: 0,
				limit: 20,
			  };
			this.getTruckInfo(data)
		  },
		});
	  };
	
  render() {

    return (
		
        <div className = "master">
          <MainComponent>
		  {
			this.props.msgpopup.active && <MessagePopUp close = {this.closeMsgPopup} type = {this.props.msgpopup.type} msg = {this.props.msgpopup.msg}/>
		}
		{
			this.props.loader.isLoading && <Loader/>
		}
            {/* <NavigationPanel selectedSubTab = {this.selectedSubTab} selectedTab = {this.selectedTab}  />  */}
           {this.state.selectedMainTab == 1 && <Vehicle
			getDriverInfo={this.getDriverInfo}
			driverInfo={this.props.dataGovernance.driverInfoData}
			deleteDriverInfo={this.deleteDriverInfo}
			addDriverInfo={this.addDriverInfo}
			updateDriverInfo={this.updateDriverInfo}
			downloadDriverTemplate={this.downloadDriverTemplate}
			uploadDriverTemplate={this.uploadDriverTemplate}

			getTruckInfo={this.getTruckInfo}
			truckInfo={this.props.dataGovernance.truckInfoData}
			deleteTruckInfo={this.deleteTruckInfo}
			addTruckInfo={this.addTruckInfo}
			updateTruckInfo={this.updateTruckInfo}
			getVehicleTypes={this.getVehicleTypes}
			getFuleTypes={this.getFuleTypes}
			vehicleSizeTypesData={this.props.dataGovernance.vehicleTypesData}
			fuleTypesDataList={this.props.dataGovernance.fuleTypesData}
			uploadfiels={this.uploadfiels}
			downloadVehicleTemplate={this.downloadVehicleTemplate}
			uploadVehicleTemplate={this.uploadVehicleTemplate}
			/>}
            
          </MainComponent>
      </div>
    );
  }
}
const mapStateToProps = state => {
	return {
		loader: state.loader,
        msgpopup: state.msgpopup,
        dataGovernance: state.dataGovernance,
	};
};
export default connect(mapStateToProps)(Vehicledrivermaster);

