import React, { Component } from 'react';
import Search from '../../../common/components/search-text/search-text';
import Select from 'react-select';
import Button from '../../../home/button-classic/button-classic';
import Download from '../../../../assets/images/direct-download.svg';
import { connect } from 'react-redux';
import ACTIONS from '../../../common/action_constant';
import AddDisposalProduct from './add-disposal-material';
import Edit from '../../../../assets/images/edit.svg';
import Delete from '../../../../assets/images/delete.svg';

class DisposalMaterialMaster extends Component {
    constructor() {
        super();
        this.state = {
            search: '',
            offset: 0
        }
    }

    componentDidMount = () => {
        this.getDisposalMaterial();
        this.getDisposalMaterialDropdowns();
    }

    getDisposalMaterialDropdowns = () => {
        const { dispatch, company_id } = this.props;
        dispatch({
            type: ACTIONS.HOME.GET_DISPOSAL_MATERIAL_DROPDOWNS,
            companyId: company_id
        })
    }
     
    changeHandler = (value) => {
        this.setState({ search: value });
    }

    getDisposalMaterial = () => {
        const { dispatch, company_id } = this.props;
        const { search, offset } = this.state
        const param = {
            offset,
            limit: 20,
            companyId: company_id
        }
        if(search) {
            param.search = search;
        }
        dispatch({
            type: ACTIONS.HOME.GET_DISPOSAL_MATERIAL,
            param
        })

    }

    downloadDisposalMaterialTemplate = () => {
        const { dispatch, company_id } = this.props;
        const param = { companyId: company_id};
        dispatch({ 
            type : ACTIONS.HOME.DOWNLOAD_DISPOSAL_MATERIAL_TEMPLATE,
            param, 
            onSuccess: ((data) => {
                this.setState({
                    downloadLink: data.link
                });
                this.downloadBtn.click();
            })
        });
    }

    uploadDisposalMaterial = (e) =>{
        let formData = new FormData();
        const { dispatch , company_id} = this.props;
        formData.append('file', e.target.files[0]);
        formData.append('companyId', company_id);
        formData.append('type', 1);
        dispatch({
           type :ACTIONS.HOME.UPLOAD_DISPOSAL_MATERIAL,
           data: formData,
           onSuccess: ((data) => {
            if(data)
                this.getDisposalMaterial()
           })
        })
    }

    downloadDisposalMaterialDetails = () => {
        const { dispatch, company_id } = this.props;
        const param = { companyId: company_id };
        dispatch({ 
            type : ACTIONS.HOME.DOWNLOAD_DISPOSAL_MATERIAL_DETAILS, 
            param,
            onSuccess: ((data) => {
                this.setState({
                    downloadLink: data[0] ? data[0].link : data.link
                });
                this.downloadBtn.click();
            })
        });
    }

    previous = () =>{
		if(this.state.offset > 0){
			this.setState({
				offset : this.state.offset - 20
			},this.getDisposalMaterial);
		}
	}

	next = () =>{
		this.setState({
			offset : this.state.offset + 20
		},this.getDisposalMaterial);
    }

    toggleAdd = () => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen, editData: '' });
    }

    onEditRoute = (data) => {
        this.setState({ editData: {...data}, isOpen: true })
    }

    onSaveDisposalMaterial = (data) => {
        const { dispatch, company_id } = this.props;
        data['company_id'] = company_id;
        dispatch({
            type: ACTIONS.HOME.ADD_DISPOSAL_MATERIAL,
            data,
            onSuccess: this.getDisposalMaterial
        });
        this.toggleAdd();
    }

    onUpdateDisposalMaterial = (data) => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTIONS.HOME.UPDATE_DISPOSAL_MATERIAL,
            data,
            onSuccess: this.getDisposalMaterial
        });
        this.toggleAdd();
    }

    onDelete = (el) => {
        const { dispatch, company_id } = this.props;
        const data = {
            company_id: company_id,
            material_id: el.material_id
        }
        dispatch({
            type: ACTIONS.HOME.DELETE_DISPOSAL_MATERIAL,
            data,
            onSuccess: this.getDisposalMaterial
        })
    }

    clearFilterOnCancel = () => {
        this.setState({ search : '', offset: 0 }, this.getDisposalMaterial);
    }

    onSearch = () => {
        this.setState({ offset: 0 }, this.getDisposalMaterial);
    }

    render() {
        const { disposalMaterial, disposalMaterialDropdowns } = this.props;
        return (
            <div className={'location-network'}>
                <div>
                    <Search id = "search" value = {this.state.search} placeholder = "Search..." changeHandler = {this.changeHandler} click = {this.onSearch} canClear={this.state.search !== ''} handleClearFilter={this.clearFilterOnCancel} />
                    <Button value={'+ Add'} click={this.toggleAdd}/>
                    <button className="button-classic" onClick={this.downloadDisposalMaterialTemplate}>  Download Template </button>
                    <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
                    <button className="button-classic" onClick={() => {this.uploadExcel.click()}}> Upload </button>
                    <div className={'download-icon'} style={{backgroundImage: `url(${Download})`}} onClick={this.downloadDisposalMaterialDetails}/>
                    <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
                  <input type='file' style ={{display :'none'}} ref={(e) => { this.uploadExcel = e ;}} onChange={this.uploadDisposalMaterial} onClick={(e) => {e.target.value = null }}/>
                </div>
                <div className={'chain-details-section'}>
                    <div className={'list-section header'}>
                        <div className={'tabel-header'} style={{width: '80px'}}>{'Zone'}</div>
                        <div className={'tabel-header '} style={{width: '80px', 'paddingRight': '5px'}}>Depot Code</div>
                        <div className={'tabel-header'} style={{'paddingRight': '5px'}}>Depot Name</div>
                        <div className={'tabel-header'} style={{width: '100px'}}>Material Code</div>
                        <div className={'tabel-header'}>Material Desc.</div>
                        <div className={'tabel-header'} style={{width: '80px'}}>Product Type</div>
                        <div className={'tabel-header'} style={{width: '50px'}}>UOM</div>
                        <div className={'tabel-header'} style={{width: '50px'}}>MSP</div>
                        <div className={'tabel-header'} style={{width: '40px'}}>Actions</div>
                    </div>
        
                        {disposalMaterial && disposalMaterial.length > 0 && disposalMaterial.map((el, index) => {
                            return(<div className={'list-section content'} key={`disposal_product_${index}`}>
                                        <div className={'tabel-header'} style={{width: '80px'}}>{el.zone}</div>
                                        <div className={'tabel-header'} style={{width: '80px', 'paddingRight': '5px'}}>{el.depo_code}</div>
                                        <div className={'tabel-header'} style={{'paddingRight': '5px'}}>{el.depo_name}</div>
                                        <div className={'tabel-header'} style={{width: '100px'}}>{el.material_code}</div>
                                        <div className={'tabel-header'}>{el.material_description}</div>
                                        <div className={'tabel-header'} style={{width: '80px'}}>{el.product_type}</div>
                                        <div className={'tabel-header'} style={{width: '50px'}}>{el.pricing_basis}</div>
                                        <div className={'tabel-header'} style={{width: '50px'}}>{el.price}</div>
                                    <div className={'tabel-header'} style={{width: '40px'}}>
                                        <div className={'delete-icon'} style={{backgroundImage: `url(${Edit})`}} onClick={() => this.onEditRoute(el)}></div>
                                    </div>
                                    <div className={'tabel-header'} style={{width: '80px'}}>
                                    <div className={'delete-icon'} style={{backgroundImage: `url(${Delete})`}} onClick={() => this.onDelete(el)}/>
                                    </div>
                            </div>)})
                        }
                </div>
                <div className = "submit-section-pagination">
                    <div className ="next">
                        <Button value = "Next" click = {this.next}/>    
                    </div>
                    <div className ="pre">
                        <Button value = "Previous" click = {this.previous}/>
                    </div>
                </div>
                {this.state.isOpen && <AddDisposalProduct disposalMaterialDropdowns={disposalMaterialDropdowns} toggleAdd={this.toggleAdd} onSaveDisposalMaterial={this.onSaveDisposalMaterial} editData={this.state.editData} onUpdateDisposalMaterial={this.onUpdateDisposalMaterial}/>}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        disposalMaterial : state.home.disposalMaterial || [],
        disposalMaterialDropdowns: state.home.disposalMaterialDropdowns || {}
    };
};

export default connect(mapStateToProps)(DisposalMaterialMaster);