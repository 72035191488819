import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ACTION from "../../common/action_constant";
import InputText from "../../common/components/input-text/input-text";
import Button from "../../home/button-classic/button-classic";
import moment from 'moment';
import axios from 'axios';
import "./tatForm.less";

class TATForm extends Component {
    constructor(props) {
       super(props);
       this.state = {
        selectedLanes: [],
        isAllSelected: false,
        app_ip_add: ''
       }
    }

    componentDidMount(){
        this.getTATFormDetails();
        this.getIPAddress();
    }

    getIPAddress = async() => {
        const res = await axios.get('https://geolocation-db.com/json/')
        this.setState({app_ip_add: res.data.IPv4})
    }

    getTATFormDetails = () => {
        const { dispatch, match } = this.props;
        const params = `?companyId=${match.params.companyId}&vendorId=${match.params.vendorId}`
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_TAT_FORM_VENDOR_DETAILS,
            params,
            onSuccess: (data) => {
                this.setState({tatVendorDetailsCopy: data.list, vendorName: data.vendor_name})
            }
        })
    }

    updateTATForm = (status) => {
        const { dispatch } = this.props;
        const { selectedLanes, tatVendorDetailsCopy, app_ip_add } = this.state;
        let approval_list = [];
        (tatVendorDetailsCopy || []).forEach((lane) => {
            if(selectedLanes.includes(lane.tat_route_id)){
                approval_list.push({approval_status: status, tat_route_id: lane.tat_route_id, approval_remarks: lane.remarks, validity: lane.validity}) 
            }
        })
        const data = {
            approval_list,
            app_ip_add
        }
        dispatch({
            type: ACTION.DATA_GOVERNANCE.UPDATE_TAT_FORM,
            data,
            onSuccess: () => {
                this.getTATFormDetails()
            }
        })
    }

    handleLaneSelection = (e, id) => {
        let { selectedLanes, isAllSelected, tatVendorDetailsCopy } = this.state;
        if (selectedLanes.includes(id)) {
            let laneIndex = selectedLanes.findIndex(laneid => laneid === id)
            selectedLanes.splice(laneIndex, 1);
        } else {
            selectedLanes.push(id);
        }
        if(selectedLanes.length !== tatVendorDetailsCopy.length){
            isAllSelected = false;
        } else {
            isAllSelected = true;
        }
        this.setState({ selectedLanes, isAllSelected });
    }

    allLaneSelection = () => {
        let { isAllSelected, tatVendorDetailsCopy, selectedLanes } = this.state;
        let selectedLanesCopy = [];
        if(!isAllSelected){
            tatVendorDetailsCopy.map((lane) => {
                selectedLanesCopy.push(lane.tat_route_id)
            })
        }
        selectedLanes = selectedLanesCopy;
        this.setState({isAllSelected: !isAllSelected, selectedLanes})
    }

    render(){
        const { selectedLanes, isAllSelected, tatVendorDetailsCopy } = this.state;
        return(
            <div className='tatForm'>
                <div className='tat-header'>{`TAT Master Approval - ${this.state.vendorName || ''}`}</div>
                <div className='ml-10 mr-10' style={{width: '98%'}}>
                    <div className='flex' style={{marginLeft: '880px'}}>
                        <div className='mr-20 approveButton'>
                            <Button value="Approve Lane(s)" disabled={selectedLanes.length === 0} click={() => this.updateTATForm(2)} />
                        </div>
                        <div className='mr-20 rejectButton '>
                            <Button value="Reject Lane(s)" disabled={selectedLanes.length === 0} click={() => this.updateTATForm(3)} />
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr key={1}>
                                <th className='tat-table wt-5p'>
                                    <input 
                                        type="checkbox" 
                                        checked={isAllSelected}
                                        onClick={() => this.allLaneSelection()}
                                        className='m-5'
                                    />Sl. No.</th>
                                {/* <th className='tat-table wt-8p'>Vendor Name</th> */}
                                <th className='tat-table wt-8p'>Service Type</th>
                                <th className='tat-table wt-8p'>Service Mode</th>
                                <th className='tat-table wt-8p'>Route Type</th>
                                <th className='tat-table wt-8p'>Distribution Type</th>
                                <th className='tat-table wt-8p'>Contract Validity Date</th>
                                <th className='tat-table wt-5p'>Contract Price</th>
                                <th className='tat-table wt-10p'>From City</th>
                                <th className='tat-table wt-10p'>To City</th>
                                <th className='tat-table wt-10p'>To State</th>
                                <th className='tat-table wt-5p'>TAT</th>
                                <th className='tat-table wt-10p'>Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(tatVendorDetailsCopy || []).map((tat, index) => {
                                return(
                                <tr key={index}>
                                    <td className='tat-body wt-5p'>
                                    <input 
                                        type="checkbox" 
                                        checked={selectedLanes.includes(tat.tat_route_id)}
                                        onClick={(e) => this.handleLaneSelection(e, tat.tat_route_id)}
                                        className='m-5'
                                    />{index+1}</td>
                                    {/* <td className='tat-body wt-5p'>{tat.vendor_name}</td> */}
                                    <td className='tat-body wt-5p'>{tat.lan_type}</td>
                                    <td className='tat-body wt-5p'>{tat.service_mode}</td>
                                    <td className='tat-body wt-5p'>{tat.route_type}</td>
                                    <td className='tat-body wt-5p'>{tat.distribution_type}</td>
                                    <td className='tat-body wt-5p'>{tat.validity ? moment(tat.validity).format('DD/MM/YYYY') : ''}</td>
                                    <td className='tat-body wt-5p'>{tat.contract_price}</td>
                                    <td className='tat-body wt-5p'>{tat.from}</td>
                                    <td className='tat-body wt-5p'>{tat.to}</td>
                                    <td className='tat-body wt-5p'>{tat.to_state}</td>
                                    <td className='tat-body wt-5p'>{tat.tat + ' ' + tat.tat_uom}</td>
                                    <td className='tat-body wt-5p'>
                                        <div className="laneInput">
                                            <InputText 
                                                value={tat.remarks || ''} 
                                                placeholder="Remarks"
                                                changeHandler={(value) => {
                                                    const {tatVendorDetailsCopy} = this.state;
                                                    tatVendorDetailsCopy[index].remarks = value;
                                                    this.setState({ tatVendorDetailsCopy });
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )})}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
       loader: state.loader,
       msgpopup: state.msgpopup,
       tatVendorDetails: state.dataGovernance.tatVendorDetails,
    };
 };
 
 export default connect(mapStateToProps)(TATForm);