import React from "react";
import Select from "react-select";
import { DateRangePicker } from "react-dates";
import Moment from "moment";
import ACTIONS from "../../../common/action_constant";
import Download from "../../../../assets/images/download.svg";
import Delete from "../../../../assets/images/delete.svg";
import Loader from "../../../common/components/loader/loader";
import "./locationMgmt.less";
import e from "cors";
import { connect } from "react-redux";
import { getUserCompanyName } from "../../../../utils/common";

import SearchText from "../../../common/components/search-text/search-text";
import MainComponent from "../../main/main";
import { Link } from "react-router-dom";
import Edit from '../../../../assets/images/edit.svg'
import Save from '../../../../assets/images/save.svg'
import MessagePopUp from "../../../common/components/msg-popup/msg-popup";

class LocationMgmt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      company_id: "",
      data: []
    };
  }

  componentDidMount() {
    this.getLocationManagemetListing();
  }

  closeMsgPopup = () => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.MSG_DISPLAY.HIDE_MSG })
  }

  getLocationManagemetListing = () => {
    const { dispatch } = this.props;
    const { selectedPlantName, selectedState, selectedLOCchild, selectedChildCode, selectedZoneChild, from_date, to_date, search } = this.state;

    dispatch({
      type: ACTIONS.PARENTCHILD.GET_LOCATION_MANAGEMENT_LISTING,
      ...(selectedPlantName ? { plantName: selectedPlantName } : {}),
      ...(selectedState ? { state: selectedState } : {}),
      ...(selectedLOCchild ? { locationChild: selectedLOCchild } : {}),
      ...(selectedChildCode ? { locationCodeChild: selectedChildCode } : {}),
      ...(selectedZoneChild ? { zoneChild: selectedZoneChild } : {}),
      ...(from_date ? { fromDate: from_date } : {}),
      ...(to_date ? { toDate: to_date } : {}),
      ...(search ? { search: search } : {}),

      onSuccess: (data) => {
        let locChildCodeList = []
        let locChildList = []
        let plantList = []
        let stateList = []
        let zoneChildList = []

        data && data.locChildCodeList && data.locChildCodeList.length > 0 && data.locChildCodeList.map((d) => {
          locChildCodeList.push({
            label: d,
            value: d
          })
        })

        data && data.locChildList && data.locChildList.length > 0 && data.locChildList.map((d) => {
          locChildList.push({
            label: d,
            value: d
          })
        })

        data && data.plantList && data.plantList.length > 0 && data.plantList.map((d) => {
          plantList.push({
            label: d,
            value: d
          })
        })

        data && data.stateList && data.stateList.length > 0 && data.stateList.map((d) => {
          stateList.push({
            label: d,
            value: d
          })
        })

        data && data.zoneChildList && data.zoneChildList.length > 0 && data.zoneChildList.map((d) => {
          zoneChildList.push({
            label: d,
            value: d
          })
        })

        this.setState({
          data: data.list ? data.list : [],
          locChildCodeList: locChildCodeList || [],
          locChildList: locChildList || [],
          plantList: plantList || [],
          stateList: stateList || [],
          zoneChildList: zoneChildList || []
        })
      }
    })
  }

  changeHandler = (val, id) => {
    this.setState({
      [id]: val
    });
  }

  submitSearch = () => {
    this.getLocationManagemetListing()
  }

  clearFilterOnCancel = (e, type) => {
    this.setState({ [type]: '' }, () => {
      console.log(this.state.search);
    });
  }

  handleSelect = (item, id) => {
    this.state[id] = item.label
    this.setState(this.state, () => {
      this.getLocationManagemetListing()
    })
  }

  deleteData = (d) => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.PARENTCHILD.DELETE_PARENT_CHILD_LOCATION_DATA,
      data: d.child_id,
      onSuccess: (() => {
        this.getLocationManagemetListing()
      })
    })
  }

  handleEditChange = (value, id, idx) => {
    const { data } = this.state;
    data[idx][id] = value;
    this.setState({ data })
  }

  editDataSaveIcon = (d) => {
    const { dispatch } = this.props;
    delete d.is_Edit
    delete d.is_selected
    dispatch({
      type: ACTIONS.PARENTCHILD.EDIT_PARENT_CHILD_LOCATION_DATA,
      data: d,
      onSuccess: (() => {
        this.getLocationManagemetListing()
      })
    })
  }

  handleChecked = (Idx) => {
    const { data } = this.state;
    data[Idx].is_selected = data[Idx].is_selected ? false : true
    this.setState({ data })
  }

  allDataChecked = () => {
    const { data } = this.state;
    if (!data.find((d) => !d.is_selected)) {
      data.map((d) => d.is_selected = false)
    } else {
      data.map((d) => d.is_selected = true)
    }
    this.setState({ data })
  }

  deleteAllData = () => {
    const { data } = this.state;
    const { dispatch } = this.props;
    const filteredData = data.filter((d) => d.is_selected)
    let temp = []
    filteredData.map((d) => {
      temp.push({child_id : d.child_id})
    })
    let payload = {
      type: 1,
      list: temp
    }
    dispatch({
      type: ACTIONS.PARENTCHILD.DELETE_ALL_CHILD_DATA,
      data: payload,
      onSuccess: (() => {
        this.getLocationManagemetListing()
      })
    })
  }

  render() {
    const { loader } = this.props;
    const { data, locChildCodeList, locChildList, plantList, stateList, zoneChildList } = this.state;
    const customStyles = {
      control: (base) => ({
        ...base,
        borderColor: "#528aea",
      }),
    };
    return (
      <div className="master">
        {loader.isLoading && <Loader />}

        {
          this.props.msgpopup.active && <MessagePopUp close={this.closeMsgPopup} type={this.props.msgpopup.type} msg={this.props.msgpopup.msg} />
        }
        <MainComponent>
          <div className="LM-upload">
            <div className="upload">
              <div className="border">
                <div>
                  <h2>
                    Emami Parent Child Location Management
                  </h2>
                </div>
                <div className="flex-item flex-between">

                  <div className="search-filter-section">
                    <SearchText id="search" value={this.state.search} placeholder="Search Customer Code..." changeHandler={this.changeHandler} click={this.submitSearch} canClear={this.state.search !== ''} handleClearFilter={(e) => this.clearFilterOnCancel(e, "search")} />
                  </div>

                  <div className="flex-item">

                    <Link to='/clusterMapping' className="link">
                      Cluster Mapping
                    </Link>
                    <Link to='/parentChildView' className="link">
                      Parent Child View
                    </Link>

                    <Link className="upload-button" to='/uploadLocation'>
                      Upload Location
                    </Link>
                  </div>
                </div>
                <div className="flex-item">
                  <Select
                    styles={customStyles}
                    options={plantList || []}
                    onChange={(item) => this.handleSelect(item, 'selectedPlantName')}
                    className="plantName"
                    placeholder="Plant Name"
                    selectedItem={(option) => option.label === this.state.selectedPlantName}
                  />

                  <Select
                    styles={customStyles}
                    className="state"
                    options={stateList || []}
                    onChange={(item) => this.handleSelect(item, 'selectedState')}
                    placeholder="State"
                    selectedItem={(option) => option.label === this.state.selectedState}
                  />

                  <Select
                    styles={customStyles}
                    className="loc"
                    options={locChildList || []}
                    onChange={(item) => this.handleSelect(item, 'selectedLOCchild')}
                    placeholder="LOC Child"
                    selectedItem={(option) => option.label === this.state.selectedLOCchild}
                  />

                  <Select
                    styles={customStyles}
                    className="locCode"
                    options={locChildCodeList || []}
                    onChange={(item) => this.handleSelect(item, 'selectedChildCode')}
                    placeholder="LOC Code Child"
                    selectedItem={(option) => option.label === this.state.selectedChildCode}
                  />

                  <Select
                    styles={customStyles}
                    className="zone"
                    options={zoneChildList || []}
                    onChange={(item) => this.handleSelect(item, 'selectedZoneChild')}
                    placeholder="Zone Child"
                    selectedItem={(option) => option.label === this.state.selectedZoneChild}
                  />

                  <div className="flex-item order-daterange">
                    <DateRangePicker
                      startDate={this.state.from_date ? Moment(+this.state.from_date) : null}
                      startDateId="startDate"
                      endDate={this.state.to_date ? Moment(+this.state.to_date) : null}
                      endDateId="endDate"
                      startDatePlaceholderText="00/00/0000"
                      endDatePlaceholderText="00/00/0000"
                      onDatesChange={({ startDate, endDate }) => {
                        let { from_date, to_date } = this.state;
                        from_date = startDate ? startDate.valueOf() : from_date;
                        to_date = endDate ? endDate.valueOf() : to_date;
                        this.setState({ from_date, to_date }, () => {
                          this.getLocationManagemetListing()
                        });
                      }}
                      focusedInput={this.state.focusedInput}
                      onFocusChange={focusedInput => this.setState({ focusedInput })}
                      displayFormat={'DD/MM/YYYY'}
                      noBorder={true}
                      minimumNights={0}
                      isOutsideRange={() => false}
                    />
                  </div>
                </div>
              </div>

              <div
                className={"delete-icon"}
                style={{ backgroundImage: `url(${Delete})`, marginLeft: '10px', marginTop:'10px' }}
                onClick={() => this.deleteAllData()}
              />
              <div className="table">
                <table className="table-view">
                  <thead>
                    <tr className="table-tr">
                      <th>
                        <CustomCheckBox toggle={() => this.allDataChecked()} selected={data.length > 0 && !data.find((d) => !d.is_selected)} />
                      </th>
                      <th className="tCenter" width="10%">
                        Plant Name
                      </th>
                      <th>
                        Plant State
                      </th>
                      <th className="tCenter" width="10%">
                        Plant Code
                      </th>
                      <th className="tCenter" width="10%">
                        Customer Code
                      </th>
                      <th className="tCenter" width="20%">
                        Customer Name
                      </th>
                      <th className="tCenter" width="10%">
                        State
                      </th>
                      <th className="tCenter" width="10%">
                        LOC Child
                      </th>
                      <th className="tCenter" width="10%">
                        LOC Code Child
                      </th>
                      <th
                        className="tCenter"
                        width="10%"
                      >
                        Zone Child
                      </th>
                      <th
                        className="tCenter"
                        width="10%"
                      >
                        Date
                      </th>
                      <th
                        className="tCenter"
                        width="10%"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(data || []).map((d, Idx) => {
                      return (
                        <tr className="tr-1">
                          <td>
                            {/* <input 
                                 type="checkbox" 
                                 checked={d.is_selected}
                                 onChange={(e) => this.handleChecked(Idx)}
                            /> */}
                            <CustomCheckBox id={Idx} toggle={() => this.handleChecked(Idx)} selected={d.is_selected} />
                          </td>
                          <td>
                            {d.is_Edit ?
                              <input
                                id="plant_name"
                                type={"text"}
                                className="input-box-location-mgmt"
                                placeholder="Cluster Name"
                                value={d.plant_name}
                                onChange={(e) => this.handleEditChange(e.target.value, 'plant_name', Idx)}
                              />
                              :
                              (d.plant_name || '-')
                            }
                          </td>
                          <td>
                            {d.is_Edit ?
                              <input
                                id="plant_state"
                                type={"text"}
                                className="input-box-location-mgmt"
                                placeholder="Cluster Name"
                                value={d.plant_state}
                                onChange={(e) => this.handleEditChange(e.target.value, 'plant_state', Idx)}
                              />
                              :
                              (d.plant_state || '-')
                            }
                          </td>
                          <td>
                            {d.is_Edit ?
                              <input
                                id="plant_code"
                                type={"text"}
                                className="input-box-location-mgmt"
                                placeholder="Cluster Name"
                                value={d.plant_code}
                                onChange={(e) => this.handleEditChange(e.target.value, 'plant_code', Idx)}
                              />
                              :
                              (d.plant_code || '-')
                            }
                          </td>
                          <td>
                            {d.customer_code || '-'}
                          </td>
                          <td>
                            {d.customer_name || '-'}
                          </td>
                          <td>
                            {d.is_Edit ?
                              <input
                                id="state"
                                type={"text"}
                                className="input-box-location-mgmt"
                                placeholder="Cluster Name"
                                value={d.state}
                                onChange={(e) => this.handleEditChange(e.target.value, 'state', Idx)}
                              />
                              :
                              (d.state || '-')
                            }
                          </td>
                          <td>
                            {d.is_Edit ?
                              <input
                                id="location_child"
                                type={"text"}
                                className="input-box-location-mgmt"
                                placeholder="Cluster Name"
                                value={d.location_child}
                                onChange={(e) => this.handleEditChange(e.target.value, 'location_child', Idx)}
                              />
                              :
                              (d.location_child || '-')
                            }
                          </td>
                          <td>
                            {d.is_Edit ?
                              <input
                                id="location_code_child"
                                type={"text"}
                                className="input-box-location-mgmt"
                                placeholder="Cluster Name"
                                value={d.location_code_child}
                                onChange={(e) => this.handleEditChange(e.target.value, 'location_code_child', Idx)}
                              />
                              :
                              (d.location_code_child || '-')
                            }
                          </td>
                          <td>
                            {d.is_Edit ?
                              <input
                                id="zone_child"
                                type={"text"}
                                className="input-box-location-mgmt"
                                placeholder="Cluster Name"
                                value={d.zone_child}
                                onChange={(e) => this.handleEditChange(e.target.value, 'zone_child', Idx)}
                              />
                              :
                              (d.zone_child || '-')
                            }
                          </td>
                          <td>{Moment(d.location_date).format('DD/MM/YYYY') || '-'}</td>
                          <td>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              {d.is_Edit ?
                                <img src={Save}
                                  width="22px"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => this.editDataSaveIcon(d)}
                                />
                                :
                                <img src={Edit} width="22px" style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    const { data } = this.state;
                                    data[Idx].is_Edit = true;
                                    this.setState({ data })
                                  }}
                                />
                              }
                              <div
                                className={"delete-icon"}
                                style={{ backgroundImage: `url(${Delete})`, marginLeft: '10px' }}
                                onClick={() => this.deleteData(d)}
                              />
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                    {/* );
                  })} */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </MainComponent>
      </div>
    );
  }
}

const CustomCheckBox = (props) => {
  return (
    <div className={props.selected ? "custome-checkbox-wrap-parent-child selected" : "custome-checkbox-wrap-parent-child"} onClick={() => props.toggle(props.id)}>
      {props.selected && <div className="inner-circle"></div>}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    loader: state.loader,
    msgpopup: state.msgpopup,
  };
};

export default connect(mapStateToProps)(LocationMgmt);
