import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import './vendorsList.less';
import history from "../../history";
import MainComponent from "../../home/main/main";
import logo from "../../../assets/images/group-2.svg";
import crossMark from "../../../assets/images/cross-mark.svg";
import View from "../../../assets/images/view.svg";
import Select from 'react-select';
import CheckBox from "../../components/checkbox/checkbox";
import AddCompany from "../add-company/add-company";
import ACTION from "../../common/action_constant";
import Button from "../../home/button-classic/button-classic";
import moment from "moment";
import Loader from "../../common/components/loader/loader";
import MSGPopUp from "../../common/components/msg-popup/msg-popup";
import SearchText from "../../common/components/search-text/search-text";
import { getCookie } from "../../../utils/cookies";
import { ABFRL_COMPANY_IDS, AWL_COMPANY_IDS, CARGILL_COMPANY_IDS, SAP_DEEPAK_PHENOLICS, isCompanyAdmin } from "../../../utils/common.js";
import { toPascalCase } from "../../../utils/validate";
import { DEEPAK_COMPANY_ID } from '../../../utils/common';
import StarRatings from "react-star-ratings";
import NewVendor from "../newVendor/newVendor";
import { withRouter } from 'react-router-dom';
import cancel from '../../../assets/images/NewCancel.svg'


const APPROVALS = [
	// { id: 1, type: 'Vendor' },
	{ id: 2, type: 'Logistic' },
	{ id: 3, type: 'Commercial' },
	{ id: 4, type: 'Legal' },
	{ id: 5, type: 'Admin' },
];

class VendorsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeButton: 1,
			search: "",
			offset: 0,
			checkbox: false,
			addCompanyModal: false,
			companyApp: null,
			vendorCategory: 1,
			isHover: false
		}
	}
	handleButtonClick = (buttonIndex) => {
		this.setState({ activeButton: buttonIndex }, () => {
		  if (buttonIndex === 2) {
			this.callHomeDetails(true);
		  }else if(buttonIndex === 1){
			this.callHomeDetails();
		  }
		});
	  };
	changeHandler = (val, id) => {
		this.setState({
			[id]: val
		});
	}
	submitSearch = () => {
		this.callHomeDetails()
	}
	callHomeDetails = (isExWorks) => {
		const { dispatch } = this.props;
		let params = "?companyId=" + JSON.parse(getCookie('user')).company_id + "&limit=10&offset=" + this.state.offset + "&userId=" + JSON.parse(getCookie('user')).user_id;
		if(this.state.search != ""){
		    params = params +"&search="+this.state.search;
		}
		if(this.state.vendorCategory != "" && !JSON.parse(getCookie('user')).is_admin){
		    params = params +"&status="+this.state.vendorCategory;
		}
		if(!!isExWorks){
		    params = params +"&exworks="+ isExWorks;
		}
		dispatch({ type: ACTION.VENDORS.GET_ALL_VENDOR_LIST, params });
	}
	getAllScope = () => {
      const { dispatch } = this.props;
      dispatch({
         type: ACTION.VENDORS.GET_ALL_SCOPE
      })
   }
	handleChange = (selectedOption) => {
		this.setState({ selectedOption }, () => {
			this.callHomeDetails()
		});
	}
	checkBoxChange = (e, id, type) => {
		e.preventDefault();
		e.stopPropagation();
		const data = {
			company_type: type,
			company_id: id,
			is_active: e.target.checked
		}
		// const { dispatch } = this.props;
		// dispatch({type : ACTION.HOME.UPDATE_COMPANY_STATUS ,data})
	}
	clearFilterOnCancel = (e, type) => {
		this.setState({ [type]: '' }, () => {
			this.callHomeDetails();
		});
	}
	toggelAddCompany = () => {
		const { addCompanyModal } = this.state;
		this.setState({
			addCompanyModal: !addCompanyModal,
			companyApp: null,
		})
		this.callHomeDetails();
	}
	submitAddCompany = (data) => {
		const { dispatch } = this.props;
		const user = JSON.parse(getCookie('user'));
		const sourceDataString = data.sourceData
		const payload = {
			...data,
			userId: user.user_id,
		}
		if (data.sourceData) {
			payload.sourceData = data.sourceData.value;
		  }
		dispatch({ type: ACTION.VENDORS.POST_PRIMARY_VENDOR_FIRST_TIME, payload });
		this.setState({ addCompanyModal: false })
		this.callHomeDetails();
	}
	updateCompany = (params) => {
		const { dispatch } = this.props;
		if (params.sourceData) {
			params.sourceData = params.sourceData.value;
		  }
		dispatch({ type: ACTION.VENDORS.EDIT_VENDOR_EMAIL, params });
		this.setState({ addCompanyModal: false })
		this.callHomeDetails();
	}
	sendEmail = (params) => {
		const { dispatch } = this.props;
		dispatch({ type: ACTION.VENDORS.RETRIGGER_VENDOR_FORM, params });
		this.setState({ addCompanyModal: false })
	}
	componentDidMount() {
		// if (isCompanyAdmin()) {
		// this.getCompanyDetails();
		// } else {
			// this.getAllVendors();
		// }addPrimaryVendorDetails
		if (this.props.match.params.isApproval === '1') {
			this.getChargesMaster();
			this.getAllScope();
		}
		this.callHomeDetails();
		this.getApprovedVendorsCount();

		// if (this.props.match.params.isApproval === '2') {
			this.getVendorApproversCount();
		// }
	}
	// componentDidUpdate(prevProps, prevState, snapshot){
	// 	const {match} = this.props
	// 	if(prevProps.match.params.isApproval !== match.params.isApproval){
	// 		this.handleButtonClick(1)
	// 		this.callHomeDetails();
	// 	}
		
	// }
	componentDidUpdate(prevProps, prevState, snapshot) {
		const { match } = this.props;
		if (prevProps.match.params.isApproval !== match.params.isApproval) {
		  // Reset vendorCategory to 1 when the component updates
		  this.setState({ vendorCategory: 1 }, () => {
			this.handleButtonClick(1);
			this.callHomeDetails();
		  });
		}
	  }
	  
	
	getAllVendors = (id) => {
		const { dispatch } = this.props;
		const company_id = JSON.parse(getCookie('user')).company_id;
		const params = DEEPAK_COMPANY_ID.includes(company_id) ? `?mode_type=${id}&companyId=${company_id}` : `?mode_type=${id}`;
		dispatch({
			type: ACTION.VENDORS.GET_ALL_VENDOR_MODES,
			params 
		});
	}
	getVendorApproversCount = () => {
		const { dispatch } = this.props;
		dispatch({
			type: ACTION.VENDORS.GET_ALL_VENDOR_APPROVERS,
			params: `?company_id=${JSON.parse(getCookie('user')).company_id}&approval_type=3&approval_mode=2`
		})
	}
	getApprovedVendorsCount = () => {
		const { dispatch } = this.props;
		dispatch({
			type: ACTION.VENDORS.GET_VENDORA_PPROVAL_COUNTS,
			params: `companyId=${JSON.parse(getCookie('user')).company_id}`
		})
	}
	getChargesMaster = () => {
		const { dispatch } = this.props;
		dispatch({
			type: ACTION.VENDORS.GET_CHARGES,
			params: `?company_id=${JSON.parse(getCookie('user')).company_id}`
		})
	}
	activateVendor = (e, data) => {
		const { dispatch } = this.props;
		dispatch({
			type: ACTION.VENDORS.ACTIVATE_VENDOR,
			params: {
				ob_vendor_id: data.ob_vendor_id,
				is_active: e.target.checked,
				updateby: JSON.parse(getCookie('user')).email,
			},
			onSuccess: (() => {
				this.callHomeDetails();
			})
		})
	}
	previous = () => {
		if (this.state.offset > 0) {
			this.setState({
				offset: this.state.offset - 10
			}, () => {
				this.callHomeDetails()
			});
		}
	}
	previousEX = () => {
		if (this.state.offset > 0) {
			this.setState({
				offset: this.state.offset - 10
			}, () => {
				this.callHomeDetails(true)
			});
		}
	}
	next = () => {
		this.setState({
			offset: this.state.offset + 10
		}, () => {
			this.callHomeDetails()
		});
	}
	nextEX = () => {
		this.setState({
			offset: this.state.offset + 10
		}, () => {
			this.callHomeDetails(true)
		});
	}
	goToCompanyDetails = (e = { event }, id, type) => {
		if (e.target.tagName !== "LABEL" && e.target.type !== "checkbox" && !e.target.parentElement.classList.value.includes('switch')) {
			e.preventDefault();
			e.stopPropagation();
			this.props.history.push("/home/" + id + "/" + type + "?tab=1")
		}
	}

	showRating = (isHover, company) => {
		this.setState({ isHover, companyApp: isHover ? company : null});
	}

	closeMsgPopup = () => {
		const { dispatch } = this.props;
		dispatch({ type: ACTION.MSG_DISPLAY.HIDE_MSG })
		this.callHomeDetails();
	}

	changeRating = (newRating) => {
		this.setState({
		  rating: newRating
		});
	 }
  

	handleApproval = (type, companyApp) => {
		const { dispatch } = this.props;
		const { approvalStatus, rating } = this.state;
		const user = JSON.parse(getCookie('user'))
		dispatch({
			type: ACTION.VENDORS.UPDATE_VENDOR_APPROVAL,
			params: {
				ob_vendor_id: companyApp.ob_vendor_id,
				company_id: user.company_id,
				step: this.state.approvalStatus,
				level: user.level || 0,
    			level_name: user.level_name || `${user.level}` || '',
				is_approved: type,
				updateby: companyApp.updateby,
				department_id: user.department_id || '',
				branch_id: this.branchIdLogic(),
				approved_by_user: user.email || '',
				reason_for_rejection: this.state.reason_for_rejection || null,
				reason_for_resend : this.state.reason_for_resend || null,
				created_by: companyApp.created_by,
				rating
			},
			onSuccess: (() => {
				this.callHomeDetails();
			})
		})
		this.setState({ approvalStatus: null, rating: 0 });
	} 

	branchIdLogic=()=>{
		// (AWL_COMPANY_IDS.includes(user.company_id) || CARGILL_COMPANY_IDS.includes(user.company_id)) ? 
		// user.branch_id : 
		// (user.branches && user.branches.length > 0 ? 
		// user.branches[0].branch_id : '')

		const user = JSON.parse(getCookie('user'))
		
		if(AWL_COMPANY_IDS.includes(user.company_id)){
			 return user.branch_id;
		}
		if(CARGILL_COMPANY_IDS.includes(user.company_id)){
			 return user.branch_id;
		}
		if(SAP_DEEPAK_PHENOLICS.includes(user.company_id)){
			 return user.branch_id;
		}
		if(user.branches && user.branches.length > 0){
			 return user.branches[0].branch_id
		}
		return '';
 }

     shouldRenderOnboardNewVendorBtn =()=>{
		// {(!DEEPAK_COMPANY_ID.includes(user.company_id) ? (this.props.match.params.isApproval === '1' && user && user.is_admin) : true)
		 const user = JSON.parse(getCookie('user'));
		 const C1 = DEEPAK_COMPANY_ID.includes(user.company_id)
		 const C2 = ABFRL_COMPANY_IDS.includes(user.company_id)
		 const C3 = (this.props.match.params.isApproval === '1' && user && user.is_admin)
		 
		 if(C1 || C2) {
			return true 
		 }

		 if(C3) {
			return true 
		 }

        return false 
	 }  

	render() {
		const { activeButton } = this.state;
		const { vendors, vendorForm } = this.props;
        const companyList = vendors && vendors.vendorsList.length > 0 ? vendors.vendorsList : [];
		const approvedVendorCounts = vendors && vendors.approvalCounts.length > 0 ? vendors.approvalCounts[0] : [];
		const { approvalStatus, companyApp, vendorCategory } = this.state;
		const isApproval = this.props.match.params.isApproval === '2';
		const user = JSON.parse(getCookie('user'));
		
		const dept_name = user && user.department_name ? user.department_name : '';
		return (
                 <div className="home-wrap vendors-list-wrap">
				{
					this.state.addCompanyModal && 
					((ABFRL_COMPANY_IDS.includes(user.company_id)) ? 
					<NewVendor 
					 close={this.toggelAddCompany} 
					 submit={this.submitAddCompany} 
					 chargesList={vendors.chargesList} 
					 vendorModes={vendors.vendorModes} 
					 scopesList={vendorForm && vendorForm.scopesList ? vendorForm.scopesList : []} 
					 companyApp={companyApp} 
					 getAllVendors={this.getAllVendors} 
					 update={this.updateCompany} 
					 sendEmail={this.sendEmail} />
					 : 
					 <AddCompany 
					  close={this.toggelAddCompany} 
					  submit={this.submitAddCompany} 
					  chargesList={vendors.chargesList} 
					  vendorModes={vendors.vendorModes} 
					  scopesList={vendorForm && vendorForm.scopesList ? vendorForm.scopesList : []} 
					  companyApp={companyApp} 
					  getAllVendors={this.getAllVendors} 
					  update={this.updateCompany} 
					  sendEmail={this.sendEmail} />)
				}
				{
					this.props.loader.isLoading && <Loader />
				}
				{
					this.props.msgpopup.active && <MSGPopUp close={this.closeMsgPopup} type={this.props.msgpopup.type} msg={this.props.msgpopup.msg} />
				}
				<div className="wrapper">
					<MainComponent>
						

	 <div className="App">
        {this.props.match.params.isApproval === '1' && <div className="button-container">
          <button
            className={activeButton === 1 ? 'active' : ''}
            onClick={() => this.handleButtonClick(1)}
			>
            Vendor List
          </button>
		  {DEEPAK_COMPANY_ID.includes(user.company_id)  && (
          <button
             className={activeButton === 2 ? 'active' : ''}
             onClick={() => this.handleButtonClick(2)}
                   >
              Ex-Works
              </button>
             )}

        </div>}
        <div className="content">
          {activeButton === 1 ? <Fragment key={"VD"}>
					<div className="sub-heading-section">
						{this.props.match.params.isApproval === '2' &&	<div className="sub-heading">  Vendor List </div>}
							{this.shouldRenderOnboardNewVendorBtn() && <div className="add-company-section">
								<Button value="+ Onboard New Vendor" click={this.toggelAddCompany} />
							</div>}
							{this.props.match.params.isApproval === '2' && 
							<div className="add-company-section">
								{
									Object.keys(approvedVendorCounts || {}).length > 0 &&
									<div className="list-count-box">
										<h4 className="mb-10"> Approval Status </h4>
										<div>Total: {approvedVendorCounts.total}</div>
										<div>Pending: {approvedVendorCounts.pending_count}</div>
										<div>Approved: {approvedVendorCounts.approved_count}</div>
										<div>Rejected: {approvedVendorCounts.rejected_count}</div>
										<div>Resent : {approvedVendorCounts.resent_count}</div>
									</div>
								}
							</div>}
						</div>
						<div className="search-filter-section">
							<SearchText id="search" value={this.state.search} placeholder="Search Company..." changeHandler={this.changeHandler} click={this.submitSearch} canClear={this.state.search !== ''} handleClearFilter={(e) => this.clearFilterOnCancel(e, "search")} />
						</div>
						{this.props.match.params.isApproval === '2' && user && !user.is_admin && <div className="mb-10">
							{[{name: 'Pending', id: 1}, {name: 'Approved', id: 2}, {name: 'Rejected', id: 3}, {name: 'Resend', id: 4}].map((type, statusIndex) => {
								return (
									<div key={type.id}
										className={type.id===vendorCategory ? `tab-box active` : `tab-box`}
										onClick={type.id!==vendorCategory ? () => { this.setState({ vendorCategory: type.id }, () => { this.callHomeDetails() }) } : null}
									>  {type.name}  </div>
								)
							})}
						</div>}
						<div className="company-list-wrap">
							<div className="list-section header">
								<div className={isApproval ? "account-type table-header" : "approvals-listing table-header"}>
									Vendor name
                                </div>
								<div className="account-type vendor-email table-header">
									Vendor Email
                                </div>
								{!(DEEPAK_COMPANY_ID.includes(user.company_id))&&<div className="account-type table-header">
									Type
                                </div>}
								<div className="status table-header" style={isApproval ? {width: "50px"} : null}>
									Status
                                </div>
								{!isApproval && AWL_COMPANY_IDS.includes(user.company_id) &&
									<div className="status table-header" style={{width:'100px'}}>
										Added By
									</div>
								}
								{
									isApproval &&
									<div className="approvals-listing table-header">
										Approval Status
                                    </div>
								}
								<div className="status table-header " style={isApproval ? {width: "50px"} : {marginLeft:'10px'}}>Rating</div>

								{(this.props.match.params.isApproval === '1' ? (user && user.is_admin) : true) &&
									<div className="table-header dInline">View</div>
								}

								{
									(CARGILL_COMPANY_IDS.includes(user.company_id) || DEEPAK_COMPANY_ID.includes(user.company_id)) && this.props.match.params.isApproval === '1' && user && (user.is_admin || user.is_ho_user) &&
									<div className="status table-header" style={{marginLeft:"20px"}}>SAP Vendor Form</div>
								}
							</div>
							{
								// this.props.home.homeDetails && this.props.home.homeDetails.length == 0 && <div className = "no-records-section">No records found.</div>
							}
							{
								companyList.map((company, index) => {
									const compnay_logo = company.company_logo == "" ? logo : company.company_logo;
									return (
										<div key={index+"VD" }
											className="list-section content"
										// onClick = {(e) => this.goToCompanyDetails(e, company.id, company.company_type)}
										>
											<div className={isApproval ? "account-type" : "approvals-listing"}>
												{compnay_logo && compnay_logo.length > 0 && <img src={compnay_logo} className="comany-logo" />}
												<div className="compnay-details">
												<div onMouseOver={() => this.showRating(true, company)} onMouseLeave={() => this.showRating(false, company)}>
														<div className="name">{toPascalCase(company.vendor_company_name)}</div>
														<div className="date">{moment(company.created).format('DD-MMM-YYYY')}</div>
														
														<div className="date">
															<StarRatings
																rating={company.avg_rating}
																starDimension="18px"
																starSpacing="2px"
															/>
														</div>
													</div>
												</div>
												
											</div>
											<div className="account-type vendor-email">
												<div className="admin-email">{company.email}</div>
												{/*<div className = "admin-pan">{company.pan_no}</div>*/}
												{company.vendor_code && <div className="name">{'code: ' + company.vendor_code}</div>}
												{company.carrier_code && <div className="name">{'carrier Code: ' + company.carrier_code}</div>}		
											</div>
											{!DEEPAK_COMPANY_ID.includes(user.company_id)&&
											<div className="account-type">
												<div>
													{(company.type || []).length > 0 ?
														<div>
															{(company.type || []).map((cmp, index) => {
																return <span> {index !== 0 ? ', ' : ''} {cmp.name} {' '}</span>
															})}
														</div>
														:
														<div> - </div>
													}
												</div>
											</div>}
											<div className="status" style={isApproval ? {width: "50px"} : null}>
												<CheckBox
													id={company.ob_vendor_id}
													type={company.company_type}
													status={company.is_active}
													handleChange={(e) => { this.activateVendor(e, company) }}
												/>
											</div>
											{!isApproval && AWL_COMPANY_IDS.includes(user.company_id) && 
											<div className='status' style={{fontSize:'14px', width:'100px'}}>
												{company.created_user_name}
											</div>
											}
											{
												isApproval &&
												<div className="approvals-listing table-header">
													{(vendors.approvalLevel || []).map((type) => {
														const approvalItem = (company.approvals || []).filter((obj) => { return obj.department_id === type.department_id && obj.is_approved==='APPROVED' });
														const rejectedItem = (company.approvals || []).filter((obj) => { return obj.department_id === type.department_id && obj.is_approved==='REJECTED' });
														const resendItem = (company.approvals || []).filter((obj) => { return obj.department_id === type.department_id && obj.is_approved==='RESEND' });
														const isCurrentDept = type.department_name===dept_name
														let approveStatus = null; //( ? approvalItem[0].is_approved : null)
														if (rejectedItem.length > 0) {
															if (type.level_count <= rejectedItem.length) approveStatus = 'reject';
														}
														if (approvalItem.length > 0) {
															if (type.level_count <= approvalItem.length) approveStatus = 'approve';
														}
														if (resendItem.length > 0) {
															if (type.level_count <= resendItem.length) approveStatus = 'resend';
														}
														return (
															<div key={`${type.department_name}-${type.department_id}`}
																className={isCurrentDept ? `grey-box ${approveStatus} enable-menu` : `grey-box ${approveStatus} disable-menu`}
																onClick={isCurrentDept ? () => { this.setState({ approvalStatus: type.department_name, companyApp: company }) } : null}
															>  {type.department_name}  </div>
														)
													})}
												</div>
											}
											<div className="status fs-14" style={isApproval ? {width: "50px"} : {marginLeft:'10px'}}>
												{company.prequalifications_count ? (company.prequalifications_count + '/ 30') : '-' }
											</div>

											{(this.props.match.params.isApproval === '1' ? (user && user.is_admin) : true) &&
												<div className="dInline">
													<div className="delete-icon"
														style={{ backgroundImage: `url(${View})` }}
														onClick={() => {
															if (isApproval) {
																localStorage.setItem('companyApproval', JSON.stringify(company));
																if (AWL_COMPANY_IDS.includes(user.company_id)) {
																	if (user.level && user.level == 2) {
																		history.push(`/vendor-headoffice-approver/${company.company_id}/${company.ob_vendor_id}/1`)
																	}
																	else if (user.level && user.level == 4) {
																		history.push(`/vendor-bank-approver/${company.company_id}/${company.ob_vendor_id}/1`)
																	}
																	else {
																		history.push(`/vendor-approver/${company.company_id}/${company.ob_vendor_id}/1`)
																	}
																}
																else {
																	history.push(`/vendor-approver/${company.company_id}/${company.ob_vendor_id}/1`)
																}
															} else {
																this.setState({ companyApp: company, addCompanyModal: true });
															}
														}}
													/>
												</div>
											}
											

											{(CARGILL_COMPANY_IDS.includes(user.company_id) || DEEPAK_COMPANY_ID.includes(user.company_id)) && this.props.match.params.isApproval === '1' && user && (user.is_admin || user.is_ho_user) &&
												<div className="dInline" style={{marginLeft:'30px'}}>
												<div className="delete-icon"
													style={{ backgroundImage: `url(${View})` }}
													onClick={() => {
														// if (isApproval) {
															localStorage.setItem('companyApproval', JSON.stringify(company));
															{CARGILL_COMPANY_IDS.includes(user.company_id)&&
																history.push(`/sap-vendor-form/${company.company_id}/${company.ob_vendor_id}/1`);
															}
															{DEEPAK_COMPANY_ID.includes(user.company_id)&&
																history.push(`/sap-vendor-form/${company.company_id}/${company.ob_vendor_id}/1/newForm`);
																// history.push(`/nnn`);
															}
															
														// } else {
														// 	this.setState({ companyApp: company, addCompanyModal: true });
														// }
													}}
												/>
											</div>
											}


											{
												this.state.approvalStatus && Object.keys(companyApp).length > 0 && companyApp.ob_vendor_id===company.ob_vendor_id &&
												<div className="msg-pop-up" onClick={this.props.close}>
													<div className='newpop'>
													<div className="msg-section">
																<span className="cancel-icon" onClick={() => { this.setState({ approvalStatus: null }) }} style={{ backgroundImage: `url(${cancel})` }}></span>
														<div className="msg">
															Are You Sure To Process This Vendor Data For {this.state.approvalStatus} Validation?
                                                    	</div>
														{(DEEPAK_COMPANY_ID.includes(user.company_id) || AWL_COMPANY_IDS.includes(user.company_id)) &&
														<Fragment>
															<div className="mb-10">Rating:
															<StarRatings
																rating={this.state.rating}
																starRatedColor='rgb(243 236 5 / 85%)'
																changeRating={this.changeRating}
																numberOfStars={5}
																name='rating'
																starDimension='25px'
																starHoverColor='rgb(243 236 5 / 85%)'
															/>
															</div>
														</Fragment>
														}
														<div className="button-section">
															{
																(!this.state.isRejected && !this.state.isResend) &&
																<Fragment>
																	<div style={{display:'flex', marginLeft:'0px'}}>
																	<Button value="Accept"
																		click={() => { this.handleApproval('APPROVED', companyApp) }}
																	/>
																	<Button value="Reject"
																		click={() => {
																			this.setState({ isRejected: true });
																			// this.handleApproval(false, companyApp) 
																		}}
																	/>
																	<Button value="Resend"
																		click={() => {
																			this.setState({ isResend: true });
																			// this.handleApproval(false, companyApp) 
																		}}
																	/>
																	</div>
																	
																</Fragment>
															}
															{
																(this.state.isRejected || this.state.isResend) &&
																<div className="form-textarea">
																	<label>{this.state.isRejected ? 'Rejection Reason:' : 'Resend Reason'}</label>
																	<textarea
																		className="text-input-content"
																		rows="5"
																		cols="30"
																		value={this.state.isRejected ? this.state.reason_for_rejection : this.state.reason_for_resend}
																		placeholder="Content"
																		onChange={(e) => { 
																			if(this.state.isRejected){
																				this.setState({ reason_for_rejection: e.target.value }) 
																			} 
																			else{
																				this.setState({ reason_for_resend: e.target.value }) 
																			}
																		}}
																	/>
																	<div className="button-section">
																		<Button value={this.state.isRejected ? "Reject" : 'Resend'}
																			click={() => {
																				if(this.state.isRejected) {
																					this.handleApproval('REJECTED', companyApp)
																					this.setState({ isRejected: false });
																				} 
																				else{
																					this.handleApproval('RESEND', companyApp)
																					this.setState({ isResend: false });
																				}
																			}}
																		/>
																		<Button value="Cancel"
																			click={() => { this.setState({ isRejected: false, isResend: false }); }}
																		/>
																	</div>
																</div>
															}
														</div>
													</div>
													</div>
												</div>
												
											}
											{this.state.isHover && companyApp.ob_vendor_id === company.ob_vendor_id &&
												<div className="overlay" style={{width: '200px', display: 'block'}}>
													<h4 className="mb-10 white"> {`Approver Rating - ${(company.total_rating || 0) + '/' +(company.approvals ? (company.approvals.length * 5) : 0)}`}</h4>
													{(company.approvals || []).map((rate) => {
														return (
															<div className='white'>{`L${rate.level}-${rate.approved_by_username}: `} 
															<StarRatings
																rating={rate.rating}
																changeRating={this.changeRating}
																numberOfStars={5}
																name='rating'
																starDimension="18px"
																starSpacing="2px"
																starRatedColor="yellow"
																starHoverColor="yellow"
																isSelectable={false}
																/>
															</div>
														)
													})}
													
												</div>}
										
										</div>
										
									)
								})
							}
							<div className="submit-section-pagination">
								<div className="next">
									<Button value="Next" click={this.next} />
								</div>
								<div className="pre">
									<Button value="Previous" click={this.previous} />
								</div>
							</div>
							
						</div>
					</Fragment> : null}
          {activeButton === 2 ? <Fragment key={"EX"}>
					<div className="sub-heading-section">
					{this.props.match.params.isApproval === '2' && 	 <div className="sub-heading">Vendor List </div> }
							{(!DEEPAK_COMPANY_ID.includes(user.company_id) ? (this.props.match.params.isApproval === '1' && user && user.is_admin) : true) && <div className="add-company-section">
								<Button value="+ Onboard Ex work vendor" click={() => {
									history.push(`/sap-vendor-form/${user.company_id}/OnboardExworkvendor`);
								}} />
							</div>}
							{this.props.match.params.isApproval === '2' && 
							<div className="add-company-section">
								{
									Object.keys(approvedVendorCounts || {}).length > 0 &&
									<div className="list-count-box">
										<h4 className="mb-10"> Approval Status </h4>
										<div>Total: {approvedVendorCounts.total}</div>
										<div>Pending: {approvedVendorCounts.pending_count}</div>
										<div>Approved: {approvedVendorCounts.approved_count}</div>
										<div>Rejected: {approvedVendorCounts.rejected_count}</div>
										<div>Resent : {approvedVendorCounts.resent_count}</div>
									</div>
								}
							</div>}
						</div>
						<div className="search-filter-section">
							<SearchText id="search" value={this.state.search} placeholder="Search Company..." changeHandler={this.changeHandler} click={this.submitSearch} canClear={this.state.search !== ''} handleClearFilter={(e) => this.clearFilterOnCancel(e, "search")} />
						</div>
						{this.props.match.params.isApproval === '2' && user && !user.is_admin && <div className="mb-10">
							{[{name: 'Pending', id: 1}, {name: 'Approved', id: 2}, {name: 'Rejected', id: 3}, {name: 'Resend', id: 4}].map((type, statusIndex) => {
								return (
									<div key={type.id}
										className={type.id===vendorCategory ? `tab-box active` : `tab-box`}
										onClick={type.id!==vendorCategory ? () => { this.setState({ vendorCategory: type.id }, () => { this.callHomeDetails() }) } : null}
									>  {type.name}  </div>
								)
							})}
						</div>}
						<div className="company-list-wrap">
							<div className="list-section header">
								<div className={isApproval ? "account-type table-header" : "approvals-listing table-header"}>
								Company Name
                                </div>
								<div className="account-type vendor-email table-header">
								Email
                                </div>
								<div className="account-type table-header">
								Contact Number
                                </div>
								<div className="status table-header" style={isApproval ? {width: "50px"} : null}>
								Pan No
                                </div>
								{!isApproval && AWL_COMPANY_IDS.includes(user.company_id) &&
									<div className="status table-header" style={{width:'100px'}}>
										Added By
									</div>
								}
								{
									isApproval &&
									<div className="approvals-listing table-header">
										Approval Status
                                    </div>
								}
								<div className="status table-header " style={isApproval ? {width: "50px"} : {marginLeft:'10px'}}>SAP Code</div>

								{/* {(this.props.match.params.isApproval === '1' ? (user && user.is_admin) : true) &&
									<div className="table-header dInline">View</div>
								} */}

								{
									(CARGILL_COMPANY_IDS.includes(user.company_id) || DEEPAK_COMPANY_ID.includes(user.company_id)) && this.props.match.params.isApproval === '1' && user && (user.is_admin || user.is_ho_user) &&
									<div className="status table-header" style={{marginLeft:"20px"}}>SAP Vendor Form</div>
								}
							</div>
							{
								// this.props.home.homeDetails && this.props.home.homeDetails.length == 0 && <div className = "no-records-section">No records found.</div>
							}
							{
								companyList.map((company, index) => {
									console.log('company:', company)
									const compnay_logo = company.company_logo == "" ? logo : company.company_logo;
									return (
										<div key={index +"EX"}
											className="list-section content"
										// onClick = {(e) => this.goToCompanyDetails(e, company.id, company.company_type)}
										>
											<div className={isApproval ? "account-type" : "approvals-listing"}>
												{compnay_logo && compnay_logo.length > 0 && <img src={compnay_logo} className="comany-logo" />}
												<div className="compnay-details">
												<div onMouseOver={() => this.showRating(true, company)} onMouseLeave={() => this.showRating(false, company)}>
														<div className="name">{toPascalCase(company.vendor_company_name)}</div>
														<div className="date">{moment(company.created).format('DD-MMM-YYYY')}</div>
														
														<div className="date">
															<StarRatings
																rating={company.avg_rating}
																starDimension="18px"
																starSpacing="2px"
															/>
														</div>
													</div>
												</div>
												
											</div>
											<div className="account-type vendor-email">
												<div className="admin-email">{company.email}</div>
												{/*<div className = "admin-pan">{company.pan_no}</div>*/}
												{company.vendor_code && <div className="name">{'code: ' + company.vendor_code}</div>}
												{company.carrier_code && <div className="name">{'carrier Code: ' + company.carrier_code}</div>}		
											</div>
											<div className="account-type">
												<div>
                                                 {company.contact_no}
                                               </div>
											</div>
											 <div className="status" style={isApproval ? {width: "10px"} : null}>
											 {company.pan_no}
											 </div> 
											
											<div className="status fs-14" style={isApproval ? {width: "10px"} : {marginLeft:'30px'}}>
                                                 {company.sap_vendor_code}
                                               </div>

											{/* {(this.props.match.params.isApproval === '1' ? (user && user.is_admin) : true) &&
												<div className="dInline">
													<div className="delete-icon"
														style={{ backgroundImage: `url(${View})` }}
														onClick={() => {
															if (isApproval) {
																localStorage.setItem('companyApproval', JSON.stringify(company));
																if (AWL_COMPANY_IDS.includes(user.company_id)) {
																	if (user.level && user.level == 2) {
																		history.push(`/vendor-headoffice-approver/${company.company_id}/${company.ob_vendor_id}/1`)
																	}
																	else if (user.level && user.level == 4) {
																		history.push(`/vendor-bank-approver/${company.company_id}/${company.ob_vendor_id}/1`)
																	}
																	else {
																		history.push(`/vendor-approver/${company.company_id}/${company.ob_vendor_id}/1`)
																	}
																}
																else {
																	history.push(`/vendor-approver/${company.company_id}/${company.ob_vendor_id}/1`)
																}
															} else {
																this.setState({ companyApp: company, addCompanyModal: true });
															}
														}}
													/>
												</div>
											} */}
											

											{(CARGILL_COMPANY_IDS.includes(user.company_id) || DEEPAK_COMPANY_ID.includes(user.company_id)) && this.props.match.params.isApproval === '1' && user && (user.is_admin || user.is_ho_user) &&
												<div className="dInline" style={{marginLeft:'30px'}}>
												<div className="delete-icon"
													style={{ backgroundImage: `url(${View})` }}
													onClick={() => {
														// if (isApproval) {
															localStorage.setItem('companyApproval', JSON.stringify(company));
															{CARGILL_COMPANY_IDS.includes(user.company_id)&&
																history.push(`/sap-vendor-form/${company.company_id}/${company.ob_vendor_id}/1`);
															}
															 {DEEPAK_COMPANY_ID.includes(user.company_id)&&
															 	history.push(`/sap-vendor-form/${company.company_id}/${company.ob_vendor_id}/1/newForm`);
																// history.push(`/nnn`);
															 }
															
														// } else {
														// 	this.setState({ companyApp: company, addCompanyModal: true });
														// }
													}}
												/>
											</div>
											}


											{
												this.state.approvalStatus && Object.keys(companyApp).length > 0 && companyApp.ob_vendor_id===company.ob_vendor_id &&
												<div className="msg-pop-up" onClick={this.props.close}>
													<div className="msg-section">
														<div className="msg">
															Are You Sure To Process This Vendor Data For {this.state.approvalStatus} Validation?
                                                    	</div>
														{(DEEPAK_COMPANY_ID.includes(user.company_id) || AWL_COMPANY_IDS.includes(user.company_id)) &&
														<Fragment>
															<div className="mb-10">Rating:
															<StarRatings
																rating={this.state.rating}
																starRatedColor='rgb(243 236 5 / 85%)'
																changeRating={this.changeRating}
																numberOfStars={5}
																name='rating'
																starDimension='25px'
																starHoverColor='rgb(243 236 5 / 85%)'
															/>
															</div>
														</Fragment>
														}
														<div className="button-section">
															{
																(!this.state.isRejected && !this.state.isResend) &&
																<Fragment>
																	<div style={{display:'flex', marginLeft:'-40px'}}>
																	<Button value="Accept"
																		click={() => { this.handleApproval('APPROVED', companyApp) }}
																	/>
																	<Button value="Reject"
																		click={() => {
																			this.setState({ isRejected: true });
																			// this.handleApproval(false, companyApp) 
																		}}
																	/>
																	<Button value="Resend"
																		click={() => {
																			this.setState({ isResend: true });
																			// this.handleApproval(false, companyApp) 
																		}}
																	/>
																	</div>
																	
																	<div className="mt-20 cancel-btn">
																		<Button value="Cancel" click={() => { this.setState({ approvalStatus: null }); }} />
																	</div>
																</Fragment>
															}
															{
																(this.state.isRejected || this.state.isResend) &&
																<div className="form-textarea">
																	<label>{this.state.isRejected ? 'Rejection Reason:' : 'Resend Reason'}</label>
																	<textarea
																		className="text-input-content"
																		rows="5"
																		cols="30"
																		value={this.state.isRejected ? this.state.reason_for_rejection : this.state.reason_for_resend}
																		placeholder="Content"
																		onChange={(e) => { 
																			if(this.state.isRejected){
																				this.setState({ reason_for_rejection: e.target.value }) 
																			} 
																			else{
																				this.setState({ reason_for_resend: e.target.value }) 
																			}
																		}}
																	/>
																	<div className="button-section">
																		<Button value={this.state.isRejected ? "Reject" : 'Resend'}
																			click={() => {
																				if(this.state.isRejected) {
																					this.handleApproval('REJECTED', companyApp)
																					this.setState({ isRejected: false });
																				} 
																				else{
																					this.handleApproval('RESEND', companyApp)
																					this.setState({ isResend: false });
																				}
																			}}
																		/>
																		<Button value="Cancel"
																			click={() => { this.setState({ isRejected: false, isResend: false }); }}
																		/>
																	</div>
																</div>
															}
														</div>
													</div>
												</div>
											}
											{this.state.isHover && companyApp.ob_vendor_id === company.ob_vendor_id &&
												<div className="overlay" style={{width: '200px', display: 'block'}}>
													<h4 className="mb-10 white"> {`Approver Rating - ${(company.total_rating || 0) + '/' +(company.approvals ? (company.approvals.length * 5) : 0)}`}</h4>
													{(company.approvals || []).map((rate) => {
														return (
															<div className='white'>{`L${rate.level}-${rate.approved_by_username}: `} 
															<StarRatings
																rating={rate.rating}
																changeRating={this.changeRating}
																numberOfStars={5}
																name='rating'
																starDimension="18px"
																starSpacing="2px"
																starRatedColor="yellow"
																starHoverColor="yellow"
																isSelectable={false}
																/>
															</div>
														)
													})}
													
												</div>}
										
										</div>
										
									)
								})
							}
							<div className="submit-section-pagination">
								<div className="next">
									<Button value="Next" click={this.nextEX} />
								</div>
								<div className="pre">
									<Button value="Previous" click={this.previousEX} />
								</div>
							</div>
							
						</div>
					</Fragment> : null}
        </div>
      </div>

					</MainComponent>
				</div>
			</div>
		);
	}
}



const mapStateToProps = state => {
	return {
		loader: state.loader,
		vendors: state.vendors,
		msgpopup: state.msgpopup,
		vendorForm: state.vendorForm,
	};
};



export default connect(mapStateToProps)(withRouter(VendorsList));
