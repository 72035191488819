import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ACTION from "../../common/action_constant";
import "./laneMasters.less";

import MainComponent from "../../home/main/main";
import Button from "../../home/button-classic/button-classic";
import CheckBox from "./../../components/checkbox/checkbox";
import InputText from "../../common/components/input-text/input-text";
import Loader from "../../common/components/loader/loader";
import MSGPopUp from "../../common/components/msg-popup/msg-popup";

import { getCookie } from "../../../utils/cookies";

import Save from '../../../assets/images/save.svg'
import Cancel from '../../../assets/images/cancel.svg'
import Edit from '../../../assets/images/edit.svg'

import Select from "react-select";
import { getSecureRandomKey } from '../../../utils';

class LaneMasters extends Component {
    constructor(props){
        super(props);
        this.state = {
            selectedLanes: [],
            serviceType: 1,
            locationType: 1,
            isAdded: false,
            laneMasterData: (props.dataGovernance && props.dataGovernance.laneMasterData) ? props.dataGovernance.laneMasterData : [],
            newData: {
                company_id: JSON.parse(getCookie("user")).company_id, //"0ba6f7f37e0743eaaf119d81756dfec2",
                lan_type: this.getServiceName(1),
                looking_for: 'city',
                mode_id: 2,
                from: {
                        city_name:"",
                        city_id:"",
                        city_pincode:"",
                        state_name:"",
                        region: ""
                },
                to: {
                        city_name:"",
                        city_id:"",
                        city_pincode:"",
                        state_name:"",
                        region: ""
                }
            }
        }
    }

    componentDidMount() {
        this.getLaneMasterData();
    }

    componentWillReceiveProps(nextProps) {
        const { dataGovernance } = this.props;
        
        if (dataGovernance !== nextProps.dataGovernance) {
            if (dataGovernance.laneMasterData !== nextProps.dataGovernance.laneMasterData) {
                this.setState({
                    laneMasterData: nextProps.dataGovernance.laneMasterData
                });
            }
        }
    }

    getServiceName = (id) => {
        let name = '';
        switch(id) {
            case 1:
                name = 'LCL';
                break;
            case 2:
                name = 'FTL';
                break;
            case 3:
                name = 'Courier';
                break;
            default:
                break;
        }
        return name;
    }

    getCities = (searchValue) => {
        const { dispatch } = this.props;
        const lanType = (this.state.locationType===1)?'city':'pincode';
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_CITY_LIST,
            params: `cityName=${searchValue}&lookingFor=${lanType}`,
        });
    }

    getPincodes = (value) => {
        const { dispatch } = this.props;
        const lanType = (this.state.locationType===1)?'city':'pincode';
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_CITY_PINCODES,
            params: `cityName=${value}&lookingFor=${lanType}`,
        });
    }

    getLaneMasterData = () => {
        const { dispatch } = this.props;
        const { serviceType, locationType } = this.state;
        const companyId = JSON.parse(getCookie("user")).company_id; // "0ba6f7f37e0743eaaf119d81756dfec2"; // JSON.parse(getCookie("user")).company_id;
        const lanType = (locationType===1)?'city':'pincode';

        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_LANE_MASTERS,
            params: `companyId=${companyId}&lanType=${this.getServiceName(serviceType)}&lookingFor=${lanType}`,
            onSuccess: (() => { this.setState({ editId: null, isAdded: false  }) })
        })
    }

    handleLaneSelection = (e, id) => {
        const { selectedLanes } = this.state;
        if (selectedLanes.includes(id)) {
            selectedLanes.splice(id, 1);
        } else {
            selectedLanes.push(id);
        }
        
        this.setState({ selectedLanes });
    }

    saveLane = (lane) => {
        let { isAdded } = this.state;
        const { dispatch } = this.props;
        
        if (isAdded) {
            dispatch({
                type: ACTION.DATA_GOVERNANCE.POST_LANE,
                params: this.state.newData,
                onSuccess: (() => {
                    this.setState({ isAdded: false })
                    this.getLaneMasterData();
                })
            })
        } else {
            if (Object.keys(lane).length > 0) {
                const { laneMasterData } = this.state;
                const data = {
                    ...lane,
                    company_id: JSON.parse(getCookie("user")).company_id,
                    lan_type: laneMasterData.lan_type,
                    looking_for: laneMasterData.looking_for,
                    mode_id: "2",
                }
                dispatch({
                    type: ACTION.DATA_GOVERNANCE.UPDATE_LANE,
                    params: data,
                    onSuccess: (() => {
                        this.setState({ editId: null })
                        this.getLaneMasterData();
                    })
                })
            }
        }
    }

    updateDelayPenalty = (penalty) => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.UPDATE_DELAY_PENALTY,
            params: penalty,
            onSuccess: (() => {
                this.setState({ isAdded: false, editId: null });
                this.getLaneMasterData();
            })
        });
    }

    deleteLanes = () => {
        const { laneMasterData, selectedLanes } = this.state;
        const { dispatch } = this.props;
        const companyId = JSON.parse(getCookie("user")).company_id;
        const data = {
            company_id: companyId,
            route_ids: selectedLanes,
            lan_type: this.getServiceName(this.state.serviceType),
            looking_for: (this.state.locationType===1)?"city":"pincode",
        };
        dispatch({
            type: ACTION.DATA_GOVERNANCE.DELETE_LANE,
            params: data,
            onSuccess: (() => {
                this.getLaneMasterData();
            })
        });
    }

    downloadLane = () => {
        const { laneMasterData } = this.state;
        const { dispatch } = this.props;
        const companyId = JSON.parse(getCookie("user")).company_id;
        const params = `companyId=${companyId}&lookingFor=${this.state.locationType===1?"city":"pincode"}&lanType=${this.getServiceName(this.state.serviceType)}`
        dispatch({
            type: ACTION.DATA_GOVERNANCE.DOWNLOAD_LANE,
            params,
            onSuccess: ((data) => {
                if (data.length > 0) {
                    this.setState({ downloadLink: data[0].Link });
                    this.downloadBtn.click();
                }
            })
        })
    }

    uploadLane = (e) => {
        let formData = new FormData();
        formData.append('file', e.target.files[0]);
        const { dispatch } = this.props;
        const company_id = JSON.parse(getCookie("user")).company_id;
        const data = {
            company_id,
            looking_for: (this.state.locationType===1?"city":"pincode"),
            lan_type: this.getServiceName(this.state.serviceType),
        }
        formData.append('jsonData', JSON.stringify(data));

        dispatch({ 
            type: ACTION.DATA_GOVERNANCE.UPLOAD_LANE, 
            data: formData, 
            // params, 
            onSuccess: (() => {
                this.getLaneMasterData();
            })
        });
    }

    closeMsgPopup = () =>{
        const {dispatch} = this.props;
        dispatch({ type : ACTION.MSG_DISPLAY.HIDE_MSG });
    }

    clickCustomCheckBox = (id, name) =>{
        this.setState({
            [name]: id,
        }, () => {
            if (name==='locationType') {
                const { newData } = this.state;
                newData.looking_for = (id===1) ? 'city' : 'pincode';
                this.setState({ newData })
            }
            if (name==='serviceType') {
                const { newData } = this.state;
                newData.lan_type = this.getServiceName(this.state.serviceType);
                this.setState({ newData })
            }
            this.getLaneMasterData();
        })
    }

    editLane = (id) => {
        this.setState({
            editId: this.state.editId===id ? null : id,
            isAdded: false
        });
    }

    cancelAction = () => {
        this.setState({
          ...this.state,
          isAdded: false,
        })
      }

    renderNewLaneBody = () => {
        const { dataGovernance } = this.props;
        const { newData, locationType } = this.state;
        const fromCity = newData.from;
        const toCity = newData.to;
        let cities = [];

        if (dataGovernance.citiesList && dataGovernance.citiesList.length > 0) {
            (dataGovernance.citiesList || []).forEach((items) => {
                cities = cities.concat(items.cities || items.states || []);
            });
        }
        
        return (
            <div className="list-section content divTableRow" key={getSecureRandomKey()}>
                <div className="divTableCell select-row"> </div>
                {
                    locationType===2 
                    ?
                      <div className="divTableCell"> Auto-fetch </div>
                    :
                      <div className="divTableCell"> 
                        <Select
                            className="select"
                            options={cities || []}
                            value={(cities || []).filter(obj => { return (obj.label === newData.from.city_name || obj.label === newData.from.state_name)})}
                            isSearchable={true}
                            placeholder={newData.from.city_name || newData.from.state_name || fromCity.city_name || fromCity.state_name || 'Search'}
                            onInputChange={(value, items) => {
                                this.setState({ fromSearch: value })
                                if (value.length > 1) {
                                    this.getCities(value)
                                }
                            }}
                            isDisabled={this.props.dataGovernance.loading}
                            isLoading={this.props.dataGovernance.loading}
                            autoFocus={this.state.fromSearch}
                            menuIsOpen={this.state.fromSearch}
                            inputValue={this.state.fromSearch}
                            onChange={(value) => { 
                                const { newData } = this.state;
                                if (value.city_name) {
                                    newData.from.city_name = value.label;
                                    newData.from.city_id = value.city_id;
                                    newData.from.state_name = value.state_name;
                                    newData.from.state_id = value.state_id;
                                    newData.from.region = value.region;
                                } else {
                                    newData.from.state_name = value.state_name;
                                    newData.from.state_id = value.state_id;
                                    newData.from.region = value.region;
                                }
                                this.setState({ newData, fromSearch: '' });
                            }}
                        />
                      </div>
                }
                <div className="divTableCell"> {fromCity.state_name || 'Auto-fetch'} </div>
                <div className="divTableCell"> {fromCity.region || 'Auto-fetch'} </div>
                {
                    locationType === 2 &&
                    <div className="divTableCell"> 
                        <Select
                            className="select"
                            options={cities || []}
                            isSearchable={true}
                            placeholder={newData.from.city_pincode || toCity.city_name || toCity.state_name || 'Search'}
                            onInputChange={(value, items) => {
                                this.setState({ fromPinSearch: value })
                                if (value.length > 1) {
                                    this.getPincodes(value);
                                }
                            }}
                            isDisabled={this.props.dataGovernance.loading}
                            isLoading={this.props.dataGovernance.loading}
                            autoFocus={this.state.fromPinSearch}
                            menuIsOpen={this.state.fromPinSearch}
                            inputValue={this.state.fromPinSearch}
                            value={(cities || []).filter(obj => { return obj.pincode === newData.from.city_pincode})}
                            onChange={(value) => { 
                                const { newData } = this.state;
                                newData.from.city_pincode = value.pincode;
                                newData.from.city_name = value.city_name;
                                newData.from.city_id = value.city_id;
                                newData.from.state_name = value.state_name;
                                newData.from.state_id = value.state_id;
                                newData.from.region = value.region;
                                this.setState({ newData, fromPinSearch: '' });
                            }}
                        />
                    </div>
                }
                <div className="divTableCell"> 
                {
                    locationType===2 
                    ?
                      <div className="divTableCell"> Auto-fetch </div>
                    :
                      <Select
                        className="select"
                        options={cities || []}
                        isSearchable={true}
                        onInputChange={(value) => {
                            this.setState({ toSearch: value })
                            if (value.length > 1) {
                                this.getCities(value)
                            }
                        }}
                        isDisabled={this.props.dataGovernance.loading}
                        isLoading={this.props.dataGovernance.loading}
                        autoFocus={this.state.toSearch}
                        menuIsOpen={this.state.toSearch}
                        inputValue={this.state.toSearch}
                        placeholder={newData.to.city_name || newData.to.state_name || 'Search'}
                        value={(cities || []).filter(obj => { return (obj.label === newData.to.city_name || obj.label === newData.to.state_name)})}
                        onChange={(value) => { 
                            const { newData } = this.state;
                            if (value.city_name) {
                                newData.to.city_name = value.label;
                                newData.to.city_id = value.city_id;
                                newData.to.state_name = value.state_name;
                                newData.to.state_id = value.state_id;
                                newData.to.region = value.region;
                            } else {
                                newData.to.state_id = value.state_id;
                                newData.to.state_name = value.state_name;
                                newData.to.region = value.region;
                            }
                            this.setState({ newData });
                        }}
                      />
                }
                </div>
                <div className="divTableCell"> {toCity.state_name || 'Auto-fetch'} </div>
                <div className="divTableCell"> {toCity.region || 'Auto-fetch'} </div>
                {
                    locationType===2 &&
                    <div className="divTableCell"> 
                        <Select
                            className="select"
                            options={cities || []}
                            isSearchable={true}
                            placeholder={newData.to.city_pincode || 'Search'}
                            onInputChange={(value, items) => {
                                this.setState({ toPinSearch: value });
                                if (value.length > 1) {
                                    this.getPincodes(value);
                                }
                            }}
                            isDisabled={this.props.dataGovernance.loading}
                            isLoading={this.props.dataGovernance.loading}
                            autoFocus={this.state.toPinSearch}
                            menuIsOpen={this.state.toPinSearch}
                            inputValue={this.state.toPinSearch}
                            value={(cities || []).filter(obj => { return obj.pincode === newData.to.city_pincode})}
                            onChange={(value) => { 
                                const { newData } = this.state;
                                newData.to.city_pincode = value.pincode;
                                newData.to.city_name = value.city_name;
                                newData.to.city_id = value.city_id;
                                newData.to.state_id = value.state_id;
                                newData.to.state_name = value.state_name;
                                newData.to.region = value.region;
                                this.setState({ newData, toPinSearch: '' });
                            }}
                        />
                    </div>
                }
                
                <div className="divTableCell"> 
                    <div className={'action-icon'} style={{backgroundImage: `url(${Save})`}} onClick={this.saveLane} />
                    <div className={'action-icon'} style={{backgroundImage: `url(${Cancel})`}} onClick ={this.cancelAction} />
                </div>
            </div>
        );
    }

    renderTableBody = (laneData) => {
        const { editId, isAdded, selectedLanes, locationType } = this.state;
        const { dataGovernance } = this.props;
        let cities = [];

        if (dataGovernance.citiesList && dataGovernance.citiesList.length > 0) {
            (dataGovernance.citiesList || []).forEach((items) => {
                cities = cities.concat(items.cities || items.states || []);
            });
        }
        
        return (
          <Fragment>
            {Object.keys(laneData || {}).length > 0 && laneData.company_routes && 
                (laneData.company_routes || []).map((lane, index) => {
                    const fromCity = lane.from || {};
                    const toCity = lane.to || {};
                return (
                    <div className="list-section content divTableRow" key={lane.route_id || getSecureRandomKey()}>
                        <div className="divTableCell select-row"> 
                        {!isAdded && (index !== editId) && 
                            <input 
                                type="checkbox" 
                                defaultChecked={selectedLanes.includes(lane.route_id)}
                                onClick={(e) => this.handleLaneSelection(e, lane.route_id)}
                            />
                        }
                        </div>
                        <div className="divTableCell"> 
                            {(editId===index) && locationType===1
                                ?
                                    <Select
                                        className="select"
                                        options={cities || []}
                                        isSearchable={true}
                                        onInputChange={(value) => {
                                            this.setState({ fromSearch: value })
                                            if (value.length > 1) {
                                                this.getCities(value)
                                            }
                                        }}
                                        isDisabled={this.props.dataGovernance.loading}
                                        isLoading={this.props.dataGovernance.loading}
                                        autoFocus={this.state.fromSearch}
                                        menuIsOpen={this.state.fromSearch}
                                        inputValue={this.state.fromSearch}
                                        placeholder={fromCity.city_name || 'Search'}
                                        value={(cities || []).filter(obj => { return (obj.label === fromCity.city_name || obj.label === fromCity.state_name)})}
                                        onChange={(value) => { 
                                            const { laneMasterData } = this.state;
                                            if (value.city_name) {
                                                laneMasterData.company_routes[index].from.city_name = value.label;
                                                laneMasterData.company_routes[index].from.city_id = value.city_id;
                                                laneMasterData.company_routes[index].from.state_name = value.state_name;
                                                laneMasterData.company_routes[index].from.state_id = value.state_id;
                                                laneMasterData.company_routes[index].from.region = value.region;
                                            } else {
                                                laneMasterData.company_routes[index].from.state_id = value.state_id;
                                                laneMasterData.company_routes[index].from.state_name = value.state_name;
                                                laneMasterData.company_routes[index].from.region = value.region;
                                            }
                                            this.setState({ laneMasterData });
                                        }}
                                      />
                                :
                                    <Fragment>{fromCity.city_name || ''}</Fragment>
                            }
                        </div>
                        <div className="divTableCell"> {fromCity.state_name || ''} </div>
                        <div className="divTableCell"> {fromCity.region || ''} </div>
                        {locationType === 2 && 
                            <div className="divTableCell"> 
                            {(editId===index) ?
                                <Select
                                    className="select"
                                    options={cities || []}
                                    isSearchable={true}
                                    placeholder={fromCity.city_pincode || 'Search'}
                                    onInputChange={(value, items) => {
                                        this.setState({ fromPinSearch: value });
                                        if (value.length > 1) {
                                            this.getPincodes(value);
                                        }
                                    }}
                                    isDisabled={this.props.dataGovernance.loading}
                                    isLoading={this.props.dataGovernance.loading}
                                    autoFocus={this.state.fromPinSearch}
                                    menuIsOpen={this.state.fromPinSearch}
                                    inputValue={this.state.fromPinSearch}
                                    value={(cities || []).filter(obj => { return obj.pincode === fromCity.city_pincode})}
                                    onChange={(value) => { 
                                        const { laneMasterData } = this.state;
                                        laneMasterData.company_routes[index].from.city_pincode = value.pincode;
                                        laneMasterData.company_routes[index].from.city_name = value.city_name;
                                        laneMasterData.company_routes[index].from.city_id = value.city_id;
                                        laneMasterData.company_routes[index].from.state_id = value.state_id;
                                        laneMasterData.company_routes[index].from.state_name = value.state_name;
                                        laneMasterData.company_routes[index].from.region = value.region;
                                        this.setState({ laneMasterData, fromPinSearch: '' });
                                    }}
                                />
                              :
                                <Fragment>{fromCity.city_pincode || ''}</Fragment>
                            }
                            </div>
                        }
                        <div className="divTableCell"> 
                            {(editId===index) && locationType===1
                                ?
                                    <Select
                                        className="select"
                                        options={cities || []}
                                        isSearchable={true}
                                        onInputChange={(value) => {
                                            this.setState({ toSearch: value })
                                            if (value.length > 1) {
                                                this.getCities(value)
                                            }
                                        }}
                                        isDisabled={this.props.dataGovernance.loading}
                                        isLoading={this.props.dataGovernance.loading}
                                        autoFocus={this.state.toSearch}
                                        menuIsOpen={this.state.toSearch}
                                        inputValue={this.state.toSearch}
                                        placeholder={toCity.city_name || toCity.state_name || 'Search'}
                                        value={(cities || []).filter(obj => { return (obj.label === toCity.city_name || obj.label === toCity.state_name)})}
                                        onChange={(value) => { 
                                            const { laneMasterData } = this.state;
                                            if (value.city_name) {
                                                laneMasterData.company_routes[index].to.city_name = value.label;
                                                laneMasterData.company_routes[index].to.city_id = value.city_id;
                                                laneMasterData.company_routes[index].to.state_name = value.state_name;
                                                laneMasterData.company_routes[index].to.state_id = value.state_id;
                                                laneMasterData.company_routes[index].to.region = value.region;
                                            } else {
                                                laneMasterData.company_routes[index].to.state_id = value.state_id;
                                                laneMasterData.company_routes[index].to.state_name = value.state_name;
                                                laneMasterData.company_routes[index].to.region = value.region;
                                            }
                                            this.setState({ laneMasterData });
                                        }}
                                    />
                                :
                                    <Fragment>{toCity.city_name || ''}</Fragment>
                            }
                        </div>
                        <div className="divTableCell"> {toCity.state_name || ''} </div>
                        <div className="divTableCell"> {toCity.region || ''} </div>
                        {locationType === 2 && 
                            <div className="divTableCell"> 
                                {(editId===index) ?
                                    <Select
                                        className="select"
                                        options={cities || []}
                                        isSearchable={true}
                                        placeholder={toCity.city_pincode || 'Search'}
                                        onInputChange={(value, items) => {
                                            this.setState({ toPinSearch: value });
                                            if (value.length > 1) {
                                                this.getPincodes(value);
                                            }
                                        }}
                                        isDisabled={this.props.dataGovernance.loading}
                                        isLoading={this.props.dataGovernance.loading}
                                        autoFocus={this.state.toPinSearch}
                                        menuIsOpen={this.state.toPinSearch}
                                        inputValue={this.state.toPinSearch}
                                        value={(cities || []).filter(obj => { return obj.pincode === toCity.city_pincode})}
                                        onChange={(value) => { 
                                            const { laneMasterData } = this.state;
                                            laneMasterData.company_routes[index].to.city_pincode = value.pincode;
                                            laneMasterData.company_routes[index].to.city_name = value.city_name;
                                            laneMasterData.company_routes[index].to.city_id = value.city_id;
                                            laneMasterData.company_routes[index].to.state_id = value.state_id;
                                            laneMasterData.company_routes[index].to.state_name = value.state_name;
                                            laneMasterData.company_routes[index].to.region = value.region;
                                            this.setState({ laneMasterData, toPinSearch: '' });
                                        }}
                                    />
                                  :
                                    <Fragment>{toCity.city_pincode || ''}</Fragment>
                                }
                            </div>
                        }
                        <div className="divTableCell"> 
                        {(editId===index) 
                            ?
                                <Fragment>
                                    <div className={'action-icon'} style={{backgroundImage: `url(${Save})`}} onClick={() => this.saveLane(lane)} />
                                <div className={'action-icon'} style={{backgroundImage: `url(${Cancel})`}} onClick={() => this.editLane(index)} />
                                </Fragment>
                            :
                            <div className={'action-icon'} style={{backgroundImage: `url(${Edit})`}} onClick={() => this.editLane(index)} />
                        }
                        </div>
                    </div>
                )
            })}
          </Fragment>
        )
    }
    
    render() {
        const { laneMasterData, isAdded, serviceType, locationType } = this.state;
        const { dataGovernance } = this.props;
        return (
            <div className="discount-wrap">
                <div className="wrapper">
                    <MainComponent>
                        {this.props.loader.isLoading && <Loader />}
                        {this.props.msgpopup.active && <MSGPopUp close = {this.closeMsgPopup} type = {this.props.msgpopup.type} msg = {this.props.msgpopup.msg}/>}
                        <div className="sub-heading-section"> 
                            <div className="sub-heading"> Lane Master </div>
                        </div>

                        <div>
                            <div className="serviceTypes">
                                <div className = "section-item">
                                    <CustomeCheckBox id={1} toggle={(id) => this.clickCustomCheckBox(id, 'serviceType')} selected={(serviceType===1)}/>
                                    <div className="title"> LCL</div>
                                </div>
                                <div className = "section-item">
                                    <CustomeCheckBox id={2} toggle={(id) => this.clickCustomCheckBox(id, 'serviceType')} selected={(serviceType===2)}/>
                                    <div className="title"> FTL</div>
                                </div>
                                <div className = "section-item">
                                    <CustomeCheckBox  id={3} toggle={(id) => this.clickCustomCheckBox(id, 'serviceType')} selected={(serviceType===3)}/>
                                    <div className="title"> Courier</div>
                                </div>
                            </div>
                            <div className="cta-action-btns">
                                <button className="button-classic" onClick={this.downloadLane}> Download Template</button>
                                <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
                                
                                <button className="button-classic" onClick={() => {this.uploadExcel.click()}}> Upload Excel </button>
                                <input type='file' style={{ display: 'none'}}  ref={(e) => { this.uploadExcel = e ;}} onChange={this.uploadLane} onClick={(e) => {e.target.value = null }}/>
                            </div>
                            <div className="cta-action-btns cta-btn-right">
                                <button className="button-classic" onClick={() => { this.setState({ isAdded: true }) }}>Add Record</button>
                                <button className="button-classic" onClick={this.deleteLanes}>Delete Record(s)</button>
                                {/*<Button value="Advanced Filters" />*/}
                            </div>

                            <div className="serviceTypes">
                                <div className = "section-item">
                                    <CustomeCheckBox id={1} toggle={(id) => this.clickCustomCheckBox(id, 'locationType')} selected={(locationType===1)}/>
                                    <div className="title"> City</div>
                                </div>
                                <div className = "section-item">
                                    <CustomeCheckBox id={2} toggle={(id) => this.clickCustomCheckBox(id, 'locationType')} selected={(locationType===2)}/>
                                    <div className="title"> Pincode</div>
                                </div>
                            </div>
                            <div className="divTable company-list-wrap">
                                <div className="list-section table-header divTableHeading divTableRow">
                                    <div className="divTableCell select-row"> </div>
                                    <div className="divTableCell"> From City </div>
                                    <div className="divTableCell"> From State </div>
                                    <div className="divTableCell"> From Region </div>
                                    {locationType===2 && <div className="divTableCell"> City Pincode </div>}
                                    <div className="divTableCell"> To City </div>
                                    <div className="divTableCell"> To State </div>
                                    <div className="divTableCell"> To Region </div>
                                    {locationType===2 && <div className="divTableCell"> City Pincode </div>}
                                    
                                    <div className="divTableCell"> Action </div>
                                </div>
                                <div className="divTableBody">
                                    {
                                        isAdded && this.renderNewLaneBody()
                                    }
                                    
                                    {this.renderTableBody(laneMasterData)}
                                </div>
                            </div> 

                        </div>
                    </MainComponent>
                </div>
            </div>
        );
    }
}

const CustomeCheckBox = (props) =>{
    return(
        <div className = {props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick = {()=>props.toggle(props.id)}>
            {props.selected && <div className = "inner-circle"></div>}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loader: state.loader,
        msgpopup: state.msgpopup,
        dataGovernance: state.dataGovernance,
    }
}

export default connect(mapStateToProps)(LaneMasters);
