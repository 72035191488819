import React, { Component, Fragment } from 'react';
import Search from '../../common/components/search-text/search-text';
import Select from 'react-select';
import Button from '../../home/button-classic/button-classic';
// import Delete from '../../../assets/images/delete.svg';
import Edit from '../../../assets/images/edit.svg';
import Delete from '../../../assets/images/delete.svg';
import { connect } from "react-redux";
import ACTIONS from '../../common/action_constant';
import AddRouteMaster from './add-route-master';
import { toPascalCase } from "../../../utils/validate";
import Download from '../../../assets/images/direct-download.svg';


class RouteMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            offset: 0
        }
    }

    componentWillMount() {
        this.getRouteMaster();
    }

    getRouteMaster() {
        const { dispatch, company_id } = this.props;
        const { search, offset } = this.state
        const param = {
            offset,
            limit: 20,
            companyId: company_id
        }
        if(search) {
            param.search = search
        }
        dispatch({
            type: ACTIONS.HOME.GET_ROUTE_MASTER,
            param
        })
    }

    changeHandler = (value) => {
        this.setState({ search: value });
    }

    toggleAdd = () => {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen , editData: '' });
    }

    onEditRoute = (route) => {
        this.setState({ editData: route, isOpen: true })
    }

    onSaveMasterRoute = (data) => {
        const { search, offset } = this.state;
        const { dispatch, company_id } = this.props;
        const param = {
            offset,
            limit: 10,
            companyId: company_id
        }
        if(search) {
            param.search = search
        }
        dispatch({
            type: ACTIONS.HOME.ADD_ROUTE_MASTER,
            data,
            param,
            onSuccess: () => {
                this.setState({ isOpen: false });
            } 
        })
    }

    onSearch = () => {
        this.setState({ offset: 0 }, this.getRouteMaster);
    }

    onUpdateRouteMaster = (data) => {
        const { search, offset, routeCodeType } = this.state;
        const { dispatch, company_id } = this.props;
        const param = {
            offset,
            limit: 10,
            companyId: company_id
        }
        if(search) {
            param.search = search
        }
        dispatch({
            type: ACTIONS.HOME.UPDATE_ROUTE_MASTER,
            data,
            param,
            onSuccess: () => {
                this.setState({ isOpen: false, editData: '' });
            } 
        })
    }

    previous = () =>{
		if(this.state.offset > 0){
			this.setState({
				offset : this.state.offset-10
			},this.getRouteMaster);
		}
	}
	next = () =>{
		this.setState({
			offset : this.state.offset+10
		},this.getRouteMaster);
    }

    clearFilterOnCancel = () => {
        this.setState({ search: '', offset: 0 }, this.getRouteMaster);
    }

    downloadRoute=()=>{
        const { dispatch, company_id } = this.props;
        const param = { companyId: company_id};
        dispatch({ 
          type : ACTIONS.HOME.DOWNLOAD_ROUTE_MASTER_TEMPLATE,
          param, 
          onSuccess: ((data) => {
            this.setState({
                downloadLink: data.link
            });
            this.downloadBtn.click();
        })
        });
    }

    downloadRouteDetails=()=>{
        const { dispatch, company_id } = this.props;
        const param = { companyId: company_id };
        dispatch({ 
          type : ACTIONS.HOME.DOWNLOAD_ROUTE_MASTER_DETAILS, 
          param,
          onSuccess: ((data) => {
            this.setState({
                downloadLink: data[0] ? data[0].link : data.link
            });
            this.downloadBtn.click();
        })
        });
    }

    uploadRouteDetails = (e) =>{
            let formData = new FormData();
            const { dispatch , company_id} = this.props;
            formData.append('file', e.target.files[0]);
            formData.append('companyId', company_id);
            dispatch({
               type :ACTIONS.HOME.UPLOAD_ROUTE_MASTER,
               data: formData,
               onSuccess: ((data) => {
                 if(data)
                 this.getRouteMaster()
               })
            });
    }

    onDeleteRouteDetails = (route) => {
        const { dispatch, company_id } = this.props;
        const data = {
            company_id: company_id,
            route_id:route.route_id
        }
        dispatch({
            type: ACTIONS.HOME.DELETE_ROUTE_MASTER,
            data,
            onSuccess: (data) => {
                this.getRouteMaster();
            } 
        })
    }

    render() {
        const { routeMaster } = this.props;
         return(
            <div className={'location-network'}>
                <div>
                    <Search id = "search" value = {this.state.search} placeholder = "Search..." changeHandler = {this.changeHandler} click = {this.onSearch} canClear={this.state.search !== ''} handleClearFilter={this.clearFilterOnCancel} />
                    <Button value={'+ Route'} click={this.toggleAdd}/>
                    <button className="button-classic" onClick={this.downloadRoute}>  Download Template </button>
                    <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
                    <button className="button-classic" onClick={() => {this.uploadExcel.click()}}> Upload </button>
                    <div className={'download-icon'} style={{backgroundImage: `url(${Download})`}} onClick={this.downloadRouteDetails}/>
                    <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
                  <input type='file' style ={{display :'none'}} ref={(e) => { this.uploadExcel = e ;}} onChange={this.uploadRouteDetails} onClick={(e) => {e.target.value = null }}/>
                </div>
                
                <div className={'chain-details-section'}>
                    <div className={'list-section header'}>
                        <div className={'tabel-header'} style={{width: '220px'}}>City</div>
                        <div className={'tabel-header '} style={{width: '220px'}}>State</div>
                        <div className={'tabel-header'}>Country</div>
                        <div className={'tabel-header'} style={{width: '40px'}}>Actions</div>
                    </div>
                    {routeMaster && routeMaster.length > 0 && routeMaster.map((route, index) => {
                            return(<div className={'list-section content'} key={`route_${index}`}>
                                    <div className={'tabel-header'} style={{width: '220px'}}>{route.from_city}</div>
                                    <div className={'tabel-header'} style={{width: '220px'}}>{route.from_state}</div>
                                    <div className={'tabel-header'}>{route.from_country}</div>
                                    <div className={'tabel-header'} style={{width: '40px'}}>
                                        <div className={'delete-icon'} style={{backgroundImage: `url(${Edit})`}} onClick={() => this.onEditRoute(route)}></div>
                                    </div>
                                    <div className={'tabel-header'} style={{width: '80px'}}>
                                    <div className={'delete-icon'} style={{backgroundImage: `url(${Delete})`}} onClick={() => this.onDeleteRouteDetails(route)}/>
                                    </div>
                            </div>)})
                        }
                </div>
                <div className = "submit-section-pagination">
                    <div className ="next">
                        <Button value = "Next" click = {this.next}/>    
                    </div>
                    <div className ="pre">
                        <Button value = "Previous" click = {this.previous}/>
                    </div>
                </div>
                {this.state.isOpen && <AddRouteMaster toggleAdd={this.toggleAdd} onSaveMasterRoute={this.onSaveMasterRoute} company_id={this.props.company_id} editData={this.state.editData} onUpdateRouteMaster={this.onUpdateRouteMaster} districtList={this.props.districtList} TodistrictList={this.props.TodistrictList}/>}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        routeMaster : state.home.routeMaster || [],
        districtList: state.home.districtList || []
    };
  };

export default connect(mapStateToProps)(RouteMaster);