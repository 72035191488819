import React, { Component } from 'react';
import InputText from '../../common/components/input-text/input-text';
import Select from 'react-select';
import Button from '../button-classic/button-classic';
import './../route_code/route_code.less';
import { AWL_COMPANY_IDS, getEmail, getUserCompanyId, getUserId } from '../../../utils/common';

const uom = [
    { label: 'KG', value: 'KG' },
    { label: 'MT', value: 'MT' },
    { label: 'BAG', value: 'BAG' },
    { label: 'EA', value: 'EA' },
    { label: 'LT', value: 'LT' },
	{ label: 'KAR', value: 'KAR' }
]

class AddMaterialConversion extends Component {
		constructor(props) {
			super(props);
			this.state = {
				data: {
					"Material": this.props.editData ? this.props.editData.Material : '',
					"Alternative Unit of Measure": this.props.editData ? this.props.editData["Alternative Unit of Measure"] : '',
					"Counter": this.props.editData ? this.props.editData.Counter : '',
					"Denominator": this.props.editData ? this.props.editData.Denominator : '',
					"Length": this.props.editData ? this.props.editData.Length : '',
					"Height": this.props.editData ? this.props.editData.Height : '',
					"Unit of Dimension": this.props.editData ? this.props.editData["Unit of Dimension"] : '',
					"Gross Weight": this.props.editData ? this.props.editData["Gross Weight"] : '',
					"Weight unit": this.props.editData ? this.props.editData["Weight unit"] : '',
					"company_id": this.props.company_id,
					"id": this.props.editData ? this.props.editData.id : undefined,
					"previous_data": this.props.editData ? this.props.previous_data : null,
				}
			}
		}

		onChangeHandler = (value, id) => {
			const { data } = this.state;
			data[id] = id === 'Material' ? (value || '').toUpperCase() :value;
			this.setState({ data });
		}

		onSave = () => {
			const { data } = this.state;
			this.props.onSave(data);
		}

		onUpdate = () => {
			const { data } = this.state;
			const { editData } = this.props;
			data.update_flag = true;
			if(!!data.previous_data){
				data.previous_data.user_id = getUserId();
				data.previous_data.user_email = getEmail();
				data.previous_data.update_flag = true;
				data.previous_data.master_type = 19;
				data.previous_data.company_id = getUserCompanyId()
			}
			this.props.onUpdate(data);
		}

		render() {
			const { data } = this.state;
			const { companyId } = this.props;
            const disable = !(data['Material'] && data['Alternative Unit of Measure'] && data['Counter'] && data['Denominator']);
			return (
				<div className="route_code_detail">
					<div onClick={this.props.toggleAdd} className="overlay"></div>
					<div id="modal" className="modal-popup">
						<div className="pop-heading">
							{`${this.props.editData ? 'Edit' : 'Add'} Material Conversion`}
						</div>
						<div className="route-detail">
							<div className="details-section">
								<InputText
									placeholder="Material"
									id={"Material"}
									label="Material"
									changeHandler={this.onChangeHandler}
									value={data['Material']}
                                    required={true}
								/>
                                <div className="select-wrap-depot">
						            <div className="label">{'Alternative Unit of Measure'}<sup className="error error-font">*</sup></div>
                                    <Select
                                        options={uom}
                                        className="select"
                                        value={data['Alternative Unit of Measure'] && uom.find(el => el.value === data['Alternative Unit of Measure'])}
                                        onChange={(opt) => {  
                                            const { data } = this.state;
                                            data['Alternative Unit of Measure'] = opt.value;
                                            this.setState({ data });
                                        }}
                                    />
                                </div>
								<InputText
                                    type="number"
									placeholder={`Counter ${companyId && AWL_COMPANY_IDS.includes(companyId) ? '(Y)' : '(X)'}`}
									id={"Counter"}
									label={`Counter ${companyId && AWL_COMPANY_IDS.includes(companyId) ? '(Y)' : '(X)'}`}
									changeHandler={this.onChangeHandler}
									value={data["Counter"]}
                                    required={true}
								/>
							</div>
                            <div className="details-section">
                                <InputText
                                    type="number"
									placeholder={`Denominator ${companyId && AWL_COMPANY_IDS.includes(companyId) ? '(X)' : '(Y)'}`}
									id={"Denominator"}
									label={`Denominator ${companyId && AWL_COMPANY_IDS.includes(companyId) ? '(X)' : '(Y)'}`}
									changeHandler={this.onChangeHandler}
									value={data['Denominator']}
                                    required={true}
								/>
								<InputText
                                    type="number"
									placeholder="Length"
									id={"Length"}
									label="Length"
									changeHandler={this.onChangeHandler}
									value={data['Length']}
								/>
								<InputText
                                    type="number"
									placeholder="Height"
									id={"Height"}
									label="Height"
									changeHandler={this.onChangeHandler}
									value={data["Height"]}
								/>
							</div>
                            <div className="details-section">
								<InputText
									placeholder="Unit of Dimension"
									id={"Unit of Dimension"}
									label="Unit of Dimension"
									changeHandler={this.onChangeHandler}
									value={data['Unit of Dimension']}
								/>
                                <InputText
                                    type="number"
									placeholder="Gross Weight"
									id={"Gross Weight"}
									label="Gross Weight"
									changeHandler={this.onChangeHandler}
									value={data['Gross Weight']}
								/>
								<InputText
									placeholder="Weight unit"
									id={"Weight unit"}
									label="Weight unit"
									changeHandler={this.onChangeHandler}
									value={data["Weight unit"]}
								/>
							</div>
							{/* {this.state.isError && <div className={'error-message'}>
								{'Please Enter all mandatory fields'}
							</div>} */}
							<div className={'button_route'}>
								<Button value={'Cancel'} click={this.props.toggleAdd} />
								{!this.props.editData ?
									<Button value={'Save'} click={this.onSave} disabled={disable}/>
									:
									<Button value={'Update'} click={this.onUpdate} disabled={disable}/>
								}

							</div>
						</div>

					</div>
				</div>
			)
		}
	}

export default AddMaterialConversion;
