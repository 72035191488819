import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ACTION from "../../common/action_constant";
import "./discountStructureDPHRates.less";

import MainComponent from "../../home/main/main";
import Button from "../../home/button-classic/button-classic";
import CheckBox from "./../../components/checkbox/checkbox";
import InputText from "../../common/components/input-text/input-text";
import Loader from "../../common/components/loader/loader";
import MSGPopUp from "../../common/components/msg-popup/msg-popup";

import { getCookie } from "../../../utils/cookies";

import Save from '../../../assets/images/save.svg'
import Cancel from '../../../assets/images/cancel.svg'
import Edit from '../../../assets/images/edit.svg'

import Select from "react-select";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const RATE_TYPES = [
    {label: 'PAISA', 'value': 'PAISA', id: 1}, 
    {label: 'PERCENTAGE','value': 'PERCENTAGE', id: 1}
];

const DISCOUNT_TYPES = [
    {label: 'POSITIVE', 'value': 'POSITIVE'}, 
    {label: 'NEGATIVE','value': 'NEGATIVE'}
];

class MyDatePicker extends Component {
  render() {
    return (
      <div className="data-picker">
        <div className="label">{this.props.label}</div>
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={this.props.startDate}
          onChange={this.props.handleChange.bind(this, this.props.id)}
        />
      </div>
    );
  }
}

class DiscountStructureDPHRates extends Component {
    constructor(props){
        super(props);
        this.state = {
            selectedLanes: [],
            limit: 20,
            offset: 0,
            isAdded: false,
            vendorId: '',
            masterData: (props.dataGovernance && props.dataGovernance.dphDiscountRates) ? props.dataGovernance.dphDiscountRates : [],
            newData: {
                company_id: JSON.parse(getCookie("user")).company_id,
                created_by: JSON.parse(getCookie("user")).user_email,
                vendor_id: "", 
                vendor_name: "",
                discount_value: 0,
                discount_logic: "", 
                discount_value_type: "PERCENTAGE", 
                discount_from: new Date().getTime(), 
                discount_to: new Date().getTime(),
                mode_id: '',
                mode_type: '',
            }
        }
    }

    componentDidMount() {
        this.getHubMasterData();
        this.getVendorsData();
        this.getModes();
    }

    componentWillReceiveProps(nextProps) {
        const { dataGovernance } = this.props;
        
        if (dataGovernance !== nextProps.dataGovernance) {
            if (dataGovernance.dphDiscountRates !== nextProps.dataGovernance.dphDiscountRates) {
                this.setState({
                    masterData: nextProps.dataGovernance.dphDiscountRates
                });
            }
        }
    }

    getVendorsData = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_VENDORS_TYPES,
        })
    }

    getHubMasterData = (vendorId) => {
        const { dispatch } = this.props;
        const { limit, offset } = this.state;
        const companyId = JSON.parse(getCookie("user")).company_id;
        let params = `companyId=${companyId}`; //&limit=${limit}&offset=${offset}`;
        if (this.state.vendorId) {
            params += `&vendorId=${this.state.vendorId}`
        }

        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_DPH_DISCOUNT_STRUCTURE,
            params, //`companyId=${companyId}`, //&vendorId=c0d99acbcc2948069373b69ba4cfabba`,
            onSuccess: (() => { 
                this.setState({ editId: null, isAdded: false  }) 
            })
        })
    }

    handleLaneSelection = (e, id) => {
        let { selectedLanes } = this.state;
        if (selectedLanes.includes(id)) {
            selectedLanes.splice(id, 1);
        } else {
            selectedLanes = [id];
        }
        this.setState({ selectedLanes });
    }

    resetData = () => {
        let { newData } = this.state;
        newData = {
            company_id: JSON.parse(getCookie("user")).company_id,
            created_by: JSON.parse(getCookie("user")).user_email,
            vendor_id: "", 
            vendor_name: "",
            discount_value: 0, 
            discount_logic: "",
            discount_value_type: "PERCENTAGE", 
            discount_from: new Date().getTime(), 
            discount_to: new Date().getTime(),
            mode_id: '',
            mode_type: '',
        }
        this.setState({ newData });
    }

    saveLane = (lane) => {
        let { isAdded, newData } = this.state;
        const { dispatch } = this.props;
        const company_id = JSON.parse(getCookie("user")).company_id;
        
        if (isAdded) {
            dispatch({
                type: ACTION.DATA_GOVERNANCE.ADD_DPH_DISCOUNT_STRUCTURE,
                data: {
                    ...newData, 
                    discount_from: moment(newData.discount_from).format('DD/MM/YYYY'), 
                    discount_to: moment(newData.discount_to).format('DD/MM/YYYY')
                },
                params: `companyId=${company_id}&vendorId=${newData.vendor_id}`,
                onSuccess: (() => {
                    this.setState({ isAdded: false });
                    this.getHubMasterData();
                    this.resetData();
                })
            })
        } else {
            if (Object.keys(lane).length > 0) {
                const { laneMasterData } = this.state;
                const data = {
                    ...lane,
                    discount_from: moment(lane.discount_from).format('DD/MM/YYYY'), 
                    discount_to: moment(lane.discount_to).format('DD/MM/YYYY'),
                    company_id: JSON.parse(getCookie("user")).company_id,
                }
                dispatch({
                    type: ACTION.DATA_GOVERNANCE.UPDATE_DPH_DISCOUNT_STRUCTURE,
                    data,
                    params: `companyId=${company_id}&discountId=${data.discount_id}`,
                    onSuccess: (() => {
                        this.setState({ editId: null })
                        this.getHubMasterData();
                        this.resetData();
                    })
                })
            }
        }
    }

    deleteLanes = () => {
        const { selectedLanes } = this.state;
        const { dispatch } = this.props;
        const companyId = JSON.parse(getCookie("user")).company_id;
        let params = `companyId=${companyId}`
        if (selectedLanes.length>0) {
            params += `&discountId=${selectedLanes[0]}`
        }
        dispatch({
            type: ACTION.DATA_GOVERNANCE.DELETE_DPH_DISCOUNT_STRUCTURE,
            params,
            onSuccess: (() => {
                this.getHubMasterData();
            })
        });
    }

    getModes = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_RM_MODES_TYPES,
        })
    }

    closeMsgPopup = () =>{
        const {dispatch} = this.props;
        dispatch({ type : ACTION.MSG_DISPLAY.HIDE_MSG });
    }

    clickCustomCheckBox = (id, name) =>{
        this.setState({
            [name]: id,
        }, () => {
            this.getHubMasterData();
        })
    }

    editLane = (id, data) => {
        this.setState({
            editId: this.state.editId===id ? null : id,
            newData: {...data},
            isAdded: false
        });
        if (this.state.editId===id) {
            this.resetData();
        }
    }

    cancelAction = () => {
        this.setState({
          ...this.state,
          isAdded: false,
        });
        this.resetData();
    }

    onVendorChange = (value) => {
        const { newData } = this.state;
        newData.vendor_name = value.label;
        newData.vendor_id = value.value;
        newData.vendor_code = value.sap_vendor_code;
        this.setState({ newData });
    }

    onChangeInput = (value, type) => {
      let { newData } = this.state;
      newData[type] = value;
      this.setState({ newData });
    }

    previous = () => {
        if (this.state.offset > 0) {
            this.setState({
                offset: this.state.offset - 20
            }, () => {
                this.getHubMasterData()
            });
        }
    }
    next = () => {
        this.setState({
            offset: this.state.offset + 20
        }, () => {
            this.getHubMasterData()
        });
    }

    renderNewLaneBody = () => {
        const { dataGovernance } = this.props;
        const { newData, isAdded } = this.state;
        
        return (
            <div className="list-section content divTableRow">
                <div className="divTableCell select-row"> </div>
                <div className="divTableCell">
                    <Select
                        className="select"
                        value={(dataGovernance.vendorTypes || []).filter((vendor) => { return vendor.value===newData.vendor_id })}
                        options={dataGovernance.vendorTypes || []}
                        onChange={(value) => { this.onVendorChange(value) }}
                    />
                </div>
                <div className="divTableCell">
                    <Select className="select"
                        value={(dataGovernance.modeTypes || []).filter((el) => { return el.value===newData.mode_id })}
                        options={dataGovernance.modeTypes || []}
                        onChange={(value) => { 
                            const { newData } = this.state;
                            newData.mode_id = value.value;
                            newData.mode_type = value.label;
                            this.setState({ newData });
                        }}
                    />
                </div>
                <div className="divTableCell"> 
                    <MyDatePicker 
                        id="startDate" 
                        startDate={newData.discount_from}
                        handleChange={(id, date) => { this.onChangeInput(new Date(date).getTime(), 'discount_from') }}
                    />
                </div>
                <div className="divTableCell"> 
                    <MyDatePicker 
                        id="endDate" 
                        startDate={newData.discount_to}
                        handleChange={(id, date) => { this.onChangeInput(new Date(date).getTime(), 'discount_to') }}
                    />
                </div>
                <div className="divTableCell">
                    <Select
                        className="select"
                        options={DISCOUNT_TYPES}
                        onChange={(value) => this.onChangeInput(value.value, 'discount_logic')}
                        value={DISCOUNT_TYPES.filter(el => el.value===newData.discount_logic)}
                    />
                </div>
                <div className="divTableCell dphDiscInput">
                    {/* <Select
                        className="select"
                        options={DISCOUNT_TYPES}
                        onChange={(value) => this.onChangeInput(value.value, 'discount_logic')}
                        value={DISCOUNT_TYPES.filter(el => el.value===newData.discount_logic)}
                    />  */}
                    
                    <InputText type="number" placeholder="Discount Value" value={newData.discount_value} 
                        changeHandler={(value) =>{ this.onChangeInput(value, 'discount_value') }} 
                    />
                </div>
                <div className="divTableCell"> 
                    <div className={'action-icon'} style={{backgroundImage: `url(${Save})`}} onClick={this.saveLane} />
                    <div className={'action-icon'} style={{backgroundImage: `url(${Cancel})`}} onClick ={this.cancelAction} />
                </div>
            </div>
        );
    }

    renderTableBody = (laneData) => {
        const { editId, isAdded, selectedLanes } = this.state;
        const { dataGovernance } = this.props;
        
        return (
          <Fragment>
            {(laneData || []).length > 0 && 
                (laneData || []).map((lane, index) => {
                    console.log('lane.discount_to', lane, moment(lane.discount_to || '').format('DD/MM/YYYY'));
                return (
                    <div className="list-section content divTableRow" key={lane.discount_id}>
                        <div className="divTableCell select-row"> 
                        {!isAdded && (index !== editId) && 
                            <input 
                                type="checkbox" 
                                checked={selectedLanes.includes(lane.discount_id)}
                                onClick={(e) => this.handleLaneSelection(e, lane.discount_id)}
                            />
                        }
                        </div>
                        <div className="divTableCell"> 
                            {(editId===index)
                                ?
                                    <Select
                                        className="select"
                                        value={(dataGovernance.vendorTypes || []).filter((vendor) => { return vendor.value===lane.vendor_id })}
                                        options={dataGovernance.vendorTypes || []}
                                        onChange={(value) => { 
                                            const { masterData } = this.state;
                                            masterData[index].vendor_name = value.label;
                                            masterData[index].vendor_id = value.value;
                                            masterData[index].vendor_code = value.sap_vendor_code;
                                            this.setState({ masterData });
                                            // this.onVendorChange(value) 
                                        }}
                                    />
                                :
                                    <Fragment>{lane.vendor_name || ''}</Fragment>
                            }
                        </div>
                        <div className="divTableCell">
                            {(editId===index)
                                ?
                                <Select className="select"
                                    value={(dataGovernance.modeTypes || []).filter((el) => { return el.value===lane.mode_id })}
                                    options={dataGovernance.modeTypes || []}
                                    onChange={(value) => { 
                                        const { masterData } = this.state;
                                        masterData[index].mode_id = value.value;
                                        masterData[index].mode_type = value.label;
                                        this.setState({ masterData });
                                    }}

                                />
                              :
                                <Fragment>{lane.mode_type || ''}</Fragment>
                            }
                        </div>
                        <div className="divTableCell"> 
                            {(editId===index) ?
                                <MyDatePicker
                                    id="startDate"
                                    startDate={new Date(lane.discount_from).getTime()}
                                    handleChange={(id, date) => { 
                                        const { masterData } = this.state;
                                        masterData[index].discount_from = new Date(date).getTime();
                                        this.setState({ masterData });
                                    }}
                                />
                              : 
                                <Fragment>{lane.discount_from || ''}</Fragment>
                            }
                        </div>
                        <div className="divTableCell"> 
                        {(editId===index) ?
                            <MyDatePicker
                                id="endDate"
                                startDate={new Date(lane.discount_to).getTime()}
                                handleChange={(id, date) => { 
                                    const { masterData } = this.state;
                                        masterData[index].discount_to = new Date(date).getTime();
                                        this.setState({ masterData });
                                }}
                            />
                          :
                            <Fragment>{lane.discount_to || ''}</Fragment>
                        }
                        </div>
                        <div className="divTableCell"> 
                            {(editId===index)
                                ?
                                <Select
                                    className="select"
                                    options={DISCOUNT_TYPES}
                                    onChange={(value) => this.onChangeInput(value.value, 'discount_logic')}
                                    value={DISCOUNT_TYPES.filter(el => el.value===lane.discount_logic)}
                                />
                                :
                                    // <Fragment>{lane.discount_logic || ''}</Fragment>
                                    ''
                            }
                        </div>
                        <div className="divTableCell dphDiscInput"> 
                            {(editId===index)
                                ?   
                                    <InputText type="number" placeholder="Discount Value" value={lane.discount_value} 
                                        changeHandler={(value) =>{
                                            const { masterData } = this.state;
                                            masterData[index].discount_value = value;
                                            this.setState({ masterData });
                                        }} 
                                    />
                                :
                                    <Fragment>{(lane.discount_logic === "POSITIVE" ? '+' : '-') || ''}{lane.discount_value || ''}</Fragment>
                            }
                        </div>
                        
                        <div className="divTableCell"> 
                        {(editId===index) 
                            ?
                                <Fragment>
                                    <div className={'action-icon'} style={{backgroundImage: `url(${Save})`}} onClick={() => this.saveLane(lane)} />
                                <div className={'action-icon'} style={{backgroundImage: `url(${Cancel})`}} onClick={() => this.editLane(index)} />
                                </Fragment>
                            :
                            <div className={'action-icon'} style={{backgroundImage: `url(${Edit})`}} onClick={() => this.editLane(index, lane)} />
                        }
                        </div>
                    </div>
                )
            })}
          </Fragment>
        )
    }
    
    render() {
        const { masterData, isAdded } = this.state;
        const { dataGovernance, modeTypes } = this.props;

        return (
            <div className="discount-wrap">
                <div className="wrapper">
                    <MainComponent>
                        {this.props.loader.isLoading && <Loader />}
                        {this.props.msgpopup.active && <MSGPopUp close = {this.closeMsgPopup} type = {this.props.msgpopup.type} msg = {this.props.msgpopup.msg}/>}
                        <div className="sub-heading-section"> 
                            <div className="sub-heading"> Discount Structure </div>
                        </div>

                        <div className="cta-action-btns cta-btn-right filterSelect">
                            <Select
                                isClearable={true}
                                className="select"
                                placeholder={'Vendor Select'}
                                options={dataGovernance.vendorTypes || []}
                                value={(dataGovernance.vendorTypes || []).filter(obj => {return obj.value===this.state.vendorId})}
                                onChange={(value) => { this.setState({ offset: 0, vendorId: value===null ? '' : value.value }, () => { this.getHubMasterData(this.state.vendorId) }) }}
                            />
                        </div>

                        <div>
                            <div className="cta-action-btns cta-btn-right mb-20">
                                <button className="button-classic" onClick={() => { this.setState({ isAdded: true }) }}>Add Record</button>
                                <button className="button-classic" onClick={this.deleteLanes}>Delete Record(s)</button>
                                {/*<Button value="Advanced Filters" />*/}
                            </div>

                            <div className="divTable company-list-wrap">
                                <div className="list-section table-header divTableHeading divTableRow">
                                    <div className="divTableCell select-row"> </div>
                                    <div className="divTableCell"> Vendor </div>
                                    <div className="divTableCell"> Mode </div>
                                    <div className="divTableCell"> From </div>
                                    <div className="divTableCell"> To </div>
                                    <div className="divTableCell">  </div>
                                    <div className="divTableCell"> Discount Value (%) </div>
                                    <div className="divTableCell"> Action </div>
                                </div>
                                <div className="divTableBody">
                                    {
                                        isAdded && this.renderNewLaneBody()
                                    }
                                    
                                    {this.renderTableBody(masterData)}
                                </div>
                            </div> 

                            <div className="submit-section-pagination">
                                <div className="next">
                                    <Button value="Next" disabled={masterData.length===0 || masterData.length < this.state.limit} click={this.next} />
                                </div>
                                <div className="pre">
                                    <Button value="Previous" disabled={this.state.offset===0} click={this.previous} />
                                </div>
                            </div>

                        </div>
                    </MainComponent>
                </div>
            </div>
        );
    }
}

const CustomeCheckBox = (props) =>{
    return(
        <div className = {props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick = {()=>props.toggle(props.id)}>
            {props.selected && <div className = "inner-circle"></div>}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loader: state.loader,
        msgpopup: state.msgpopup,
        dataGovernance: state.dataGovernance,
    }
}

export default connect(mapStateToProps)(DiscountStructureDPHRates);
