import React, { Component } from "react";
import { connect } from "react-redux";
import "./copymail.less";
import { getDate } from "date-fns/esm";

class CopyMail extends Component {
  constructor(props) {
    super(props);
   //  console.log("send props", this.props.broadcast.data);
    this.state = {
      maildetails: [
        {
          date: null,
          message: null,
          reciepients: null,
          count: null,
          action: null
        }
      ]
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.broadcast.data !== this.props.broadcast.data) {
      this.setState({
        reciepients: nextProps.broadcast.data
      });
    }
  }

  componentDidMount() {
    //this.props.getAllMails();
    this.props.copyAllMails();
  }

  tableData() {
    return this.props.broadcast.data && this.props.broadcast.data.map((details ,i) => {
      return (
        <tr key={i}>
          <td>{details.inserted_on.slice(0, 10)}</td>
          <td>{details.message}</td>
          <td>
            {(details.email_list || []).map(mail => {
              return <div>{mail}</div>;
            })}
          </td>
          <td>{details.count}</td>
          <td>
            <button
              className="generate-button"
              type="button"
              onClick={() => this.props.onCopy(i)}
            >
              Copy
            </button>
          </td>
        </tr>
      );
    });
  }

  tableHeader() {
    let header = Object.keys(this.state.maildetails[0]);
    return header.map(key => <th>{key.toUpperCase()}</th>);
  }

  render() {
    //console.log("emails", this.props.getAllMails);
   //  console.log("broadcast data", this.props.selected);
    //  const checkvar = this.props.broadcast.data;
    //  console.log("copy", checkvar);
    return (
      <div>
        <h1 id="table-title">Sent Broadcasts</h1>
        <table id="broadcast-table">
          <tbody>
            <tr>{this.tableHeader()}</tr>
            {this.tableData()}
          </tbody>
        </table>
      </div>
    );
  }
}

export default CopyMail;
