import React, { Component } from 'react';
import Dropup from "../../../assets/images/drop-up-arrow.svg";
import InputText from '../../common/components/input-text/input-text';
import Delete from "../../../assets/images/delete.svg";
import Add from '../../../assets/images/add.svg';
import { connect } from 'react-redux';
import ACTIONS from "../../common/action_constant";
import CreateTemplate from "./create_template";

const navigationPanel = [
    {heading: 'Create Particulars', id: 1},
    {heading: 'Create Template', id: 2}
]

class WarehouseParticulars extends Component {
    constructor() {
        super();
        this.state = {
            data: [],
            selectedOption: 1,
            templateData: []
        }
    }

    componentDidMount = () => {
        this.getWarehouseParticulars();
        this.getWarehouseTemplateList();
    }

    getWarehouseParticulars = () => {
        const { dispatch, companyId } = this.props;
        dispatch({
            type: ACTIONS.HOME.GET_WAREHOUSE_PARTICULARS_LIST,
            param: {
                limit: 20,
                offset: 0,
                companyId
            },
            onSuccess: (data) => {
                this.setState({ data });
            }
        })
    }

    onChangeHandler = (value) => {
        this.setState({ headerText: value });
    }

    addHeader = () => {
        const { headerText, data } = this.state;
        data.push({
            [headerText]: {}
        });
        this.setState({ data, [`showSection_${(headerText || '').replaceAll(" ", "")}`]: true, headerText: '' });
    }

    addType = (value, index) => {
        const { data } = this.state;
        data[index][value][this.state[`headType${(value || '').replaceAll(" ", "")}`]] = [{ label: '', deprication_period: ''}]
        this.setState({ data, [`headType${(value || '').replaceAll(" ", "")}`]: '' });
    }

    onAddSubType = (val, subType, index) => {
        const { data } = this.state;
        data[index][val][subType].push({ label: '', deprication_period: ''});
        this.setState({ data });
    }

    deleteSubType = (val, subType, index, headerIndex) => {
        const { data } = this.state;
        data[headerIndex][val][subType].splice(index, 1);
        this.setState({ data });
    }

    onSave = () => {
        const { dispatch, companyId } = this.props;
        const { data } = this.state;

        dispatch({
            type: ACTIONS.HOME.SAVE_WAREHOUSE_PARTICULARS_LIST,
            data: {
                "warehouse_particulars_list": data,
                "company_id": companyId
            },
            onSuccess: this.getWarehouseParticulars
        })
    }

    getWarehouseTemplateList = () => {
        const { dispatch, companyId } = this.props;
        dispatch({
            type: ACTIONS.HOME.GET_WAREHOUSE_TEMPLATE_LIST,
            param: {
                limit: 20,
                offset: 0,
                companyId
            },
            onSuccess: (data) => {
                this.setState({ templateData: data });
            }
        })
    }

    onSaveTemplate = (template) => {
        const { dispatch, companyId } = this.props;
        dispatch({
            type: ACTIONS.HOME.SAVE_WAREHOUSE_TEMPLATE_LIST,
            data: {
                "template_list": template,
                "company_id": companyId
            },
            onSuccess: this.getWarehouseTemplateList
        })
    }

    downloadParticularsTemp =(withData) =>{
      const { dispatch , companyId} = this.props;
      this.setState({
        downloadLink : null
      })
     dispatch({
       type :ACTIONS.HOME.DOWNLOAD_PARTICULARS_CONVERSION,
       param: {
        companyId
      },
       withData,
       onSuccess :((data)=>{
       this.setState({
        downloadLink : data.Link
       })
       this.downloadBtn.click();
       })
     });
    }

    uploadParticulars=(e)=>{
        let formData = new FormData();
        const { dispatch , companyId} = this.props;
        formData.append('file', e.target.files[0]);
        dispatch({
           type :ACTIONS.HOME.UPLOAD_PARTICULARS_CONVERSION,
           data: formData,
           companyId: companyId,
           onSuccess: ((data) => {
             if(data){
                this.getWarehouseParticulars();
             }
             
           })
        })
    }
    

    render() {
        const { data } = this.state;
        return (
            <div className="golden-box warehouse_particulars">
                <div className="home-navigation-holder">
                {navigationPanel.map((option, index) => {
                    return (
                    <div
                        key={index}
                        className={
                        this.state.selectedOption === option.id
                            ? "selected-option options"
                            : "options"
                        }
                        onClick={() => {
                            this.setState({ selectedOption: option.id }, () => {
                                if(this.state.selectedOption === 1) {
                                    this.getWarehouseParticulars();
                                } else if(this.state.selectedOption === 2) {
                                    this.getWarehouseTemplateList();
                                }
                            })
                        }}
                    >
                        {option.heading}
                    </div>
                    );
                })}
                </div>
                {this.state.selectedOption === 1 && <>
                <div>
                    <InputText
                        placeholder="Header"
                        id={"header"}
                        label="Header"
                        changeHandler={this.onChangeHandler}
                        value={this.state.headerText}
                    />
                    <div className="add-button add-level" onClick={this.addHeader}>Add Header</div>
                        
                    <button className="add-button add-level" onClick={() => this.downloadParticularsTemp(true)}> Download Template</button>
                    <button className="add-button add-level" onClick={() => {this.uploadExcel.click()}}> Upload Excel </button>
                    <button className="add-button add-level" onClick={() => this.downloadParticularsTemp(false)}> Download Data</button>
                    <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
                    <input type='file' style={{ display: 'none'}}  ref={(e) => { this.uploadExcel = e ;}} onChange={this.uploadParticulars} onClick={(e) => {e.target.value = null }}/> 
                </div>
                {(data || []).map((val, valIndex) => {
                const headerValue = Object.keys(val)[0];
                return(
                <div className={'golden-box golden-box-sub'} key={valIndex}>
                    <div className={'heading sub-heading'}>{headerValue}
                    <div className={ this.state[`showSection_${headerValue}`] ? "arrow-icon rotate" : "arrow-icon"} style={{ backgroundImage: `url(${Dropup})` }} onClick={() => {
                        this.setState({ [`showSection_${(headerValue || '').replaceAll(" ", "")}`] : !this.state[`showSection_${(headerValue || '').replaceAll(" ", "")}`]})}}></div>
                    <div className="fRight" onClick={() => {
                        const { data } = this.state;
                        data.splice(valIndex, 1);
                        this.setState({ data });
                    }}>X</div>
                    </div>
                    {this.state[`showSection_${(headerValue || '').replaceAll(" ", "")}`] && 
                    <>
                        <div style={{'margin': '10px'}}>
                            <InputText
                                placeholder="Type"
                                id={"type"}
                                label="Type"
                                changeHandler={(value) => this.setState({ [`headType${(headerValue || '').replaceAll(" ", "")}`]: value })}
                                value={this.state[`headType${(headerValue || '').replaceAll(" ", "")}`]}
                            />
                            <div className="add-button add-level" onClick={() => this.addType(headerValue, valIndex)}>Add Type</div>
                        </div>
                        {(Object.keys(val[headerValue]) || []).map((typeVal, typeIndex) => (
                        <>{ typeVal &&
                            <> 
                            <div className="level-header">
                                <div style={{'display': 'inline-block', 'width': '98%'}}>{typeVal}</div>
                                <div className="cancel-icon" onClick={() => {
                                    const { data } = this.state;
                                    delete data[valIndex][headerValue][typeVal]
                                    this.setState({ data });
                                }}>X</div>
                            </div>
                            <div className="golden-box-display">
                                <div className="headers"><div className="labels">Line Items</div></div>
                                <div className="headers"><div className="labels">Deprication Period</div></div>
                                <div className="headers"><div className="labels">Action</div></div>
                            </div>
                            </>}
                            {Array.isArray(data[valIndex][headerValue][typeVal]) && (data[valIndex][headerValue][typeVal] || []).map((element, eleInd) => {
                                return (
                                    <>
                                    {element &&
                                    <div className="golden-box-display">
                                    <div className="details">
                                        <InputText
                                            placeholder="Line Item"
                                            id={"Line Item"}
                                            label=""
                                            changeHandler={(value) => {
                                                const { data } = this.state;
                                                data[valIndex][headerValue][typeVal][eleInd]['label'] = value
                                                this.setState({ data });
                                            }}
                                            value={element.label}
                                        />
                                    </div>
                                    <div className="details">
                                        <InputText
                                            placeholder="Eg. 1"
                                            id={"deprication_period"}
                                            label=""
                                            changeHandler={(value) => {
                                                const { data } = this.state;
                                                data[valIndex][headerValue][typeVal][eleInd]['deprication_period'] = value && Number(value);
                                                this.setState({ data });
                                            }}
                                            value={element.deprication_period}
                                        />
                                    </div>
                                    <div className="details">
                                        <div className={'delete-icon'} style={{backgroundImage: `url(${Add})`}} onClick={() => this.onAddSubType(headerValue, typeVal, valIndex)}></div>
                                        <div className = "delete-icon" style={{backgroundImage: `url(${Delete})`}} onClick = {() => this.deleteSubType(headerValue, typeVal, eleInd, valIndex)}></div>
                                    </div>
                                    </div>}
                                    </>
                                )
                            })}
                        </>))}
                    </>}
                </div>)})}
                <div className="details">
                    <div className="add-button add-level" onClick={this.onSave}>Save</div>
                </div>
                </>}
                {
                    this.state.selectedOption === 2 && 
                    <CreateTemplate
                        warehouseParticulars={data}
                        data={this.state.templateData}
                        onSaveTemplate={this.onSaveTemplate}
                    />
                }
            </div> 
        )
    }
}

export default connect()(WarehouseParticulars);