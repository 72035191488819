import React, { Component } from 'react';
import Button from "../../../button-classic/button-classic";
import Delete from "../../../../../assets/images/delete.svg";
import Edit from "../../../../../assets/images/edit.svg";
import AddLoadingGang from "./add_gang";
import Search from '../../../../common/components/search-text/search-text';
import { withRouter } from 'react-router';

class LoadingGang extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
            search: '',
            previous_data:null
        }
    }

    componentDidMount() {
        this.props.getLoadingGang();
    }

    AddVendor = (editData = '') => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen, editData, previous_data:editData });
    }

    toggleDirectDelete = (gang) => {
        const data = {
            vendor_code: gang.vendor_id,
            branch_code: gang.branch_code
        }
        this.props.deleteLoadingGang(data);
    }
    onAuditClick = () => {
        const {history,branch_id,company_id,depotCode} = this.props;
        const companyId = company_id
        const branchId = branch_id
        const branchCode=depotCode
        const masterType = 12
        const navUrl = `/auditScreen/${companyId}/${masterType}/${branchId}/${branchCode}`;
        history.push(navUrl)
      }

    render() {
        const { loadingGangData } = this.props;

        return(
            <div className="plants-depots-main-wrap">
                <div className="search-holder">
                <div className="add-plants-section">
                    <div style={{display: 'block', paddingBottom: '15px'}}>
                        <Search id = "search" value = {this.state.search} placeholder = "Search..." changeHandler = {(e) => this.setState({search: e})} click = {() => this.props.getLoadingGang(this.state.search)} canClear={this.state.search !== ''} handleClearFilter={() => this.setState({search: ''}, this.props.getLoadingGang())}/>
                    </div>
                    <Button value="+ Vendor" click={() => this.AddVendor('')} />
                    <input class="button-classic ml-10 mr-10" type="button" value="Download Temp." onClick={() => this.props.downloadLoadingVendor(false)} />
                    <a className="download-btn" id="loadingVendorDownloadBtn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.props.downloadLink} download />
                    <button className="button-classic ml-10 mr-10" onClick={() => {this.uploadExcel.click()}}> Upload </button>
                    <input type='file' style ={{display :'none'}} ref={(e) => { this.uploadExcel = e ;}} onChange={this.props.uploadLoadingVendorDetails} onClick={(e) => {e.target.value = null }}/>
                    <input class="button-classic ml-10" type="button" value="Download" onClick={() => this.props.downloadLoadingVendor(true)} />
                    <a className="download-btn" id="loadingVendorDownloadBtn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.props.downloadLink} download />
                    <button className="button-classic ml-10" onClick={()=>this.onAuditClick()}>Audit</button>
                
                </div>
                </div>
                <div className="heading">
                    <div className="flow-head">Vendor Name</div>
                    <div className="flow-head">Vendor Code</div>
                    <div className="flow-head">Labour Code</div>
                    <div className="flow-head">Gang Code</div>
                    <div className="flow-head">Warehouse Id</div>
                    <div className="flow-head">Actions</div>
                </div>
                {(loadingGangData || []).map((gang, index) => {
                return(
                    <div className={"depot-users-list"} key={`gang_${index}`}>
                        <div className="flow-head">{gang.name}</div>
                        <div className="flow-head">{gang.vendor_id}</div>
                        <div className="flow-head">{gang.labour_code}</div>
                        <div className="flow-head">{gang.gang_code}</div>
                        <div className="flow-head">{gang.warehouse_id}</div>
                        <div className="flow-head">
                            <div className = "delete-icon" style={{backgroundImage: `url(${Edit})`}} onClick = {() => this.AddVendor(gang)}></div>
                            <div className = "delete-icon" style={{backgroundImage: `url(${Delete})`}} onClick = {() => this.toggleDirectDelete(gang)}></div>
                        </div>
                    </div>)
                })}
                
                {(!loadingGangData || loadingGangData.length === 0) && <div className={'no_data_found'}>{'No Data Found'}</div>}

                {this.state.isOpen && <AddLoadingGang toggleAdd={this.AddVendor} onAddLoadingGang={this.props.addLoadingGang} data={this.state.editData} updateLodingGang={this.props.updateLodingGang} previous_data={this.state.previous_data} company_id={this.props.company_id}/>}
            </div>
        )
    }
}

export default (withRouter(LoadingGang));
