import React, { Component, Fragment } from 'react';
import "./module_config.less";
import Button from "../button-classic/button-classic";


class ModuleConfig extends Component {
    constructor() {
        super();
        this.state = {
            isAllSelected: false,
            modules : []
        }
    }

    componentWillMount() {
        this.props.getModuleList();
        this.props.getRFQTypes();
    }

    onSelectAll = () => {
        let { isAllSelected, modules } = this.state;
        isAllSelected = !isAllSelected;
        modules.map(val => {
            val.enable = isAllSelected;
        })
        this.setState({ isAllSelected, module })
    }

    onSelectModule = (index) => {
        let { modules, isAllSelected } = this.state;
        modules[index].enable = !modules[index].enable;
        if(isAllSelected) {
            isAllSelected = false;
        } else {
            isAllSelected = modules.filter(val => { return val.enable }).length ===  modules.length; 
            
        }
        this.setState({ modules,  isAllSelected });
    }

    onSubmit = () => {
        const { modules } = this.state;
        const data = {
            modules_access_list: modules
        }
        this.props.saveModuleList(data);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.moduleList && nextProps.moduleList.length > 0) {
            const modules = JSON.parse(JSON.stringify(nextProps.moduleList));
            const isAllSelected = modules.filter(val => { return val.enable }).length ===  modules.length;
            this.setState({ modules , isAllSelected });
        }
    }

    onSelectRFQType = (moduleIdx, rfqId) => {
        let { modules } = this.state;
        modules[moduleIdx].rfq_types = modules[moduleIdx].rfq_types || [];
        const isRFQExist = modules[moduleIdx].rfq_types.findIndex(rfq => rfq === rfqId);
        if(isRFQExist > -1) {
            modules[moduleIdx].rfq_types.splice(isRFQExist, 1);
        } else {
            modules[moduleIdx].rfq_types.push(rfqId);
        }
        this.setState({ modules });
    }

    onSelectRFQTypesAll = (moduleIdx) => {
        let { modules } = this.state;
        const { rfqTypes } = this.props;
        modules[moduleIdx].rfq_types = modules[moduleIdx].rfq_types || [];
        if(modules[moduleIdx].rfq_types.length === 0 || modules[moduleIdx].rfq_types.length < rfqTypes.length) {
            modules[moduleIdx].rfq_types = [];
            (rfqTypes || []).forEach(rfq => {
                modules[moduleIdx].rfq_types.push(rfq.rfq_type)
            })
        } else {
            modules[moduleIdx].rfq_types = [];
        }
        this.setState({
            modules
        })
        return modules
    }

    render() {
        const { isAllSelected, modules } = this.state;
        return (
            <div className={'module-config'}>
                <div className = "select-relevant-category-section">
                    {modules.length > 0 && <div className = "section-item">
                        <CustomeCheckBox selected={isAllSelected} toggle={this.onSelectAll} />
                        <div className = "title">All</div>
                    </div>}

                    {modules.map((mod, index) => {
                        return (
                            <div className = "section-item" key={`module-$${index}`}>
                                <CustomeCheckBox selected={mod.enable} id={index} toggle={this.onSelectModule} />
                                <div className = "title">{mod.module_name}</div>
                                    {mod.module_name === 'Contracting' && mod.enable && this.props.rfqTypes.length > 0 &&
                                    <Fragment>
                                        <div className = "section-item sub-item">
                                            <CustomeCheckBox selected={mod.rfq_types && (mod.rfq_types.length === this.props.rfqTypes.length)} toggle={this.onSelectRFQTypesAll.bind(this, index)} />
                                            <div className = "title">All</div>
                                        </div>

                                        {(this.props.rfqTypes || []).map((rfq, rfqIdx) => {
                                            return (
                                                <div className = "section-item sub-item" key={`moduleRfQ-${rfqIdx}`}>
                                                    <CustomeCheckBox selected={(mod.rfq_types || []).indexOf(rfq.rfq_type) > -1} id={index} toggle={this.onSelectRFQType.bind(this, index , rfq.rfq_type)} />
                                                    <div className = "title">{rfq.name}</div>
                                                </div>
                                            )})}
                                    </Fragment>
                                    }
                            </div>
                        )
                    })}
                </div><br/>
                <Button value="Submit" click={this.onSubmit} disabled={JSON.stringify(this.props.moduleList) === JSON.stringify(modules)}/>
            </div>
        )
    }
}

const CustomeCheckBox = (props) =>{
    return(
        <div className = {props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick = {()=>props.toggle(props.id)}>
            {props.selected && <div className = "inner-circle"></div>}
        </div>
    )
}

export default ModuleConfig;