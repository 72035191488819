import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import ACTION from "../../../../common/action_constant";
import './add-pod.less';


const InputBox = (props) => {
	return <input className="input-text" type={props.type || "text"} id={props.id} placeholder={props.placeholder} value={props.value} onChange={(e) => props.changeHandler(e.target.value, props.id)} />
}

const TextArea = (props) => {
	return <textarea className="input-textarea" placeholder={props.placeholder} value={props.value} onChange={(e) => props.changeHandler(e.target.value, props.id)} rows={props.rows} cols={props.cols} />
}

const CustomeCheckBox = (props) => {
	return (
		<div className={props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick={() => props.handleSelectChange(props.id, props.name)}>
			{props.selected && <div className="inner-circle"></div>}
		</div>
	)
}

const defaultData = {
	"pod_cycle": 1,
	"pod_submission": 1,
	"pod_slabs": {
		"slab_1": {
			"equal_than": "",
			"status": "",
			"value": "",
			"content": ""
		},
		"slab_2": {
			"less_than": "",
			"greater_than": "",
			"status": "",
			"value": "",
			"content": ""
		},
		"slab_3": {
			"less_than": "",
			"status": "",
			"value": "",
			"content": ""
		},
		"slab_4": {
			"status": "",
			"value": "",
			"content": ""
		}
	},
	"vendor_includes": []
};

const AddPod = (props) => {

	const { dispatch, dataGovernance } = props;
	const { podClauseRowData, vendorTypes, podStatus, podCycles, podSubmission } = dataGovernance;
	const [rowData, setRowData] = useState(defaultData)

	useEffect(() => {
		getPodStatusData();
		getVendors();
	}, [])

	useEffect(() => {
		if (Object.keys(props.rowData).length > 0) {
			setRowData(props.rowData);
		}
	}, [props.rowData])

	useEffect(() => {

	}, [])

	const handleSelectChange = (value, name) => {
		setRowData({
			...rowData,
			[name]: value,
		})
	}

	const handleMultiSelect = (name, value) => {
		let newVendorTypes = [];
		value.map(row => {
			newVendorTypes.push(row.company_id)
		})
		setRowData({
			...rowData,
			[name]: newVendorTypes,
		})
	}

	const handleSelectInputChange = (slab, name, selected) => {
		setRowData({
			...rowData,
			pod_slabs: {
				...rowData.pod_slabs,
				[slab]: {
					...rowData.pod_slabs[slab],
					[name]: selected.value,
				},
			}
		})
	}

	const handleInputChange = (slab, name, value) => {
		setRowData({
			...rowData,
			pod_slabs: {
				...rowData.pod_slabs,
				[slab]: {
					...rowData.pod_slabs[slab],
					[name]: value,
				},
			}
		})
	}

	const getPodCycleData = () => {
		const { dispatch } = props;
		dispatch({
			type: ACTION.DATA_GOVERNANCE.GET_POD_CYCLES,
		})
	}

	const getPodStatusData = () => {
		const { dispatch } = props;
		dispatch({
			type: ACTION.DATA_GOVERNANCE.GET_POD_STATUS,
		})
	}

	const getVendors = () => {
		const { dispatch } = props;
		dispatch({
			type: ACTION.DATA_GOVERNANCE.GET_POD_VENDORS_TYPES,
		})
	}

	return (
		<div className="pod-cycle-form add-company-wrap add-provider-root add-row">
			<div className="overlay">
				<div className="modal-popup" onClick={(e) => { }}>
					<div className="cycle-row">
						<div className="serviceTypes">
							<div>Cycle</div>
							{
								podCycles.map((row, index) => (
									<div key={index} className="section-item checkbox">
										<CustomeCheckBox name="pod_cycle" id={row.id} handleSelectChange={handleSelectChange} selected={parseInt(rowData.pod_cycle) == row.id ? true : false} />
										<div className="title"> {row.value}</div>
									</div>
								))
							}
						</div>
						<div className="serviceTypes align-right">
							<div>Submission Within</div>
							{
								podSubmission.map((row, index) => (
									<div key={index} className="section-item checkbox">
										<CustomeCheckBox name="pod_submission" handleSelectChange={handleSelectChange} id={row.id} selected={rowData.pod_submission == row.id ? true : false} />
										<div className="title"> {row.value}</div>
									</div>
								))
							}
						</div>
					</div>

					<div className="divTable company-list-wrap">
						<div className="list-section table-header divTableHeading divTableRow">
							<div className="divTableCell w-p-40 f-s-16"> POD Submission (In %) </div>
							<div className="divTableCell w-p-20 f-s-16"> Status </div>
							<div className="divTableCell w-p-60 f-s-16"> Payment </div>
						</div>
						<div className="list-section divTableRow">
							<div className="divTableCell inline-flex w-p-40 f-s-16">
								<table className="w-p-100">
									<tbody>
										<tr>
											<td width="70%">
												Equal
                                            </td>
											<td width="30%" className="align-right">
												<InputBox
													type="number"
													placeholder="Percent"
													value={rowData.pod_slabs.slab_1.equal_than}
													changeHandler={(value) => handleInputChange('slab_1', 'equal_than', value)}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="divTableCell w-p-20 f-s-16">
								<Select
									className="select"
									options={podStatus}
									value={podStatus.filter(row => row.value == rowData.pod_slabs.slab_1.status)}
									isSearchable={true}
									onChange={(value) => handleSelectInputChange('slab_1', 'status', value)}
								/> </div>
							<div className="divTableCell inline-flex w-p-60 f-s-16">
								<table className="w-p-100">
									<tbody>
										<tr>
											<td width="20%">
												<InputBox
													type="number"
													placeholder="Percent"
													value={rowData.pod_slabs.slab_1.value}
													changeHandler={(value) => handleInputChange('slab_1', 'value', value)}
												/>
											</td>
											<td width="80%">
												<span className="m-l-20"> % Payment</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div className="list-section divTableRow">
							<div className="divTableCell inline-flex w-p-40 f-s-16">
								<table className="w-p-100">
									<tbody>
										<tr>
											<td width="70%">
												Less
                                            </td>
											<td width="30%" className="align-right">
												<InputBox
													type="number"
													placeholder="Percent"
													value={rowData.pod_slabs.slab_2.less_than}
													changeHandler={(value) => handleInputChange('slab_2', 'less_than', value)}
												/>
											</td>
										</tr>
										<tr>
											<td width="70%">
												Greater Than Or Equal To
                                            </td>
											<td width="30%" className="align-right">
												<InputBox
													type="number"
													placeholder="Percent"
													value={rowData.pod_slabs.slab_2.greater_than}
													changeHandler={(value) => handleInputChange('slab_2', 'greater_than', value)}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="divTableCell w-p-20 f-s-16">
								<Select
									className="select"
									options={podStatus}
									value={podStatus.filter(row => row.value == rowData.pod_slabs.slab_2.status)}
									isSearchable={true}
									onChange={(value) => handleSelectInputChange('slab_2', 'status', value)}
								/> </div>
							<div className="divTableCell inline-flex w-p-60 f-s-16">
								<table className="w-p-100">
									<tbody>
										<tr>
											<td width="20%">
												<InputBox
													type="number"
													placeholder="Percent"
													value={rowData.pod_slabs.slab_2.value}
													changeHandler={(value) => handleInputChange('slab_2', 'value', value)}
												/>
											</td>
											<td width="80%">
												<span className="m-l-20"> % Payment</span>
											</td>
										</tr>
										<tr>
											<td className="info-cell">
												<span className="m-t-10"> Balance Payment Will Be Made After Submission Of 100% POD</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div className="list-section divTableRow">
							<div className="divTableCell inline-flex w-p-40 f-s-16">
								<table className="w-p-100">
									<tbody>
										<tr>
											<td width="70%">
												Less Than
                                            </td>
											<td width="30%" className="align-right">
												<InputBox
													type="number"
													placeholder="Percent"
													value={rowData.pod_slabs.slab_3.less_than}
													changeHandler={(value) => handleInputChange('slab_3', 'less_than', value)}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="divTableCell w-p-20 f-s-16">
								<Select
									className="select"
									options={podStatus}
									value={podStatus.filter(row => row.value == rowData.pod_slabs.slab_3.status)}
									isSearchable={true}
									onChange={(value) => handleSelectInputChange('slab_3', 'status', value)}
								/> </div>
							<div className="divTableCell inline-flex w-p-60 f-s-16">
								<TextArea
									rows={3}
									placeholder="Enter T&C..."
									value={rowData.pod_slabs.slab_3.content}
									changeHandler={(value) => handleInputChange('slab_3', 'content', value)}
								/>
							</div>
						</div>
						<div className="list-section divTableRow">
							<div className="divTableCell inline-flex w-p-40 f-s-16" />
							<div className="divTableCell w-p-20 f-s-16">
								<Select
									className="select"
									options={podStatus}
									value={podStatus.filter(row => row.value == rowData.pod_slabs.slab_4.status)}
									isSearchable={true}
									onChange={(value) => handleSelectInputChange('slab_4', 'status', value)}
								/> </div>
							<div className="divTableCell inline-flex w-p-60 f-s-16">
								<TextArea
									rows={2}
									placeholder="Enter T&C..."
									value={rowData.pod_slabs.slab_4.content}
									changeHandler={(value) => handleInputChange('slab_4', 'content', value)}
								/>
							</div>
						</div>
					</div>
					<div className={'select-wrap product-details'}>
						<div className="label">Vendor Includes</div>
						<Select
							value={vendorTypes.filter(row => rowData.vendor_includes.includes(row.company_id))}
							onChange={(value) => handleMultiSelect('vendor_includes', value)}
							options={vendorTypes}
							isMulti
						/>
					</div>
					<div className="submit-section submit-area">
						<div className="button-section" >
							<div className="add-button" onClick={() => props.handleSubmitForm(rowData)}>{rowData.pod_id !== undefined ? 'Update' : 'Add'}</div>
							<div className="add-button" onClick={() => props.handleForm()}>Close</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		loader: state.loader,
		msgpopup: state.msgpopup,
		dataGovernance: state.dataGovernance,
	}
}

export default connect(mapStateToProps)(AddPod);