import ACTION from "../../common/action_constant";

const contractsReducer = (state = { }, action) => {
    let tempState = Object.assign({}, state);
     if(action.type === ACTION.CONTRACTS.STORE_BASIC_CONTRACT_SETTING){
		tempState.basicContract  = action.data;		
		return tempState;
	}else if(action.type === ACTION.CONTRACTS.STORE_OTHER_CONTRACT_SETTING){
		tempState.otherContract  = action.data;		
		return tempState;
    }else if(action.type === ACTION.CONTRACTS.STORE_COLLAPSIBLE_SECTION_1){
		tempState.type1 = action.data;		
		return tempState;
	}else if(action.type === ACTION.CONTRACTS.STORE_COLLAPSIBLE_SECTION_2){
		tempState.type2 = action.data;		
		return tempState;
	}
    else if(action.type === ACTION.CONTRACTS.STORE_COLLAPSIBLE_SECTION_3){
		tempState.type3 = action.data;		
		return tempState;
	}else if(action.type === ACTION.CONTRACTS.STORE_COLLAPSIBLE_SECTION_4){
		tempState.type4 = action.data;		
		return tempState;
	}else if(action.type === ACTION.CONTRACTS.STORE_COLLAPSIBLE_SECTION_5){
		tempState.type5 = action.data;		
		return tempState;
	}else if(action.type === ACTION.CONTRACTS.STORE_COLLAPSIBLE_SECTION_6){
		tempState.type6 = action.data;		
		return tempState;
	}else if(action.type === ACTION.CONTRACTS.STORE_BRANCH_LIST){
        tempState.branchList  = action.data.list;		
		return tempState;
	}
	else if(action.type === ACTION.CONTRACTS.STORE_RFQ_LIST){
        tempState.rfqList  = action.data.list;		
		return tempState;
	}
	else if(action.type === ACTION.CONTRACTS.GET_EMAIL_LIST_DATA){
		tempState.emailList  = action.data;		
		return tempState;
	}
	else if(action.type === ACTION.CONTRACTS.SAVE_APPROVER_TYPES) {
		tempState.approverTypes = action.data;
		return tempState;
	}else if(action.type === ACTION.CONTRACTS.STORE_CURRENCY_DETAILS){
	    tempState.currencyDetails  = action.data;   
	    return tempState;
	}else if(action.type === ACTION.CONTRACTS.STORE_MASTER_CONTRACT_DATA){
	    tempState.masterDetails  = action.data;   
		return tempState;
	}else if(action.type === ACTION.CONTRACTS.STORE_PLANT_WISE_CONTRACT_DATA){
	    tempState.plantWiseContract  = action.data;   
		return tempState;
	}
	else if(action.type === ACTION.CONTRACTS.STORE_AUTO_DECREMENT_DATA){
	    tempState.autoDecrementDetails  = action.data;
		return tempState;
	}
	return state;
};

export default contractsReducer;