import React, { Component } from 'react';
import Select from 'react-select';
import InputText from '../../../../common/components/input-text/input-text';
import { uomType, detentionType, chainType } from '../../../../../utils/constants';
import '../../reconciliation.less';
import { decimalNumber } from '../../../../../utils/validate';
import Button from '../../../button-classic/button-classic';
import Delete from '../../../../../assets/images/delete.svg';
import Add from '../../../../../assets/images/add.svg';


class Detention extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                detention_at: this.props.editData ? this.props.editData.detention_at : '',
                detention_grace: this.props.editData ? this.props.editData.detention_grace : '',
                detention_amount: this.props.editData ? this.props.editData.detention_amount : '',
                detention_grace_uom: this.props.editData ? this.props.editData.detention_grace_uom : '',
                detention_amount_uom: this.props.editData ? this.props.editData.detention_amount_uom : '',
                chain_type: this.props.editData ? this.props.editData.chain_type: '',
                slabs: this.props.editData ? this.props.editData.slabs : [{
                    detention_at: '',
                    slab_min: '',
                    slab_min_uom: '',
                    slab_max: '',
                    slab_max_uom: '',
                    vehicles: [],
                    detention_amount: '',
                    detention_amount_uom: ''
                }]
            },
            vehicleOptions: []
        }
    }

    componentDidMount = () => {
        let vehicleOptions = [];
        (this.props.vehicleTypes || []).forEach(vehicle => {
            vehicleOptions.push({
                vehicle_name: vehicle.name,
                vehicle_type_id: vehicle.id
            })
        })
        this.setState({ vehicleOptions });
     }

    handleChange = (id , selectOptions) => {
        let { data } = this.state;
        data[id] = selectOptions.value;
        this.setState({ data });
    }


    onChangeHandler = (value, id) => {
        let { data } = this.state;
        data[id] = value;
        this.setState({ data });
    }

     onAmountChangeHandler = (value, id) => {
      if(decimalNumber(value) || value == '') {  
      let { data } = this.state;
        data[id] = value;
        this.setState({ data });}
    }

    onSave = () => {
        this.props.addDetentionDetails(this.state.data);
        this.props.toggleAdd();
    }

    updateDetails = () => {
        let {data} = this.state;
        data.detention_id = this.props.editData.detention_id;
        data.is_deleted = this.props.editData.is_deleted;
        data.company_id = this.props.editData.company_id;
        data.load_type = this.props.editData.load_type;
        data.mode_type = this.props.editData.mode_type;
        this.props.updateDetentionDetails(this.state.data);
        this.props.toggleAdd();
    }
   validateGrace = (value) => {
      return value.toString().match(/^\d{1,3}?$/)
   }
   validateAmt = (val) => {
      return val.toString().match(/^\d{1,10}(\.\d{1,3})?$/)
   }

   validData = ({ data } = this.state) => {
      return (data.detention_at && this.validateGrace(data.detention_grace) && data.detention_grace_uom && this.validateAmt(data.detention_amount) && data.detention_amount_uom);
   }

   onAddSlabs = () => {
       const { data } = this.state;
       data.slabs.push({
            detention_at: '',
            slab_min: '',
            slab_min_uom: '',
            slab_max: '',
            slab_max_uom: '',
            vehicles: [],
            detention_amount: '',
            detention_amount_uom: ''
        });
        this.setState({ data });
   }
   
   onDeleteSlabs = (index) => {
       const { data } = this.state;
       data.slabs.splice(index, 1);
       this.setState({ data });
   }

   handleSlabChange = (index, value, id) => {
        const { data } = this.state;
        data.slabs[index][id] = value;
        this.setState({ data });
   }

   handleSlabSelectChange = (id, index, selectOptions) => {
        const { data } = this.state;
        data.slabs[index][id] =  (id === 'vehicles') ? selectOptions : selectOptions.value;
        this.setState({ data });
   }

    render() {
       let { data }= this.state;
       const disable = true || this.validData();
        return(<div className = "add-company-wrap add-provider-root add-row detention">
            <div onClick = {this.props.toggleAdd} className = "overlay">
                <div className = "modal-popup" onClick={(e) => { e.stopPropagation() }}>
                    <div className = "heading">
                        {`${this.props.editData ? 'Edit' : 'Add'} Detention`}
                    </div>
                    <form className = "form-section" autoComplete = "nopes">
                        {[3, 4, 5, 6].indexOf(this.props.mainTabOption) > -1 && <div className="select-wrap">
                                <div className="label">Chain Type</div>
                                <Select className={'select'}
                                    options={chainType} 
                                    value={chainType.filter(val => {return val.label === this.state.data.chain_type})} 
                                    onChange={(options) => {
                                        const { data } = this.state;
                                        data.chain_type = options.label;
                                        this.setState({ data });
                                    }}
                                />
                            </div>
                        }
                        {/* <div className="select-wrap">
                            <div className="label">Detention</div>
                            <Select className={'select'} value={detentionType.filter(val =>{return val.value == this.state.data.detention_at})} onChange={this.handleChange.bind(this, 'detention_at')} options={detentionType}/>
                        </div>  */}
                        <InputText placeholder={'Grace'} label={'Grace'} id={'detention_grace'} value = {this.state.data.detention_grace} changeHandler = {this.onAmountChangeHandler} type={'number'}/>
                        <div className="select-wrap">
                            <div className="label">UOM</div>
                            <Select className={'select'} value={uomType.filter(val => { return val.value == this.state.data.detention_grace_uom})} onChange={this.handleChange.bind(this, 'detention_grace_uom')} options={uomType}/>
                        </div>
                        {/* <InputText placeholder={'Amount'} label={'Amount'} id={'detention_amount'} value = {this.state.data.detention_amount} changeHandler = {this.onAmountChangeHandler} type={'number'}/>
                        <div className="select-wrap">
                            <div className="label">UOM</div>
                            <Select className={'select'} value={uomType.filter(val => {return val.value == this.state.data.detention_amount_uom})} onChange={this.handleChange.bind(this, 'detention_amount_uom')} options={uomType}/>
                        </div> */}
                        <div className={'fuel-escalation-table'}>
                            <div className={'list-section'}>
                                <div className={'table-header table-header-max'}>Detention</div>
                                <div className={'table-header table-header-max-vehicle-type'}>Vehicle Type</div>
                                <div className={'table-header'}>Min</div>
                                <div className={'table-header'}>UOM</div>
                                <div className={'table-header'}>Max</div>
                                <div className={'table-header'}>UOM</div>
                                <div className={'table-header'}>Amount</div>
                                <div className={'table-header'}>UOM</div>
                                <div className={'table-header'}>Actions</div>
                            </div>
                            {data.slabs && (data.slabs || []).map((slab, ind) => {
                                return(
                                <div className={'list-section deliveryPerformance'}>
                                    <div className={'table-row align-center'}>
                                        <Select className={'select select-max'} value={detentionType.filter(val =>{return val.value == slab.detention_at })} onChange={this.handleSlabSelectChange.bind(this, 'detention_at', ind)} options={detentionType}/>
                                    </div>
                                    <div className={'table-row align-center'}>
                                        <Select 
                                            className={'select select-max-vehcile-type'} 
                                            isMulti 
                                            value={slab.vehicles} 
                                            onChange={this.handleSlabSelectChange.bind(this, 'vehicles', ind)} 
                                            options={this.state.vehicleOptions}
                                            getOptionLabel ={(option)=> option.vehicle_name}
                                            getOptionValue ={(option)=> option.vehicle_type_id}
                                        />
                                    </div>
                                    <div className={'table-row align-center'}>
                                        <InputText 
                                            placeholder={'Min'} 
                                            id='slab_min' 
                                            value={slab.slab_min}
                                            changeHandler={this.handleSlabChange.bind(this, ind)}
                                            type={'number'}
                                        />
                                    </div>
                                    <div className={'table-row align-center'}>
                                        <Select className={'select'} value={uomType.filter(val => { return val.value == slab.slab_min_uom })} onChange={this.handleSlabSelectChange.bind(this, 'slab_min_uom', ind)} options={uomType}/>
                                    </div>
                                    <div className={'table-row align-center'}>
                                        <InputText 
                                            placeholder={'Max'} 
                                            id='slab_max' 
                                            value={slab.slab_max}
                                            changeHandler={this.handleSlabChange.bind(this, ind)}
                                            type={'number'}
                                        />
                                    </div>
                                    <div className={'table-row align-center'}>
                                        <Select className={'select'} value={uomType.filter(val => { return val.value == slab.slab_max_uom })} onChange={this.handleSlabSelectChange.bind(this, 'slab_max_uom', ind)} options={uomType}/>
                                    </div>
                                    <div className={'table-row align-center'}>
                                        <InputText 
                                            placeholder={'Amount'} 
                                            id='detention_amount' 
                                            value={slab.detention_amount}
                                            changeHandler={this.handleSlabChange.bind(this, ind)}
                                            type={'number'}
                                        />
                                    </div>
                                    <div className={'table-row align-center'}>
                                        <Select className={'select'} value={uomType.filter(val => { return val.value == slab.detention_amount_uom })} onChange={this.handleSlabSelectChange.bind(this, 'detention_amount_uom', ind)} options={uomType}/>
                                    </div>
                                    <div className={'table-row'}>
                                        {data.slabs.length - 1 === ind && <div className={'delete-icon'} style={{backgroundImage: `url(${Add})`}} onClick={this.onAddSlabs}></div>}
                                        {data.slabs.length > 1 && <div className={'delete-icon'} style={{backgroundImage: `url(${Delete})`}} onClick={() => this.onDeleteSlabs(ind)}></div>}
                                    </div>
                                </div>)})}
                        </div>
                    </form>
                    <div className = "submit-section submit-area">
                    {
                    <div className="button-section">
                        {!this.props.editData && <div className ="button-section">
                            <Button className ="add-button" click={this.onSave} disabled={!disable} value="Save" />
                        </div>}
                        {this.props.editData && <div className ="button-section">
                            <Button className ="add-button" click={this.updateDetails} disabled={!disable} value="Update"/>   
                        </div>}
                        </div> 
                     }
                        <div className ="button-section" >
                            <div className ="add-button" onClick={this.props.toggleAdd}>Close</div>
                        </div> 
                    
                    </div>
                </div>
            </div>
         </div>)
    }
}

export default Detention;
