import React, { Component , Fragment } from 'react';
import Select from 'react-select';
import InputText from '../../../common/components/input-text/input-text';
import { damagesUOM } from '../../../../utils/constants';
import { decimalNumber } from '../../../../utils/validate'
import Button from '../../button-classic/button-classic';
import Delete from '../../../../assets/images/delete.svg';
import Add from '../../../../assets/images/add.svg';

class RASCharges extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                transporters: props.rasData ? props.rasData.transporters.map(val => { return { label: val.company_name, value: val.id }}) : [],
                // states: props.rasData ? props.rasData.states.map(val => { return { label: val.name, value: val.code }}) : [],
                // ras_charge: props.rasData ? props.rasData.ras_charge : '',
                // ras_uom: props.rasData ? damagesUOM.find(val => { return val.value === props.rasData.ras_uom }) : [],
                min_charge: props.rasData ? props.rasData .min_charge : '',
                max_charge: props.rasData ? props.rasData .max_charge : '',
                rates: props.rasData ? props.rasData.rates : [
                    {
                        zipcode: [],
                        rate: ''
                    }
                ]
            }
        }
    }

    onChangeamountHandler = (value, field) => {
       if (decimalNumber(value) || value == '') {
            const { data } = this.state;
            data[field] = value; 
            this.setState({ data }); 
        }
    }

    onSave = () => {
        const { data } = this.state;
        data.transporters = data.transporters.length > 0 ? data.transporters.map(val => { return { company_name : val.label, id : val.value }}) : data.transporters;
        // data.states = data.states.length > 0 ? data.states.map(state => { return { name: state.label , code : state.value }}) : data.states;
        // data.ras_uom = data.ras_uom.value;
        this.props.addPayables(data, 22);
        this.props.toggleAdd(22);
    }

    onUpdate = () => {
        const { data } = this.state;
        const { rasData } = this.props;
        data.transporters = data.transporters.length > 0 ? data.transporters.map(val => { return { company_name : val.label, id : val.value }}) : data.transporters;
        // data.states = data.states.length > 0 ? data.states.map(state => { return { name: state.label , code : state.value }}) : data.states;
        // data.ras_uom = data.ras_uom.value;
        data.payable_id = rasData.payable_id;
        data.load_type = rasData.load_type;
        data.mode_type = rasData.mode_type;
        data.type = rasData.type;
        this.props.updatePayables(data, 22);
        this.props.toggleAdd(22);
    }

    onTransporterSearch = (value) => {
        if(value) {
            this.props.getTransporterList(value);
        }
    }

   validateCharge = (val) => {
      return val.toString().match(/^\d{1,7}(\.\d{1,3})?$/)
   }
   validData = ({ data } = this.state) => {
      return (data.transporters.length !== 0 && data.min_charge && data.max_charge && data.rates.length > 0 && data.rates[0].zipcode.length > 0 && data.rates[0].rate);
   }

   onAddRates = () => {
       const { data } = this.state;
       data.rates.push({
           zipcode: [],
           rate: ''
       })
       this.setState({ data })
   }

   onDeleteRates = (index) => {
       const { data } = this.state;
       data.rates.splice(index, 1);
       this.setState({ data });
   }
   

    render() {
        const { data } = this.state;
        const { transporterList, rasData, stateList, zipCodes } = this.props;
       const disable = this.validData();

        return (
            <div className = "add-company-wrap add-provider-root add-row">
                <div onClick = {this.props.toggleAdd} className = "overlay">
                    <div className = "modal-popup" onClick={(e) => { e.stopPropagation() }}>
                        <div className = "heading">
                            { `${rasData ? 'Edit' : 'Add'} RAS Charges`}
                        </div>
                        <form className = "form-section" autoComplete = "nopes">
                            <div className="select-wrap">
                                <div className="label">Transporter</div>
                                <Select 
                                    isMulti
                                    className={'select'} 
                                    value={data.transporters}
                                    options={transporterList && transporterList.length > 0 ? transporterList.map(trans => {
                                        return { label : trans.company_name, value: trans.branch_id }
                                    }) : []}
                                    onChange= {(options) => {
                                        const { data } = this.state;
                                        data.transporters = options;
                                        this.setState({ data });
                                    }}
                                    onInputChange={this.onTransporterSearch}
                                />
                            </div>
                            <InputText id='min_charge' value={data.min_charge} placeholder={'Minimum Charge'} label={'Min. Charge'} changeHandler={this.onChangeamountHandler} type={'number'}/>
                            <InputText id='max_charge' value={data.max_charge} placeholder={'Maximum Charge'} label={'Max. Charge'} changeHandler={this.onChangeamountHandler} type={'number'}/>
                            <div className={'fuel-escalation-table'}>
                                <div className={'list-section'}>
                                    <div className={'table-header'}>ZIP Code</div>
                                    <div className={'table-header'}>Rate</div>
                                    <div className={'table-header'}>Actions</div>
                                </div>
                                {data.rates && data.rates.length > 0 && data.rates.map((rate, ind) => {
                                return(
                                    <div className={'list-section'}>
                                        <div className={'table-row align-center'}>
                                            <Select 
                                            isMulti 
                                            options={zipCodes && zipCodes.length > 0 ? zipCodes.map(code =>{ return { label: code.zipcode, value: code.zipcode }}) : []}
                                            value={rate.zipcode && rate.zipcode.length > 0 ? rate.zipcode.map(code =>{ return { label: code , value: code }}) : [] }
                                            onChange={(values)=> {
                                                const { data } = this.state;
                                                if(values.length > 0) {
                                                    const result = [];
                                                    values.map(val => {
                                                        result.push(val.label)
                                                    })
                                                    data.rates[ind]['zipcode'] = result;
                                                } else {
                                                    data.rates[ind]['zipcode'] = values;
                                                }
                                                this.setState({ data });
                                            }}
                                            />
                                        </div>
                                        <div className={'table-row align-center'}>
                                            <InputText 
                                                placeholder={'Rate/KG'} 
                                                id='rate' 
                                                value={rate.rate}
                                                changeHandler={(value)=> {
                                                    const { data } = this.state;
                                                    data.rates[ind]['rate'] = value;
                                                    this.setState({ data });
                                                }}
                                                type={'number'}
                                            />
                                        </div>
                                        <div className={'table-row align-center'}>
                                            {data.rates.length - 1 === ind && <div className={'delete-icon'} style={{backgroundImage: `url(${Add})`}} onClick={() => this.onAddRates()}></div>}
                                            {data.rates.length > 1 && <div className={'delete-icon'} style={{backgroundImage: `url(${Delete})`}} onClick={() => this.onDeleteRates(ind)}></div>}
                                        </div>
                                     </div>)})}
                            </div>
                            {/* <div className="select-wrap">
                                <div className="label">States</div>
                                <Select 
                                    isMulti
                                    className={'select'} 
                                    value={data.states}
                                    options={stateList && stateList.length > 0 ? stateList.map(state => {
                                        return { label : state.name, value: state.code }
                                    }) : []}
                                    onChange= {(options) => {
                                        const { data } = this.state;
                                        data.states = options;
                                        this.setState({ data });
                                    }}
                                />
                            </div>

                            <InputText id='ras_charge' value={data.ras_charge} placeholder={'amount/kg or percentage'} label={'Charge'} changeHandler={this.onChangeamountHandler} type={'number'}/>
                            <div className="select-wrap">
                                <div className="label">UOM</div>
                                <Select 
                                    options={damagesUOM}
                                    placeholder={'Select UOM'}
                                    value={data.ras_uom}
                                    onChange={(options) => {
                                        const { data } = this.state;
                                        data.ras_uom = options;
                                        this.setState({ data });
                                    }}
                                />
                            </div> */}
                        </form>
                        <div className = "submit-section submit-area">
                       {   
                          <div className="button-section">
                             {!this.props.rasData && <Button className="add-button" click={this.onSave} disabled={!disable} value="Save" />}
                             {this.props.rasData && <Button className="add-button" click={this.onUpdate} disabled={!disable} value="Update" />}
                          </div> 
                        }
                            <div className ="button-section" >
                                <div className ="add-button" onClick={this.props.toggleAdd}>Close</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RASCharges;