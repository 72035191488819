import React, { Component } from 'react';
import Search from '../../../../common/components/search-text/search-text';
import Button from '../../../../home/button-classic/button-classic';
import { connect } from "react-redux";
import ACTIONS from '../../../../common/action_constant'
// import { toPascalCase } from "../../../utils/validate";

import Delete from '../../../../../assets/images/delete.svg';
import Edit from '../../../../../assets/images/edit.svg'
import { getCookie } from '../../../../../utils/cookies';
import AddInventory from './add_inventory';
import { withRouter } from 'react-router-dom';
import { getEmail, getUserId } from '../../../../../utils/common';
import { getItemFromSessionStorage } from '../../../../../utils/sessionStorage';
// import { AWL_COMPANY_IDS } from '../../../../../utils/common';

class InventoryMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            offset: 0,
            limit: 20,
        }
    }
    componentDidMount(){
        this.getInventoryMaster();
        this.fetchDepotName();
    }

    fetchDepotName = () => {
        const {dispatch, companyId } = this.props;
        const queryString = `?companyId=${companyId}`;

        dispatch({
            type: ACTIONS.PLANTS.GET_DEPO_NAME,
            queryString
        })
    }
    getInventoryMaster() {
        const { dispatch, companyId } = this.props;
        const { search, offset, limit  } = this.state;
        const queryString = `?company_id=${companyId}&search=${search}&limit=${limit}&offSet=${offset}`;
        dispatch({
          type: ACTIONS.PLANTS.GET_INVENTORY_MASTER,
          queryString,
        });
      }

    changeHandler = (value) => {
        this.setState({ search: value });
    }

    toggleAdd = () => {
      
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen, editData: '' });
    }


    onSave = (data) => {
        const { search, offset } = this.state;
        const { dispatch, company_id } = this.props;
        data['master_type'] = 10;
        data['new_flag'] = true;
        data['user_email'] = getEmail();
        data['user_id'] = getUserId();
        dispatch({
            type: ACTIONS.PLANTS.ADD_INVENTORY_MASTER,
            data,
           
            onSuccess: () => {
                this.setState({ isOpen: false }, this.getInventoryMaster());
            }
        })
    }


    onSearch = () => {
        this.setState({ offset: 0 }, this.getInventoryMaster);
      };

    
    onUpdate = (data) => {
        const previousData = getItemFromSessionStorage('InventoryIn');
        previousData['update_flag'] = true;
        previousData['user_email'] = getEmail();
        previousData['user_id'] = getUserId();
        const { dispatch } = this.props;
        data['master_type'] = 10;
        data['update_flag'] = true;
        data['user_email'] = getEmail();
        data['user_id'] = getUserId();
        data['previous_data'] = previousData;
        dispatch({
            type: ACTIONS.PLANTS.UPDATE_INVENTORY_MASTER,
            data,
            onSuccess: () => {
                this.setState({ isOpen: false, editData: '' }, this.getInventoryMaster);
            }
        })
    }

    editMC = (data) => {
        this.setState({
            editData: data,
            isOpen: true,
        });
    }

    deleteMC = (data) => {
        
        const { dispatch } = this.props;
        dispatch({
            type: ACTIONS.PLANTS.DELETE_INVENTORY_MASTER,
            queryString:`?company_id=${data.company_id}&branch_code=${data.branch_code}&user_id=${getUserId()}&user_email=${getEmail()}&master_type=${10}`,
            onSuccess: () => {
                this.getInventoryMaster();
            }
        })
    }

    previous = () => {
        if (this.state.offset >= this.state.limit) {
          this.setState(
            (prevState) => ({
              offset: prevState.offset - prevState.limit,
            }),
            this.getInventoryMaster
          );
        }
      };
      
      next = () => {
        this.setState(
          (prevState) => ({
            offset: prevState.offset + prevState.limit,
          }),
          this.getInventoryMaster
        );
      };
      
    clearFilterOnCancel = () => {
        this.setState({ search: '', offset: 0 }, this.getInventoryMaster);
      };

    handleOnClickForAuditBtn = () => {
        const {history, companyId} = this.props;
        const masterType = 10
        const navUrl = `/auditScreen/${companyId}/${masterType}`;
        history.push(navUrl)
    }

    render() {
        const { plantsDepots, home } = this.props;    
        const{DepotName,inventoryMaster}=plantsDepots;
         
        return (
            <div className={'location-network'}>
                <div>
                    <div style={{ display: 'block', paddingBottom: '15px' }}>
                        <Search id="search" value={this.state.search} placeholder="Search..." changeHandler={this.changeHandler} click={this.onSearch} canClear={this.state.search !== ''} handleClearFilter={this.clearFilterOnCancel} />
                        <Button value={'+ Inventory Master'} click={this.toggleAdd}  />
                        <button
                            className="button-classic"
                            onClick={this.handleOnClickForAuditBtn}
                        >
                            Audit
                        </button>
                    </div>

                </div>
                < table className={'chain-details-section'}>
                   
                    <tr className={'list-section header'}>
                        <td className={'tabel-header vol-th'}>Branch Name </td>
                        <td className={'tabel-header vol-th'}>Total Area in sqft</td>                                              
                        <td className={'tabel-header vol-th'}>Carpet Area in sqft</td>
                        <td className={'tabel-header vol-th'}>Storage Capacity in mt</td>                       
                        <td className={'tabel-header vol-th'}>Actions</td>
                    </tr>
        
                        {(inventoryMaster|| []).map((mc, index) => {
                            return(
                            <div className={'list-section content'} key={`route_${index}`}>
                                        <div className={'tabel-header vol-td'}>{mc.branch_name}</div>  
                                        <div className={'tabel-header vol-td'}>{mc.total_area_in_sqft}</div>
                                        <div className={'tabel-header vol-td'}>{mc.carpet_area_in_sqft}</div>
                                        <div className={'tabel-header vol-td'}>{mc.storage_capacity_in_mt}</div>
                                    <div className={'tabel-header vol-act'}> 
                                        <div className={'action-icon'} style={{backgroundImage: `url(${Edit})`}} onClick={() => this.editMC(mc)} />
                                        <div className={'action-icon'} style={{backgroundImage: `url(${Delete})`}} onClick={() => this.deleteMC(mc)} />
                                    </div>
                            </div>)})
                        }
                       
                </table>
                <div className="submit-section-pagination">
                    <div className="next">
                        <Button value="Next" click={this.next} />
                    </div>
                    <div className="pre">
                        <Button value="Previous" click={this.previous} />
                    </div>
                </div>
                {this.state.isOpen && 
                <AddInventory  toggleAdd={this.toggleAdd} onSave={this.onSave} companyId={this.props.companyId} editData={this.state.editData} onUpdate={this.onUpdate} 
                fetchDepotName = {DepotName}
                />
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        plantsDepots: state.plantsDepots|| [],      
        home: state.home,
        fetchDepotName: state.fetchDepotName
    };
};

export default connect(mapStateToProps)(withRouter(InventoryMaster));
