import React, { Component, Fragment } from 'react';
import "./delayPenaltyStructure.less";

import Button from "../../home/button-classic/button-classic";
import InputText from "../../common/components/input-text/input-text";
import Switch from "react-switch";
import Select from "react-select";
import { ABFRL_COMPANY_IDS, DISTRIBUTION_TYPE_DELAY_PENALTY_DEFINE_SLAB, PANTALOONS_COMPANY_IDS, SERVICE_TYPE_DELAY_PENALTY_DEFINE_SLAB, getUserCompanyId } from '../../../utils/common';

const BOUND_TYPES = [
    {
        label: 'Inbound',
        value: 1
    },
    {
        label: 'Outbound',
        value: 2
    }
];

class AddNewSlabs extends Component {

	constructor(props){
        super(props);
        this.state = {
            isError: '',
            showAddSlab: true,
            bound_type:'',
            dropdown_type_id: '',
            dropdown_name: '',
        }
    }

    isDisabled = () => {
        const { newSlabData } = this.props;
        console.log('newSlabData:', newSlabData)
        
        if (!PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && newSlabData.company_category && newSlabData.vendor_name && newSlabData.vendor_id && newSlabData.mode_type) {
            return false;
        }
        if(PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && newSlabData.vendor_name && newSlabData.vendor_id && newSlabData.mode_type) {
            return false;
        }
        return true;
    }

    componentDidMount() {
        this.checkForBusinessStop();
    }

    componentWillReceiveProps(nextProps) {
        this.checkForBusinessStop();
    }

    checkForBusinessStop () {
        const { newSlabData, typeUOM, modesList, vendorsList } = this.props;
        const slabAdded = (typeUOM && typeUOM.id && typeUOM.id === 2) ? newSlabData.delay_penalty_ontime_efficiency.vendor_ontime_efficiency : newSlabData.delay_penalty_days.vendor_delay_penalty_days;
        let showAddSlab = true;
        //checking for business stop
        slabAdded.forEach(element => {
            if (element.isBusinessStop) {
                showAddSlab = false
            }
        });
        this.setState({
            showAddSlab: showAddSlab,
        })
    }

	render() {
		const { newSlabData, typeUOM, modesList, vendorsList,distribution_type } = this.props;
        console.log('distribution_type:', distribution_type)
        const slabAdded = (typeUOM && typeUOM.id && typeUOM.id === 2) ? newSlabData.delay_penalty_ontime_efficiency.vendor_ontime_efficiency : newSlabData.delay_penalty_days.vendor_delay_penalty_days;
        console.log('slabAdded:', newSlabData)
        const tempSlabData = newSlabData
        const shouldPRender = PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && typeUOM.id != 2
        return(
			<div className="add-user-wrap">
                <div className="overlay"></div>
                <div id="modal" className="modal-popup delay-penalty-popup" style={{width: '1150px'}}>
                    <div className="pop-heading"> Define Slab </div>
                    <div className="content">

                        <div className="list-section table-header divTableRow">
                            <div className="divTableCell bordered"> Vendor Name </div>
                            <div className="divTableCell bordered"> Vendor Code </div>
                            <div className="divTableCell bordered"> Email </div>
{PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && <div className="divTableCell bordered"> Service Mode </div>}
{PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) &&  <div className="divTableCell bordered"> Distribution Type </div>}
{PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) &&  <div className="divTableCell bordered"> Bound Type </div>}
{!PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && <div className="divTableCell bordered"> Service Mode </div>}
                            {shouldPRender &&
                                    <div className="divTableCell bordered"> Service Type </div>
                            }
                            {shouldPRender &&
                                    <div className="divTableCell bordered">Same city logic</div>
                            }
                       
                        </div>

                        <div className="list-section content divTableRow">
                            <div className="divTableCell">
                                <Select
                                    className="select"
                                    options={vendorsList || []}
                                    // value={typeUOM && typeUOM.value ? DELAY_UOM_TYPES.find(val => { return  val.value === typeUOM.value }) : null}
                                    onChange={(value) => {
                                        this.props.onChangeInput(value, null, 'vendor_obj')
                                    }}
                                />
                            </div>
                            <div className="divTableCell">
                                {tempSlabData && tempSlabData.vendor_code ? tempSlabData.vendor_code : ''}
                            </div>
                            <div className="divTableCell slabRange">
                                <InputText type="text" changeHandler={(value, id) => this.props.onChangeInput(value, id, 'vendor_email')} value={tempSlabData && tempSlabData.vendor_email ? tempSlabData.vendor_email : ''} placeholder="Email" />
                            </div>
                            {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) &&
                                <div className="divTableCell">
                                    <Select
                                        className="select"
                                        options={modesList || []}
                                        // value={typeUOM && typeUOM.value ? DELAY_UOM_TYPES.find(val => { return  val.value === typeUOM.value }) : null}
                                        onChange={(value) => {
                                            this.props.onChangeInput(value, null, 'mode_type_obj')
                                        }}
                                    />
                                </div>}
                            {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) &&
                                <div className="divTableCell slabRange">
                                    <Select
                                        className="select"
                                        options={(DISTRIBUTION_TYPE_DELAY_PENALTY_DEFINE_SLAB || []).map((el) => { return { label: el.distribution_type, value: el.distribution_type_id, id: el.distribution_type_id } })}
                                        // value={distribution_type.filter(el => el.value === slabAdded.distribution_type)[0]}
                                        onChange={(value) => {
                                            this.props.onChangeInput(value, 'distribution_type_id', 'distribution_type')
                                        }}
                                    />
                                </div>}
                            {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) &&
                                <div className="divTableCell slabRange">
                                    <Select
                                        className="select"
                                        options={BOUND_TYPES}
                                        value={BOUND_TYPES.filter(el => el.value === slabAdded.bound_type)[0]}
                                        onChange={(value) => {
                                            this.props.onChangeInput(value.value, null, 'bound_type')
                                        }}
                                    />
                                </div>}
                            {!PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) &&
                                <div className="divTableCell">
                                    <Select
                                        className="select"
                                        options={modesList || []}
                                        // value={typeUOM && typeUOM.value ? DELAY_UOM_TYPES.find(val => { return  val.value === typeUOM.value }) : null}
                                        onChange={(value) => {
                                            this.props.onChangeInput(value, null, 'mode_type_obj')
                                        }}
                                    />
                                </div>}
                            {shouldPRender &&
                                <div className="divTableCell slabRange">
                                    <Select
                                        className="select"
                                        options={(SERVICE_TYPE_DELAY_PENALTY_DEFINE_SLAB || []).map((el) => { return { label: el.service_type, value: el.service_type_id, id: el.service_type_id } })}
                                        // value={newSlabData.service_type}
                                        onChange={(value) => {
                                            this.props.onChangeInput(value, 'service_type_id', 'service_type')
                                        }}
                                    />
                                </div>
                            }
                            {shouldPRender &&
                                <div className="divTableCell slabRange">
                                    <Switch
                                        // onChange={(checked)=>handleServiceCityToggleChange(checked)}
                                        onChange={(value) => {
                                            this.props.handleServiceCityToggleChange(value)
                                        }}
                                        checked={newSlabData.same_city_logic} 
                                    />
                                </div>
                            }
                        </div>

                        <div className="list-section table-header divTableRow">
                            <div className="divTableCell bordered"> Slabs </div>
                            <div className="divTableCell bordered"> From {(typeUOM && typeUOM.id && typeUOM.id === 2) ? '%' : ''} </div>
                            <div className="divTableCell bordered"> To {(typeUOM && typeUOM.id && typeUOM.id === 2) ? '%' : ''} </div>
                            <div className="divTableCell bordered"> </div>
                        </div>

                        {(slabAdded || []).map((slab, index) => {
                            console.log("slabs",slab)
                            return (
                              <div className="list-section content divTableRow">
                                 <div className="divTableCell"> 
                                        <div key={index + 1}> {`Slab ${index + 1}`} </div>
                                    </div>
                                    <div className="divTableCell slabRange"> 
                                        <InputText type="number" changeHandler={(value, id) => this.props.onChangeInput(value, id, 'max', index)} value={slab.max} placeholder="From" />
                                    </div>
                                    <div className="divTableCell slabRange"> 
                                        <InputText type="number" changeHandler={(value, id) => this.props.onChangeInput(value, id, 'min', index)} value={slab.min} placeholder="To" />
                                    </div>
                                    <div className="divTableCell submit-section business-button">
                                        <button className={slab.isBusinessStop ? "add-button" : "add-button discard"} onClick={(value, id) => this.props.onChangeInput(!slab.isBusinessStop, id, 'isBusinessStop', index)}>Business Stop</button>
                                    </div>
                              </div>
                            );
                        })}
                        
                        <div className="submit-section mt-40">
                            {
                                this.state.showAddSlab && <button className="add-button" onClick={this.props.addNewSlabs}>+ Add Slab</button>
                            }
                            <button className="add-button discard" onClick={this.props.cancelNewSlab}>Cancel</button>
                            <button 
                                className="add-button" 
                                // disabled={this.isDisabled()} 
                                onClick={() => {
                                    if (this.isDisabled()) {
                                        this.setState({ isError: 'Please fill all the fields to proceed.' });
                                    } else {
                                        this.setState({ isError: '' });
                                        this.props.handleSave(slabAdded);
                                    }
                                }}
                            >Save
                            </button>
                        </div>
                        
                        {this.state.isError.length > 0 && <div className="error">{this.state.isError}</div>}
                    </div>
                </div>
            </div>
		)
	}
}

export default AddNewSlabs;
