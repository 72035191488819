import React, { Component } from 'react';
import InputText from '../../../../common/components/input-text/input-text';
import Select from 'react-select';
import Button from '../../../../home/button-classic/button-classic';
import { PackagingType, LoadType, unloadingMhe } from '../../../../../utils/constants';
import { getCookie } from "../../../../../utils/cookies";
import CheckBox from "../../../../components/checkbox/checkbox";

class AddItem extends Component {
    constructor(props){
        super(props);
        this.state = {
            data : {
                part_id:this.props.data.part_id,
                part_number:this.props.data.part_number,
                part_description:this.props.data.part_description,
                warehouse:this.props.data.warehouse,
                warehouse_loc:this.props.data.warehouse_loc,
                spq:JSON.stringify(this.props.data.spq),
                packing_type:this.props.data.packing_type,
                load_type:this.props.data.load_type,
                bin_length:JSON.stringify(this.props.data.bin_length),
                bin_width:JSON.stringify(this.props.data.bin_width),
                bin_height:JSON.stringify(this.props.data.bin_height),
                weight_per_pallete:JSON.stringify(this.props.data.weight_per_pallete),
                bin_stack_permit:JSON.stringify(this.props.data.bin_stack_permit),
                freq_of_dispatch:JSON.stringify(this.props.data.freq_of_dispatch),
                vendor_code:this.props.data.vendor_code,
                vendor_name:this.props.data.vendor_name,
                delivery_loc:this.props.data.delivery_loc,
                plant_name:this.props.data.plant_name,
                unloading_dock:this.props.data.unloading_dock,
                unloading_mhe:this.props.data.unloading_mhe,
                packing_type_id:JSON.stringify(this.props.data.packing_type_id),
                weight:JSON.stringify(this.props.data.weight),
                pallete_height:JSON.stringify(this.props.data.pallete_height),
                pallete_length:JSON.stringify(this.props.data.pallete_length),
                pallete_width:JSON.stringify(this.props.data.pallete_width),
                // weight_per_pallete:JSON.stringify(this.props.data.weight_per_pallete),
                no_of_bins_on_pallete:JSON.stringify(this.props.data.no_of_bins_on_pallete),
                is_active:this.props.data.is_active,
            }
        }
    }

    handleChange = (id, selectedOption ) => {
        let { data } = this.state;
        data[id] = selectedOption.label;
        this.setState({ data });
    }
    

    onChangeHandler = ( value, id ) => {
        let { data } = this.state;
        data[id] = value;
       
        this.setState({ data });
    }

    validateCheck = () => {
        const { data } = this.state;
        if(data.part_number && data.part_description && data.spq && data.packing_type && data.load_type && data.bin_length && data.bin_width && data.bin_height) {
            return true;
        } else{
            return false;
        }
    }

    onSave = () => {
        const { data } = this.state;
        if(this.validateCheck()){
            this.props.toggleAdd("Edit");
            this.props.updateSkuDetails(data);
        } else {
            this.setState({errorMsg: "Please enter all Mandatory fields"});
        }
    }

    toggleStatus = () => {
        const { data } = this.state;
        data.is_active = !data.is_active;
        this.setState({ data });
    }

    render() {
        let { data } = this.state;
        const user = JSON.parse(getCookie("user"));
        return (
        <div className = "add-user-wrap">

        <div onClick = {() => this.props.toggleAdd("Edit")} className = "overlay"></div>
        <div id = "modal" className = "modal-popup">
            <div className = "pop-heading">
                Add Sku master
            </div>
            <div className = "content add-item-details">
                <div className="select-wrap">
               
                    <InputText required label='Part No' id='part_number' value={data.part_number} placeholder='Part No' changeHandler = {this.onChangeHandler}/>
                    
                </div>
                <div className="select-wrap">
                <InputText required label='Part Name' id='part_description' value={data.part_description} placeholder='Part Name' changeHandler = {this.onChangeHandler}/>
                </div>

                <div className="select-wrap">
                <InputText label='Warehouse ' id='warehouse' value={data.warehouse} placeholder='Warehouse ' changeHandler = {this.onChangeHandler}/>
                </div>

                <div className="select-wrap">
                <InputText label='Warehouse loc' id='warehouse_loc' value={data.warehouse_loc} placeholder='Warehouse Loc' changeHandler = {this.onChangeHandler}/>
                </div>
                
                <div className="select-wrap">
                <InputText required label='SPQ' id='spq' value={data.spq} placeholder='SPQ' changeHandler = {this.onChangeHandler}/>
                </div>
                
                <div className="select-wrap">
                    <div className="label">Packaging Type<span className="error-color">*</span></div>
                    <Select className = "item-select" 
                    value={PackagingType.find((val) => { return val.label == data.packing_type}) }
                    options={PackagingType}
                    onChange={(value)=>{
                        let {data} =this.state
                        data.packing_type=value.label;
                        data.packing_type_id=JSON.stringify(value.value);
                        this.setState({data})
                     }}
                />
                </div>
                <div className="select-wrap">
                    <div className="label">Load Type<span className="error-color">*</span></div>
                    <Select className = "item-select" 
                        value={LoadType.find((val) => { return val.label === data.load_type }) }
                        options={LoadType} 
                        onChange={this.handleChange.bind(this, "load_type")}
                    />
                </div>
                <InputText required label='Length' placeholder='Length' id='bin_length' value={data.bin_length} changeHandler = {this.onChangeHandler}/>
                <InputText required label='Width' placeholder='Width' id='bin_width' value={data.bin_width} changeHandler = {this.onChangeHandler}/>
                <InputText required label='Height' placeholder='Height' id='bin_height' value={data.bin_height} changeHandler = {this.onChangeHandler}/>
                <InputText label='Weight(optional)' placeholder="Weight" id='weight_per_pallete' value={data.weight_per_pallete} changeHandler = {this.onChangeHandler}/>
                <InputText label='Max Stack' placeholder='Max Stack' id='bin_stack_permit' value={data.bin_stack_permit} changeHandler = {this.onChangeHandler}/>
                <InputText label='Freq Of Dispatch' placeholder="Freq Of Dispatch" id='freq_of_dispatch' value={data.freq_of_dispatch} changeHandler = {this.onChangeHandler}/>
                
                <div className="select-wrap">
                <div className="label">Status</div>
                <span className="head active mt-30" style={{paddingRight: "30px"}}>
                    <CheckBox type={user.company_type} status={data.is_active} user={user} id={data.part_id} handleChange = {this.toggleStatus}/>
                </span>
                </div>

                {data.packing_type=="Bin" &&
                <div>
                <div className = "pop-heading">Pallete Details</div>
                <InputText label='Pallete Length' placeholder='Pallete length' id='pallete_length' value={data.pallete_length} changeHandler = {this.onChangeHandler}/>
                <InputText label='Pallete Width' placeholder='Pallete width' id='pallete_width' value={data.pallete_width} changeHandler = {this.onChangeHandler}/>
                <InputText label='Pallete Height' placeholder='Pallete height' id='pallete_height' value={data.pallete_height} changeHandler = {this.onChangeHandler}/>
                <InputText label='Weight per Pallete(optional)' placeholder="Weight per pallete" id='weight_per_pallete' value={data.weight_per_pallete} changeHandler = {this.onChangeHandler}/>
                <InputText label='No of bins on Pallete' placeholder="No of bins on pallete" id='no_of_bins_on_pallete' value={data.no_of_bins_on_pallete} changeHandler = {this.onChangeHandler}/>
                </div>
            }
                <div className = "pop-heading">Vendor Details</div>
              <div className="select-wrap">
              <InputText label=' Vendor Code' placeholder=" Vendor Code" id='vendor_code' value={data.vendor_code} changeHandler = {this.onChangeHandler}/>
                </div>
                <InputText label="Vendor Name " id='vendor_name' value={data.vendor_name} placeholder="Vendor Name " changeHandler = {this.onChangeHandler}/>
                <div className = "pop-heading"> Unloading Specifications</div>
               
                <InputText label=" Delivery Location " id='delivery_loc' value={data.delivery_loc} placeholder=" Delivery Location" changeHandler = {this.onChangeHandler}/>
                <InputText label="Plant Name" id='plant_name' value={data.plant_name} placeholder="Plant Name" changeHandler = {this.onChangeHandler}/>
                <InputText label="Unloading Dock" id='unloading_dock' value={data.unloading_dock} placeholder="Unloading Dock" changeHandler = {this.onChangeHandler}/>
                
                <div className="select-wrap">
                    <div className="label">Unloading MHE</div>
                    <Select className = "item-select" 
                        value={unloadingMhe.find((val) => { return val.label === data.unloading_mhe})}
                        options={unloadingMhe} 
                        onChange={this.handleChange.bind(this, "unloading_mhe")}
                    />
                </div>
               
            
            
            </div>
            <div className="submit-button">
                {this.state.errorMsg && <div className="error-color">{this.state.errorMsg}</div>}
                <div className="button-section"><Button value="Save"  click={this.onSave}/></div>
                <div className="button-section"><Button value="Cancel" click={() => this.props.toggleAdd("Edit")}/></div>
            </div>
        </div>
    </div>
    );
    }
}

export default AddItem;