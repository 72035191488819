import React, { Component } from 'react';
import "./input-text.less"
import key from '../../../../assets/images/key.svg';
import Cx from "classnames";

const InputText = (props) =>{
	return (
		<div className = "input-text-wrap">
			<div className = "label">
				{props.label}
				{props.required && <sup className={'error error-font'}>*</sup>}
			</div>
			<div className = "input-element">
				{
					props.readonly && <input readOnly 
											type  = {props.type || "text" }  
											id = {props.id} 
											placeholder = {props.placeholder} 
											className = {Cx("text-input",{
												'wt-100px-imp': props.className == 'wt-100px-imp'
											})}
											value = {props.value} 
											onChange = {(e) => props.changeHandler(e.target.value,props.id)} 
											autoComplete="nopes"
										/>
				}
				{
					!props.readonly && !props.placeholder.includes("Password") &&
					[<input type  = {props.type || "text"} 
							id = {props.id} 
							placeholder = {props.placeholder} 
							className = {Cx("text-input",{
								'wt-100px-imp': props.className == 'wt-100px-imp'
							})}
							value = {props.value} 
							onChange = {(e) => props.changeHandler(e.target.value,props.id,props.isValidate)} 
							autoComplete="nopes" 
							maxLength={props.length} {...props.additionalProps}
					/>,
						<span>{ props.iserror === props.id && [<br />, <span className={'error error-font'}>{props.errormessage}</span>] }</span>,
						
					]
				}
				{
					!props.readonly && props.placeholder.includes("Password") && [<input type  = "password" id = {props.id} placeholder = {props.placeholder} className = {`text-input ${props.isGenerate ? 'pwd-input' : ''}`} value = {props.value} onChange = {(e) => props.changeHandler(e.target.value,props.id)} autoComplete="nopes"{...props.additionalProps}/>, 
						props.isGenerate && <div className={'gen-btn'} style={{backgroundImage:`url(${key})`}} onClick={props.generate}></div>,
						<span>{ props.iserror === props.id && [<br />, <span className={'error error-font'}>{props.errormessage}</span>] }</span>
					]
				}
			</div>
		</div>
	)
}

export default InputText;