import ACTION from "../common/action_constant";


const loginReducer = (state = {broadcastMsgs:[]}, action) => {
    
    let tempState = Object.assign({}, state);
    if(action.type === ACTION.LOGIN.STORE_USER) {
        tempState = Object.assign({},state,action.data);
        return tempState;
    }
    else if(action.type === ACTION.LOGIN.STORE_SSO_CONFIG_DATA) {
        tempState.ssoConfigData = Object.assign({},state,action.ssoConfigData);
        return tempState;
    }
    else if(action.type === ACTION.BANNER_SETTING.STORE_BROADCAST_MSG) {
        tempState.broadcastMsgs = action.broadcastMsgs;
        return tempState;
    }
	return state;
};

export default loginReducer;
