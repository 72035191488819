import React, { Component, Fragment } from 'react';
import { SingleDatePicker } from 'react-dates';
import plus from '../../../../assets/images/new-add.svg';
import Delete from '../../../../assets/images/new-delete.svg';
import Cancel from '../../../../assets/images/model-cancel.svg';
import ItemDelete from '../../../../assets/images/delete-new.svg';
import Edit from '../../../../assets/images/edit-new.svg';
import Moment from 'moment';

class RateChart extends Component  {
    constructor(props) {
        super(props);
        this.state = {
            data: [{
                "product": props.materialName, 
                "from_date": null,
                "to_date": null, 
                "price": ''
            }],
            editData: {}
        }
    }

    onDeleteAddRate = (index) => {
        const { data } = this.state;
        data.splice(index, 1);
        this.setState({
            data
        });
    }

    onAddRate = () => {
        const { data } = this.state;
        data.push({
            "product": this.props.materialName, 
        });
        this.setState({ data });
    }

    onSave = () => {
        const { data } = this.state;
        this.props.onAddRateChart(data[0]);
    }

    render() {
        const { data, editData } = this.state;
        const { rateChartData } = this.props;

        return (
            <div className="add-user-wrap">
                <div className="overlay"></div>
                <div id="modal" className="modal-popup rateChart" style={{'background': '#E3E9FF'}} >
                    <div style={{'padding': '20px'}}>
                        {/* <div className="add-img" style = {{backgroundImage: `url(${Cancel})`, 'right': '25px', 'marginTop': '-34px', 'position': 'absolute'}} onClick={this.props.onCancel}></div> */}
                        <div className='label'>Rate Chart</div>
                        <div style={{'background': 'white', 'borderRadius': '5px', 'fontSize': '18px', 'fontWeight': '500', 'boxShadow': '0px 0px 4px 0px #00000040'}}>
                            <div style={{'padding': '10px 10px', 'borderBottom': '1px solid #86868A'}}>Add Rate</div>
                            <div>
                                <table width="98%" border={0}>
                                    <thead>
                                        <tr>
                                            <th width="450">SKU Name</th>
                                            <th width="250">From Date</th>
                                            <th width="250">To Date</th>
                                            <th width="100">Price (per MT)</th>
                                            {/* <th width="50"></th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(data || []).map((el, elIndex) => { 
                                        return(<tr>
                                                    <td>
                                                        <input type="text" style={{'width' : '100%', 'fontSize': '14px', 'padding': '5px'}} placeholder='SKU Name' value={el.product} readOnly={true}/></td>
                                                    <td>
                                                        <SingleDatePicker 
                                                            noBorder={true}
                                                            displayFormat={"DD MMM YYYY"}
                                                            hideKeyboardShortcutsPanel={true}
                                                            placeholder={"Date"}
                                                            numberOfMonths={1}
                                                            date={el.from_date && Moment(el.from_date) || null} 
                                                            onDateChange={(fromD) =>{
                                                                const { data } = this.state;
                                                                const date = fromD && fromD.format('YYYY-MM-DD HH:mm') || null
                                                                data[elIndex].from_date = date && new Date(date).getTime() || null;
                                                                this.setState({ data });
                                                            }}
                                                            focused={this.state[`focusedStart_from_${elIndex}`]} 
                                                            onFocusChange={({ focused }) => this.setState({ [`focusedStart_from_${elIndex}`]: focused })} 
                                                            isOutsideRange={()=> false}
                                                        />
                                                    </td>
                                                    <td>
                                                        <SingleDatePicker 
                                                            noBorder={true}
                                                            displayFormat={"DD MMM YYYY"}
                                                            hideKeyboardShortcutsPanel={true}
                                                            placeholder={"Date"}
                                                            numberOfMonths={1}
                                                            date={el.to_date && Moment(el.to_date) || null} 
                                                            onDateChange={(fromT) =>{
                                                                const { data } = this.state;
                                                                const date = fromT && fromT.format('YYYY-MM-DD HH:mm') || null
                                                                data[elIndex].to_date = date && new Date(date).getTime() || null;
                                                                this.setState({ data });
                                                            }}
                                                            focused={this.state[`focusedStart_to_${elIndex}`]} 
                                                            onFocusChange={({ focused }) => this.setState({ [`focusedStart_to_${elIndex}`]: focused })} 
                                                            isOutsideRange={()=> false}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input style={{'fontSize': '14px', 'padding': '5px'}} type="number" placeholder='Price' value={el.price} onChange={(e) => {
                                                            const { data } = this.state;
                                                            data[elIndex]['price'] = e.target.value && Number(e.target.value) || e.target.value;
                                                            this.setState({
                                                                data
                                                            });
                                                        }}/>
                                                    </td>
                                                    {/* <td>
                                                        <div style={{'display': 'flex'}}>
                                                            {data.length > 1 && <div className="add-img" style = {{backgroundImage: `url(${Delete})`}} onClick={() => this.onDeleteAddRate(elIndex)}></div>}
                                                            {((data.length - 1) == elIndex) && <div className="add-img" style = {{backgroundImage: `url(${plus})`}}  onClick={this.onAddRate}></div>}
                                                        </div>
                                                        
                                                    </td> */}
                                                </tr>)})}
                                    </tbody>
                                </table>
                                <div style={{"display": 'flex', 'justifyContent': 'end', 'padding': '10px 50px'}}>
                                    <div className='action-save' onClick={this.onSave}>Save</div>
                                </div>
                            </div>
                        </div>
                        <div style={{'marginTop': '10px'}} className='sku-table'>
                            <table cellPadding={1}>
                                <thead>
                                    <tr>
                                            <th width="400">SKU Name</th>
                                            <th width="200">From Date</th>
                                            <th width="200">To Date</th>
                                            <th width="150">Price (per MT)</th>
                                            <th width="50"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(rateChartData || []).map((rate, rateIndex) => {
                                    return(<tr>
                                            <td>{rate.product}</td>
                                            <td>
                                                {(editData.product_rate_id === rate.product_rate_id) ?
                                                    <SingleDatePicker 
                                                    noBorder={true}
                                                    displayFormat={"DD MMM YYYY"}
                                                    hideKeyboardShortcutsPanel={true}
                                                    placeholder={"Date"}
                                                    numberOfMonths={1}
                                                    date={editData.from_date && Moment(editData.from_date) || null} 
                                                    onDateChange={(fromD) =>{
                                                        const { editData } = this.state;
                                                        const date = fromD && fromD.format('YYYY-MM-DD HH:mm') || null
                                                        editData.from_date = date && new Date(date).getTime() || null;
                                                        this.setState({ editData });
                                                    }}
                                                    focused={this.state[`focusedStart_editfrom`]} 
                                                    onFocusChange={({ focused }) => this.setState({ [`focusedStart_editfrom`]: focused })} 
                                                    // isOutsideRange={(date) => date > new Date()}
                                                /> :
                                                <>
                                                    {rate.from_date && Moment(rate.from_date).format('DD/MM/YYYY')}
                                                </>}
                                            </td>
                                            <td>
                                            {(editData.product_rate_id === rate.product_rate_id) ?
                                                    <SingleDatePicker 
                                                    noBorder={true}
                                                    displayFormat={"DD MMM YYYY"}
                                                    hideKeyboardShortcutsPanel={true}
                                                    placeholder={"Date"}
                                                    numberOfMonths={1}
                                                    date={editData.to_date && Moment(editData.to_date) || null} 
                                                    onDateChange={(fromD) =>{
                                                        const { editData } = this.state;
                                                        const date = fromD && fromD.format('YYYY-MM-DD HH:mm') || null
                                                        editData.to_date = date && new Date(date).getTime() || null;
                                                        this.setState({ editData });
                                                    }}
                                                    focused={this.state[`focusedStart_editto`]} 
                                                    onFocusChange={({ focused }) => this.setState({ [`focusedStart_editto`]: focused })} 
                                                    // isOutsideRange={(date) => date > new Date()}
                                                /> :
                                                <>{rate.from_date && Moment(rate.to_date).format('DD/MM/YYYY')}</>}</td>
                                            <td>
                                                {(editData.product_rate_id === rate.product_rate_id) ?
                                                    <input style={{'fontSize': '14px', 'padding': '5px'}} type="number" placeholder='Price' value={editData.price} onChange={(e) => {
                                                        const { editData } = this.state;
                                                        editData['price'] = e.target.value && Number(e.target.value) || e.target.value;
                                                        this.setState({
                                                            editData
                                                        });
                                                    }}/> : <>{rate.price}</>
                                                }
                                            </td>
                                            <td>
                                                <div style={{'display': 'flex'}}>
                                                {(editData.product_rate_id === rate.product_rate_id) ? 
                                                <div className='theme-color curP' onClick={() => {
                                                    this.props.onUpdateRateChart(editData);
                                                    this.setState({
                                                        editData: {}
                                                    })
                                                }}>Update</div>:
                                                    <>
                                                    <div className="add-img" style = {{backgroundImage: `url(${ItemDelete})`}} onClick={() => this.props.onDeleteRate(rate)}></div>
                                                    <div className="add-img" style = {{backgroundImage: `url(${Edit})`}}  onClick={() => {
                                                        this.setState({
                                                            editData: {...rate}
                                                        })
                                                    }}>
                                                    </div>
                                                    </>}
                                                </div>
                                            </td>
                                    </tr>)})}
                                </tbody>
                            </table>

                        </div>
                        <div style={{'display': 'flex', 'justifyContent': 'space-around', 'marginTop': '20px'}}>
                            <div className='action-save' onClick={this.props.onCancel}>Cancel</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RateChart;