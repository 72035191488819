import React, { Fragment } from "react";
import Delete from "../../../assets/images/delete.svg";
import Edit from "../../../assets/images/edit.svg";
import Moment from "moment";
const BannerMessagesListTableSection = (props) => {
  const {
    bannerMessagesList,
    handleRowHover,
    handleRowLeave,
    hoveredRow,
    getModuleNames,
    editMessage,
    deleteMessage,
    gmtOffset
  } = props;

  return (
    <Fragment>
      <div className="flex items-center justify-between">
        <div className="table-container121">
          <table className="enlarging-table121">
            <thead>
              <tr>
                <th className="blue-header121">Status</th>
                <th className="blue-header121">Message</th>
                <th className="blue-header121">Start Time</th>
                <th className="blue-header121">End Time</th>
                <th className="blue-header121">Modules</th>
                <th className="blue-header121">Actions</th>
              </tr>
            </thead>
            <tbody>
              {(bannerMessagesList || []).map((row, index) => (
                <tr
                  key={index}
                  className={index === hoveredRow ? "enlarged-row121" : ""}
                  onMouseEnter={() => handleRowHover(index)}
                  onMouseLeave={handleRowLeave}
                >
                  <td>
                    <div
                      style={{
                        color: row.is_active === true ? "green" : "red",
                        fontWeight: "900",
                        fontSize: "20px",
                      }}
                    >
                      {row.is_active === true ? "Active" : "Inactive"}
                    </div>
                    <div style={{color:"red"}}>{row.end_time+gmtOffset < Moment() ? "(expired)" : ""}</div>
                  </td>
                  <td>{row.message}</td>
                  <td>
                    {Moment(row.start_time + gmtOffset).format("DD/MM/YYYY (HH:mm a)")}
                  </td>
                  <td>{Moment(row.end_time + gmtOffset).format("DD/MM/YYYY (HH:mm a)")}</td>
                  <td>{getModuleNames(row.module_ids)}</td>
                  <td>
                    <div
                      className={"action-icon121"}
                      style={{ backgroundImage: `url(${Edit})` }}
                      onClick={() => editMessage(row)}
                    />
                    <div
                      className={"action-icon121"}
                      style={{ backgroundImage: `url(${Delete})` }}
                      onClick={() => deleteMessage(row)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

export default BannerMessagesListTableSection;
