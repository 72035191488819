import React from "react";
import Select from "react-select";
import { DateRangePicker } from "react-dates";
import Moment from "moment";
import ACTIONS from "../../../common/action_constant";
import Download from "../../../../assets/images/download.svg";
import Delete from "../../../../assets/images/delete.svg";
import "./parentChildView.less";
import e from "cors";
import { connect } from "react-redux";
import Loader from "../../../common/components/loader/loader";
import { getUserCompanyName } from "../../../../utils/common";

import SearchText from "../../../common/components/search-text/search-text";
import MainComponent from "../../main/main";
import { Link } from "react-router-dom";

class ParentChildView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      company_id: "",
    };
  }

  componentDidMount() {
    this.getParentChildListing();
  }

  getParentChildListing = () => {
    const { dispatch } = this.props;
    const { selectedPlantName, selectedState, selectedLOCchild, selectedChildCode, selectedZoneChild, from_date, to_date, selectedLocCodeParent, selectedClusterName } = this.state;

    dispatch({
      type: ACTIONS.PARENTCHILD.GET_PARENT_CHILD_VIEW_LISTING,
      ...(selectedPlantName ? { plantName: selectedPlantName } : {}),
      ...(selectedState ? { state: selectedState } : {}),
      ...(selectedLOCchild ? { locationChild: selectedLOCchild } : {}),
      ...(selectedChildCode ? { locationCodeChild: selectedChildCode } : {}),
      ...(selectedZoneChild ? { zoneChild: selectedZoneChild } : {}),
      ...(selectedLocCodeParent ? { locationCodeParent: selectedLocCodeParent } : {}),
      ...(selectedClusterName ? { clusterName: selectedClusterName } : {}),
      ...(from_date ? { fromDate: from_date } : {}),
      ...(to_date ? { toDate: to_date } : {}),

      onSuccess: (data) => {

        let locChildCodeList = []
        let locCodeParentList = []
        let locChildList = []
        let plantList = []
        let stateList = []
        let zoneChildList = []
        let clusterNameList = []

        data && data.locChildCodeList && data.locChildCodeList.length > 0 && data.locChildCodeList.map((d) => {
          locChildCodeList.push({
            label: d,
            value: d
          })
        })

        data && data.locCodeParentList && data.locCodeParentList.length > 0 && data.locCodeParentList.map((d) => {
          locCodeParentList.push({
            label: d,
            value: d
          })
        })

        data && data.locChildList && data.locChildList.length > 0 && data.locChildList.map((d) => {
          locChildList.push({
            label: d,
            value: d
          })
        })

        data && data.plantList && data.plantList.length > 0 && data.plantList.map((d) => {
          plantList.push({
            label: d,
            value: d
          })
        })

        data && data.stateList && data.stateList.length > 0 && data.stateList.map((d) => {
          stateList.push({
            label: d,
            value: d
          })
        })

        data && data.zoneChildList && data.zoneChildList.length > 0 && data.zoneChildList.map((d) => {
          zoneChildList.push({
            label: d,
            value: d
          })
        })

        data && data.clusterNameList && data.clusterNameList.length > 0 && data.clusterNameList.map((d) => {
          clusterNameList.push({
            label: d,
            value: d
          })
        })

        this.setState({
          data: data.list ? data.list : [],
          locChildCodeList: locChildCodeList || [],
          locCodeParentList: locCodeParentList || [],
          locChildList: locChildList || [],
          plantList: plantList || [],
          stateList: stateList || [],
          zoneChildList: zoneChildList || [],
          clusterNameList: clusterNameList || []
        })
      }
    })
  }

  changeHandler = (val, id) => {
    this.setState({
      [id]: val
    });
  }

  handleSelect = (item, id) => {
    this.state[id] = item.label
    this.setState(this.state, () => {
      this.getParentChildListing()
    })
  }

  downloadTemplate = () => {
    const { dispatch } = this.props;

    dispatch({
      type: ACTIONS.PARENTCHILD.DOWNLOAD_TEMPLATE_IN_UPLOAD_LOCATION,
      data: 3,
      onSuccess: ((data) => {
        const element = document.createElement('a');
        element.setAttribute('href', data.link);
        element.setAttribute('download', true);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      })
    })
  }

  render() {
    const { data, locChildCodeList, locCodeParentList, locChildList, plantList, stateList, zoneChildList, clusterNameList } = this.state
    const { loader } = this.props;
    const customStyles = {
      control: (base) => ({
        ...base,
        borderColor: "#528aea",
      }),
    };
    return (
      <div className="master">
        {loader.isLoading && <Loader />}
        <MainComponent>
          <div className="PC">
            <div className="upload">
              <div className="border">
                <div className="flex-item flex-between">
                  <div className="flex-item">
                    <Link to='/locationMgmt' className="link">
                      <h4>
                        Back
                      </h4>
                    </Link>
                    <div>
                      <h2>
                        Parent Child View
                      </h2>
                    </div>
                  </div>
                  <div className="template" onClick={() => this.downloadTemplate()}>
                    <div
                      className={"download-icon"}
                      style={{
                        backgroundImage: `url(${Download})`,
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <div className="template-text">Download</div>
                  </div>
                </div>
                <div style={{ marginTop: '10px' }} className="flex-item">
                  <Select
                    styles={customStyles}
                    options={plantList || []}
                    onChange={(item) => this.handleSelect(item, 'selectedPlantName')}
                    className="plantName"
                    placeholder="Plant Name"
                    selectedItem={(option) => option.label === this.state.selectedPlantName}
                  />

                  <Select
                    styles={customStyles}
                    className="state"
                    options={stateList || []}
                    onChange={(item) => this.handleSelect(item, 'selectedState')}
                    placeholder="State"
                    selectedItem={(option) => option.label === this.state.selectedState}
                  />

                  <Select
                    styles={customStyles}
                    className="loc"
                    options={locChildList || []}
                    onChange={(item) => this.handleSelect(item, 'selectedLOCchild')}
                    placeholder="LOC Child"
                    selectedItem={(option) => option.label === this.state.selectedLOCchild}
                  />

                  <Select
                    styles={customStyles}
                    className="locCode"
                    options={locChildCodeList || []}
                    onChange={(item) => this.handleSelect(item, 'selectedChildCode')}
                    placeholder="LOC Code Child"
                    selectedItem={(option) => option.label === this.state.selectedChildCode}
                  />

                  <Select
                    styles={customStyles}
                    className="zone"
                    options={zoneChildList || []}
                    onChange={(item) => this.handleSelect(item, 'selectedZoneChild')}
                    placeholder="Zone Child"
                    selectedItem={(option) => option.label === this.state.selectedZoneChild}
                  />

                  <Select
                    styles={customStyles}
                    className="locCodeParent"
                    options={locCodeParentList || []}
                    onChange={(item) => this.handleSelect(item, 'selectedLocCodeParent')}
                    placeholder="LOC Code Parent"
                    selectedItem={(option) => option.label === this.state.selectedLocCodeParent}
                  />

                  <Select
                    styles={customStyles}
                    className="clusterName"
                    options={clusterNameList || []}
                    onChange={(item) => this.handleSelect(item, 'selectedClusterName')}
                    placeholder="Cluster Name"
                    selectedItem={(option) => option.label === this.state.selectedClusterName}
                  />

                  <div className="flex-item order-daterange">
                    <DateRangePicker
                      startDate={this.state.from_date ? Moment(+this.state.from_date) : null}
                      startDateId="startDate"
                      endDate={this.state.to_date ? Moment(+this.state.to_date) : null}
                      endDateId="endDate"
                      startDatePlaceholderText="00/00/0000"
                      endDatePlaceholderText="00/00/0000"
                      onDatesChange={({ startDate, endDate }) => {
                        let { from_date, to_date } = this.state;
                        from_date = startDate ? startDate.valueOf() : from_date;
                        to_date = endDate ? endDate.valueOf() : to_date;
                        this.setState({ from_date, to_date }, () => {
                          this.getParentChildListing()
                        });
                      }}
                      focusedInput={this.state.focusedInput}
                      onFocusChange={focusedInput => this.setState({ focusedInput })}
                      displayFormat={'DD/MM/YYYY'}
                      noBorder={true}
                      minimumNights={0}
                      isOutsideRange={() => false}
                    />
                  </div>
                </div>
              </div>

              <div className="table">
                <table className="table-view">
                  <thead>
                    <tr className="table-tr">
                      <th className="tCenter" width="150px">
                        Plant Name
                      </th>
                      <th className="tCenter" width="150px">
                        Plant State
                      </th>
                      <th className="tCenter" width="100px">
                        Plant Code
                      </th>
                      <th className="tCenter" width="100px">
                        Customer Code
                      </th>
                      <th className="tCenter" width="200px">
                        Customer Name
                      </th>
                      <th className="tCenter" width="100px">
                        State
                      </th>
                      <th className="tCenter" width="100px">
                        LOC Child
                      </th>
                      <th className="tCenter" width="100px">
                        LOC Code Child
                      </th>
                      <th
                        className="tCenter"
                        width="100px"
                      >
                        Zone Child
                      </th>
                      <th className="tCenter" width="100px">
                        LOC Code Parent
                      </th>
                      <th className="tCenter" width="100px">
                        Cluster Name
                      </th>
                      <th className="tCenter" width="200px">
                        LOC Cluster
                      </th>
                      <th
                        className="tCenter"
                        width="100px"
                      >
                        Date
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {(data || []).map((d) => {
                      return (
                        <tr className="tr-1">
                          <td>{d.plant_name || '-'}</td>
                          <td>{d.plant_state || '-'}</td>
                          <td>{d.plant_code || '-'}</td>
                          <td>{d.customer_code || '-'}</td>
                          <td>{d.customer_name || '-'}</td>
                          <td>{d.state || '-'}</td>
                          <td>{d.location_child || '-'}</td>
                          <td>{d.location_code_child || '-'}</td>
                          <td>{d.zone_child || '-'}</td>
                          <td>{d.location_code_parent || '-'}</td>
                          <td>{d.cluster_code || '-'}</td>
                          <td>
                            {
                              d.location_cluster_name && d.location_cluster_name.length > 0 ?
                                d.location_cluster_name.map((name, idx) => {
                                  return (
                                    <p>
                                      {name}{`${idx === d.location_cluster_name.length - 1 ? '' : ','}`}
                                    </p>
                                  )
                                })
                                :
                                <>{'-'}</>
                            }
                          </td>
                          <td>{Moment(d.location_date).format('DD/MM/YYYY') || '-'}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </MainComponent>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loader: state.loader,
  };
};

export default connect(mapStateToProps)(ParentChildView);
