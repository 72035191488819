import React, { Component , Fragment } from 'react';
import Select from 'react-select';
import InputText from '../../../common/components/input-text/input-text';
import {decimalNumber} from '../../../../utils/validate'
import Button from '../../button-classic/button-classic';


class RHCCharges extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                freight_bill_percentage: props.rhcData ? props.rhcData.freight_bill_percentage : '',
                transporters: props.rhcData ? props.rhcData.transporters.map(val => { return { label: val.company_name, value: val.id }}) : [],
                min_rhc: props.rhcData ? props.rhcData.min_rhc : ''
            }
        }
    }

    onChangeamountHandler = (value, field) => {
       if (decimalNumber(value) || value == '') {
        const { data } = this.state;
        data[field] = value; 
        this.setState({ data }); }
    }

    onSave = () => {
        const { data } = this.state;
        data.transporters = data.transporters.length > 0 ? data.transporters.map(val => { return { company_name : val.label, id : val.value }}) : data.transporters;
        this.props.addPayables(data, 20);
        this.props.toggleAdd(20);
    }

    onUpdate = () => {
        const { data } = this.state;
        const { rhcData } = this.props;
        data.transporters = data.transporters.length > 0 ? data.transporters.map(val => { return { company_name : val.label, id : val.value }}) : data.transporters;
        data.payable_id = rhcData.payable_id;
        data.load_type = rhcData.load_type;
        data.mode_type = rhcData.mode_type;
        data.type = rhcData.type;
        this.props.updatePayables(data, 20);
        this.props.toggleAdd(20);
    }

    onTransporterSearch = (value) => {
        if(value) {
            this.props.getTransporterList(value);
        }
    }
   validatePercentage = (val) => {
      return val.toString().match(/^\d{1,2}(\.\d{1,3})?$/)
   }
   validateCharge = (val) => {
      return val.toString().match(/^\d{1,8}(\.\d{1,3})?$/)
   }
    
   validData = ({ data } = this.state) =>
   {
     return (data.transporters.length >= 1 && this.validatePercentage(data.freight_bill_percentage) && this.validateCharge(data.min_rhc));
   }

    render() {
        const { data } = this.state;
        const { transporterList, rhcData} = this.props;
       const disable = this.validData();

        return (
            <div className = "add-company-wrap add-provider-root add-row">
                <div onClick = {this.props.toggleAdd} className = "overlay">
                    <div className = "modal-popup" onClick={(e) => { e.stopPropagation() }}>
                        <div className = "heading">
                            { `${rhcData ? 'Edit' : 'Add'} RHC Charges`}
                        </div>
                        <form className = "form-section" autoComplete = "nopes">
                            <div className="select-wrap">
                                <div className="label">Transporter1</div>
                                <Select 
                                    isMulti
                                    className={'select'} 
                                    value={data.transporters }
                                    options={transporterList && transporterList.length > 0 ? transporterList.map(trans => {
                                        return { label : trans.company_name, value: trans.branch_id }
                                    }) : []}
                                    onChange= {(options) => {
                                        const { data } = this.state;
                                        data.transporters = options;
                                        this.setState({ data });
                                    }}
                                    onInputChange={this.onTransporterSearch}
                                    
                                />
                            </div>
                            <InputText id='freight_bill_percentage' value={data.freight_bill_percentage} placeholder={'Percentage of Invoice Amount'} label={'Percentage of Invoice Amount'} changeHandler={this.onChangeamountHandler} type={'number'}/>
                            <InputText id='min_rhc' value={data.min_rhc} placeholder={'Minimum RHC Amount'} label={'Minimum RHC Amount'} changeHandler={this.onChangeamountHandler} type={'number'}/>
                        </form>
                        <div className = "submit-section submit-area">
                       {
                          <div className="button-section">
                             {!this.props.rhcData && <Button className="add-button" click={this.onSave} disabled={!disable} value="Save" />}
                             {this.props.rhcData && <Button className="add-button" click={this.onUpdate} disabled={!disable} value="Update" />}
                          </div> 
                           
                            }
                            <div className ="button-section" >
                                <div className ="add-button" onClick={this.props.toggleAdd}>Close</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RHCCharges;