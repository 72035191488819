import React, { Component } from 'react';
import "./discountStructure.less";
import MainComponent from "../../home/main/main";
import Button from "../../home/button-classic/button-classic";
import InputText from "../../common/components/input-text/input-text";

class DiscountStructure extends Component {
    constructor(props){
        super(props);
        this.state = { }
    }
    
    render() {
        return (
            <div className="discount-wrap">
                <div className = "wrapper">
                    <MainComponent>
                        <div className="sub-heading-section"> 
                            <div className="sub-heading"> Discount Structure </div>
                        </div>
                    </MainComponent>
                </div>
            </div>
        );
    }
}

export default DiscountStructure;