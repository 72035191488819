import React, { Component} from 'react';
import {connect} from 'react-redux';
import "../route_import/route_import.less";
import Select from 'react-select';

class ModeImport extends Component {
	constructor(props){
		super(props);
		this.state = {
			selectedOption: { value: '1', label: 'All' }
		}
    }
    
    generateModeExcel = () => {
        let data = this.state.selectedOption;
    }

	componentDidMount(){
		const { dispatch } = this.props;
	}
	render() {   
		const options = [
			{ value: '1', label: 'All' },
			{ value: '2', label: 'Seekers' },
			{ value: '3', label: 'Providers' }
		  ];
		return (
			<div className = "route-import-root">
                <div className = "container-1">
                    <div className = "banner">Generate Mode Import Excel</div>
                    <div className = "header">Transport Service Type</div>
                    <div className = "sub-container">
                        <Select className = "select" options={options} />
                        <div className = "generate-button" onClick = {this.generateModeExcel}>Generate Excel</div>
                    </div>
                </div>
                <div className = "container-1">
                    <div className = "banner">Upload Mode Import Excel</div>
                    <div className = "header">Company</div>
                    <div className = "sub-container">
                        <Select className = "select" value={this.state.selectedOption} onChange={this.handleChange} options={options} />
                    </div>
                    <div className = "header">Upload SAP Route Excel</div>
                    <div className = "sub-container">
                        <input type = "file" autoComplete="off"/>
                        <div className = "generate-button">Upload</div>
                    </div>
                </div>
			</div>
		);
	}
}

  
export default ModeImport;