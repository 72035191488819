import React, { Component, Fragment } from 'react';
import "./otherCharges.less";

import Button from "../../home/button-classic/button-classic";
import InputText from "../../common/components/input-text/input-text";

import Select from "react-select";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Delete from '../../../assets/images/delete.svg';
import { PANTALOONS_COMPANY_IDS, getUserCompanyId } from '../../../utils/common';

const DATES = [ {label: 'On', value: 'on'},
                {label: 'After', value: 'After'},
                {label: 'Before', value: 'Before'}
                ]

const LOADS = [{label: 'LCL', value: 1}, {label: 'FTL', value: 2}, {label: 'Courier', value: 3}, {label:'Container', value: 4}];
const BOUND_TYPES = [
    {
        label: 'Inbound',
        value: 1
    },
    {
        label: 'Outbound',
        value: 2
    }
];

class MyDatePicker extends Component {
  render() {
    return (
      <div className="data-picker">
        <div className="label">{this.props.label}</div>
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={this.props.startDate}
          onChange={this.props.handleChange.bind(this, this.props.id)}
        />
      </div>
    );
  }
}

class AddNewOtherCharges extends Component {

	constructor(props){
        super(props);
        this.state = {
            isError: '',
            showAddSlab: true,
        }
    }

    isDisabled = () => {
        const { newDemurrage } = this.props;
        
        if (!PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && newDemurrage.load_type && newDemurrage.mode_type && newDemurrage.movement_type && newDemurrage.distribution_type) {
            return false;
        }
        if (PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && newDemurrage.load_type && newDemurrage.mode_type && newDemurrage.distribution_type) {
            return false;
        }
        return true;
    }

	render() {
		const { newDemurrage, modesList, vendorsList, allDropdownTypes } = this.props;
        const modifiedTransporterList = allDropdownTypes.distribution_type&&
        allDropdownTypes.distribution_type.map(el=>{
            const newObj={...el} 
            newObj.label= el.dropdown_name; 
            newObj.value= el.dropdown_value; 
            newObj.name= el.dropdown_name; 
            newObj.id= el.dropdown_id;
            return newObj
        })
        const slabAdded = newDemurrage;

        const mov_tye_val = (allDropdownTypes.movement_type || []).filter(el=> { return el.dropdown_value===slabAdded.movement_type}).length>0 ? (allDropdownTypes.movement_type || []).filter(el=> {return el.dropdown_value===slabAdded.movement_type})[0] : {};
        const charge_val = (allDropdownTypes.charges || []).filter(el=> { return el.dropdown_value===slabAdded.charge_id}).length>0 ? (allDropdownTypes.charges || []).filter(el=> {return el.dropdown_value===slabAdded.charge_id})[0] : {};
        const charge_uom_val = (allDropdownTypes.charges_uom || []).filter(el=> { return el.dropdown_value===slabAdded.charge_uom}).length>0 ? (allDropdownTypes.charges_uom || []).filter(el=> {return el.dropdown_value===slabAdded.charge_uom})[0] : {};
        const distribution_type_val = (allDropdownTypes.distribution_type).filter(el => {return el.dropdown_value === slabAdded.distribution_type})         

        return(
			<div className="add-user-wrap">
                <div className="overlay"></div>
                <div id="modal" className="modal-popup">
                    <div className="pop-heading"> Add Demurrage </div>
                    <div className="content">

                        <div className="list-section table-header divTableRow">
                            <div className="divTableCell bordered"> Load type </div>
                            <div className="divTableCell bordered"> Service mode </div>
                            <div className="divTableCell bordered"> Vendors </div>
{!PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && <div className="divTableCell bordered"> Movement Type </div>}
{PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) &&<div className="divTableCell bordered"> Bound Type </div>}
                            <div className="divTableCell bordered"> Distribution Type </div>
                            <div className="divTableCell bordered"> Charges </div>
                        </div>
                        <div className="list-section content divTableRow">
                        {true ? <div className="divTableCell"> 
                            <Select
                                className="select"
                                options={LOADS}
                                value={LOADS.filter(el => el.value===slabAdded.load_type)[0]}
                                onChange={(value) => {  
                                    this.props.onChangeInput(value.value, null, 'load_type')
                                }}
                            />
                        </div>:<div className="divTableCell"> </div>}
                        {!PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) &&
                            <div className="divTableCell"> 
                                <Select isMulti
                                    className="select"
                                    options={modesList || []}
                                    value={(modesList || []).filter(el => {return (slabAdded.mode_type || []).includes(el.value) })}
                                    onChange={(value) => {  
                                        this.props.onChangeInput(value, null, 'mode_type_obj')
                                    }}
                                />
                            </div>}
                        {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) &&
                            <div className="divTableCell"> 
                                <Select
                                    className="select"
                                    options={modesList}
                                    value={modesList.filter(el => el.value===slabAdded.mode_type)}
                                    onChange={(value) => {  
                                        this.props.onChangeInput(value, null, 'mode_type_obj')
                                    }}
                                />
                            </div>}
                        
                        {!PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && <div className="divTableCell"> 
                            <Select isMulti
                                className="select"
                                options={vendorsList || []}
                                value={(vendorsList || []).filter(el => {return (slabAdded.transporter || []).includes(el.value) })}
                                onChange={(value) => {  
                                    this.props.onChangeInput(value, null, 'vendor_obj')
                                }}
                            />
                        </div>}
                        {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && <div className="divTableCell"> 
                            <Select 
                                className="select"
                                options={vendorsList}
                                value={vendorsList.filter(el => el.value=== slabAdded.transporter)}
                                onChange={(value) => {  
                                    this.props.onChangeInput(value, null, 'vendor_obj')
                                }}
                            />
                        </div>}
                        {!PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) &&
                         <div className="divTableCell">
                            <Select
                                className="select"
                                options={(allDropdownTypes.movement_type || []).map((el) => { return { label: el.dropdown_name, value: el.dropdown_value, name: el.dropdown_name } })}
                                value={Object.keys(mov_tye_val).length>0 ? {...mov_tye_val, label: mov_tye_val.dropdown_name, value: mov_tye_val.dropdown_value, name: mov_tye_val.dropdown_name} : null}
                                onChange={(value) => {  
                                    this.props.onChangeInput(value.value, null, 'movement_type')
                                }}
                            />
                        </div>}
                        {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) && 
                            <Select
                                className="select"
                                options={BOUND_TYPES}
                                value={BOUND_TYPES.filter(el => el.value===slabAdded.bound_type)[0]}
                                onChange={(value) => {  
                                    this.props.onChangeInput(value.value, null, 'bound_type')
                                }}
                            />}

                        {!PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) &&
                        <div className="divTableCell">
                            <Select
                                isMulti
                                className="select"
                                options={modifiedTransporterList || []}
                                value={(modifiedTransporterList || []).filter(el => {return (slabAdded.distribution_type || []).includes(el.value) })}
                                onChange={(value) => {
                                    this.props.onChangeInput(value, null, 'distribution_type')
                                }}
                            />
                        </div>}
                        {PANTALOONS_COMPANY_IDS.includes(getUserCompanyId()) &&
                        <div className="divTableCell">
                            <Select
                                className="select"
                                options={modifiedTransporterList}
                                value={modifiedTransporterList.filter(el => el.value===slabAdded.distribution_type)}
                                onChange={(value) => {
                                    this.props.onChangeInput(value, null, 'distribution_type')
                                }}
                            />
                        </div>}
                        {true ? <div className="divTableCell">
                            <Select
                                className="select"
                                options={(allDropdownTypes.charges || []).map((el) => { return { label: el.dropdown_name, value: el.dropdown_value, name: el.dropdown_name } })}
                                value={Object.keys(charge_val).length > 0 ? {...charge_val, label: charge_val.dropdown_name, name: charge_val.name, value: charge_val.dropdown_value} : null}
                                onChange={(value) => {
                                    this.props.onChangeInput(value, null, 'charges')
                                }}
                            />
                            <Select
                                className="select"
                                options={(allDropdownTypes.charges_uom || []).map((el) => { return { label: el.dropdown_name, value: el.dropdown_value, name: el.dropdown_name } })}
                                value={Object.keys(charge_uom_val).length > 0 ? {...charge_uom_val, label: charge_uom_val.dropdown_name, name: charge_uom_val.name, value: charge_uom_val.dropdown_value} : null}
                                onChange={(value) => {
                                    this.props.onChangeInput(value.value, null, 'charge_uom')
                                }}
                            />
                        </div>:<div className="divTableCell"></div>}                   
                        </div>
                        
                        <h3> Charges </h3>

                        <div className="list-section table-header divTableRow">
                            <div className="divTableCell bordered"> Levy Logic </div>
                            <div className="divTableCell bordered"> Levy UOM </div>
                            <div className="divTableCell bordered"> Min. Value </div>
                            <div className="divTableCell bordered"> Min. UOM </div>
                            <div className="divTableCell bordered"> Levy Logic Values </div>
                        </div>
                        {(slabAdded.sub_charges || []).map((slab, index) => {
                            const levy_logic_val = (allDropdownTypes.levy_logic || []).filter(el=> { return el.dropdown_value===slab.levy_logic}).length>0 ? (allDropdownTypes.levy_logic || []).filter(el=> {return el.dropdown_value===slab.levy_logic})[0] : {};
                            const levy_uom_val = (allDropdownTypes.levy_uom || []).filter(el=> {return el.dropdown_value===slab.levy_uom}).length>0 ?  (allDropdownTypes.levy_uom || []).filter(el=> {return el.dropdown_value===slab.levy_uom})[0] : {};
                            const min_uom_val = (allDropdownTypes.min_uom || []).filter(el=> {return el.dropdown_value===slab.minimum_uom}).length>0 ?  (allDropdownTypes.min_uom || []).filter(el=> {return el.dropdown_value===slab.minimum_uom})[0] : {};
                            return (
                                <div key={index} className="list-section content divTableRow">
                                    <div className="divTableCell slabRange"> 
                                        {true ? <div className="divTableCell">
                                            <Select
                                                className="select"
                                                options={(allDropdownTypes.levy_logic || []).map((el) => { return { label: el.dropdown_name, value: el.dropdown_value, name: el.dropdown_name } })}
                                                value={Object.keys(levy_logic_val).length>0 ? { ...levy_logic_val, label: levy_logic_val.dropdown_name, name: levy_logic_val.dropdown_name, value: levy_logic_val.dropdown_value  } : null}
                                                onChange={(value) => {  
                                                    this.props.onChangeInput(value.value, null, 'levy_logic', index)
                                                }}
                                            />
                                        </div>:<div className="divTableCell"></div>}
                                    </div>
                                    <div className="divTableCell slabRange"> 
                                        {true ? <div className="divTableCell">
                                            <Select
                                                className="select"
                                                options={(allDropdownTypes.levy_uom || []).map((el) => { return { label: el.dropdown_name, value: el.dropdown_value, name: el.dropdown_name } })}
                                                value={Object.keys(levy_uom_val).length>0 ? { ...levy_uom_val, label: levy_uom_val.dropdown_name, name: levy_uom_val.dropdown_name, value: levy_uom_val.dropdown_value  } : null}
                                                onChange={(value) => {  
                                                    this.props.onChangeInput(value.value, null, 'levy_uom', index)
                                                }}
                                            />
                                        </div>:<div className="divTableCell"></div>}
                                    </div>
                                    <div className="divTableCell slabRange">
                                        <InputText placeholder="Min. value" changeHandler={(value, id) => this.props.onChangeInput(value, id, 'minimum_value', index)} value={slab.minimum_value} />
                                    </div>
                                    <div className="divTableCell slabRange"> 
                                        {true ? <div className="divTableCell">
                                            <Select
                                                className="select"
                                                options={(allDropdownTypes.min_uom || []).map((el) => { return { label: el.dropdown_name, value: el.dropdown_value, name: el.dropdown_name } })}
                                                value={Object.keys(min_uom_val).length>0 ? { ...min_uom_val, label: min_uom_val.dropdown_name, name: min_uom_val.dropdown_name, value: min_uom_val.dropdown_value  } : null}
                                                onChange={(value) => {  
                                                    this.props.onChangeInput(value.value, null, 'minimum_uom', index)
                                                }}
                                            />
                                        </div>:<div className="divTableCell"></div>}
                                    </div>
                                    <div className="divTableCell slabRange"> 
                                        {
                                            Object.keys(slab.levy_logic_values || {}).map((levyVal, objIndex) => {
                                                const updatedLevyVal = levyVal.replace(/NUMBER_.*?_CODE/, "");
                                                return <Fragment>
                                                    <InputText placeholder="Type" 
                                                        changeHandler={(value, id) => this.props.onChangeInput(value, id, 'levy_logic_key', index, objIndex)} 
                                                        value={updatedLevyVal} 
                                                    /> 
                                                    : 
                                                    <InputText placeholder="Value" 
                                                        changeHandler={(value, id) => this.props.onChangeInput(value, id, 'levy_logic_values', index, objIndex, levyVal)} 
                                                        value={slab.levy_logic_values[levyVal]} 
                                                    />
                                                    <div className={"delete-icon levyDelete"} style={{ backgroundImage: `url(${Delete})`}} onClick={() => this.props.onDeleteLevy(index, levyVal)}></div>
                                                </Fragment>
                                            })
                                        }
                                        <button className="add-button" onClick={() => this.props.onChangeInput(null, null, 'add_levy', index)}> Add Levy </button>
                                    </div>
                                    <div className={"delete-icon"} style={{ backgroundImage: `url(${Delete})`}} onClick={() => this.props.onDeleteCharges(index)}></div>
                                </div>
                            );
                        })}
                        
                        <div className="submit-section mt-40">
                            {
                                this.state.showAddSlab && <button className="add-button" onClick={this.props.addNewSlabs}>Add Charge</button>
                            }
                            <button className="add-button discard" onClick={this.props.cancelNewSlab}>Cancel</button>
                            <button 
                                className="add-button" 
                                // disabled={this.isDisabled()} 
                                onClick={() => {
                                    if (this.isDisabled()) {
                                        this.setState({ isError: 'Please fill all the fields to proceed.' });
                                    } else {
                                        this.setState({ isError: '' });
                                        this.props.handleSave();
                                    }
                                }}
                            >Save
                            </button>
                        </div>
                        
                        {this.state.isError.length > 0 && <div className="error">{this.state.isError}</div>}
                    </div>
                </div>
            </div>
		)
	}
}

export default AddNewOtherCharges;