import React, { Component, Fragment } from "react";
import UpArrow from "../../../assets/images/incr.svg";
import DownArrow from "../../../assets/images/decr.svg";
import "./dph-amendment-details.less";
import Edit from "../../../assets/images/edit.svg";
import Save from "../../../assets/images/save.svg";
import Cancel from "../../../assets/images/cancel.svg";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const DphAmendmentDetails = (props) => {
  const diff = (a, b) => (a > b ? a - b : b - a);

  console.log("props", props);

  const generateButtonRender = (data) => {
    const C1 = data.awaiting_for_approval;

    if (C1 === true) {
      return true;
    }
  };
  const approvedButtonRender = (data) => {
    const C1 = data.awaiting_for_approval;

    if (C1 === true) {
      return true;
    }
  };

  // const checkGenerateRate = (data) => {
  //   return data.lane.some((lane) => {
  //     return lane.monthly_rate.some((monthlyRate) => {
  //       return "generate_rate" in monthlyRate;
  //     });
  //   });
  // };

  // console.log("checkGenerateRate",checkGenerateRate(props.rowData))
  // console.log("row Data",props.rowData)
  return (
    <div className="add-user-wrap">
      <div className="overlay"></div>
      <div id="modal" className="modal-popup">
        <div className="heading">
          {" "}
          {props.rowData.vendor_name}
          <div className="vehicleAttendenceHeaderDate">
            <input
              type="month"
              className="dateInput"
              onChange={props.handleDateChange}
              value={props.formatDateForInput()}
              placeholder="select date"
            />
          </div>
          {/* <button
            className={"close-button"}
            onClick={(value, id) => props.handleAmendSubmit(props.lanesData,props.rowData)}
          >
            Update Amend
          </button> */}
          {props.isAbfrl && (
            // approvedButtonRender(props.rowData) &&
            // checkGenerateRate(props.rowData) &&
            <button
              className={"close-button"}
              onClick={() => props.onClickAmendSave(props.lanesData,props.rowData)}
            >
              Save
            </button>
          )}
          {props.isAbfrl && (
            // !checkGenerateRate(props.rowData) &&
            // generateButtonRender(props.rowData) &&
            <button
              className={"close-button"}
              onClick={() => props.generateRates()}
            >
              Generate Rate
            </button>
          )}
          {props.isAbfrl && (
            // approvedButtonRender(props.rowData) &&
            // checkGenerateRate(props.rowData) &&
            <button
              className={"close-button"}
              onClick={() => props.approveRates()}
            >
              Approve
            </button>
          )}
          {/* {!props.isAbfrl && props.rowData.awaiting_for_approval && (
            <button
              className={"close-button"}
              onClick={(value, id) => props.approveRates(value, id)}
            >
              Approve
            </button>
          )} */}
          <button
            className={"close-button"}
            onClick={(value, id) => props.downloadVendorLanes()}
          >
            Download
          </button>
          <button
            className={"close-button"}
            onClick={(value, id) => props.closePopup()}
          >
            Close
          </button>
        </div>
        <div className="zone-average">
          <span className="subheading">
            {" "}
            Type: {props.rowData.rate_type}, 
            Diff: {props.rowData.rate_diff},
            Zone: {props.rowData.base_region},
          </span>
          <span className="subheading">
            {" "}
            {/* Type: {props.rowData.rate_type},  */}
            Base Rate:{" "}
            {props.isEditing === "my_rate" ? (
              <input
                type="number"
                value={props.editData.my_rate}
                onChange={(e) => props.handleChange("my_rate", e.target.value)}
              />
            ) : (
              props.rowData.my_rate
            )}
            {props.isEditing === "my_rate" ? (
              <Fragment>
                <div
                  className={"action-icon"}
                  style={{ backgroundImage: `url(${Save})` }}
                  onClick={(value, id) =>
                    props.handleAmendSubmit(
                      props.lanesData,
                      props.rowData,
                      "my_rate"
                    )
                  }
                />
                <div
                  className={"action-icon"}
                  style={{ backgroundImage: `url(${Cancel})` }}
                  onClick={() => props.toggleEdit("my_rate")}
                />
              </Fragment>
            ) : (
              <div className="divTableCell">
                <div
                  className={"action-icon"}
                  style={{ backgroundImage: `url(${Edit})` }}
                  onClick={() => props.toggleEdit("my_rate")}
                />
              </div>
            )}
            {/* Diff: {props.rowData.rate_diff}{" "} */}
          </span>

          {!props.isAbfrl && (
            <div className="subheading">
              {" "}
              AR:- Actual Rate, DR:- Discount Rate, PR:- Proforma Rate
            </div>
          )}
          {props.isAbfrl && (
            <div className="subheading">
              {" "}
              AR:- Actual Rate, DR:- Discount Rate, PR:- Proforma Rate , GR:-
              Generate Rate
            </div>
          )}
        </div>
        <div className="content p-t-0">
          <div className="divTable company-list-wrap">
            <div className="list-section table-header divTableRow">
              <div className="divTableCell"> FROM CITY </div>
              <div className="divTableCell"> TO CITY </div>
              <div className="divTableCell"> TO STATE </div>
              <div className="divTableCell"> TO REGION </div>
              <div className="divTableCell"> CONTRACT LANE RATE </div>
              {/* <div className="divTableCell"> NEW LANE RATE </div> */}
              {/* <div className="divTableCell"> FLUCTUATIONS </div> */}
              {/* {(props.months || []).map((dt, index) => {
                const monthNumber = new Date(dt);
                const mm = new Date(monthNumber).getMonth();
                const yyyy = new Date(monthNumber).getFullYear();
                return (
                  <div className="divTableCell" key={`dt-${index + 1}`}>
                    {" "}
                    {monthNames[mm]} - {yyyy}{" "}
                  </div>
                );
              })} */}
            </div>
            <div className="divTableBody">
              {Array.isArray(props.lanesData) && props.lanesData.length > 0 ? (
                props.lanesData.map((row, index) => {
                  const oldRate =
                    (row.rate || 0) -
                    parseFloat(row.last_month_approved_rate_diff || 0);
                  return (
                    <div key={index} className="list-section divTableRow">
                      <div className="divTableCell"> {row.from} </div>
                      <div className="divTableCell"> {row.to} </div>
                      <div className="divTableCell"> {row.tostate} </div>
                      <div className="divTableCell"> {row.toregion} </div>
                      <div className="divTableCell">
                        <div style={{ display: "flex", gap: "5px" }}>
                          {props.isEditing === `old_freight_rate_${index}` ? (
                            <input
                              type="number"
                              value={
                                props.editData.lanes[index] &&
                                props.editData.lanes[index].old_freight_rate
                              }
                              onChange={(e) =>
                                props.handleChange(
                                  "old_freight_rate",
                                  e.target.value,
                                  index
                                )
                              }
                            />
                          ) : (
                            <div>
                              {row.old_freight_rate ||
                                parseFloat(oldRate).toFixed(2)}
                            </div>
                          )}
                          {props.isEditing === `old_freight_rate_${index}` ? (
                            <div>
                              <div
                                className={"action-icon"}
                                style={{ backgroundImage: `url(${Save})` }}
                                // onClick={() => props.handleSubmitForm()}
                                onClick={(value, id) =>
                                  props.handleAmendSubmit(
                                    props.lanesData,
                                    props.rowData,
                                    `old_freight_rate_${index}`
                                  )
                                }
                              />
                              <div
                                className={"action-icon"}
                                style={{ backgroundImage: `url(${Cancel})` }}
                                onClick={() =>
                                  props.toggleEdit(`old_freight_rate_${index}`)
                                }
                              />
                            </div>
                          ) : (
                            <div>
                              <div
                                className={"action-icon"}
                                style={{ backgroundImage: `url(${Edit})` }}
                                onClick={() =>
                                  props.toggleEdit(`old_freight_rate_${index}`)
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {/* <div className="divTableCell">
                        <div style={{ display: "flex", gap: "5px" }}>
                          {props.isEditing === `new_freight_rate_${index}` ? (
                            <input
                              type="number"
                              value={
                                props.editData.lanes[index] &&
                                props.editData.lanes[index].new_freight_rate
                              }
                              onChange={(e) =>
                                props.handleChange(
                                  "new_freight_rate",
                                  e.target.value,
                                  index
                                )
                              }
                            />
                          ) : (
                            <div>
                              {row.new_freight_rate ||
                                parseFloat(oldRate).toFixed(2)}
                            </div>
                          )}

                          {props.isEditing === `new_freight_rate_${index}` ? (
                            <div>
                              <div
                                className={"action-icon"}
                                style={{ backgroundImage: `url(${Save})` }}
                                // onClick={() => props.handleSubmitForm()}
                                onClick={(value, id) =>
                                  props.handleAmendSubmit(
                                    props.lanesData,
                                    props.rowData,
                                    `new_freight_rate_${index}`
                                  )
                                }
                              />
                              <div
                                className={"action-icon"}
                                style={{ backgroundImage: `url(${Cancel})` }}
                                onClick={() =>
                                  props.toggleEdit(`new_freight_rate_${index}`)
                                }
                              />
                            </div>
                          ) : (
                            <div>
                              <div
                                className={"action-icon"}
                                style={{ backgroundImage: `url(${Edit})` }}
                                onClick={() =>
                                  props.toggleEdit(`new_freight_rate_${index}`)
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div> */}
                      {/* <div className="divTableCell">
                        {" "}
                        {parseFloat(
                          row.last_month_approved_rate_diff || 0
                        ).toFixed(2)}
                        <div
                          className={"indicate-icon"}
                          style={{
                            backgroundImage: `url(${
                              row.last_month_approved_rate_diff > 0
                                ? UpArrow
                                : DownArrow
                            })`,
                          }}
                        />
                      </div> */}
                      {/* {!props.isAbfrl &&
                        (props.months || []).map((dt) => {
                          const month = new Date(dt).getMonth() + 1;
                          const year = new Date(dt).getFullYear();
                          const mth = (row.monthly_rate || []).filter(
                            (m) =>
                              parseInt(m.year) === parseInt(year) &&
                              parseInt(m.month) === parseInt(month)
                          );
                          if (
                            mth.length > 0 &&
                            parseInt(month) === parseInt(mth[0].month)
                          ) {
                            return (
                              <div
                                className="divTableCell"
                                style={{ minWidth: "90px" }}
                              >
                                {mth[0].actual_rate && (
                                  <div> AR: {mth[0].actual_rate} </div>
                                )}
                                {mth[0].covid_rate && (
                                  <div> DR: {mth[0].covid_rate} </div>
                                )}
                                {mth[0].new_freight && (
                                  <div> PR: {mth[0].new_freight} </div>
                                )}
                              </div>
                            );
                          } else {
                            return <div className="divTableCell"> - </div>;
                          }
                        })} */}
                      {/* {props.isAbfrl &&
                        (props.months || []).map((dt) => {
                          const month = new Date(dt).getMonth() + 1;
                          const year = new Date(dt).getFullYear();
                          const mth = (row.monthly_rate || []).filter(
                            (m) =>
                              parseInt(m.year) === parseInt(year) &&
                              parseInt(m.month) === parseInt(month)
                          );
                          if (
                            mth.length > 0 &&
                            parseInt(month) === parseInt(mth[0].month)
                          ) {
                            return (
                              <div
                                className="divTableCell"
                                style={{ minWidth: "90px" }}
                              >
                                {mth[0].actual_rate && (
                                  <div> AR: {mth[0].actual_rate} </div>
                                )}
                                {mth[0].covid_rate && (
                                  <div> DR: {mth[0].covid_rate} </div>
                                )}
                                {mth[0].new_freight && (
                                  <div> PR: {mth[0].new_freight} </div>
                                )}
                                {mth[0].generate_rate && (
                                  <div> GR: {mth[0].generate_rate} </div>
                                )}
                              </div>
                            );
                          } else {
                            return <div className="divTableCell"> - </div>;
                          }
                        })} */}
                    </div>
                  );
                })
              ) : (
                <div className="divTableCell"> No Data found </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DphAmendmentDetails;
