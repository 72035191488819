import React, { Component } from 'react';
import InputText from '../../../../common/components/input-text/input-text';
import Select from 'react-select';
import { numeric } from '../../../../../utils/validate';
import Button from '../../../button-classic/button-classic';


class AddBillingCycle extends Component {
	constructor(props) {
		super(props);
		this.state = {
			billing_cycle: props.editBillCycle ? props.editBillCycle.billing_cycle : '',
			from: props.editBillCycle ? props.editBillCycle.from : '',
			to: props.editBillCycle ? props.editBillCycle.to : '',
			type: this.props.isType
		}
	}

	addCompanyAccounts = () => {
		let data = {
			billing_cycle: this.state.billing_cycle,
			from: this.state.from ? +this.state.from : this.state.from,
			to: this.state.to ? +this.state.to : this.state.to,
			type: this.state.type
		}
		this.props.addCompanyAccounts(data);
		this.props.toggleAdd();
	}

	updateCompanyAccounts = () => {
		let data = {
			billing_cycle: this.state.billing_cycle,
			from: this.state.from ? +this.state.from : this.state.from,
			to: this.state.to ? +this.state.to : this.state.to,
			type: this.state.type,
			id: this.props.editBillCycle.id,
			company_id: this.props.editBillCycle.company_id,
			delete_status: false,
			load_type: this.props.editBillCycle.load_type,
			mode_type: this.props.editBillCycle.mode_type
		}
		this.props.updateCompanyAccounts(data);
		this.props.toggleAdd();
	}

	onChangeHandler = (value, id) => {
		this.setState({ [id]: value });
	}

	onAmountChangeHandler = (value, id) => {
		if (numeric(value) || value == '') {
			this.setState({ [id]: value });
		}
	}

	dateValidate = (value) => {
		return value.toString().match(/^(0?[1-9]|[12][0-9]|3[01])$/);
	}

	validData = ({ billing_cycle, from, to } = this.state) => {
		return (billing_cycle && this.dateValidate(from) && this.dateValidate(to));
	}


	render() {
		let { billing_cycle, from, to } = this.state;
		const disable = this.validData();
		return (
			<div className="add-company-wrap add-provider-root add-row">
				<div onClick={this.props.toggleAdd} className="overlay">
					<div className="modal-popup" onClick={(e) => { e.stopPropagation() }}>
						<div className="heading">
							{`${this.props.editBillCycle ? 'Edit' : 'Add'} Billing Cycle`}
						</div>
						<form className="form-section" autoComplete="nopes">
							<InputText placeholder={'Bill Cycle'} label={'Bill Cycle'} id={'billing_cycle'} value={this.state.billing_cycle} changeHandler={this.onChangeHandler} />
							<div className="select-wrap">
								<InputText placeholder={'From'} label={'From'} id={'from'} value={this.state.from} changeHandler={this.onAmountChangeHandler} />
							</div>
							<div className="select-wrap">
								<InputText placeholder={'To'} label={'To'} id={'to'} value={this.state.to} changeHandler={this.onAmountChangeHandler} />
							</div>
						</form>
						<div className="submit-section submit-area">
							{
								<div className="button-section">
									<div className="button-section"> {!this.props.editBillCycle && <div className="button-section">
										<Button className="add-button" click={this.addCompanyAccounts} value="Save" disabled={!disable} />
									</div>}
									</div>
									<div>{this.props.editBillCycle && <div className="button-section">
										<Button className="add-button" click={this.updateCompanyAccounts} value="Update" disabled={!disable} />
									</div>}
									</div>
								</div>

							}
							<div className="button-section" >
								<div className="add-button" onClick={this.props.toggleAdd}>Close</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default AddBillingCycle;