import React, { Component } from 'react'
import './InputModal.less'
import InputText from '../../common/components/input-text/input-text';
import Button from '../button-classic/button-classic';
import Select from "react-select";
import Add from '../../../assets/images/add.svg';
import Delete from '../../../assets/images/delete.svg';
import { connect } from 'react-redux';
import ACTIONS from '../../common/action_constant';
import { setItemToSessionStorage } from '../../../utils/sessionStorage';

const materialObj = {
	sap_material_code: '',
	material_code_description: '',
	uom: '',
	vendor_name: [{
		name: '',
		sap_code: ''
	}]
}

class InputModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: props.isEditData ? { ...props.isEditData } : {
				sap_material_code: '',
				material_code_description: '',
				uom: '',
				vendor_name: [{
					name: '',
					sap_code: ''
				}]
			}
		}
		console.log(this.props);
	}

	componentDidMount() {
		setItemToSessionStorage('vendorIn', this.state.data)	
	}

	onChangeHandler = (value, id) => {
		const { data } = this.state;
		data[id] = value;
		this.setState({ data });
	}

	onAddVendor = () => {
		const { data } = this.state;
		data.vendor_name.push({
			name: '',
			sap_code: ''
		});
		this.setState({ data });
	}

	onDeleteVendor = (index) => {
		const { data } = this.state;
		data.vendor_name.splice(index, 1);
		this.setState({ data });
	}

	onSelectVendor = (option, index) => {
		const { data } = this.state;
		if (option) {
			data.vendor_name[index]['name'] = option.company_name;
			data.vendor_name[index]['id'] = option.id;
			const { dispatch } = this.props;
			dispatch({
				type: ACTIONS.HOME.GET_VENDOR_WITH_SAP_CODE,
				companyId: option.id,
				companyType: 2,
				onSuccess: (result) => {
					data.vendor_name[index]['sap_code'] = (result[0] || []).sap_vendor_code;
					this.setState({ data });
				}
			})
		} else {
			data.vendor_name[index] = {};
			this.setState({ data });
		}
	}

	addMaterialVendorDetails = () => {
		const { data } = this.state;
		if (this.props.isEditData) {
			this.props.updateMaterialVendorDetails(data);
		} else {
			this.props.addMaterialVendorDetails(data);
		}
	}

	render() {

		const { data } = this.state;
		const { companyTransporters } = this.props;
		return (
			<div className="vendor_mapping_detail">
				<div className="overlay"></div>
				<div id="modal" className="modal-popup" >
					<div className="pop-heading">
						Add Vendor Mapping
					</div>

					<div className="vendor-detail">

						<div className="details-section">
							<InputText
								placeholder="Material Code"
								label="Material Code"
								value={data.sap_material_code}
								id={"sap_material_code"}
								changeHandler={this.onChangeHandler}
							/>
							<InputText
								placeholder="Material Code Description"
								label="Material Code Description"
								value={data.material_code_description}
								id={"material_code_description"}
								changeHandler={this.onChangeHandler}
							/>
							<InputText
								placeholder="UOM"
								label="UOM"
								value={data.uom}
								id={"uom"}
								changeHandler={this.onChangeHandler}
							/>
							<div className={'list-section'} style={{ 'display': 'flex' }}>
								<div className={'table-header'}>Vendor</div>
								<div className={'table-header'} style={{ 'marginLeft': '67px' }}>SAP vendor Code</div>
								<div className={'table-header'}>Actions</div>
							</div>
							{(data.vendor_name || []).map((vendor, index) => (
								<div className="select-btn">
									<Select
										className="select"
										placeholder="Vendor"
										label="vendor"
										options={companyTransporters}
										value={vendor.id && (companyTransporters || []).find(trans => trans.id === vendor.id) || null}
										getOptionLabel={option => option.company_name}
										getOptionValue={option => option.id}
										onChange={(option) => this.onSelectVendor(option, index)}
									/>
									<Select
										className="select"
										placeholder="SAP Vendor Code"
										label="vendor"
										options={[] || companyTransporters}
										value={vendor.id && { 'sap_code': vendor.sap_code } || null}
										getOptionLabel={option => option.sap_code}
										getOptionValue={option => option.id}
										onChange={(option) => this.onSelectVendor(option, index)}
									/>
									<div className={'table-row align-center'}>
										<div className={'delete-icon'} style={{ backgroundImage: `url(${Add})` }} onClick={this.onAddVendor}></div>
										<div className={'delete-icon'} style={(data.vendor_name || []).length != 1 ? { backgroundImage: `url(${Delete})` }:null} onClick={() => this.onDeleteVendor(index)}></div>
									</div>
								</div>))}
						</div>
						<div className={'button_route'}>
							<Button value={'Cancel'} click={this.props.toggle} />
							<Button value={this.props.isEditData ? 'Update' : 'Save'} click={this.addMaterialVendorDetails} />
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default connect()(InputModal);
