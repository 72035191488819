import { put, call, select } from "redux-saga/effects";
import ACTIONS from "../common/action_constant";
import API from "../common/api_config";
import {
	getData,
	postData,
	postDataForGetDetails,
	putData,
	fileUpload,
	deleteData,
	getDataFromPIN,
	postDataWithOutToken,
} from "../../utils/ajax";


export function* getAllScope(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getScopesList);
		yield put({
			type: ACTIONS.VENDORS.SCOPES_LIST,
			data: data.data && data.data.scopes ? data.data.scopes : []
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* getFormVendorModes(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getAllVendorModes + action.params);
		yield put({
			type: ACTIONS.VENDORS.ALL_VENDOR_MODES,
			data: data.data && data.data.mode_types ? data.data.mode_types : []
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* getCharges(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getChargesList + action.params);
		yield put({
			type: ACTIONS.VENDORS.CHARGES_DATA,
			data: data.data && data.data.charges ? data.data.charges : []
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* postPrimaryVendorForm(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.addPrimaryVendorDetails, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data && data.message ? data.message : "Primary Details Updated Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* putPrimaryVendorForm(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(putData, API.addPrimaryVendorDetails, JSON.stringify(action.data));
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data && data.message ? data.message : "Primary Details Updated Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* SubmitPrimaryVendorForm(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(putData, API.addPrimaryVendorDetails, JSON.stringify(action.data));
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		// yield put({
		// 	type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		// 	data: { type: 1, msg: data && data.message ? data.message : "Primary Details Updated Successfully!" }
		// })
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* postSecondaryVendorForm(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.addSecondaryVendorDetails, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		if(!action.data.isSubmit){
			yield put({
				type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data: { type: 1, msg: data && data.message ? data.message : "Details Updated Successfully!" }
			})
		}
		
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* updateSecondaryVendorForm(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(putData, API.updateAllVendorDetails, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data && data.message ? data.message : "Details Updated Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* getVendorDetails(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getOnboardVendorDetails + action.params);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		if(action.onSuccess){
			action.onSuccess(data.data)
		}
		yield put({
			type: ACTIONS.VENDORS.GET_VENDOR_DETAILS_DATA,
			data: data.data && (data.data || []).length > 0 ? data.data[0] : {}
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Oops! Something Went Wrong." }
		})
	}
}

export function* uploadVendorOperationalAreas(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(fileUpload, API.uploadVendorOperationalAreas, action.data);

		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Data Updated Successfully!" }
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Oops! Something Went Wrong." }
		})
	}
}

export function* uploadVendorLogo(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(fileUpload, API.uploadVendorLogo, action.data);
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Data Uploaded Successfully!" }
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Oops! Something Went Wrong." }
		})
	}
}

export function* downloadOperationalAreas(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		})
		const data = yield call(getData, API.downloadOperationalAreas + action.params)
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Oops! Something Went Wrong." }
		})
	}
}

export function* getVendorOnboardAreas(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		})
		const data = yield call(getData, API.getVendorOnboardAreas + action.params)
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		yield put({
			type: ACTIONS.VENDORS.ONBOARD_AREAS_DETAILS,
			data: data.data ? data.data : [],
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Oops! Something Went Wrong." }
		})
	}
}

export function* uploadVendorDocs(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(fileUpload, API.uploadVendorDocs, action.data);
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Data Uploaded Successfully!" }
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Oops! Something Went Wrong." }
		})
	}
}

export function* uploadVendorDeclarationFiles(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(fileUpload, API.uploadVendorDeclarationFileURL, action.data);
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Data Uploaded Successfully!" }
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Oops! Something Went Wrong." }
		})
	}
}

export function* sendChallengePrice(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(putData, API.sendChallengePrice, JSON.stringify(action.data));
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Challenge Price Updated Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* getFormStates(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getFormStates);
		yield put({
			type: ACTIONS.VENDORS.FORM_ZONES_DATA,
			data: data.data && data.data.length > 0 ? data.data[0].states : []
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* getOperationalAreas(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getOperationalAreas);
		yield put({
			type: ACTIONS.VENDORS.STORE_OPERATIONAL_AREAS,
			data: data.data ? data.data : []
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* getStateListDetails(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getStateListDetails + action.data);
		yield put({
			type: ACTIONS.VENDORS.STORE_STATE_LIST_DETAILS,
			data: data.data ? data.data : []
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		// yield put({
		// 	type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		// 	data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		// });
	}
}

export function* getServicesProvided(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, action.param ? API.getServicesProvided + action.param : API.getServicesProvided);
		yield put({
			type: ACTIONS.VENDORS.STORE_SERVICE_PROVIDED,
			data: data.data ? data.data : []
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* getAllServicesOffered(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getAllServicesOffered);
		yield put({
			type: ACTIONS.VENDORS.STORE_ALL_SERVICE_OFFERED,
			data: data.data ? data.data : []
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* getVendorRatingQuestions(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.getVendorRatingQuestions+ action.param);
		yield put({
			type: ACTIONS.VENDORS.STORE_ALL_QUESTIONS,
			data: data.data ? data.data : []
		})
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* vendorGSTDetails(action) {
	try{
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const data =  yield call (fileUpload, API.vendorGSTDetails, action.data);
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		if(action.onSuccess){
			action.onSuccess(data);
		}
		yield put({
			type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data :{type :1, msg :"Excel uploaded Sucessfully!"}
		})
	}catch (e){
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data :{type :2, msg :e && e.message ? e.message :"Oops! Something went wrong."}
		})
	}
}

export function* vendorGSTDownload(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.vendorGSTDownload);
		// yield put({
		// 	type: ACTIONS.VENDORS.STORE_VENDOR_GST_DETAILS,
		// 	data: data.data ? data.data : []
		// })
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* getCityAndStateFromPin(action) {
	try {
		const data = yield call(getDataFromPIN, API.getCityAndStateFromPinURL + action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if (action.onSuccess) {
			action.onSuccess(data);
		}
	} 
	catch (e) {
	}
}

export function* deleteUploadedDocumentInVendor(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(deleteData, API.deleteUploadedDocumentInVendorURL + action.params);
		if(action.onSuccess()) {
			action.onSuccess()
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* sendOTPInVendorForm(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.sendOTPURL, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data && data.message ? data.message : "Primary Details Updated Successfully!" }
		})
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* verifyWithOTPInVendorForm(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postData, API.verifyOTPURL, action.data);
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data && data.message ? data.message : "Primary Details Updated Successfully!" }
		})
		if (action.onSuccess) {
			action.onSuccess(data);
		}
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}

export function* uploadExternalApproverDocInVendor(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(fileUpload, API.uploadExternalApproverDocURL, action.data);
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Data Uploaded Successfully!" }
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Oops! Something Went Wrong." }
		})
	}
}
export function* uploadApproverDocInVendor(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(fileUpload, API.uploadApproverDocURL, action.data);
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Data Uploaded Successfully!" }
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Oops! Something Went Wrong." }
		})
	}
}

export function* downloadVehicleDetailsTemplate(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(getData, API.downloadVehicleDetailsTemplate);
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Oops! Something went wrong." }
		})
	}
}

export function* getUploadedVehicleDetails(action) {
	try{
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const data =  yield call (fileUpload, API.getUploadedVehicleDetails, action.data);
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		if(action.onSuccess){
			action.onSuccess(data);
		}
		yield put({
			type : ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data :{type :1, msg :"Excel uploaded Sucessfully!"}
		})
	}catch (e){
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data :{type :2, msg :e && e.message ? e.message :"Oops! Something went wrong."}
		})
	}
}

export function* getPaymentTerms(action) {
	try{
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const data =  yield call (getData, API.getPaymentTermsURL + action.params);
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		if(action.onSuccess){
			action.onSuccess(data);
		}
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
	}catch (e){
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data :{type :2, msg :e && e.message ? e.message :"Oops! Something went wrong."}
		})
	}
}

export function* getAdminNames(action) {
	try{
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const data =  yield call (getData, API.getAdminNamesURL + action.params);
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		if(action.onSuccess){
			action.onSuccess(data);
		}
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
	}catch (e){
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data :{type :2, msg :e && e.message ? e.message :"Oops! Something went wrong."}
		})
	}
}

export function* getCurrencyOptions(action) {
	try{
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const data =  yield call (getData, API.getCurrencyOptionsURL + action.params);
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		if(action.onSuccess){
			action.onSuccess(data);
		}
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
	}catch (e){
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data :{type :2, msg :e && e.message ? e.message :"Oops! Something went wrong."}
		})
	}
}

export function* getCountryList(action) {
	try{
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const data =  yield call (getData, API.getCountryOptionsURL + action.params);
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		if(action.onSuccess){
			action.onSuccess(data);
		}
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
	}catch (e){
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data :{type :2, msg :e && e.message ? e.message :"Oops! Something went wrong."}
		})
	}
}

export function* saveSAPVendorForm(action) {
	try{
		yield put({
			type :ACTIONS.LOADER.SHOW_LOADER
		})
		const data =  yield call (postData, API.saveSAPVendorFormURL, action.data||action.payload);
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		if(action.onSuccess){
			action.onSuccess(data);
		}
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data :{type :1, msg :"SAP vendor form saved successfully!"}
		})
	}catch (e){
		yield put({
			type :ACTIONS.LOADER.HIDE_LOADER
		})
		yield put({
			type :ACTIONS.MSG_DISPLAY.SHOW_MSG,
				data :{type :2, msg :e && e.message ? e.message :"Oops! Something went wrong."}
		})
	}
}

export function* uploadBankDocs(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(fileUpload, API.uploadBankDocumentURL, action.data);
		if (action.onSuccess) {
			action.onSuccess(data.data);
		}
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: "Data Uploaded Successfully!" }
		});
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e && e.responseJSON ? e.responseJSON.message : "Oops! Something Went Wrong." }
		})
	}
}

export function* saveBankApprovalData(action) {
	try {
		yield put({
			type: ACTIONS.LOADER.SHOW_LOADER
		});
		const data = yield call(postDataWithOutToken, API.saveBankApprovalDataURL, action.payload);
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 1, msg: data && data.message ? data.message : "Bank Approval Data Saved Successfully!" }
		});
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		if(action.onSuccess) {
			action.onSuccess()
		}
	} catch (e) {
		yield put({
			type: ACTIONS.LOADER.HIDE_LOADER
		});
		yield put({
			type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
			data: { type: 2, msg: e.responseJSON && e.responseJSON.message ? e.responseJSON.message : "Oops, Something went wrong!" }
		});
	}
}






