import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ACTION from "../../common/action_constant";
import MainComponent from "../../home/main/main";
import Loader from "../../common/components/loader/loader";
import MSGPopUp from "../../common/components/msg-popup/msg-popup";
import Select from "react-select";
import InputText from "../../common/components/input-text/input-text";
import "./hsd-masters.less";
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Moment from 'moment';
import { getCookie } from "../../../utils/cookies";
import HSDLanesPopup from './hsd-lanes-popup';
import HSDConsolidatedViewPopup from './hsd-consolidated-view';
import Save from '../../../assets/images/save.svg';
import Cancel from '../../../assets/images/cancel.svg';
import Edit from '../../../assets/images/edit.svg';
import { downloadFile, getEmail, getUserCompanyId, getUserId, isSapDeepakPhenolics } from '../../../utils/common';
import HsdDetailsEditPopup from './hsdDetailsEdit-popup';
import { toast } from 'react-toastify';


const monthNames = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const currentMonth = new Date();

const months = [
    new Date(currentMonth.getFullYear(), currentMonth.getMonth()+1, 0),
    new Date(currentMonth.getFullYear(), currentMonth.getMonth()-0, 0), //.getMonth() + 1,
    new Date(currentMonth.getFullYear(), currentMonth.getMonth()-1, 0), //.getMonth() + 1,
    new Date(currentMonth.getFullYear(), currentMonth.getMonth()-2, 0), //.getMonth() + 1,
    new Date(currentMonth.getFullYear(), currentMonth.getMonth()-3, 0), //.getMonth() + 1,
    new Date(currentMonth.getFullYear(), currentMonth.getMonth()-4, 0), //.getMonth() + 1,
]

class HSDMasters extends Component {
    constructor(props){
        super(props);
        this.state = {
            hsdMasterDataCopy: [],
            newData: {
                hsd_cycle_type: 1,
                bound_type_name: '',
                bound_type: 1,
                contract_type_name: '',
                contract_type: 1,
                contract_start_date: null,
                contract_end_date: null,
            },
            search: '',
            selectedHSDIds: [],
            hsdLaneData: [],
            showPopup: false,
            cycleType: 0,
            selectedHSD: [],
            zoneRates: [],
            consolidatedView: [],
            isView: false,
            selectedAppLanes: [],
            showHsdDetailsEditPopup:false,
            hsdEditIndex:-1,
            HSDEditLineitem: {},
        }
    }

    componentDidMount() {
        this.getHSDContractData();
        this.getVendorsData();
        this.showHSDZoneData();
    }

    getHSDContractData = () => {
        const user = JSON.parse(getCookie("user"));
        const { dispatch } = this.props;
        const { newData, search } = this.state;
        let param = `?companyId=${user.company_id}&hsdCycleType=${newData.hsd_cycle_type}`
        if(search.length>0) {
            param = param + `&search=` + search
        }
        if(newData.bound_type_name){
            param = param + `&boundType=${newData.bound_type_name.label == 'Outbound' ? 1 : 2}`
        }
        if(newData.contract_type_name){
            param = param + `&contractType=${newData.contract_type_name.label === 'Dedicated' ? 1 : 2}`
        }
        if(newData.contract_start_date){
            param = param + `&contractStartDate=${newData.contract_start_date}`
        }
        if(newData.contract_end_date){
            param = param + `&contractEndDate=${newData.contract_end_date}`
        }
        if(newData.zone) {
            param = param + `&zone=` + newData.zone.label
        }
        
        dispatch({
            type: ACTION.DATA_GOVERNANCE.HSD_CONTRACT_DETAILS,
            param,
            onSuccess: (data) => {
                this.setState({hsdMasterDataCopy: data})
            }
        })
    }

    getVendorsData = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_VENDORS_TYPES,
        });
    }

    closeMsgPopup = () => {
		const { dispatch } = this.props;
		dispatch({ type: ACTION.MSG_DISPLAY.HIDE_MSG })
		this.getHSDContractData();
	}

    approveRates = (selectedHSDLanes, contract, vendor) => {
        const { newData, hsdLaneData } = this.state;
        const { dispatch } = this.props;
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();
        const user = JSON.parse(getCookie("user"));
        let selectedLanesForApproval = [];
        const today = new Date().getDate();
        const data = {
            approval_list: []
        };
        if(selectedHSDLanes.length > 0){
            selectedLanesForApproval = hsdLaneData.filter(lane => selectedHSDLanes.includes(lane.hsd_lane_id))
            selectedLanesForApproval.map((lane) => {
                data.approval_list.push({
                    hsd_lane_id: lane.hsd_lane_id,
                    approved_month: (currentMonth === 12 ? 1 : currentMonth+1),
                    approved_year: currentYear,
                    approved_by: user.name,
                    hsd_cycle_type: newData.hsd_cycle_type,
                    is_first_fortnight: today < 16 ? true : false,
                })
            })
        }
        dispatch({
            type: ACTION.DATA_GOVERNANCE.HSD_LANE_APPROVAL,
            data,
            params: `?companyId=${user.company_id}`,
            onSuccess: () => {
                this.setState({showPopup: false});
                this.getHSDContractData();
            }
        })
    }

    clickCustomCheckBox = (id) =>{
        this.setState({
            hsd_cycle_type: id,
            offset: 0,
        }, () => {
            const { newData } = this.state;
            newData.hsd_cycle_type = id;
            this.setState({ newData }, this.getHSDContractData)
            
        })
    }

    changeHandler = (value, name) => {
        const { newData } = this.state;
        newData[name] = value;
        this.setState({newData}, this.getHSDContractData)
    }

    handleHSDIdsSelection = (id) => {
        const { selectedHSDIds } = this.state;
        let selectedHSDIdsCopy = [...selectedHSDIds];
        if (selectedHSDIdsCopy.includes(id)) {
            selectedHSDIdsCopy.splice(id, 1);
        } else {
            selectedHSDIdsCopy.push(id);
        }
        this.setState({selectedHSDIds: selectedHSDIdsCopy});
    }

    showHSDLanes = (contract, vendor) => {
        const { dispatch } = this.props;
        let params = `?rfqId=${contract.rfq_id}`
        if(vendor){
            params = params + `&transporterId=${vendor.vendor_id}`
        }
        dispatch({
            type: ACTION.DATA_GOVERNANCE.HSD_CONTRACT_LANE_DETAILS,
            params,
            onSuccess: (data) => {
                this.setState({showPopup: true, hsdLaneData: data, cycleType: contract.hsd_cycle_type, selectedHSD: contract});
            }
        })
    }

    showHSDZoneData = () => {
        const { dispatch } = this.props;
        const user = JSON.parse(getCookie("user"));
        let params = `?companyId=${user.company_id}&zone=Vadodara`
        dispatch({
            type: ACTION.DATA_GOVERNANCE.HSD_ZONE_DETAILS,
            params,
            onSuccess: (data) => {
                this.setState({zoneRates: data})
            }
        })
    }

    consolidatedData = (month, cycleType, selectedRFQs, vendor) => {
        const { dispatch } = this.props;
        const user = JSON.parse(getCookie("user"));
        let rfqs = selectedRFQs.map((rfq) => (rfq.value))
        
        let data = {
            companyId: user.company_id,
            month,
            cycleType,
            rfqs,
            transporterId: vendor
        }
        dispatch({
            type: ACTION.DATA_GOVERNANCE.HSD_CONSOLIDATED_DETAILS,
            data,
            onSuccess: (details) => {
                this.setState({consolidatedView: details})
            }
        })
    }

    consolidatedRFQList = (cycleType) => {
        const { dispatch } = this.props;
        const user = JSON.parse(getCookie("user"));
        let params = `?companyId=${user.company_id}&type=${cycleType}`
        dispatch({
            type: ACTION.DATA_GOVERNANCE.HSD_CONSOLIDATED_RFQ_DETAILS,
            params
        })
    }
    

    editHandler = (value, name, index) => {
        const { hsdMasterDataCopy } = this.state;
        hsdMasterDataCopy[index][name] = value.label;
        this.setState({hsdMasterDataCopy})
    }

    closePopup = () => {
        this.setState({showPopup: false, hsdLaneData: [], cycleType: 0, selectedHSD: [], isView: false, consolidatedView: []})
    }

    editLane = (id) => {
        this.setState({
            editId: this.state.editId===id ? null : id,
            isAdded: false
        });
    }

    saveLane = (rfqId, index) => {
        const { hsdMasterDataCopy } = this.state;
        const { dispatch } = this.props;
        let data = { zone: hsdMasterDataCopy[index].zone };
        dispatch({
            type: ACTION.DATA_GOVERNANCE.HSD_EDIT_CONTRACT_DETAILS,
            params: `?rfqId=${rfqId}`,
            data,
            onSuccess: () => {
                this.setState({editId: null}, this.getHSDContractData);
            }
        })
    }

    editHsdDetails = (contract, vendor) => {
        const { dispatch } = this.props;
        let params = `?rfqId=${contract.rfq_id}`
        if (vendor) {
            params = params + `&transporterId=${vendor.vendor_id}`
        }
        dispatch({
            type: ACTION.DATA_GOVERNANCE.HSD_CONTRACT_LANE_DETAILS,
            params,
            onSuccess: (data) => {
                this.setState({
                    showHsdDetailsEditPopup: true,
                    hsdLaneDetailsEditData: data,
                    // cycleType: contract.hsd_cycle_type, 
                    selectedHSDforEdit: contract
                });
            }
        })
    }

    closeHsdDetailsPopup = () => {
        this.setState({
            showHsdDetailsEditPopup: false,
            hsdLaneDetailsEditData: [],
            selectedHSDforEdit: [],
            HSDEditLineitem:{},
            hsdEditIndex: -1,
        })
    }

    handleHSDDetailsEdit = (idx,item) => {
        this.setState({
            hsdEditIndex: idx,
            HSDEditLineitem: item,
        });
    };

    handleHsdEditCancel = () => {
        this.setState({ hsdEditIndex: -1, HSDEditLineitem:{}});
    };

    handleHSDDetailsChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            HSDEditLineitem: {
            ...prevState.HSDEditLineitem,
            [name]: value,
          },
        }));
      };

      handleHsdDateChange=(date,name)=>{
        this.setState((prevState) => ({
            HSDEditLineitem: {
            ...prevState.HSDEditLineitem,
            [name]: date,
          },
        }));
      }

      handledatefocuschange1=(focused)=>{
        this.setState({ contract_start_date_focused: focused })
      }

      handledatefocuschange2=(focused)=>{
        this.setState({ contract_end_date_focused: focused })
      }

      handleSubmitHSDDetails=()=>{
        const {dispatch}=this.props;
        const {HSDEditLineitem,selectedHSDforEdit}=this.state;
        const companyId = getUserCompanyId();
        const user_id = getUserId();
        const user_email=getEmail();

        let data = {...HSDEditLineitem};
        if (data.contract_start_date) {
            const contractStartDate = new Date(data.contract_start_date);
            data.contract_start_date = contractStartDate.getTime(); // Convert to epoch in milliseconds
        }
        if (data.contract_end_date) {
            const contractEndDate = new Date(data.contract_end_date);
            data.contract_end_date = contractEndDate.getTime(); // Convert to epoch in milliseconds
        }
        data.company_id=companyId;
        data.user_id=user_id;
        data.user_email=user_email;

        dispatch({
            type: ACTION.DATA_GOVERNANCE.POST_HSD_DETAILS,
            params: data,
            onSuccess: ()=>{
                this.setState({ hsdEditIndex: -1},()=>{

                    let params = `?rfqId=${selectedHSDforEdit.rfq_id}`
                    dispatch({
                        type: ACTION.DATA_GOVERNANCE.HSD_CONTRACT_LANE_DETAILS,
                        params,
                        onSuccess: (data) => {
                            this.setState({
                                hsdLaneDetailsEditData: data,
                            });
                        }
                    })
                });
            }
        });

      }
    downloadHsd = (withData) => {
        const { dispatch } = this.props;
        const { selectedHSD } = this.state;
        const companyId = getUserCompanyId();
        let params = {};
        if (!!withData) {
            params.company_id= companyId;
            params.rfq_id= selectedHSD.rfq_id;
        }
        dispatch({
            type: ACTION.DATA_GOVERNANCE.DOWNLOAD_HSD_DETAILS,
            withData,
            params,
            onSuccess: ((data) => {
                this.setState({ downloadUrl: data ? data[0].Link : '' }, () => {
                    toast.success("File Downloded successfully");
                    downloadFile(data[0].Link || "");
                });
            })
        })
    }

    uploadHSD = (e) => {
        // debugger
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      this.uploadHSDFile(formData);
    }
  };
  uploadHSDFile = (e) => {
        const { dispatch } = this.props;
        const payload={
            company_id:getUserCompanyId(),
            hsd_lane_id:selectedHSD.hsd_lane_id,
            userId:getUserId(),
            userEmail:getEmail(),
        }
        dispatch({
            type: ACTION.DATA_GOVERNANCE.UPLOAD_HSD_DETAILS, 
            data: formData, 
            payload, 
            // onSuccess: () => {
            //     this.getMaterialConversion();
            // }
        });
    }
    uploadMC = () => {
        this.uploadExcel.click();
      };

    render() {
        let { newData, search, selectedHSDIds, editId, hsdMasterDataCopy, zoneRates,selectedHSDforEdit } = this.state;
        // let { hsdMasterData } = this.props;
        // hsdMasterDataCopy = JSON.parse(JSON.stringify(hsdMasterData))
        const currentYear = new Date().getFullYear();
        const user = JSON.parse(getCookie("user"));
        return(
            <div className="hsdMaster">
                <div className="wrapper">
                    <MainComponent>
                        {/* {this.props.loader.isLoading && <Loader />}
                        {this.props.msgpopup.active && <MSGPopUp close = {this.closeMsgPopup} type = {this.props.msgpopup.type} msg = {this.props.msgpopup.msg}/>} */}
                        <div className="sub-heading-section"> 
                            <div className="heading" style={{color: '#528aea'}}> HSD Masters </div>
                        </div>
                        <div>
                            <div className="serviceTypes">
                                <div className="cta-action-btns">
                                    <div className = "section-item">
                                        <CustomeCheckBox id={1} toggle={(id) => this.clickCustomCheckBox(id)} selected={(newData.hsd_cycle_type===1)}/>
                                        <div className="title"> Fortnight Cycle</div>
                                    </div>
                                    <div className = "section-item">
                                        <CustomeCheckBox id={2} toggle={(id) => this.clickCustomCheckBox(id)} selected={(newData.hsd_cycle_type===2)}/>
                                        <div className="title"> Monthly Cycle</div>
                                    </div>
                                    {!isSapDeepakPhenolics(user.company_id) &&
                                    <div className="fRight">
                                        <table className="zoneTable card-view" style={{marginTop: '15px'}}>
                                            <thead className="zoneHeader">
                                                <tr>
                                                    <th style={{fontSize: '14px', fontWeight: '500', color: '#528aea'}}>Vadodara</th>
                                                    {(zoneRates || []).map((zone) => {
                                                    return (
                                                    <th>{monthNames[zone.month] + ' - ' + currentYear}</th>
                                                    )})}
                                                </tr>
                                            </thead>
                                            <tbody className="zoneHeader">
                                                <tr>
                                                    <th>First Fortnight Approved Rates</th>
                                                    {(zoneRates || []).map((zone) => {
                                                    return (
                                                        <th>{zone.FF_approved_diesel_rate}</th>
                                                    )})}
                                                </tr>
                                                <tr>
                                                    <th>Second Fortnight Approved Rates</th>
                                                    {(zoneRates || []).map((zone) => {
                                                    return (
                                                        <th>{zone.SF_approved_diesel_rate}</th>
                                                    )})}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>}
                                    <div>
                                    <button className={"close-button mt-20"} onClick={() => this.setState({isView: true}, this.consolidatedRFQList(newData.hsd_cycle_type))}>Consolidated View</button>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="serviceTypes">
                                <div className="cta-action-btns">
                                    <div className="divTableCell"> 
                                    <div className="sub-heading"> Search </div>
                                        <div className="search">
                                            <InputText 
                                                id={`search`}
                                                type='text' 
                                                placeholder="RFQ Search"
                                                value={search}
                                                changeHandler={(e) => { this.setState({ search: e.target.value }) }}
                                                style={{width: '250px'}}
                                            />
                                        </div>
                                    </div>
                                    <div className="divTableCell pt-20">
                                    <div className="divTableCell"> Bound Type </div>
                                        <Select
                                            isClearable={true}
                                            className="select"
                                            placeholder={'Bound Type'}
                                            value={newData.bound_type_name} //{label: mode_type, value: mode_type}}
                                            options={(['Inbound','Outbound']).map(bound => { return { label : bound, value: bound}})}
                                            onChange={(value) => this.changeHandler(value,'bound_type_name')}
                                        />
                                    </div>
                                    <div className="divTableCell pt-20">
                                    <div className="divTableCell"> Contract Type </div>
                                        <Select
                                            isClearable={true}
                                            className="select"
                                            placeholder={'Contract Type'}
                                            value={newData.contract_type_name} //{label: mode_type, value: mode_type}}
                                            options={(['Dedicated','Non-Dedicated']).map(contract => { return { label : contract, value: contract}})}
                                            onChange={(value) => this.changeHandler(value,'contract_type_name')}
                                        />
                                    </div>
                                    {!isSapDeepakPhenolics(user.company_id) &&
                                    <div className="divTableCell pt-20">
                                    <div className="divTableCell"> Zone </div>
                                        <Select
                                            isClearable={true}
                                            className="select"
                                            placeholder={'Zone'}
                                            value={newData.zone} //{label: mode_type, value: mode_type}}
                                            options={(['Vadodara','North','South','East','West']).map(zn => { return { label : zn, value: zn}})}
                                            onChange={(value) => this.changeHandler(value,'zone')}
                                        />
                                    </div>}
                                    <div className="divTableCell pt-20">
                                    <div className="divTableCell"> Contract Date </div>
                                        <DateRangePicker
                                            startDate={newData.contract_start_date ? Moment(newData.contract_start_date) : null}
                                            startDateId="startDate"
                                            endDate={newData.contract_end_date ? Moment(newData.contract_end_date) : null}
                                            endDateId="endDate"
                                            onDatesChange={({ startDate, endDate }) => {
                                            let { newData } = this.state;
                                            newData.contract_start_date = startDate ? startDate.valueOf() : newData.contract_start_date;
                                            newData.contract_end_date = endDate ? endDate.valueOf() : newData.contract_end_date;
                                            this.setState({ newData }, this.getHSDContractData);
                                            }}
                                            focusedInput={this.state.focusedInput}
                                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                                            displayFormat={'DD MMM YYYY'}
                                            noBorder={true}
                                            minimumNights={0}
                                            isOutsideRange={() => false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="serviceTypes">
                                <table className="hsdTable">
                                    <thead className="hsdHeader">
                                        <tr>
                                        <th>RFQ Name & Number</th>
                                        {/* <th>RFQ No.</th> */}
                                        <th>Contract Date</th>
                                        <th>Bound Type</th>
                                        <th>Contract Type</th>
                                        {!isSapDeepakPhenolics(user.company_id) &&
                                        <>
                                            <th style={{width: '100px'}}>Zone</th>
                                            <th>Zone Avg.</th>
                                        </>
                                        }
                                        <th>View</th>
                                        <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(hsdMasterDataCopy || []).map((contract, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="hsdDetails">
                                                    {/* <input
                                                        type="checkbox"
                                                        readOnly
                                                        checked={selectedHSDIds.includes(contract.hsd_id) ? true : false}
                                                        onClick={() => this.handleHSDIdsSelection(contract.hsd_id)}
                                                        style={{float: 'left'}}
                                                    /> */}
                                                    {contract.rfq_name}
                                                    <div>{contract.rfq_no}</div></td>
                                                    {/* <td className="hsdDetails">{contract.rfq_no}</td> */}
                                                    <td className="hsdDetails">{Moment(contract.contract_start_date).format('DD-MM-yyyy') + ' to ' + Moment(contract.contract_end_date).format('DD-MM-yyyy')}</td>
                                                    <td className="hsdDetails">{contract.bound_type_name}</td>
                                                    <td className="hsdDetails">{contract.contract_type_name}</td>
                                                    {!isSapDeepakPhenolics(user.company_id) &&
                                                    <>
                                                    {editId === contract.hsd_id ?
                                                    <td className="hsdDetails">
                                                        <Select
                                                            isClearable={true}
                                                            className="select"
                                                            placeholder={'Zone'}
                                                            value={{value: contract.zone, label: contract.zone}} //{label: mode_type, value: mode_type}}
                                                            options={(['Vadodara','North','South','East','West']).map(zn => { return { label : zn, value: zn}})}
                                                            onChange={(value) => this.editHandler(value,'zone',index)}
                                                        />
                                                    </td> : 
                                                    <td className="hsdDetails">{contract.zone}</td>}
                                                    <td className="hsdDetails">{contract.zone_average}</td>
                                                    </>}
                                                    <td className="hsdDetails"><span className="lanes-link" onClick={() => this.showHSDLanes(contract)}>Details</span></td>
                                                    <td className="hsdDetails">
                                                    <div className="divTableCell"> 
                                                    {(editId===contract.hsd_id) 
                                                        ?
                                                            <Fragment>
                                                                <div className={'action-icon'} style={{backgroundImage: `url(${Save})`}} onClick={() => this.saveLane(contract.rfq_id, index)} />
                                                            <div className={'action-icon'} style={{backgroundImage: `url(${Cancel})`}} onClick={() => this.editLane(contract.hsd_id)} />
                                                            </Fragment>
                                                        :
                                                                <div className='flex'>
                                                                    <div className={'action-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.editLane(contract.hsd_id)} />
                                                                    <div className={'action-icon mb-20'} onClick={() => this.editHsdDetails(contract)}>Edit</div>
                                                                </div>

                                                    }
                                                    </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </MainComponent>
                    {this.state.showPopup && 
                    <HSDLanesPopup
                        hsdLaneData={this.state.hsdLaneData}
                        closePopup={this.closePopup}
                        cycleType={this.state.cycleType}
                        contract={this.state.selectedHSD}
                        vendorTypes={this.props.vendorTypes}
                        showHSDLanes={this.showHSDLanes}
                        approveRates={this.approveRates}
                        months={months}
                        selectedHSDLanes={this.state.selectedAppLanes}
                        isSapDPL={isSapDeepakPhenolics(user.company_id)}
                        downloadHsd={this.downloadHsd}
                        selectedHSD={this.state.selectedHSD}
                    />}
                    {this.state.showHsdDetailsEditPopup && 
                    <HsdDetailsEditPopup
                    hsdLaneDetailsEditData={this.state.hsdLaneDetailsEditData}
                    closeHsdDetailsPopup={this.closeHsdDetailsPopup}
                    hsdEditIndex={this.state.hsdEditIndex}
                    handleHSDDetailsEdit={this.handleHSDDetailsEdit}
                    handleHsdEditCancel={this.handleHsdEditCancel}
                    HSDEditLineitem={this.state.HSDEditLineitem}
                    handleHSDDetailsChange={this.handleHSDDetailsChange}
                    handleHsdDateChange={this.handleHsdDateChange}
                    handledatefocuschange1={this.handledatefocuschange1}
                    handledatefocuschange2={this.handledatefocuschange2}
                    contract_start_date_focused={this.state.contract_start_date_focused}
                    contract_end_date_focused={this.state.contract_end_date_focused}
                    handleSubmitHSDDetails={this.handleSubmitHSDDetails}
                    
                    />}
                    {
					this.props.loader.isLoading && <Loader />
				    }
                    {
                        this.props.msgpopup.active && <MSGPopUp close={this.closeMsgPopup} type={this.props.msgpopup.type} msg={this.props.msgpopup.msg} />
                    }
                    {this.state.isView && 
                    <HSDConsolidatedViewPopup
                        consolidatedView={this.state.consolidatedView}
                        closePopup={this.closePopup}
                        consolidatedData={this.consolidatedData}
                        vendorTypes={this.props.vendorTypes}
                        hsdConsolidatedRFQs={this.props.hsdConsolidatedRFQs}
                        isSapDPL={isSapDeepakPhenolics(user.company_id)}
                    />}
                </div>
            </div>

        )
    }
}

const CustomeCheckBox = (props) =>{
    return(
        <div className = {props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick = {()=>props.toggle(props.id)}>
            {props.selected && <div className = "inner-circle"></div>}
        </div>
    )
}

const mapStateToProps = state => {
    console.log(state.dataGovernance)
    return {
        loader: state.loader,
        msgpopup: state.msgpopup,
        dataGovernance: state.dataGovernance,
        hsdMasterData: state.dataGovernance.hsdMasterData,
        vendorTypes: state.dataGovernance.vendorTypes,
        hsdConsolidatedRFQs: state.dataGovernance.hsdConsolidatedRFQs
    }
}

export default connect(mapStateToProps)(HSDMasters);
