import ACTION from "../common/action_constant";

const vendorForm = (
	state = {
		scopesList: [],
		vendorModes: [],
		chargesList: [],
        onboardDetails: [],
        vendorDefaultData: {},
        zonesList: [],
        serviceProvided: [],
        operationalArea: [],
        serviceOffered: [],
        vendorRating: [],
	}, action) => {
    
    let tempState = Object.assign({}, state);

    if(action.type === ACTION.VENDORS.SCOPES_LIST) {
        tempState.scopesList = action.data;
        return tempState;
    }
    
    else if (action.type === ACTION.VENDORS.ALL_VENDOR_MODES) {
    	tempState.vendorModes = action.data;
    	return tempState;
    }

    else if (action.type === ACTION.VENDORS.CHARGES_DATA) {
    	tempState.chargesList = action.data;
    	return tempState;
    }
	
    else if (action.type === ACTION.VENDORS.GET_VENDOR_DETAILS_DATA) {
        tempState.vendorDefaultData = action.data;
        return tempState;
    }

    else if (action.type === ACTION.VENDORS.ONBOARD_AREAS_DETAILS) {
        tempState.onboardDetails = action.data;
        return tempState;
    }

    else if (action.type === ACTION.VENDORS.FORM_ZONES_DATA) {
        tempState.zonesList = action.data;
        return tempState;
    }
    else if(action.type === ACTION.VENDORS.STORE_OPERATIONAL_AREAS) {
        tempState.operationalArea = action.data;
        return tempState;
	}
    else if(action.type === ACTION.VENDORS.STORE_STATE_LIST_DETAILS) {
        tempState.stateListDetails = action.data;
        return tempState;
	}
	else if(action.type === ACTION.VENDORS.STORE_SERVICE_PROVIDED) {
		tempState.serviceProvided = action.data;
		return tempState;
    }
    else if(action.type === ACTION.VENDORS.STORE_ALL_SERVICE_OFFERED) {
        tempState.serviceOffered = action.data;
        return tempState;
    }
    else if(action.type === ACTION.VENDORS.STORE_ALL_QUESTIONS) {
        tempState.vendorRating = action.data;
        return tempState;
	}
	return state;
};

export default vendorForm