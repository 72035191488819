import React from "react";
import Select from "react-select";
import ThreshholdPopUp from "../threshold/threshold";
import ACTIONS from "../../../common/action_constant";
import { lspOptions, selectedOptions } from "../../home.saga";
import Download from "../../../../assets/images/download.svg";
import { getUserCompanyId } from "../../../../utils/common";
import "./lrSummary.less";

export default class LrSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      users: null,
      datas: "",
      selectedLSP: "",
      LSPDropdownOptions: [],
    };
  }

  componentDidMount() {
    this.lspOptions();
    this.selectedOptions();
  }

  lspOptions = () => {
    const { dispatch } = this.props;
    const companyId = getUserCompanyId();
    dispatch({
      type: ACTIONS.HOME.LSP_OPTIONS,
      data: this.props.companyId,
      onSuccess: (data) => {
        this.setState({ users: data });
      },
    });
  };

  selectedOptions = () => {
    const { dispatch } = this.props;
    const companyId = getUserCompanyId();

    dispatch({
      type: ACTIONS.HOME.SELECTED_OPTIONS,
      data: this.props.companyId,
      onSuccess: (data) => {
        const options =
          data &&
          data.map((ele) => {
            return { value: ele.lsp_alias, label: ele.lsp_alias ,
              lsp_id: ele.lsp_id,
              inbound_threshold:ele.inbound_threshold,
              outbound_threshold:ele.outbound_threshold,
              email:ele.primary_consignee_email
            };
          });
        this.setState({ LSPDropdownOptions: options });

      },
    });

  };

  toggleAdd = (data = "") => {
    this.setState({ modalOpen: true, editData: data });
  };

  handleSelectedLSP = (e) => {
    this.setState({ selectedLSP: e.value });
    this.setState({ selectedLSPId: e.lsp_id });
    this.setState({ selectedLSPInbound: e.inbound_threshold });
    this.setState({ selectedLSPOutbound: e.outbound_threshold });
    this.setState({ selectedLSPEmail: e.email });
  };

  handleInput(e, users) {
    const my = users.filter((item) => {
      return e.value === item.data.lsp_alias;
    });
  }

  downloadLRUploadDetail = (data) => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.HOME.DOWNLOAD_LSP_DATA,
      data: `${this.props.companyId}&lspName=${data.lsp_alias}`,
      onSuccess: (data) => {
        const element = document.createElement('a');
        element.setAttribute('href', data[0].link);
        element.setAttribute('download', true);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      },
    });
  };

  downloadInboundDetails = (data, type) => {
    const { dispatch } = this.props;

    let status
    if(type === 'inbound_total'){
      status = 0
    }
    else if(type === 'inbound_available'){
      status = 1
    }
    else if(type === 'inbound_consumed') {
      status = 4
    }

    dispatch({
      type: ACTIONS.HOME.DOWNLOAD_INDIVIDUAL_LR_DETAILS,
      data: `${this.props.companyId}&doc_type=${data.doc_type}&lr_status=${status}&lspName=${data.lsp_alias}&bound_type=1`,
      onSuccess: (data) => {
        const element = document.createElement('a');
        element.setAttribute('href', data[0].link);
        element.setAttribute('download', true);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      },
    })

  }

  render() {
    const { modalOpen } = this.state;
    // console.log("state",this.state)
    const { companyId } = this.props;
    return (
      <div className="Lr-summary">
        <div className="summary">
          <div className="LSP-threshold">
            <Select
              className="dropLSP"
              options={this.state.LSPDropdownOptions}
              onChange={this.handleSelectedLSP}
              placeholder="Select LSP"
            />
            {this.state.selectedLSP &&
              <div
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => this.toggleAdd()}
              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="#528aea"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M16 16v-4l5 5-5 5v-4H4v-2h12zM8 2v3.999L20 6v2H8v4L3 7l5-5z" />
                  </g>
                </svg>
                <div className="change-thresold">Change Threshold</div>
              </div>
            }
          </div>

          {
            this.props.companyId === '999' ?
              <div className="table">
                <table className="table-view">
                  <thead>
                    <tr className="table-tr">
                      <th
                        className="tCenter"
                        style={{ borderTopLeftRadius: "8px" }}
                      >
                        Available LRs
                      </th>
                      <th className="tCenter">Consumed LRs</th>
                      <th className="tCenter">Consumed Inbound LRs</th>
                      <th className="tCenter">Consumed Outbound LRs</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.users &&
                      this.state.users.map((data) => {
                        return (
                          <tr className="tr-2">
                            <td>{data.available_lrs}</td>
                            <td>{data.consumed_lrs}</td>
                            <td>{data.consumed_inbound_lrs}</td>
                            <td>{data.consumed_outbound_lrs}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              :
              <div className="table">
                <table className="table-view">
                  <thead>
                    <tr className="table-tr">
                      <th
                        className="tCenter"
                        style={{ borderTopLeftRadius: "8px" }}
                      >
                        LSP Name
                      </th>
                      <th className="tCenter">Inbound Total</th>
                      <th className="tCenter">Inbound Available</th>
                      <th className="tCenter">Inbound Consumed</th>
                      <th className="tCenter">Inbound Reserved </th>
                      {/* <th className="tCenter">Inbound Available %</th> */}
                      <th
                        className="tCenter"
                        style={{ borderTopRightRadius: "8px" }}
                      >
                        Inbound Threshhold
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.users &&
                      this.state.users.map((data) => {
                        return (
                          <tr className="tr-2">
                            <td>{data.lsp_alias}</td>
                            <td>
                              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <p style={{ width: '50%' }}>{data.inbound_total} </p>
                                <img src={Download} width="30%" style={{ cursor: 'pointer' }} onClick={() => this.downloadInboundDetails(data, 'inbound_total')} />
                              </div>
                            </td>
                            <td>
                              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <p style={{ width: '50%' }}> {data.inbound_available}</p>
                                <img src={Download} width="30%" style={{ cursor: 'pointer' }} onClick={() => this.downloadInboundDetails(data, 'inbound_available')} />
                              </div>
                            </td>
                            <td>
                              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <p style={{ width: '50%' }}>   {data.inbound_consumed} </p>
                                <img src={Download} width="27%" style={{ cursor: 'pointer' }} onClick={() => this.downloadInboundDetails(data, 'inbound_consumed')} />
                              </div>
                            </td>
                            <td>{data.inbound_reserved_lrs}</td>
                            {/* <td>{data.inbound_available}</td> */}
                            <td>{data.inbound_threshold}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>

                <table className="table-view2">
                  <thead>
                    <tr className="table-tr">
                      <th
                        className="tCenter"
                        style={{ borderTopLeftRadius: "8px" }}
                      >
                        Outbound Total
                      </th>
                      <th className="tCenter">Outbound Available</th>
                      <th className="tCenter">Outbound Consumed</th>
                      <th className="tCenter">Outbound Reserved </th>
                      {/* <th className="tCenter">Outbound Available %</th> */}
                      <th className="tCenter">Outbound Threshhold</th>
                      {/* <th
                        className="tCenter"
                        style={{ borderTopRightRadius: "8px" }}
                      >
                        Download LR
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.users &&
                      this.state.users.map((data) => {
                        return (
                          <tr className='tr-2'>
                            <td>{data.outbound_total}</td>
                            <td>{data.outbound_available}</td>
                            <td>{data.outbound_consumed}</td>
                            <td>{data.outbound_reserved_lrs}</td>
                            {/* <td>{data.outbound_available}</td> */}
                            <td>{data.outbound_threshold}</td>
                            {/* <td>
                              <img src={Download} width="22%" style={{ cursor: 'pointer' }} onClick={() => { this.downloadLRUploadDetail(data)}} />
                            </td> */}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <table className="table-view2">
                  <thead>
                    <tr className="table-tr">
                      <th
                        // className="tCenter"
                        style={{ borderTopRightRadius: "8px" }}
                      >
                        Download IB & OB 
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.users &&
                      this.state.users.map((data) => {
                        return (
                          <tr className='tr-2'>
                            <td>
                              <img src={Download} width="22%" style={{ cursor: 'pointer' }} onClick={() => { this.downloadLRUploadDetail(data)}} />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
          }

          {modalOpen && (
            <ThreshholdPopUp
              perData={this.props.tableSummary}
              lsp={this.state.selectedLSP}
              raws={this.state.users}
              dispatch={this.props.dispatch}
              modalOpen={() =>
                this.setState({
                  modalOpen: false,
                })
              }
              th={this.props.thresholdData}
              getData={this.lspOptions}
              companyId={companyId}
              selectedLSPId={this.state.selectedLSPId}
              selectedLSPInbound={this.state.selectedLSPInbound}
              selectedLSPOutbound={this.state.selectedLSPOutbound}
              selectedLSPEmail={this.state.selectedLSPEmail}
            />
          )}
        </div>
      </div>
    );
  }
}
