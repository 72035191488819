import React, { Component, Fragment } from "react";
import InputText from "../../../../../common/components/input-text/input-text";
import Button from "../../../../../home/button-classic/button-classic";
import { data } from "jquery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Select from "react-select";
import Switch from "react-switch";
import { ReactComponent as Upload } from "../../../../../../assets/images/upload-blue.svg";
import ACTION from "../../../../../common/action_constant";
import { connect } from "react-redux";
import { getCookie } from "../../../../../../utils/cookies.js";
import { getUserCompanyId,AWL_COMPANY_IDS } from "../../../../../../utils/common";
import { validationCheck } from "../../../../../../utils/validate";
import { setItemToSessionStorage } from "../../../../../../utils/sessionStorage.js";

const compartmentDetails = [
  {
    compartment_no: 1,
    capacity: "",
    capacity_uom: "L",
    pl: "",
    pl_uom: "cm",
    dl: "",
    dl_uom: "cm",
  },
  {
    compartment_no: 2,
    capacity: "",
    capacity_uom: "L",
    pl: "",
    pl_uom: "cm",
    dl: "",
    dl_uom: "cm",
  },
  {
    compartment_no: 3,
    capacity: "",
    capacity_uom: "L",
    pl: "",
    pl_uom: "cm",
    dl: "",
    dl_uom: "cm",
  },
  {
    compartment_no: 4,
    capacity: "",
    capacity_uom: "L",
    pl: "",
    pl_uom: "cm",
    dl: "",
    dl_uom: "cm",
  },
  {
    compartment_no: 5,
    capacity: "",
    capacity_uom: "L",
    pl: "",
    pl_uom: "cm",
    dl: "",
    dl_uom: "cm",
  },
  {
    compartment_no: 6,
    capacity: "",
    capacity_uom: "L",
    pl: "",
    pl_uom: "cm",
    dl: "",
    dl_uom: "cm",
  },
  {
    compartment_no: 7,
    capacity: "",
    capacity_uom: "L",
    pl: "",
    pl_uom: "cm",
    dl: "",
    dl_uom: "cm",
  },
  {
    compartment_no: 8,
    capacity: "",
    capacity_uom: "L",
    pl: "",
    pl_uom: "cm",
    dl: "",
    dl_uom: "cm",
  },
];

class AddItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        company_id: JSON.parse(getCookie("user")).company_id,
        user_name:this.props.data.user_name,
        user_id: this.props.data.user_id,
        vehicle_reg_no: this.props.data.vehicle_reg_no,
        vehicle_engine_no: this.props.data.vehicle_engine_no,
        vehicle_chassis_no: this.props.data.vehicle_chassis_no,
        vehicle_owner: this.props.data.vehicle_owner,
        vehicle_owner_mobile: this.props.data.vehicle_owner_mobile,
        vehicle_registration_date: this.props.data.vehicle_registration_date,
        fitness_certificate_date: this.props.data.fitness_certificate_date,
        puc_validity_date: this.props.data.puc_validity_date,
        rc_validity_date: this.props.data.rc_validity_date,
        insurance_validity_date: this.props.data.insurance_validity_date,
        vehicle_net_weight: this.props.data.vehicle_net_weight,
        vehicle_tare_weight: this.props.data.vehicle_tare_weight,
        vehicle_passing_capacity: this.props.data.vehicle_passing_capacity,
        vehicle_service_type: this.props.data.vehicle_service_type,
        gpsAvailable: this.props.data.gpsAvailable,
        container_no: this.props.data.container_no,
        container_weight: this.props.data.container_weight,
        seal_no: this.props.data.seal_no,
        excise_sNo: this.props.data.excise_sNo,
        gross_weight: this.props.data.gross_weight,
        preferredroute: this.props.data.preferredroute || [],
        gps_availability:this.props.data.gps_availability ,
        gpsCompanies: this.props.data.gpsCompanies || [],
        // user_name: this.props.data.user_name,
        has_compartment: this.props.data.has_compartment,
        vehicle_class_type: this.props.data.vehicle_class_type,
        compartment_capacity: this.props.data.compartment_capacity,
        tanker_capacity: this.props.data.tanker_capacity,
        fitness_no: this.props.data.fitness_no,
        fitness_certificate_img: this.props.data.fitness_certificate_img,
        puc_no: this.props.data.puc_no,
        puc_validity_img: this.props.data.puc_validity_img,
        rc_no: this.props.data.rc_no,
        rc_validity_img: this.props.data.rc_validity_img,
        calibration_no: this.props.data.calibration_no,
        calibration_date: this.props.data.calibration_date ? moment(this.props.data.calibration_date).format("DD/MM/YYYY") : "",
        calibration_img: this.props.data.calibration_img,
        explosive_no: this.props.data.explosive_no,
        explosive_date: this.props.data.explosive_date ? moment(this.props.data.explosive_date).format("DD/MM/YYYY") : "",
        explosive_img: this.props.data.explosive_img,
        insurance_no: this.props.data.insurance_no,
        insurance_validity_img: this.props.data.insurance_validity_img,
        national_permit_no: this.props.data.national_permit_no,
        national_permit_date: this.props.data.national_permit_date ? moment(this.props.data.national_permit_date).format("DD/MM/YYYY") : "",
        national_permit_img: this.props.data.national_permit_img,
        half_year_no: this.props.data.half_year_no,
        half_year_date: this.props.data.half_year_date ? moment(this.props.data.half_year_date).format("DD/MM/YYYY") : "",
        half_year_img: this.props.data.half_year_img,
        safety_valve_no: this.props.data.safety_valve_no,
        safety_valve_date: this.props.data.safety_valve_date ? moment(this.props.data.safety_valve_date).format("DD/MM/YYYY") : "",
        safety_valve_img: this.props.data.safety_valve_img,
        suraksha_certificate_no: this.props.data.suraksha_certificate_no,
        suraksha_certificate_date: this.props.data.suraksha_certificate_date ? moment(this.props.data.suraksha_certificate_date).format("DD/MM/YYYY") : "",
        suraksha_certificate_img: this.props.data.suraksha_certificate_img,
        hazardous_no: this.props.data.hazardous_no,
        hazardous_date: this.props.data.hazardous_date ? moment(this.props.data.hazardous_date).format("DD/MM/YYYY") : "",
        hazardous_img: this.props.data.hazardous_img,
        tanker_cleaning_certificate_no: this.props.data.tanker_cleaning_certificate_no,
        tanker_cleaning_certificate_date: this.props.data.tanker_cleaning_certificate_date ? moment(this.props.data.tanker_cleaning_certificate_date).format("DD/MM/YYYY") : "",
        tanker_cleaning_certificate_img: this.props.data.tanker_cleaning_certificate_img,
        vehicle_age: this.props.data.vehicle_age,
        compartment_details: this.props.data.compartmentDetails ? this.props.data.compartmentDetails : [...compartmentDetails],
        vehicles_id: this.props.data.vehicles_id,
        vehicles_service_id: this.props.data.vehicles_service_id,
        block_data: this.props.data.block_data || [],
        fuel_name: this.props.data.fuel_name,
        // blocked_by: JSON.parse(getCookie("user")).user_name,
        // weightErrorMsg:''
      },
      tabs: [
        {label: "Vehicle details", id: 1},
        {label: "Compartment Details", id: 2}
      ],
      selectedOption : 1,
      blockedUser: false
    };
  }

  componentDidMount() {
    this.props.getVehicleTypes();
    this.props.getFuleTypes();
    this.getGpsVendorData();
    setItemToSessionStorage('vehicleIn', this.props.data)
  }

  getGpsVendorData = () => {
    const { dispatch } = this.props;
    dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING.GET_GPS_VENDOR_DATA});
  }

  handleChange = (id, selectedOption) => {
    let { data } = this.state;
    if(id === 'vehicle_service_type'){
      data.has_compartment = selectedOption.has_compartment;
      data.vehicles_service_id = selectedOption.value;
    }
    data[id] = selectedOption.label;
    this.setState({ data });
  };

  onChangeHandler = (value, id, isType) => {
    let { data } = this.state;
    const result = validationCheck(value, this.state.data[id], isType);
    if(id === 'vehicle_tare_weight' || id === 'gross_weight') {
          data.vehicle_net_weight = (id === 'vehicle_tare_weight')  ? (+data.gross_weight  - +value) : (+value - +data.vehicle_tare_weight);
      }
      data[id] = result;
   this.setState({ data });
   if(data.vehicle_net_weight < 0){
    this.setState({ weightErrorMsg: "Tare weight should be less than gross weight" })
  }else{
    this.setState({ weightErrorMsg:null })
  }
  };
  
  SelectDate = (id, selectedOption) => {
    let { data } = this.state;
    data[id] = selectedOption;
    this.setState({ data });
  };

  validateCheck = () => {
    const { data } = this.state;
    if ( this.state.selectedOption === 1 ?
      (data.company_id &&
        data.vehicle_reg_no &&
      data.vehicle_engine_no &&
      data.vehicle_chassis_no &&
      data.vehicle_owner &&
      data.vehicle_owner_mobile &&
      data.vehicle_registration_date &&
      data.fitness_certificate_date &&
      data.rc_validity_date &&
      data.insurance_validity_date &&
      data.gross_weight&&
      data.vehicle_tare_weight&&
      data.vehicle_net_weight &&
      data.vehicle_passing_capacity &&
      data.fitness_certificate_img&&
      data.rc_validity_img &&
      data.insurance_validity_img) :
      ( data.vehicle_reg_no &&
        data.vehicle_engine_no &&
        data.vehicle_chassis_no &&
        data.vehicle_owner &&
        data.vehicle_owner_mobile &&
        data.vehicle_registration_date &&
        data.fitness_certificate_date &&
        data.rc_validity_date &&
        data.insurance_validity_date &&
        data.vehicle_net_weight &&
        data.vehicle_passing_capacity &&
        data.fitness_certificate_img &&
        data.rc_validity_img &&
        data.insurance_validity_img &&
        data.fitness_no &&
        data.rc_no &&
        data.insurance_no &&
        data.explosive_no &&
        data.explosive_date &&
        data.national_permit_no &&
        data.national_permit_date &&
        data.calibration_date &&
        data.calibration_no)
    ) {
      return true;
    } else return false;
  };
  onSave = () => {
    const { data } = this.state;
    if (this.validateCheck()) {
      
      data.rc_validity_date = data.rc_validity_date ? data.rc_validity_date.valueOf() : undefined;
      data.vehicle_registration_date = data.vehicle_registration_date ? data.vehicle_registration_date.valueOf() : undefined;
      data.fitness_certificate_date = data.fitness_certificate_date ? data.fitness_certificate_date.valueOf() : undefined;
      data.insurance_validity_date = data.insurance_validity_date ? data.insurance_validity_date.valueOf() : undefined;
      data.puc_validity_date = data.puc_validity_date ? data.puc_validity_date.valueOf() : undefined;
      this.props.toggleAdd("Edit");
      this.props.updateTruckInfo(data);
    } else {
      this.setState({ errorMsg: "Please add all mandatory fields" });
    }
  };

  addLocation = () => {
    let { preferredroute } = this.state.data;
    preferredroute.push({ source: "", destination: "" });
    this.setState({ preferredroute });
  };
  deleteLocation = (index) => {
    const { preferredroute } = this.state;
    preferredroute.splice(index, 1);
    this.setState({ preferredroute });
  };

  handleSwitchChange = (name) => (checked) => {
    let { data } = this.state;
    data.gps_availability=checked;
    this.setState({ data });
  };
  // handleSwitchChange = (name) => (checked) => {
  //   // let {gps_availability}=this.state;
  //   this.setState({ gps_availability: checked });
  // };
  addGps = () => {
    let { gpsCompanies } = this.state.data;
    gpsCompanies.push({ gps_company: "" });
    this.setState({ gpsCompanies });
  };
  deleteGps = (index) => {
    const { gpsCompanies } = this.state.data;
    gpsCompanies.splice(index, 1);
    this.setState({ gpsCompanies });
  };
  uploadfiles = (e, name) => {
    const { dispatch } = this.props;
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    dispatch({
      type: ACTION.DATA_GOVERNANCE.UPLOAD_VEHICLE_FIELS,
      formData,
      onSuccess: (details) => {
        const { data } = this.state;
        data[name] = details;
        this.setState({ data });
      },
    });
   
  };
  onUpload = (name) => {
    this[name].click();
  };
  onTabChange = (tabId) => {
    this.setState({
      selectedOption : tabId
    })
  }
  handleSwitch = (checked) => {
    let {data}=this.state;
    data.is_blocked=checked;
    data.block_remarks='';
    this.setState( { data, blockedUser: true } );
  };
  render() {
    let block=this.state.data.is_blocked;
    let { block_data,gps_availability } = this.state.data;
    let { data, blockedUser } = this.state;
    const {gpsVendorData } = this.props.master;
    console.log(data.gpsCompanies)
    const gpsVendorList = 
    (gpsVendorData || []).map((data) => ({ 
      label: data.name,
      value: data.id
    })) || [];
    const vehicleSizeListName =
      (this.props.VehicleTypes || []).map((data) => ({
        label: data.name,
        value: data.id,
        has_compartment: data.has_compartment
      })) || [];
      const FuleTypeListName =
      (this.props.FuleTypes || []).map((data) => ({
        label: data.name,
        value: data.id,
        has_compartment: data.has_compartment
      })) || [];
    let isBlocked = block_data.filter(block => block.company_id === getUserCompanyId() && block.is_blocked === true)
    if(!blockedUser){
      data.is_blocked = (isBlocked && isBlocked.length > 0) ? isBlocked[0].is_blocked : false;
      data.block_remarks = (isBlocked && isBlocked.length > 0) ? isBlocked[0].block_remarks : '';
      data.blocked_by = (isBlocked && isBlocked.length > 0) ? isBlocked[0].blocked_by : '';
    } 
    return (
      <div className="add-user-wrap">
        <div onClick={this.props.toggleAdd} className="overlay"></div>
        <div id="modal" className="modal-popup" style={{width: '1200px'}}>
          <div className="pop-heading">Edit Vehicle Info</div>
          {data.has_compartment &&
          <div className = "sub-navigation-holder">
          {
            this.state.tabs.map(tab => {
              return(
              <div key={tab.id} className={this.state.selectedOption === tab.id
                ? "selected-option options"
                : "options"} onClick={() => this.onTabChange(tab.id)}>{tab.label}</div>
              )})
          }
        </div>}
        {this.state.selectedOption === 1 &&
            <Fragment>
            <div className="content add-item-details">
            <div className="select-wrap-vehicle">
              <InputText
                label="Vehicle Reg No"
                id="vehicle_reg_no"
                value={data.vehicle_reg_no}
                placeholder="Vehicle Reg No"
                changeHandler={this.onChangeHandler}
                required
              />
            </div>

            <div className="select-wrap-vehicle">
              <InputText
                label="Vehicle Engine No"
                id="vehicle_engine_no"
                value={data.vehicle_engine_no}
                placeholder="Vehicle Engine No"
                changeHandler={this.onChangeHandler}
                required
              />
            </div>

            <div className="select-wrap-vehicle">
              <InputText
                label="Chassis No"
                id="vehicle_chassis_no"
                value={data.vehicle_chassis_no}
                placeholder="Chassis No"
                // changeHandler={(e) => this.setState({age: e})}
                changeHandler={this.onChangeHandler}
                required
              />
            </div>

            {/* <div style={{ display: 'flex' }}> */}
              <div className="select-wrap-vehicle">
                <InputText
                  label="Vehicle Owner"
                  id="vehicle_owner"
                  value={data.vehicle_owner}
                  placeholder="Vehicle Owner"
                  changeHandler={this.onChangeHandler}
                  required
                />
              </div>

              <div className="select-wrap-vehicle">
                <InputText
                  label="Contact No"
                  id="vehicle_owner_mobile"
                  value={data.vehicle_owner_mobile}
                  placeholder="Contact No"
                  changeHandler={this.onChangeHandler}
                  length={"10"}
                  isValidate={"numeric"}
                  required
                />
              </div>
              <div className="select-wrap-vehicle">
                <div className="label">Vehicle Type <span className={"error error-font"}>*</span>
                </div>
                <Select
                  className="item-select"
                  value={vehicleSizeListName.find((val) => {
                    return val.label == data.vehicle_service_type;
                  })}
                  
                  options={vehicleSizeListName}
                  onChange={this.handleChange.bind(this, "vehicle_service_type")}
                />
              </div>
              <div className="select-wrap-vehicle">
                <div className="label">Fule Type <span className={"error error-font"}>*</span>
                </div>
                <Select
                  className="item-select"
                  value={FuleTypeListName.find((val) => {
                    return val.label == data.fuel_name;
                  })}
                  
                  options={FuleTypeListName}
                  onChange={this.handleChange.bind(this, "fuel_name")}
                />
              </div>

              {data.has_compartment &&
              <Fragment>
                <div className="select-wrap-vehicle">
                  <InputText
                    label="Vehicle Class Type"
                    id="vehicle_class_type"
                    value={data.vehicle_class_type}
                    placeholder="Vehicle Class Type"
                    changeHandler={this.onChangeHandler}
                    
                  />
                </div>

                <div className="select-wrap-vehicle">
                  <InputText
                    label="Compartment Capacity"
                    id="compartment_capacity"
                    value={data.compartment_capacity}
                    placeholder="Compartment Capacity"
                    changeHandler={this.onChangeHandler}
                    
                  />
                </div>

                <div className="select-wrap-vehicle">
                  <InputText
                    label="Tanker Capacity"
                    id="tanker_capacity"
                    value={data.tanker_capacity}
                    placeholder="Tanker Capacity"
                    changeHandler={this.onChangeHandler}
                    
                  />
                </div>
              </Fragment>
              }

            <div className="select-wrap-vehicle">
                <div className="data-picker">
                  <div className="label">
                    Vehicle Registration Date
                    <span className="error-color">*</span>
                  </div>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    value={data.vehicle_registration_date ? moment(data.vehicle_registration_date).format('DD/MM/yyyy') : null}
               
                    onChange={this.SelectDate.bind(
                      this,
                      "vehicle_registration_date"
                    )}
                    placeholderText={"DD/MM/YYYY"}
                    maxDate={new Date()}
                  />
                </div>
                <div></div>
              </div>
              
              {data.has_compartment &&
                <div className="select-wrap-vehicle">
                  <InputText
                    label="Fitness No."
                    id="fitness_no"
                    value={data.fitness_no}
                    placeholder="Fitness No."
                    changeHandler={this.onChangeHandler}
                    required
                  />
                </div>}
              <div className="select-wrap-vehicle">
                <div className="data-picker">
                <div className="label">
                    Fitness Certificate Validity Date{" "}
                    <span className="error-color">*</span>
                  </div>
                  <div className={'flex'}>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    // value={data.fitness_certificate_date}
                    value={data.fitness_certificate_date ? moment(data.fitness_certificate_date).format('DD/MM/yyyy') : null}
               
                    onChange={this.SelectDate.bind(
                      this,
                      "fitness_certificate_date"
                    )}
                    placeholderText={"DD/MM/YYYY"}
                  />
                  <Upload
                 className=" ml-10 curP"
                 onClick={() => this.onUpload('uploadFitnessUrl')}
                 value="Upload file"
               /><span className="error-color">*</span></div>
                
                <input
                type="file"
                style={{ display: "none" }}
                ref={(e) => {
                  this.uploadFitnessUrl = e;
                }}
                onChange={(e) => {
                  this.uploadfiles(e, "fitness_certificate_img");
                }}
                onClick={(e) => {
                  e.target.value = null;
                }}
                  />
               </div>
                
               
                <div>
                  {data.fitness_certificate_img &&
                    data.fitness_certificate_img.name ? (
                    <a
                      href={data.fitness_certificate_img.url}
                      download
                      className={"theme-color fs-12 mt-5"}
                    >
                      {data.fitness_certificate_img.name}
                    </a>
                  ) : null}
                </div>
              </div>
              {data.has_compartment &&
                <div className="select-wrap-vehicle">
                  <InputText
                    label="PUC No."
                    id="puc_no"
                    value={data.puc_no}
                    placeholder="PUC No."
                    changeHandler={this.onChangeHandler}
                    required
                  />
                </div>}
              <div className="select-wrap-vehicle">
                <div className="data-picker">
                  <div className="label">
                    Puc Validity Date <span className="error-color">*</span>
                  </div>
                  <div className={'flex'}>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    // value={data.puc_validity_date}
                    onChange={this.SelectDate.bind(this, "puc_validity_date")}
                    value={data.puc_validity_date ? moment(data.puc_validity_date).format('DD/MM/yyyy') : null}
               
                    placeholderText={"DD/MM/YYYY"}
                  />
                  <Upload
                  className="ml-10  curP"
                  onClick={() => this.onUpload('uploadPUCUrl')}
                  value="Upload file"
                />
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={(e) => {
                    this.uploadPUCUrl = e;
                  }}
                  onChange={(e) => {
                    this.uploadfiles(e, "puc_validity_img");
                  }}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                /></div>
                  
                </div>
                
                <div>
                  {data.puc_validity_img &&
                    data.puc_validity_img.name ? (
                    <a
                      href={data.puc_validity_img.url}
                      download
                      className={"theme-color fs-12 mt-5"}
                    >
                      {data.puc_validity_img.name}
                    </a>
                  ) : null}
                </div>
              </div>
              {/* </div> */}

              {data.has_compartment &&
                <div className="select-wrap-vehicle">
                  <InputText
                    label="RC Validity No."
                    id="rc_no"
                    value={data.rc_no}
                    placeholder="RC Validity No."
                    changeHandler={this.onChangeHandler}
                    required
                  />
                </div>}
            {/* <div style={{ display: 'flex' }}> */}
            <div className="select-wrap-vehicle">
            <div className="data-picker">
            <div className="label">
                RC Validity Date{" "}
                <span className="error-color">*</span>
              </div>
              <div className={'flex'}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                // value={data.rc_validity_date}
                value={data.rc_validity_date ? moment(data.rc_validity_date).format('DD/MM/yyyy') : null}
               
                onChange={this.SelectDate.bind(
                  this,
                  "rc_validity_date"
                )}
                placeholderText={"DD/MM/YYYY"}
              />
              <Upload
             className=" ml-10 curP"
             onClick={() => this.onUpload('uploadRCUrl')}
             value="Upload file"
           /><span className="error-color">*</span></div>
            
            <input
            type="file"
            style={{ display: "none" }}
            ref={(e) => {
              this.uploadRCUrl = e;
            }}
            onChange={(e) => {
              this.uploadfiles(e, "rc_validity_img");
            }}
            onClick={(e) => {
              e.target.value = null;
            }}
              />
           </div>
            
           
            <div>
              {data.rc_validity_img &&
                data.rc_validity_img.name ? (
                <a
                  href={data.rc_validity_img.url}
                  download
                  className={"theme-color fs-12 mt-5"}
                >
                  {data.rc_validity_img.name}
                </a>
              ) : null}
            </div>
          </div>

          {data.has_compartment &&
          <Fragment>
                <div className="select-wrap-vehicle">
                  <InputText
                    label="Calibration No."
                    id="calibration_no"
                    value={data.calibration_no}
                    placeholder="Calibration No."
                    changeHandler={this.onChangeHandler}
                    required
                  />
                </div>
            {/* <div style={{ display: 'flex' }}> */}
            <div className="select-wrap-vehicle">
            <div className="data-picker">
            <div className="label">
                Calibration Date{" "}
                <span className="error-color">*</span>
              </div>
              <div className={'flex'}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                value={data.calibration_date}
                onChange={this.SelectDate.bind(
                  this,
                  "calibration_date"
                )}
                placeholderText={"DD/MM/YYYY"}
              />
              <Upload
             className=" ml-10 curP"
             onClick={() => this.onUpload('uploadCalUrl')}
             value="Upload file"
           /></div>
            
            <input
            type="file"
            style={{ display: "none" }}
            ref={(e) => {
              this.uploadCalUrl = e;
            }}
            onChange={(e) => {
              this.uploadfiles(e, "calibration_img");
            }}
            onClick={(e) => {
              e.target.value = null;
            }}
              />
           </div>
            
           
            <div>
              {data.calibration_img &&
                data.calibration_img.name ? (
                <a
                  href={data.calibration_img.url}
                  download
                  className={"theme-color fs-12 mt-5"}
                >
                  {data.calibration_img.name}
                </a>
              ) : null}
            </div>
          </div>
          </Fragment>}

          {data.has_compartment &&
          <Fragment>
                <div className="select-wrap-vehicle">
                  <InputText
                    label="Explosive No."
                    id="explosive_no"
                    value={data.explosive_no}
                    placeholder="Explosive No."
                    changeHandler={this.onChangeHandler}
                    required
                  />
                </div>
            {/* <div style={{ display: 'flex' }}> */}
            <div className="select-wrap-vehicle">
            <div className="data-picker">
            <div className="label">
                Explosive Date{" "}
                <span className="error-color">*</span>
              </div>
              <div className={'flex'}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                value={data.explosive_date}
                onChange={this.SelectDate.bind(
                  this,
                  "explosive_date"
                )}
                placeholderText={"DD/MM/YYYY"}
              />
              <Upload
             className=" ml-10 curP"
             onClick={() => this.onUpload('uploadExpUrl')}
             value="Upload file"
           /><span className="error-color">*</span></div>
            
            <input
            type="file"
            style={{ display: "none" }}
            ref={(e) => {
              this.uploadExpUrl = e;
            }}
            onChange={(e) => {
              this.uploadfiles(e, "explosive_img");
            }}
            onClick={(e) => {
              e.target.value = null;
            }}
              />
           </div>
            
           
            <div>
              {data.explosive_img &&
                data.explosive_img.name ? (
                <a
                  href={data.explosive_img.url}
                  download
                  className={"theme-color fs-12 mt-5"}
                >
                  {data.explosive_img.name}
                </a>
              ) : null}
            </div>
          </div>
          </Fragment>}
          
          {data.has_compartment &&
            <div className="select-wrap-vehicle">
              <InputText
                label="Insurance No."
                id="insurance_no"
                value={data.insurance_no}
                placeholder="Insurance No."
                changeHandler={this.onChangeHandler}
                required
              />
            </div>}
          <div className="select-wrap-vehicle">
          <div className="data-picker">
          <div className="label">
          Insurance validity Date
              <span className="error-color">*</span>
            </div>
            <div className={'flex'}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              // value={data.insurance_validity_date}
              
              value={data.insurance_validity_date ? moment(data.insurance_validity_date).format('DD/MM/yyyy') : null}
              onChange={this.SelectDate.bind(
                this,
                "insurance_validity_date"
              )}
              placeholderText={"DD/MM/YYYY"}
            />
            <Upload
           className=" ml-10 curP"
           onClick={() => this.onUpload('uploadInsuranceUrl')}
           value="Upload file"
         /><span className="error-color">*</span></div>
          
          <input
          type="file"
          style={{ display: "none" }}
          ref={(e) => {
            this.uploadInsuranceUrl = e;
          }}
          onChange={(e) => {
            this.uploadfiles(e, "insurance_validity_img");
          }}
          onClick={(e) => {
            e.target.value = null;
          }}
            />
         </div>
          
         
          <div>
            {data.insurance_validity_img &&
              data.insurance_validity_img.name ? (
              <a
                href={data.insurance_validity_img.url}
                download
                className={"theme-color fs-12 mt-5"}
              >
                {data.insurance_validity_img.name}
              </a>
            ) : null}
          </div>
        </div>

        {data.has_compartment &&
          <Fragment>
                <div className="select-wrap-vehicle">
                  <InputText
                    label="National Permit No."
                    id="national_permit_no"
                    value={data.national_permit_no}
                    placeholder="National Permit No."
                    changeHandler={this.onChangeHandler}
                    required
                  />
                </div>
            {/* <div style={{ display: 'flex' }}> */}
            <div className="select-wrap-vehicle">
            <div className="data-picker">
            <div className="label">
                National Permit Date{" "}
                <span className="error-color">*</span>
              </div>
              <div className={'flex'}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                value={data.national_permit_date}
                onChange={this.SelectDate.bind(
                  this,
                  "national_permit_date"
                )}
                placeholderText={"DD/MM/YYYY"}
              />
              <Upload
             className=" ml-10 curP"
             onClick={() => this.onUpload('uploadNPUrl')}
             value="Upload file"
           /></div>
            
            <input
            type="file"
            style={{ display: "none" }}
            ref={(e) => {
              this.uploadNPUrl = e;
            }}
            onChange={(e) => {
              this.uploadfiles(e, "national_permit_img");
            }}
            onClick={(e) => {
              e.target.value = null;
            }}
              />
           </div>
            
           
            <div>
              {data.national_permit_img &&
                data.national_permit_img.name ? (
                <a
                  href={data.national_permit_img.url}
                  download
                  className={"theme-color fs-12 mt-5"}
                >
                  {data.national_permit_img.name}
                </a>
              ) : null}
            </div>
          </div>
          </Fragment>}

          {data.has_compartment &&
          <Fragment>
                <div className="select-wrap-vehicle">
                  <InputText
                    label="Half Year No."
                    id="half_year_no"
                    value={data.half_year_no}
                    placeholder="Half Year No."
                    changeHandler={this.onChangeHandler}
                    
                  />
                </div>
            {/* <div style={{ display: 'flex' }}> */}
            <div className="select-wrap-vehicle">
            <div className="data-picker">
            <div className="label">
                Half Year Date{" "}
                
              </div>
              <div className={'flex'}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                value={data.half_year_date}
                onChange={this.SelectDate.bind(
                  this,
                  "half_year_date"
                )}
                placeholderText={"DD/MM/YYYY"}
              />
              <Upload
             className=" ml-10 curP"
             onClick={() => this.onUpload('uploadHYUrl')}
             value="Upload file"
           /></div>
            
            <input
            type="file"
            style={{ display: "none" }}
            ref={(e) => {
              this.uploadHYUrl = e;
            }}
            onChange={(e) => {
              this.uploadfiles(e, "half_year_img");
            }}
            onClick={(e) => {
              e.target.value = null;
            }}
              />
           </div>
            
           
            <div>
              {data.half_year_img &&
                data.half_year_img.name ? (
                <a
                  href={data.half_year_img.url}
                  download
                  className={"theme-color fs-12 mt-5"}
                >
                  {data.half_year_img.name}
                </a>
              ) : null}
            </div>
          </div>
          </Fragment>}

          {data.has_compartment &&
          <Fragment>
                <div className="select-wrap-vehicle">
                  <InputText
                    label="Safety Valve No."
                    id="safety_valve_no"
                    value={data.safety_valve_no}
                    placeholder="Safety Valve No."
                    changeHandler={this.onChangeHandler}
                    
                  />
                </div>
            {/* <div style={{ display: 'flex' }}> */}
            <div className="select-wrap-vehicle">
            <div className="data-picker">
            <div className="label">
                Safety Valve Date{" "}
                
              </div>
              <div className={'flex'}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                value={data.safety_valve_date}
                onChange={this.SelectDate.bind(
                  this,
                  "safety_valve_date"
                )}
                placeholderText={"DD/MM/YYYY"}
              />
              <Upload
             className=" ml-10 curP"
             onClick={() => this.onUpload('uploadSVUrl')}
             value="Upload file"
           /></div>
            
            <input
            type="file"
            style={{ display: "none" }}
            ref={(e) => {
              this.uploadSVUrl = e;
            }}
            onChange={(e) => {
              this.uploadfiles(e, "safety_valve_img");
            }}
            onClick={(e) => {
              e.target.value = null;
            }}
              />
           </div>
            
           
            <div>
              {data.safety_valve_img &&
                data.safety_valve_img.name ? (
                <a
                  href={data.safety_valve_img.url}
                  download
                  className={"theme-color fs-12 mt-5"}
                >
                  {data.safety_valve_img.name}
                </a>
              ) : null}
            </div>
          </div>
          </Fragment>}

          {data.has_compartment &&
          <Fragment>
                <div className="select-wrap-vehicle">
                  <InputText
                    label="Suraksha Certificate No."
                    id="suraksha_certificate_no"
                    value={data.suraksha_certificate_no}
                    placeholder="Suraksha Certificate No."
                    changeHandler={this.onChangeHandler}
                    
                  />
                </div>
            {/* <div style={{ display: 'flex' }}> */}
            <div className="select-wrap-vehicle">
            <div className="data-picker">
            <div className="label">
                Suraksha Certificate Date{" "}
              </div>
              <div className={'flex'}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                value={data.suraksha_certificate_date}
                onChange={this.SelectDate.bind(
                  this,
                  "suraksha_certificate_date"
                )}
                placeholderText={"DD/MM/YYYY"}
              />
              <Upload
             className=" ml-10 curP"
             onClick={() => this.onUpload('uploadSCUrl')}
             value="Upload file"
           /></div>
            
            <input
            type="file"
            style={{ display: "none" }}
            ref={(e) => {
              this.uploadSCUrl = e;
            }}
            onChange={(e) => {
              this.uploadfiles(e, "suraksha_certificate_img");
            }}
            onClick={(e) => {
              e.target.value = null;
            }}
              />
           </div>
            
           
            <div>
              {data.suraksha_certificate_img &&
                data.suraksha_certificate_img.name ? (
                <a
                  href={data.suraksha_certificate_img.url}
                  download
                  className={"theme-color fs-12 mt-5"}
                >
                  {data.suraksha_certificate_img.name}
                </a>
              ) : null}
            </div>
          </div>
          </Fragment>}
          {data.has_compartment &&
          <Fragment>
                <div className="select-wrap-vehicle">
                  <InputText
                    label="Hazardous No."
                    id="hazardous_no"
                    value={data.hazardous_no}
                    placeholder="Hazardous No."
                    changeHandler={this.onChangeHandler}
                    
                  />
                </div>
            {/* <div style={{ display: 'flex' }}> */}
            <div className="select-wrap-vehicle">
            <div className="data-picker">
            <div className="label">
                Hazardous Date{" "}
              </div>
              <div className={'flex'}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                value={data.hazardous_date}
                onChange={this.SelectDate.bind(
                  this,
                  "hazardous_date"
                )}
                placeholderText={"DD/MM/YYYY"}
              />
              <Upload
             className=" ml-10 curP"
             onClick={() => this.onUpload('uploadHCUrl')}
             value="Upload file"
           /></div>
            
            <input
            type="file"
            style={{ display: "none" }}
            ref={(e) => {
              this.uploadHCUrl = e;
            }}
            onChange={(e) => {
              this.uploadfiles(e, "hazardous_img");
            }}
            onClick={(e) => {
              e.target.value = null;
            }}
              />
           </div>
            
           
            <div>
              {data.hazardous_img &&
                data.hazardous_img.name ? (
                <a
                  href={data.hazardous_img.url}
                  download
                  className={"theme-color fs-12 mt-5"}
                >
                  {data.hazardous_img.name}
                </a>
              ) : null}
            </div>
          </div>
          </Fragment>}
          {data.has_compartment &&
          <Fragment>
                <div className="select-wrap-vehicle">
                  <InputText
                    label="Tanker Cleaning Certificate No."
                    id="tanker_cleaning_certificate_no"
                    value={data.tanker_cleaning_certificate_no}
                    placeholder="Tanker Cleaning Certificate No."
                    changeHandler={this.onChangeHandler}
                    
                  />
                </div>
            {/* <div style={{ display: 'flex' }}> */}
            <div className="select-wrap-vehicle">
            <div className="data-picker">
            <div className="label">
                Tanker Cleaning Certificate Date{" "}
              </div>
              <div className={'flex'}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                value={data.tanker_cleaning_certificate_date}
                onChange={this.SelectDate.bind(
                  this,
                  "tanker_cleaning_certificate_date"
                )}
                placeholderText={"DD/MM/YYYY"}
              />
              <Upload
             className=" ml-10 curP"
             onClick={() => this.onUpload('uploadTCUrl')}
             value="Upload file"
           /></div>
            
            <input
            type="file"
            style={{ display: "none" }}
            ref={(e) => {
              this.uploadTCUrl = e;
            }}
            onChange={(e) => {
              this.uploadfiles(e, "tanker_cleaning_certificate_img");
            }}
            onClick={(e) => {
              e.target.value = null;
            }}
              />
           </div>
            
           
            <div>
              {data.tanker_cleaning_certificate_img &&
                data.tanker_cleaning_certificate_img.name ? (
                <a
                  href={data.tanker_cleaning_certificate_img.url}
                  download
                  className={"theme-color fs-12 mt-5"}
                >
                  {data.tanker_cleaning_certificate_img.name}
                </a>
              ) : null}
            </div>
          </div>
          </Fragment>}
            {data.has_compartment &&
              <div className="select-wrap-vehicle">
                  <InputText
                    label="Vehicle Age"
                    id="vehicle_age"
                    value={data.vehicle_registration_date && moment().diff(data.vehicle_registration_date, 'years', true).toFixed(0) || ''}
                    placeholder="Vehicle Age"
                    changeHandler={this.onChangeHandler}
                    readOnly={true}
                  />
                </div>}
              {/* </div> */}
              <div className="select-wrap-vehicle">
              <InputText
                label="Gross Weight"
                placeholder="Gross Weight"
                id="gross_weight"
                value={data.gross_weight}
                // changeHandler={(e) => this.setState({gross_weight: Number(e)})}
                changeHandler={this.onChangeHandler}
                type="number"
                isValidate={'numeric'}
                required
              /></div>
              <div className="select-wrap-vehicle">
            <InputText
              label="Vehicle Tare Weight"
              placeholder="Vehicle Tare Weight"
              id="vehicle_tare_weight"
              value={data.vehicle_tare_weight}
              // changeHandler={(e) => this.setState({vehicle_tare_weight: Number(e)})}
              changeHandler={this.onChangeHandler}
              type="number"
              isValidate={'numeric'}
              required
            />
            {this.state.weightErrorMsg && (
              <div className="error-color" style={{fontSize:"13px"}}>{this.state.weightErrorMsg}</div>
            )}
            </div>
           
            <div className="select-wrap-vehicle">
              <InputText
                label="Vehicle Net Weight"
                placeholder="Vehicle Net Weight"
                id="vehicle_net_weight"
                value={data.vehicle_net_weight}
                readonly={true}
              changeHandler={this.onChangeHandler}
                type="number"
                required
              />
              </div>
            <div className="select-wrap-vehicle">
            <InputText
              label="Vehicle Passing Capacity"
              placeholder="Vehicle Passing Capacity"
              id="vehicle_passing_capacity"
              value={data.vehicle_passing_capacity}
              // changeHandler={(e) => this.setState({vehicle_passing_capacity: Number(e)})}
              changeHandler={this.onChangeHandler}
              required
              type="number"
              isValidate={'numeric'}
            /></div>
            <div className="select-wrap-vehicle">
            <InputText
              label="Container No"
              placeholder="Container No"
              id="container_no"
              value={data.container_no}
              changeHandler={this.onChangeHandler}
              type="number"
              isValidate={'numeric'}
            />
            </div>
            <div className="select-wrap-vehicle">
            <InputText
              label="Container Weight"
              placeholder="Container Weight"
              id="container_weight"
              value={data.container_weight}
              changeHandler={this.onChangeHandler}
              type="number"
              isValidate={'numeric'}
            />
            </div>
            <div className="select-wrap-vehicle">
            <InputText
              label="Seal No"
              placeholder="Seal No"
              id="seal_no"
              value={data.seal_no}
              changeHandler={this.onChangeHandler}
            />
            </div>
            <div className="select-wrap-vehicle">
            <InputText
              label="Excise No"
              placeholder="Excise No"
              id="excise_sNo"
              value={data.excise_sNo}
              changeHandler={this.onChangeHandler}
            />
          </div>
          </div>
          <div
            className="fBold fs-16 mt-10 mb-1 header-medium-color col-2 flex-1"
            style={{ marginLeft: '20px', color: "#868e96" }}
          >
            
            
           {AWL_COMPANY_IDS.includes(data.company_id) ? <span>Vehicle Block</span>:
           block ? "Black listed" : "White listed" }
            <div className="mt-10">
              <Switch
                onChange={this.handleSwitch}
                onColor="#528aea"
                checked={data.is_blocked}
              />
            </div>
          </div>

          {data.is_blocked ? (

            <div
              className="flex col-1"
              style={{ marginLeft: '20px' }}
            >
              <InputText
              placeholder={AWL_COMPANY_IDS.includes(data.company_id) ? "Block remark":"Black listed remark "  }
              label={AWL_COMPANY_IDS.includes(data.company_id) ? "Block remark":"Black listed remark "  }
                id={"block_remarks"}
                value={data.block_remarks}
                changeHandler={(value) => {
                  const { data } = this.state;
                  data.block_remarks = value;
                  this.setState({ data });
                }}
              />

            </div>
          ) : null}

          <div
            className="fBold fs-16 mt-10 mb-10 header-medium-color col-2 flex-1 mr-30"
            style={{ marginLeft: "20px", color: "#868e96" }}
          >
            GPS Available
            <div className="mt-10">
              <Switch
                onChange={this.handleSwitchChange()}
                onColor="#528aea"
                checked={data.gps_availability}
              />
            </div>
          </div>

          {data.gps_availability ? (
            <div className="flex">
              <div className="fBold fs-16 mt-10 mb-10 header-medium-color col-2 flex-1 mr-30">
                <div>
                  <div
                    style={{
                      marginLeft: "20px",
                      color: "#868e96",
                      display: "inline",
                    }}
                  >
                    GPS Companies
                    <div
                      className="button-add"
                      onClick={this.addGps}
                      style={{ margin: "auto", marginLeft: "10px" }}
                    >
                      ADD
                    </div>
                  </div>
                </div>
                {data.gpsCompanies.map((item, index) => (
                  <div
                    className="flex col-1"
                    style={{ marginLeft: "20px", marginTop: "15px" }}
                  >
                    {/* <InputText
                      placeholder="GPS Companies"
                      label="GPS Companies"
                      id={"GPS Companies"}
                      value={item.gps_company}
                      changeHandler={(value) => {
                        let { data } = this.state;
                        data.gpsCompanies[index].gps_company = value;
                        this.setState({ data });
                      }}
                    /> */}
                    <Select
                      className="item-select wt-220"
                      options={gpsVendorList}
                      value={(gpsVendorList || []).filter(obj => obj.label === item.gps_company)}
                      onChange={(value) => {
                        let { data } = this.state;
                        data.gpsCompanies[index].gps_company = value.label;
                        this.setState({ data });
                      }}
                    />
                    <div
                      className="button-add mt-1rem"
                      onClick={() => this.deleteGps(index)}
                      style={{
                        minWidth: "76px",
                        marginTop: "20px",
                        marginLeft: "148px",
                      }}
                    >
                      X
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          <div
            className="label"
            style={{
              fontSize: "20px",
              marginLeft: "20px",
              color: "#868e96",
              display: "inline",
              marginRight: "10px",
            }}
          >
            Add Location
          </div>
          {
            <div
              className="button-add"
              onClick={this.addLocation}
              style={{ margin: "auto" }}
            >
              ADD
            </div>
          }
          {data.preferredroute.map((value, index) => {
            return (
              <div className="details flex space-between" key={index}>
                <div
                  className="fiscal-section"
                  style={{
                    width: "24%",
                    marginLeft: "20px",
                    marginTop: "15px",
                  }}
                >
                  <InputText
                    placeholder="Source"
                    id={"source"}
                    label="SOURCE"
                    value={value.source}
                    changeHandler={(value) => {
                      let { data } = this.state;
                      data.preferredroute[index].source = value;
                      this.setState({ data });
                    }}
                  />
                </div>
                <div
                  className="fiscal-section"
                  style={{
                    width: "24%",
                    marginLeft: "20px",
                    marginTop: "15px",
                  }}
                >
                  <InputText
                    placeholder="Destinations"
                    id={"destination"}
                    label="Destination"
                    value={value.destination}
                    changeHandler={(value) => {
                      let { data } = this.state;
                      data.preferredroute[index].destination = value;
                      this.setState({ data });
                    }}
                  />
                </div>

                <div
                  className="button-add"
                  style={{ marginTop: "30px" }}
                  onClick={() => this.deleteLocation(index)}
                >
                  X
                </div>
              </div>
            );
          })}
          </Fragment>}
          
          {data.has_compartment && this.state.selectedOption === 2 &&
                  <div className="flex-between border-bottom-light pv-20 ht-400 overflowDiv">
                  <div className="mt-10">
                  {/* <span className="fSemibold"> {`Compartment Total: 
                    ${totalCapacity}`}</span> */}
                    <table className="mt-10">
                      <thead>
                         <tr>
                           <th></th>
                           <th>Liters</th>
                           <th>PL CM</th>
                           <th>DL CM</th>
                         </tr>
                      </thead>
                      <tbody>
                        {(data.compartment_details || []).map((val, index) => (
                        <tr>
                          <td className="fSemibold wt-150">{`Compartment ${val.compartment_no}`}</td>
                          <td>
                            <div className="compartment">
                            <InputText
                              type="number"
                              id={"capacity"}
                              placeholder="Capacity"
                              value={val.capacity}
                              changeHandler={(e) => this.handleCompartments(e, index, "capacity")}
                              
                            />
                            </div>
                          </td>
                          <td>
                          <div className="compartment">
                            <InputText
                              type="number"
                              id={"pl"}
                              placeholder="PL"
                              value={val.pl}
                              changeHandler={(e) => this.handleCompartments(e, index, "pl")}
                             
                            />
                            </div>
                          </td>
                          <td>
                          <div className="compartment">
                            <InputText
                              type="number"
                              id={"dl"}
                              placeholder="DL"
                              value={val.dl}
                              changeHandler={(e) => this.handleCompartments(e, index, "dl")}
                              className="compartment"
                            />
                            </div>
                          </td>
                        </tr>))}
                      </tbody>
                    </table>
                    
                  </div>
                  </div>}
          <div className="submit-button">
            {this.state.errorMsg && (
              <div className="error-color">{this.state.errorMsg}</div>
            )}
            <div className="button-section">
              <Button value="Save" click={this.onSave} />
            </div>
            <div className="button-section">
              <Button
                value="Cancel"
                click={() => this.props.toggleAdd("Edit")}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
	return {
		master: state.master,
	};
};

export default connect(mapStateToProps)(AddItem);