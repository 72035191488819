import React, { Component } from "react";
import "./vendorMappingList.less";
import Search from "../../common/components/search-text/search-text";
import Delete from "../../../assets/images/delete.svg";
import Edit from "../../../assets/images/edit.svg";
import InputModel from "./InputModel";
import { connect } from 'react-redux';
import ACTIONS from '../../common/action_constant';
import Button from '../../home/button-classic/button-classic';
import { ValidationError } from "schema-utils";
import { withRouter } from 'react-router';
import { getEmail, getUserId } from "../../../utils/common";
import { getItemFromSessionStorage } from "../../../utils/sessionStorage";

class VendorMappingList extends Component {
  constructor() {
    super();
    this.state = {
      modalOpen: false,
      limit: 10,
      offset: 0,
      editData: '',
		  search: '',
      prevDisabled: false,
      nextDisabled: false,
    }
  }

  UNSAFE_componentWillMount() {
    this.getVendorMaterialDetails();
    this.getCompanyTransporters();
  }

  getCompanyTransporters = () => {
    const { dispatch, companyId } = this.props;
    dispatch({type: ACTIONS.MASTER.TRANSPORTERS.GET_COMPANY_TRANSPORTERS, companyId, branchId: companyId});
  }

  getVendorMaterialDetails = () => {
    const { dispatch, companyId } = this.props;
    const { limit, offset, search } = this.state;
    dispatch({
      type: ACTIONS.HOME.GET_VENDOR_MATERIAL_DETAILS,
      param: {
        companyId,
        limit,
        offset,
        search
      }
    })
  }

  toggleAdd = (data = '') => {
    this.setState({ modalOpen: true,  editData: data});
  }

  toggle = () => {
    this.setState({ modalOpen: false, editData: '' });
  }

  uploadVendorDetails = (e) =>{
    let formData = new FormData();
    const { dispatch , companyId} = this.props;
    const userEmail = getEmail();
    formData.append('file', e.target.files[0]);
    dispatch({
       type :ACTIONS.HOME.UPLOAD_VENDOR_MATERIAL_DETAILS,
       data: formData,
       companyId: companyId,
       userEmail,
       onSuccess: ((data) => {
         if(data)
         this.getVendorMaterialDetails()
       })
    })
  }

  updateButtonStates() {
    const { offset, limit } = this.state;
    const { vendorMaterialCount }=this.props;
    this.getVendorMaterialDetails();

   this.setState({
         prevDisabled: offset === 0,
         nextDisabled: offset + limit >= vendorMaterialCount,
      });
  }

  previous = () => {
    if (this.state.offset > 0) {
    this.setState(prevState => ({
        offset: (prevState.offset - prevState.limit),
    }), this.updateButtonStates);
  }
}

  next = () => {
    this.setState(prevState => ({
        offset: (prevState.offset + prevState.limit)
    }), this.updateButtonStates);
  }

  downloadVendorMaterialDetails = (actionType) => {
    const { dispatch, companyId } = this.props;
    dispatch({ 
      type : ACTIONS.HOME.DOWNLOAD_VENDOR_MATERIAL_DETAILS, 
      param: {
        companyId
      },
      actionType,
      onSuccess: ((data) => {
        // this.setState({
        //     downloadLink: data[0].Link
        // });
        this.downloadBtn.href = data[0].Link;
        this.downloadBtn.click();
      })
    });
  }

addMaterialVendorDetails = (data) => {
  const { dispatch, companyId } = this.props;
  data['master_type'] = 8;
	data['user_email'] = getEmail();
	data['new_flag'] = true;
	data['user_id'] = getUserId();
  dispatch({
    type: ACTIONS.HOME.ADD_VENDOR_MATERIAL_DETAILS,
    companyId,
    data,
    onSuccess: () => {
      this.toggle();
      this.getVendorMaterialDetails();
    }
  })
}

updateMaterialVendorDetails = (data) => {
  const previousData = getItemFromSessionStorage('vendorIn');
  previousData['update_flag'] = true;
  previousData['user_email'] = getEmail();
	previousData['user_id']= getUserId();
  previousData['master_type'] = 8;
  const { dispatch } = this.props;
  data['master_type'] = 8;
	data['user_email'] = getEmail();
	data['update_flag'] = true;
  data['user_id'] = getUserId();
  data['previous_data'] = previousData;
  dispatch({
    type: ACTIONS.HOME.UPDATE_VENDOR_MATERIAL_DETAILS,
    data,
    onSuccess: () => {
      this.toggle();
      this.getVendorMaterialDetails();
    }
  })
}

deleteVendorMaterialDetails = (id, sap_material_code) => {
  const { dispatch, companyId } = this.props;
  dispatch({
    type: ACTIONS.HOME.DELETE_VENDOR_MATERIAL_DETAILS,
    data: {
      materialcode_id: id,
      user_id: getUserId(),
      user_email: getEmail(),
      master_type: 8,
      sap_material_code: sap_material_code,
      company_id:  companyId,
      delete_flag: true,
      
    },
    onSuccess: this.getVendorMaterialDetails
  })
}

changeHandler = (value) => {
  this.setState({ search: value });
}

onSearch = () => {
  this.setState({ offset: 0 }, this.getVendorMaterialDetails);
}

clearFilterOnCancel = () => {
  this.setState({ search: '', offset: 0 }, this.getVendorMaterialDetails);
}

handleOnClickForAuditBtn = () => {
  const {history, companyId} = this.props;
  const masterType = 8
  const navUrl = `/auditScreen/${companyId}/${masterType}`;
  history.push(navUrl)
}

  render() {

    const { modalOpen } = this.state;
    const { vendorMaterialData, companyTransporters, vendorWithSapcode } = this.props;

    return (
      <div>
        <div className={"vendor-mapping"}>
          <div className="vendor_btn">
            <Search id = "search" value = {this.state.search} placeholder = "Search..." changeHandler = {this.changeHandler} click = {this.onSearch} canClear={this.state.search !== ''} handleClearFilter={this.clearFilterOnCancel} />

            <button
              value={"+ Vendor"}
              className="button-classic"
              onClick={() => this.toggleAdd()}
            >
              + Vendor
            </button>
            <button
              className="button-classic"
              onClick={this.handleOnClickForAuditBtn}
            >
              Audit
            </button>

            <button className="button-classic" onClick={() => this.downloadVendorMaterialDetails(1)}>  Download Template </button>
            <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input}  download />
            <button className="button-classic" onClick={() => {this.uploadExcel.click()}}> Upload </button>
            <input type='file' style ={{display :'none'}} ref={(e) => { this.uploadExcel = e ;}} onChange={this.uploadVendorDetails} onClick={(e) => {e.target.value = null }}/>
            <button className="button-classic" onClick={() => this.downloadVendorMaterialDetails(2)}>  Download Data </button>
            {/* <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download /> */}
          </div>
          <div className={"mapping-details-section"}>
            <div className={"list-section header"}>
              <div className={"tabel-header"}>Material Code</div>
              <div className={"tabel-header"}>Material Code Description</div>
              <div className={"tabel-header "}>UOM</div>
              <div className={'table-header weight'}>
                  <div className={'label'}>Vendor</div>
                  <div className={'slab-weight'}>
                    <div>Vendor Name</div>
                    <div>SAP Code</div>
                  </div>
              </div>
              <div className={"tabel-header"}>Action</div>
            </div>

            {(vendorMaterialData || []).map((val,index) => (
            <div className={"list-section content"}>
              <div className={"tabel-header"}>{val.sap_material_code}</div>
              <div className={"tabel-header"}>{val.material_code_description}</div>
              <div className={"tabel-header "}>{val.uom}</div>
              <div className={'table-row slab-weight'}>
                {(val.vendor_name || []).map((vendor, index) => {
                    return (
                      <div className={'slab-weight-data row-data'} style={{ 'marginTop': '5px' }}>
                          <div className="vendor">{vendor.name}</div>
                          <div>{vendor.sap_code}</div>
                      </div>
                    );
                })}
              </div>

              <div className={"tabel-header"} style={{ width: "140px" }}>
                <div
                  className={"delete-icon"}
                  style={{ backgroundImage: `url(${Edit})` }}
                  onClick={() => this.toggleAdd(val)}
                ></div>
                <div
                  className={"delete-icon"}
                  style={{ backgroundImage: `url(${Delete})` }}
                  onClick={() => this.deleteVendorMaterialDetails(val.materialcode_id, val.sap_material_code)}
                ></div>
              </div>
            </div>))}
          </div>
          <div className = "submit-section-pagination">
            <div className ="next">
              <Button value = "Next" click = {this.next} disabled={this.state.nextDisabled}/>    
            </div>
            <div className ="pre">
              <Button value = "Previous" click = {this.previous} disabled={this.state.prevDisabled}/>
            </div>
          </div>
          {modalOpen && (
            <InputModel
              isOpen={modalOpen}
              toggleAdd={this.toggleAdd}
              toggle={this.toggle}
              isEditData={this.state.editData}
              companyTransporters={companyTransporters}
              updateMaterialVendorDetails={this.updateMaterialVendorDetails}
              addMaterialVendorDetails={this.addMaterialVendorDetails}
              vendorWithSapcode={vendorWithSapcode}
            />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      vendorMaterialData : state.home.vendorMaterialData || [],
      companyTransporters: state.master.companyTransporters || [],
      vendorWithSapcode: state.master.vendorWithSapcode || [],
      vendorMaterialCount: state.home.vendorMaterialCount || null,
  };
};

export default connect(mapStateToProps)(withRouter(VendorMappingList));
