import React, { Component } from "react";
import InputText from "../../../../../common/components/input-text/input-text";
import Button from "../../../../../home/button-classic/button-classic";
import { getCookie } from "../../../../../../utils/cookies.js";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import ACTION from "../../../../../common/action_constant";
import Switch from "react-switch";
import { validationCheck } from "../../../../../../utils/validate";

class AddItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company_id: JSON.parse(getCookie("user")).company_id,
      user_id: JSON.parse(getCookie("user")).user_id,
      user_name: JSON.parse(getCookie("user")).name,
      driver_name: "",
      cell_no: "",
      dl_no: "",
      driver_acc_no: "",
      driver_ifsc_code: "",
      driver_alternative_location_id: "",
      driver_detail_validity_date: "",
      preferredroute: [],
      dlName: '',
      is_blocked: false,
      block_remarks: "",
      blocked_by: ""
    };
  }
  componentDidMount() { }
  handleChange = (id, selectedOption) => {
    this.setState({ [id]: selectedOption.label });
  };
  validateCheck = () => {
    const { driver_name, cell_no, dl_no, driver_detail_validity_date } = this.state;
    if (driver_name && cell_no && dl_no && driver_detail_validity_date) {
      return true;
    } else return false;
  };

  onSave = () => {
    const data = this.state;
    if (this.validateCheck()) {
      this.props.toggleAdd();
      this.props.addDriverInfo(data);
    } else {
      this.setState({ errorMsg: "Please add all mandatory fields" });
    }
  };
  addLocation = () => {
    let { preferredroute } = this.state;
    preferredroute.push({ source: "", destination: "" });
    this.setState({ preferredroute });
  };
  deleteLocation = (index) => {
    const { preferredroute } = this.state;
    preferredroute.splice(index, 1);
    this.setState({ preferredroute });
  };

  SelectDate = (id, selectedOption) => {
    this.setState({ [id]: selectedOption.valueOf() });
  };

  onChangeHandler = (value, id, isType) => {
    if (id === 'driver_ifsc_code' || id === 'dl_no'){
      value = value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g,'');
		}
    const result = validationCheck(value, this.state[id], isType);
    this.setState({ [id]: result });
  }

  uploadfiles = (e, name) => {
    const { dispatch } = this.props;
    const { driverinfo } = this.state;
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    dispatch({
      type: ACTION.DATA_GOVERNANCE.UPLOAD_VEHICLE_FIELS,
      formData,
      onSuccess: (data) => {
        this.setState({ [name]: data });
      },
    });
  };
  onUpload = () => {
    this.uploadUrl.click();
  };
  handleSwitch = (name) => (checked) => {

    this.setState({ is_blocked: checked });
  };
  render() {
    const { preferredroute, driverinfo, is_blocked } = this.state;

    return (
      <div className="add-user-wrap">
        <div onClick={this.props.toggleAdd} className="overlay"></div>
        <div id="modal" className="modal-popup">
          <div className="pop-heading">Add Driver Info</div>
          <div className="content add-item-details">
            <div className="select-wrap">
              <InputText
                label="Driver Name"
                id="driver_name"
                value={this.state.driver_name}
                placeholder="Driver Name"
                changeHandler={this.onChangeHandler}
                length={"45"}
                required
              />
            </div>

            <div className="select-wrap">
              <InputText
                label="Driver Contact"
                id="cell_no"
                value={this.state.cell_no}
                placeholder="Driver Contact"
                changeHandler={this.onChangeHandler}
                length={"10"}
                isValidate={"numeric"}
                required
              />
            </div>

            <div className="select-wrap">
              <InputText
                label="Driver Location"
                id="driver_alternative_location_id"
                value={this.state.driver_alternative_location_id}
                placeholder="Driver Location"
                changeHandler={this.onChangeHandler}
                length={"45"}
              />
            </div>

            <div className="select-wrap">
              <InputText
                label="Driver A/C No"
                id="driver_acc_no"
                value={this.state.driver_acc_no}
                placeholder="Driver A/C No"
                changeHandler={this.onChangeHandler}
                length={"17"}
              // required
              />
            </div>

            <div className="select-wrap">
              <InputText
                label="IFSC"
                id="driver_ifsc_code"
                value={this.state.driver_ifsc_code}
                placeholder="IFSC"
                changeHandler={this.onChangeHandler}
                length={"11"}
              />
            </div>
            <div className="select-wrap">
              <InputText
                label="DL No"
                placeholder="DL No"
                id="dl_no"
                value={this.state.dl_no}
                changeHandler={this.onChangeHandler}
                length={"16"}
                required
              />
            </div>
            <div style={{ display: "flex" }}>
              <div className="select-wrap">
                <div className="data-picker" style={{ marginTop: "10px" }}>
                  <div className="label">Driver Dl Validity Date{" "}
                    <span className="error-color">*</span>
                  </div>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.driver_detail_validity_date}
                    onChange={this.SelectDate.bind(
                      this,
                      "driver_detail_validity_date"
                    )}
                    placeholderText={"DD/MM/YYYY"}
                    minDate={new Date()}
                    required
                  />
                </div>
              </div>
              <div className="select-wrap" style={{ marginTop: "25px" }}>
                <span
                  style={{
                    fontSize: "13px",
                    color: "#868e96",
                    marginBottom: "10px",
                    marginRight: "5%",
                  }}
                >
                  DL Image
                </span>
                <Button value={"Upload DL"} click={this.onUpload} />
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={(e) => {
                    this.uploadUrl = e;
                  }}
                  accept={'.jpeg, .jpg, .png'}
                  // onChange={this.uploadfiles}
                  onChange={(e) => {
                    this.uploadfiles(e, "dlName");
                  }}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                />
                <div>
                  {this.state.dlName && this.state.dlName.name ? (
                    <a
                      href={this.state.dlName.url}
                      download
                      className={"theme-color fs-12 mt-5"}
                    >
                      {this.state.dlName.name}
                    </a>
                  ) : null}
                </div>
              </div>

            </div>
            <div
              className="label"
              style={{ fontSize: "20px", display: "inline-block" }}
            >
              Add Location
            </div>
            {
              <div className="button-add" onClick={this.addLocation}>
                ADD
              </div>
            }
            {preferredroute.map((value, index) => {
              return (
                <div className="details flex space-between" key={index}>
                  <div className="fiscal-section" style={{ width: "24%" }}>
                    <InputText
                      placeholder="Source"
                      id={"source"}
                      label="Source"
                      value={value.source}
                      changeHandler={(value) => {
                        let { preferredroute } = this.state;
                        preferredroute[index].source = value;
                        this.setState({ preferredroute });
                      }}
                    />
                  </div>
                  <div className="fiscal-section" style={{ width: "24%" }}>
                    <InputText
                      placeholder="Destinations"
                      id={"destination"}
                      label="Destination"
                      value={value.destination}
                      changeHandler={(value) => {
                        let { preferredroute } = this.state;
                        preferredroute[index].destination = value;
                        this.setState({ preferredroute });
                      }}
                    />
                  </div>

                  <div
                    className="button-add"
                    style={{ marginTop: "30px" }}
                    onClick={() => this.deleteLocation(index)}
                  >
                    X
                  </div>
                </div>
              );
            })}
          </div>
          <div className="submit-button">
            {this.state.errorMsg && (
              <div className="error-color">{this.state.errorMsg}</div>
            )}
            <div className="button-section">
              <Button value="Save" click={this.onSave} />
            </div>
            <div className="button-section">
              <Button value="Cancel" click={this.props.toggleAdd} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(AddItem);
