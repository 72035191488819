import { call, takeLatest } from "redux-saga/effects";
import { getToken } from "../../../utils/common";
import axiosT1 from "../../../utils/axios";
import { GET_COMPANY_USER_INFO_LIST } from "./companyUserInfoActionConstants";
import API from "../../common/api_config";


export function* getcompanyUserInfoList(action) {
  try {
    const resp = yield call(
      axiosT1.get,
      API.getCompanyUserInfoList,
      {
        params: action.queryParams,
        headers: {
          token: getToken()
        }
      }
    );

    if (action.onSuccess) {
      const companyUserInfoList = resp.data.data
      action.onSuccess(companyUserInfoList)
    }

  } catch (e) {
    console.log('e:', e)
  }
}



export function companyUserInfoScreenSaga() {
  return [
     takeLatest(GET_COMPANY_USER_INFO_LIST, getcompanyUserInfoList)
  ]
}