import React, { Component } from 'react';
import InputText from '../../../../../src/common/components/input-text/input-text';
import Select from 'react-select';
import Button from '../../../../../src/home/button-classic/button-classic';
import Rupee from '../../../../../assets/images/rupee.svg';

class AddItem extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className = "add-user-wrap">
            <div onClick = {this.props.toggleAdd} className = "overlay"></div>
            <div id = "modal" className = "modal-popup">
                <div className = "pop-heading">
                    Add Item
                </div>
                <div className = "content add-item-details">
                    <InputText label = "Material" placeholder="Material"/>
                    <InputText label = "Material Description" placeholder = "Material Description" />
                    <InputText label = "Material Group" placeholder = "Material Group" />
                    <InputText label = "Material Type" placeholder = "Material Type" />
                    <InputText label = "Plant" placeholder = "Plant" />
                    <InputText label = "Plant Group" placeholder = "Plant Group" />
                    <div className = "Price">
                    <div className = "rupee-icon" style={{backgroundImage:`url(${Rupee})`}}></div>
                        <InputText label = "Price" placeholder = "Price" />
                    </div>
                </div>
                <div className="submit-button">
                    <div className="button-section"><Button value="Save" /></div>
                    <div className="button-section"><Button value="Cancel" click={this.props.toggleAdd}/></div>
                </div>
            </div>
        </div>
        )
    }
}

export default AddItem;