import React, { Fragment } from 'react';
import BackButton from '../../components/common/Button/BackButton';

const BannerHeaderSection = (props) => {
  const {
    handleOnClickBackBtn,
  } = props;

  return (
    <Fragment>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-15'>
          <BackButton
            className='text-lg'
            onClick={handleOnClickBackBtn}
          />
          <h5
            className='typographyT1'
          >
            Banner Settings
          </h5>
        </div>

      </div>
    </Fragment>
  )
}

export default BannerHeaderSection
