import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ACTION from "../../../../common/action_constant";
import MainComponent from "../../../main/main";
import "./pod-status.less";
import Button from "../../../button-classic/button-classic";

import Loader from "../../../../common/components/loader/loader";

import Save from '../../../../../assets/images/save.svg'
import Cancel from '../../../../../assets/images/cancel.svg'
import Edit from '../../../../../assets/images/edit.svg'

import AddPod from './add-pod'

const getInfoText = (content) => {
    return <span className="info tooltip">i<span className="tooltiptext">{content}</span></span>;
}

const getPodCell = (podSlabs) => {
    return (
        <div className="pod-cell">
            <table className="w-p-100">
                <tbody>
                    <tr>
                        <td className="pod-status-cell">Equal to {podSlabs.slab_1.equal_than}</td>
                        <td>{podSlabs.slab_1.equal_than} %</td>
                    </tr>
                    <tr>
                        <td className="pod-status-cell">Less than {podSlabs.slab_2.less_than} & Greater/Equal {podSlabs.slab_2.greater_than}</td>
                        <td>{podSlabs.slab_2.value} %</td>
                    </tr>
                    <tr>
                        <td className="pod-status-cell">Less than {podSlabs.slab_3.less_than}</td>
                        <td>According to T&C {getInfoText(podSlabs.slab_3.content)}</td>
                    </tr>
                    <tr>
                        <td className="pod-status-cell">Lost</td>
                        <td>According to T&C {getInfoText(podSlabs.slab_4.content)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

const PodStatus = (props) => {
    const { dispatch, dataGovernance } = props;
    const { podClauseData, podCycles, vendorTypes, podSubmission } = dataGovernance;

    const [podDataArray, setPodDataArray] = useState([])
    const [isFormOpen, setIsFormOpen] = useState(false)
    const [rowData, setRowData] = useState({})
    const [selectedPods, setSelectedPods] = useState([])
    useEffect(() => {
        getVendors();
        getPODData();
    }, [])

    useEffect(() => {
        setPodDataArray(podClauseData)
    }, [podClauseData])

    const getPODData = () => {
        let inputData = { type: 2 };
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_POD_CLAUSE_DATA,
            params: inputData,
            onSuccess: (() => { })
        })
    }

    const getVendors = () => {
        const { dispatch } = props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_POD_VENDORS_TYPES,
        })
    }

    const handleForm = (bool) => {
        // setIsFormOpen(!isFormOpen)
        setIsFormOpen(bool)
    }

    const handleSubmitForm = (rowData) => {
        const { dispatch } = props;
        dispatch({
            type: rowData.pod_id!=undefined?ACTION.DATA_GOVERNANCE.UPDATE_POD_DATA:ACTION.DATA_GOVERNANCE.POST_POD_DATA,
            params: rowData,
            onSuccess: (() => {
                handleForm(false)
                getPODData();
            })
        })
    }

    const getVendorName = (vendorIncludes) => {
        const { vendorTypes} = dataGovernance;
        let vendorString = '';
        // vendorIncludes.map(id => {
        //     vendorTypes;
        // });
        let vendorsArray = vendorTypes.filter(row => vendorIncludes.includes(row.company_id));
        if(vendorsArray.length>0) {
            vendorsArray.forEach(vendor => {
                vendorString+=vendor.company+', ';
            })
        }
        return vendorString;
    }

    const handleEdit = (data) => {
        setRowData(data);
        handleForm(true);
    }

    const handleAdd = () => {
        setRowData({});
        handleForm(false);
    }

    const handlePodSelection = (id) => {
        let selectedPodsCopy = [...selectedPods];
        if (selectedPodsCopy.includes(id)) {
            selectedPodsCopy.splice(id, 1);
        } else {
            selectedPodsCopy.push(id);
        }
        setSelectedPods(selectedPodsCopy);
    }

    const handleDelete = () => {
        if(selectedPods.length == 0) {
            return;
        }
        const { dispatch } = props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.DELETE_POD_DATA,
            params: selectedPods,
            onSuccess: (() => {
                setSelectedPods([])
                getPODData();
            })
        })
    }

    return (<>
        <div className="pod-cycle">
            <div className={'list-section'}>
                <div className={'table-header w-p-5'}></div>
                <div className={'table-header w-p-25'}>Vendors</div>
                <div className={'table-header w-p-10'}>Cycle</div>
                <div className={'table-header w-p-50'}>
                    <table className="w-p-100">
                        <tbody>
                            <tr>
                                <td className="pod-status-cell">POD Status</td>
                                <td>Payment</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={'table-header w-p-10'}>Actions</div>
            </div>
            {podDataArray.map((row, index) => {
                return (<div className={'list-section'} key={index}>
                    <div className={'table-row w-p-5'}>
                        <input
                            type="checkbox"
                            defaultChecked={selectedPods.includes(row.pod_id)?true:false}
                            onClick={(e) => handlePodSelection(row.pod_id)}
                        />
                    </div>
            <div className={'table-row w-p-25'}>{row.vendor_includes.length == 0?'All Vendors':getVendorName(row.vendor_includes)}</div>
                    <div className={'table-row w-p-10'}>{podCycles.filter(el => el.id == row.pod_cycle)[0]['value']}</div>
                    <div className={'table-row w-p-50'}>
                        {
                            getPodCell(row.pod_slabs)
                        }
                    </div>
                    <div className={'table-header w-p-10'}><div className={'action-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => handleEdit(row)} /></div>
                </div>)
            })}
            <div className={'approval-btn'}>
            <Button value={'Delete'} click={() => handleDelete()}></Button>
                <Button value={'Add'} click={() => handleAdd()}></Button>
            </div>
            {
                isFormOpen && <AddPod rowData={rowData} handleSubmitForm={handleSubmitForm} handleForm={handleForm} />
            }
        </div>
    </>
    );
}

const mapStateToProps = state => {
    return {
        loader: state.loader,
        msgpopup: state.msgpopup,
        dataGovernance: state.dataGovernance,
    }
}

export default connect(mapStateToProps)(PodStatus);