import React, { Component } from "react";
import { connect } from "react-redux";
import "./navigation-panel.less";
import { getCookie } from "../../../utils/cookies";

class NavigationPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigationOptions: [
        {
          heading: "Send Mail",
          id: "1"
        },
        {
          heading: "Copy Mail",
          id: "2"
        }
      ],
      selectedOption: "1" || props.selectedOption 
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.selectedOption !== nextProps.selectedOption) {
      this.setState({
        selectedOption: nextProps.selectedOption
      });
    }
  }

  setOption = option => {
    this.setState({
      selectedOption: option
    });
    this.props.selectedTab(option);
  };

  componentDidMount() {
    const { dispatch } = this.props;
    //this.props.selectedTab(this.state.selectedOption);
  }
  render() {
    const user = JSON.parse(getCookie("user"));
    return (
      <div className="navigation-panel-root">
        <div className="navigation-holder">
          {this.state.navigationOptions.map((option, index) => {
            if (option.heading == "Item") {
              return (
                <div
                  key={index}
                  className={
                    this.state.selectedOption === option.id
                      ? "selected-option options"
                      : "options"
                  }
                  onClick={() => this.setOption(option.id)}
                >
                  {option.heading}
                </div>
              );
            } else if (option.heading != "Item") {
              return (
                <div
                  key={index}
                  className={
                    this.state.selectedOption === option.id
                      ? "selected-option options"
                      : "options"
                  }
                  onClick={() => this.setOption(option.id)}
                >
                  {option.heading}
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }
}

export default NavigationPanel;
