import React, { Component } from 'react';
import InputText from "../../../../common/components/input-text/input-text";
import Select from 'react-select';
import "../../home-navigation-panel.less";


class AddFields extends Component {
    constructor(props){
        super(props);
        this.state = {
            name : "",
            company_id : "",
            error : "",
        }
    }

    onChangeHandler = (value, id) =>{
        this.setState({
			  [id]: value
        })
    }

    addFields = () =>{
        const paramObj = {
            fieldTitle: this.state.name,
            company_id: this.props.company_id
        }
        this.props.addFields(paramObj);
        this.props.toggleAdd4();
    }

    render() {
        const buttonDisable = (this.state.name) ? 'add-button' : 'add-button btn-disabled';
        return (
            <div className = "add-user-wrap add-action-wrap-popup">
                <div onClick = {this.props.toggleAdd3} className = "overlay"></div>
                <div id = "modal" className = "modal-popup">
                    <div className = "pop-heading">
                        Add Field
                    </div>
                    <div className = "content">
                    <label className = "error">{this.state.error}</label>
                        <form className = "form-section" autoComplete = "nope">
                            <InputText placeholder = "Name" id = {"name"} label = "Name" value = {this.state.name} changeHandler = {this.onChangeHandler}/>
                        </form>
                        <div className = "submit-section">
                            <button className = "add-button discard" onClick = {this.props.toggleAdd4}>Cancel</button>
                            <button className = {buttonDisable} onClick = {this.addFields}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddFields;