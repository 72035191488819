import React, { Component} from 'react';
import {connect} from 'react-redux';
// import "./sap.less"
import ModeImport from "./mode_import/mode_import";
import RouteImport from "./route_import/route_import";
import ShipmentLog from "./shipment_log/shipment_log";

class SapModule extends Component {
	constructor(props){
		super(props);
	}
	componentDidMount(){
		const { dispatch } = this.props;
	}
	render() {
		return (
			<div className = "master">
            {this.props.selectedSubTab == 2 && <ModeImport/>}
            {this.props.selectedSubTab == 1 && <RouteImport companyList = {this.props.companyList} exportRouteExcel = {this.props.exportRouteExcel} generateRouteExcel = {this.props.generateRouteExcel}/>}
            {this.props.selectedSubTab == 3 && <ShipmentLog/>}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		master: state.master
	};
};
  
export default connect(mapStateToProps)(SapModule);