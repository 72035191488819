import ACTION from "../common/action_constant";
import home from "./home";

const homeReducer = (state = {addCompanyModal : false, modes: [], types: [] }, action) => {
	let tempState = Object.assign({}, state);
	if(action.type === ACTION.HOME.TOGGLE_ADD_COMPANY_MODAL) {
		tempState.addCompanyModal = !tempState.addCompanyModal;
		return tempState;
	}else if(action.type === ACTION.HOME.STORE_HOME_DETAILS){
		tempState.homeDetails = action.data;
		return tempState;
	}else if(action.type === ACTION.HOME.STORE_COMPANY_STATUS){
		if(tempState.homeDetails && tempState.homeDetails.length > 0){
			tempState.homeDetails.map((item)=>{
				if(item.id == action.data.company_id){
					item.is_active = action.data.is_active;
				}
			})
		}		
		return tempState;
	}else if(action.type === ACTION.HOME.STORE_BASIC_DETAILS){
		tempState.basicDetails  = action.data;		
		return tempState;
	}else if(action.type === ACTION.HOME.STORE_BASIC_DETAILS_FOR_VENDOR_SCREEN){
		tempState.basicDetailsForVendorScreen  = action.data;		
		return tempState;
	}else if(action.type === ACTION.HOME.STORE_EST_CATEGORY){
		tempState.estCategory  = action.data;		
		return tempState;
	}else if(action.type === ACTION.HOME.STORE_BUSINESS_SEG){
		tempState.businessSeg  = action.data;		
		return tempState;
	}else if(action.type === ACTION.HOME.STORE_ROLES){
		tempState.roles = action.data;
		return tempState;
	}else if(action.type === ACTION.HOME.STORE_DEPARTMENTS){
		tempState.departments = action.data;
		return tempState;
	}else if(action.type === ACTION.HOME.STORE_COMPANY_IMG){
		tempState.basicDetails.company_logo = action.data.url;	
		return tempState;
	}else if(action.type === ACTION.HOME.STORE_PROVIDER_USERS){
		tempState.providersUsers = action.data;	
		return tempState;
	}else if(action.type === ACTION.HOME.STORE_ALL_SEEKERS){
		tempState.seekers = action.data;	
		return tempState;
	} else if(action.type === ACTION.HOME.STORE_ALL_STATE){
		tempState.states = action.data;
		return tempState;
	} else if(action.type === ACTION.HOME.STORE_INDENT_REASONS) {
		tempState.indentReasons = action.data;
		return tempState;
	} else if(action.type === ACTION.HOME.STORE_MODULES_LIST) {
		tempState.moduleList = action.data;
		return tempState;
	} else if(action.type == ACTION.HOME.STORE_COMPANY_FILE){
		tempState.vendorDocumets = action.data;
		return tempState;
	}else if(action.type === ACTION.HOME.STORE_ALL_COMPANY_CATEGORY){
		tempState.companyCategory = action.data;	
		return tempState;
	}else if(action.type === ACTION.HOME.STORE_COMPANY_CATEGORY_DETAILS){
		tempState.companyCategoryDetails = action.data;	
		return tempState;
	}
	else if(action.type === ACTION.HOME.STORE_TYPES){
		tempState.types = action.data;	
		return tempState;
	}
	else if(action.type === ACTION.HOME.STORE_MODES){
		tempState.modes = action.data;	
		return tempState;
	}
	else if(action.type === ACTION.HOME.SAVE_ROUTE_CODE) {
		tempState.routeCodes = action.data;
		return tempState;
	}
	else if(action.type === ACTION.HOME.SAVE_DISTRICT) {
		tempState.districtList = action.data;
		
		return tempState;
	}
	else if(action.type === ACTION.HOME.SAVE_TO_DISTRICT) {
		tempState.TodistrictList = action.data;
		
		return tempState;
	}
	else if(action.type === ACTION.HOME.STORE_VENDOR_DETAILS) {
		tempState.vendorMDGDetails = action.data;
		return tempState;
	}
	else if(action.type === ACTION.HOME.SAVE_RFQ_TYPES) {
		tempState.rfqTypes = action.data;
		return tempState;
	}else if(action.type === ACTION.HOME.STORE_USER_URL){
		tempState.downloadUserDetails = action.data;
		return tempState;
	}else if(action.type === ACTION.HOME.STORE_BRANCH_URL){
		tempState.downloadBranchDetails = action.data;
		return tempState;
	}else if(action.type === ACTION.HOME.STORE_DEPARTMENT_URL){
		tempState.downloadDepartmentDetails = action.data;
		return tempState;
	}else if(action.type === ACTION.HOME.SAVE_MATERIAL_CONVERSION) {
		tempState.materialConversion = action.data;
		return tempState;
	}
	else if(action.type === ACTION.HOME.SAVE_SCHEDULER_CODE) {
		tempState.schedulerCodes = action.data;
		return tempState;
	}else if(action.type === ACTION.HOME.SAVE_VENDOR_MATERIAL_DETAILS) {
		tempState.vendorMaterialData = action.data;
		tempState.vendorMaterialCount = action.count;
		return tempState;
	}else if(action.type === ACTION.HOME.SAVE_VENDOR_WITH_SAP_CODE) {
		tempState.vendorWithSapcode = action.data || [];
		return tempState;
	}else if(action.type === ACTION.HOME.STORE_MATERIAL_ITEM_DETAILS){
		tempState.materialItemDetails = action.data || [];
		return tempState;
	} else if(action.type === ACTION.HOME.SAVE_MODE_OF_TRANSPORT) {
		tempState.modeOfTransport = action.data || [];
		return tempState;
	} else if(action.type === ACTION.HOME.SAVE_ROUTE_MASTER) {
		tempState.routeMaster = action.data || [];
		return tempState;
	} else if(action.type === ACTION.HOME.SAVE_DISPOSAL_MATERIAL) {
		tempState.disposalMaterial = action.data || [];
		return tempState;
	} else if(action.type === ACTION.HOME.SAVE_DISPOSAL_MATERIAL_DROPDOWNS) {
		tempState.disposalMaterialDropdowns = action.data || [];
		return tempState;
	} else if(action.type === ACTION.HOME.SAVE_CONTRACT_TENURE_TYPES) {
		tempState.contractTenureTypes = action.data || [];
		return tempState;
	}
	else if(action.type === ACTION.HOME.FETCH_LR_UPLOAD_SUMMARY_SUCCESS) {
		tempState.lrUploadSummaryData = action.data || [];
		return tempState;
	} else if(action.type === ACTION.HOME.SAVE_GROUP_NAME_DROPDOWN) {
		tempState.groupNames = action.data || [];
		return tempState;
	}
	else if(action.type === ACTION.HOME.FETCH_LR_RANGE_SUCCESS) {
		tempState.lrRangeData = action.data || [];
		return tempState;
	}
	else if(action.type === ACTION.HOME.FETCH_LR_LSP_SUCCESS) {
		tempState.lspLrRange = action.data || [];
		return tempState;
	}
	else if(action.type === ACTION.HOME.FETCH_AWB_LR_RANGE_SUCCESS) {
		tempState.lrAwbRangeData = action.data || [];
		return tempState;
	}
	else if(action.type === ACTION.HOME.FETCH_AWB_LR_LSP_SUCCESS) {
		tempState.lspAwbLrRange = action.data || [];
		return tempState;
	}
	else if(action.type === ACTION.HOME.SAVE_CUSTOMER_LANE_MAPPING) {
		tempState.customerLaneMappingDetails = action.data || [];
		return tempState;
	}
	return state;
};

export default homeReducer;
