import React, { Fragment } from 'react'
import Select from "react-select";
const SelectT1 = (props) => {
    const { styles={} , ...rest } = props


      const commonStyles = {
           control: (styles, state) => {
            return {
                ...styles,
                height:"39px",
                minHeight:"39px",
                
            }
        },
        ...styles,
    }

    return (
        <Fragment>
            <Select
                styles={commonStyles}

                {...rest}
            />
        </Fragment>
    )
}

export default SelectT1


