import React, { Fragment } from 'react'

const Spacer = (props) => {
    const { style, spacing, ...restProps } = props
    return (
        <Fragment>
            <div style={{ padding: spacing, ...style }} {...restProps}>
            </div>
        </Fragment>
    )
}

export default Spacer