import React, { Component , Fragment } from 'react';
import Select from 'react-select';
import InputText from '../../../common/components/input-text/input-text';
import { decimalNumber } from '../../../../utils/validate'
import Button from '../../button-classic/button-classic';


class FOVCharges extends Component {
   constructor(props) {
      super(props);
      this.state = {
         data: {
            transporters: props.fovData ? props.fovData.transporters.map(val => { return { label: val.company_name, value: val.id }}) : [],
            invoice_amount_percentage: props.fovData ? props.fovData.invoice_amount_percentage : ''
         }
      }
   }
   
   onChangeamountHandler = (value, field) => {
      if (decimalNumber(value) || value == '') {
         const { data } = this.state;
         data[field] = value; 
         this.setState({ data }); }
      }
      
      onSave = () => {
         const { data } = this.state;
         data.transporters = data.transporters.length > 0 ? data.transporters.map(val => { return { company_name : val.label, id : val.value }}) : data.transporters;
         this.props.addPayables(data, 23);
         this.props.toggleAdd(23);
      }
      
      onUpdate = () => {
         const { data } = this.state;
         const { fovData } = this.props;
         data.transporters = data.transporters.length > 0 ? data.transporters.map(val => { return { company_name : val.label, id : val.value }}) : data.transporters;
         data.payable_id = fovData.payable_id;
         data.load_type = fovData.load_type;
         data.mode_type = fovData.mode_type;
         data.type = fovData.type;
         this.props.updatePayables(data, 23);
         this.props.toggleAdd(23);
      }
      
      onTransporterSearch = (value) => {
         if(value) {
            this.props.getTransporterList(value);
         }
      }
      
   validateVal = (val) => {
      return val.toString().match(/^\d{1,2}(\.\d{1,3})?$/)
   }
   
   validData = ({ data } = this.state) => {
      return (this.validateVal(data.invoice_amount_percentage) && data.transporters.length !== 0);
   }
   
         
      render() {
         const { data } = this.state;
         const { transporterList, fovData} = this.props;
         const disable = this.validData();
         
        return (
            <div className = "add-company-wrap add-provider-root add-row">
                <div onClick = {this.props.toggleAdd} className = "overlay">
                    <div className = "modal-popup" onClick={(e) => { e.stopPropagation() }}>
                        <div className = "heading">
                            { `${fovData ? 'Edit' : 'Add'} FOV Charges`}
                        </div>
                        <form className = "form-section" autoComplete = "nopes">
                            <div className="select-wrap">
                                <div className="label">Transporter</div>
                                <Select 
                                    isMulti
                                    className={'select'} 
                                    value={data.transporters}
                                    options={transporterList && transporterList.length > 0 ? transporterList.map(trans => {
                                        return { label : trans.company_name, value: trans.branch_id }
                                    }) : []}
                                    onChange= {(options) => {
                                        const { data } = this.state;
                                        data.transporters = options;
                                        this.setState({ data });
                                    }}
                                    onInputChange={this.onTransporterSearch}
                                />
                            </div>
                            <InputText id='invoice_amount_percentage' value={data.invoice_amount_percentage} placeholder={'Percentage of Invoice Amount'} label={'Percentage of Invoice Amount'} changeHandler={this.onChangeamountHandler} type={'number'}/>
                        </form>
                        <div className = "submit-section submit-area">
                       
                          <div className="button-section">
                             {!this.props.fovData && <Button className="add-button" click={this.onSave} value="Save" disabled={!disable} />}
                             {this.props.fovData&& <Button className="add-button" click={this.onUpdate} value="Update" disabled={!disable} />}
                          </div>
                       
                            <div className ="button-section" >
                                <div className ="add-button" onClick={this.props.toggleAdd}>Close</div>
                            </div>
                        </div>
                 </div> //this.props.fovData
                </div>
            </div>
        )
    }
}

export default FOVCharges;