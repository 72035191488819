import React, { Fragment } from 'react'
import './messageCard.scss';
const MessageCard = () => {
    return (
        <Fragment >
            <div className='message-card'>
                <h5 className='message-card__title'>
                    No Data Found
                </h5>
            </div>
        </Fragment>
    )
}

export default MessageCard