import React, { Component } from 'react';
import InputText from '../../../common/components/input-text/input-text';



class AddEvent extends Component {
	constructor() {
		super();
		this.state = {
			event_name: ""
		}
	}

	onChangeHandler = (value, id) => {
		this.setState({ [id]: value });
	}

	onSave = () => {
		let data = {
			"type": this.props.isType,
			"event_name": this.state.event_name
		}
		this.props.onAddEvent(data);
	}



	render() {
		return (
			<div className="add-company-wrap">
				<div onClick={this.props.close} className="overlay event-overlay">
					<div className="modal-popup small-modal smallest" onClick={(e) => { e.stopPropagation() }}>
						<div className="heading">
							Add A Event
                        </div>
						{/* <label className = "error">{this.state.error}</label> */}
						<form className="form-section" autoComplete="nopes">
							<InputText placeholder="Event" id={"event_name"} label="Event" value={this.state.event_name} changeHandler={this.onChangeHandler} />
						</form>
						<div>
							<div className="submit-section submit-area">
								<div className="button-section">
									<div className="add-button" onClick={this.onSave} >Save</div>
								</div>
								<div className="button-section" >
									<div className="add-button" onClick={this.props.toggleAdd}>Close</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}


export default AddEvent;