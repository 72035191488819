import React, { Component } from 'react';
import "./add_department.less";
import Select from 'react-select';
import validate from "../../../../../../utils/validate";
import {connect} from 'react-redux';
import { getEmail } from '../../../../../../utils/common';
import { getItemFromSessionStorage, setItemToSessionStorage } from '../../../../../../utils/sessionStorage';

class AddDepartment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			department_name: "",
			roles: [],
			selectedRoles: []
		}
		console.log(this.props);
	}


	componentWillMount() {
		let tempRoles = [];
		this.props.roles && this.props.roles.forEach(role => {
			tempRoles.push(
				{
					label: role.role_name,
					value: role.role_id
				}
			)
		});
		this.setState({
			roles: tempRoles
		});
	}
	componentDidMount() {
		window.scroll(0, 0);
		this.addAnotherRole();
	}

	removeRole = (ind) => {
		let tempRoles = this.state.selectedRoles;
		tempRoles.splice(ind, 1);
		this.setState({ selectedRoles: tempRoles });
	}

	handleChange = (role, ind) => {
		let tempRoles = this.state.selectedRoles.slice();
		for (let i = 0; i < tempRoles.length; i++) {
			if (role.value == tempRoles[i].value) {
				// tempRoles[ind] = {
				//     value : "",
				//     label : ""
				// }
				// this.setState({ selectedRoles : tempRoles});
				return;
			}
		}
		tempRoles[ind] = role;
		this.setState({ selectedRoles: tempRoles });
	}

	populateRoles = () => {
		return this.state.selectedRoles.map((role, index) => {
			return (
				<div className="roles-holder" key={index}>
					<Select className="select" value={role.role_name} onChange={(role) => this.handleChange(role, index)} options={this.state.roles} />
					<button className="add-button " onClick={() => this.removeRole(index)}>Delete</button>
				</div>
			)
		})

	}

	addDepartment = () => {
		let paramObj = {
			company_id: this.props.companyDetails.id,
			company_type: this.props.companyDetails.company_type,
			department_name: this.state.department_name,
			roles: this.state.selectedRoles.map((role) => {
				return {
					name: role.label,
					role_id: role.value
				}
			}),
			master_type: 6,
			user_email: getEmail(),
			new_flag: true,
		}
		if (validate.isEmpty(paramObj.department_name)) {
			this.setState({
				error: "Please Enter A Valid Department Name"
			});
			return;
		}
		// if(paramObj.roles.length == 0){
		//     this.setState({
		//         error : "Please Select atleast one role from the list"
		//     });
		//     return;
		// }
		// else if(!(paramObj.roles[paramObj.roles.length-1]).name){
		//     this.setState({
		//         error : "Roles cannot be empty, make sure you have selected!"
		//     });
		//     return;
		// }

		this.props.addDepartment(paramObj);
		this.props.toggleAdd();
	}

	addAnotherRole = () => {
		let sample = this.state.selectedRoles;
		if (sample.length >= this.state.roles.length) {
			return;
		}
		if (sample.length != 0 && validate.isEmpty(sample[sample.length - 1].value)) {
			return;
		}
		let obj = {
			role_name: "",
			role_id: ""
		}
		sample.push(obj);
		this.setState({
			selectedRoles: sample
		}, () => { this.populateRoles() });
	}
	onChangeHandler = (value, id) => {
		this.setState({
			[id]: value
		})
	}
	render() {
		const { props, state } = this;
		return (
			<div className="add-company-wrap">
				<div onClick={props.close} className="overlay"></div>
				<div className="modal-popup small-modal add-department ">
					<div className="heading">
						Add New Department
					</div>
					<label className="error">{this.state.error}</label>
					<form className="form-section" autoComplete="nopes">
						<InputText placeholder="Department Name" id={"department_name"} label="Department Name" value={state.department_name} changeHandler={this.onChangeHandler} maxLength={30}/>
						<div><span className="sub-label">Roles</span>{this.populateRoles()}</div>
						<div className="addMore" onClick={this.addAnotherRole}><span>Add More</span></div>
					</form>
					<div>
						<div className="submit-section submit-area">
							<div className="button-section">
								<div className="add-button" onClick={this.addDepartment}>Save</div>
							</div>
							<div className="button-section">
								<div className="add-button" onClick={this.props.toggleAdd}>Close</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}


const InputText = (props) => {
	return (
		<div className="input-text-wrap">
			<div className="label">
				{props.label}
			</div>
			<div className="input-element">
				<input type="text" id={props.id} placeholder={props.placeholder} className="text-input" value={props.value} onChange={(e) => props.changeHandler(e.target.value, props.id)} autoComplete="nopes" maxLength={props.maxLength}/>
			</div>
		</div>
	)
}


export default connect()(AddDepartment);