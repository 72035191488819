import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from "react-redux";

class Banner extends Component {
    render() {
        const { data} = this.props;
        console.log('data:', data)
        let messages = [];

        return (
            <>
            {data && data.length > 0 &&
            <div className={classNames("banner col-1 z-100 psticky t-0", {})}>
                {data.length > 0 && data.map((title, index) => {
                    messages.push(title.message);
                })}
                <marquee width="100%" direction="left" height="20px" lineHeight="8px">
                    <div className="fs-16 fBolder">{messages.join(' | ')}</div>
                </marquee>
            </div>}
            </>
            
        );
    }
}

const mapStateToProps = state => {
    return {
        data:state.login.broadcastMsgs,
    };
  };
  
  export default connect(mapStateToProps)(Banner);
