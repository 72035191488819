import React, { Component } from 'react';
import AddItem from './add-item/add-item';
import EditItem from './edit-item/edit-item';
import Search from '../../../../src/common/components/search-text/search-text';
import Button from '../../../home/button-classic/button-classic';
import Edit from '../../../../assets/images/edit.svg';
import Delete from '../../../../assets/images/delete.svg';
import Rupee from '../../../../assets/images/rupee.svg';


class BacardiItem extends Component {
    constructor(){
        super();
        this.state = {
            isOpen: false,
            isEdit: false
        }
    }

    toggleAdd = (isType) => {
        if(isType === "Edit"){
            this.setState({ isEdit : !this.state.isEdit });
        } else {
            this.setState({ isOpen : !this.state.isOpen });
        }
    }

    render() {
        return(
            <div className={'bacardi-item'}>
                <div className = "header">
                    <Search placeholder={'Search'}/>
                    <Button value={' + Item'} click={this.toggleAdd}/>
                    <Button value={'Generate'} />
                    <Button value={'Upload'} />
                    <Button value={'Download'} />
                </div>
                <div className = "item-table">
                    <div className = "list-section item-header">
                        <div className = "item-label">Material</div>
                        <div className = "item-label product-label">Material Description</div>
                        <div className = "item-label">Material Group</div>
                        <div className = "item-label">Type</div>
                        <div className = "item-label">Price</div>
                        <div className = "item-label">Quantity</div>
                        <div className = "item-label">Actions</div>
                    </div>
                    <div className = "list-section item-header">
                        <div className = "item-label">{'4101000100'}</div>
                        <div className = "item-label product-label">{'WLFB 12X75 IN 42.8% NR DIC PB GLA IN'}</div>
                        <div className = "item-label">{'0100'}</div>
                        <div className = "item-label">{'X0'}</div>
                        <div className = "item-label rupee-icon" style={{backgroundImage:`url(${Rupee})`}}>{'272,970.06'}</div>
                        <div className = "item-label">{'100 CS'}</div>
                        <div className = "item-label">
                            <div className={'delete-icon'} style={{backgroundImage:`url(${Edit})`}} onClick={() => this.toggleAdd("Edit")}></div>
                            <div className={'delete-icon'} style={{backgroundImage:`url(${Delete})`}}></div>
                        </div>
                    </div>
			    </div>
                {this.state.isOpen && <AddItem toggleAdd={this.toggleAdd}/>}
                {this.state.isEdit && <EditItem toggleAdd={this.toggleAdd} />}
            </div>
        )
    }
}

export default BacardiItem;