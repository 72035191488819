module.exports = {
  name: function name(_name) {
    var nameRegx = /^[A-Za-z\s]+$/;
    if (_name != undefined) {
      var nameStr = _name.trimLeft();
      nameStr = nameStr.trim();
      return nameStr && nameStr.length > 0 && nameRegx.test(nameStr);
    }
    return _name && _name.length > 1;
  },
  arabicName: function arabicName(name) {
    var arabicNameRegex = /[\u0600-\u06FF]/;
    return name && arabicNameRegex.test(name);
  },
  email: function email(_email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return _email && re.test(_email);
  },
  alphanumeric: function alphanumeric(_alphanumeric) {
    var re = /^[a-z\d\s]+$/i;
    return _alphanumeric && re.test(_alphanumeric);
  },
  password: function password(_password) {
    return _password && _password.length >= 3;
  },
  user_password: function user_password(userPassword) {
    var mediumRegex = new RegExp("^((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%]).{6,})");
    return userPassword && mediumRegex.test(userPassword);
  },
  //Rgex to check only for numbers
  numeric: function numeric(value) {
    var regex = /(^$)|(^\d+$)/;
    return value && regex.test(value);
  },
  decimalNumber: function decimalNumber(value) {
    var val = value.toString();
    if (val.length > 11) {
      val = val.substring(0, 11);
    }
    val = parseFloat(val);
    var zeroVal = parseInt(value);
    if (zeroVal == 0) {
      return true;
    }
    var decimal = /^\s*-?[0-9]\d*(\.\d{0,9})?\s*$/;
    return val && decimal.test(val);
  },
  checkImageFormat: function checkImageFormat(file, dimension) {
    if (!file) {
      return true;
    }
    var fileFormat = file.name.split(".")[file.name.split(".").length - 1];
    var reader = new FileReader();
    var allowedImageFormat = ['png', 'jpg', 'jpeg', 'gif', 'PNG', 'JPG', 'JPEG'];
    if (!dimension) {
      return true;
    }
    // Return dimension.height==dimension.width;//check for only square images
    // If(allowedImageFormat.indexOf(fileFormat) < 0)
    //   Return false;
    // Else
    return dimension.height == dimension.width && allowedImageFormat.indexOf(fileFormat) > -1;
  },
  phoneNumber: function phoneNumber(_phoneNumber) {
    if (_phoneNumber && _phoneNumber.toString().replace(".", "").length > 9) {
      return true;
    }
    return false;
  },
  promocode: function promocode(_promocode) {
    var regex = /^[a-zA-Z0-9]*$/g;
    return _promocode && regex.test(_promocode);
  },
  validAmount: function validAmount(amount) {
    var newAmt = amount;
    if (newAmt) {
      if (newAmt.toString().replace(".", "").length > 6) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  },
  validatePhoneWithCountyCode: function validatePhoneWithCountyCode(number) {
    var newAmt = number;
    if (newAmt) {
      if (newAmt.toString().length < 5) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  },
  join: function join(ary, joinDelmiter) {
    if (typeof joinDelmiter == "string") return ary.join(joinDelmiter);else if (typeof joinDelmiter == "function") {
      var str = "";
      ary.forEach(function (item, idx) {
        str += joinDelmiter(item, idx) + item;
      });
      return str;
    }
  },
  isEmpty: function isEmpty(obj) {
    // Null and undefined are "empty"
    if (obj == null) {
      return true;
    }

    // Assume if it has a length property with a non-zero value
    // That that property is correct.
    if (obj.length > 0) {
      return false;
    }
    if (obj.length === 0) {
      return true;
    }

    // If it isn't an object at this point
    // It is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") {
      return true;
    }
    if (typeof obj == 'undefined') {
      return true;
    }

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // ToString and valueOf enumeration bugs in IE < 9
    for (var _key in obj) {
      if (hasOwnProperty.call(obj, _key)) {
        return false;
      }
    }
    return true;
  },
  isObjElementEmpty: function isObjElementEmpty(obj) {
    for (key in obj) {
      if (obj[key] === null || obj[key] === '') {
        return true;
      }
    }
    return false;
  },
  validationCheck: function validationCheck(value, oldValue, isType) {
    switch (isType) {
      case 'alphanumeric':
        return module.exports.alphanumeric(value.trim()) || value === '' ? value : oldValue;
      case 'numeric':
        return module.exports.numeric(value.trim()) || value === '' ? value : oldValue;
      default:
        return value;
    }
  },
  legthValidation: function legthValidation(field, value) {
    switch (field) {
      case 'pan_no':
      case 'contact':
      case 'mobile':
      case 'phone':
        return value.length === 10 ? '' : "".concat(field === 'pan_no' ? 'PAN No' : 'Mobile No.', " Length Should Be 10");
      case 'tax_no':
        return value.length === 12 ? '' : 'TAX No. Length Should Be 12';
      case 'email':
      case 'user_email':
      case 'company_email':
        return module.exports.email(value) || value === '' ? '' : 'Please Enter The Validate Email Id';
      case 'gstin':
        return value.length === 15 ? '' : 'GSTN Length Should Be 15';
      case 'pincode':
        return value.length === 6 ? '' : 'Pin Code Length Should Be 6';
      case 'user_password':
        return module.exports.user_password(value) || value === '' ? 'Please Enter The Password' : 'Password Should Contain Minimum 6 Characters With 1 Uppercase Letter, 1 Lowercase Letter, 1 Number And 1 Special Character [!@#$%].';
      default:
        return '';
    }
  },
  generatePassword: function generatePassword() {
    var length = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 8;
    var chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOP1234567890";
    var pwd = "";
    for (var x = 0; x < length; x++) {
      var i = Math.floor(Math.random() * chars.length);
      pwd += chars.charAt(i);
    }
    return pwd;
  },
  toPascalCase: function toPascalCase(str) {
    if (typeof str === "string") {
      var data = str.replace(/\w\S*/g, function (m) {
        return m.charAt(0).toUpperCase() + m.substr(1).toLowerCase();
      });
      return data;
    } else {
      return str;
    }
  }
};