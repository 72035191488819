import React, { Component, } from 'react';
import InputText from "../../../../common/components/input-text/input-text";
import Cx from "classnames";
import { ReactComponent as PlusIcon } from '../../../../../assets/images/add.svg'
import { ReactComponent as DeleteIcon } from '../../../../../assets/images/reject.svg'
import './add_parking_lot.less'

class AddParkingLot extends Component {
    constructor(props){
        super(props);
        this.state = {
            parkingName: this.props.data ? this.props.data.name : '',
            parkingCapacity: this.props.data ? this.props.data.parking_capacity : '',
            priority: this.props.data ? this.props.data.priority : '',
            addParkingData: [
                {
                    parkingName: '',
                    parkingCapacity: null,
                    priority: null
                }
            ]
        }
    }

    onChangeHandler = (value, id) =>{
        if (value != 0 && id != 'parkingName') {
            const regEx = /^[+]?([0-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
            const check = value.match(regEx);
            if (!check) {
                return
            }
        }
        this.setState({
            [id] : value
        })
    }

    addRow = () => {
        this.setState({
            addParkingData: [
                ...this.state.addParkingData,
                {
                    parkingName: '',
                    parkingCapacity: null,
                    priority: null
                }
            ]
        })
    }

    deleteRow = (index) => {
        const { addParkingData } = this.state;
        let tempParkingData = [...addParkingData]

        tempParkingData = tempParkingData.filter((p, id)=> id!=index)

        this.setState({
            addParkingData: tempParkingData
        })

    }

    onRowChange = (idx, type, value) => {
        const { addParkingData } = this.state;

        if (value != 0 && type != 'parkingName') {
            const regEx = /^[+]?([0-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
            const check = value.match(regEx);
            if (!check) {
                return
            }
        }

        addParkingData[idx][type] = value;

        this.setState({
            addParkingData
        })
    }

    onSave = () => {
        this.props.onAddParkingLot(this.state.addParkingData);
        this.props.toggleAdd();
    }

    updateParkingLot = () => {
        let { parkingCapacity, parkingName, priority } = this.state;
        let payload = {
            parkingCapacity, 
            parkingName, 
            priority
        }
        if(this.props.data.parking_id)
            payload.parking_id = this.props.data.parking_id;
        this.props.updateParkingLot(payload);
        this.props.toggleAdd();
    }

    validateForm = () => {
        const { addParkingData } = this.state;

        let valid = true;

        const emptyData = addParkingData.find(data => (!data.parkingName || !data.parkingCapacity || !data.priority))

        if(emptyData){
            valid = false
        }

        return valid
    }

    render() {
        const { priority, parkingName, parkingCapacity, addParkingData } = this.state;
        const { data } = this.props;

        return (
            <div className = "add-user-wrap add-bay">
                <div onClick = {this.props.toggleAdd} className = "overlay"></div>
                <div id = "modal" className = "modal-popup">
                    <div className = "pop-heading">
                        {`${data ?'Edit' : 'Add'} Parking Lot`}
                    </div>
                    <div className = "content add-edit-user">
                        {
                            data ?
                        <form className = "form-section" autoComplete = "nope">
                            <InputText required={true} placeholder = "Enter Name" id = {"parkingName"} label = "Parking Name" value = {parkingName} changeHandler = {this.onChangeHandler}/>
                            <InputText required={true} placeholder = "Capacity" id = {"parkingCapacity"} label = "Parking Capacity" value = {parkingCapacity} changeHandler = {this.onChangeHandler} />
                            <InputText required={true} placeholder = "Priority" id = {"priority"} label = "Parking Priority" value = {priority} changeHandler = {this.onChangeHandler} />
                        </form>
                            :
                            addParkingData.map((park, pIndex)=>{
                                return(
                                    <form className="form-section-map" autoComplete="nope">
                                        <InputText required={true} placeholder="Enter Name" id={"parkingName"} label="Parking Name" value={park.parkingName} changeHandler={this.onRowChange.bind(this, pIndex, 'parkingName')} />
                                        <InputText required={true} placeholder="Capacity" id={"parkingCapacity"} label="Parking Capacity" value={park.parkingCapacity} changeHandler={this.onRowChange.bind(this, pIndex, 'parkingCapacity')} />
                                        <InputText required={true} placeholder="Priority" id={"priority"} label="Parking Priority" value={park.priority} changeHandler={this.onRowChange.bind(this, pIndex, 'priority')} />
                                        {
                                            (pIndex == addParkingData.length - 1) ?
                                                <PlusIcon style={{ width: '20px', cursor: 'pointer', height: '20px' }} onClick={this.addRow}/>
                                            :
                                                <DeleteIcon style={{ width: '20px', cursor: 'pointer', height: '20px' }} onClick={() => { this.deleteRow(pIndex) }} />
                                        }
                                    </form>     
                                )
                            })
                        }

                        <div className = "submit-section">
                            <button className = "add-button discard" onClick={this.props.toggleAdd} >Cancel</button>
                            {!data ? <button className ={Cx("add-button", {
                                'disable-btn': !(this.validateForm())
                            })} onClick={this.onSave}>Save</button>
                            :  <button className ={Cx("add-button", {
                                'disable-btn' : !(priority && parkingCapacity && parkingName)
                            })} onClick={this.updateParkingLot}>Update</button>
                        }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddParkingLot;