import React, { Component } from 'react';
import InputText from '../../../common/components/input-text/input-text';
import Select from 'react-select';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { fuelStatus } from '../../../../utils/common';
import Moment from 'moment';
import '../reconciliation.less';
import Button from '../../button-classic/button-classic';
import { numeric , decimalNumber} from '../../../../utils/validate';

class EditFuel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data : props.editFuel
        }
    }

    handleChange = ( selectedOption ) => {
        let { data } = this.state;
        data.fuel_rate_variation = selectedOption.label;
        this.setState({ data });
    }

    onChangeHandler = (value, id) => {
       if (decimalNumber(value) || value == '') {
        let { data } = this.state;
        data[id] = value;
        this.setState( { data });}
    }

    onUpdateFuel = (id) => {
        let { data } = this.state;
        data.valid_from = data.valid_from ? data.valid_from.toString() : data.valid_from;
        data.valid_to = data.valid_from ? data.valid_to.toString() : data.valid_from;
        this.props.updateCreditDetails(data, 9);
        this.props.toggleAdd();
    }

    validateVal=(val)=>{
       return val.toString().match(/^\d{1,2}(\.\d{1,3})?$/)
    }

   validData = ({ data } = this.state) => {
      return (this.validateVal(data.percentage_of_baserate) && this.validateVal(data.fuel_rate) && data.fuel_rate_variation && data.valid_from && data.valid_to);
    }
    

    render() {
       let { data }=this.state;
       const disable = this.validData();
        return(<div className = "add-company-wrap add-provider-root add-row">
            <div onClick = {this.props.toggleAdd} className = "overlay">
                <div className = "modal-popup" onClick={(e) => { e.stopPropagation() }}>
                    <div className = "heading">
                        Edit Fuel Escalation
                    </div>
                    <form className = "form-section" autoComplete = "nopes">
                        <InputText placeholder={'% Of Base Rate'} label={'% Of Base Rate'} id = {"percentage_of_baserate"} value = {this.state.data.percentage_of_baserate} changeHandler = {this.onChangeHandler} type={'number'}/>
                        <InputText placeholder={'Fuel Variation %'} label={'Fuel Variation'} id={'fuel_rate'} value = {this.state.data.fuel_rate} changeHandler = {this.onChangeHandler} type={'number'}/>
                        <div className="select-wrap">
                        <div className="label">UOM</div>
                            <Select  
                                className={'select'}
                                value= {this.state.data.fuel_rate_variation ? {label : this.state.data.fuel_rate_variation, value: this.state.data.fuel_rate_variation} : null}
                                onChange={this.handleChange}
                                options={fuelStatus}
                                placeholder={'Select..'}
                            />
                        </div>
                        <div className="select-wrap">
                            <div className="label">Validity</div>
                            <DateRangePicker
                                startDate={this.state.data.valid_from ? Moment(+this.state.data.valid_from) : null}
                                startDateId="startDate"
                                endDate={this.state.data.valid_to ?  Moment(+this.state.data.valid_to) : null }
                                endDateId="endDate" 
                                onDatesChange={({ startDate, endDate }) =>{
                                    let { data } = this.state;
                                    data.valid_from = startDate ? startDate.valueOf() : data.valid_from;
                                    data.valid_to = endDate ? endDate.valueOf() : data.valid_to;
                                    this.setState({ data });
                                }} 
                                focusedInput={this.state.focusedInput} 
                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                displayFormat={'DD MMM YYYY'}
                                noBorder={true}
                                minimumNights={0}
                                isOutsideRange={() => false}
                            />
                        </div>
                    </form>
                    <div className = "submit-section submit-area"> 
                    {
                       
                          <div className="button-section"><Button className="add-button" click={() => this.onUpdateFuel()} disabled={!disable} value='Update' /></div> 
                    }
                        <div className ="button-section" >
                            <div className ="add-button" onClick={this.props.toggleAdd}>Close</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default EditFuel;

