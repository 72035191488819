import React, { Component, Fragment } from 'react';
import Search from '../../common/components/search-text/search-text';
import Select from 'react-select';
import Button from '../../home/button-classic/button-classic';
// import Delete from '../../../assets/images/delete.svg';
import Edit from '../../../assets/images/edit.svg';
import Delete from '../../../assets/images/delete.svg';
import Copy from "../../../assets/images/copy.svg";
import Add from '../../../assets/images/add.svg';
import Save from '../../../assets/images/save.svg'
import Cancel from '../../../assets/images/cancel.svg';
import Paste from "../../../assets/images/paste.png";
import { connect } from "react-redux";
import ACTIONS from '../../common/action_constant';
import { toPascalCase } from "../../../utils/validate";
import Download from '../../../assets/images/direct-download.svg';
import { TTBIL_COMPANY_IDS } from '../../../utils/common';
import InputText from '../../common/components/input-text/input-text';
import './scheduler.less';
import { getCookie } from "../../../utils/cookies";
import CheckBox from "../../components/checkbox/checkbox";
import './scheduler.less';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Moment from 'moment';
import { schedule } from 'popmotion';

const CUSTOMER_TYPE = [
    {value: 1, label: 'DENSO CUSTOMER'},
    {value: 2, label: 'NON DENSO CUSTOMER'}
]

const TRIP_TYPE = [
    {value: 1, label: 'SHORT HAUL'},
    {value: 2, label: 'LONG HAUL'}
]

class Scheduler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            offset: 0,
            vehicleOptions: [],
            
            schedulerData: [],
            copiedData: null,
            
            isOpen: false,
            editId: null,
            isUpdate: false,
            search_routeCode: ''
        }
    }

    componentWillMount() {
        this.getVehicleType();
        this.getSchedulerDetails();
    }

    addVehicleType = () => {
        const { vehicleTypes } = this.props;
        let vehicleOptions = [];
        (vehicleTypes || []).forEach(vehicle => {
            vehicleOptions.push({
                vehicle_name: vehicle.name,
                vehicle_type_id: vehicle.id
            })
        })
        this.setState({ vehicleOptions });
    }

    getVehicleType = () => {
        const { dispatch, company_id } = this.props;
        const param = { companyId: company_id}
        dispatch({ 
            type: ACTIONS.COMPANY_ACCOUNTS.GET_VEHICLE_DETAILS,
            param,
            isTTBIL: true,
            onSuccess: () => {
                this.addVehicleType();
            }
        });
    }

    toggleStatus = (e, index) => {
        const { schedulerData } = this.state;
        schedulerData[index].is_active = !schedulerData[index].is_active;
        this.setState({ schedulerData });
    }

    onHandleChange = (selectedOption, index, value) => {
        const { schedulerData } = this.state;
        schedulerData[index][selectedOption] = value.value;
        this.setState({ schedulerData });
    }

    onChangeHandler = (e, index) => {
        let search_routeCode = "";
        search_routeCode = e.target.value;
        this.setState({ search_routeCode }, this.getRouteCode(index));
    }

    changeHandler = (value) => {
        this.setState({ search: value });
    }

    onSearch = () => {
        this.setState({ offset: 0 }, this.getSchedulerDetails);
    }

    clearFilterOnCancel = () => {
        this.setState({ search: '', offset: 0 }, this.getSchedulerDetails);
    }

    onRouteCodeChange = (index, value) => {
        const { schedulerData } = this.state;
        schedulerData[index].route_code = value ? value : '';
        this.setState({schedulerData})
    }

    onVehicleChange = (selectedOption, index, value) => {
        const { schedulerData } = this.state;
        schedulerData[index][selectedOption][0].vehicle_name = value.vehicle_name;
        schedulerData[index][selectedOption][0].vehicle_type_id = value.vehicle_type_id;
        this.setState({ schedulerData });
    }

    onAddScheduler = () => {
        let { schedulerData } = this.state;
        let hasRecord = schedulerData.filter((scheduler) => !scheduler.scheduler_id)
        const newData = {
            company_id: this.props.company_id,
            route_code: "",
            plan: [
                {
                    code: "",
                    gate_in_time: "",
                    gate_out_time: "",
                    transit_hours: "",
                    loading_hours: "",
                    transit_time: ""
                },
                {
                    code: "",
                    reporting_time: "",
                    gate_in_time: "",
                    gate_out_time: "",
                    transit_hours: "",
                    unloading_hours: "",
                    transit_time: ""
                }
            ],
            di_time: null,
            origin_gate_in_time: null,
            customer_type: undefined,
            valid_from: null,
            valid_to: null,
            is_active: true,
            vehicle_type: [{vehicle_name: "", vehicle_type_id: 0}],
            tat: "",
            trip_type: undefined,
            // source_transit_time: null,
            // destination_transit_time: null
        }
        if(hasRecord.length == 0){
            schedulerData.push(newData)
        }
        this.setState({ schedulerData });
    }

    onEditScheduler = (id) => {
        this.setState({
            editId: this.state.editId===id ? null : id,
            isAdded: false
        });
    }

    onCopyScheduler = (id) => {
        let { schedulerData, copiedData } = this.state;
        copiedData = JSON.parse(JSON.stringify(schedulerData[id]));
        this.setState({ copiedData });
    }

    onPasteScheduler = (id) => {
        let { schedulerData, copiedData } = this.state;
        if(copiedData){
            schedulerData[id].customer_type = copiedData.customer_type;
            // schedulerData[id].destination = copiedData.destination;
            schedulerData[id].plan = copiedData.plan;
            schedulerData[id].di_time = copiedData.di_time;
            // schedulerData[id].destination_transit_time = copiedData.destination_transit_time;
            schedulerData[id].is_active = copiedData.is_active;
            schedulerData[id].origin_gate_in_time = copiedData.origin_gate_in_time;
            schedulerData[id].route_code = copiedData.route_code;
            // schedulerData[id].source = copiedData.source;
            // schedulerData[id].source_transit_time = copiedData.source_transit_time;
            schedulerData[id].tat = copiedData.tat;
            schedulerData[id].trip_type = copiedData.trip_type;
            schedulerData[id].valid_from = copiedData.valid_from;
            schedulerData[id].valid_to = copiedData.valid_to;
            schedulerData[id].vehicle_type[0].vehicle_name = copiedData.vehicle_type[0].vehicle_name;
            schedulerData[id].vehicle_type[0].vehicle_type_id = copiedData.vehicle_type[0].vehicle_type_id;
        }
        this.setState({ schedulerData });
    }

    onCancelScheduler = (scheduler_id, index) => {
        if(scheduler_id){
          this.setState({
            editId: null,
            errorMsg: null
            }) 
        } else {
          const { schedulerData } = this.state;
          schedulerData.splice(index,1);
          this.setState({
            schedulerData
          })
        }
    }

    getRouteCode(e, index) {
        const { dispatch, company_id } = this.props;
        const { schedulerData, offset } = this.state
        const param = {
            offset,
            limit: 10,
            companyId: company_id
        }
        if(e.target.value) {
            param.search = e.target.value;
        }
        dispatch({
            type: ACTIONS.HOME.GET_ROUTE_CODE,
            param,
            onSuccess: (data) => {
                if(data.length >= 1) {
                    schedulerData[index].plan[0].code = data[0].source;
                    if(data[0].destination_list.length > 1){
                        for(let i = 0; i < data[0].destination_list.length; i++){
                            this.onMultipleDestination(index, i, data[0].destination_list[i]);
                        }
                    } else {
                        schedulerData[index].plan[1].code = data[0].destination;
                    }
                    
                } else {
                    schedulerData[index].plan[0].code = null;
                    schedulerData[index].plan[1].code = null;
                }
                this.setState({ schedulerData })
            }
        })
    }

    getSchedulerDetails() {
        const { dispatch, company_id } = this.props;
        const { search, offset } = this.state
        const param = {
            offset,
            limit: 10,
            companyId: company_id
        }
        if(search) {
            param.search = search
        }
        dispatch({
            type: ACTIONS.HOME.GET_SCHEDULER_CODE,
            param,
            onSuccess: (data) => {
                this.setState({ editId: null, errorMsg: null, schedulerData: data})
                if(data.length === 0 && !search){
                    this.onAddScheduler();
                }
                
            }
        })
    }

    downloadSchedulerDetails=(withData)=>{
        const { dispatch, company_id } = this.props;
        const param = { companyId: company_id, withData}
        dispatch({ 
          type : ACTIONS.HOME.DOWNLOAD_SCHEDULER_DETAILS, 
          param,
          onSuccess: ((data) => {
            this.setState({
                downloadLink: data.link
            });
            this.downloadBtn.click();
        })
        });
    }

    uploadSchedulerDetails = (e) =>{
            let formData = new FormData();
            const { dispatch , company_id} = this.props;
            const param = { companyId: company_id}
            formData.append('file', e.target.files[0]);
            dispatch({
               type :ACTIONS.HOME.UPLOAD_SCHEDULER_DETAILS,
               param,
               data: formData, 
               onSuccess: ((data) => {
                 if(data)
                 this.getSchedulerDetails()
               })
            })
    }

    // getTAT = (index) => {
    //     let { schedulerData } = this.state;
    //     console.log("scheduler time",schedulerData)
    //     let source_gate_in = schedulerData[index].plan[0].gate_in_time && (schedulerData[index].plan[0].gate_in_time).split(":");
    //     let return_time = schedulerData[index].origin_gate_in_time && (schedulerData[index].origin_gate_in_time).split(":");
    //     if(source_gate_in && return_time){
    //         source_gate_in[1] = (source_gate_in[1] == 0) ? 60 : Math.abs(parseInt(source_gate_in[1])-60);
    //         return_time[1] = (return_time[1] == 0) ? 60 : Math.abs(parseInt(return_time[1])-60);
    //     }
    //     let hours = source_gate_in && return_time ? (Math.abs(parseInt(source_gate_in[0])-parseInt(return_time[0])) >= 24 ? (Math.abs(parseInt(source_gate_in[0])-parseInt(return_time[0]))-24) : (Math.abs(parseInt(source_gate_in[0])-parseInt(return_time[0])))) : ''
    //     let min = source_gate_in && return_time ? (parseInt(source_gate_in[1])-parseInt(return_time[1]) < 0 ? (Math.abs(parseInt(source_gate_in[1])+parseInt(return_time[1]))) : (Math.abs(parseInt(source_gate_in[1])-parseInt(return_time[1])))) : ''
    //     hours = source_gate_in && return_time ? (parseInt(source_gate_in[1])-parseInt(return_time[1]) < 0 ? (hours - 1) : (hours)) : '';
    //     let tat = (hours < 10 ? (`0${hours}`) : (hours)) + ":" + (min < 10 ? (`0${min}`) : (min))
    //     schedulerData[index].tat = tat
    //     this.setState({schedulerData}); 
                      
    // }


    getTAT = (index) => {
        const { schedulerData } = this.state;
        if (!schedulerData[index]) {
            console.error("Invalid index provided for getTAT function");
            return;
        }
        var sourceGateIn = schedulerData[index].plan[0] && schedulerData[index].plan[0].gate_in_time && schedulerData[index].plan[0].gate_in_time.split(":");
        var returnTime = schedulerData[index].origin_gate_in_time && schedulerData[index].origin_gate_in_time.split(":");
        if (!sourceGateIn || !returnTime) {
            console.error("Gate in times are missing for calculating TAT");
            return;
        }
        const sourceHours = parseInt(sourceGateIn[0]);
        const sourceMinutes = parseInt(sourceGateIn[1]);
        const returnHours = parseInt(returnTime[0]);
        const returnMinutes = parseInt(returnTime[1]);
        let hoursDiff = returnHours - sourceHours;
        let minutesDiff = returnMinutes - sourceMinutes;
        if (minutesDiff < 0) {
            minutesDiff += 60;
            hoursDiff--;
        }
        if (hoursDiff < 0) {
            hoursDiff += 24;
        }
        const formattedHours = hoursDiff.toString().padStart(2, '0');
        const formattedMinutes = minutesDiff.toString().padStart(2, '0');
        schedulerData[index].tat = `${formattedHours}:${formattedMinutes}`;
        this.setState({ schedulerData });
    }    
    
    setTime = (index) => {
        let { schedulerData } = this.state;
        let time_1 = null;
        let time_2 = null;
        let destinationCode = schedulerData[index].plan.filter((destination, index) => index !== 0)
        if(schedulerData[index].plan[0].gate_out_time){
            time_1 = schedulerData[index].plan[0].gate_in_time && (schedulerData[index].plan[0].gate_in_time).split(":");
            time_2 = schedulerData[index].plan[0].loading_hours && (schedulerData[index].plan[0].loading_hours).split(":");
            let time_3 = this.calulateTime(time_1,time_2);
            schedulerData[index].plan[0].gate_out_time = time_3;
        }
        if(schedulerData[index].plan[1].reporting_time){
            time_1 = schedulerData[index].plan[0].gate_out_time && (schedulerData[index].plan[0].gate_out_time).split(":");
            time_2 = schedulerData[index].plan[0].transit_time && (schedulerData[index].plan[0].transit_time).split(":");
            let time_3 = this.calulateTime(time_1,time_2);
            schedulerData[index].plan[1].reporting_time = time_3;
        }
        
        (destinationCode || []).forEach((dest,destIndex) => {
            if(destinationCode.length > 2){
                if(dest.gate_in_time){
                    time_1 = dest.reporting_time && (dest.reporting_time).split(":");
                    time_2 = dest.transit_hours && (dest.transit_hours).split(":");
                    let time_3 = this.calulateTime(time_1,time_2);
                    schedulerData[index].plan[destIndex+1].gate_in_time = time_3;
                }
                if(dest.gate_out_time){
                    time_1 = dest.gate_in_time && (dest.gate_in_time).split(":");
                    time_2 = dest.unloading_hours && (dest.unloading_hours).split(":");
                    let time_3 = this.calulateTime(time_1,time_2);
                    schedulerData[index].plan[destIndex+1].gate_out_time = time_3;
                }
                if(dest.origin_gate_in_time){
                    time_1 = dest.gate_out_time && (dest.gate_out_time).split(":");
                    time_2 = dest.transit_time && (dest.transit_time).split(":");
                    let time_3 = this.calulateTime(time_1,time_2);
                    if(destIndex === (destinationCode.length-1)){
                        schedulerData[index].origin_gate_in_time = time_3;
                    } else {
                        schedulerData[index].plan[destIndex+2].reporting_time = time_3;
                    }
                    
                }
            } else {
                if(schedulerData[index].plan[1].gate_in_time){
                    time_1 = schedulerData[index].plan[1].reporting_time && (schedulerData[index].plan[1].reporting_time).split(":");
                    time_2 = schedulerData[index].plan[1].transit_hours && (schedulerData[index].plan[1].transit_hours).split(":");
                    let time_3 = this.calulateTime(time_1,time_2);
                    schedulerData[index].plan[1].gate_in_time = time_3;
                }
                if(schedulerData[index].plan[1].gate_out_time){
                    time_1 = schedulerData[index].plan[1].gate_in_time && (schedulerData[index].plan[1].gate_in_time).split(":");
                    time_2 = schedulerData[index].plan[1].unloading_hours && (schedulerData[index].plan[1].unloading_hours).split(":");
                    let time_3 = this.calulateTime(time_1,time_2);
                    schedulerData[index].plan[1].gate_out_time = time_3;
                }
                if(schedulerData[index].origin_gate_in_time){
                    time_1 = schedulerData[index].plan[1].gate_out_time && (schedulerData[index].plan[1].gate_out_time).split(":");
                    time_2 = schedulerData[index].plan[1].transit_time && (schedulerData[index].plan[1].transit_time).split(":");
                    let time_3 = this.calulateTime(time_1,time_2);
                    schedulerData[index].origin_gate_in_time = time_3;
                }
            }
        })
        this.setState({ schedulerData });
        this.getTAT(index);
    }

    calulateTime = (time_1,time_2) => {
        let hours = time_1 && time_2 ? (Math.abs(parseInt(time_1[0])+parseInt(time_2[0])) >= 24 ? (Math.abs(parseInt(time_1[0])+parseInt(time_2[0]))-24) : (Math.abs(parseInt(time_1[0])+parseInt(time_2[0])))) : ''
        let min = time_1 && time_2 ? (Math.abs(parseInt(time_1[1])+parseInt(time_2[1])) >= 60 ? (Math.abs(parseInt(time_1[1])+parseInt(time_2[1]))-60) : (Math.abs(parseInt(time_1[1])+parseInt(time_2[1])))) : ''
        hours = time_1 && time_2 ? (Math.abs(parseInt(time_1[1])+parseInt(time_2[1])) >= 60 ? (hours + 1) : (hours)) : ''
        let time_3 = (hours < 10 ? (`0${hours}`) : (hours)) + ":" + (min < 10 ? (`0${min}`) : (min));
        return time_3;
    }

    previous = () =>{
		if(this.state.offset > 0){
			this.setState({
				offset : this.state.offset-10
			},this.getSchedulerDetails);
		}
	}
	next = () =>{
		this.setState({
			offset : this.state.offset+10
		},this.getSchedulerDetails);
    }

    onMultipleDestination = (index, destIndex, destinationCode) => {
        const { schedulerData } = this.state;
        const multi_destination = {
            code: destinationCode,
            reporting_time: "",
            gate_in_time: "",
            gate_out_time: "",
            transit_hours: "",
            unloading_hours: ""
        }
        if(destIndex === 0){
            schedulerData[index].plan[1].code = destinationCode;
        } else {
            schedulerData[index].plan.push(multi_destination);
        }
        
        this.setState(schedulerData)
    }

    onDeleteScheduler = (id) => {
        const { dispatch } = this.props;
        const data = { scheduler_id: id }
        dispatch({
            type: ACTIONS.HOME.DELETE_SCHEDULER_CODE,
            data,
            onSuccess: () => {
                this.getSchedulerDetails();
            }
        })
    }

    saveSchedulerDetails(data) {
        const { dispatch } = this.props;
        if(data.plan[0].code && data.plan[1].code !== -1){
            if(data.scheduler_id){
                dispatch({
                    type: ACTIONS.HOME.UPDATE_SCHEDULER_CODE,
                    data,
                    onSuccess: () => {
                        this.setState({ editId: null, errorMsg: null })
                        this.getSchedulerDetails()
                    }
                })
            } else {
                dispatch({
                    type: ACTIONS.HOME.ADD_SCHEDULER_CODE,
                    data,
                    onSuccess: () => {
                        this.setState({ editId: null, errorMsg: null})
                        this.getSchedulerDetails()
                    }
                })
            }
        } else {
            this.setState({ errorMsg: "Please enter all the fields"})
        }
    }

    render () {
        const { schedulerData, isUpdate, editId } = this.state;
        const user = JSON.parse(getCookie("user"));
        return(
            <div className={'scheduler'}>
                <div>
                    <Search id = "search" value = {this.state.search} placeholder = "Search...soure,destination,routecode" changeHandler = {this.changeHandler} click = {this.onSearch} canClear={this.state.search !== ''} handleClearFilter={this.clearFilterOnCancel} />
                    {/* <Button value={'+ New Record'} click={this.toggleAdd}/> */}
                    <button className="button-classic" onClick={() => this.downloadSchedulerDetails(false)}>  Download Template </button>
                    <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
                    <button className="button-classic" onClick={() => this.downloadSchedulerDetails(true)}>  Download </button>
                    <a className="download-btn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
                    <button className="button-classic" onClick={() => {this.uploadExcel.click()}}> Upload </button>
                    <input type='file' style ={{display :'none'}} ref={(e) => { this.uploadExcel = e ;}} onChange={this.uploadSchedulerDetails} onClick={(e) => {e.target.value = null }}/>
                </div>
                
                <div className='scheduler_detail_section'>
                    <table className="scheduler_detail">
                        <thead className="header">
                            <tr>
                            <th className="code-header" style={{width: "120px"}} rowSpan={2}>Action</th>
                            <th className="action-header" rowSpan={2}> Status </th>
                            <th className="code-header" rowSpan={2}>Customer Type</th>
                            <th className="code-header" rowSpan={2}>Trip Type</th>
                            <th className="code-header" rowSpan={2}>Source Code</th>
                            <th className="code-header" rowSpan={2}>Destination Code</th>
                            <th className="code-header" rowSpan={2}>Route Code</th>
                            <th className="code-header" rowSpan={2}>Vehicle Type</th>
                            <th className="code-header" colSpan={5}>Source
                                {/* <th className="code-header subheader"> DI Time</th>
                                <th className="code-header subheader"> Gate In Time</th>
                                <th className="code-header subheader"> Gate Out Time</th> */}
                            </th>
                            <th className="code-header" rowSpan={2}>Transit Time (Hours)</th>
                            <th className="code-header" colSpan={5}>Destination
                                {/* <th className="code-header subheader"> Report Time</th>
                                <th className="code-header subheader"> Gate In Time</th>
                                <th className="code-header subheader"> Gate Out Time</th> */}
                            </th>
                            <th className="code-header" rowSpan={2}>Transit Time (Hours)</th>
                            <th className="code-header" rowSpan={2}>Source Return Gate In Time</th>
                            <th className="code-header" rowSpan={2}>Validity</th>
                            <th className="action-header" style={{width: "100px"}} rowSpan={2}> TAT </th>
                            </tr>
                            <tr>
                            {/* <th className="action-header" colSpan={8}></th> */}
                            
                                <th className="code-header " style={{width: "95px"}}> DI Time</th>
                                <th className="code-header " style={{width: "95px"}}> Transit Hours</th>
                                <th className="code-header " style={{width: "95px"}}> Gate In Time</th>
                                <th className="code-header " style={{width: "95px"}}> Loading Hours</th>
                                <th className="code-header " style={{width: "95px"}}> Gate Out Time</th>

                            
                                <th className="code-header " style={{width: "95px"}}> Report Time</th>
                                <th className="code-header " style={{width: "95px"}}> Transit Hours</th>
                                <th className="code-header " style={{width: "95px"}}> Gate In Time</th>
                                <th className="code-header " style={{width: "95px"}}> Unloading Hours</th>
                                <th className="code-header " style={{width: "95px"}}> Gate Out Time</th>
                            </tr>
                            
                        </thead>
                        <tbody>
                            {(schedulerData || []).map((scheduler,index) => {
                                let sourceData = scheduler && scheduler.plan[0];
                                let destinationCode = scheduler && scheduler.plan.filter((destination,destinationIndex) => destinationIndex > 0);
                                return(
                                    
                                <tr style={{borderBottom: "2px solid #eeeeee"}}>
                                {(editId === index || !scheduler.scheduler_id) ?
                                <Fragment>
                                <td className="action-header">
                                    <div>
                                    <div className={'delete-icon'} style={{backgroundImage: `url(${Save})`}} onClick={() => this.saveSchedulerDetails(scheduler)}/>
                                    <div className={'delete-icon'} style={{backgroundImage: `url(${Cancel})`}} onClick={() => this.onCancelScheduler(scheduler.scheduler_id, index)}></div>
                                    <div className={'delete-icon'} style={{backgroundImage: `url(${Paste})`}} onClick={() => this.onPasteScheduler(index)}></div>
                                    </div>
                                    <div className='danger-color fs-12'>{this.state.errorMsg}</div>
                                </td>
                                <td className="action-header">
                                    <span className="head active mt-30" style={{paddingRight: "30px"}}>
                                    <CheckBox type={user.company_type} status={scheduler.is_active} user={user} id={index} handleChange = {this.toggleStatus}/>
								    </span>
								</td>
                                <td className="code-header">
                                    <div className="select-option">
                                        <Select
                                            className="select"
                                            options={CUSTOMER_TYPE}
                                            value={CUSTOMER_TYPE.filter(val => val.value == scheduler.customer_type)}
                                            onChange={this.onHandleChange.bind(
                                                this,
                                                "customer_type",
                                                index
                                              )}
                                            />
                                    </div>
                                </td>
                                <td className="code-header">
                                    <Select
                                        className="select"
                                        options={TRIP_TYPE}
                                        value={TRIP_TYPE.filter(val => val.value == scheduler.trip_type)}
                                        onChange={this.onHandleChange.bind(
                                            this,
                                            "trip_type",
                                            index
                                          )}
                                        />
                                </td>
                                <td className="code-header">{sourceData.code}</td>
                                <td className="code-header">
                                {(destinationCode || []).map((destinationData) => {
                                    return (
                                        <tr className="code-header">{destinationData.code}</tr>
                                )})}
                                </td>
                                <td className="code-header" style={{width: '100px'}}>
                                    <InputText
                                        placeholder="Route Code"
                                        changeHandler={(e) => {this.onRouteCodeChange(index, e)}}
                                        value={scheduler.route_code}
                                        additionalProps={{ onBlur: (e) => {this.getRouteCode(e, index) }}}
								    /></td>
                                <td className="code-header">
                                    <Select
                                        className="select"
                                        options={this.state.vehicleOptions}
                                        value={(this.state.vehicleOptions || []).filter(val => val.vehicle_type_id == scheduler.vehicle_type[0].vehicle_type_id)}
                                        onChange={this.onVehicleChange.bind(
                                            this,
                                            "vehicle_type",
                                            index
                                          )}
                                        getOptionLabel ={(option)=> option.vehicle_name}
                                        getOptionValue ={(option)=> option.vehicle_type_id}
                                        />
                                
                                </td>
                                <td className="code-header" colSpan={5}>
                                {/* {(scheduler.plan || []).map((sourceData,sourceIndex) => {
                                    return (
                                        <Fragment> */}
                                            <tr>
                                                <td className='code-header' style={{width: "20%"}}>{
                                                    <TimePicker
                                                    showSecond={false}
                                                    placeholder={'Time'}
                                                    className={'wt-90p ht-30'}
                                                    clearText='clear'
                                                    value={scheduler.di_time && Moment(scheduler.di_time, 'HH:mm') || null}
                                                    onChange={(value) => {
                                                            let { schedulerData } = this.state;
                                                            let source_di_hours = value && (Moment(value).format('HH:mm')).split(":");
                                                            source_di_hours[0] = (source_di_hours[0] === "00" ? "24" : source_di_hours[0]); 
                                                            let trans_hours = sourceData.transit_hours && (sourceData.transit_hours).split(":");
                                                            let hours = source_di_hours && trans_hours ? (Math.abs(parseInt(source_di_hours[0])-parseInt(trans_hours[0])) >= 24 ? (Math.abs(parseInt(source_di_hours[0])-parseInt(trans_hours[0]))-24) : (Math.abs(parseInt(source_di_hours[0])-parseInt(trans_hours[0])))) : ''
                                                            let min = source_di_hours && trans_hours ? (Math.abs(parseInt(source_di_hours[1])-parseInt(trans_hours[1])) >= 60 ? (Math.abs(parseInt(source_di_hours[1])-parseInt(trans_hours[1]))-60) : (Math.abs(parseInt(source_di_hours[1])-parseInt(trans_hours[1])))) : ''
                                                            hours = source_di_hours && trans_hours ? (Math.abs(parseInt(source_di_hours[1])+parseInt(trans_hours[1])) >= 60 ? (hours - 1) : (hours)) : ''
                                                            let gate_in_time = (hours < 10 ? (`0${hours}`) : (hours)) + ":" + (min < 10 ? (`0${min}`) : (min))
                                                            schedulerData[index].di_time = value ? Moment(value).format('HH:mm') : '';
                                                            schedulerData[index].plan[0].gate_in_time = gate_in_time;
                                                            this.setState({ schedulerData });
                                                            this.setTime(index);
                                                    }
                                                    }
                                                    />
                                                }</td>
                                                <td className='code-header' style={{width: "20%"}}>{
                                                    <TimePicker
                                                    showSecond={false}
                                                    placeholder={'Time'}
                                                    className={'wt-90p ht-30'}
                                                    clearText='clear'
                                                    value={sourceData.transit_hours && Moment(sourceData.transit_hours, 'HH:mm') || null}
                                                    onChange={(value) => {
                                                            let { schedulerData } = this.state;
                                                            let source_di_hours = scheduler.di_time && (scheduler.di_time).split(":");
                                                            source_di_hours[0] = (source_di_hours[0] === "00" ? "24" : source_di_hours[0]);
                                                            let trans_hours = value && (Moment(value).format('HH:mm')).split(":");
                                                            let hours = source_di_hours && trans_hours ? (Math.abs(parseInt(source_di_hours[0])-parseInt(trans_hours[0])) >= 24 ? (Math.abs(parseInt(source_di_hours[0])-parseInt(trans_hours[0]))-24) : (Math.abs(parseInt(source_di_hours[0])-parseInt(trans_hours[0])))) : ''
                                                            let min = source_di_hours && trans_hours ? (Math.abs(parseInt(source_di_hours[1])+parseInt(trans_hours[1])) >= 60 ? (Math.abs(parseInt(source_di_hours[1])+parseInt(trans_hours[1]))-60) : (Math.abs(parseInt(source_di_hours[1])+parseInt(trans_hours[1])))) : ''
                                                            hours = source_di_hours && trans_hours ? (Math.abs(parseInt(source_di_hours[1])+parseInt(trans_hours[1])) >= 60 ? (hours - 1) : (hours)) : ''
                                                            let gate_in_time = (hours < 10 ? (`0${hours}`) : (hours)) + ":" + (min < 10 ? (`0${min}`) : (min))
                                                            schedulerData[index].plan[0].transit_hours = value ? Moment(value).format('HH:mm') : '';
                                                            schedulerData[index].plan[0].gate_in_time = gate_in_time;
                                                            this.setState({ schedulerData });
                                                            this.setTime(index);
                                                    }
                                                    }
                                                    />
                                                }</td>
                                                <td className='code-header' style={{width: "20%"}}>{sourceData.gate_in_time}</td>
                                                <td className='code-header' style={{width: "20%"}}>{
                                                    <TimePicker
                                                        showSecond={false}
                                                        placeholder={'Time'}
                                                        className={'wt-90p ht-30'}
                                                        value={sourceData.loading_hours && Moment(sourceData.loading_hours, 'HH:mm') || null}
                                                        onChange={(value) => {
                                                                let { schedulerData } = this.state;
                                                                let gate_in_time = sourceData.gate_in_time && (sourceData.gate_in_time).split(":");
                                                                let loading_hours = value && (Moment(value).format('HH:mm')).split(":");
                                                                let hours = gate_in_time && loading_hours ? (Math.abs(parseInt(gate_in_time[0])+parseInt(loading_hours[0])) >= 24 ? (Math.abs(parseInt(gate_in_time[0])+parseInt(loading_hours[0]))-24) : (Math.abs(parseInt(gate_in_time[0])+parseInt(loading_hours[0])))) : ''
                                                                let min = gate_in_time && loading_hours ? (Math.abs(parseInt(gate_in_time[1])+parseInt(loading_hours[1])) >= 60 ? (Math.abs(parseInt(gate_in_time[1])+parseInt(loading_hours[1]))-60) : (Math.abs(parseInt(gate_in_time[1])+parseInt(loading_hours[1])))) : ''
                                                                hours = gate_in_time && loading_hours ? (Math.abs(parseInt(gate_in_time[1])+parseInt(loading_hours[1])) >= 60 ? (hours + 1) : (hours)) : ''
                                                                let gate_out_time = (hours < 10 ? (`0${hours}`) : (hours)) + ":" + (min < 10 ? (`0${min}`) : (min))
                                                                schedulerData[index].plan[0].loading_hours = value ? Moment(value).format('HH:mm') : '';
                                                                schedulerData[index].plan[0].gate_out_time = gate_out_time;
                                                                this.setState({ schedulerData });
                                                        }
                                                        }
                                                    />}
                                                </td>
                                                <td className='code-header' style={{width: "20%"}}>{sourceData.gate_out_time}</td>
                                            </tr>
                                            
                                        {/* </Fragment>
                                    )
                                })} */}
                                </td>
                                
                                <td className='code-header'>{
                                    <TimePicker
                                        showSecond={false}
                                        placeholder={'Time'}
                                        className={'wt-90p ht-30'}
                                        value={scheduler.plan[0].transit_time && Moment(scheduler.plan[0].transit_time, 'HH:mm') || null}
                                        onChange={(value) => {
                                                let { schedulerData } = this.state;
                                                let gate_out_time = scheduler.plan[0].gate_out_time && (scheduler.plan[0].gate_out_time).split(":");
                                                // let gate_out_time = scheduler.plan[0].gate_out_time && (scheduler.plan[0].gate_out_time).split(":");
                                                let source_transit_time = value && (Moment(value).format('HH:mm')).split(":");
                                                let hours = gate_out_time && source_transit_time ? (Math.abs(parseInt(gate_out_time[0])+parseInt(source_transit_time[0])) >= 24 ? (Math.abs(parseInt(gate_out_time[0])+parseInt(source_transit_time[0]))-24) : (Math.abs(parseInt(gate_out_time[0])+parseInt(source_transit_time[0])))) : ''
                                                let min = gate_out_time && source_transit_time ? (Math.abs(parseInt(gate_out_time[1])+parseInt(source_transit_time[1])) >= 60 ? (Math.abs(parseInt(gate_out_time[1])+parseInt(source_transit_time[1]))-60) : (Math.abs(parseInt(gate_out_time[1])+parseInt(source_transit_time[1])))) : ''
                                                hours = gate_out_time && source_transit_time ? (Math.abs(parseInt(gate_out_time[1])+parseInt(source_transit_time[1])) >= 60 ? (hours + 1) : (hours)) : ''
                                                let dest_report_time = (hours < 10 ? (`0${hours}`) : (hours)) + ":" + (min < 10 ? (`0${min}`) : (min))
                                                schedulerData[index].plan[0].transit_time = value ? Moment(value).format('HH:mm') : '';
                                                schedulerData[index].plan[1].reporting_time = dest_report_time;
                                                this.setState({ schedulerData });
                                        }
                                        }
                                    />}
                                </td>
                                <td className="code-header" colSpan={5}>
                                {(destinationCode || []).map((destinationData, destIndex) => {
                                    return(
                                        <Fragment>
                                            <tr>
                                                {/* <td className="code-header">{destinationDate.code}</td> */}
                                                <td className='code-header' style={{width: "20%"}}>{destinationData.reporting_time}</td>
                                                
                                                <td className='code-header' style={{width: "20%"}}>{
                                                    <TimePicker
                                                        showSecond={false}
                                                        placeholder={'Time'}
                                                        className={'wt-90p ht-30'}
                                                        value={destinationData.transit_hours && Moment(destinationData.transit_hours, 'HH:mm') || null}
                                                        onChange={(value) => {
                                                                let { schedulerData } = this.state;
                                                                let reporting_time = destinationData.reporting_time && (destinationData.reporting_time).split(":");
                                                                let dest_transit_time = value && (Moment(value).format('HH:mm')).split(":");
                                                                let hours = reporting_time && dest_transit_time ? (Math.abs(parseInt(reporting_time[0])+parseInt(dest_transit_time[0])) >= 24 ? (Math.abs(parseInt(reporting_time[0])+parseInt(dest_transit_time[0]))-24) : (Math.abs(parseInt(reporting_time[0])+parseInt(dest_transit_time[0])))) : ''
                                                                let min = reporting_time && dest_transit_time ? (Math.abs(parseInt(reporting_time[1])+parseInt(dest_transit_time[1])) >= 60 ? (Math.abs(parseInt(reporting_time[1])+parseInt(dest_transit_time[1]))-60) : (Math.abs(parseInt(reporting_time[1])+parseInt(dest_transit_time[1])))) : ''
                                                                hours = reporting_time && dest_transit_time ? (Math.abs(parseInt(reporting_time[1])+parseInt(dest_transit_time[1])) >= 60 ? (hours + 1) : (hours)) : ''
                                                                let gate_in_time = (hours < 10 ? (`0${hours}`) : (hours)) + ":" + (min < 10 ? (`0${min}`) : (min))
                                                                schedulerData[index].plan[destIndex+1].transit_hours = value ? Moment(value).format('HH:mm') : '';
                                                                schedulerData[index].plan[destIndex+1].gate_in_time = gate_in_time;
                                                                this.setState({ schedulerData });
                                                        }
                                                        }
                                                    />}
                                                </td>
                                                <td className='code-header' style={{width: "20%"}}>{destinationData.gate_in_time}</td>
                                                
                                                <td className='code-header' style={{width: "20%"}}>{
														<TimePicker
														showSecond={false}
														placeholder={'Time'}
														className={'wt-90p ht-30'}
														clearText='clear'
														value={destinationData.unloading_hours && Moment(destinationData.unloading_hours, 'HH:mm') || null}
														onChange={(value) => {
                                                              let { schedulerData } = this.state;
                                                              let gate_in_time = destinationData.gate_in_time && (destinationData.gate_in_time).split(":");
                                                              let dest_unloading_time = value && (Moment(value).format('HH:mm')).split(":");
                                                              let hours = gate_in_time && dest_unloading_time ? (Math.abs(parseInt(gate_in_time[0])+parseInt(dest_unloading_time[0])) >= 24 ? (Math.abs(parseInt(gate_in_time[0])+parseInt(dest_unloading_time[0]))-24) : (Math.abs(parseInt(gate_in_time[0])+parseInt(dest_unloading_time[0])))) : ''
                                                                let min = gate_in_time && dest_unloading_time ? (Math.abs(parseInt(gate_in_time[1])+parseInt(dest_unloading_time[1])) >= 60 ? (Math.abs(parseInt(gate_in_time[1])+parseInt(dest_unloading_time[1]))-60) : (Math.abs(parseInt(gate_in_time[1])+parseInt(dest_unloading_time[1])))) : ''
                                                                hours = gate_in_time && dest_unloading_time ? (Math.abs(parseInt(gate_in_time[1])+parseInt(dest_unloading_time[1])) >= 60 ? (hours + 1) : (hours)) : ''
                                                                let gate_out_time = (hours < 10 ? (`0${hours}`) : (hours)) + ":" + (min < 10 ? (`0${min}`) : (min))
                                                              schedulerData[index].plan[destIndex+1].unloading_hours = value ? Moment(value).format('HH:mm') : '';
                                                              schedulerData[index].plan[destIndex+1].gate_out_time = gate_out_time
															  this.setState({ schedulerData });
														}
														}
													 />
													}
                                                </td>
                                                <td className='code-header' style={{width: "20%"}}>{destinationData.gate_out_time}</td>
                                                	
                                            </tr>
                                        </Fragment>
                                    )
                                })}
                                </td>
                                <td className="code-header">
                                {(destinationCode || []).map((destinationData, destIndex) => {
                                    return(
                                        <tr>
                                            <td className='code-header'>{
                                        <TimePicker
                                            showSecond={false}
                                            placeholder={'Time'}
                                            className={'wt-90p ht-30'}
                                            value={destinationData.transit_time && Moment(destinationData.transit_time, 'HH:mm') || null}
                                            onChange={(value) => {
                                                    let { schedulerData } = this.state;
                                                    let gate_out_time = destinationData.gate_out_time && (destinationData.gate_out_time).split(":");
                                                    let dest_trans_time = value && (Moment(value).format('HH:mm')).split(":");
                                                    let hours = gate_out_time && dest_trans_time ? (Math.abs(parseInt(gate_out_time[0])+parseInt(dest_trans_time[0])) >= 24 ? (Math.abs(parseInt(gate_out_time[0])+parseInt(dest_trans_time[0]))-24) : (Math.abs(parseInt(gate_out_time[0])+parseInt(dest_trans_time[0])))) : ''
                                                    let min = gate_out_time && dest_trans_time ? (Math.abs(parseInt(gate_out_time[1])+parseInt(dest_trans_time[1])) >= 60 ? (Math.abs(parseInt(gate_out_time[1])+parseInt(dest_trans_time[1]))-60) : (Math.abs(parseInt(gate_out_time[1])+parseInt(dest_trans_time[1])))) : ''
                                                    hours = gate_out_time && dest_trans_time ? (Math.abs(parseInt(gate_out_time[1])+parseInt(dest_trans_time[1])) >= 60 ? (hours + 1) : (hours)) : ''
                                                    let origin_gate_in_time = (hours < 10 ? (`0${hours}`) : (hours)) + ":" + (min < 10 ? (`0${min}`) : (min))
                                                    schedulerData[index].plan[destIndex+1].transit_time = value ? Moment(value).format('HH:mm') : '';
                                                    if((destinationCode.length - 1) > destIndex) {
                                                        schedulerData[index].plan[destIndex+2].reporting_time = origin_gate_in_time
                                                    } else {
                                                        schedulerData[index].origin_gate_in_time = origin_gate_in_time;
                                                    }
                                                    this.setState({ schedulerData });
                                                    this.getTAT(index)
                                            }
                                            }
                                        />}
                                    </td>
                                        </tr>
                                    
                                )})}   
                                </td>
                                <td className="code-header">{scheduler.origin_gate_in_time}</td>
                                <td className="code-header">
                                    <span className={'date-filter'}>
                                    <DateRangePicker
                                        startDate={scheduler.valid_from ? Moment(scheduler.valid_from) : null}
                                        startDateId="startDate"
                                        endDate={scheduler.valid_to ?  Moment(scheduler.valid_to) : null }
                                        endDateId="endDate" 
                                        onDatesChange={({ startDate, endDate }) =>{
                                            let { schedulerData } = this.state;
                                            schedulerData[index].valid_from = startDate ? startDate.valueOf() : scheduler.valid_from;
                                            schedulerData[index].valid_to = endDate ? endDate.valueOf() : scheduler.valid_to;
                                            this.setState({ schedulerData });
                                        }} 
                                        focusedInput={this.state.focusedInput} 
                                        onFocusChange={focusedInput => this.setState({ focusedInput })}
                                        displayFormat={'DD MMM YYYY'}
                                        noBorder={true}
                                        minimumNights={0}
                                        isOutsideRange={() => false}
                                        
                                    />
                                    </span>
                                </td>
                                
                                <td className="code-header">{scheduler.tat}</td>
                                </Fragment> : 
                                <Fragment>
                                    <td className="action-header">
                                        <div>
                                        <div className={'delete-icon'} style={{backgroundImage: `url(${Add})`}} onClick={() => this.onAddScheduler()}/>
                                        <div className={'delete-icon'} style={{backgroundImage: `url(${Edit})`}} onClick={() => this.onEditScheduler(index)}></div>
                                        <div className={'delete-icon'} style={{backgroundImage: `url(${Delete})`}} onClick={() => this.onDeleteScheduler(scheduler.scheduler_id)}/>
                                        <div className={'delete-icon'} style={{backgroundImage: `url(${Copy})`}} onClick={() => this.onCopyScheduler(index)}></div>
                                        </div>
                                    </td>
                                    <td className="action-header">
                                        <span className="head active mt-30" style={{paddingRight: "30px"}}>
                                        <CheckBox type={user.company_type} status={scheduler.is_active} user={user} id={index} handleChange = {
                                             this.toggleStatus}/> 
                                        </span>
                                    </td>  
                                    <td className="code-header">{scheduler.customer_type == 1 ? "DENSO CUSTOMER" : "NON DENSO CUSTOMER"}</td>
                                    <td className="code-header">{scheduler.trip_type == 1 ? "SHORT HAUL" : "LONG HAUL"}</td>
                                    <td className="code-header">{sourceData.code}</td>
                                    <td className="code-header">
                                        {(destinationCode || []).map((destinationData) => {
                                            return (
                                                <tr className="code-header">{destinationData.code}</tr>
                                        )})}
                                    </td>
                                    <td className="code-header">{scheduler.route_code}</td>
                                    <td className="code-header">{scheduler.vehicle_type[0].vehicle_name}</td> 
                                    <td className="code-header" colSpan={5}>
                                        <tr>
                                            <td className="code-header " style={{width: "95px"}}>{scheduler.di_time}</td>
                                            <td className="code-header " style={{width: "95px"}}>{sourceData.transit_hours}</td>
                                            <td className="code-header " style={{width: "95px"}}>{sourceData.gate_in_time}</td>
                                            <td className="code-header " style={{width: "95px"}}>{sourceData.loading_hours}</td>
                                            <td className="code-header " style={{width: "95px"}}>{sourceData.gate_out_time}</td>
                                        </tr>
                                        
                                    </td>
                                    <td className="code-header ">{sourceData.transit_time}</td>
                                     
                                    <td className="code-header" colSpan={5}>
                                        {(destinationCode || []).map((destinationData) => {
                                            return (
                                                <tr>
                                                    <td className="code-header " style={{width: "95px"}}>{destinationData.reporting_time}</td>
                                                    <td className="code-header " style={{width: "95px"}}>{destinationData.transit_hours}</td>
                                                    <td className="code-header " style={{width: "95px"}}>{destinationData.gate_in_time}</td>
                                                    <td className="code-header " style={{width: "95px"}}>{destinationData.unloading_hours}</td>
                                                    <td className="code-header " style={{width: "95px"}}>{destinationData.gate_out_time}</td>
                                                </tr>
                                        )})}
                                    </td> 
                                    <td className="code-header">
                                        {(destinationCode || []).map((destinationData) => {
                                            return (
                                                <tr className="code-header">{destinationData.transit_time}</tr>
                                        )})}
                                    </td>
                                    <td className="code-header ">{scheduler.origin_gate_in_time}</td>
                                    <td className="code-header " style={{width: "200px"}}>{Moment(scheduler.valid_from).format("DD-MM-YYYY") +" to "+ Moment(scheduler.valid_to).format("DD-MM-YYYY")}</td>
                                    <td className="code-header ">{scheduler.tat}</td>
                                    
                                </Fragment>}
                            </tr>
                            )
                        })}
                        </tbody>
                    </table>

                    <div className = "submit-section-pagination">
                        <div className ="next">
                            <Button value = "Next" click = {this.next}/>    
                        </div>
                        <div className ="pre">
                            <Button value = "Previous" click = {this.previous}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        routeCodeList : state.home.routeCodes || [],
        schedulerList : state.home.schedulerCodes || [],
        vehicleTypes : state.companyAccounts.vehicleType,
    };
  };

export default connect(mapStateToProps)(Scheduler);
