import React, { Component, Fragment } from 'react';
import InputText from '../../../common/components/input-text/input-text';
import Select from 'react-select';
import Delete from '../../../../assets/images/delete.svg';
import Add from '../../../../assets/images/add.svg';
import { decimalNumber } from '../../../../utils/validate'
import Button from '../../button-classic/button-classic';

const distance_slab = {
    min_dist : '',
    max_dist : '',
    weight_slab : [
        {
            min_weight: '',
            max_weight: '',
            rate: ''
        }
    ]
}

const weight_slab_obj = {
    min_weight: '',
    max_weight: '',
    rate: ''
}

class ODACharges extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data : {
                // min_oda_charge: props.odaData ? props.odaData.min_oda_charge : '',
                // max_oda_charge: props.odaData ? props.odaData.max_oda_charge : '',
                // flat_rate: props.odaData ? props.odaData.flat_rate : '',
                transporters: props.odaData ? props.odaData.transporters.map(val => { return { label: val.company_name, value: val.id }}) : [],
                // distance_slab: props.odaData ? props.odaData.distance_slab : [{
                //     min_dist : '',
                //     max_dist : '',
                //     weight_slab : [
                //         {
                //             min_weight: '',
                //             max_weight: '',
                //             rate: ''
                //         }
                //     ]
                // }]
                min_charge: props.odaData ? props.odaData.min_charge : '',
                max_charge: props.odaData ? props.odaData.max_charge : '',
                rates: props.odaData ? props.odaData.rates : [{
                    zipcode: [],
                    rate: ''
                }]
            } 
        }   
    }
   //  this.state.data.distance_slab.every((data, i) => (check = (data.min_dist[i] && data.max_dist[i] && data.weight_slab.every((sdata,j)=>(check1 = (sdata.min_weight[j] && sdata.max_weight[j] && sdata.rate[j] ))))))

    onChangeHandler = (value, field) => {
       if (decimalNumber(value) || value == '') {
        const { data } = this.state;
        data[field] = value;
        this.setState({ data });}
    }

    onAddWeight = (index) => {
        const { data } = this.state;
        const row = Object.assign({}, weight_slab_obj);
        data.distance_slab[index].weight_slab.push(row);
        this.setState({ data });
    }

    onDeleteSlabWeight = (index, ind) => {
        const { data } = this.state;
        data.distance_slab[index].weight_slab.splice(ind, 1);
        this.setState({ data });
    }

    onChangeSlabWeight = (mainIndex, index, value, field) => {
       if (decimalNumber(value) || value == '') {
        const { data } = this.state;
        data.distance_slab[mainIndex].weight_slab[index][field] = value;
        this.setState({ data });}
    }

    onChangeDistanceSlab = (index, value, field) => {
        if (decimalNumber(value) || value == '') {
        const { data } = this.state;
        data.distance_slab[index][field] = value;
        this.setState({ data }); }
    }

    onTransporterSearch = (value) => {
        if(value) {
            this.props.getTransporterList(value);
        }
    }

    onSave = () => {
        const { data } = this.state;
        data.transporters = data.transporters.length > 0 ? data.transporters.map(val => { return { company_name : val.label, id : val.value }}) : data.transporters;
        this.props.addPayables(data, 24);
        this.props.toggleAdd(24);
    }

    onAddSlab = () => {
        const { data } = this.state;
        const row = JSON.parse(JSON.stringify(distance_slab));
        data.distance_slab.push(row);
        this.setState({ data });
    }

    onDeleteSlab = (index) => {
        const { data } = this.state;
        data.distance_slab.splice(index, 1);
        this.setState({ data });
    }

    onUpdate = () => {
        const { data } = this.state;
        const { odaData } = this.props;
        data.transporters = data.transporters.length > 0 ? data.transporters.map(val => { return { company_name : val.label, id : val.value }}) : data.transporters;
        data.payable_id = odaData.payable_id;
        data.load_type = odaData.load_type;
        data.mode_type = odaData.mode_type;
        data.type = odaData.type;
        this.props.updatePayables(data, 24);
        this.props.toggleAdd(24);
    }

    validateCharge=(val)=>{
       return val.toString().match(/^\d{1,5}(\.\d{1,3})?$/)
    }

   validData = ({ data } = this.state) =>{
      // let check, check1;
      //  return (data.transporters.length !== 0 && this.validateCharge(data.min_oda_charge) && this.validateCharge(data.flat_rate) && this.validateCharge(data.max_oda_charge) && data.distance_slab.every((data, i) => (check = ((data.min_dist[i] !== null && data.min_dist !== '') && (data.max_dist[i] !== null && data.max_dist !== '') && data.weight_slab.every((sdata, j) => (check1 = ((sdata.min_weight[j] !== null && sdata.min_weight !== '') && (sdata.max_weight[j] !== null && sdata.max_weight !== '') && (sdata.rate[j] !== null && sdata.rate !== '' ))))))))
      return (data.transporters.length > 0 && data.min_charge && data.max_charge && data.rates && data.rates.length > 0 && data.rates[0].zipcode && data.rates[0].rate);
    }

    onAddRates = () => {
        const { data } = this.state;
        data.rates.push({
            zipcode: [],
            rate: ''
        })
        this.setState({ data })
    }
 
    onDeleteRates = (index) => {
        const { data } = this.state;
        data.rates.splice(index, 1);
        this.setState({ data });
    }
 
    render() {
        const { odaData, transporterList, zipCodes } = this.props;
        const { data } = this.state;
        
      const disable = this.validData();

        return (
            <div className = "add-company-wrap add-provider-root add-row">
                <div onClick = {this.props.toggleAdd} className = "overlay">
                    <div className = "modal-popup" onClick={(e) => { e.stopPropagation() }}>
                        <div className = "heading">
                            { `${odaData ? 'Edit' : 'Add'} ODA Charges`}
                        </div>
                        <form className = "form-section" autoComplete = "nopes">
                            <div className="select-wrap">
                                <div className="label">Transporter</div>
                                <Select 
                                    isMulti
                                    className={'select'} 
                                    value={data.transporters}
                                    options={transporterList && transporterList.length > 0 ? transporterList.map(trans => {
                                        return { label : trans.company_name, value: trans.branch_id }
                                    }) : []}
                                    onChange= {(options) => {
                                        const { data } = this.state;
                                        data.transporters = options;
                                        this.setState({ data });
                                    }}
                                    onInputChange={this.onTransporterSearch}
                                />
                            </div>
                            <InputText id='min_charge' placeholder='Minimum Charge' label='Minimum Charge' value={data.min_charge} changeHandler={this.onChangeHandler} type={'number'}/>
                            <InputText id='max_charge' placeholder='Maximum Charge' label='Maximum Charge' value={data.max_charge} changeHandler={this.onChangeHandler} type={'number'}/>
                            <div className={'fuel-escalation-table'}>
                                <div className={'list-section'}>
                                    <div className={'table-header'}>ZIP Code</div>
                                    <div className={'table-header'}>Rate</div>
                                    <div className={'table-header'}>Actions</div>
                                </div>
                                {data.rates && data.rates.length > 0 && data.rates.map((rate, ind) => {
                                return(
                                    <div className={'list-section'}>
                                        <div className={'table-row align-center'}>
                                            <Select 
                                            isMulti 
                                            options={zipCodes && zipCodes.length > 0 ? zipCodes.map(code =>{ return { label: code.zipcode, value: code.zipcode }}) : []}
                                            value={rate.zipcode && rate.zipcode.length > 0 ? rate.zipcode.map(code =>{ return { label: code , value: code }}) : [] }
                                            onChange={(values)=> {
                                                const { data } = this.state;
                                                if(values.length > 0) {
                                                    const result = [];
                                                    values.map(val => {
                                                        result.push(val.label)
                                                    })
                                                    data.rates[ind]['zipcode'] = result;
                                                } else {
                                                    data.rates[ind]['zipcode'] = values;
                                                }
                                                this.setState({ data });
                                            }}
                                            />
                                        </div>
                                        <div className={'table-row align-center'}>
                                            <InputText 
                                                placeholder={'Rate/KG'} 
                                                id='rate' 
                                                value={rate.rate}
                                                changeHandler={(value)=> {
                                                    const { data } = this.state;
                                                    data.rates[ind]['rate'] = value;
                                                    this.setState({ data });
                                                }}
                                                type={'number'}
                                            />
                                        </div>
                                        <div className={'table-row align-center'}>
                                            {data.rates.length - 1 === ind && <div className={'delete-icon'} style={{backgroundImage: `url(${Add})`}} onClick={() => this.onAddRates()}></div>}
                                            {data.rates.length > 1 && <div className={'delete-icon'} style={{backgroundImage: `url(${Delete})`}} onClick={() => this.onDeleteRates(ind)}></div>}
                                        </div>
                                     </div>)})}
                            </div>
                            {/* <InputText id='flat_rate' placeholder='Flat rate/kg' label='Flat Rate' value={data.flat_rate} changeHandler={this.onChangeHandler} type={'number'}/>
                            <div className={'heading'}>Distance Slab</div>
                            <div className={'fuel-escalation-table'}>
                                <div className={'list-section'}>
                                    <div className={'table-header'}>Min Dist.</div>
                                    <div className={'table-header'}>Max Dist.</div>
                                    <div className={'table-header weight'}>
                                        <div className={'label'}>Weight</div>
                                        <div className={'slab-weight'}>
                                            <div>Min Weight</div>
                                            <div>Max Weight</div>
                                            <div>Rate</div>
                                            <div>Actions</div>
                                        </div>
                                    </div>
                                    <div className={'table-header'}>Actions</div>
                                </div>
                                {data.distance_slab && data.distance_slab.length > 0 && data.distance_slab.map((slab, index) => {
                                return(<div className={'list-section oda-charges'}>
                                    <div className={'table-row align-center'}><InputText placeholder={'Min Distance'} id='min_dist' value={slab.min_dist} changeHandler={this.onChangeDistanceSlab.bind(this, index)} type={'number'}/></div>
                                    <div className={'table-row align-center'}><InputText placeholder={'Max Distance'} id='max_dist' value={slab.max_dist} changeHandler={this.onChangeDistanceSlab.bind(this, index)} type={'number'}/></div>
                                    <div className={'table-row slab-weight'}>
                                    {slab.weight_slab && slab.weight_slab.length > 0 && slab.weight_slab.map((weight, ind) => {
                                        return(
                                            <div className={'slab-weight-data'} key={ind}>
                                                <InputText id='min_weight' placeholder={'Min Weight'} value={weight.min_weight} changeHandler={this.onChangeSlabWeight.bind(this, index, ind)} type={'number'}/>
                                                <InputText id='max_weight' placeholder={'Max weight'} value={weight.max_weight} changeHandler={this.onChangeSlabWeight.bind(this, index, ind)} type={'number'}/>
                                                <InputText id='rate' placeholder={'rate/kg'} value={weight.rate} changeHandler={this.onChangeSlabWeight.bind(this, index, ind)} type={'number'}/>
                                                <div className={'slab-action'}>
                                                    {slab.weight_slab.length-1 === ind && <div className={'delete-icon'} style={{backgroundImage: `url(${Add})`}} onClick={() => this.onAddWeight(index)}></div>}
                                                    {slab.weight_slab.length > 1 && <div className={'delete-icon'} style={{backgroundImage: `url(${Delete})`}} onClick={() => this.onDeleteSlabWeight(index, ind)}></div>}
                                                </div>
                                            </div>
                                        )})}
                                    </div>
                                    <div className={'table-row align-center'}>
                                        {data.distance_slab.length-1 === index && <div className={'delete-icon'} style={{backgroundImage: `url(${Add})`}} onClick={() => this.onAddSlab()}></div>}
                                        {data.distance_slab.length > 1 && <div className={'delete-icon'} style={{backgroundImage: `url(${Delete})`}} onClick={() => this.onDeleteSlab(index)}></div>}
                                    </div>
                                </div>)})}
                            </div> */}
                        </form>
                        <div className = "submit-section submit-area">
                       {     
                        <div className="button-section">
                          {!this.props.odaData && <Button className="add-button" click={this.onSave} value="Save" disabled={!disable} />}
                          {this.props.odaData && <Button className="add-button" click={this.onUpdate} value="Update" disabled={!disable}  />}
                       </div> 
                        }
                            <div className ="button-section" >
                                <div className ="add-button" onClick={this.props.toggleAdd}>Close</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ODACharges;