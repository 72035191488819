import ACTION from "../common/action_constant";

const vendors = (
	state = {
		vendorsList: [],
		vendorModes: [],
        chargesList: [],
		link:'',
        vendorMode:[],
        approvalLevel: [],
        approvalCounts: [],
        vendorSource:[],
        incotermsOptions:[],
        billSequenceList: [],
        billSequenceDropdown: []
	}, action) => {
	let tempState = Object.assign({}, state);

    if(action.type === ACTION.VENDORS.GET_VENDORS_DATA) {
        tempState.vendorsList = action.data;
        return tempState;
    }
    else if(action.type === ACTION.VENDORS.ALL_VENDOR_MODES) {
        (action.data || []).forEach((mode) => {
            mode.label = mode.name;
            mode.value = mode.id;
        });
    	tempState.vendorModes = action.data;
        return tempState;
    }
    else if (action.type === ACTION.VENDORS.ALL_CHARGES) {
        tempState.chargesList = action.data;
        return tempState;
    }
	else if (action.type === ACTION.VENDORS.DOWNLOAD_CONTRACT_FORM) {
		 tempState.link = action.data;
		 return tempState;
	}
    else if (action.type === ACTION.VENDORS.APPROVAL_LEVELS_DATA) {
        tempState.approvalLevel = action.data;
        return tempState;
    }
    else if (action.type === ACTION.VENDORS.VENDOR_APPROVAL_COUNT) {
        tempState.approvalCounts = action.data;
        return tempState;
    }
    else if (action.type === ACTION.VENDORS.SET_VENDOR_SOURCE_LIST) {
        tempState.vendorSource = action.data || [];
        return tempState;
    }  
    else if (action.type === ACTION.VENDORS.SET_VENDOR_DETAILS_FOR_SAP) {
      tempState.vendorDetails = action.data || [];
      return tempState;
    } else if (action.type === ACTION.VENDORS.SET_PAYMENT_TERMS_LIST) {
      tempState.vendorPaymentDetails = action.data || [];
      return tempState;
    } else if (action.type === ACTION.VENDORS.SET_CURRENCY_LIST) {
      tempState.vendorCurrencyDetails = action.data || [];
      return tempState;
    }
    if (action.type === ACTION.VENDORS.GET_VENDORS_MODE_DATA) {
        tempState.vendorMode = action.data;
        return tempState;
    }else if(action.type === ACTION.VENDORS.SAVE_INCO_TERMS_OPTIONS) {
        tempState.incotermsOptions = action.data;
        return tempState;
	}
    else if(action.type === ACTION.VENDORS.SET_BILL_SEQUENCE_LIST){
        tempState.billSequenceList = action.data;
        return tempState;
    }
    else if(action.type === ACTION.VENDORS.SET_BILL_SEQUENCE_DROPDOWNS){
        tempState.billSequenceDropdown = action.data;
        return tempState;
    }
   
	return state;
};

export default vendors;
