import React, { Component } from 'react';

class deleteDepartment extends Component {
   constructor(props) {
      super(props);
      this.state = {

      }
   }

   render() {
      const { props, state } = this;
      return (
         <div className="delete-company-wrap">
            <div onClick={props.close} className="overlay"></div>
            <div className={"modal-popup1 small-modal"} style={this.props.deactivateUser ? {boxShadow: 'black 0px 0px 20px 0px'} : {}}>
               <div>
                  <div className="question"> Are you sure ?</div>
                  <div className="submit-section submit-area delete-confirmation">
                     <div className="button-section">
                        <div className="add-button" onClick={this.props.delete}>{this.props.deactivateUser ? 'Deactivate' : 'Delete'}</div>
                     </div>
                     <div className="button-section">
                        <div className="add-button" onClick={this.props.toggleDelete}>Close</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      );
   }
}

export default deleteDepartment;