import React, { Component } from "react";
import MainComponent from "../main/main";
import "./update-plants-depots.less";
import ACTIONS from "../../common/action_constant";
import { connect } from "react-redux";
import PlantsBasicDetails from "./plants-basic-details/plants-basic-details";
import history from "../../history";
import backIcon from "../../../assets/images/left-arrow.svg";
import Loader from "../../common/components/loader/loader";
import MSGPopUp from "../../common/components/msg-popup/msg-popup";
import PlantsDepotsUsers from './plants-depots-users/plants-depots-users';
import PlantsDepotsIndents from './plants-depots-indents/plants-depots-indents';
import PlantsDepotsSubPlants from './plants-depots-subplants/plants-depots-subplants';
import ContractManagement from "../contracts_management/contracts_management";
import { getEmail, getUserId, isAWL, isCompanyAdmin } from "../../../utils/common.js";
import TransporterList from "../../master/transporters_mapping/transporters_mapping";
import LoadingAndStandardTimeConfig from "./Loading/loading";
import ApprovalMatrix from "./ApprovalMatrix/ApprovalMatrix";
import IndentMgmtConfig from "../IndentMgmtConfig/IndentMgmtConfig";
import { withRouter } from "react-router";

class UpdatePlantDepots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      data: {},
      selectedOption: "1",
      redirectToOption: "1",
      isStateEditedInForms: false,
      isTabClicked: false,
      navigationOptions: isAWL()?[
        {
          heading: "Basic Details",
          id: "1"
        },
        {
          heading: "Users",
          id: "2"
        },
        {
          heading: "Contract Management",
          id: "3"
        },
        {
          heading: "Indents",
          id: "7"
        },
        {
          heading: "Indent Mgmt.",
          id: "12"
        },
        {
          heading: "Sub Plants",
          id: "8"
        },
        {
          heading: "Providers Mapping",
          id: "9"
        },
        {
          heading: "Loading & Stand. Time Config.",
          id: "10"
        },
        {
          heading: "Approver Matrix",
          id: "11"
        }
      ]:[
        {
          heading: "Basic Details",
          id: "1"
        },
        {
          heading: "Users",
          id: "2"
        },
        {
          heading: "Contract Management",
          id: "3"
        },
        {
          heading: "Allocation Policy",
          id: "4"
        },
        {
          heading: "Operational factors",
          id: "5"
        },
        {
          heading: "QC/Alerts/SAP",
          id: "6"
        },
        {
          heading: "Indents",
          id: "7"
        },
        {
          heading: "Indent Mgmt.",
          id: "12"
        },
        {
          heading: "Sub Plants",
          id: "8"
        },
        {
          heading: "Providers Mapping",
          id: "9"
        },
        {
          heading: "Loading & Stand. Time Config.",
          id: "10"
        },
        {
          heading: "Approver Matrix",
          id: "11"
        }
      ],
    navigationProviderOptions: [
        {
          heading: "Basic Details",
          id: "1"
        },
        {
          heading: "Users",
          id: "2"
        },
        {
          heading: "Vendors Mapping",
          id: "9"
        }
        // {
        //   heading: "Contract Management",
        //   id: "3"
        // },
        // {
        //   heading: "Allocation Policy",
        //   id: "4"
        // },
        // {
        //   heading: "Operational factors",
        //   id: "5"
        // },
        // {
        //   heading: "QC/Alerts/SAP",
        //   id: "6"
        // },
        // {
        //   heading: "Indents",
        //   id: "7"
        // },
        // {
        //   heading: "Sub Plants",
        //   id: "8"
        // },
        // {
        //   heading: "Transporters Mapping",
        //   id: "9"
        // },
        // {
        //   heading: "Loading & Stand. Time Config.",
        //   id: "10"
        // },
        // {
        //   heading: "Approver Matrix",
        //   id: "11"
        // }
      ]
    };
  }

  // funtions for user-depots
  // getRoles = (id) =>{
	// 	const {dispatch} = this.props;
	// 	dispatch({ type : ACTIONS.HOME.GET_ROLES, data : id});
  // }

  // getBranches = () =>{
	// 	const {dispatch} = this.props;
	// 	dispatch({ type : ACTIONS.PLANTS.GET_BRANCHES});
  // }

  verifyUser = (user) => {
		const {dispatch} = this.props;
		dispatch({ type : ACTIONS.PLANTS.VERIFY_USER, data : user});
  }

  addUser = (data) => {
		const {dispatch} = this.props;
		dispatch({ type : ACTIONS.PLANTS.ADD_USER, data : data});
  }
  editUser = (data) => {
		const {dispatch} = this.props;
		dispatch({ type : ACTIONS.PLANTS.UPDATE_USER, data : data});
  }
  userBranchTransfer = (data) => {
    const { dispatch, match, plantsDepots } = this.props;
    const param = { company_id: match.params.id, company_type: this.props.home.basicDetails.company_type, branchCode: plantsDepots.currentDepot.branch_code,  branch_id: match.params.branchId, offset: 0}
    dispatch({ type : ACTIONS.PLANTS.USER_BRANCH_TRANSFER, data : data, param});
  }
  deactivateUser = (data) => {
    const {dispatch, match, plantsDepots} = this.props;
    const param = { company_id: match.params.id, company_type: this.props.home.basicDetails.company_type, branchCode: plantsDepots.currentDepot.branch_code, branch_id: match.params.branchId, offset: 0}
    dispatch({ type : ACTIONS.PLANTS.DEACTIVATE_BRANCH_USER, data : data, param});
  }
  deleteUser = (data) => {
		const {dispatch} = this.props;
		dispatch({ type : ACTIONS.PLANTS.DELETE_USER, data : data});
  }

  getUserDepots = (data) => {
    const {dispatch} = this.props;
    const paramdata = {
      company_id : data.company_id,
      branch_id : data.branch_id,
      company_type : data.company_type,
      search : data.search,
      offset : data.offset
    }
    dispatch({type:ACTIONS.PLANTS.GET_DEPOT_USERS, paramdata});
  }
  getActions = () => {
		const {dispatch} = this.props;
    dispatch({ type : ACTIONS.PLANTS.GET_ACTIONS});
  }
  getDepartments = (id) => {
		const {dispatch} = this.props;
		dispatch({ type : ACTIONS.HOME.GET_DEPARTMENTS, data : id || this.props.home.basicDetails.id});
  }
  toggleUserStatus = (data) => {
    const {dispatch} = this.props;
		dispatch({ type : ACTIONS.PLANTS.TOGGLE_USER_STATUS, data : data});
  }
  getItems = (data) => {
    const {dispatch} = this.props;
		dispatch({ type : ACTIONS.PLANTS.GET_INDENTING_ITEMS, data});
  }

  getUsers = (data) => {
    const {dispatch} = this.props;
		dispatch({ type : ACTIONS.PLANTS.GET_INDENTING_USERS, data});
  }

  getDepartmentUserDetails = (data) => {
    const { dispatch } = this.props;
    data.companyType = 1;
    dispatch({
      type: ACTIONS.PLANTS.GET_DEPOT_AND_USER_DETAILS,
      data
    })
  }

  getChecklists= (data) => {
    const {dispatch} = this.props;
		dispatch({ type : ACTIONS.PLANTS.GET_INDENTING_CHECKLISTS, data});
  }

  getFields = (data) => {
    const {dispatch} = this.props;
		dispatch({ type : ACTIONS.PLANTS.GET_INDENTING_FIELDS, data});
  }

  getMoves = (data) => {
    const {dispatch} = this.props;
    dispatch({ type : ACTIONS.PLANTS.GET_INDENTING_MOVES, data});
  }

  getSubMoves = (data) => {
    const {dispatch} = this.props;
		dispatch({ type : ACTIONS.PLANTS.GET_INDENTING_SUBMOVES, data});
  }

  getWarehouses= (data) => {
    const {dispatch} = this.props;
		dispatch({ type : ACTIONS.PLANTS.GET_INDENTING_WAREHOUSES, data });
  }

  getVendors= (data) => {
    const {dispatch} = this.props;
		dispatch({ type : ACTIONS.PLANTS.GET_INDENTING_VENDORS, data});
  }

  getIndentingReasons = (data) => {
		const {dispatch} = this.props;
    dispatch({ type : ACTIONS.PLANTS.GET_INDENTING_REASONS, data});
  }

  addMove = (data) => {
    const {dispatch} = this.props;
		dispatch({ type : ACTIONS.PLANTS.ADD_MOVE, data : data});
  }

  getFlowDetail = (data) => {
    const {dispatch} = this.props;
		dispatch({ type : ACTIONS.PLANTS.GET_FLOW_DETAILS, data});
  }

  getIndentActions = (data) => {
    const {dispatch} = this.props;
		dispatch({ type : ACTIONS.PLANTS.GET_INDENTING_ACTIONS, data});
  }
   getState = () => {
		const { dispatch , match} = this.props;
		const data = {
			company_id: match.params.id
		}
		dispatch({
      type: ACTIONS.MASTER.LOCATION_NETWORK.GET_STATE, 
      data, 
    });
	}

  addAction = (data) => {
		const {dispatch} = this.props;
    dispatch({ type : ACTIONS.PLANTS.ADD_ACTION, data : data});
    
  }

  addChecklist = (data) => {
		const {dispatch} = this.props;
		dispatch({ type : ACTIONS.PLANTS.ADD_CHECKLIST, data : data});
  }

  addWarehouse = (data) => {
    console.log("paramObj4data",data);
		const {dispatch, match } = this.props;
    const onSuccess = () => {
      let data = {
          companyId: match.params.id,
          branchId: match.params.branchId,
      }
      this.getWarehouses(data);
    }
		dispatch({ type : ACTIONS.PLANTS.ADD_WAREHOUSE, data : data, onSuccess});
  }

  addVendor = (data) => {
		const { dispatch, match } = this.props;
    const onSuccess = () => {
      let dObj = {
          companyId: match.params.id,
          branchId: match.params.branchId,
      }
      this.getVendors(dObj);
    }
		dispatch({ type : ACTIONS.PLANTS.ADD_VENDOR, data : data, onSuccess });
  }

  addSubPlants = (data) => {
		const {dispatch} = this.props;
		dispatch({ type : ACTIONS.PLANTS.ADD_SUBPLANTS, data : data});
  }

  updateSubPlants = (data) => {
    const {dispatch} = this.props;
		dispatch({ type : ACTIONS.PLANTS.EDIT_SUBPLANT, data : data});
  }

  deleteSubPlants = (data) => {
    const {dispatch} = this.props;
    data['master_type'] = 11;
    data['user_id'] = getUserId();
		data['user_email'] = getEmail();
		data['delete_flag'] = true;
    dispatch({ type : ACTIONS.PLANTS.DELETE_SUBPLANT, data : data})
  }

  submitMoves = (data) => {
		const {dispatch} = this.props;
    data['master_type'] = 7;
    data['user_email'] = getEmail();
		dispatch({ type : ACTIONS.PLANTS.SUBMIT_MOVES, data : data});
  }

  deleteMove = (data) => {
		const {dispatch} = this.props;
	  dispatch({ type : ACTIONS.PLANTS.DELETE_MOVE, data : data});
  }

  getAllBranch = (id, type) => {
    const {dispatch, } = this.props;
    dispatch({ 
      type : ACTIONS.PLANTS.GET_ALL_BRANCH,
      data:{
        companyId:id,
        companyType: this.props.home && this.props.home.basicDetails && this.props.home.basicDetails.company_type || 1
      }
    });
    
  }

  getServiceModes = ()=>{
    const { dispatch, } = this.props;
    dispatch({
      type: ACTIONS.PLANTS.GET_SERVICE_MODES,
    });
  }

  //////////////////////// user -depot functions end here

  componentWillMount() {
    let edit = false;
    if (this.props.match.params.branchId != "newdepot") {
      // if(!this.props.plantsDepots.currentDepot){
      //     this.props.history.push('/home/');
      // }
      edit = true;
    }
    if (this.props.match.params.branchId == "newdepot" && !this.props.home.basicDetails ) {
          this.props.history.push('/home/');
    }
    this.setState({
      editMode: edit,
      isTabClicked: false
    });
    
  }

  setOption = option => {
    if (!this.state.isStateEditedInForms) {
      this.setState({
        selectedOption: option,
      });
    }
    this.setState({
      redirectToOption: option,
      isTabClicked: true
    });
  };
  updatePlant = data => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.SET_CURRENT_DEPOT, data });
    dispatch({ type: ACTIONS.PLANTS.UPDATE_CURRENT_DEPOT, data });
  };
  createNewPlant = data => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.SET_CURRENT_DEPOT, data });
    dispatch({ type: ACTIONS.PLANTS.ADD_NEW_CURRENT_DEPOT, data });
    this.setState({
      editMode : true
    })
  };
  closeMsgPopup = () =>{
		const {dispatch} = this.props;
		dispatch({ type : ACTIONS.MSG_DISPLAY.HIDE_MSG })
  };

  handleBackToPrevScreen = () => {
    if (history) {
      history.goBack();
    }
  }
  isFormEdited = (data) => {
    if (this.state.isStateEditedInForms !== data) {
      const { dispatch } = this.props;
      dispatch({ type: ACTIONS.MSG_DISPLAY.IS_FORM_EDITED, data: data });
      this.setState({ isStateEditedInForms: data, isTabClicked: false });
    }
  }
  closeMsgModal = () => {
    const {tab} = this.props;
    // dispatch({ type : ACTIONS.MSG_DISPLAY.HIDE_MSG });
    this.setState({ redirectToOption: tab, isTabClicked: false });
  }
  handleRedirection = () => {
    // history.replace(
    //   "/home/"+this.props.id+"/"+ this.props.type +"?tab="+this.state.redirectToOption
    // );
    this.setState({ selectedOption: this.state.redirectToOption, isStateEditedInForms: false, isTabClicked: false });
  }
  getIndentingFlows = (params) => {
    const { dispatch } = this.props;
    dispatch({ type : ACTIONS.PLANTS.GET_INDENTING_FLOWS, data : params});
  };

  getSubPlants = (params) => {
    const { dispatch } = this.props;
    dispatch({ type : ACTIONS.PLANTS.GET_SUBPLANTS, data : params});
  };
  getIndentingFlowDetails = (params) => {
    const { dispatch } = this.props;
    dispatch({ type : ACTIONS.PLANTS.GET_FLOW_DETAILS, data : params});
  };

  getCompanyTransporters = (isMultimode) => {
    const { dispatch, match } = this.props;
    dispatch({ type: ACTIONS.MASTER.TRANSPORTERS.GET_COMPANY_TRANSPORTERS , companyId: match.params.id, branchId: match.params.id, isMultimode: isMultimode && 2 || undefined });
  }

  getBranchTransporters = (isMultimode) => {
    const { dispatch, match } = this.props;
    dispatch({ type: ACTIONS.MASTER.TRANSPORTERS.GET_COMPANY_TRANSPORTERS , companyId: match.params.id, branchId: match.params.branchId, isMultimode: isMultimode && 2 || undefined  });
  }

  addCompanyTransporters = (data, companyType) => {
		const { dispatch, match } = this.props;
    dispatch({ 
      type: ACTIONS.MASTER.TRANSPORTERS.ADD_COMPANY_TRANSPORTERS, 
      data, 
      companyId: match.params.id, 
      branchId: match.params.branchId,
      companyTypeToAdd: companyType,
      onSuccess: () => {
				this.setState({ isSuccess : true });
			}
    });
	}

	onDeleteTransporter = (id, companyType) => {
		const { dispatch, match } = this.props;
		dispatch({ type: ACTIONS.MASTER.TRANSPORTERS.DELETE_COMPANY_TRANSPORTERS , companyId: match.params.id, branchId: match.params.branchId , transporterId: id, companyTypeToDelete: companyType });
  }
  
  onAddBay = (data) => {
    const { dispatch, match, plantsDepots } = this.props;
    // data.branch_code = plantsDepots.currentDepot.branch_code;
    data.company_id = match.params.id;
    data.user_id=getUserId();
    data.user_email=getEmail();
    data.new_flag=true;
    data.master_type=13;
    dispatch({ type: ACTIONS.PLANTS.ADD_LOADING_BAY, data, onSuccess: this.getLoadingBay });
  }

  getLoadingBay = (search) => {
    const { dispatch, match, plantsDepots} = this.props;
    const data = {
      company_id: match.params.id,
      branch_code: plantsDepots.currentDepot.branch_code,
    }
    if(search){
      data['search'] = search;
    }
    dispatch({ type: ACTIONS.PLANTS.GET_LOADING_BAY , data });
  }

  getLoadingGang = (search) => {
    const { dispatch, match,  plantsDepots} = this.props;
    const data = {
      company_id: match.params.id,
      branch_code: plantsDepots.currentDepot.branch_code
    }
    if(search){
      data['search'] = search;
    }
    dispatch({ type: ACTIONS.PLANTS.GET_LOADING_GANG, data });
  }

  addLoadingGang = (data) => {
    const { dispatch, match, plantsDepots } = this.props;
    data.company_id = match.params.id;
    data.user_id=getUserId();
    data.user_email=getEmail();
    data.new_flag=true;
    data.master_type=12;
    // data.branch_code = plantsDepots.currentDepot.branch_code
    dispatch({ type: ACTIONS.PLANTS.ADD_LOADING_GANG, data , onSuccess: this.getLoadingGang });
  }

  getWarehouseDetails = (search, offset) => {
    const { dispatch, match,  plantsDepots} = this.props;
    const data = {
      company_id: match.params.id,
      branch_code: plantsDepots.currentDepot.branch_code,
      offset: offset ? offset : 0,
    }
    if(search){
      data['search'] = search;
    }
    dispatch({ type: ACTIONS.PLANTS.WAREHOUSE_STORAGE_DETAILS, data });
  }

  onAddWarehouse = (data, isUpdate) => {
    const { dispatch, match } = this.props;
    if(data){
      data.company_id = match.params.id;
      data.isUpdate = isUpdate;
      data.master_type = 17;
      data.user_email = getEmail();
      data.user_id = getUserId()
      if(isUpdate){
      data.update_flag = true;
      }else{
      data.new_flag = true;
      }
    }
    dispatch({ type: ACTIONS.PLANTS.EDIT_WAREHOUSE_DETAILS, data, onSuccess: this.getWarehouseDetails });
  }

  deleteLoadingBay = (data) => {
    const { dispatch, match, plantsDepots } = this.props;
    data.company_id = match.params.id;
    data['user_id'] = getUserId();
		data['user_email'] = getEmail();
    data['delete_flag'] = true;
    data['master_type'] = 13;
    // data.branch_code = plantsDepots.currentDepot.branch_code
    dispatch({ type: ACTIONS.PLANTS.DELETE_LOADING_BAY , data, onSuccess: this.getLoadingBay });
  }

  downloadWarehouse=(withData)=>{
    const { dispatch, match, plantsDepots } = this.props;
    const param = { company_id: match.params.id, branch_code: plantsDepots.currentDepot.branch_code}
    dispatch({ 
      type : ACTIONS.PLANTS.DOWNLOAD_WAREHOUSE_TEMP_DETAILS, 
      param,
      withData,
      onSuccess: ((data) => {
        this.setState({
          downloadLink: data[0].Link
        }, () => { 
          document.getElementById('warehouseDownloadBtn').setAttribute('href', data[0].Link);
          document.getElementById('warehouseDownloadBtn').click();
          // this.downloadBtn.click();
      });
      })
    });
  }
  
  uploadWarehouseDetails = (e) =>{
    let formData = new FormData();
    const { dispatch , match, plantsDepots} = this.props;
    const param = { 
      company_id: match.params.id, 
      branch_code: plantsDepots.currentDepot.branch_code,
      user_email : getEmail(),
      user_id : getUserId()
    };
    formData.append('file', e.target.files[0]);
    formData.append('company_id', match.params.id);
    formData.append('branch_code', plantsDepots.currentDepot.branch_code);
    dispatch({
        type :ACTIONS.PLANTS.UPLOAD_WAREHOUSE_DETAILS,
        data: formData, 
        param,
        onSuccess: ((data) => {
          if(data)
          this.getWarehouseDetails()
        })
    })
  }

  downloadLoadingBay=(withData)=>{
    const { dispatch, match, plantsDepots } = this.props;
    const param = { companyId: match.params.id, branchCode: plantsDepots.currentDepot.branch_code}
    dispatch({ 
      type : ACTIONS.PLANTS.DOWNLOAD_LOADINGBAY_TEMP, 
      param,
      withData,
      onSuccess: ((data) => {
        this.setState({
          downloadLink: data[0].Link
        }, () => { 
          document.getElementById('loadingBayDownloadBtn').setAttribute('href', data[0].Link);
          document.getElementById('loadingBayDownloadBtn').click();
          // this.downloadBtn.click();
      });
      })
    });
  }
  
  uploadLoadingBayDetails = (e) =>{
    let formData = new FormData();
    const { dispatch , match, plantsDepots} = this.props;
    const param = { companyId: match.params.id, branchCode: plantsDepots.currentDepot.branch_code};
    formData.append('file', e.target.files[0]);
    formData.append('companyId', match.params.id);
    formData.append('branchCode', plantsDepots.currentDepot.branch_code);
    dispatch({
        type :ACTIONS.PLANTS.UPLOAD_LOADINGBAY_DETAILS,
        data: formData, 
        param,
        onSuccess: ((data) => {
          if(data)
          this.getLoadingBay()
        })
    })
  }

  downloadLoadingVendor=(withData)=>{
    const { dispatch, match, plantsDepots } = this.props;
    const param = { companyId: match.params.id, branchCode: plantsDepots.currentDepot.branch_code}
    dispatch({ 
      type : ACTIONS.PLANTS.DOWNLOAD_LOADINGVENDOR_TEMP, 
      param,
      withData,
      onSuccess: ((data) => {
        this.setState({
          downloadLink: data[0].Link
        }, () => { 
          document.getElementById('loadingVendorDownloadBtn').setAttribute('href', data[0].Link);
          document.getElementById('loadingVendorDownloadBtn').click();
          // this.downloadBtn.click();
      });
      })
    });
  }
  
  uploadLoadingVendorDetails = (e) =>{
    let formData = new FormData();
    const { dispatch , match, plantsDepots} = this.props;
    const param = { companyId: match.params.id, branchCode: plantsDepots.currentDepot.branch_code};
    formData.append('file', e.target.files[0]);
    formData.append('companyId', match.params.id);
    formData.append('branchCode', plantsDepots.currentDepot.branch_code);
    dispatch({
        type :ACTIONS.PLANTS.UPLOAD_LOADINGVENDOR_DETAILS,
        data: formData, 
        param,
        onSuccess: ((data) => {
          if(data)
          this.getLoadingGang()
        })
    })
  }

  deleteLoadingGang = (data) => {
    const { dispatch, match, plantsDepots } = this.props;
    data.company_id = match.params.id;
    data['user_id'] = getUserId();
		data['user_email'] = getEmail();
    data['delete_flag'] = true;
    data['master_type'] = 12;
    // data.branch_id = plantsDepots.currentDepot.branch_id
    dispatch({ type: ACTIONS.PLANTS.DELETE_LODING_GANG , data, onSuccess: this.getLoadingGang });
  }

  updateLodingGang = (data) => {
    const { dispatch, match } = this.props;
    data.company_id = match.params.id;
    data['user_id'] = getUserId();
		data['user_email'] = getEmail();
    data['update_flag'] = true;
    data['master_type'] = 12;
    dispatch({ type: ACTIONS.PLANTS.UPDATE_LOADING_GANG, data, onSuccess: this.getLoadingGang });
  }

  updateLoadingBay = (data) => {
    const { dispatch, match} = this.props;
    data.company_id = match.params.id;
    data['user_id'] = getUserId();
		data['user_email'] = getEmail();
    data['update_flag'] = true;
    data['master_type'] = 13;
    dispatch({ type: ACTIONS.PLANTS.UPDATE_LOADING_BAY, data, onSuccess: this.getLoadingBay})
  }

  getIndentEditFields = () => {
    const { dispatch, match } = this.props;
    const data = {
      companyId: match.params.id
    }
    dispatch({
      type: ACTIONS.PLANTS.GET_INDENTING_EDIT_FIELDS,
      data
    })
  }

  getIndentMoves = () => {
    const { dispatch, match } = this.props;
    const queryString = `?company_id=${match.params.id}`;
    dispatch({
      type: ACTIONS.PLANTS.FETCH_INDENTING_MOVES,
      queryString
    })
  }

  saveMoveStandardTime = (data) => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.PLANTS.ADD_MOVE_STANDARD_TIME,
      data,
      onSuccess: this.getMoveStanadardTime
    })
  }

  getMoveStanadardTime = () => {
    const { dispatch, match, plantsDepots } = this.props;
    const data = {
      company_id: match.params.id,
      branch_id: plantsDepots.currentDepot.branch_id
    }
    dispatch({ type: ACTIONS.PLANTS.GET_MOVE_STANDARD_TIME , data });
  }

  updateStandardTime = (data) => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.PLANTS.UPDATE_MOVE_STANDARD_TIME,
      data,
      onSuccess: this.getMoveStanadardTime
    })
  }

  getApproverTypes = () => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.CONTRACTS.GET_APPROVER_TYPES
    })
  }

  getAllChargesForApproval = () => {
    const { dispatch, match } = this.props;
    dispatch({
      type: ACTIONS.CONTRACTS.GET_CHARGES_FOR_APPROVAL,
      data: match.params.id,
      onSuccess: ((data) => {
        let temp = []
        let mainTemp = []

        if (data && data.data && data.data.charges) {
          Object.values(data.data.charges).map((obj) => temp = [...temp, ...obj])

          temp && temp.length > 0 && temp.map((d) => {
            mainTemp.push({
              label: d.name,
              value: d.id
            })
          })
          this.setState({ allChargesOptions: mainTemp })
        }
      })
    })
  }

  getEmailList = (search) => {
    const { dispatch, match } = this.props;
    const data = {
       company_id: match.params.id,
       company_type: 1
    }
    if(search){
      data.search = search;
    }
    dispatch({ type: ACTIONS.CONTRACTS.GET_EMAIL_LIST, data });
 }

 getApproverList = () => {
    const { dispatch, match, plantsDepots } = this.props;
    const data = {
      companyId: match.params.id,
      branchId: plantsDepots.currentDepot.branch_id
    }
    dispatch({ type: ACTIONS.PLANTS.GET_APPROVER_LIST, data });
 }

 getAllApproverList = () => {
    const { dispatch, match, plantsDepots } = this.props;
    const data = {
      companyId: match.params.id,
      branchId: plantsDepots.currentDepot.branch_id
    }
    dispatch({ type: ACTIONS.PLANTS.GET_ALL_APPROVERS, data });
 }

 postApproverList = (data) => {
    const { dispatch, match } = this.props;
    dispatch({ type: ACTIONS.PLANTS.POST_APPROVER_LIST, data, onSuccess: this.getAllApproverList });
 }

 updateApproverList = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.UPDATE_APPROVER_LIST, data, onSuccess: this.getAllApproverList });
 }

 applyApproverToOtherBranches = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.APPLY_APPROVER_SETTING_TO_OTHER_BRANCH, data });
 }

  getBranches = () => {
    const { dispatch, match , plantsDepots } = this.props;
    let data = match.params.id + "&branchId=" + plantsDepots.currentDepot.branch_id; //company_id
    dispatch({ type: ACTIONS.CONTRACTS.GET_BRANCH_LIST, data });
  }

  getLOBList = () => {
    const { dispatch, match } = this.props;
    dispatch({ type: ACTIONS.PLANTS.GET_LOB_LIST, data: { companyId: match.params.id } });
  }

  getCitiesList = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.GET_CITIES_LIST, data });
  }

  saveWeighBridge = (data) => {
    const { dispatch , match , plantsDepots  } = this.props;
    data.company_id = match.params.id;
    data.branch_id = plantsDepots.currentDepot.branch_id;
    dispatch({ type: ACTIONS.PLANTS.SAVE_WEIGH_BRIDGE_CONFIG, data, onSuccess: this.getWeighBridgeDetails });
  }

  getDepartmentsEmailList = () => {
    const { dispatch, match } = this.props;
    dispatch({
      type: ACTIONS.PLANTS.DEPARTMENT_EMAIL_LIST,
      data: { company_id: match.params.id }
    })
  }

  getWBConversion = () => {
    const { dispatch, match, plantsDepots } = this.props;
    dispatch({
      type: ACTIONS.PLANTS.GET_WB_CONVERSION,
      data: { 
        company_id: match.params.id,
        branch_id: plantsDepots.currentDepot.branch_id
      }
    })
  }

  onAddWBConversion = (data) => {
    const { dispatch, match, plantsDepots } = this.props;
    if(data){
      data.company_id = match.params.id;
      data.branch_id = plantsDepots.currentDepot.branch_id;
      data.new_flag = true;
      data.master_type = 16;
      data.user_email = getEmail();
      data.user_id = getUserId()
    }
    dispatch({
      type: ACTIONS.PLANTS.ADD_WB_CONVERSION,
      data, 
      onSuccess: this.getWBConversion
    })
  }

  updateWBConversion = (data) => {
    const { dispatch, match, plantsDepots} = this.props;
    if(data){
      data.company_id = match.params.id;
      data.branch_id = plantsDepots.currentDepot.branch_id;
      data.update_flag = true;
      data.master_type = 16;
      data.user_email = getEmail();
      data.user_id = getUserId()
    }
    console.log("data-->",data)
    dispatch({
      type: ACTIONS.PLANTS.UPDATE_WB_CONVERSION,
      data, 
      onSuccess: this.getWBConversion
    })
  }

  deleteWBConversion = (data) => {
    const { dispatch, match, plantsDepots} = this.props;
    if(data){
      data.company_id = match.params.id;
      data.branch_id = plantsDepots.currentDepot.branch_id;
      data.delete_flag = true;
      data.master_type = 16;
      data.user_email=getEmail();
      data.user_id = getUserId();
    }
    dispatch({
      type: ACTIONS.PLANTS.DELETE_WB_CONVERSION,
      data, 
      onSuccess: this.getWBConversion
    })
  }

  getWarehouseList = () => {
    const { dispatch, match, plantsDepots } = this.props;
    dispatch({
      type: ACTIONS.PLANTS.GET_WAREHOUSE_LIST,
      data: {
        companyId: match.params.id,
        branchId: plantsDepots.currentDepot.branch_id
      }
    })
  }

  getWeighBridgeDetails = () => {
    const { dispatch, match, plantsDepots } = this.props;
    dispatch({
      type: ACTIONS.PLANTS.GET_WEIGH_BRIDGE_DETAILS,
      data: {
        companyId: match.params.id,
        branchId: plantsDepots.currentDepot.branch_id
      }
    })
  }

  getWeighBridgeMasterListing = (search) => {
    const { dispatch, match, plantsDepots } = this.props;
    dispatch({
      type: ACTIONS.PLANTS.GET_WEIGH_BRIDGE_MASTER_LISTING,
      data: {
        branchcode: plantsDepots.currentDepot.branch_code,
        ...(search ? { search: search } : {})
      }
    })
  }
  getParkingLotListing = (search) => {
    const { dispatch, match, plantsDepots } = this.props;
    dispatch({
      type: ACTIONS.PLANTS.GET_PARKING_LOT_LISTING,
      data: {
        companyId: match.params.id,
        branchId: plantsDepots.currentDepot.branch_id,
        ...(search ? { search: search } : {})
      }
    })
  }

  getIndentingDashoardListing = () => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.PLANTS.INDENTING_DASHBOARD_LIST,
    })
  }

  onAddWBMaster = (data) => {
    const { dispatch, match, plantsDepots, home } = this.props;
    if(data){
    data.company_id = match.params.id;
    data.branch_id = plantsDepots.currentDepot.branch_id;
    data.company_name = home.basicDetails.company_name;
    data.new_flag = true;
    data.master_type = 15;
    data.user_email=getEmail();
    data.user_id = getUserId()
    console.log("data",data)
    }
    dispatch({
      type: ACTIONS.PLANTS.CREATE_WEIGH_BRIDGE_MASTER,
      data,
      onSuccess: this.getWeighBridgeMasterListing
    })
  }
  updateWBMaster = (data) => {
    const { dispatch, match, plantsDepots, home } = this.props;
    if(data){
      data.company_id = match.params.id;
      data.branch_id = plantsDepots.currentDepot.branch_id;
      data.company_name = home.basicDetails.company_name;
      data.update_flag = true;
      data.master_type = 15;
      data.user_email = getEmail();
      data.user_id = getUserId()
    }
    dispatch({
      type: ACTIONS.PLANTS.UPDATE_WEIGH_BRIDGE_MASTER,
      data,
      onSuccess: this.getWeighBridgeMasterListing
    })
  }

  deleteWBMaster = (data) => {
    const { dispatch, match, plantsDepots, home} = this.props;
    if(data){
      data.delete_flag = true;
      data.master_type = 15;
      data.user_email=getEmail();
      data.user_id = getUserId();
      data.company_id = match.params.id
      data.branch_id = plantsDepots.currentDepot.branch_id
      // data.vendor_name = data.vendor_name
      }
    dispatch({
      type: ACTIONS.PLANTS.DELETE_WEIGH_BRIDGE_MASTER,
      data,
      onSuccess: this.getWeighBridgeMasterListing
    })
  }
  //MFS MASTER
  
  getMfsMasterListing = (search) => {
    const { dispatch, match, plantsDepots } = this.props;
    const companyId = match.params.id
    const depotCode = plantsDepots.currentDepot.branch_code
   let queryString ={companyId,depotCode,}
   if (search !== undefined ){
    queryString.search = search
   }
   queryString = new URLSearchParams(queryString)
  dispatch({
      type: ACTIONS.PLANTS.GET_MFS_MASTER_DETAILS,
      queryString,
       })
  }
  getSalesdistrictMapping=(search)=> {
    const { dispatch,match} = this.props;
    const queryString = `?company_id=${match.params.id}${search ? `&search=${search}` : ''}`;
    dispatch({
      type: ACTIONS.PLANTS.GET_DISTRICT_MAPPING_MASTER,
      ...(search ? { search: search } : {}),
      queryString,
    });
  }
  
  onAddSalesDistrictMapping =(temp)=> {
  const { dispatch,match,plantsDepots} = this.props;
  if(temp.data){
    temp.data.company_id = match.params.id
  }
  const data = temp.data
  dispatch({
      type: ACTIONS.PLANTS.ADD_DISTRICT_MAPPING_MASTER,
      data,
      onSuccess: () => {
          this.setState({ isOpen: false }, this.getSalesdistrictMapping());
      }
  })
}

updateSalesDistrictMapping =(temp)=> {
  const { dispatch} = this.props;
  const data = temp.data
  dispatch({
  type: ACTIONS.PLANTS.UPDATE_DISTRICT_MAPPING_MASTER,
  data,
  onSuccess: () => {
      this.setState({ isOpen: false, editData: '' },
       this.getSalesdistrictMapping);
  }
})
}
deleteSalesDistrictMapping =(data)=> {
const { dispatch } = this.props;
dispatch({
    type: ACTIONS.PLANTS.DELETE_DISTRICT_MAPPING_MASTER,
    queryString:`?company_id=${data.company_id}&region_code=${data.region_code}`,
    onSuccess: () => {
        this.getSalesdistrictMapping();
    }
})
}

  onAddMfsMaster = (data) => {
    const { dispatch, match, plantsDepots,  } = this.props;
    const companyId = match.params.id
    const depotCode = plantsDepots.currentDepot.branch_code
    data={
      company_id:companyId,
      depot_code:depotCode,
      user_email:getEmail(),
      user_id:getUserId(),
      new_flag:true,
      master_type:14,
      ...data
    }
   
     dispatch({
      type: ACTIONS.PLANTS.CREATE_MFS_MASTER_DETAILS,
       data,
       onSuccess: this.getMfsMasterListing
     })
  }

  updateMfsMaster = (data) => {
    const { dispatch, match, plantsDepots, home } = this.props;
    data.company_id = match.params.id;
    data.depot_code = plantsDepots.currentDepot.branch_code;
    data.user_id=getUserId();
    data.user_email=getEmail();
    data.update_flag=true;
    data.master_type = 14;
    
     dispatch({
       type: ACTIONS.PLANTS.UPDATE_MFS_MASTER_DETAILS,
       data,
       onSuccess: this.getMfsMasterListing
     })
  }
  deleteMfsMaster = (data) => {
    const { dispatch, match, plantsDepots,  } = this.props;
    const companyId = match.params.id
    const depotCode = plantsDepots.currentDepot.branch_code
    const user_id = getUserId()
    const user_email= getEmail()
     dispatch({
      type: ACTIONS.PLANTS.DELETE_MFS_MASTER_DETAILS,
       data,
       params:`companyId=${companyId}&depotCode=${depotCode}&materialCode=${data.material_code}&user_id=${user_id}&user_email=${user_email}&delete_flag=${true}&master_type=${14}`,
       onSuccess: this.getMfsMasterListing
     })
  }

  getMfsMasterUomListing = () => {

    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.PLANTS.GET_MFS_MASTER_UOM_DETAILS,
    })
   
  }


  onAddParkingLot = (data) => {
    const { dispatch, match, plantsDepots, home } = this.props;
    const payload = {
      companyId: match.params.id,
      branchId: plantsDepots.currentDepot.branch_id,      
      parking_area: (data || []).map(d=>{
        return {
          ...d,
          parkingCapacity: parseInt(d.parkingCapacity),
          priority: parseInt(d.priority)
        }
      })
    }
    dispatch({
      type: ACTIONS.PLANTS.CREATE_PARKING_LOT,
      data: payload,
      onSuccess: this.getParkingLotListing
    })
  }

  updateParkingLot = (data) => {
    const { dispatch, match, plantsDepots, home } = this.props;
    data.companyId = match.params.id;
    data.branchId = plantsDepots.currentDepot.branch_id;
    data.company_name = home.basicDetails.company_name;
    data.parkingCapacity = parseInt(data.parkingCapacity);
    data.priority = parseInt(data.priority);
    dispatch({
      type: ACTIONS.PLANTS.UPDATE_PARKING_LOT,
      data,
      onSuccess: this.getParkingLotListing
    })
  }

  deleteParkingLot = (data) => {
    const { dispatch, match, plantsDepots } = this.props;
    data.companyId = match.params.id;
    data.branchId = plantsDepots.currentDepot.branch_id;
    dispatch({
      type: ACTIONS.PLANTS.DELETE_PARKING_LOT,
      data,
      onSuccess: this.getParkingLotListing
    })
  }

  termAndConditionUpload = (data) =>{
    const { dispatch } = this.props;
    dispatch({type : ACTIONS.PLANTS.UPLOAD_TERM_CONDITION,data })
  }

  uploadUserDetails = (formData, data) => {
    const { dispatch } = this.props;
    const param = { 
      companyId: data.company_id, 
      companyType: data.company_type,
      branchId: data.branch_id
    };
    dispatch({
      type: ACTIONS.PLANTS.UPLOAD_USER_DETAILS,
      data: formData,
      param,
      onSuccess: () => this.getUserDepots(data)
    })
  }

   uploadLorealUserDetails = (formData, data) => {
    const { dispatch } = this.props;
    const param = { 
      companyId: data.company_id, 
      companyType: data.company_type,
      branchId: data.branch_id
    };
    dispatch({
      type: ACTIONS.PLANTS.LOREAL_UPLOAD_USER_DETAILS,
      data: formData,
      param,
      onSuccess: () => this.getUserDepots(data)
    })
  }

  downloadUserDetails = () => {
    const { dispatch } = this.props;
    dispatch({ 
      type: ACTIONS.PLANTS.DOWNLOAD_USER_DETAILS_TEMPLATE, 
      onSuccess: (data) => {
        if((data[0] || []).Link) {
          const link = document.createElement("a");
          link.href = (data[0] || []).Link;
          // link.download = filename;
          link.click();
        }
      }
    });
  }

  downloadLorealUserDetails = () => {
    const { dispatch } = this.props;
    dispatch({ 
      type: ACTIONS.PLANTS.LOREAL_DOWNLOAD_USER_DETAILS_TEMPLATE, 
      onSuccess: (data) => {
        if((data[0] || []).Link) {
          const link = document.createElement("a");
          link.href = (data[0] || []).Link;
          // link.download = filename;
          link.click();
        }
      }
    });
  }

  applyBranches = (dataList) => {
    const { dispatch, match, plantsDepots} = this.props;
    dispatch({
        type: ACTIONS.PLANTS.APPLY_INDENT_FLOW_SETTINGS_TO_BRANCHES,
        data: {
          flow_id: dataList.setting_id,
          branch_list: dataList.branch_list,
          companyId: match.params.id,
          branchId: plantsDepots.currentDepot.branch_id
        }
    });
  }

  getAutomoveDetails = () => {
    const { dispatch, match, plantsDepots} = this.props;
    dispatch({
        type: ACTIONS.PLANTS.GET_AUTOMOVE,
        data: {
          company_id: match.params.id,
          branch_id: plantsDepots.currentDepot.branch_id
        }
    });
  }

  addAutomoveDetails = (details) => {
    const { dispatch, match, plantsDepots} = this.props;
    let automoves = [];
    details.selectedMoves.map((obj) => (
      automoves.push({name: obj.label, move_id: obj.value})
    ));
    const data = {
      company_id: match.params.id,
      branch_id: plantsDepots.currentDepot.branch_id,
      auto_moves_id: automoves,
      is_auto_moves: details.is_auto_moves
    }
    dispatch({
        type: ACTIONS.PLANTS.ADD_AUTOMOVE,
        data,
        onSuccess: () => this.getAutomoveDetails()
    });
  }

  getReportDashboardsList = () => {
    const { dispatch, match } = this.props;
    dispatch({
      type: ACTIONS.PLANTS.GET_REPORTS_DASHBOARDS_LIST,
      params: {
        company_id: (match.params || {}).id
      }
    })
  }

  getSlabRange = () => {
    const { dispatch, match,  plantsDepots} = this.props;
    const data = {
      company_id: match.params.id,
      branch_id: plantsDepots.currentDepot.branch_id
    }
    dispatch({ type: ACTIONS.PLANTS.GET_SLAB_RANGE, data });
  }

  addSlabRange = (payload) => {
    const { dispatch, match,  plantsDepots} = this.props;
    const data = {
      company_id: match.params.id,
      branch_id: plantsDepots.currentDepot.branch_id,
      lowerlimit: payload.lowerlimit,
      upperlimit: payload.upperlimit,
      type: payload.type
    }
    dispatch({ type: ACTIONS.PLANTS.ADD_SLAB_RANGE, data,
    onSuccess: this.getSlabRange });
  }

  updateSlabRange = (payload) => {
    const { dispatch, match,  plantsDepots} = this.props;
    const data = {
      company_id: match.params.id,
      branch_id: plantsDepots.currentDepot.branch_id,
      lowerlimit: payload.lowerlimit,
      upperlimit: payload.upperlimit,
      type: payload.type,
      range_id: payload.range_id
    }
    dispatch({ type: ACTIONS.PLANTS.ADD_SLAB_RANGE, data,
    onSuccess: this.getSlabRange });
  }

  deleteSlabRange = (payload) => {
    const { dispatch, match,  plantsDepots} = this.props;
    const data = {
      company_id: match.params.id,
      branch_id: plantsDepots.currentDepot.branch_id,
      lowerlimit: payload.lowerlimit,
      upperlimit: payload.upperlimit,
      range_id: payload.range_id
    }
    dispatch({ type: ACTIONS.PLANTS.DELETE_SLAB_RANGE, data, 
      onSuccess: this.getSlabRange });
  }
  
  

  render() {
    const{plantsDepots}=this.props;
    let company_name = "",
      company_id = "",
      company_type = "",
      companyPageRoute = "/home/";
    if (
      this.props.home.basicDetails &&
      this.props.home.basicDetails.company_name
    ) {
      company_name = this.props.home.basicDetails.company_name;
      company_id = this.props.home.basicDetails.id;
      company_type = this.props.home.basicDetails.company_type;
      companyPageRoute = companyPageRoute + company_id + "/" + company_type +"?tab=3";
    }
    const branchName = this.props.plantsDepots.currentDepot
      ? this.props.plantsDepots.currentDepot.branch_name
      : "";
    const code = this.props.plantsDepots.currentDepot
      ? this.props.plantsDepots.currentDepot.branch_code
      : "";
    const type = this.props.plantsDepots.currentDepot
      ? this.props.plantsDepots.currentDepot.branch_type_name
      : "";
    const isCmpyAdmin = isCompanyAdmin();
    const depotCode = plantsDepots && plantsDepots.currentDepot && plantsDepots.currentDepot.branch_code
    return (
      <div className="update-plant-depots-wrap">
      {
					this.props.loader.isLoading && <Loader/>
        }
        {
					this.props.msgpopup.active && <MSGPopUp close = {this.closeMsgPopup} type = {this.props.msgpopup.type} msg = {this.props.msgpopup.msg}/>
				}
        <div className="wrapper">
          <MainComponent>
            <div className = "go-back-prev-screen" onClick={this.handleBackToPrevScreen} style={{backgroundImage: `url(${backIcon})`}}></div>
            <div className={'plant-edit-title'}>
              <div className="page-title">
                {" "}
                {
                  !isCmpyAdmin &&
                  <span
                    className="home"
                    onClick={() => history.push("/home") }
                  >
                    {"Home > "}
                  </span>
                }
                <span
                  className="home"
                  onClick={() => history.push(companyPageRoute)}
                >
                  {company_name}
                </span>{" "}
                {" > "}
                {!this.state.editMode && <span className="current-screen">New Plant</span>}
                {this.state.editMode && <span className="current-screen">{branchName}</span>}
              </div>
              <div className="plant-header-section">
                {!this.state.editMode && <div>New Plant</div>}
                {this.state.editMode && <div>{branchName}</div>}
              </div>
            </div>
            <div className={this.state.editMode ? 'card-view card-width' : ''}>
              {this.state.editMode && (
                <div className="plant-other-details">
                  <div className="content">
                    <span className="bold">Code:</span> {code}
                  </div>
                  <div className="content">
                    <span className="bold">Type:</span> {type}
                  </div>
                </div>
              )}
            </div>
            {this.props.home.basicDetails && this.props.home.basicDetails.company_type ==1 && 
            <div className="home-navigation-holder">
              {this.state.navigationOptions.map((option, index) => {
                return (
                  <div
                    key={index}
                    className={
                      this.state.selectedOption === option.id
                        ? "selected-option options"
                        : "options"
                    }
                    onClick={() => this.setOption(option.id)}
                  >
                    {option.heading}
                  </div>
                );
              })}
            </div>
            }
            {this.props.home.basicDetails && this.props.home.basicDetails.company_type > 1 && 
            <div className="home-navigation-holder">
              {this.state.navigationProviderOptions.map((option, index) => {
                return (
                  <div
                    key={index}
                    className={
                      this.state.selectedOption === option.id
                        ? "selected-option options"
                        : "options"
                    }
                    onClick={() => this.setOption(option.id)}
                  >
                    {option.heading}
                  </div>
                );
              })}
            </div>
            }
            {
              this.state.redirectToOption !== this.props.selectedOption && this.state.isStateEditedInForms && this.state.isTabClicked &&
              <MSGPopUp
                type = {1}
                isEditMode = {true}
                close = {this.closeMsgModal}
                isEditing = {this.state.isStateEditedInForms}
                handleRedirection = {this.handleRedirection}
                msg = {'Are You Sure You Want Leave Without Saving?'}
              /> 
            }
            {this.state.selectedOption == "1" && (
              <PlantsBasicDetails
                updatePlant={this.updatePlant}
                createNewPlant = {this.createNewPlant}
                company_id = {company_id}
                company_type = {company_type}
                branch_id = {this.props.match.params.branchId}
                editMode={this.state.editMode}
                plantsDepots={this.props.plantsDepots}
                isFormEdited={this.isFormEdited}
                termAndConditionUpload = {this.termAndConditionUpload}
                termAndConditions ={this.props.termAndConditions}
                getAllBranch = {this.getAllBranch}
                allPlantBranches = {this.props.allPlantBranches}
              />
            )}
            {this.state.selectedOption == "2" && (
              <PlantsDepotsUsers
                getDepartments = {this.getDepartments}
                getActions = {this.getActions}
                userList = {this.props.plantsDepots && this.props.plantsDepots.depotUsers}
                company_id = {company_id}
                company_type = {company_type}
                branch_id = {this.props.match.params.branchId}
                branch_name = {this.props.plantsDepots.currentDepot.branch_name}
                getUserDepots = {this.getUserDepots}
                roles = {this.props.home && this.props.home.roles}
                // getRoles = {this.getRoles}
                verifyUser = {this.verifyUser}
                departments = {this.props.home.departments}
                actions = {this.props.plantsDepots.actions}
                addUser = {this.addUser}
                editUser = {this.editUser}
                toggleUserStatus = {this.toggleUserStatus}
                deleteUser = {this.deleteUser}
                is_verified = {this.props.plantsDepots.verifiedUser ?this.props.plantsDepots.verifiedUser.is_verified :false}
                isFormEdited = {this.isFormEdited}
                getAllBranch = {this.getAllBranch}
                allPlantBranches = {this.props.allPlantBranches}
                indentEditFields={this.props.indentEditFields}
                getIndentEditFields= {this.getIndentEditFields}
                getWarehouseList={this.getWarehouseList}
                warehouseList={this.props.warehouseList}
                getIndentActions={this.getIndentActions}
                indentingActions={this.props.indentingActions}
                userBranchTransfer={this.userBranchTransfer}
                deactivateUser={this.deactivateUser}
                uploadUserDetails={this.uploadUserDetails}
                downloadUserDetails={this.downloadUserDetails}
                moveNamesList={this.props.moveNamesList}
                getIndentingDashoardListing={this.getIndentingDashoardListing}
                indentingDashboardListing={this.props.indentingDashboardListing}
                getReportDashboardsList={this.getReportDashboardsList}
                reportsDashboardsList={this.props.reportsDashboardsList}
                getSlabRange={this.getSlabRange}
                slabRange={this.props.slabRange}
                getIndentMoves={this.getIndentMoves}
                indentingAdminMoves={this.props.indentingAdminMoves}
                downloadLorealUserDetails={this.downloadLorealUserDetails}
                uploadLorealUserDetails={this.uploadLorealUserDetails}
                stateList={this.props.stateListData}
                getState={this.getState}
              />
            )}
            {this.state.selectedOption == "3" && (
              <ContractManagement
                company_id = {company_id}
                branch_id = {this.props.match.params.branchId}
                isFormEdited = {this.isFormEdited}
              />
            )}
            {this.state.selectedOption == "7" && (
              <PlantsDepotsIndents 
                branch_id = {this.props.match.params.branchId}
                company_id = {this.props.match.params.id}
                // company_type = {this.props.match.params.type}
                getIndentingFlows={this.getIndentingFlows}
                indentingFlows={this.props.indentingFlows}
                getItems={this.getItems}
                indentingItems={this.props.indentingItems}
                getUsers={this.getUsers}
                indentingUsers={this.props.indentingUsers}
                addChecklist={this.addChecklist}
                getChecklists={this.getChecklists}
                indentingChecklists={this.props.indentingChecklists}
                addMove={this.addMove}
                getMoves={this.getMoves}
                getSubMoves={this.getSubMoves}
                indentingMoves={this.props.indentingMoves}
                indentingSubMoves={this.props.indentingSubMoves}
                addAction={this.addAction}
                getActions={this.getIndentActions}
                indentingActions={this.props.indentingActions}
                addWarehouse={this.addWarehouse}
                getWarehouses={this.getWarehouses}
                indentingWarehouses={this.props.indentingWarehouses}
                addVendor={this.addVendor}
                getVendors={this.getVendors}
                indentingVendors={this.props.indentingVendors}
                submitMoves={this.submitMoves}
                deleteMove={this.deleteMove}
                getIndentingFlowDetails={this.getIndentingFlowDetails}
                indentingFlowDetails={this.props.indentingFlowDetails}
                getSubPlants={this.getSubPlants}
                subPlants={this.props.subPlants}
                getFields={this.getFields}
                indentingFields={this.props.indentingFields}
                getDepartmentUserDetails={this.getDepartmentUserDetails}
                depotAndUserDetails={this.props.depotAndUserDetails}
                getBranches={this.getBranches}
                branchList={this.props.branchList}
                applyBranches={this.applyBranches}
                getServiceModes={this.getServiceModes}
                serviceModes={this.props.serviceModes}
                getIndentingReasons={this.getIndentingReasons}
                indentingReasons={this.props.indentingReasons}
              />
            )}
            {this.state.selectedOption == "8" && (
              <PlantsDepotsSubPlants 
                company_id = {this.props.match.params.id}
                // company_type = {company_type}
                branch_id = {this.props.match.params.branchId}
                getSubPlants={this.getSubPlants}
                addSubPlants={this.addSubPlants}
                subPlants={this.props.subPlants}
                updateSubPlants={this.updateSubPlants}
                deleteSubPlants={this.deleteSubPlants}
              />
            )}
            {this.state.selectedOption == "9" &&
              <TransporterList 
                getCompanyTransporters={this.getCompanyTransporters}
                transporterList={this.props.master.companyTransporters}
                addCompanyTransporters={this.addCompanyTransporters} 
                companyTransporters={this.props.master.branchTransporters}
                onDeleteTransporter={this.onDeleteTransporter}
                heading={'Transporters'}
                subHeading={'Branch Transporters'}
                isSuccess={this.state.isSuccess}
                getBranchTransporters={this.getBranchTransporters}
              />
            }
            {this.state.selectedOption == "10" &&
              <LoadingAndStandardTimeConfig 
                {...this.props}
                onAddBay={this.onAddBay}
                getLoadingBay={this.getLoadingBay}
                getLoadingGang={this.getLoadingGang}
                addLoadingGang={this.addLoadingGang}
                loadingBayData={this.props.loadingBayData}
                loadingGangData={this.props.loadingGangData}
                deleteBay={this.deleteLoadingBay}
                deleteLoadingGang={this.deleteLoadingGang}
                updateLodingGang={this.updateLodingGang}
                updateLoadingBay={this.updateLoadingBay}
                getMoves={this.getMoves}
                company_id = {this.props.match.params.id}
                branch_id = {this.props.match.params.branchId}
                indentingMoves={this.props.indentingMoves}
                saveStandardTime={this.saveMoveStandardTime}
                moveStandardTime={this.props.moveStandardTime}
                updateStandardTime={this.updateStandardTime}
                getMoveStanadardTime={this.getMoveStanadardTime}
                saveWeighBridge={this.saveWeighBridge}
                getWBConversion={this.getWBConversion}
                WBConversionData={this.props.WBConversionData}
                onAddWBConversion={this.onAddWBConversion}
                updateWBConversion={this.updateWBConversion}
                deleteWBConversion={this.deleteWBConversion}
                getWeighBridgeDetails={this.getWeighBridgeDetails}
                weighBridgeDetails={this.props.weighBridgeDetails}
                downloadLoadingBay={this.downloadLoadingBay}
                uploadLoadingBayDetails={this.uploadLoadingBayDetails}
                downloadLink={this.state.downloadLink}
                downloadBtn={this.downloadBtn}
                downloadLoadingVendor={this.downloadLoadingVendor}
                uploadLoadingVendorDetails={this.uploadLoadingVendorDetails}
                getWarehouseDetails={this.getWarehouseDetails}
                onAddWarehouse={this.onAddWarehouse}
                warehouseStorageData={this.props.warehouseStorageData}
                downloadWarehouse={this.downloadWarehouse}
                uploadWarehouseDetails={this.uploadWarehouseDetails}
                getAutomoveDetails={this.getAutomoveDetails}
                automoveDetails={this.props.automoveDetails}
                // indentingMoves={this.props.indentingMoves}
                addAutomoveDetails={this.addAutomoveDetails}
                getWeighBridgeMasterListing={this.getWeighBridgeMasterListing}
                weighBridgeMasterListing={this.props.weighBridgeMasterListing}
                onAddWBMaster={this.onAddWBMaster}
                updateWBMaster={this.updateWBMaster}
                deleteWBMaster={this.deleteWBMaster}
                //MFS MASTER
                getMfsMasterListing = {this.getMfsMasterListing}
                deleteMfsMaster={this.deleteMfsMaster}
                mfsMasterListing ={this.props.mfsMasterListing}
                onAddMfsMaster={this.onAddMfsMaster}
                updateMfsMaster = {this.updateMfsMaster}
                getMfsMasterUomListing={this.getMfsMasterUomListing}
                mfsMasterUomListing={this.props.mfsMasterUomListing}
                depotCode={depotCode}
                getParkingLotListing={this.getParkingLotListing}
                parkingLotListing={this.props.parkingLotListing}
                onAddParkingLot={this.onAddParkingLot}
                updateParkingLot={this.updateParkingLot}
                deleteParkingLot={this.deleteParkingLot}
                getSlabRange={this.getSlabRange}
                slabRange={this.props.slabRange}
                addSlabRange={this.addSlabRange}
                deleteSlabRange={this.deleteSlabRange}
                updateSlabRange={this.updateSlabRange}
                // sales and district Mapping Master
                getSalesdistrictMapping = {this.getSalesdistrictMapping}
                SalesdistrictMappingData = {this.props.SalesdistrictMappingData}
                onAddSalesDistrictMapping = {this.onAddSalesDistrictMapping}
                updateSalesDistrictMapping = {this.updateSalesDistrictMapping}
                deleteSalesDistrictMapping = {this.deleteSalesDistrictMapping}
              />
            }
            {this.state.selectedOption === '11' && 
              <ApprovalMatrix
                approverTypes={this.props.approverTypes}
                getApproverTypes={this.getApproverTypes}
                getAllChargesForApproval = {this.getAllChargesForApproval}
                getEmailList={this.getEmailList}
                emailList={this.props.emailList}
                getApproverList={this.getAllApproverList}
                postApproverList={this.postApproverList}
                updateApproverList={this.updateApproverList}
                applyApproverToOtherBranches={this.applyApproverToOtherBranches}
                approverMatrix={this.props.approverMatrix}
                branchList={this.props.branchList}
                getBranches={this.getBranches}
                branchId = {this.props.match.params.branchId}
                companyId = {this.props.match.params.id}
                getDepartmentsEmailList = {this.getDepartmentsEmailList}
                deptEmailList = {this.props.deptEmailList}
                getLOBList={this.getLOBList}
                lobList={this.props.lobList}
                plantsDepots = {this.props.plantsDepots}
                getCitiesList={this.getCitiesList}
                citiesList = {this.props.citiesList}
                emailIDList = {this.props.emailIDList}
                allChargesOptions = {this.state.allChargesOptions}
                regionmaster={this.props.regionmaster}
                code={this.props.code}
                getSlabRange={this.getSlabRange}
                slabRange={this.props.slabRange}
              />
            }
            {this.state.selectedOption === '12' && 
            <IndentMgmtConfig 
              branchId = {this.props.match.params.branchId}
              companyId = {this.props.match.params.id}
            />}
          </MainComponent>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    home: state.home,
    loader: state.loader,
    msgpopup: state.msgpopup,
    plantsDepots: state.plantsDepots,
    isFormEditing: state.isFormEditing,
    indentingFlows: state.plantsDepots.indentingFlows,
    indentingItems: state.plantsDepots.indentingItems,
    indentingUsers: state.plantsDepots.indentingUsers,
    indentingChecklists: state.plantsDepots.indentingChecklists,
    indentingMoves: state.plantsDepots.indentingMoves,
    indentingSubMoves: state.plantsDepots.indentingSubMoves,
    indentingActions: state.plantsDepots.indentingActions,
    indentingWarehouses: state.plantsDepots.indentingWarehouses,
    indentingVendors: state.plantsDepots.indentingVendors,
    indentingFlowDetails: state.plantsDepots.indentingFlowDetails,
    subPlants: state.plantsDepots.subPlants,
    deptEmailList: state.plantsDepots.deptEmailList,
    master: state.master,
    allPlantBranches: state.plantsDepots.allPlantBranches,
    indentingFields: state.plantsDepots.indentingFields,
    loadingBayData: state.plantsDepots.loadingBayData || [],
    SalesdistrictMappingData: state.plantsDepots.SalesdistrictMappingData || [],
    loadingGangData: state.plantsDepots.loadingGangData || [],
    indentEditFields: state.plantsDepots.indentEditFields || [],
    moveStandardTime: state.plantsDepots.moveStandardTime || [],
    approverTypes: state.contracts.approverTypes || [],
    emailList: (state.contracts.emailList || {}).emails,
    approverMatrix: state.plantsDepots.approverMatrix || {},
    branchList: state.contracts.branchList || [],
    WBConversionData: state.plantsDepots.WBConversionData || [],
    warehouseList: state.plantsDepots.warehouseList || [],
    weighBridgeDetails: state.plantsDepots.weighBridgeDetails || [],
    actiontypeslist: state.plantsDepots.actiontypeslist,
    termAndConditions : state.plantsDepots.termAndConditions,
    warehouseStorageData: state.plantsDepots.warehouseStorageData || [],
    depotAndUserDetails: state.plantsDepots.depotAndUserDetails || [],
    automoveDetails: state.plantsDepots.automoveDetails || [],
    moveNamesList: state.plantsDepots.moveNamesList,
    lobList: state.plantsDepots.lobList || [],
    citiesList: state.plantsDepots.citiesList || [],
    emailIDList: (state.contracts.emailList || {}).ids,
    weighBridgeMasterListing: state.plantsDepots.weighBridgeMasterListing || [],
    mfsMasterListing: state.plantsDepots.mfsMasterListing || [],
    mfsMasterUomListing: state.plantsDepots.mfsMasterUomListing || [],
    

    indentingDashboardListing: state.plantsDepots.indentingDashboardListing || [],
    parkingLotListing: state.plantsDepots.parkingLotListing || [],
    reportsDashboardsList: state.plantsDepots.reportsDashboardsList || {},
    serviceModes: state.plantsDepots.serviceModes || [],
    regionmaster: state.plantsDepots.regionmaster || [],
    slabRange: state.plantsDepots.slabRange || [],
    indentingReasons: state.plantsDepots.indentingReasons || [],
    indentingAdminMoves: state.plantsDepots.indentingAdminMoves || [],
    stateListData:state.master.stateList || [],
  };
};

export default connect(mapStateToProps)(withRouter(UpdatePlantDepots));
