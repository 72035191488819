import React, { Component } from "react";
import { withRouter } from "react-router";
import ExtraDeliveryCharges from "./extraDeliveryCharges";
import AdditionalCost from "./additionalCost";

class DeliveryCharges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Tabs: [
        {
          label: "Extra Delivery Charges",
          id: "1",
        },
        {
          label: "Additional Cost",
          id: "2",
        },
      ],
      selectedOption: "1",
    };
  }

  onTabChange = (tabId) => {
    this.setState({
      selectedOption: tabId,
    });
  };

  render() {
    return (
      <div className="">
        <div className="br254 TabContainer121">
          {this.state.Tabs.map((tab) => {
            return (
              <button
                key={tab.id}
                className={`${this.state.selectedOption === tab.id ? "selectedTab1221 TabOptions1221 fs-bold" : "TabOptions1221 fs-bold"}`}
                onClick={() => this.onTabChange(tab.id)}
              >
                {tab.label}
              </button>
            );
          })}
        </div>
        {this.state.selectedOption === "1" && (
          <ExtraDeliveryCharges company_id={this.props.company_id} />
        )}
        {this.state.selectedOption === "2" && (
          <AdditionalCost company_id={this.props.company_id} />
        )}
      </div>
    );
  }
}
export default withRouter(DeliveryCharges);
