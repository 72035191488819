import React, { Fragment } from 'react';
import TopPanel from '../../components/header-panel/header-panel';
import './index.scss';
const NavBarSection = () => {
    return (
        <Fragment>
            <div className='navBarT1'>
                <TopPanel />
            </div>
        </Fragment>
    )
}

export default NavBarSection