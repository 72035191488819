import React, { Component, Fragment } from 'react';
import InputText from '../../common/components/input-text/input-text';
import Button from '../button-classic/button-classic';
import '../route_code/route_code.less';

class AddRouteMaster extends Component {
		constructor(props) {
			super(props);
			this.state = {
				data: this.props.editData || {
                    from_city: '',
                    from_state: '',
                    from_country: '',
                    company_id: this.props.company_id
                }
			}
		}

		onChangeHandler = (value, id) => {
			const { data } = this.state;
			data[id] = value;
			this.setState({ data });
		}

		onSave = () => {
			const { data } = this.state;
            if(!(data.from_city && data.from_state && data.from_country)) {
                this.setState({ isError: true });
            } else {
                this.setState({ isError: false });
                this.props.onSaveMasterRoute(data);
            }
		}

		onUpdate = () => {
			const { data } = this.state;
            if(!(data.from_city && data.from_state && data.from_country)) {
                this.setState({ isError: true });
            } else {
                this.setState({ isError: false });
                this.props.onUpdateRouteMaster(data);
            }
		}

		render() {
			const { data } = this.state;
			return (
				<div className="route_code_detail">
					<div onClick={this.props.toggleAdd} className="overlay"></div>
					<div id="modal" className="modal-popup">
						<div className="pop-heading">
							{`${this.props.editData ? 'Edit' : 'Add'} Route Details`}
						</div>
						
						<div className="route-detail">
							<div className="details-section">
								<InputText
									placeholder="City"
									id={"from_city"}
									label="City"
									changeHandler={this.onChangeHandler}
									value={data.from_city}
									required={true}
								/>
								<InputText
									placeholder="State"
									id={"from_state"}
									label="State"
									changeHandler={this.onChangeHandler}
									value={data.from_state}
									required={true}
								/>
								<InputText
									placeholder="Country"
									id={"from_country"}
									label="Country"
									changeHandler={this.onChangeHandler}
									value={data.from_country}
                                    required={true}
								/>
							</div> 
						</div>
                        {this.state.isError && <div className={'error-message'}>
								{'Please Enter all mandatory fields'}
						</div>}
                        <div className={'button_route'}>
                            <Button value={'Cancel'} click={this.props.toggleAdd} />
                            {!this.props.editData ?
                                <Button value={'Save'} click={this.onSave} />
                                :
                                <Button value={'Update'} click={this.onUpdate} />
                            }
                        </div>
                </div> 
				</div>
			)
		}
	}

export default AddRouteMaster;
