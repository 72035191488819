import React, {PropTypes} from 'react';
import './days-hours-min.less';

export default class DaysHoursMin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        days : null,
        hours : null,
        minutes : null
    }
  }

  dayChanged(field,val,e){
    let tempVal = this.props.val;
    tempVal[field] = e.target.value;
    this.setState({
      [field] : e.target.value
    })
    this.props.dayChanged(tempVal,this.props.id);
  }
  componentWillReceiveProps (newProps) {
    // if(newProps.val && !(this.state.days || this.state.hours ||this.state.minutes))
    this.setState({
      days : newProps.val.days,
      hours : newProps.val.hours,
      minutes : newProps.val.minutes
    })
  }

  setOption = (option) => {
      this.setState({
        selectedOption : option
      })
  }

  render() {
    return (
      <div className = "days-hours-min-root">
        <div className = "days-hours-holder"><input className = "dhm-input" type = "text" value = {this.state.days || ''} onChange = {this.dayChanged.bind(this, "days",this.props.val)}/><div className = "dhm-label">Days</div></div>
        <div className = "days-hours-holder"><input className = "dhm-input" type = "text" value = {this.state.hours || ""} onChange = {this.dayChanged.bind(this, "hours", this.props.val)}/><div className = "dhm-label">Hours</div></div>
        <div className = "days-hours-holder"><input className = "dhm-input" type = "text" value = {this.state.minutes || ""} onChange = {this.dayChanged.bind(this, "minutes", this.props.val)}/><div className = "dhm-label">Minutes</div></div>
      </div>
    )
  }
}
