import React, { Component} from "react";
import { withRouter } from "react-router";
import MaterialMrp from "../../material-mrp/material-mrp";
import DamageRate from "../../Damage Rate/damageRate";

class MaterialDamage extends Component {
    constructor(props) {
        super(props);
        this.state = {
          Tabs : [
            {
              label: "Material MRP",
              id: "1"
            },
            {
              label: "Damage Rate",
              id: "2"
            }
          ],
          selectedOption : "1"
        }
      }

      onTabChange = (tabId) => {
        this.setState({
          selectedOption : tabId
        })
      }

      render() {
        return (
          <div className = "dept-roles-root">
            <div className={"home-navigation-holder"}>
              {
                this.state.Tabs.map(tab => {
                  return(
                  // <div key={tab.id} className={this.state.selectedOption === tab.id
                  //   ? "selected-option options"
                  //   : "options"} onClick={() => this.onTabChange(tab.id)}>{tab.label}</div>
                    <div key={tab.id} className={`${this.state.selectedOption === tab.id ? "selected-option options" : "options"} ${tab.id == 2 ? "roles-class" : ""}`} onClick={() => this.onTabChange(tab.id)}>
                      {tab.label}
                    </div>
                    )
                })
              }
            </div>
            {this.state.selectedOption === "1" && <MaterialMrp company_id={this.props.company_id}/>}
            {this.state.selectedOption === "2" && <DamageRate company_id={this.props.company_id}/>}
            {/* {this.state.selectedOption === "1" && <Deparment editDepartment={this.props.editDepartment} addDepartment = {this.props.addDepartment} deleteDepartment = {this.props.deleteDepartment} departments = {this.props.departments} roles = {this.props.roles} companyDetails = {this.props.companyDetails}  companyType = {this.props.companyType} downloadProviderDepartmentDeatils ={this.props.downloadProviderDepartmentDeatils} companyId ={this.props.companyId}  uploadProviderDepartmentFile ={this.props.uploadProviderDepartmentFile}/>} */}
            {/* {this.state.selectedOption === "2" && <Roles addRole = {this.props.addRole} deleteRole = {this.props.deleteRole} roles = {this.props.roles} companyDetails = {this.props.companyDetails} companyType = {this.props.companyType} downloadProviderRolesDeatils ={this.props.downloadProviderRolesDeatils} companyId ={this.props.companyId} uploadProviderRolesFile ={this.props.uploadProviderRolesFile}/>}  */}
          </div>
        )
      }
    }
    export default withRouter (MaterialDamage);


