import React, { Component } from 'react';
import Button from "../../../button-classic/button-classic";
import Delete from "../../../../../assets/images/delete.svg";
import Edit from "../../../../../assets/images/edit.svg";
import AddRange from "./add_range";

class Range extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
            search: ''
        }
    }

    componentDidMount() {
        this.props.getSlabRange();
    }

    addSlab = (editData = '') => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen, editData });
    }

    toggleDirectDelete = (slab) => {
        this.props.deleteSlabRange(slab);
    }

    render() {

        const { slabRange } = this.props;
        return(
            <div className="plants-depots-main-wrap">
                <div className="search-holder">
                <div className="add-plants-section">
                    <Button value="+ Slab Range" click={() => this.addSlab('')} />
                    {/* <input class="button-classic ml-10 mr-10" type="button" value="Download Temp." onClick={() => this.props.downloadLoadingBay(false)} />
                    <a className="download-btn" id="loadingBayDownloadBtn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.props.downloadLink} download />
                    <button className="button-classic ml-10 mr-10" onClick={() => {this.uploadExcel.click()}}> Upload </button>
                    <input type='file' style ={{display :'none'}} ref={(e) => { this.uploadExcel = e ;}} onChange={this.props.uploadLoadingBayDetails} onClick={(e) => {e.target.value = null }}/>
                    <input class="button-classic ml-10 mr-10" type="button" value="Download" onClick={() => this.props.downloadLoadingBay(true)} />
                    <a className="download-btn" id="loadingBayDownloadBtn" style={{ display: 'none'}} ref={input => this.downloadBtn = input} href={this.props.downloadLink} download /> */}
                </div>
                </div>
                <div className="heading">
                    <div className="flow-head">Min. Range</div>
                    <div className="flow-head">Max. Range</div>
                    <div className="flow-head">Actions</div>
                </div>
                {(slabRange || []).map((slab, index) => {
                return(
                    <div className={"depot-users-list"} key={`slab-${index}`} style={{marginLeft: '40px'}}>
                        <div className="flow-head">{slab.lowerlimit}</div>
                        <div className="flow-head">{slab.upperlimit}</div>
                        <div className="flow-head">
                            <div className = "delete-icon" style={{backgroundImage: `url(${Edit})`}} onClick = {() => this.addSlab(slab)}></div>
                            <div className = "delete-icon" style={{backgroundImage: `url(${Delete})`}} onClick = {() => this.toggleDirectDelete(slab)}></div>
                        </div>
                    </div>)
                })}

                {(!slabRange || slabRange.length === 0) && <div className={'no_data_found'}>{'No Data Found'}</div>}

                {this.state.isOpen && <AddRange toggleAdd={this.addSlab} onAddSlab={this.props.addSlabRange} updateSlabRange={this.props.updateSlabRange} data={this.state.editData}/>}
        </div>
        )
    }
}

export default Range;