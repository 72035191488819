import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import InputText from "../../../common/components/input-text/input-text";
import { chainType } from "../../../../utils/constants";
import Delete from "../../../../assets/images/delete.svg";
import Add from "../../../../assets/images/add.svg";
import { decimalNumber, numeric } from "../../../../utils/validate";
import Button from "../../button-classic/button-classic";
import ACTION from "../../../common/action_constant";
import { getCookie } from "../../../../utils/cookies";
import {
  ABFRL_COMPANY_IDS,
  PANTALOONS_COMPANY_IDS,
} from "../../../../utils/common";

import "./add-freight-rate.less";
const zoneObj = {
  from_zone_id: "",
  to_zone_id: "",
  rate: "",
};

const slab_rate = {
  rate: "",
  min_applicable_weight: "0",
  max_applicable_weight: "0",
};

const typesArray = [
  {
    value: "Actual",
    label: "Actual",
  },
  {
    value: "Volumetric",
    label: "Volumetric",
  },
];

class MinimumFreightRate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      vendors: [],
      modes: [],
      data: {
        vendor_id: props.editMinimumFreightData
          ? props.editMinimumFreightData.vendor_id
          : "",
        vendor_name: props.editMinimumFreightData
          ? props.editMinimumFreightData.vendor_name
          : "",
        company_category:props.editMinimumFreightData
          ?props.editMinimumFreightData.company_category
          :"",
        category_id: props.editMinimumFreightData
          ? props.editMinimumFreightData.category_id
          : "",
        type: props.editMinimumFreightData
          ? props.editMinimumFreightData.type
          : "",
        mode: props.editMinimumFreightData
          ? props.editMinimumFreightData.mode
          : "",
          mode_name: props.editMinimumFreightData
          ? props.editMinimumFreightData.mode_name
          : "",
        freight_remarks: props.editMinimumFreightData
          ? props.editMinimumFreightData.freight_remarks
          : "",
        min_docket_charge: props.editMinimumFreightData
          ? props.editMinimumFreightData.min_docket_charge
          : "",
        min_docket_remarks: props.editMinimumFreightData
          ? props.editMinimumFreightData.min_docket_remarks
          : "",
        chain_type: props.editMinimumFreightData
          ? props.editMinimumFreightData.chain_type
          : "",
        // transporter: props.editMinimumFreightData
        //   ? props.editMinimumFreightData.transporter.length > 0 &&
        //     props.editMinimumFreightData.transporter.map((val) => {
        //       return { label: val.company_name, value: val.id };
        //     })
        //   : [],
        // slab: props.editMinimumFreightData
        //   ? props.editMinimumFreightData.slab
        //   : [
        //       {
        //         rate: "",
        //         min_applicable_weight: "",
        //         max_applicable_weight: "",
        //       },
        //     ],
        min_chargeable_freight: props.editMinimumFreightData
          ? props.editMinimumFreightData.min_chargeable_freight
          : "",
        // max_applicable_weight: props.editMinimumFreightData ? props.editMinimumFreightData.max_applicable_weight : '',
        // min_applicable_weight: props.editMinimumFreightData ? props.editMinimumFreightData.min_applicable_weight : '',
        // zone_rates: props.editMinimumFreightData
        //   ? props.editMinimumFreightData.zone_rates
        //   : [
        //       {
        //         from_zone_id: "",
        //         to_zone_id: "",
        //         rate: "",
        //       },
        //     ],
      },
      zoneOptions:
        props.zones && props.zones.length > 0
          ? props.zones.map((val) => {
              return { label: val.name, value: val.id };
            })
          : [],
    };
  }

  componentWillMount() {
    this.getVendorsData();
    this.getModeTypesData();
  }

  componentWillReceiveProps(nextProps) {
    const { dataGovernance } = this.props;
    let newCategories = [];
    let newModes = [];
    let newVendors = [];

    if (
      dataGovernance.vendorTypes != undefined &&
      dataGovernance.modeTypes != undefined
    ) {
      const { vendorTypes, modeTypes } = dataGovernance;
      if (vendorTypes.length > 0 && this.state.categories.length == 0) {
        newCategories = Object.values(
          vendorTypes.reduce(
            (acc, cur) =>
              Object.assign(acc, { [cur.company_category_id]: cur }),
            {},
          ),
        );
        this.setState({
          categories: newCategories.map((el) => ({
            value: el.company_category_id,
            label: el.company_category,
          })),
        });
      }
      if (vendorTypes.length > 0 && this.state.vendors.length == 0) {
        this.setState({
          vendors: vendorTypes,
        });
      }
      if (modeTypes.length > 0 && this.state.modes.length == 0) {
        newModes = Object.values(
          modeTypes.reduce(
            (acc, cur) => Object.assign(acc, { [cur.id]: cur }),
            {},
          ),
        );
        this.setState({
          modes: newModes.map((el) => ({ ...el, selected: false })),
        });
      }
    }
  }

  onChangeHandler = (value, field) => {
    if (decimalNumber(value) || value == "") {
      const { data } = this.state;
      data[field] = value;
      this.setState({ data });
    }
  };

  onSave = () => {
    const { data } = this.state;
    const { mainTabOption } = this.props;
    // data.transporter = data.transporter.map((val) => {
    //   return { company_name: val.label, id: val.value };
    // });
    // data.zone_rates = [3, 4].indexOf(mainTabOption) > -1 ? [] : data.zone_rates;
    this.props.addCreditDetails(data);
    this.props.toggleAdd(140);
  };

  onUpdate = () => {
    const { data } = this.state;
    const { editMinimumFreightData, mainTabOption } = this.props;
    // data.transporter =
    //   data.hasOwnProperty("transporter") && data.transporter != ""
    //     ? data.transporter.map((val) => {
    //         return { company_name: val.label, id: val.value };
    //       })
    //     : [];
    data.credit_master_id = editMinimumFreightData.credit_master_id;
    data.company_id = editMinimumFreightData.company_id;
    data.is_active = true;
    data.mode_type = editMinimumFreightData.mode_type;
    data.load_type = editMinimumFreightData.load_type;
    data.type = editMinimumFreightData.type;
    // data.zone_rates = [3, 4].indexOf(mainTabOption) > -1 ? [] : data.zone_rates;
    this.props.updateCreditDetails(data, 140);
    this.props.toggleAdd(140);
  };

  onAddZoneRate = () => {
    const { data } = this.state;
    const row = Object.assign({}, zoneObj);
    data.zone_rates.push(row);
    this.setState({ data });
  };

  onDeleteZoneRate = (index) => {
    const { data } = this.state;
    data.zone_rates.splice(index, 1);
    this.setState({ data });
  };

  onTransporterSearch = (value) => {
    if (value) {
      this.props.getTransporterList(value);
    }
  };

  onAddSlabs = () => {
    const { data } = this.state;
    const row = Object.assign({}, slab_rate);
    data.slab.push(row);
    this.setState({ data });
  };

  onDeleteSlabs = (index) => {
    const { data } = this.state;
    data.slab.splice(index, 1);
    this.setState({ data });
  };

  onChangeSlab = (index, value, field) => {
    if (decimalNumber(value) || value == "") {
      const { data } = this.state;
      data.slab[index][field] = value;
      this.setState({ data });
    }
  };

  validateVal = (val) => {
    if(!!val){
      return val.toString().match(/^\d{1,8}(\.\d{1,3})?$/);
    }
  };

  validData = ({ data } = this.state) => {
    let check, check1;
    //return (data.chain_type && data.transporter.length !== 0 && this.validateVal(data.min_chargeable_freight) && (data.slab && data.slab.every((data, i) => (check = (data.min_applicable_weight[i] && data.max_applicable_weight[i] && data.rate[i] !== null && data.rate !== '' ))) || data.zone_rates && data.zone_rates.every((data, i) => (check1 = (data.from_zone_id && data.to_zone_id && data.rate[i]!==null && data.rate!=='' )))));
    return (
      data.chain_type &&
      data.transporter.length !== 0 &&
      this.validateVal(data.min_chargeable_freight)
    );
  };

  validAbData = ({ data } = this.state) => {
    return (
      data.vendor_id &&
      data.category_id !== "" &&
      this.validateVal(data.min_chargeable_freight) &&
      data.mode !== ""
    );
  };

  getModeTypesData = () => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTION.DATA_GOVERNANCE.GET_MODE_TYPES,
      onSuccess: (data) => {
        this.setState({ modes: data });
      },
      // params:
    });
  };

  getVendorsData = () => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTION.DATA_GOVERNANCE.GET_VENDORS_TYPES,
      onSuccess: (data) => {
        const newCategories = Object.values(
          data.reduce(
            (acc, cur) =>
              Object.assign(acc, { [cur.company_category_id]: cur }),
            {},
          ),
        );
        this.setState({
          categories: newCategories.map((el) => ({
            value: el.company_category_id,
            label: el.company_category,
          })),
          vendors: data,
        });
      },
    });
  };

  render() {
    const {
      vendors,
      modes,
      categories,
      data
    } = this.state;
    console.log('data:', data)
    const disable = this.validAbData();
    return (
      <div className="add-company-wrap add-provider-root add-row add-freight-rate">
        <div onClick={this.props.toggleAdd} className="overlay">
          <div
            className="modal-popup"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="heading">{"Add Minimum Freight Rate"}</div>
            {
              <form className="form-section" autoComplete="nopes">
                <div className="select-wrap w-p-25">
                  <div className="label">Vendor Company Name <span className={"error error-font"}>*</span></div>
                  <Select
                    className="select w-200"
                    options={vendors}
                    value={
                      vendors.filter(
                        (el) => el.value == this.state.data.vendor_id,
                      ) || null
                    }
                    onChange={(selectedOption) => {
                      const { data } = this.state;
                      data.vendor_name = selectedOption.label;
                      data.vendor_id = selectedOption.value;
                      data.company_category = selectedOption.company_category;
                      data.company_category_id =
                        selectedOption.company_category_id;
                      data.category_id = selectedOption.company_category_id;
                      this.setState({ data });
                    }}
                  />
                </div>
                <div className="select-wrap w-p-25">
                  <div className="label">Category <span className={"error error-font"}>*</span></div>
                  <Select
                    className="select w-200"
                    options={categories}
                    value={
                      categories.filter(
                        (el) => el.value == this.state.data.category_id,
                      ) || null
                    }
                    onChange={(selectedOption) => {
                      const { data } = this.state;
                      data.category_id = selectedOption.value;
                      data.company_category=selectedOption.label;
                      this.setState({ data });
                    }}
                  />
                </div>
                <div className="select-wrap w-p-25">
                  <div className="label">Mode <span className={"error error-font"}>*</span></div>
                  <Select
                    className="select w-200"
                    options={modes}
                    value={
                      modes.filter((el) => el.value == this.state.data.mode) ||
                      null
                    }
                    onChange={(selectedOption) => {
                      const { data } = this.state;
                      data.mode = selectedOption.value;
                      data.mode_name = selectedOption.label;
                      this.setState({ data });
                    }}
                  />
                </div>
                <div className={"select-wrap"}>
                  <div className="label">Min chargeable Freight <span className={"error error-font"}>*</span></div>
                  <div className={"inline-flex"}>
                    <InputText
                      placeholder={"rate"}
                      id={"rate"}
                      value={this.state.data.min_chargeable_freight}
                      type={"number"}
                      changeHandler={(value, field) => {
                        const { data } = this.state;
                        data.min_chargeable_freight = value;
                        this.setState({ data });
                      }}
                    />
                    {<div className="m-t-10">(₹)</div>}
                  </div>
                </div>
                <div className={"select-wrap w-p-50"}>
                  <div className="label">Freight remarks</div>
                  <InputText
                    style={{ width: "100%" }}
                    placeholder={"Freigt remarks"}
                    id={"weight-remarks"}
                    value={this.state.data.freight_remarks}
                    type={"text"}
                    changeHandler={(value, field) => {
                      const { data } = this.state;
                      data.freight_remarks = value;
                      this.setState({ data });
                    }}
                  />
                </div>
              </form>
            }
            <div className="submit-section submit-area">
              {
                <div className="button-section">
                  {!this.props.editMinimumFreightData && (
                    <Button
                      className="add-button"
                      click={this.onSave}
                      disabled={!disable}
                      value="Save"
                    />
                  )}
                  {this.props.editMinimumFreightData && (
                    <Button
                      className="add-button"
                      click={this.onUpdate}
                      disabled={!disable}
                      value="Update"
                    />
                  )}
                </div>
              }
              <div className="button-section">
                <div className="add-button" onClick={this.props.toggleAdd}>
                  Close
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataGovernance: state.dataGovernance,
  };
};

export default connect(mapStateToProps)(MinimumFreightRate);
