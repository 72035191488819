import React, { Component } from 'react';
import SidePanel from "../../components/side-panel/side-panel";
import TopPanel from "../../components/header-panel/header-panel";
import "./main.less";

class MainComponent extends Component {
    render() {
        return (
            <div className = "main-wrap">
                <TopPanel />
                <div className = "panel-body-contents">
                    <SidePanel />
                    <div className = "main-content">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default MainComponent;