
import React, { Component } from 'react';
import InputText from '../../../common/components/input-text/input-text';
import Cx from "classnames";


class AddSalesdistrictMapping extends Component{
    constructor(props) {
        super(props);
        this.state = {
            data: {					
                "sales_district": this.props.editData ? this.props.editData.sales_district : '',
                "description": this.props.editData ? this.props.editData.description : '',
                "region_code" : this.props.editData ? this.props.editData.region_code : '',
                "state": this.props.editData ? this.props.editData.state : '',					
                "company_id": this.props.editData ? this.props.editData.company_id:this.props.companyId,			
            },
        }
    }

        
        onChangeHandler = (value, id) => {
            let { data } = this.state;
                if (id === 'sales_district') {
                    data[id] = (value || '').toUpperCase().replace(/[^A-Z0-9]/g, '');
                }
                else if(id === 'description') {
                        data[id] = (value || '').toUpperCase().replace(/[^A-Z0-9]/g, '');
                }
                else if(id === 'region_code') {
                    data[id] = (value || '').toUpperCase().replace(/[^A-Z0-9]/g, '');
                }
                else if(id === 'state') {
                    data[id] = (value || '').toUpperCase().replace(/[^A-Z0-9]/g, '');
                }
                else {
                    data[id] = value
                }
                this.setState({ data });    
                
        }
    
        onSave = () => {
            this.props.onAddSalesDistrictMapping(this.state);
            this.props.toggleAdd();
        }

        updateSalesDistrictMapping = () => {
            let details = this.state;
            this.props.updateSalesDistrictMapping(details);
            this.props.toggleAdd();
        }
    
        render() {
            const { sales_district, description, region_code, state,data } = this.state;
            const {editData } = this.props;
            return (
                <div className = "add-user-wrap add-bay">
                    <div onClick = {this.props.toggleAdd} className = "overlay"></div>
                    <div id = "modal" className = "modal-popup">
                        <div className = "pop-heading">
                            {`${editData ?'Edit' : 'Add'} Sales District`}
                        </div>
                        <div className = "content add-edit-user">
                        <form className = "form-section" autoComplete = "nope">
                            <InputText placeholder = "Sales District" id = {"sales_district"} label = "Sales District" value = {data.sales_district} changeHandler = {this.onChangeHandler}/>
                            <InputText placeholder = "Description" id = {"description"} label = "Description" value = {data.description} changeHandler = {this.onChangeHandler} />
                            <InputText placeholder = "Region Code" id = {"region_code"} label = "Region Code" value = {data.region_code} changeHandler = {this.onChangeHandler} />
                            <InputText placeholder = "State" id = {"state"} label = "State" value = {data.state} changeHandler = {this.onChangeHandler} />
                        </form>

                        <div className = "submit-section">
                            <button className = "add-button discard" onClick={this.props.toggleAdd} >Cancel</button>
                            {!editData ? <button className ={Cx("add-button", {
                                'disable-btn' : !(data.sales_district && data.description && data.region_code && data.state)
                            })} onClick={this.onSave}>Save</button>
                            :  <button className ={Cx("add-button", {
                                'disable-btn' : !(data.sales_district && data.description && data.region_code && data.state)
                            })} onClick={this.updateSalesDistrictMapping}>Update</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default AddSalesdistrictMapping;