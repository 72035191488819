import React, { Component } from 'react';
import "../company-details/department-roles/department/add_dept/add_department.less";


class AddGroup extends Component {

	render() {
		const { props, state } = this;
		const { group_company_name,
		handleSaveModal,
		handleCloseModal,
		company_id,
		handleOnChangeForGroupCompanyName} = this.props;
		return (
			<div className="add-company-wrap">
				<div onClick={props.close} className="overlay"></div>
				<div className="modal-popup small-modal add-department">
					<div className="heading">
						Add New Group
					</div>
					
					<form className="form-section" autoComplete="nopes">
										<InputText
									 		placeholder="Enter New Group Name"											
									 		value={group_company_name} 
									 		changeHandler={(value) => {		
											handleOnChangeForGroupCompanyName(value)								
										}}											
										/>					
					</form>
					<div>
						<div className="submit-section submit-area">
							<div className="button-section">
								<div className="add-button" onClick={()=>{
									handleSaveModal(company_id)
								}}>Save</div>
							</div>
							<div className="button-section">
								<div className="add-button" onClick={()=>{
								handleCloseModal()
								}}>Close</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}


const InputText = (props) => {
	return (
		<div className="input-text-wrap">
			<div className="label">
				{props.label}
			</div>
			<div className="input-element">
				<input type="text" id={props.id} placeholder={props.placeholder} className="text-input" value={props.value} onChange={(e) => props.changeHandler(e.target.value, props.id)} autoComplete="nopes" />
			</div>
		</div>
	)
}


export default AddGroup;