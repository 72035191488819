import React, { Component, Fragment } from 'react';
import { debounce, isValueThere } from '../../../utils';
import { GET_COMPANY_USER_INFO_LIST } from './companyUserInfoActionConstants';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import './companyUserInfo.scss';
import MessageCard from '../../common/components/messageCard/MessageCard';
import LoaderComponent from '../../common/components/loader/loader';
import KeyboardArrowUpIcon from '../../icon/KeyboardArrowUpIcon';
import KeyboardArrowDownIcon from '../../icon/KeyboardArrowDownIcon';
import { getCompanyType } from '../../../utils/common';
export class CompanyUserInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            companyUserInfoList: [],
            limit: 10,
            currentPage: 1,
            totalItems: 0,
        };
        this.handleSearch = debounce(this.handleSearch.bind(this), 500);
    }

    componentDidMount() {
        const { currentPage, limit, search } = this.state;
        const querys = {
            search,
            limit,
        };
        const args = {
            currentPage,
        };
        this.handleGetCompanyUserInfoList(querys, args);
    }

    handleGetCompanyUserInfoList = (querys = {}, args = {}) => {
        console.log('args:', args);
        const { dispatch, match } = this.props;
        const offset = (args.currentPage - 1) * querys.limit;
        const queryParams = {
            companyId: match.params.id,
            companyType:getCompanyType(),
            offset,
            ...querys,
        };

        const onSuccess = (resData = {}) => {
            this.setState((prevState) => {
                const updatedPrevState = { ...prevState };
                updatedPrevState.companyUserInfoList = resData.list || [];
                updatedPrevState.totalItems = resData.totalCount || 0
                return updatedPrevState;
            });
        };

        dispatch({
            type: GET_COMPANY_USER_INFO_LIST,
            queryParams,
            onSuccess,
        });
    };

    handleSearchInputOnChange = (event) => {
        const search = event.target.value;
        this.setState((prevState) => {
            const updatedPrevState = { ...prevState };
            updatedPrevState.search = search;
            return updatedPrevState;
        });
        this.handleSearch((search||'').trim());
    };

    handleSearch = (search) => {
        const { limit } = this.state;
        const querys = {
            search,
            limit,
        };
        const args = {
            currentPage: 1,
        };
        this.handleGetCompanyUserInfoList(querys, args);
        this.setState((prevState) => {
            const updatedPrevState = { ...prevState };
            updatedPrevState.currentPage = 1;
            return updatedPrevState;
        });
    };

    shouldRenderCompanyUserInfoList = () => {
        const { companyUserInfoList } = this.state;

        const C1 =
            Array.isArray(companyUserInfoList) && companyUserInfoList.length > 0;

        if (C1) {
            return true;
        }

        return false;
    };

    shouldRenderNoDataFoundCard = () => {
        const { companyUserInfoList } = this.state;

        const C1 =
            Array.isArray(companyUserInfoList) && companyUserInfoList.length <= 0;

        if (C1) {
            return true;
        }
        return false;
    };

    handlePreviousPage = () => {
        const { currentPage, limit } = this.state;
        const prevPage = currentPage - 1;
        this.setState({ currentPage: prevPage }, () => {
            this.fetchData(prevPage, limit);
        });
    };

    handleNextPage = () => {
        const { currentPage, limit, totalItems, search } = this.state;
        const totalPages = Math.ceil(totalItems / limit);

        if (currentPage < totalPages) {
            const nextPage = currentPage + 1;
            this.setState({ currentPage: nextPage }, () => {
                const querys = {
                    search,
                    limit,
                };
                const args = {
                    currentPage: nextPage,
                };
                this.handleGetCompanyUserInfoList(querys, args);
            });
        }
    };

    handlePreviousPage = () => {
        const { currentPage, limit, search } = this.state;

        if (currentPage > 1) {
            const prevPage = currentPage - 1;
            this.setState({ currentPage: prevPage }, () => {
                const querys = {
                    search,
                    limit,
                };
                const args = {
                    currentPage: prevPage,
                };
                this.handleGetCompanyUserInfoList(querys, args);
            });
        }
    };

    render() {
        const { companyUserInfoList, currentPage, totalItems, limit } = this.state;
        const { loader } = this.props;
        const totalPages = Math.ceil(totalItems / limit);
        return (
            <Fragment>
                {!!loader.loaderT1 && <LoaderComponent />}
                <div className="companyUserInfo">
                    <div className="flex items-center justify-between mb-16">
                        <h5 className="typographyT1 ">Company User Details</h5>

                        <input
                            className="search-input"
                            type="text"
                            placeholder="Search..."
                            value={this.state.search}
                            onChange={this.handleSearchInputOnChange}
                        />
                    </div>

                    <div className="tableT1Wrapper" style={{ maxWidth: '980px', maxHeight: '550px'  }}>
                        <table className="tableT1">
                            <thead className="theadT1">
                                <tr className="trT1">
                                    <th className="thT1">
                                        <div className="w-max"> User Name</div>
                                    </th>
                                    <th className="thT1">
                                        <div className="w-max"> Email</div>
                                    </th>
                                    <th className="thT1">
                                        <div className="w-max"> Branch Name</div>
                                    </th>
                                    <th className="thT1">
                                        <div className="w-max">Branch code</div>
                                    </th>
                                    <th className="thT1">
                                        <div className="w-max">User Branch Details</div>
                                    </th>
                                    <th className="thT1">
                                        <div className="w-max">Is Admin</div>
                                    </th>
                                    <th className="thT1">
                                        <div className="w-max">Is Active</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="tbodyT1">
                                {this.shouldRenderCompanyUserInfoList() && (
                                    <Fragment>
                                        {companyUserInfoList.map((ele, index) => {
                                            return (
                                                <Fragment key={ele.user_id}>
                                                    <tr
                                                        className={cx('trT1 cursor-pointer', {
                                                            'bg-EFF2FD': (index + 1) % 2 === 0,
                                                        })}
                                                    >
                                                        <td className="tdT1">
                                                            <div className="w-max">

                                                                {ele.user_name || '-'}
                                                            </div>
                                                        </td>
                                                        <td className="tdT1">
                                                            <div className="w-max">{ele.email || '-'}</div>
                                                        </td>
                                                        <td className="tdT1">
                                                            <div className="w-max">

                                                                {ele.branch_name || '-'}
                                                            </div>
                                                        </td>
                                                        <td className="tdT1">
                                                            <div className="w-max">
                                                                {ele.branch_code || '-'}
                                                            </div>
                                                        </td>
                                                        <UserBranchDetails branchList={ele.branchList} />
                                                        <td className="tdT1">
                                                            {ele.is_admin === true ? 'True' : 'False' || '-'}
                                                        </td>
                                                        <td className="tdT1">
                                                            {ele.is_active === true ? 'True' : 'False' || '-'}
                                                        </td>
                                                    </tr>
                                                </Fragment>
                                            );
                                        })}
                                    </Fragment>
                                )}

                                {this.shouldRenderNoDataFoundCard() && (
                                    <Fragment>
                                        <Fragment>
                                            <tr className={cx('trT1 cursor-pointer')}>
                                                <td colSpan={7} className="tdT1">
                                                    <MessageCard />
                                                </td>
                                            </tr>
                                        </Fragment>
                                    </Fragment>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination-wrapper">
                        <button
                            onClick={this.handlePreviousPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span>
                            Page {currentPage} of {totalPages}
                        </span>
                        <button
                            onClick={this.handleNextPage}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loader: state.loader,
    };
};

export default connect(mapStateToProps, null)(withRouter(CompanyUserInfo));

class UserBranchDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isViewMoreActive: false,
        }
    }

    getBranchList = () => {
        const { isViewMoreActive } = this.state;
        const { branchList } = this.props;

        if (isViewMoreActive) {
            return branchList || []
        }

        return (branchList || []).slice(0, 2)
    }

    handleOnToggleViewMoreAndLessBtn = () => {
        this.setState((prevState) => {
            const updatedStated = { ...prevState };
            updatedStated.isViewMoreActive = !prevState.isViewMoreActive
            return updatedStated
        })
    }

    render() {
        const { isViewMoreActive } = this.state;
        return (
            <Fragment>
                <td className="tdT1 ">

                    <ul>
                        {this.getBranchList().map((ele) => {
                            return (
                                <Fragment key={ele}>
                                    <li className='w-max'>{ele || '-'}</li>
                                </Fragment>
                            );
                        })}
                    </ul>
                    <button
                        onClick={this.handleOnToggleViewMoreAndLessBtn}
                        className='view-more-less-btn'
                    >

                        {isViewMoreActive
                            ? ' View less'
                            : ' View more'}
                        {isViewMoreActive
                            ? <Fragment>
                                <KeyboardArrowUpIcon
                                    width='24px'
                                    height='24px'
                                    fill='#42569E'
                                  
                                />
                            </Fragment>
                            : <Fragment>
                                <KeyboardArrowDownIcon
                                    width='24px'
                                    height='24px'
                                    fill='#42569E'
                                   
                                />
                            </Fragment>}
                    </button>
                </td>
            </Fragment>
        );
    }
}