import React from "react";
import Select from "react-select";
import { DateRangePicker } from "react-dates";
import Moment from "moment";
import ACTIONS from "../../../common/action_constant";
import Download from "../../../../assets/images/download.svg";
import Delete from "../../../../assets/images/delete.svg";
import Edit from "../../../../assets/images/edit.svg";
import "./DigitalLR.less";
import e from "cors";
import { connect } from "react-redux";
import { getUserCompanyName } from "../../../../utils/common";
import InputText from "../../../common/components/input-text/input-text";
import { getCookie } from "../../../../utils/cookies";
import Button from '../../../home/button-classic/button-classic';

class DigitalLR extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      data: {
        valid_from: null,
        valid_to: null,
        fuel_rate_variation: "",
        percentage_of_baserate: "40",
        fuel_rate: "",
      },
      company_id: "",
      boundTypeForUpload: "",
      selectedLSPForUpload: "",
      LSPDropdownOptions: [],
      seekerDropDown: [],
      selectedSeeker: "",
      selectedSeekerName: "",
      branchDropDown: [],
      lrProviderDetails: [],
      selectedBranch: "",
      selectedBranchName: "",
      LSPDropdownOptionsForDownload: [{
        value: 'All',
        label: 'All'
      }],
      selectedLSPs: [],
      selectedLSPindex: [],
      allDataSelected: false,
      fileName: "",
      boundTypeOptions: [{ key: 1, value: 1, label: "Inbound" }, { key: 2, value: 2, label: "Outbound" }],
      boundTypeOptionsForDownload: [{ key: 1, value: 1, label: "Inbound" }, { key: 2, value: 2, label: "Outbound" }, { key: 3, value: 3, label: 'All' }],
      details: {
        company_name: "",
        company_email: "",
        company_website: "",
        email: "",
        contact: "",
        pan_no: "",
        admin_name: "",
        gstin: "",
        tax_no: "",
        year_of_formation: "",
        last_fiscal_turnover_value: "",
        sap_vendor_code: "",
        sap_carrier_code: "",
        gstin_details: [
          {
            gstin: "",
            state: "",
            code: "",
            vendor_code: "",
            gst_percentage: "",
          },
        ],
        is_gst_applicable: false,
        sap_vendor_details: [
          {
            sap_vendor_code: "",
            company: "",
            company_id: "",
            sap_carrier_code: "",
          },
        ],
        bank_name: "",
        bank_account_no: "",
        bank_ifsc_code: "",
      },
    };
  }

  componentDidMount() {
    this.props.getBasicDetails()
    this.selectedOptions();

    this.fetchLRUploadSummaryData()
    this.getBranchForLR()
    this.fetchAllSeekers()
  }

  componentWillReceiveProps(newProps) {
    if (newProps.basicDetails) {
      this.getProviderLRDetails(newProps.basicDetails)
    }
  }

  fetchAllSeekers = () => {
    const { dispatch } = this.props;
    const { seekerDropDown } = this.state;
    dispatch({
      type: ACTIONS.HOME.GET_ALL_SEEKERS_FOR_LR,
      onSuccess: (data) => {
        data && data.list && data.list.map((ele, idx) => {
          seekerDropDown.push({ value: ele.id, label: ele.company_name })
        })
        this.setState({ seekerDropDown });
      }
    });
  }

  getBranchForLR = (selectedSeeker) => {
    const { dispatch } = this.props;
    const { branchDropDown } = this.state
    dispatch({
      type: ACTIONS.HOME.GET_BRANCH_FOR_LR,
      data: `${selectedSeeker}&limit=300`,
      onSuccess: (data) => {
        data && data.list && data.list.map(d => {
          branchDropDown.push({ value: d.branch_id, label: d.branch_name })
        })
        this.setState({ branchDropDown });
      },
    })
  }

  fetchLRUploadSummaryData = async () => {
    const { dispatch, basicDetails } = this.props;
    const user = getCookie('user') && JSON.parse(getCookie('user')) || {};

    const companyId = this.props && this.props.companyId || user.company_id;
    await dispatch({
      type: ACTIONS.HOME.FETCH_LR_UPLOAD_SUMMARY,
      ...((basicDetails && basicDetails.company_type === 2) ? { companyType: basicDetails.company_type } : { companyId: companyId }),
      ...((basicDetails && basicDetails.company_type === 2) ? { lspName: basicDetails.company_name } : (this.state.selectedLSPForDownload ? { lspName: this.state.selectedLSPForDownload } : {})),
      ...(this.state.boundTypeForDownload ? { boundType: this.state.boundTypeForDownload } : {}),
      ...(this.state.data.valid_from ? { fromDate: this.state.data.valid_from } : {}),
      ...(this.state.data.valid_to ? { toDate: this.state.data.valid_to } : {}),
      onSuccess: (data) => {
        this.setState({ uploadSummaryData: data, filteredSummaryData: data });
      },
    });
  };

  getProviderLRDetails = async (data) => {
    const { dispatch, basicDetails } = this.props;
    await dispatch({
      type: ACTIONS.HOME.GET_PROVIDER_LR_DETAILS,
      data: data && data.id,
      onSuccess: ((data) => {
        this.setState({ lrProviderDetails: data })
      })
    })
  }

  selectedOptions = () => {

    const { dispatch } = this.props;
    const user = getCookie('user') && JSON.parse(getCookie('user')) || {};
    const companyId = this.props && this.props.companyId || user.company_id;
    const { LSPDropdownOptions, LSPDropdownOptionsForDownload } = this.state
    dispatch({
      type: ACTIONS.HOME.SELECTED_OPTIONS,
      data: companyId,
      onSuccess: (data) => {
        data &&
          data.map((ele) => {
            LSPDropdownOptions.push({ value: ele.lsp_alias, label: ele.lsp_alias });
            LSPDropdownOptionsForDownload.push({ value: ele.lsp_alias, label: ele.lsp_alias })
          });
        this.setState({ LSPDropdownOptions, LSPDropdownOptionsForDownload })
      },
    });
  };

  downloads = () => {
    const { dispatch } = this.props;
    const companyId = this.props.companyId;
    dispatch({
      type: ACTIONS.HOME.DOWNLOADS_OPTIONS,
      data: companyId,
      onSuccess: (data) => {
        const element = document.createElement('a');
        element.setAttribute('href', data[0].link);
        element.setAttribute('download', true);
        document.body.appendChild(element);

        element.click();
        document.body.removeChild(element);


        this.setState({ boundTypeForDownload: "", selectedLSPForDownload: "" })

        let DATA = this.state.data
        this.setState({ DATA }, () => {
          this.setState({ data: { ...DATA, valid_from: "", valid_to: "" } })
        })
      },
    });
  };

  fileClicked = (e) => {
    e.preventDefault();
    document.getElementById("file").click();
  };

  fileUpload = e => {
    e.persist();
    this.setState({
      fileName: e.target.files[0]
    });
  };

  uploadFile = async () => {
    const { dispatch } = this.props;
    const formData = new FormData();

    if (this.state.fileName === "" || this.state.selectedLSPForUpload === "" || this.state.boundTypeForUpload === "") {
      dispatch({
        type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
        data: { type: 2, msg: "Please select the all details for upload LR details" },
      })
    } else {
      formData.append("file", this.state.fileName);
      dispatch({
        type: ACTIONS.HOME.UPLOAD_LR_DETAIL,
        file: formData,
        data: `${this.state.selectedLSPForUpload}&companyId=${this.props.companyId}&boundType=${this.state.boundTypeForUpload}`,
        onSuccess: (() => {
          this.fetchLRUploadSummaryData()
          this.setState({ selectedLSPForUpload: "", boundTypeForUpload: "", fileName: "" })
        }),
      });
    }
  };

  handleSelectBoundTypeForUpload = (e) => {
    this.setState({ boundTypeForUpload: e.value });
  };

  // handleSelectBoundTypeForDownload = (e) => {
  //   this.setState({ boundTypeForDownload: e.value }, () => {
  //     this.fetchLRUploadSummaryData()
  //   });
  // }

  handleSelectedLSPForUpload = (e) => {
    this.setState({ selectedLSPForUpload: e.value });
  };

  // handleSelectedLSPForDownload = async (e) => {
  //   this.setState({ selectedLSPForDownload: e.value }, () => {
  //     this.fetchLRUploadSummaryData()
  //   });
  // };

  buildPayloadForDelete = (data, multipleDelete) => {
    const { selectedLSPindex, allDataSelected, filteredSummaryData } = this.state;
    let payload = {
      list: []
    }
    if (multipleDelete) {
      if (allDataSelected) {
        filteredSummaryData.map((dat) => {
          payload.list.push({ uploadedOn: dat.uploaded_on, fileName: dat.file_name, companyId: this.props.companyId })
        })
      } else {
        const data = filteredSummaryData.filter((data, index) => selectedLSPindex.includes(index))
        data.map((dat) => {
          payload.list.push({ uploadedOn: dat.uploaded_on, fileName: dat.file_name, companyId: this.props.companyId })
        })
      }
    } else {
      payload.list.push({ uploadedOn: data.uploaded_on, fileName: data.file_name, companyId: this.props.companyId })
    }
    this.deleteLRUploadDetail(payload)
  }

  deleteLRUploadDetail = async (data) => {
    const { dispatch } = this.props;
    await dispatch({
      type: ACTIONS.HOME.DELETE_LR_UPLOAD_DETAILS,
      data: data,
      onSuccess: (() => {
        dispatch({
          type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
          data: { type: 2, msg: "LR details deleted successfully!" },
        })
        this.fetchLRUploadSummaryData()
        this.setState({ selectedLSPindex: [], allDataSelected: false })
      }),
    });


  }

  selectLSP = (id, data, allSelected) => {
    const { selectedLSPindex } = this.state;
    if (allSelected) {
      if (this.state.allDataSelected) {
        return this.setState({ allDataSelected: !this.state.allDataSelected, selectedLSPindex: [] })
      } else {
        return this.setState({ allDataSelected: !this.state.allDataSelected })
      }
    }

    if (selectedLSPindex.includes(id)) {
      const index = selectedLSPindex.findIndex(data => data == id)
      selectedLSPindex.splice(index, 1)
      this.setState({ selectedLSPindex, allDataSelected: false })
    } else {
      if (selectedLSPindex.length + 1 == this.state.filteredSummaryData.length) {
        this.setState({ selectedLSPindex: [...selectedLSPindex, id], allDataSelected: true })
      } else {
        this.setState({ selectedLSPindex: [...selectedLSPindex, id] })
      }
    }
  }

  downloadLRUploadDetail = (data) => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.HOME.DOWNLOAD_LR_UPLOAD_DETAIL,
      data: `${data.file_name}&companyId=${this.props.companyId}&uploadedOn=${data.uploaded_on}`,
      onSuccess: (data) => {
        const element = document.createElement('a');
        element.setAttribute('href', data[0].link);
        element.setAttribute('download', true);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      },
    });
  };

  sendToMonglix = (data) => {

    this.setState((prevState) => ({
      count: prevState.count + 1,
    }));
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.HOME.SEND_LR_TO_MOGLIX,
      data: `${this.props.companyId}&lspName=${data.lsp_alias}&fileName=${data.file_name}&uploadedOn=${data.uploaded_on}`,
      onSuccess: (data) => {
        dispatch({
          type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
          data: { type: 1, msg: data },
        })
        this.fetchLRUploadSummaryData()
      },
    });
  }

  saveLRrange = () => {
    const { dispatch, basicDetails } = this.props;
    const { edit_provider_data } = this.state;
    //const companyName = getUserCompanyName()

    if (basicDetails && basicDetails.company_type === 2) {
      if (this.state.data.start_lr && this.state.data.end_lr) {
        if (!edit_provider_data) {
          let payload = {
            provider_id: basicDetails.id,
            company_id: this.state.selectedSeeker,
            branch_id: this.state.selectedBranch,
            company_name: this.state.selectedSeekerName,
            branch_name: this.state.selectedBranchName,
            lr_from: parseInt(this.state.data.start_lr),
            lr_to: parseInt(this.state.data.end_lr),
            lr_prefix: this.state.lr_prefix
          }

          dispatch({
            type: ACTIONS.HOME.SET_LR_RANGE_FOR_PROVIDER,
            data: payload,
            onSuccess: (() => {
              this.setState({ ...this.state, data: { start_lr: "", end_lr: "" } })
              this.setState({ selectedBranch: "", selectedSeekerName: "", selectedSeeker: "", lr_prefix: "", edit_provider_data: false, openAddRecord: false })
              this.getProviderLRDetails(basicDetails)
            })
          })
        }
        else {
          let payload = {
            provider_id: basicDetails.id,
            company_id: this.state.selectedSeeker,
            branch_id: this.state.selectedBranch,
            lr_from: parseInt(this.state.data.start_lr),
            lr_to: parseInt(this.state.data.end_lr),
            lr_prefix: this.state.lr_prefix,
            lr_mgmt_id: this.state.lr_mgmt_id,
            created_on: this.state.created_on,
            branch_name: this.state.selectedBranchName,
            company_name: this.state.selectedSeekerName,
          }

          dispatch({
            type: ACTIONS.HOME.EDIT_LR_DATA_FOR_PROVIDER,
            data: payload,
            onSuccess: (() => {
              this.setState({ ...this.state, data: { start_lr: "", end_lr: "" } })
              this.setState({ selectedBranch: "", selectedSeekerName: "", selectedSeeker: "", lr_prefix: "", edit_provider_data: false, openAddRecord: false })
              this.getProviderLRDetails(basicDetails)
            })
          })
        }
      }
      else {
        this.setState({ rangeError: 'Please select the LR range' })
      }

    }
    else {
      if (this.state.data.start_lr && this.state.data.end_lr) {
        this.setState({ rangeError: '' })
        dispatch({
          type: ACTIONS.HOME.SET_LR_RANGE,
          data: this.state.selectedSeeker,
          ...(this.state.data.start_lr ? { start: this.state.data.start_lr } : {}),
          ...(this.state.data.end_lr ? { end: this.state.data.end_lr } : {}),
          ...(this.state.selectedBranch ? { branchId: this.state.selectedBranch } : {}),
          ...(this.state.selectedSeekerName ? { selectedSeekerName: this.state.selectedSeekerName } : {}),
          // ...{ companyName: companyName },
          ...{ companyId: this.props.companyId },
          onSuccess: (() => {
            this.setState({ ...this.state, data: { start_lr: "", end_lr: "" } })
            this.setState({ selectedBranch: "", selectedSeekerName: "", selectedSeeker: "" })
          })
        })
      }
      else {
        this.setState({ rangeError: 'Please select the LR range' })
      }
    }
  }

  deleteLRforProvider = (data) => {
    const { dispatch, basicDetails } = this.props;
    dispatch({
      type: ACTIONS.HOME.DELETE_LR_FOR_PROVIDER,
      data: data,
      onSuccess: (() => {
        this.getProviderLRDetails(basicDetails)
      })
    })
  }

  EditProviderData = (providerLrData) => {
    const { data } = this.state;

    this.setState({
      selectedSeeker: providerLrData.company_id,
      selectedBranch: providerLrData.branch_id,
      selectedBranchName: providerLrData.branch_name,
      selectedSeekerName: providerLrData.company_name,
      lr_prefix: providerLrData.lr_prefix,
      edit_provider_data: true,
      lr_mgmt_id: providerLrData.lr_mgmt_id,
      created_on: providerLrData.created_on

    }, () => {
      this.getBranchForLR(this.state.selectedSeeker)
    })
    data.start_lr = providerLrData.lr_from;
    data.end_lr = providerLrData.lr_to;

    this.setState({ data, openAddRecord: true })

  }

  onChangeForSetLR = (value, id) => {
    const { data } = this.state
    data[id] = value.replace(/[a-zA-Z&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, '');
    this.setState({ data })
  }

  handleSelectedSeeker = (e) => {
    this.setState({ selectedSeeker: e.value, selectedSeekerName: e.label, selectedBranchName: "", branchDropDown: [] }, () => {
      this.getBranchForLR(this.state.selectedSeeker)
    })
  }

  handleSelectBranch = (e) => {
    this.setState({ selectedBranchName: e.label })
    this.setState({ selectedBranch: e.value })
  }

  onAddRecord = () => {
    this.setState({openAddRecord: !this.state.openAddRecord})
  }

  render() {

    const { basicDetails } = this.props;
    const { lrProviderDetails, searchValue } = this.state;
    const customStyles = {
      control: (base) => ({
        ...base,
        borderColor: "#528aea",
      }),
    };

    const CustomeCheckBox = (props) => {
      return (
        <div
          className={
            (props.selected || this.state.allDataSelected)
              ? "custome-checkbox-wrap selected"
              : "custome-checkbox-wrap"
          }
          onClick={() => { this.selectLSP(props.idx, props.data, props.allSelected) }}
        >
          {(props.selected || this.state.allDataSelected) && <div className="inner-circle"></div>}
        </div>
      );
    };

    return (
      <div className="Lr-upload">
        <div className="upload">
          <div className='contact-btn mt-10'>
						<Button value="+ Add Record" click={this.onAddRecord} className={'button-classic'}/>
					</div>

          

          {basicDetails && basicDetails.company_type === 2 && this.state.openAddRecord &&
          <div className = "add-chain">
          <div onClick = {this.props.toggleAdd} className = "overlay">
          <div id = "modal" className = "modal-popup" style={{minHeight: '300px'}}>
              <div className = "pop-heading">
                  Add Record
              </div>
              <div className="content">
                <div className="details-section">
                  <div className="heading-section network-consignee">LR Management</div>
                  <div className="details">
                    <div style={{ display: 'flex', marginTop: '20px', width: '100%', justifyContent: 'space-between' }}>
                      <Select
                        styles={customStyles}
                        options={this.state.seekerDropDown}
                        onChange={this.handleSelectedSeeker}
                        className='branchdrop'
                        placeholder="Select Seeker"
                        value={(this.state.seekerDropDown || []).filter((seeker) => { return seeker.value === this.state.selectedSeeker })}
                      />
                      <Select
                        styles={customStyles}
                        options={this.state.branchDropDown}
                        onChange={this.handleSelectBranch}
                        className='branchdrop'
                        placeholder="Select Branch"
                        value={(this.state.branchDropDown || []).filter((branch) => { return branch.value === this.state.selectedBranch })}
                      />

                      <input
                        name="lr_prefix"
                        placeholder=" Enter LR prefix"
                        style={{ width: "200px", height: '35px', padding: '5px', border: '1px solid #528AEA', borderRadius: '4px', color: '#528AEA', outline: 'none' }}
                        onChange={(e) => {
                          this.setState({ lr_prefix: e.target.value })
                        }}
                        value={this.state.lr_prefix}
                      />

                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="lr-range" style={{ width: '280px', marginRight: '20px'}}>
                          <div className="lr-rangeleft" style={{borderRight: 'none'}}>
                            <div className="lr-range1">
                              <input id="start_lr" type={"text"} placeholder="LR" value={this.state.data.start_lr} onChange={(e) => { this.onChangeForSetLR(e.target.value, 'start_lr') }} />
                            </div>

                            <span className="lr-range-to">TO</span>
                            <div className="lr-range1">
                              <input id="end_lr" type={"text"} placeholder="LR" value={this.state.data.end_lr} onChange={(e) => { this.onChangeForSetLR(e.target.value, 'end_lr') }} />
                            </div>
                          </div>

                        </div>
                        <span style={{ color: 'red', fontSize: '14px' }}>{this.state.rangeError ? this.state.rangeError : ""}</span>
                      </div>

                      

                      {/* <input
                        name="pending_qty"
                        placeholder="Search"
                        style={{ width: "200px", height: '35px', padding: '5px', border: '1px solid #528AEA', borderRadius: '4px', color: '#528AEA', outline: 'none' }}
                        onChange={(e) => {
                          this.setState({ searchValue: e.target.value }, () => this.getProviderLRDetails())
                        }}
                      /> */}

                    </div>
                    <div className="flex mt-20">
                        <div className="button-classic" style={{padding: '5px 20px'}} onClick={() => this.saveLRrange()}>
                            Save
                        </div>
                        <div className="button-classic" style={{padding: '5px 20px'}} onClick={() => this.onAddRecord()}>
                            Cancel
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }

          {/* <div className="del-check">
            <div className="checkbox">
              <CustomeCheckBox allSelected />
            </div>

            <div className="delete-button">
              <div
                className={"delete-icon"}
                style={{ backgroundImage: `url(${Delete})` }}
                onClick={() => this.buildPayloadForDelete('', true)}
              ></div>
            </div>
          </div> */}

          <div className="table">
            {basicDetails && basicDetails.company_type === 2 &&
              <table className="table-view"  style={{width: '100%'}}>
                <thead>
                  <tr className="table-tr">
                    <th className="tCenter" width="120px">
                      Seeker Name
                    </th>
                    <th className="tCenter" width="120px">
                      Branch Name
                    </th>
                    <th className="tCenter" width="120px">
                      LR Prefix
                    </th>
                    <th className="tCenter" width="120px">
                      LR from
                    </th>
                    <th className="tCenter" width="120px">
                      LR to
                    </th>
                    
                    <th
                      className="tCenter"
                      width="50px"
                      style={{ borderTopRightRadius: "8px" }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(lrProviderDetails || []).map((data, idx) => {
                    let date = Moment(data.uploaded_on).format('DD/MMM/YYYY')
                    let time = Moment(data.uploaded_on).format('LT')
                    return (
                      <tr className="tr-1">
                        <td>{data.company_name}</td>
                        <td>{data.branch_name}</td>
                        <td>{data.lr_prefix}</td>
                        <td>{data.lr_from}</td>
                        <td>{data.lr_to}</td>
                        
                        <td>
                          <div style={{ display: 'flex' }}>
                            <div
                              className={"delete-icon"}
                              style={{ backgroundImage: `url(${Edit})` }}
                              onClick={() => this.EditProviderData(data)}
                            />

                            <div
                              className={"delete-icon"}
                              style={{ backgroundImage: `url(${Delete})` }}
                              onClick={() => this.deleteLRforProvider(data)}
                            />
                          </div>

                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    basicDetails: state.home.basicDetails
  };
};

export default connect(mapStateToProps)(DigitalLR);
