import ACTIONS from "../../common/action_constant"


const initialState = {
  allBranchList:[],
  allDlno:[],
  allVehRegno:[],
  allSapMaterialCode:[],
}


const auditScreenReducer = (state = initialState, action)=>
 {
    const updatedState = {...state}
    switch (action.type) {
      case ACTIONS.AUDIT_SCREEN.SET_ALL_BRANCH_LIST:{
        updatedState.allBranchList = action.payload;
        return updatedState
      }
      case ACTIONS.AUDIT_SCREEN.SET_ALL_DL_NO:{
        updatedState.allDlno = action.payload;
        return updatedState
      }
      case ACTIONS.AUDIT_SCREEN.SET_ALL_VEH_REG_NO:{
        updatedState.allVehRegno = action.payload;
        return updatedState
      }
      case ACTIONS.AUDIT_SCREEN.SET_ALL_SAP_MATERIAL_CODE:{
        updatedState.allSapMaterialCode = action.payload;
        return updatedState
      }
      default:
        return state
    }
  }


export default auditScreenReducer

