import React, { Component } from 'react';
import InputText from "../../common/components/input-text/input-text";

class deleteDepartment extends Component {
   constructor(props) {
      super(props);
      this.state = {

      }
   }

   render() {
      const { props, state } = this;
      return (
         <div className="delete-company-wrap">
            <div onClick={props.close} className="overlay"></div>
            <div className={"modal-popup1 small-modal"} style={this.props.deactivateUser ? {boxShadow: 'black 0px 0px 20px 0px'} : {}}>
               <div>
                  <div className="question tCenter mb-20">Black Listed Remarks</div>
                    <div className="flex col-1">
                        <InputText
                        placeholder={"Black listed remark"}
                        label={"Black listed remark"}
                        id={"block_remarks"}
                        value={props.blacklisted_remarks}
                        changeHandler={props.onChangeHandler}
                        />
                    </div>
                  <div className="submit-section submit-area delete-confirmation">
                     <div className="button-section">
                        <div className="add-button" onClick={this.props.onSave}>{'Save'}</div>
                     </div>
                     <div className="button-section">
                        <div className="add-button" onClick={this.props.onCancel}>Cancel</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      );
   }
}

export default deleteDepartment;