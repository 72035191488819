import ACTION from "../../action_constant";

const initialState = {
	isLoading : false,
	loaderT1:0
}

const loaderReducer = (state = initialState, action) => {
    
    let tempState = Object.assign({}, state);
    if(action.type === ACTION.LOADER.SHOW_LOADER) {
		tempState.isLoading = true;
		return tempState;
	}
	else if(action.type == ACTION.LOADER.HIDE_LOADER){
		tempState.isLoading = false;
		return tempState;
	}
	else if (action.type === ACTION.LOADER.START_LOADER_T1) {
		tempState.loaderT1 =  ++tempState.loaderT1;
		return tempState
	}  
	else if (action.type === ACTION.LOADER.STOP_LOADER_T1) {
		tempState.loaderT1 =  --tempState.loaderT1;
		return tempState
	  }  
	return state;
};

export default loaderReducer;