import React, { Component, Fragment } from "react";
import "./SAPVendorForm.less"; // Import a CSS file to style the component (see note below)
import ACTION from "../../common/action_constant";
import { connect } from "react-redux";
import TopPanel from "../../components/header-panel/header-panel";
import { withRouter } from "react-router-dom";

import MsgPopup from "../../common/components/msg-popup/msg-popup";
import Loader from "../../common/components/loader/loader";
import Switch from "react-switch";
import SelectT1 from "../../components/common/Select/SelectT1";
import Select from "react-select";
import ACTIONS from "../../common/action_constant";

class SapVendorFormEX extends Component {
  constructor(props) {
    super(props);

    this.state = {
    vendorDetailsForSapForm: {
      vendor_company_name: "",
      company_id: "",
      contact_no: "",
      email: "",
      pan_no: "",
      account_number: "",
      bank_name: "",
      branch_name: "",
      IFSC_code: "",
      companyCode: "2000",
      currencyOrder: "",
      incoTerm1:"",
      sendSapDetailsSuccess:false,
      paymentMethod: "BANK",
      paymentTerms: "",
      purchaseOrganisation: "2000",
      reconAccount: "5031010",
      vendorAcctGroup: "ZTPT",
      centralBlocking: "",
      gstDetails: [
          {
              state: "",
              gstin: "",
              gst_percentage: ""
          }
      ],
      head_office_address: "",
      head_office_city: "",
      head_office_number: "",
      head_office_pincode: "",
      head_office_state: "",
     
  },
  }
}

   
  
  componentDidMount() {
    const { dispatch, match } = this.props;
    // let queryString = `?vendorId=${match.params.vendorId}`;
    let queryString1 = `?companyId=${match.params.companyId}`;
    console.log(match.companyId);
    dispatch({
      type: ACTION.VENDORS.GET_PAYMENT_TERMS_LIST,
      queryString: queryString1,
    });

    let queryString2 = `?companyId=${match.params.companyId}`;
    console.log(match.companyId);
    dispatch({
      type: ACTION.VENDORS.GET_CURRENCY_LIST,
      queryString: queryString2,
    });

    this.getStateListData();
    this.getIncotermsOptions();
  }

  componentWillReceiveProps(nextProps) {
   
  }

  getIncotermsOptions = () => {
    const { dispatch } = this.props;
    dispatch({type: ACTIONS.VENDORS.GET_INCO_TERMS_OPTIONS});
  }

  isSapVendorCodethere = (sap_vendor_code) => {
    return !!sap_vendor_code;
  };
  //Gst code
  getStateListData = () => {
    this.props.dispatch({
      type: ACTION.VENDORS.GET_STATE_LIST_DETAILS,
      data: "default",
      onSuccess: (data) => {
        const stateOptions = [];
        data.list.map((state) => {
          stateOptions.push({
            label: state.name,
            value: state.name,
            gst_state_code: state.gst_state_string,
          });
        });
        stateOptions.sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
        );
        this.setState({ stateOptions });
      },
    });
  };
  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { vendorDetails } = this.props;
    const { SAPDetails } = this.state;
    if (prevProps.vendorDetails !== vendorDetails) {
      if (!!vendorDetails && !!vendorDetails[0]) {
        this.setState({
          vendorDetailsForSapForm: vendorDetails[0],
          SAPDetails: {
            ...SAPDetails,
            paymentTerms: vendorDetails[0].paymentTerms,
            currencyOrder: vendorDetails[0].currencyOrder,
            incoTerm1: vendorDetails[0].incoTerm1,
            paymentMethod: vendorDetails[0].paymentMethod,
          },
        });
      }
    }
    const { vendorPaymentDetails } = this.props;
    if (prevProps.vendorPaymentDetails !== vendorPaymentDetails) {
      if (!!vendorPaymentDetails && !!vendorPaymentDetails[0]) {
        this.setState({
          vendorDetailsForPaymentTerms: vendorPaymentDetails[0],
        });
      }
    }
    const { vendorCurrencyDetails } = this.props;
    if (prevProps.vendorCurrencyDetails !== vendorCurrencyDetails) {
      if (!!vendorCurrencyDetails && !!vendorCurrencyDetails[0]) {
        this.setState({ vendorCurrencyDetails: vendorCurrencyDetails[0] });
      }
    }
  }

  isFalsyValue = (value) => {
    if (value === null) {
      return true;
    } else if (value === undefined) {
      return true;
    } else if (value === "") {
      return true;
    } else if (value.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  handleOnChangeSapDeatails = (event) => {
    const { name, value } = event.target;
    const { vendorDetailsForSapForm } = this.state;
    let updateSAPDetails = { ...vendorDetailsForSapForm, [name]: value };

    this.setState({ vendorDetailsForSapForm: updateSAPDetails }, () => {
      // const isFormValid = this.areFieldsValid();
      // this.setState({ isFormValid });
    });
  };

  validateFields = () => {
    const {
      vendor_company_name,
      email,
      contact_no,
      pan_no,
      head_office_pincode,
      head_office_state,
      head_office_city,
      head_office_address,
      head_office_number,
      currencyOrder,
      incoTerm1,
      paymentTerms,
      vendorAcctGroup,
      companyCode,
      purchaseOrganisation,
      reconAccount,
      paymentMethod,
     
 } = this.state.vendorDetailsForSapForm;
 const { state, gstin, gst_percentage} = this.state.vendorDetailsForSapForm.gstDetails[0];
 const gstPercentageString = String(gst_percentage);
 const gstinLengthValid = gstin.trim().length === 15;
 
const requiredFieldsFilled =
      vendor_company_name.trim() !== "" &&
      email.trim() !== "" &&
      contact_no.trim() !== "" &&
      pan_no.trim() !== "" &&
      head_office_pincode.trim() !== "" &&
      head_office_state.trim() !== "" &&
      head_office_city.trim() !== "" &&
      head_office_address.trim() !== "" &&
      head_office_number.trim() !== "" &&
      currencyOrder.trim() !== "" &&
      incoTerm1.trim() !== "" &&
      paymentTerms.trim() !== "" &&
      vendorAcctGroup.trim() !== "" &&
      companyCode.trim() !== "" &&
      purchaseOrganisation.trim() !== "" &&
      reconAccount.trim() !== "" &&
      paymentMethod.trim() !== "" &&
      state.trim() !== "" &&
      gstin.trim() !== "" &&
      gstPercentageString.trim() !== ""&&
      gstinLengthValid
    return requiredFieldsFilled ;
  };

  handleClickSend = () => {
    const { dispatch,match } = this.props;
    const{vendorDetailsForSapForm}=this.state;
    const payload = { ob_vendor_id: vendorDetailsForSapForm.ob_vendor_id };
 
    const onSuccess = () => {
      let queryString = `?vendorId=${vendorDetailsForSapForm.ob_vendor_id}`;
      dispatch({
        type: ACTION.VENDORS.GET_VENDOR_DETAILS_FOR_SAP,
        queryString,
      });
    };
    dispatch({
      type: ACTION.VENDORS.POST_SEND_SAP_DEATAILS,
      data: payload,
      onSuccess,
    });
  };

  handleCancelClick = () => {
    const { history } = this.props;
    console.log("history:", history);

    if (history) {
      history.goBack();
    }
  };

  isSaveDisable = () => {
    const {
      regionalOfficeList,
      gstin_details,
      data,
      vendorDetailsForSapForm,
      SAPDetails,
    } = this.state;
    let C1 =
      !!vendorDetailsForSapForm && !!vendorDetailsForSapForm.sap_vendor_code;
    if (C1) {
      return true;
    }

    let C3 =
      !!vendorDetailsForSapForm && !!vendorDetailsForSapForm.save_SAP_form;

    if (C3) {
      return true;
    }  
    return false;
  };
  isSendDisable = () => {
    const { vendorDetailsForSapForm } = this.state;
    let C1 =
      !!vendorDetailsForSapForm && !!vendorDetailsForSapForm.sap_vendor_code;
    if (C1) {
      return true;
    }

    let C2 =
      !!vendorDetailsForSapForm && !!vendorDetailsForSapForm.save_SAP_form;

    if (C2) {
      return false;
    }
  let C3 = !!vendorDetailsForSapForm.sendSapDetailsSuccess;

  if (C3) {
    return false;
  }

    return true;
  };

  closeMsgPopup = () => {
    const { dispatch } = this.props;
    dispatch({ type: ACTION.MSG_DISPLAY.HIDE_MSG });
  };

 handleOnChangeVendorDetailsForSapForm = (event) => {
    const { name, value } = event.target;
    const { vendorDetailsForSapForm } = this.state;
  
    if (name === 'pan_no') {
      const alphaRegex = /^[A-Za-z]{0,5}$/;
      const numericRegex = /^[0-9]{0,4}$/;
  
      const alphaPart = value.slice(0, 5);
      const numericPart = value.slice(5, 9);
      const lastAlpha = value.slice(9);
  
      if (alphaRegex.test(alphaPart) && numericRegex.test(numericPart) && alphaRegex.test(lastAlpha)) {
        const cleanedValue = alphaPart.toUpperCase() + numericPart + lastAlpha.toUpperCase();
        vendorDetailsForSapForm[name] = cleanedValue;
        this.setState({ vendorDetailsForSapForm, panError: '' });
      } else {
        vendorDetailsForSapForm[name] = value;
        this.setState({ vendorDetailsForSapForm, panError: 'Invalid PAN No.', emailError: '' });
      }
    } else if (name === 'email') {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
      if (emailRegex.test(value)) {
        vendorDetailsForSapForm[name] = value;
        this.setState({ vendorDetailsForSapForm, emailError: '' });
      } else {
        vendorDetailsForSapForm[name] = value;
        this.setState({ vendorDetailsForSapForm, emailError: 'Invalid email address', panError: '' });
      }
    } else {
      const updatedVendorDetailsForSapForm = {
        ...vendorDetailsForSapForm,
        [name]: value,
      };
      this.setState({ vendorDetailsForSapForm: updatedVendorDetailsForSapForm, panError: '', emailError: '' });
    }
  };

  
  handleOnChangeRegionalOfficeList = (event) => {
    const { name, value } = event.target;
    const { vendorDetailsForSapForm } = this.state;
    const updatedRegionalOfficeList = {
      ...vendorDetailsForSapForm,
      [name]: value,
    };

   this.setState({ vendorDetailsForSapForm: updatedRegionalOfficeList });
 };



  handleOnChangeBankDeatails = (event) => {
    const { name, value } = event.target;
    const { vendorDetailsForSapForm } = this.state;
    const updateBankDeatailsList = {
      ...vendorDetailsForSapForm,
      [name]: value,
    };
    this.setState({ vendorDetailsForSapForm: updateBankDeatailsList });
  };

  handleInputChange = (value, index, name) => {
    const { vendorDetailsForSapForm } = this.state;
    let updateVendorDetailsForSapForm = {...vendorDetailsForSapForm}
    const updatedGstDetails = [...vendorDetailsForSapForm.gstDetails];
    if (name === "state" ) {
      updatedGstDetails[index] = {
        ...updatedGstDetails[index],
        [name]: value.value,
        gstin: `${value.gst_state_code}${vendorDetailsForSapForm.pan_no}1Z`,
      };
    } else if( name === "gstin"){
      updatedGstDetails[index][name] =value
    }else if(name === "gst_percentage"){
      

      updatedGstDetails[index] = {
        ...updatedGstDetails[index],
        [name]: value.label.replace("%", ""),
      };
    }

    updateVendorDetailsForSapForm.gstDetails=updatedGstDetails
    this.setState({vendorDetailsForSapForm:updateVendorDetailsForSapForm});
  };

  // handleAddRow = () => {
  //   this.setState((prevState) => ({
  //     gstDetails: [
  //       ...prevState.gstdetails,
  //       { state: "", gstin: "", gst_percentage: "" },
  //     ],
  //   }));
  // };
  handleAddRow = () => {
    this.setState((prevState) => ({
      vendorDetailsForSapForm: {
        ...prevState.vendorDetailsForSapForm,
        gstDetails: [
          ...prevState.vendorDetailsForSapForm.gstDetails,
          { state: "", gstin: "", gst_percentage: "" },
        ],
      },
    }));
  };

  handleDeleteRow = (index) => {
    this.setState((prevState) => ({
      vendorDetailsForSapForm: {
        ...prevState.vendorDetailsForSapForm,
        gstDetails: prevState.vendorDetailsForSapForm.gstDetails.filter(
          (_, i) => i !== index
        ),
      },
    }));
  };
  

  getBankDetailsFromIFSCCode = () => {
    const { vendorDetailsForSapForm } = this.state;
    const { dispatch } = this.props;
    dispatch({
      type: ACTION.VENDORS.GET_BANK_DETAILS_FROM_IFSC,
      params: vendorDetailsForSapForm.IFSC_code,
      onSuccess: (bankDetails) => {
        const { data } = this.state;
        if (bankDetails) {
          vendorDetailsForSapForm.bank_name = bankDetails.BANK;
          vendorDetailsForSapForm.branch_name = bankDetails.BRANCH;
        }
        this.setState({ vendorDetailsForSapForm });
      },
    });
  };

  
  handleSaveExworksDetails = () => {
    const {vendorDetailsForSapForm}= this.state;
    const { dispatch, match } = this.props;
    const company_id = match.params.companyId
    let payload ={... this.state.vendorDetailsForSapForm,
      
       company_id,

     }
   dispatch({
      type: ACTION.VENDORS.POST_EXWORKS_DEATAILS, 
      payload,
     });
     vendorDetailsForSapForm.sendSapDetailsSuccess = true;
      this.setState({ vendorDetailsForSapForm });


        if (history) {
            history.back();
       
    }
  };

getStateAndCityFromPin = (pin, value) => {
  const { vendorDetailsForSapForm } = this.state;
  this.props.dispatch({
    type: ACTIONS.VENDORS.GET_STATE_AND_CITY_FROM_PIN_CODE,
    data: pin,
    onSuccess: (pincodeData) => {
      if (pincodeData && pincodeData[0].Status === 'Success') {
        vendorDetailsForSapForm.head_office_pincode = pin;
        vendorDetailsForSapForm.head_office_state = pincodeData[0].PostOffice[0].State;
        vendorDetailsForSapForm.head_office_city = pincodeData[0].PostOffice[0].District;

        this.setState({ vendorDetailsForSapForm });
      }
    }
  });
};


handleOnChangeBranchOfficeList = (event, fieldName) => {
  const newValue = event.target.value;
  this.setState(prevState => ({
    vendorDetailsForSapForm: {
      ...prevState.vendorDetailsForSapForm,
      [fieldName]: newValue
    }
  }));
};

onChangeIncoterm = (option) => {
  const { vendorDetailsForSapForm } = this.state;
  vendorDetailsForSapForm.incoTerm1 = option.short_name;
  
  this.setState({ vendorDetailsForSapForm });
  console.log("incoterm",vendorDetailsForSapForm.incoTerm1)
};

onChangepaymentTerms = (option) => {
  const { vendorDetailsForSapForm } = this.state;
  vendorDetailsForSapForm.paymentTerms = option.short_name;
  
  this.setState({ vendorDetailsForSapForm });
  console.log("paymentTerms",vendorDetailsForSapForm.paymentTerms)
};


  render() {
     const isButtonEnabled = this.validateFields();
    const { vendorDetails, vendorCurrencyDetails,incotermsOptions,vendorPaymentDetails } = this.props;
    console.log('incotermsOptions:', incotermsOptions)
    const {data,vendorDetailsForSapForm,SAPDetails,isFormValid,showSapVendorCode, stateOptions, emailError,gstin_details} = this.state;
    const {incoterm1}=this.state.vendorDetailsForSapForm
    const incoterms =
      (incotermsOptions || []).map((data) => ({
        label: data.name,
        value: data.id,
        short_name: data.short_name,
      })) || [];
      const paymentMethod =
      (vendorPaymentDetails || []).map((data) => ({
        label: data.name,
        value: data.id,
        short_name: data.short_name,
      })) || [];
      
    return (
      <div className="form-container">
        {this.props.loader.isLoading && <Loader />}
        {this.props.msgpopup.active && (
          <MsgPopup
            close={this.closeMsgPopup}
            type={this.props.msgpopup.type}
            msg={this.props.msgpopup.msg}
          />
        )}
        <div style={{ padding: "20px" }}>
          <TopPanel newVendor />
        </div>
        <div style={{ padding: "7px" }}> </div>
        <div className="heading">Onboard Ex work Vendor</div>
        <div className="container">
          <div className="container-heading">Company Details</div>
          <div className="form-section">
            <div className="form-item">
              <label className="labelname" htmlFor="input1">
                Company Name (Vendor Name/Transporter/LSP)
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                className="common"
                type="text"
                id="company_name"
                placeholder="Company Name"
                name="vendor_company_name"
                value={
                  this.isFalsyValue(vendorDetailsForSapForm.vendor_company_name)
                    ? ""
                    : vendorDetailsForSapForm.vendor_company_name
                }
                onChange={(event) => {
                  this.handleOnChangeVendorDetailsForSapForm(event);
                }}
              />
            </div>

            <div className="form-item">
              <label htmlFor="input2">
                Email<span style={{ color: "red" }}>*</span>
              </label>
              <input
             
        className="common"
        type="text"
        id="email"
        placeholder="Email"
        name="email"
        value={
          this.isFalsyValue(vendorDetailsForSapForm.email)
            ? ""
            : vendorDetailsForSapForm.email
        }
        onChange={(event) => {
          this.handleOnChangeVendorDetailsForSapForm(event);
        }}
        required
      />
      {emailError && <span className="error">{emailError}</span>}
            </div>

            <div className="form-item">
              <label htmlFor="input3">
                Contact Number<span style={{ color: "red" }}>*</span>
              </label>
              <input
                className="common"
                type="text"
                id="contact_number"
                placeholder="contact number"
                name="contact_no"
                value={
                  this.isFalsyValue(vendorDetailsForSapForm.contact_no)
                    ? ""
                    : vendorDetailsForSapForm.contact_no
                }
                onChange={(event) => {
                  this.handleOnChangeVendorDetailsForSapForm(event);
                }}
                onInput={(event) => {
                  event.target.value = event.target.value.replace(/\D/g, ''); 
                  this.handleOnChangeVendorDetailsForSapForm(event);
                }}
                maxLength={10}
              />
            </div>

            <div className="form-item">
              <label htmlFor="input4">
                PAN Number<span style={{ color: "red" }}>*</span>
              </label>
              <input
               className="common"
               type="text"
               id="pan_number"
               placeholder="PAN Number"
               name="pan_no"
               value={
                this.isFalsyValue(vendorDetailsForSapForm.pan_no)
                  ? ""
               : vendorDetailsForSapForm.pan_no
               }
              onChange={this.handleOnChangeVendorDetailsForSapForm}
              maxLength={10}
              />
             {this.state.panError && (
             <p style={{ color: 'red', fontSize: '13px' }}>{this.state.panError}</p>
             )}
            </div>
          </div>
        </div>
         <div className="container">
          <div className="container-heading"> Head Office Details</div>
         
              <Fragment>
                <div className="form-section">

                <div className="form-item">
                    <label htmlFor="input4">
                      Pincode<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="common"
                      type="text"
                      id="pincode"
                      placeholder="Pincode"
                      name="head_office_pincode"
                      value={
                        this.isFalsyValue(vendorDetailsForSapForm.head_office_pincode)
                          ? ""
                          : vendorDetailsForSapForm.head_office_pincode
                      }
                     
                      onChange={(e) => {
                        const { vendorDetailsForSapForm } = this.state;
                        if (e.target.value.length > 6) {
                           return
                        }
                        else {
                           if (e.target.value != 0) {
                              const regEx = /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/;
                              const check = e.target.value.match(regEx);
                              if (!check) {
                                 return
                              }
                           }
                          vendorDetailsForSapForm.head_office_pincode = e.target.value;
                           this.getStateAndCityFromPin(e.target.value)
                           this.setState({ vendorDetailsForSapForm });
                        }
                     }}
                      
                    />
                  </div>
               
                 <div>
                 <div className="black dropdownT1">State<span className='error'>*</span>
                 
                     <SelectT1
                      value={
                       (this.state.stateOptions || []).find(
                       obj => vendorDetailsForSapForm.head_office_state === obj.label)
                            }
                      options={(this.state.stateOptions || [])}
                      onChange={(selectedOption) => {
                      const { vendorDetailsForSapForm } = this.state;

                       this.setState({
                          vendorDetailsForSapForm: {
                            ...vendorDetailsForSapForm,
                        head_office_state: selectedOption.label
                                                   }
                                    });
                                   }}
                      placeholder={'State'}
                      className='regional-details-vendor-select'
                      styles={{ height: '50px', width: '0px' }}
                      />
                     </div>
                 </div>
                                               
                                            
                  <div className="form-item" id="CityheadOffice">
                    <label htmlFor="input3">
                      City<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="common"
                      type="text"
                      id="input3"
                      placeholder="city"
                      name="head_office_city"
                      value={
                      (vendorDetailsForSapForm.head_office_city)
                       
                      }
                   
                    //  onChange={(selectedOption) => {
                    //   const { vendorDetailsForSapForm } = this.state;
                  
                    //   this.setState({
                    //     vendorDetailsForSapForm: {
                    //       ...vendorDetailsForSapForm,
                    //       head_office_statehead_office_city: selectedOption.label
                    //     }
                    //   });
                    // }}
                    onChange={(e) => {
                      const { vendorDetailsForSapForm } = this.state;
                      vendorDetailsForSapForm.head_office_city = e.target.value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}0-9]/g, '');
                      this.setState({
                            vendorDetailsForSapForm: {
                              ...vendorDetailsForSapForm,
                              head_office_statehead_office_city:  e.target.value
                            }
                          });
                   }}
                     
                    />
                  </div>


                  <div className="form-item" id="addressheadOffice">
                    <label className="labelname" htmlFor="input1">
                      Address<span style={{ color: "red" }}>*</span>
                    </label>

                    <input
                      className="common"
                      type="text"
                      id="address"
                      placeholder="Address"
                      name="head_office_address"
                      value={
                      this.isFalsyValue(vendorDetailsForSapForm.head_office_address)
                        ? ""
                        : vendorDetailsForSapForm.head_office_address
                       }
                     
                      onChange={(event) =>
                        this.handleOnChangeBranchOfficeList(event, 'head_office_address')
                      }
                    />
                  </div>

                
                  <div className="form-item" id="ContactheadOffice">
                    <label htmlFor="input5">
                      Contact Number<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="common"
                      type="text"
                      id="contact_number"
                      placeholder="Contact Number"
                      name="head_office_number"
                      value={
                        this.isFalsyValue(vendorDetailsForSapForm.head_office_number)
                          ? ""
                          : vendorDetailsForSapForm.head_office_number
                      }
                      onChange={(event) =>
                        this.handleOnChangeBranchOfficeList(event, 'head_office_number')
                      }
                      onInput={(event) => {
                        event.target.value = event.target.value.replace(/\D/g, ''); 
                        this.handleOnChangeVendorDetailsForSapForm(event);
                      }}
                      maxLength={10}
                      
                    />
                  </div>
                </div>
              </Fragment>
           
        </div> 

         <div className="GST-div">
          <div className="GST-div-top">
            <div className="GST-div-heading">GST Details</div>
            <div className="GST-div-inp"></div>
          </div>
          <hr className="GST-div-hr"></hr>
          <div className="GST-div-bot">
            <table className="GST-table">
              <thead className="GST-table-head">
                <tr>
                  <th>
                    State<span style={{ color: "red"  }}>*</span>
                  </th>
                  <th>
                    GSTIN<span style={{ color: "red" }}>*</span>
                  </th>
                  <th>
                    GST Percentage<span style={{ color: "red" }}>*</span>
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody className="GST-table-body">
                {this.state.vendorDetailsForSapForm.gstDetails.map((eleROA, indOfROA) => (
                  <tr key={indOfROA}>
                    <td>
                      <SelectT1
                        value={(this.state.stateOptions || []).find(
                          (ele) => ele.value === eleROA.state
                        )} 
                        options={this.state.stateOptions || []}
                        onChange={(value) => {
                          this.handleInputChange(value, indOfROA, "state");
                        }}
                        placeholder={"State"}
                        className="gst-table-details-vendor-select"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="gstin"
                        value={eleROA.gstin}
                        onChange={(event) => {
                          const { value } = event.target;
                          this.handleInputChange(value, indOfROA, "gstin");
                        }}
                        maxLength={15}
                      />
                      {(eleROA.gstin).length!=15?<div className="error">Invalid GST Number</div>:null}
                    </td>
                    <td>
                    <SelectT1
                        value={[
                          { label: "5", value: 1 },
                          { label: "12", value: 2 },
                          { label: "18", value: 3 },
                        ].find((ele) => ele.value === eleROA.gst_percentage)} //{label: transporter, value: transporter}}
                        options={[
                          { label: "5", value: 1 },
                          { label: "12", value: 2 },
                          { label: "18", value: 3 },
                        ]}
                       onChange={(selectedOption) => {
                          this.handleInputChange(
                            selectedOption,
                            indOfROA,
                            "gst_percentage"
                          );
                        }}
                        placeholder={"GST %"}
                        className="gst-table-details-vendor-select"
                        style={{ height: "50px" }}
                      />
                    </td>
                    <td>
                      {indOfROA === this.state.vendorDetailsForSapForm.gstDetails.length - 1 ? (
                        <>
                        <button onClick={this.handleAddRow}>+</button> &nbsp;
                        {indOfROA > 0 &&
                        <button onClick={() => this.handleDeleteRow(indOfROA)}>x</button>}
                        </>
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="container">
          <div className="container-heading">Bank Details</div>

          <Fragment>
            <div className="form-section" style={{ display: "flex" }}>
              <div className="form-item">
                <label htmlFor="input4">
                  IFSC Code
                </label>
               <input
                  type="text"
                  placeholder="IFSC code"
                  // className="bank-new-vendor-input"
                  className="common"
                  value={vendorDetailsForSapForm.IFSC_code}
                  onChange={(e) => {
                    const { vendorDetailsForSapForm } = this.state;
                    if (e.target.value.length > 12) {
                      return;
                    } else {
                      vendorDetailsForSapForm.IFSC_code = e.target.value
                        .replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g, "")
                        .toUpperCase();
                      this.setState({ vendorDetailsForSapForm }, () => {
                        this.getBankDetailsFromIFSCCode();
                      });
                    }
                  }}
                />
              </div>
              <div className="form-item">
                <label className="labelname" htmlFor="input1">
                  Bank Name
                </label>
               <input
                  type="text"
                  placeholder="Bank Name"
                  // className="bank-new-vendor-input"
                  className="common"
                  value={vendorDetailsForSapForm.bank_name}
                  onChange={(e) => {
                    const { vendorDetailsForSapForm } = this.state;
                    vendorDetailsForSapForm.bank_name = e.target.value.replace(
                      /[&\/\\#,+()$~%.@!^&()-/'":*?<>{}0-9]/g,
                      ""
                    );
                    this.setState({ vendorDetailsForSapForm });
                  }}
                />
              </div>

              <div className="form-item">
                <label htmlFor="input2">
                  Bank Address
                </label>
               <input
                  type="text"
                  placeholder="Branch Name"
                  className="common"
                  value={vendorDetailsForSapForm.branch_name}
                  onChange={(e) => {
                    const { vendorDetailsForSapForm } = this.state;
                    vendorDetailsForSapForm.branch_name = e.target.value.replace(
                      /[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g,
                      ""
                    );
                    this.setState({ vendorDetailsForSapForm });
                  }}
                />
              </div>

              <div className="form-item">
                <label htmlFor="input3">
                  Account Number
                </label>
                <input
                  className="common"
                  type="text"
                  id="account_number"
                  placeholder="Account Number"
                  name="account_number"
                  value={
                    this.isFalsyValue(vendorDetailsForSapForm.account_number)
                      ? ""
                      : vendorDetailsForSapForm.account_number
                  }
                  onChange={(event) => {
                    this.handleOnChangeBankDeatails(event);
                  }}
                  onKeyPress={(event) => {
                    const charCode = event.which ? event.which : event.keyCode;
                    if (charCode < 48 || charCode > 57) {
                      event.preventDefault();
                    }
                  }}
                  maxLength={16}
                />
              </div>
            </div>
          </Fragment>
        </div>

         <div className="SAPcontainer">
          <div className="container-heading">SAP Details</div>

          <div className="form-section">
            <div id="Acctlabel" className="form-item">
              <label className="labelname Acct" htmlFor="input1">
                Account Group<span style={{ color: "red" }}>*</span>
              </label>
              <input
                className="common"
                type="text"
                id="account_group"
                placeholder="Account Group"
                name="vendorAcctGroup"
                value={
                  this.isFalsyValue(vendorDetailsForSapForm.vendorAcctGroup)
                    ? ""
                    : vendorDetailsForSapForm.vendorAcctGroup
                }
                onChange={(event) => {
                  this.handleOnChangeSapDeatails(event);
                }}
              />
            </div>

            <div className="form-item">
              <label htmlFor="input3">
                Company Code<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="common"
                id="company_code"
                placeholder="Company Code"
                name="companyCode"
                value={
                  this.isFalsyValue(vendorDetailsForSapForm.companyCode)
                    ? ""
                    : vendorDetailsForSapForm.companyCode
                }
                onChange={(event) => {
                  this.handleOnChangeSapDeatails(event);
                }}
              />
            </div>

            <div className="form-item">
              <label htmlFor="input4">
                Recon Account<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                id="recon_account"
                placeholder="Recon Account"
                className="common"
                name="reconAccount"
                value={
                  this.isFalsyValue(vendorDetailsForSapForm.reconAccount)
                    ? ""
                    : vendorDetailsForSapForm.reconAccount
                }
                onChange={(event) => {
                  this.handleOnChangeSapDeatails(event);
                }}
              />
            </div>

            

            <div className="form-item">
              <label htmlFor="input6">
                Purchase Org<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                id="purchase_org"
                placeholder="Purchase Org"
                className="common"
                name="purchaseOrganisation"
                value={
                  this.isFalsyValue(vendorDetailsForSapForm.purchaseOrganisation)
                    ? ""
                    : vendorDetailsForSapForm.purchaseOrganisation
                }
                onChange={(event) => {
                  this.handleOnChangeSapDeatails(event);
                }}
              />
            </div>
          </div>
          <div className="form-section">
            <div className="form-item">
              <label htmlFor="input1">
                Order Currency<span style={{ color: "red" }}>*</span>
              </label>
              <select
                id="order_currency"
                name="currencyOrder"
                className="common"
                value={
                  this.isFalsyValue(vendorDetailsForSapForm.currencyOrder)
                    ? ""
                    : vendorDetailsForSapForm.currencyOrder
                }
                onChange={(event) => {
                  this.handleOnChangeSapDeatails(event);
                }}
              >
                <option value={""}>Please Select</option>
                {(Object.keys(vendorCurrencyDetails) || []).map((ele, ind) => (
                  <Fragment key={ind}>
                    <option value={ele}>{ele}</option>
                  </Fragment>
                ))}
              </select>
            </div>

            <div className="form-item">
              <label htmlFor="input2">
                Payment Terms<span style={{ color: "red" }}>*</span>
              </label>
              
              <Select
                    className="incotermsOptions"
                    options={paymentMethod}
                    value={paymentMethod.find((option) => option.short_name === vendorDetailsForSapForm.paymentTerms)}
                    onChange={(option) => this.onChangepaymentTerms(option)}
                    placeholder="Select Payment Method"
                  />
            </div>

            <div className="form-item">
              <label htmlFor="input3">
                Inco Terms<span style={{ color: "red" }}>*</span>
              </label>
              
               <Select
                    className="incotermsOptions"
                    options={incoterms}
                    value={incoterms.find((option) => option.short_name === vendorDetailsForSapForm.incoTerm1)}
                    onChange={(option) => this.onChangeIncoterm(option)}
                    selectedValue={incoterms.find((option) => option.name === "EXW")}
                    placeholder="Select Inco Terms"
                  />
            </div>

            <div className="form-item">
              <label htmlFor="input5">
                Payment Method<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                id="payment_method"
                placeholder="Payment Method"
                className="common"
                name="paymentMethod"
                value={
                  this.isFalsyValue(vendorDetailsForSapForm.paymentMethod)
                    ? ""
                    : vendorDetailsForSapForm.paymentMethod
                }
                onChange={(event) => {
                  this.handleOnChangeSapDeatails(event);
                }}
              />
            </div>

            {/* <div className="form-item">
              <label htmlFor="input3">
                sap vendor code
              </label>
              <input
                className="common"
                type="text"
                id="sap_vendor_code"
                placeholder="sap vendor code"
                value={vendorDetailsForSapForm.sap_vendor_code}
                readOnly
              />
            </div> */}
          </div>
        </div> 
        <div className="mb-25 mt-25 button">
          <button
            className="mb-25 button-classic mr-20"
            id="btn1"
            onClick={this.handleCancelClick}
            // disabled={isCancelButtonDisable}
          >
            {" "}
            Cancel{" "}
          </button>
        
          <button
            className={`mb-25 button-classic mr-20`}
            id="btn2"
            onClick={this.handleSaveExworksDetails}
             disabled={!isButtonEnabled}
          >
            Save
          </button>

          {/* <button
            className={`mb-25 button-classic mr-20`}
            id="btn"
            onClick={this.handleClickSend}
            disabled={this.isSendDisable()}
          >
            Send
          </button> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    vendorDetails: state.vendors.vendorDetails || [],
    vendorPaymentDetails: state.vendors.vendorPaymentDetails || [],
    vendorCurrencyDetails: state.vendors.vendorCurrencyDetails || [],
    incotermsOptions: state.vendors.incotermsOptions || [],
    loader: state.loader,
    msgpopup: state.msgpopup,
  };
};

export default connect(mapStateToProps)(withRouter(SapVendorFormEX));
