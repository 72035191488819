import { put, call } from "redux-saga/effects";
import ACTIONS from "../../common/action_constant";
import API from '../../common/api_config'
import axiosT1 from "../../../utils/axios";
import { deleteData, getData } from "../../../utils/ajax";


export function* getBannerList(action) {
  try {
    const resp = yield call(
      axiosT1.get,
      API.getBannerListUrl,
      {
        params: action.queryParams
      });

      if(action.onSuccess){
        const bannerMessagesList = resp.data.data.messages || []
        action.onSuccess(bannerMessagesList)
      }

  } catch (e) {
    console.log('e:', e)
  }
}

export function* deleteBannerMessage(action) {
	try {
	  yield put({
		type: ACTIONS.LOADER.SHOW_LOADER,
	  });
  
	  const data = yield call(
		deleteData,
		API.deleteBannerMessageUrl + action.queryString
		
	  );
	  if (action.onSuccess) {
		action.onSuccess(data);
	  }
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER,
	  });
  
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: {
		  type: 1,
		  msg:
			(data && data.message) ||
			"Volumetric Record Deleted Successfully",
		},
	  });
	} catch (e) {
	  yield put({
		type: ACTIONS.LOADER.HIDE_LOADER,
	  });
	  yield put({
		type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
		data: {
		  type: 2,
		  msg: e && e.message ? e.message : "Oops! Something went wrong.",
		},
	  });
	}
}


export function* createBannerMessage(action) {
	try {
			const { data } = yield call(
					axiosT1.post,
					API.createBannerMessageUrl,
					action.payload,
			);

			if (action.onSuccess) {
					action.onSuccess();
			}

			yield put({
					type: TOAST_SUCCESS,
					message: data && data.message ? data.message : "Successful",
				});


	} catch (e) {

	}
}

export function* updateBannerMessage(action) {
	try {
	 const data  =  yield call(
		axiosT1.put, 
		API.updateBannerMessageUrl+action.queryString,
		action.payload,
		);
		if(action.onSuccess){
			action.onSuccess()
		}
	 const message = data.data.message || ""
	 yield put({
			type: TOAST_SUCCESS,
			message: !!message?message:"Data updated successfully!!"
	});

		

	} catch (e) {
		
	}
}

export function* getBroadcastMsg(action) {
  try {
      const data = yield call(getData, API.getBroadCastMessageUrl+action.params);
      if (data && data.data && data.data.messages) {
          yield put({
              type: ACTIONS.BANNER_SETTING.STORE_BROADCAST_MSG,
              broadcastMsgs: data.data.messages
          });
      }
  } catch(e) {
      console.log('ERROR', e);
  }
}
