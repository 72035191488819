import React, { Component} from 'react';
import {connect} from 'react-redux';
import "./sub-navigation-panel.less";

class SubNavigationPanel extends Component {
	constructor(props){
		super(props);
		this.state = {
			subNavOptions : [{
				heading : "Vehicle Info",
				id : "1"
			},{
				heading : "Driver Info",
				id : "2"
			}],
			selectedOption : "1",
		}
	}


	setOption = (option) => {
		this.setState({
		  selectedOption: option
		});
		this.props.selectedSubTab(option);
	  };

	componentDidMount(){
		const { dispatch } = this.props;
		// this.props.selectedSubTab(this.state.selectedOption);
	}

	render() {
		return (
			<></>
		);
	}
}
  
export default SubNavigationPanel;