import React, { Component } from "react";
import Search from "../../common/components/search-text/search-text";
import Button from "../../home/button-classic/button-classic";
import { ToastContainer, toast } from 'react-toastify';
import { connect } from "react-redux";
import ACTIONS from "../../common/action_constant";
import { toPascalCase } from "../../../utils/validate";
import { DateRangePicker } from "react-dates";
import Delete from "../../../assets/images/delete.svg";
import Edit from "../../../assets/images/edit.svg";
import {
  AWL_COMPANY_IDS,
  convertEpochToNormalDate,
  downloadFile,
  getEpochFromDate,
  getEpochToDate,
  getOneMonthBeforeDate,
} from "../../../utils/common";
import { SingleDatePicker } from "react-dates";
import Moment from "moment";
import "./material-mrp.less";
import ACTION from "../../common/action_constant";
import LoaderComponent from "../../common/components/loader/loader";
import { withRouter } from "react-router-dom";
import axios from "axios";

var COMPANY_ID="999"

class MaterialMRP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
      offset: 0,
      limit: 10,
      fromMDate: null,
      toMDate: null,
      focusedInput: null,
      gridDataSource: [],
    };
  }

  componentDidMount = () => {
    const { searchString } = this.state;
    const currentDate = new Date();
    const oneMonthBeforeDate = getOneMonthBeforeDate(currentDate);

    const epochToDate = getEpochToDate(currentDate);
    const epochFromDate = getEpochFromDate(oneMonthBeforeDate);
    this.setState({ fromMDate: oneMonthBeforeDate, toMDate: currentDate });
    this.getMaterialMrpGridData(epochFromDate, epochToDate, searchString);
  };

  viewStateData = (stateData) => {
    this.setState({ stateData, isShowPopup: true });
  };

  getMaterialMrpGridData = (fromDate, toDate, searchString) => {
    const { dispatch } = this.props;
    const {limit,offset}=this.state
    const queryParams = {
      companyId: COMPANY_ID,
      fromDate: fromDate,
      toDate: toDate,
      //   fromDate: 1707772400000,
      //   toDate: 1707792400000,
      search: searchString,
      limit:limit,
      offset:offset
    };
    const onSuccess = (data = []) => {
      this.setState({ gridDataSource: data });
    };
    dispatch({
      type: ACTION.HOME.GET_MATERIALA_DETAILS,
      queryParams,
      onSuccess,
    });
  };

  downloadMRPTemplate = () => {
    const { dispatch } = this.props;
    const onSuccess = (data = []) => {
      toast.success("File Downloded successfully");
      downloadFile(data[0].Link || "");
    };
    dispatch({
      type: ACTION.HOME.DOWNLOAD_MRP_TEMPLATE,
      // queryParams,
      onSuccess,
    });
  };

  downloadMRPData = () => {
    const { dispatch } = this.props;
    const {searchString,fromMDate,toMDate}=this.state
    const epochToDate = getEpochToDate(toMDate);
    const epochFromDate = getEpochFromDate(fromMDate);
    const queryParams = {
      companyId: COMPANY_ID,
      fromDate: epochFromDate,
      toDate: epochToDate,
      // fromDate: 1707772400000,
      // toDate: 1707792400000,
      search: searchString,
    };
    const onSuccess = (data = []) => {
      toast.success("File Downloded successfully");
      downloadFile(data[0].Link || "");
    };
    dispatch({
      type: ACTION.HOME.DOWNLOAD_MRP_DATA,
      queryParams,
      onSuccess,
    });
  };

  uploadMRPData = (file) => {
    const { dispatch } = this.props;
    const { fromMDate, toMDate, searchString } = this.state;
    const onSuccess = (data) => {
      toast.success(data.message || "");
      const epochToDate = getEpochToDate(toMDate);
      const epochFromDate = getEpochFromDate(fromMDate);
      this.getMaterialMrpGridData(epochFromDate, epochToDate, searchString);
    };

    const onCatchError = (error) => {
      console.log(error.response.data,"error error error----")
      if(error.response.status===400){
        toast.error(error.response.data.message || "");
      }
      else{
        toast.error("Something went wrong");
      }
    };

    dispatch({
      type: ACTION.HOME.UPLOAD_MRP_DATA,
      file,
      onSuccess,
      onCatchError
    });
  };

  uploadMaterialConversion = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      this.uploadMRPData(formData);
    }
  };

  uploadMC = () => {
    this.uploadExcel.click();
  };

  clearFilterOnCancel = () => {
    const { fromMDate, toMDate, searchString } = this.state;
    this.setState({ searchString: "" }, () => {
      const epochToDate = getEpochToDate(toMDate);
      const epochFromDate = getEpochFromDate(fromMDate);
      const search="";
      this.getMaterialMrpGridData(epochFromDate, epochToDate, search);
    });
  };

  onSearch = () => {
    const { fromMDate, toMDate, searchString } = this.state;
    const epochToDate = getEpochToDate(toMDate);
    const epochFromDate = getEpochFromDate(fromMDate);
    this.getMaterialMrpGridData(epochFromDate, epochToDate, searchString);
  };

  onSearchChangeHandler = (event) => {
    this.setState({ searchString: event });
  };

  previous = () => {
    const{fromMDate,toMDate,searchString}=this.state
    if (this.state.offset > 0) {
        this.setState({
            offset: this.state.offset - 10
        }, () => {
          const epochToDate = getEpochToDate(toMDate);
          const epochFromDate = getEpochFromDate(fromMDate);
          this.getMaterialMrpGridData(epochFromDate,epochToDate,searchString)
        });
    }
}
next = () => {
  const{fromMDate,toMDate,searchString}=this.state
    this.setState({
        offset: this.state.offset + 10
    }, () => {
      const epochToDate = getEpochToDate(toMDate);
      const epochFromDate = getEpochFromDate(fromMDate);
        this.getMaterialMrpGridData(epochFromDate,epochToDate,searchString)
    });
}

isOutsideRange = (day) => {
  const currentDate = Moment();
  return day.isAfter(currentDate, 'day');
};

handleOnClickForAuditBtn = () => {
  const {history, company_id} = this.props;
  const masterType = 22
  const navUrl = `/auditScreen/${company_id}/${masterType}`;
  history.push(navUrl)
}

  render() {
    const {
      data,
      isShowPopup,
      stateData,
      gridDataSource,
      fromMDate,
      toMDate,
      searchString,
    } = this.state;
    const { loader } = this.props;
    return (
      <div className={"location-network"}>
        <ToastContainer />
        {!!loader.loaderT1 && <LoaderComponent />}
        <div className="material-mrp-main">
          <div style={{ display: "flex", paddingBottom: "15px" }}>
            <Search
              id="search"
              value={searchString}
              placeholder="Search..."
              changeHandler={this.onSearchChangeHandler}
              click={this.onSearch}
              canClear={searchString !== ""}
              handleClearFilter={this.clearFilterOnCancel}
              className="material-search"
            />
            <div className="date">
              <div className="label">Date</div>
              <DateRangePicker
                startDate={fromMDate ? Moment(+this.state.fromMDate) : null}
                startDateId="startDate"
                endDate={toMDate ? Moment(+this.state.toMDate) : null}
                endDateId="endDate"
                startDatePlaceholderText="00/00/0000"
                endDatePlaceholderText="00/00/0000"
                onDatesChange={({ startDate, endDate }) => {
                  let { fromMDate, toMDate, searchString } = this.state;
                  fromMDate = startDate ? startDate.valueOf() : fromMDate;
                  toMDate = endDate ? endDate.valueOf() : toMDate;
                  this.setState({ fromMDate, toMDate }, () => {
                    const epochToDate = getEpochToDate(toMDate);
                    const epochFromDate = getEpochFromDate(fromMDate);
                    this.getMaterialMrpGridData(
                      epochFromDate,
                      epochToDate,
                      searchString
                    );
                  });
                }}
                focusedInput={this.state.focusedInput}
                onFocusChange={(focusedInput) =>
                  this.setState({ focusedInput })
                }
                displayFormat={"DD/MM/YYYY"}
                noBorder={true}
                minimumNights={0}
                isOutsideRange={()=>false}
              //  isOutsideRange={this.isOutsideRange}
              />
            </div>
            <Button
              value={"Download Temp."}
              // click={() => this.downloadTemp(true)}
              click={this.downloadMRPTemplate}
              className="mt-20"
            />
            <a
              href={this.state.downloadUrl}
              style={{ display: "none" }}
              ref={(e) => {
                this.downloadLink = e;
              }}
            />
            <Button
              value={"Download Details"}
              // click={(fromMDate, toMDate, searchString) =>
              //   this.downloadMRPData(fromMDate, toMDate, searchString)
              // }
              click={this.downloadMRPData}
              className="mt-20"
            />
            <a
              href={this.state.downloadUrl}
              style={{ display: "none" }}
              ref={(e) => {
                this.downloadLink = e;
              }}
            />
            <Button value={"Upload"} click={this.uploadMC} className="mt-20" />
            <input
              type="file"
              style={{ display: "none" }}
              ref={(e) => {
                this.uploadExcel = e;
              }}
              onChange={this.uploadMaterialConversion}
              onClick={(e) => {
                e.target.value = null;
              }}
              accept=".xlsx"
            />
          </div>
          <Button value={'Audit'} click={this.handleOnClickForAuditBtn} />
          <div className={"material-details-section"}>
            <div className={"list-section header"}>
              <div className={"tabel-header material"}>Material</div>
              <div className={"tabel-header"}>Material Code</div>
              <div className={"tabel-header"}>Effective Date</div>
              <div className={"tabel-header"}>Oil Type</div>
              <div className={"tabel-header"}>Oil Name</div>
              <div className={"tabel-header"}>All India MRP(Rs)</div>
              <div className={"tabel-header"}>Actions</div>
            </div>

            {(gridDataSource || []).map((mc, index) => {
              return (
                <div className={"list-section content"} key={`route_${index}`}>
                  <div className={"tabel-header material"}>
                    {mc.material_description}
                  </div>
                  <div className={"tabel-header materialCode"}>{mc.material_code}</div>
                  <div className={"tabel-header materialCode"}>{convertEpochToNormalDate(mc.effective_date)}</div>
                  <div className={"tabel-header"}>{mc.oil_name}</div>
                  <div className={"tabel-header"}>{mc.main_oil}</div>
                  <div className={"tabel-header"}>{mc.all_india_mrp}</div>
                  <div className={"tabel-header"}>
                    <Button
                      value={"View State Rate"}
                      click={() => this.viewStateData(mc.state_wise_rates)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="submit-section-pagination">
            <div className="next">
              <Button value="Next" 
              // disabled={Object.keys(gridDataSource || {}).length <= 0 || (cityMasterData.tat_routes_count <= this.state.offset + this.state.limit)} 
              click={this.next} />
            </div>
            <div className="pre">
              <Button value="Previous" disabled={this.state.offset === 0} click={this.previous} />
            </div>
          </div>
        </div>
        {isShowPopup && (
          <div className="material-modal">
            <div className="route_code_detail">
              <div className="overlay"></div>
              <div id="modal" className="modal-popup modal-pad">
                <div className="pop-heading">
                  {"State Wise Material Details"}
                </div>
                <div className="route-stages-section point-of-contact">
                  <table className="routestages-table">
                    <thead>
                      <th>State</th>
                      <th>MRP(Rs)</th>
                    </thead>
                    <tbody>
                      {stateData &&
                        stateData.map((val, index) => {
                          return (
                            <tr key={index}>
                              <td>{val && val.name}</td>
                              <td>{val && val.mrp}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className={"mt-10 mb-10 tCenter"}>
                  <Button
                    value={"Close"}
                    click={() =>
                      this.setState({ isShowPopup: false, stateData: "" })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loader: state.loader,
  };
};

export default connect(mapStateToProps)(withRouter(MaterialMRP));
// export default connect()(MaterialMRP)
