import React, { Component } from "react";
import InputText from "../../../../common/components/input-text/input-text";
import Select from "react-select";
import Button from "../../../../home/button-classic/button-classic";
import {typesOfUse,typeOfOperations} from "../../../../../utils/constants";
import { DEEPAK_COMPANY_ID } from "../../../../../utils/common";
import { getCookie } from "../../../../../utils/cookies.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "../truck.less";
import ACTION from "../../../../common/action_constant";
import {connect} from 'react-redux';

class AddItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        truck_id: this.props.data.truck_id,
        vehicle_reg_no: this.props.data.vehicle_reg_no,
        vehicle_size: this.props.data.vehicle_size,
        type_of_use: this.props.data.type_of_use,
        type_of_operation: this.props.data.type_of_operation,
        transporter: this.props.data.transporter,
        length: this.props.data.length,
        height: this.props.data.height,
        width: this.props.data.width,
        driver_name: this.props.data.driver_name,
        insurance_validity: this.props.data.insurance_validity,
        contact: this.props.data.contact,
        fitness_certificate_validity: this.props.data.fitness_certificate_validity,
        goods_permit_validity: this.props.data.goods_permit_validity,
        location: this.props.data.location,
        national_permit_validity: this.props.data.national_permit_validity,
        puc_validity: this.props.data.puc_validity,
        tax_validity: this.props.data.tax_validity,
        truck_age: this.props.data.truck_age,
        truck_registration_date:this.props.data.truck_registration_date,
        load_capacity: this.props.data.load_capacity,
        last_service_date: this.props.data.last_service_date,
        last_service_km: this.props.data.last_service_km ? this.props.data.last_service_km : "",
        next_service_due_date: this.props.data.next_service_due_date,
        next_service_due_km: this.props.data.next_service_due_km ? this.props.data.next_service_due_km : "",
        inhouse_maintenance_date: this.props.data.inhouse_maintenance_date,
        demand_maintenance_date: this.props.data.demand_maintenance_date,
        attachments: this.props.data.attachments,
        gps_details: this.props.data.gps_details
        // remarks:'',
      },
    };
  }
  componentDidMount() {
    this.props.getCompanyTransporters();
    this.props.getVehicleSize();
    // this.props.getTransporterDetails();
    this.getGpsVendorData()
  }

  getGpsVendorData = () => {
    const { dispatch } = this.props;
    dispatch({ type : ACTION.MASTER.VEHICLE_PLANNING.GET_GPS_VENDOR_DATA});
  }

  handleChange = (id, selectedOption) => {
    let { data } = this.state;
    if(id === 'gps_details'){
      data[id] = {id : selectedOption.value, name: selectedOption.label}
      this.setState({ data });
     }
     else{
      data[id] = selectedOption.label;
      if(id === "transporter"){
        data['transporter_id'] = selectedOption.value;
      }
      if(id === 'location'){
        data['branch_id'] = selectedOption.value;
      }
      this.setState({ data });
     }
  };

  onChangeHandler = (value, id) => {
    let { data } = this.state;
    if(['next_service_due_km', 'last_service_km'].includes(id)){
      data[id] = Number(value);
    }else{
      data[id] = value;
    }
    this.setState({ data });
  };

  SelectDate = (id, selectedOption) => {
    let { data } = this.state;
    data[id] = selectedOption;
    if(id === 'truck_registration_date'){
      this.calTruckAge(selectedOption)
    }
    this.setState({ data });
  };
  autoPop = (id, selectedOption) => {
    const { data } = this.state;
    data['contact'] = selectedOption.contactNo;
    data[id] = selectedOption.label;
    this.setState({ data });
  };
  calTruckAge=(date)=>{
    let { data } = this.state;
    let currentDate = new Date();
    let truckAge = new Date(date);
    let ageDiff = currentDate.getFullYear() - truckAge.getFullYear();
    let monthDiff = currentDate.getMonth() - truckAge.getMonth();
    if(monthDiff > 0){
      // monthDiff;
    } 
    else if(monthDiff === 0){
      // ageDiff;
    }
    else {
      ageDiff = ageDiff - 1;
      monthDiff = 12 + monthDiff;
    }
    data.truck_age = `${ageDiff}/${monthDiff}`;
    this.setState({data});
  }
  validateCheck = () => {
    const { data } = this.state;
    if(data.vehicle_reg_no && data.vehicle_size && data.height && data.width && data.length && data.load_capacity && data.transporter && data.driver_name && data.contact && data.gps_details){
      return true;
    } else return false;
  }
  checkExpiry = (date) => {
    const currentDate = new Date();
    var dateMomentObject = moment(date, "DD/MM/YYYY");
    var dateObject = dateMomentObject.toDate();
    var differenceInTime = currentDate.getTime() - dateObject.getTime();
    var differenceInDays = differenceInTime / (1000 * 3600 * 24);
    let colorCoding = '';
    if(differenceInDays < 0 && differenceInDays > -30){
      colorCoding = 'text-orange item-label';
    } else if(differenceInDays > 0){
     colorCoding = 'text-red item-label';
    }
    else {
     colorCoding = 'item-label';
    }
    return colorCoding;
  }
  onSave = () => {
    const { data } = this.state;
    if(this.validateCheck()){
      data.insurance_validity = data.insurance_validity ? data.insurance_validity.valueOf() : undefined;
      data.national_permit_validity = data.national_permit_validity ? data.national_permit_validity.valueOf() : undefined;
      data.fitness_certificate_validity = data.fitness_certificate_validity ? data.fitness_certificate_validity.valueOf() : undefined;
      data.puc_validity = data.puc_validity ? data.puc_validity.valueOf() : undefined;
      data.goods_permit_validity = data.goods_permit_validity ? data.goods_permit_validity.valueOf() : undefined;
      data.tax_validity = data.tax_validity ? data.tax_validity.valueOf() : undefined;
      data.last_service_date = data.last_service_date ? data.last_service_date.valueOf() : undefined;
      data.next_service_due_date = data.next_service_due_date ? data.next_service_due_date.valueOf() : undefined;
      data.inhouse_maintenance_date = data.inhouse_maintenance_date ? data.inhouse_maintenance_date.valueOf() : undefined;
      data.demand_maintenance_date = data.demand_maintenance_date ? data.demand_maintenance_date.valueOf() : undefined;
      data.truck_registration_date = data.truck_registration_date ? data.truck_registration_date.valueOf() : undefined;
      data.last_service_km = data.last_service_km ? data.last_service_km : undefined;
      data.next_service_due_km = data.next_service_due_km ? data.next_service_due_km : undefined;
      data.length = data.length !== "" ? Number(data.length.toFixed(2)) : data.length;
      data.width = data.width !== "" ? Number(data.width.toFixed(2)) : data.width;
      data.height = data.height !== "" ? Number(data.height.toFixed(2)) : data.height;
      data.load_capacity = data.load_capacity !== "" ? Number(data.load_capacity.toFixed(2)) : data.load_capacity;
      this.props.toggleAdd("Edit");
      this.props.updateTruckDetails(data);
    }else{
      this.setState({errorMsg: "Please add all mandatory fields"})
    }
  };

  onUpload = () => {
    this.uploadUrl.click();
  };

  uploadfiles = (e) => {
		const { dispatch } = this.props;
		let formData = new FormData();
		formData.append("file", e.target.files[0]);
		dispatch({
		  type: ACTION.DATA_GOVERNANCE.UPLOAD_VEHICLE_FIELS,
		  formData,
		  onSuccess: (details) => {
        let { data } = this.state;
        data.attachments.push(details)
			  this.setState({data})
		  }
		});
	};

  removeAttachment = (index) => {
    let { data } = this.state;
    data.attachments.splice(index, 1);
    this.setState({ data })
  }
  
  render() {

    let { data } = this.state;
    const { vehicleSize, allPlantBranches, driverData, companyTransporters, master } = this.props;
    const {gpsVendorData} = master
    const vehicleSizeListName =
      (vehicleSize || []).map((data) => ({
        label: data.name,
        value: data.id,
      })) || [];
    const companyTranspoList =
      (companyTransporters || []).map((data) => ({
        label: data.company_name,
        value: data.id,
      })) || [];
    const driverDataList =
      (driverData || []).map((data) => ({
        label: data.driver_name,
        value: data.driver_name,
        contactNo: data.contact,
      })) || [];
    const branchList = 
    (allPlantBranches || []).map((branch) => ({
      label: branch.branch_name,
      value: branch.branch_id
    })) || [];

    const gpsVendorList = 
    (gpsVendorData || []).map((data) => ({
      label: data.name,
      value: data.id
    })) || [];

    return (
      <div className="add-user-wrap">
        <div
          onClick={() => this.props.toggleAdd("Edit")}
          className="overlay"
        ></div>
        <div id="modal" className="modal-popup">
          <div className="pop-heading">Edit Truck Master</div>
          <div className="content add-item-details">
            <div className="select-wrap">
              <InputText
                label="Vehicle Reg. No."
                id="vehicle_reg_no"
                value={data.vehicle_reg_no}
                placeholder="Vehicle Reg. No."
                changeHandler={this.onChangeHandler}
                required
              />
            </div>
            <div className="select-wrap">
              <div className="label">Type Of Use</div>
              <Select
                className="item-select"
                value={typesOfUse.find((val) => {
                  return val.label == data.type_of_use;
                })}
                onChange={this.handleChange.bind(this, "type_of_use")}
                options={typesOfUse}
              />
            </div>

            <div className="select-wrap">
              <div className="label">Type Of Operation</div>
              <Select
                className="item-select"
                value={typeOfOperations.find((val) => {
                  return val.label == data.type_of_operation;
                })}
                onChange={this.handleChange.bind(this, "type_of_operation")}
                options={typeOfOperations}
              />
            </div>
            <div className="select-wrap">
              <div className="label">Transporter <span className={"error error-font"}>*</span></div>
              <Select
                className="item-select"
                value={companyTranspoList.find((val) => {
                  return val.label == data.transporter;
                })}
                options={companyTranspoList}
                onChange={this.handleChange.bind(this, "transporter")}
              />
            </div>
            <div className="select-wrap">
              <div className="label">Vehicle Size <span className={"error error-font"}>*</span></div>
              <Select
                className="item-select"
                value={vehicleSizeListName.find((val) => {
                  return val.label == data.vehicle_size;
                })}
                options={vehicleSizeListName}
                onChange={this.handleChange.bind(this, "vehicle_size")}
              />
            </div>
            <div className="select-wrap">
            <div className="data-picker">
              <div className="label1">Truck Registration Date</div>
              <DatePicker
                dateFormat="MM/yyyy"
                showMonthYearPicker
                value={data.truck_registration_date ? moment(data.truck_registration_date).format('MM/yyyy') : null}
                onChange={this.SelectDate.bind(
                  this,
                  "truck_registration_date"
                )}
                placeholderText={"MM/YYYY"}
                maxDate={new Date()}
              />
            </div>
          </div>
          <div className="select-wrap">
            <InputText
              label="Truck Age (Years/Months)"
              id="truck_age"
              value={data.truck_age}
              placeholder="Truck Age"
              readonly={true}
            />
          </div>
          <div className="select-wrap">
            <InputText
              type="number"
              label="Max Load Capacity(%)"
              min={1}
              max={100}
              id="load_capacity"
              value={data.load_capacity}
              placeholder="Max Load Capacity"
              changeHandler={(e) => {
                const { data } = this.state;
                data.load_capacity = (e <= 100 && e >= 0) ? Number(e) : Number(data.load_capacity);
                this.setState({data})
              }}
              required
            />
            </div>
            <div className="select-wrap">
            <InputText
              type="number"
              label="Length"
              id="length"
              value={data.length}
              placeholder="Length"
              changeHandler={(e) => {
                let { data } = this.state;
                const regex = /^[0-9.]+$/;
                if(regex.test(e) || e == ""){
                  data.length = (e == "" ? "" : Number(e))
                }
                this.setState({data});
                }
              }
              required
            />
            </div>
            <div className="select-wrap">
            <InputText
              type="number"
              label="Width"
              id="width"
              value={data.width}
              placeholder="Width"
              changeHandler={(e) => {
                let { data } = this.state;
                const regex = /^[0-9.]+$/
                if(regex.test(e) || e == ""){
                  data.width = (e == "" ? "" : Number(e))
                }
                this.setState({data});
                }
              }
              required
              />
              </div>
              <div className="select-wrap">
              <InputText
              type="number"
              label="Height"
              id="height"
              value={data.height}
              placeholder="Height"
              changeHandler={(e) => {
                let { data } = this.state;
                const regex = /^[0-9.]+$/
                if(regex.test(e) || e == ""){
                  data.height = (e == "" ? "" : Number(e))
                }
                this.setState({data});
                }
              }
              required
              />
              </div>
            <div className="select-wrap">
              <div className="label">Location</div>
              <Select
                className="item-select"
                options={branchList}
                value={branchList.find((val) => {
                  return val.label == data.location})}
                onChange={this.handleChange.bind(this, "location")}
              />
            </div>

            <div className="select-wrap">
              <div className="label">Gps Vendors <span className={"error error-font"}>*</span></div>
              <Select
                className="item-select"
                options={gpsVendorList}
                onChange={this.handleChange.bind(this, "gps_details")}
                value={gpsVendorList.find((val) => {
                  if(data.gps_details){
                    return val.label == data.gps_details.name
                  }
                })}
              />
            </div>

            <div className="pop-heading">Driver Details</div>
            <div className="select-wrap">
              <div className="label">Driver Name<span className={"error error-font"}>*</span></div>
              <Select
                className="item-select"
                value={driverDataList.find((val) => {
                  return val.label == data.driver_name})}
                options={driverDataList}
                onChange={this.autoPop.bind(this, "driver_name")}
              />
            </div>

            <InputText
              label="contact"
              id="contact"
              value={data.contact}
              placeholder=""
              required
            />

            <div className="pop-heading">Documentation Details</div>

            <div className="select-wrap">
              <div className="data-picker">
                <div className="label1">Insurance Exp Date</div>
                <DatePicker
                  label={"insurance_validity"}
                  dateFormat="dd/MM/yyyy"
                  value={data.insurance_validity ? moment(data.insurance_validity).format("DD/MM/YYYY") : null}
                  // selected={data.insurance_validity}
                  onChange={this.SelectDate.bind(this, "insurance_validity")}
                  className={this.checkExpiry(data.insurance_validity)}
                />
              </div>
            </div>
            <div className="select-wrap">
              <div className="data-picker">
                <div className="label1">National Permit Exp Date</div>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  value={data.national_permit_validity ? moment(data.national_permit_validity).format("DD/MM/YYYY") : null}
                  onChange={this.SelectDate.bind(
                    this,
                    "national_permit_validity"
                  )}
                  className={this.checkExpiry(data.national_permit_validity)}
                />
              </div>
            </div>
            <div className="select-wrap">
              <div className="data-picker">
                <div className="label">Fitness Certificate Exp Date</div>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  value={data.fitness_certificate_validity ? moment(data.fitness_certificate_validity).format("DD/MM/YYYY") : null}
                  onChange={this.SelectDate.bind(
                    this,
                    "fitness_certificate_validity"
                  )}
                  className={this.checkExpiry(data.fitness_certificate_validity)}
                />
              </div>
            </div>

            <div className="select-wrap">
              <div className="data-picker">
                <div className="label1">PUC Exp Date</div>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  value={data.puc_validity ? moment(data.puc_validity).format("DD/MM/YYYY") : null}
                  onChange={this.SelectDate.bind(this, "puc_validity")}
                  className={this.checkExpiry(data.puc_validity)}
                />
              </div>
            </div>

            <div className="select-wrap">
              <div className="data-picker">
                <div className="label1">Goods Permit Exp Date</div>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  value={data.goods_permit_validity ? moment(data.goods_permit_validity).format("DD/MM/YYYY") : null}
                  onChange={this.SelectDate.bind(this, "goods_permit_validity")}
                  className={this.checkExpiry(data.goods_permit_validity)}
                />
              </div>
            </div>

            <div className="select-wrap">
              <div className="data-picker">
                <div className="label1">Tax Exp Date</div>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  value={data.tax_validity ? moment(data.tax_validity).format("DD/MM/YYYY") : null}
                  onChange={this.SelectDate.bind(this, "tax_validity")}
                  className={this.checkExpiry(data.tax_validity)}
                />
              </div>
            </div>

            <div className="pop-heading">Maintenance Details</div>
            <div className="select-wrap">
              <div className="data-picker">
                <div className="label1">Date of Service</div>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  value={data.last_service_date ? moment(data.last_service_date).format("DD/MM/YYYY") : null}
                  onChange={this.SelectDate.bind(this, "last_service_date")}
                />
              </div>
            </div>
            <div className="select-wrap">
              <InputText
                label="Last KM of Service"
                id="last_service_km"
                value={data.last_service_km}
                placeholder="Last KM of Service"
                changeHandler={this.onChangeHandler}
              />
            </div>
            <div className="select-wrap">
              <div className="data-picker">
                <div className="label1">Due Date of Next Service</div>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  value={data.next_service_due_date ? moment(data.next_service_due_date).format("DD/MM/YYYY") : null}
                  onChange={this.SelectDate.bind(this, "next_service_due_date")}
                />
              </div>
            </div>
            <div className="select-wrap">
              <InputText
                label="Due KM of Next Service"
                id="next_service_due_km"
                value={data.next_service_due_km}
                placeholder="Due KM of Next Service"
                changeHandler={this.onChangeHandler}
              />
            </div>
            <div className="select-wrap">
              <div className="data-picker">
                <div className="label1">Date of In-House Maintenance</div>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  value={data.inhouse_maintenance_date ? moment(data.inhouse_maintenance_date).format("DD/MM/YYYY") : null}
                  onChange={this.SelectDate.bind(
                    this,
                    "inhouse_maintenance_date"
                  )}
                />
              </div>
            </div>
            <div className="select-wrap">
              <div className="data-picker">
                <div className="label">Date of Demand Maintenace by Driver</div>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  value={data.demand_maintenance_date ? moment(data.demand_maintenance_date).format("DD/MM/YYYY") : null}
                  onChange={this.SelectDate.bind(
                    this,
                    "demand_maintenance_date"
                  )}
                />
              </div>
            </div>
            <div className="select-wrap">
                <div className="data-picker">
                  <div className="label">
                    Attach Documents
                  </div>
                <Button value={"Upload"} click={this.onUpload} />
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={(e) => {
                    this.uploadUrl = e;
                  }}
                  onChange={(e) => {this.uploadfiles(e)}}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                />
                {(data.attachments || []).map((att, index) => {
                return(
                <div>
                  {att && att.name ? (
                    <a
                      href={att.url}
                      download
                      className={"theme-color fs-12 mt-5"}
                    >
                      {att.name}
                    </a>
                  ) : null}
                  <span className="error pl-20 curP" onClick={() => this.removeAttachment(index)}>X</span>
                </div>
                )})}
                </div>
              </div>
          </div>
          <div className="submit-button">
          {this.state.errorMsg && <div className="error-color">{this.state.errorMsg}</div>}
            <div className="button-section">
              <Button value="Save" click={this.onSave} />
            </div>
            <div className="button-section">
              <Button
                value="Cancel"
                click={() => this.props.toggleAdd("Edit")}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
	return {
		master: state.master,
	};
};

export default connect(mapStateToProps)(AddItem);
