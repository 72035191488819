import { put, call } from "redux-saga/effects";
import ACTIONS from "../../common/action_constant";
import API from "../../common/api_config";
import {
  getData,
  putData,
  postData,
  deleteData,
  fileUpload
} from "../../../utils/ajax";

function* addCreditMaster(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(postData, API.postCreditMaster+action.param, action.data);
        yield put({ type: ACTIONS.COMPANY_ACCOUNTS.GET_CREDIT_MASTER, param : action.param, typeId: action.typeId });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 1, msg: data.result? data.result.message : "Event Added Successfully" }
          });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });

    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
        });
    }

}

function* updateCreditMaster(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(putData, API.postCreditMaster+action.param, JSON.stringify(action.data));
        yield put({ type: ACTIONS.COMPANY_ACCOUNTS.GET_CREDIT_MASTER, param : action.param, typeId : action.typeId });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 1, msg: "Updated Data Successfully" }
          });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });

    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
        });
    }
}

function* getCreditMaster(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(getData, API.getCreditMaster+action.param, action.data);
        let actionConst ;
        //let type = action.param.split('type=');
        if( action.typeId == 9){
            actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_CREDIT_MASTER;
        }
        else if( action.typeId == 10){
            actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE10;
        } 
        else if(action.typeId == 14){
            actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_FREIGHT_RATE;
        }
        else if(action.typeId == 33){
            actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_LOADING_CHARGES;
        }
        else if(action.typeId == 140){
            actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_MINIMUM_FREIGHT_RATE;
        }
        yield put({
        type: actionConst,
        data: data.data
        });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
          });
    }
}

function* addDebitMaster(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(postData, API.postDebitMaster+action.param, action.data);
        yield put({ type: ACTIONS.COMPANY_ACCOUNTS.GET_DEBIT_MASTER, param: action.param, data: action.data.type});
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 1, msg: "Event Added Successfully" }
          });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
          });
    }
}

function* updateDebitMaster(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(putData, API.postDebitMaster+action.companyId, JSON.stringify(action.data));
        yield put({ type: ACTIONS.COMPANY_ACCOUNTS.GET_DEBIT_MASTER, param: action.param, data: action.data.type});
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 1, msg: "Data Updated Successfully" }
          });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
          });
    }
}

function* getDebitMaster(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(getData, API.getDebitMaster+action.param);
        let actionConst ;
            if(action.data == 1){
                actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE1;
            }
            else if(action.data == 2){
                actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE2;
            }
            else if(action.data == 3){
                actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE3;
            }
            else if(action.data == 4){
                actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE4;
            }
            else if(action.data== 5){
                actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE5;
            }
            else if(action.data == 6){
                actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE6;
            }
            else if(action.data == 7){
                actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE7;
            }
            else if(action.data== 8){
                actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE8;
            }
            else if(action.data == 9){
                actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE9;
            }
            else if(action.data == 10){
                actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE10;
            }
            else if(action.data == 11){
                actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE11;
            }
            else if(action.data == 12){
                actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE12;
            }
            else if(action.data == 13){
                actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE13;
            }
            yield put({
            type: actionConst,
            data: data.data
        });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: error.responseJSON.result.message }
        });
    }
}

function* deleteDebitMaster(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(deleteData, API.deleteDebitMaster+action.companyId, JSON.stringify(action.input));
        yield put({ type: ACTIONS.COMPANY_ACCOUNTS.GET_DEBIT_MASTER, param: action.param, data : action.data});
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 1, msg: "Event deleted Successfully" }
          });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
        });
    }
}


function* getVehicleType(action) {
    try{
      yield put({
        type: ACTIONS.LOADER.SHOW_LOADER
      });
      
      const url =  action.isTTBIL ? `${API.getVehicleType}&companyId=${action.param.companyId}` : API.getVehicleType;
      const data = yield call(getData, url);
      yield put({ type: ACTIONS.COMPANY_ACCOUNTS.STORE_VEHICLE_DETAILS, data: data.data.list });
      yield put({
        type: ACTIONS.LOADER.HIDE_LOADER
      });
      if(action.onSuccess) {
        action.onSuccess();
        }
    } catch(error) {
        console.log(error);
    }
  }

function* getCompanyAccounts(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(getData, API.getCompanyAccounts+action.param);
        let actionConst ;
            if(action.data == 11){
                actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE11;
            }
            else if(action.data == 12){
                actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE12;
            }
            else if(action.data == 13){
                actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_DEBIT_MASTER_TYPE13;
            }
            yield put({
            type: actionConst,
            data: data.data
        });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
        });
    }
}

function* deleteCompanyAccounts(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(deleteData, API.deleteCompanyAccounts+action.companyId, JSON.stringify(action.input));
        yield put({ type: ACTIONS.COMPANY_ACCOUNTS.GET_ACCOUNTS_DETAILS, param: action.param, data : action.data});
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 1, msg: "Event deleted Successfully" }
          });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
          });
    }
}

function* addCompanyAccounts(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(postData, API.postCompanyAccounts+action.param,action.data);
        yield put({ type: ACTIONS.COMPANY_ACCOUNTS.GET_ACCOUNTS_DETAILS, param: action.param, data: action.data.type});
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
          });
    }
}

function* updateCompanyAccounts(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const url =  `${API.postCompanyAccounts}${action.companyId}&type=${action.data.type}`;
        const data = yield call(putData, url, JSON.stringify(action.data));
        yield put({ type: ACTIONS.COMPANY_ACCOUNTS.GET_ACCOUNTS_DETAILS, param: action.param, data: action.data.type});
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
          });
    }
}

function* deleteCreditMaster(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(deleteData, API.deleteCreditMaster+action.companyId, JSON.stringify(action.input));
        yield put({ type: ACTIONS.COMPANY_ACCOUNTS.GET_CREDIT_MASTER, param: action.param, data : action.data, typeId: action.data });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 1, msg: "Event deleted Successfully" }
          });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
        });
    }
}

function* getBillNumberDetails(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(getData, API.getBillNumber+action.query);
       
        yield put({
            type: ACTIONS.COMPANY_ACCOUNTS.STORE_BILL_NUMBER_DETAILS,
            data: data.data
        });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
        });
    }
}

function* postBillNumber(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(postData, API.postBillNumber+action.query , action.data);
        yield put({
            type: ACTIONS.COMPANY_ACCOUNTS.GET_BILL_NUMBER_DETAILS,
            query: action.query
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 1, msg: "Added Successfully" }
        });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
          });
    }
}

function* deleteBillNumber(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(deleteData, API.deleteBillNumber+action.data);
        yield put({
            type: ACTIONS.COMPANY_ACCOUNTS.GET_BILL_NUMBER_DETAILS,
            query: action.query
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 1, msg: "Deleted Successfully" }
        });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
          });
    }
}

function* updateBillNumber(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(putData, API.updateBillNumber+action.param, JSON.stringify(action.data));
        yield put({
            type: ACTIONS.COMPANY_ACCOUNTS.GET_BILL_NUMBER_DETAILS,
            query: action.query
          });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 1, msg: "Updated Successfully" }
        });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
          });
    }

}

function* getMaterialDetails(action) {
    try{
        const url =`${API.getMaterialDetails}${action.param}&company_id=${action.companyId}`;
        const data = yield call(getData, url);
        yield put({ type: ACTIONS.COMPANY_ACCOUNTS.STORE_MATERIAL_DETAILS, data : data ? data.data : [] });

    } catch(error) {
        console.log(error);
    }
}

function* getDetentionDetails(action) {
    try{
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(getData, API.getDetentionDetails + action.param);
        yield put({ type : ACTIONS.COMPANY_ACCOUNTS.STORE_DETENTION_DETAILS, data: data.data || [] });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
        });
    }
}

function* addDetentionDetails(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(postData, API.addDetentionDetails+action.param, action.data);
        yield put({ type: ACTIONS.COMPANY_ACCOUNTS.GET_DETENTION_DETAILS, param: action.param });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
          });
    }
}

function* updateDetentionDetails(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(putData, API.addDetentionDetails+action.companyId, JSON.stringify(action.data));
        yield put({ type: ACTIONS.COMPANY_ACCOUNTS.GET_DETENTION_DETAILS, param: action.param });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
        });
    }
}

function* deleteDetentionDetails(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(deleteData, API.addDetentionDetails + action.data);
        yield put({ type: ACTIONS.COMPANY_ACCOUNTS.GET_DETENTION_DETAILS, param: action.param });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
        });
    }
}

function* getMutltiPickupDropDetails(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(getData, API.getMultiPickupDropDetail + action.param);
        yield put({ type: ACTIONS.COMPANY_ACCOUNTS.STORE_MULTI_PICKUP_DETAILS, data: data.data || [] });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
          });
    }
}

function* addMutltiPickupDropDetails(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(postData, API.addMultiPickupDropDetail + action.param, action.data);
        yield put({ type: ACTIONS.COMPANY_ACCOUNTS.GET_MULTI_PICKUP_DETAILS, param: action.param });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
          });
    }
}

function* updateMutltiPickupDropDetails(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(putData, API.addMultiPickupDropDetail + action.param, JSON.stringify(action.data));
        yield put({ type: ACTIONS.COMPANY_ACCOUNTS.GET_MULTI_PICKUP_DETAILS, param: action.param });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
        });
    }
}

function* getHolidayList(action) {
    try{
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(getData, API.getHolidayList+action.param);
        yield put({ type : ACTIONS.COMPANY_ACCOUNTS.STORE_HOLIDAY_LIST, data: data.data });
        yield put({ type : ACTIONS.COMPANY_ACCOUNTS.DOWNLOAD_HOLIDAY_LIST, param: action.param });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    }catch(error){
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
        });
    }
}

function* uploadHolidayList(action) {
    try{
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(fileUpload, API.uploadHolidayList+action.param, action.data);
        yield put({ type : ACTIONS.COMPANY_ACCOUNTS.GET_HOLIDAY_LIST, param: action.param })
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    }catch(error){
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
        });
    }
}

function* downloadHolidayList(action) {
    try{
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(getData, API.downloadHolidayList+action.param );
        yield put({ type : ACTIONS.COMPANY_ACCOUNTS.DOWNLOAD_HOLIDAY_LIST_URL, data: data.data ? data.data[0].Link : '' })
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    }catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
        });
    }
}

function* getTransporterList(action) {
    try{
        let url = API.getTransporterList;
        if(action.search) {
            url += `search=${action.search}`;
        }
        if(action.param && Object.keys(action.param).length > 0) {
            let companyTypeTo = action.param.companyTypeTo !== undefined ? action.param.companyTypeTo : 0;
            url += `limit=20&offset=${action.param.offset || 0}`+`&companyType=${action.param.companyType}`+`&companyTypeTo=${companyTypeTo}`+`&isMultimode=${action.param.isMultimode}`+`&isWarehouse=${action.param.isWarehouse || false}`;
            if(action.param.search) {
                url +=`&search=${action.param.search}`;
            }
        }
        const data = yield call(getData, url );
        yield put({ type: ACTIONS.COMPANY_ACCOUNTS.STORE_TRANSPORTER_LIST, data : data.data && data.data.list ? data.data.list : [] });
    } catch(error) {
        console.log('ERROR', error);
    }
}

function* getPayables(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(getData, API.getPayables+action.param, action.data);
        let actionConst ;
        if( action.typeId == 20){
            actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_CREDIT_MASTER_PAYABLES_RHC;
        }
        else if( action.typeId == 21){
            actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_CREDIT_MASTER_PAYABLES_FUEL_SURCHARGE;
        } 
        else if(action.typeId == 22){
            actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_CREDIT_MASTER_PAYABLES_RAS;
        }
        else if(action.typeId == 23){
            actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_CREDIT_MASTER_PAYABLES_FOV;
        }
        else if(action.typeId == 24){
            actionConst = ACTIONS.COMPANY_ACCOUNTS.STORE_CREDIT_MASTER_PAYABLES_ODA;
        }
        yield put({
        type: actionConst,
        data: data.data
        });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
          });
    }
}

function* addPayables(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(postData, API.addPayables+action.param, action.data);
        yield put({ type: ACTIONS.COMPANY_ACCOUNTS.GET_CREDIT_MASTER_PAYABLES, param : action.param, typeId: action.typeId });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 1, msg: "Event Added Successfully" }
          });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });

    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
        });
    }
}

function* updatePayables(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(putData, API.updatePayables+action.param, JSON.stringify(action.data));
        yield put({ type: ACTIONS.COMPANY_ACCOUNTS.GET_CREDIT_MASTER_PAYABLES, param : action.param, typeId : action.typeId });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 1, msg: "Updated Data Successfully" }
          });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });

    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
        });
    }
}

function* deletePayables(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const deleteUrl = `${API.deletePayables}${action.companyId}&payableId=${action.input.payable_id}`;
        const data = yield call(deleteData, deleteUrl );
        yield put({ type: ACTIONS.COMPANY_ACCOUNTS.GET_CREDIT_MASTER_PAYABLES, param: action.param, data : action.data, typeId: action.data });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 1, msg: "Event deleted Successfully" }
          });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
        });
    }
}

function* getStateList(action) {
    try {
        const data = yield call(getData, API.getStateListUrl);
        yield put({
            type: ACTIONS.COMPANY_ACCOUNTS.STORE_STATE_LIST,
            data: data.data || []
        })
    } catch(error) {
        console.log('ERROR', error);
    }
}

function* getZIPCodeList(action) {
    try {
        const data = yield call(getData, API.getZIPCodeLists);
        yield put({
            type: ACTIONS.COMPANY_ACCOUNTS.STORE_ZIPCODE_LIST,
            data: data.data || []
        })
    } catch(error) {
        console.log('ERROR', error);
    }
}

function* getShortagePilferageToleranceList(action) {
    try{
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(getData, API.getShortagePilferageTolerance+action.companyId);
        yield put({ type : ACTIONS.COMPANY_ACCOUNTS.STORE_SHORTAGE_PILFERAGE_TOLERANCE_LIST, data: data.data });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    }catch(error){
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
        });
    }
}

function* addShortagePilferageTolerance(action) {
    try{
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        if(action.isUpdate) {
            yield call(putData, API.postShortagePilferageTolerance, JSON.stringify(action.data));
        } else {
            yield call(postData, API.postShortagePilferageTolerance, action.data);
        }
        if(action.onSuccess) {
            action.onSuccess();
        }
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 1, msg: `Data ${action.isUpdate ? 'Updated' : 'Added'} Successfully` }
        });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    }catch(error){
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
        });
    }
}

function* deleteShortagePilferageTolerance(action) {
    try {
        yield put({
            type: ACTIONS.LOADER.SHOW_LOADER
        });
        const data = yield call(deleteData, API.deleteShortagePilferageTolerance, JSON.stringify(action.data));
        if(action.onSuccess) {
            action.onSuccess();
        }
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 1, msg: "Event deleted Successfully" }
          });
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
    } catch(error) {
        yield put({
            type: ACTIONS.LOADER.HIDE_LOADER
        });
        yield put({
            type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
            data: { type: 2, msg: (error &&  error.responseJSON && error.responseJSON.result && error.responseJSON.result.message) || 'We are facing some technical issue. Please try after sometime.' }
        });
    }
}

export {
    addCreditMaster,
    getCreditMaster,
    updateCreditMaster,
    addDebitMaster,
    getDebitMaster,
    updateDebitMaster,
    deleteDebitMaster,
    getVehicleType,
    getCompanyAccounts,
    deleteCompanyAccounts,
    addCompanyAccounts,
    updateCompanyAccounts,
    updateBillNumber,
    deleteCreditMaster,
    getBillNumberDetails,
    postBillNumber,
    deleteBillNumber,
    getMaterialDetails,
    getDetentionDetails,
    addDetentionDetails,
    updateDetentionDetails,
    deleteDetentionDetails,
    getMutltiPickupDropDetails,
    addMutltiPickupDropDetails,
    updateMutltiPickupDropDetails,
    getHolidayList,
    uploadHolidayList,
    downloadHolidayList,
    getTransporterList,
    getPayables,
    addPayables,
    updatePayables,
    deletePayables,
    getStateList,
    getZIPCodeList,
    getShortagePilferageToleranceList,
    addShortagePilferageTolerance,
    deleteShortagePilferageTolerance
  };
