import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ACTION from "../../common/action_constant";
import "./glCodeMasters.less";

import MainComponent from "../../home/main/main";
import Button from "../../home/button-classic/button-classic";
import CheckBox from "../../components/checkbox/checkbox";
import InputText from "../../common/components/input-text/input-text";
import InputTextGLCode from "./input-text-glcode";
import Loader from "../../common/components/loader/loader";
import MSGPopUp from "../../common/components/msg-popup/msg-popup";

import NewOdaSlab from "./newOdaSlab";
import Save from '../../../assets/images/save.svg';
import Cancel from '../../../assets/images/cancel.svg';
import Edit from '../../../assets/images/edit.svg';
// import Download from '../../../assets/images/direct-download.svg'
import Select from "react-select";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import MultiSelectAll from "./MultiSelectAll";
import { getCookie } from "../../../utils/cookies";
// import { getUserCompanyId } from '../../../utils/common';

class GLCodeMasters extends Component {
    constructor(props){
        super(props);
        this.state = {
            addItemPopup: false,
            selectedOdas: [],
            selectedChannlesList: [],
            selectedChannles: [],
            selectedDistributionsList: [],
            selectedDistributions: [],
            selectedOptionsChannel: [],
            selectedOptionsDistribution: [],
            serviceType: 1,
            isAdded: false,
            offset: 0,
            limit: 20,
            vendorId: '',
            vendorName: '',
            isSlab: 1,
            isCity: false,
            data: { 
                //     vendor_name: '',
                //     vendor_id: '',
                //     vendor_code: '',
                //     mode_type: '',
                //     mode_id: '',
                //     company_category: '',
                //     company_category_id: '',
                //     oda_type: '',
                //     oda_type_id: '',
                //     from: '',
                //     from_id: '',
                //     to: '',
                //     to_id: '',
                //     // oda_distance: ["26-75", "76-150", "151-300"],
                //     // oda_distance_uom: "KM",
                //     // oda_weights: ["0-100", "101-250", "251-500", "501-1000", "Above 1000"],
                //     // oda_weights_uom: "Kgs",
                //     // odas: []
            },
            glCodeMasterData: (props.dataGovernance && props.dataGovernance.glCodeMasterData) ? props.dataGovernance.glCodeMasterData : [],
            error: '',
            isCitySlab: false
        }
    }

    setSelectedOptionsChannel = (value) =>{
        this.setState({
            selectedOptionsChannel: value
        })
        let result = value.map(ch => ch.label)
        this.setState({
            selectedChannlesList: result
        })
        
    }
    setSelectedOptionsDistribution = (value) =>{
        this.setState({
            selectedOptionsDistribution: value
        })
        let result = value.map(dt => dt.label)
        this.setState({
            selectedDistributionsList: result
        })
    }

    componentDidMount() {
        this.getModeTypes();
        this.getChannelTypes();
        this.getDistributionTypes();
        this.getVendorsData();
        this.getAllTypesOfODA();
        this.getGLCodeMasterData(this.state.isSlab===2);
    }

    componentWillReceiveProps(nextProps) {
        const { dataGovernance } = this.props;
        
        if (dataGovernance !== nextProps.dataGovernance) {
            if (dataGovernance.glCodeMasterData !== nextProps.dataGovernance.glCodeMasterData) {
                this.setState({
                    glCodeMasterData: nextProps.dataGovernance.glCodeMasterData
                });
            }
        }
    }

    getServiceName = (id) => {
        let name = '';
        switch(id) {
            case 1:
                name = 'LCL';
                break;
            case 2:
                name = 'FTL';
                break;
            case 3:
                name = 'Courier';
                break;
            default:
                break;
        }
        return name;
    }

    getCities = (searchValue) => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_CITY_LIST,
            params: `cityName=${searchValue}&lookingFor=city`,
        });
    }

    getHubs = (searchValue, vendorId) => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_HUB_LIST,
            params: `companyId=${JSON.parse(getCookie("user")).company_id}&vendorId=${vendorId}&limit=100&offset=0&search=${searchValue}`,
        });
    }

    // getOdaMasterData = (isSlab) => {
    //     const { dispatch } = this.props;
    //     const { offset, limit, vendorId, isCity } = this.state;
    //     let params = `company_id=${JSON.parse(getCookie("user")).company_id}&lan_type=${this.getServiceName(this.state.serviceType)}&limit=${limit}&offset=${offset}`;
    //     isCity === true ?
    //     params += `&is_citywise=${isCity}`:
    //     params += `&is_slab=${isSlab}`;

    //     if (vendorId) {
    //         params = params + `&vendor_id=${vendorId}`;
    //     }
        

    //     dispatch({
    //         type: ACTION.DATA_GOVERNANCE.GET_ODA_MASTERS,
    //         params,
    //         onSuccess: (() => { this.setState({ editId: null, isAdded: false, error: '' }) })
    //     })
        
    // }

    getGLCodeMasterData = (isSlab) => {
        const { dispatch } = this.props;
        // const { offset, limit, vendorId, isCity } = this.state;
        let params = `company_id=${JSON.parse(getCookie("user")).company_id}`
        // let params = `company_id=${JSON.parse(getCookie("user")).company_id}&lan_type=${this.getServiceName(this.state.serviceType)}&limit=${limit}&offset=${offset}`;
        // isCity === true ?
        // params += `&is_citywise=${isCity}`:
        // params += `&is_slab=${isSlab}`;

        // if (vendorId) {
        //     params = params + `&vendor_id=${vendorId}`;
        // }
        

        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_GLCODE_MASTERS,
            params,
            onSuccess: (() => { this.setState({ editId: null, isAdded: false, error: '' }) })
        })
        
    }

    getVendorsData = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_VENDORS_TYPES,
        })
    }

    getModeTypes = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_MODE_TYPES,
        })
    }

    getChannelTypes = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_CHANNEL_TYPES,
        })
    }

    getDistributionTypes = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_DISTRIBUTION_TYPES,
        })
    }

    

    getAllTypesOfODA = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.GET_ALL_TYPES_OF_ODA
        });
    }

    handleSave = (data) => {
        this.editGLCode(0);
        this.setState({
            isAdded: true,
            addItemPopup: false,
        });
    }

    editGLCode = (index) => {
        this.setState({
            editId: (this.state.editId===index) ? null : index,
            // selectedChannlesList: [],
            // selectedDistributionsList: []
        });
    }
    
    handleCkSelect = (e, id) => {
        const { selectedOdas } = this.state;
        let index = selectedOdas.indexOf(id)
        if (selectedOdas.includes(id)) {
            selectedOdas.splice(index, 1);
        } else {
            selectedOdas.push(id);
        }
        
        this.setState({ selectedOdas });
    }

    saveGLCode = (oda) => { 
        let { isAdded, selectedChannlesList, selectedDistributionsList, glCodeMasterData } = this.state;
        const { dispatch } = this.props;
        
        let prevGlCodeMasterData = [...glCodeMasterData]
        prevGlCodeMasterData.shift()
        let isExistingGLCode = prevGlCodeMasterData.map(gl => gl.gl_code).includes(oda.gl_code)
        
        const data = {
                ...oda,
                channel: selectedChannlesList,
                distribution_type: selectedDistributionsList
        };

        if (isAdded) {
            if(oda.gl_code && selectedChannlesList.length > 0 && selectedDistributionsList.length > 0){
                if(!isExistingGLCode){
                    dispatch({
                        type: ACTION.DATA_GOVERNANCE.POST_GLCODE_MASTERS,
                        params: data,
                        onSuccess: (() => {
                            this.setState({ isAdded: false, editId: null, isCitySlab: false, selectedChannlesList: [] , selectedDistributionsList: [],  });
                            this.getGLCodeMasterData(this.state.isSlab===2);
                            oda.gl_code = ''
                        })
                    })
                } else {
                    alert("GL Code already exists, Please enter a different value.")
                }
        } else if(oda.gl_code === undefined) {
            alert("Please enter GL Code")
        } else if(selectedChannlesList.length == 0){
            alert("Please select atleast one Channel")
        } else if(selectedDistributionsList.length == 0){
            alert("Please select atleast one Distribution Type")
        } else {
            alert('All fields are mandatory!')
        }
    } else {
            this.updateOda(data);
        }
    }

    updateOda = (oda) => {
        const { dispatch } = this.props;
        let { glCodeMasterData, selectedChannlesList, selectedDistributionsList } = this.state;
        
        let prevGlCodeMasterData = [...glCodeMasterData]
        //shift wont work , remove from index, use indexOf
        // let ind = prevGlCodeMasterData.indexOf(oda)

        // map out gl_code only from prevGlCodeMasterData in a separate array
        let newArray = prevGlCodeMasterData.map(code=>code.gl_code)
        // compare oda.gl_code to filtered out gl_code instead of array of object
        var idx = newArray.indexOf(oda.gl_code)
        // set isExisting from this array of existing gl_code
        newArray.splice(idx, 1)
        let isExistingGLCode = newArray.includes(oda.gl_code)
        
        if(oda.gl_code && selectedChannlesList.length > 0 && selectedDistributionsList.length > 0){
            if(!isExistingGLCode){
                dispatch({
                    type: ACTION.DATA_GOVERNANCE.UPDATE_GLCODE_MASTERS,
                    params: oda,
                    onSuccess: (() => {
                        this.setState({ isAdded: false, editId: null });
                        this.getGLCodeMasterData(this.state.isSlab===2);
                    })
                });
            } else {
                alert("GL Code already exists, Please enter a different value.")
            }
        }  else if(oda.gl_code === "") {
            alert("Please enter GL Code")
        } else if(selectedChannlesList.length == 0){
            alert("Please select atleast one Channel")
        } else if(selectedDistributionsList.length == 0){
            alert("Please select atleast one Distribution Type")
        } else {
            alert('All fields are mandatory!')
        }
    }

    deleteGLCode = () => {
        const { selectedOdas, glCodeMasterData } = this.state;
        const { dispatch } = this.props;
        const data = {
                company_id: JSON.parse(getCookie("user")).company_id,
                gl_id: selectedOdas
            }
        if(selectedOdas.length > 0){
            dispatch({
                type: ACTION.DATA_GOVERNANCE.DELETE_GLCODE_MASTERS,
                params: data,
                onSuccess: (() => {
                    this.getGLCodeMasterData(this.state.isSlab===2);
                })
            })
        } else {
            alert("Please Select atleast 1 record!")
        }   
        
    }

    downloadSlab = () => {
        const { odaMasterData, isCity } = this.state;
        const { dispatch } = this.props;
        const company_id = JSON.parse(getCookie("user")).company_id;
        dispatch({
            type: ACTION.DATA_GOVERNANCE.DOWNLOAD_SLAB,
            params: isCity ? `company_id=${company_id}&is_citywise=${isCity}` :`company_id=${company_id}`,
            onSuccess: ((data) => {
                if(data.length > 0) {
                    this.setState({
                        downloadLink: data[0].Link
                    }, () => { 
                        document.getElementById('slabDownloadBtn').setAttribute('href', data[0].Link);
                        document.getElementById('slabDownloadBtn').click();
                        // this.downloadBtn.click();
                    });
                }
            })
        })
    }

    uploadODA = (e) => {
        let formData = new FormData();
        formData.append('file', e.target.files[0]);
        const { dispatch } = this.props;
        const { isCity } = this.state;
        const company_id = JSON.parse(getCookie("user")).company_id;

        formData.append('is_citywise', isCity);
        formData.append('company_id', company_id);
        formData.append('lan_type', this.getServiceName(this.state.serviceType));


        dispatch({ 
            type: ACTION.DATA_GOVERNANCE.UPLOAD_GLCODE, 
            data: formData, 
            onSuccess: (() => {
                this.getGLCodeMasterData(this.state.isSlab===2);
            })
        });
    }

    uploadSlab = (e) => {
        let formData = new FormData();
        formData.append('file', e.target.files[0]);
        const { dispatch } = this.props;
        const { isCity, vendorId, vendorName } = this.state;
        const company_id = JSON.parse(getCookie("user")).company_id;
        const otherParams = Object.keys(this.state.data).length > 0 ? this.state.data : this.state.openSlab;
        const data = {
            company_id: JSON.parse(getCookie("user")).company_id,
            oda_details: {
                ...otherParams,
                lan_type: this.getServiceName(this.state.serviceType),
                is_citywise: isCity ? isCity : undefined,
                vendor_id: vendorId ? vendorId : undefined,
                vendor_name: vendorName ? vendorName : undefined,
                // oda_distance: ["26-75", "76-150", "151-300"],
                // oda_weights: ["0-100 Kgs", "101-250 Kgs", "251-500 Kgs", "501-1000 Kgs", "Above 1000 Kgs"],
            },
        };
        
        formData.append('jsonData', JSON.stringify(data));
        
        dispatch({ 
            type: ACTION.DATA_GOVERNANCE.UPLOAD_SLAB, 
            data: formData, 
            onSuccess: (() => {
                this.setState({ 
                    openSlab: null,
                    editId: null,
                    isAdded: false,
                    isCitySlab: false,
                })
                this.getGLCodeMasterData(this.state.isSlab===2);
            })
        });
    }

    closeMsgPopup = () =>{
        const {dispatch} = this.props;
        dispatch({ type : ACTION.MSG_DISPLAY.HIDE_MSG });
    }

    clickCustomCheckBox = (id, name) =>{
        if(id===3){
            this.setState({isCity: true})
        }
        else{
            this.setState({isCity: false})
        }
        this.setState({
            [name]: id,
            offset: 0,
        }, () => {
            this.getGLCodeMasterData((id===2));
        })
    }

    // handleChange = (id, selcetedOption) => {
    //     // filters from fe
	// 	let { selectedChannles } = this.state;
	// 	if (id === 'channel') {
	// 		selectedChannles = [];
	// 		for (let i = 0; i < selcetedOption.length; i++) {
	// 			selectedChannles.push(selcetedOption[i].label);
	// 		}
	// 	} else {
	// 		selectedChannles[id] = selcetedOption.label
	// 	}
	// 	this.setState({ selectedChannles });
	// }

    selectChannelList = (value, option) => { 

        let { selectedChannlesList, selectedChannles } = this.state;
            console.log(option, 'option from multi')
            let selectedIndex = selectedChannlesList.indexOf(option.option.label)
            if (selectedChannlesList.includes(option.option.label)) {
                selectedChannlesList.splice(selectedIndex, 1);
            } else {
                // selectedChannlesList = [] will stop multiselect
                selectedChannlesList.push(option.option.label);
                selectedChannles.push(option.option.label)
            }
        this.setState({ selectedChannlesList }); 
        console.log(selectedChannlesList, selectedChannles, "SCL") 
    }

    selectDistributionList = (value, option) => {
        let { selectedDistributionsList }= this.state;
            let selectedIndex = selectedDistributionsList.indexOf(option.option.label)
            if (selectedDistributionsList.includes(option.option.label)) {
                selectedDistributionsList.splice(selectedIndex, 1);
            } else {
                // selectedDistributionsList = []
                selectedDistributionsList.push(option.option.label);
            }
        this.setState({selectedDistributionsList })
        console.log(selectedDistributionsList, "SDL")
      }

    // handleChannelSelect = (e, id) => {
    //     const { selectedChannles } = this.state;
    //     if (selectedChannles.includes(id)) {
    //         selectedChannles.splice(id, 1);
    //     } else {
    //         selectedChannles.push(id);
    //     }
    //     this.setState({ selectedOdas });
    // }

    // handleDistributionSelect = (e, id) => {
    //     const { selectedOdas } = this.state;
    //     if (selectedOdas.includes(id)) {
    //         selectedOdas.splice(id, 1);
    //     } else {
    //         selectedOdas.push(id);
    //     }
    //     this.setState({ selectedOdas });
    // }

    onODAChange = (value, index) => {
        const { odaMasterData } = this.state;
        odaMasterData[index].oda_type = value.label;
        odaMasterData[index].oda_type_id = value.value;
        if (value.value===3) {
            odaMasterData[index].oda_weight_uom = 'Kgs';
            odaMasterData[index].oda_rate_uom = 'Rs/Kg';
        }
        if (value.value===4) {
            odaMasterData[index].oda_distance_uom = 'KMS';
            odaMasterData[index].oda_rate_uom = 'Rs/KM';
        }
        this.setState({ odaMasterData });
    }

    handleAddGLCode = (isCitySlab) => {
        const { glCodeMasterData, data, isAdded } = this.state;
        if(!isAdded){
            // glCodeMasterData.shift()
            (glCodeMasterData || []).unshift(data);
        }
        // let qwe = glCodeMasterData.shift()
        this.setState({
            glCodeMasterData,
            editId: 0,
            isAdded: true,
            isCitySlab,
            selectedChannlesList: [],
            selectedDistributionsList: []
        });
    }

    handleCancelGLCode = (oda) => {
        const { glCodeMasterData } = this.state;
        if (!oda.gl_id) {
            (glCodeMasterData || []).shift();
            this.setState({ 
                glCodeMasterData, 
                selectedChannlesList: [],
                selectedDistributionsList: []
            })
            oda.gl_code = ""
        }

        this.setState({
            editId: null,
            isAdded: false,
            selectedChannlesList: [],
            selectedDistributionsList: [],
        });
    }

    previous = () => {
        if (this.state.offset > 0) {
            this.setState({
                offset: this.state.offset - 20
            }, () => {
                this.getGLCodeMasterData(this.state.isSlab===2)
            });
        }
    }
    next = () => {
        this.setState({
            offset: this.state.offset + 20
        }, () => {
            this.getGLCodeMasterData(this.state.isSlab===2)
        });
    }

    renderTableBody = (odaData) => { 
        const { editId, isAdded, selectedOdas, isSlab } = this.state;
        const { dataGovernance } = this.props;
        let cities = [];

        if (dataGovernance.citiesList && dataGovernance.citiesList.length > 0) {
            (dataGovernance.citiesList || []).forEach((items) => {
                cities = cities.concat(items.cities || items.states || []);
            });
        }
        if(isSlab===3){
            if(isAdded === false){
                odaData = (odaData || []).filter((oda) => oda.oda_value)
            }
        }
        // let selectedOptionsValues =  odaData.map(oda =>{
        //            return (dataGovernance.channelTypes || []).filter(o=>oda.channel.includes(o.label))
        //         })
        //         this.setState({
        //             selcetedOptionsChannel: selectedOptionsValues
        //         })
        return (
          <Fragment>
            {odaData && odaData.length > 0 && (odaData).map((oda, index) => {
                const isAddOrEdit = (editId===index);

                let channelList = typeof(oda.channel) === 'object' ? [...oda.channel] : oda.channel
                let chanelFormatted = typeof(oda.channel) === 'object' ? channelList.join(", ") : channelList
                // console.log(oda.channel, typeof(oda.channel), '111111')
                let distributionList = typeof(oda.distribution_type) === 'object' ? [...oda.distribution_type] : oda.distribution_type
                let distributionTypeFormatted = typeof(oda.distribution_type) === 'object' ? distributionList.join(", ") : distributionList
                // let targetArray = (dataGovernance.channelTypes || []).filter(o=>oda.channel.includes(o.label))
                // this.setState({
                //     selcetedOptionsChannel: selectedOptionsValues
                // })
                return (
                    <div className="list-section content divTableRow" key={`glc-${(index + 1)}`}>
                        <div className="divTableCell select-row"> 
                        {!isAdded && (index !== editId) && 
                            <input 
                                type="checkbox" 
                                checked={selectedOdas.includes(oda.gl_id)}
                                onChange={(e) => this.handleCkSelect(e, oda.gl_id)}
                            />
                        }
                        </div>
                        <div className="divTableCell"> 
                            {isAddOrEdit ?  <InputTextGLCode 
                                                required={true}
                                                type="text"
                                                placeholder="GL Code"
                                                value={oda.gl_code}
                                                className="wt-100px-imp" 
                                                changeHandler={(value, id) => {
                                                    const { glCodeMasterData } = this.state;
                                                    glCodeMasterData[index].gl_code = value;
                                                    // if(glCodeMasterData[index].gl_code != ''){
                                                        this.setState({ glCodeMasterData });
                                                    // } else {
                                                    //     alert("GL Code can't be left blank")
                                                    // }
                                                }}
                                            /> 
                                            : 
                                            <Fragment>{oda.gl_code}</Fragment>}
                        </div>
                        <div className="divTableCell" style={{padding:"0"}}> 
                            { isAddOrEdit ? 
                            <MultiSelectAll 
                                options = {(dataGovernance.channelTypes || [])}
                                defaultValue={ oda && oda.channel ? (dataGovernance.channelTypes || []).filter(o=>oda.channel.includes(o.label)) : []}
                                selectedOptions = {this.state.selectedOptionsChannel}
                                setSelectedOptions = {this.setSelectedOptionsChannel}
                            />
                                // <Select
                                //     className="select"
                                //     value={(this.state.selectedChannles || [])}
                                //     options={(channelTypes || [])}
                                //     onChange={this.handleChange.bind(this, 'channel')}
                                //     isMulti={true}
                                // />
                                :
                                <Fragment>{chanelFormatted}</Fragment>
                            }
                        </div>
                        <div className="divTableCell" style={{padding:"0"}}> 
                            {isAddOrEdit ? 
                                <MultiSelectAll 
                                    options = {(dataGovernance.distributionTypes || [])}
                                    defaultValue={ oda && oda.distribution_type ? (dataGovernance.distributionTypes || []).filter(o=>oda.distribution_type.includes(o.label)):[]}
                                    selectedOptions = {this.state.selectedOptionsDistribution}
                                    setSelectedOptions = {this.setSelectedOptionsDistribution}
                                />
                                :
                                <Fragment>{distributionTypeFormatted}</Fragment>} 
                        </div>
                        <div className="divTableCell"> 
                        {(editId===index) 
                            ?
                                <Fragment>
                                    <div className={'action-icon'} style={{backgroundImage: `url(${Save})`, transform:"scale(1.2)"}}  onClick={() => this.saveGLCode(oda)} /> 
                                    <div className={'action-icon'} style={{backgroundImage: `url(${Cancel})` , transform:"scale(1.2)"}} onClick={() => this.handleCancelGLCode(oda)} />
                                </Fragment>
                            :
                                <div className={'action-icon'} style={{backgroundImage: `url(${Edit})`}} onClick={() => this.editGLCode(index)} /> 
                        }
                        </div>
                    </div>
               )
            })}
          </Fragment>
        )
    }
    
    render() {
        const { addItemPopup, glCodeMasterData, isAdded, serviceType, isSlab, vendorId, vendorName } = this.state;
        const { dataGovernance } = this.props;
        const company_id = JSON.parse(getCookie("user")).company_id;
       
        // console.log((dataGovernance.distributionTypes || []).map(no => { return { label : no.label, value: no.value}}), "multi")
        // console.log((dataGovernance.distributionTypes || []).filter((distribution) => { return distribution.value===oda.label }), 'value')

        return (
            <div className="discount-wrap">
                <div className="wrapper">
                    <MainComponent>
                        
                        {this.props.loader.isLoading && <Loader />}
                        {this.props.msgpopup.active && <MSGPopUp close = {this.closeMsgPopup} type = {this.props.msgpopup.type} msg = {this.props.msgpopup.msg}/>}
                        <div className="sub-heading-section"> 
                            <div className="sub-heading"> GL Code Master </div>
                        </div>

                        <div>
                            <div className="cta-action-btns cta-btn-right">
                                <button className="button-classic" onClick={() => {this.handleAddGLCode(false)}}>Add Record</button>
                                <button className="button-classic" onClick={this.deleteGLCode}>Delete Record(s)</button>
                                {this.state.error && this.state.error.length > 0 && <div className="ml-130 error  mt-10">{this.state.error}</div>}
                                {/* {isSlab===3 && <div><button className="button-classic" onClick={() => {this.handleAddGLCode(true)}}>Add Slab</button></div>} */}
                            </div>
                            <div className="serviceTypes vis-hidden">
                                <div className="cta-action-btns">
                                    <div className = "section-item">
                                        <CustomeCheckBox id={1} toggle={(id) => this.clickCustomCheckBox(id, 'isSlab')} selected={(isSlab===1)}/>
                                        <div className="title"> General </div>
                                    </div>
                                    <div className = "section-item">
                                        <CustomeCheckBox id={2} toggle={(id) => this.clickCustomCheckBox(id, 'isSlab')} selected={(isSlab===2)}/>
                                        <div className="title"> Slabwise </div>
                                    </div>
                                    <div className = "section-item">
                                        <CustomeCheckBox id={3} toggle={(id) => this.clickCustomCheckBox(id, 'isSlab')} selected={(isSlab===3)}/>
                                        <div className="title"> Cityslab </div>
                                    </div>
                                </div>
                            </div>

                            <div className="divTable company-list-wrap mt-20">
                                <div className="list-section table-header divTableHeading divTableRow">
                                    <div className="divTableCell select-row"> </div>
                                    <div className="divTableCell"> GL Code </div>
                                    <div className="divTableCell"> Channel </div>
                                    <div className="divTableCell"> Distribution type </div>
                                    <div className="divTableCell"> Actions </div>
                                </div>
                                <div className="divTableBody">
                                    {this.renderTableBody(glCodeMasterData)}
                                </div>
                            </div> 

                            {
                                this.state.openSlab && 
                                <NewOdaSlab 
                                    slabData={this.state.openSlab}
                                    downloadSlab={this.downloadSlab}
                                    uploadSlab={this.uploadSlab}
                                    downloadLink={this.state.downloadLink}
                                    cancelNewSlab={() => { this.setState({ openSlab: null }) }}
                                />
                            }
                        </div>
                        <div className="submit-section-pagination">
                            <div className="next">
                                <Button value="Next" disabled={dataGovernance.odaCount === 0 || (dataGovernance.odaCount <= (this.state.offset+this.state.limit))} click={this.next} />
                            </div>
                            <div className="pre">
                                <Button value="Previous" disabled={this.state.offset===0} click={this.previous} />
                            </div>
                        </div>
                    </MainComponent>
                </div>
            </div>
        );
    }
}

const CustomeCheckBox = (props) =>{
    return(
        <div className = {props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick = {()=>props.toggle(props.id)}>
            {props.selected && <div className = "inner-circle"></div>}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loader: state.loader,
        msgpopup: state.msgpopup,
        dataGovernance: state.dataGovernance,
    }
}

export default connect(mapStateToProps)(GLCodeMasters);
