import React, { Component } from 'react';
import '../home-navigation-panel/home-navigation-panel.less';
import DisposalMaterialMaster from './disposal-material-master/disposal-material-master';
import ContractManagement from './disposal-material-contracts-mgmt/disposal-material-contracts-mgmt';

const tabs = [
    { heading: 'Disposal Material Master', id: 1},
    { heading: 'Contracts Mgmt', id: 2 }
]

class DisposalMaterial extends Component {
    constructor() {
        super();
        this.state = {
            selectedOption: 1
        }
    }

    setOption = (id) => {
        this.setState({ selectedOption: id });
    }

    render() {
        const { selectedOption } = this.state;
        return (
            <div className = "plants-depots-main-wrap">
                <div className="home-navigation-holder home-navigation-holder-sub">
                {tabs.map((option, index) => {
                    return (
                    <div
                        key={index}
                        className={
                        this.state.selectedOption === option.id
                            ? "selected-option options"
                            : "options"
                        }
                        onClick={() => this.setOption(option.id)}
                    >
                        {option.heading}
                    </div>
                    );
                })}
                </div>
                {selectedOption === 1 && 
                    <DisposalMaterialMaster 
                        company_id={this.props.company_id}
                    />}
                {selectedOption === 2 &&
                    <ContractManagement
                        company_id = {this.props.company_id}
                        branch_id = {this.props.branch_id}
                    />

                }
            </div>
        )
    }
}

export default DisposalMaterial;