import React, { Component } from 'react';
import Select from 'react-select';
import InputText from '../../../common/components/input-text/input-text';
import { uomEquality } from '../../../../utils/common';
import { damagesUOM } from '../../../../utils/constants';
import '../reconciliation.less';
import { decimalNumber } from '../../../../utils/validate';
import Button from '../../button-classic/button-classic';

class AddDamages extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {
				sub_type: props.isEditDamagesData ? props.isEditDamagesData.sub_type : '',
				condition: props.isEditDamagesData ? props.isEditDamagesData.condition : '',
				damage_rate: props.isEditDamagesData ? props.isEditDamagesData.damage_rate : '',
				max_amnt_to_be_claimed_transporter: props.isEditDamagesData ? props.isEditDamagesData.max_amnt_to_be_claimed_transporter.toString() : '',
				min_amnt_to_be_claimed_transporter: props.isEditDamagesData ? props.isEditDamagesData.min_amnt_to_be_claimed_transporter.toString() : '',
				remarks: props.isEditDamagesData ? props.isEditDamagesData.remarks : '',
				product_list_inclusive: props.isEditDamagesData ? props.isEditDamagesData.product_list_inclusive : [],
				product_list_exclusive: props.isEditDamagesData ? props.isEditDamagesData.product_list_exclusive : [],
				claim_uom: props.isEditDamagesData ? props.isEditDamagesData.claim_uom : '',
			},
			productOptions: []
		}
	}

	handleChange = (id, selectOptions) => {

		let { data } = this.state;
		if (id == 'product_list_inclusive' || id == 'product_list_exclusive') {
			let selected = [];
			for (var i in selectOptions) {
				selected.push(selectOptions[i].label);
			}
			data[id] = selected;
		} else {
			data[id] = selectOptions.value;
		}
		this.setState({ data }, () => {
			if (id === 'sub_type') {
				this.props.getMaterailDetails(selectOptions.label);
			}
		});
	}


	onChangeHandler = (value, id) => {
		let { data } = this.state;
		data[id] = value;
		this.setState({ data });
	}

	onAmountChangeHandler = (value, id) => {
		if (decimalNumber(value) || value == '') {
			let { data } = this.state;
			data[id] = value;
			this.setState({ data });
		}
	}

	onSave = () => {
		let { data, productOptions } = this.state;
		if (data.product_list_inclusive.length === 0 || (data.product_list_inclusive.length === 1 && data.product_list_inclusive[0] === 'All Products')) {
			let products = [];
			for (var i in productOptions) {
				if (data.product_list_exclusive.indexOf(productOptions[i].label) === -1 && productOptions[i].label !== 'All Products') {
					products.push(productOptions[i].label);
				}
			}
			data.product_list_inclusive = products;
		} else if (data.product_list_exclusive.length === 0 || (data.product_list_exclusive.length === 1 && data.product_list_exclusive[0] === 'All Products')) {
			let products = [];
			for (var i in productOptions) {
				if (data.product_list_inclusive.indexOf(productOptions[i].label) === -1 && productOptions[i].label !== 'All Products') {
					products.push(productOptions[i].label);
				}
			}
			data.product_list_exclusive = products;
		}
		this.props.addCreditDetails(data, this.props.isType);
		this.props.toggleAdd();
	}

	onUpdate = () => {
		const { data } = this.state;
		data.mode_type = this.props.isEditDamagesData.mode_type;
		data.load_type = this.props.isEditDamagesData.load_type;
		this.props.updateCreditDetails(data, this.props.isType);
		this.props.toggleAdd();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.materialList) {
			let productOptions = [{ label: 'All Products', value: 'All Products' }];
			for (var i in nextProps.materialList) {
				const productName = nextProps.materialList[i]['material_name'];
				productOptions.push({ label: productName, value: productName });
			}
			this.setState({ productOptions });
		}
	}

	validateRate = (val) => {
		return val.toString().match(/^\d{1,8}(\.\d{1,3})?$/)
	}

	validateAmt = (val) => {
		return val.match(/^\d{1,8}(\.\d{1,3})?$/)
	}

	validData = ({ data } = this.state) => {
		return (this.validateRate(data.damage_rate) && this.validateAmt(data.max_amnt_to_be_claimed_transporter) && this.validateAmt(data.min_amnt_to_be_claimed_transporter) && data.remarks && data.sub_type && data.claim_uom && data.condition);
	}


	render() {
		const { data } = this.state;
		const disable = this.validData();

		return (<div className="add-company-wrap add-provider-root add-row">
			<div onClick={this.props.toggleAdd} className="overlay">
				<div className="modal-popup" onClick={(e) => { e.stopPropagation() }}>
					<div className="heading">
						{`${this.props.isEditDamagesData ? 'Edit' : 'Add'} Damages`}
					</div>
					<form className="form-section" autoComplete="nopes">
						<div className="select-wrap">
							<div className="label">Type</div>
							<Select className={'select'} value={data.sub_type ? { label: data.sub_type, value: data.sub_type } : null} onChange={this.handleChange.bind(this, 'sub_type')} options={[{ label: 'Cold Chain', value: 'Cold Chain' }, { label: 'Non Cold Chain', value: 'Non Cold Chain' }]} />
						</div>
						<div className="select-wrap">
							<div className="label">Condition</div>
							<Select className={'select'} value={data.condition ? uomEquality.filter(val => { return val.value === data.condition }) : null} onChange={this.handleChange.bind(this, 'condition')} options={uomEquality} />
						</div>
						<InputText placeholder={'Damages Threshold Amount'} label={'Damages Threshold Amount'} id={'damage_rate'} value={this.state.data.damage_rate} changeHandler={this.onAmountChangeHandler} type={'number'} />

						<InputText placeholder={'Max Amount Claimed'} label={'Maximum Amount To Claimed From Trasporter'} id={'max_amnt_to_be_claimed_transporter'} value={this.state.data.max_amnt_to_be_claimed_transporter} changeHandler={this.onAmountChangeHandler} type={'number'} />
						<InputText placeholder={'Min Amount Claimed'} label={'Minimum Amount To Claimed From Trasporter'} id={'min_amnt_to_be_claimed_transporter'} value={this.state.data.min_amnt_to_be_claimed_transporter} changeHandler={this.onAmountChangeHandler} type={'number'} />
						<div className={'select-wrap'}>
							<div className="label">UOM</div>
							<Select className={'select'} value={data.claim_uom ? { label: data.claim_uom, value: data.claim_uom } : null} options={damagesUOM} onChange={this.handleChange.bind(this, 'claim_uom')} />
						</div>
						<div className={'select-wrap product-details'}>
							<div className="label">Product Type Includes</div>
							<Select value={data.product_list_inclusive.length ? data.product_list_inclusive.map(val => { return { label: val, value: val } }) : null} onChange={this.handleChange.bind(this, 'product_list_inclusive')} options={this.state.productOptions} isMulti />
						</div>
						<div className={'select-wrap product-details'}>
							<div className="label">Product Type Excludes</div>
							<Select value={data.product_list_exclusive.length ? data.product_list_exclusive.map(val => { return { label: val, value: val } }) : null} onChange={this.handleChange.bind(this, 'product_list_exclusive')} options={this.state.productOptions} isMulti />
						</div>
						<InputText placeholder={'Remark'} label={'Remark'} id={'remarks'} value={this.state.data.remarks} changeHandler={this.onChangeHandler} />
					</form>
					<div className="submit-section submit-area">
						{
							<div className="button-section">
								<div className="button-section">{!this.props.isEditDamagesData && <div className="button-section">
									<Button className="add-button" click={this.onSave} disabled={!disable} value="Save" />
								</div>}</div>
								<div className="button-section">{this.props.isEditDamagesData && <div className="button-section">
									<Button className="add-button" click={this.onUpdate} disabled={!disable} value="Update" />
								</div>}</div>
							</div>

						}

						<div className="button-section" >
							<div className="add-button" onClick={this.props.toggleAdd}>Close</div>
						</div>
					</div>
				</div>
			</div>
		</div>)
	}
}

export default AddDamages;