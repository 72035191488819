import React, { Component } from 'react';
import Select from 'react-select';
import InputText from '../../../common/components/input-text/input-text';
import { uomType, detentionType, chainType } from '../../../../utils/constants';
import '../reconciliation.less';
import { decimalNumber } from '../../../../utils/validate';
import Button from '../../button-classic/button-classic';
import Delete from '../../../../assets/images/delete.svg';
import Add from '../../../../assets/images/add.svg';


class Detention extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                min_km: this.props.editData ? this.props.editData.min_km : '',
                max_km: this.props.editData ? this.props.editData.max_km : '',
                amount: this.props.editData ? this.props.editData.amount : '',
                vehicles: this.props.editData ? this.props.editData.vehicles : '',
            },
            slabDetails: this.props.slabDetails,
            slabindex: this.props.slabindex || ''
        }
    }

    componentDidMount = () => {
        this.props.getConfiguredVehicles();
        let vehicles = [];
        (this.props.configuredVehicles || []).forEach(vehicle => {
            vehicles.push({
                name: vehicle.name,
                id: vehicle.id,
                vehicle_capacity: vehicle.vehicle_capacity
            })
        })
        this.setState({ vehicles });
     }

    handleChange = (id , selectOptions) => {
        let { data } = this.state;
        data[id] = selectOptions;
        this.setState({ data });
    }


    onChangeHandler = (value, id) => {
        let { data } = this.state;
        data[id] = value;
        this.setState({ data });
    }

     onAmountChangeHandler = (value, id) => {
      if(decimalNumber(value) || value == '') {  
      let { data } = this.state;
        data[id] = value;
        this.setState({ data });}
    }

    onSave = () => {
        let { slabDetails, data } = this.state;
        data.max_km = data.max_km ? Number(data.max_km) : data.max_km;
        data.min_km = data.min_km ? Number(data.min_km) : data.min_km;
        data.amount = data.amount ? Number(data.amount) : data.amount;
        slabDetails.push(data);
        this.props.addChargeDetails({slabs: slabDetails}, 33);
        this.props.toggleAdd();
    }

    updateDetails = () => {
        let {slabDetails, slabindex, data} = this.state;
        slabDetails[slabindex].max_km = data.max_km ? Number(data.max_km) : data.max_km;
        slabDetails[slabindex].min_km = data.min_km ? Number(data.min_km) : data.min_km;
        slabDetails[slabindex].amount = data.amount ? Number(data.amount) : data.amount;
        slabDetails[slabindex].vehicles = data.vehicles;
        this.props.updateCreditDetails(this.state.slabDetails, 33);
        this.props.toggleAdd();
    }
   
  
   render() {
       let { data }= this.state;
       return(<div className = "add-company-wrap add-provider-root add-row detention">
            <div onClick = {this.props.toggleAdd} className = "overlay">
                <div className = "modal-popup" onClick={(e) => { e.stopPropagation() }} style={{width: '1000px'}}>
                    <div className = "heading">
                        {`${this.props.editData ? 'Edit' : 'Add'} Unloading Charges`}
                    </div>
                    <form className = "form-section" autoComplete = "nopes">
                        <div className={'fuel-escalation-table'}>
                            <div className={'list-section'}>
                                <div className={'table-header'} style={{width: '45%'}}>Vehicle Type</div>
                                <div className={'table-header'} style={{width: '25%'}}>Min</div>
                                <div className={'table-header'} style={{width: '25%'}}>Max</div>
                                <div className={'table-header'} style={{width: '25%'}}>Amount</div>
                            </div>
                            <div className={'list-section deliveryPerformance'}>
                                    <div className={'table-row align-center'}>
                                        <Select 
                                            className={'select select-max-vehcile-type'} 
                                            isMulti 
                                            value={data.vehicles} 
                                            onChange={this.handleChange.bind(this, 'vehicles')} 
                                            options={this.state.vehicles}
                                            getOptionLabel ={(option)=> option.name}
                                            getOptionValue ={(option)=> option.id}
                                        />
                                    </div>
                                    <div className={'table-row align-center'} style={{marginLeft: '50px'}}>
                                        <InputText 
                                            placeholder={'Min. KM'} 
                                            id='min_km' 
                                            value={data.min_km}
                                            changeHandler={this.onChangeHandler.bind(this)}
                                            type={'number'}
                                        />
                                    </div>
                                    <div className={'table-row align-center'} style={{marginLeft: '0px'}}>
                                        <InputText 
                                            placeholder={'Max. KM'} 
                                            id='max_km' 
                                            value={data.max_km}
                                            changeHandler={this.onChangeHandler.bind(this)}
                                            type={'number'}
                                        />
                                    </div>
                                    <div className={'table-row align-center'} style={{marginLeft: '0px', marginRight: '30px'}}>
                                        <InputText 
                                            placeholder={'Amount'} 
                                            id='amount' 
                                            value={data.amount}
                                            changeHandler={this.onChangeHandler.bind(this)}
                                            type={'number'}
                                        />
                                    </div>
                                    
                                </div>
                        </div>
                    </form>
                    <div className = "submit-section submit-area">
                    {
                    <div className="button-section">
                        {!this.props.editData && <div className ="button-section">
                            <Button className ="add-button" click={this.onSave} value="Save" />
                        </div>}
                        {this.props.editData && <div className ="button-section">
                            <Button className ="add-button" click={this.updateDetails} value="Update"/>   
                        </div>}
                        </div> 
                     }
                        <div className ="button-section" >
                            <div className ="add-button" onClick={this.props.toggleAdd}>Close</div>
                        </div> 
                    
                    </div>
                </div>
            </div>
         </div>)
    }
}

export default Detention;
