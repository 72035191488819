import React, { Component } from 'react';
import ACTIONS from "../../../common/action_constant";

class FlowDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flowName: "",
            mobile: "",
            email: "",
            indentsname: "",
            password: "",
            roles: [],
            departments: [],
            boundType: null,
            serviceCategory: null,
            items: null,
            move: null,
            count: 0,
            selectedItem: null,
            addActions: false
        }
    }
    componentDidMount() {
        const { flowId } = this.props;
        const data = {
            flowId
        }
        this.props.getIndentingFlowDetails(data);
    }
    
    render() {
        const { indentingFlowDetails } = this.props;
        console.log(indentingFlowDetails);
        return (
            <div className="add-user-wrap">
               <div className="heading border-bottom">
                    <div className="flow-head email">Bound Type</div>
                    <div className="flow-head email">Indent Type</div>
                    <div className="flow-head email">Name</div>
                    <div className="flow-head email">Packing Type</div>
                    <div className="flow-head email">Service Mode</div>
                    <div className="flow-head email">Service Type</div>
                    <div className="flow-head email">Subplant</div>
                    <div className="flow-head email">UOM</div>
                    <div className="flow-head email">Item</div>

                </div>
                {!indentingFlowDetails &&
                    <div className="depot-users-list no-records"> No flows found. Please click on Add Flow button to create flow.
                </div>
                }
                { indentingFlowDetails && Object.keys(indentingFlowDetails).length > 0 &&
                    <div className="depot-users-list border-bottom">
                        <div className="flow-head email">{indentingFlowDetails.bound_type && ACTIONS.BOUND_TYPE_OPTION.find(o => o.id === indentingFlowDetails.bound_type).label}</div>
                        <div className="flow-head email">{indentingFlowDetails.indent_type && ACTIONS.INDENT_TYPE_OPTION.find(o => o.id === indentingFlowDetails.indent_type).label}</div>
                        <div className="flow-head email">{indentingFlowDetails.name}</div>
                        <div className="flow-head email">{indentingFlowDetails.packing_type && ACTIONS.PACKING_TYPE.find(o => o.id === indentingFlowDetails.packing_type).label}</div>
                        <div className="flow-head email">{indentingFlowDetails.service_mode && ACTIONS.MODE_OPTION.find(o => o.id === indentingFlowDetails.service_mode).label}</div>
                        <div className="flow-head email">{indentingFlowDetails.service_type && ACTIONS.SERVICE_CATEGORY_OPTION.find(o => o.id === indentingFlowDetails.service_type).label}</div>
                        <div className="flow-head email">{indentingFlowDetails.subplant_name}</div>
                        <div className="flow-head email">{indentingFlowDetails.uom && ACTIONS.UOM_OPTION.find(o => o.id === indentingFlowDetails.uom).label}</div>
                        <div className="flow-head email">{indentingFlowDetails.item_name}</div>
                    </div>
                }
            <p className="mt-10 mb-10">Source Moves</p>
            <div className="heading border-bottom">
                <div className="flow-head email">Name</div>
                <div className="flow-head email">Actions</div>
                <div className="flow-head email">User List</div>
                <div className="flow-head email">Check List</div>
            </div>
            { indentingFlowDetails && indentingFlowDetails.moves && indentingFlowDetails.moves.map((move) => {
                return (
                    <div className="depot-users-list border-bottom">
                        <div className="flow-head email">{move.name}</div>
                        <div className="flow-head email">
                            {
                                move.actions && move.actions.map((actions) => {
                                    return (
                                        <ul>
                                            <li>{actions.name}</li>
                                        </ul>
                                    )
                                })
                            }
                        </div>
                        <div className="flow-head email">
                            {
                                move.users && move.users.map((users) => {
                                    return (
                                        <ul>
                                            <li>{users.user_name}</li>
                                        </ul>
                                    )
                                })
                            }
                        </div>
                        <div className="flow-head email">
                            {
                                move.checklists && move.checklists.map((checklists) => {
                                    return (
                                        <ul>
                                            <li>{checklists.name}</li>
                                        </ul>
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            })
        }
        <p className="mt-10 mb-10">Destination Moves</p>
            <div className="heading border-bottom">
                <div className="flow-head email">Name</div>
                <div className="flow-head email">Actions</div>
                <div className="flow-head email">User List</div>
                <div className="flow-head email">Check List</div>
            </div>
            { indentingFlowDetails && indentingFlowDetails.destinationMoves && indentingFlowDetails.destinationMoves.map((destinationMoves) => {
                return (
                    <div className="depot-users-list border-bottom">
                        <div className="flow-head email">{destinationMoves.name}</div>
                        <div className="flow-head email">
                            {
                                destinationMoves.actions && destinationMoves.actions.map((actions) => {
                                    return (
                                        <ul>
                                            <li>{actions.name}</li>
                                        </ul>
                                    )
                                })
                            }
                        </div>
                        <div className="flow-head email">
                            {
                                destinationMoves.users && destinationMoves.users.map((users) => {
                                    return (
                                        <ul>
                                            <li>{users.user_name}</li>
                                        </ul>
                                    )
                                })
                            }
                        </div>
                        <div className="flow-head email">
                            {
                                destinationMoves.checklists && destinationMoves.checklists.map((checklists) => {
                                    return (
                                        <ul>
                                            <li>{checklists.name}</li>
                                        </ul>
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            })
        }
    </div>
        );
    }
}

export default FlowDetail;