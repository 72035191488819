import React, { Component, Fragment } from 'react';
import Search from '../../common/components/search-text/search-text';
import Select from 'react-select';
import Button from '../../home/button-classic/button-classic';
import './location_network.less';
import AddChain from './add_chain/add_chain';
import Delete from '../../../assets/images/delete.svg';
import Edit from '../../../assets/images/edit.svg';
import { connect } from "react-redux";
import ACTIONS from '../../common/action_constant';
import EditChain from './edit_chain/edit_chain';
import {
	netWorkTypes, movementOptions, GREENPLY_COMPANY_IDS, CARGILL_COMPANY_IDS, AWL_COMPANY_IDS,
	getEmail,
	getUserId
} from "../../../utils/common";
import { toPascalCase } from "../../../utils/validate";
import AddDepotConsignee from './add_depot_consignee/addDepotConsignee';
import EditDepotConsignee from './edit_depot_consignee/editDepotConsignee';
import Back from '../../../assets/images/back.svg';
import { withRouter } from 'react-router';



class LocationNetwork extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAddChain: false,
			isAddDepotConsignee: false,
			offset: 0,
			isEditChain: false,
			isEditDepotConsignee: false,
			editData: null,
			search: '',
			searchDepot: '',
			selectedOption: { label: 'All', value: 0 },
			movementOptions: { label: 'All', value: 0 },
			downloadUrl: '',
			downloadDUrl: '',
			downloadUrlTemp:'',
			downloadUrlDTemp:'',
			count: 10,
			showPlants: true,
		}
	}

	onAddChain = () => {
		this.setState({ isAddChain: !this.state.isAddChain });
	}
	onAddDepotConsignee = () => {
		this.setState({ isAddDepotConsignee: !this.state.isAddDepotConsignee });
	}

	componentDidMount = () => {
		const { dispatch } = this.props;
		let data = "?company_id=" + this.props.company_id;
		dispatch({ type: ACTIONS.PLANTS.GET_COMPANY_WISE_ZONES, data });
		dispatch({ type : ACTIONS.COMPANY_ACCOUNTS.GET_VEHICLE_DETAILS });
		this.getNetworkDetails();
		this.getInventoryItemData();
		this.getDepotConsignee();
	}

	getNetworkDetails = () => {
		let data = this.props.company_id + "&limit=" + 10 + "&offset=" + this.state.offset;
		if (this.state.search) {
			data += "&search=" + this.state.search;
		}
		if (this.state.selectedOption.value !== 0) {
			data += "&chainType=" + this.state.selectedOption.value;
		}
		if (this.state.movementOptions.value !== 0) {
			data += "&movementType=" + this.state.movementOptions.value;
		}
		const { dispatch } = this.props;
		dispatch({ type: ACTIONS.MASTER.LOCATION_NETWORK.GET_NETWORK_CHAIN, data });
	}
	getDepotConsignee = () => {
		let companyId = this.props.company_id;
		let params = `?companyId=${companyId}&search=${this.state.searchDepot}`
		const { dispatch } = this.props;
		dispatch({ type: ACTIONS.MASTER.LOCATION_NETWORK.GET_DEPOT_CONSIGNEE, params });
	}

	getInventoryItemData = () => {
		const data = {
			offset : this.state.offset,
			count : this.state.count
		}
		if(this.state.search) {
			data.search = this.state.search;
		}
		this.getInventoryItem(data);
	}

	getInventoryItem = (data) => {
		const company_id = this.props.company_id;
		let param = `${company_id}&count=${data.count}&offset=${data.offset}`;
		if(data.search) {
			param += `&search=${data.search}`;
		}
		const { dispatch } = this.props;
		dispatch({ type : ACTIONS.MASTER.INVENTORY_ITEM.GET_INVENTORY_ITEM, param, company_id });
	}
	
	addNetworkChain = (data) => {
		data.company_id = this.props.company_id;
		data['master_type'] = 2;
		data['user_email'] = getEmail();
		data['new_flag'] = true;

		const param = this.props.company_id + "&limit=" + 10 + "&offset=" + this.state.offset;
		const { dispatch } = this.props;
		dispatch({ type: ACTIONS.MASTER.LOCATION_NETWORK.ADD_NETWORK_CHAIN, data, param });
	}

	AddDepotConsignee = (data) => {
		data.company_id = this.props.company_id;
		const param = this.props.company_id;
		const onSuccess = ()=>{
			this.getDepotConsignee()
		}
		const { dispatch } = this.props;
		dispatch({ type: ACTIONS.MASTER.LOCATION_NETWORK.ADD_DEPOT_CONSIGNEE, data, param, onSuccess});
	}

	onEditChain = (data={}) => {
		const trackDetails = JSON.parse(JSON.stringify(data));
		trackDetails['master_type'] = 2;
		trackDetails['user_email'] = getEmail();
		trackDetails['update_flag'] = true;
		if(!!trackDetails.previous_data){
		  delete trackDetails.previous_data	
		}
		this.setState({ isEditChain: !this.state.isEditChain, editData: data,
			trackDetails
		});

	}
	onEditDepotConsignee = (data) => {
		this.setState({ isEditDepotConsignee: !this.state.isEditDepotConsignee, editData: data });

	}

	previous = () => {
		if (this.state.offset > 0) {
			this.setState({
				offset: this.state.offset - 10
			}, () => {
				this.getNetworkDetails()
			});
		}
	}
	next = () => {
		this.setState({
			offset: this.state.offset + 10
		}, () => {
			this.getNetworkDetails()
		});
	}

	updateNetworkChain = (data) => {
		const { trackDetails } = this.state;
		data.company_id = this.props.company_id;
		data['master_type'] = 2;
		data['user_email'] = getEmail();
		data['update_flag'] = true;
		data['previous_data'] = trackDetails;
		const param = this.props.company_id + "&limit=" + 10 + "&offset=" + this.state.offset;
		const { dispatch } = this.props;
		dispatch({ type: ACTIONS.MASTER.LOCATION_NETWORK.UPDATE_NETWORK_CHAIN, data, param });
	}
	updateDepotConsignee = (data) => {
		data.company_id = this.props.company_id;
		const onSuccess = ()=>{
			this.getDepotConsignee()
		}
		
		const { dispatch } = this.props;
		dispatch({ type: ACTIONS.MASTER.LOCATION_NETWORK.UPDATE_DEPOT_CONSIGNEE, data,onSuccess });
	}

	changeHandler = (value, id) => {
		this.setState({ [id]: value });
	}

	submitSearch = () => {
		this.getNetworkDetails();
	}
	submitSearchDepot = () => {
		this.getDepotConsignee();
	}

	clearFilterOnCancel = (e, type) => {
		this.setState({ [type]: '' }, () => {
			this.getNetworkDetails();
		});
	}

	deleteNetworkChain = (val) => {
		const { dispatch,match } = this.props;
		const companyId =  match.params.id;
		const data = {
			["location_network_id"]: val.location_network_id,
			['master_type']: 2,
			['user_email']: getEmail(),
			["delete_flag"]: true,
			["user_id"]: getUserId(),
			["company_id"]:companyId,
			["consignee_code"]:val.branch_code,
		}
		const param = this.props.company_id + "&limit=" + 10 + "&offset=" + this.state.offset;
		dispatch({ type: ACTIONS.MASTER.LOCATION_NETWORK.DELETE_NETWORK_CHAIN, data, param });
	}
	deleteDepotConsignee = (consignee_id) => {	
		const { dispatch } = this.props;
		const params =`?consignee_id=${consignee_id}`
		const onSuccess = ()=>{
			this.getDepotConsignee()
		}
		dispatch({ type: ACTIONS.MASTER.LOCATION_NETWORK.DELETE_DEPOT_CONSIGNEE, params ,onSuccess});
	}

	handleSelectChange = (selectedOption) => {
		this.setState({ selectedOption }, () => {
			this.getNetworkDetails();
		})
	}

	handleSelectMove = (movementOptions) => {
		this.setState({ movementOptions }, () => {
			this.getNetworkDetails();
		})
	}

	getNetworkType = (type) => {
		const data = netWorkTypes.filter(val => val.value == type)
		return (data[0] || {}).label;
	}

	uploadNetworkChain = (e) => {
		let formData = new FormData();
		formData.append('file', e.target.files[0]);
		const param = this.props.company_id + "&limit=" + 10 + "&offset=" + this.state.offset;
		const { dispatch } = this.props;
		dispatch({ type: ACTIONS.MASTER.LOCATION_NETWORK.UPLOAD_NETWORK_CHAIN, data: formData, param, companyId: this.props.company_id });
	}
	
	uploadDepotConsignee = (e) => {
		let formData = new FormData();
		formData.append('file', e.target.files[0]);
		const param = this.props.company_id ;
		// + "&limit=" + 10 + "&offset=" + this.state.offset;
		const { dispatch } = this.props;
		const onSuccess= ()=>{
			this.getDepotConsignee()
		}
		dispatch({ type: ACTIONS.MASTER.LOCATION_NETWORK.UPLOAD_DEPOT_CONSIGNEE, data: formData, param, companyId: this.props.company_id ,onSuccess});
	}

	uploadChain = () => {
		this.uploadChainUrl.click();
	}
	uploadDepot = () => {
		this.uploadDepotUrl.click();
	}

	onDownload = () => {
		const { dispatch } = this.props;
		const param = this.props.company_id;
		dispatch({
			type: ACTIONS.MASTER.LOCATION_NETWORK.DOWNLOAD_NETWORK_CHAIN,
			param,
			onSuccess: ((data) => {
				this.setState({ downloadUrl: data ? data.data[0].Link : '' }, () => {
					this.downloadChainUrl.click();
				});
			})
		});
	}
	onDownloadDepot = () => {
		const { dispatch } = this.props;
		const param = this.props.company_id;
		dispatch({
			type: ACTIONS.MASTER.LOCATION_NETWORK.DOWNLOAD_DEPOT_CONSIGNEE,
			param,
			onSuccess: ((data) => {
				this.setState({ downloadDUrl: data ? data.data[0].Link : '' }, () => {
					this.downloadDepotUrl.click();
				});
			})
		});
	}

	getState = () => {
		const { dispatch } = this.props;
		dispatch({ type: ACTIONS.MASTER.LOCATION_NETWORK.GET_STATE, data: { company_id: this.props.company_id } });
	  }
	  getCountry = () => {
		const { dispatch } = this.props;
		dispatch({type: ACTIONS.MASTER.LOCATION_NETWORK.GET_COUNTRY });
	  }
	syncWithVIMS = (branch, company) => {
		let branches = [];
		branches.push(branch);
		this.props.syncChainWithVIMS(branches, company);
        this.getNetworkDetails();
	}
	onDownloadTemp = () => {
		const { dispatch } = this.props;
		const param = this.props.company_id;
		dispatch({
			type: ACTIONS.MASTER.LOCATION_NETWORK.DOWNLOAD_NETWORK_CHAIN_TEMP,
			param,
			onSuccess: ((data) => {
				this.setState({ downloadUrlTemp: data ? data.data[0].Link : '' }, () => {
					this.downloadChainUrlTemp.click();
				});
			})
		})
	};
	onDownloadDepotTemp = () => {
		const { dispatch } = this.props;
		const param = this.props.company_id;
		dispatch({
		  type: ACTIONS.MASTER.LOCATION_NETWORK.DOWNLOAD_DEPOT_CONSIGNEE_TEMP,
		  param,
		  onSuccess: ((data) => {
			this.setState({ downloadUrlDTemp: data ? data.data[0].Link : '' }, () => {
			  // Use anchor tag to trigger the download
			  const link = document.createElement('a');
			  link.href = this.state.downloadUrlDTemp;
			  link.setAttribute('download', 'depot_consignee_template.xlsx');
			  document.body.appendChild(link);
			  link.click();
			  document.body.removeChild(link);
			});
		  })
		});
	  };

	  handleOnClickForAuditBtn = ()=>{
		const { history,match} = this.props;
		const companyId =  match.params.id
		const masterType = 2
		const navUrl = `/auditScreen/${companyId}/${masterType}`
		history.push(navUrl)
		}
	  
	render() {
		const company_id = this.props.company_id;
		const isCargill = CARGILL_COMPANY_IDS.includes(company_id);
		const { showPlants } = this.state;
		return (
			<div className={'location-network'}>
				<div className='plants' style={{ display: showPlants ? 'block' : 'none' }}>
					<div className='depobtn-div'>
					{AWL_COMPANY_IDS.includes(company_id) && <Button className={`depots-btn plantsDepos-btn hj ${!showPlants ? 'active' : ''}`} value={'Depot Consignee'} click={() => this.setState({ showPlants: false })}></Button>}
					</div>
				
				<br></br>
				<br></br>
				<br></br>
				<div>
					<Search id="search" value={this.state.search} placeholder="Search..." changeHandler={this.changeHandler} click={this.submitSearch} canClear={this.state.search !== ''} handleClearFilter={(e) => this.clearFilterOnCancel(e, "search")} />
					<Select value={this.state.selectedOption} options={netWorkTypes} className="select" onChange={this.handleSelectChange} />
					<Select value={this.state.movementOptions} options={movementOptions} className="select" onChange={this.handleSelectMove}  />
					{!GREENPLY_COMPANY_IDS.includes(company_id) && <Button value={'+ Chain'} click={this.onAddChain} />}
					<button
						className="button-classic"
						onClick={this.handleOnClickForAuditBtn}
					>
						Audit
					</button>
					<Button value={'Download'} click={this.onDownload} />
					<a href={this.state.downloadUrl} style={{ display: 'none' }} ref={(e) => { this.downloadChainUrl = e; }} />
					{!GREENPLY_COMPANY_IDS.includes(company_id) && 
					<Fragment>
						<Button value={'Upload'} click={this.uploadChain} />
						<input type='file' style={{ display: 'none' }} ref={(e) => { this.uploadChainUrl = e; }} onChange={this.uploadNetworkChain} onClick={(e) => { e.target.value = null }} />
					</Fragment>}
					{!GREENPLY_COMPANY_IDS.includes(company_id) && 
					<Fragment>
						<Button value={'Download Temp'} click={this.onDownloadTemp} />
						<a href={this.state.downloadUrlTemp} style={{ display: 'none' }} ref={(e) => { this.downloadChainUrlTemp = e; }} />
					</Fragment>}
					
				</div>
				<div className={'chain-details-section'}>
					<div className={'list-section header'}>
						<div className={'tabel-header'}>Network Type</div>
						<div className={'tabel-header'}>Company Name</div>
						<div className={'tabel-header address'}>Address</div>
						<div className={'tabel-header'}>Branch</div>
						<div className={'tabel-header'}>Actions</div>
					</div>

					{this.props.netWorkDetails && this.props.netWorkDetails.map((val, index) => {
						return (
							<div className={'list-section content'}>
								<div className={'tabel-header'} style={isCargill ? {whiteSpace: 'normal'} : {}}>{this.getNetworkType(val.network_type || val.chain_type)}</div>
								<div className={'tabel-header'} style={isCargill ? {whiteSpace: 'normal'} : {}}>{toPascalCase(val.company_name)}</div>
								<div className={'tabel-header address'} style={isCargill ? {whiteSpace: 'normal'} : {}}>{val.address}</div>
								<div className={'tabel-header'} style={isCargill ? {whiteSpace: 'normal'} : {}}>{toPascalCase(val.consignee_branch)}</div>
								<div className={'tabel-header'} style={isCargill ? {whiteSpace: 'normal', width: '140px'} : {width: '140px'}}>
									<div className={'delete-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.onEditChain(val)}></div>
									<div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.deleteNetworkChain(val)}></div>
									<div className={(val.sync_errors && val.sync_errors.length > 0) ? 'fs-12 curP sync-button error-color' : 'fs-12 curP sync-button theme-color'} onClick={() => this.syncWithVIMS(val.location_network_id, val.company_id)}>Sync to VIMS</div>
								</div>
							</div>)
					})}
				</div>
				<div className="submit-section-pagination">
					<div className="next">
						<Button value="Next" click={this.next} />
					</div>
					<div className="pre">
						<Button value="Previous" click={this.previous} />
					</div>
				</div>
				{this.state.isAddChain && <AddChain toggleAdd={this.onAddChain} zones={this.props.zoneList} vehicles={this.props.vehicles} productList={this.props.productList} addNetworkChain={this.addNetworkChain} getstateF={this.getState} stateListData={this.props.stateListData} countryListData={this.props.countryListData} getCountry={this.getCountry} companyId={this.props.company_id}/>}
				{this.state.isEditChain && <EditChain toggleAdd={this.onEditChain} zones={this.props.zoneList} vehicles={this.props.vehicles} productList={this.props.productList} updateNetworkChain={this.updateNetworkChain} data={this.state.editData} getstateF={this.getState} stateListData={this.props.stateListData} countryListData={this.props.countryListData} getCountry={this.getCountry} companyId={this.props.company_id} syncWithVIMS={this.syncWithVIMS}/>}
				</div>


				<div className='Depots' style={{ display: !showPlants ? 'block' : 'none' }}>
				{AWL_COMPANY_IDS.includes(company_id) && <Button className={`depots-btn plantsDepos-btn ${showPlants ? 'active' : ''}`}value={'Back'} click={() => this.setState({ showPlants: true })}></Button>}
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<Search id="searchDepot" value={this.state.searchDepot} placeholder="Search..." changeHandler={this.changeHandler} click={this.submitSearchDepot} canClear={this.state.searchDepot !== ''} handleClearFilter={(e) => this.clearFilterOnCancel(e, "search")} />
				{AWL_COMPANY_IDS.includes(company_id) && <Button value={'+ Depot Consignees'} click = {this.onAddDepotConsignee} />}
				
				
				<Button value={'Download'} click={this.onDownloadDepot} />
					<a href={this.state.downloadDUrl} style={{ display: 'none' }} ref={(e) => { this.downloadDepotUrl = e; }} />
					{AWL_COMPANY_IDS.includes(company_id) && 
					<Fragment>
						<Button value={'Upload'} click={this.uploadDepot} />
						<input type='file' style={{ display: 'none' }} ref={(e) => { this.uploadDepotUrl = e; }} onChange={this.uploadDepotConsignee } onClick={(e) => { e.target.value = null }} />
					</Fragment>}
					{AWL_COMPANY_IDS.includes(company_id) && 
					<Fragment>
						<Button value={' Download Temp'} click={this.onDownloadDepotTemp} />
						<a href={this.state.downloadUrlDTemp} style={{ display: 'none' }} ref={(e) => { this.downloadDepotUrlTemp = e; }} />
					</Fragment>}
					
				<table className={'chain-details-section cdsTable'}>
					<th className={'list-section header'}>
						<td className={'tabel-header address'}>Consignee Name</td>
						<td className={'tabel-header address'}>Consignee Branch</td>
						<td className={'tabel-header address'}>Primary Consignee Code</td>
						<td className={'tabel-header address'}>Consignee Email</td>
						<td className={'tabel-header address'}>Address</td>
						<td className={'tabel-header address'}>Address Type </td>
						<td className={'tabel-header address'}>Primary Consignee Contact</td>
						<td className={'tabel-header address'}>Actions</td>
					</th>
					{this.props.depotConsigneeDetails && this.props.depotConsigneeDetails.map((val, index) => {
						return (
							<tr className={'list-section content'}>
								<td className={'tabel-header address'} style={isCargill ? {whiteSpace: 'normal'} : {}}>{val.consignee_name}</td>
								<td className={'tabel-header address'} style={isCargill ? {whiteSpace: 'normal'} : {}}>{val.consignee_branch}</td>
								<td className={'tabel-header address'} style={isCargill ? {whiteSpace: 'normal'} : {}}>{val.primary_consignee_code}</td>
								<td className={'tabel-header address'} style={isCargill ? {whiteSpace: 'normal'} : {}}>{val.consignee_email}</td>
								<td className={'tabel-header address'} style={isCargill ? {whiteSpace: 'normal'} : {}}>{val.address}</td>
								<td className={'tabel-header address'} style={isCargill ? {whiteSpace: 'normal'} : {}}>{val.address_type}</td>
								<td className={'tabel-header address'} style={isCargill ? {whiteSpace: 'normal'} : {}}>{val.primary_consignee_contact}</td>
								<td className={'tabel-header'} style={isCargill ? {whiteSpace: 'normal', width: '140px'} : {width: '140px'}}>
									<div className={'delete-icon'} style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.onEditDepotConsignee(val)}></div>
									<div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.deleteDepotConsignee(val.consignee_id)}></div>
									
								</td>
							</tr>)
					})}
				</table>
				
				</div>
				{this.state.isAddDepotConsignee && <AddDepotConsignee toggleAdd={this.onAddDepotConsignee}  AddDepotConsignee={this.AddDepotConsignee}   companyId={this.props.company_id}/>}
				{this.state.isEditDepotConsignee && <EditDepotConsignee toggleAdd={this.onEditDepotConsignee}  updateDepotConsignee={this.updateDepotConsignee} data={this.state.editData}  companyId={this.props.company_id} />}
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		zones: state.plantsDepots.zones,
		netWorkDetails: state.master.netWorkDetails,
		depotConsigneeDetails:state.master.depotConsigneeDetails,
		vehicles: state.companyAccounts.vehicleType,
		productList: state.master.itemList,
		stateListData:state.master.stateList,
		countryListData:state.master.countryList,
		zoneList: state.plantsDepots.zoneList
	};
};

export default connect(mapStateToProps)(withRouter(LocationNetwork));
