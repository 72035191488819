import React, { Component } from "react";

import './sampleFile.less';
import moment from "moment";

const LOADS = [{label: 'LCL', value: 1}, {label: 'FTL', value: 2}, {label: 'Courier', value: 3}, {label:'Container', value: 4}]


class SampleFile extends Component {
  	constructor(props) {
	    super(props);
	    this.state = {
	    	setChildData: props.setChildData,
		}
	}

  	render() {
  		const { vendorMDGDetails, headers, delay_penalty_list_keys, keys, tentureFrom, tentureTo, address, allDropdownTypes } = this.props;
  		return (
  			<div className="sampleFile" id="sampleContractFile" 
  					style={{width: '700px', lineHeight: 1.5}} 
  					ref={ this.props.setChildData }>
				   <div style={{padding: '20px', lineHeight: 1.5}}>
				      <h4 style={{ textAlign: 'center' }}>LOGISTIC SERVICE PROVIDER AGREEMENT</h4>
				      <p>This Logistic Service Provider Agreement ("Agreement") is made, entered into and executed on this 23rd day of
				         September,2019 at Bangalore
				      </p>
				      <br />
				      <h2>BETWEEN</h2>
				      <p>MIS ADITYA BIRLA FASHION AND RETAIL LIMITED, a company registered in India under the Companies Act, 1956 and having its
				         office at 4th & 5th Floor, Agastya Corporate Park, Sunder Bung Lane, LBS Road, Kuria West, Mumbai - 400070,(hereinafter
				         referred to as "ABFRL" or "Company" which expression shall include their respective successors and permitted assigns),
				         represented by its authorized signatory, Mr. Rajesh Annamalai- Chief Commercial Officer of the FIRST PART
				      </p>
				      <br />
				      <h2>AND</h2>
				      <p>M/s. {this.props.company}, a company registered in India under the Companies
				         Act, 1956 and having its registered office at HO: 113/2 , Shed No. 6 & 282 , Manchamma Industrial Estate, Doddabele
				         Road, Near KSRTC Bus Depot, Kengri , Bangalore 560 060 (hereinafter called "Service Provider"or "LSP" which expression
				         shall unless repugnant to the context or meaning thereof mean and include its successors and permitted assigns)
				         represented by its authorized signatory Mr. H.S. Suresha - Managing Director of the Other Part
				      </p>
				      <br />
				      <p>The expressions 'Company' and ‘LSP' are hereinafter collectively referred to as the 'parties' and individually as
				         'party'.
				      </p>
				      <ol style={{ padding: '1px 20px' }}>
				         <li>WHEREAS the Company is inter alia engaged in the business of developing and/or manufacturing, marketing, distribution
				            and sale of readymade garments and accessories under reputed brand names like 'Louis Philippe', 'Allen Sony', 'Peter
				            England', 'Van Heusen', *People' its sub-brands and brand extensions and many other brand names; and
				         </li>
				         <li>AND WHEREAS the LSP is engaged in the business of providing forward and reverse logistic services for various
				            companies across India;
				         </li>
				         <li>AND WHEREAS the Company is desirous of engaging a logistic service provider for the transportation of Company
				            Products from the warehouse to retail stores and vice versa as well as interplant transportation;
				         </li>
				         <li>AND WHEREAS the LSP has evinced interest in providing the logistic services to the Company and has further
				            represented that it has all the experience, expertise and resources to provide the desired services to the Company;
				         </li>
				         <li>AND WHEREAS the Company relying upon representations of the LSP has agreed to engage the LSP for providing logistic
				            services subject to terms and conditions herein after set out
				         </li>
				      </ol>
				   </div>
				   <div style={{padding: '50px'}}>
				      <p >NOW THEREFORE the Parties are desirous of recording the terms and conditions of this Agreement as set out here below</p>
				      1. 	DEFINITIONS & INTERPRETATION:<br /><br />
				      1.1 	In this Agreement, unless the context otherwise requires, the following words shall have the meanings ascribed to them
				      hereunder:
				      <table>
				         <tr>
				            <td><span style={{ textAlign: 'center' }}>"AGREEMENT"</span></td>
				            <td><span style={{ textAlign: 'center' }}>Shall mean and include this Agreement, and include any schedule or annexure attached to
				               it or
				               incorporated in it by reference and shall include any modifications of this Agreement as may be mutually
				               agreed in
				               writing by the Parties.</span>
				            </td>
				         </tr>
				         <tr>
				            <td><span style={{ textAlign: 'center' }}>"BUSINESS DAY OR WORKING DAY"</span></td>
				            <td><span style={{ textAlign: 'center' }}>Shall mean a day (other than Sunday, national holidays and festival holidays.)</span>
				            </td>
				         </tr>
				         <tr>
				            <td><span style={{ textAlign: 'center' }}>"BUSINESS TO TRANSACTIONS (B2B)" BUSINESS"</span></td>
				            <td><span style={{ textAlign: 'center' }}>Shall mean transportation of Company Products between Company warehouses and
				               consignees.</span>
				            </td>
				         </tr>
				         <tr>
				            <td><span style={{ textAlign: 'center' }}>""CONSIGNEE"</span></td>
				            <td><span style={{ textAlign: 'center' }}>Shall mean the person named as consignee in the goods forwarding note/ railway receipt
				               Under this Agreement, consignee includes but is not limited to retail stores, departmental stores,
				               wholesalers, trade, value stores, factories that are intended recipients of services as communicated by the
				               Company to the Logistic Service Provider (LSP).
				               </span>
				            </td>
				         </tr>
				         <tr>
				            <td><span style={{ textAlign: 'center' }}>"CONSIGNOR"</span></td>
				            <td><span style={{ textAlign: 'center' }}>Shall mean a person, named as consignor in the goods forwarding note/ railway receipt,
				               by whom or on whose behalf the documents, goods or articles covered by such forwarding note/railway receipt
				               are entrusted to the LSP for carriage thereof. Under this Agreement, the Consignor is The Company</span>
				            </td>
				         </tr>
				         <tr>
				            <td><span style={{ textAlign: 'center' }}>"COMPANY PRODUCTS OR CONSIGNEMENT"</span></td>
				            <td><span style={{ textAlign: 'center' }}>Shall mean finished goods of the Company, which includes ready-made Garments, Accessories
				               and other related goods
				               developed and/or manufactured by the consignor under world renowned brand names like 'Louis Philippe', 'Allen
				               Solly',
				               Teter England', `People% *Van Heusen' its sub-brands and brand extensions and many other brand names and are
				               named in
				               the goods forwarding note.</span>
				            </td>
				         </tr>
				         <tr>
				            <td><span style={{ textAlign: 'center' }}>"DELIVERY"</span></td>
				            <td><span style={{ textAlign: 'center' }}>Shall mean (a) collection of the Company Products by LSP from the location(s) agreed
				               between the Parties; and (b) delivery of such Products to the Consignee/ Consignees at the designated
				               location(s) and taking the receiver's
				               confirmation signatures on receipt; Delivery or its other grammatical variation shall be construed
				               accordingly.</span>
				            </td>
				         </tr>
				         <tr>
				            <td><span style={{ textAlign: 'center' }}>"DELIVERY PERSONNEL"</span></td>
				            <td><span style={{ textAlign: 'center' }}>Shall mean the person/persons engaged by LSP for delivery or return of Company Products
				               under this Agreement.</span>
				            </td>
				         </tr>
				         <tr>
				            <td><span style={{ textAlign: 'center' }}>"FORWARDING NOTE"</span></td>
				            <td><span style={{ textAlign: 'center' }}>Shall mean a note executed by the consignor in such form and manner as may be prescribed,
				               which shall include a
				               declaration about the value of the consignment and the nature of goods.</span>
				            </td>
				         </tr>
				      </table>
				   </div>
				   <div style={{padding: '50px'}}>
				      <table>
				         <tr>
				            <td><span style={{ textAlign: 'center' }}>"LORRY RECIEPT/ RAILWAY RECIEPT"</span></td>
				            <td><span style={{ textAlign: 'center' }}>Shall mean a receipt made by the LSP once the Company Products are loaded on the vehicle
				               for delivery. It is been issued
				               in triplicate and the original is retained by the Consignor. It contains the Vehicle Number, train number,
				               Pickup
				               address and delivery address, number of packages, material description, vehicle type and date of
				               dispatch.</span>
				            </td>
				         </tr>
				         <tr>
				            <td><span style={{ textAlign: 'center' }}>"LOGISTIC SERVICES"</span></td>
				            <td><span style={{ textAlign: 'center' }}>Shall mean and include the service of collecting the Company Products from Consignor's
				               warehouse's and delivering them
				               to the Consignee's for and on behalf of the Consignor and in case of reverse pick-up, collecting the Company
				               Products
				               from the Consignee/s and delivering them to the Consignor at the location's designated by the
				               Consignor.</span>
				            </td>
				         </tr>
				         <tr>
				            <td><span style={{ textAlign: 'center' }}>"LOGISTIC SERVICE PROVIDER"</span></td>
				            <td><span style={{ textAlign: 'center' }}>Shall mean the carrier of Company Products from the Consignor for delivery to
				               Consignee/s and reverse pick up (as
				               defined below) from the Consignee/s of the Company Products.</span>
				            </td>
				         </tr>
				         <tr>
				            <td><span style={{ textAlign: 'center' }}>"MODE OF TRANSPORT"</span></td>
				            <td><span style={{ textAlign: 'center' }}>Shall mean transportation of Company Products by Road & Train</span></td>
				         </tr>
				         <tr>
				            <td><span style={{ textAlign: 'center' }}>"ODA"</span></td>
				            <td><span style={{ textAlign: 'center' }}>means For Out of Delivery Area [hereinafter for the sake of brevity the same shall be
				               referred to as ODA] location
				               Pick-up or Delivery is beyond 25 kms from the local branch then ODA charges as mentioned in Annexure</span>
				            </td>
				         </tr>
				         <tr>
				            <td><span style={{ textAlign: 'center' }}>"PROOF OF DELIVERY (POD)"</span></td>
				            <td><span style={{ textAlign: 'center' }}>Shall mean a method to establish the fact that the recipient received the contents sent
				               by the Consignor. It is a
				               document required from the carrier or driver for proper payment, POD includes the time of delivery, full
				               delivery
				               address, and the name and the signature of the consignee/person who accepted the shipment.</span>
				            </td>
				         </tr>
				         <tr>
				            <td><span style={{ textAlign: 'center' }}>"REVERSE PICK-UP"</span></td>
				            <td><span style={{ textAlign: 'center' }}>Shall have the meaning as defined in 'Scope of Services' under Annexure -A.</span>
				            </td>
				         </tr>
				         <tr>
				            <td><span style={{ textAlign: 'center' }}>"TERRITORY""</span></td>
				            <td><span style={{ textAlign: 'center' }}>Shall mean any territory, which has been mutually agreed between the Parties to be their
				               operational area.</span>
				            </td>
				         </tr>
				         <tr>
				            <td><span style={{ textAlign: 'center' }}>"SERVICE LEVEL AGREEMENT"</span></td>
				            <td><span style={{ textAlign: 'center' }}>Shall mean a contract between the LSP (carrier) and the Company (Consignor) that defines
				               the level of service expected
				               from the service provider.</span>
				            </td>
				         </tr>
				         <tr>
				            <td><span style={{ textAlign: 'center' }}>"TERRITORY"</span></td>
				            <td><span style={{ textAlign: 'center' }}>Shall mean any territory, which has been
				               mutually agreed between the Parties to be their operational area.
				               </span>
				            </td>
				         </tr>
				      </table>
				   </div>
				   <div style={{padding: '50px'}} >
				      1.2. In this Agreement:
				      <ol type="i">
				         <li>unless otherwise specified, all references to recitals, clauses and schedules are to
				            Recitals to, Clauses of, and Schedules to, this Agreement;
				         </li>
				         <li>unless the context otherwise requires, words denoting the singular number shall include the plural and vice versa and reference to one gender includes reference to 11 AND e other gender;</li>
				         <li>references to any document are to be construed as references to such document as amended, varied, novated or
				            supplemented in writing from time to time;
				         </li>
				         <li>references to any enactments, rules, regulations or ordinances shall include re-enactments, amendments and
				            extensions thereof from time to time;
				         </li>
				         <li>capitalized terms not defined in Clause 1 and used in this Agreement shall have the meaning assigned to them in the
				            respective Clauses of this Agreement;
				         </li>
				         <li>viClauses of, Recitals and Schedules to, this Agreement shall be deemed to form an integral part of this Agreement;</li>
				         <li>headings are used for convenience only and shall not affect the interpretation of this Agreement; and</li>
				         <li>any date or period of time mentioned in this Agreement shall, unless extended by
				            mutual written agreement of the Parties prior to the expiry of the specified date or period of time, be of the essence.
				         </li>
				      </ol>
				      <h3>2. SCOPE OF LOGISTIC SERVICE PROVIDER BY LSP:</h3>
				      <p>The LSP shall provide the following logistic services to the Company in the manner provided in Annexure  A -</p>
				      <ol type="a">
				         <li>Outbound & Inbound logistic services using Surface, Superfast & Train Mode respectively for the rates as mentioned
				            in Annexure - B below; and
				         </li>
				         <li>Interplant logistic services between Factories and Warehouses of ABFRL (including reverse pick-up); and</li>
				         <li>Full truck Load (FTL) logistic services,</li>
				      </ol>
				      <p>The details of the city/state from which to the city/state where (and vice versa) including the Rates and TAT
				         (Turnaround Time) along with other terms and conditions have been clearly mentioned in Annexure B below.
				      </p>
				   </div>
				   <div style={{padding: '50px'}} >
				      <h3>3. TERM</h3>
				      <ol type="a">
				         <li>This LSP Agreement shall be effective for a period of 2 (Two) years having commenced
				            from {moment(tentureFrom).format('Do MMM, YYYY')} until {moment(tentureTo).format('Do MMM, YYYY')} unless terminated earlier in accordance with the terms and conditions as
				            mentioned herein below.
				         </li>
				         <li>Prior to expiry of the TERM, the Parties may opt to renew the Agreement on mutually
				            agreeable terms stated to the other Party in writing. It is hereby expressly clarified that such renewal if any shall be
				            subject to the mutual consent of the Parties in writing. There shall not be any automatic renewal of this Agreement.
				         </li>
				      </ol>
				      <h3>4. CONSIDERATION:</h3>
				      <ol type="A">
				         <li>Service Charge: In consideration of the logistic services rendered by LSP under this
				            Agreement, the Company shall pay to LSP consideration ("Rates") as mentioned under Annexure-B subject to deduction of tax at source.
				         </li>
				         <li>
				            Manner of payment:
				            <ol>
				               <li>The Service Provider shall raise a GST compliant invoice by the 10' of every subsequent month for the previous month
				                  in which logistic services were provided along with a copy of proof of delivery (-POD") and Lorry Receipts/Consignment
				                  Note for each trip, Trip summary and all necessary correspondence issued for respective transportation and rates
				                  approval in case of Adhoc movements; and
				               </li>
				               <li>The GST compliant invoice must contain Agreement reference, consignment no., delivery date, consignee details and
				                  such other details as required by ABFRL from time to time.
				               </li>
				            </ol>
				         </li>
				         <li>Frequency of Payment:</li>
				         <br /><li>ABFRL shall pay consideration within 30 (thirty) days of receipt of the invoices provided the contents of the invoice
				         are in order and in accordance with the terms and conditions agreed herein and subject to verification and satisfaction
				         of ABFRL Authorized Representative. Service Provider shall immediately on execution of this Agreement, fill up and
				         submit the mandatory form as per the requirements of the Company providing details of RTGS along with proper
				         confirmation of the Bank to enable ABFRL to pay the bills by RTGS which shall be paid directly into the account of the
				         Service Provider.</li>
				      </ol>
				   </div>
				   <div style={{padding: '50px'}} >
				      <ul style={{listStyleType:"none"}}>
				         <li>
				            D. G S T
				            <ol>
				               <li>The invoice raised by the LSP shall be in compliance with GST Laws.</li>
				               <li>The LSP on receipt of consideration along with GST amount shall remit the GST within
				                  the stipulated period as prescribed under GST Law to the Government authorities, upload error free invoices in the GST
				                  portal in a timely manner so to enable ABFRL to avail the entire eligible input tax credit
				               </li>
				               <li>In case any input tax credit is denied to ABFRL on account of failure to upload the invoices
				                  or uploading of incorrect invoices or delay in remittance of GST or failure to pay GST to the Government authorities or
				                  for any other reason whatsoever, LSP shall make good all the losses or liabilities suffered by ABFRL ("GST Related
				                  Loss").
				               </li>
				            </ol>
				         </li>
				      </ul>
				      <h3>5. REPRESENTATIONS AND WARRANTIES OF THE COMPANY:</h3>
				      <p>The Company represents that:</p>
				      <ol>
				         <li>it is a corporation duly organized, validly existing and in good standing under the laws of the jurisdiction of its
				            incorporation;
				         </li>
				         <li>it has full right, power and authority to execute, perform and deliver this Agreement and the other obligations
				            contemplated hereby or which are ancillary hereto and to perform its obligations contemplated hereby;
				         </li>
				         <li>it is the owner of the Company Products entrusted to LSP for carriage;</li>
				         <li>all statements and information provided by it or its agents relating to the consignment under this Agreement is true
				            and correct;
				         </li>
				         <li>there are no actions, suits, proceedings or regulatory investigations ppending or, to its knowledge, threatened
				            against or affecting the Company that might affect its ability to meet and carry out its obligations under this
				            Agreement.
				         </li>
				      </ol>
				   </div>
				   <div style={{padding: '50px'}} >
				      <h3>6. REPRESENTATIONS AND WARRANTIES OF THE LSP:</h3>
				      <p>The LSP represents and warrants that:</p>
				      <ol>
				         <li>it is an organization duly organized, validly existing and in good standing under the laws
				            of the jurisdiction of its incorporation;
				         </li>
				         <li>it has all the necessary licenses, approvals and permits or authorizations required under law
				            to carry out its business and provide the logistic services as contemplated by this Agreement;
				         </li>
				         <li>it has full right, power and authority to execute, perform and deliver this Agreement and
				            the other obligations contemplated hereby or which are ancillary hereto and to perform its obligations contemplated
				            hereby;
				         </li>
				         <li>it has the requisite experience, knowledge, expertise, manpower, infrastructure, capability,
				            necessary to effectively and properly render the logistic services as agreed to under this Agreement;
				         </li>
				         <li>it can carry out its obligations under this Agreement without any delay and as per the
				            requirements of the Company contemplated hereunder; and
				         </li>
				         <li>there are no actions, suits, proceedings or regulatory investigations pending or, to its
				            knowledge, threatened against or affecting the LSP that might affect its ability to meet and carry out its obligations
				            under this Agreement
				         </li>
				      </ol>
				      <h3>7. COVENANTS OF THE COMPANY:</h3>
				      <p>The Company covenants that:</p>
				      <ol>
				         <li> It shall provide all necessary documentation and fulfil all procedural requirements to expedite clearance of the
				            shipment as may be required by LSP;
				         </li>
				         <li> It shall comply with all prevailing laws and rules in respect of the consignment before the same has been handed
				            over to LSP;
				         </li>
				         <li> It shall declare at the time of giving the consignment for delivery to LSP proper value and particulars for each
				            consignment;
				         </li>
				      </ol>
				   </div>
				   <div style={{padding: '50px'}} >
				      <ol start="4">
				         <li> It shall not provide to LSP any consignment which contains (i) anything that cannot be transported safely or
				            legally; (ii) anything that is prohibited by any law, regulation or statute, (iii) bullion, currency,
				            dangerous/hazardous goods, combustible materials, drugs/narcotics (iv) negotiable instruments in bearer form like bearer
				            cheques / drafts, money orders, promissory notes, bearer bonds, bearer share certificates, stamps, open tickets,
				            uncashed travellers' cheques, credit cards and SIM cards. In such an event Company shall be solely liable for any loss,
				            claim or damage arising from the shipment of the said consignment;
				         </li>
				         <li> It shall authorize LS P for getting clearance of the consignments/Shipment provided under this LSP Agreement from
				            the customs, entry tax and such other applicable statutory authorities. If any statutory authority requires additional
				            documentation for confirming LSP's authorized status, Company shall provide the required documentation at its expense;
				         </li>
				         <li> It shall be liable for any statutory penalties, storage charges or other expenses levied as a
				            result of the actions of customs or governmental authorities or the Company's failure to provide proper documentation
				            and/or to obtain the required license or permit;
				         </li>
				         <li> It shall pay LSP the consideration as agreed under Annexure-B on the receipt of GST
				            compliant invoices.
				         </li>
				      </ol>
				      <h3>8. COVENANTS OF THE LSP:</h3>
				      <p>The LSP covenants that:</p>
				      <ol>
				         <li> It is duly registered with the registering authority under the Carriage by Road Act, 2007 to
				            carry on the business of LSP;
				         </li>
				         <li> It shall comply with the laws, rules and regulations as framed under the Motor Vehicles Act, 1988, Motor Vehicles
				            (Amendment) Act, 2019 and any other amendment to the said rules from time to time;
				         </li>
				         <li> it shall be solely responsible for Company Products entrusted to him for transport either by him or through his
				            associates till delivery of the Company Products in a safe and untampered condition to the Consignees/s;
				         </li>
				         <li> the vehicles engaged in providing logistic services under this Agreement are roadworthy, have the necessary permits
				            and licenses, insurance documents, PUC certificate at all time in the vehicles. Additionally, the vehicles are clean and
				            odour free as well as sturdy to carry Company Products without any damage/pilferage;
				         </li>
				         <li> Additionally, every vehicle engaged in the logistic services under this Agreement has a reverse horn in place to
				            ensure safety of the employees working at the Premises of the Company;
				         </li>
				      </ol>
				   </div>
				   <div style={{padding: '50px'}} >
				      <ol start="6">
				         <li> the vehicles engaged in providing logistic services shall not overloaded beyond the
				            permitted limit
				         </li>
				         <li> Each of the vehicles are adequately insured to cover any damage/ loss caused to the vehicle or for any third party
				            loss and the Personnel engaged by the Service Provider are also insured, in accordance with industry practices and
				            standards and the Service Provider has been paying the premium regularly without any default and has not committed any
				            default or violated any provision of the insurance policies;
				         </li>
				         <li> Consignments loaded in the vehicles are fully protected by covering it properly;</li>
				         <li>In case of breakdown of any vehicle performing services under this Agreement, it shall immediately tranship the
				            goods to a different vehicle with the prior written approval from the Company and any goods damaged in such transhipment
				            shall be the sole responsibility of the LS P;
				         </li>
				         <li>It shall not club consignments of any other client with that of ABFRL's consignment, and if done so, ABFRL shall not be
				            liable to pay service charges for the said consignment. In case of damage caused to ABFRL's consignment because of such
				            unauthorized clubbing, ABFRL shall debit the value of damaged goods/consignment in accordance to terms as agreed between
				            the Parties;
				         </li>
				         <li>It shall provide updates on the status of all consignments that are in the process of being
				            delivered;
				         </li>
				         <li>It shall be liable for all missing or damaged Company Products/ consignment during the
				            process of transportation;
				         </li>
				         <li> It shall have no lien on Company Products in transit for transit charges payable by the Company to LSP under this
				            LSP Agreement or for any other reason whatsoever and that LSP would only act as a carrier. LSP shall neither mortgage
				            nor dispose of the Company Products in his custody for carriage in any manner whatsoever;
				         </li>
				         <li> It shall duly intimate non-delivery of the Company Products for any reason whatsoever to Company ;</li>
				         <li> it shall ensure that all the packages are in good condition and are not damaged. It shall also ensure that none of
				            its employees are carrying unauthorized Company packaging and sealing materials like boxes, sealing tapes etc. with them
				            during transportation of the Company Products;
				         </li>
				         <li> it shall ensure proper behavior of its personnel whilst they are in the Company's premises:</li>
				         <li> it maintains a record of all the Personnel along with all relevant details/ documents such as the driving license of
				            the drivers, other identity and address proof of the Personnel, the details of the timings during which the Personnel
				            has provided Services to Company;
				         </li>
				         <li> the Personnel are its own employees and shall not be construed as employees of Company at any time during the
				            Agreement;
				         </li>
				      </ol>
				   </div>
				   <div style={{padding: '50px'}}>
				      <ol start="19">
				         <li> it shall allow the Lorries and personnel of LSP entering the Company's premises subjected to security and other
				            rules framed by the Company from time to time;
				         </li>
				         <li> It will promptly notify Company of any accident, incident or event that impairs the safety of or delays delivery of
				            logistic services, and will ensure reasonable care and due diligence while performing the services;
				         </li>
				         <li> It shall ensure that if the consignment is held back at any check post for improper or incomplete documents, it's
				            personnel shall intimate warehouse within 24 (twenty-four) hours of such incidence;
				         </li>
				         <li> It shall be solely responsible for all statutory compliances in respect of staff employed by it to render the
				            logistic services as required under this Agreement as well as shall be in compliance with the e-way bill rules &
				            regulations as amended from time to time;
				         </li>
				         <li> It shall be bound by the confidentiality obligations as provided in this Agreement herein below.</li>
				         <li> It shall comply with the applicable provisions of law, including but not limited to section 171 of the Central Goods
				            and Services Tax Act, 2017 or applicable section of IGST/UTGST/SGST law, and pass on to ABFRL all the benefits arising
				            from any reduction in the rate of tax on services both or the benefits of input tax credit, exemptions, concessions, rebate, set off, by
				            way of reduction in the prices of the services / work undertaken by the Service Provider as per said Amendment; and
				         </li>
				         <li> it shall comply with the provisions of goods and services tax laws to ensure that ABFRL is
				            able to avail the entire eligible input tax credit on timely basis for the services / supply / work undertaken by the
				            Service Provider as per the said Agreement.
				         </li>
				      </ol>
				      <div>&nbsp</div>
				      <h3 >9. RIGHT TO AUDIT / EXAMINE RECORDS</h3>
				      <p>ABFRL may, on its own, or by engaging a third-party auditor (-Auditor") at ABFRL's expense, and, upon ABFRL's written
				         request thereof to LSP, examine any records, invoices and/or other data or documents of LSP, relating to Services
				         provided under this Agreement that are necessary to establish LSP's compliance or non-compliance with this Agreement.
				         LSP agrees to provide all relevant reports and related information in a mutually agreed format, to allow ABFRL to
				         conduct such audit. If an audit identifies an overcharge by Service Provider, Service Provider will reimburse ABFRL for
				         the full amount of any overcharge identified in the audit within 30 days from receipt of the audit results. Such
				         reimbursement of overcharge by LSP to ABFRL shall be on gross basis without reducing the withholding taxes that may have
				         been deducted by ABFRL at the time of payment. ABFRL however agrees to refund the withholding taxes to LSP at a
				         subsequent date subject to it receiving a refund for it or a right to adjust it against future withholding tax
				         liability.
				      </p>
				   </div>
				   <div style={{padding: '30px'}} >
				      <h3>10. CODE OF ETHICS:</h3>
				      <p>LSP shall not offer or attempt to offer any bribes / kickbacks / enticement whatsoever to any person connected with or
				         working in ABFRL in order to further the Service Provider's business interests. The Service Provider neither shall offer
				         any gifts, gratification or any other valuables of any kind to any employee of ABFRL nor shall attempt to reach out to
				         their family members in order to further Service Provider's business interests
				      </p>
				      <p>The Service Provider, while this contract is in force, shall not directly or indirectly do any such act that would
				         prejudice the interest of ABFRL, nor shall make any representations about ABFRL or the association with ABFRL, which in
				         the opinion of ABFRL could damage its goodwill or reputation.
				      </p>
				      <h3>11. TECHNICAL ASSISTANCE:</h3>
				      <ol type="A">
				         <li>
				            The LSP shall provide the following technical assistance as value added service to ensure that the Company Products
				            transported can be tracked on real time basis-
				            <ol>
				               <li>The LSP shall scan the barcode pasted on the carton box before they are loaded into the ehicle at the warehouse of the Company and same shall match with the code (LR no. or words/ expressions of similar connotations) created by the vendor in their system to ensure that there is no discrepancy
				                  in the data available with both Parties
				               </li>
				               <li>to ensure that the Company Products picked up from the Warehouse are delivered within the TAT communicated by the
				                  Company, LSP have a milestone tracking system which provides for status from the time of pick-up of consignment until
				                  delivery to the Customer. At the time of Delivery of the consignment to the Customer, the LSP shall collect a sealed and
				                  signed POD from the customer mentioning the date and time of delivery and the same shall be uploaded within 24- 48 hours
				                  from the time of delivery of the consignment.
				               </li>
				            </ol>
				         </li>
				         <li>If the Server is down, the LSP shall immediately inform the representative of the Company and shall rectify the same
				            within 24 hours of such breakdown of the server.
				         </li>
				         <li>If the LSP cannot provide the technical infrastructure as required by the Company, the LSP shall adopt the
				            infrastructure designed by the Company and integrate the same into their system.
				         </li>
				      </ol>
				   </div>
				   <div style={{padding: '50px'}} >
				      <h3>INDEMNITY:</h3>
				      <ol type="A">
				         <li>Company shall at its own cost and expense indemnify, defend and hold the LSP, its
				            directors, officers, employees, and agents, client free and harmless from and against costs limited to the actual cost
				            of detention and examination incurred by the LSP due to incorrect declaration of the value and particulars of the
				            Company Products by the Company.
				         </li>
				         <li>LSP, shall, at its own cost and expenses indemnify, defend and hold the Company, its
				            directors, officers, employees, agents, clients free and harmless from and against including but not limited to any and
				            all losses, claims, damages, liabilities, reasonable costs and expenses, including attorneys' fees and court cost
				            relating to, resulting from and arising out of the breach or material breach of LSP's representation, warranty,
				            covenants or obligations or any other provision of this Agreement including loss or damage arising out of theft,
				            pilferage, accident, death, loss of any vehicle, overloading of the vehicle or wagon, misconduct, misrepresentation,
				            fraud, negligence and/or, any action of LSP or his transportation staff or any other third party acting on his behalf
				            that is not authorized under this agreement;
				         </li>
				         <li>LSP shall also indemnify, defend and hold the Company, its directors, officers, employees, agents, clients free and
				            harmless from and against any loss, damage, investigation, launched by any authority or any third party for carriage of
				            duplicated goods or mixing of spurious goods with that of the Company Products.
				         </li>
				      </ol>
				      <h3>13. TERMINATION:</h3>
				      <ol type="1">
				         <li><b><u>Termination by efflux of time:</u></b>
				         <br />This Agreement shall stand terminated by efflux of time unless renewed on mutual consent by the parties in writing;</li>
				         <li><b><u>Termination by Company for convenience:</u></b>
				         <br />The Company may terminate this Agreement at any time by giving 15 (fifteen) days' notice without assigning any reasons;</li>
				      </ol>
				   </div>
				   <div style={{padding: '50px'}} >
				      <ol start="3">
				         <li>
				            <b><u>Termination by the Company for breach by LSP:</u></b>
				            <ol type="a">
				               <li> If LSP breaches the Confidentiality Obligations, Company shall be entitled to terminate
				                  the LSP Agreement forthwith;
				               </li>
				               <li> in the event LSP becomes insolvent, has a receiver, administrative receiver, administrator
				                  or manager appointed for the whole or any part of its assets or business, makes any composition or
				                  extraordinary
				                  arrangement with its creditors or takes or suffers any similar action in consequence of debt or an order of
				                  resolution
				                  is made for its dissolution or liquidation (other than for the purpose of solvent amalgamation of
				                  reconstruction) or is
				                  unable to pay its debts as they fall due, the Company shall be entitled to terminate the Agreement forwith.
				               </li>
				            </ol>
				         </li>
				         <li><b><u>Termination by either party for breach:</u></b><br />In the event of breach of any of the terms of this
				            Agreement, the non-defaulting party shall issue 15 (fifteen) days'
				            notice calling upon the breaching party to rectify the breach. If the breaching party fails to rectify the breach
				            within
				            the stipulated time, the non-defaulting party may terminate this Agreement forthwith.
				         </li>
				      </ol>
				      <h3>CONSEQUENCES OF TERMINATION:</h3>
				      <ol>
				         <li>Termination or expiration of this Agreement shall not relieve any of the Parties of any liability or obligation it may
				            have to the other, arising out of or related to acts or omissions occurring prior to such termination or expiration;
				         </li>
				         <li>LSP shall not accept any orders from the date of termination of the Agreement and shall return the consignments not
				            delivered or in transit if any to the Company within 15 (fifteen) days from the date of expiry or termination of this
				            Agreement;
				         </li>
				         <li>LSP shall return any logo or trademark of the Company affixed on the vehicles used for carrying Company's consignments
				            immediately on expiry or termination of this Agreement;
				         </li>
				         <li>Additionally, LSP shall return to the Company all Confidential Information received by it or destroy all such
				            Confidential Information and certify in writing to such destruction. Nothing in this agreement shall prevent the Company
				            from recovering additional damages from the LSP due to any loss suffered by it from the breach of confidentiality
				            obligations;
				         </li>
				         <li>Notwithstanding anything to the contrary under this Agreement, all provisions including but not limited to
				            Consideration, Invoicing and Payment Terms; Intellectual Property Ownership; Indemnity; Limitation of Liability,
				            Confidential Information; Insurance; Severability; Notices; Dispute Resolution, Governing Law shall survive expiration
				            or termination of this Agreement.
				         </li>
				      </ol>
				   </div>
				   <div style={{padding: '50px'}}>
				      <h3>15. INTELLECTUAL PROPERTY RIGHTS:</h3>
				      <p>LSP hereby acknowledges that all rights including all Intellectual Property Rights in the Intellectual Property
				         including but not limited to trademarks, copyrights, of the Company, and any data and/or material provided by the
				         Company to the LSP shall remain the property of the Company. Except as set forth in this Agreement, the LSP shall not
				         have any right or title to any Intellectual Property Rights attached to such Intellectual Property belonging to the
				         Company and the LSP shall not claim any such rights or title. The LSP shall not use the name, trademarks, or
				         Intellectual Properties of the Company in its advertising or other publications or in any other manner without the prior
				         written consent of the Company. The LSP shall not do or abstain from doing any act, which causes damage to the
				         reputation of the Company or its trademarks.
				      </p>
				      <div>&nbsp</div>
				      <h3>16. MISCELLANEOUS:</h3>
				      <ol>
				         <li>CONFIDENTIALITY<br /><br />The LSP shall ensure that it shall: (a) not reveal to any person directly or indirectly any confidential operations,
				            processes, dealings or information concerning the organization, policies, procedure, of the Company or any information
				            concerning the Catalogue and Blog or activities, business, images, objects, properties (collectively, "Confidential
				            Information"); (b) keep with complete secrecy all Confidential Information; (c) not use or attempt to use any such
				            Confidential Information in any manner except as may be required for the performance of the Services under this
				            Agreement. The LSP agrees to keep confidential all Confidential Information and to limit access to such information and
				            materials to those who need to know and who are contractually bound by policies of confidentiality with substantially
				            similar function, purpose, scope and effect which are at least as stringent as this Agreement by virtue of their
				            employment relationships or written employment/ service/consulting agreements with the LSP. The restrictions under this
				            Clause shall apply during the term of this Agreement and after the termination/ expiry of this Agreement without limit
				            in period of time.
				         </li>
				         <li>NON-HIRE AND NON-SOLICITATION <br /><br />LSP shall not solicit any of the Company's employees or its customers without the written approval of the other Party,
				            for the duration of this Agreement and for an additional period of one (1) year from the expiry or termination of the
				            Agreement.
				         </li>
				      </ol>
				   </div>
				   <div style={{padding: '50px'}} >
				      <ol start="3">
				         <li>
				            LIMITATION OF LIABILITY
				            <ol type="a">
				               <li> In no event will Company be liable to Service Provider for any special, exemplary, incidental, or
				                  consequential
				                  damages, or for any direct or indirect loss of profits, goodwill, whether arising out of contract, tort
				                  (including
				                  negligence), strict liability or otherwise, resulting from or related to the Agreement (whether or not Company
				                  knew or
				                  should have known of the possibility of any such damages).
				               </li>
				               <li> Under no circumstances will either Company's aggregate liability to the other for any and all claims
				                  (including
				                  third party claims) arising from or in connection with or relating to the Agreement (whether in contract, tort
				                  (including negligence), strict liability or otherwise) exceed an amount equal to the Service Fees paid by the
				                  Company to
				                  the Service Provider for the applicable Services that gave rise to the claim.
				               </li>
				            </ol>
				         </li>
				         <li>ANTI BRIBERY AND ANTI-CORRUPTION<br /><br />Either party shall not support, permit, indulge itself or through its
				            subsidiaries and/or affiliates and/or through its
				            respective, directors, employees (whether contractual and/or permanent), independent contractors, representatives,
				            agents (said Personnel) in any act to promise and/or authorize to make any payment in kind or otherwise, in
				            contravention and/or violation of the provisions of the Prevention of Corruption Act, 1988 and/or any other applicable
				            laws in force and/or any violation of Anti-Bribery policy or otherwise. Parties shall comply with all applicable laws,
				            statutes, regulations relating to anti-bribery and anti-corruption. Parties are committed not only to complying with all
				            applicable laws and regulations regarding such activities but also to acting with integrity and the highest ethical
				            standards. Parties shall not participate in, facilitate or permit any form of corruption, bribery, kickback, extortion,
				            embezzlement or money laundering, whether with respect to public officials or to any other person. Parties shall comply
				            strictly with all applicable laws and regulations and with policies of both Parties. Breach of this clause shall be
				            deemed a material breach of this Agreement entitling such other Party to terminate this Agreement forthwith.<br />Either
				            Party shall promptly report to the other Party any request or demand which if complied with would amount to a
				            breach of this Agreement.
				         </li>
				         <li>FORCE MAJEURE	<br /><br />Neither Party shall be liable for any failure or delay in the performance of its obligations under this Agreement to the
				            extent such failure or delay is caused by any reason beyond its reasonable control, such as fire, flood, earthquake,
				            elements of nature or acts of God, acts of state, strikes, acts of war, terrorism, riots, civil disorders, rebellions or
				            revolutions, quarantines, embargoes and other similar governmental actions, virus, attacks, bugs, computer crashes,
				            which is beyond the control of affected Party (a "Force Majeure Event"). The affected Party will immediately notify the
				            other by reasonable detail of the Force Majeure Event. If a Force Majeure Event continues for more than 30 days, either Party may, by a written notice to the
				            other Party, terminate this Agreement, without liability.
				         </li>
				         <li>SEVERABILITY<br /><br />If any provision of this Agreement be held invalid or unenforceable by a competent court, such provision shall be
				            modified to the extent necessary to make it valid and enforceable whilst preserving the intent of the Parties and all
				            other provisions of this Agreement shall remain fully valid and enforceable unless otherwise agreed between the Parties.
				         </li>
				      </ol>
				   </div>
				   <div style={{padding: '50px'}} >
				      <ol start="7">
				         <li>WAIVER<br /><br />No provision of this Agreement nor any breach thereof will be considered waived by either Party,
				            unless such waiver is
				            in writing signed on behalf of that Party and no such waiver will constitute a waiver of, or excuse for any other or
				            subsequent breach of this Agreement. Any agreement on the part of any Party to any such waiver shall be valid only if
				            set forth in a duly executed written instrument.
				         </li>
				         <li>AMENDMENT<br /><br />This Agreement may be amended only by a written instrument signed by a duly authorized representative of each of the
				            Parties.
				         </li>
				         <li>
				            NOTICE <br /><br />Any notice, request or instructions permitted or required to be given hereunder by any Party to the other shall be in
				            writing and shall be deemed sufficiently given if delivered
				            personally, sent by registered or certified mail, postage prepaid, or sent by telex or facsimile.	<br /><br />
				            If to Company: Legal Department
				            <div style={{marginLeft: "80px"}}>ADITYA BIRLA FASHION AND RETAIL LIMITED,<br />
				               Madura Fashion and Lifestyle, situated at Plot Kh No. 118/110/1,<br /> Building 2, Divyasree Technopolis, Yemalur, Bangalore -560037
				            </div>
				            <br />
				            If to Service Provider: {this.props.company},
				            <div style={{marginLeft: "80px"}}>
				            	{address}
				            </div>
				            <br />	
				            unless in each case Company and Service Provider shall have given notice as provided herein of a different address.
				            <br /><br />	Notices and other communications sent by mail shall be deemed to have been given 5 days
				            after the date on which the same shall be proved to have been posted. Notices and communications sent by telex or facsimile shall be deemed to have been given forty-eight (48) hours after the same shall
				            be proved to have been sent.
				         </li>
				      </ol>
				   </div>
				   <div style={{padding: '50px'}} >
				      <ol start="10">
				         <li>ASSIGNMENT <br /><br />Neither this Agreement nor any of the rights or obligations of the Service Provider arising under this Agreement may be
				            assigned or transferred without the Company's prior written consent. The Company shall have the right to terminate this
				            Agreement to any third party as it deems fit
				         </li>
				         <li>ENTIRE AGREEMENT<br /><br />
				            This Agreement constitutes the entire agreement of the Parties in respect of the services contemplated herein and
				            supersedes over all other representations or understanding whether oral or in writing made prior to the date of the
				            Agreement.
				         </li>
				         <li>INDEPENDENT CONTRACTOR<br /><br />
				            The relationship between the Parties shall be on a principal to principal basis. The Parties shall act as independent
				            contractots and shall not claim any employment under this Agreement. Nothing in this Agreement shall imply a contractual
				            relationship of employment, agency, association of persons, partnership or joint venture between the Parties.
				         </li>
				         <li>EXECUTION<br /><br />
				            The Original of this Agreement shall be retained by ABFRL. White Cross shall retain a photocopy of the Agreement.
				         </li>
				         <li>GOVERNING LAW AND DISPUTE RESOLUTION<br /><br />
				            This Agreement shall be governed by and construed in accordance with the laws of India and the Courts at Bangalore shall
				            have the exclusive jurisdiction to adjudicate upon and settle any and all disputes that may arise out of or in
				            connection with this Agreement between the Parties.
				         </li>
				         <li>SURVIVAL<br /><br />
				            Certain provisions of this Agreement, which by their very nature ought to survive, shall so survive the termination of
				            this Agreement
				         </li>
				         <li>STAMP DUTY<br /><br />
				            In the event this Agreement attracts Stamp duty and/or registration charges, same shall be borne equally by the Parties.
				         </li>
				      </ol>
				   </div>
				   <div style={{padding: '50px'}}>
				      <h5>NOW THEREFORE IN WITNESS, HEREOF THE PARTIES HAVE EXECUTED THIS AGREEMENT ON THE DAY AND DATE MENTIONED ABOVE.</h5>
				      <h6>ADITYA BIRLA FASHION & RETAIL LIMITED,<br />(Madura Fashion Lifestyle Division)</h6>
				      <br /><br />
				      <p>By: Rajesh Annamalai <br /> Designation: ChiefCommercialOfficer</p>
				      <p>Witness</p>
				   </div>
				   <div style={{padding: '50px'}} >
				      <h4 style={{textAlign: "center"}}><u>ANNEXURE-A</u><br /><u>SCOPE OF SERVICES</u></h4>
				      <ol>
				         <li>
				            <b>PICK UP OF COMPANY PRODUCTS:</b>
				            <ol type="a">
				               <li> The LSP shall allocate and send Delivery Personnel to the location(s) mutually agreed with
				                  the Company on any Business Day as required by the Company to pick up the Company Products for delivery to the
				                  Consignees/s by using Surface Mode or Train Mode;
				               </li>
				               <li> The LSP shall provide the Company a list of pin codes of the areas lying within the Territory where the LSP will
				                  make the Delivery of the Company Products within the timeline from the time of collection of the Company Products from
				                  the mutually agreed locations of the Company. This list may be updated by the LSP from time to time;
				               </li>
				               <li> The LSP shall deliver the Company Products within the Turn Around Times and as per the cut-off times mutually agreed
				                  to between the Parties and at the time of delivering the Company Products shall obtain the signature of the Consignee or
				                  his representative on a document which is referred to as a proof of delivery ("POD"). The LSP will not bear any
				                  liability on the Company Product once the same has been delivered to the Consignee
				               </li>
				            </ol>
				         </li>
				         <li>
				            <b>RESPONSIBILITIES OF LSP:</b>
				            <ol type="1">
				               <li>
				                  On communication of pick-up received by LSP from the Company, the LSP shall arrange
				                  and send sufficient number of vehicles at the pickup locations given by Company to ensure that all the consignments are
				                  picked up and dispatched on the same day.<br />
				                  The LSP shall;
				                  <ol type="a">
				                     <li> Ensure that the vehicles shall be available at ABFRL specified timings for providing the
				                        logistic services and shall report at the Premises of ABFRL as per the instructions given by ABFRL. If the vehicle/s is
				                        not available at the timings specified, LSP shall be provided with a further time period of 3 hours from the designated
				                        time to arrange for the vehicle/ s , failing which ABFRL shall have right to take service from outside transporter and
				                        deduct such amount or any penalty amount from monthly payment.
				                     </li>
				                     <li> provide a representative of the LSP who shall accompany the vehicle's along with a
				                        minimum of 3 (three) load men per vehicle to Company's pickup locations.
				                     </li>
				                     <li> Ensure that the Company Products before loading in vehicle/s at Premises shall be
				                        checked and noted with remarks about each of the Company Products package by Service Provider's authorized
				                        representative and in case it observe any damage in package then the same shall be informed/escalated to ABFRL's
				                        authorized representative and LSP shall refuse to load in vehicle the Company Products until receipt of written approval
				                        of ABFRL' s authorized representative.
				                     </li>
				                  </ol>
				               </li>
				            </ol>
				         </li>
				      </ol>
				   </div>
				   <div style={{padding: '50px'}}>
				      <p>d.In case of transportation of the Company Products by railway, handover the exact quantum
				         of products transported by the Company to the railway administration and load it properly into the wagon to avoid any
				         damage or loss to the Products of the Company. Additionally,it shall also ensure that no consignment is loaded beyond
				         the permissible limit prescribed for the wagon.
				      </p>
				      <ol  start="2">
				         <li>
				            Prepare receipts / Dockets for the goods loaded in the lorry:
				            <ol type="a">
				               <li> Company shall deliver the Company Products to the representative of the LSP and sign on the -Trip Sheet/Load Sheet/
				                  forwarding note" stating the particulars of packages loaded and
				               </li>
				               <li> The LSP shall arrange to provide the Lorry receipt/goods receipt/ railway receipt & e-way bill on the basis of this
				                  trip sheet and hand over Company's copy of the Lorry receipt/ goods receipt before the Lorry leaves the Company's pickup
				                  locations; and
				               </li>
				               <li> The lorry receipt/ railway receipt & e-waybill shall be conclusive evidence of the receipt by LSP of the Company
				                  Products described therein. Any provision or conditions in the lorry receipt or e-way bill, inconsistent with the terms
				                  hereof, shall not be binding on the Company.
				               </li>
				            </ol>
				         </li>
				         <li>The LSP shall at all times designate a dedicated Manager in Bangalore who will be a single
				            point contact for the Company for all service related issues. The LSP will also appoint one "Back Up- contact who will
				            attend to the Company requirements in the absence of the designated Manager. The LSP shall provide a single point of
				            contact at Bangalore to handle all India pickups and a regional coordinator also, to handle incoming shipments/ pickup
				            from various locations.
				         </li>
				      </ol>
				      <p><b>3. DELIVERY & REBOOKING:</b></p>
				      <ol type="a">
				         <li> The LSP shall deliver the Company Products to the specified shipping addresses as per the manifest and obtain proof
				            of deliveries (POD) signed by consignees (hard copies or soft copies);
				         </li>
				         <li>In the case where the seal of the Company Products appears tampered with, the Company shall bring the case to the
				            notice of the LSP. The LSP shall enable open delivery of the Company Product/Shipments where the number of pieces shall
				            be counted and verified against the Manifest shared;
				         </li>
				         <li>d In the event the Company's consignees wish to return or refuses to accept the Company Products for reasons
				            whatsoever, LSP shall rebook the consignment to the pickup locations, after obtaining the relevant approvals from the
				            Company in writing. If the relevant approval is not provided by the Company even after the LSP has made 3 attempts to
				            intimate the Company for rebooking the said Company Products to the origin, as per the Manifest, the LSP shall deem that
				            the movement is approved by the Company and all associated charges for the movement will be borne by the Company.
				         </li>
				      </ol>
				   </div>
				   <div style={{padding: '50px'}} >
				      <p style={{marginLeft: "30px"}}>d. Delivery Status Reporting</p>
				      <div style={{marginLeft: "50px"}}>
				         <p>The Service provider will send MIS on daily basis for updates on shipments and performance report as per the schedule
				            below,
				         </p>
				         <ol type="i">
				            <li>1 to 10 Dispatches : Report on the 15th</li>
				            <li>11 - 20" Dispatches : Report on the 25th</li>
				            <li>21' - 30th : Report on the 5th,</li>
				         </ol>
				      </div>
				      <p><b>REVERSE PICK-UP:</b></p>
				      <ol type="a">
				         <li>(a) The LSP shall on receiving a written approval from the Company shall arrange to pick-up the Product as per the
				            Cut-Off Times from the date of initiation of such request;
				         </li>
				         <li>(b) The LSP shall not be responsible for verifying the contents of the Products handed over by the Consignee to its
				            Delivery Personnel. The Packaging of such products shall also be the sole responsibility of the Consignee. The Packaging
				            should be good enough to ensure no damage in transit. The sole responsibility of the contents of the packed consignment
				            shall lie with the end Consignee. The LSP shall not reverse pick up any Product if the packaging is not transit worthy.
				            In case the LSP reverse picks up a Product which is not transit worthy, then LSP shall provide the number of pieces
				            against the number specified in the Manifest and any shortages during delivery shall be mentioned in the POD by the
				            Consignee. All such liabilities shall be governed by the Limitation of Liability clause contained herein the agreement.
				         </li>
				      </ol>
				   </div>
				   <div style={{padding: '50px'}} >
				      <h4 style={{textAlign: "center"}}>
				         <u>ANNEXURE -B</u><br /><u>
				         SERVICEABLE LOCATIONS MATRIX WITH RATES (in Rs.) FOR FORWARD AND
				         REVERSE PICK UP- SURFACE MODE</u>
				         {/*<table style={{justifyContent: 'center', margin: 'auto', marginTop: "20px"}}>
				            <tr>
				               <th>Mode</th>
				               <th>VENDOR</th>
				               <th>From</th>
				               <th>City</th>
				               <th>State</th>
				               <th>Region</th>
				               <th>Rates</th>
				            </tr>
				            <tr>
				               <td>Surface</td>
				               <td>Speedex</td>
				               <td>Bangalore</td>
				               <td>Orissa</td>
				               <td>Orissa</td>
				               <td>East</td>
				               <td>12.00</td>
				            </tr>
				         </table>*/}
				      </h4>

				      {
			            // Object.keys(vendorMDGDetails || {}).length > 0 &&
			            Object.keys(vendorMDGDetails || {}).map((item, index) => {
			              if (vendorMDGDetails[item].length > 0 || Object.keys(vendorMDGDetails[item] || {}).length > 0) {
			                const tab = item.replace(/_/g, ' ');
			                const header = headers[`${item}_header`];
			                
			                return (
			                  <div>
			                    <div className="top-margin-20">{tab.toUpperCase()}</div>

			                    <div className="divTable company-list-wrap">
			                      <div className="list-section table-header divTableHeading divTableRow">
			                          { (header || []).map((head) => { return (<div className="divTableCell"> {head} </div>) }) }
			                      </div>
			                      <div className="divTableBody">
			                        {
		                              Array.isArray(vendorMDGDetails[item]) ?
		                              <React.Fragment>
			                          {(vendorMDGDetails[item] || []).map((detail, dIndex) => {
			                            const keyItems = keys[`${item}_keys`];
			                            return (
			                              <div className="list-section content divTableRow" key={`sample-${(index+1)}-${(dIndex+1)}`}>
			                                {
			                                  (keyItems || []).map((data, dId) => {
			                                    if (item==='liablity_list') {
		                                          // return null;
		                                          if (data==='types_of_shortages') {
		                                            return (
		                                              <div className="divTableCell">
		                                                {(detail[data] || []).map((dItem, sIdx) => {
		                                                    return (<div className="mb-10 flex" key={`sample-sub-${(sIdx+1)}-${dItem.shortage_id}`}>
		                                                      <div className="mr-10 wt-200"> {dItem.shortage_name}</div>
		                                                      {
		                                                        dItem.claim_type && dItem.claim_type.length > 0 && 
		                                                        <div>
		                                                          {(dItem.claim_type || []).map((cItem, cId) => {
		                                                            return (
		                                                              <div key={`sample-laim-${(cId+1)}-${cItem.claim_type_id}`} className="mb-10">
		                                                                <span className="mr-10">Claim Type: </span> <span>{cItem.claim_type_name}</span>
		                                                                {
		                                                                  Object.keys(cItem.rules || {}).length > 0 && 
		                                                                  <React.Fragment>
		                                                                    <span> {cItem.rules.rule || ''} {cItem.rules.range_unit || ''} </span>
		                                                                    {
		                                                                      cItem.rules.inputs === 1 && <span> <b>{cItem.rules.range[0] || '0'}</b> </span>
		                                                                    }
		                                                                    {
		                                                                      cItem.rules.inputs > 1 && <span> <b>{cItem.rules.min || '0'} - {cItem.rules.max || '0'}</b> </span>
		                                                                    }
		                                                                  </React.Fragment>
		                                                                }
		                                                              </div>
		                                                            )
		                                                          })}
		                                                        </div>
		                                                      }
		                                                    </div>)
		                                                })}
		                                              </div>
		                                            )
		                                          } else {
		                                            return <div className="divTableCell" key={`data-${(dId+1)}`}> {detail[data]} </div>
		                                          }
			                                    } else if (item==='delay_penalty_list') {
			                                      if (data==='vendor_penalty') {
			                                      	const typeOfEfficiency = (detail.type===2) ? (detail.delay_penalty_ontime_efficiency || {}) : (detail.delay_penalty_days || {});
			                                        const arrData = detail.type===2 ? (typeOfEfficiency.vendor_ontime_efficiency || []) : (typeOfEfficiency.vendor_delay_penalty_days || []);
			                                        return (
			                                          <div className="divTableCell">
			                                            {(arrData || []).map((dItem, idx) => {
			                                                return (<div className="mb-10" key={`sam-delay-${dIndex+1}-${dId+1}-${(idx+1)}`}>
			                                                  	{idx===0 && <div className="mb-10">Type: {typeOfEfficiency.name}</div>}
                                                      		  	<div className="flex">
				                                                  <div className="mr-10">Min: {dItem.min}</div>
				                                                  <div className="mr-10">Max: {dItem.max}</div>
				                                                  <div className="mr-10">Value: {dItem.penalty_percentage}</div>
				                                                </div>
			                                                </div>)
			                                            })}
			                                          </div>
			                                        )
			                                      } else {
			                                        return <div className="divTableCell" key={`filel-${(dId+1)}`}> {detail[data]} </div>
			                                      }
			                                    } else if (item === 'demurrage_list') {
		                                          if (data === 'demurrage_charges') {
		                                            return (
		                                              <div className="divTableCell">
		                                                {(detail[data] || []).map((dItem, idx) => {
		                                                    return (<div className="mb-10 flex" key={`sample-dem-${(idx+1)}`}>
		                                                      {dItem.min && <div className="mr-10">Min: {dItem.min}</div>}
		                                                      {dItem.max && <div className="mr-10">Max: {dItem.max}</div>}
		                                                      <div className="mr-10">Freight: {dItem.frieght} {dItem.frieght_uom}</div>
		                                                    </div>)
		                                                })}
		                                              </div>
		                                            )
		                                          } else {
		                                            return <div className="divTableCell" key={`data-${(dId+1)}`}> {detail[data]} </div>
		                                          }
		                                        } else if (item === 'other_charge_list') {
		                                          if (data === 'load_type') {
		                                            const load = LOADS.filter(el => { return el.id===detail[data] })
		                                            return ( <div className="divTableCell">{load.length>0 ? load[0].label : ''}</div> )
		                                          } else if (data === 'mode_type') {
		                                            const mode_name = (allDropdownTypes.movement_type || []).filter(el => { return detail[data].includes(el.dropdown_value) });
		                                            return (
		                                              <div className="divTableCell">
		                                                {(mode_name).map((dItem, idx) => {
		                                                    return (<div className="mb-10" key={`oc-mode-${(idx+1)}`}> {dItem.dropdown_name} </div>)
		                                                })}
		                                              </div>
		                                            )
		                                          } else if (data === 'distribution_type') {
		                                            const dist_type = (allDropdownTypes.distribution_type || []).filter(el => { return detail[data].includes(el.dropdown_value) });
		                                            return ( 
		                                              <div className="divTableCell">
		                                                {(dist_type).map((dItem, idx) => {
		                                                    return (<div className="mb-10" key={`oc-dist-${(idx+1)}`}>{dItem.dropdown_name}</div>)
		                                                })}
		                                              </div>
		                                            )
		                                          } else if (data === 'charges') {
		                                            const charge_type = (allDropdownTypes.charges || []).filter(el => { return detail.charge_id===(el.dropdown_value) });
		                                            const charges_uom = (allDropdownTypes.charges_uom || []).filter(el => { return detail.charge_uom===(el.dropdown_value) });
		                                            return ( 
		                                              <div className="divTableCell">
		                                                <div className="mb-10"> Name: {charge_type.length > 0 ? charge_type[0].dropdown_name : ''} </div>
		                                                <div className="mb-10"> UOM: {charges_uom.length > 0 ? charges_uom[0].dropdown_name : ''} </div>
		                                              </div>
		                                            )
		                                          } else if (data === 'sub_charges') {
		                                            console.log('dataaaa', data, detail);
		                                            return ( 
		                                              <div className="divTableCell">
		                                              {
		                                                // (detail.sub_charges || []).map((obj, index) => {
		                                                //   return (<div className="mb-10" key={`levy-${obj.charge_id}-${dIndex}-${index}`}> 
		                                                //       <div className="mb-5 fs-11"><span className="header-color">Levy: </span>{obj.levy_logic} </div>
		                                                //       <div className="mb-5 fs-11"><span className="header-color">Levy UOM:</span> {obj.levy_uom} </div>
		                                                //       {obj.minimum_value && <div className="mb-5 fs-11"><span className="header-color">Min. value: </span>{obj.minimum_value || '-'} </div>}
		                                                //       {obj.levy_uom && <div className="mb-5 fs-11"><span className="header-color">Value UOM:</span> {obj.levy_uom} </div>}
		                                                //       <div className="levy-logic-container">
		                                                //       {Object.keys(obj.levy_logic_values || {}).map((levy) => {
		                                                //           return ( <div className="fs-11"> {levy} : {obj.levy_logic_values[levy]} </div> )
		                                                //       }) }
		                                                //       </div>
		                                                //   </div>)
		                                                // })
		                                              }
		                                              </div>
		                                            )
		                                          }
		                                        }
		                                        else {
			                                      return <div className="divTableCell" key={`filem-${(dId+1)}`}> {detail[data]} </div>
			                                    }
			                                  })
			                                }
			                              </div>
			                            )
			                          })}
									  </React.Fragment>
									  :
									  <React.Fragment>
									  	{
		                                  Object.entries(vendorMDGDetails[item]).map(([key, value], idx)=> {
		                                    const keyItems = keys[`${item}_keys`];
		                                    if (item === 'tat_route_list') {
		                                      return (<React.Fragment>
		                                        {idx===0 && <div className="list-section content divTableRow mb-20" key={`sample-tat-${key+1}-${idx+1}`}>
		                                          <div className='mb-10 mt-10'>{key.replace(/_/g, ' ')}</div>
		                                        </div>}
		                                        {
		                                          (value || []).map((valItem, vId) => {
		                                            return (<div className="list-section content divTableRow" key={`sample-tatVal-${key}-${idx+1}-${vId+1}`}>
		                                              {(keyItems || []).map((data, dId) => {
		                                                let dataItem = valItem[data];
		                                                if (data==='from.city_name' || data==='to.city_name' || data==='from.state_name' || data==='to.state_name') {
		                                                  const splitWord = data.split('.');
		                                                  dataItem = valItem[splitWord[0]][splitWord[1]];
		                                                } 
		                                                return (<div className="divTableCell" key={`sample-sub-${vId+1}-${idx+1}-${dId+1}`}> {dataItem} </div>)
		                                              })}
		                                            </div>)
		                                          })
		                                        }
		                                      </React.Fragment>)
		                                    } else {
		                                      return (<React.Fragment>
		                                        {idx===0 && <div className="list-section content divTableRow mb-20" key={`sample-odaa-${key+1}-${idx+1}`}>
		                                          <div className='mb-10 mt-10'>{key.replace(/_/g, ' ')}</div>
		                                        </div>}
		                                        {
		                                          (value || []).map((valItem, vId) => {
		                                            return (<div className="list-section content divTableRow" key={`sample-row-${key}-${idx+1}-${vId+1}`}>
		                                              {(keyItems || []).map((data, dId) => {
		                                                return (<div className="divTableCell" key={`sample-oda-${vId+1}-${idx+1}-${dId+1}`}> {valItem[data]} </div>)
		                                              })}
		                                            </div>)
		                                          })
		                                        }
		                                      </React.Fragment>)
		                                    }
		                                  })
		                                }
									  </React.Fragment>
			                        }
			                      </div>
			                    </div>
			                  </div>
			                )
			              }
			              return null
			            })
			          }

				   </div>
				   {/*<div style={{padding: '50px'}} >
				      <h4 style={{textAlign: "center"}}>SERVICEABLE LOCATIONS MATRIX WITH RATES (in Rs.) FOR FORWARD AND
				         REVERSE PICK UP- SUPER FAST MODE
				      </h4>
				      <table style={{justifyContent: 'center', margin: 'auto', marginTop: "20px"}}>
				         <tr>
				            <th>Mode</th>
				            <th>VENDOR</th>
				            <th>From</th>
				            <th>City</th>
				            <th>State</th>
				            <th>Region</th>
				            <th>Minimum Charges on Docket</th>
				            <th>Rates</th>
				            <th>TAT</th>
				         </tr>
				         <tr>
				            <td>SuperFast</td>
				            <td>Speedex</td>
				            <td>Bangalore</td>
				            <td>Andhra Pradesh</td>
				            <td>Andhra Pradesh</td>
				            <td>South</td>
				            <td>400Rs/-</td>
				            <td>9.4</td>
				            <td>One Day</td>
				         </tr>
				      </table>
				   </div>
				   <div style={{padding: '50px'}} >
				      <h4 style={{textAlign: "center"}}>INTERPLANT TRANSPORTATION (from factory to warehouse and vice -versa) & ARS
				         vehicle
				      </h4>
				      <table>
				         <tr>
				            <th>Type of Service</th>
				            <th>Particulars</th>
				            <th>Amount</th>
				         </tr>
				         <tr>
				            <td rowspan="6">SuperFast</td>
				            <td>Fixed per Month</td>
				            <td>56100</td>
				         </tr>
				         <tr>
				            <td>Per Day additional Trip</td>
				            <td>1500</td>
				         </tr>
				         <tr>
				            <td>Extra Flour</td>
				            <td>125</td>
				         </tr>
				         <tr>
				            <td>Extra km</td>
				            <td>12</td>
				         </tr>
				         <tr>
				            <td>Sunday Working</td>
				            <td>2500</td>
				         </tr>
				         <tr>
				            <td>Monthly Fixed KM</td>
				            <td>2250</td>
				         </tr>
				      </table>
				      <h4 style={{textAlign: 'center', marginTop: "100px"}}>SERVICEABLE LOCATION MATRIX WITH RATE PER KG- IINBOUND</h4>
				      <table>
				         <tr>
				            <th>To Place</th>
				            <th>Origin Place</th>
				            <th>Surface Mode</th>
				            <th>Express Mode</th>
				            <th>Train Mode</th>
				            <th>Handling Charges</th>
				            <th>Docket Charges</th>
				            <th>Surafce Mode TAT</th>
				            <th>Express Mode TAT</th>
				            <th>Super fast TAT</th>
				         </tr>
				         <tr>
				            <td>Bangalore</td>
				            <td>Ahmedabad</td>
				            <td>6.35</td>
				            <td>7.4</td>
				            <td>13</td>
				            <td>15</td>
				            <td>50</td>
				            <td>5days</td>
				            <td>3days</td>
				            <td>*</td>
				         </tr>
				      </table>
				   </div>
				   <div style={{padding: '50px'}} >
				      <table>
				         <tr >
				            <td colspan="5">Serviceable location matric with Rate per KG- Inbound (Trian Mode)</td>
				         </tr>
				         <tr>
				            <th>SL.NO.</th>
				            <th>To place</th>
				            <th>Origin</th>
				            <th>Rate per KG</th>
				            <th>TAT</th>
				         </tr>
				         <tr>
				            <td>1</td>
				            <td>Bangalore</td>
				            <td>Mumbai</td>
				            <td>11</td>
				            <td>3days</td>
				         </tr>
				      </table>
				   </div>
				   <div style={{padding: '50px'}} >
				      <table>
				         <tr>
				            <td colspan="5">FTL Rates -32 feet Super fast</td>
				         </tr>
				         <tr>
				            <th>From	</th>
				            <th>To place</th>
				            <th>Frieght</th>
				            <th>TAT</th>
				         </tr>
				         <tr>
				            <td>Bangalore</td>
				            <td>Bhubaneswar</td>
				            <td>100000</td>
				            <td>3rd day</td>
				         </tr>
				      </table>
				   </div>
				   <div style={{padding: '50px'}} >
				      <p style={{textAlign: "center"}}><b>TAT for Surface Mode</b></p>
				      <table>
				         <tr>
				            <th>From </th>
				            <th>To </th>
				            <th>State</th>
				            <th>Branch</th>
				            <th>TAT</th>
				         </tr>
				         <tr>
				            <td>Bangalore</td>
				            <td>Vizag</td>
				            <td>Andhra Pradesh</td>
				            <td> Vizag</td>
				            <td>3 </td>
				         </tr>
				      </table>
				   </div>*/}
				   <div style={{padding: '50px'}} >
				      <p>Note:</p>
				      <ol>
				         <li>If the consignment is not handed over by the warehouse as per the cut -off time (5:30 pm), then TAT will be considered
				            as next day booking. However, if the LSP fails to pick -up at the cut-off time, then for undelivered consignments as per
				            the TAT above, the Penalty will follow as provided in Annexure - C.
				         </li>
				         <li> ODA inclusion and Halting Charges need to be approved by mail.</li>
				         <li> TAT is excluding pick-up day as confirmed by the representative of ABFRL.</li>
				      </ol>
				   </div>
				   <div style={{padding: '50px'}} >
				      <p style={{textAlign: "center"}}><b>OTHER TERMS AND CONDITIONS IN RELATION TO TRANSPORATION OF
				         COMPANY PRODUCTS
				         </b>
				      </p>
				      <table>
				         <tr>
				            <td>1</td>
				            <td>Diesel Price Hike</td>
				            <td>With Base Rate of 73.24(Average of city - Mumbai, Chennai,
				               Bangalore, Hyderabad and Trivandrum as AUG-2018), For every 10 %
				               increase / Decrease in Diesel Price there would be corresponding 5 %
				               Increase / Decrease in above fixed component of the Freight. This
				               Change or fluctuation will not be applicable to other charges
				            </td>
				         </tr>
				         <tr>
				            <td>2</td>
				            <td>Way bill Charge</td>
				            <td>NIL</td>
				         </tr>
				         <tr>
				            <td>3</td>
				            <td>GST </td>
				            <td>As Applicable</td>
				         </tr>
				         <tr>
				            <td>4</td>
				            <td>Volumetric</td>
				            <td>Actual Weight</td>
				         </tr>
				         <tr>
				            <td>5</td>
				            <td>Minimum Weight</td>
				            <td>10 kgs</td>
				         </tr>
				         <tr>
				            <td>6</td>
				            <td>Handling Charges</td>
				            <td>Kerala Rs.19 / Carton, TN (Expect Chennai)Rs.10/ Carton, Maharashtra
				               Rs.25/ Carton Andhra Pradesh Rs.12/ Carton
				            </td>
				         </tr>
				         <tr>
				            <td>7</td>
				            <td>Extended Service
				               Station
				            </td>
				            <td>NIL</td>
				         </tr>
				         <tr>
				            <td>8</td>
				            <td>Suits Carton</td>
				            <td>Minimum Weight 5 kgs and weight at volumetric (L cmX Bern
				               xHcm)/4750
				            </td>
				         </tr>
				         <tr>
				            <td>9</td>
				            <td>Outside Delivery Area</td>
				            <td>Rs. 300 per Shipment for agreed city only</td>
				         </tr>
				         <tr>
				            <td>10</td>
				            <td>Return/ Reverse
				               Logistics
				            </td>
				            <td>Same Rate and Terms and Condition</td>
				         </tr>
				         <tr>
				            <td>11</td>
				            <td>Open Pickup</td>
				            <td>Rs. 1 per Pcs</td>
				         </tr>
				         <tr>
				            <td>12</td>
				            <td>Open Delivery</td>
				            <td>Rs. 1 per Pcs</td>
				         </tr>
				         <tr>
				            <td>13</td>
				            <td>Halting Charges for
				               E.com deliveries
				            </td>
				            <td>Rs. 25001- Per day</td>
				         </tr>
				      </table>
				   </div>
				   <div style={{padding: '50px'}}>
				      <h4 style={{textAlign: "center"}}><u>ANNEXURE-C</u>
				         <br /><u>SERVICE LEVEL AGREEMENT (SLA)</u>
				      </h4>
				      <p>1. LIQUIDATES DAMAGES FOR DELAY IN DELIVERY:</p>
				      <table>
				         <tr>
				            <th>Surface Mode</th>
				            <th>Deduction from freight charges</th>
				         </tr>
				         <tr>
				            <td>1 day</td>
				            <td>Waived off</td>
				         </tr>
				         <tr>
				            <td>2-3 days</td>
				            <td>15% of freight charges</td>
				         </tr>
				         <tr>
				            <td>4-5 days</td>
				            <td>25% of freight charges</td>
				         </tr>
				         <tr>
				            <td>6 and more days</td>
				            <td>50% of freight charges</td>
				         </tr>
				         <tr>
				            <td>uper-fast days</td>
				            <td></td>
				         </tr>
				         <tr>
				            <td>1 day delay</td>
				            <td>100% of Superfast freight charges</td>
				         </tr>
				      </table>
				      <p>Note: Above table will be applicable subject to following three conditions:-</p>
				      <ol type="i">
				         <li>
				            The shipment need to be handed over within cut off time by ABFRL.
				         </li>
				         <li> For all Mall delivers, one day extra will be given from the TAT</li>
				         <li> Penalty will be charged by means of raising a debit note to LSP on 20th of next month</li>
				         <li> Reasons for all delays need to be given within 7 days from delivery.</li>
				      </ol>
				      <p>Penalty will be imposed only at the discretion of the Head of the Supply Chain of the Company.</p>
				   </div>
				   <div style={{padding: '50px'}} >
				      <p><b>2. SHORTAGE CLAUSE:</b></p>
				      <p>LSP shall be responsible for the safe carriage of goods entrusted to him for carriage under this Agreement. Any shortage
				         of goods in transit, LSP shall be responsible for the claims as mentioned herein below:
				      </p>
				      <ol type="a">
				         <li>
				            DIRECT DEBIT: In the event the Authorized Representative of ABFRL assess a
				            piece shortage of a value less than or equal to Rs. 10,000 ( Rupees Ten Thousand only) on per occurrence basis, LSP
				            shall be liable for the entire value of the piece shortage and the same shall be recoverable by ABFRL-
				            <ul>
				               <li>by raising an invoice (debit note) and deduct the amount from any amount payable to Service Provider without any further
				                  notice; and
				               </li>
				               <li>(ii) If nothing is due and payable to Service Provider at that point of time, the Service Provider shall pay aforesaid
				                  losses within 21 (twenty one) days from receipt of aforesaid invoice/debit note; and
				               </li>
				               <li>(iii) Failing which ABFRL shall be entitled to recover the same by due course of law. Under this paragraph the amount
				                  accessed by ABFRL representative and its decision shall be final and binding on Service Provider.
				               </li>
				            </ul>
				         </li>
				         <li>(b) Additionally, in the event the Authorized Representative of ABFRL assess a carton
				            shortage of more than 40 cartons in a year, then the LSP shall be liable for the entire assessed value and the same
				            shall be directly debited to LSP in the manner provided above.
				         </li>
				         <li> INSURANCE CLAIM: In the event the piece shortage , by way of pilferage, theft,
				            hijack, accident, force majeure or any other reason of whatsoever in nature is exceeding Rs. 10,000/- (Rupees Ten
				            Thousand Only) on per occurrence basis, and carton shortage of less than 40 cartons in a year, the Service Provider
				            shall be liable to provide ABFRL with the copy of FIR, incident report, survey report (if applicable), original
				            consignment copy, ABFRL invoice, Certificate Of Facts (COF) within 14 (fourteen) days from the date of incident.
				            However, it is expressly agreed that in the event Service Provider fails to provide the aforesaid Certificate Of
				            Facts(COF) and other relevant documents within 14 (fourteen) days from the date of incident then Service Provider shall
				            be solely liable to bear the amount of loss or damage and such claim amount shall be immediately deducted from the
				            service charges payable to Set-vice Provider by Company by raising Debit Note or by any other means of recovery without
				            prejudice to Company's other remedial rights available under laws.
				         </li>
				         <li> LOSS OF CONSIGNMENT: In case of loss of consignment/ non-delivery of
				            consignment within 30 days, the LSP shall be directly debited for the entire invoice value from running bills payable to
				            the LSP unless the LSP is able to conclusively prove that the shipment is not lost.
				            In case of lost consignment is found by LSP, LSP shall return the consignment within the timelines as mentioned below
				            and shall be entitled to refund of the debited amount to the extent mentioned against each time line,
				         </li>
				      </ol>
				   </div>
				   <div style={{padding: '50px'}}>
				      <table>
				         <tr>
				            <td>Above 30 days but within 60 days</td>
				            <td>80%</td>
				         </tr>
				         <tr>
				            <td>Above 60 days but within 120 days</td>
				            <td>50%</td>
				         </tr>
				         <tr>
				            <td>Above 120 days</td>
				            <td>No refund</td>
				         </tr>
				         <p>e. INTERCHANGE: In case of carton interchange and for wrong delivery, the LSP
				            shall be given 7 days to rectify the interchange and for wrong delivery failing which ABFRL shall directly debit the
				            entire invoice value of the carton from running bills payable to the LSP.
				         </p>
				      </table>
				      <br />
				      <br />
				      <p><b>3. SHORTAGES ON REVERSE PICK -UP:</b></p>
				      <p>For all shortages in reverse pick up ( open pick-up & open delivery) the LSP will be
				         debited directly for the entire invoice value from the running bills payable by the Company.
				      </p>
				      <p><b>4. INCENTIVE FOR ON -TIME DELIVERY:</b></p>
				      <table>
				         <tr>
				            <td>Delivery Performance</td>
				            <td>Incentive</td>
				         </tr>
				         <tr>
				            <td>92%-96%</td>
				            <td>5% additional freight for the month of the base freight</td>
				         </tr>
				         <tr>
				            <td>97% and above</td>
				            <td>10% additional freight for the month of the base freight</td>
				         </tr>
				         <p>Note: The above incentive clause is subject to half-yearly review of the overall performance. It will applicable only with
				            respect to outbound deliveries.
				         </p>
				      </table>
				   </div>
				   <div style={{padding: '50px'}} >
				      <p><b>5. DEMURRAGE CLAUSE:</b></p>
				      <ol>
				         <li>
				            Company shall be liable to pay Demurrage at the rate as mentioned below provided
				            that:
				            <ol type="a">
				               <li>LSP shall communicate within 1 day of the refusal of acceptance of the Company Products by the Consignee to the
				                  Company's representative and Company shall respond to the same within 5 days of receipt of such communication from the
				                  LSP;
				               </li>
				            </ol>
				         </li>
				         <li>Demurrage calculation would start after 7 days from the date of receipt of
				            communication by the LSP from the Company's representative only if the Company for the same has communicated no action.
				         </li>
				         <li>The demurrage bill need to be generated with all mail and documents proof and it is
				            charged as per the below
				         </li>
				      </ol>
				      <table>
				         <tr>
				            <td> DAYS</td>
				            <td>InceDEMUURAGE CHARGEntive</td>
				         </tr>
				         <tr>
				            <td>2-3 days</td>
				            <td>10% of Freight</td>
				         </tr>
				         <tr>
				            <td>4-5 days</td>
				            <td>15% of freight</td>
				         </tr>
				         <tr>
				            <td>more than 6 days</td>
				            <td>20% of freight</td>
				         </tr>
				         <p>Note: Demurrage clause will be enforced only at the discretion of Head Supply Chain.</p>
				      </table>
				      <p><b>6. POD CLAUSE:</b></p>
				      <table>
				         <tr>
				            <th>SR. NO. </th>
				            <th>DESCRIPTION</th>
				            <th>PAYMENT</th>
				         </tr>
				         <tr>
				            <td>1</td>
				            <td>100% POD submissions</td>
				            <td>Full Payment</td>
				         </tr>
				         <tr>
				            <td>2</td>
				            <td>More than or equal to 95% POD submissions within 48 hours</td>
				            <td>90% Payment only will be made. Balance payment will be made on submission of POD.</td>
				         </tr>
				         <tr>
				            <td>3</td>
				            <td>In case of Pending POD's</td>
				            <td>LSP shall give a declaration on its letter head with LR numbers by 5th of every subsequent month stating that the list
				               of shipments are delivered and pending for POD, any claim arising for non-delivery against such LRs in future, LSP will
				               compensate the invoice value in full with respect to LRs mentioned therein.
				            </td>
				         </tr>
				         <tr>
				            <td>4</td>
				            <td>In case of Lost POD's</td>
				            <td>LSP shall issue an indemnity letter in favour of the Company indemnifying it against any claims that may be raised at a
				               later date by the consignee.
				            </td>
				         </tr>
				      </table>
				   </div>
				   <div style={{padding: '50px'}} >
				      <p>Note 
				      <ol>
				         <li>When remark on the POD is open condition or damaged and there is no shortages, please ensure it is written ( No QTY is
				            missing) on the POD
				         </li>
				         <li>If POD has no seal or signature or is not clean, the LSP will be debited for the same.</li>
				      </ol>
				      </p>
				      <p><b>7. SUSTAINABILITY INDEX:</b></p>
				      <p>LSP shall provide a declaration of the following to the Company:</p>
				      <ol type="a">
				         <li>LSP does not use any vehicle, which is more than lOyears old and if so used, will be discontinued for the purpose of
				            carrying out logistic services under this Agreement;
				         </li>
				         <li> LSP has carried out the Emission test which is 100% compliant;</li>
				         <li> Fuel efficient routing has been complied with by the LSP and effective steps have been undertaken to optimize fuel
				            efficiency;
				         </li>
				         <li> All environmental and sustainability initiatives have been undertaken and complied with by the LSP.</li>
				      </ol>
				   </div>
			</div>
  		)
	}
}

export default SampleFile;
