import React from 'react';
import './checkbox.less'

export default class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value : this.props.status
    }
  }
  handleInputChange = (e) =>{    
      this.props.handleChange(e,this.props.id,this.props.type,this.props.user);
  }
  componentWillReceiveProps(nextProps){
    this.setState({
      value : nextProps.status
    })
  }
  render() {    
    return (
      <div className="switch" >
	        <input type="checkbox" disabled={this.props.disabled} checked={this.state.value} id={this.props.id} onChange = {this.handleInputChange}/>
	        <label htmlFor={this.props.id}></label>
      </div>
    )
  }
}
