import React, { Component, } from 'react';
import InputText from "../../../../common/components/input-text/input-text";
import Cx from "classnames";
import Select from "react-select";
import { getEmail, getUserCompanyId, getUserId } from '../../../../../utils/common';

class AddMfsMaster extends Component {
    constructor(props){
        super(props);
        this.state = {
            material_code: this.props.data ? this.props.data.material_code : '',
            quantity: this.props.data ? this.props.data.quantity : '',
            percentage: this.props.data ? this.props.data.percentage.replace(/[^0-9]/g, '') : '',
            uom:  this.props.data ? this.props.data.uom : '',
            previous_data:this.props.data ? this.props.previous_data : null,
        }
    }
    onChangeHandler = (value, id) => {
       let sanitizedValue = value;
        
        switch (id) {
          case "material_code":
            sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, ''); 
            break;
          case "quantity":
          case "percentage":
            sanitizedValue = value.replace(/[^0-9]/g, ''); 
            break;
        //   case "uom":
        //     sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '');
        //     break;
          default:
            break;
        }
        
        sanitizedValue = sanitizedValue.toUpperCase(); 
        
        this.setState({
          [id]: sanitizedValue
        });
      }
    
     onSave = () => { 
        this.props.onAddMfsMaster(this.state);
        this.props.toggleAdd();
    }

    updateMfsMaster = () => {
        let details = this.state;
            details.previous_data.user_id = getUserId();
            details.previous_data.user_email = getEmail();
            details.previous_data.update_flag = true;
            details.previous_data.master_type = 14;
            details.previous_data.company_id = getUserCompanyId()
        this.props.updateMfsMaster(details);
        this.props.toggleAdd();
    }

    render() {
        const { percentage, material_code, quantity, uom } = this.state;
        const { data, mfsMasterUomListing } = this.props;
        const updateMfsMasterUomListing = mfsMasterUomListing.map((ele)=>{
            return { ...ele, value: ele.name, label: ele.name }
        })
       
          return (
            <div className = "add-user-wrap add-bay">
                <div onClick = {this.props.toggleAdd} className = "overlay"></div>
                <div id = "modal" className = "modal-popup">
                    <div className = "pop-heading">
                        {`${data ?'Edit' : 'Add'} Mfs Master`}
                    </div>
                    <div className = "content add-edit-user">
                        <form className = "form-section" autoComplete = "nope">
                            <InputText placeholder = "Material code" id = {"material_code"} label = "Material code" value = {material_code} readonly={!!data?true:false}      changeHandler = {this.onChangeHandler}/>
                            <InputText placeholder = "Quantity" id = {"quantity"} label = "Quantity" value = {quantity} changeHandler = {this.onChangeHandler} />
                            <InputText placeholder = "Percentage" id = {"percentage"} label = "Percentage" value = {percentage} changeHandler = {this.onChangeHandler} />
                              {/* <select className="select" style={{marginTop:"20px",height:"30px"}} placeholder = "Uom" id = {"uom"} label = "Uom" value = {uom} changeHandler = {this.onChangeHandler} />   */}
                              
                                  <div className="label">Uom</div>
                              <Select
                                  label="Uom"
                                  className="select"
                                  value={updateMfsMasterUomListing.find((ele) => ele.value == uom)}
                                  options={updateMfsMasterUomListing}
                                  onChange={(e)=>{
                                      this.onChangeHandler(e.value,"uom")
                                  }} 
                              />
                        
                              
                             
                              
                              

                             

                        </form>
                        <div className = "submit-section">
                            <button className = "add-button discard" onClick={this.props.toggleAdd} >Cancel</button>
                            {!data ? <button className ={Cx("add-button", {
                                'disable-btn' : !(percentage && quantity && material_code && uom)
                            })} onClick={this.onSave}>Save</button>
                            :  <button className ={Cx("add-button", {
                                'disable-btn' : !(percentage && quantity && material_code && uom)
                            })} onClick={this.updateMfsMaster}>Update</button>
                        }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddMfsMaster;
