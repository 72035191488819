import React, { Component} from 'react';
import {connect} from 'react-redux';
import "./shipment_log.less";

class ShipmentLog extends Component {
	constructor(props){
		super(props);
	}
	componentDidMount(){
		const { dispatch } = this.props;
	}
	render() {
		return (
			<div className = "shipment-root">
                <div className = "banner">SAP Shipment Document Log</div>
			</div>
		);
	}
}

  
export default ShipmentLog;