import React, { Component, Fragment } from 'react';
import "./liabilityStructure.less";

import Button from "../../home/button-classic/button-classic";
import InputText from "../../common/components/input-text/input-text";

import Select from "react-select";
import { getCookie } from "../../../utils/cookies";

const LIABILITY_TYPES = [{ id: 1, label: 'Limited', value: 1 }, { id: 2, label: 'Unlimited', value: 2 }];

const RULES_LIST = [
                        {id: 1, value: 1, inputs: 1, unit: 'Rs', name: 'Upto', label: 'Upto (amount)'},
                        {id: 2, value: 2, inputs: 1, unit: 'Rs', name: 'Above', label: 'Above (amount)'},
                        {id: 3, value: 3, inputs: 2, unit: 'Rs', name: 'In Between', label: 'In Between (amount range)'},
                        {id: 4, value: 4, inputs: 1, unit: 'cartons', name: 'Limited till', label: 'Limited till (cartons)'},
                        {id: 8, value: 8, inputs: 1, unit: 'cartons', name: 'Limited Above', label: 'Limited Above (cartons)'},
                        {id: 5, value: 5, inputs: 1, unit: 'Days', name: 'After', label: 'After (days)'},
                        {id: 6, value: 6, inputs: 0, unit: null, name: 'Nil', label: 'Nil'},
                        {id: 7, value: 7, inputs: 1, unit: 'Rs', name: 'Invoice', label: 'Invoice (amount)'},
                    ];

const DUMMY_CLAIM = {
                        claim_type_id: null,
                        claim_type_name: null,
                        rules: { range: [], rule: null, range_unit: null, inputs: 0, max: 0, min: 0 }
                    };

const DUMMY_SHORTAGE = {
                            shortage_id: null,
                            shortage_name: null,
                            claim_type: [],
                        }

class AddLiability extends Component {

	constructor(props){
        super(props);
        this.state = {
            isError: '',
            claims: [],
            data: {
                liability_type: null,
                company_id: JSON.parse(getCookie("user")).company_id,
                // liability_id: null,
                vendor_id: null,
                vendor_name: null,
                seeker_id: null,
                mode_id: null,
                mode_name: null,
                types_of_shortages: []
            },
        }
    }

    handleClaimSelection = (claim) => {
        const { claims } = this.state;
        const data = {...this.state.data};

        if (claims.includes(claim.value)) {
            claims.splice(claims.indexOf(claim.value), 1);

                (data.types_of_shortages || []).forEach((shortage) => {
                    if (shortage.claim_type.findIndex(obj => obj.claim_type_id===claim.value) > -1) {
                        shortage.claim_type.splice(shortage.claim_type.findIndex(obj => obj.claim_type_id===claim.value), 1);
                    }
                })

        } else {
            claims.push(claim.value);

            (data.types_of_shortages || []).forEach((shortage) => {
                if (shortage.claim_type.findIndex(obj => obj.claim_type_id===claim.value) === -1) {
                    const newRule = {
                                claim_type_id: null,
                                claim_type_name: null,
                                rules: { range: [], rule: null, range_unit: null, inputs: 0, max: 0, min: 0 }
                            };
                    newRule.claim_type_name = claim.label;
                    newRule.claim_type_id = claim.value;
                    shortage.claim_type.push(newRule);
                }
            })
        }
        this.setState({ claims, data });
    }

    handleLiabilityChange = (value) => {
        const { data } = this.state;
        data.liability_type = value.label;
        // data.liability_id = value.value;
        this.setState({ data });
    }

    onVendorChange = (value) => {
        const { data } = this.state;
        data.vendor_name = value.label;
        data.vendor_id = value.value;
        data.seeker_id = value.seeker_id;
        data.company_category = value.company_category;
        data.company_category_id = value.company_category_id;
        this.setState({ data });
    }

    onModeChange = (value) => {
        const { data } = this.state;
        data.mode_name = value.label;
        data.mode_id = value.value;
        this.setState({ data });
    }

    onShortageChange = (value) => {
        const data = {...this.state.data};
        let shortages = [];
        
        if (value.length > 0) {
            (value || []).forEach((val) => {
                // const isAdded = (data.types_of_shortages || []).filter((obj) => { return obj.shortage_id === val.value });
                // if (isAdded.length > 0) {
                //     shortages = data.types_of_shortages || [];
                //     if (shortages.findIndex(obj => obj.shortage_id === isAdded[0].shortage_id) > -1) {
                //         shortages.splice(shortages.findIndex(obj => obj.shortage_id === isAdded[0].shortage_id), 1);
                //     }
                // } else {
                    const data = {
                                shortage_id: null,
                                shortage_name: null,
                                claim_type: [],
                            };
                    data.shortage_name = val.label;
                    data.shortage_id = val.value;
                    shortages.push(data);
                // }
            })
        } 
        
        data.types_of_shortages = shortages;
        
        this.setState({ data });
    }

    handleRuleType = (value, sIndex, cIndex, type) => {
        const data = {...this.state.data};
        const claim = data.types_of_shortages[sIndex].claim_type[cIndex].rules;
        claim.rule = value.name;
        claim.inputs = value.inputs; 
        claim.range_unit = value.unit;
        this.setState({ 
            data, 
            // [type]: value 
        });
    }

    handleInputChange = (value, type, sIndex, cIndex, id) => {
        const data = {...this.state.data};
        data.types_of_shortages[sIndex].claim_type[cIndex].rules[type] = parseInt(value);
        this.setState({ 
            data, 
            // [id]: parseInt(value) 
        });
    }

    handleSaveItem = () => {
        const { data, claims } = this.state;
        (data.types_of_shortages || []).forEach((shortage, sIdx) => {
            (shortage.claim_type || []).forEach((claim, ind) => {
                if (claim.rules.inputs===0) {
                    claim.rules.range = [];
                } else {
                    // const rule = this.state[`rule-${sIdx}-${ind}`] ? this.state[`rule-${sIdx}-${ind}`] : {};
                    if (claim.rules.inputs===1) {
                        // claim.rules.rule ;
                        // claim.rules.range_unit;
                        claim.rules.range = Array.isArray(claim.rules.range) ? claim.rules.range : [claim.rules.range];
                    } else {
                        // claim.rules.rule;
                        // claim.rules.range_unit;
                        claim.rules.range = [claim.rules.max, claim.rules.min]; 
                    }
                }
            })
        });
        if (data.types_of_shortages.length > 0 && data.vendor_name && claims.length > 0) {
            this.props.handleSave(data);
            this.setState({ isError: '' });
        } else {
            this.setState({ isError: 'Please fill all the details.' });
        }
    }

	render() {
		const { modesList, vendorsList, claimTypes, shortageTypes } = this.props;
        const { claims, data } = this.state;
		
        return(
			<div className="add-user-wrap">
                <div className="overlay"></div>
                <div id="modal" className="modal-popup">
                    <div className="pop-heading"> CLAIM CLAUSES </div>
                    <div className="content">

                        <div className="list-section table-header divTableRow">
                            <div className="divTableCell bordered"> Liability Type </div>
                            <div className="divTableCell bordered"> Vendor Name </div>
                            <div className="divTableCell bordered"> Mode </div>
                            <div className="divTableCell bordered"> Types Of Shortages </div>
                            <div className="divTableCell bordered"> Claim Type </div>
                            <div className="divTableCell bordered"> </div>
                        </div>

                        <div className="list-section table-header divTableRow">
                            <div className="divTableCell"> 
                                <Select
                                    className="select"
                                    options={LIABILITY_TYPES}
                                    onChange={(value) => { this.handleLiabilityChange(value) }}
                                />
                            </div>
                            <div className="divTableCell"> 
                                <Select
                                    className="select"
                                    options={vendorsList || []}
                                    onChange={(value) => { this.onVendorChange(value) }}
                                />
                            </div>
                            <div className="divTableCell">
                                <Select
                                    className="select"
                                    options={modesList || []}
                                    onChange={(value) => { this.onModeChange(value) }}
                                />
                            </div>
                            <div className="divTableCell"> 
                                <Select
                                    isMulti={true}
                                    className="select"
                                    options={shortageTypes || []}
                                    onChange={(value) => { this.onShortageChange(value) }}
                                />
                            </div>
                            <div className="divTableCell">
                                <div className="claim-box">
                                {(claimTypes || []).map((claim) => {
                                  return (
                                    <div 
                                        className={(claims.includes(claim.value)) ? "claim-item active" : "claim-item"} 
                                        key={`${claim.label}-${claim.value}`}
                                        onClick={() => this.handleClaimSelection(claim)}
                                    >{claim.label}</div>
                                  )
                                })}
                                </div>
                            </div>
                            <div className="divTableCell"> </div>
                        </div>

                        <div>
                        {
                            (data.types_of_shortages || []).map((shortage, sIndex) => {
                                return (
                                    <div className="mb-10">
                                    {(shortage.claim_type || []).length > 0 && <div>{shortage.shortage_name}</div>}
                                    {
                                        (shortage.claim_type || []).map((claim, cIndex) => {
                                            return (
                                                <div key={`${claim.claim_type_id}-${cIndex}`} className="mb-10">
                                                    <div className="list-section table-header divTableRow">
                                                        <div className="divTableCell"></div>
                                                        <div className="divTableCell bordered"> Rule </div>
                                                        <div className="divTableCell bordered"> Input/Range </div>
                                                    </div>
                                                    <div className="list-section table-header divTableRow">
                                                        <div className="divTableCell">{claim.claim_type_name}</div>
                                                        <div className="divTableCell"> 
                                                            <Select 
                                                                className="select" 
                                                                options={RULES_LIST}
                                                                onChange={(value) => { this.handleRuleType(value, sIndex, cIndex, `rule-${sIndex}-${cIndex}`) }}
                                                                value={RULES_LIST.filter((rule) => { return rule.name===claim.rules.rule })}
                                                            />
                                                        </div>
                                                        {
                                                            claim.rules.inputs===1 &&
                                                            <div className="divTableCell"> 
                                                                <div className="sub-header-inline penaltyPercent">
                                                                    <InputText 
                                                                        id={`range-${sIndex}-${cIndex}`}
                                                                        type="number" 
                                                                        placeholder="No."
                                                                        value={claim.rules.range} //{this.state[`range-${sIndex}-${cIndex}`] || ''} //{claim.rules.range}
                                                                        changeHandler={(value, id) => { this.handleInputChange(value, 'range', sIndex, cIndex, id) }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            claim.rules.inputs>1 &&
                                                            <Fragment>
                                                                <div className="divTableCell"> 
                                                                    <div className="sub-header-inline penaltyPercent">
                                                                        <InputText 
                                                                            id={`max-${sIndex}-${cIndex}`}
                                                                            type="number" 
                                                                            placeholder="No." 
                                                                            value={claim.rules.max} //{this.state[`max-${sIndex}-${cIndex}`] || ''} //{claim.rules.max}
                                                                            changeHandler={(value, id) => { this.handleInputChange(value, 'max', sIndex, cIndex, id) }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="divTableCell"> 
                                                                    <div className="sub-header-inline penaltyPercent">
                                                                        <InputText 
                                                                            id={`min-${sIndex}-${cIndex}`}
                                                                            type="number" 
                                                                            placeholder="No." 
                                                                            value={claim.rules.min} //{this.state[`min-${sIndex}-${cIndex}`] || ''} //{claim.rules.min}
                                                                            changeHandler={(value, id) => { this.handleInputChange(value, 'min', sIndex, cIndex, id) }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                    </div>
                                );

                                
                            })
                        }
                        </div>
                        
                        <div className="submit-section mt-40">
                            <button 
                                className="add-button discard" 
                                onClick={() => { 
                                        this.setState({ isError: '' }); 
                                        this.props.cancelNewSlab(); 
                                }}>Cancel</button>
                            <button 
                                className="add-button" 
                                // disabled={this.isDisabled()} 
                                onClick={this.handleSaveItem}
                            >Save
                            </button>
                        </div>
                        
                        {this.state.isError.length > 0 && <div className="error">{this.state.isError}</div>}
                    </div>
                </div>
            </div>
		)
	}
}

export default AddLiability;