import React, { Component } from 'react';
import Button from "../../../button-classic/button-classic";
import Delete from "../../../../../assets/images/delete.svg";
import Edit from "../../../../../assets/images/edit.svg";
import AddWBConversion from "./add_wbconversion";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";

class WBConversion extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
            previousWBConversionData : null
        }
    }

    componentDidMount() {
        this.props.getWBConversion();
    }

    AddWB = (editData = '') => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen, editData, previousWBConversionData : editData});
    }

    toggleDirectDelete = (WBData) => {
        const data = {
            Plant: WBData.Plant,
            Vendor: WBData.Vendor
        }
        this.props.deleteWBConversion(data);
    }

    onAuditClick = () => {
        const {history,match,depotCode} = this.props;
        // const companyId = getUserCompanyId()
        const companyId = match.params.id
        const branchId = match.params.branchId
        // const branchCode = depotCode
        const masterType = 16
        const navUrl = `/auditScreen/${companyId}/${masterType}/${branchId}`;
        history.push(navUrl)
      }

    render() {
        const { WBConversionData } = this.props;
        const {previousWBConversionData} = this.state
        return(
            <div className="plants-depots-main-wrap">
                <div className="search-holder">
                <div className="add-plants-section">
                    <Button value="+ Weigh Bridge" click={() => this.AddWB('')} />
                    <Button value="Audit" click={()=>this.onAuditClick()} />
                </div>
                </div>
                <div className="heading">
                    <div className="flow-head">Site</div>
                    <div className="flow-head">Plant</div>
                    <div className="flow-head">Description</div>
                    <div className="flow-head">Vendor</div>
                    <div className="flow-head">Actions</div>
                </div>
                {(WBConversionData || []).map((WBData, index) => {
                return(
                    <div className={"depot-users-list"} key={`WB_${index}`}>
                        <div className="flow-head">{WBData.Site}</div>
                        <div className="flow-head">{WBData.Plant}</div>
                        <div className="flow-head">{WBData.Description}</div>
                        <div className="flow-head">{WBData.Vendor}</div>
                        <div className="flow-head">
                            <div className = "delete-icon" style={{backgroundImage: `url(${Edit})`}} onClick = {() => this.AddWB(WBData)}></div>
                            <div className = "delete-icon" style={{backgroundImage: `url(${Delete})`}} onClick = {() => this.toggleDirectDelete(WBData)}></div>
                        </div>
                    </div>)
                })}
                
                {(!WBConversionData || WBConversionData.length === 0) && <div className={'no_data_found'}>{'No Data Found'}</div>}

                {this.state.isOpen && 
                <AddWBConversion 
                toggleAdd={this.AddWB} 
                onAddWBConversion={this.props.onAddWBConversion} 
                data={this.state.editData} 
                updateWBConversion={this.props.updateWBConversion}
                previousWBConversionData={previousWBConversionData}
                />}
            </div>
        )
    }
}

export default withRouter(WBConversion);