import React, { Component } from 'react';
import Select from 'react-select';
import InputText from '../../../../common/components/input-text/input-text';
import { physicalState, damagesUOM, dplPhysicalState, BoundType } from '../../../../../utils/constants';
import { uomEquality, SAP_DEEPAK_PHENOLICS } from '../../../../../utils/common';
import '../../reconciliation.less';
import { decimalNumber } from '../../../../../utils/validate';
import Button from '../../../button-classic/button-classic';
import Delete from '../../../../../assets/images/delete.svg';
import Add from '../../../../../assets/images/add.svg';
import Switch from "react-switch";

class ShortagePilferage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                physical_state: this.props.editData ? this.props.editData.physical_state : '',
                tolerance_condition: this.props.editData ? this.props.editData.tolerance_condition : '',
                tolerance: this.props.editData ? this.props.editData.tolerance : '',
                tolerance_uom: this.props.editData ? this.props.editData.tolerance_uom : '',
                product: this.props.editData ? this.handleGetProductList() : '',
                bound_type: this.props.editData ? this.props.editData.bound_type : undefined,
                tolerance_switchable: this.props.editData ? this.props.editData.tolerance_switchable : undefined,
                purity_applicable: this.props.editData ? this.props.editData.purity_applicable : undefined,
                switch_to_tolerance: this.props.editData ? this.props.editData.switch_to_tolerance : undefined,
                shortage_applicable: this.props.editData ? this.props.editData.shortage_applicable : undefined,
            }
        }
    }

    handleChange = (id , selectOptions) => {
        let { data } = this.state;
        data[id] = (id === 'product' ? selectOptions : (id === 'tolerance_condition' ? selectOptions.label : selectOptions.value));
        this.setState({ data });
    }

     onAmountChangeHandler = (value, id) => {  
      let { data } = this.state;
        data[id] = value;
        this.setState({ data });
    }

    mandatoryCheck = () => {
        const { data } = this.state;
        const { editData } = this.props;
        if(data.tolerance_switchable ? data.switch_to_tolerance : true){
            if(editData){
                this.setState({errorMsg: ''}, this.updateDetails)
                
            }else{
                this.setState({errorMsg: ''}, this.onSave)
            } 
        } else{
            this.setState({errorMsg: 'Please add switch to tolerance value'})
        }
    }

    onSave = () => {
        this.props.addShortagePilferage(this.state.data);
        this.props.toggleAdd();
    }

    updateDetails = () => {
        const { data } = this.state;
        const { editData } = this.props;
        data.company_id = editData.company_id;
        data.shortage_tolerance_id = editData.shortage_tolerance_id
        this.props.addShortagePilferage(this.state.data, true);
        this.props.toggleAdd();
    }

    handleSwitch = (checked, name) => {
        const { data } = this.state;
        data[name] = checked;
        this.setState({ data })
    }

    handleGetProductList = () => {
        const product = (this.props.editData.product || []).map((ele) => {
            const newEle = {
                ...ele,
                label: `${ele.materialName} - (${ele.product})`
            }
            return newEle
        })
        return product
    }

    render() {
       let { data }= this.state;
       const { productList } = this.props;
        const isSapDPL = SAP_DEEPAK_PHENOLICS.includes(this.props.companyId)
        return(<div className = "add-company-wrap add-provider-root add-row detention">
            <div onClick = {this.props.toggleAdd} className = "overlay">
                <div className = "modal-popup" onClick={(e) => { e.stopPropagation() }}>
                    <div className = "heading">
                        {`${this.props.editData ? 'Edit' : 'Add'} Shoratage/Pilferage Tolerance`}
                    </div>
                    <form className = "form-section" autoComplete = "nopes">
                        <div className="select-wrap">
                            <div className="label">Product Type</div>
                            <Select className={'select'}
                                isMulti
                                options={(productList || []).map((ele)=>{
                                    const newEle = {
                                        ...ele ,
                                        label:`${ele.materialName} - (${ele.product})`
                                    }
                                    return newEle
                                })} 
                                // getOptionLabel={(option) => option.materialName}
                                getOptionValue={(option) => option.productMasterId}
                                value={data.product} 
                                onChange={this.handleChange.bind(this, 'product')}
                                onInputChange={(inputValue) => {
                                    this.props.getProductDetails(inputValue);
                                }}
                            
                            />
                        </div>
                        <div className="select-wrap">
                            <div className="label">Physical State</div>
                            <Select className={'select'}
                                options={isSapDPL ? dplPhysicalState : physicalState} 
                                value={(isSapDPL ? dplPhysicalState : physicalState).filter(val => {return val.value === data.physical_state})} 
                                onChange={this.handleChange.bind(this, 'physical_state')}
                            />
                        </div>
                        <div className="select-wrap">
                            <div className="label">Condition</div>
                            <Select className={'select'}
                                options={uomEquality} 
                                value={uomEquality.filter(val => {return val.label === data.tolerance_condition})} 
                                onChange={this.handleChange.bind(this, 'tolerance_condition')}
                            />
                        </div>
                        <InputText placeholder={'Tolerance'} label={'Tolerance'} id={'tolerance'} value = {data.tolerance} changeHandler = {(value) => this.onAmountChangeHandler(value, 'tolerance')} type={'number'}/>
                        <div className="select-wrap">
                            <div className="label">UOM</div>
                            <Select className={'select'} value={damagesUOM.filter(val => { return val.value == data.tolerance_uom})} onChange={this.handleChange.bind(this, 'tolerance_uom')} options={damagesUOM}/>
                        </div>
                        {isSapDPL &&
                        <>
                            <div className="select-wrap">
                                <div className="label">Bound Type</div>
                                <Select className={'select'} value={BoundType.filter(val => { return val.value == data.bound_type})} onChange={this.handleChange.bind(this, 'bound_type')} options={BoundType} isDisabled={this.props.editData ? true : false}/>
                            </div>
                            <div
                                className="fBold fs-16 mt-10 mb-10 header-medium-color col-2 flex-1 select-wrap"
                                style={{  color: "#868e96" }}
                            >
                            <span> Is Tolerance Switchable</span>
                                <div className="mt-10">
                                <Switch
                                    onChange={(value) => this.handleSwitch(value, 'tolerance_switchable')}
                                    onColor="#528aea"
                                    checked={data.tolerance_switchable}
                                />
                                </div>
                            </div>

                            <div
                                className="fBold fs-16 mt-10 mb-10 header-medium-color col-2 flex-1 select-wrap"
                                style={{  color: "#868e96" }}
                            >
                            <span> Is Purity Applicable</span>
                                <div className="mt-10">
                                <Switch
                                    onChange={(value) => this.handleSwitch(value, 'purity_applicable')}
                                    onColor="#528aea"
                                    checked={data.purity_applicable}
                                />
                                </div>
                            </div>
                            <InputText placeholder={'Switch To Tolerance Value'} label={'Switch To Tolerance Value'} id={'switch_to_tolerance'} value = {data.switch_to_tolerance} changeHandler = {(value) => this.onAmountChangeHandler(value, 'switch_to_tolerance')} type={'number'}/>
                        
                            <div
                                className="fBold fs-16 mt-10 mb-10 header-medium-color col-2 flex-1 select-wrap"
                                style={{  color: "#868e96" }}
                            >
                            <span> Is Shortage Applicable</span>
                                <div className="mt-10">
                                <Switch
                                    onChange={(value) => this.handleSwitch(value, 'shortage_applicable')}
                                    onColor="#528aea"
                                    checked={data.shortage_applicable}
                                />
                                </div>
                            </div>
                        </>}
                    </form>
                    <div className = "submit-section submit-area">
                    {this.state.errorMsg && <div className='error-color'>{this.state.errorMsg}</div>}
                    {
                    <div className="button-section">
                        {!this.props.editData && <div className ="button-section">
                            <Button className ="add-button" click={isSapDPL ? this.mandatoryCheck : this.onSave} value="Save" />
                        </div>}
                        {this.props.editData && <div className ="button-section">
                            <Button className ="add-button" click={isSapDPL ? this.mandatoryCheck : this.updateDetails} value="Update"/>   
                        </div>}
                        </div> 
                     }
                        <div className ="button-section" >
                            <div className ="add-button" onClick={this.props.toggleAdd}>Close</div>
                        </div> 
                    
                    </div>
                </div>
            </div>
         </div>)
    }
}

export default ShortagePilferage;
